import styled from "@material-ui/core/styles/styled";

export const Downloading = styled("div")(({ theme }) => ({
  fontSize: "0.875rem",
  color: theme.colors.main,
  fontWeight: 500,
  marginTop: "5px ",
  marginLeft: "4px",
}));
export const Error = styled("span")(({ theme }) => ({
  fontWeight: 600,
  color: theme.colors.pink,
  fontSize: "0.875rem",
  marginTop: "4px",
  marginLeft: "4px",
}));
