import {
  GET_STAFF_SINGLE_SPEND_VENUE_LOADING,
  GET_STAFF_SINGLE_SPEND_VENUE_SUCCESS,
  GET_STAFF_SINGLE_SPEND_VENUE_FAIL,
  GET_STAFF_SPEND_VENUE_ACTIVITIES_LOADING,
  GET_STAFF_SPEND_VENUE_ACTIVITIES_SUCCESS,
  GET_STAFF_SPEND_VENUE_ACTIVITIES_FAIL,
} from "../../../../redux/types";

const initState = {
  id: "",
  name: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  address: "",
  region: "",
  url: "",
  dateOfLastAccept: "",
  loading: false,
  activities: [],
  activitiesLoading: true,
  error: null,
};

const staffSingleSpendVenueReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_STAFF_SINGLE_SPEND_VENUE_LOADING:
      return {
        loading: true,
      };
    case GET_STAFF_SINGLE_SPEND_VENUE_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case GET_STAFF_SINGLE_SPEND_VENUE_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case GET_STAFF_SPEND_VENUE_ACTIVITIES_LOADING:
      return {
        activitiesLoading: true,
        activities: [],
      };
    case GET_STAFF_SPEND_VENUE_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activities: payload,
        activitiesLoading: false,
      };
    case GET_STAFF_SPEND_VENUE_ACTIVITIES_FAIL:
      return {
        ...state,
        activitiesLoading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default staffSingleSpendVenueReducer;
