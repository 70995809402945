import { combineReducers } from "redux";

import { LOGOUT_SUCCESS } from "../types";
import spendVenuesPublic from "../../pages/GeneralRoutes/SelectVenue/reducer";
import venueGroupProfile from "../../pages/GeneralRoutes/Profile/VenueOrGroup/reducer";
import tempoDashboard from "../../pages/Dashboard/Tempo/reducer";
import earnGroupDashboard from "../../pages/Dashboard/EarnGroup/reducer";
import uiReducer from "./uiReducer";
import userReducer from "./userReducer";
import auth from "./auth";
import AcceptTC from "../../pages/SpendVenueRoutes/AcceptTimeCredits/reducer";
import loadingReducer from "./loadingReducer";
import giveCredits from "../../pages/EarnGroupRoutes/GiveCredits/reducer";
import spendVenueInsights from "../../pages/SpendVenueRoutes/SpendVenueInsights/reducer";
import activityProfile from "../../pages/GeneralRoutes/Profile/Activity/reducer";
import activities from "./activities";
import invite from "../../pages/EarnGroupRoutes/Invite/reducer";
import requestTimeCredits from "../../pages/EarnGroupRoutes/RequestTimeCredits/reducer";
import staffCreditRequests from "../../pages/StaffRoutes/Staff/CreditRequests/reducer";
import staffInsights from "../../pages/StaffRoutes/Staff/insights.reducer";
import staffTransactions from "../../pages/StaffRoutes/Staff/Transactions/reducer";
import staffMembers from "../../pages/StaffRoutes/Staff/Members/reducer";
import staffEarnGroups from "../../pages/StaffRoutes/Staff/EarnGroups/reducer";
import staffSingleSpendVenue from "../../pages/StaffRoutes/Staff/SingleSpendVenue/reducer";
import staffSpendVenues from "../../pages/StaffRoutes/Staff/SpendVenues/reducer";
import staffOffers from "../../pages/StaffRoutes/Staff/Offers/reducer";
import staffSingleEarnGroup from "../../pages/StaffRoutes/Staff/SingleEarnGroup/reducer";
import staffSingleMember from "../../pages/StaffRoutes/Staff/SingleMember/reducer";
import staffSingleProgramme from "../../pages/StaffRoutes/Staff/SingleProgramme/reducer";
import earnGroupMembers from "../../pages/EarnGroupRoutes/Members/reducer";
import earnGroups from "./earnGroups";
import singleTransaction from "./singleTransaction";

import giftTimeCredits from "../../pages/MemberRoutes/GiftTimeCredits/reducer";
import forgetPassword from "../../pages/GeneralRoutes/ForgetPassword/reducer";
import staffProgrammes from "../../pages/StaffRoutes/Staff/Programmes/reducer";
import updateEarnGroupProfile from "./updateEarnGroupProfile";
import spendActivityForm from "../../pages/SpendActivityForm/reducer";
import spendVenueForm from "../../pages/StaffRoutes/SpendVenueForm/reducer";
import programmeInviteEarnGroup from "../../pages/StaffRoutes/Staff/ProgrammeInviteEarnGroup/reducer";
import accessPermissions from "../../pages/StaffRoutes/Staff/AccessPermission/reducer";
import administratorSection, {
  earnGroupAccountHolderLogs,
} from "../../components/AdministratorSection/reducer";
import extProgManagers from "../../pages/StaffRoutes/Staff/CreateProgramme/ExtProgManagerSection/reducer";
import location from "./location";
import addMembersToEarnGroup from "../../pages/StaffRoutes/Staff/AddMembers/reducer";
import staffSingleSpendPartner from "../../pages/StaffRoutes/Staff/SingleSpendPartner/reducer";
import staffEditSVAccount from "../../pages/StaffRoutes/Staff/EditSpendVenueAccount/reducer";
import spendPartnerForm from "../../pages/StaffRoutes/SpendPartnerForm/reducer";
import spendPartner from "../../pages/SpendPartner/reducer";
import selectUsers from "../../pages/GeneralRoutes/SelectUser/reducer";
import spendVenueOffers from "../../pages/SpendVenueRoutes/Offers/reducer";
import earnGroupOffers from "../../pages/EarnGroupRoutes/Offers/reducer";
import RecognitionListingRequests from "../../pages/StaffRoutes/Staff/RecognitionListingRequests/reducer";
import zendesk from "./zendesk";
import memberGroups from "../../pages/MemberRoutes/MyGroups/reducer";
import orders from "../../pages/StaffRoutes/Staff/Orders/reducer";
import paidPackages from "./paidPackages";
import orderInvoice from "../../pages/GeneralRoutes/InvoiceOrder/reducer";
import programmes from "./programmes";
import activateAccount from "../../pages/GeneralRoutes/ActivateAccount/reducer";
import tkGroupRequests from "../../pages/StaffRoutes/Staff/TkGroupRequests/reducer";
import tkProgrammeConflict from "../../pages/StaffRoutes/Staff/TkProgrammeConflict/reducer";
import tkGroupConflictData from "../../pages/StaffRoutes/Staff/TkSingleGroupRequest/reducer";
import userAccount from "./userAccount";
import duplicateSpendActivity from "../../pages/StaffRoutes/Staff/DuplicateSpendActivity/reducer";

const reducers = combineReducers({
  ui: uiReducer,
  user: userReducer,
  selectUsers,
  auth,
  spendVenuesPublic,
  earnGroupDashboard,
  tempoDashboard,
  AcceptTC,
  spendVenueInsights,
  loading: loadingReducer,
  venueGroupProfile,
  activityProfile,
  activities,
  giveCredits,
  invite,
  requestTimeCredits,
  staffCreditRequests,
  staffInsights,
  staffTransactions,
  staffMembers,
  staffEarnGroups,
  staffSingleMember,
  staffSingleSpendVenue,
  staffSpendVenues,
  staffOffers,
  staffSingleEarnGroup,
  staffSingleProgramme,
  earnGroupMembers,
  singleTransaction,
  giftTimeCredits,
  forgetPassword,
  staffProgrammes,
  userAccount,
  updateEarnGroupProfile,
  earnGroups,
  spendActivityForm,
  spendVenueForm,
  programmeInviteEarnGroup,
  accessPermissions,
  administratorSection,
  extProgManagers,
  location,
  addMembersToEarnGroup,
  staffSingleSpendPartner,
  staffEditSVAccount,
  spendPartnerForm,
  spendPartner,
  spendVenueOffers,
  earnGroupOffers,
  zendesk,
  RecognitionListingRequests,
  memberGroups,
  earnGroupAccountHolderLogs,
  orders,

  paidPackages,
  orderInvoice,
  programmes,

  activateAccount,
  tkGroupRequests,
  tkProgrammeConflict,
  tkGroupConflictData,
  duplicateSpendActivity,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return reducers(state, action);
};

export default rootReducer;
