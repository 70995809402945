import React from "react";
import * as T from "../Typograpy";

import { Row, Col } from "../Grid";
import { InputField } from "../Inputs";
import RemoveRejoin from "../RemoveRejoin";
import * as S from "./style";

const PricePackage = ({
  element,
  packages,
  setPackages,
  errors = {},
  tcPrice,
  vat,
  showRemove,
}) => {
  const changeBigState = (fieldName, value) => {
    const previousArray = [...packages];

    const packageIndex = previousArray.findIndex((e) => e.id === element.id);
    previousArray[packageIndex][fieldName] = value;

    setPackages(previousArray);
  };

  const onChange = async ({ target: { name, value } }) => {
    await changeBigState(name, value);
  };

  const pgAmount =
    Math.round(Number(tcPrice) * Number(element.credits) * 1000) / 1000;

  const totalVat = Math.round(Number(pgAmount) * Number(vat) * 1000) / 1000;

  const pgTotalAmount = Math.round((Number(pgAmount) + totalVat) * 1000) / 1000;

  return (
    <>
      <Row ai="flex-start">
        <Col w={[4, 4, 4]}>
          <S.FiledWrapper>
            <InputField
              placeholder="ex. 50"
              label="Time Credits"
              name="credits"
              value={element.credits}
              setValue={onChange}
              error={errors.credits}
              type="number"
            />
          </S.FiledWrapper>
        </Col>
        {showRemove && (
          <Col w={[4, 4, 4]} style={{ marginTop: 12 }}>
            <RemoveRejoin
              style={{
                alignSelf: "center",
                marginTop: 50,
              }}
              handleClick={() => {
                setPackages(packages.filter((e) => e.id !== element.id));
              }}
              type="Remove"
              showText
              text="Remove"
            />
          </Col>
        )}
      </Row>
      <Row mt="2">
        <Col
          w={[4, 4, 4]}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <T.Body14B color="gray3" style={{ margin: 0 }}>
            Subtotal
          </T.Body14B>
          <div style={{ width: 100 }}>
            <InputField value={`£${pgAmount.toFixed(2)}`} />
          </div>
        </Col>
        <Col w={[4, 4, 4]}>
          <S.TextWrapper>
            <T.Body16B color="gray3" style={{ margin: 0 }}>
              Total VAT 20%
            </T.Body16B>
            <T.Body16R>£{totalVat.toFixed(2)}</T.Body16R>
          </S.TextWrapper>
          <S.TextWrapper>
            <T.Body20B color="primary" style={{ margin: 0 }}>
              Total Amount
            </T.Body20B>
            <T.Body20 color="gray2">£{pgTotalAmount.toFixed(2)}</T.Body20>
          </S.TextWrapper>
        </Col>
      </Row>
    </>
  );
};

const PricePackages = ({
  packages = [],
  setPackages,
  tcPrice,
  vat,
  errors = {},
}) => {
  const handleClick = () => {
    const maxId = Math.max(...packages.map((e) => e.id));
    setPackages([
      ...packages,
      {
        id: maxId + 1,
        credits: "",
        vat: "",
        price: "",
        new: true,
      },
    ]);
  };

  return (
    <>
      {packages.map((e, i) => (
        <PricePackage
          key={e.id}
          element={e}
          index={e.id || 0}
          packages={packages}
          setPackages={setPackages}
          errors={errors[`packages[${i}]`]}
          tcPrice={tcPrice}
          vat={vat}
          showRemove={packages.length > 1}
        />
      ))}
      <Row ai="flex-end">
        <Col w={[4, 12, 12]}>
          <S.Add onClick={handleClick}>
            <T.LinkB14
              color="blue"
              mt={packages.length > 1 ? "30" : "20"}
              mb={packages.length > 1 ? "30" : "0"}
            >
              + Add another package
            </T.LinkB14>
          </S.Add>
        </Col>
      </Row>
    </>
  );
};

export default PricePackages;
