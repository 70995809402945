import styled from "@material-ui/core/styles/styled";

export const Container = styled("div")(() => ({
  width: "100%",
}));

export const Head = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

export const TableContainer = styled("div")(({ theme }) => ({
  marginTop: 30,
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.mobileXL]: {
    transform: "translateY(-130px)",
  },

  [theme.breakpoints.laptopL]: {
    transform: "translateY(0px)",
  },
}));

export const BtnContainer = styled("div")(({ theme }) => ({
  alignSelf: "flex-end",
  zIndex: 3,
  display: "flex",
  transform: "translateY(215px)",
  [theme.breakpoints.mobileXL]: {
    flexDirection: "column",
  },

  [theme.breakpoints.laptopL]: {
    flexDirection: "row",
  },
}));

export const ImageWrapper = styled("div")({
  minWidth: 250,
  height: 210,
  transform: "translate(30px, -30px)",
});

export const CapitalizedSpan = styled("span")({
  textTransform: "capitalize",
});

export const CsvExport = styled("a")(({ theme }) => ({
  textDecoration: "none",
  marginBottom: theme.spacing(2),
  display: "block",
}));

export const ModalContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
});
export const HeadContent = styled("div")({
  display: "flex",
  flexDirection: "column",
});
export const FilterContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
});

export const SearchWrapper = styled("div")({
  justifyItems: "center",
  width: "365px",
  marginTop: "-2px",
  marginBottom: "10px",
});
