/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import * as S from "./style";
import * as T from "../../../components/Typograpy";
import { earnTypesMapWelsh } from "../../../constants/data/earnActivityTypes";

import GoBack from "../../../components/GoBack";
import TimeCredit from "../../../components/TimeCredit";
import { Tag } from "../../../components/Tags";
import Loading from "../../../components/Loading";
import { Regular } from "../../../components/Button";

import t from "../../../utils/helpers/translator";

import kformatter from "../../../utils/helpers/kformatter";
import dateFormatter from "../../../utils/helpers/dateFormatter";
import calendarFormatter from "../../../utils/helpers/calendarFormatter";
import { getSingleTransaction } from "../../../redux/actions/singleTransaction";
import { GENERAL } from "../../../constants/navRoutes";
import calculatePriceDetails from "../../../utils/helpers/calculate-price-details";

const statusOptions = {
  PAID: "purchased",
  CANCELLED: "cancelled",
  PENDING: "paymentDue",
};

const EarnGroupIndividualTransaction = (props) => {
  const { id: orderId } = useParams();
  const {
    isLoading,
    credits,
    date,
    status,
    cancellationReason,
    language,
    getSingleTransactionAction,
    vat,
    price,
    braintreeTransactionId,
  } = props;

  const { totalPriceFormatted } = calculatePriceDetails({
    vat,
    price,
    credits,
  });

  useEffect(() => {
    getSingleTransactionAction(orderId, "paid", {
      redirectToRelatedPage: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  if (isLoading) return <Loading fullPage navbar />;

  const isPurchased = status === "PAID";
  return (
    <S.TransactionsWrapper>
      <GoBack showText />
      <S.Header>
        <T.H18Caps color="pink" style={{ margin: "30px 0 5px 0" }}>
          {t("activityType", language)}:
        </T.H18Caps>
        {isMobile ? (
          <T.H30Caps color="midnight" style={{ margin: "0" }}>
            {t("timeCreditsPurchase", language)}
          </T.H30Caps>
        ) : (
          <T.H40Caps color="midnight" style={{ margin: "0" }}>
            {t("timeCreditsPurchase", language)}
          </T.H40Caps>
        )}
      </S.Header>
      <S.Content>
        <S.Details>
          <T.H20 color="midnight" mb={10}>
            {t("transactionDate", language)}:
          </T.H20>
          <T.Body16R color="gray3">
            {language !== "english"
              ? dateFormatter(date)
              : calendarFormatter(date, language)}
          </T.Body16R>
        </S.Details>

        {!isPurchased && (
          <>
            <S.Details>
              <T.H20 color="midnight" mb={10}>
                {`${t("activityDateSingle", language)}:`}
              </T.H20>
              {!date ? (
                <T.Body16R>-</T.Body16R>
              ) : (
                <T.Body16R color="gray3">-</T.Body16R>
              )}
            </S.Details>
            <S.Details>
              <T.H20 color="midnight" mb={10}>
                {t("memberName", language)}:
              </T.H20>
              <T.Body16R color="gray3">-</T.Body16R>
            </S.Details>
            <S.Details>
              <T.H20 color="midnight" mb={10}>
                {t("givenOutBy", language)}:
              </T.H20>
              <T.Body16R color="gray3">-</T.Body16R>
            </S.Details>
          </>
        )}

        <S.Details>
          <T.H20 color="midnight" mb={10}>
            {t("activityName", language)}:
          </T.H20>
          <T.Body16R color="gray3">
            {t("timeCreditsPurchase", language)}
          </T.Body16R>
        </S.Details>

        <S.Details>
          <T.H20 color="midnight" mb={10}>
            {t("TCNumber", language)}:
          </T.H20>
          <TimeCredit
            credits={kformatter(Number(credits))}
            gradient="blue"
            color="blue"
            description
          />
        </S.Details>

        <S.Details>
          <T.H20 color="midnight" mb={10}>
            {t(status === "PAID" ? "amountPaid" : "amountDue", language)}:
          </T.H20>
          <T.Body16R color="gray3">{totalPriceFormatted}</T.Body16R>
        </S.Details>
        <S.Details>
          <T.H20 color="midnight" mb={10}>
            {t("type", language)}:
          </T.H20>

          <Tag
            tagName={statusOptions[status]}
            justify="flexStart"
            m="0"
            lang={language}
          />
        </S.Details>
        {status === "CANCELLED" ? (
          <S.Details>
            <T.H20 color="midnight" mb={10}>
              {t("cancellationReason", language)}:
            </T.H20>
            <T.Body16R color="gray3">{cancellationReason || "-"}</T.Body16R>
          </S.Details>
        ) : (
          <S.Details>
            <Regular
              outline
              textColor="blue"
              mr="0"
              light
              style={{ maxWidth: 200 }}
              to={GENERAL.ORDER_INVOICE.replace(":id", orderId)}
            >
              {t("downloadInvoice", language)}
            </Regular>
          </S.Details>
        )}
        {braintreeTransactionId && (
          <S.Details>
            <T.H20 color="midnight" mb={10}>
              {t("transactionId", language)}:
            </T.H20>
            <T.Body16R color="gray3">{braintreeTransactionId}</T.Body16R>
          </S.Details>
        )}
      </S.Content>
    </S.TransactionsWrapper>
  );
};

const mapStateToProps = ({ singleTransaction, auth }) => ({
  isLoading: singleTransaction.data.loading,
  activityDate: singleTransaction.data.activityDate,
  amountPaid: singleTransaction.data.amountPaid,
  braintreeTransactionId: singleTransaction.data.braintreeTransactionId,
  credits: singleTransaction.data.credits,
  date: singleTransaction.data.date,
  status: singleTransaction.data.status,
  cancellationReason: singleTransaction.data.cancellationReason,
  language: auth.language,
  vat: singleTransaction.data.vat,
  price: singleTransaction.data.price,
});

const mapActionToProps = {
  getSingleTransactionAction: getSingleTransaction,
};

export default connect(
  mapStateToProps,
  mapActionToProps,
)(EarnGroupIndividualTransaction);
