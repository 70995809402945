import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyle = makeStyles((theme) => ({
  Wrapper: {
    minHeight: "100vh",
    width: "100%",
    position: "relative",
    display: "flex",
    flex: "1",
    flexDirection: "column",
    paddingTop: "35px",
    [theme.breakpoints.tablet]: {
      flexDirection: "row",
      paddingTop: 0,
    },
  },
  LogoHeader: {
    display: "none",
    [theme.breakpoints.tablet]: {
      display: "block",
      alignSelf: "flex-start",
    },
  },
  ContentWrapper: {
    display: "flex",
    flexDirection: "column",
    flex: "1",
    width: "100%",
    [theme.breakpoints.tablet]: {
      paddingTop: ({ logo }) => (logo ? "100px" : "0"),
      alignItems: "center",
    },
  },
  NonColouredDiv: {
    paddingTop: "30px",
    paddingLeft: "25px",
    paddingRight: "25px",
    flex: "1",
    display: "flex",
    flexDirection: "column",
    paddingBottom: ({ footer }) => (footer ? "350px" : "15px"),
    [theme.breakpoints.mobileM]: {
      paddingBottom: ({ footer }) => (footer ? "350px" : "30px"),
    },
    [theme.breakpoints.tablet]: {
      width: "50%",
      minHeight: "100vh",
      position: "absolute",
      left: ({ side }) => side === "left" && "0",
      right: ({ side }) => side === "right" && "0",
      paddingTop: ({ logo }) => (logo ? "50px" : "80px"),
      paddingLeft: "50px",
      paddingRight: "50px",
      alignItems: "center",
    },
  },
  ColouredDiv: {
    display: "none",
    [theme.breakpoints.tablet]: {
      display: "block",
      minHeight: "100vh",
      width: "50%",
      background: ({ color }) => theme.gradients[color] || theme.gradients.blue,
      position: "fixed",
      left: ({ side }) => side === "right" && "0",
      right: ({ side }) => side === "left" && "0",
    },
  },
}));

export default useStyle;
