const keys = {
  NO: "No",
  LEARNING_DISABILITY: "Learning disability",
  LONG_TERM_HEALTH_CONDITION: "Long term health condition",
  MENTAL_HEALTH_CONDITION: "Mental health condition",
  PHYSICAL_HEALTH_CONDITION: "Physical health condition",
  SENSORY_IMPAIRMENT: "Sensory impairment",
  OTHER: "Other",
};

const keysWelsh = {
  NO: "Nac oes",
  LEARNING_DISABILITY: "Anabledd dysgu",
  LONG_TERM_HEALTH_CONDITION: "Cyflwr iechyd tymor hir",
  MENTAL_HEALTH_CONDITION: "Cyflwr iechyd meddwl",
  PHYSICAL_HEALTH_CONDITION: "Cyflwr iechyd corfforol",
  SENSORY_IMPAIRMENT: "Nam ar y synhwyrau",
  OTHER: "Arall",
};

const mentalHealthValuesMapWelsh = {};
Object.keys(keys).forEach((e) => {
  mentalHealthValuesMapWelsh[keys[e]] = keysWelsh[e];
});

const mentalHealthDropDownEnglish = Object.values(keys).map((e) => ({
  label: e,
  value: e,
}));

const mentalHealthDropDownWelsh = Object.entries(keysWelsh).map(
  ([key, value]) => ({
    label: value,
    value: keys[key],
  }),
);

export {
  keys,
  keysWelsh,
  mentalHealthDropDownEnglish,
  mentalHealthDropDownWelsh,
  mentalHealthValuesMapWelsh,
};
