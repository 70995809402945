import styled from "@material-ui/core/styles/styled";

export const PopContainer = styled("div")({
  position: "absolute",
  right: -35,
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 999,
});

export const PopBtn = styled("button")(({ m }) => ({
  background: "none",
  boxShadow: "none",
  border: "none",
  outline: "none",
  margin: m || "0",
}));
