import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

import * as S from "./style";
import Image from "../../../../components/Image";
import * as T from "../../../../components/Typograpy";
import Table from "../../../../components/FilterTable";
import Button from "../../../../components/Button";
import Notification from "../../../../components/Notification";
import { Row, Col } from "../../../../components/Grid";
import InviteLink from "../../../../components/Links/InviteLink";
import RemoveRejoin from "../../../../components/RemoveRejoin";
import Search from "../../../../components/Search";
import validate from "../../../../validation/schemas/addMembersToEarnGroup";

import {
  customDateCol,
  memberCol,
  lastActiveCol,
} from "../../../../components/Table/config/reusableColumns";

import { getMembers as getAllMembers } from "../Members/actions";
import { addMembersToEarnGroup } from "./actions";

const popPush = (
  rowData,
  earnGroupUserId,
  pushPopMembers,
  setPushPopMembers,
) => {
  const isJoined =
    rowData.earnGroups && rowData.earnGroups.includes(earnGroupUserId);
  const isPushed = pushPopMembers.find((member) => member.id === rowData.id);
  if (isJoined) {
    return <T.Body14I>Already in the group</T.Body14I>;
  }
  return isPushed ? (
    <RemoveRejoin
      type="Remove"
      loading={rowData.loading}
      handleClick={() =>
        setPushPopMembers((old) =>
          old.filter((member) => member.id !== rowData.id),
        )
      }
      showText
    />
  ) : (
    <RemoveRejoin
      type="Add"
      text="join"
      loading={rowData.loading}
      handleClick={() =>
        setPushPopMembers((old) => [
          ...old,
          { name: rowData.member, id: rowData.id, userId: rowData.userId },
        ])
      }
      showText
    />
  );
};

const tempoMembers = (earnGroupUserId, pushPopMembers, setPushPopMembers) => ({
  columns: [
    memberCol,
    customDateCol({ title: "Joined" }),
    lastActiveCol,
    {
      title: "",
      field: "removeRejoin",
      disableClick: true,
      render: (rowData) =>
        popPush(rowData, earnGroupUserId, pushPopMembers, setPushPopMembers),
    },
  ],
});

const StaffMembers = ({
  members,
  getMembersAction,
  membersLoading,
  addMembersToEarnGroupAction,
  addMembersLoading,
  addMembersError,
  addMembersSuccess,
}) => {
  const {
    state: { earnGroupUserId, earnGroupName, inviteToken },
  } = useLocation();
  const [pushPopMembers, setPushPopMember] = useState([]);
  const [errors, setErrors] = useState(null);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    getMembersAction({
      query: search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      const formData = {
        earnGroupUserId,
        membersToAdd: pushPopMembers,
      };

      validate(formData);
      setErrors(null);
      // submit
      addMembersToEarnGroupAction(formData);
    } catch (error) {
      setErrors("There was an error submitting your request");
    }
  };

  useEffect(() => {
    if (addMembersSuccess) {
      setIsNotificationOpen(true);
      setPushPopMember([]);
      getMembersAction({
        query: search,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addMembersSuccess]);

  const handleSearch = (e) => {
    e.preventDefault();

    getMembersAction({
      query: search,
    });
  };

  return (
    <S.Container>
      <Notification
        open={isNotificationOpen}
        setOpen={setIsNotificationOpen}
        message="Added members to group"
        duration={1500}
      />
      <div>
        <S.Head>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <T.H40Caps color="primary" style={{ margin: 0 }}>
              {earnGroupName}
            </T.H40Caps>
            <Row>
              <Col w={[4, 12, 8]}>
                <T.H30Caps color="pink" style={{ marginTop: 30 }}>
                  ADD VOLUNTEERS
                </T.H30Caps>
                <T.H24
                  color="midnight"
                  style={{ marginTop: 62, marginBottom: 40 }}
                >
                  Add volunteers via unique invite link
                </T.H24>
                <InviteLink
                  background="pinkLight"
                  color="pink"
                  style={{ maxWidth: "initial" }}
                  url={`${window.location.origin}/sign-up/?invite=${inviteToken}`}
                />
                <T.Body16R style={{ padding: "20px 0" }}>
                  Send this{" "}
                  <T.Body16B color="midnight" style={{ display: "inline" }}>
                    {" "}
                    unique invite link{" "}
                  </T.Body16B>
                  to new and existing volunteers by email, text or social media
                  for them to be automatically added to {earnGroupName}.
                </T.Body16R>
                <T.H24 color="midnight" style={{ marginTop: 62 }}>
                  Add existing volunteers manually
                </T.H24>
              </Col>
            </Row>
          </div>
          <S.ImageWrapper>
            <Image image="group" alt="group" height={210} width={220} />
          </S.ImageWrapper>
        </S.Head>
        <Row style={{ marginTop: 30 }}>
          <Col w={[4, 4, 5]}>
            <T.Body18B color="midnight" style={{ marginBottom: 20 }}>
              Volunteers to add
            </T.Body18B>
            <S.MembersContainer>
              {pushPopMembers.length ? (
                pushPopMembers.map((member) => (
                  <div key={member.id}>
                    <T.Body16R style={{ marginLeft: 0, marginTop: "0.5rem" }}>
                      {member.name}
                    </T.Body16R>
                    <RemoveRejoin
                      type="Remove"
                      handleClick={() =>
                        setPushPopMember((old) =>
                          old.filter((item) => item.id !== member.id),
                        )
                      }
                      showText
                    />
                  </div>
                ))
              ) : (
                <T.Body16I style={{ margin: 0, marginBottom: 10 }}>
                  None selected
                </T.Body16I>
              )}
            </S.MembersContainer>
          </Col>

          <Col w={[3, 3, 3]} style={{ marginLeft: 150 }}>
            <Button
              primary
              onClick={handleSubmit}
              loading={addMembersLoading}
              disabled={!pushPopMembers.length}
            >
              Confirm
            </Button>
          </Col>
        </Row>
        <Row>
          <Col w={[4, 4, 6]}>
            {addMembersError && (
              <S.Error>
                {addMembersError.message || "Something went wrong"}
              </S.Error>
            )}
            {errors && <S.Error>{errors}</S.Error>}
          </Col>
        </Row>
        <S.TableContainer>
          {membersLoading && (
            <Table
              data={members.data}
              columns={
                tempoMembers(earnGroupUserId, pushPopMembers, setPushPopMember)
                  .columns
              }
              isLoading
            />
          )}
          {!membersLoading && (
            <>
              <S.SearchWrapper>
                <Search
                  handleSearch={handleSearch}
                  search={search}
                  setSearch={setSearch}
                  loading={members.loading}
                />
              </S.SearchWrapper>
              <Table
                color="blue"
                data={members.data}
                columns={
                  tempoMembers(
                    earnGroupUserId,
                    pushPopMembers,
                    setPushPopMember,
                  ).columns
                }
                disableClick
                hideSearch
              />
            </>
          )}
        </S.TableContainer>
      </div>
    </S.Container>
  );
};

const mapStateToProps = (state) => ({
  // insights and getting members
  ...state.staffInsights,
  members: state.staffMembers,
  membersLoading: state.staffMembers.loading,
  insightsLoading: state.staffMembers.loading,
  // add members to group
  addMembersLoading: state.addMembersToEarnGroup.loading,
  addMembersError: state.addMembersToEarnGroup.error,
  addMembersSuccess: state.addMembersToEarnGroup.success,
});

const mapActionsToProps = {
  getMembersAction: getAllMembers,
  addMembersToEarnGroupAction: addMembersToEarnGroup,
};

export default connect(mapStateToProps, mapActionsToProps)(StaffMembers);
