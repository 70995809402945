import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";

import * as S from "./style";
import * as T from "../../../components/Typograpy";
import Loading from "../../../components/Loading";

import { EARN_GROUP } from "../../../constants/navRoutes";
import { earnTypesMapWelsh } from "../../../constants/data/earnActivityTypes";

import {
  getEarnGroupsMemberById,
  getEarnGroupMemberTransactions,
} from "./actions";

import GoBack from "../../../components/GoBack";
import BigLink from "../../../components/Links/BigLink";
import Stats from "../../../components/Stats";
import Icon from "../../../components/Icon";

import Table from "../../../components/Table";
import {
  dateCol,
  creditsCol,
  typeCol,
} from "../../../components/Table/config/reusableColumns";

import t from "../../../utils/helpers/translator";

const EarnGroupSingleMember = (props) => {
  const history = useHistory();
  const { id: memberId } = useParams();

  const {
    getEarnGroupsMemberByIdAction,
    getEarnGroupMemberTransactionsAction,
    member,
    transactions,
    isLoading,
    memberTransactionsLoaded,
    language,
  } = props;

  useEffect(() => {
    getEarnGroupsMemberByIdAction(memberId);
    getEarnGroupMemberTransactionsAction(memberId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    member: firstName,
    lastName,
    status,
    creditsEarned,
    creditsSpentRatio,
  } = member;
  const columns = [
    dateCol(t("date", language)),
    { title: t("activity", language), field: "activity" },
    creditsCol(t("numberOfTC", language)),
    typeCol(t("type", language), language),
  ];

  const translateActivity = (activityName, activityDetails) => {
    if (language === "welsh") {
      const res = earnTypesMapWelsh[activityName];
      const welshName = activityDetails?.activity?.welshName;
      return res || welshName || activityName;
    }
    return activityName;
  };

  const getCleanData = (transactionsData) => {
    const createTableRows = transactionsData.map((item) => {
      let activity =
        item && item.activity && item.activity.name ? item.activity.name : "--";

      if (item.type === "gift") {
        activity = t("gifted", language);
      } else if (["free", "bought"].includes(item.type)) {
        activity = t("TCdelivery", language);
      }
      activity = translateActivity(activity, item);

      return {
        date: item.createdAt,
        activity,
        credits: item.tc,
        type: item.type === "earn" ? "givenOut" : "received",
        id: item.id,
      };
    });
    return createTableRows;
  };

  const clickRow = (event, rowData) =>
    history.push(EARN_GROUP.HISTORY_SINGLE.replace(":id", rowData.id));

  if (isLoading) return <Loading fullPage navbar />;
  return (
    <S.MemberWrapper>
      <GoBack showText p="0 0 0 20px" />

      <S.MemberHeader>
        {member && (
          <S.MemberDetails>
            <S.MemberName color="midnight">
              {firstName} {lastName}
            </S.MemberName>
            <S.MemberStatus>
              <Icon
                icon={status === "activated" ? "tickRound" : "no"}
                width="20px"
                height="20px"
                margin="0 10px 0 0"
                color={status === "activated" ? "white" : "pink"}
              />
              <T.H18Caps color={status === "activated" ? "cyan" : "pink"} m="0">
                {status !== "activated" ? `${t("not", language)} ` : ""}
                {t("VolunteeringWithMe", language)}
              </T.H18Caps>
            </S.MemberStatus>
            <S.Div>
              <S.StatsWrapper>
                <Stats
                  size="m"
                  statNum={creditsEarned || 0}
                  statText={t("TempoTCEarned", language)}
                  statNumColor="blue"
                />
                <Stats
                  size="m"
                  statNum={creditsSpentRatio || 0}
                  statText={t("TempoTCSpent", language)}
                  statNumColor="blue"
                />
              </S.StatsWrapper>
              <S.BigLinkWrapper status={status}>
                <BigLink
                  path={EARN_GROUP.GIVE}
                  color="pink"
                  text={t("giveOutTC", language)}
                />
              </S.BigLinkWrapper>
            </S.Div>
          </S.MemberDetails>
        )}
      </S.MemberHeader>

      <S.TableWrapper>
        <T.H24Caps color="midnight" mb={20}>
          {t("transactionsWith", language)} {firstName}
        </T.H24Caps>

        {transactions && transactions.length > 0 && memberTransactionsLoaded ? (
          <Table
            color="pink"
            data={getCleanData(transactions)}
            columns={columns}
            onRowClick={clickRow}
            hideSearch
          />
        ) : (
          <div>{t("noRecords", language)}</div>
        )}
      </S.TableWrapper>
    </S.MemberWrapper>
  );
};

const mapStateToProps = (state) => ({
  member: state.earnGroupMembers.memberDetails,
  transactions: state.earnGroupMembers.memberTransactions,
  memberTransactionsLoaded: state.earnGroupMembers.memberTransactionsLoaded,
  isLoading: state.earnGroupMembers.loading,
  language: state.auth.language,
});

const mapActionToProps = {
  getEarnGroupsMemberByIdAction: getEarnGroupsMemberById,
  getEarnGroupMemberTransactionsAction: getEarnGroupMemberTransactions,
};

export default connect(
  mapStateToProps,
  mapActionToProps,
)(EarnGroupSingleMember);
