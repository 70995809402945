import makeStyles from "@material-ui/core/styles/makeStyles";

import { styled } from "@material-ui/core/styles";
import * as T from "../../../components/Typograpy";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  content: {},
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    width: "100%",
    marginBottom: theme.spacing(5),
    [theme.breakpoints.tablet]: {
      flexDirection: "row",
    },
    [theme.breakpoints.mobileXL]: {
      marginBottom: theme.spacing(9),
    },
  },
  section: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
    width: 300,
    [theme.breakpoints.mobileXL]: {
      flexDirection: "row",
      marginTop: theme.spacing(9),
      width: "100%",
    },
  },
  sectionNumber: {
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "30px",
    lineHeight: "30px",
    color: theme.colors.blue,
    margin: 0,
    textAlign: "center",
    marginBottom: theme.spacing(3),

    [theme.breakpoints.mobileXL]: {
      marginBottom: theme.spacing(6),
    },
  },
  sectionHeading: {
    marginBottom: theme.spacing(1),
    color: theme.colors.midnight,
    [theme.breakpoints.mobileXL]: {
      marginBottom: theme.spacing(3),
      fontWeight: "bold",
      fontSize: "30px",
      lineHeight: "30px",
    },
  },
  sectionBody: {
    maxWidth: "685px",
    marginLeft: 0,
    flexGrow: 1,
    [theme.breakpoints.mobileXL]: {
      marginLeft: theme.spacing(5),
    },
  },
  membershipWrapper: {
    margin: "0",
    [theme.breakpoints.laptop]: {
      flexWrap: "nowrap !important",
    },
  },
  activitiesWrapper: {
    justifyContent: "flex-start",
    marginLeft: "-10px",

    [theme.breakpoints.mobileXL]: {
      justifyContent: "flex-start",
    },
  },
  counters: {
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.laptop]: {
      flexDirection: "row",
    },
  },
  activity: {
    margin: theme.spacing(1),
  },
  flex: {
    display: "flex",
    flexWrap: "wrap",
  },
  submitButton: {
    marginTop: theme.spacing(9),
  },

  label: {
    color: theme.colors.gray2,
    fontSize: "20px",
    lineHeight: "20px",
    [theme.breakpoints.mobileXL]: {
      fontWeight: "bold",
      fontSize: "30px",
      lineHeight: "30px",
    },
  },
  venueName: {
    marginBottom: theme.spacing(0),
    [theme.breakpoints.mobileXL]: {
      marginBottom: theme.spacing(2),
      fontSize: "22px",
      lineHeight: "25px",
    },
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.mobileXL]: {
      fontSize: "40px",
      lineHeight: "40px",
    },
  },
  costWrapper: {
    marginTop: theme.spacing(8),
  },
  disabled: {
    opacity: "0.5",
  },
}));

export const SuccessWrapper = styled("section")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: theme.spacing(3),
  maxWidth: "420px",
  margin: "0 auto",
  paddingBottom: theme.spacing(7),

  [theme.breakpoints.tablet]: {
    paddingTop: 0,
  },
}));

export const Header = styled("header")(() => ({
  width: "100%",
}));

export const H40Caps = styled(T.H40Caps)(({ theme }) => ({
  color: theme.colors.midnight,
}));

export const Body18R = styled(T.Body16R)(({ theme }) => ({
  color: theme.colors.gray2,
  marginTop: "2rem",
  fontSize: "16px",
  display: "inline-block",
}));

export const Black = styled(Body18R)(({ theme }) => ({
  color: theme.colors.black,
  display: "inline",
  width: "100%",
}));

export const BlackBold = styled(Black)(() => ({
  fontWeight: "bold",
  marginTop: "0",
}));

export const GrayBold = styled(Body18R)(({ theme }) => ({
  color: theme.colors.gray3,
  fontWeight: "bold",
  marginRight: "auto",
}));
export const Gray = styled(Body18R)(({ theme }) => ({
  color: theme.colors.gray3,
  fontWeight: "normal",
  display: "inline",
}));

export const Underline = styled(Black)(() => ({
  textDecoration: "underline",
  cursor: "pointer",
}));

export const Body18S = styled(T.Body18S)(({ theme }) => ({
  color: theme.colors.gray2,
  marginTop: "2rem",
  fontWeight: "600",
  fontSize: "16px",
}));

export const StyledHeader = styled("header")(() => ({
  width: "100%",
}));

export const membershipInputWrapper = styled("div")(
  ({ theme, extraMargin }) => ({
    marginRight: extraMargin && theme.spacing(3),
    marginBottom: extraMargin && theme.spacing(3),
  }),
);

export const ButtonContainer = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 300,
  marginLeft: 0,
  marginTop: 20,
  [theme.breakpoints.tablet]: {
    width: "40%",
    maxWidth: "auto",
    marginLeft: 75,
    marginTop: 40,
  },
}));
export const CustomInputsWrapper = styled("div")(({ theme, marginRight }) => ({
  marginBottom: theme.spacing(3),
  width: "100%",

  [theme.breakpoints.tablet]: {
    marginRight: marginRight && theme.spacing(3),
    width: "45%",
  },
  [theme.breakpoints.laptop]: {
    marginLeft: "30px",
    marginRight: 0,
  },
}));

export const SomethingElse = styled(T.H20)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: theme.colors.midnight,

  [theme.breakpoints.mobileS]: {
    fontSize: "18px",
    fontWeight: "700",
  },
  [theme.breakpoints.laptop]: {
    fontSize: "20px",
    fontWeight: "900",
    marginLeft: "30px",
  },
}));

export default useStyles;
