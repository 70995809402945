import { styled } from "@material-ui/core/styles";

export const Wrapper = styled("section")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  maxWidth: "460px",
  paddingTop: "5rem",
  margin: "0 auto",
}));

export const Header = styled("header")(() => ({
  width: "100%",
}));

export const Body = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  height: "50%",
  alignItems: "center",
  justifyContent: "center",

  paddingLeft: 0,

  [theme.breakpoints.tablet]: {
    paddingLeft: theme.spacing(2),
  },
}));
