const keys = {
  DOESNT_REPEAT: "Doesn't repeat",
  REPEATS_WEEKLY: "Repeats weekly",
  REPEATS_MONTHLY: "Repeats monthly",
};

const keysWelsh = {
  DOESNT_REPEAT: "cy - Doesn't repeat",
  REPEATS_WEEKLY: "cy - Repeats weekly",
  REPEATS_MONTHLY: "cy - Repeats monthly",
};

const repeatValuesMapWelsh = {};
Object.keys(keys).forEach((e) => {
  repeatValuesMapWelsh[keys[e]] = keysWelsh[e];
});

const repeatDropDownEnglish = [
  {
    label: keys.DOESNT_REPEAT,
    value: "none",
  },
  {
    label: keys.REPEATS_WEEKLY,
    value: "weekly",
  },
  {
    label: keys.REPEATS_MONTHLY,
    value: "monthly",
  },
];

const repeatDropDownWelsh = [
  {
    label: keysWelsh.DOESNT_REPEAT,
    value: "none",
  },
  {
    label: keysWelsh.REPEATS_WEEKLY,
    value: "weekly",
  },
  {
    label: keysWelsh.REPEATS_MONTHLY,
    value: "monthly",
  },
];

export {
  keys,
  keysWelsh,
  repeatDropDownEnglish,
  repeatDropDownWelsh,
  repeatValuesMapWelsh,
};
