import React from "react";
import * as T from "../Typograpy";
import * as S from "./style";
import Loading from "../Loading";
import QuestionPop from "../QuestionPop";

import numberWithCommas from "../../utils/helpers/numberWithCommas";

const Stats = ({
  size,
  statNum,
  statNumColor,
  statText,
  statTextColor,
  subText,
  subTextColor,
  margin,
  height,
  isLoading,
  bottomMessage,
  rightMessage,
  width,
}) => (
  <>
    {size === "xl" && (
      <S.Wrapper width={width || "210px"} margin={margin} height={height}>
        <T.H50 color={statNumColor || "midnight"}>
          <S.NumberWrapper>
            {isLoading ? <Loading /> : numberWithCommas(statNum)}
            <S.RightPopWrapper>
              {rightMessage && <QuestionPop message={rightMessage} size="l" />}
            </S.RightPopWrapper>
          </S.NumberWrapper>
        </T.H50>
        <T.H20Caps color={statTextColor || "midnight"}>{statText}</T.H20Caps>
        {subText && (
          <T.Body14I color={subTextColor || "midnight"}>{subText}</T.Body14I>
        )}
        {bottomMessage && <QuestionPop message={bottomMessage} />}
      </S.Wrapper>
    )}
    {size === "l" && (
      <S.Wrapper width={width || "210px"} margin={margin} height={height}>
        <T.H50 color={statNumColor || "midnight"}>
          <S.NumberWrapper>
            {isLoading ? <Loading /> : numberWithCommas(statNum)}
            <S.RightPopWrapper>
              {rightMessage && <QuestionPop message={rightMessage} size="l" />}
            </S.RightPopWrapper>
          </S.NumberWrapper>
        </T.H50>

        <T.H18Caps color={statTextColor || "midnight"}>{statText}</T.H18Caps>
        {subText && (
          <T.Body14I color={statTextColor || "midnight"}>{subText}</T.Body14I>
        )}

        {bottomMessage && <QuestionPop message={bottomMessage} size="s" />}
      </S.Wrapper>
    )}
    {size === "m" && (
      <S.Wrapper width={width || "200px"} height={height}>
        <T.H40
          color={statNumColor || "midnight"}
          style={{ marginBottom: "5px" }}
        >
          <S.NumberWrapper>
            {isLoading ? <Loading /> : numberWithCommas(statNum)}
            <S.RightPopWrapper>
              {rightMessage && <QuestionPop message={rightMessage} size="l" />}
            </S.RightPopWrapper>
          </S.NumberWrapper>
        </T.H40>
        <T.H20Caps color={statTextColor || "midnight"}>{statText}</T.H20Caps>
        {subText && (
          <T.Body14I color={subTextColor || "midnight"}>{subText}</T.Body14I>
        )}
        {bottomMessage && <QuestionPop message={bottomMessage} size="s" />}
      </S.Wrapper>
    )}
    {size === "s" && (
      <S.Wrapper width={width || "170px"} height={height}>
        <T.H30
          color={statNumColor || "midnight"}
          style={{ marginBottom: "5px" }}
        >
          <S.NumberWrapper>
            {isLoading ? <Loading /> : numberWithCommas(statNum)}
            <S.RightPopWrapper>
              {rightMessage && <QuestionPop message={rightMessage} size="l" />}
            </S.RightPopWrapper>
          </S.NumberWrapper>
        </T.H30>
        <T.H18Caps
          color={statTextColor || "midnight"}
          style={{ fontWeight: 900 }}
        >
          {statText}
        </T.H18Caps>
        {subText && (
          <T.Body14I color={subTextColor || "midnight"}>{subText}</T.Body14I>
        )}
        {bottomMessage && <QuestionPop message={bottomMessage} size="s" />}
      </S.Wrapper>
    )}
  </>
);
export default Stats;
