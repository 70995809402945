import styled from "@material-ui/core/styles/styled";

export const Container = styled("div")(() => ({
  width: "100%",
}));

export const Head = styled("header")(({ theme }) => ({
  marginBottom: theme.spacing(5),
}));

export const StatsWrapper = styled("div")(() => ({
  display: "flex",
}));

export const TableContainer = styled("div")({
  marginTop: 30,
  display: "flex",
  flexDirection: "column",
  position: "relative",
});

export const BtnContainer = styled("div")({
  alignSelf: "flex-end",
  transform: "translateY(59px)",
  zIndex: 3,

  display: "flex",
});

export const ImageWrapper = styled("div")({
  minWidth: 250,
  height: 210,
  transform: "translate(30px, -30px)",
});

export const CapitalizedSpan = styled("span")({
  textTransform: "capitalize",
});

export const Section = styled("div")(({ theme }) => ({
  background: theme.colors.white,
  boxShadow: theme.customShadows.dp3,
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(6),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  minHeight: 280,
}));

export const StatSection = styled("section")(({ theme, color }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  minHeight: "160px",
  minWidth: "700px",
  width: "100vw",
  overflow: "hidden",
  backgroundColor: theme.colors[color] || color,
  zIndex: "0",
  border: "1px solid #F9ECF2",
  marginBottom: "2rem",
  position: "relative",
  paddingLeft: theme.spacing(7),
  paddingRight: theme.spacing(7),

  [theme.breakpoints.tablet]: {
    width: "calc(100vw - 320px)",
  },
}));

export const CsvExport = styled("a")({
  textDecoration: "none",
});
