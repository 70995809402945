import React from "react";
import { connect } from "react-redux";
import { toggleLanguage as toggleLanguageAction } from "../../redux/actions/auth";
import * as S from "./style";
import * as T from "../Typograpy";
import Divider from "../Divider";
import removeCookie from "../../utils/helpers/removeCookie";

const Toggle = ({
  toggleOne = { text: "Cymraeg", value: "welsh" },
  toggleTwo = { text: "English", value: "english" },
  language,
  toggleLanguage,
}) => {
  const onLanguageChange = (_language) => {
    toggleLanguage(_language);
    removeCookie("googtrans");
  };

  return (
    <S.Wrapper>
      <S.LangBtn onClick={() => onLanguageChange(toggleOne.value)}>
        <T.Body16R
          style={{ fontWeight: language === toggleOne.value && "700" }}
        >
          {toggleOne.text}
        </T.Body16R>
      </S.LangBtn>
      <Divider color="pink" vertical h="16px" w="2px" m="0 15px" />
      <S.LangBtn onClick={() => onLanguageChange(toggleTwo.value)}>
        <T.Body16R
          style={{ fontWeight: language === toggleTwo.value && "700" }}
        >
          {toggleTwo.text}
        </T.Body16R>
      </S.LangBtn>
    </S.Wrapper>
  );
};

const mapActionToProps = {
  toggleLanguage: toggleLanguageAction,
};

const mapStateToProps = (state) => ({
  language: state.auth.decideLanguage(state.auth),
});

export default connect(mapStateToProps, mapActionToProps)(Toggle);
