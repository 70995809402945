import { styled } from "@material-ui/core/styles";

export const Wrapper = styled("div")(({ width }) => ({
  width: width || "auto",
}));

export const Tab = styled("button")(({ selected, theme }) => ({
  border: "none",
  borderBottom: selected
    ? `5px solid ${theme.colors.cyan}`
    : `5px solid transparent`,
  outline: "none",
  background: "none",
  transition: "all ease-in 0.1s",
  cursor: "pointer",
  padding: "5px 10px",
  width: "50%",
}));

export const SmallTabWrapper = styled("div")(({ width, theme }) => ({
  width: width || "auto",
  maxWidth: "300px",

  [theme.breakpoints.mobileL]: {
    maxWidth: "100%",
  },
}));

export const TabSmall = styled("button")(
  ({ selected, theme, left, tabLineWidth, tabSpace, disabled }) => ({
    border: "none",
    borderBottom: selected
      ? `5px solid ${theme.colors.pink}`
      : `5px solid transparent`,
    outline: "none",
    background: "none",
    transition: "all ease-in 0.1s",
    padding: "5px 0px",
    marginRight: left ? tabSpace || "5%" : "0",
    textAlign: "left",
    width: tabLineWidth || "45%",

    opacity: disabled ? 0.5 : 1,
    cursor: disabled ? "not-allowed" : "pointer",
    [theme.breakpoints.mobileL]: {
      width: "auto",
    },
  }),
);
