import axios from "axios";
import handleError from "./handle-errors";

const GROUPS_BASE = "/groups";

const updateGroupMemberById = async ({
  groupId,
  memberId,
  status,
  options,
}) => {
  try {
    const { data } = await axios.patch(
      `${GROUPS_BASE}/${groupId}/members/${memberId}`,
      {
        status,
      },
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const deleteGroupMemberById = async ({
  groupId,
  memberId,
  options,
}) => {
  try {
    const { data } = await axios.delete(
      `${GROUPS_BASE}/${groupId}/members/${memberId}`,
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export { updateGroupMemberById, deleteGroupMemberById };
