import React from "react";

import { useHistory } from "react-router-dom";

import Table from "../../../../components/Table";
import {
  dateCol,
  creditsCol,
  typeCol,
} from "../../../../components/Table/config/reusableColumns";

import * as T from "../../../../components/Typograpy";
import { TEMPO } from "../../../../constants/navRoutes";

import { decideTypeTempo } from "../../../../utils/helpers/decideTransactionType";

const Transactions = ({ transactions, loading, title, titleColor }) => {
  const history = useHistory();

  const createData = (arr) => {
    if (arr && arr.length > 0) {
      return arr.map((t) => ({
        id: t.id,
        date: t.createdAt,
        activity: t.activity.name || "N/A",
        credits: t.tc,
        type: decideTypeTempo(t.type),
      }));
    }
    return [];
  };

  const transactionColumns = [
    dateCol(),
    { title: "Activity", field: "activity" },
    creditsCol(),
    typeCol(),
  ];

  const clickRow = (event, rowData) =>
    history.push(TEMPO.TRANSACTIONS_SINGLE.replace(":id", rowData.id));

  return (
    <>
      <T.H30 mb={20} mt={50} color={titleColor || "midnight"}>
        {title || "TRANSACTIONS"}
      </T.H30>
      {loading && (
        <Table
          color="pink"
          data={createData(transactions)}
          columns={transactionColumns}
          hideSearch
          isLoading
          limit={5}
        />
      )}
      {!loading && (
        <Table
          color="pink"
          data={createData(transactions)}
          columns={transactionColumns}
          hideSearch
          onRowClick={clickRow}
          limit={5}
        />
      )}
    </>
  );
};

export default Transactions;
