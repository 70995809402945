import {
  GET_PARTNER_SPEND_VENUES_LOADING,
  GET_PARTNER_SPEND_VENUES_SUCCESS,
  GET_PARTNER_SPEND_VENUES_FAIL,
} from "../../redux/types";

const initState = {
  spendVenues: [],
  getLoading: false,
  error: null,
};

const spendPartner = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PARTNER_SPEND_VENUES_LOADING:
      return {
        ...state,
        getLoading: true,
      };
    case GET_PARTNER_SPEND_VENUES_SUCCESS:
      return {
        ...state,
        spendVenues: payload,
        getLoading: false,
      };
    case GET_PARTNER_SPEND_VENUES_FAIL:
      return {
        ...state,
        getLoading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default spendPartner;
