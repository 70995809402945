import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";
import Image from "../../../components/Image";
import * as S from "./style";
import { Regular } from "../../../components/Button";
import * as links from "../../../constants/navRoutes";
import t from "../../../utils/helpers/translator";

const AcceptTcSuccess = ({ language }) => {
  const history = useHistory();
  const onClick = () => {
    history.push(links.SPEND_VENUE.ACCEPT_TC);
  };

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.event({
        category: "Activity",
        action: "Accepted TCs at Venue",
      });
    }
  }, []);

  return (
    <S.SuccessWrapper>
      <Image
        image="successLarge"
        alt="success"
        width="250px"
        height="auto"
        customStyle={{ maxHeight: "280px", margin: "0 auto 10px auto" }}
      />
      <S.StyledHeader>
        <S.H40Caps>{t("accepted", language).toUpperCase()}!</S.H40Caps>
      </S.StyledHeader>
      <S.Body18R mt="20">{t("transactionApproved", language)}.</S.Body18R>
      <S.Body18S mt="20">{t("dontForgetInternalBooking", language)}</S.Body18S>
      <Regular
        bgColor="blue"
        primary
        mt="50"
        onClick={onClick}
        style={{ textTransform: "capitalize" }}
      >
        {t("done", language)}
      </Regular>
    </S.SuccessWrapper>
  );
};

const mapStateToProps = (state) => ({
  language: state.auth.language,
});

export default connect(mapStateToProps)(AcceptTcSuccess);
