import React, { useState } from "react";
import { NavLink as Link } from "react-router-dom";
import { connect } from "react-redux";
import * as T from "../Typograpy";
import Icon from "../Icon";
import * as S from "./styled";
import NavLink from "./NavLink";
import t from "../../utils/helpers/translator";
import { SPEND_PARTNER, SPEND_VENUE } from "../../constants/navRoutes";
import { roles } from "../../constants";

const links = [
  { to: SPEND_VENUE.ACCEPT_TC, title: "acceptTC" },
  { to: SPEND_VENUE.INSIGHTS, title: "venueInsights" },
  { to: SPEND_VENUE.OFFERS, title: "offers" },
];
const links1 = [
  { to: SPEND_PARTNER.SPEND_VENUES, title: "venues" },
  { to: SPEND_PARTNER.SETTINGS, title: "accountSettings" },
];
const SpendPartnerNav = ({
  lang,
  mobile,
  onClick,
  spendPartnerName,
  venueName,
  venueNameWelsh,
  logOut,
  spendVenuesCount,
}) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <>
      {Number(spendVenuesCount) > 1 ? (
        <Link
          to={SPEND_PARTNER.SELECT_VENUE}
          style={{ display: "flex", textDecoration: "none" }}
          isActive={(match) => {
            if (match) {
              setIsActive(true);
            } else {
              setIsActive(false);
            }
          }}
          onClick={() => (mobile ? onClick(false) : null)}
        >
          <T.H16Caps
            color="white"
            style={{ width: 210, margin: "40px 0 0 0", paddingLeft: 40 }}
          >
            {lang === "welsh" && venueNameWelsh ? venueNameWelsh : venueName}
          </T.H16Caps>
          <Icon
            icon="changeArrows"
            color={isActive ? "cyan" : "white"}
            width="32"
            height="32"
            style={{ alignSelf: "flex-end" }}
          />
        </Link>
      ) : (
        <T.H16Caps
          color="white"
          style={{ width: 210, margin: "40px 0 0 0", paddingLeft: 40 }}
        >
          {lang === "welsh" && venueNameWelsh ? venueNameWelsh : venueName}
        </T.H16Caps>
      )}
      <S.SiblingList>
        {links.map((link) => (
          <li key={link.to}>
            <NavLink to={link.to} mobile={mobile} onClick={onClick}>
              {t(link.title, lang)}
            </NavLink>
          </li>
        ))}
      </S.SiblingList>
      <S.Divider />
      <T.H16Caps
        color="white"
        style={{ margin: "40px 0 0 0", paddingLeft: 40 }}
      >
        {spendPartnerName}
      </T.H16Caps>
      <S.SiblingList>
        {links1.map((link) => (
          <li key={link.to}>
            <NavLink to={link.to} mobile={mobile} onClick={onClick}>
              {t(link.title, lang)}
            </NavLink>
          </li>
        ))}
        <S.LogOutButton onClick={logOut} type="button">
          <T.Body16M color="white" m={0}>
            {t("logOut", lang)}
          </T.Body16M>
        </S.LogOutButton>
      </S.SiblingList>
    </>
  );
};

const mapStateToProps = (state) => ({
  lang: state.auth.language,
  spendPartnerName: state.auth.spendPartner.name,
  spendVenuesCount: state.auth.spendVenuesCount,
  venueName:
    state.auth.role === roles.SPEND_VENUE
      ? state.auth.profile.name
      : state.auth.selectedSpendVenue.profile.name,
  venueNameWelsh:
    state.auth.role === roles.SPEND_VENUE
      ? state.auth.profile.welshName
      : state.auth.selectedSpendVenue.profile.welshName,
});
export default connect(mapStateToProps)(SpendPartnerNav);
