import styled from "@material-ui/core/styles/styled";
import Typograpy from "@material-ui/core/Typography";

export const Wrapper = styled("main")(() => ({
  width: "100%",
  position: "relative",
}));

export const Header = styled("header")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  marginBottom: theme.spacing(6),
}));

export const ImageWrapper = styled("div")({
  minWidth: 220,
  height: 210,
  position: "absolute",
  top: 0,
  right: 0,
  zIndex: -1,
});

export const Form = styled("form")(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const AddDate = styled("button")(() => ({
  outline: "none",
  border: "none",
  background: "none",
  cursor: "pointer",
}));

export const Error = styled(Typograpy)(({ theme }) => ({
  width: "100%",
  color: theme.colors.pink,
  fontSize: "0.875rem",
  textAlign: "left",
  marginBottom: theme.spacing(1),
}));
