import alphabetise from "../../utils/helpers/alphabetise";

const keys = {
  CULTURAL_EXPERIENCES: "Cultural Experiences",
  DAYS_OUT: "Days Out",
  EDUCATION_TRAINING: "Education & Training",
  FITNESS_LEISURE: "Fitness & Leisure",
  GREAT_FOR_KIDS: "Great for Kids",
  HEALTH_BEAUTY: "Health & Beauty",
  SHOWS_PERFORMANCES: "Shows & Performances",
  LIVE_SPORT: "Live Sport",
  PLAY_ENTERTAINMENT: "Play & Entertainment",
  ROOM_EQUIPMENT_HIRE: "Room & Equipment Hire",
  TRANSPORT_ACCOMMODATION: "Transport & Accommodation",
  // added as part of feb 21 sprint
  GROCERY_SHOPPING: "Grocery Shopping",
  RESTAURANTS_CAFES: "Restaurants & Cafes",
  HOLIDAYS: "Holidays",
  UTILITIES: "Utilities",
};

const keysWelsh = {
  CULTURAL_EXPERIENCES: "Profiadau Diwylliannol",
  DAYS_OUT: "Diwrnodau Allan",
  EDUCATION_TRAINING: "Addysg a Hyfforddiant",
  FITNESS_LEISURE: "Ffitrwydd a Hamdden",
  GREAT_FOR_KIDS: "Gwych i Blant",
  HEALTH_BEAUTY: "Iechyd a Harddwch",
  SHOWS_PERFORMANCES: "Sioeau a Pherfformiadau",
  LIVE_SPORT: "Chwaraeon Byw",
  PLAY_ENTERTAINMENT: "Chwarae ac Adloniant",
  ROOM_EQUIPMENT_HIRE: "Llogi Ystafell ac Offer",
  TRANSPORT_ACCOMMODATION: "Trafnidiaeth a Llety",
  // added as part of feb 21 sprint
  GROCERY_SHOPPING: "Welsh-Grocery Shopping",
  RESTAURANTS_CAFES: "Welsh-Restaurants & Cafes",
  HOLIDAYS: "Welsh-Holidays",
  UTILITIES: "Welsh-Utilities",
};

const spendActivitiesMapWelsh = {};
Object.keys(keys).forEach((e) => {
  spendActivitiesMapWelsh[keys[e]] = keysWelsh[e];
});

const spendActivitiesDropDownEnglish = Object.values(keys).map((e) => ({
  label: e,
  value: e,
}));

const spendActivitiesDropDownEnglishSorted = alphabetise(
  spendActivitiesDropDownEnglish,
);

const spendActivitiesDropDownWelsh = Object.entries(keysWelsh).map(
  ([key, value]) => ({
    label: value,
    value: keys[key],
  }),
);

export {
  keys,
  keysWelsh,
  spendActivitiesDropDownEnglish,
  spendActivitiesDropDownWelsh,
  spendActivitiesDropDownEnglishSorted,
  spendActivitiesMapWelsh,
};
