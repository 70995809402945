import React from "react";
import { Redirect } from "react-router-dom";

import AllMembers from "./AllMembers";
import IndividualMember from "./IndividualMember";

const EarnGroupMembers = (props) => {
  const { type } = props;
  switch (type) {
    case "all":
      return <AllMembers {...props} />;
    case "single":
      return <IndividualMember {...props} />;

    default:
      return <Redirect to="#" />;
  }
};

export default EarnGroupMembers;
