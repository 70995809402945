import { styled } from "@material-ui/core/styles";

export const Row = styled("div")(
  ({ inner, jcM, jc, jcT, theme, mt, mb, ai, mbM, mbT }) => ({
    display: "flex",
    flexWrap: "wrap",
    marginLeft: `-${inner ? theme.vars.gridGutter / 2 : 0}px !important;`,
    marginRight: `-${inner ? theme.vars.gridGutter / 2 : 0}px !important;`,
    width: inner ? `calc(100% + ${theme.vars.gridGutter}px)` : "100%",
    justifyContent: jc || "flex-start",
    marginTop: theme.spacing(mt) || mt || "0",
    marginBottom: theme.spacing(mb) || mb || "0",
    alignItems: ai || "flex-start",
    // tablet
    [theme.gridBreakpoints.tablet]: {
      justifyContent: jcT || jc || "flex-start",
      marginBottom: theme.spacing(mbT) || mbT,
    },

    [theme.gridBreakpoints.mobile]: {
      justifyContent: jcM || jcT || jc || "flex-start",
      marginBottom: theme.spacing(mbM) || mbM,
    },
  }),
);

export const Col = styled("div")(
  ({ c3, c2, c1, theme, mb, mbM, mbT, mtM, mtT, mt, jc, display }) => ({
    boxSizing: "border-box",
    paddingLeft: 10,
    paddingRight: 10,
    flexShrink: 0,
    position: "relative",
    width: "100%",
    minHeight: 1,
    height: "100%",
    flexBasis: `calc(${(c3 / theme.vars.columns.desktop) * 100}%)`,
    maxWidth: `calc(${(c3 / theme.vars.columns.desktop) * 100}%)`,
    display: display || (c3 ? "block" : "none"),
    marginBottom: theme.spacing(mb) || mb || "0",
    marginTop: theme.spacing(mt) || mt || "0",
    justifyContent: jc || "flex-start",

    [theme.gridBreakpoints.tablet]: {
      display: c2 ? "block" : "none",
      flexBasis: `calc(${(c2 / theme.vars.columns.tablet) * 100}%)`,
      maxWidth: `calc(${(c2 / theme.vars.columns.tablet) * 100}%)`,
      marginBottom: theme.spacing(mbT) || mbT,
      marginTop: theme.spacing(mtT) || mtT,
    },

    [theme.gridBreakpoints.mobile]: {
      display: c1 ? "block" : "none",
      flexBasis: `calc(${(c1 / theme.vars.columns.mobile) * 100}%)`,
      maxWidth: `calc(${(c1 / theme.vars.columns.mobile) * 100}%)`,
      marginBottom: theme.spacing(mbM) || mbM,
      marginTop: theme.spacing(mtM) || mtM,
    },
  }),
);
