import React, { useState } from "react";

import { Piker, Label } from "./style";

export default function InlineTimePicker({ label, setValue, name, value }) {
  const [selectedDate] = useState(); // this should be from the parent component
  const [focus, setFocus] = useState(false);
  return (
    <Piker
      focus={focus}
      id="time"
      name={name}
      value={value}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(true)}
      label={
        <Label selectedDate={selectedDate} focus={focus}>
          {value || label}
        </Label>
      }
      type="time"
      onInput={setValue}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        step: 300, // 5 min
      }}
    />
  );
}
