import React from "react";

const MinusCircle = ({ width, height, color, ...props }) => (
  <svg
    width={width}
    height={height}
    {...props}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="16" fill={color || "currentColor"} />
    <line
      x1="8.25781"
      y1="16"
      x2="23.7417"
      y2="16"
      stroke="#F9F9F9"
      strokeWidth="2"
    />
    <line
      x1="16"
      y1="23.4844"
      x2="16"
      y2="8.0005"
      stroke="#F9F9F9"
      strokeWidth="2"
    />
  </svg>
);
export default MinusCircle;
