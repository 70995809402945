import { fields, createSchema, validate as _validate } from "..";

const { email, firstName, lastName } = fields;

const memberAccount = createSchema({
  firstName,
  lastName,
  email,
});

const memberAccountOffline = createSchema({
  firstName,
  lastName,
});

const validate = (data) => {
  if (data.isOffline) return _validate(memberAccountOffline, data);
  return _validate(memberAccount, data);
};

export default validate;
