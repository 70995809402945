import React, { useReducer, useEffect, useRef, useState } from "react";

import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../../../utils/theme";
import CreditCard from "../../../components/Cards/CreditCard";
import CardFields from "./CardFields";
import calculatePriceDetails from "../../../utils/helpers/calculate-price-details";

import Icon from "../../../components/Icon";
import * as T from "../../../components/Typograpy";
import Button from "../../../components/Button";
import WarningMessage from "../../../components/WarningMessage";

import * as paidPackagesActions from "../../../redux/actions/paidPackages";

import validate from "../../../validation/schemas/confirm-order";

import { InputField, Checkbox } from "../../../components/Inputs";
import { Row, Col } from "../../../components/Grid";

import t from "../../../utils/helpers/translator";

import * as S from "./style";
import { GENERAL } from "../../../constants/navRoutes";
import { externalLinks } from "../../../constants";

const initialState = {
  addressLine1: "",
  addressLine2: "",
  city: "",
  postcode: "",
  agreedOnTerms: false,
  errors: {},
};
function reducer(state, newState) {
  let value = newState;
  if (typeof newState === "function") {
    value = newState(state);
  }

  return { ...state, ...value };
}

const OrderConfirmation = ({
  lang,
  confirmError,
  // getOrderLoading,
  // getOrderError,
  order,
  getOrderDetails,
  confirmOrder,
  confirmLoading,
}) => {
  const {
    subTotalPriceFormatted,
    totalPriceFormatted,
    totalPriceFormatted2,
    totalVatCostFormatted,
    totalPrice,
  } = calculatePriceDetails({
    vat: order.vat,
    price: order.price,
    credits: order.credits,
  });

  const [state, setState] = useReducer(reducer, initialState);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const { id } = useParams();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const termsOfUseText = t("agreeToTermsAndConditions", lang).split(" | ");
  const braintreeRef = useRef({
    deviceData: null,
    instance: null,
  });
  const submitAttempt = useRef();
  const history = useHistory();
  const { addressLine1, addressLine2, city, postcode, agreedOnTerms, errors } =
    state;
  useEffect(() => {
    if (id) {
      getOrderDetails(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const validateForm = () => {
    try {
      validate({
        id,

        addressLine1,
        addressLine2,
        city,
        postcode,
        agreedOnTerms,
      });
      setState({ errors: {} });
      return true;
    } catch (err) {
      if (err.name === "ValidationError") {
        setState({ errors: err.inner });
      }
      return false;
    }
  };

  useEffect(() => {
    if (confirmError?.message) {
      setOpenErrorModal(true);
    } else {
      setOpenErrorModal(false);
    }
    return setOpenErrorModal;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmError.message]);

  useEffect(
    () => () =>
      braintreeRef?.current?.instance?.teardown((teardownErr) => {
        if (teardownErr) {
          setState({
            errors: {
              payment: "Could not tear down the Hosted Fields form!",
            },
          });
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressLine1, addressLine2, city, postcode, agreedOnTerms]);

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    submitAttempt.current = true;

    const valid = validateForm();
    if (!valid) return;

    braintreeRef.current.instance.tokenize(async (tokenizeErr, payload) => {
      if (tokenizeErr?.code === "HOSTED_FIELDS_FIELDS_EMPTY" || !payload) {
        return setState({
          errors: {
            payment: "Card details are required",
          },
        });
      }

      const response = await braintreeRef.current.threeDSecure.verifyCard({
        nonce: payload.nonce,
        amount: totalPrice,
        bin: payload.bin,
      });

      await confirmOrder({
        id,
        body: {
          addressLine1,
          addressLine2,
          city,
          postcode,
          nonce: response.nonce,
          deviceData: braintreeRef.current.deviceData,
          type: "CARD",
        },
        history,
      });
    });
  };

  return (
    <S.Wrapper>
      <Row>
        <Col w={[4, 12, 12]}>
          {isMobile ? (
            <T.H30Caps color="primary">
              {t("orderConfirmation", lang)}
            </T.H30Caps>
          ) : (
            <T.H40Caps color="primary">
              {t("orderConfirmation", lang)}
            </T.H40Caps>
          )}
        </Col>
      </Row>
      <Row mt={6}>
        <Col w={[4, 12, 6]}>
          <T.H20 color="primary" mb="20">
            {t("numberOfTimeCreditsAndCost", lang)}
          </T.H20>
        </Col>
      </Row>
      <Row mt={2}>
        <Col w={[4, 12, 12]}>
          <div
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            <CreditCard
              type="digital"
              credits={order.credits}
              width={isMobile ? "300px" : "340px"}
              hideID
              tcText={t("timeCreditsNumInFront", lang)}
              price={totalPriceFormatted2}
            />
            <div
              style={{
                flexGrow: 1,
                marginLeft: isMobile ? "0" : "50px",
                marginTop: isMobile && "24px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <T.Body16B color="midnight" mb="3">
                  {t("subtotal", lang)}
                </T.Body16B>
                <T.Body16R color="gray2" mb="3">
                  {subTotalPriceFormatted}
                </T.Body16R>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <T.Body16B color="midnight" mb="5">
                  {t("totalVAT", lang)}
                </T.Body16B>
                <T.Body16R color="gray2" mb="3">
                  {totalVatCostFormatted}
                </T.Body16R>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <T.Body16B color="midnight" mb="3">
                  {t("amountDue", lang)}
                </T.Body16B>
                <T.Body16R color="gray2" mb="3">
                  {totalPriceFormatted}
                </T.Body16R>
              </div>
              <S.DownloadWrapper
                to={GENERAL.ORDER_INVOICE.replace(":id", id)}
                style={{ marginTop: isMobile && "24px" }}
              >
                <T.LinkB20 color="midnight">
                  {t("downloadInvoice", lang)}
                  <Icon icon="arrowLink" color="blue" width="22" height="22" />
                </T.LinkB20>
              </S.DownloadWrapper>
            </div>
          </div>
        </Col>
      </Row>

      <CardFields
        lang={lang}
        braintreeRef={braintreeRef}
        setError={(e) => {
          setState({
            errors: {
              payment: e,
            },
          });
        }}
      />

      <Row mt={7}>
        <Col w={[4, 12, 12]} mb="3">
          <T.Body16B color="midnight">{t("billingAddress", lang)}</T.Body16B>
        </Col>
        <Col w={[4, 6, 6]} mb="5">
          <InputField
            placeholder={`${t("addressLine1", lang)}...`}
            label={t("addressLine1", lang)}
            name="addressLine1"
            value={addressLine1}
            setValue={(e) => setState({ addressLine1: e.target.value })}
            error={errors.addressLine1}
          />
        </Col>
        <Col w={[4, 6, 6]} mb="5">
          <InputField
            placeholder={`${t("addressLine2", lang)}...`}
            label={t("addressLine2", lang)}
            name="addressLine2"
            value={addressLine2}
            setValue={(e) => setState({ addressLine2: e.target.value })}
            error={errors.addressLine2}
          />
        </Col>
        <Col w={[4, 6, 6]} mb="5">
          <InputField
            placeholder={`${t("cityTown", lang)}...`}
            label={t("cityTown", lang)}
            name="cityTown"
            value={city}
            setValue={(e) => setState({ city: e.target.value })}
            error={errors.city}
          />
        </Col>
        <Col w={[4, 6, 6]} mb="5">
          <InputField
            placeholder={`${t("postcode", lang)}...`}
            label={t("postcode", lang)}
            name="postcode"
            value={postcode}
            setValue={(e) => setState({ postcode: e.target.value })}
            error={errors.postcode}
          />
        </Col>
        <Col w={[4, 12, 12]} mb="5">
          <Checkbox
            size="small"
            value={agreedOnTerms}
            setValue={(e) => setState({ agreedOnTerms: e.target.checked })}
            name="agreedOnTerms"
            label={
              <>
                {termsOfUseText[0]}{" "}
                <S.ExtLink
                  href={externalLinks.EARN_TERMS}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {termsOfUseText[1]}
                </S.ExtLink>
                .
              </>
            }
            error={errors.agreedOnTerms}
          />
        </Col>
        <Col w={[4, 12, 12]} mb="5">
          {/* {error && error.message && (
            <S.Error>{t(error.message, lang)}</S.Error>
          )} */}
          {errors.payment && <S.Error>{errors.payment}</S.Error>}
          {confirmError?.message && (
            <S.Error>
              {confirmError?.message
                ? t(confirmError?.message, lang)
                : confirmError}
            </S.Error>
          )}
          <Button
            primary
            bgColor="blue"
            onClick={handleSubmit}
            loading={confirmLoading}
          >
            {t("pay", lang)} {totalPriceFormatted}
          </Button>
        </Col>
      </Row>
      <Row mt={8}>
        <Col w={[4, 12, 12]}>
          <T.Body20B color="midnight">
            {t("payByBankTransfer", lang)}?
          </T.Body20B>
          <T.Body14R color="midnight">
            {t("contactUsUsingHelpButton", lang)}
          </T.Body14R>
        </Col>
      </Row>
      <Row mt="205px">
        <Col w={[4, 12, 12]}>
          <T.Body20B color="gray3">{t("needToCancelOrder", lang)}</T.Body20B>
        </Col>
      </Row>
      <WarningMessage
        open={openErrorModal}
        setOpen={setOpenErrorModal}
        handleClick={handleSubmit}
        title="payment unsuccessful"
        titleColor="pink"
        buttonText={t("tryAgain", lang)}
        cancelText={t("cancel", lang)}
      >
        <T.Body20B color="midnight" style={{ marginBottom: "16px" }}>
          {t("confirmPaymentError", lang)}
        </T.Body20B>
        <T.Body16R style={{ marginBottom: "20px" }}>
          {t("error", lang)} -{" "}
          {confirmError?.data?.transaction?.processorResponseCode} -{" "}
          {confirmError?.data?.message || t(confirmError.message, lang)}
        </T.Body16R>
      </WarningMessage>
    </S.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  lang: state.auth.decideLanguage(state.auth),
  getOrderLoading: state.paidPackages.getOrderLoading,
  getOrderError: state.paidPackages.getOrderError,
  order: state.paidPackages.order,
  confirmError: state.paidPackages.confirmError,
  confirmLoading: state.paidPackages.confirmLoading,
});

const mapActionsToProps = {
  getOrderDetails: paidPackagesActions.getOrderDetails,
  confirmOrder: paidPackagesActions.confirmOrder,
};

export default connect(mapStateToProps, mapActionsToProps)(OrderConfirmation);
