import React from "react";

const PaymentCard = ({ width, height, color, ...props }) => (
  <svg
    width={width || "20"}
    height={height || "20"}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.8453 3.75H4.53573C3.38513 3.75 2.45239 4.72938 2.45239 5.9375V14.0625C2.45239 15.2706 3.38513 16.25 4.53573 16.25H15.8453C16.9959 16.25 17.9286 15.2706 17.9286 14.0625V5.9375C17.9286 4.72938 16.9959 3.75 15.8453 3.75Z"
      stroke={color || "#201751"}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.45239 7.5H17.9286"
      stroke={color || "#201751"}
      strokeWidth="2.34375"
      strokeLinejoin="round"
    />
    <path
      d="M7.2143 11.7188H5.42859V12.5H7.2143V11.7188Z"
      stroke={color || "#201751"}
      strokeWidth="2.34375"
      strokeLinejoin="round"
    />
  </svg>
);
export default PaymentCard;
