import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Row, Col } from "../../../components/Grid";
import { Dropdown } from "../../../components/Inputs";
import Button from "../../../components/Button";
import GoBack from "../../../components/GoBack";
import * as T from "../../../components/Typograpy";

import { getSpendPartners as getSpendPartnersAction } from "../Staff/EditSpendVenueAccount/actions";

import * as actions from "../../../redux/actions/userAccount";
import { TEMPO } from "../../../constants/navRoutes";
import t from "../../../utils/helpers/translator";

const EditSpendVenueAccount = ({
  isMobile,
  spendPartners,
  getSpendPartners,
}) => {
  const [spendPartner, setSpendPartner] = useState({
    id: null,
    name: "",
    userId: null,
  });
  const [btnOn, setBtnOn] = useState(false);
  const [errors, setErrors] = useState({});
  const history = useHistory();

  useEffect(() => {
    getSpendPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDropdown = (selected) => {
    // handle delete
    if (!selected) {
      setSpendPartner({});
    } else {
      setBtnOn(true);
      setSpendPartner({
        name: selected.label,
        id: selected.value,
        userId: selected.userId,
      });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      if (spendPartner && spendPartner.id) {
        history.push(
          TEMPO.CREATE_SPEND_VENUE.replace(
            ":spendPartnerId",
            spendPartner.userId,
          ),
        );
      } else {
        setErrors({
          spendPartner: "Select recognition partner first or create a new one",
        });
      }
    } catch (err) {
      if (err.name === "ValidationError") {
        setErrors(err.inner);
      } else {
        setErrors({ serverError: err.message });
      }
      setBtnOn(false);
    }
  };

  return (
    <>
      {/* Account details */}
      <Row jc="space-between" mb={5}>
        <Col w={[4, 6, 7]}>
          <GoBack showText />
          <T.H40Caps color="midnight" mt="30">
            Add venue
          </T.H40Caps>
        </Col>
        <Col w={[4, 12, 12]}>
          <T.Body16R color="gray3" mt="62">
            To begin, please select the Recognition Partner associated with this
            venue.
          </T.Body16R>
        </Col>
      </Row>

      <Row mb={3}>
        <Col w={[4, 6, 4]}>
          <Dropdown
            placeholder="Select partner..."
            label="Select partner"
            name="spendPartner"
            helper="ensure consistency with CRM"
            options={spendPartners.map((partner) => ({
              label: partner.name,
              value: partner.id,
              userId: partner.userId,
            }))}
            value={
              spendPartner &&
              spendPartner.id && [
                {
                  value: spendPartner.id,
                  label: spendPartner.name,
                  userId: spendPartner.userId,
                },
              ]
            }
            setValue={({ selected }) =>
              handleDropdown(selected, "spendPartner")
            }
            error={errors.spendPartner}
            blurInputOnSelect={false}
            justify="center"
          />
        </Col>
      </Row>
      <Row mb={9}>
        <Col w={[4, 12, 12]}>
          <T.Body14B color="gray3">
            Partner doesn’t exist yet?{" "}
            <Link to={TEMPO.CREATE_SPEND_PARTNER}>
              <T.Link14R
                color="blue"
                style={{ display: "inline", fontWeight: 700 }}
              >
                Add partner
              </T.Link14R>
            </Link>
          </T.Body14B>
        </Col>
      </Row>
      <Row
        style={{
          marginTop: !isMobile ? "40px" : "2rem",
        }}
      >
        <Col w={[4, 4, 4]}>
          <Button
            primary
            size="l"
            type="submit"
            handleClick={onSubmit}
            disabled={!btnOn}
          >
            Continue
          </Button>
        </Col>
      </Row>
      {errors.serverError && (
        <Row mt={2}>
          <Col w={[4, 8, 8]}>
            <T.Body16R color="pink">
              {t(errors.serverError, "english")}
            </T.Body16R>
          </Col>
        </Row>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  role: state.auth.role,
  userAccount: state.userAccount.data,
  getAccountLoading: state.userAccount.getAccountLoading,
  updateAccountLoading: state.userAccount.updateAccountLoading,
  spendPartners: state.staffEditSVAccount.spendPartners,
  getSpendPartnersLoading: state.staffEditSVAccount.getSpendPartnersLoading,
});

const mapActionToProps = {
  getUserAccount: actions.getUserAccount,
  getSpendPartners: getSpendPartnersAction,
};

export default connect(
  mapStateToProps,
  mapActionToProps,
)(EditSpendVenueAccount);
