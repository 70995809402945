import {
  GET_USER_TC_BALANCE_LOADING,
  GET_USER_TC_BALANCE_SUCCESS,
  GET_USER_TC_BALANCE_FAIL,
  GET_USER_TRANSACTIONS_LOADING,
  GET_USER_TRANSACTIONS_SUCCESS,
  GET_USER_TRANSACTIONS_FAIL,
  CANCEL_GIFT_LOADING_START,
  CANCEL_GIFT_SUCCESS,
  CANCEL_GIFT_FAIL,
} from "../types";

const initialState = {
  tc: { loading: false, tcBalance: 0, error: null },
  transactions: { loading: false, data: [], error: null },
};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_USER_TC_BALANCE_LOADING:
      return {
        ...state,
        tc: { ...state.tc, loading: true },
      };
    case GET_USER_TC_BALANCE_SUCCESS:
      return {
        ...state,
        tc: {
          ...state.tc,
          loading: false,
          tcBalance: Number(payload.sumCurrentTc),
        },
      };
    case GET_USER_TC_BALANCE_FAIL:
      return { ...state, tc: { ...state.tc, loading: false, error: payload } };
    case GET_USER_TRANSACTIONS_LOADING:
      return {
        ...state,
        transactions: { ...state.transactions, loading: true },
      };
    case GET_USER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          loading: false,
          data: payload,
        },
      };
    case GET_USER_TRANSACTIONS_FAIL:
      return {
        ...state,
        transactions: { ...state.transactions, loading: false, error: payload },
      };

    case CANCEL_GIFT_LOADING_START:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          data: payload,
        },
      };

    case CANCEL_GIFT_SUCCESS:
      return {
        ...state,
        tc: {
          ...state.tc,
          tcBalance: payload.tcBalance,
        },
        transactions: {
          ...state.transactions,
          data: payload.data,
        },
      };

    case CANCEL_GIFT_FAIL:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          data: payload,
        },
      };

    default:
      return state;
  }
};

export default userReducer;
