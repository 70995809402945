import React from "react";
import { useHistory } from "react-router-dom";
import Button from "./style";
import * as T from "../Typograpy";
import Icon from "../Icon";
import Loading from "../Button/Loading";

const SpendButton = ({ to, classes: _classes, icon, m, ...props }) => {
  const { loading, handleClick = () => {}, disabled, children } = props;
  const history = useHistory();
  const onClick = (e) => {
    if (to) history.push(to);
    if (handleClick instanceof Function) handleClick(e);
  };

  return (
    <Button
      onClick={onClick}
      type="button"
      disabled={disabled || loading}
      m={m}
      {...props}
    >
      <T.Body16B color="white" m="0">
        {children}
      </T.Body16B>
      {loading ? (
        <Loading {...props} />
      ) : (
        <Icon
          icon={icon}
          width={icon === "arrowWhite" ? "24px" : "40px"}
          height={icon === "arrowWhite" ? "24px" : "40px"}
        />
      )}
    </Button>
  );
};

export default SpendButton;
