import { fields, createSchema, validate as _validate } from "..";

const { positiveNumRequired, maximumTc, packages } = fields;

const schema = createSchema({
  price: positiveNumRequired,
  maximumTc,
  minimumTc: positiveNumRequired,
  packages,
});

const validate = (data) => _validate(schema, data);

export default validate;
