import { styled } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

export const Wrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
}));

export const HeaderWrapper = styled("header")(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const HeaderWrapperMobile = styled("header")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

export const HeadlineContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  paddingTop: theme.spacing(2),

  [theme.breakpoints.tablet]: {
    paddingTop: 0,
  },
}));

export const Section = styled("section")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  alignItems: "center",
  justifyContent: "flex-start",
  width: "100%",
  paddingTop: "1rem",
  "& div": {
    width: "100%",
  },
  // desktop view
  [theme.breakpoints.tablet]: {
    alignItems: "flex-start",
  },
}));

export const Details = styled("div")(({ theme }) => ({
  width: "100%",
  marginBottom: theme.spacing(6),

  [theme.breakpoints.mobileXL]: {
    width: "50%",
  },
}));

export const OptionalDetailsWrapper = styled("div")(() => ({
  display: "flex",
  flexWrap: "wrap",
}));

export const OptionalDetail = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(6),

  [theme.breakpoints.mobileL]: {
    marginRight: theme.spacing(12),
  },
}));

export const IndividualWrapper = styled("section")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  paddingLeft: theme.spacing(2),
}));

export const IndividualHeader = styled("header")(({ theme }) => ({
  paddingTop: theme.spacing(4),
  marginBottom: theme.spacing(4),

  [theme.breakpoints.mobileL]: {
    marginBottom: theme.spacing(7),
  },
}));

export const StyledLink = styled(Link)(() => ({
  textDecoration: "none",
}));

export const ImageWrapper = styled("div")(({ theme }) => ({
  display: "none",

  [theme.breakpoints.laptopL]: {
    display: "block",
    position: "absolute",
    top: theme.spacing(9),
    right: theme.spacing(3),
  },
}));

export const StyledButton = styled("button")(({ theme }) => ({
  background: "transparent",
  outline: "none",
  border: "none",
  display: "flex",
  alignItems: "center",
  fontSize: "0.75rem",
  fontWeight: "bold",
  color: theme.colors.gray3,
  paddingTop: theme.spacing(0),
  cursor: "pointer",
}));

export const StyledMobileButton = styled("button")(({ theme }) => ({
  background: "transparent",
  outline: "none",
  border: "none",
  display: "flex",
  alignItems: "center",
  fontSize: "0.75rem",
  fontWeight: "bold",
  color: theme.colors.gray3,
  paddingTop: theme.spacing(0),
  cursor: "pointer",
  padding: "0",
  margin: "0 0 0 auto",
  alignSelf: "flex-end",
}));
