import Typography from "@material-ui/core/Typography";
import { styled } from "@material-ui/core/styles";

export const AddLocation = styled("button")(() => ({
  border: "none",
  background: "none",
  cursor: "pointer",
  margin: 0,
  padding: 0,
  outline: "none",
}));

export const Wrapper = styled("div")(() => ({
  marginTop: 30,
}));

export const Error = styled(Typography)(({ theme }) => ({
  width: "100%",
  color: theme.colors.pink,
  fontSize: "0.875rem",
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(2),
  marginTop: -theme.spacing(4),
}));
