import { fields, createSchema, validate as _validate } from "..";

const { agreedOnTerms, postcode, textRequired, id, optionalText } = fields;

const schema = createSchema({
  id,
  postcode,

  addressLine1: textRequired,
  addressLine2: optionalText(100),
  city: textRequired,
  agreedOnTerms,
});

const validate = (data) => _validate(schema, data);

export default validate;
