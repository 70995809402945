import React from "react";

import { Row, Col } from "../../components/Grid";

import * as T from "../../components/Typograpy";
import t from "../../utils/helpers/translator";

import { InputField, Checkbox } from "../../components/Inputs";

const decideCodes = (codes) =>
  codes.map((code) => code.trim()).filter((code) => !!code).length;

const HowToSpend = ({
  turnUpAtVenue,
  callToBook,
  callExtraDetails,
  onlineCodesToBook,
  bookingCodes,
  useCodesLink,
  useCodesDetails,
  handleText,
  handleCheckbox,
  handleCodes,
  costVaries,
  isMobile,
  errors,
  edit,
  bookingCodesUsed,
  language,
  // Welsh
  welshVersion,
  callExtraDetailsWelsh,
  useCodesDetailsWelsh,
}) => (
  <>
    <Row mb={5}>
      <Col w={[4, 12, 8]}>
        <T.H24 color="midnight" m="0 0 15px 0">
          {t("howToUseTc", language)}
        </T.H24>
        <T.Body16R color="gray3" m="0 0 20px 0">
          {t("howToUseTcDetails", language)}
        </T.Body16R>
      </Col>
    </Row>
    <Row mb={1}>
      <Col w={[4, 12, 8]}>
        <Checkbox
          value={turnUpAtVenue}
          setValue={handleCheckbox}
          label={`${t("turnUp", language)}(${t("noNeedToBook", language)})`}
          name="turnUpAtVenue"
          size={isMobile ? "normal" : "medium"}
          error={errors.atLeastOneWayToSpend || errors.turnUpAtVenue}
        />
      </Col>
    </Row>
    <Row mb={1}>
      <Col w={[4, 12, 8]}>
        <Checkbox
          value={callToBook}
          setValue={handleCheckbox}
          label={t("callEmail", language)}
          name="callToBook"
          size={isMobile ? "normal" : "medium"}
          error={errors.atLeastOneWayToSpend || errors.callToBook}
        />
      </Col>
    </Row>
    {callToBook && (
      <Row mb={5}>
        <Col w={[4, 12, 6]}>
          <InputField
            placeholder="Type here..."
            label="Please provide any extra details on what they need to do to book in advance"
            name="callExtraDetails"
            type="textarea"
            value={callExtraDetails}
            setValue={handleText}
            error={errors.callExtraDetails}
            justify="center"
            showLimit
            limit={300}
          />
        </Col>
        {welshVersion && (
          <Col w={[4, 12, 6]}>
            <InputField
              placeholder="Type here..."
              label="Please provide any extra details on what they need to do to book in advance (Welsh)"
              labelColor="blue"
              name="callExtraDetailsWelsh"
              type="textarea"
              value={callExtraDetailsWelsh}
              setValue={handleText}
              error={errors.callExtraDetailsWelsh}
              justify="center"
              showLimit
              limit={300}
            />
          </Col>
        )}
      </Row>
    )}
    <Row mb={onlineCodesToBook ? 0 : 8}>
      <Col w={[4, 12, 8]}>
        <Checkbox
          value={costVaries ? false : onlineCodesToBook}
          setValue={handleCheckbox}
          mt="10"
          label={t("getOnlineBooking", language)}
          name="onlineCodesToBook"
          size={isMobile ? "normal" : "medium"}
          error={errors.atLeastOneWayToSpend || errors.onlineCodesToBook}
          disabled={costVaries}
        />
      </Col>
    </Row>
    {onlineCodesToBook && !costVaries && (
      <>
        <Row mb={5}>
          <Col w={[4, 12, 6]}>
            <InputField
              placeholder={`${t("typeHere", language)}...`}
              label={t("enterBookingCodes", language)}
              name="bookingCodes"
              type="textarea"
              value={bookingCodes && bookingCodes.toString()}
              setValue={handleCodes}
              error={errors.bookingCodes}
              justify="center"
            />
            {bookingCodes && !edit && (
              <T.Body16I color="midnight">
                {t("codesDetected", language, [decideCodes(bookingCodes)])}
              </T.Body16I>
            )}
            {edit && (
              <T.Body14I color="gray3" ml={15} mt={10}>
                {t("codesStatus", language, [
                  bookingCodes ? decideCodes(bookingCodes) : 0,
                  bookingCodesUsed,
                ])}
              </T.Body14I>
            )}
          </Col>
          <Col w={[4, 6, 6]} />
        </Row>
        <Row mb={5}>
          <Col w={[4, 12, 6]}>
            <InputField
              placeholder={`${t("typeUrlHere", language)}...`}
              label={t("useCodesLink", language)}
              helper={t("useCodesLinkHelper", language)}
              name="useCodesLink"
              value={useCodesLink}
              setValue={handleText}
              error={errors.useCodesLink}
              justify="center"
              isOptional
            />
          </Col>
        </Row>

        <Row mb={8}>
          <Col w={[4, 12, 6]}>
            <InputField
              placeholder={`${t("typeHere", language)}...`}
              label={`${t("bookingCodesDetails", language)}${
                language === "welsh" ? ` (${t("english", language)})` : ""
              }`}
              name="useCodesDetails"
              type="textarea"
              value={useCodesDetails}
              setValue={handleText}
              error={errors.useCodesDetails}
              justify="center"
              showLimit
              limit={800}
              helper={t("maxCharacters", language, [800])}
            />
          </Col>
          {welshVersion && (
            <Col w={[4, 12, 6]}>
              <InputField
                placeholder={`${t("typeHere", language)}...`}
                label={`${t("bookingCodesDetails", language)}${
                  language === "english" ? ` (${t("welsh", language)})` : ""
                }`}
                labelColor="blue"
                name="useCodesDetailsWelsh"
                type="textarea"
                value={useCodesDetailsWelsh}
                setValue={handleText}
                error={errors.useCodesDetailsWelsh}
                justify="center"
                showLimit
                limit={800}
                helper={t("maxCharacters", language, [800])}
              />
            </Col>
          )}
        </Row>
      </>
    )}
  </>
);

export default React.memo(HowToSpend);
