import { fields, createSchema, validate as _validate } from "..";

const { email, firstName, lastName, phoneNumber, password, id } = fields;

const spendVenueAccountAdmin = createSchema({
  spendPartnerId: id,
  firstName,
  lastName,
  email,
  phoneNumber,
});

const spendVenueNewAccountAdmin = createSchema({
  firstName,
  lastName,
  email,
  phoneNumber,
  password,
  spendPartnerId: id,
});
const updateSpendPartner = createSchema({
  spendPartnerId: id,
});

const validate = (data) => {
  if (data.hasLogin) {
    return _validate(spendVenueAccountAdmin, data);
  }
  if (data.isCreatingAccount) {
    return _validate(spendVenueNewAccountAdmin, data);
  }

  return _validate(updateSpendPartner, data);
};

export default validate;
