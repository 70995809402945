import {
  GET_SPEND_VENUE_PROFILE_ADMIN_LOADING_START,
  GET_SPEND_VENUE_PROFILE_ADMIN_LOADING_END,
  GET_SPEND_VENUE_PROFILE_ADMIN_SUCCESS,
  GET_SPEND_VENUE_PROFILE_ADMIN_FAIL,
  UPDATE_SPEND_VENUE_PROFILE_ADMIN_LOADING_START,
  UPDATE_SPEND_VENUE_PROFILE_ADMIN_LOADING_END,
  UPDATE_SPEND_VENUE_PROFILE_ADMIN_SUCCESS,
  UPDATE_SPEND_VENUE_PROFILE_ADMIN_FAIL,
  RESET_SPEND_VENUE_PROFILE_ADMIN,
  GET_SPEND_PARTNER_LOADING_START,
  GET_SPEND_PARTNER_LOADING_END,
  GET_SPEND_PARTNER_SUCCESS,
  GET_SPEND_PARTNER_FAIL,
} from "../../../redux/types";

import { spendVenues, spendPartners } from "../../../api-calls";

export const getSpendVenue =
  ({ id, profile, isBasic }) =>
  async (dispatch) => {
    dispatch({ type: GET_SPEND_VENUE_PROFILE_ADMIN_LOADING_START });
    try {
      const { data, error } = await spendVenues.getSpendVenueById({
        spendVenueId: id,
        type: "spend",
        profile,
        isBasic,
      });
      if (data) {
        dispatch({
          type: GET_SPEND_VENUE_PROFILE_ADMIN_SUCCESS,
          payload: data,
        });
      } else if (error) {
        dispatch({
          type: GET_SPEND_VENUE_PROFILE_ADMIN_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_SPEND_VENUE_PROFILE_ADMIN_FAIL,
        payload: error,
      });
    } finally {
      dispatch({ type: GET_SPEND_VENUE_PROFILE_ADMIN_LOADING_END });
    }
  };

export const updateSpendVenue =
  ({ spendVenueId, updateData }) =>
  async (dispatch) => {
    dispatch({ type: UPDATE_SPEND_VENUE_PROFILE_ADMIN_LOADING_START });
    try {
      const { error } = await spendVenues.updateSpendVenue({
        id: spendVenueId,
        updateData,
      });
      if (error) {
        dispatch({
          type: UPDATE_SPEND_VENUE_PROFILE_ADMIN_FAIL,
          payload: error,
        });
      } else {
        dispatch({
          type: UPDATE_SPEND_VENUE_PROFILE_ADMIN_SUCCESS,
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_SPEND_VENUE_PROFILE_ADMIN_FAIL,
        payload: error,
      });
    } finally {
      dispatch({ type: UPDATE_SPEND_VENUE_PROFILE_ADMIN_LOADING_END });
    }
  };

export const createSpendVenue =
  ({ createData }) =>
  async (dispatch) => {
    dispatch({ type: UPDATE_SPEND_VENUE_PROFILE_ADMIN_LOADING_START });
    try {
      const { data, error } = await spendVenues.createSpendVenue({
        createData,
      });
      if (error) {
        dispatch({
          type: UPDATE_SPEND_VENUE_PROFILE_ADMIN_FAIL,
          payload: error,
        });
      } else {
        dispatch({
          type: UPDATE_SPEND_VENUE_PROFILE_ADMIN_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_SPEND_VENUE_PROFILE_ADMIN_FAIL,
        payload: error,
      });
    } finally {
      dispatch({ type: UPDATE_SPEND_VENUE_PROFILE_ADMIN_LOADING_END });
    }
  };

export const getSpendPartnerById =
  ({ id }) =>
  async (dispatch) => {
    dispatch({ type: GET_SPEND_PARTNER_LOADING_START });
    try {
      const { data, error } = await spendPartners.getSpendPartnerById({
        id,
      });
      if (error) {
        dispatch({
          type: GET_SPEND_PARTNER_FAIL,
          payload: error,
        });
      } else {
        dispatch({
          type: GET_SPEND_PARTNER_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_SPEND_PARTNER_FAIL,
        payload: error,
      });
    } finally {
      dispatch({ type: GET_SPEND_PARTNER_LOADING_END });
    }
  };

export const resetSpendVenueForm = () => ({
  type: RESET_SPEND_VENUE_PROFILE_ADMIN,
});
