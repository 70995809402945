import {
  GET_SELECT_USER_LOADING,
  GET_SELECT_USER_SUCCESS,
  GET_SELECT_USER_FAIL,
  SELECT_ACTIVE_USER_LOADING,
  SELECT_ACTIVE_USER_FAIL,
} from "../../../redux/types";

const initState = {
  loading: false,
  error: null,
  users: [],
};

const selectUsersReducer = (state = initState, action) => {
  const { type, payload, programmeName } = action;

  switch (type) {
    case GET_SELECT_USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_SELECT_USER_SUCCESS:
      return {
        ...state,
        users: payload,
        programmeName,
        loading: false,
      };
    case GET_SELECT_USER_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    case SELECT_ACTIVE_USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    // on success go with login reducer
    case SELECT_ACTIVE_USER_FAIL:
      return {
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default selectUsersReducer;
