import {
  GET_STAFF_USERS_LOADING_START,
  GET_STAFF_USERS_LOADING_END,
  GET_STAFF_USERS_SUCCESS,
  GET_STAFF_USERS_FAIL,
  ADD_STAFF_USER_LOADING_START,
  ADD_STAFF_USER_LOADING_END,
  ADD_STAFF_USER_SUCCESS,
  ADD_STAFF_USER_FAIL,
  DEACTIVATE_STAFF_USER_LOADING_START,
  DEACTIVATE_STAFF_USER_LOADING_END,
  DEACTIVATE_STAFF_USER_SUCCESS,
  DEACTIVATE_STAFF_USER_FAIL,
  UPDATE_STAFF_USER_LOADING_START,
  UPDATE_STAFF_USER_LOADING_END,
  UPDATE_STAFF_USER_SUCCESS,
  UPDATE_STAFF_USER_FAIL,
} from "../../../../redux/types";

const initState = {
  users: [],
  getLoading: false,
  getError: null,
  addLoading: false,
  addError: null,
  deactivateLoading: false,
  deactivateError: null,
};

const accessPermissions = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_STAFF_USERS_LOADING_START:
      return {
        ...state,
        getLoading: true,
        getError: null,
      };
    case GET_STAFF_USERS_LOADING_END:
      return {
        ...state,
        getLoading: false,
      };
    case GET_STAFF_USERS_SUCCESS:
      return {
        ...state,
        users: payload,
        getLoading: false,
      };
    case GET_STAFF_USERS_FAIL:
      return {
        ...state,
        getLoading: false,
        getError: payload,
      };

    case ADD_STAFF_USER_LOADING_START:
      return {
        ...state,
        addLoading: true,
        addError: null,
      };
    case ADD_STAFF_USER_LOADING_END:
      return {
        ...state,
        addLoading: false,
      };
    case ADD_STAFF_USER_SUCCESS:
      return {
        ...state,
        addLoading: false,
      };
    case ADD_STAFF_USER_FAIL:
      return {
        ...state,
        addLoading: false,
        addError: payload,
      };

    case DEACTIVATE_STAFF_USER_LOADING_START:
      return {
        ...state,
        deactivateLoading: true,
        deactivateError: null,
        getLoading: true,
      };
    case DEACTIVATE_STAFF_USER_LOADING_END:
      return {
        ...state,
        getLoading: false,
        deactivateLoading: false,
      };
    case DEACTIVATE_STAFF_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter((i) => i.id !== payload),
        getLoading: false,
        deactivateLoading: false,
      };
    case DEACTIVATE_STAFF_USER_FAIL:
      return {
        ...state,
        deactivateLoading: false,
        getLoading: false,
        deactivateError: payload,
      };

    case UPDATE_STAFF_USER_LOADING_START:
      return {
        ...state,
        updateLoading: true,
        updateError: null,
        getLoading: true,
      };
    case UPDATE_STAFF_USER_LOADING_END:
      return {
        ...state,
        getLoading: false,
        updateLoading: false,
      };
    case UPDATE_STAFF_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map((i) => {
          if (i.id === payload.id) {
            return { ...i, role: payload.role };
          }
          return i;
        }),
        getLoading: false,
        updateLoading: false,
      };
    case UPDATE_STAFF_USER_FAIL:
      return {
        ...state,
        updateLoading: false,
        getLoading: false,
        updateError: payload,
      };
    default:
      return state;
  }
};

export default accessPermissions;
