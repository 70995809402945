import { members } from "../../../../api-calls";

import {
  GET_STAFF_SINGLE_MEMBER_LOADING,
  GET_STAFF_SINGLE_MEMBER_SUCCESS,
  GET_STAFF_SINGLE_MEMBER_FAIL,
  GET_STAFF_SINGLE_MEMBER_TRANSACTIONS_LOADING,
  GET_STAFF_SINGLE_MEMBER_TRANSACTIONS_SUCCESS,
  GET_STAFF_SINGLE_MEMBER_TRANSACTIONS_FAIL,
  DELETE_STAFF_SINGLE_MEMBER_SUCCESS,
  DELETE_STAFF_SINGLE_MEMBER_FAIL,
} from "../../../../redux/types";

export const getMemberById = (memberId) => async (dispatch) => {
  dispatch({ type: GET_STAFF_SINGLE_MEMBER_LOADING });
  try {
    const { data, error } = await members.getMemberById({
      memberId,
    });
    if (data) {
      dispatch({ type: GET_STAFF_SINGLE_MEMBER_SUCCESS, payload: data });
    } else {
      dispatch({
        type: GET_STAFF_SINGLE_MEMBER_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_STAFF_SINGLE_MEMBER_FAIL,
      payload: error,
    });
  }
};

export const deleteMemberById = (memberId) => async (dispatch, getState) => {
  dispatch({ type: GET_STAFF_SINGLE_MEMBER_LOADING });
  try {
    await members.deleteMemberById({ memberId });

    const { staffSingleMember } = getState();
    dispatch({
      type: DELETE_STAFF_SINGLE_MEMBER_SUCCESS,
      payload: {
        ...staffSingleMember,
        email: "",
        memberName: "",
        phone: "",
        groups: "",
      },
    });
  } catch (error) {
    dispatch({
      type: DELETE_STAFF_SINGLE_MEMBER_FAIL,
      payload: error,
    });
  }
};

export const getMemberTransactions = (memberId) => async (dispatch) => {
  dispatch({ type: GET_STAFF_SINGLE_MEMBER_TRANSACTIONS_LOADING });
  try {
    const { data, error } = await members.getMemberTransactions({
      memberId,
    });
    if (data) {
      dispatch({
        type: GET_STAFF_SINGLE_MEMBER_TRANSACTIONS_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: GET_STAFF_SINGLE_MEMBER_TRANSACTIONS_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_STAFF_SINGLE_MEMBER_TRANSACTIONS_FAIL,
      payload: error,
    });
  }
};
