import {
  GET_STAFF_TRANSACTIONS_LOADING,
  GET_STAFF_TRANSACTIONS_SUCCESS,
  GET_STAFF_TRANSACTIONS_FAIL,
} from "../../../../redux/types";

const initState = {
  data: [],
  loading: false,
  error: null,
};

const staffTransactionsReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_STAFF_TRANSACTIONS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_STAFF_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        data: payload,
        loading: false,
      };
    case GET_STAFF_TRANSACTIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default staffTransactionsReducer;
