import React, { useEffect } from "react";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import * as S from "./style";
// components

import TransactionSection from "./AllTransactionsLogic";
import { getUserCurrentTc } from "../../../redux/actions/userActions";
import * as T from "../../../components/Typograpy";

import t from "../../../utils/helpers/translator";

const EarnGroupAllTransactions = ({
  credits,
  getUserCurrentTcAction,
  language,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    getUserCurrentTcAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isMobile ? (
        <S.TransactionsWrapper>
          <S.Header>
            <T.H30Caps
              color="midnight"
              style={{ marginBottom: "10px", marginLeft: "0" }}
            >
              {t("myTCHistory", language)}
            </T.H30Caps>
            <T.Body16B
              color="blue"
              style={{ marginBottom: "20px", marginLeft: "0" }}
            >
              {t("tcBalance", language)}:{` ${(credits && credits) || "0"}`}
            </T.Body16B>
          </S.Header>
          <TransactionSection isMobile={isMobile} credits={credits} />
        </S.TransactionsWrapper>
      ) : (
        <S.TransactionsWrapper>
          <S.Header>
            <T.H40Caps
              color="midnight"
              style={{ marginBottom: "1rem", marginLeft: "0" }}
            >
              {t("myTCHistory", language)}
            </T.H40Caps>
            <T.H24 color="blue" style={{ marginLeft: "0" }}>
              {t("tcBalance", language)}:{` ${(credits && credits) || "0"}`}
            </T.H24>
          </S.Header>
          <TransactionSection isMobile={isMobile} credits={credits} />
        </S.TransactionsWrapper>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  credits: state.user.tc.tcBalance,
  language: state.auth.language,
});

const mapActionToProps = { getUserCurrentTcAction: getUserCurrentTc };

export default connect(
  mapStateToProps,
  mapActionToProps,
)(EarnGroupAllTransactions);
