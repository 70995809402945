import {
  ADD_MEMBERS_TO_EARN_GROUP_LOADING,
  ADD_MEMBERS_TO_EARN_GROUP_SUCCESS,
  ADD_MEMBERS_TO_EARN_GROUP_FAIL,
} from "../../../../redux/types";

const initState = {
  loading: false,
  error: null,
  success: false,
};

const staffMembersReducer = (state = initState, action) => {
  const { type } = action;

  switch (type) {
    case ADD_MEMBERS_TO_EARN_GROUP_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
      };
    case ADD_MEMBERS_TO_EARN_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
      };
    case ADD_MEMBERS_TO_EARN_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    default:
      return state;
  }
};

export default staffMembersReducer;
