import React from "react";
import { connect } from "react-redux";
import Image from "../../Image";
import translate from "../../../utils/helpers/translator";

import * as S from "./style";
import * as T from "../../Typograpy";

import { MEMBER } from "../../../constants/navRoutes";

const Gift = ({ w, lang }) => (
  <S.Wrapper to={MEMBER.GIFT} w={w}>
    <S.Header>
      <Image image="giftWhite" width="50px" height="50px" margin="0 10px 0 0" />
      <T.H22 color="white">{translate("shareLove", lang)}...</T.H22>
    </S.Header>
    <T.Body14R color="white">
      {translate("youCanGiftExplanation", lang)}
    </T.Body14R>
  </S.Wrapper>
);

const mapStateToProps = (state) => ({
  lang: state.auth.language,
});

export default connect(mapStateToProps)(Gift);
