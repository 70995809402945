import React from "react";

import * as T from "../../Typograpy";
import { TimepickerContainer, TimePicker } from "./style";
// import Icon from "../../Icon";
import SingleTimePicker from "../SingleTimePicker";
import useStyles from "../style";
import t from "../../../utils/helpers/translator";

const Timepicker = (props) => {
  const {
    setValue,
    label,
    error,
    startTime,
    endTime,
    language = "english",
  } = props;

  const classes = useStyles(props);
  return (
    <>
      {label && (
        <T.H16 color="midnight" style={{ padding: "0 0 5px 10px" }}>
          {label}
        </T.H16>
      )}
      <TimepickerContainer error={error}>
        <TimePicker>
          <SingleTimePicker
            setValue={setValue}
            label={t("startTime", language)}
            name="startTime"
            value={startTime}
          />
        </TimePicker>
        <T.H14Caps color="gray3">-</T.H14Caps>
        <TimePicker>
          <SingleTimePicker
            setValue={setValue}
            label={t("endTime", language)}
            name="endTime"
            value={endTime}
          />
        </TimePicker>
        {/* <Icon icon="clock" width="17px" height="17px" color="midnight" /> */}
      </TimepickerContainer>
      {error && (
        <T.Body14R
          className={classes.textFieldError}
          style={{ textAlign: "center" }}
        >
          {error}
        </T.Body14R>
      )}{" "}
    </>
  );
};

export default Timepicker;
