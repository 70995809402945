import { styled } from "@material-ui/core/styles";
import * as T from "../Typograpy";

export const BoxWrapper = styled("div")(({ theme, disabled }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: 20,
  borderWidth: 2,
  borderRadius: 2,
  borderColor: theme.colors["gray6"],
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: disabled ? "not-allowed" : "pointer",
  minHeight: "145px",

  "&:focus, &:active, &:hover": {
    borderColor: theme.colors.midnight,
  },
}));

export const FileNameWrapper = styled("div")(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  width: "100%",
  marginTop: theme.spacing(1),
}));

export const Error = styled(T.Body16R)(({ theme }) => ({
  width: "100%",
  color: theme.colors.pink,
  fontSize: "0.875rem",
  textAlign: "left",
  marginTop: theme.spacing(1),
}));
