import { spendPartners } from "../../../../api-calls";

import {
  GET_STAFF_SINGLE_SPEND_PARTNER_LOADING,
  GET_STAFF_SINGLE_SPEND_PARTNER_SUCCESS,
  GET_STAFF_SINGLE_SPEND_PARTNER_FAIL,
} from "../../../../redux/types";

export const getSpendPartnerById = (spendPartnerUserId) => async (dispatch) => {
  dispatch({ type: GET_STAFF_SINGLE_SPEND_PARTNER_LOADING });
  try {
    const { data, error } = await spendPartners.getSpendPartnerById({
      id: spendPartnerUserId,
    });
    if (data) {
      dispatch({ type: GET_STAFF_SINGLE_SPEND_PARTNER_SUCCESS, payload: data });
    } else {
      dispatch({
        type: GET_STAFF_SINGLE_SPEND_PARTNER_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_STAFF_SINGLE_SPEND_PARTNER_FAIL,
      payload: error,
    });
  }
};
