import moment from "moment";

const dateFormatter = (rawDate) => {
  if (moment(rawDate, "DD/MM/YYYY").isValid()) {
    return moment(rawDate, "DD/MM/YYYY").format("DD/MM/YYYY");
  }

  if (moment(rawDate).isValid()) {
    return moment(rawDate).format("DD/MM/YYYY");
  }
  return rawDate || "N/A";
};

export default dateFormatter;
