import React from "react";

import useStyle from "./style";
import Toggle from "../../Toggle";

import ZendeskButton from "../../ZendeskButton";

const Center = ({ children, ...props }) => {
  const classes = useStyle(props);
  const { hideLangToggle } = props;
  const showZendesk = typeof window?.zE === "function";

  return (
    <main className={classes.Wrapper}>
      {!hideLangToggle && <Toggle />}

      <div className={classes.ContentWrapper}>{children}</div>
      {showZendesk && <ZendeskButton />}
    </main>
  );
};
export default Center;
