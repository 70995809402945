import { styled } from "@material-ui/core/styles";

export const Wrapper = styled("section")(({ theme, success }) => ({
  width: "100%",
  flex: "1",
  flexDirection: "column",
  maxWidth: success && "400px",
  alignItems: success ? "center" : "flex-start",
  margin: success && "0 auto",
  marginBottom: theme.spacing(9),
}));

export const Header = styled("header")(({ theme }) => ({
  marginBottom: theme.spacing(9),
}));

export const ImageWrapper = styled("span")(({ theme }) => ({
  display: "none",

  [theme.breakpoints.tablet]: {
    display: "block",
    position: "absolute",
    right: theme.spacing(5),
    top: theme.spacing(8),
    zIndex: -1,
  },
}));

export const Questions = styled("section")(({ theme }) => ({
  width: "100%",
  flex: "1",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",

  paddingTop: theme.spacing(10),

  paddingRight: theme.spacing(5),
  paddingLeft: theme.spacing(5),
  maxWidth: "400px",
  margin: "0 auto",
}));

export const SideContent = styled("div")({
  position: "absolute",
  left: "-45vw",
  top: "25%",
  zIndex: "999",
  maxWidth: "40vw",
});

export const CreditWrapper = styled("span")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  marginBottom: theme.spacing(8),
}));

export const Emphasis = styled("span")(() => ({
  fontWeight: "bold",
}));

export const ButtonsWrapper = styled("div")(({ theme }) => ({
  [theme.breakpoints.tablet]: {
    display: "flex",
  },
}));

export const Error = styled("p")(({ theme }) => ({
  width: "100%",
  color: theme.colors.pink,
  fontSize: "0.875rem",
  textAlign: "left",
  fontWeight: "normal",
  marginBottom: theme.spacing(2),
}));
