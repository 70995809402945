import {
  // Tempo Top Stats
  GET_TEMPO_TOP_STATS_LOADING_START,
  GET_TEMPO_TOP_STATS_LOADING_END,
  GET_TEMPO_TOP_STATS_SUCCESS,
  GET_TEMPO_TOP_STATS_FAIL,
} from "../../../redux/types";

const initState = {
  getTopStatsLoading: false,
  error: null,
  topStats: [],
};

const TempoDashboardReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    // GET SPEND ACTIVITIES
    case GET_TEMPO_TOP_STATS_LOADING_START:
      return {
        ...state,
        getTopStatsLoading: true,
      };
    case GET_TEMPO_TOP_STATS_LOADING_END:
      return {
        ...state,
        getTopStatsLoading: false,
      };
    case GET_TEMPO_TOP_STATS_SUCCESS:
      return {
        ...state,
        topStats: payload,
      };
    case GET_TEMPO_TOP_STATS_FAIL:
      return {
        error: payload,
      };

    default:
      return state;
  }
};

export default TempoDashboardReducer;
