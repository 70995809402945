import axios from "axios";
import handleError from "./handle-errors";

const TRANSACTIONS_BASE = "/transactions";

const getUserTransactions = async ({
  limit,
  offset = 0,
  category,
  options,
  earnGroupId,
  spendVenueId,
  startDate,
  endDate,
  query,
} = {}) => {
  try {
    const { data } = await axios.get(TRANSACTIONS_BASE, {
      params: {
        category,
        earnid: earnGroupId,
        spendVenueId,
        limit,
        offset,
        startDate,
        endDate,
        query,
      },
    });

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const createTransaction = async ({
  tc,
  memberId,
  activityCategory,
  activityCategoryName,
  activityName,
  type,
  toUserId,
  message,
  startDate,
  endDate,
  programmeId,
  toRole,
  options,
} = {}) => {
  try {
    const { data } = await axios.post(TRANSACTIONS_BASE, {
      tc,
      memberId,
      activityCategory,
      activityCategoryName,
      activityName,
      toUserId,
      message,
      type,
      startDate,
      endDate,
      programmeId,
      toRole,
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getEarngroupMemberTransactions = async ({ memberId, options } = {}) => {
  try {
    const { data } = await axios.get(TRANSACTIONS_BASE, {
      params: { category: "memberhistory", memberId },
    });

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getTransactionById = async (transactionId, type, options) => {
  try {
    const { data } = await axios.get(`${TRANSACTIONS_BASE}/${transactionId}`, {
      params: { type },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getRecentEarnGroupTransactions = async ({ options }) => {
  try {
    const { data } = await axios.get(
      `${TRANSACTIONS_BASE}/recent-transactions`,
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export {
  getUserTransactions,
  createTransaction,
  getEarngroupMemberTransactions,
  getTransactionById,
  getRecentEarnGroupTransactions,
};
