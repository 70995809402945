import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import * as S from "./style";
import Image from "../../../../components/Image";
import * as T from "../../../../components/Typograpy";
import Stats from "../../../../components/Stats";
import Table from "../../../../components/FilterTable";
import FilterByDate from "../../../../components/FilterByDate";
import Search from "../../../../components/Search";

import Modal from "../../../../components/Modal";
import DownloadFile from "../../../../components/DownloadFile";
import Button from "../../../../components/Button";
import {
  dateCol,
  fromCol,
  toCol,
  typeCol,
} from "../../../../components/Table/config/reusableColumns";

import { getInsights } from "../insights.action";
import { getTransactions } from "./actions";

import { TEMPO } from "../../../../constants/navRoutes";
import t from "../../../../utils/helpers/translator";

const Transactions = ({
  issuedTc,
  earnedTc,
  spentTc,
  giftedTc,
  transactions,
  getInsightsAction,
  getTransactionsAction,
  insightsLoading,
  language,
}) => {
  const history = useHistory();
  const [dates, setDates] = useState({ startDate: null, endDate: null });
  const [filterDates, setFilterDates] = useState({
    startDate: null,
    endDate: null,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");

  const startDateValue = dates?.startDate?.valueOf();
  const endDateValue = dates?.endDate?.valueOf();
  const tempoTransactions = {
    columns: [
      dateCol(t("date", language)),
      fromCol,
      toCol,
      typeCol(t("type", language)),
      {
        title: "Amount",
        field: "credits",
        type: "numeric",
      },
    ],
  };
  useEffect(() => {
    getInsightsAction({ category: "transactions" });
  }, [getInsightsAction]);

  useEffect(() => {
    if (!isOpen) {
      getTransactionsAction({
        query: search,
        startDate: dates.startDate,
        endDate: dates.endDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateValue, endDateValue]);

  const clickRow = (event, rowData) =>
    history.push(TEMPO.TRANSACTIONS_SINGLE.replace(":id", rowData.id));

  const handleSearch = (e) => {
    e.preventDefault();

    getTransactionsAction({
      query: search,
      startDate: dates.startDate,
      endDate: dates.endDate,
    });
  };

  const statsNum = [issuedTc, earnedTc, spentTc, giftedTc];
  const statsText = [
    "Time Credits issued to earn groups",
    "Time Credits earned",
    "Time Credits Used",
    "Time Credits Gifted",
  ];
  const bottomMessage = [false, false, false, false];

  const rightMessage = [false, false, false, false];

  return (
    <>
      <S.Container>
        <div>
          <S.Head>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <T.H40Caps color="primary" style={{ margin: 0 }}>
                Tempo Transactions
              </T.H40Caps>
              <div style={{ display: "flex" }}>
                {statsText.map((e, i) => (
                  <div key={e} style={{ marginTop: 50, marginRight: 15 }}>
                    <Stats
                      size="s"
                      statNum={statsNum[i]}
                      statNumColor="pink"
                      statText={e}
                      isLoading={insightsLoading}
                      rightMessage={rightMessage[i]}
                      bottomMessage={bottomMessage[i]}
                    />
                  </div>
                ))}
              </div>
            </div>
            <S.ImageWrapper>
              <Image image="cinema" alt="cinema" height={210} width={220} />
            </S.ImageWrapper>
          </S.Head>
          <S.TableContainer>
            <S.BtnContainer>
              <Button outline onClick={() => setIsOpen(true)}>
                Export Data
              </Button>
            </S.BtnContainer>
            <S.FilterContainer>
              <T.Body16B color="midnight" m="8">
                Filter by date
              </T.Body16B>
              <FilterByDate dates={dates} setDates={setDates} />
              <T.Body16B color="midnight" m="8">
                Search
              </T.Body16B>
              <S.SearchWrapper>
                <Search
                  handleSearch={handleSearch}
                  search={search}
                  setSearch={setSearch}
                  loading={transactions.loading}
                />
              </S.SearchWrapper>
            </S.FilterContainer>
            {transactions.loading && (
              <Table
                color="blue"
                data={transactions.data}
                columns={tempoTransactions.columns}
                isLoading
                limit={10}
                hideSearch
              />
            )}
            {!transactions.loading && (
              <Table
                dates={dates}
                setDates={setDates}
                color="blue"
                data={transactions.data}
                columns={tempoTransactions.columns}
                onRowClick={clickRow}
                limit={10}
                hideSearch
              />
            )}
          </S.TableContainer>
        </div>
      </S.Container>
      <Modal
        modalOpen={isOpen}
        handleCloseModal={() => setIsOpen(false)}
        onCancel={() => {
          setIsOpen(false);
        }}
        custom
        width="690px"
      >
        <S.ModalContent>
          <T.H20 color="blue" mb={20}>
            Export data{" "}
          </T.H20>

          <T.Body16B color="midnight" m="8">
            Filter by transaction date
          </T.Body16B>
          <FilterByDate dates={filterDates} setDates={setFilterDates} />
          <DownloadFile
            url={`/csv/transactions?startDate=${filterDates?.startDate?.format(
              "YYYY-MM-DD",
            )}&endDate=${filterDates?.endDate?.format("YYYY-MM-DD")}`}
          />
        </S.ModalContent>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state.staffInsights,
  transactions: state.staffTransactions,
  insightsLoading: state.staffInsights.loading,
  language: state.auth.language,
});

const mapActionsToProps = {
  getInsightsAction: getInsights,
  getTransactionsAction: getTransactions,
};

export default connect(mapStateToProps, mapActionsToProps)(Transactions);
