import store from "../redux/store";
import translate from "../utils/helpers/translator";
/**
 * change error plain object to nested object
 * @param {*} error error object
 */
const handleValidationError = (error, info) => {
  const state = store.getState();
  const lang = state.auth.language;

  const newErrors = {};

  if (error.inner)
    error.inner.forEach(({ path, message: errorMessage }) => {
      if (path && path.includes(".")) {
        const segments = path.split(".");
        const [parent, childrenPath] = segments;
        newErrors[path] = newErrors[path] || {};

        newErrors[parent] = {
          ...newErrors[parent],
          [childrenPath]: translate(errorMessage, lang),
        };
      } else {
        newErrors[path] = translate(errorMessage, lang);
      }
    });

  // eslint-disable-next-line no-param-reassign
  error.inner = newErrors;
  // eslint-disable-next-line no-param-reassign
  error.info = info;
  return error;
};

const validate = (schema, data, { abortEarly = false, ...options } = {}) => {
  try {
    const validData = schema.validateSync(data, {
      abortEarly,
      ...options,
    });
    return { data: validData };
  } catch (error) {
    const errorData = handleValidationError(error, options.info);
    throw errorData;
  }
};

export default validate;
