import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import * as S from "./style";
import Image from "../../../../components/Image";
import * as T from "../../../../components/Typograpy";
import Button, { Regular } from "../../../../components/Button";
import { Col, Row } from "../../../../components/Grid";
import { InputField } from "../../../../components/Inputs";
import PricePackages from "../../../../components/PricePackage";
import Loading from "../../../../components/Loading";
import Notification from "../../../../components/Notification";
import Modal from "../../../../components/WarningMessage";

import * as actions from "../../../../redux/actions/paidPackages";

import validate from "../../../../validation/schemas/manage-packages";
import t from "../../../../utils/helpers/translator";

const ManagePackages = ({
  packages: prevPackages,
  getPackagesError,
  getPackagesLoaded,
  activePrice,
  getPriceError,
  getPriceLoading,
  getPriceLoaded,
  getPaidPackages,
  getTcPrice,
  updatedSuccessfully,
  updatePackages,
  getPackagesLoading,
  updateError,
  updateLoading,
}) => {
  const [price, setPrice] = useState(null);
  const [vat, setVat] = useState(null);
  const [minimumTc, setMinimumTc] = useState(null);
  const [maximumTc, setMaximumTc] = useState(null);
  const [packages, setPackages] = useState([]);
  const [errors, setErrors] = useState({});
  const [showNotification, setShowNotification] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const submitAttempt = useRef();

  useEffect(() => {
    getPaidPackages();
    getTcPrice();
  }, []);

  useEffect(() => {
    setPackages(prevPackages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPackagesLoaded]);

  useEffect(() => {
    setPrice(activePrice.price);
    setVat(activePrice.vat);
    setMinimumTc(activePrice.minimumTc);
    setMaximumTc(activePrice.maximumTc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPriceLoaded]);

  const validateForm = () => {
    try {
      validate({
        price,
        maximumTc,
        minimumTc,
        packages: packages.map((e) => ({ ...e, maximumTc, minimumTc })),
      });
      setErrors({});
      return true;
    } catch (err) {
      if (err.name === "ValidationError") {
        setErrors(err.inner);
      }
      return false;
    }
  };

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price, minimumTc, maximumTc, packages]);

  useEffect(() => {
    if (updatedSuccessfully) {
      setShowNotification(true);
    }
  }, [updatedSuccessfully]);

  const totalVat = price
    ? Math.round(Number(price) * Number(vat) * 1000) / 1000
    : 0;
  const totalAmount = price
    ? Math.round((Number(price) + totalVat) * 1000) / 1000
    : 0;

  const minPrice = minimumTc
    ? Math.round(Number(minimumTc) * totalAmount * 1000) / 1000
    : 0;
  const maxPrice = maximumTc
    ? Math.round(Number(maximumTc) * totalAmount * 1000) / 1000
    : 0;

  const submit = async () => {
    await updatePackages({
      vat,
      price,
      maximumTc,
      minimumTc,
      packages,
    });
  };

  const handleClick = async () => {
    submitAttempt.current = true;

    const valid = validateForm();

    if (!valid) return;
    setOpenModal(true);
  };

  if (getPriceLoading || getPackagesLoading) {
    return <Loading size={28} color="cyan" center fullPage />;
  }

  return (
    <>
      <Row jc="space-between">
        <Col w={[4, 4, 4]}>
          <T.H40Caps color="primary" style={{ margin: 0 }}>
            manage pricing
          </T.H40Caps>

          <S.CsvExport href="/api/csv/packages">
            <Button outline>Export change log</Button>
          </S.CsvExport>

          <T.H24 color="primary" mt={44}>
            Time Credit Unit Cost
          </T.H24>
          <T.Body16R color="gray3" mt={10} mb={30}>
            Manage the price per Time Credit purchased
          </T.Body16R>
          <InputField
            label="Time Credit Price"
            placeholder="Ex: £0.20"
            value={price}
            setValue={(e) => setPrice(Number(e.target.value))}
            type="number"
            error={t(errors.price, "english")}
            onBlur={() => {
              setPrice(Number(price).toFixed(2));
            }}
          />
          <S.TotalVatContainer>
            <T.Body16B color="gray3" style={{ margin: 0 }}>
              Total VAT 20%
            </T.Body16B>
            <T.Body16R>£{totalVat}</T.Body16R>
          </S.TotalVatContainer>
          <S.TotalAmountContainer>
            <T.Body20B color="primary" style={{ margin: 0 }}>
              Total Amount
            </T.Body20B>
            <T.Body20 color="gray2">£{totalAmount.toFixed(2)}</T.Body20>
          </S.TotalAmountContainer>
        </Col>
        <Col w={[4, 4, 4]}>
          <S.ImageWrapper>
            <Image image="gallery" alt="gallery" height={210} width={220} />
          </S.ImageWrapper>
        </Col>
      </Row>
      <Row mt="8">
        <Col w={[4, 12, 12]}>
          <T.H24 color="primary">
            Minimum and Maximum Time Credits per purchase
          </T.H24>
        </Col>
      </Row>
      <Row mt="4">
        <Col w={[4, 4, 4]}>
          <InputField
            label="Minimum Time Credits"
            placeholder="ex. 10"
            value={minimumTc}
            setValue={(e) => setMinimumTc(e.target.value)}
            type="number"
            error={errors.minimumTc}
          />
          <S.TotalVatContainer>
            <T.Body16B color="gray3" style={{ margin: 0 }}>
              Minimum cost incl. VAT
            </T.Body16B>
            <T.Body16R>£{minPrice.toFixed(2)}</T.Body16R>
          </S.TotalVatContainer>
        </Col>
        <Col w={[4, 4, 4]}>
          <InputField
            label="Maximum Time Credits"
            placeholder="ex. 2000"
            value={maximumTc}
            setValue={(e) => setMaximumTc(e.target.value)}
            type="number"
            error={errors.maximumTc}
          />
          <S.TotalVatContainer>
            <T.Body16B color="gray3" style={{ margin: 0 }}>
              Maximum cost incl. VAT
            </T.Body16B>
            <T.Body16R>£{maxPrice.toFixed(2)}</T.Body16R>
          </S.TotalVatContainer>
        </Col>
      </Row>
      <Row mt="10">
        <Col w={[4, 12, 12]}>
          <T.H24 color="primary">Manage Packages</T.H24>
        </Col>
      </Row>

      <PricePackages
        packages={packages}
        setPackages={setPackages}
        tcPrice={price}
        vat={vat}
        errors={errors}
      />

      <Row mt={4}>
        <Col w={[4, 6, 4]}>
          {updateError?.message && <S.Error>{updateError.message}</S.Error>}
          <Regular
            primary
            bgColor="blue"
            onClick={handleClick}
            loading={updateLoading}
          >
            Save Changes
          </Regular>
        </Col>
      </Row>
      <Notification
        open={showNotification}
        setOpen={setShowNotification}
        message="PRICING UPDATED"
        duration={1500}
      />
      <Modal
        open={openModal}
        setOpen={setOpenModal}
        handleClick={submit}
        buttonText="Confirm changes"
        title="Are you sure?"
      >
        <T.Body14R
          style={{
            paddingRight: 50,
            marginTop: -34,
          }}
        >
          You&apos;re about to change what people pay for Time Credits. Are you
          sure you want to do this?
        </T.Body14R>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ paidPackages }) => ({
  packages: paidPackages.packages,
  getPackagesError: paidPackages.getPackagesError,
  getPackagesLoaded: paidPackages.getPackagesLoaded,
  getPackagesLoading: paidPackages.getPackagesLoading,
  activePrice: paidPackages.activePrice,
  getPriceError: paidPackages.getPriceError,
  getPriceLoading: paidPackages.getPriceLoading,
  getPriceLoaded: paidPackages.getPriceLoaded,
  updateError: paidPackages.updateError,
  updateLoading: paidPackages.updateLoading,
  updatedSuccessfully: paidPackages.updatedSuccessfully,
});

const mapActionsToProps = {
  updatePackages: actions.updatePackages,
  getPaidPackages: actions.getPaidPackages,
  getTcPrice: actions.getTcPrice,
};

export default connect(mapStateToProps, mapActionsToProps)(ManagePackages);
