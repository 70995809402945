import React, { useState } from "react";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import Icon from "../Icon";
import Popper from "../Popper";
import * as S from "./style";

const PopQuestion = ({ content, m, iconPosition, popperProps, children }) => {
  const [popperOpen, togglePopper] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    togglePopper(!popperOpen);
  };

  const handleClickAway = () => {
    togglePopper(false);
  };

  return (
    <S.PopContainer position={iconPosition}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <S.PopBtn type="button" onClick={handleClick} m={m}>
            <Icon
              icon="questionMark"
              width="21px"
              height="21px"
              color="blue"
              style={{ cursor: "pointer" }}
            />
          </S.PopBtn>
          <Popper
            placement={(popperProps && popperProps.placement) || "bottom"}
            popperProps={popperProps}
            open={popperOpen}
            content={content}
            type="small"
            icon="info"
            anchorEl={anchorEl}
            marginThreshold={30}
          >
            {children}
          </Popper>
        </div>
      </ClickAwayListener>
    </S.PopContainer>
  );
};

export default PopQuestion;
