import React, { useState } from "react";
import { connect } from "react-redux";
import Wrapper from "./style";
import * as T from "../Typograpy";
import Icon from "../Icon";
import Popper from "../Popper";

import t from "../../utils/helpers/translator";

const Copy = ({ showText, color, strToCopy, lang }) => {
  const [popperOpen, togglePopper] = useState(false);
  const [popperMsg, setPopperMsg] = useState("");
  const [popperIcon, setPopperIcon] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const closePopper = () => {
    togglePopper(false);
  };

  const openPopper = (event) => {
    setAnchorEl(event.currentTarget);
    togglePopper(true);

    setTimeout(closePopper, 500);
  };

  const copyTextToClipboard = (event) => {
    try {
      const el = document.createElement("textarea");
      el.value = strToCopy;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      setPopperMsg(t("successfullyCopied", lang));
      setPopperIcon("tick");
      openPopper(event);
    } catch (error) {
      setPopperMsg(t("errorCoying", lang));
      setPopperIcon("alertTriangle");
      openPopper(event, false);
    }
  };

  return (
    <Wrapper onClick={(event) => copyTextToClipboard(event)}>
      {showText && (
        <T.H14Caps color={color || "midnight"}>{t("copy", lang)}</T.H14Caps>
      )}
      <Icon
        icon="copy"
        color={color || "midnight"}
        width="24px"
        height="24px"
        margin={showText && "0 0 0 5px"}
      />
      <Popper
        open={popperOpen}
        content={popperMsg}
        icon={popperIcon}
        type="small"
        anchorEl={anchorEl}
      >
        <T.Body16R>{popperMsg}</T.Body16R>
      </Popper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  lang: state.auth.language,
});

export default connect(mapStateToProps)(Copy);
