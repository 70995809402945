import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 18,
    height: 18,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: theme.colors.aliceBlue,
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: theme.colors.midnight,
    padding: "1px",
    width: 18,
    height: 18,
    "&:before": {
      border: `2px solid ${theme.colors.white}`,
      borderRadius: "50%",
      backgroundColor: theme.colors.midnight,
      display: "block",
      width: 14,
      height: 14,
      content: '""',
      top: "50%",
      left: "50%",
      position: "absolute",
      transform: "translate(-50%, -50%)",
    },
    "input:hover ~ &": {
      backgroundColor: theme.colors.matisse,
    },
  },
}));

export default useStyles;
