import { styled } from "@material-ui/core/styles";

export const Wrapper = styled("div")(() => ({
  //   display: "flex",
  //   flexDirection: "column",
  //   justifyContent: "space-between",
  //   alignItems: "center",
  //   width: "100%",
}));

export const HeadlineContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  paddingTop: theme.spacing(2),

  [theme.breakpoints.tablet]: {
    paddingTop: 0,
  },
}));
