/* eslint-disable import/prefer-default-export */
import {
  GET_LOGGED_IN_USER_INFO_REQUEST,
  GET_LOGGED_IN_USER_INFO_SUCESSS,
  GET_LOGGED_IN_USER_INFO_FAIL,
  LOGIN_SUCESSS,
  LOGIN_FAIL,
  LOGIN_LOADING_START,
  LOGIN_LOADING_END,
  TEMP_LOGIN_SUCESSS,
  TEMP_LOGIN_FAIL,
  TEMP_LOGIN_LOADING_START,
  TEMP_LOGIN_LOADING_END,
  SESSION_REFRESH_SUCCESS,
  SESSION_REFRESH_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  SIGN_UP_POST_LOADING_START,
  SIGN_UP_POST_LOADING_END,
  SIGN_UP_POST_SUCCESS,
  SIGN_UP_POST_FAIL,
  GET_EARN_GROUP_INFO_BY_TOKEN_SUCCESS,
  GET_EARN_GROUP_INFO_BY_TOKEN_FAIL,
  UPDATE_MEMBER_PROFILE_LOADING_START,
  UPDATE_MEMBER_PROFILE_LOADING_END,
  UPDATE_MEMBER_PROFILE_SUCCESS,
  UPDATE_MEMBER_PROFILE_FAIL,
  UPDATE_LANGUAGE_LOADING_START,
  UPDATE_LANGUAGE_SUCCESS,
  UPDATE_LANGUAGE_FAIL,
  RESET_SIGNUP_UP_ERROR,
  CHANGE_PASSWORD_LOADING_START,
  CHANGE_PASSWORD_LOADING_END,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
} from "../types";
// import history from "../../history";
import { users, auth, earnGroups, members } from "../../api-calls";
import { navRoutes, roles } from "../../constants";

import { GENERAL } from "../../constants/navRoutes";
import { redirectAfterLogin, redirectAfterSignup } from "../utils";

export const getLoggedInUserInfo = (history) => async (dispatch) => {
  dispatch({ type: GET_LOGGED_IN_USER_INFO_REQUEST });
  const { data, error } = await users.getLoggedInUserInfo();
  if (error) {
    return dispatch({
      type: GET_LOGGED_IN_USER_INFO_FAIL,
      payload: error,
    });
  }

  localStorage.setItem("language", data.language || "english");

  dispatch({
    type: GET_LOGGED_IN_USER_INFO_SUCESSS,
    payload: data,
  });

  if (!data.emailVerified) {
    if (!history?.location?.pathname.includes("activate-account")) {
      return history.push(navRoutes.GENERAL.ACTIVATE_ACCOUNT_EMAIL_SENT);
    }
    return;
  }

  if (data.role === roles.SPEND_PARTNER) {
    if (data.isVerified && !data.spendPartner.acceptedTerms) {
      return history.push(navRoutes.GENERAL.ACCEPT_TERMS);
    }
  }

  if (data.role === roles.SPEND_VENUE) {
    if (data.isVerified && !data.acceptedTerms) {
      return history.push(navRoutes.GENERAL.ACCEPT_TERMS);
    }
  }
};

export const login =
  ({
    email,
    password,
    earnGroupUserId,
    giftToken,
    programmeToken,
    administratorToken,
    history,
    reToken,
    programmeManagerToken,
    activateAccountToken,
    addToTk,
  }) =>
  async (dispatch) => {
    dispatch({
      type: LOGIN_LOADING_START,
    });
    const { data, error } = await auth.login({
      email,
      password,
      earnGroupUserId,
      giftToken,
      programmeToken,
      reToken,
      administratorToken,
      programmeManagerToken,
      addToTk,
    });
    dispatch({
      type: LOGIN_LOADING_END,
    });

    if (error) {
      return dispatch({
        type: LOGIN_FAIL,
        payload: error,
      });
    }

    dispatch({
      type: LOGIN_SUCESSS,
      payload: data,
    });

    if (!data.emailVerified && activateAccountToken) {
      return history.push(
        navRoutes.GENERAL.ACTIVATE_ACCOUNT_CHECK_TOKEN.replace(
          ":token",
          activateAccountToken,
        ),
      );
    }

    if (data.redirectToTk) {
      let url = data.redirectToTk;

      if (!/^https?:\/\//i.test(url)) {
        url = `http://${url}`;
      }

      window.location.href = url;
      return;
    }

    if (data.multipleUsers && !data.selectedActiveUser) {
      return history.push(navRoutes.GENERAL.SELECT_USER);
    }

    redirectAfterLogin({ data, history });
    return null;
  };

export const refreshSession = () => async (dispatch) => {
  const { data, error } = await auth.refreshSession({});

  if (error) {
    return dispatch({
      type: SESSION_REFRESH_FAIL,
      payload: error,
    });
  } else {
    return dispatch({
      type: SESSION_REFRESH_SUCCESS,
      payload: data,
    });
  }
};

export const temporaryLogin =
  ({ id, role, history }) =>
  async (dispatch) => {
    dispatch({
      type: TEMP_LOGIN_LOADING_START,
    });
    const { data, error } = await auth.temporaryLogin({ id, role });
    dispatch({
      type: TEMP_LOGIN_LOADING_END,
    });

    if (error) {
      return dispatch({
        type: TEMP_LOGIN_FAIL,
        payload: error,
      });
    }

    dispatch({
      type: TEMP_LOGIN_SUCESSS,
      payload: { ...data, tempLogin: true },
    });

    switch (data.role) {
      case roles.EARN_GROUP:
        history.push(navRoutes.EARN_GROUP.ACCEPT_TC);
        break;

      case roles.SPEND_VENUE:
        history.push(navRoutes.SPEND_VENUE.ACCEPT_TC);
        break;

      default:
    }
    return null;
  };

export const logout =
  ({ history, noRedirect }) =>
  async (dispatch) => {
    const { error } = await auth.logout();
    const actualLanguage = localStorage.getItem("language");

    if (error) {
      dispatch({
        type: LOGOUT_FAIL,
        payload: error,
      });
    }

    dispatch({
      type: LOGOUT_SUCCESS,
      payload: { actualLanguage },
    });

    if (!noRedirect) {
      history.push(navRoutes.GENERAL.LOG_IN);
    }
  };

export const signup = (formData, history) => async (dispatch) => {
  dispatch({
    type: SIGN_UP_POST_LOADING_START,
  });

  const { data, error } = await auth.signup({ formData });
  dispatch({
    type: SIGN_UP_POST_LOADING_END,
  });
  if (data) {
    if (data.redirectToTk) {
      window.location = data.redirectToTk;
      return;
    }

    dispatch({
      type: SIGN_UP_POST_SUCCESS,
      payload: data,
    });

    redirectAfterSignup({
      role: data.role,
      emailVerified: data.emailVerified,
      giftToken: data.giftToken,
      history,
      userId: data.id, // just for logging
      credId: data.credentialId, // just for logging
    });
  } else if (error) {
    dispatch({
      type: SIGN_UP_POST_FAIL,
      payload: error,
    });
  }
};

export const updateMemberProfile = (formData, history) => async (dispatch) => {
  dispatch({
    type: UPDATE_MEMBER_PROFILE_LOADING_START,
  });

  const { data, error } = await members.updateMemberProfile({ formData });
  dispatch({
    type: UPDATE_MEMBER_PROFILE_LOADING_END,
  });
  if (data) {
    dispatch({
      type: UPDATE_MEMBER_PROFILE_SUCCESS,
      payload: data,
    });
    history.push(navRoutes.GENERAL.WELCOME);
  } else if (error) {
    dispatch({
      type: UPDATE_MEMBER_PROFILE_FAIL,
      payload: error,
    });
  }
};

export const changePassword =
  ({ oldPassword, newPassword, credentialId }) =>
  async (dispatch) => {
    dispatch({
      type: CHANGE_PASSWORD_LOADING_START,
    });

    const { error } = await auth.changePassword({
      oldPassword,
      newPassword,
      credentialId,
    });
    dispatch({
      type: CHANGE_PASSWORD_LOADING_END,
    });
    if (error) {
      dispatch({
        type: CHANGE_PASSWORD_FAIL,
        payload: error,
      });
      return { error };
    } else {
      dispatch({
        type: CHANGE_PASSWORD_SUCCESS,
      });
      return {};
    }
  };

export const getEarnGroupInfo =
  ({ inviteToken, invitedUserRole = "member", language, history }) =>
  async (dispatch) => {
    try {
      const { data, error } = await earnGroups.getEarnGroups({
        inviteToken,
        invitedUserRole,
        language,
        isPublic: true,
      });

      if (data && data[0]) {
        dispatch({
          type: GET_EARN_GROUP_INFO_BY_TOKEN_SUCCESS,
          payload: data[0],
        });
      } else if (error) {
        if (error.httpStatusCode === 404) {
          history.push(GENERAL.NOT_FOUND);
        }
        dispatch({
          type: GET_EARN_GROUP_INFO_BY_TOKEN_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_EARN_GROUP_INFO_BY_TOKEN_FAIL,
        payload: error,
      });
    }
  };

export const toggleLanguage = (language) => async (dispatch, getState) => {
  const { isLoggedIn, role } = getState().auth;

  if (isLoggedIn) {
    dispatch({
      type: UPDATE_LANGUAGE_LOADING_START,
    });
    if (role.startsWith("tempo") || role === "programmeManager") {
      localStorage.setItem("language", "english");
      dispatch({
        type: UPDATE_LANGUAGE_SUCCESS,
        payload: { language: "english", viewLanguage: language },
      });
    } else {
      const { error } = await users.updateUserLanguage({ language });
      if (error) {
        dispatch({
          type: UPDATE_LANGUAGE_FAIL,
          payload: error,
        });
      }
      localStorage.setItem("language", language);

      dispatch({
        type: UPDATE_LANGUAGE_SUCCESS,
        payload: { language, viewLanguage: null },
      });
    }
  } else {
    localStorage.setItem("language", language);

    dispatch({
      type: UPDATE_LANGUAGE_SUCCESS,
      payload: { language, viewLanguage: null },
    });
  }
};

export const resetSignupError = () => ({
  type: RESET_SIGNUP_UP_ERROR,
});
