import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getTempoTopStats } from "./actions";
import * as S from "./style";
// components
import * as T from "../../../components/Typograpy";
import Image from "../../../components/Image";
import Stats from "../../../components/Stats";
import Loading from "../../../components/Loading";
import { TEMPO } from "../../../constants/navRoutes";
import FilterByDate from "../../../components/FilterByDate";
import Dropdown from "../../../components/Inputs/Dropdown";

import { getBasicProgrammes } from "../../../redux/actions/programmes";

import { regionsDropDownEnglish } from "../../../constants/dropdDownData";
import { tcTags } from "./statsData";
import DownloadFile from "../../../components/DownloadFile";

const sortedUserStats = [
  { name: "members", value: "members" },
  { name: "earngroups", value: "earng roups" },
  { name: "spendvenues", value: "spend venues" },
  { name: "programmes", value: "programmes" },
];

const sortedVenuesStats = [
  { name: "venuesWithLiveOffers", value: "Venues with Live Offers" },
  { name: "liveOffers", value: "Live OFFERS" },
  { name: "draftOffers", value: "DRAFT OFFERS" },
  { name: "soldOutOffers", value: "SOLD OUT OFFERS" },
];

const decideLink = (key) => {
  const linksObj = {
    members: TEMPO.MEMBERS,
    earngroups: TEMPO.EARN_GROUPS,
    spendvenues: TEMPO.SPEND_VENUES,
    programmes: TEMPO.PROGRAMMES,

    tcTotalIssued: TEMPO.TRANSACTIONS,
    earn: TEMPO.TRANSACTIONS,
    spend: TEMPO.TRANSACTIONS,
    gift: TEMPO.TRANSACTIONS,

    venuesWithLiveOffers: TEMPO.SPEND_VENUES_WITH_LIVE_OFFERS,
    liveOffers: TEMPO.OFFERS,
    draftOffers: TEMPO.OFFERS,
    soldOutOffers: TEMPO.OFFERS,
  };

  return linksObj[key];
};

const TempoDashboard = (props) => {
  const [dates, setDates] = useState({ startDate: null, endDate: null });
  const [selectedProgramme, setSelectedProgramme] = useState();

  const [region, setRegion] = useState(null);
  const [country, setCountry] = useState(null);
  const {
    userInfo,
    getTempoTopStatsAction,
    topStats,
    topStatsLoading,
    getBasicProgrammes: getBasicProgrammesAction,
  } = props;

  const onRegionChange = ({ selected }) => {
    setSelectedProgramme(null);
    setRegion(selected?.value);
    setCountry(selected?.country);
  };

  useEffect(() => {
    getTempoTopStatsAction();
    getBasicProgrammesAction();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const programmesList = props.programmes.map((e) => ({
    id: e.id,
    value: e.name,
    label: e.name,
  }));

  const users = topStats && topStats[0] && Object.entries(topStats[0]);
  const programmes = topStats && topStats[1] && Object.entries(topStats[1]);
  const tcSummaries = topStats && topStats[2] && Object.entries(topStats[2]);
  const totalTcIssued = topStats && topStats[3] && Object.entries(topStats[3]);
  const venueStats = topStats && topStats[4];

  const userStats = users && programmes && users.concat(programmes);

  const renderUserStats =
    users &&
    programmes &&
    sortedUserStats.map((el) => {
      const value = userStats.find((e) => e[0] === el.name);
      let text = "";
      if (value[0] === "spendvenues") {
        text = "venues";
      } else if (value[0] === "earngroups") {
        text = "earn groups";
      } else if (value[0] === "members") {
        text = "volunteers";
      } else {
        [text] = value;
      }

      return (
        <Link to={decideLink(el.name)} style={{ textDecoration: "none" }}>
          <Stats
            size="s"
            statNum={value[1] || "0"}
            statNumColor="blue"
            statText={text || "N/A"}
            statTextColor="darkPrimary"
          />
        </Link>
      );
    });

  const renderTcStats =
    tcSummaries &&
    totalTcIssued &&
    totalTcIssued.concat(tcSummaries).map((el) => {
      const key = el[0];
      const value = el[1];

      return (
        <Link to={decideLink(key)} style={{ textDecoration: "none" }}>
          <Stats
            size="s"
            statNum={value || "0"}
            statNumColor="pink"
            statText={tcTags[key]}
            statTextColor="midnight"
          />
        </Link>
      );
    });

  const renderVenuesStats =
    venueStats &&
    sortedVenuesStats.map((el) => {
      const value = venueStats[el.name];

      return (
        <Link to={decideLink(el.name)} style={{ textDecoration: "none" }}>
          <Stats
            size="s"
            statNum={value || "0"}
            statNumColor="purple"
            statText={el.value || "N/A"}
            statTextColor="midnight"
          />
        </Link>
      );
    });

  let exportText = "Export";
  if (selectedProgramme && dates?.endDate && dates?.startDate) {
    exportText = "Export EGs & Vols";
  } else if (region && dates?.endDate && dates?.startDate) {
    exportText = "Export EGs, Offers, & Venues";
  }
  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <T.H40 style={{ margin: "0" }} color="midnight">
          Welcome back, {userInfo.name}!
        </T.H40>
        <Image image="officeLarge" alt="Office" height={210} />
      </S.HeaderWrapper>
      <T.H24Caps
        style={{ marginLeft: "0", marginBottom: "20px" }}
        color="midnight"
      >
        ALL TEMPO DATA
      </T.H24Caps>
      <S.Section color="cyanLight">
        <S.Stats>
          {topStatsLoading || !topStats ? <Loading center /> : renderUserStats}
        </S.Stats>
      </S.Section>
      <S.Section color="pinkLight">
        <S.Stats>
          {topStatsLoading || !topStats ? (
            <Loading center color="pink" />
          ) : (
            renderTcStats
          )}
        </S.Stats>
      </S.Section>
      <S.Section color="gray8">
        <S.Stats>
          {topStatsLoading || !topStats ? (
            <Loading center color="pink" />
          ) : (
            renderVenuesStats
          )}
        </S.Stats>
      </S.Section>

      <T.Body18B
        style={{ marginLeft: "0", marginBottom: "20px", marginTop: "68px" }}
        color="midnight"
      >
        EXPORT RECENT ACTIVITY
      </T.Body18B>

      <S.FilterContainer>
        <S.FilterWrapper first marginRight>
          <T.Body16B color="midnight" m="8">
            Filter by date
          </T.Body16B>
          <FilterByDate dates={dates} setDates={setDates} />
        </S.FilterWrapper>
        <S.FilterWrapper marginRight>
          <Dropdown
            label="Filter by programme"
            placeholder="Select..."
            options={programmesList}
            setValue={({ selected }) => {
              setCountry(null);
              setRegion(null);
              setSelectedProgramme(selected);
            }}
            value={selectedProgramme}
            isClearable
          />
        </S.FilterWrapper>

        <S.FilterWrapper marginRight>
          <Dropdown
            label="Filter by region"
            placeholder="Select..."
            options={regionsDropDownEnglish}
            value={
              region ? { label: region, value: region, country } : undefined
            }
            setValue={onRegionChange}
            isClearable
          />
        </S.FilterWrapper>
      </S.FilterContainer>

      <S.FilterWrapper minWidth="300px" style={{ marginRight: "auto" }}>
        <DownloadFile
          text={exportText}
          disabled={!dates?.endDate || !dates?.startDate}
          url={`/csv/recent-activities?startDate=${dates?.startDate
            ?.startOf("day")
            .toISOString()}&endDate=${dates?.endDate
            ?.endOf("day")
            .toISOString()}&programmeId=${
            selectedProgramme?.id
          }&region=${region}&country=${country}`}
          width="100%"
        />
      </S.FilterWrapper>
    </S.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  userInfo: {
    name: state.auth.firstName,
  },
  topStatsLoading: state.tempoDashboard.getTopStatsLoading,
  topStats: state.tempoDashboard.topStats,
  programmes: state.programmes.data,
});

const mapActionToProps = {
  getTempoTopStatsAction: getTempoTopStats,
  getBasicProgrammes,
};

export default connect(mapStateToProps, mapActionToProps)(TempoDashboard);
