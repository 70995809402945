import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

// import { getEarnGroups } from "./actions";

import * as S from "./style";
import * as T from "../../../components/Typograpy";
import Table from "../../../components/Table";
import TCBalance from "../../../components/TCBalance";
import GoBack from "../../../components/GoBack";
import { Regular } from "../../../components/Button";
import WarningMessage from "../../../components/WarningMessage";

import {
  programmeEarnGroupsColumns,
  programmeEarnGroupsColumnsOther,
} from "../../StaffRoutes/Staff/programmeEarnGroups/tableConfig";

import { PROGRAMME_MANAGER } from "../../../constants/navRoutes";

import * as actions from "../../StaffRoutes/Staff/SingleProgramme/actions";
import * as singleGroupActions from "../../StaffRoutes/Staff/SingleEarnGroup/actions";

const EarnGroups = ({
  getProgrammeEarnGroup,
  programmeEarnGroups,
  earnGroupsLoading,
  balance,
  programmeName,
  programmeId,
  updateProgrammeEarnGroup,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");

  const history = useHistory();

  useEffect(() => {
    getProgrammeEarnGroup({ programmeId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const issueCredits = (rowData) =>
    history.push(
      PROGRAMME_MANAGER.ISSUE_CREDITS_EARN_GROUP.replace(":userId", rowData.id),
    );

  const handleRemoveEarnGroup = () => {
    updateProgrammeEarnGroup({
      earnGroupId: selectedGroup.id,
      programmeId,
      status: "deactivated",
    });
  };

  const onRemove = (rowData) => {
    setSelectedGroup(rowData);
    setOpen(true);
  };

  return (
    <S.Container>
      <div>
        <S.Head>
          <div>
            <GoBack showText p="0 0 40px 0" />
            <T.H40 style={{ margin: "0" }} color="midnight">
              {programmeName}
            </T.H40>
            <T.H30Caps color="pink" mt={20}>
              Earn groups
            </T.H30Caps>
          </div>
          <TCBalance credits={balance} color="cyan" />
        </S.Head>
        <S.TableContainer>
          <S.BtnContainer>
            <Regular
              primary
              bgColor="blue"
              m="0 10px 10px 0"
              onClick={() =>
                history.push(
                  PROGRAMME_MANAGER.PROGRAMME_INVITE_GROUPS.replace(
                    ":id",
                    programmeId,
                  ),
                )
              }
              customWidth="fit-content"
              style={{ padding: "0 25px" }}
            >
              Add Earn Group
            </Regular>
            <S.CsvExport
              href={`/api/csv/programmes/${programmeId}?type=earn-groups`}
            >
              <Regular outline textColor="blue" mr="0" light>
                Export Data
              </Regular>
            </S.CsvExport>
          </S.BtnContainer>

          <Table
            isLoading={earnGroupsLoading}
            tabs
            color="blue"
            tabbedData={[
              {
                tabText: "key info",
                data: programmeEarnGroups,
                columns: programmeEarnGroupsColumns(issueCredits),
              },
              {
                tabText: "other",
                data: programmeEarnGroups,
                columns: programmeEarnGroupsColumnsOther(onRemove),
              },
            ]}
          />
        </S.TableContainer>
      </div>
      <WarningMessage
        open={open}
        setOpen={setOpen}
        handleClick={handleRemoveEarnGroup}
        title="Are you sure you want to remove this Earn Group?"
      >
        <T.Body14B color="midnight" style={{ display: "inline" }}>
          {selectedGroup.name}
        </T.Body14B>
        <T.Body14R style={{ display: "inline" }}>
          ’s earn activity will no longer count towards
        </T.Body14R>
        <T.Body14B color="midnight" style={{ display: "inline" }}>
          {" "}
          {programmeName}{" "}
        </T.Body14B>
        <T.Body14R style={{ display: "inline" }}>
          from this date onwards.
        </T.Body14R>
      </WarningMessage>
    </S.Container>
  );
};

const mapStateToProps = (state) => ({
  balance: state.user.tc.tcBalance,
  programmeName: state.auth.programmeName,
  programmeId: state.auth.programmeId,
  programmeEarnGroups: state.staffSingleProgramme.programmeEarnGroups.filter(
    (eg) => eg && eg.programme && eg.programme.status === "activated",
  ),
  earnGroupsLoading: state.staffSingleProgramme.earnGroupsLoading,
});

const mapActionsToProps = {
  getProgrammeById: actions.getProgrammeById,
  getProgrammeEarnGroup: actions.getProgrammeEarnGroup,
  updateProgrammeEarnGroup: singleGroupActions.updateProgrammeEarnGroup,
};

export default connect(mapStateToProps, mapActionsToProps)(EarnGroups);
