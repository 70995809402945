import axios from "axios";
import handleError from "./handle-errors";

const AUTH_BASE = "/auth";

const login = async ({
  email,
  password,
  earnGroupUserId,
  giftToken,
  programmeToken,
  administratorToken,
  options,
  reToken,
  programmeManagerToken,
  addToTk,
}) => {
  try {
    const { data } = await axios.post(`${AUTH_BASE}/login`, {
      email,
      password,
      earnGroupUserId,
      giftToken,
      programmeToken,
      reToken,
      administratorToken,
      programmeManagerToken,
      addToTk,
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const temporaryLogin = async ({ id, role, options }) => {
  try {
    const { data } = await axios.post(`${AUTH_BASE}/temp-login`, {
      id,
      role,
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const refreshSession = async () => {
  try {
    const { data } = await axios.post(`${AUTH_BASE}/refresh-session`, {});
    return { data };
  } catch (error) {
    const err = handleError(error);
    return { error: err };
  }
};

const logout = async () => {
  try {
    const { data } = await axios.post(`${AUTH_BASE}/logout`, {});
    return { data };
  } catch (error) {
    const err = handleError(error);
    return { error: err };
  }
};

const signup = async ({ formData, options }) => {
  try {
    const { data } = await axios.post(`${AUTH_BASE}/signup`, formData, {
      params: { isPublic: true },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const addGroupSignup = async ({ formData, options }) => {
  try {
    const { data } = await axios.post(
      `${AUTH_BASE}/add-group-signup`,
      formData,
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const sendResetPasswordLink = async ({ email, options }) => {
  try {
    const { data } = await axios.post(`${AUTH_BASE}/reset-password`, { email });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const setPassword = async ({ token, password, options }) => {
  try {
    const { data } = await axios.post(`${AUTH_BASE}/set-password`, {
      token,
      password,
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const changePassword = async ({
  oldPassword,
  newPassword,
  credentialId,
  options,
}) => {
  try {
    const { data } = await axios.post(`${AUTH_BASE}/change-password`, {
      oldPassword,
      newPassword,
      credentialId,
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getSelectUsers = async ({ options = {}, language }) => {
  try {
    const { data } = await axios.get(`${AUTH_BASE}/users`, {
      params: { language },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const selectActiveUser = async ({ activeUserId, options = {} }) => {
  try {
    const { data } = await axios.post(`${AUTH_BASE}/select-user`, {
      activeUserId,
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getBrainTreeClientToken = async ({ language, options } = {}) => {
  try {
    const { data } = await axios.get(`${AUTH_BASE}/client-token`, {
      language,
    });

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const checkActivateAccountToken = async ({ token, options } = {}) => {
  try {
    const { data } = await axios.get(`${AUTH_BASE}/activate-account/${token}`, {
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    });

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getActivateAccountToken = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${AUTH_BASE}/activate-account-token`);

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const resendActivateAccountToken = async ({ options } = {}) => {
  try {
    const { data } = await axios.post(`${AUTH_BASE}/activate-account-token`);

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export {
  login,
  signup,
  logout,
  temporaryLogin,
  refreshSession,
  sendResetPasswordLink,
  setPassword,
  changePassword,
  getSelectUsers,
  selectActiveUser,
  addGroupSignup,
  getBrainTreeClientToken,
  checkActivateAccountToken,
  getActivateAccountToken,
  resendActivateAccountToken,
};
