import {
  GET_SPEND_ACTIVITY_BY_ID_LOADING_START,
  GET_SPEND_ACTIVITY_BY_ID_LOADING_END,
  GET_SPEND_ACTIVITY_BY_ID_SUCCESS,
  GET_SPEND_ACTIVITY_BY_ID_FAIL,
  PURCHASE_BOOKING_CODES_LOADING,
  PURCHASE_BOOKING_CODES_SUCCESS,
  PURCHASE_BOOKING_CODES_FAIL,
  RESET_PURCHASE_BOOKING_CODES,
  GET_USER_BOOKING_CODES_LOADING,
  GET_USER_BOOKING_CODES_SUCCESS,
  GET_USER_BOOKING_CODES_FAIL,
  APPROVE_ACTIVITY_APPROVAL_REQUEST_LOADING,
  APPROVE_ACTIVITY_APPROVAL_REQUEST_SUCCESS,
  APPROVE_ACTIVITY_APPROVAL_REQUEST_FAIL,
} from "../../../../redux/types";

const initState = {
  getSpendActivityLoading: false,
  error: null,
  spendActivityData: {},
  purchaseBookingCodesLoading: false,
  purchasedBookingCodesData: [],

  approveRequestLoading: false,
  approveRequestError: {},
  approveRequestDone: false,

  userBookingCodes: [],
  getUserBookingCodesLoading: false,
};

const activityReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    // GET SPEND ACTIVITIES
    case GET_SPEND_ACTIVITY_BY_ID_LOADING_START:
      return {
        ...state,
        getSpendActivityLoading: true,
      };
    case GET_SPEND_ACTIVITY_BY_ID_LOADING_END:
      return {
        ...state,
        getSpendActivityLoading: false,
      };
    case GET_SPEND_ACTIVITY_BY_ID_SUCCESS:
      return {
        ...state,
        spendActivityData: payload,
      };
    case GET_SPEND_ACTIVITY_BY_ID_FAIL:
      return {
        ...state,
        error: payload,
      };
    case APPROVE_ACTIVITY_APPROVAL_REQUEST_LOADING:
      return {
        ...state,
        approveRequestLoading: payload,
      };
    case APPROVE_ACTIVITY_APPROVAL_REQUEST_SUCCESS:
      return {
        ...state,
        approveRequestDone: true,
        approveRequestError: {},
      };
    case APPROVE_ACTIVITY_APPROVAL_REQUEST_FAIL:
      return {
        ...state,
        approveRequestError: payload,
      };

    // BUY BOOKING CODES FOR SPEND ACTIVITY
    case PURCHASE_BOOKING_CODES_LOADING:
      return {
        ...state,
        purchaseBookingCodesLoading: true,
      };
    case PURCHASE_BOOKING_CODES_SUCCESS:
      return {
        ...state,
        purchaseBookingCodesLoading: false,
        purchasedBookingCodesData: payload,
      };
    case PURCHASE_BOOKING_CODES_FAIL:
      return {
        ...state,
        purchaseBookingCodesLoading: false,
        error: payload,
      };

    // Get user booking codes
    case GET_USER_BOOKING_CODES_LOADING:
      return {
        ...state,
        getUserBookingCodesLoading: true,
        userBookingCodes: [],
      };
    case GET_USER_BOOKING_CODES_SUCCESS:
      return {
        ...state,
        userBookingCodes: payload,
        getUserBookingCodesLoading: false,
      };
    case GET_USER_BOOKING_CODES_FAIL:
      return {
        ...state,
        error: payload,
        getUserBookingCodesLoading: false,
      };

    case RESET_PURCHASE_BOOKING_CODES:
      return {
        ...state,
        ...initState,
      };

    default:
      return state;
  }
};

export default activityReducer;
