import { programmes } from "../../../../api-calls";

import {
  GET_PROGRAMMES_LOADING_START,
  GET_PROGRAMMES_LOADING_END,
  GET_PROGRAMMES_SUCCESS,
  GET_PROGRAMMES_FAIL,
  GET_FILTERED_PROGRAMMES_SUCCESS,
} from "../../../../redux/types";

export const getProgrammes =
  ({ limit, offset, startDate, endDate }) =>
  async (dispatch) => {
    dispatch({ type: GET_PROGRAMMES_LOADING_START });
    try {
      const { data, error } = await programmes.getProgrammes({
        limit,
        offset,
        startDate,
        endDate,
      });

      if (data) {
        dispatch({ type: GET_PROGRAMMES_SUCCESS, payload: data });
      } else {
        dispatch({
          type: GET_PROGRAMMES_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_PROGRAMMES_FAIL,
        payload: error,
      });
    } finally {
      dispatch({ type: GET_PROGRAMMES_LOADING_END });
    }
  };
export const getFilteredProgrammes =
  ({ limit, offset, startDate, endDate }) =>
  async (dispatch) => {
    dispatch({ type: GET_PROGRAMMES_LOADING_START });
    try {
      const { data, error } = await programmes.getProgrammes({
        limit,
        offset,
        startDate,
        endDate,
      });

      if (data) {
        dispatch({ type: GET_FILTERED_PROGRAMMES_SUCCESS, payload: data });
      } else {
        dispatch({
          type: GET_PROGRAMMES_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_PROGRAMMES_FAIL,
        payload: error,
      });
    } finally {
      dispatch({ type: GET_PROGRAMMES_LOADING_END });
    }
  };
