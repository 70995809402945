import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { getSpendVenuesPublic } from "./../../../GeneralRoutes/SelectVenue/actions";
import { getSpendActivityById } from "../../../GeneralRoutes/Profile/Activity/actions";
import { resetState, duplicateSpendActivity } from "./actions";
import * as S from "./style";
import * as T from "../../../../components/Typograpy";
import Button from "../../../../components/Button";
import { Checkbox } from "../../../../components/Inputs";
import GoBack from "../../../../components/GoBack";
import { Row, Col } from "../../../../components/Grid";
import t from "../../../../utils/helpers/translator";
import { AutoComplete } from "../../../../components/Inputs";
import * as R from "../../../../constants/navRoutes";

const IssueCredits = ({
  getSpendActivityById,
  getSpendVenuesPublic,
  spendVenues,
  getLoading,
  spendActivity,
  resetState,
  duplicateSpendActivity,
  duplicateError,
  duplicateLoading,
  duplicateSuccess,
  createdSpendActivity,
}) => {
  const { id } = useParams();
  const [venue, setVenue] = useState(null);
  const [copyDates, setCopyDates] = useState(true);
  const [copyBookingCodes, setCopyBookingCodes] = useState(true);
  const [copyUsedBookingCodes, setCopyUsedBookingCodes] = useState(true);
  const history = useHistory();

  useEffect(() => {
    getSpendVenuesPublic();
    getSpendActivityById(id, "spend", { isPublic: false });
    return resetState;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!getLoading && spendVenues.length > 0) {
      const currentSpendVenueId = spendActivity?.spendVenue?.id;

      setVenue(
        spendVenues.find((venue) => {
          return venue.id === currentSpendVenueId;
        }),
      );
    }
  }, [getLoading, spendActivity?.spendVenue?.id, spendVenues]);

  useEffect(() => {
    if (!copyBookingCodes) setCopyUsedBookingCodes(false);
  }, [copyBookingCodes]);

  const onSubmit = () => {
    if (!venue?.id) return;
    duplicateSpendActivity({
      spendActivityId: id,
      spendVenueIdUserId: venue.id,
      copyDates: spendActivity?.dates?.length > 0 && copyDates,
      copyBookingCodes:
        spendActivity?.bookingCodes?.length > 0 && copyBookingCodes,
      copyUsedBookingCodes:
        spendActivity?.bookingCodes?.length > 0 && copyUsedBookingCodes,
    });
  };

  useEffect(() => {
    if (duplicateSuccess) {
      history.push(
        R.TEMPO.EDIT_SPEND_ACTIVITY.replace(":id", createdSpendActivity?.id),
      );
    }
  }, [createdSpendActivity?.id, duplicateSuccess, history]);

  return (
    <S.Wrapper>
      <S.Questions>
        <Row mb={5}>
          <Col w={[4, 12, 12]}>
            <GoBack showText />
          </Col>
        </Row>
        <T.H24 color="midnight" mb={10}>
          You are about to duplicate
        </T.H24>
        <T.H40 color="midnight" mb="25">
          {spendActivity?.name}
        </T.H40>
        <AutoComplete
          disabled={getLoading}
          options={spendVenues}
          setValue={({ value }) => setVenue(value)}
          value={venue}
          fullWidth
          placeholder={`Type a venue...`}
          label={"Select venue to duplicate offer for"}
          mt="2"
          mb="4"
        />

        {spendActivity?.dates?.length > 0 && (
          <Checkbox
            label="Copy same dates"
            setValue2={setCopyDates}
            value={copyDates}
          />
        )}
        {spendActivity?.bookingCodes?.length > 0 && (
          <Checkbox
            label="Copy booking codes"
            setValue2={setCopyBookingCodes}
            value={copyBookingCodes}
          />
        )}

        {spendActivity?.bookingCodes?.length > 0 && (
          <Checkbox
            label="Copy used booking codes"
            disabled={!copyBookingCodes}
            setValue2={setCopyUsedBookingCodes}
            value={copyUsedBookingCodes}
          />
        )}
        <Row mt={5} mb={5}>
          <Col w={[4, 12, 12]}>
            {duplicateError && duplicateError.message && (
              <S.Error>{t(duplicateError.message, "english")}</S.Error>
            )}

            <Button
              primary
              onClick={onSubmit}
              loading={duplicateLoading}
              disabled={!venue?.id}
            >
              Duplicate
            </Button>
          </Col>
        </Row>
      </S.Questions>
    </S.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  spendVenues: state.spendVenuesPublic.spendVenues,
  getLoading: state.spendVenuesPublic.getLoading,
  postLoading: state.spendVenuesPublic.postLoading,
  spendActivity: state.activityProfile.spendActivityData,
  duplicateError: state.duplicateSpendActivity.error,
  duplicateLoading: state.duplicateSpendActivity.loading,
  duplicateSuccess: state.duplicateSpendActivity.success,
  createdSpendActivity: state.duplicateSpendActivity.createdSpendActivity,
});

const mapActionToProps = {
  getSpendVenuesPublic,
  getSpendActivityById,
  resetState,
  duplicateSpendActivity,
};

export default connect(mapStateToProps, mapActionToProps)(IssueCredits);
