import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getSpendVenuesPublic } from "./actions";
import { temporaryLogin } from "../../../redux/actions/auth";
import * as S from "./style";
import * as T from "../../../components/Typograpy";
import { AutoComplete } from "../../../components/Inputs";
import Button from "../../../components/Button";

import t from "../../../utils/helpers/translator";

const SelectVenue = (props) => {
  const [venue, setVenue] = useState(null);
  const history = useHistory();
  const { getSpendVenuesAction, spendVenues, getLoading, postLoading, lang } =
    props;

  useEffect(() => {
    getSpendVenuesAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    if (!venue?.value?.id) return;
    props.handleTemporaryLogin({
      id: venue?.value?.id,
      role: venue?.value?.role,
      history,
    });
  };
  return (
    <>
      <S.Wrapper>
        <S.Header>
          <T.H40Caps color="midnight">{t("selectVenue", lang)}</T.H40Caps>
        </S.Header>
        <S.Body>
          <AutoComplete
            disabled={getLoading || postLoading}
            options={spendVenues}
            setValue={setVenue}
            fullWidth
            placeholder={`${t("typeVenue", lang)}...`}
            label={t("venue", lang)}
            mt="8"
          />
          <Button
            loading={postLoading}
            disabled={!venue || getLoading || postLoading}
            style={{ marginTop: "4rem" }}
            primary
            size="l"
            onClick={onSubmit}
          >
            {t("confirm", lang)}
          </Button>
        </S.Body>
      </S.Wrapper>
    </>
  );
};

const mapStateToProps = (state) => ({
  spendVenues: state.spendVenuesPublic.spendVenues,
  getLoading: state.spendVenuesPublic.getLoading,
  postLoading: state.spendVenuesPublic.postLoading,
  lang: state.auth.language,
});

const mapActionToProps = {
  getSpendVenuesAction: getSpendVenuesPublic,
  handleTemporaryLogin: temporaryLogin,
};

export default connect(mapStateToProps, mapActionToProps)(SelectVenue);
