import { earnGroups } from "../../../../api-calls";

import {
  GET_STAFF_EARNGROUPS_LOADING,
  GET_STAFF_EARNGROUPS_SUCCESS,
  GET_STAFF_EARNGROUPS_FAIL,
} from "../../../../redux/types";

export const getEarnGroups =
  ({ limit, offset, startDate, endDate, query }) =>
  async (dispatch) => {
    dispatch({ type: GET_STAFF_EARNGROUPS_LOADING });
    try {
      const { data, error } = await earnGroups.getEarnGroups({
        limit,
        offset,
        startDate,
        endDate,
        query,
      });
      if (data) {
        dispatch({ type: GET_STAFF_EARNGROUPS_SUCCESS, payload: data });
      } else {
        dispatch({
          type: GET_STAFF_EARNGROUPS_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_STAFF_EARNGROUPS_FAIL,
        payload: error,
      });
    }
  };
