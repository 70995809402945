import axios from "axios";
import handleError from "./handle-errors";

const CREDITS_REQUESTS_BASE = "/credits-requests";

const getCreditsRequests = async ({ status, options }) => {
  try {
    const { data } = await axios.get(CREDITS_REQUESTS_BASE, {
      params: { status },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const createCreditsRequests = async ({ requestedCredits, options }) => {
  try {
    const { data } = await axios.post(CREDITS_REQUESTS_BASE, {
      requestedCredits,
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getCreditsRequestById = async ({ id, options }) => {
  try {
    const { data } = await axios.get(`${CREDITS_REQUESTS_BASE}/${id}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updateCreditsRequest = async ({
  id,
  status,
  tc,
  responseMessage,
  programmeId,
  options,
}) => {
  try {
    const { data } = await axios.patch(`${CREDITS_REQUESTS_BASE}/${id}`, {
      status,
      tc,
      responseMessage,
      programmeId,
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export {
  getCreditsRequests,
  createCreditsRequests,
  getCreditsRequestById,
  updateCreditsRequest,
};
