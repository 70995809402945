import React, { useEffect } from "react";
import { connect } from "react-redux";

import * as T from "../../../components/Typograpy";
import * as actions from "./actions";

import { InputField, Counter, Dropdown } from "../../../components/Inputs";
import { Regular } from "../../../components/Button";
import { Row, Col } from "../../../components/Grid";
import translate from "../../../utils/helpers/translator";

const Step2 = ({
  giftOption,
  handleEmail,
  handleGroup,
  memberGroups,
  selectedGroup,
  credits,
  handleCredits,
  balance,
  readyToSend,
  onSubmit,
  createGiftError,
  createGiftLoading,
  // setStage,
  resetCreateGiftError,
  emailError,
  clearFields,
  email,
  lang,
}) => {
  useEffect(
    () => () => {
      resetCreateGiftError();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <>
      {giftOption === "friends" && (
        <Row>
          <Col w={[4, 12, 12]}>
            <InputField
              label={translate("whatEmail", lang)}
              type="email"
              placeholder={`${translate("typeEmail", lang)}...`}
              setValue={handleEmail}
              error={emailError}
              value={email}
            />
          </Col>
        </Row>
      )}
      {giftOption === "earnGroup" && (
        <Dropdown
          name="earnGroup"
          label="Select your group"
          placeholder="Select your group"
          setValue={handleGroup}
          options={memberGroups}
          value={selectedGroup}
        />
      )}
      <Row mb={2}>
        <Col w={[4, 12, 12]}>
          <T.H18 ml={15} mt={30} color="midnight">
            {translate("howManyTCsGift", lang)}?
          </T.H18>
          <Counter
            value={credits}
            setValue={handleCredits}
            counterWidth="65%"
            justify="flex-start"
            height="60px"
          />
          {credits > balance && (
            <T.Body14R color="pink" mt="20">
              {translate("sorryNotEnough", lang)}
            </T.Body14R>
          )}
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 12]}>
          <T.Body14R color="pink" mt={60}>
            {createGiftError &&
              createGiftError.message &&
              translate(createGiftError.message, lang)}
          </T.Body14R>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 12]}>
          {!readyToSend() || createGiftLoading ? (
            <Regular bgColor="gray5" textColor="black" mb={20} disabled>
              {translate("confirm", lang)}
            </Regular>
          ) : (
            <Regular
              primary
              bgColor="blue"
              mb={20}
              loading={createGiftLoading}
              onClick={onSubmit}
            >
              {translate("confirm", lang)}
            </Regular>
          )}
          {/* <Button outline size="l" onClick={() => setStage(1)}> */}
          <Regular
            outline
            textColor={!email && !credits ? "gray4" : "blue"}
            disabled={!email && !credits}
            onClick={clearFields}
          >
            {translate("cancel", lang)}
          </Regular>
        </Col>
      </Row>
    </>
  );
};

const mapActionToProps = {
  resetCreateGiftError: actions.resetErrors,
};

export default connect(null, mapActionToProps)(Step2);
