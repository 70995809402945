import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

import Table from "../../../components/Table";
import Loading from "../../../components/Loading";
import Credits from "../../../components/Table/components/Credits";
import Icon from "../../../components/Icon";
import { StyledButton, StyledMobileButton } from "./style";
import colors from "../../../utils/theme/colors";
import * as T from "../../../components/Typograpy";

import t from "../../../utils/helpers/translator";

import { decideType } from "../../../utils/helpers/decideTransactionType";

import {
  dateCol,
  creditsColWithTag,
  mobileDateCol,
  mobileMemberActivityCol,
} from "../../../components/Table/config/reusableColumns";

import { MEMBER } from "../../../constants/navRoutes";
import { earnTypesMapWelsh } from "../../../constants/data/earnActivityTypes";

const TransactionTable = (props) => {
  const history = useHistory();
  const { transactions, userInfo, isMobile, preview, cancelGift, language } =
    props;

  const { loading, data } = transactions;

  // create transaction table friendly data
  const decideVenue = (userId, from, to, toEmail) => {
    if (!to.involved && toEmail) {
      return toEmail;
    }
    if (from.userId === userId) {
      if (language === "welsh") {
        return to.involvedWelsh || to.involved;
      }
      return to.involved;
    }
    if (language === "welsh") {
      return from.involvedWelsh || from.involved;
    }
    return from.involved;
  };

  const clickRow = (event, rowData) =>
    rowData.id &&
    history.push(MEMBER.HISTORY_SINGLE.replace(":id", rowData.id));

  const renderCancelStatus = (rowData) => {
    if (rowData.cancelGiftLoading) return <Loading size={20} />;
    if (rowData.cancelGiftError && rowData.cancelGiftError.message)
      return t("error", language);
    return (
      <>
        {" "}
        <Icon icon="close" width="20px" height="20px" color="pink" />{" "}
        <T.Body12B style={{ textTransform: "uppercase" }} color="gray3">
          {t("cancel", language)}
        </T.Body12B>
      </>
    );
  };

  const handleCancel = (id) => {
    cancelGift(id);
  };

  const desktopColumns = [
    dateCol(t("date", language)),
    {
      title: t("activity", language),
      field: "activity",
      render: (rowData) =>
        rowData.status === "pending" ? (
          <>
            {rowData.activity}{" "}
            <i style={{ color: colors.gray3 }}>({t("pending", language)})</i>
          </>
        ) : (
          rowData.activity
        ),
    },
    { title: t("organisationPerson", language), field: "with" },
    creditsColWithTag(t("numberOfTC", language)),
    {
      title: "",
      field: "giftId",
      render: (rowData) => (
        <>
          {rowData.status === "pending" && (
            <StyledButton
              type="button"
              id={rowData.giftId}
              onClick={() => handleCancel(rowData.giftId)}
              disabled={rowData.cancelGiftLoading}
            >
              {renderCancelStatus(rowData)}
            </StyledButton>
          )}
        </>
      ),
    },
  ];

  const mobileColumns = [
    mobileDateCol,
    mobileMemberActivityCol,
    // mobileMemberCreditsCol,
    {
      title: "",
      field: "credits",
      type: "numeric",
      render: (rowData) => (
        <div>
          <Credits
            withSymbol
            credits={rowData.credits}
            type={rowData.type}
            fromUser={rowData.fromUser}
            justify="flex-end"
            pr="0"
            pending={rowData.status === "pending"}
          />
          {rowData.status === "pending" && (
            <StyledMobileButton
              type="button"
              id={rowData.giftId}
              onClick={() => handleCancel(rowData.giftId)}
              disabled={rowData.cancelGiftLoading}
            >
              {renderCancelStatus(rowData)}
            </StyledMobileButton>
          )}
        </div>
      ),
      cellStyle: {
        padding: "0",
        width: "25%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        margin: "0 0 0 0 auto",
        textAlign: "right",
      },
    },
  ];

  const decideActivityName = (activity) => {
    if (!activity.activity.name) {
      switch (activity.type) {
        case "free":
        case "exchange":
          return t("tcDelivery", language);
        case "gift":
        case "pending":
          return t("gift", language);
        default:
          return "--";
      }
    }
    if (language === "welsh") {
      if (activity.activity.welshName) return activity.activity.welshName;
      const res = earnTypesMapWelsh[activity.activity.name];
      return res || activity.activity.name;
    }
    return activity.activity.name;
  };

  const getMemberTransactions = (transactionsArray, mobileView) => {
    let currDate;
    let dateToShow;
    const createTableRows = transactionsArray.map((transaction, index) => {
      if (mobileView) {
        const day = moment(transaction.createdAt).format("l");
        const formattedDate = moment(day).calendar(null, {
          sameDay: "[Today]",
          lastDay: "[Yesterday]",
          lastWeek: "ddd Do MMMM",
          sameElse: "ddd Do MMMM",
        });
        if (index === 0) {
          currDate = formattedDate;
          dateToShow = formattedDate;
        } else if (currDate === formattedDate && index % 10 > 0) {
          dateToShow = "";
        } else {
          currDate = formattedDate;
          dateToShow = formattedDate;
        }
      }
      const row = {
        date: isMobile ? dateToShow : transaction.createdAt,
        activity: decideActivityName(transaction),
        with:
          decideVenue(
            userInfo.id,
            transaction.from,
            transaction.to,
            transaction.toEmail,
          ) || "Tempo",
        credits: transaction.tc,
        type: decideType(transaction.type),
        fromUser: transaction.from.userId === userInfo.id,
        id: transaction.id,
        giftId: transaction.giftId,
        status: transaction.type,
      };

      if (transaction.type === "pending") {
        return {
          ...row,
          cancelGiftLoading: transaction.cancelGiftLoading,
          cancelGiftError: transaction.cancelGiftError,
        };
      }

      return row;
    });

    return createTableRows;
  };

  const renderTable = () => {
    if (!loading && transactions.data.length === 0) {
      return <div>{t("noTransactions", language)}</div>;
    }

    if (loading) return <Loading />;

    if (!loading && isMobile) {
      return (
        <Table
          color="pink"
          data={getMemberTransactions(data, true)}
          columns={mobileColumns}
          memberMobile={isMobile}
          hideSearch
          preview={preview}
          previewPath={MEMBER.HISTORY}
          onRowClick={clickRow}
          isLoading={loading}
          limit={10}
        />
      );
    }
    if (!loading)
      return (
        <Table
          color="pink"
          data={getMemberTransactions(data)}
          columns={desktopColumns}
          hideSearch
          preview={preview}
          previewPath={MEMBER.HISTORY}
          onRowClick={clickRow}
          isLoading={loading}
          limit={10}
        />
      );
  };

  return renderTable();
};

const mapstateToProps = (state) => ({
  language: state.auth.decideLanguage(state.auth),
});

export default connect(mapstateToProps)(TransactionTable);
