import {
  CREATE_TICKET_LOADING_START,
  CREATE_TICKET_SUCCESS,
  CREATE_TICKET_FAIL,
  CREATE_TICKET_LOADING_END,
} from "../types";

import { zendesk } from "../../api-calls/thirdParty";

export const createTicket =
  ({ query }) =>
  async (dispatch) => {
    dispatch({ type: CREATE_TICKET_LOADING_START });

    try {
      const { data, error } = await zendesk.createTicket({ query });

      if (data) {
        dispatch({
          type: CREATE_TICKET_SUCCESS,
          payload: data,
        });
        return data;
      }
      if (error) {
        dispatch({
          type: CREATE_TICKET_FAIL,
          payload: error,
        });
        return error;
      }
    } catch (error) {
      dispatch({
        type: CREATE_TICKET_FAIL,
        payload: error,
      });
      return error;
    } finally {
      dispatch({ type: CREATE_TICKET_LOADING_END });
    }
  };
