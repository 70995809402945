import { styled } from "@material-ui/core/styles";

export const Wrapper = styled("div")(({ theme }) => ({
  zIndex: 999,
  display: "flex",
  padding: "10px 15px",
  alignItems: "center",
  background: theme.colors.midnight,
  justifyContent: "flex-end",
  position: "fixed",
  top: 0,
  width: "100%",

  [theme.breakpoints.tablet]: {
    position: "absolute",
    right: theme.spacing(5),
    top: theme.spacing(5),
    width: "196px",
    height: "45px",
    justifyContent: "center",
    background: theme.colors.white,
    borderRadius: theme.spacing(1),
    padding: "10px 0px",
  },
}));

export const LangBtn = styled("button")(({ theme }) => ({
  outline: "none",
  margin: 0,
  padding: 0,
  display: "flex",
  justifyContent: "center",

  background: "none",
  border: "none",
  cursor: "pointer",

  "& p": {
    color: theme.colors.white,
    margin: 0,
  },

  [theme.breakpoints.tablet]: {
    "& p": {
      color: `${theme.colors.gray2} !important`,
      margin: 0,
    },
  },
}));
