import { styled } from "@material-ui/core/styles";

export const Line = styled("span")(
  ({ theme, color, bgColor, h, w, vertical, m }) => ({
    background: bgColor
      ? theme.colors[bgColor || "pink"]
      : theme.gradients[color || "pink"],
    height: vertical ? h || "100%" : h || theme.spacing(0),
    display: "block",
    width: vertical ? w || theme.spacing(0) : w || "100%",
    margin: m || "inherit",
  }),
);
