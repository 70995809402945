import React, { useState, useEffect, useRef } from "react";
import ReactGA from "react-ga4";

import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { GENERAL } from "../../constants/navRoutes";
import userRoles from "../../constants/roles";
import cleanWhitespace from "../../utils/helpers/cleanWhitespace";
import t from "../../utils/helpers/translator";
import { Row, Col } from "../../components/Grid";
import * as T from "../../components/Typograpy";

import {
  signup as signupAction,
  getEarnGroupInfo as getEarnGroupInfoAction,
  logout as logoutAction,
} from "../../redux/actions/auth";

import { Body18B, Body16R, Body12B } from "../../components/Typograpy";
import InputField from "../../components/Inputs/InputField";
import { Regular } from "../../components/Button";
import Checkbox from "../../components/Inputs/Checkbox";
import WelcomeSection from "../Login/WelcomeSection";
import { ReCaptcha } from "../../components/TextSections";

import validate from "../../validation/schemas/signup";
import { roles, externalLinks } from "../../constants";

import tidyPostcode from "../../utils/helpers/tidyPostcode";

import { ExistsError, ExtLink, StyledTitle, StyledRow } from "./style";

import { Error, StyledLink } from "./customSignupStyle";

const { MEMBER } = roles;

const useQuery = () => new URLSearchParams(useLocation().search);

const initErrors = {
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  agreedOnTerms: "",
  postcode: "",
  under16: "",
  above16: "",
};

const Signup = ({
  getEarnGroupInfo,
  earnGroupInfo,
  handleSignup,
  signupLoading,
  signupError,
  language,
  userId,
  logout,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [postcode, setPostcode] = useState("");
  const [agreedOnTerms, setAgreedOnTerms] = useState(false);
  const [under16, setUnder16] = useState(false);
  const [above16, setAbove16] = useState(false);
  const [submitAttemp, setSubmitAttemp] = useState(false);
  const history = useHistory();
  const query = useQuery();
  const inviteToken = query.get("invite");
  const giftToken = query.get("gift");
  const volunteerTkId = query.get("guid");
  const tkRedirectUrl = query.get("redirect_uri");
  const disableLogout = useRef(false); // if true, prevents the useEffect from logging out if user just signed up

  const searchParams = new URLSearchParams();
  if (inviteToken) searchParams.append("invite", inviteToken);
  if (giftToken) searchParams.append("gift", giftToken);
  if (volunteerTkId) searchParams.append("guid", volunteerTkId);
  if (tkRedirectUrl) searchParams.append("redirect_uri", tkRedirectUrl);

  const [errors, setErrors] = useState(initErrors);
  const [submitError, setSubmitError] = useState("");

  const partialValidate = (name, value) => {
    try {
      if (submitAttemp) {
        validate(
          {
            [name]: value,
          },
          true,
        );
      }
    } catch (err) {
      if (err.name === "ValidationError") {
        setErrors((_errors) => ({ ..._errors, [name]: err.inner[name] }));
      }
    }
  };

  const onChange = (e) => {
    const fieldName = e.target.name;
    if (fieldName === "under16" || fieldName === "above16") {
      partialValidate("agreedAge", e.target.checked);
    } else if (fieldName === "agreedOnTerms") {
      partialValidate(fieldName, e.target.checked);
    } else {
      partialValidate(e.target.name, e.target.value);
    }

    switch (e.target.name) {
      case "email":
        setEmail(e.target.value);
        break;
      case "password":
        setPassword(e.target.value);
        break;
      case "firstName":
        setFirstName(e.target.value);
        break;
      case "lastName":
        setLastName(e.target.value);
        break;
      case "postcode":
        setPostcode(e.target.value);
        break;
      case "agreedOnTerms":
        setAgreedOnTerms(e.target.checked);
        break;
      case "under16":
        setUnder16(e.target.checked);
        setAbove16(false);
        break;
      case "above16":
        setAbove16(e.target.checked);
        setUnder16(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (inviteToken) {
      getEarnGroupInfo({
        inviteToken,
        invitedUserRole: userRoles.MEMBER,
        language,
        history,
      });
    }
    setErrors(initErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    if (userId && tkRedirectUrl && volunteerTkId && !disableLogout.current) {
      logout({
        noRedirect: true,
      });
    }
  }, [userId, tkRedirectUrl, volunteerTkId, logout]);

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      setSubmitAttemp(true);
      const cleanEmail = email.toLowerCase().trim();
      const cleanPostcode = tidyPostcode(postcode);

      validate({
        role: MEMBER,
        email: cleanEmail,
        password,
        earnGroupUserId: earnGroupInfo.id,
        firstName,
        lastName,
        agreedOnTerms,
        giftToken,
        postcode: cleanPostcode,
        agreedAge: under16 || above16,
      });
      const data = {
        role: MEMBER,
        email: cleanEmail,
        password,
        earnGroupUserId: earnGroupInfo.id,
        firstName: cleanWhitespace(firstName),
        lastName: cleanWhitespace(lastName),
        giftToken,
        postcode: cleanPostcode,
        over16: !!above16,
        language,
        addToTk: {
          isTkVolunteer: !!volunteerTkId,
          volunteerTkId,
          tkRedirectUrl,
          inviteToken,
        },
      };

      disableLogout.current = true;
      handleSignup(data, history);
    } catch (err) {
      if (err.name === "ValidationError") {
        setErrors(err.inner);
      } else {
        setSubmitError(err.message);
      }
    }
  };

  const renderMainParagraph = () => {
    if (inviteToken) {
      const wantsYouToBecomeMember = t("wantsYouToBecomeMember", language, [
        earnGroupInfo.profile.name,
      ]).split(" | ");
      return (
        <Body16R>
          {wantsYouToBecomeMember[0]}{" "}
          <Body18B
            color="gray2"
            style={{
              textTransform: "capitalize",
              display: "inline",
            }}
          >
            {wantsYouToBecomeMember[1]}
          </Body18B>{" "}
          {wantsYouToBecomeMember[2]}
        </Body16R>
      );
    }

    return null;
  };

  useEffect(() => {
    // Analytics track those who arrive at sign up page
    if (process.env.NODE_ENV === "production") {
      if (giftToken) {
        ReactGA.event({
          category: "Member",
          action: "Arrived at sign up page (with gift link)",
        });
      } else {
        ReactGA.event({
          category: "Member",
          action: "Arrived at sign up page (no gift link)",
        });
      }
    }
  }, [giftToken]);

  const termsOfUseText = t("agreeToTerms", language).split(" | ");
  const alreadyHaveAccountLogIn = t("alreadyHaveAccountLogIn", language).split(
    " | ",
  );
  return (
    <StyledRow jc="space-between" mt="8">
      <Col w={[4, 12, 7]}>
        {/* page title */}
        <Row inner>
          <Col w={[4, 12, 12]}>
            {/* title */}
            <Row inner>
              <Col w={[4, 12, 12]}>
                <StyledTitle color="primary">
                  {t("signUp", language)}
                </StyledTitle>
              </Col>
            </Row>
            {/* invite message */}
            {!!renderMainParagraph() && (
              <Row inner mt="1">
                <Col w={[4, 12, 12]}>{renderMainParagraph()}</Col>
              </Row>
            )}
            {/* already have an account */}
            <Row inner mb="6" mt="2">
              <Col w={[4, 12, 12]}>
                <T.Body16R color="gray3" style={{ display: "inline" }}>
                  {alreadyHaveAccountLogIn[0]}{" "}
                </T.Body16R>
                <StyledLink
                  to={{
                    pathname: GENERAL.LOG_IN,
                    search: searchParams.toString(),
                  }}
                >
                  {alreadyHaveAccountLogIn[1]}
                </StyledLink>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* first name and last name row */}
        <Row inner mb="6" mbM="4">
          <Col w={[4, 6, 6]} mbM="4">
            {/* first name */}
            <InputField
              placeholder={`${t("typeFirstNameHere", language)}...`}
              label={t("firstName", language)}
              name="firstName"
              value={firstName}
              setValue={onChange}
              error={errors.firstName}
              justify="center"
            />
          </Col>
          <Col w={[4, 6, 6]}>
            {/* last name */}
            <InputField
              placeholder={`${t("typeLastNameHere", language)}...`}
              label={t("lastName", language)}
              name="lastName"
              value={lastName}
              setValue={onChange}
              error={errors.lastName}
            />
          </Col>
        </Row>
        {/* email and password row */}
        <Row inner mb="6" mbM="4">
          <Col w={[4, 6, 6]} mbM="4">
            {/* email */}
            <InputField
              placeholder={`${t("typeEmail", language)}...`}
              label={t("email", language)}
              name="email"
              value={email}
              setValue={onChange}
              error={errors.email}
            />
            {signupError && signupError.httpStatusCode === 409 ? (
              <ExistsError>{t(signupError.message, language)}</ExistsError>
            ) : null}
          </Col>
          <Col w={[4, 6, 6]}>
            {/* password */}
            <InputField
              type="password"
              placeholder={`${t("createPassword", language)}...`}
              label={t("password", language)}
              name="password"
              value={password}
              setValue={onChange}
              error={errors.password}
              showPasswordPop
              lang={language}
            />
          </Col>
        </Row>
        {/* postcode  row */}
        <Row inner>
          <Col w={[4, 12, 12]}>
            <Row inner>
              <Col w={[4, 6, 6]}>
                {/* postcode */}
                <InputField
                  placeholder={`${t("typePostcode", language)}...`}
                  label={t("postcode", language)}
                  name="postcode"
                  value={postcode}
                  setValue={onChange}
                  error={errors.postcode}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {/* above16 and terms row */}
        <Row inner mt="6">
          <Col w={[4, 6, 6]} mbM="5">
            {/* above16 */}
            <Checkbox
              checkboxSize="small"
              value={above16}
              setValue={onChange}
              bottom
              mt="20"
              name="above16"
              label={t("confirm16OrOlder", language)}
              error={!!errors.agreedAge}
            />
            <Body12B
              mb="20"
              mt="6"
              color="midnight"
              style={{ textAlign: "center" }}
              caps
            >
              {t("or", language)}
            </Body12B>
            <Checkbox
              checkboxSize="small"
              value={under16}
              setValue={onChange}
              bottom
              mt="20"
              name="under16"
              label={t("iAmUnder16", language)}
              error={errors.agreedAge}
            />
          </Col>
          <Col w={[4, 6, 6]}>
            {/* terms checkbox */}
            <Checkbox
              checkboxSize="small"
              value={agreedOnTerms}
              setValue={onChange}
              bottom
              mt="20"
              name="agreedOnTerms"
              label={
                <>
                  {termsOfUseText[0]}{" "}
                  <ExtLink
                    href={externalLinks.MEMBER_TERMS}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {termsOfUseText[1]}
                  </ExtLink>
                  {termsOfUseText[2]}{" "}
                  <ExtLink
                    href={externalLinks.MEMBER_PRIVACY}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {termsOfUseText[3]}
                  </ExtLink>
                  .
                </>
              }
              error={errors.agreedOnTerms}
            />
          </Col>
        </Row>
        {/* sign up button and error message  row */}
        <Row inner mt="6">
          <Col w={[4, 12, 12]}>
            <Row inner>
              {/* error message */}
              {submitError ||
                (signupError && signupError.httpStatusCode !== 409 && (
                  <Col w={[4, 12, 12]}>
                    <Error>
                      {t(submitError || signupError.message, language)}
                    </Error>
                  </Col>
                ))}
              <Col w={[4, 6, 6]}>
                {/* sign up button */}
                <Regular
                  bgColor="blue"
                  primary
                  handleClick={handleSubmit}
                  style={{ margin: 0, marginTop: "10px" }}
                  loading={signupLoading}
                  type="submit"
                >
                  {t("signUp", language)}
                </Regular>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* reCaptcha row */}
        <Row inner mt="5">
          <Col w={[4, 12, 12]}>
            <Row inner>
              <Col w={[4, 6, 6]}>
                {/* recaptcha */}
                <ReCaptcha />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col w={[0, 0, 4]}>
        <WelcomeSection fullWidth />
      </Col>
    </StyledRow>
  );
};

const mapStateToProps = (state) => ({
  earnGroupInfo: state.auth.earnGroupInfo,
  signupLoading: state.auth.signupLoading,
  signupError: state.auth.signupError,
  language: state.auth.language,
  userId: state.auth.id,
});

const mapActionToProps = {
  getEarnGroupInfo: getEarnGroupInfoAction,
  handleSignup: signupAction,
  logout: logoutAction,
};

export default connect(mapStateToProps, mapActionToProps)(Signup);
