import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { connect } from "react-redux";

import * as S from "./style";
import * as T from "../../../components/Typograpy";
import { externalLinks } from "../../../constants";

import Button from "../../../components/Button";

import t from "../../../utils/helpers/translator";
import * as actions from "./actions";

const Terms = ({
  updateSpendPartnerTerms,
  loading,
  lang,
  selectedSpendVenue,
  role,
}) => {
  const [error, setError] = useState("");
  const history = useHistory();
  const bodyText = t("spendPartnerAcceptTermsBody", lang).split(" | ");

  const handleAccept = async () => {
    try {
      await updateSpendPartnerTerms({ history, selectedSpendVenue, role });
    } catch (_error) {
      setError(_error.message);
    }
  };

  return (
    <>
      <S.Wrapper>
        <S.Body>
          <T.H40Caps color="midnight" align="left" mb="30">
            {t("welcomeToTC", lang)}
          </T.H40Caps>
          <>
            <T.Body16R color="gray3" style={{ display: "inline" }}>
              {" "}
              {bodyText[0]}{" "}
            </T.Body16R>
            <Link
              to={{ pathname: externalLinks.SPEND_PARTNER_TERMS }}
              target="_blank"
            >
              <T.LinkB16 color="blue" style={{ display: "inline" }}>
                {bodyText[1]}
              </T.LinkB16>
            </Link>
            <T.Body16R color="gray3" style={{ display: "inline" }}>
              {" "}
              {bodyText[2]}{" "}
            </T.Body16R>
            <Link
              to={{ pathname: externalLinks.SPEND_PARTNER_PRIVACY }}
              target="_blank"
            >
              <T.LinkB16 color="blue" style={{ display: "inline" }}>
                {bodyText[3]}
              </T.LinkB16>
            </Link>
            <T.Body16R color="gray3" style={{ display: "inline" }}>
              {" "}
              {bodyText[4]}.
            </T.Body16R>
          </>
          <Button
            primary
            bgColor="blue"
            customWidth="300px"
            onClick={handleAccept}
            w="80%"
            m="40px 0 0"
            loading={loading}
          >
            {t("continue", lang)}
          </Button>
          {error && <T.Body14B color="pink">{t(error, lang)}</T.Body14B>}
        </S.Body>
      </S.Wrapper>
    </>
  );
};

const mapStateToProps = (state) => ({
  lang: state.auth.language,
  role: state.auth.role,
  loading: state.auth.spendPartner.acceptedTermsLoading,
  selectedSpendVenue: state.auth.selectedSpendVenue,
});

const mapActionToProps = {
  updateSpendPartnerTerms: actions.updateSpendPartnerTerms,
};
export default connect(mapStateToProps, mapActionToProps)(Terms);
