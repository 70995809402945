import {
  // profile
  GET_SPEND_VENUE_BY_ID_LOADING_START,
  GET_SPEND_VENUE_BY_ID_LOADING_END,
  GET_SPEND_VENUE_BY_ID_SUCCESS,
  GET_SPEND_VENUE_BY_ID_FAIL,

  // activities by spend venue
  GET_ACTIVITIES_BY_SPEND_VENUE_ID_LOADING_START,
  GET_ACTIVITIES_BY_SPEND_VENUE_ID_LOADING_END,
  GET_ACTIVITIES_BY_SPEND_VENUE_ID_SUCCESS,
  GET_ACTIVITIES_BY_SPEND_VENUE_ID_FAIL,

  // earn group profile data
  GET_EARN_GROUP_BY_GROUP_ID_LOADING,
  GET_EARN_GROUP_BY_GROUP_ID_SUCCESS,
  GET_EARN_GROUP_BY_GROUP_ID_FAIL,
} from "../../../../redux/types";

const initState = {
  getSpendVenueLoading: false,
  getSpendActivitiesLoading: false,
  error: null,
  spendVenueProfileData: {
    name: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    postcode: "",
    contactNumber: "",
    socialLinks: "",
    description: "",
    imageCoverUrl: "",
  },
  getEarnGroupLoading: false,
  earnGroupProfileData: {},
  activitiesData: [],
};

const profileReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    // GET SPEND ACTIVITIES
    case GET_SPEND_VENUE_BY_ID_LOADING_START:
      return {
        ...state,
        getSpendVenueLoading: true,
      };
    case GET_SPEND_VENUE_BY_ID_LOADING_END:
      return {
        ...state,
        getSpendVenueLoading: false,
      };
    case GET_SPEND_VENUE_BY_ID_SUCCESS:
      return {
        ...state,
        spendVenueProfileData: { ...payload },
      };
    case GET_SPEND_VENUE_BY_ID_FAIL:
      return {
        ...state,
        error: payload,
      };

    // Activities by spend Venue id
    case GET_ACTIVITIES_BY_SPEND_VENUE_ID_LOADING_START:
      return {
        ...state,
        getSpendActivitiesLoading: true,
      };
    case GET_ACTIVITIES_BY_SPEND_VENUE_ID_LOADING_END:
      return {
        ...state,
        getSpendActivitiesLoading: false,
      };
    case GET_ACTIVITIES_BY_SPEND_VENUE_ID_SUCCESS:
      return {
        ...state,
        activitiesData: payload,
      };
    case GET_ACTIVITIES_BY_SPEND_VENUE_ID_FAIL:
      return {
        ...state,
        error: payload,
      };

    // GET EARN GROUP
    case GET_EARN_GROUP_BY_GROUP_ID_LOADING:
      return {
        ...state,
        getEarnGroupLoading: true,
      };

    case GET_EARN_GROUP_BY_GROUP_ID_SUCCESS:
      return {
        ...state,
        earnGroupProfileData: payload,
        getEarnGroupLoading: false,
      };
    case GET_EARN_GROUP_BY_GROUP_ID_FAIL:
      return {
        ...state,
        error: payload,
        getEarnGroupLoading: false,
      };

    default:
      return state;
  }
};

export default profileReducer;
