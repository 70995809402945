import moment from "moment";

const convertDateIntoMs = (rawDate) => {
  if (moment(rawDate, "DD/MM/YYYY").isValid()) {
    return moment(rawDate, "DD/MM/YYYY").valueOf();
  }

  if (moment(rawDate).isValid()) {
    return moment(rawDate).valueOf();
  }
  return 0;
};

export default convertDateIntoMs;
