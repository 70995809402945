import ReactGA from "react-ga4";
import { navRoutes, roles } from "../constants";

const redirectAfterLoginWithTracker = ({
  data,
  earnGroupUserId,
  emailJustVerified,
  history,
}) => {
  let tracker = "RAL";
  if (!data.emailVerified) {
    tracker = `${tracker}=>1`;
    history.push(navRoutes.GENERAL.ACTIVATE_ACCOUNT_EMAIL_SENT);
    return tracker;
  }

  switch (data.role) {
    case roles.MEMBER:
      tracker = `${tracker}=>2`;
      if (earnGroupUserId) {
        tracker = `${tracker}=>3`;
        history.push(navRoutes.MEMBER.INVITED_BY_GROUP_SUCCESS);
        break;
      }

      if (data.acceptedGiftId) {
        tracker = `${tracker}=>4`;
        history.push(
          navRoutes.MEMBER.RECEIVED_GIFT_SUCCESS.replace(
            ":id",
            data.acceptedGiftId,
          ),
        );
        break;
      }

      if (data.selectedActiveUser) {
        tracker = `${tracker}=>5`;
        history.push(
          emailJustVerified
            ? navRoutes.GENERAL.CONTINUE_SIGN_UP
            : navRoutes.MEMBER.DASHBOARD,
        );
      }
      break;

    case roles.EARN_GROUP:
    case roles.EARN_GROUP_ADMIN:
      tracker = `${tracker}=>6`;
      history.push(navRoutes.EARN_GROUP.DASHBOARD);
      break;

    case roles.SPEND_VENUE:
      tracker = `${tracker}=>7`;
      if (data.acceptedTerms) {
        tracker = `${tracker}=>8`;
        history.push(navRoutes.SPEND_VENUE.ACCEPT_TC);
        return tracker;
      }
      tracker = `${tracker}=>9`;
      history.push(navRoutes.GENERAL.ACCEPT_TERMS);
      return tracker;

    case roles.TEMPO_STAFF_ADMIN:
    case roles.TEMPO_STAFF_USER:
    case roles.TEMPO_STAFF_SUPER:
      history.push(navRoutes.TEMPO.DASHBOARD);
      break;

    case roles.SPEND_PARTNER:
      tracker = `${tracker}=>10`;
      if (data.spendPartner.acceptedTerms) {
        tracker = `${tracker}=>11`;
        if (data.selectedSpendVenue && data.selectedSpendVenue.id) {
          tracker = `${tracker}=>12`;

          history.push(navRoutes.SPEND_VENUE.ACCEPT_TC);
          return tracker;
        }
        tracker = `${tracker}=>13`;
        history.push(navRoutes.SPEND_PARTNER.SELECT_VENUE);
        return tracker;
      }
      tracker = `${tracker}=>14`;
      history.push(navRoutes.GENERAL.ACCEPT_TERMS);
      return tracker;

    default:
      tracker = `${tracker}=>15`;
      return tracker;
  }

  tracker = `${tracker}=>16`;
  return tracker;
};

export function redirectAfterLogin({
  data,
  earnGroupUserId,
  emailJustVerified,
  history,
}) {
  redirectAfterLoginWithTracker({
    data,
    earnGroupUserId,
    emailJustVerified,
    history,
  });
}

const redirectAfterSignupWithTracker = ({
  role,
  emailVerified,
  giftToken,
  history,
}) => {
  let tracker = "RAS";

  if (!emailVerified) {
    tracker = `${tracker}=>1`;
    history.push(navRoutes.GENERAL.ACTIVATE_ACCOUNT_EMAIL_SENT);
    return tracker;
  }
  if ([roles.TEMPO_STAFF_ADMIN, roles.TEMPO_STAFF_USER].includes(role)) {
    tracker = `${tracker}=>2`;

    history.push(navRoutes.TEMPO.DASHBOARD);
  } else if (role === roles.MEMBER) {
    // Analytics track members who completed sign up
    if (process.env.NODE_ENV === "production") {
      if (giftToken) {
        ReactGA.event({
          category: "Member",
          action: "Completed sign up (with gift link)",
        });
      } else {
        ReactGA.event({
          category: "Member",
          action: "Completed sign up (no gift link)",
        });
      }
    }

    tracker = `${tracker}=>3`;
    history.push(navRoutes.GENERAL.CONTINUE_SIGN_UP);
  } else if (role === roles.PROGRAMME_MANAGER) {
    tracker = `${tracker}=>4`;
    history.push(navRoutes.PROGRAMME_MANAGER.DASHBOARD);
  } else {
    // Analytics track earn groups who completed sign up
    if (process.env.NODE_ENV === "production" && roles.EARN_GROUP === role) {
      ReactGA.event({
        category: "Earn Group",
        action: "Completed sign up",
      });
    }
    tracker = `${tracker}=>5`;
    history.push(navRoutes.GENERAL.WELCOME);
  }
  tracker = `${tracker}=>6`;
  return tracker;
};

export function redirectAfterSignup({
  role,
  emailVerified,
  giftToken,
  history,
}) {
  redirectAfterSignupWithTracker({
    role,
    emailVerified,
    giftToken,
    history,
  });
}
