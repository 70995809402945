import React from "react";

import * as S from "./style";
import * as T from "../../Typograpy";
import Icon from "../../Icon";

const EarnActivity = ({
  category,
  categoryName,
  subtitle,
  index,
  handleClick,
  selected,
  m,
  noBackground,
  noButton,
  w,
}) => (
  // const card = earnActivityCategories.find((e) => e.category === category);

  <>
    {noButton ? (
      <S.Wrapper m={m} noBackground={noBackground} w={w}>
        <S.IconWrapper className="icon" index={index}>
          <Icon icon={category} width="24px" height="24px" color="white" />
        </S.IconWrapper>
        <S.Body>
          <T.H16 color="midnight">{categoryName}</T.H16>
          <T.Body14R color="gray3">{subtitle}</T.Body14R>
        </S.Body>
      </S.Wrapper>
    ) : (
      <S.ButtonWrapper
        onClick={handleClick}
        selected={selected}
        m={m}
        noBackground={noBackground}
        w={w}
      >
        <S.IconWrapper className="icon" index={index}>
          <Icon icon={category} width="24px" height="24px" color="white" />
        </S.IconWrapper>
        <S.Body>
          <T.H16 color="midnight">{categoryName}</T.H16>
          <T.Body14R color="gray3">{subtitle}</T.Body14R>
        </S.Body>
      </S.ButtonWrapper>
    )}
  </>
);
export default EarnActivity;
