import React from "react";
import { connect } from "react-redux";

import * as S from "./style";
import * as T from "../Typograpy";
import Icon from "../Icon";

import translate from "../../utils/helpers/translator";

const TimeCredit = ({
  description,
  subDescription,
  credits,
  costVaries,
  size,
  label,
  color,
  gradient,
  m,
  language,
  viewLanguage,
}) => (
  <>
    {label && (
      <T.H20 color="midnight" mb="15">
        {translate("TCNumber", viewLanguage || language)}
      </T.H20>
    )}
    <S.Wrapper m={m}>
      <S.Number
        size={size}
        description={description}
        color={color}
        credits={credits}
        costVaries={costVaries}
      >
        {credits < 100 && (
          <Icon
            icon={gradient ? "creditCircleGradient" : "creditCircle"}
            custom={{ position: "absolute" }}
            color={color}
          />
        )}
        {costVaries ? "TC" : credits}
      </S.Number>
      {description && (
        <S.Description size={size}>
          <T.H16Caps color={color} style={{ lineHeight: "20px" }}>
            {translate(
              costVaries && size !== "small" ? "costs" : "time",
              viewLanguage || language,
            )}{" "}
            {!subDescription && <br />}{" "}
            {costVaries && size !== "small"
              ? translate("vary", viewLanguage || language)
              : translate(
                  credits === 1 ? "creditsNumInFront" : "credits",
                  viewLanguage || language,
                )}
          </T.H16Caps>
          {subDescription && (
            <T.Body12R color={color} m="0" style={{ lineHeight: "15px" }}>
              {costVaries && size === "small"
                ? translate("vary", viewLanguage || language)
                : translate("perPerson", viewLanguage || language)}
            </T.Body12R>
          )}
        </S.Description>
      )}
    </S.Wrapper>
  </>
);

const mapStateToProps = (state) => ({
  language: state.auth.decideLanguage(state.auth),
});

export default connect(mapStateToProps)(TimeCredit);
