import styled from "@material-ui/core/styles/styled";

export const Container = styled("div")(() => ({
  width: "100%",
}));

export const ImageWrapper = styled("div")({
  minWidth: 250,
  height: 210,
  right: 0,
  position: "absolute",
});

export const Form = styled("form")(() => ({
  width: "100%",
}));

export const AddButton = styled("button")(() => ({
  outline: "none",
  border: "none",
  background: "none",
  cursor: "pointer",
}));
