import { styled } from "@material-ui/core/styles";

export const Wrapper = styled("form")(({ theme }) => ({
  background: "none",
  [theme.breakpoints.tablet]: {
    marginTop: "-85px",
  },
}));

export const EarnHeader = styled("header")(() => ({
  width: "100%",
  padding: 0,
}));

export const Content = styled("section")(() => ({}));

export const Options = styled("div")(() => ({
  display: "flex",
  flexWrap: "wrap",
}));

export const PageButton = styled("button")(({ prev }) => ({
  background: "none",
  border: "none",
  cursor: "pointer",
  margin: prev ? "0 auto 0 0" : "0 0 0 auto",
}));

export const NoData = styled("span")(({ theme }) => ({
  paddingLeft: theme.spacing(2),

  [theme.breakpoints.tablet]: {
    paddingLeft: "0",
  },
}));

export const SortByWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));
