import React from "react";

import { connect } from "react-redux";
import { SmallTabWrapper, TabSmall } from "./style";
import * as T from "../Typograpy";
import t from "../../utils/helpers/translator";

const TabsSmall = ({
  tabs,
  selected,
  handleClick,
  color,
  width,
  tabLineWidth,
  tabSpace,
  language,
  disabledTabs = [],
  smallFont,
}) => {
  const Text = smallFont ? T.Body14B : T.Body16B;
  return (
    <SmallTabWrapper width={width}>
      {tabs.map((tab) => (
        <TabSmall
          selected={selected === tab}
          onClick={() => handleClick(tab)}
          left
          tabSpace={tabSpace}
          tabLineWidth={tabLineWidth}
          type="button"
          key={tab}
          disabled={disabledTabs?.includes(tab)}
        >
          <Text color={selected === tab ? color || "midnight" : "gray3"}>
            {t(tab, language)}
          </Text>
        </TabSmall>
      ))}
    </SmallTabWrapper>
  );
};

const mapStateToProps = (state) => ({
  language: state.auth.decideLanguage(state.auth),
});

export default connect(mapStateToProps, {})(TabsSmall);
