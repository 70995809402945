import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Image from "../../../components/Image";
import * as S from "./style";
import Button from "../../../components/Button";
import { EARN_GROUP } from "../../../constants/navRoutes";
import t from "../../../utils/helpers/translator";

const PaymentSuccess = ({ language }) => {
  const history = useHistory();
  const onClick = () => {
    history.push(EARN_GROUP.DASHBOARD);
  };
  return (
    <S.SuccessWrapper>
      <Image
        image="successLarge"
        alt="success"
        customStyle={{ maxWidth: "100%" }}
      />
      <S.StyledHeader>
        <S.H40Caps style={{ textAlign: "center" }}>
          {t("paymentSuccessful", language).toUpperCase()}!
        </S.H40Caps>
      </S.StyledHeader>
      <S.Body18R mt="20" style={{ textAlign: "center" }}>
        {t("paymentSuccessfulMessage", language)}
      </S.Body18R>
      <Button primary size="l" mt="50" onClick={onClick}>
        {t("viewDashboard", language)}
      </Button>
    </S.SuccessWrapper>
  );
};

const mapStateToProps = (state) => ({
  language: state.auth.language,
});

export default connect(mapStateToProps)(PaymentSuccess);
