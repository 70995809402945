import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { MTableToolbar } from "material-table";
import * as S from "./style";
import * as T from "../Typograpy";
import Icon from "../Icon";
import ViewMoreLink from "../Links/ViewMoreLink";

import Pagination from "./components/CustomPagination";
import { TabsLarge } from "../Tabs";

import Loading from "../Loading";
import t from "../../utils/helpers/translator";
import {
  searchFieldStyle,
  cellStyle,
  rowStyle,
  style,
  headerStyle,
} from "./style";

const decideTableHeight = (dataLength, pageSize) =>
  dataLength < pageSize ? dataLength : pageSize;

const Table = ({
  color,
  columns,
  data,
  onRowClick,
  hideSearch,
  preview,
  previewPath,
  memberMobile,
  limit = 20,
  isLoading,
  tabbedData,
  searchPlaceholder,
  language,
  noPagination,
}) => {
  const [dataToShow, setDataToShow] = useState(null);
  const [load, setLoad] = useState(false);
  const selectTab = (tabText) => {
    if (["key info", "pending"].includes(tabText)) {
      setDataToShow(tabbedData[0]);
    } else {
      setDataToShow(tabbedData[1]);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      if (tabbedData) {
        setDataToShow(tabbedData[0]);
      } else {
        setDataToShow({ columns, data });
      }
      setLoad(true);
    } else {
      setDataToShow({ columns: [], data: [] });
      setLoad(false);
    }
  }, [isLoading, tabbedData, columns, data]);

  if (!load)
    return (
      <S.TableContainer>
        {/* render another instance for loading to force render the table
          to show new rows have been updated */}
        <S.LoadingStyledTable
          columns={[]}
          data={[]}
          options={{
            searchFieldStyle,
            rowStyle: rowStyle(color, memberMobile),
            cellStyle: cellStyle(color),
            headerStyle: headerStyle(color, memberMobile),
            toolbar: !hideSearch,
            showTitle: false,
            searchFieldAlignment: "left",
          }}
          style={style}
          isLoading
          localization={{
            body: {
              emptyDataSourceMessage: <div />,
            },
          }}
          components={{
            Pagination: (props) => {
              if (noPagination) return null;
              const { page, count, onChangePage } = props;
              if (preview)
                return (
                  <ViewMoreLink
                    path={previewPath}
                    showIcon
                    showText
                    padding="20px 0"
                    tableMobile={memberMobile}
                  />
                );
              return (
                <Pagination
                  page={page}
                  count={count}
                  onChangePage={onChangePage}
                  color={color}
                  limit={limit}
                  language={language}
                />
              );
            },
            OverlayLoading: () => (
              <div>
                <Loading center />
              </div>
            ),
          }}
        />
      </S.TableContainer>
    );

  return (
    <S.TableContainer>
      {tabbedData && (
        <S.TabContainer>
          <TabsLarge
            tabOneText={tabbedData[0].tabText || "key info"}
            tabTwoText={tabbedData[1].tabText || "other"}
            handleClick={selectTab}
            selected={dataToShow.tabText}
            width="300px"
          />
        </S.TabContainer>
      )}
      <S.StyledTable
        columns={dataToShow.columns}
        data={dataToShow.data}
        onRowClick={onRowClick}
        rowsPerPageOptions={[]}
        localization={{
          toolbar: {
            searchPlaceholder: searchPlaceholder || t("search", language),
          },
          body: {
            emptyDataSourceMessage: (
              <T.H16
                color="gray3"
                style={{
                  padding: "20px",
                  textAlign: "center",
                }}
              >
                {t("noRecordsToDisplay", language)}
              </T.H16>
            ),
          },
        }}
        options={{
          pageSize: preview
            ? decideTableHeight(dataToShow.data.length, 5)
            : decideTableHeight(dataToShow.data.length, limit),
          showTitle: false,
          toolbar: !hideSearch,
          searchFieldAlignment: "left",
          sorting: true,
          grouping: false,
          defaultExpanded: true,
          searchFieldStyle,
          rowStyle: rowStyle(color, memberMobile),
          cellStyle: cellStyle(color),
          headerStyle: headerStyle(color, memberMobile),
        }}
        style={style}
        icons={{
          SortArrow: () => null,
          DetailPanel: (props) => (
            <div>
              <Icon icon="arrowUp" {...props} width="15px" height="15px" />
            </div>
          ),
        }}
        components={{
          Toolbar: (props) => (
            <S.ToolbarContent tabbedData={tabbedData}>
              <MTableToolbar {...props} />
            </S.ToolbarContent>
          ),

          Pagination: (props) => {
            if (noPagination) return null;
            const { page, count, onChangePage } = props;
            if (preview)
              return (
                <ViewMoreLink
                  path={previewPath}
                  showIcon
                  showText
                  padding="40px 0 0 5px"
                  tableMobile={memberMobile}
                />
              );
            return (
              <Pagination
                page={page}
                count={count}
                onChangePage={onChangePage}
                color={color}
                limit={limit}
                language={language}
              />
            );
          },
        }}
      />
    </S.TableContainer>
  );
};

const mapStateToProps = (state) => ({
  language: state.auth.language,
});

export default connect(mapStateToProps)(Table);
