import { styled } from "@material-ui/core/styles";

export const FeedbackLink = styled("button")(({ theme, lang }) => ({
  position: "fixed",
  right: lang === "english" ? "-10px" : "-15px",
  bottom: "200px",
  textAlign: "center",
  cursor: "pointer",
  zIndex: 9998,
  transform: "rotate(-90deg)",
  transformOrigin: "bottom right", // Set the transform origin to the bottom right
  height: "50px",
  background: theme.colors.midnight,
  color: theme.colors.white,
  padding: theme.spacing(1),
  paddingBottom: theme.spacing(3),
  border: "none",
  borderRadius: theme.borders.radius,
  display: "flex",
  transition: "all ease 0.1s",
  outline: "none",

  "&:hover": {
    right: lang === "english" ? "-8px" : "-13px",
  },
}));
