import React from "react";
import { connect } from "react-redux";

import * as S from "./style";
import * as T from "../../Typograpy";
import TimeCredit from "../../TimeCredit";
import stringCutter from "../../../utils/helpers/stringCutter";

import t from "../../../utils/helpers/translator";

const EarnSpendOpportunity = ({
  imgUrl,
  title,
  subtitle,
  venue,
  cities,
  credits,
  disabled,
  path,
  customWidth,
  type,
  m,
  p,
  isOnline,
  featuredAt,
  costVaries,
  language,
  soldOut,
}) => {
  const decideAdress = () => {
    if (isOnline === true) {
      return t("online", language);
    }
    if (cities && cities.length === 1) {
      return cities[0];
    }
    if (cities && cities.length > 1) {
      return t("multipleLocations", language);
    }
    return "";
  };

  return (
    <S.Wrapper to={disabled ? "#" : path} customWidth={customWidth} m={m} p={p}>
      {featuredAt ? <S.Featured>★ Featured</S.Featured> : null}
      <S.Header imgUrl={imgUrl} style={{ backgroundSize: "cover" }} />
      <S.Body extraRightPadding={type === "spend"}>
        {type === "spend" && (
          <T.H18 color="midnight" style={{ textTransform: "uppercase" }}>
            {stringCutter(title, 20)}
          </T.H18>
        )}
        {type === "earn" && (
          <T.H16 color="midnight">{stringCutter(title, 45)}</T.H16>
        )}
        {type === "spend" && subtitle && (
          <T.H18 color="midnight" style={{ textTransform: "uppercase " }}>
            - {stringCutter(subtitle, 17)}
          </T.H18>
        )}
        <T.H16 color={type === "spend" ? "blue" : "midnight"}>{venue}</T.H16>
        <T.Body14R color="gray3">{decideAdress()}</T.Body14R>
        {type === "spend" && (
          <S.TimeCredits>
            <TimeCredit
              credits={credits}
              costVaries={costVaries}
              size="small"
            />
          </S.TimeCredits>
        )}
      </S.Body>
      <S.RestrictionLayer soldOut={soldOut}>
        <S.RestrictionTag>
          <T.Body14B color="white">
            {t("unavailable", language).toUpperCase()}
          </T.Body14B>
        </S.RestrictionTag>
      </S.RestrictionLayer>
    </S.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  language: state.auth.decideLanguage(state.auth),
});

export default connect(mapStateToProps, {})(EarnSpendOpportunity);
