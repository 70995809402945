import {
  GET_PROGRAMMES_BASIC_ADMIN_LOADING,
  GET_PROGRAMMES_BASIC_ADMIN_SUCCESS,
  GET_PROGRAMMES_BASIC_ADMIN_FAIL,
} from "../types";

const initState = {
  loading: false,
  data: [],
  error: null,
};

const programmes = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    // PROGRAMMES
    case GET_PROGRAMMES_BASIC_ADMIN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_PROGRAMMES_BASIC_ADMIN_SUCCESS:
      return {
        ...state,
        data: payload,
        loading: false,
      };
    case GET_PROGRAMMES_BASIC_ADMIN_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default programmes;
