import axios from "axios";
import handleError from "./handle-errors";

const TK_GROUPS_BASE = "/tk-integration/earn-groups";

const getTkGroupsRequests = async ({
  limit,
  offset,
  status,
  options = {},
} = {}) => {
  try {
    const { data } = await axios.get(`${TK_GROUPS_BASE}`, {
      params: {
        limit,
        offset,
        status,
      },
    });
    // const data = [
    //   {
    //     userId: 3,
    //     date: "17/01/2023",
    //     name: "Rita John",
    //     earnGroup: { name: "Movers and Shakers", id: 3 },
    //     email: "earn-group@tempo.com",
    //     programmeId: 1,
    //     programmeName: "Programme 1",
    //     tkId: "123ABC",
    //     hasConflict: true,
    //   },
    //   {
    //     userId: 3,
    //     date: "17/01/2023",
    //     name: "Rita John",
    //     earnGroup: { name: "Aura Volunteering", id: 4 },
    //     email: "earn-group@tempo.com",
    //     programmeId: 2,
    //     programmeName: "Programme 2",
    //     tkId: "456ABC",
    //     hasConflict: false,
    //   },
    // ];
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getTkConflictedProgrammesByProgrammeId = async ({
  userId,
  approvalRequestId,
  options = {},
} = {}) => {
  try {
    const { data } = await axios.get(
      `${TK_GROUPS_BASE}/${approvalRequestId}/programmes/${userId}`,
    );
    // const data = [
    //   {
    //     earnGroups: "0",
    //     endDate: "17/12/2022",
    //     gifts: "0",
    //     id: 2,
    //     mainFocus: "Health & Social Care",
    //     members: "0",
    //     name: "Programme 2",
    //     region: "Mid Wales",
    //     spent: "0%",
    //     startDate: "15/04/2020",
    //     status: "inactive",
    //   },
    //   {
    //     earnGroups: "1",
    //     endDate: "01/01/2022",
    //     gifts: "2",
    //     id: 1,
    //     mainFocus: "Rights, Religion & Equality",
    //     members: "2",
    //     name: "Programme 1",
    //     region: "Yorkshire and the Humber",
    //     spent: "20%",
    //     startDate: "01/02/2020",
    //     status: "inactive",
    //   },
    // ];
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getTkGroupConflictDataByUserId = async ({ id, options = {} }) => {
  try {
    const { data } = await axios.get(`${TK_GROUPS_BASE}/${id}`);
    // const data = [
    //   {
    //     earnGroup: { name: "earnGroup", id: 1 },
    //     programmeName: "programme1",
    //     programmeId: 1,
    //     email: "eg-15905@tempo.com",
    //   },
    //   {
    //     earnGroup: {
    //       name: "G.BEATZ NIGHTCLUB CIC",
    //       id: 17150,
    //     },
    //     programmeName: "programme",
    //     programmeId: 10,
    //     email: "email@example.com",
    //   },
    // ];

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const TkMergeGroups = async ({ id, userId, options = {} } = {}) => {
  try {
    const { data } = await axios.patch(`${TK_GROUPS_BASE}/${id}/merge`, {
      userId,
    });
    // const data = [
    //   {
    //     earnGroups: "0",
    //     endDate: "17/12/2022",
    //     gifts: "0",
    //     id: 2,
    //     mainFocus: "Health & Social Care",
    //     members: "0",
    //     name: "Programme 2",
    //     region: "Mid Wales",
    //     spent: "0%",
    //     startDate: "15/04/2020",
    //     status: "inactive",
    //   },
    //   {
    //     earnGroups: "1",
    //     endDate: "01/01/2022",
    //     gifts: "2",
    //     id: 1,
    //     mainFocus: "Rights, Religion & Equality",
    //     members: "2",
    //     name: "Programme 1",
    //     region: "Yorkshire and the Humber",
    //     spent: "20%",
    //     startDate: "01/02/2020",
    //     status: "inactive",
    //   },
    // ];
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const approveRejectGroup = async ({
  id,
  userId,
  status,
  name,
  email,
  options = {},
} = {}) => {
  try {
    const { data } = await axios.patch(
      `${TK_GROUPS_BASE}/${id}/approve-reject`,
      {
        userId,
        status,
        id,
        name,
        email,
      },
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const assignProgramme = async ({
  groupUserId,
  approvalRequestId,
  programmeId,
  options = {},
}) => {
  try {
    const { data } = await axios.patch(
      `${TK_GROUPS_BASE}/${approvalRequestId}/programmes/${groupUserId}`,
      { programmeId },
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export {
  getTkGroupsRequests,
  getTkConflictedProgrammesByProgrammeId,
  getTkGroupConflictDataByUserId,
  TkMergeGroups,
  approveRejectGroup,
  assignProgramme,
};
