import React from "react";
import moment from "moment";

import { TEMPO } from "../../../../constants/navRoutes";
import { tempoRoles } from "../../../../constants/roles";

import * as S from "./style";
import * as T from "../../../../components/Typograpy";

import { Row, Col } from "../../../../components/Grid";
import Button from "../../../../components/Button";
import Stats from "../../../../components/Stats";
import Loading from "../../../../components/Loading";
import DownloadFile from "../../../../components/DownloadFile";

const DetailsAndStatsSection = ({
  id,
  details,
  stats,
  history,
  loading,
  role,
  managers,
}) => (
  <>
    <Row mb={5}>
      <Col w={[4, 12, 9]}>
        <S.Section>
          <T.H24 color="midnight" ml="10" mb="40">
            Programme Details
          </T.H24>
          {loading ? (
            <Loading />
          ) : (
            <>
              <Row mb={3}>
                <Col w={[2, 4, 4]}>
                  <T.Body18S color="gray2">Start Date</T.Body18S>
                </Col>
                <Col w={[2, 8, 8]}>
                  <T.Body16R color="gray2">
                    {details.startDate
                      ? moment(details.startDate).format("DD/MM/YYYY")
                      : "N/A"}
                  </T.Body16R>
                </Col>
              </Row>
              <Row mb={3}>
                <Col w={[2, 4, 4]}>
                  <T.Body18S color="gray2">End Date</T.Body18S>
                </Col>
                <Col w={[2, 8, 8]}>
                  <T.Body16R color="gray2">
                    {details.endDate
                      ? moment(details.endDate).format("DD/MM/YYYY")
                      : "N/A"}
                  </T.Body16R>
                </Col>
              </Row>
              <Row mb={3}>
                <Col w={[2, 4, 4]}>
                  <T.Body18S color="gray2">Key Focuses</T.Body18S>
                </Col>
                <Col w={[2, 8, 8]}>
                  {details.focuses ? (
                    details.focuses.map((focus) => (
                      <T.Body16R color="gray2">{focus}</T.Body16R>
                    ))
                  ) : (
                    <T.Body16R color="gray2">N/A</T.Body16R>
                  )}
                </Col>
              </Row>
              <Row mb={3}>
                <Col w={[2, 4, 4]}>
                  <T.Body18S color="gray2">
                    External Programme Manager
                  </T.Body18S>
                </Col>
                <Col w={[2, 8, 8]}>
                  {managers && managers.length ? (
                    managers.map((manager) => (
                      <T.Body16R color="gray2">{manager}</T.Body16R>
                    ))
                  ) : (
                    <T.Body16R color="gray2">N/A</T.Body16R>
                  )}
                </Col>
              </Row>
              <Row>
                <Col w={[2, 4, 4]}>
                  <T.Body18S color="gray2">Team Kinetic ID</T.Body18S>
                </Col>
                <Col w={[2, 8, 8]}>
                  <T.Body16R color="gray2">
                    {details.tkId ? details.tkId : "N/A"}
                  </T.Body16R>
                </Col>
              </Row>
            </>
          )}
        </S.Section>
      </Col>
    </Row>
    {[tempoRoles.TEMPO_STAFF_ADMIN, tempoRoles.TEMPO_STAFF_SUPER].includes(
      role,
    ) && (
      <Row>
        <Col w={[4, 4, 3]}>
          <Button
            outline
            m="0"
            onClick={() =>
              history.push(TEMPO.EDIT_PROGRAMME.replace(":id", id))
            }
          >
            Edit Programme
          </Button>
        </Col>
        <Col w={[4, 4, 3]}>
          <DownloadFile
            url={`/csv/programmes/${id}/members-demographics`}
            text="Export Volunteer Demographics"
            buttonProps={{ style: { marginTop: "0" } }}
          />
        </Col>
      </Row>
    )}
    <Row mt={8}>
      <Col w={[4, 8, 8]} style={{ paddingLeft: 0 }}>
        <T.H24Caps color="midnight" mb="20">
          Programme Data
        </T.H24Caps>
      </Col>
    </Row>
    <Row mb={8}>
      <S.StatSection color="pinkLight">
        <Stats
          size="s"
          statNum={stats.issued}
          statText="Issued"
          statNumColor="pink"
          isLoading={loading}
        />
        <Stats
          size="s"
          statNum={stats.earned}
          statText="earned"
          statNumColor="pink"
          isLoading={loading}
        />
        <Stats
          size="s"
          statNum={stats.spendPercVenues}
          statText="% used with venues"
          statNumColor="pink"
          isLoading={loading}
          bottomMessage="the % of Time Credits in a programme that have been earned or issued directly to volunteers that have been used with Venues"
        />
        <Stats
          size="s"
          statNum={stats.spendPercEarnGroups}
          statText="% used with earn groups"
          statNumColor="pink"
          isLoading={loading}
          bottomMessage="the % of Time Credits in a programme that have been earned or issued directly to volunteers that have been used with Earn Groups"
        />
        <Stats
          size="s"
          statNum={stats.gifted}
          statText="gifted"
          statNumColor="pink"
          isLoading={loading}
        />
      </S.StatSection>
    </Row>
  </>
);

export default DetailsAndStatsSection;
