import React from "react";

import Button from "../Button";
import fileSizeFormatter from "../../utils/helpers/fileSizeFormatter";
import useCsvDownload from "../../hooks/useCsvDownload";

import * as S from "./style";

const DownloadFile = ({
  url,
  buttonProps,
  text,
  disabled,
  width = "200px",
}) => {
  const [data, handleClick] = useCsvDownload(url);

  const { size, loading, error } = data;
  return (
    <div>
      <Button
        type="button"
        onClick={handleClick}
        loading={loading}
        style={{ width, marginTop: "30px", marginLeft: 0 }}
        disabled={disabled}
        {...buttonProps}
      >
        {text || "Export"}
      </Button>
      {error ? (
        <S.Error>{error}</S.Error>
      ) : (
        <S.Downloading>
          {loading
            ? "Searching & downloading data: ..."
            : !!size && "Downloaded: "}
          {!!size && fileSizeFormatter(size)}
        </S.Downloading>
      )}
    </div>
  );
};

export default DownloadFile;
