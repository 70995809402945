import React from "react";
import * as S from "../style";
import * as T from "../../Typograpy";

import t from "../../../utils/helpers/translator";

const decideColor = (type) => {
  if (
    ["spend", "rejected", "returning", "given out", "givenOut"].includes(type)
  ) {
    return "pink";
  }
  if (type === "gift") {
    return "purple";
  }
  if (["issue", "issued"].includes(type)) {
    return "gray2";
  }
  if (type === "pending") {
    return "cyan";
  }
  return "blue";
};

const ActivityDate = ({ activity, date, type, language }) => (
  <span>
    <T.Body14S style={{ textTransform: "uppercase" }}>{activity}</T.Body14S>
    <S.DateTypeWrapper>
      <T.Body12R color="gray3" style={{ margin: "0" }}>
        {date}
      </T.Body12R>
      <T.Body12B
        color={decideColor(type)}
        style={{
          textTransform: "uppercase",
          margin: "0 10px",
          minWidth: "max-content",
          width: "max-content",
        }}
      >
        {language ? t(type, language) : type}
      </T.Body12B>
    </S.DateTypeWrapper>
  </span>
);

export default ActivityDate;
