import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MomentUtils from "@date-io/moment";

import * as S from "./style";
import * as T from "../../../components/Typograpy";
import Stats from "../../../components/Stats";

import t from "../../../utils/helpers/translator";

import { getInsights } from "./actions";

const StatSection = (props) => {
  const [activeTab, setActiveTab] = useState("month");
  const tabClick = (value) => {
    setActiveTab(value);
  };

  const { insights, createdAt, getInsightsAction, language } = props;

  const joinDate = new MomentUtils().parse(createdAt).format("DD/MM/YYYY");

  useEffect(() => {
    getInsightsAction(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <S.StatsSection>
      <S.Tabs>
        <S.Tab onClick={() => tabClick("month")} active={activeTab === "month"}>
          <T.Body18S color="midnight" style={{ lineHeight: "25px" }}>
            {t("thisMonth", language)}
          </T.Body18S>
        </S.Tab>
        <S.Tab onClick={() => tabClick("year")} active={activeTab === "year"}>
          <T.Body18S color="midnight" style={{ lineHeight: "25px" }}>
            {t("thisYear", language)}
          </T.Body18S>
        </S.Tab>
        <S.Tab onClick={() => tabClick("all")} active={activeTab === "all"}>
          <T.Body18S color="midnight">{t("allTime", language)}</T.Body18S>
          <T.Body14R style={{ marginLeft: "10px" }}>
            ({t("since", language)} {joinDate})
          </T.Body14R>
        </S.Tab>
      </S.Tabs>

      <S.StatsWrapper>
        <Stats
          size="l"
          statNumColor="blue"
          statNum={insights.givenOut}
          statTextColor="midnight"
          statText={t("tcGivenOut", language)}
          margin="0 0 40px 0"
        />
        <Stats
          size="l"
          statNumColor="blue"
          statNum={insights.spentByMembers}
          statTextColor="midnight"
          statText={t("tcSpentByMembers", language)}
          margin="0 0 40px 0"
        />
        <Stats
          size="l"
          statNumColor="blue"
          statNum={insights.givenByMembers}
          statTextColor="midnight"
          statText={t("tcGivenToOthers", language)}
          margin="0 0 40px 0"
        />
        <Stats
          size="l"
          statNumColor="blue"
          statNum={insights.signedUp}
          statTextColor="midnight"
          statText={t("newMembers", language)}
          margin="0 0 40px 0"
        />
      </S.StatsWrapper>
    </S.StatsSection>
  );
};

const mapStateToProps = (state) => ({
  insights: state.earnGroupDashboard.insights,
  createdAt: state.auth.profile.createdAt,
  language: state.auth.language,
});

const mapActionToProps = {
  getInsightsAction: getInsights,
};

export default connect(mapStateToProps, mapActionToProps)(StatSection);
