import { fields, createSchema, validate as _validate } from "..";
import { url } from "../fields";

const {
  text,
  textRequired,
  requiredText,
  arrRequired,
  personTcPrice,
  personTcPriceOptional,
  booleanValue,
  booleanNullable,
  callExtraDetails,
  dateTimes,
  dateTimesOptional,
  bookingCodes,
  bookingCodesOptional,
  useCodesLink,
  useCodesDetails,
  atLeastOneWayToSpend,
  endDate,
  arrOptional,
  optionalText,
  optionalNumber,
  optionalNumberGreaterThan0,
  isLive,
} = fields;

const Step1Fields = {
  name: requiredText(100),
  subTitle: text(60),
  description: requiredText(300),
  activityTypes: arrRequired,
  personTcPrice,
  costVaries: booleanValue,
  isOnline: booleanValue,
  imageKey: textRequired,
  keywords: arrOptional,
  externalLink: url,
};

const Step1FieldsDraft = {
  name: requiredText(100),
  subTitle: text(60),
  description: optionalText(300),
  activityTypes: arrOptional,
  personTcPrice: personTcPriceOptional,
  costVaries: booleanValue,
  isOnline: booleanValue,
  imageKey: optionalText(),
  keywords: arrOptional,
  externalLink: url,
};

const Step1FieldsWelsh = {
  ...Step1Fields,
  nameWelsh: requiredText(100),
  subTitleWelsh: text(60),
  descriptionWelsh: requiredText(300),
  externalLinkWelsh: url,
};

const Step1FieldsWelshDraft = {
  ...Step1FieldsDraft,
  nameWelsh: requiredText(100),
  subTitleWelsh: text(60),
  descriptionWelsh: optionalText(300),
  externalLinkWelsh: url,
};

const Step2Fields = {
  endDate,
  restrictions: optionalText(500),
  restrictionsWelsh: optionalText(500),
  isAvailableAnyTime: booleanValue,
  dateTimes,
  capacity: optionalNumberGreaterThan0,
  capacityMonth: optionalNumberGreaterThan0,
  capacityVolunteerMonth: optionalNumberGreaterThan0,
  capacityWeek: optionalNumberGreaterThan0,
  capacityVolunteerWeek: optionalNumberGreaterThan0,
  capacityVolunteer: optionalNumberGreaterThan0,
  capacitySlot: optionalNumberGreaterThan0,
  capacityPeoplePerTransaction: optionalNumberGreaterThan0,
};

const Step2FieldsDraft = {
  endDate,
  restrictions: optionalText(500),
  restrictionsWelsh: optionalText(500),
  isAvailableAnyTime: booleanValue,
  dateTimes: dateTimesOptional,
  capacity: optionalNumberGreaterThan0,
  capacityMonth: optionalNumberGreaterThan0,
  capacityWeek: optionalNumberGreaterThan0,
  capacityVolunteer: optionalNumberGreaterThan0,
  capacitySlot: optionalNumberGreaterThan0,
  capacityPeoplePerTransaction: optionalNumberGreaterThan0,
};

const Step3Fields = {
  isBookingRequiredForGroups: booleanNullable,
  maxGroupSize: optionalNumber,
  furtherInformation: optionalText(500),
  furtherInformationWelsh: optionalText(500),
};

const Step4Fields = {
  isBookingRequiredForGroups: booleanNullable,
  callExtraDetails,
  useCodesLink,
  bookingCodes,
  atLeastOneWayToSpend,
  useCodesDetails,
  isLive,
};

const Step4FieldsDraft = {
  isBookingRequiredForGroups: booleanNullable,
  callExtraDetails: optionalText(300),
  useCodesLink,
  bookingCodes: bookingCodesOptional,
  useCodesDetails: optionalText(800),
  isLive,
};

const Step4FieldsWelsh = {
  ...Step4Fields,
  callExtraDetailsWelsh: callExtraDetails,
  useCodesDetailsWelsh: useCodesDetails,
};

const Step4FieldsWelshDraft = {
  ...Step4FieldsDraft,
  callExtraDetailsWelsh: optionalText(300),
  useCodesDetailsWelsh: optionalText(300),
};

const allStepsFields = {
  0: Step1Fields,
  1: Step2Fields,
  2: Step3Fields,
  3: Step4Fields,
};

const allStepsFieldsDraft = {
  0: Step1FieldsDraft,
  1: Step2FieldsDraft,
  2: Step3Fields,
  3: Step4FieldsDraft,
};

const allStepsFieldsWithWelsh = {
  0: Step1FieldsWelsh,
  1: Step2Fields,
  2: Step3Fields,
  3: Step4FieldsWelsh,
};

const allStepsFieldsWithWelshDraft = {
  0: Step1FieldsWelshDraft,
  1: Step2FieldsDraft,
  2: Step3Fields,
  3: Step4FieldsWelshDraft,
};

export const validateAll = (data, visitedSteps) => {
  const allFields = data.welshVersion
    ? allStepsFieldsWithWelsh
    : allStepsFields;
  const allFieldsDraft = data.welshVersion
    ? allStepsFieldsWithWelshDraft
    : allStepsFieldsDraft;

  const _allStepsFields = data.isLive ? allFields : allFieldsDraft;
  const createdSchemas = visitedSteps.map((step) => ({
    schema: createSchema(_allStepsFields[step]),
    step,
  }));

  let errors = {}; // reset errors
  const stepsStatuses = {}; // reset steps statuses
  let oneStepHasError = false;

  createdSchemas.forEach(({ schema, step }) => {
    try {
      _validate(schema, { ...data, cloneEndDate: data.endDate });
      stepsStatuses[step] = { error: false, completed: true };
    } catch (error) {
      stepsStatuses[step] = { error: true, completed: false };
      errors = { ...errors, ...error.inner };
      oneStepHasError = true;
    }
  });

  if (errors.endDate && !errors.isLive) {
    errors.isLive = errors.endDate;
  }

  if (errors.isLive && !errors.endDate) {
    errors.endDate = errors.isLive;
  }

  return { errors, stepsStatuses, oneStepHasError };
};
