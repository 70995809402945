import React from "react";

import * as S from "./style";

const Divider = ({ color, bgColor, h, w, vertical, m }) => (
  <S.Line
    color={color}
    h={h}
    w={w}
    vertical={vertical}
    m={m}
    bgColor={bgColor}
  />
);

export default Divider;
