import styled from "@material-ui/core/styles/styled";

export const Container = styled("div")(() => ({
  width: "100%",
}));

export const Head = styled("header")({
  display: "flex",
  justifyContent: "space-between",
});

export const TableContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  [theme.breakpoints.mobileXL]: {
    transform: "translateY(-130px)",
  },

  [theme.breakpoints.laptopL]: {
    transform: "translateY(0px)",
  },
}));

export const ImageWrapper = styled("div")({
  minWidth: 250,
  height: 210,
  transform: "translate(30px, -30px)",
});

export const HeadContent = styled("div")({
  display: "flex",
  flexDirection: "column",
});
