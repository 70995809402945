import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import * as T from "../../../components/Typograpy";
import { Dropdown } from "../../../components/Inputs";
import { Regular } from "../../../components/Button";
import translate from "../../../utils/helpers/translator";
import { navRoutes } from "../../../constants";

import { formateDropDownList } from "./utils";
import * as A from "./actions";
import * as S from "./style";

function SelectUser({
  selectUsers,
  getSelectUser,
  selectActiveUser,
  credentialId,
  lang,
  isMobile,
}) {
  const [activeUser, setActiveUser] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (credentialId) {
      getSelectUser();
    } else {
      history.push(navRoutes.GENERAL.LOG_IN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentialId, lang]);

  const handleSubmit = () => {
    selectActiveUser({ activeUserId: activeUser.userId, history });
  };

  const dropdownData = formateDropDownList(selectUsers.users);

  return (
    <S.Wrapper>
      <S.Body>
        <T.H40Caps color="midnight" align="left" mb="40">
          {translate("selectYourAccount", lang)}
        </T.H40Caps>
        <Dropdown
          name="members"
          options={dropdownData}
          value={activeUser}
          customSelectFunc={setActiveUser}
          placeholder={translate("selectAccount", lang)}
          label={translate("account", lang)}
        />
        {selectUsers.error && (
          <S.Error>{translate(selectUsers.error.message, lang)}</S.Error>
        )}
        <S.ButtonErrorContainer>
          <Regular
            primary
            bgColor="blue"
            customWidth={!isMobile ? "300px" : undefined}
            handleClick={handleSubmit}
            loading={selectUsers.loading}
            w={!isMobile ? "80%" : undefined}
            m="40px 0 0"
            type="submit"
            disabled={!activeUser || selectUsers.loading}
          >
            {translate("confirm", lang)}
          </Regular>
        </S.ButtonErrorContainer>
      </S.Body>
    </S.Wrapper>
  );
}

const mapStateToProps = (state) => ({
  selectUsers: state.selectUsers,
  credentialId: state.auth.credentialId,
  isMobile: state.ui.isMobile,
  lang: state.auth.language,
});

const mapActionToProps = {
  selectActiveUser: A.selectActiveUser,
  getSelectUser: A.getSelectUser,
};

export default connect(mapStateToProps, mapActionToProps)(SelectUser);
