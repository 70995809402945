// FILE CURRENTLY NOT USED BUT KEPT IN CASE WE WANT TO SHOW HISTORY IN FUTURE

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import * as S from "./style";
import * as T from "../../../components/Typograpy";

import { MEMBER } from "../../../constants/navRoutes";

import Icon from "../../../components/Icon";

import Button from "../../../components/Button";

import GiftTable from "./GiftTable";

import * as actions from "./actions";

const GiftHistory = ({ getGifts, gifts, getGiftsLoaded, cancelGift }) => {
  const history = useHistory();

  useEffect(() => {
    getGifts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => history.push(MEMBER.DASHBOARD);

  const handleCancel = (id) => {
    cancelGift(id);
  };

  return (
    <S.Wrapper>
      <S.Header>
        <Icon
          icon="gift"
          color="pink"
          width="70px"
          height="70px"
          margin="0 20px 0 0"
        />
        <T.H30Caps color="midnight" style={{ width: "80%" }}>
          PREVIOUS GIFTS
        </T.H30Caps>
      </S.Header>
      {getGiftsLoaded && (
        <GiftTable gifts={gifts} handleCancel={handleCancel} />
      )}
      <Button primary onClick={handleClick} mt={40} size="l">
        Go to home page
      </Button>
    </S.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  gifts: state.giftTimeCredits.gifts,
  getGiftsLoaded: state.giftTimeCredits.getGiftsLoaded,
});

const mapActionToProps = {
  getGifts: actions.getGifts,
  cancelGift: actions.cancelGift,
};

export default connect(mapStateToProps, mapActionToProps)(GiftHistory);
