import React from "react";

import { Row, Col } from "../../components/Grid";

import * as T from "../../components/Typograpy";

import { InputField, RadioInput } from "../../components/Inputs";
import t from "../../utils/helpers/translator";

const WhatToKnow = ({
  isBookingRequiredForGroups,
  maxGroupSize,
  furtherInformation,
  handleText,
  errors,
  language,

  // Welsh
  welshVersion,
  furtherInformationWelsh,
}) => (
  <>
    <Row>
      <Col w={[4, 12, 8]}>
        <T.H24 color="midnight" m="0 0 15px 0">
          {t("WhatTheyNeedToKnow", language)}
        </T.H24>
        <T.Body16R color="gray3" m="0 0 30px 0">
          {t("WhatTheyNeedToKnowMoreInfo", language)}
        </T.Body16R>
      </Col>
    </Row>
    <Row mb={5}>
      <Col w={[4, 12, 6]}>
        <RadioInput
          options={[
            { label: t("yes", language), value: "true" },
            { label: t("no", language), value: "false" },
          ]}
          label={t("isBookingRequiredForGroups", language)}
          name="isBookingRequiredForGroups"
          value={
            isBookingRequiredForGroups === null
              ? null
              : isBookingRequiredForGroups?.toString()
          }
          setValue={handleText}
          isOptional
          lang={language}
        />
      </Col>
    </Row>
    <Row mb={5}>
      <Col w={[4, 12, 6]}>
        <InputField
          placeholder={t("typeNumber", language)}
          label={t("maxGroupSizeLabel", language)}
          name="maxGroupSize"
          value={maxGroupSize}
          setValue={handleText}
          error={errors.maxGroupSize}
          justify="center"
          type="number"
          isOptional
          lang={language}
        />
      </Col>
    </Row>
    <Row mb={4}>
      <Col w={[4, 12, 6]}>
        <InputField
          placeholder={`${t("typeHere", language)}...`}
          label={`${t("addAnyFurtherInfo", language)}${
            language === "welsh" ? ` (${t("english", language)})` : ""
          }`}
          name="furtherInformation"
          type="textarea"
          value={furtherInformation}
          setValue={handleText}
          error={errors.furtherInformation}
          justify="center"
          limit={300}
          showLimit
          helper={t("furtherInfoHelper", language)}
          isOptional
          lang={language}
        />
      </Col>
      {welshVersion && (
        <Col w={[4, 12, 6]}>
          <InputField
            placeholder={`${t("typeHere", language)}...`}
            label={`${t("addAnyFurtherInfo", language)}${
              language === "english" ? ` (${t("welsh", language)})` : ""
            }`}
            labelColor="blue"
            name="furtherInformationWelsh"
            type="textarea"
            value={furtherInformationWelsh}
            setValue={handleText}
            error={errors.furtherInformationWelsh}
            justify="center"
            limit={300}
            showLimit
            helper={t("furtherInfoHelper", language)}
            isOptional
            lang={language}
          />
        </Col>
      )}
    </Row>
  </>
);

export default React.memo(WhatToKnow);
