import {
  CHECK_ACTIVATE_ACCOUNT_TOKEN_LOADING,
  CHECK_ACTIVATE_ACCOUNT_TOKEN_SUCCESS,
  CHECK_ACTIVATE_ACCOUNT_TOKEN_FAIL,
  GET_ACTIVATE_ACCOUNT_TOKEN_LOADING,
  GET_ACTIVATE_ACCOUNT_TOKEN_SUCCESS,
  GET_ACTIVATE_ACCOUNT_TOKEN_FAIL,
  RESEND_ACTIVATE_ACCOUNT_TOKEN_LOADING,
  RESEND_ACTIVATE_ACCOUNT_TOKEN_SUCCESS,
  RESEND_ACTIVATE_ACCOUNT_TOKEN_FAIL,
} from "../../../redux/types";
import { redirectAfterLogin } from "../../../redux/utils";
import { auth } from "../../../api-calls";

export const checkActivateAccountToken =
  (token, history) => async (dispatch, getState) => {
    const userData = getState().auth;
    const { emailVerified } = userData;
    if (emailVerified) {
      redirectAfterLogin({ data: userData, history });
    }

    dispatch({ type: CHECK_ACTIVATE_ACCOUNT_TOKEN_LOADING });
    try {
      const { data, error } = await auth.checkActivateAccountToken({
        token,
      });

      if (!error) {
        if (data.redirectToTk) {
          window.location = data.redirectToTk;
          return;
        }

        dispatch({
          type: CHECK_ACTIVATE_ACCOUNT_TOKEN_SUCCESS,
          payload: data,
        });

        redirectAfterLogin({
          data: { ...userData, emailVerified: true },
          history,
          emailJustVerified: true,
        });
      } else if (error) {
        dispatch({
          type: CHECK_ACTIVATE_ACCOUNT_TOKEN_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: CHECK_ACTIVATE_ACCOUNT_TOKEN_FAIL,
        payload: error,
      });
    }
  };

export const getActivateAccountToken =
  ({ userId, history }) =>
  async (dispatch, getState) => {
    const userData = getState().auth;
    const { emailVerified } = userData;
    if (emailVerified) {
      redirectAfterLogin({ data: userData, history });
    }

    dispatch({ type: GET_ACTIVATE_ACCOUNT_TOKEN_LOADING });
    try {
      const { data, error } = await auth.getActivateAccountToken({
        userId,
      });

      if (!error) {
        dispatch({
          type: GET_ACTIVATE_ACCOUNT_TOKEN_SUCCESS,
          payload: data,
        });
      } else if (error) {
        dispatch({
          type: GET_ACTIVATE_ACCOUNT_TOKEN_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_ACTIVATE_ACCOUNT_TOKEN_FAIL,
        payload: error,
      });
    }
  };

export const resendActivateAccountToken = () => async (dispatch) => {
  dispatch({ type: RESEND_ACTIVATE_ACCOUNT_TOKEN_LOADING });
  try {
    const { data, error } = await auth.resendActivateAccountToken();

    if (!error) {
      dispatch({
        type: RESEND_ACTIVATE_ACCOUNT_TOKEN_SUCCESS,
        payload: data,
      });
    } else if (error) {
      dispatch({
        type: RESEND_ACTIVATE_ACCOUNT_TOKEN_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: RESEND_ACTIVATE_ACCOUNT_TOKEN_FAIL,
      payload: error,
    });
  }
};
