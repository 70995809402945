import React from "react";

import * as S from "./style";
import { EARN_GROUP } from "../../../constants/navRoutes";
import BigLink from "../../../components/Links/BigLink";
import t from "../../../utils/helpers/translator";

const LinkSection = ({ isTablet, language }) => (
  <S.LinkWrapper>
    <BigLink
      color="pink"
      text={t("giveOutTC", language)}
      path={EARN_GROUP.GIVE}
      lang={language}
    />
    <BigLink
      color="blue"
      text={t("getMoreTC", language)}
      path={EARN_GROUP.REQUEST}
      lang={language}
    />
    {isTablet ? (
      <>
        <BigLink
          color="pink"
          text={t("viewMembers", language)}
          path={EARN_GROUP.MEMBERS}
          lang={language}
        />
        <BigLink
          color="blue"
          text={t("inviteMembers", language)}
          path={EARN_GROUP.INVITE}
          lang={language}
        />
      </>
    ) : (
      <>
        <BigLink
          color="blue"
          text={t("inviteMembers", language)}
          path={EARN_GROUP.INVITE}
          lang={language}
        />
        <BigLink
          color="pink"
          text={t("viewMembers", language)}
          path={EARN_GROUP.MEMBERS}
          lang={language}
        />
      </>
    )}
  </S.LinkWrapper>
);

export default LinkSection;
