import React from "react";

const Gift = ({ width, height, color, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    {...props}
  >
    <path
      d="M3.765 11.53l-1.12 1.115a.5.5 0 000 .71l2.75 2.75a.501.501 0 00.71 0l1.395-1.4V21a.5.5 0 00.5.5h13a.5.5 0 00.5-.5V10a.5.5 0 00-.5-.5h-8.295l3.4-3.395a.501.501 0 000-.71l-2.75-2.75a.5.5 0 00-.71 0l-1.62 1.62a2.085 2.085 0 00-3.5-1L7.4 3.39a3.68 3.68 0 00-.95 3.55 3.68 3.68 0 00-3.55.95L2.77 8a2.1 2.1 0 000 2.96c.273.278.617.475.995.57zm7.38-1.885l-1.895 1.9L7.205 9.5l.9-.895 1.395-1.4 2.045 2.045-.4.395zm-5.395 5.4L3.705 13l2-2 .795-.795 2.045 2.045-.9.895-1.895 1.9zm2.75-1.34l1.105-1.1 2.1-2.105h.795v10h-4v-6.795zm12 6.795h-4v-10h4v10zm-5-10v10h-2v-10h2zM13 3.705l2.045 2.045-2.795 2.795L10.205 6.5l.275-.27L13 3.705zm-4.895.4L8.23 4a1.085 1.085 0 011.54 0 1.09 1.09 0 010 1.54l-.625.625L7.785 7.5a2.68 2.68 0 01.32-3.395zM3.5 8.73l.125-.125A2.68 2.68 0 017 8.285l-1 1-1 1a1.115 1.115 0 01-1.54 0 1.085 1.085 0 010-1.54l.04-.015z"
      fill={color || "currentColor"}
    />
  </svg>
);

export default Gift;
