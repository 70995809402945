import styled from "@material-ui/core/styles/styled";

export const PopContainer = styled("div")({
  width: "100%",
});

export const PopBtn = styled("button")({
  height: "30px",
  background: "none",
  boxShadow: "none",
  border: "none",
  outline: "none",
});
