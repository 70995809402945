import React, { useEffect, useState } from "react";

import { Row, Col } from "../../../../../../components/Grid";
import GoBack from "../../../../../../components/GoBack";
import { Body16R } from "../../../../../../components/Typograpy";

import { LANGUAGE_ERROR } from "../../../../../../validation/err-msgs";
import t from "../../../../../../utils/helpers/translator";

import * as S from "../style";

import Content from "./Content";

const NotAuthorized = ({ welshVersion, isTablet, language }) => {
  const [pageState, setPageState] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (welshVersion && language === "welsh") {
      setPageState({ languageError: null });
    } else if (!welshVersion && language === "welsh") {
      setPageState({
        languageError: t(LANGUAGE_ERROR, language),
      });
    } else {
      setPageState({ languageError: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <S.Wrapper>
      {isTablet && (
        <Row mb={6}>
          <Col w={[2, 6, 8]}>
            <GoBack
              showText
              lang={welshVersion && language === "welsh" && "welsh"}
            />
          </Col>
        </Row>
      )}
      {!isTablet && (
        <Row mb={6}>
          <Col w={[2, 6, 8]}>
            <GoBack
              showText
              lang={welshVersion && language === "welsh" && "welsh"}
            />
          </Col>
        </Row>
      )}
      {pageState && pageState.languageError && (
        <Body16R color="pink" style={{ marginBottom: 40 }}>
          {pageState.languageError}
        </Body16R>
      )}
      <Content isTablet={isTablet} lang={welshVersion ? language : "english"} />
    </S.Wrapper>
  );
};

export default NotAuthorized;
