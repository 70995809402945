import React from "react";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import * as S from "../style";
import * as T from "../../../../../../components/Typograpy";
import { Row, Col } from "../../../../../../components/Grid";
import Image from "../../../../../../components/Image";
import { GeneralList } from "../../../../../../components/List";
import { TextSection } from "../../../../../../components/TextSections";

import translate from "../../../../../../utils/helpers/translator";

import CreateAccount from "../CreateAccount";

const Content = ({
  hideImage,
  lang,
  isTablet,
  memberID,
  spendVenue = {},
  bookExtraDetails,
  isLoggedIn,
}) => {
  const { contactNumber, contactEmail } = spendVenue;
  const theme = useTheme();

  const renderContactExplainer = () => {
    if (contactNumber && contactEmail) {
      return (
        <T.Body18S color="midnight">
          {translate("contactVenueByPhone", lang)}:{" "}
          <span style={{ color: theme.colors.pink }}>{contactNumber}</span>
          <br /> {translate("orByEmail", lang)}:{" "}
          <span
            style={{
              color: theme.colors.pink,
              wordWrap: "break-word !important",
              overflowWrap: "break-word",
              "-ms-word-break": "break-all",
              wordBreak: "break-word",
            }}
          >
            {contactEmail}
          </span>
        </T.Body18S>
      );
    }

    if (contactNumber) {
      return (
        <T.Body18S color="midnight">
          {contactNumber && translate("contactVenueByPhone", lang)}:{" "}
          <span style={{ color: theme.colors.pink }}>{contactNumber}</span>
        </T.Body18S>
      );
    }

    if (contactEmail) {
      return (
        <T.Body18S color="midnight">
          {contactEmail && translate("contactVenueByEmail", lang)}:{" "}
          <span style={{ color: theme.colors.pink }}>{contactEmail}</span>
        </T.Body18S>
      );
    }
    return (
      <T.Body18S color="midnight">
        {translate("contactVenueBefore", lang)}
      </T.Body18S>
    );
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <S.Header mt={hideImage && 9}>
        {!hideImage && (
          <Row>
            <Col w={[4, 0, 0]}>
              <Image image="officeLarge" alt="office" />
            </Col>
          </Row>
        )}
        <Row jcM="center">
          <Col w={[4, 8, 8]}>
            {isTablet ? (
              <T.H30Caps
                color="midnight"
                mb="10"
                style={{ textTransform: "uppercase", paddingTop: 20 }}
              >
                {translate("bookAdvance", lang)}
              </T.H30Caps>
            ) : (
              <T.H40Caps color="midnight" mb="10">
                {translate("bookAdvance", lang)}
              </T.H40Caps>
            )}
          </Col>
        </Row>
      </S.Header>
      <Row mb={!hideImage ? 5 : 2}>
        <Col w={[4, 8, 8]}>
          <GeneralList
            customTitleElem={renderContactExplainer()}
            m={isMobile ? 0 : "0 0 20px 0"}
          />
          <GeneralList
            title={translate("tellThemActivity", lang)}
            subtitle={`(${translate("includeDates", lang)})`}
            graphic="graphic2"
            m={isMobile ? 0 : "0 0 20px 0"}
          />
          <GeneralList
            graphic="graphic3"
            customTitleElem={
              <T.Body18S color="midnight">
                {translate("giveID", lang)}
                {memberID && ": "}
                {memberID && (
                  <span style={{ color: theme.colors.pink, fontWeight: 900 }}>
                    {memberID}
                  </span>
                )}
              </T.Body18S>
            }
            m={isMobile ? 0 : "0 0 20px 0"}
          />
          <GeneralList
            title={`${translate("theyWillTakeTC", lang)}. ${translate(
              "haveFun",
              lang,
            )}`}
            graphic="graphic4"
            m="0 0 20px 0"
          />
          <TextSection
            title={translate("furtherInfo", lang)}
            text={bookExtraDetails}
          />
        </Col>
        {!hideImage && (
          <Col w={[0, 4, 4]}>
            <Image image="officeLarge" alt="office" />
          </Col>
        )}
      </Row>
      {!isLoggedIn && <CreateAccount lang={lang} />}
    </>
  );
};

const mapStateToProps = (state) => ({
  memberID: state.auth.profile.membershipId,
  isLoggedIn: state.auth.isLoggedIn,
});

export default connect(mapStateToProps, null)(Content);
