import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import { Col, Row } from "../../../components/Grid";
import { InputField } from "../../../components/Inputs";
import Loading from "../../../components/Loading";
import Notification from "../../../components/Notification";
import * as T from "../../../components/Typograpy";
import { GENERAL } from "../../../constants/navRoutes";
import t from "../../../utils/helpers/translator";

import { changePassword } from "../../../redux/actions/auth";

import validateChangePassword from "../../../validation/schemas/changePassword";
import { device, subHeadlineComponents } from "./data";

const PasswordChange = (props) => {
  const { userAccount, auth, changePasswordAction, isMobile, language } = props;

  const [showMsg, setShowMsg] = useState(false);
  const [errors, setErrors] = useState({});
  const [passwordChangeState, setPasswordChangeState] = useState({});

  const validateNewPassword = (newPassword) => {
    try {
      validateChangePassword({
        newPassword,
      });
      setErrors({});
      return true;
    } catch (error) {
      setErrors(error.inner);
      return false;
    }
  }

  const handlePasswordChange = async ({ e, state }) => {
    e.preventDefault();
    const { oldPassword, newPassword } = state;

    if (!validateNewPassword(newPassword)) {
      return;
    }

    const { error } = await changePasswordAction({
      oldPassword,
      newPassword,
      credentialId: auth.credentialId,
    });

    if (error) {
      setErrors((e) => ({
        ...e,
        changePassword: "Incorrect old password",
      }));
    } else {
      setPasswordChangeState({});
      setShowMsg(true);
    }
  };

  const showNotification = (message) => (
    <Notification
      open={showMsg}
      setOpen={setShowMsg}
      message={message}
      duration={2500}
    />
  );

  const showResetPW = () => (
    <>
      <Link to={`${GENERAL.FORGET_PASSWORD}`}>
        <T.LinkB16
          style={{
            marginTop: "1rem",
            marginLeft: "15px",
          }}
          color="blue"
        >
          {t("resetPassword", language)}
        </T.LinkB16>
      </Link>{" "}
    </>
  );

  const SubHeadline = subHeadlineComponents[device(isMobile)];

  if (userAccount.getAccountLoading) {
    return <Loading />;
  }

  return (
    <>
      <Row>
        <Col w={[4, 12, 12]}>
          <SubHeadline
            color="midnight"
            style={{
              marginTop: isMobile ? "2.5rem" : "3.5rem",
              fontWeight: "900",
            }}
          >
            {t("password", language)}
          </SubHeadline>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 6, 6]}>
          <InputField
            mt={!isMobile ? 6 : 4}
            label={t("currentPassword", language)}
            type="password"
            placeholder={`${t("typeCurrentPassword", language)}...`}
            name="oldPassword"
            setValue={(e) =>
              setPasswordChangeState({
                ...passwordChangeState,
                [e.target.name]: e.target.value,
              })
            }
            value={passwordChangeState.oldPassword || ""}
            error={errors.oldPassword}
          />
        </Col>
        <Col w={[4, 6, 6]}>
          <InputField
            mt={!isMobile ? 6 : 4}
            label={t("newPassword", language)}
            type="password"
            placeholder={`${t("typeNewPassword", language)}...`}
            name="newPassword"
            setValue={(e) => {
              setPasswordChangeState({
                ...passwordChangeState,
                [e.target.name]: e.target.value,
              });
              validateNewPassword(e.target.value);
            }}
            value={passwordChangeState.newPassword || ""}
            error={errors.newPassword}
          />
        </Col>
      </Row>
      <Row>
        <Col
          style={{
            alignSelf: "center",
            paddingTop: isMobile ? "1rem" : "0.5rem",
          }}
          w={[4, 6, 6]}
        >
          {showResetPW()}
        </Col>
      </Row>
      <Row>
        <Col
          style={{
            marginTop: !isMobile ? "60px" : "40px",
          }}
          w={[4, 6, 6]}
        >
          <Button
            primary
            size="l"
            type="button"
            handleClick={(e) =>
              handlePasswordChange({ e, state: passwordChangeState })
            }
            disabled={
              (passwordChangeState.oldPassword || "").length === 0 ||
              (passwordChangeState.newPassword || "").length === 0
            }
            loading={auth.changePasswordLoading}
          >
            {t("changePassword", language)}
          </Button>
          {showNotification(t("passwordChanged", language))}
        </Col>
      </Row>
      {errors.changePassword && (
        <Row>
          <Col w={[4, 8, 8]}>
            <T.Body16R color="pink">{t("invalidPassword", language)}</T.Body16R>
          </Col>
        </Row>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  userAccount: state.userAccount,
  language: state.auth.language,
  auth: state.auth,
});

const mapActionToProps = {
  changePasswordAction: changePassword,
};

export default connect(mapStateToProps, mapActionToProps)(PasswordChange);
