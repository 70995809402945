import {
  GET_PENDING_REQUESTS_LOADING_START,
  GET_PENDING_REQUESTS_LOADING_END,
  GET_PENDING_REQUESTS_SUCCESS,
  GET_PENDING_REQUESTS_FAIL,
  GET_PREV_REQUESTS_LOADING_START,
  GET_PREV_REQUESTS_LOADING_END,
  GET_PREV_REQUESTS_SUCCESS,
  GET_PREV_REQUESTS_FAIL,
  SELECT_CREDITS_REQUEST,
  GET_SINGLE_REQUEST_SUCCESS,
  GET_SINGLE_REQUEST_FAIL,
  GET_MEMBER_INFO_TO_ISSUEE_TC_LOADING_START,
  GET_MEMBER_INFO_TO_ISSUEE_TC_LOADING_END,
  GET_MEMBER_INFO_TO_ISSUEE_TC_SUCCESS,
  GET_MEMBER_INFO_TO_ISSUEE_TC_FAIL,
  GET_EARN_GROUP_INFO_TO_ISSUEE_TC_LOADING_START,
  GET_EARN_GROUP_INFO_TO_ISSUEE_TC_LOADING_END,
  GET_EARN_GROUP_INFO_TO_ISSUEE_TC_SUCCESS,
  GET_EARN_GROUP_INFO_TO_ISSUEE_TC_FAIL,
  ISSUE_TC_LOADING_START,
  ISSUE_TC_LOADING_END,
  RESET_ISSUE_TC_STATE,
  ISSUE_TC_FAIL,
} from "../../../../redux/types";

const initState = {
  pendingError: {},
  pendingLoading: false,
  pendingRequests: [],
  pendingRequestsLoaded: false,
  prevError: {},
  prevLoading: false,
  prevRequests: [],
  prevRequestsLoaded: false,
  selectedUser: {},
  getError: {},
  getLoading: false,
  issueTcLoading: false,
  issueError: {},
  requestId: "",
  creditsRequested: "",
  recipientName: "",
  programmeId: null,
  programmes: [],
};

const getName = (user = {}) => {
  const { firstName = "", lastName = "" } = user;
  return `${firstName} ${lastName}`;
};

const creditRequestsReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PENDING_REQUESTS_LOADING_START:
      return {
        ...state,
        pendingLoading: true,
        pendingError: {},
        pendingRequestsLoaded: false,
      };

    case GET_PENDING_REQUESTS_LOADING_END:
      return {
        ...state,
        pendingLoading: false,
      };

    case GET_PENDING_REQUESTS_SUCCESS:
      return {
        ...state,
        pendingRequestsLoaded: true,
        pendingRequests: payload.map((e) => ({
          id: e.id,
          earnGroup: e.name,
          earnGroupID: e.userId,
          creditsRequested: e.requestedCredits,
          date: e.createdAt,
          earnGroupBalance: e.sumCurrentTc,
          programmeName: e.programmeName,
          programmeId: e.programmeId,
          isTkRequest: !!e.tkRequestId,
        })),
      };

    case GET_PENDING_REQUESTS_FAIL:
      return {
        ...state,
        pendingError: payload,
      };

    case GET_PREV_REQUESTS_LOADING_START:
      return {
        ...state,
        prevLoading: true,
        prevError: {},
        prevRequestsLoaded: false,
      };

    case GET_PREV_REQUESTS_LOADING_END:
      return {
        ...state,
        prevLoading: false,
      };

    case GET_PREV_REQUESTS_SUCCESS:
      return {
        ...state,
        prevRequestsLoaded: true,
        prevRequests: payload.map((e) => ({
          id: e.id,
          earnGroup: e.name,
          earnGroupID: e.userId,
          creditResponse: e.status,
          creditsRequested: e.requestedCredits,
          creditsIssued: e.issuedTc || 0,
          by: getName(e.respondedBy),
          date: e.createdAt,
          responseDate: e.responseDate || e.issueDate,
          isTkRequest: !!e.tkRequestId,
        })),
      };

    case GET_PREV_REQUESTS_FAIL:
      return {
        ...state,
        prevError: payload,
      };

    case SELECT_CREDITS_REQUEST:
      return {
        ...state,
        recipientName: payload.earnGroup || "N/A",
        recipientID: payload.earnGroupID,
        recipientBalance: payload.earnGroupBalance || 0,
        creditsRequested: payload.creditsRequested || 0,
        date: payload.date,
        userId: "",
        requestId: payload.id,
        programmeId: payload.programmeId,
        programmeName: payload.programmeName,
        isTkRequest: !!payload.tkRequestId,
      };

    case GET_SINGLE_REQUEST_SUCCESS:
      return {
        ...state,
        recipientName: payload.name,
        recipientID: payload.userId,
        recipientBalance: payload.sumCurrentTc,
        creditsRequested: payload.requestedCredits,
        date: payload.createdAt,
        userId: "",
        requestId: payload.id,
        programmeId: payload.programmeId,
        programmeName: payload.programmeName,
        isTkRequest: !!payload.tkRequestId,
      };

    case GET_SINGLE_REQUEST_FAIL:
      return {
        ...state,
        getError: payload,
      };

    case GET_MEMBER_INFO_TO_ISSUEE_TC_SUCCESS:
      return {
        ...state,
        requestId: "",
        creditsRequested: null,
        recipientName: `${payload.firstName} ${payload.lastName}`,
        recipientID: payload.id,
        programmes: payload.programmes,
      };

    case GET_MEMBER_INFO_TO_ISSUEE_TC_FAIL:
      return {
        ...state,
        getError: payload,
      };

    case GET_EARN_GROUP_INFO_TO_ISSUEE_TC_SUCCESS:
      return {
        ...state,
        requestId: "",
        creditsRequested: null,
        recipientName: payload.profile.name,
        recipientID: payload.id,
        programmeName: payload.programmeName,
        programmeId: payload.programmeId,
        //   recipientBalance: payload.sumCurrentTc,
        //   date: payload.createdAt,
        //   userId: "",
        //   id: payload.id,
      };

    case GET_EARN_GROUP_INFO_TO_ISSUEE_TC_FAIL:
      return {
        ...state,
        getError: payload,
      };

    case GET_MEMBER_INFO_TO_ISSUEE_TC_LOADING_START:
    case GET_EARN_GROUP_INFO_TO_ISSUEE_TC_LOADING_START:
      return {
        ...state,
        getLoading: true,
        getError: {},
      };

    case GET_MEMBER_INFO_TO_ISSUEE_TC_LOADING_END:
    case GET_EARN_GROUP_INFO_TO_ISSUEE_TC_LOADING_END:
      return {
        ...state,
        getLoading: false,
        getError: {},
      };

    case ISSUE_TC_LOADING_START:
      return {
        ...state,
        issueTcLoading: true,
      };
    case ISSUE_TC_LOADING_END:
      return {
        ...state,
        issueTcLoading: false,
      };

    case ISSUE_TC_FAIL:
      return {
        ...state,
        issueError: payload,
      };

    case RESET_ISSUE_TC_STATE:
      return {
        ...state,
        ...initState,
      };
    default:
      return state;
  }
};

export default creditRequestsReducer;
