import React from "react";
import * as S from "./style";
import * as T from "../Typograpy";
import Image from "../Image";

const EarnActivityList = ({ title, subtitle, graphic }) => (
  <S.Wrapper>
    <Image
      image={graphic || "graphic1"}
      alt="graphic"
      width="60px"
      height="60px"
    />
    <S.Details>
      <T.H18Caps color="midnight" style={{ fontWeight: "900" }}>
        {title}
      </T.H18Caps>
      <T.Body14R color="gray3">{subtitle}</T.Body14R>
    </S.Details>
  </S.Wrapper>
);

export default EarnActivityList;
