import React from "react";

import { Row, Col } from "../../../components/Grid";

import {
  InputField,
  Checkbox,
  Dropdown,
  AutoComplete,
} from "../../../components/Inputs";

import { regionsDropDownEnglish } from "../../../constants/dropdDownData";

const VenueAddress = ({
  handleInput,
  handleRegion,
  handleCheckbox,
  handleValueChange,
  citesAndCounties,
  formData,
  errors,
  isMobile,
  welshVersion,
}) => {
  const {
    addressLine1,
    addressLine2,
    city = "",
    county = "",
    addressLine1Welsh,
    addressLine2Welsh,
    cityWelsh = "",
    countyWelsh = "",
    region,
    regionCountry,
    postcode,
    isOnline,
  } = formData;

  const selectedRegion =
    (region &&
      regionCountry && {
        country: regionCountry,
        label: region,
        value: region,
      }) ||
    undefined;

  return (
    <>
      {!isOnline && (
        <>
          <Row mb={5}>
            <Col w={[4, 6, 4]}>
              <InputField
                placeholder="Type first line of address..."
                label="Address Line 1"
                name="addressLine1"
                value={addressLine1}
                setValue={handleInput}
                error={errors.addressLine1}
                justify="center"
              />
            </Col>
            <Col w={[4, 6, 4]}>
              <InputField
                placeholder="Type second line of address..."
                label="Address Line 2"
                name="addressLine2"
                value={addressLine2}
                setValue={handleInput}
                error={errors.addressLine2}
                justify="center"
                isOptional
              />
            </Col>
          </Row>
          <Row mb={5}>
            <Col w={[4, 6, 4]}>
              <AutoComplete
                placeholder="Type the town or city..."
                label="Town / City"
                name="city"
                value={city}
                error={errors.city}
                justify="center"
                fullWidth
                blurOnSelect
                options={(citesAndCounties?.EnglishCitiesList?.cities || [])
                  .sort()
                  .map((text) => ({ name: text, value: text }))}
                setValue={({ value }) =>
                  handleValueChange("city", value?.value || value)
                }
                freeSolo
              />
            </Col>
            <Col w={[4, 6, 4]}>
              <Dropdown
                placeholder="Select region..."
                label="Region"
                name="region"
                options={regionsDropDownEnglish}
                value={selectedRegion}
                setValue={({ selected }) => handleRegion(selected, "region")}
                error={errors.region}
                blurInputOnSelect={false}
                justify="center"
              />
            </Col>
          </Row>
          <Row mb={5}>
            <Col w={[4, 6, 4]}>
              <AutoComplete
                placeholder="Type county..."
                label="County / London borough"
                name="county"
                value={county}
                error={errors.county}
                justify="center"
                isOptional
                fullWidth
                blurOnSelect
                options={(citesAndCounties?.EnglishCitiesList?.counties || [])
                  .sort()
                  .map((text) => ({ name: text, value: text }))}
                setValue={({ value }) =>
                  handleValueChange("county", value?.value || value)
                }
                freeSolo
              />
            </Col>
            <Col w={[4, 6, 4]}>
              <InputField
                placeholder="Type postcode..."
                label="Postcode"
                name="postcode"
                value={postcode}
                setValue={handleInput}
                error={errors.postcode}
                justify="center"
              />
            </Col>
          </Row>
          {welshVersion && (
            <>
              <Row mb={5} mt={3}>
                <Col w={[4, 6, 4]}>
                  <InputField
                    placeholder="Type first line of address..."
                    label="Address Line 1 (Welsh)"
                    name="addressLine1Welsh"
                    value={addressLine1Welsh}
                    setValue={handleInput}
                    error={errors.addressLine1Welsh}
                    justify="center"
                    labelColor="blue"
                  />
                </Col>
                <Col w={[4, 6, 4]}>
                  <InputField
                    placeholder="Type second line of address..."
                    label="Address Line 2 (Welsh)"
                    name="addressLine2Welsh"
                    value={addressLine2Welsh}
                    setValue={handleInput}
                    error={errors.addressLine2Welsh}
                    justify="center"
                    labelColor="blue"
                    isOptional
                  />
                </Col>
              </Row>
              <Row mb={5}>
                <Col w={[4, 6, 4]}>
                  <AutoComplete
                    placeholder="Type the town or city..."
                    label="Town / City (Welsh)"
                    name="cityWelsh"
                    value={cityWelsh}
                    error={errors.cityWelsh}
                    justify="center"
                    labelColor="blue"
                    fullWidth
                    blurOnSelect
                    options={(citesAndCounties?.WelshCitiesList?.cities || [])
                      .sort()
                      .map((text) => ({ name: text, value: text }))}
                    setValue={({ value }) =>
                      handleValueChange("cityWelsh", value?.value || value)
                    }
                    freeSolo
                  />
                </Col>
                <Col w={[4, 6, 4]}>
                  <AutoComplete
                    placeholder="Type county..."
                    label="County / London borough (Welsh)"
                    name="countyWelsh"
                    value={countyWelsh}
                    error={errors.countyWelsh}
                    justify="center"
                    labelColor="blue"
                    isOptional
                    fullWidth
                    blurOnSelect
                    options={(citesAndCounties?.WelshCitiesList?.counties || [])
                      .sort()
                      .map((text) => ({ name: text, value: text }))}
                    setValue={({ value }) =>
                      handleValueChange("countyWelsh", value?.value || value)
                    }
                    freeSolo
                  />
                </Col>
              </Row>
            </>
          )}
        </>
      )}
      <Row mb={8}>
        <Col w={[4, 8, 8]}>
          <Checkbox
            value={isOnline}
            setValue={handleCheckbox}
            mt="10"
            label="This venue is online only"
            name="isOnline"
            size={isMobile ? "normal" : "medium"}
          />
        </Col>
      </Row>
    </>
  );
};

export default VenueAddress;
