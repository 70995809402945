import {
  GET_MEMBERS_SUCCESS,
  GET_MEMBERS_FAIL,
  GET_MEMBERS_LOADING_START,
  GET_MEMBERS_LOADING_END,
  // GIVE
  GIVE_MEMBER_LOADING_START,
  GIVE_MEMBER_LOADING_END,
  GIVE_MEMBER_LOADING_SUCCESS,
  GIVE_MEMBER_LOADING_FAIL,
  // give bulk
  GIVE_BULK_MEMBER_LOADING_START,
  GIVE_BULK_MEMBER_LOADING_END,
  // reset
  RESET_GIVE_TC,
  // get recent transactions
  GET_EARN_GROUP_TRANSACTIONS_LOADING_START,
  GET_EARN_GROUP_TRANSACTIONS_SUCCESS,
  GET_EARN_GROUP_TRANSACTIONS_FAIL,
} from "../../../redux/types";

import { members, transactions } from "../../../api-calls";

import { EARN_GROUP } from "../../../constants/navRoutes";

export const getMembersBasicInfo = () => async (dispatch) => {
  dispatch({ type: GET_MEMBERS_LOADING_START });
  const { data, error } = await members.getMembers({ basic: true });

  if (data) {
    const shapeMembers = data.map((member) => ({
      id: member.id,
      value: member.id,
      label: `${member.firstName} ${member.lastName}`,
    }));

    dispatch({
      type: GET_MEMBERS_SUCCESS,
      payload: shapeMembers,
    });
  } else if (error) {
    dispatch({
      type: GET_MEMBERS_FAIL,
      payload: error,
    });
  }

  dispatch({ type: GET_MEMBERS_LOADING_END });
};

export const giveOutTC =
  ({
    membersData,
    activityCategory,
    activityCategoryName,
    activityName,
    startDate,
    endDate,
    history,
  }) =>
  async (dispatch) => {
    const errors = [];
    dispatch({ type: GIVE_BULK_MEMBER_LOADING_START });
    let requestsResponded = 0;

    for (let i = 0; i < membersData.length; i += 1) {
      const { id, credits } = membersData[i];
      dispatch({ type: GIVE_MEMBER_LOADING_START, payload: id });

      // eslint-disable-next-line no-await-in-loop
      const { error } = await transactions.createTransaction({
        type: "earn",
        tc: credits,
        memberId: id,
        activityCategory,
        activityCategoryName,
        activityName,
        startDate,
        endDate,
      });

      requestsResponded += 1;

      if (!error) {
        dispatch({
          type: GIVE_MEMBER_LOADING_SUCCESS,
          payload: id,
        });
      } else {
        errors.push(error);

        dispatch({
          type: GIVE_MEMBER_LOADING_FAIL,
          payload: { id, error },
        });
      }
      dispatch({ type: GIVE_MEMBER_LOADING_END, payload: id });
      if (requestsResponded === membersData.length) {
        dispatch({ type: GIVE_BULK_MEMBER_LOADING_END });

        if (!errors.length) {
          history.push(EARN_GROUP.GIVE_SUCCESS);
        }
      }
    }
  };

export const reset = () => ({ type: RESET_GIVE_TC });

export const getRecentEarnGroupTransactions = () => async (dispatch) => {
  dispatch({ type: GET_EARN_GROUP_TRANSACTIONS_LOADING_START });
  const { data, error } = await transactions.getRecentEarnGroupTransactions({});

  if (!error) {
    dispatch({
      type: GET_EARN_GROUP_TRANSACTIONS_SUCCESS,
      payload: data,
    });
  } else {
    dispatch({
      type: GET_EARN_GROUP_TRANSACTIONS_FAIL,
      payload: error,
    });
  }
};
