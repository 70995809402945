import React from "react";

const Facebook = ({ width, height, color, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5 12.063c0-5.798-4.702-10.5-10.5-10.5S1.5 6.265 1.5 12.063c0 5.24 3.84 9.585 8.86 10.373V15.1H7.692v-3.036h2.666V9.75c0-2.63 1.568-4.085 3.966-4.085 1.15 0 2.351.205 2.351.205v2.584h-1.324c-1.304 0-1.712.81-1.712 1.641v1.97h2.912l-.465 3.036H13.64v7.337c5.02-.788 8.86-5.132 8.86-10.374z"
      fill={color || "currentColor"}
    />
  </svg>
);
export default Facebook;
