import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { device, headlineComponents, subHeadlineComponents } from "./data";

import AccountHolderLog from "./AccountHolderLog";
import { Row, Col } from "../Grid";
import Table from "../Table";
import RemoveRejoin from "../RemoveRejoin";
import Notification from "../Notification";
import Button from "../Button";
import Modal from "../Modal";
import { InputField } from "../Inputs";
import * as T from "../Typograpy";
import Add from "../Add";
import WarningMessage from "../WarningMessage";

import * as actions from "./actions";
import * as myAccountActions from "../../redux/actions/userAccount";

import validate from "../../validation/schemas/administrators";

import { ADMINISTRATOR_LIMIT } from "../../constants/addUserLimits";
import { roles } from "../../constants";

import dateFormatter from "../../utils/helpers/dateFormatter";

import theme from "../../utils/theme";

import t from "../../utils/helpers/translator";

const { TEMPO_STAFF_ADMIN, TEMPO_STAFF_SUPER, TEMPO_STAFF_USER } = roles;

const AdministratorSection = ({
  isMobile,
  getEarnGroupAdmins,
  admins,
  id,
  role,
  earnGroupUserId,
  addNewAdmin,
  addError,
  promoteError,
  getLoading,
  deleteAdministrator,
  promoteEGAdmin,
  getEarnGroupAccountHolderLogs,
  getUserAccount,
  addLoading,
  language,
}) => {
  const [newEmail, setNewEmail] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [promoteAdmin, setPromoteAdmin] = useState(false);
  const [userToRemoveOrPromote, setUserToRemoveOrPromote] = useState({});
  const [errors, setErrors] = useState({});

  const Headline = headlineComponents[device(isMobile)];
  const SubHeadline = subHeadlineComponents[device(isMobile)];

  const remainingSpaces = () => ADMINISTRATOR_LIMIT - admins.length;
  const disableAdd = () => admins.length >= ADMINISTRATOR_LIMIT;

  const isTempoAdmin = [
    TEMPO_STAFF_ADMIN,
    TEMPO_STAFF_SUPER,
    TEMPO_STAFF_USER,
  ].includes(role);

  const handleEmail = (event) => {
    setNewEmail(event.target.value);
  };

  useEffect(() => {
    getEarnGroupAdmins(earnGroupUserId || id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [earnGroupUserId, id]);

  const removeAdministrator = async (rowData) => {
    setUserToRemoveOrPromote(rowData);
    setShowWarning(true);
  };
  const makeMainAccountHandle = async (rowData) => {
    setUserToRemoveOrPromote(rowData);
    setShowWarning(true);
    setPromoteAdmin(true);
  };

  const handlePromoteAdminSuccess = () => {
    setPromoteAdmin(false);
    if (!promoteError) {
      getEarnGroupAdmins(earnGroupUserId);
      getEarnGroupAccountHolderLogs({ earnGroupUserId });
      getUserAccount(earnGroupUserId, roles.EARN_GROUP);
    }
  };

  const handleWarningConfirm = async () => {
    try {
      let _notificationMsg = "adminSuccessfullyRemoved";
      if (promoteAdmin) {
        _notificationMsg = "adminSuccessChangeMainAccount";

        await promoteEGAdmin({
          earnGroupUserId,
          administratorId: userToRemoveOrPromote.id,
        });
      } else {
        // api call to remove administrator user's access to this earn group
        await deleteAdministrator({
          earnGroupUserId: earnGroupUserId || id,
          administratorId: userToRemoveOrPromote.id,
        });
      }

      setNotificationMsg(t(_notificationMsg, language));
      setShowNotification(true);
      setUserToRemoveOrPromote({});
      setErrors({});
    } catch (err) {
      setErrors({ removeError: t(err.message, language) });
    }
  };

  const addAdministrator = async () => {
    const cleanEmail = newEmail.toLowerCase().trim();
    try {
      validate({ email: cleanEmail });

      // api call to set up invite link and email to the user
      await addNewAdmin({
        email: cleanEmail,
        id: earnGroupUserId || id,
      });

      setNotificationMsg(t("woohooSent", language).toUpperCase());
      setShowNotification(true);
      setNewEmail("");
      setErrors({});
    } catch (err) {
      if (err.name === "ValidationError") {
        setErrors(err.inner);
      } else {
        setErrors({ addError: t(err.message, language) });
      }
    }
  };

  const columns = [
    {
      title: t("name", language),
      render: ({ firstName, lastName }) => `${firstName} ${lastName}`,
    },
    {
      title: t("email", language),
      render: ({ email }) => (
        <a href={`mailto: ${email}`}>
          <T.LinkS14 color="blue">{email}</T.LinkS14>
        </a>
      ),
    },
    {
      title: t(isTempoAdmin ? "dateLastGiveOutTc" : "dateLastTc", language),
      field: "lastIssued",
      render: (rowData) => <span>{dateFormatter(rowData.lastIssued)}</span>,
      cellStyle: {
        width: isTempoAdmin ? "20%" : "50%",
        fontSize: "0.875rem",
        fontWeight: "normal",
      },
    },
    {
      title: "",
      field: "removeRejoin",
      disableClick: true,
      render: (rowData) => (
        <RemoveRejoin
          type="Remove"
          handleClick={() => removeAdministrator(rowData)}
          showText={!isMobile}
          language={language}
        />
      ),
      cellStyle: {
        width: "120px",
        padding: 0,
      },
    },
  ];

  const administratorsExplanation = () => {
    const translatedText = t("administratorsExplanation", language, [
      admins.length,
      remainingSpaces(),
    ]).split(admins.length);
    const firstPart = translatedText[0];
    const [secondPart, thirdPart] = translatedText[1].split(remainingSpaces());

    return [
      firstPart,
      <b style={{ color: theme.colors.midnight }}>{admins.length} </b>,
      secondPart,
      <b style={{ color: theme.colors.midnight }}> {remainingSpaces()} </b>,
      thirdPart,
    ];
  };

  if (isTempoAdmin) {
    const makeMainAccountHolder = {
      title: "",
      field: "makeMainAccountHolder",
      disableClick: true,
      render: (rowData) => (
        <Button
          primary
          size="xs"
          handleClick={() => makeMainAccountHandle(rowData)}
          showText={!isMobile}
          language={language}
        >
          Make main account holder
        </Button>
      ),
      cellStyle: {
        padding: 0,
        paddingRight: "7%",
      },
    };
    columns.splice(2, 0, makeMainAccountHolder);
  }

  return (
    <>
      <Row mt={isMobile ? 8 : 9} mb={2}>
        <Col w={[4, 8, 8]}>
          <Headline color="midnight">{t("administrators", language)}</Headline>
        </Col>
      </Row>
      <Row mb={5}>
        <Col w={[4, 8, 8]}>
          <T.Body16R color="gray3">{administratorsExplanation()} .</T.Body16R>
        </Col>
      </Row>
      <Table
        isLoading={getLoading || addLoading}
        data={admins}
        columns={columns}
        hideSearch
      />
      {errors.removeError && (
        <Row mt={2}>
          <Col w={[4, 8, 8]}>
            <T.Body16R color="pink">{errors.removeError}</T.Body16R>
          </Col>
        </Row>
      )}
      <Row mb={3} mt={8} style={{ opacity: disableAdd() ? "0.5" : "1" }}>
        <Col w={[4, 8, 8]}>
          <SubHeadline color="blue">{t("addNewAdmin", language)}</SubHeadline>
        </Col>
      </Row>
      <Row ai="flex-end" style={{ opacity: disableAdd() ? "0.5" : "1" }}>
        <Col w={[4, 4, 4]}>
          <InputField
            label={t("emailAddress", language)}
            type="email"
            placeholder={`${t("typeEmailHere", language)}...`}
            setValue={handleEmail}
            value={newEmail}
            error={errors.email}
            disabled={disableAdd()}
          />
        </Col>
        <Col
          w={[4, 4, 4]}
          style={{
            display: "flex",
            alignItems: "center",
            height: "50px",
            marginBottom: errors.email && "31px",
          }}
        >
          <Add
            handleClick={addAdministrator}
            disabled={disableAdd()}
            isLoading={getLoading || addLoading}
          />
        </Col>
      </Row>
      {addError && addError.message && (
        <Row mt={2}>
          <Col w={[4, 8, 8]}>
            <T.Body16R color="pink">{t(addError.message, language)}</T.Body16R>
          </Col>
        </Row>
      )}
      {isTempoAdmin && <AccountHolderLog earnGroupUserId={earnGroupUserId} />}
      <Notification
        open={showNotification}
        setOpen={setShowNotification}
        message={notificationMsg}
        duration={2500}
      />
      <Modal
        modalOpen={!showWarning && promoteAdmin}
        confirmFun={handlePromoteAdminSuccess}
        handleCloseModal={() => {}}
        buttonText={t(promoteError ? "goBack" : "ok", language)}
        buttonStyle={{ width: 200, marginTop: 30 }}
        width="690px"
        loading={getLoading}
      >
        <T.H20 color="blue" mb={20}>
          {t("adminSuccessChangeMainAccount", language)}
        </T.H20>
        <T.Body14R
          style={{ maxWidth: 380 }}
          color={promoteError ? "pink" : "gray3"}
        >
          {promoteError
            ? t(promoteError.message, language)
            : "I should notify the earn group and also change the phone number on the account if that has also changed."}
        </T.Body14R>
      </Modal>
      <WarningMessage
        open={showWarning}
        setOpen={setShowWarning}
        handleModalClose={() => {
          setPromoteAdmin(false);
          setUserToRemoveOrPromote({});
        }}
        title={`${t(
          promoteAdmin ? "areYouSure" : "areYouSureRemoveAdmin",
          language,
        )}${promoteAdmin ? "" : "?"}`}
        handleClick={handleWarningConfirm}
        language={language}
        loading={getLoading}
      >
        {promoteAdmin ? (
          <T.Body14R>
            This will demote the current main account holder to an
            administrator.
          </T.Body14R>
        ) : (
          <>
            {language === "welsh" && (
              <T.Body14R style={{ display: "inline" }}>
                {t("willBeforeName", language)}{" "}
              </T.Body14R>
            )}
            <T.Body14B color="midnight" style={{ display: "inline" }}>
              {userToRemoveOrPromote.firstName} {userToRemoveOrPromote.lastName}{" "}
            </T.Body14B>
            <T.Body14R
              style={{ display: "inline", textTransform: "lowercase" }}
            >
              {t("willBeDeletedAsAdmin", language)}
            </T.Body14R>
          </>
        )}
      </WarningMessage>
    </>
  );
};

const mapStateToProps = (state) => ({
  admins: state.administratorSection.admins,
  getLoading: state.administratorSection.getLoading,
  addError: state.administratorSection.addError,
  addLoading: state.administratorSection.addLoading,
  promoteError: state.administratorSection.promoteError,
  id: state.auth.id,
  role: state.auth.role,
  isMobile: state.ui.isMobile,
  language: state.auth.language,
});

const mapActionsToProps = {
  getEarnGroupAdmins: actions.getEarnGroupAdmins,
  addNewAdmin: actions.addNewAdmin,
  deleteAdministrator: actions.deleteAdministrator,
  promoteEGAdmin: actions.promoteEGAdmin,
  getEarnGroupAccountHolderLogs: actions.getEarnGroupAccountHolderLogs,
  getUserAccount: myAccountActions.getUserAccount,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(AdministratorSection);
