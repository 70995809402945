export default {
  purple: "#5E2E86",
  blue: "#0057A3",
  cyan: "#00A9CE",
  yellow: "#FFC709",
  pink: "#D91473",
  midnight: "#201751",
  gray1: "#0E0E0E",
  gray2: "#2F2F2F",
  gray3: "#585858",
  gray4: "#C4C4C4",
  gray5: "#E1E0E0",
  gray6: "#EEEEEE",
  gray7: "#F9F9F9",
  gray8: "#FCFCFC",
  white: "#FFFFFF",
  pinkLight: "#FFFBFD",
  pinkMed: "#F9ECF2",
  cyanLight: "#FAFEFF",
  cyanMed: "#EBF8FB",
  darkPrimary: "#03497E",
  black: "#000000",
  aliceBlue: "#f5f8fa",
  matisse: "#106ba3",
};
