import React from "react";
// only Router accepts the history prop (BrowserRouter does not)
import { Switch } from "react-router-dom";

// CONSTANTS
import { TEMPO } from "../../constants/navRoutes";
import { roles } from "../../constants";

// STAFF COMPONENTS
import Staff from "./Staff";
import { IssueCredits, IssueCreditsSuccess } from "./Staff/CreditRequests";
import SpendVenueForm, {
  CreateSpendVenueSelectPartner,
  CreateSpendVenueSuccess,
} from "./SpendVenueForm";
import SpendPartnerForm from "./SpendPartnerForm";

// MULTI-USE COMPONENTS
import SpendActivityForm from "../SpendActivityForm";
import SignUpStaff from "../Signup/SignUpStaff";
import Login from "../Login";
import Dashboard from "../Dashboard";
import DuplicateSpendActivity from "./Staff/DuplicateSpendActivity";

// OTHER ROUTERS
import ErrorRouter from "../ErrorRoutes";
import SpendVenueInsights from "../SpendVenueRoutes/SpendVenueInsights";

// COMMON
import Route from "../../components/Route";
import EditMemberAccount from "./Staff/EditMemberAccount";

const StaffRouter = () => (
  <Switch>
    <Route
      exact
      path={TEMPO.SIGN_UP}
      layout="onboarding"
      Component={SignUpStaff}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.ADMIN_LOG_IN}
      layout="onboarding"
      footer
      Component={Login}
      staff
      hideLangToggle
    />
    <Route
      isPrivate
      exact
      path={TEMPO.DASHBOARD}
      Component={Dashboard}
      navbar
      layout="general"
      header
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />

    <Route
      exact
      path={TEMPO.DUPLICATE_SPEND_ACTIVITY}
      Component={DuplicateSpendActivity}
      action="issue"
      toRole={roles.EARN_GROUP}
      navbar
      header
      layout="splitScreen"
      side="right"
      isPrivate
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />

    <Route
      isPrivate
      exact
      path={TEMPO.RECOGNITION_LISTING_REQUESTS}
      Component={Staff.RecognitionListingRequests}
      navbar
      layout="general"
      header
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.PROGRAMMES}
      Component={Staff.Programmes}
      navbar
      header
      isPrivate
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.TK_GROUP_REQUESTS}
      Component={Staff.TkGroupRequests}
      navbar
      header
      isPrivate
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.TK_SINGLE_GROUP_REQUESTS}
      Component={Staff.TkSingleGroupRequest}
      navbar
      header
      isPrivate
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />

    <Route
      exact
      path={TEMPO.TK_PROGRAMME_CONFLICT}
      Component={Staff.TkProgrammeConflict}
      navbar
      header
      isPrivate
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.NEW_PROGRAMME}
      navbar
      header
      isPrivate
      Component={Staff.CreateProgramme}
      allowedRoles={[roles.TEMPO_STAFF_ADMIN, roles.TEMPO_STAFF_SUPER]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.PROGRAMME_SINGLE}
      Component={Staff.SingleProgramme}
      navbar
      isPrivate
      layout="general"
      header
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.SPEND_VENUE_INSIGHTS}
      Component={SpendVenueInsights}
      navbar
      header
      isPrivate
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.EDIT_PROGRAMME}
      navbar
      header
      isPrivate
      edit
      Component={Staff.CreateProgramme}
      allowedRoles={[roles.TEMPO_STAFF_ADMIN, roles.TEMPO_STAFF_SUPER]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.REQUESTS}
      Component={Staff.CreditRequests}
      navbar
      header
      isPrivate
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />

    <Route
      exact
      path={TEMPO.REQUEST_APPROVE}
      Component={IssueCredits}
      action="approve"
      navbar
      header
      layout="splitScreen"
      side="right"
      isPrivate
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.REQUEST_REJECT}
      Component={IssueCredits}
      action="reject"
      navbar
      header
      layout="splitScreen"
      side="right"
      isPrivate
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.REQUEST_PARTIAL}
      Component={IssueCredits}
      action="partially approve"
      navbar
      header
      layout="splitScreen"
      side="right"
      isPrivate
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.REQUEST_SUCCESS}
      Component={IssueCreditsSuccess}
      navbar
      header
      layout="splitScreen"
      side="right"
      isPrivate
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />

    <Route
      exact
      path={TEMPO.ISSUE_CREDITS_MEMBER}
      Component={IssueCredits}
      action="issue"
      toRole={roles.MEMBER}
      navbar
      header
      layout="splitScreen"
      side="right"
      isPrivate
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.ISSUE_CREDITS_EARN_GROUP}
      Component={IssueCredits}
      action="issue"
      toRole={roles.EARN_GROUP}
      navbar
      header
      layout="splitScreen"
      side="right"
      isPrivate
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.ISSUE_SUCCESS}
      Component={IssueCreditsSuccess}
      navbar
      header
      layout="splitScreen"
      side="right"
      isPrivate
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />

    <Route
      exact
      path={TEMPO.EARN_GROUP_SINGLE}
      Component={Staff.IndividiualEarnGroup}
      navbar
      header
      isPrivate
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />

    <Route
      exact
      path={TEMPO.ACCESS_PERMISSION}
      Component={Staff.AccessPermission}
      navbar
      header
      isPrivate
      allowedRoles={[roles.TEMPO_STAFF_ADMIN, roles.TEMPO_STAFF_SUPER]}
      hideLangToggle
    />

    <Route
      exact
      path={TEMPO.SPEND_VENUES}
      navbar
      header
      isPrivate
      Component={Staff.SpendVenues}
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />

    <Route
      exact
      path={TEMPO.MANAGE_PACKAGES}
      navbar
      header
      isPrivate
      Component={Staff.ManagePackages}
      allowedRoles={[roles.TEMPO_STAFF_ADMIN, roles.TEMPO_STAFF_SUPER]}
      hideLangToggle
    />

    <Route
      exact
      path={TEMPO.ORDERS}
      navbar
      header
      isPrivate
      Component={Staff.Orders}
      allowedRoles={[roles.TEMPO_STAFF_ADMIN, roles.TEMPO_STAFF_SUPER]}
      hideLangToggle
    />

    <Route
      exact
      path={TEMPO.MARK_ORDER_AS_PAID}
      navbar
      header
      isPrivate
      Component={Staff.MarkOrderAsPaid}
      allowedRoles={[roles.TEMPO_STAFF_ADMIN, roles.TEMPO_STAFF_SUPER]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.CANCEL_ORDER}
      navbar
      header
      isPrivate
      Component={Staff.CancelOrder}
      allowedRoles={[roles.TEMPO_STAFF_ADMIN, roles.TEMPO_STAFF_SUPER]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.SPEND_VENUES_WITH_LIVE_OFFERS}
      navbar
      header
      isPrivate
      Component={Staff.SpendVenuesWithLiveOffers}
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.SPEND_VENUES_SINGLE}
      navbar
      header
      isPrivate
      Component={Staff.SingleSpendVenue}
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.OFFERS}
      navbar
      header
      isPrivate
      Component={Staff.Offers}
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.CREATE_SPEND_PARTNER}
      navbar
      header
      isPrivate
      Component={SpendPartnerForm}
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />

    <Route
      exact
      path={TEMPO.SPEND_PARTNER}
      navbar
      header
      isPrivate
      Component={Staff.SingleSpendPartner}
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />

    <Route
      exact
      path={TEMPO.EARN_GROUPS}
      navbar
      header
      isPrivate
      Component={Staff.EarnGroups}
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.EARN_GROUP_SINGLE}
      navbar
      header
      isPrivate
      Component={Staff.IndividiualEarnGroup}
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.EDIT_EARN_GROUP_PROFILE}
      navbar
      header
      isPrivate
      Component={Staff.EditEarnGroupProfile}
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.EDIT_EARN_GROUP_ADMIN}
      navbar
      header
      isPrivate
      Component={Staff.EditEarnGroupAdmin}
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.EDIT_SPEND_VENUE_ACCOUNT}
      navbar
      header
      isPrivate
      Component={Staff.EditSpendVenueAccount}
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.MEMBERS_SINGLE}
      navbar
      header
      isPrivate
      Component={Staff.SingleMember}
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.TRANSACTIONS}
      navbar
      header
      isPrivate
      Component={Staff.Transactions}
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.TRANSACTIONS_SINGLE}
      navbar
      header
      isPrivate
      Component={Staff.SingleTransaction}
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.MEMBERS}
      navbar
      header
      isPrivate
      Component={Staff.Members}
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />

    <Route
      exact
      path={TEMPO.ADD_MEMBERS}
      navbar
      header
      isPrivate
      Component={Staff.AddMembers}
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />

    <Route
      exact
      path={TEMPO.CREATE_SPEND_ACTIVITY}
      navbar
      header
      isPrivate
      Component={SpendActivityForm}
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
        roles.SPEND_PARTNER,
      ]}
      hideLangToggle
    />

    <Route
      exact
      path={TEMPO.EDIT_SPEND_ACTIVITY}
      navbar
      header
      isPrivate
      Component={SpendActivityForm}
      edit
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />

    <Route
      exact
      path={TEMPO.PROGRAMME_EARN_GROUPS}
      navbar
      header
      isPrivate
      Component={Staff.ProgrammeEarnGroups}
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.CREATE_SPEND_VENUE_SELECT_VENUE}
      navbar
      header
      isPrivate
      Component={CreateSpendVenueSelectPartner}
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />

    <Route
      exact
      path={TEMPO.CREATE_SPEND_VENUE_SUCCESS}
      navbar
      header
      isPrivate
      Component={CreateSpendVenueSuccess}
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />

    <Route
      exact
      path={TEMPO.CREATE_SPEND_VENUE}
      navbar
      header
      isPrivate
      Component={SpendVenueForm}
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />

    <Route
      exact
      path={TEMPO.CREATE_VENUE}
      navbar
      header
      isPrivate
      Component={SpendVenueForm}
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.CREATE_SPEND_PARTNER}
      navbar
      header
      isPrivate
      Component={SpendPartnerForm}
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />

    <Route
      exact
      path={TEMPO.EDIT_SPEND_PARTNER_ACCOUNT}
      navbar
      header
      isPrivate
      Component={SpendPartnerForm}
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
      edit
    />

    <Route
      exact
      path={TEMPO.PROGRAMME_INVITE_GROUPS}
      navbar
      header
      isPrivate
      Component={Staff.ProgrammeInviteEarnGroup}
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />

    <Route
      exact
      path={TEMPO.EDIT_SPEND_VENUE}
      navbar
      header
      edit
      isPrivate
      Component={SpendVenueForm}
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />
    <Route
      exact
      path={TEMPO.EDIT_MEMBER_ACCOUNT}
      navbar
      header
      edit
      isPrivate
      Component={EditMemberAccount}
      allowedRoles={[
        roles.TEMPO_STAFF_ADMIN,
        roles.TEMPO_STAFF_SUPER,
        roles.TEMPO_STAFF_USER,
      ]}
      hideLangToggle
    />

    {/* ERROR ROUTES */}
    <ErrorRouter />
  </Switch>
);

export default StaffRouter;
