import React, { useState, useEffect, useCallback, useRef } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { getDatesTimesObjectsFromPatterns, sortDatesTimesArray } from "./utils";

import theme from "../../utils/theme";
import t from "../../utils/helpers/translator";
import * as T from "../../components/Typograpy";
import * as S from "./style";
import { Row, Col } from "../../components/Grid";
import R from "../../constants/roles";

import trimArrayOfStrings from "../../utils/helpers/trimArrayOfStrings";
import cleanWhitespace from "../../utils/helpers/cleanWhitespace";

import WarningMessage from "../../components/WarningMessage";
import { Checkbox } from "../../components/Inputs";
import Button from "../../components/Button";
import GoBack from "../../components/GoBack";
import Image from "../../components/Image";
import Notification from "../../components/Notification";
import Modal from "../../components/Modal";
import Loading from "../../components/Loading";
import { roles } from "../../constants";
import { spendActivitiesLocationTypesKeys } from "../../constants/dropdDownData";
import StepBased from "../../components/StepBased";

import { validateAll } from "../../validation/schemas/createEditSpendActivity";

import * as actions from "./actions";
import { createTicket as createTicketAction } from "../../redux/actions/zendesk";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

import {
  GENERAL,
  SPEND_VENUE,
  TEMPO,
  EARN_GROUP,
} from "../../constants/navRoutes";

const stepsArray = [
  (props) => <Step1 {...props} />,
  (props) => <Step2 {...props} />,

  (props) => <Step3 {...props} />,
  (props) => <Step4 {...props} />,
];

const SpendActivityForm = ({
  loaded,
  edit,
  data,
  getActivityById,
  updateActivity,
  createActivity,
  updatedSuccessfully,
  error,
  resetSpendActivityForm,
  welshRequiredFieldsPresent,
  welshProfilePresents,
  role,
  deleteSpendActivity,
  userRole,
  deleteActivityLoading,
  // deleteActivitySuccess,
  deleteActivityError,
  deleteActivitySuccessData,
  language,
  loading,
  createTicket,
  orgName,
}) => {
  const [stepperDisabled, setStepperDisabled] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [visitedSteps, setVisitedSteps] = useState(() =>
    edit ? [0, 1, 2, 3] : [0],
  );
  const [stepsStatuses, setStepsStatuses] = useState({
    0: { error: false, completed: !!edit },
    1: { error: false, completed: !!edit },
    2: { error: false, completed: !!edit },
    3: { error: false, completed: !!edit },
  });

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isWelshPromptOpen, setIsWelshPromptOpen] = useState(false);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const [deleteModalSuccessOpened, setDeleteModalSuccessOpened] =
    useState(false);

  const firstApprovalModalOpened = useRef(false);
  const isLiveBackend = useRef(false);
  const isUnderReviewBackend = useRef(false);
  const comingFromCreatePage = useRef(!edit);

  const [confirmMessage, setConfirmMessage] = useState(false);
  const [areYouSureModalOpen, setAreYouSureModalOpen] = useState(false);

  const history = useHistory();
  const { id, spendVenueId } = useParams();

  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const [formData, setFormData] = useState({
    isLive: false,
    locationType: spendActivitiesLocationTypesKeys.REGIONAL,
    dateTimes: [
      {
        dates: {},
        times: {},
        repeats: "none",
      },
    ],
  });
  //   image state to go here
  const [imageInfo, setImageInfo] = useState(
    data.imageInfo || {
      id: null,
      name: "",
      key: "",
      bucketRegion: "",
      bucket: "",
      new: false,
      uploadedToS3: false,
    },
  );
  const [startUpload, setStartUpload] = useState(false);

  // WELSH DATA
  const [welshVersion, setWelshVersion] = useState(false);

  const [errors, setErrors] = useState({});

  const [welshLoaded, setWelshLoaded] = useState(false);

  const isTempoUser = ![
    R.SPEND_PARTNER,
    R.SPEND_VENUE,
    R.EARN_GROUP,
    R.EARN_GROUP_ADMIN,
  ].includes(userRole);

  useEffect(() => {
    if (updatedSuccessfully) {
      setIsNotificationOpen(true);
      setImageInfo((state) => ({ ...state, new: false, uploadedToS3: false }));
      setStartUpload(false);
      if (welshRequiredFieldsPresent && !welshProfilePresents) {
        setIsWelshPromptOpen(true);
      }
    }
  }, [
    updatedSuccessfully,
    welshRequiredFieldsPresent,
    welshProfilePresents,
    edit,
  ]);

  useEffect(() => {
    let filteredData;
    if (loaded) {
      setImageInfo({ ...data.image, new: false });

      // include welsh fields if present
      const { welshFields = {} } = data;

      if (welshFields) {
        const formattedWelshFields = {
          nameWelsh: welshFields.name,
          descriptionWelsh: welshFields.description,
          subTitleWelsh: welshFields.subTitle,
          restrictionsWelsh: welshFields.restrictions,
          callExtraDetailsWelsh: welshFields.callExtraDetails,
          useCodesDetailsWelsh: welshFields.useCodesDetails,
          furtherInformationWelsh: welshFields.furtherInformation,
          keywordsWelsh: welshFields.keywords,
        };
        filteredData = { ...data, ...formattedWelshFields };
        setWelshVersion(!!filteredData.nameWelsh);
      } else {
        filteredData = data;
      }

      setFormData(() => {
        const maxGroupSizeOld = Number(data.maxGroupSize) || undefined;
        const bookingCodesOld =
          (data.bookingCodes &&
            data.bookingCodes.length &&
            data.bookingCodes
              .filter(({ usedByUserId }) => !usedByUserId)
              .map(({ code }) => code)) ||
          [];

        const bookingCodesUsedOld =
          (data.bookingCodes &&
            data.bookingCodes.length &&
            data.bookingCodes.filter(({ usedByUserId }) => !!usedByUserId)
              .length) ||
          0;

        const patterns = data.dates;
        const sortedDateTimesOld = sortDatesTimesArray(
          getDatesTimesObjectsFromPatterns(patterns),
        );

        isLiveBackend.current = data.isLive;
        isUnderReviewBackend.current = data.isUnderReview;

        return {
          ...filteredData,
          personTcPrice: data.costVaries ? undefined : data.personTcPrice || 0,
          isBookingRequiredForGroups: data.isBookingRequiredForGroups,
          isLive: isTempoUser
            ? data.isLive || false
            : data.isLive || data.isUnderReview || false,
          hideIsLiveCheckbox: isTempoUser && data.isUnderReview,
          isAvailableAnyTime: data.isAvailableAnyTime || false,
          maxGroupSize: maxGroupSizeOld,
          bookingCodes: bookingCodesOld,
          bookingCodesUsed: bookingCodesUsedOld,
          dateTimes: sortedDateTimesOld,
        };
      });

      setWelshLoaded(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);
  useEffect(() => {
    if (edit) {
      getActivityById(id, { isPublic: false });
    }
    return resetSpendActivityForm;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleCheckbox = useCallback((e) => {
    switch (e.target.name) {
      case "welshVersion":
        return setWelshVersion(e.target.checked);
      default:
        break;
    }
  }, []);

  const submit = async (cb) => {
    try {
      let formDataToSubmit = {
        ...formData,
        name: cleanWhitespace(formData.name),
        personTcPrice: formData.costVaries ? undefined : formData.personTcPrice,
        bookingCodes: formData.costVaries
          ? []
          : trimArrayOfStrings(formData.bookingCodes),
        // to delete the codes if user had codes and checked costVaries
        isBookingCodesUpdated:
          formData.costVaries &&
          formData.bookingCodes &&
          formData.bookingCodes.length
            ? true
            : formData.isBookingCodesUpdated,
        maxGroupSize: formData.maxGroupSize || 0,
        keywords: trimArrayOfStrings(formData.keywords),
        imageInfo,
        imageKey: imageInfo.key,
      };

      const welshFormData = {
        nameWelsh: cleanWhitespace(formData.nameWelsh),
        subTitleWelsh: formData.subTitleWelsh,
        descriptionWelsh: formData.descriptionWelsh,
        restrictionsWelsh: formData.restrictionsWelsh,
        callExtraDetailsWelsh: formData.callExtraDetailsWelsh,
        useCodesDetailsWelsh: formData.useCodesDetailsWelsh,
        furtherInformationWelsh: formData.furtherInformationWelsh,
        keywordsWelsh: trimArrayOfStrings(formData.keywordsWelsh),
      };
      // add welsh fields if checkbox enabled
      if (welshVersion) {
        formDataToSubmit = {
          ...formDataToSubmit,
          welshFields: { ...welshFormData },
          spendVenueId,
        };
      }

      setFormData((_) => ({ ..._, finalSubmit: false }));

      const objToBeValidated = {
        ...formData,
        imageKey: imageInfo.key,
        welshVersion,
        atLeastOneWayToSpend: {
          turnUpAtVenue: formData.turnUpAtVenue || false,
          callToBook: formData.callToBook || false,
          onlineCodesToBook: formData.onlineCodesToBook || false,
        },
      };

      const validateObj = validateAll(objToBeValidated, [0, 1, 2, 3]);

      if (validateObj.oneStepHasError) {
        validateObj.errors.anyErrors =
          "At least one of the input fields has not been filled in or details entered incorrectly. Please check the form above for more details.";

        // create central bookingCodes error msg if array of codes contains error
        if (validateObj.errors.bookingCodes) {
          validateObj.errors.bookingCodes =
            "Each booking code can have 50 charaters at most";
        }

        setErrors(validateObj.errors);
        return;
      }

      setErrors({});
      let res;
      if (edit) {
        res = await updateActivity(id, {
          ...formDataToSubmit,
          spendVenueId: data.spendVenue.id,
        });

        setTimeout(() => {
          setActiveStep((a) => (a === 3 ? 0 : a + 1));
          window.scrollTo(0, 0);
        });
      } else {
        res = await createActivity(
          {
            type: "spend",
            ...formDataToSubmit,
            spendVenueId,
          },
          history,
          userRole,
        );
      }

      if (cb && typeof cb === "function") cb();

      isLiveBackend.current = formData.isLive;

      // CREATE ZENDESK TICKET IF LIVE AND NOT TEMPO USER
      if (
        res.data?.approvalRequestCreated &&
        process.env.NODE_ENV === "production"
      ) {
        const result = await createTicket({ query: { orgName } });

        if (result?.error) {
          // eslint-disable-next-line no-console
          console.error(result.error);
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  useEffect(() => {
    if (imageInfo.new && !imageInfo.uploadedToS3) {
      setStepperDisabled(true);
    } else {
      setStepperDisabled(false);
    }
  }, [imageInfo.new, imageInfo.uploadedToS3]);

  const partialSubmit = async () => {
    // not tempo user logic
    if (!isTempoUser) {
      // if user editing the form
      if (edit) {
        if (
          formData.isLive !== isLiveBackend.current ||
          (!firstApprovalModalOpened.current && formData.isLive)
        ) {
          return setAreYouSureModalOpen(true);
        }
        return submit();
      }

      // if the user creating activity
      if (
        stepsStatuses[0].completed &&
        stepsStatuses[1].completed &&
        stepsStatuses[2].completed &&
        stepsStatuses[3].completed
      ) {
        return submit(() => setConfirmMessage(true));
      }
    } else {
      await submit();
    }
  };

  useEffect(() => {
    if (
      stepsStatuses[0].completed &&
      stepsStatuses[1].completed &&
      stepsStatuses[2].completed &&
      stepsStatuses[3].completed &&
      !edit
    ) {
      partialSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    stepsStatuses[0].completed,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    stepsStatuses[1].completed,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    stepsStatuses[2].completed,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    stepsStatuses[3].completed,
  ]);

  if ((!welshLoaded || !loaded) && edit)
    return <Loading fullPage center navbar />;

  const handleDeleteActivity = async () => {
    const success = await deleteSpendActivity(id);
    if (success) {
      setDeleteModalSuccessOpened(true);
    }
  };

  const handleClickOnStep = (nextStep) => {
    if (!stepperDisabled) {
      const objToBeValidated = {
        ...formData,
        imageKey: imageInfo.key,
        welshVersion,
        atLeastOneWayToSpend: {
          turnUpAtVenue: formData.turnUpAtVenue || false,
          callToBook: formData.callToBook || false,
          onlineCodesToBook: formData.onlineCodesToBook || false,
        },
      };

      const validateObj = validateAll(objToBeValidated, visitedSteps);

      setErrors({ ...validateObj.errors });
      setStepsStatuses((_) => ({ ..._, ...validateObj.stepsStatuses }));

      setActiveStep(nextStep);
      window.scrollTo(0, 0);

      setVisitedSteps((_s) =>
        Object.keys({
          ..._s.reduce((acc, cur) => ({ ...acc, [cur]: true }), {}),
          [nextStep]: true,
        }),
      );
    }
  };

  const handleNext = async (a) => {
    const objToBeValidated = {
      ...formData,
      imageKey: imageInfo.key,
      welshVersion,
      atLeastOneWayToSpend: {
        turnUpAtVenue: formData.turnUpAtVenue || false,
        callToBook: formData.callToBook || false,
        onlineCodesToBook: formData.onlineCodesToBook || false,
      },
    };

    const validateObj = validateAll(objToBeValidated, visitedSteps);

    setErrors({ ...validateObj.errors });
    setStepsStatuses((_) => ({ ..._, ...validateObj.stepsStatuses }));

    if (!validateObj.oneStepHasError && edit) {
      await partialSubmit();
      return;
    }
    setActiveStep(a === 3 ? 0 : a + 1);
    window.scrollTo(0, 0);

    setVisitedSteps((_s) =>
      Object.keys({
        ..._s.reduce((acc, cur) => ({ ...acc, [cur]: true }), {}),
        [a === 3 ? 0 : a + 1]: true,
      }),
    );
  };

  const confirmSuccessFun = () => {
    if (!isTempoUser) {
      if (edit) {
        return;
      }
      if ([R.SPEND_PARTNER, R.SPEND_VENUE].includes(userRole)) {
        history.push(SPEND_VENUE.OFFERS);
      }
      if ([R.EARN_GROUP, R.EARN_GROUP_ADMIN].includes(userRole)) {
        history.push(EARN_GROUP.OFFERS);
      }
    }
  };

  const renderCreateMessage = () => {
    if (!formData.isLive) {
      return t("draftActivityCreatedMessage", language);
    }

    return t("yourActivityIsCurrentlyBeingReviewed", language);
  };

  const handleSendUpdate = () => {
    setAreYouSureModalOpen(false);
    return submit(() => {
      firstApprovalModalOpened.current = true;
      setConfirmMessage(true);
      comingFromCreatePage.current = false;
    });
  };

  const decideRoute = () => {
    if ([R.SPEND_PARTNER, R.SPEND_VENUE].includes(userRole)) {
      history.push(SPEND_VENUE.OFFERS);
    }
    if ([R.EARN_GROUP, R.EARN_GROUP_ADMIN].includes(userRole)) {
      history.push(EARN_GROUP.OFFERS);
    } else if (data?.spendVenue?.userRole === R.SPEND_VENUE) {
      history.push(
        TEMPO.SPEND_VENUES_SINGLE.replace(":id", data.spendVenue.id),
      );
    } else if (data?.spendVenue?.userRole === R.EARN_GROUP) {
      history.push(TEMPO.EARN_GROUP_SINGLE.replace(":id", data.spendVenue.id));
    }
  };

  const stepsHead = [
    t("step1", language),
    t("step2", language),
    t("step3", language),
    t("step4", language),
  ];

  return (
    <S.Wrapper>
      <Modal
        modalOpen={confirmMessage}
        handleCloseModal={() => setConfirmMessage(false)}
        confirmFun={confirmSuccessFun}
        onCancel={() => setConfirmMessage(false)}
      >
        <T.H24 color="blue" mb={20}>
          {comingFromCreatePage.current
            ? t("activityCreated", language)
            : t("changeSubmitted", language)}
        </T.H24>
        <T.Body14R>{renderCreateMessage()}</T.Body14R>
      </Modal>
      <Notification
        open={isNotificationOpen}
        setOpen={setIsNotificationOpen}
        message={t("activitySaved", language)}
        duration={1500}
      />
      <Row mb={6}>
        <Col w={[4, 12, 12]}>
          <GoBack showText onClick={() => decideRoute()} />
        </Col>
        <S.ImageWrapper>
          <Image image="galleryWithBg" alt="gallery" height={210} width={220} />
        </S.ImageWrapper>
      </Row>
      <S.Header>
        <Row>
          <Col w={[4, 12, 12]}>
            <T.H40Caps color="midnight" m="0">
              {edit ? t("edit", language) : t("add", language)}{" "}
              {t("activity", language)}
            </T.H40Caps>
            {edit && (
              <Link
                to={GENERAL.SPEND_ACTIVITY.replace(":id", id)}
                target="_blank"
              >
                <T.LinkB16 color="pink" m="20px 0 0 0">
                  {t("viewActivity", language)}
                </T.LinkB16>
              </Link>
            )}

            {isTempoUser && edit && formData.hideIsLiveCheckbox && (
              <div style={{ display: "flex", marginTop: "20px" }}>
                <T.Body16R m="0" mr="3" color="midnight">
                  {" "}
                  This activity is awaiting review{" "}
                </T.Body16R>
                <Link
                  to={GENERAL.SPEND_ACTIVITY.replace(":id", id)}
                  target="_blank"
                >
                  <T.LinkB16 color="midnight">Click to approve</T.LinkB16>
                </Link>
              </div>
            )}
          </Col>
        </Row>
        {edit && (
          <Row mt={5}>
            <Col w={[4, 12, 12]}>
              <Checkbox
                value={welshVersion}
                setValue={handleCheckbox}
                label={t("addVersionInWelsh", language)}
                name="welshVersion"
                size={isMobile ? "normal" : "medium"}
              />
            </Col>
          </Row>
        )}
      </S.Header>

      <S.Form>
        <StepBased
          componentArr={stepsArray}
          steps={stepsHead}
          disabled={stepperDisabled}
          activeStep={activeStep}
          stepsStatuses={stepsStatuses}
          handleClickOnStep={handleClickOnStep}
          handleNext={handleNext}
          loading={loading}
          edit={edit}
          stepProps={{
            welshVersion,
            data: formData,
            setState: setFormData,
            startUpload,
            imageInfo,
            setImageInfo,
            errors,
            setErrors,
            edit,
            userRole,
          }}
        />
      </S.Form>

      {[roles.TEMPO_STAFF_ADMIN, roles.TEMPO_STAFF_SUPER].includes(userRole) &&
        edit && (
          <Row mt={6}>
            <Col w={[4, 6, 3]}>
              {deleteActivityError.message && (
                <S.Error>{t(deleteActivityError.message, "english")}</S.Error>
              )}
              {error.message && (
                <S.Error>{t(error.message, "english")}</S.Error>
              )}
              <Button
                secondary
                size="l"
                handleClick={() => setDeleteModalOpened(true)}
                loading={deleteActivityLoading}
              >
                Delete activity
              </Button>
            </Col>
          </Row>
        )}

      <WarningMessage
        open={deleteModalOpened}
        setOpen={setDeleteModalOpened}
        handleClick={handleDeleteActivity}
        title="Are you sure you want to delete this recognition activity?"
        text="You are about to delete this recognition activity. This cannot be undone."
      />
      <WarningMessage
        open={areYouSureModalOpen}
        setOpen={setAreYouSureModalOpen}
        handleClick={handleSendUpdate}
        title={t("areYouSure", language)}
        text={t(
          isLiveBackend.current
            ? "thisActivityWillNeedToBeReApproved"
            : "yourActivityIsCurrentlyBeingReviewed",
          language,
        )}
      />
      <>
        <Modal
          modalOpen={isWelshPromptOpen}
          confirmFun={() =>
            history.push(
              role === "spendVenue"
                ? TEMPO.EDIT_SPEND_VENUE.replace(
                    ":spendVenueId",
                    data.spendVenue.id,
                  )
                : TEMPO.EDIT_EARN_GROUP_PROFILE.replace(
                    ":id",
                    data.spendVenue.id,
                  ),
            )
          }
          buttonText="Add Welsh"
          handleCloseModal={() => setIsWelshPromptOpen(false)}
        >
          <T.H24 color="midnight" mb={20}>
            A quick reminder
          </T.H24>
          <T.Body14R>
            You&apos;ve successfully updated this activity. Please now add a
            Welsh translation to the related{" "}
            {role === "spendVenue" ? "venue" : "earn group"}.
          </T.Body14R>
        </Modal>

        <Modal
          modalOpen={deleteModalSuccessOpened}
          confirmFun={() => {
            history.replace(
              deleteActivitySuccessData.spendVenue.userRole ===
                roles.SPEND_VENUE
                ? TEMPO.SPEND_VENUES_SINGLE.replace(
                    ":id",
                    deleteActivitySuccessData.spendVenue.id,
                  )
                : TEMPO.EARN_GROUP_SINGLE.replace(
                    ":id",
                    deleteActivitySuccessData.spendVenue.id,
                  ),
            );
          }}
          handleCloseModal={setDeleteModalSuccessOpened}
          buttonText="Ok"
        >
          <T.H24 color="cyan" mb={20}>
            Listing deleted
          </T.H24>
          <T.Body14R color="gray3">
            This recognition activity has been successfully deleted. Please
            inform the partner or venue if appropriate.
          </T.Body14R>
        </Modal>
      </>
    </S.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  loaded: state.spendActivityForm.loaded,
  data: state.spendActivityForm.spendActivity,
  updatedSuccessfully: state.spendActivityForm.updatedSuccessfully,
  loading: state.spendActivityForm.updateSpendActivityLoading,
  error: state.spendActivityForm.updateSpendActivityError,
  welshRequiredFieldsPresent:
    state.spendActivityForm.welshRequiredFieldsPresent,
  welshProfilePresents: state.spendActivityForm.welshProfilePresents,
  role: state.spendActivityForm.role,
  deleteActivityLoading: state.spendActivityForm.deleteActivityLoading,
  deleteActivitySuccess: state.spendActivityForm.deleteActivitySuccess,
  deleteActivityError: state.spendActivityForm.deleteActivityError,
  deleteActivitySuccessData: state.spendActivityForm.deleteActivitySuccessData,
  userRole: state.auth.role,
  language: state.auth.language,
  orgName:
    state.auth.profile?.name || state.auth.selectedSpendVenue?.profile?.name,
});

const mapActionToProps = {
  getActivityById: actions.getActivityById,
  updateActivity: actions.updateActivity,
  createActivity: actions.createActivity,
  resetSpendActivityForm: actions.resetSpendActivityForm,
  deleteSpendActivity: actions.deleteSpendActivity,
  createTicket: createTicketAction,
};

export default connect(mapStateToProps, mapActionToProps)(SpendActivityForm);
