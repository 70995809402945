import React from "react";

import { Wrapper, Tab } from "./style";
import * as T from "../Typograpy";

const TabsLarge = ({
  tabOneText,
  tabTwoText,
  selected,
  handleClick,
  color,
  width,
}) => (
  <Wrapper width={width}>
    <Tab
      selected={selected === tabOneText}
      onClick={() => handleClick(tabOneText)}
    >
      <T.Body18B
        color={selected === tabOneText ? color || "midnight" : "gray3"}
        style={{
          textTransform: "uppercase",
        }}
      >
        {tabOneText}
      </T.Body18B>
    </Tab>
    <Tab
      selected={selected === tabTwoText}
      onClick={() => handleClick(tabTwoText)}
    >
      <T.Body18B
        color={selected === tabTwoText ? color || "midnight" : "gray3"}
        style={{
          textTransform: "uppercase",
        }}
      >
        {tabTwoText}
      </T.Body18B>
    </Tab>
  </Wrapper>
);

export default TabsLarge;
