import { fields, createSchema, validate as _validate } from "..";

const { birthDate, gender, ethnic, mentalHealthCondition, volunteeredBefore } =
  fields;

const continueSignup = createSchema({
  birthDate,
  gender,
  ethnic,
  mentalHealthCondition,
  volunteeredBefore,
});

const validate = (data) => _validate(continueSignup, data);

export default validate;
