// THIS SHOULD BE STORED IN THE DATABASE AS ITS OWN TABLE AT SOME POINT I THINK
import alphabetise from "../utils/helpers/alphabetise";
import {
  keys,
  keysWelsh,
  helperTextKeys,
  helperTextKeysWelsh,
} from "./data/earnActivityTypes";

const earnCategories = [
  {
    category: "adminOfficeSupport",
    categoryName: keys.OFFICE_ADMIN_SUPPORT,
    subtitle: helperTextKeys.EG_WORKING_ON_RECEPTION_OR_COMPLETING_PAPERWORK,
    categoryNameWelsh: keysWelsh.OFFICE_ADMIN_SUPPORT,
    subtitleWelsh:
      helperTextKeysWelsh.EG_WORKING_ON_RECEPTION_OR_COMPLETING_PAPERWORK,
  },
  {
    category: "developingServices",
    categoryName: keys.IMPROVING_SERVICES,
    subtitle: helperTextKeys.EG_SITTING_ON_A_BOARD_OR_A_USER_FORUM,
    categoryNameWelsh: keysWelsh.IMPROVING_SERVICES,
    subtitleWelsh: helperTextKeysWelsh.EG_SITTING_ON_A_BOARD_OR_A_USER_FORUM,
  },
  {
    category: "fundraisingPromotion",
    categoryName: keys.PROMOTION_FUNDRAISING,
    subtitle: helperTextKeys.EG_MANAGING_SOCIAL_MEDIA_OR_FUNDRAISING_ACTIVITIES,
    categoryNameWelsh: keysWelsh.PROMOTION_FUNDRAISING,
    subtitleWelsh:
      helperTextKeysWelsh.EG_MANAGING_SOCIAL_MEDIA_OR_FUNDRAISING_ACTIVITIES,
  },
  {
    category: "operations",
    categoryName: keys.EVENTS_OPERATIONS,
    subtitle: helperTextKeys.EG_HELPING_RUN_AN_EVENT_CAFE_FOODBANK_OR_MUSEUM,
    categoryNameWelsh: keysWelsh.EVENTS_OPERATIONS,
    subtitleWelsh:
      helperTextKeysWelsh.EG_HELPING_RUN_AN_EVENT_CAFE_FOODBANK_OR_MUSEUM,
  },
  {
    category: "practicalHelp",
    categoryName: keys.PRACTICAL_HELP,
    subtitle: helperTextKeys.EG_GARDENING_DRIVING_OR_LITTER_PICKING,
    categoryNameWelsh: keysWelsh.PRACTICAL_HELP,
    subtitleWelsh: helperTextKeysWelsh.EG_GARDENING_DRIVING_OR_LITTER_PICKING,
  },
  {
    category: "professionalSkills",
    categoryName: keys.PROFESSIONAL_SKILLS,
    subtitle: helperTextKeys.EG_ACCOUNTING_GOVERNANCE_OR_REPORT_WRITING,
    categoryNameWelsh: keysWelsh.PROFESSIONAL_SKILLS,
    subtitleWelsh:
      helperTextKeysWelsh.EG_ACCOUNTING_GOVERNANCE_OR_REPORT_WRITING,
  },
  {
    category: "runningActivity",
    categoryName: keys.RUNNING_AN_ACTIVITY_OR_GROUP,
    subtitle: helperTextKeys.EG_MANAGING_VOLUNTEERS_OR_ORGANISING_TRIPS,
    categoryNameWelsh: keysWelsh.RUNNING_AN_ACTIVITY_OR_GROUP,
    subtitleWelsh:
      helperTextKeysWelsh.EG_MANAGING_VOLUNTEERS_OR_ORGANISING_TRIPS,
  },
  {
    category: "supportingOthers",
    categoryName: keys.SUPPORTING_OTHERS,
    subtitle: helperTextKeys.EG_BEFRIENDING_OR_PROVIDING_ADVICE,
    categoryNameWelsh: keysWelsh.SUPPORTING_OTHERS,
    subtitleWelsh: helperTextKeysWelsh.EG_BEFRIENDING_OR_PROVIDING_ADVICE,
  },
  {
    category: "teachingTraining",
    categoryName: keys.TEACHING_TRAINING,
    subtitle: helperTextKeys.EG_SCHOOL_READING_MENTORING_OR_UPSKILLING_OTHERS,
    categoryNameWelsh: keysWelsh.TEACHING_TRAINING,
    subtitleWelsh:
      helperTextKeysWelsh.EG_SCHOOL_READING_MENTORING_OR_UPSKILLING_OTHERS,
  },
];

export const earnActivityTypesWithKey = alphabetise(
  earnCategories.map(({ category, categoryName }) => ({
    label: categoryName,
    value: category,
  })),
);

export const earnActivityTypesWithKeyWelsh = alphabetise(
  earnCategories.map(({ category, categoryNameWelsh }) => ({
    label: categoryNameWelsh,
    value: category,
  })),
);

export default earnCategories;
