import React from "react";

const At = ({ width, height, color, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 23"
    width={width}
    height={height}
    {...props}
  >
    <path
      d="M13.445.56c2.173 0 4.06.367 5.66 1.1 1.6.72 2.834 1.78 3.7 3.18.88 1.387 1.32 3.067 1.32 5.04 0 1.333-.287 2.56-.86 3.68-.573 1.107-1.34 1.987-2.3 2.64-.96.64-1.98.96-3.06.96-.947 0-1.727-.207-2.34-.62-.613-.427-.986-1.073-1.12-1.94-.44.827-.993 1.46-1.66 1.9-.653.44-1.46.66-2.42.66-.813 0-1.54-.193-2.18-.58a4.164 4.164 0 01-1.48-1.66c-.347-.72-.52-1.547-.52-2.48 0-.173.013-.433.04-.78.093-1.147.433-2.167 1.02-3.06a6.321 6.321 0 012.32-2.12c.96-.507 2.014-.76 3.16-.76 1.454 0 2.62.38 3.5 1.14.893.747 1.34 1.793 1.34 3.14 0 .253-.02.52-.06.8l-.26 1.86c-.027.24-.04.4-.04.48 0 .373.074.64.22.8.16.147.4.22.72.22.427 0 .827-.153 1.2-.46.387-.32.7-.793.94-1.42.24-.627.36-1.387.36-2.28 0-1.333-.3-2.5-.9-3.5-.587-1-1.46-1.773-2.62-2.32-1.16-.547-2.566-.82-4.22-.82-1.986 0-3.687.4-5.1 1.2-1.413.8-2.487 1.887-3.22 3.26-.72 1.36-1.08 2.893-1.08 4.6 0 2.293.687 4.08 2.06 5.36 1.387 1.293 3.46 1.933 6.22 1.92l-.64 2.78c-2.36-.027-4.36-.447-6-1.26-1.627-.813-2.86-1.967-3.7-3.46-.84-1.493-1.26-3.273-1.26-5.34 0-2.413.593-4.513 1.78-6.3 1.2-1.8 2.807-3.173 4.82-4.12 2.027-.96 4.247-1.44 6.66-1.44zm.8 10.32c0-.733-.2-1.28-.6-1.64-.4-.36-.906-.54-1.52-.54-.453 0-.88.12-1.28.36-.4.227-.727.587-.98 1.08-.253.48-.38 1.08-.38 1.8 0 .733.2 1.287.6 1.66.4.373.893.56 1.48.56.454 0 .88-.113 1.28-.34.413-.24.747-.607 1-1.1.267-.493.4-1.107.4-1.84z"
      fill={color || "currentColor"}
    />
  </svg>
);
export default At;
