import React from "react";

const AlertCycle = ({ width, height, color, ...props }) => (
  <svg
    width={width}
    height={height}
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 12C21 7.03125 16.9688 3 12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12Z"
      stroke={color || "currentColor"}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M11.7312 7.78358L12.0003 13.5L12.2689 7.78358C12.2705 7.74704 12.2647 7.71054 12.2518 7.67632C12.2389 7.6421 12.2191 7.61087 12.1937 7.58454C12.1683 7.55822 12.1378 7.53735 12.1041 7.52322C12.0703 7.50908 12.0341 7.50198 11.9975 7.50233V7.50233C11.9613 7.50267 11.9256 7.51029 11.8925 7.52474C11.8594 7.53918 11.8295 7.56016 11.8046 7.58641C11.7798 7.61266 11.7605 7.64365 11.7479 7.67752C11.7352 7.7114 11.7296 7.74747 11.7312 7.78358V7.78358Z"
      stroke={color || "currentColor"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 17.2459C11.8146 17.2459 11.6333 17.1909 11.4792 17.0879C11.325 16.9848 11.2048 16.8384 11.1339 16.6671C11.0629 16.4958 11.0443 16.3073 11.0805 16.1255C11.1167 15.9436 11.206 15.7765 11.3371 15.6454C11.4682 15.5143 11.6352 15.425 11.8171 15.3889C11.999 15.3527 12.1875 15.3713 12.3588 15.4422C12.5301 15.5132 12.6765 15.6333 12.7795 15.7875C12.8825 15.9417 12.9375 16.1229 12.9375 16.3084C12.9375 16.557 12.8387 16.7954 12.6629 16.9713C12.4871 17.1471 12.2486 17.2459 12 17.2459Z"
      fill={color || "currentColor"}
    />
  </svg>
);
export default AlertCycle;
