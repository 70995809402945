import React from "react";

const CheckboxUnchecked = ({ width, height, color, ...props }) => (
  <svg
    width={width}
    height={height}
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2.75"
      y="2.75"
      width="18"
      height="18"
      rx="2.375"
      fill="white"
      stroke={color || "currentColor"}
      strokeWidth="1.5"
    />
  </svg>
);
export default CheckboxUnchecked;
