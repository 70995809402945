const keys = {
  INFORMAL_OR_CONSTITUTED_COMMUNITY_GROUP_LITTLE_OR_NO_ANNUAL_INCOME:
    "Informal or constituted community group [little or no annual income]",
  MICRO_CHARITY_VOLUNTARY_ORGANISATION__LESS_THAN_10000_ANNUAL_INCOME:
    "Micro Charity / Voluntary Organisation [Less than £10,000 annual income]",
  SMALL_CHARITY_VOLUNTARY_ORGANISATION_10000_TO_100000_ANNUAL_INCOME:
    "Small Charity / Voluntary Organisation [£10,000 to £100,000 annual income]",
  MEDIUM_SIZE_CHARITY_VOLUNTARY_ORGANISATION_100000_TO_1M_ANNUAL_INCOME:
    "Medium Size Charity / Voluntary Organisation [£100,000 to £1m annual income]",
  LARGE_CHARITY_VOLUNTARY_ORGANISATION_1M_TO_10M_ANNUAL_INCOME:
    "Large Charity / Voluntary Organisation [£1m to 10m annual income]",
  MAJOR_CHARITY_VOLUNTARY_ORGANISATION_10M_ANNUAL_INCOME:
    "Major Charity / Voluntary Organisation [£10m+ annual income]",
  LOCAL_GOVERNMENT: "Local Government",
  NATIONAL_GOVERNMENT: "National Government",
  NHS_CCG: "NHS / CCG",
  COMMUNITY_INTEREST_COMPANY: "Community Interest Company",
  SOCIAL_ENTERPRISE: "Social Enterprise",
  SCHOOL: "School",
  FAITH_ORGANISATION: "Faith Organisation",
  OTHER_PLEASE_SPECIFY: "Other (Please Specify)",
};

const keysWelsh = {
  INFORMAL_OR_CONSTITUTED_COMMUNITY_GROUP_LITTLE_OR_NO_ANNUAL_INCOME:
    "Grŵp cymunedol anffurfiol neu gyfansoddiadol [ychydig neu ddim incwm blynyddol]",
  MICRO_CHARITY_VOLUNTARY_ORGANISATION__LESS_THAN_10000_ANNUAL_INCOME:
    "Micro Elusen/Sefydliad Gwirfoddol [Incwm blynyddol o lai na £10,000]",
  SMALL_CHARITY_VOLUNTARY_ORGANISATION_10000_TO_100000_ANNUAL_INCOME:
    "Elusen / Sefydliad Gwirfoddol Bach [incwm blynyddol o £10,000 i £100,000]",
  MEDIUM_SIZE_CHARITY_VOLUNTARY_ORGANISATION_100000_TO_1M_ANNUAL_INCOME:
    "Elusen / Sefydliad Gwirfoddol Maint Canolig [incwm blynyddol o £100,000 i £1m]",
  LARGE_CHARITY_VOLUNTARY_ORGANISATION_1M_TO_10M_ANNUAL_INCOME:
    "Elusen / Sefydliad Gwirfoddol Mawr [incwm blynyddol o £1m i 10m]",
  MAJOR_CHARITY_VOLUNTARY_ORGANISATION_10M_ANNUAL_INCOME:
    "Prif Elusen / Sefydliad Gwirfoddol [incwm blynyddol o £10m+]",
  LOCAL_GOVERNMENT: "Llywodraeth Leol",
  NATIONAL_GOVERNMENT: "Llywodraeth Genedlaethol",
  NHS_CCG: "Y GIG/Grŵp Comisiynu Clinigol",
  COMMUNITY_INTEREST_COMPANY: "Cwmni Buddiannau Cymunedol",
  SOCIAL_ENTERPRISE: "Menter Gymdeithasol",
  SCHOOL: "Ysgol",
  FAITH_ORGANISATION: "Sefydliad Ffydd",
  OTHER_PLEASE_SPECIFY: "Arall (Nodwch)",
};

const organisationTypesValuesMapWelsh = {};
Object.keys(keys).forEach((e) => {
  organisationTypesValuesMapWelsh[keys[e]] = keysWelsh[e];
});

const organisationTypesListEnglishDropDown = Object.values(keys).map((e) => ({
  label: e,
  value: e,
}));

const organisationTypesListWelshDropDown = Object.entries(keysWelsh).map(
  ([key, value]) => ({
    label: value,
    value: keys[key],
  }),
);

export {
  keys,
  keysWelsh,
  organisationTypesListEnglishDropDown,
  organisationTypesListWelshDropDown,
  organisationTypesValuesMapWelsh,
};
