import { styled } from "@material-ui/core/styles";

export const Wrapper = styled("section")(() => ({
  position: "relative",
  flex: "1",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export const ValidatePage = styled("section")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flex: "1",
  flexDirection: "column",
}));

export const Body = styled("div")(({ theme }) => ({
  flex: "1",
  display: "flex",
  flexDirection: "column",
  height: "50%",
  alignItems: "flex-start",

  "& p": {
    marginLeft: "0",
    fontSize: "14px",
    width: "95%",
  },

  [theme.breakpoints.mobileS]: {
    paddingTop: theme.spacing(3),
    textAlign: "left",

    "& p": {
      marginTop: theme.spacing(3),
    },
  },

  [theme.breakpoints.mobileM]: {
    paddingTop: theme.spacing(4),

    "& p": {
      fontSize: "16px !important",
      width: "100% !important",
    },
  },

  [theme.breakpoints.mobileL]: {
    alignItems: "center",
    textAlign: "center",
    maxWidth: "350px",
    margin: "0 auto",

    "& p": {
      margin: "0 auto",
      marginTop: theme.spacing(3),
    },
  },

  [theme.breakpoints.mobileXL]: {
    textAlign: "center",
    "& p": {
      marginLeft: "auto",
      marginRight: "0",
      margin: "0 auto",
      marginTop: theme.spacing(3),
      width: "100%",
    },
  },

  [theme.breakpoints.tablet]: {
    alignItems: "center",
    textAlign: "center",
    paddingTop: theme.spacing(3),
    maxWidth: "350px",

    "& p": {
      marginLeft: "auto",
      margin: "0 auto",
      marginTop: theme.spacing(3),
    },
  },
}));
