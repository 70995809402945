import React from "react";

const CreditCircle = ({ width, height, color, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M32.3981 15.7756C32.3981 18.896 31.4613 21.9463 29.7062 24.5407C27.951 27.1352 25.4564 29.1573 22.5378 30.3513C19.6192 31.5453 16.4077 31.8576 13.3094 31.2487C10.211 30.6398 7.36511 29.1371 5.13147 26.9305C2.89783 24.7239 1.37681 21.9127 0.760757 18.8522C0.144705 15.7918 0.461295 12.6196 1.67049 9.73686C2.87968 6.85413 4.92717 4.39034 7.55402 2.65704C10.1809 0.923754 13.2691 -0.00117668 16.4281 -0.000778069C20.6625 0.00402355 24.722 1.66778 27.7159 4.62545C30.7099 7.58311 32.3937 11.5931 32.3981 15.7756ZM28.2643 23.2325L26.4932 22.2248L27.1196 21.1547L28.8907 22.1624C29.8375 20.3736 30.3739 18.4009 30.4618 16.3843H28.4091V15.1589H30.4597C30.3719 13.1417 29.8355 11.1682 28.8886 9.3788L27.1176 10.3865L26.4912 9.31834L28.2622 8.31065C27.1597 6.61114 25.6981 5.16735 23.9775 4.07835L22.9573 5.8277L21.88 5.21503L22.9002 3.46366C21.0889 2.52932 19.0919 1.99954 17.0504 1.91182V3.9272H15.8038V1.91182C13.7617 1.99951 11.764 2.52928 9.95201 3.46366L10.9722 5.21503L9.8908 5.83173L8.87061 4.08238C7.1506 5.17208 5.68905 6.61573 4.58585 8.31468L6.35688 9.32237L5.73253 10.3905L3.95946 9.38283C3.01349 11.1726 2.47715 13.1458 2.38837 15.1629H4.42874V16.3964H2.38837C2.47934 18.4111 3.01705 20.3815 3.96354 22.1684L5.73661 21.1607L6.36096 22.2309L4.58993 23.2386C5.69242 24.9381 7.15411 26.3819 8.87469 27.4709L9.89488 25.7215L10.9763 26.3402L9.95609 28.0916C11.7682 29.0254 13.7659 29.5544 15.8079 29.6414V27.6099H17.0566V29.6354C19.0982 29.5487 21.0954 29.0188 22.9063 28.0835L21.8861 26.3342L22.9695 25.7155L23.9897 27.4648C25.7066 26.3772 27.1648 24.9354 28.2643 23.2386V23.2325Z"
      fill={color || "currentColor"}
    />
  </svg>
);
export default CreditCircle;
