import React from "react";
import { styled } from "@material-ui/core/styles";

import LogoSmall from "../../assets/logo/logoSmall.png";
import LogoMed from "../../assets/logo/logoMed.png";
import LogoLarge from "../../assets/logo/logoLarge.png";
import Cinema from "../../assets/graphics/cinema.png";
import CinemaLarge from "../../assets/graphics/cinemaLarge.png";
import Football from "../../assets/graphics/football.png";
import GalleryWithBg from "../../assets/graphics/galleryWithBg.svg";
import Gallery from "../../assets/graphics/gallery.png";
import GalleryLarge from "../../assets/graphics/galleryLarge.png";
import Gardening from "../../assets/graphics/gardening.png";
import GardeningLarge from "../../assets/graphics/gardeningLarge.png";
import Group from "../../assets/graphics/group.png";
import GroupLarge from "../../assets/graphics/groupLarge.png";
import Gym from "../../assets/graphics/gym.png";
import GymLarge from "../../assets/graphics/gymLarge.png";
import Office from "../../assets/graphics/office.png";
import OfficeLarge from "../../assets/graphics/officeLarge.png";
import Painting from "../../assets/graphics/painting.png";
import PaintingLarge from "../../assets/graphics/paintingLarge.png";
import Reading from "../../assets/graphics/reading.png";
import Success from "../../assets/graphics/success.svg";
import SuccessLarge from "../../assets/graphics/successLarge.svg";
import Unsuccessful from "../../assets/graphics/unsuccessful.svg";
import UnsuccessfulLarge from "../../assets/graphics/unsuccessfulLarge.svg";
import WelcomeCustomer1 from "../../assets/graphics/welcomeCustomer1.png";
import WelcomeCustomer2 from "../../assets/graphics/welcomeCustomer2.png";
import WelcomeCustomer3 from "../../assets/graphics/welcomeCustomer3.png";
import WelcomeCustomer4 from "../../assets/graphics/welcomeCustomer4.png";
import WelcomeMember1 from "../../assets/graphics/welcomeMember1.png";
import WelcomeMember2 from "../../assets/graphics/welcomeMember2.png";
import WelcomeMember3 from "../../assets/graphics/welcomeMember3.png";
import WelcomeMember4 from "../../assets/graphics/welcomeMember4.png";
import MenuBottom from "../../assets/graphics/MenuBottom.svg";
import FooterBackgroundLeft from "../../assets/graphics/footerBackgroundLeft.svg";
import GiftShapes1 from "../../assets/graphics/giftShapes1.svg";
import GiftShapes2 from "../../assets/graphics/giftShapes2.svg";
import GiftWhite from "../../assets/graphics/giftWhite.svg";
import Graphic1 from "../../assets/graphics/graphic1.svg";
import Graphic2 from "../../assets/graphics/graphic2.svg";
import Graphic3 from "../../assets/graphics/graphic3.svg";
import Graphic4 from "../../assets/graphics/graphic4.svg";

const imgMap = {
  logoSmall: LogoSmall,
  logoMed: LogoMed,
  logoLarge: LogoLarge,
  cinema: Cinema,
  cinemaLarge: CinemaLarge,
  football: Football,
  galleryWithBg: GalleryWithBg,
  gallery: Gallery,
  galleryLarge: GalleryLarge,
  gardening: Gardening,
  gardeningLarge: GardeningLarge,
  group: Group,
  groupLarge: GroupLarge,
  gym: Gym,
  gymLarge: GymLarge,
  office: Office,
  officeLarge: OfficeLarge,
  painting: Painting,
  paintingLarge: PaintingLarge,
  reading: Reading,
  success: Success,
  successLarge: SuccessLarge,
  unsuccessful: Unsuccessful,
  unsuccessfulLarge: UnsuccessfulLarge,
  welcomeCustomer1: WelcomeCustomer1,
  welcomeCustomer2: WelcomeCustomer2,
  welcomeCustomer3: WelcomeCustomer3,
  welcomeCustomer4: WelcomeCustomer4,
  welcomeMember1: WelcomeMember1,
  welcomeMember2: WelcomeMember2,
  welcomeMember3: WelcomeMember3,
  welcomeMember4: WelcomeMember4,
  menuBottom: MenuBottom,
  footerBackgroundLeft: FooterBackgroundLeft,
  giftShapes1: GiftShapes1,
  giftShapes2: GiftShapes2,
  giftWhite: GiftWhite,
  graphic1: Graphic1,
  graphic2: Graphic2,
  graphic3: Graphic3,
  graphic4: Graphic4,
};

const StyledImage = styled("img")(({ width, height, margin, customStyle }) => ({
  maxWidth: "100%",
  width: width ? `${width}px` : "auto",
  height: height ? `${height}px` : "auto",
  margin: `${margin}` || "0 0 0 0",
  ...customStyle,
}));

const Image = ({
  image,
  width,
  height,
  margin,
  alt,
  customStyle,
  className,
}) => {
  if (!imgMap[image]) {
    // eslint-disable-next-line no-console
    console.warn(`<Image /> called with invalid image prop "${image}"`);
    return null;
  }

  return (
    <StyledImage
      src={imgMap[image]}
      alt={alt}
      width={width}
      height={height}
      margin={margin}
      customStyle={customStyle}
      className={className}
    />
  );
};

export default Image;
