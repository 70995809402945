import styled from "@material-ui/core/styles/styled";
import * as T from "../../../components/Typograpy";

export const Title = styled("div")(({ theme }) => ({
  margin: "0 auto",
  display: "none",
  [theme.breakpoints.tablet]: {
    display: "flex",
    justifyContent: "space-between",
    padding: `0 ${theme.spacing(0)}`,
    margin: 0,
    paddingTop: 0,
  },
}));

export const TitleContent = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flexStart",
}));

export const TitleMobile = styled("div")(({ theme }) => ({
  width: 200,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: `0 ${theme.spacing(0)}`,
  paddingTop: 20,
  [theme.breakpoints.tablet]: {
    display: "none",
  },
}));

export const Activities = styled("div")({
  display: "inline",
});

export const Activity = styled("div")(({ theme }) => ({
  display: "flex",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

export const NumberOfActivities = styled(T.H30)(({ color }) => ({
  margin: "0",
  lineHeight: "1.375rem",
  textAlign: "left",
  width: "30px",
  color,
}));

export const ActivityType = styled(T.H20)(({ theme }) => ({
  color: theme.colors.gray3,
  marginLeft: "5px",
  lineHeight: "1.375rem",
  textTransform: "lowercase",
}));

export const MobileCol = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
}));

export const MobileButton = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(5),
  display: "flex",
  [theme.breakpoints.tablet]: {
    display: "none",
  },
}));
