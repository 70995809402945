// actions go here
import {
  // Tempo Top Stats
  GET_TEMPO_TOP_STATS_LOADING_START,
  GET_TEMPO_TOP_STATS_LOADING_END,
  GET_TEMPO_TOP_STATS_SUCCESS,
  GET_TEMPO_TOP_STATS_FAIL,
} from "../../../redux/types";

import { insights } from "../../../api-calls";

export const getTempoTopStats = () => async (dispatch) => {
  dispatch({ type: GET_TEMPO_TOP_STATS_LOADING_START });

  try {
    const { data, error } = await insights.getTopStats({
      category: "top-stats",
    });

    if (data) {
      dispatch({
        type: GET_TEMPO_TOP_STATS_SUCCESS,
        payload: data,
      });
    } else if (error) {
      dispatch({
        type: GET_TEMPO_TOP_STATS_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_TEMPO_TOP_STATS_FAIL,
      payload: error,
    });
  }
  dispatch({ type: GET_TEMPO_TOP_STATS_LOADING_END });
};
