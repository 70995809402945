import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import Wrapper from "./style";
import * as T from "../Typograpy";
import Icon from "../Icon";
import Loading from "../Loading";
import t from "../../utils/helpers/translator";

const Add = ({ handleClick, disabled, isLoading, language }) => (
  <Wrapper>
    <IconButton
      onClick={handleClick}
      aria-label="Add"
      style={{ padding: 0 }}
      disabled={disabled}
    >
      {isLoading ? (
        <span style={{ height: "24px", width: "24px" }}>
          <Loading size={18} color="cyan" />
        </span>
      ) : (
        <Icon icon="plus" width="24px" height="24px" color="cyan" />
      )}
      <T.Body14B color="gray3">{t("add", language)}</T.Body14B>
    </IconButton>
  </Wrapper>
);

const mapStateToProps = (state) => ({
  language: state.auth.language,
});
export default connect(mapStateToProps)(Add);
