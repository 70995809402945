import {
  CREATE_TICKET_LOADING_START,
  CREATE_TICKET_SUCCESS,
  CREATE_TICKET_FAIL,
  CREATE_TICKET_LOADING_END,
} from "../types";

const initState = {
  zendeskLoading: false,
  error: null,
  result: null,
};

const createTicketReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_TICKET_LOADING_START:
      return { ...state, zendeskLoading: true };
    case CREATE_TICKET_SUCCESS:
      return { ...state, result: payload };
    case CREATE_TICKET_FAIL:
      return { ...state, error: payload };
    case CREATE_TICKET_LOADING_END:
      return { ...state, zendeskLoading: false };
    default:
      return state;
  }
};

export default createTicketReducer;
