import React from "react";
import { connect } from "react-redux";

import * as S from "./style";
import * as T from "../Typograpy";
import * as URL from "../../constants/externalLinks";
import userRoles from "../../constants/roles";
import Icon from "../Icon";
import translate from "../../utils/helpers/translator";

const { EARN_GROUP, EARN_GROUP_ADMIN, SPEND_VENUE, MEMBER } = userRoles;

const LinkArr = [
  {
    to: {
      general: URL.GENERAL_TERMS,
      [EARN_GROUP]: URL.EARN_TERMS,
      [EARN_GROUP_ADMIN]: URL.EARN_TERMS,
      [SPEND_VENUE]: URL.SPEND_VENUE_TERMS,
      [MEMBER]: URL.MEMBER_TERMS,
    },
    title: "termsOfUse",
  },
  {
    to: {
      general: URL.GENERAL_PRIVACY,
      [EARN_GROUP]: URL.EARN_PRIVACY,
      [EARN_GROUP_ADMIN]: URL.EARN_PRIVACY,
      [SPEND_VENUE]: URL.SPEND_VENUE_PRIVACY,
      [MEMBER]: URL.MEMBER_PRIVACY,
    },
    title: "privacy",
  },
  {
    to: { general: URL.COOKIE_POLICY },
    title: "cookies",
  },
];

const ExtLinkArr = [
  { to: URL.FACEBOOK, icon: "facebook" },
  { to: URL.TWITTER, icon: "twitter" },
  { to: URL.INSTAGRAM, icon: "instagram" },
  { to: URL.LINKEDIN, icon: "linkedin" },
];

const Footer = ({ navbar, profile, lang, role }) => (
  <S.Wrapper navbar={navbar} profile={profile}>
    <S.BackgroundLeft image="footerBackgroundLeft" />
    <S.BackgroundRight />
    <S.Links>
      {LinkArr.map((link) => {
        const href = link.to[role] || link.to.general;
        return (
          <S.StyledLink href={href} target="_blank" navbar={navbar}>
            <T.LinkS14>{translate(link.title, lang)}</T.LinkS14>
          </S.StyledLink>
        );
      })}
    </S.Links>
    <S.SocialLinkWrapper navbar={navbar}>
      {ExtLinkArr.map((link) => (
        <S.SocialLink href={link.to} target="_blank" navbar={navbar}>
          <Icon icon={link.icon} />
        </S.SocialLink>
      ))}
    </S.SocialLinkWrapper>
  </S.Wrapper>
);

const mapStateToProps = (state) => ({
  lang: state.auth.language,
  role: state.auth.role,
});

export default connect(mapStateToProps)(Footer);
