import Grid from "@material-ui/core/Grid";
import styled from "@material-ui/styles/styled";
import Typography from "@material-ui/core/Typography";

export const Container = styled(Grid)(() => ({
  maxWidth: "100%",
  flexBasis: "100%",
  display: "flex",
  justifyContent: "flex-start",
  "@media (min-width: 1155px)": {
    maxWidth: "60%",
    flexBasis: "60%",
    marginLeft: "-2%",
  },
}));

export const GridContent = styled("div")(() => ({
  width: "100%",
  paddingLeft: 0,
  display: "flex",
  flexDirection: "column",
}));

export const Title = styled("div")(({ theme }) => ({
  [theme.breakpoints.mobileS]: {
    marginTop: 40,
    marginBottom: 30,
  },
  [theme.breakpoints.laptop]: {
    marginTop: 60,
    marginBottom: 40,
  },
}));

export const WelcomeContainer = styled(Grid)({
  display: "none",
  paddingTop: "40px",
  "@media (min-width: 1155px)": {
    display: "flex",
  },
});

export const Box = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: 20,
  marginRight: 30,

  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  "& div div button": {
    top: "25% !important",
  },
  "& div": {
    maxWidth: "400px",

    "& div": {
      "& .css-2pdv6t-control": {
        height: 60,
      },
      "& .css-1m5e9s5-control": {
        height: 60,
      },
    },
  },

  [theme.breakpoints.tablet]: {
    alignItems: "flex-start",
    width: "50%",
    justifyContent: "flex-start",
  },
}));

export const Error = styled(Typography)(({ theme }) => ({
  ...theme.typography.placeholder,
  color: theme.colors.pink,
}));

export const SubmitBox = styled("div")(() => ({
  maxWidth: "400px",
  width: "100%",
}));

export const TextLinks = styled(Typography)(({ theme }) => ({
  color: theme.colors.gray3,
  ...theme.typography.placeholder,
}));

export const GridItemLinks = styled(Grid)(() => ({
  width: "100%",
  textAlign: "center",
  paddingTop: 0,
}));
