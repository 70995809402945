import {
  GET_PROGRAMMES_BASIC_ADMIN_LOADING,
  GET_PROGRAMMES_BASIC_ADMIN_SUCCESS,
  GET_PROGRAMMES_BASIC_ADMIN_FAIL,
} from "../types";
import { programmes } from "../../api-calls";

export const getBasicProgrammes = () => async (dispatch) => {
  dispatch({ type: GET_PROGRAMMES_BASIC_ADMIN_LOADING });
  try {
    const { data, error } = await programmes.getProgrammes({
      type: "basic",
    });
    if (data) {
      dispatch({
        type: GET_PROGRAMMES_BASIC_ADMIN_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: GET_PROGRAMMES_BASIC_ADMIN_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_PROGRAMMES_BASIC_ADMIN_FAIL,
      payload: error,
    });
  }
};
