import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import * as S from "./style";
import { TEMPO, GENERAL } from "../../../../constants/navRoutes";

import {
  getEarnGroupByUserId,
  getMembers,
  getTransactions,
  updateProgrammeEarnGroup,
  resetState,
  deleteEarnGroup,
} from "./actions";
import { getBasicProgrammes } from "../../../../redux/actions/programmes";
import * as T from "../../../../components/Typograpy";
import GoBack from "../../../../components/GoBack";
import Button from "../../../../components/Button";
import Stats from "../../../../components/Stats";
import TCBalance from "../../../../components/TCBalance";
import Dropdown from "../../../../components/Inputs/Dropdown";
import Icon from "../../../../components/Icon";
import RemoveRejoin from "../../../../components/RemoveRejoin";
import RemoveModal from "./RemoveModal";
import { Row, Col } from "../../../../components/Grid";
import WarningMessage from "../../../../components/WarningMessage";
import QuestionPop from "../../../../components/QuestionPop";

import Members from "./Members";
import Transactions from "./Transactions";
import SpendActivity from "./SpendActivity";

const IndividualEarnGroup = (props) => {
  const history = useHistory();
  const { id } = useParams();

  const {
    getEarnGroupByUserIdAction,
    getMembersAction,
    getTransactionsAction,
    earnGroupProgramme = {},
    membersLoading,
    transactionsLoading,
    programmes,
    getBasicProgrammesAction,
    updateProgrammeEarnGroupAction,
    updateEarnGroupSuccess,
    updateEarnGroupLoading,
    requests,
    firstName,
    lastName,
    name,
    email,
    phoneNumber,
    balance,
    activeMembers,
    issued,
    givenOut,
    accepted,
    spentPercentage,
    transactions,
    isPublic,
    resetStateAction,
    administratorsCount,
    updateEarnGroupError,
    inviteToken,
    hasWelsh,
    deleteEarnGroupAction,
    deleteEarnGroupLoading,
    deleteEarnGroupError,
    members,
    tkId,
  } = props;
  const { requestedCredits, id: requestID } = requests;

  useEffect(() => {
    if (Number(id) && typeof Number(id) === "number") {
      getEarnGroupByUserIdAction(id);
      getMembersAction(id);
      getTransactionsAction(id);
    }
    getBasicProgrammesAction();

    return resetStateAction;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleClick = () =>
    history.push(TEMPO.EDIT_EARN_GROUP_PROFILE.replace(":id", id));

  const [isOpen, setIsOpen] = useState(false);
  const [selectedProgramme, setSelectedProgramme] = useState("");
  const programmesList = programmes.map((e) => ({
    id: e.id,
    value: e.name,
    label: e.name,
  }));

  const [programme, setProgramme] = useState({ id: "", name: "" });
  const [lastAction, setLastAction] = useState(null);
  const [removeEGWarnOpen, setRemoveEGWarnOpen] = useState(false);

  useEffect(() => {
    if (earnGroupProgramme.id) {
      setProgramme(earnGroupProgramme);
    } else {
      setProgramme({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [earnGroupProgramme.id]);

  useEffect(() => {
    if (updateEarnGroupSuccess) {
      if (lastAction === "activated") {
        setProgramme({
          name: selectedProgramme.label,
          id: selectedProgramme.id,
        });
      } else {
        setProgramme({});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEarnGroupSuccess]);

  const stats = {
    "active volunteers": activeMembers,
    "time credits issued": issued,
    "time credits given out": givenOut,
    "time credits accepted": accepted || 0,
    "% used": spentPercentage,
  };

  // will update when get the information from TEMPO

  const bottomMessage = [
    "number of volunteers currently signed up to earn Time Credits with this Earn Group",
    null,
    null,
    null,
    "the % of Time Credits that have been earned with this Earn Group that have been used (with Earn Groups or Venues)",
  ];

  const statsArray = Object.entries(stats);

  const handleAdd = () => {
    if (selectedProgramme.id) {
      setLastAction("activated");
      updateProgrammeEarnGroupAction({
        earnGroupId: id,
        programmeId: selectedProgramme.id,
        status: "activated",
      });
    }
  };

  const issueCredits = () =>
    history.push(
      `${TEMPO.ISSUE_CREDITS_EARN_GROUP.replace(
        ":userId",
        id,
      )}/?showProgramme=true`,
    );

  const editGroupAdmin = () =>
    history.push(TEMPO.EDIT_EARN_GROUP_ADMIN.replace(":id", id));

  const handleCreditsRequest = (selected) => {
    switch (selected.value) {
      case "approve":
        return history.push(
          `${TEMPO.REQUEST_APPROVE.replace(
            ":requestId",
            requestID,
          )}/?showProgramme=true`,
        );
      case "partialApprove":
        return history.push(
          `${TEMPO.REQUEST_PARTIAL.replace(
            ":requestId",
            requestID,
          )}/?showProgramme=true`,
        );
      case "reject":
        return history.push(
          `${TEMPO.REQUEST_REJECT.replace(
            ":requestId",
            requestID,
          )}/?showProgramme=true`,
        );
      default:
        return null;
    }
  };

  const handleDeactivateEarnGroup = () => {
    setLastAction("deactivated");

    updateProgrammeEarnGroupAction({
      earnGroupId: id,
      programmeId: programme.id,
      status: "deactivated",
    });
  };

  const handleRemoveEarnGroup = async () => {
    await deleteEarnGroupAction({ id });
    history.goBack();
  };

  return (
    <S.Wrapper>
      {/* remove EG Warning */}
      <WarningMessage
        open={removeEGWarnOpen}
        setOpen={setRemoveEGWarnOpen}
        handleClick={handleRemoveEarnGroup}
        title="Are you sure you want to remove this Earn Group?"
      />
      {/* Remove Modal: should pass confirm function */}
      <RemoveModal
        modalOpen={isOpen}
        confirmFun={handleDeactivateEarnGroup}
        handleCloseModal={() => setIsOpen(false)}
        name={name}
        programmeName={programme && programme.name}
      />
      <GoBack showText onClick={() => history.push(TEMPO.EARN_GROUPS)} />
      <S.Header>
        <T.H40Caps color="midnight" style={{ margin: "0" }}>
          {name || "N/A"}
        </T.H40Caps>
        <TCBalance credits={balance || "0"} color="cyan" />
      </S.Header>
      {/* new stats section */}
      <Row
        style={{
          marginTop: "-40px",
        }}
      >
        {statsArray.map((stat, i) => (
          <Col w={[4, 2, 2]} style={{ marginLeft: "-15px" }}>
            <Stats
              size="s"
              statNum={stat[1] || "0"}
              statNumColor="pink"
              statText={stat[0] || "N/A"}
              statTextColor="primary"
              style={{ width: "auto" }}
              width="auto"
              bottomMessage={bottomMessage[i]}
            />
          </Col>
        ))}
      </Row>
      {/* Buttons section */}
      <Row mt={8}>
        <Col w={[4, 3, 3]}>
          <Button
            primary
            onClick={() => history.push(GENERAL.EARN_GROUP.replace(":id", id))}
          >
            View Earn Listing
          </Button>
        </Col>
        <Col w={[4, 3, 3]}>
          <Button outline onClick={handleClick}>
            Edit Earn Listing
          </Button>
        </Col>
      </Row>
      <Row mt={2}>
        <Col w={[4, 12, 12]}>
          <T.Body16B color="gray3" ml={5}>
            Earn Listing Status: {isPublic ? "Live" : "Draft"}
          </T.Body16B>
          <T.Body14R color="gray3" mt={5} ml={5}>
            Welsh version created:{" "}
            {
              <Icon
                icon={hasWelsh ? "tickRound" : "no"}
                width="20px"
                height="20px"
                margin="0 0 -4px 5px"
                color={hasWelsh ? "white" : "pink"}
              />
            }
          </T.Body14R>
        </Col>
      </Row>
      <Row mt={7}>
        <Col w={[4, 7, 7]}>
          <S.StyledCard width="100%">
            <Row mb={4}>
              <Col w={[4, 12, 12]}>
                <T.H24 color="midnight">Account Details</T.H24>
              </Col>
            </Row>
            <Row mb={3}>
              <Col w={[2, 4, 6]}>
                <T.H18 color="gray2">Name</T.H18>
              </Col>
              <Col w={[2, 8, 6]}>
                <T.Body16R style={{ textTransform: "capitalize" }}>
                  {firstName || lastName ? `${firstName} ${lastName}` : "N/A"}
                </T.Body16R>
              </Col>
            </Row>
            <Row mb={3}>
              <Col w={[2, 4, 6]}>
                <T.H18 color="gray2">Email</T.H18>
              </Col>
              <Col w={[2, 8, 6]}>
                <a
                  href={`mailto:${email}`}
                  rel="noopener noreferrer"
                  target="_blank"
                  alt="email"
                  style={{ textDecoration: "none" }}
                >
                  <T.Body16R color="blue">{email || "N/A"}</T.Body16R>
                </a>
              </Col>
            </Row>
            <Row mb={3}>
              <Col w={[2, 4, 6]}>
                <T.H18 color="gray2">Phone</T.H18>
              </Col>
              <Col w={[2, 8, 4]}>
                <T.Body16R>{phoneNumber || "N/A"}</T.Body16R>
              </Col>
            </Row>
            <Row>
              <Col w={[2, 4, 6]}>
                <T.H18 color="gray2" style={{ overflowWrap: "anywhere" }}>
                  Administrators
                </T.H18>
              </Col>
              <Col w={[2, 4, 4]}>
                <T.Body16R>{administratorsCount || "N/A"}</T.Body16R>
              </Col>
            </Row>
            <Row>
              <Col w={[4, 6, 6]}>
                <Button outline onClick={editGroupAdmin} mt={30}>
                  Edit Group Admin
                </Button>
              </Col>
            </Row>
          </S.StyledCard>
        </Col>
        <Col w={[4, 5, 5]}>
          <S.StyledCard width="100%" height="fit-content">
            <T.H24 color="midnight" mb={10}>
              Programmes
            </T.H24>
            <T.Body14R>
              Please note, an earn group can only be part of one programme at a
              time.
            </T.Body14R>
            {programme && programme.id ? (
              <>
                <S.ProgrammeName>
                  <T.LinkB16
                    color="blue"
                    style={{
                      margin: 0,
                      alignSelf: "center",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      history.push(
                        TEMPO.PROGRAMME_SINGLE.replace(":id", programme.id),
                      )
                    }
                  >
                    {programme.name}
                  </T.LinkB16>
                  <RemoveRejoin
                    type="Remove"
                    showText
                    handleClick={() => setIsOpen(true)}
                    loading={updateEarnGroupLoading}
                  />
                </S.ProgrammeName>
              </>
            ) : (
              <>
                <T.H16 color="midnight" mt={20}>
                  Programme
                </T.H16>
                <Dropdown
                  placeholder="Select programme..."
                  options={programmesList}
                  value={selectedProgramme}
                  setValue={({ selected }) => {
                    setSelectedProgramme(selected);
                  }}
                  size="large"
                />
                <Button
                  primary
                  onClick={handleAdd}
                  mt={30}
                  loading={updateEarnGroupLoading}
                >
                  Add to Programme
                </Button>
              </>
            )}
            {updateEarnGroupError.message && (
              <S.Error>{updateEarnGroupError.message}</S.Error>
            )}
          </S.StyledCard>
          <div style={{ marginTop: 30 }}>
            <S.Card>
              <Row>
                <Col w={[6, 6, 6]}>
                  <T.H18 color="midnight">Team Kinetic ID</T.H18>
                </Col>
                <Col w={[6, 6, 6]}>
                  <T.Body16R
                    style={{ textTransform: "capitalize", textAlign: "center" }}
                  >
                    {tkId || "N/A"}
                  </T.Body16R>
                </Col>
              </Row>
            </S.Card>
          </div>
          <Row mt="3">
            <div style={{ display: "flex" }}>
              <div style={{ marginLeft: "-1rem" }}>
                <RemoveRejoin
                  type="Remove"
                  text="Delete Earn Group"
                  showText
                  handleClick={setRemoveEGWarnOpen}
                  deactivated={transactions.length > 0}
                  loading={deleteEarnGroupLoading}
                />
              </div>
              {transactions.length > 0 && (
                <div style={{ marginTop: "0.5rem" }}>
                  <QuestionPop message="It is only possible to delete earn groups that have not been part of any transactions" />
                </div>
              )}
            </div>
            {deleteEarnGroupError && deleteEarnGroupError.message && (
              <S.Error>{deleteEarnGroupError.message}</S.Error>
            )}
          </Row>
        </Col>
        <Col w={[4, 6, 6]}>
          {requestedCredits ? (
            <div style={{ marginTop: 30 }}>
              <S.Card request>
                <Icon
                  icon="alertCycle"
                  width="100px"
                  height="100px"
                  color="cyan"
                />
                <S.Body>
                  <T.H18 color="gray2" mb={10}>
                    {name} has requested{" "}
                    <S.Credits>{requestedCredits}</S.Credits> Time Credits
                  </T.H18>
                  <Dropdown
                    placeholder="Select..."
                    options={[
                      { value: "approve", label: "Approve" },
                      { value: "partialApprove", label: "Partially Approve" },
                      { value: "reject", label: "Reject" },
                    ]}
                    customSelectFunc={handleCreditsRequest}
                    size="small"
                  />
                </S.Body>
              </S.Card>
            </div>
          ) : (
            <div style={{ marginTop: 33 }}>
              <S.Card>
                <T.H16 color="midnight" mb={10}>
                  Want to give {name} Time Credits?
                </T.H16>
                <Button
                  w="auto"
                  primary
                  style={{ margin: "0" }}
                  onClick={issueCredits}
                >
                  Issue Time Credits
                </Button>
              </S.Card>
            </div>
          )}
        </Col>
      </Row>
      <div style={{ marginTop: "80px" }}>
        <Transactions
          transactions={transactions}
          loading={transactionsLoading}
          earnGroupId={id}
          title="TIME CREDITS HISTORY"
          titleColor="pink"
        />
      </div>
      <Row mt={9}>
        <Col w={[4, 12, 12]}>
          <Members
            loading={membersLoading}
            earnGroupId={id}
            earnGroupName={name}
            inviteToken={inviteToken}
            members={members}
          />
        </Col>
      </Row>
      <Row mt={9}>
        <Col w={[4, 12, 12]}>
          <SpendActivity />
        </Col>
      </Row>
    </S.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  requests: state.staffSingleEarnGroup.requests,
  firstName: state.staffSingleEarnGroup.firstName,
  lastName: state.staffSingleEarnGroup.lastName,
  name: state.staffSingleEarnGroup.name,
  email: state.staffSingleEarnGroup.email,
  phoneNumber: state.staffSingleEarnGroup.phoneNumber,
  balance: state.staffSingleEarnGroup.balance,
  activeMembers: state.staffSingleEarnGroup.activeMembers,
  issued: state.staffSingleEarnGroup.issued,
  givenOut: state.staffSingleEarnGroup.givenOut,
  accepted: state.staffSingleEarnGroup.accepted,
  spentPercentage: state.staffSingleEarnGroup.spentPercentage,
  transactions: state.staffSingleEarnGroup.transactions,
  isPublic: state.staffSingleEarnGroup.isPublic,
  membersLoading: state.earnGroupMembers.loading,
  detailsLoading: state.staffSingleEarnGroup.detailsLoading,
  transactionsLoading: state.staffSingleEarnGroup.transactionsLoading,
  programmes: state.programmes.data,
  earnGroupProgramme: state.staffSingleEarnGroup.programme || {},
  updateEarnGroupSuccess: state.staffSingleEarnGroup.updateEarnGroupSuccess,
  updateEarnGroupLoading: state.staffSingleEarnGroup.updateEarnGroupLoading,
  administratorsCount: state.staffSingleEarnGroup.administratorsCount,
  updateEarnGroupError: state.staffSingleEarnGroup.updateEarnGroupError,
  inviteToken: state.staffSingleEarnGroup.inviteToken,
  hasWelsh: state.staffSingleEarnGroup.hasWelsh,
  deleteEarnGroupLoading: state.staffSingleEarnGroup.deleteEarnGroupLoading,
  deleteEarnGroupError: state.staffSingleEarnGroup.deleteEarnGroupError,
  members: state.earnGroupMembers.allMembers,
  tkId: state.staffSingleEarnGroup.tkId,
});

const mapActionsToProps = {
  getEarnGroupByUserIdAction: getEarnGroupByUserId,
  getMembersAction: getMembers,
  getTransactionsAction: getTransactions,
  getBasicProgrammesAction: getBasicProgrammes,
  updateProgrammeEarnGroupAction: updateProgrammeEarnGroup,
  resetStateAction: resetState,
  deleteEarnGroupAction: deleteEarnGroup,
};

export default connect(mapStateToProps, mapActionsToProps)(IndividualEarnGroup);
