/* eslint-disable func-names */
import React, { useState, useLayoutEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";

import validate from "../../validation/schemas/login";

import * as R from "../../constants/navRoutes";

// ACTIONS
import { login as loginAction } from "./actions";

import { LinkB14 } from "../../components/Typograpy";
import InputField from "../../components/Inputs/InputField";
import { Regular } from "../../components/Button";
import { ReCaptcha } from "../../components/TextSections";
import WelcomeSection from "./WelcomeSection";
import secondsToHms from "../../utils/helpers/secondsToHms";
import translate from "../../utils/helpers/translator";

import {
  GridContent,
  TextLinks,
  GridItem,
  Error,
  HeaderWrapper,
  GridItemLinks,
  ButtonErrorContainer,
  LoginForm,
  StyledTitle,
} from "./style";

const useQuery = () => new URLSearchParams(useLocation().search);

const AddGroupLogin = ({
  spendVenue,
  handleLogin,
  loginLoading,
  loginError,
  lang,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const history = useHistory();
  const query = useQuery();

  const programmeToken = query.get("programme");
  const administratorToken = query.get("administrator");
  const programmeManagerToken = query.get("programmeManager");

  const searchParams = new URLSearchParams();
  if (programmeToken) searchParams.append("programme", programmeToken);
  if (administratorToken) {
    searchParams.append("administrator", administratorToken);
  }
  if (programmeManagerToken) {
    searchParams.append("programmeManager", programmeManagerToken);
  }

  useLayoutEffect(() => {
    const script = document.createElement("script");

    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    const body = document.querySelector("body");
    body.appendChild(script);

    return () => {
      body.removeChild(script);
    };
  }, []);

  const onChange = (e) => {
    switch (e.target.name) {
      case "email":
        setEmail(e.target.value);
        break;
      case "password":
        setPassword(e.target.value);
        break;
      default:
        break;
    }
  };
  const handleClick = (e) => {
    e.preventDefault();
    const cleanEmail = email.toLowerCase().trim();
    try {
      validate({ email: cleanEmail, password }, null, { lang });
      if (process.env.NODE_ENV === "production") {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
              action: "login",
            })
            .then((reToken) => {
              handleLogin({
                email: cleanEmail,
                password,
                history,
                programmeToken,
                reToken,
              });
            });
        });
      } else {
        handleLogin({
          email: cleanEmail,
          password,
          history,
          programmeToken,
        });
      }
    } catch (err) {
      if (err.name === "ValidationError") {
        setEmailErr(err.inner.email);
        setPasswordErr(err.inner.password);
      }
    }
  };

  let error;
  if (loginError && loginError.message) {
    const { message, httpStatusCode, data } = loginError;
    error = translate(message, lang);
    if (httpStatusCode === 429) {
      const tryAfter = secondsToHms(data.tryAfter);
      error = `${translate("tooManyRequestsAfter", lang)} ${tryAfter}`;
    }
  }

  const decideSignupLink = () => {
    const link = R.GENERAL.SIGN_UP_FUNNEL;

    return link;
  };

  const translateAcceptTimeCredits = () => {
    const text = translate("clickToAcceptTimeCredits", lang).split(", ");
    return lang === "english" ? (
      <TextLinks>
        {text[0]}
        <Link
          to={{
            pathname: R.SPEND_VENUE.SPEND_LOG_IN,
            search: searchParams.toString(),
          }}
        >
          <LinkB14 color="blue" style={{ display: "inline", marginLeft: 5 }}>
            {text[1]}
          </LinkB14>
        </Link>
      </TextLinks>
    ) : (
      <TextLinks>
        <Link
          to={{
            pathname: R.SPEND_VENUE.SPEND_LOG_IN,
            search: searchParams.toString(),
          }}
        >
          <LinkB14 color="blue" style={{ display: "inline", marginRight: 5 }}>
            {text[0]}
          </LinkB14>
        </Link>
        {text[1]}
      </TextLinks>
    );
  };

  return (
    <Grid container md={12} xl={12} xs={12} style={{ flexGrow: 1 }}>
      <Grid
        item
        container
        direction="column"
        justify="center"
        alignItems="center"
        xl={6}
        style={{ display: "flex", flexGrow: 1 }}
      >
        <GridContent container>
          <GridItem style={{ order: 1 }}>
            <HeaderWrapper>
              <StyledTitle
                color="primary"
                style={{
                  marginBottom: "10px",
                  textAlign: "left",
                  width: "100%",
                }}
              >
                {translate("logIn", lang)}
              </StyledTitle>
            </HeaderWrapper>
          </GridItem>
          <LoginForm>
            <GridItem>
              <InputField
                placeholder={`${translate("typeEmail", lang)}...`}
                label={translate("email", lang)}
                name="email"
                value={email}
                setValue={onChange}
                error={emailErr}
                autoFocus
              />
            </GridItem>
            <GridItem style={{ marginBottom: "0" }}>
              <InputField
                type="password"
                placeholder={`${translate("typePassword", lang)}...`}
                label={translate("password", lang)}
                name="password"
                value={password}
                setValue={onChange}
                error={passwordErr}
              />
            </GridItem>
            <Link to={`${R.GENERAL.FORGET_PASSWORD}`}>
              <LinkB14 color="gray3" ml={15} mt={10}>
                {translate("forgotPassword", lang)}?
              </LinkB14>
            </Link>
            <ButtonErrorContainer>
              {error && <Error>{error}</Error>}
              <Regular
                primary
                bgColor="blue"
                size="l"
                handleClick={handleClick}
                style={{ margin: 0 }}
                loading={loginLoading}
                type="submit"
              >
                {translate("logIn", lang)}
              </Regular>
            </ButtonErrorContainer>
            <ReCaptcha />
          </LoginForm>

          <GridItemLinks spendVenue={spendVenue}>
            <TextLinks>
              {translate("dontHaveTempoAccount", lang)}?
              <Link
                to={{
                  pathname: decideSignupLink(),
                  search: searchParams.toString(),
                }}
              >
                <LinkB14
                  color="blue"
                  style={{ margin: "0 0 0 5px", display: "inline" }}
                >
                  {translate("signUp", lang)}
                </LinkB14>
              </Link>
            </TextLinks>
          </GridItemLinks>
          <GridItemLinks spendVenue={spendVenue} divider>
            {translateAcceptTimeCredits()}
          </GridItemLinks>
        </GridContent>
      </Grid>
      <Grid item container justify="center" md={6}>
        <WelcomeSection />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  loginLoading: state.auth.loginLoading,
  loginError: state.auth.loginError,
  lang: state.auth.language,
});

const mapActionToProps = { handleLogin: loginAction };

export default connect(mapStateToProps, mapActionToProps)(AddGroupLogin);
