import {
  GET_STAFF_INSIGHTS_LOADING,
  GET_STAFF_INSIGHTS_SUCCESS,
  GET_STAFF_INSIGHTS_FAIL,
} from "../../../redux/types";

const initState = {
  spendVenues: 0,
  spendPartners: 0,
  spentTcWithVenues: 0,
  liveOffers: 0,
  soldOutOffers: 0,
  draftOffers: 0,
  totalMembers: 0,
  totalIncome: 0,
  spentTcPercentage: 0,
  issuedTc: 0,
  earnedTc: 0,
  spentTc: 0,
  giftedTc: 0,
  loading: false,
  error: null,
};

const staffInsightsReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_STAFF_INSIGHTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_STAFF_INSIGHTS_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case GET_STAFF_INSIGHTS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default staffInsightsReducer;
