import { styled } from "@material-ui/core/styles";

export const FiledWrapper = styled("div")(() => ({
  marginTop: 30,
}));

export const Add = styled("button")(() => ({
  outline: "none",
  border: "none",
  background: "none",
  cursor: "pointer",
}));

export const TextWrapper = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});
