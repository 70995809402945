import { spendVenues } from "../../../../api-calls";

import {
  GET_STAFF_SINGLE_SPEND_VENUE_LOADING,
  GET_STAFF_SINGLE_SPEND_VENUE_SUCCESS,
  GET_STAFF_SINGLE_SPEND_VENUE_FAIL,
  GET_STAFF_SPEND_VENUE_ACTIVITIES_LOADING,
  GET_STAFF_SPEND_VENUE_ACTIVITIES_SUCCESS,
  GET_STAFF_SPEND_VENUE_ACTIVITIES_FAIL,
} from "../../../../redux/types";

export const getSpendVenueById = (spendVenueId) => async (dispatch) => {
  dispatch({ type: GET_STAFF_SINGLE_SPEND_VENUE_LOADING });
  try {
    const { data, error } = await spendVenues.getSpendVenueById({
      spendVenueId,
    });
    if (data) {
      dispatch({ type: GET_STAFF_SINGLE_SPEND_VENUE_SUCCESS, payload: data });
    } else {
      dispatch({
        type: GET_STAFF_SINGLE_SPEND_VENUE_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_STAFF_SINGLE_SPEND_VENUE_FAIL,
      payload: error,
    });
  }
};

export const getSpendVenueActivities = (spendVenueId) => async (dispatch) => {
  dispatch({ type: GET_STAFF_SPEND_VENUE_ACTIVITIES_LOADING });
  try {
    const { data, error } = await spendVenues.getActivitiesBySpendVenueId({
      spendVenueId,
    });
    if (data) {
      dispatch({
        type: GET_STAFF_SPEND_VENUE_ACTIVITIES_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: GET_STAFF_SPEND_VENUE_ACTIVITIES_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_STAFF_SPEND_VENUE_ACTIVITIES_FAIL,
      payload: error,
    });
  }
};
