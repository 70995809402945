import { styled } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

export const BigLinkWrapper = styled(Link)(({ theme, color }) => ({
  width: "100%",
  height: "215px",
  background: theme.gradients[color] || theme.gradients.blue,
  display: "flex",
  alignItems: "center",
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
  textDecoration: "none !important",
  boxShadow: theme.customShadows.card,

  [theme.breakpoints.mobileXL]: {
    width: "300px",
  },

  [theme.breakpoints.tablet]: {
    width: "375px",
  },
}));

export const InviteLinkWrapper = styled("div")(({ theme, background }) => ({
  width: "100%",
  maxWidth: "550px",
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1),
  background: background ? theme.colors[background] : theme.colors.pinkLight,
  justifyContent: "space-between",
  position: "relative",

  [theme.breakpoints.mobileL]: {
    padding: theme.spacing(4),
  },
}));

export const InviteLink = styled("a")(({ theme, color }) => ({
  textDecoration: "none !important",
  color: theme.colors[color] || theme.colors.pink,
  marginRight: theme.spacing(3),
  wordWrap: "break-word !important",
  width: "80%",
  overflowWrap: "break-word",
  "-ms-word-break": "break-all",
  wordBreak: "break-word",
  fontSize: "16px",
  fontWeight: "bold",

  [theme.breakpoints.mobileM]: {
    "& p": {
      fontSize: "14px",
      wordWrap: "break-word",
    },
  },

  [theme.breakpoints.mobileL]: {
    "& p": {
      fontSize: "18px",
    },
  },

  [theme.breakpoints.tablet]: {
    "& p": {
      fontSize: "22px",
      wordWrap: "break-word",
    },
  },
}));

export const OpportunityLinkWrapper = styled("div")(({ theme }) => ({
  maxWidth: "350px",
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(3),
  borderTop: `1px ${theme.colors.gray6} solid`,
  borderBottom: `1px ${theme.colors.gray6} solid`,

  [theme.breakpoints.tablet]: {
    border: "none",
    alignItems: "flex-start",
  },
}));

export const FindOutMore = styled(Link)(({ theme, type }) => ({
  display: type === "icon" ? "flex" : "none",
  marginTop: type === "text" && theme.spacing(1),
  alignItems: type === "icon" && "center",
  marginLeft: type === "icon" && theme.spacing(4),
  [theme.breakpoints.tablet]: {
    display: type === "icon" ? "none" : "block",
  },
  color: theme.colors.blue,
}));

export const Details = styled("span")(({ theme }) => ({
  [theme.breakpoints.tablet]: {
    "& .venue": {
      fontSize: "24px",
      lineHeight: "25px",
      fontWeight: "900",
    },
  },
}));

export const ViewMore = styled(Link)(({ theme, padding }) => ({
  color: theme.colors.midnight,
  display: "flex",
  alignItems: "center",
  padding,
  width: "content-fit",
  [theme.breakpoints.mobileXL]: {
    justifyContent: "flex-start",
  },
}));
