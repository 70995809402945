import {
  RESET_PASSWORD_LINK_SUCCESS,
  RESET_PASSWORD_LINK_FAIL,
  RESET_PASSWORD_LINK_LOADING_START,
  RESET_PASSWORD_LINK_LOADING_END,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_FAIL,
  SET_PASSWORD_LOADING_START,
  SET_PASSWORD_LOADING_END,
  RESET_FORGET_PASSWORD_STATE,
} from "../../../redux/types";

const initState = {
  sendLinkLoading: false,
  sendLinkError: {},
  sendLinkSuccess: null,
  // set password
  setPasswordError: {},
  setPasswordLoading: false,
  setPasswordSuccess: null,
};

const resetPasswordReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case RESET_PASSWORD_LINK_LOADING_START:
      return {
        ...state,
        sendLinkLoading: true,
      };
    case RESET_PASSWORD_LINK_LOADING_END:
      return {
        ...state,
        sendLinkLoading: false,
      };

    case RESET_PASSWORD_LINK_SUCCESS:
      return {
        ...state,
        sendLinkSuccess: true,
      };
    case RESET_PASSWORD_LINK_FAIL:
      return {
        sendLinkError: payload,
        sendLinkSuccess: false,
      };

    // set password
    case SET_PASSWORD_LOADING_START:
      return {
        ...state,
        setPasswordLoading: true,
      };
    case SET_PASSWORD_LOADING_END:
      return {
        ...state,
        setPasswordLoading: false,
      };

    case SET_PASSWORD_SUCCESS:
      return {
        ...state,
        setPasswordSuccess: true,
      };

    case SET_PASSWORD_FAIL:
      return {
        setPasswordError: payload,
        setPasswordSuccess: false,
      };

    case RESET_FORGET_PASSWORD_STATE:
      return initState;

    default:
      return state;
  }
};

export default resetPasswordReducer;
