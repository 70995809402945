import {
  string,
  number,
  date,
  boolean,
  array,
  object,
  ValidationError,
  lazy,
} from "yup";
import moment from "moment";
import * as errMsgs from "./err-msgs";
import { roles } from "../constants";

import "./custom-funtions";

// GENERAL VALIDATION FIELDS
export const textRequired = string()
  .required(errMsgs.DEFAULT_REQUIRED)
  .min(2, errMsgs.DEFAULT_REQUIRED)
  .typeError(errMsgs.DEFAULT_REQUIRED);

export const arrRequired = array()
  .of(string())
  .typeError(errMsgs.ARRAY_REQUIRED)
  .required(errMsgs.DEFAULT_REQUIRED);

export const arrOptional = array()
  .of(string())
  .typeError(errMsgs.ARRAY_REQUIRED)
  .nullable();

export const numRequired = number().required(errMsgs.DEFAULT_REQUIRED);
export const positiveNumRequired = number()
  .required(errMsgs.DEFAULT_REQUIRED)
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .test("Is positive?", errMsgs.MORE_THAN_ZERO, (value) => value > 0);

export const maximumTc = number()
  .required(errMsgs.DEFAULT_REQUIRED)
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .test({
    name: "grater than min",
    exclusive: false,
    params: {},
    message: errMsgs.MAX_SHOULD_BE_GRATER_THAN_MIN,
    test(value) {
      return value > this.parent.minimumTc;
    },
  });

export const booleanValue = boolean();
export const booleanNullable = boolean().nullable();

export const url = string()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .url(errMsgs.INVALID_URL)
  .nullable();

export const urlRequired = string()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .url(errMsgs.INVALID_URL)
  .required(errMsgs.DEFAULT_REQUIRED);

export const socialLinks = object()
  .shape({
    facebook: url,
    twitter: url,
  })
  .nullable();

export const activitiesRoles = array().of(
  object()
    .shape({
      name: string().when("details", {
        is: (v) => !!v,
        then: string().required(errMsgs.DEFAULT_REQUIRED),
        otherwise: string().nullable(),
      }),
      details: string().nullable(),
    })
    .nullable(),
);

export const activitiesRolesWelsh = array().of(
  object()
    .shape({
      name: string().when("details", {
        is: (v) => !!v,
        then: string().required(errMsgs.DEFAULT_REQUIRED),
        otherwise: string().nullable(),
      }),
      nameWelsh: string().when("name", {
        is: (v) => !!v,
        then: string().required(errMsgs.DEFAULT_REQUIRED),
        otherwise: string().nullable(),
      }),
      details: string().nullable(),
    })
    .nullable(),
);

// continueSignup

export const gender = string().required(errMsgs.DEFAULT_REQUIRED);
export const ethnic = string().required(errMsgs.DEFAULT_REQUIRED);
export const mentalHealthCondition = string().required(
  errMsgs.DEFAULT_REQUIRED,
);
export const volunteeredBefore = string().required(errMsgs.DEFAULT_REQUIRED);

export const emailArr = array()
  .of(string().email(errMsgs.MULTI_EMAILS))
  .test(
    "unique",
    "email must be unique",
    (emails) => emails.length === new Set([...emails]).size,
  )
  .nullable();

export const firstName = string()
  .min(1, errMsgs.TOO_SHORT_MIN_1)
  .max(20)
  .required(errMsgs.DEFAULT_REQUIRED)
  .typeError(errMsgs.DEFAULT_REQUIRED);

export const postcode = string()
  .required(errMsgs.DEFAULT_REQUIRED)
  .min(6, errMsgs.TOO_SHORT_MIN_5)
  .max(8, errMsgs.TOO_LONG_MAX_7)
  .matches(
    /\b(([a-z][0-9]{1,2})|(([a-z][a-hj-y][0-9]{1,2})|(([a-z][0-9][a-z])|([a-z][a-hj-y][0-9]?[a-z])))) [0-9][a-z]{2}\b/gi,
    errMsgs.INVALID_POSTCODE,
  );

export const lastName = string()
  .min(1, errMsgs.TOO_SHORT_MIN_1)
  .max(20)
  .required(errMsgs.DEFAULT_REQUIRED)
  .typeError(errMsgs.DEFAULT_REQUIRED);

export const spendPartnerName = string()
  .min(1, errMsgs.TOO_SHORT_MIN_1)
  .max(50, errMsgs.TOO_LONG_MAX_50)
  .required(errMsgs.DEFAULT_REQUIRED);

export const email = string()
  .email(errMsgs.INVALID_EMAIL)
  .max(50, errMsgs.TOO_LONG_MAX_50)
  .required(errMsgs.DEFAULT_REQUIRED)
  .typeError(errMsgs.DEFAULT_REQUIRED);

export const optionalEmail = string()
  .email(errMsgs.INVALID_EMAIL)
  .max(50, errMsgs.TOO_LONG_MAX_50)
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .nullable();

export const password = string()
  .matches(
    /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)(?=.{8,}).*$/,
    errMsgs.SHORT_PASSWORD,
  )
  .required(errMsgs.DEFAULT_REQUIRED);

export const loginPassword = string().required(errMsgs.DEFAULT_REQUIRED);

export const role = string()
  .oneOf(Object.values(roles), errMsgs.INVALID_ROLE)
  .required(errMsgs.DEFAULT_REQUIRED);

export const adultsParticipants = number().when("childrenParticipants", {
  is: (val, a) => !val && !a,
  then: number().min(1, errMsgs.NO_PARTICIPANTS),
  otherwise: number(),
});

export const id = number()
  .min(1)
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .required(errMsgs.DEFAULT_REQUIRED);

export const membershipId = string()
  .length(8, errMsgs.MEMBERSHIP_LENGTH)
  .required(errMsgs.DEFAULT_REQUIRED);
export const birthDate = date()
  .required(errMsgs.DEFAULT_REQUIRED)
  .max(new Date(), "future date not allowed");

// is this enough for custom activity
export const customActivityName = string().required(errMsgs.DEFAULT_REQUIRED);
export const customPersonTcPrice = number()
  .min(1, errMsgs.MORE_THAN_ZERO)
  .required(errMsgs.DEFAULT_REQUIRED);

export const personTcPrice = number().when("costVaries", {
  is: (c) => !c,
  then: number().min(1).required(),
  otherwise: number(),
});

export const personTcPriceOptional = number().when((value, schema) => {
  if (value) {
    return schema.min(1);
  }
  return schema.nullable();
});

export const phoneNumber = string()
  .phone()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .max(20, errMsgs.INVALID_PHONE)
  .required(errMsgs.DEFAULT_REQUIRED);

export const optionalPhoneNumber = string().when((value, schema) => {
  if (value) {
    return schema.phone().typeError(errMsgs.INVALID_PHONE);
  }
  return schema.nullable();
});

export const agreedOnTerms = boolean()
  .oneOf([true], errMsgs.SHOULD_AGREE_ON_TERMS)
  .required(errMsgs.DEFAULT_REQUIRED);

export const confirmPermission = boolean()
  .oneOf([true], errMsgs.SHOULD_CONFIRM_PERMISSION)
  .required(errMsgs.DEFAULT_REQUIRED);

export const agreedAge = boolean()
  .oneOf([true], errMsgs.AGREED_AGE) //
  .required(errMsgs.DEFAULT_REQUIRED);

export const organisationName = string()
  .max(100, errMsgs.TOO_LONG_MAX_100)
  .required(errMsgs.DEFAULT_REQUIRED);
export const organisationType = string()
  .notOneOf(["Other (Please Specify)"], errMsgs.DEFAULT_REQUIRED)
  .max(100, errMsgs.TOO_LONG_MAX_100)
  .required(errMsgs.DEFAULT_REQUIRED);
export const focusFields = array()
  .of(string())
  .required(errMsgs.DEFAULT_REQUIRED)
  .typeError(errMsgs.DEFAULT_REQUIRED);
export const focusFieldsOptional = array()
  .of(string())
  .typeError(errMsgs.DEFAULT_REQUIRED);

export const regionCountry = string().required(errMsgs.DEFAULT_REQUIRED);

export const region = string().required(errMsgs.DEFAULT_REQUIRED);

export const localAuthority = string().required(errMsgs.DEFAULT_REQUIRED);

export const token = string().length(8).required(errMsgs.DEFAULT_REQUIRED);

export const requiredText = (charLimit) =>
  string()
    .typeError(errMsgs.DEFAULT_REQUIRED)
    .max(charLimit)
    .required(errMsgs.DEFAULT_REQUIRED);

export const optionalText = (charLimit) =>
  string().when((value, schema) => {
    if (charLimit && value) {
      return schema
        .max(charLimit, errMsgs.TOO_LONG_MAX_500)
        .typeError(errMsgs.DEFAULT_REQUIRED)
        .nullable();
    }
    if (value) {
      return schema.typeError(errMsgs.DEFAULT_REQUIRED);
    }
    return schema.nullable();
  });

export const text = (charLimit) =>
  string()
    .transform((val) => val || undefined)
    .max(charLimit);

export const startDate = date().required(errMsgs.DEFAULT_REQUIRED).nullable();

export const budget = number()
  .required(errMsgs.DEFAULT_REQUIRED)
  .min(1, errMsgs.MORE_THAN_ZERO);

export const mainFocus = string().required(errMsgs.DEFAULT_REQUIRED);

export const otherKeyFocuses = array()
  .of(string().required(errMsgs.DEFAULT_REQUIRED))
  .typeError("Must be an option from the list")
  .nullable();

// SPEND ACTIVITY
export const atLeastOneWayToSpend = object()
  .shape({
    turnUpAtVenue: boolean(),
    callToBook: boolean(),
    onlineCodesToBook: boolean(),
  })
  .test(
    "at least one way to spend is picked",
    "atLeastOneWayToSpend",
    (obj) => {
      if (obj.turnUpAtVenue || obj.callToBook || obj.onlineCodesToBook) {
        return true;
      }
      return new ValidationError(
        "Please select at least one way to spend Time Credits",
        "",
        "atLeastOneWayToSpend",
      );
    },
  );

export const endDate = date().when("isLive", {
  is: true,
  then: date()
    .min(new Date(), errMsgs.END_DATE_FUTURE)
    .nullable()
    .default(undefined),
  otherwise: date().nullable().default(undefined),
});

export const isLive = boolean().when("cloneEndDate", (_endDate, schema) =>
  _endDate && moment(_endDate).isBefore(moment())
    ? schema.oneOf([false], errMsgs.END_DATE_FUTURE)
    : schema.nullable(),
);

export const callExtraDetails = string().when("callToBook", {
  is: true,
  then: string().required(errMsgs.DEFAULT_REQUIRED).max(300),
  otherwise: string().nullable(),
});

export const bookingCodes = array().when("onlineCodesToBook", {
  is: true,
  then: array().of(string().max(50)).required(errMsgs.DEFAULT_REQUIRED),
});
export const bookingCodesOptional = array().of(string().max(50)).nullable();

export const requiredNumber = number()
  .required(errMsgs.DEFAULT_REQUIRED)
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .min(0, errMsgs.DEFAULT_REQUIRED);

export const optionalNumber = lazy((value) =>
  value === ""
    ? string().nullable()
    : number()
        .typeError(errMsgs.DEFAULT_REQUIRED)
        .min(0, errMsgs.DEFAULT_REQUIRED)
        .nullable(),
);
export const optionalNumberGreaterThan0 = lazy((value) =>
  value === ""
    ? string().nullable()
    : number()
        .typeError(errMsgs.DEFAULT_REQUIRED)
        .min(1, errMsgs.SHOULD_BE_GREATER_THAN_0_OR_EMPTY)
        .nullable(),
);

export const useCodesDetails = string().when("onlineCodesToBook", {
  is: true,
  then: string()
    .typeError(errMsgs.DEFAULT_REQUIRED)
    .required(errMsgs.DEFAULT_REQUIRED)
    .max(300),
  otherwise: string().nullable(),
});

export const useCodesLink = string().when("onlineCodesToBook", {
  is: true,
  then: url,
  otherwise: string().nullable(),
});

export const addMembersToEarnGroup = object().shape({
  earnGroupUserId: string().required(errMsgs.DEFAULT_REQUIRED),
  membersToAdd: array().of(
    object().shape({
      id: string().required(errMsgs.DEFAULT_REQUIRED),
      name: string().required(errMsgs.DEFAULT_REQUIRED),
      userId: number().min(1).required(errMsgs.DEFAULT_REQUIRED),
    }),
  ),
});

export const dateTimes = array().when("isAvailableAnyTime", {
  is: (v) => !v,
  then: array().of(
    object().shape({
      dates: object().shape({
        startDate: date()
          .required(errMsgs.DEFAULT_REQUIRED)
          .typeError(errMsgs.DEFAULT_REQUIRED),
        endDate: date()
          .required(errMsgs.DEFAULT_REQUIRED)
          .typeError(errMsgs.DEFAULT_REQUIRED),
      }),
      times: object().shape({
        startTime: string()
          .required(errMsgs.DEFAULT_REQUIRED)
          .typeError(errMsgs.DEFAULT_REQUIRED),
        endTime: string()
          .required(errMsgs.DEFAULT_REQUIRED)
          .typeError(errMsgs.DEFAULT_REQUIRED),
      }),
      repeats: string()
        .required(errMsgs.DEFAULT_REQUIRED)
        .typeError(errMsgs.DEFAULT_REQUIRED),
    }),
  ),
  otherwise: array().nullable(),
});

export const dateTimesOptional = array().of(
  object().shape({
    dates: object().shape({
      startDate: date().typeError(errMsgs.DEFAULT_REQUIRED).nullable(),
      endDate: date().typeError(errMsgs.DEFAULT_REQUIRED).nullable(),
    }),
    times: object().shape({
      startTime: string().typeError(errMsgs.DEFAULT_REQUIRED).nullable(),
      endTime: string().typeError(errMsgs.DEFAULT_REQUIRED).nullable(),
    }),
    repeats: string().typeError(errMsgs.DEFAULT_REQUIRED),
  }),
);

export const addresses = array().when("isOnline", {
  is: false,
  then: array().of(
    object().shape({
      addressLine1: string()
        .required(errMsgs.DEFAULT_REQUIRED)
        .typeError(errMsgs.DEFAULT_REQUIRED),
      addressLine2: string(),
      city: string()
        .required(errMsgs.DEFAULT_REQUIRED)
        .typeError(errMsgs.DEFAULT_REQUIRED),
      county: string().typeError(errMsgs.DEFAULT_REQUIRED),
      postcode: string()
        .required(errMsgs.DEFAULT_REQUIRED)
        .min(6, errMsgs.TOO_SHORT_MIN_5)
        .max(8, errMsgs.TOO_LONG_MAX_7)
        .matches(
          /\b(([a-z][0-9]{1,2})|(([a-z][a-hj-y][0-9]{1,2})|(([a-z][0-9][a-z])|([a-z][a-hj-y][0-9]?[a-z])))) [0-9][a-z]{2}\b/gi,
          errMsgs.INVALID_POSTCODE,
        )
        .typeError(errMsgs.DEFAULT_REQUIRED),
    }),
  ),
  otherwise: array().nullable(),
});

export const addressesOptional = array().of(
  object().shape({
    addressLine1: string().typeError(errMsgs.DEFAULT_REQUIRED),
    addressLine2: string(),
    city: string().typeError(errMsgs.DEFAULT_REQUIRED),
    county: string().typeError(errMsgs.DEFAULT_REQUIRED),
    postcode: string().when((value, schema) => {
      if (value) {
        return schema
          .min(6, errMsgs.TOO_SHORT_MIN_5)
          .max(8, errMsgs.TOO_LONG_MAX_7)
          .matches(
            /\b(([a-z][0-9]{1,2})|(([a-z][a-hj-y][0-9]{1,2})|(([a-z][0-9][a-z])|([a-z][a-hj-y][0-9]?[a-z])))) [0-9][a-z]{2}\b/gi,
            errMsgs.INVALID_POSTCODE,
          )
          .typeError(errMsgs.DEFAULT_REQUIRED);
      }
      return schema.nullable();
    }),
  }),
);

export const addressesWelshOptional = array().of(
  object().shape({
    addressLine1: string().typeError(errMsgs.DEFAULT_REQUIRED),
    addressLine2: string(),
    city: string().typeError(errMsgs.DEFAULT_REQUIRED),
    county: string().typeError(errMsgs.DEFAULT_REQUIRED),
    postcode: string().when((value, schema) => {
      if (value) {
        return schema
          .min(6, errMsgs.TOO_SHORT_MIN_5)
          .max(8, errMsgs.TOO_LONG_MAX_7)
          .matches(
            /\b(([a-z][0-9]{1,2})|(([a-z][a-hj-y][0-9]{1,2})|(([a-z][0-9][a-z])|([a-z][a-hj-y][0-9]?[a-z])))) [0-9][a-z]{2}\b/gi,
            errMsgs.INVALID_POSTCODE,
          )
          .typeError(errMsgs.DEFAULT_REQUIRED);
      }
      return schema.nullable();
    }),
    addressLine1Welsh: string().typeError(errMsgs.DEFAULT_REQUIRED),
    addressLine2Welsh: string(),
    cityWelsh: string().typeError(errMsgs.DEFAULT_REQUIRED),
    countyWelsh: string().typeError(errMsgs.DEFAULT_REQUIRED),
  }),
);

export const addressesWelsh = array().when("isOnline", {
  is: false,
  then: array().of(
    object().shape({
      addressLine1: string()
        .required(errMsgs.DEFAULT_REQUIRED)
        .typeError(errMsgs.DEFAULT_REQUIRED),
      addressLine2: string(),
      city: string()
        .required(errMsgs.DEFAULT_REQUIRED)
        .typeError(errMsgs.DEFAULT_REQUIRED),
      county: string().typeError(errMsgs.DEFAULT_REQUIRED),
      postcode: string()
        .required(errMsgs.DEFAULT_REQUIRED)
        .min(6, errMsgs.TOO_SHORT_MIN_5)
        .max(8, errMsgs.TOO_LONG_MAX_7)
        .matches(
          /\b(([a-z][0-9]{1,2})|(([a-z][a-hj-y][0-9]{1,2})|(([a-z][0-9][a-z])|([a-z][a-hj-y][0-9]?[a-z])))) [0-9][a-z]{2}\b/gi,
          errMsgs.INVALID_POSTCODE,
        )
        .typeError(errMsgs.DEFAULT_REQUIRED),
      addressLine1Welsh: string()
        .required(errMsgs.DEFAULT_REQUIRED)
        .typeError(errMsgs.DEFAULT_REQUIRED),
      addressLine2Welsh: string(),
      cityWelsh: string()
        .required(errMsgs.DEFAULT_REQUIRED)
        .typeError(errMsgs.DEFAULT_REQUIRED),
      countyWelsh: string().typeError(errMsgs.DEFAULT_REQUIRED),
    }),
  ),
  otherwise: array().nullable(),
});

export const addressFieldRequired = string().when("isOnline", {
  is: false,
  then: string()
    .required(errMsgs.DEFAULT_REQUIRED)
    .max(100, errMsgs.TOO_LONG_MAX_100)
    .typeError(errMsgs.DEFAULT_REQUIRED),
  otherwise: string().nullable(),
});

export const cityFieldRequired = string().when("isOnline", {
  is: false,
  then: string()
    .required(errMsgs.DEFAULT_REQUIRED)
    .max(30)
    .typeError(errMsgs.DEFAULT_REQUIRED),
  otherwise: string().nullable(),
});

export const addressFieldOptional = string()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .max(100, errMsgs.TOO_LONG_MAX_100)
  .nullable();

export const county = string()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .max(50, errMsgs.TOO_LONG_MAX_50)
  .nullable();

export const cityFieldOptional = string()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .max(30)
  .nullable();

export const postcodeRequired = string().when("isOnline", {
  is: false,
  then: postcode,
  otherwise: string().nullable(),
});

export const postcodeOptional = string().when((value, schema) => {
  if (value) {
    return schema
      .min(6, errMsgs.TOO_SHORT_MIN_5)
      .max(8, errMsgs.TOO_LONG_MAX_7)
      .matches(
        /\b(([a-z][0-9]{1,2})|(([a-z][a-hj-y][0-9]{1,2})|(([a-z][0-9][a-z])|([a-z][a-hj-y][0-9]?[a-z])))) [0-9][a-z]{2}\b/gi,
        errMsgs.INVALID_POSTCODE,
      );
  }
  return schema.nullable();
});

export const packages = array().of(
  object().shape({
    credits: number()
      .required(errMsgs.DEFAULT_REQUIRED)
      .typeError(errMsgs.DEFAULT_REQUIRED)
      .test({
        name: "grater than min",
        exclusive: false,
        params: {},
        message: errMsgs.SHOULD_BE_GRATER_THAN_MIN,
        test(value) {
          return value >= this.parent.minimumTc;
        },
      })
      .test({
        name: "less than max",
        exclusive: false,
        params: {},
        message: errMsgs.SHOULD_BE_LESS_THAN_MAX,
        test(value) {
          return value <= this.parent.maximumTc;
        },
      }),
  }),
);
// export const address = object()
//   .shape({
//     addressLine1: string().when("isOnline", {
//       is: false,
//       then: string()
//         .required(errMsgs.DEFAULT_REQUIRED)
//         .typeError(errMsgs.DEFAULT_REQUIRED),
//       otherwise: string().nullable(),
//     }),
//     addressLine2: string(),
//     city: string().when("isOnline", {
//       is: false,
//       then: string()
//         .required(errMsgs.DEFAULT_REQUIRED)
//         .typeError(errMsgs.DEFAULT_REQUIRED),
//       otherwise: string().nullable(),
//     }),
//     postcode: string().when("isOnline", {
//       is: false,
//       then: string()
//         .required(errMsgs.DEFAULT_REQUIRED)
//         .typeError(errMsgs.DEFAULT_REQUIRED),
//       otherwise: string().nullable(),
//     }),
//     region: string().when("isOnline", {
//       is: false,
//       then: region,
//       otherwise: string().nullable(),
//     }),
//   })
//   .required();

export const customTCRequested = number()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .test({
    name: "grater than min",
    exclusive: false,
    params: {},
    message: errMsgs.SHOULD_BE_GREATER_THAN_MIN_VALUE,
    test(value) {
      return value >= this.parent.minimumTc;
    },
  })
  .test({
    name: "less than max",
    exclusive: false,
    params: {},
    message: errMsgs.SHOULD_BE_LESS_THAN_MAX,
    test(value) {
      return value <= this.parent.maximumTc;
    },
  })
  .nullable();

export const confirmationPassword = string().when("backendError", {
  is: (backendError) => backendError === "multipleAccountConfirmationRequired",
  then: string().required(errMsgs.DEFAULT_REQUIRED),
  otherwise: string().nullable(),
});
