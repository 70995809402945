import React from "react";
import { useParams } from "react-router-dom";
import Icon from "../../Icon";

import * as S from "./style";
import * as T from "../../Typograpy";
import Loading from "../../Loading";
import useCsvDownload from "../../../hooks/useCsvDownload";
import fileSizeFormatter from "../../../utils/helpers/fileSizeFormatter";

const ProgrammeSection = ({
  sectionLink,
  stat,
  section,
  sectionPromo,
  w,
  download,
  m,
  loading,
  type,
}) => {
  const { id: programmeId } = useParams();
  const url = `/csv/programmes/${programmeId}?type=${type}`;
  const [data, handleClick] = useCsvDownload(url);

  if (download) {
    const { size, loading: downloadLoading, error } = data;
    return (
      <>
        <S.ButtonWrapper
          onClick={!downloadLoading && handleClick}
          w={w}
          m={m}
          disabled={downloadLoading}
          // target="_blank"
        >
          <S.Top>
            {loading ? <Loading /> : <T.H40Caps color="pink">{stat}</T.H40Caps>}
            <T.H20Caps
              color="midnight"
              style={{ fontWeight: 900, textAlign: "center" }}
            >
              {section}
            </T.H20Caps>
          </S.Top>
          <S.Bottom>
            <T.Body16B
              color="white"
              style={{
                margin: "0px",
                textTransform: "capitalize",
              }}
            >
              {sectionPromo}
            </T.Body16B>
            <Icon icon="export" color="white" width="25px" height="25px" />
          </S.Bottom>
        </S.ButtonWrapper>

        {error && <S.Error>{error}</S.Error>}

        <S.Downloading>
          {loading || downloadLoading
            ? "Searching & downloading data: ..."
            : !!size && "Searching & downloading data: ..."}
          {!!size && fileSizeFormatter(size)}
        </S.Downloading>
      </>
    );
  }

  return (
    <S.Wrapper to={sectionLink} w={w} m={m}>
      <S.Top>
        {loading ? <Loading /> : <T.H40Caps color="pink">{stat}</T.H40Caps>}
        <T.H20Caps color="midnight" style={{ fontWeight: 900 }}>
          {section}
        </T.H20Caps>
      </S.Top>
      <S.Bottom>
        <T.Body16B
          color="white"
          style={{ margin: "0px", textTransform: "capitalize" }}
        >
          {sectionPromo}
        </T.Body16B>
        <Icon icon="arrowRight" color="white" width="25px" height="25px" />
      </S.Bottom>
    </S.Wrapper>
  );
};

export default ProgrammeSection;
