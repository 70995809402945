import { programmes } from "../../../../api-calls";

import {
  GET_PROGRAMME_INVITE_DETAILS_LOADING,
  GET_PROGRAMME_INVITE_DETAILS_SUCCESS,
  GET_PROGRAMME_INVITE_DETAILS_FAIL,
} from "../../../../redux/types";

export const getProgrammeById = (programmeId) => async (dispatch) => {
  dispatch({ type: GET_PROGRAMME_INVITE_DETAILS_LOADING });
  try {
    const { data, error } = await programmes.getProgramme({
      programmeId,
      type: "profile",
    });

    if (data) {
      dispatch({ type: GET_PROGRAMME_INVITE_DETAILS_SUCCESS, payload: data });
    } else {
      dispatch({
        type: GET_PROGRAMME_INVITE_DETAILS_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_PROGRAMME_INVITE_DETAILS_FAIL,
      payload: error,
    });
  }
};
