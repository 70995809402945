import { styled } from "@material-ui/core/styles";

const Wrapper = styled("div")(({ theme, type, deactivated }) => ({
  cursor: "pointer",
  border: "none",
  background: "none",
  display: "flex",
  flexDirection: type === "Re-join" && "column",
  justifyContent: "flexStart",
  alignItems: "center",
  outline: "none",
  opacity: deactivated && 0.5,

  "& p": {
    fontSize: type === "Re-join" && "14px",
    lineHeight: type === "Re-join" && "15px",
  },

  [theme.breakpoints.tablet]: {
    flexDirection: "row",

    "& p": {
      fontSize: "14px",
      lineHeight: "15px",
    },
  },
}));

export default Wrapper;
