import React from "react";

const Clock = ({ width, height, color, ...props }) => (
  <svg
    width={width}
    height={height}
    {...props}
    viewBox="0 0 17 17"
    fill={color || "none"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 0C3.81318 0 0 3.81318 0 8.5C0 13.1868 3.81318 17 8.5 17C13.1868 17 17 13.1868 17 8.5C17 3.81318 13.1868 0 8.5 0ZM8.5 15.9375C4.39889 15.9375 1.0625 12.6011 1.0625 8.5C1.0625 4.39889 4.39889 1.0625 8.5 1.0625C12.6011 1.0625 15.9375 4.39889 15.9375 8.5C15.9375 12.6011 12.6011 15.9375 8.5 15.9375Z"
      fill={color}
    />
    <path
      d="M9.03125 3.18799H7.96875V8.72045L11.3119 12.0636L12.0631 11.3124L9.03125 8.2805V3.18799Z"
      fill={color}
    />
  </svg>
);
export default Clock;
