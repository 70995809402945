import { styled } from "@material-ui/core/styles";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  minWidth: "260px",
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  margin: "0",
  alignItems: "center",
}));

export const Details = styled("div")(({ theme }) => ({
  [theme.breakpoints.mobileXL]: {
    paddingTop: "0",
  },

  [theme.breakpoints.laptop]: {
    paddingLeft: theme.spacing(3),
  },
}));

export const GeneralWrapper = styled("div")(({ theme, m }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minWidth: "260px",
  margin: m || "0",
  textAlign: "center",

  [theme.breakpoints.mobileS]: {
    paddingBottom: theme.spacing(6),
  },

  [theme.breakpoints.mobileXL]: {
    flexDirection: "row",
    textAlign: "left",
    justifyContent: "flex-start",
  },
}));
