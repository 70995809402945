import styled from "@material-ui/core/styles/styled";
import TextField from "@material-ui/core/TextField";
import * as T from "../../Typograpy";

export const Piker = styled(TextField)(({ theme, selectedDate, focus }) => ({
  background: theme.colors.white,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  "& input": {
    height: "48px",
    width: "100%",
    // margin: "-10px",
    margin: "0",
    padding: 0,
    color: focus || selectedDate ? theme.colors.gray3 : "transparent",
  },
}));

export const Label = styled(T.Body14R)(({ selectedDate, focus }) => ({
  width: 200,
  paddingTop: "14px",
  height: "100%",
  display: selectedDate || focus ? "none" : "block",
  margin: "auto 0",
}));
