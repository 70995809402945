import Grid from "@material-ui/core/Grid";
import styled from "@material-ui/styles/styled";
import Typograpy from "@material-ui/core/Typography";

export const ButtonErrorContainer = styled(Grid)(() => ({
  width: "100%",
  margin: "40px 0 30px",
  order: 2,
}));

export const Error = styled(Typograpy)(({ theme }) => ({
  width: "100%",
  color: theme.colors.pink,
  fontSize: "0.875rem",
  textAlign: "center",
  marginBottom: theme.spacing(1),
}));

export const Wrapper = styled("section")(() => ({
  position: "relative",
  flex: "1",
  display: "flex",
  flexDirection: "column",
}));

export const Body = styled("div")(({ theme }) => ({
  margin: "0 auto",
  marginTop: theme.spacing(9),
  maxWidth: "450px",
}));
