import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { device, subHeadlineComponents } from "./data";

import { Row, Col } from "../../../../../components/Grid";
import Table from "../../../../../components/Table";
import { emailCol } from "../../../../../components/Table/config/reusableColumns";
import RemoveRejoin from "../../../../../components/RemoveRejoin";
import Notification from "../../../../../components/Notification";
import { InputField } from "../../../../../components/Inputs";
import * as T from "../../../../../components/Typograpy";
import Add from "../../../../../components/Add";
import WarningMessage from "../../../../../components/WarningMessage";

import validate from "../../../../../validation/schemas/extProgManagers";

import { EXT_PROG_MANAGER_LIMIT } from "../../../../../constants/addUserLimits";

import dateFormatter from "../../../../../utils/helpers/dateFormatter";
import * as actions from "./actions";

import theme from "../../../../../utils/theme";

const ExtProgManagerSection = ({
  managers,
  isMobile,
  addNewManager,
  getProgrammeManagers,
  getLoading,
  addLoading,
  deleteProgrammeManagers,
}) => {
  const [newEmail, setNewEmail] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [userToRemove, setUserToRemove] = useState({});
  const [errors, setErrors] = useState({});
  const { id: programmeId } = useParams();

  const SubHeadline = subHeadlineComponents[device(isMobile)];

  const remainingSpaces = () => EXT_PROG_MANAGER_LIMIT - managers.length;
  const disableAdd = () => managers.length >= EXT_PROG_MANAGER_LIMIT;

  const handleEmail = (event) => {
    setNewEmail(event.target.value);
  };

  const removeManager = (rowData) => {
    setUserToRemove(rowData);
    setShowWarning(true);
  };

  const confirmRemove = async () => {
    try {
      await deleteProgrammeManagers({
        programmeId,
        managerId: userToRemove.id,
      });
      setNotificationMsg("Manager successfully removed");
      setShowNotification(true);
      setUserToRemove({});
      setErrors({});
    } catch (err) {
      setErrors({ removeError: err.message });
    }
  };

  const addManager = async () => {
    const cleanEmail = newEmail.toLowerCase().trim();
    try {
      validate({ email: cleanEmail });

      await addNewManager({
        email: cleanEmail,
        programmeId,
      });
      // api call to set up invite link and email to the user

      setNotificationMsg("WOOHOO! YOU’VE SENT THEM A SIGN-UP LINK BY EMAIL");
      setShowNotification(true);
      setNewEmail("");
      setErrors({});
    } catch (err) {
      if (err.name === "ValidationError") {
        setErrors(err.inner);
      } else {
        setErrors({ addError: err.message });
      }
    }
  };

  const columns = [
    {
      title: "Name",
      render: ({ firstName, lastName }) => `${firstName} ${lastName}`,
    },
    emailCol,
    {
      title: "Date Last Issued Time Credits",
      field: "issuedCredits",
      render: (rowData) => <span>{dateFormatter(rowData.issuedCredits)}</span>,
      cellStyle: { width: "50%", fontSize: "0.875rem", fontWeight: "normal" },
    },
    {
      title: "",
      field: "removeRejoin",
      disableClick: true,
      render: (rowData) => (
        <RemoveRejoin
          type="Remove"
          handleClick={() => removeManager(rowData)}
          showText={!isMobile}
        />
      ),
      cellStyle: {
        width: "20%",
        padding: 0,
      },
    },
  ];

  useEffect(() => {
    getProgrammeManagers(programmeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programmeId]);

  return (
    <>
      <Row mt={isMobile ? 8 : 9} mb={1}>
        <Col w={[4, 8, 8]}>
          <SubHeadline color="midnight">
            External Programme Managers
          </SubHeadline>
        </Col>
      </Row>
      <Row mb={5}>
        <Col w={[4, 10, 10]}>
          <T.Body16R color="gray3">
            You have added{" "}
            <b style={{ color: theme.colors.midnight }}>{managers.length} </b>
            external programme managers. You are allowed to add{" "}
            <b style={{ color: theme.colors.midnight }}>
              {remainingSpaces()}
            </b>{" "}
            more.
          </T.Body16R>
        </Col>
      </Row>
      <Table
        isLoading={getLoading}
        data={managers}
        columns={columns}
        hideSearch
      />
      {errors.removeError && (
        <Row mt={2}>
          <Col w={[4, 8, 8]}>
            <T.Body16R color="pink">{errors.removeError}</T.Body16R>
          </Col>
        </Row>
      )}
      <Row mb={3} mt={8} style={{ opacity: disableAdd() ? "0.5" : "1" }}>
        <Col w={[4, 8, 8]}>
          <SubHeadline color="blue">
            Add New External Programme Manager
          </SubHeadline>
        </Col>
      </Row>
      <Row ai="flex-end" style={{ opacity: disableAdd() ? "0.5" : "1" }}>
        <Col w={[4, 4, 4]}>
          <InputField
            label="Email Address"
            type="email"
            placeholder="Type email here..."
            setValue={handleEmail}
            value={newEmail}
            error={errors.email}
            disabled={disableAdd()}
          />
        </Col>
        <Col
          w={[4, 4, 4]}
          style={{
            display: "flex",
            alignItems: "center",
            height: "50px",
            marginBottom: errors.email && "31px",
          }}
        >
          <Add
            handleClick={addManager}
            disabled={disableAdd()}
            isLoading={addLoading}
          />
        </Col>
      </Row>
      {errors.addError && (
        <Row mt={2}>
          <Col w={[4, 8, 8]}>
            <T.Body16R color="pink">{errors.addError}</T.Body16R>
          </Col>
        </Row>
      )}
      <Notification
        open={showNotification}
        setOpen={setShowNotification}
        message={notificationMsg}
        duration={2500}
      />
      <WarningMessage
        open={showWarning}
        setOpen={setShowWarning}
        title="Are you sure you want to remove this manager?"
        nameOrEmail={`${userToRemove.firstName} ${userToRemove.lastName}`}
        text="will be deleted as programme manager."
        handleClick={() => confirmRemove()}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  managers: state.extProgManagers.managers,
  getLoading: state.extProgManagers.getLoading,
  addLoading: state.extProgManagers.addLoading,

  isMobile: state.ui.isMobile,
});

const mapActionsToProps = {
  addNewManager: actions.addNewManager,
  getProgrammeManagers: actions.getProgrammeManagers,
  deleteProgrammeManagers: actions.deleteProgrammeManagers,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(ExtProgManagerSection);
