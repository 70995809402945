import {
  GET_STAFF_SINGLE_SPEND_PARTNER_LOADING,
  GET_STAFF_SINGLE_SPEND_PARTNER_SUCCESS,
  GET_STAFF_SINGLE_SPEND_PARTNER_FAIL,
} from "../../../../redux/types";

const initState = {
  id: "",
  spendPartner: {
    name: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  },
  loading: false,
  error: null,
};

const staffSingleSpendVenueReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_STAFF_SINGLE_SPEND_PARTNER_LOADING:
      return {
        ...initState,
        loading: true,
      };
    case GET_STAFF_SINGLE_SPEND_PARTNER_SUCCESS:
      return {
        ...state,
        spendPartner: payload,
        loading: false,
      };
    case GET_STAFF_SINGLE_SPEND_PARTNER_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default staffSingleSpendVenueReducer;
