const calculatePriceDetails = ({
  vat,
  credits,
  price,
  // minimumTc,
  // maximumTc,
}) => {
  const totalVat = price
    ? Math.round(Number(price) * Number(vat) * 1000) / 1000
    : 0;
  const totalVatCost = totalVat * credits;

  const unitPriceIncludingVat = price
    ? Math.round((Number(price) + totalVat) * 1000) / 1000
    : 0;

  const subTotalPrice = price * credits;
  const totalPrice = unitPriceIncludingVat * credits;

  // Format for EG view
  // 18 => £18 | 18.6 => £18.60 | 18.675 => £18.68
  const totalPriceFormatted2 =
    totalPrice % 1 === 0 ? `£${totalPrice}` : `£${totalPrice.toFixed(2)}`;

  return {
    totalVat,
    unitPriceIncludingVat,
    subTotalPrice,
    totalPrice,
    totalVatCost,
    totalVatFormatted: `£${totalVat.toFixed(2)}`,
    unitPriceIncludingVatFormatted: `£${unitPriceIncludingVat.toFixed(2)}`,
    subTotalPriceFormatted: `£${subTotalPrice.toFixed(2)}`,
    totalPriceFormatted: `£${totalPrice.toFixed(2)}`,
    totalVatCostFormatted: `£${totalVatCost.toFixed(2)}`,
    totalPriceFormatted2,
  };

  // const minPrice = minimumTc
  //   ? Math.round(Number(minimumTc) * totalAmount * 1000) / 1000
  //   : 0;

  // const maxPrice = maximumTc
  //   ? Math.round(Number(maximumTc) * totalAmount * 1000) / 1000
  // : 0;
};

export default calculatePriceDetails;
