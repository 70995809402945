import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";

import { useHistory, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";

import { H60Caps } from "../../components/Typograpy";
import InputField from "../../components/Inputs/InputField";
import { Regular } from "../../components/Button";
import Checkbox from "../../components/Inputs/Checkbox";
import { ReCaptcha } from "../../components/TextSections";
import WelcomeSection from "../Login/WelcomeSection";
import Dropdown from "../../components/Inputs/Dropdown";
import { addGroupSignup as addGroupSignupAction } from "./actions";
import cleanWhitespace from "../../utils/helpers/cleanWhitespace";
import t from "../../utils/helpers/translator";

import {
  regionsDropDownEnglish,
  regionsDropDownWelsh,
  regionsValuesMapWelsh,
  authoritiesEnglishDropDown,
  authoritiesWelshDropDown,
  authoritiesValuesMapWelsh,
  focusDropDownEnglish,
  focusDropDownWelsh,
  focusValuesMapWelsh,
  organisationTypesListEnglishDropDown,
  organisationTypesListWelshDropDown,
  organisationTypesValuesMapWelsh,
} from "../../constants/dropdDownData";

import {
  Container,
  GridContentAddGroup,
  SubmitBox,
  TermsBox,
  OrgBox,
  Title,
  AuthorityBox,
  FocusBox,
  OrgTypeBox,
  RegionBox,
  WelcomeContainer,
  OrgTypeInputContainer,
  ErrorWithLink,
  ExtLink,
} from "../Signup/customSignupStyle";
import validate from "../../validation/schemas/addGroupSignup";

import { roles, externalLinks, navRoutes } from "../../constants";

const { EARN_GROUP } = roles;

const useQuery = () => new URLSearchParams(useLocation().search);

const AddGroupSignup = ({
  handleSignup,
  signupLoading,
  signupError,
  credentialId,
  language,
}) => {
  const [name, setName] = useState("");
  const [organisationType, setOrganisationType] = useState("");
  const [organisationTypeOther, setOrganisationTypeOther] = useState("");
  const [orgTypeOtherVisible, setOrgTypeOtherVisible] = useState(false);
  const [focusFields, setFocusFields] = useState([]);
  const [regionCountry, setRegionCountry] = useState("");
  const [region, setRegion] = useState("");
  const [localAuthority, setLocalAuthority] = useState("");
  const [agreedOnTerms, setAgreedOnTerms] = useState(false);
  const [submitAttemp, setSubmitAttemp] = useState(false);

  const history = useHistory();
  const query = useQuery();
  const programmeToken = query.get("programme");

  const searchParams = new URLSearchParams();
  if (programmeToken) searchParams.append("programme", programmeToken);

  const initErrors = {
    name: "",
    organisationType: "",
    focusFields: "",
    regionCountry: "",
    region: "",
    localAuthority: "",
    agreedOnTerms: "",
  };

  useEffect(() => {
    if (!credentialId) {
      history.push(navRoutes.GENERAL.ADD_GROUP_LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentialId]);

  useEffect(() => {
    if (organisationType === "Other (Please Specify)") {
      setOrgTypeOtherVisible(true);
    } else {
      setOrgTypeOtherVisible(false);
      setOrganisationTypeOther("");
    }
  }, [organisationType]);

  const [errors, setErrors] = useState(initErrors);

  const partialValidate = (fieldName, value) => {
    try {
      if (submitAttemp) {
        if (fieldName === "phoneNumber") {
          validate(
            {
              [fieldName]: value?.replace(/ /g, ""),
            },
            true,
          );
        } else {
          validate(
            {
              [fieldName]: value,
            },
            true,
          );
        }
      }
    } catch (err) {
      if (err.name === "ValidationError") {
        setErrors((_errors) => ({
          ..._errors,
          [fieldName]: err.inner[fieldName],
        }));
      }
    }
  };

  const onFocusFieldsChange = ({ selected }) => {
    const value = selected ? selected.map((e) => e.value) : [];
    if (value.length <= 3) {
      partialValidate("focusFields", value);
      setFocusFields(value);
    }
  };

  const onRegionChange = ({ selected }) => {
    partialValidate("region", selected.value);
    setRegion(selected.value);
    setRegionCountry(selected.countryValue || selected.country);
  };

  const onOrganisationTypeChange = ({ selected }) => {
    partialValidate("organisationType", selected.value);
    setOrganisationType(selected.value);
  };

  const onLocalAuthorityChange = ({ selected }) => {
    partialValidate("localAuthority", selected.value);
    setLocalAuthority(selected.value);
  };

  const onChange = (e) => {
    let { name: _name, value } = e.target;

    switch (_name) {
      case "agreedOnTerms":
        value = e.target.checked;
        break;

      case "organisationTypeOther":
        _name = "organisationType";
        break;

      default:
        break;
    }

    partialValidate(_name, value);
    switch (e.target.name) {
      case "name":
        setName(e.target.value);
        break;
      case "agreedOnTerms":
        setAgreedOnTerms(e.target.checked);
        break;
      case "organisationTypeOther":
        setOrganisationTypeOther(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async () => {
    try {
      setSubmitAttemp(true);
      const objToSubmit = {
        role: EARN_GROUP,
        name: cleanWhitespace(name),
        organisationType: organisationTypeOther || organisationType,
        focusFields,
        regionCountry,
        region,
        localAuthority,
        agreedOnTerms,
      };
      await validate(objToSubmit);

      handleSignup(
        {
          ...objToSubmit,
          programmeToken,
          language,
        },
        history,
      );
    } catch (err) {
      if (err.name === "ValidationError") {
        setErrors(err.inner);
      }
    }
  };

  useEffect(() => {
    setErrors(initErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    // Analytics track earn groups who arrive at sign up page
    if (process.env.NODE_ENV === "production") {
      ReactGA.event({
        category: "Earn Group",
        action: "Arrived at sign up page",
      });
    }
  }, []);

  const selectedRegion =
    (region && {
      label: language === "english" ? region : regionsValuesMapWelsh[region],
      value: region,
    }) ||
    undefined;

  const selectedOrgType = organisationType && {
    value: organisationType,
    label:
      language === "english"
        ? organisationType
        : organisationTypesValuesMapWelsh[organisationType],
  };
  const selectedLocalAuthority = localAuthority && {
    value: localAuthority,
    label:
      language === "english"
        ? localAuthority
        : authoritiesValuesMapWelsh[localAuthority],
  };

  const termsOfUseText = t("agreeToTerms", language).split(" | ");

  return (
    <Grid container md={12} xl={12} xs={12}>
      <Container item container md={4}>
        <Title>
          <H60Caps color="primary">{t("signUp", language)}</H60Caps>
        </Title>
        <GridContentAddGroup>
          {/* orgnaziation name */}
          <OrgBox>
            <InputField
              placeholder={`${t("typeYourOrg", language)}...`}
              label={t("nameOfOrg", language)}
              name="name"
              value={name}
              setValue={onChange}
              error={errors.name}
              styles={{ width: "252px" }}
            />
          </OrgBox>

          {/* origanization Type */}
          <OrgTypeBox>
            <Dropdown
              name="organisationType"
              options={
                language === "english"
                  ? organisationTypesListEnglishDropDown
                  : organisationTypesListWelshDropDown
              }
              value={selectedOrgType}
              setValue={onOrganisationTypeChange}
              label={t("typeOfOrg", language)}
              placeholder={t("typeOfOrg", language)}
              max={3}
              error={!orgTypeOtherVisible && errors.organisationType}
            />

            {orgTypeOtherVisible && (
              <OrgTypeInputContainer>
                <InputField
                  placeholder={`${t("typeOrgTypeHere", language)}...`}
                  name="organisationTypeOther"
                  size="large"
                  autoFocus
                  value={organisationTypeOther}
                  setValue={onChange}
                  error={errors.organisationType}
                />
              </OrgTypeInputContainer>
            )}
          </OrgTypeBox>

          {/* focus on */}
          <FocusBox>
            <Dropdown
              name="focus on"
              options={
                language === "english"
                  ? focusDropDownEnglish
                  : focusDropDownWelsh
              }
              value={focusFields?.map((e) => ({
                value: e,
                label: language === "english" ? e : focusValuesMapWelsh[e],
              }))}
              setValue={onFocusFieldsChange}
              label={t("whatFocus", language)}
              placeholder={`${t("selectUpToThree", language)}...`}
              error={errors.focusFields}
              isMulti
              allowSelectAll={false}
              fixedHeight={false}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              max={3}
            />
          </FocusBox>

          {/* Region */}
          <RegionBox>
            <Dropdown
              name="region"
              options={
                language === "english"
                  ? regionsDropDownEnglish
                  : regionsDropDownWelsh
              }
              value={selectedRegion}
              setValue={onRegionChange}
              label={t("region", language)}
              placeholder={`${t("selectRegion", language)}...`}
              error={errors.region}
            />
          </RegionBox>

          {/* authority */}
          <AuthorityBox>
            <Dropdown
              name="authority"
              options={
                language === "english"
                  ? authoritiesEnglishDropDown
                  : authoritiesWelshDropDown
              }
              value={selectedLocalAuthority}
              setValue={onLocalAuthorityChange}
              label={t("localAuthority", language)}
              placeholder={`${t("selectLocalAuthority", language)}...`}
              error={errors.localAuthority}
            />
          </AuthorityBox>

          {/* check Terms */}
          <TermsBox>
            <Checkbox
              size="small"
              value={agreedOnTerms}
              setValue={onChange}
              name="agreedOnTerms"
              label={
                <>
                  {termsOfUseText[0]}{" "}
                  <ExtLink
                    href={externalLinks.EARN_TERMS}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {termsOfUseText[1]}
                  </ExtLink>
                  {termsOfUseText[2]}{" "}
                  <ExtLink
                    href={externalLinks.MEMBER_PRIVACY}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {termsOfUseText[3]}
                  </ExtLink>
                  .
                </>
              }
              error={errors.agreedOnTerms}
            />
          </TermsBox>

          {/* submit btn */}
          <SubmitBox>
            <Regular
              bgColor="blue"
              handleClick={handleSubmit}
              ml="0"
              mb={20}
              loading={signupLoading}
              disabled={signupError.message}
            >
              {t("signUp", language)}
            </Regular>
            <ReCaptcha />
          </SubmitBox>
          {signupError && Object.keys(signupError).length > 0 && (
            <SubmitBox>
              <ErrorWithLink>{t(signupError.message, language)}</ErrorWithLink>
            </SubmitBox>
          )}
        </GridContentAddGroup>
      </Container>
      <WelcomeContainer item container justify="center" md={5}>
        <WelcomeSection />
      </WelcomeContainer>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  signupLoading: state.auth.signupLoading,
  signupError: state.auth.signupError,
  language: state.auth.language,
  credentialId: state.auth.credentialId,
});

const mapActionToProps = {
  handleSignup: addGroupSignupAction,
};

export default connect(mapStateToProps, mapActionToProps)(AddGroupSignup);
