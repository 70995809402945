import React from "react";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import * as S from "./style";
import * as T from "../Typograpy";
import Icon from "../Icon";
import translate from "../../utils/helpers/translator";

const ViewMoreLink = ({
  path,
  showIcon,
  showText,
  text,
  padding,
  tableMobile,
  lang,
}) => {
  const theme = useTheme();
  return (
    <S.ViewMore to={path} padding={padding} tableMobile={tableMobile}>
      {showText && (
        <T.H16 color="midnight" style={{ margin: "0" }}>
          {text || translate("viewMore", lang)}
        </T.H16>
      )}
      {showIcon && (
        <Icon
          icon="arrowLink"
          width="15px"
          height="15px"
          color={theme.colors.blue}
          margin="0 0 0 10px"
        />
      )}
    </S.ViewMore>
  );
};

const mapStateToProps = (state) => ({
  lang: state.auth.language,
});

export default connect(mapStateToProps)(ViewMoreLink);
