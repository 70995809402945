import React, { useState } from "react";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import Icon from "../Icon";
import Popper from "../Popper";
import * as S from "./style";

const PopPassword = ({ content, m, title, children }) => {
  const [popperOpen, togglePopper] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const _title = title || "Password must contain:";
  const _content = content || [
    "- a minimum of 8 characters",
    "- one capital letter",
    "- one lowercase letter",
    "- one number",
    "- one non alphabetical or numeric character",
  ];

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    togglePopper(!popperOpen);
  };

  const handleClickAway = () => {
    togglePopper(false);
  };

  return (
    <S.PopContainer>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <S.PopBtn type="button" onClick={handleClick} m={m}>
            <Icon
              icon="questionMark"
              width="24px"
              height="24px"
              color="blue"
              style={{ cursor: "pointer" }}
            />
          </S.PopBtn>
          <Popper
            placement="bottom-end"
            open={popperOpen}
            content={_content}
            title={_title}
            type="small"
            icon="info"
            anchorEl={anchorEl}
            popperProps={{
              placement: "bottom-end",
              position: "absolute",
              top: "76px",
              left: "-130px",
              width: "300px",
              alignIcon: "flex-start",
              iconWidth: "54px",
            }}
            password
          >
            {children}
          </Popper>
        </div>
      </ClickAwayListener>
    </S.PopContainer>
  );
};

export default PopPassword;
