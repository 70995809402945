import Alert from "../components/Alert";
import history from "../history";
import { GENERAL } from "../constants/navRoutes";

// this doesn't work
// it change the browser route without update the rendered componenet
const handleRedirect = (status) => {
  switch (status) {
    case 404:
      return history.push(GENERAL.NOT_FOUND);

    case 401:
    case 403:
      return history.push(GENERAL.UNAUTHORIZED);

    default:
      return history.push(GENERAL.SERVER_ERROR);
  }
};

const handleShowModal = ({ error, message }) =>
  Alert.fire({
    title: error,
    description: message,
  });

const makeError = (err, options = {}) => {
  const { redirectToRelatedPage = false, showModal = false } = options;
  let error = err.message || "Something went wrong";
  let message = error;
  let httpStatusCode = 500;
  let data;
  try {
    const res = err.response.data;
    error = res.error;
    message = res.message;
    httpStatusCode = res.httpStatusCode;
    data = res.data;
  } catch (e) {
    // take default values
  }

  if (redirectToRelatedPage) handleRedirect(httpStatusCode);
  if (showModal) handleShowModal({ error, message });
  // eslint-disable-next-line no-console
  if (process.env.NODE_ENV !== "production") console.error(error);

  return {
    error,
    message,
    httpStatusCode,
    data,
  };
};

export default makeError;
