import React from "react";
import PropTypes from "prop-types";

import General from "./General";
import SplitScreen from "./SplitScreen";
import Onboarding from "./Onboarding";
import Center from "./Center";

const Layout = ({ layout, ...props }) => {
  switch (layout) {
    case "onboarding":
      return <Onboarding {...props} />;
    case "splitScreen":
      return <SplitScreen layoutColor={props.color} {...props} />;
    case "center":
      return <Center layoutColor={props.color} {...props} />;
    default:
      return <General {...props} />;
  }
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
