import Grid from "@material-ui/core/Grid";
import styled from "@material-ui/styles/styled";
import Typograpy from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

export const Container = styled(Grid)(() => ({
  maxWidth: "100%",
  flexBasis: "100%",
  display: "flex",
  justifyContent: "flex-start",
  "@media (min-width: 1155px)": {
    maxWidth: "60%",
    flexBasis: "60%",
    marginLeft: "-2%",
  },
}));

export const GridContent = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  marginLeft: 0,
  justifyContent: "center",
  flexWrap: "wrap",

  [theme.breakpoints.tablet]: {
    marginLeft: "-15px",
  },
}));

export const GridContentAddGroup = styled("div")(({ theme }) => ({
  width: "100%",
  marginLeft: 0,
  [theme.breakpoints.tablet]: {
    marginLeft: "-15px",
  },
}));

// SelecteBoxs
export const RegionBox = styled(Grid)(({ theme }) => ({
  marginTop: 20,
  marginRight: 0,
  width: "100%",
  display: "flex",
  order: 9,
  justifyContent: "center",
  "& div": {
    // width: "100%",
    maxWidth: "315px",

    "& div": {
      "& .css-2pdv6t-control": {
        height: 60,
      },
      "& .css-1m5e9s5-control": {
        height: 60,
      },
    },
  },
  [theme.breakpoints.tablet]: {
    marginRight: 30,
    width: "45%",
    justifyContent: "flex-start",
    order: 7,
  },
}));

export const AuthorityBox = styled(Grid)(({ theme }) => ({
  marginTop: 20,
  marginRight: 0,

  width: "100%",
  display: "flex",
  order: 10,
  justifyContent: "center",
  "& div": {
    // width: "auto",
    maxWidth: "315px",

    "& div": {
      "& .css-2pdv6t-control": {
        height: 60,
      },
      "& .css-1m5e9s5-control": {
        height: 60,
      },
    },
  },
  [theme.breakpoints.tablet]: {
    marginRight: 30,
    width: "45%",
    justifyContent: "flex-start",
    order: 9,
  },
}));

export const FocusBox = styled(Grid)(({ theme }) => ({
  marginTop: 20,
  marginRight: 0,
  width: "100%",
  display: "flex",
  order: 8,
  justifyContent: "center",
  "& div": {
    // width: "auto",
    maxWidth: "315px",

    "& div": {
      "& .css-2pdv6t-control": {
        minHeight: 60,
      },
      "& .css-1m5e9s5-control": {
        minHeight: 60,
      },
    },
  },
  [theme.breakpoints.tablet]: {
    marginRight: 30,
    width: "45%",
    justifyContent: "flex-start",
    order: 5,
  },
}));

export const OrgTypeBox = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  "@media (min-width:950px)": {
    alignItems: "flex-start",
  },
  marginTop: 20,
  marginRight: 0,

  width: "100%",
  order: 7,
  justifyContent: "center",
  "& div": {
    maxWidth: "315px",

    "& div": {
      "& .css-2pdv6t-control": {
        height: 60,
      },
      "& .css-1m5e9s5-control": {
        height: 60,
      },
    },
  },
  [theme.breakpoints.tablet]: {
    marginRight: 30,
    width: "45%",
    justifyContent: "flex-start",
    order: 3,
  },
}));

export const PhoneBox = styled(Grid)(({ theme }) => ({
  marginTop: 20,
  marginRight: 0,

  width: "100%",
  display: "flex",
  justifyContent: "center",
  order: 11,
  "& div": {
    // width: "auto",
    maxWidth: "315px",
  },
  [theme.breakpoints.tablet]: {
    width: "45%",
    order: 8,
    marginRight: 30,
  },
}));

export const TermsBox = styled(Grid)(({ theme }) => ({
  marginTop: 40,
  marginRight: 0,
  justifyContent: "center",
  display: "flex",
  order: 12,
  [theme.breakpoints.tablet]: {
    marginRight: 30,
    width: "45%",
    order: 11,
  },
}));

export const FirstNameBox = styled("div")(({ theme }) => ({
  marginTop: 20,
  marginRight: 0,

  width: "100%",
  display: "flex",
  justifyContent: "center",
  order: 2,
  "& div": {
    // width: "auto",
    maxWidth: "315px",
  },
  [theme.breakpoints.tablet]: {
    marginRight: 30,
    width: "45%",
    order: 2,
  },
}));

export const LastNameBox = styled("div")(({ theme }) => ({
  marginTop: 20,
  marginRight: 0,

  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  order: 3,
  "& div": {
    // width: "auto",
    maxWidth: "315px",
  },
  [theme.breakpoints.tablet]: {
    marginRight: 30,
    width: "45%",
    order: 4,
  },
}));

export const EmailBox = styled("div")(({ theme }) => ({
  marginTop: 20,
  marginRight: 0,

  width: "100%",
  display: "flex",
  justifyContent: "center",
  order: 4,
  "& div": {
    // width: "auto",
    maxWidth: "315px",
  },
  [theme.breakpoints.tablet]: {
    marginRight: 30,
    width: "45%",
    order: 6,
  },
}));

export const PasswordBox = styled(Grid)(({ theme }) => ({
  marginTop: 20,
  marginRight: 0,

  width: "100%",
  display: "flex",
  justifyContent: "center",
  order: 5,
  "& div": {
    // width: "auto",
    maxWidth: "315px",
  },
  [theme.breakpoints.tablet]: {
    marginRight: 30,
    width: "45%",
    order: 10,
  },
}));

export const SubmitBox = styled("div")(({ theme }) => ({
  marginTop: 40,
  marginRight: 0,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  order: 13,
  alignItems: "center",
  justifyContent: "center",
  "& button": {
    width: "100%",
    maxWidth: "315px",
  },
  "& p": {
    width: "100%",
    maxWidth: "315px",
  },
  [theme.breakpoints.tablet]: {
    marginRight: 30,
    width: "45%",
    order: 12,
    justifyContent: "center",
  },
}));

export const OrgBox = styled(Grid)(({ theme }) => ({
  marginTop: 20,
  marginRight: 0,

  width: "100%",
  display: "flex",
  justifyContent: "center",
  order: 6,
  "& div": {
    maxWidth: "315px",
  },
  [theme.breakpoints.tablet]: {
    justifyContent: "flex-start",
    marginRight: 30,
    width: "45%",
    order: 1,
  },
}));

export const GridItemLinks = styled(Grid)(({ theme, center }) => ({
  width: "100%",
  margin: "0 auto",
  textAlign: "center",
  order: 1,
  paddingTop: 0,
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.tablet]: {
    justifyContent: center ? "center" : "flex-start",
    order: 13,
  },
}));

export const TextLinks = styled(Typograpy)(({ theme, stopMargin }) => ({
  color: theme.colors.gray3,
  textAlign: "left",
  margin: "0 auto",
  width: "100%",
  display: "flex",
  justifyContent: "center",

  marginBottom: theme.spacing(3),

  ...theme.typography.placeholder,

  fontSize: "16px",

  [theme.breakpoints.tablet]: {
    marginTop: stopMargin ? 0 : "-30px",
    justifyContent: "flex-start",
    marginBottom: stopMargin ? 0 : theme.spacing(5),
  },
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.colors.blue,
  fontSize: "16px",
  fontWeight: "bold",
  display: "inline",
  textAlign: "left",
  marginLeft: "5px",
  minWidth: "20%",
}));

export const Title = styled("div")(({ theme }) => ({
  width: "100%",
  marginBottom: 0,
  marginTop: 40,
  textAlign: "center",

  "& h1": {
    fontSize: "40px",
  },

  [theme.breakpoints.mobileM]: {
    "& h1": {
      fontSize: "50px !important",
    },
  },

  [theme.breakpoints.mobileL]: {
    "& h1": {
      fontSize: "60px !important",
    },
    marginTop: 40,
    marginBottom: 10,
  },

  [theme.breakpoints.tablet]: {
    margin: "40px auto",
    textAlign: "left",
  },
}));

export const WelcomeContainer = styled(Grid)({
  display: "none",
  paddingTop: "40px",
  "@media (min-width: 1155px)": {
    display: "flex",
  },
});

export const OrgTypeInputContainer = styled("div")({
  marginTop: "0.5rem",
});

export const Error = styled(Typograpy)(({ theme }) => ({
  ...theme.typography.placeholder,
  color: theme.colors.pink,
}));

export const ErrorWithLink = styled(Typograpy)(({ theme }) => ({
  ...theme.typography.placeholder,
  color: theme.colors.pink,
  display: "inline",
}));

export const ClickHere = styled(Link)(({ theme, color }) => ({
  color: theme.colors[color] || theme.colors.blue,
  fontWeight: "bold",
  display: "inline",
}));

export const ExtLink = styled("a")(({ theme }) => ({
  color: theme.colors.blue,
  fontWeight: "bold",
}));

export const GridItemCaptcha = styled(Grid)(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(3),
  order: 12,

  "& > p": {
    margin: "0 auto",
    width: "100%",
    [theme.breakpoints.mobileL]: {
      width: "80%",
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.tablet]: {
      width: "45%",
      margin: 0,
      marginTop: theme.spacing(3),
    },
  },
}));
