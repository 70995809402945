import {
  GET_ORDER_INVOICE_LOADING,
  GET_ORDER_INVOICE_SUCCESS,
  GET_ORDER_INVOICE_FAIL,
} from "../../../redux/types";

import { paidPackages } from "../../../api-calls";

export const getOrderInvoice =
  ({ orderId }) =>
  async (dispatch) => {
    dispatch({ type: GET_ORDER_INVOICE_LOADING });
    try {
      const { data, error } = await paidPackages.getOrderInvoice({ orderId });
      if (data) {
        dispatch({ type: GET_ORDER_INVOICE_SUCCESS, payload: data });
      } else {
        dispatch({ type: GET_ORDER_INVOICE_FAIL, payload: error });
      }
    } catch (error) {
      dispatch({ type: GET_ORDER_INVOICE_FAIL, payload: error });
    }
  };
