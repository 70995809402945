import { styled } from "@material-ui/core/styles";

const decideSize = (size, costVaries) => {
  switch (size) {
    case "xl":
      return {
        mobile: { font: costVaries ? "20px" : "30px", circle: "50px" },
        desktop: { font: costVaries ? "30px" : "50px", circle: "90px" },
      };
    case "small":
      return {
        mobile: { font: "14px", circle: "32px" },
        desktop: { font: "14px", circle: "32px" },
      };
    default:
      return {
        mobile: { font: costVaries ? "18px" : "24px", circle: "44px" },
        desktop: { font: "20px", circle: "44px" },
      };
  }
};

export const Wrapper = styled("div")(({ m }) => ({
  display: "flex",
  margin: m || "0",
}));

export const Number = styled("span")(
  ({ theme, size, color, description, costVaries }) => ({
    fontSize: decideSize(size, costVaries).mobile.font,
    color: theme.colors[color] || theme.colors.gray2,
    width: decideSize(size).mobile.circle,
    height: decideSize(size).mobile.circle,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: description && "5px",
    fontFamily: "Poppins",
    fontWeight: "bold",
    position: "relative",

    [theme.breakpoints.mobileL]: {
      fontSize: decideSize(size, costVaries).desktop.font,
      width: decideSize(size).desktop.circle,
      height: decideSize(size).desktop.circle,
    },
  }),
);

export const Description = styled("span")(({ theme, size }) => ({
  color: size === "small" ? theme.colors.gray2 : theme.colors.blue,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  textAlign: "left",

  [theme.breakpoints.mobileL]: {
    "& h1, h2, h3, h4, h5, h6": {
      fontSize: size === "xl" && "30px",
      lineHeight: size === "xl" && "30px !important",
    },
  },
}));
