import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout as logoutAction } from "../../../redux/actions/auth";

import * as actions from "./actions";
import Button from "../../../components/Button";
import Notification from "../../../components/Notification";

import translate from "../../../utils/helpers/translator";

const ResendLink = ({
  lang,
  resendActivateAccountToken,
  loading,
  handleLogout,
}) => {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const history = useHistory();

  const onClick = async () => {
    setIsNotificationOpen(true);
    await resendActivateAccountToken();
    setIsNotificationOpen(false);
  };

  const logOut = () => {
    handleLogout({ history });
  };

  return (
    <>
      <Button
        primary
        bgColor="blue"
        customWidth="140px"
        handleClick={onClick}
        w="50%"
        mt="30"
        loading={loading}
      >
        {translate("sendAgain", lang)}
      </Button>
      <Button outline customWidth="140px" handleClick={logOut} w="50%" mt="10">
        {translate("returnToLogIn", lang)}
      </Button>
      <Notification
        open={isNotificationOpen}
        setOpen={setIsNotificationOpen}
        message={translate("emailSent", lang)}
        duration={1500}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  userId: state.auth.id,
  lang: state.auth.language,
  token: state.activateAccount.token,
  loading: state.activateAccount.resendLoading,
});

export default connect(mapStateToProps, {
  resendActivateAccountToken: actions.resendActivateAccountToken,
  handleLogout: logoutAction,
})(ResendLink);
