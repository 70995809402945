import React from "react";

const DownArrow = ({ width, height, color, background, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 22"
    width={width}
    height={height}
    {...props}
  >
    <ellipse
      cx="11.393"
      cy="11"
      rx="11.393"
      ry="11"
      fill={background || "currentColor"}
    />
    <path
      d="M7.314 11.328l4.079 3.938 4.078-3.938M11.393 14.719V6.734"
      stroke={color || "currentColor"}
      strokeWidth="1.406"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DownArrow;
