import React from "react";

import { Row, Col } from "../../components/Grid";
import t from "../../utils/helpers/translator";

import * as T from "../../components/Typograpy";

import {
  InputField,
  Dropdown,
  Counter,
  RadioInput,
} from "../../components/Inputs";
import UploadImage from "../../components/UploadImage";

import {
  spendActivitiesDropDownEnglish,
  spendActivitiesDropDownWelsh,
  locationTypesDropdown,
  locationTypesDropdownWelsh,
  locationTypesValuesMapWelsh,
  spendActivitiesMapWelsh,
} from "../../constants/dropdDownData";

const isOnlineOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];
const isOnlineOptionsWelsh = [
  { label: t("yes", "welsh"), value: true },
  { label: t("no", "welsh"), value: false },
];

const BasicInformation = ({
  name,
  subTitle,
  activityTypes,
  isOnline,
  personTcPrice,
  costVaries,
  description,
  handleText,
  handleDropdown,
  handleCounter,
  handleCostVaries,
  errors,
  startUpload,
  setImageInfo,
  imageInfo,
  externalLink,
  language,
  // Welsh
  welshVersion,
  nameWelsh,
  subTitleWelsh,
  descriptionWelsh,
  // externalLinkWelsh,
  locationType,
}) => (
  <>
    <Row>
      <Col w={[4, 8, 8]}>
        <T.H24 color="midnight" m="0 0 15px 0">
          {t("basicInformation", language)}
        </T.H24>
        <T.Body16R color="gray3" m="0 0 30px 0">
          {t("provideActivityDetails", language)}
        </T.Body16R>
      </Col>
    </Row>
    <Row mb={5}>
      <Col w={welshVersion ? [4, 6, 6] : [4, 12, 6]}>
        <InputField
          placeholder={t("activityNamePlaceholder", language)}
          label={`${t("activityName", language)}${
            language === "welsh" ? ` (${t("english", language)})` : ""
          }`}
          name="name"
          value={name}
          setValue={handleText}
          error={errors.name}
          justify="center"
        />
      </Col>
      {welshVersion && (
        <Col w={[4, 6, 6]}>
          <InputField
            labelColor="blue"
            placeholder={t("activityNamePlaceholder", language)}
            label={`${t("activityName", language)}${
              language === "english" ? ` (${t("welsh", language)})` : ""
            }`}
            name="nameWelsh"
            value={nameWelsh}
            setValue={handleText}
            error={errors.nameWelsh}
            justify="center"
          />
        </Col>
      )}
    </Row>
    <Row mb={5}>
      <Col w={welshVersion ? [4, 6, 6] : [4, 12, 6]}>
        <InputField
          placeholder={t("activitySubtitlePlaceholder", language)}
          label={`${t("activitySubtitle", language)}${
            language === "welsh" ? ` (${t("english", language)})` : ""
          }`}
          name="subTitle"
          value={subTitle}
          setValue={handleText}
          error={errors.subTitle}
          justify="center"
          isOptional
          lang={language}
        />
      </Col>
      {welshVersion && (
        <Col w={[4, 6, 6]}>
          <InputField
            placeholder={t("activitySubtitlePlaceholder", language)}
            label={`${t("activitySubtitle", language)}${
              language === "english" ? ` (${t("welsh", language)})` : ""
            }`}
            labelColor="blue"
            name="subTitleWelsh"
            value={subTitleWelsh}
            setValue={handleText}
            error={errors.subTitleWelsh}
            justify="center"
            isOptional
            lang={language}
          />
        </Col>
      )}
    </Row>
    <Row mb={5}>
      <Col w={[4, 12, 6]}>
        <Dropdown
          placeholder={`${t("selectUpToThree", language)}...`}
          label={`${t("selectActivitiesTypes", language)}...`}
          name="activityTypes"
          options={
            language === "welsh"
              ? spendActivitiesDropDownWelsh
              : spendActivitiesDropDownEnglish
          }
          value={activityTypes?.map((e) => ({
            value: e,
            label: language === "welsh" ? spendActivitiesMapWelsh[e] : e,
          }))}
          setValue={handleDropdown}
          error={errors.activityTypes}
          isMulti
          allowSelectAll={false}
          fixedHeight={false}
          closeMenuOnSelect={false}
          blurInputOnSelect={false}
          justify="center"
          max={3}
        />
      </Col>
    </Row>
    <Row mb={5}>
      <Col w={[4, 12, 6]}>
        <RadioInput
          options={
            language === "english" ? isOnlineOptions : isOnlineOptionsWelsh
          }
          label={t("isOnlineActivity", language)}
          name="isOnline"
          value={!!isOnline}
          setValue={handleText}
        />
      </Col>
    </Row>
    <Row mb={5}>
      <Col w={[4, 12, 6]}>
        <Dropdown
          placeholder={`${t("select", language)}...`}
          label={`${t("selectLocationType", language)}...`}
          name="locationType"
          options={
            language === "welsh"
              ? locationTypesDropdownWelsh
              : locationTypesDropdown
          }
          value={
            locationType && {
              value: locationType,
              label:
                language === "english"
                  ? locationType
                  : locationTypesValuesMapWelsh[locationType],
            }
          }
          setValue={(val) => handleDropdown(val, "locationType")}
          error={errors.locationType}
        />
      </Col>
    </Row>
    <Row mb={6}>
      <Col w={[4, 12, 6]}>
        <Counter
          label={t("howManyPersonPerTC", language)}
          name="personTcPrice"
          value={personTcPrice}
          setValue={handleCounter}
          error={errors.personTcPrice}
          labelSize="reg"
          justify="flex-start"
          textLeft
          disabled={costVaries}
          costVaries={costVaries}
          handleCostVaries={handleCostVaries}
          lang={language}
        />
      </Col>
    </Row>
    <Row mb={5}>
      <Col w={welshVersion ? [4, 6, 6] : [4, 12, 6]}>
        <InputField
          placeholder={`${t("typeHere", language)}...`}
          label={`${t("aboutActivity", language)}${
            language === "welsh" ? ` (${t("english", language)})` : ""
          }`}
          name="description"
          type="textarea"
          value={description}
          setValue={handleText}
          error={errors.description}
          justify="center"
          limit={300}
          showLimit
          lang={language}
        />
      </Col>
      {welshVersion && (
        <Col w={[4, 6, 6]}>
          <InputField
            placeholder={`${t("typeHere", language)}...`}
            label={`${t("aboutActivity", language)}${
              language === "english" ? ` (${t("welsh", language)})` : ""
            }`}
            labelColor="blue"
            name="descriptionWelsh"
            type="textarea"
            value={descriptionWelsh}
            setValue={handleText}
            error={errors.descriptionWelsh}
            justify="center"
            limit={300}
            showLimit
            lang={language}
          />
        </Col>
      )}
    </Row>
    <Row mb={5}>
      <Col w={[4, 6, 6]}>
        <InputField
          placeholder={`${t("typeWebsiteUrl", language)}...`}
          label={t("activityExternalLink", language)}
          name="externalLink"
          value={externalLink}
          setValue={handleText}
          error={errors.externalLink}
          justify="center"
          isOptional
          lang={language}
        />
      </Col>
    </Row>

    <Row mb={8}>
      <Col w={[4, 12, 6]}>
        <UploadImage
          setImageInfo={setImageInfo}
          imageInfo={imageInfo}
          startUpload={startUpload}
          imageType="activity"
          maxSize={2e6}
          maxSizeText="2MB"
          secondaryText={t("activityImageHelper", language)}
          error={errors.imageKey}
          lang={language}
        />
      </Col>
    </Row>
  </>
);

export default React.memo(BasicInformation);
