import axios from "axios";
import handleError from "./handle-errors";

const STAFF_PERMISSIONS_BASE = "/staff-permissions";

const addStaffUser = async ({ email, role, options } = {}) => {
  try {
    const { data } = await axios.post(STAFF_PERMISSIONS_BASE, { email, role });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getStaffUsersAndPendingInvitations = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(STAFF_PERMISSIONS_BASE);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const deactivateStaffUser = async ({ id, options } = {}) => {
  try {
    const { data } = await axios.delete(`${STAFF_PERMISSIONS_BASE}/${id}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updateStaffUser = async ({ id, role, options } = {}) => {
  try {
    const { data } = await axios.patch(`${STAFF_PERMISSIONS_BASE}/${id}`, {
      role,
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export {
  addStaffUser,
  getStaffUsersAndPendingInvitations,
  deactivateStaffUser,
  updateStaffUser,
};
