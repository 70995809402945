import React, { useState, useEffect } from "react";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import MomentUtils from "@date-io/moment";

import moment from "moment";
import { connect } from "react-redux";
import * as T from "../../Typograpy";
import useStyles from "../style";
import Icon from "../../Icon";

import translateMomentWelsh from "../../../utils/helpers/translateMoment";
import t from "../../../utils/helpers/translator";

const SingleDatePicker = (props) => {
  const classes = useStyles(props);
  const [calendarOpen, setCalendar] = useState(false);

  const {
    value,
    setValue,
    label,
    subLabel,
    error,
    disableFuture = true,
    disablePast = false,
    isOptional,
    language,
  } = props;
  const handleDateChange = (newDate) => {
    setValue(newDate && newDate.format("YYYY-MM-DD"));
    setCalendar(false);
  };

  const toggleCalendar = () => setCalendar(!calendarOpen);
  useEffect(() => {
    if (language === "welsh") {
      moment.locale("cy", translateMomentWelsh(language)); // it is required to select default locale manually
    }

    return () => moment.locale("en");
  }, [language]);

  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale={language === "welsh" ? "cy" : "en"}
    >
      <div className={classes.datePickerWrapper}>
        {label && (
          <label
            htmlFor="TextInput"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <>
              <T.Body18B className={classes.textInputLabel}>{label}</T.Body18B>
              {subLabel && (
                <T.Body14R className={classes.textInputSubLabel}>
                  {subLabel}
                </T.Body14R>
              )}
            </>
            {isOptional && (
              <T.Body14R
                color="gray3"
                style={{
                  margin: "0 10px 5px 0",
                  alignSelf: "flex-end",
                }}
              >
                (optional)
              </T.Body14R>
            )}
          </label>
        )}
        <KeyboardDatePicker
          placeholder="DD/MM/YYYY"
          format="DD/MM/YYYY"
          onChange={(newDate) => handleDateChange(newDate)}
          className={classes.datePicker}
          open={calendarOpen}
          onClose={toggleCalendar}
          disableFuture={disableFuture}
          disablePast={disablePast}
          onAccept={(newDate) => handleDateChange(newDate)}
          helperText=""
          maskChar="_"
          value={value ? moment(value) : null}
          okLabel={t("ok", language)}
          cancelLabel={t("cancel", language)}
          DialogProps={{ className: classes.modal }}
        />
        <button
          className={classes.datePickerIconWrapper}
          type="button"
          onClick={toggleCalendar}
          error={error}
        >
          <Icon icon="calendar" width="25px" height="25px" color="midnight" />
        </button>
      </div>
      {error && (
        <T.Body14R className={classes.textFieldError}>{error}</T.Body14R>
      )}
    </MuiPickersUtilsProvider>
  );
};
const mapStateToProps = (state) => ({
  language: state.auth.language,
});
export default connect(mapStateToProps)(SingleDatePicker);
