import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Row, Col } from "../../../components/Grid";
import { InputField } from "../../../components/Inputs";
import Button from "../../../components/Button";
import * as T from "../../../components/Typograpy";

import * as S from "./style";
import t from "../../../utils/helpers/translator";
import PasswordChange from "./PasswordChange";
import {
  fields,
  device,
  headlineComponents,
  subHeadlineComponents,
  initStates,
} from "./data";

const { firstName, lastName, email } = fields;

const SpendPartnerAccount = ({
  isMobile,
  onChange,
  handleClick,
  errors,
  showNotification,
  userAccount,
  isUpdating,
  btnOn,
  mainError,
  spendPartner,
  language,
}) => {
  const Headline = headlineComponents[device(isMobile)];
  const SubHeadline = subHeadlineComponents[device(isMobile)];

  const [state, setState] = useState({
    ...initStates.member,
  });

  // set state with user data
  useEffect(() => {
    if (userAccount.data) {
      setState(userAccount.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <S.AccountWrapper>
      <Row>
        <Col w={[4, 12, 12]}>
          <T.H22Caps color="pink">{spendPartner?.name}</T.H22Caps>
          <Headline color="midnight" mt="10">
            {t("myAccount", language)}
          </Headline>
          <SubHeadline
            color="midnight"
            style={{
              marginTop: isMobile ? "2.5rem" : "3.5rem",
              fontWeight: "900",
            }}
          >
            {t("accountDetails", language)}
          </SubHeadline>
        </Col>
      </Row>

      {/* Fields */}
      <Row style={{ alignItems: "stretch" }} mt={3}>
        <Col w={[4, 6, 4]}>
          <InputField
            mt={!isMobile ? 3 : 4}
            label={t("emailAddress", language)}
            type={email.type}
            placeholder={`${t("typeEmail", language)}...`}
            name={email.name}
            setValue={(e) => onChange(e, setState, state)}
            value={state.email || ""}
            error={
              errors.email ||
              (mainError?.data?.field === "email" && mainError.message)
            }
          />
        </Col>
      </Row>
      <Row>
        <Col w={[4, 6, 4]}>
          <InputField
            mt={!isMobile ? 6 : 4}
            label={t("firstName", language)}
            type={firstName.type}
            placeholder={`${t("typeFirstName", language)}...`}
            name={firstName.name}
            setValue={(e) => onChange(e, setState, state)}
            value={state.firstName || ""}
            error={errors.firstName}
          />
        </Col>
        <Col w={[4, 6, 4]}>
          <InputField
            mt={!isMobile ? 6 : 4}
            label={t("lastName", language)}
            type={lastName.type}
            placeholder={`${t("typeLastName", language)}...`}
            name={lastName.name}
            setValue={(e) => onChange(e, setState, state)}
            value={state.lastName || ""}
            error={errors.lastName}
          />
        </Col>
      </Row>

      <Row>
        <Col
          style={{
            marginTop: !isMobile ? "60px" : "40px",
            marginBottom: !isMobile ? "40px" : "20px",
          }}
          w={[4, 6, 4]}
        >
          <Button
            primary
            size="l"
            type="submit"
            handleClick={(e) => handleClick({ e, state })}
            loading={isUpdating}
            disabled={!btnOn}
          >
            {t("saveChanges", language)}
          </Button>
          {showNotification("Changes saved")}
        </Col>
      </Row>
      <PasswordChange />
      <Row>
        <Col w={[4, 8, 8]}>
          <T.Body14R color="gray3">{t("setupMoreVenues", language)}</T.Body14R>
        </Col>
      </Row>
    </S.AccountWrapper>
  );
};

const mapStateToProps = (state) => ({
  spendPartner: state.auth.spendPartner,
  language: state.auth.language,
});

const mapActionToProps = {};

export default connect(mapStateToProps, mapActionToProps)(SpendPartnerAccount);
