import React from "react";

const Star = ({ width, height, color, background, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 24"
    width={width}
    height={height}
    {...props}
  >
    <path
      d="M13 3.005l2.036 6.265.208.641h7.262l-5.33 3.872-.545.397.208.641 2.036 6.265-5.33-3.872-.545-.396-.546.396-5.329 3.872 2.036-6.265.208-.641-.546-.397-5.329-3.872h7.262l.208-.641L13 3.005z"
      fill={background || "none"}
      stroke={color || "currentColor"}
      strokeWidth="1.857"
    />
  </svg>
);
export default Star;
