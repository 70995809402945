import {
  GET_SPEND_ACTIVITIES_TO_ACCEPT_SUCCESS,
  GET_SPEND_ACTIVITIES_TO_ACCEPT_FAIL,
  GET_SPEND_ACTIVITIES_TO_ACCEPT_LOADING_START,
  GET_SPEND_ACTIVITIES_TO_ACCEPT_LOADING_END,
  ACCEPT_TC_LOADING_START,
  ACCEPT_TC_LOADING_END,
  ACCEPT_TC_SUCCESS,
  ACCEPT_TC_FAIL,
  RESET_ACCEPT_TC_POST_ERROR,
} from "../../../redux/types";

import { activities } from "../../../api-calls";
import * as links from "../../../constants/navRoutes";

export const getActivities =
  ({ membershipId, language }) =>
  async (dispatch) => {
    dispatch({ type: GET_SPEND_ACTIVITIES_TO_ACCEPT_LOADING_START });
    try {
      const { data, error } = await activities.getActivities({
        membershipId,
        language,
      });
      if (data) {
        dispatch({
          type: GET_SPEND_ACTIVITIES_TO_ACCEPT_SUCCESS,
          payload: data.activities,
        });
      } else if (error) {
        dispatch({
          type: GET_SPEND_ACTIVITIES_TO_ACCEPT_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_SPEND_ACTIVITIES_TO_ACCEPT_FAIL,
        payload: error,
      });
    } finally {
      dispatch({ type: GET_SPEND_ACTIVITIES_TO_ACCEPT_LOADING_END });
    }
  };

export const acceptTimeCredits =
  ({
    membershipId,
    costVaryPersonTcPrice,
    childrenParticipants,
    adultsParticipants,
    activityId,
    customActivityName,
    customPersonTcPrice,
    isCreatedOnFly,
    confirmedByName,
    isConfirmedOnVolunteerRestriction,
    history,
  }) =>
  async (dispatch) => {
    dispatch({ type: ACCEPT_TC_LOADING_START });
    try {
      const { data, error } = await activities.addParticipantToActivity({
        membershipId,
        costVaryPersonTcPrice,
        childrenParticipants,
        adultsParticipants,
        activityId,
        customActivityName,
        customPersonTcPrice,
        isCreatedOnFly,
        confirmedByName,
        isConfirmedOnVolunteerRestriction,
      });

      if (error) {
        dispatch({
          type: ACCEPT_TC_FAIL,
          payload: { error, membershipId },
        });
      } else {
        dispatch({
          type: ACCEPT_TC_SUCCESS,
          payload: data,
        });
        history.push(links.SPEND_VENUE.ACCEPT_TC_SUCCESS);
      }
    } catch (error) {
      dispatch({
        type: ACCEPT_TC_FAIL,
        payload: { error, membershipId },
      });
    } finally {
      dispatch({ type: ACCEPT_TC_LOADING_END });
    }
  };

export const resetPostError = () => async (dispatch) => {
  dispatch({ type: RESET_ACCEPT_TC_POST_ERROR });
};
