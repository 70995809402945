import { styled } from "@material-ui/core/styles";

import CreditCardDigital from "../../../assets/card/creditCardDigital.svg";

export const StyledCard = styled("div")(({ width, theme, m }) => ({
  background: `no-repeat url(${CreditCardDigital}) 102% / 102% white`,
  borderRadius: "4px",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
  width: width || "340px",
  height: width ? `calc(${width}/1.7)` : "200px",
  color: theme.colors.white,
  position: "relative",
  margin: m || 0,
}));

export const CreditWrapper = styled("span")(({ width }) => ({
  position: "absolute",
  left: width ? `calc(${width}/12)` : "28px",
  top: width ? `calc((${width}/1.7)/5.7)` : "35px",
}));

export const Number = styled("p")(({ width }) => ({
  fontSize: width ? `calc((${width}/340)*60)` : "60px",
  lineHeight: width ? `calc((${width}/340)*60)` : "60px",
  fontWeight: "bold",
  margin: "0",
  textAlign: "left",
  fontFamily: "Poppins",
}));

export const MemberDetailsTitle = styled("p")(({ width }) => ({
  fontSize: width ? `calc((${width}/340)*24)` : "24px",
  lineHeight: width ? `calc((${width}/340)*20)` : "20px",
  paddingTop: width ? `calc((${width}/340)*16)` : "16px",
  fontWeight: "bold",
  margin: 0,
  textTransform: "uppercase",
}));

export const MemberDetailsNumber = styled("p")(({ width }) => ({
  fontSize: width ? `calc((${width}/340)*30)` : "30px",
  fontWeight: "bold",
  margin: 0,
}));

export const SubTitle = styled("p")(({ width }) => ({
  fontSize: width ? `calc((${width}/340)*18)` : "18px",
  fontFamily: "Poppins",
  fontWeight: 900,
  textTransform: "uppercase",
  margin: 0,
}));

export const ID = styled("p")(({ width, type }) => ({
  position: "absolute",
  bottom: width ? `calc((${width}/17))` : "20px",
  left: width ? `calc(${width}/5.2)` : "65px",
  fontSize: width ? `calc((${width}/340)*18)` : "18px",
  margin: 0,
  display: type === "nonDigital" && "none",
  fontWeight: "400",
}));

export const Price = styled("p")(({ width, isMobile }) => ({
  position: "absolute",
  bottom: width ? `calc((${width}/${isMobile ? "300" : "50"}))` : "20px",
  left: width ? `calc(${width}/4)` : "65px",
  margin: 0,
}));

export const Title = styled("span")({
  fontWeight: "bold",
  marginRight: "5px",
});

export const PriceTitle = styled("span")(({ isMobile }) => ({
  fontWeight: "bold",
  fontSize: isMobile ? 24 : 30,
  marginRight: "5px",
}));
