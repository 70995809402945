import t from "../../../utils/helpers/translator";

export const concatArrayToString = (arr) =>
  arr.reduce((accumulator, currentValue, index, array) => {
    if (index === 0) {
      return accumulator;
    }
    if (!currentValue) {
      return accumulator;
    }
    if (index === array.length - 1) {
      return `${accumulator}${array.length > 1 ? "," : " and"} ${currentValue}`;
    }
    return `${accumulator}, ${currentValue}`;
  }, arr[0]);

export const getRestrictionText = (obj, lang, totalTickets) => {
  const restrictionsArray = Object.entries(obj);
  if (restrictionsArray.length > 0) {
    const firstPart = t("proceedingWithTransaction", lang);
    const contentArray = restrictionsArray
      .filter(([key, value]) => {
        if (key === "capacity") return false;
        if (key === "perTransaction" && value.capacity) {
          return totalTickets > value.capacity;
        }
        if (key === "perVolunteerPerMonth" && value.capacity) {
          return totalTickets + value.totalUses > value.capacity;
        }
        if (key === "perVolunteerPerWeek" && value.capacity) {
          return totalTickets + value.totalUses > value.capacity;
        }
        return value.isReached;
      })
      .map(([key]) => t(key, lang));

    const secondPart = contentArray.length
      ? concatArrayToString(contentArray)
      : "";
    return `${firstPart} ${secondPart}`;
  }
};
