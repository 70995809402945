export const fields = {
  firstName: {
    label: "First name",
    type: "text",
    placeholder: "Type first name here...",
    name: "firstName",
  },
  lastName: {
    label: "Last name",
    type: "text",
    placeholder: "Type last name here...",
    name: "lastName",
  },
  email: {
    label: "Email address",
    type: "email",
    placeholder: "Type email here...",
    name: "email",
  },
  phoneNumber: {
    label: "Phone number",
    type: "tel",
    placeholder: "Type phone number here...",
    name: "phoneNumber",
  },
};

export const getInitState = (data) => ({
  organisationName: data.organisationName || "",
  firstName: data.firstName || "",
  lastName: data.lastName || "",
  email: data.email || "",
  phoneNumber: data.phoneNumber || "",
});
