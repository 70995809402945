import React, { useState, useEffect } from "react";
import { useHistory, Link, useParams } from "react-router-dom";
import { connect } from "react-redux";

import * as S from "./style";
import Image from "../../../../components/Image";
import * as T from "../../../../components/Typograpy";
import Table from "../../../../components/FilterTable";
import Dropdown from "../../../../components/Inputs/Dropdown";
import WarningMessage from "../../../../components/WarningMessage";
import { customDateCol } from "../../../../components/Table/config/reusableColumns";
import PopQuestion from "../../../../components/PopQuestion";
import { Col } from "../../../../components/Grid";
import { TEMPO } from "../../../../constants/navRoutes";
import Button from "../../../../components/Button";
import * as actions from "./actions";
import { getEarnGroupByUserId } from "../SingleEarnGroup/actions";

import Modal from "../../../../components/Modal";

const programmesColumns = [
  {
    disableClick: true,

    title: "programme",
    render: (rowData) => (
      <Link to={TEMPO.PROGRAMME_SINGLE.replace(":id", rowData.id)}>
        <T.Link14R>{rowData.name}</T.Link14R>
      </Link>
    ),
    field: "name",
  },
  {
    title: (
      <div style={{ position: "relative", color: "midnight" }}>
        earn groups
        <PopQuestion>
          <T.Body16R color="gray3">
            the number of Earn Groups currently part of the Programme
          </T.Body16R>
        </PopQuestion>
      </div>
    ),
    field: "earnGroups",
    type: "numeric",
  },
  {
    title: (
      <div style={{ position: "relative", width: 70, color: "midnight" }}>
        volunteers reached
        <PopQuestion>
          <T.Body16R color="gray3">
            the number of volunteers who have been issued, gifted or earned Time
            Credits in the programme
          </T.Body16R>
        </PopQuestion>
      </div>
    ),
    field: "members",
    type: "numeric",
  },
  {
    title: (
      <div style={{ position: "relative" }}>
        %Used
        <PopQuestion>
          <T.Body16R color="gray3">
            the % of Time Credits in a programme that have been earned or issued
            directly to volunteers that have been used (with Earn Groups or
            Venues)
          </T.Body16R>
        </PopQuestion>
      </div>
    ),
    field: "spent",
    customSort: (a, b) =>
      Number(a.spent?.replace("%", "")) - Number(b.spent?.replace("%", "")),
  },
  {
    title: (
      <div style={{ position: "relative" }}>
        gifted
        <PopQuestion>
          <T.Body16R color="gray3">
            the number of Time Credits that have been gifted
          </T.Body16R>
        </PopQuestion>
      </div>
    ),
    field: "gifts",
    type: "numeric",
  },
  { title: "status", field: "status" },
];

const programmesColumnsOther = [
  {
    title: "programme",
    disableClick: true,
    render: (rowData) => (
      <Link to={TEMPO.PROGRAMME_SINGLE.replace(":id", rowData.id)}>
        <T.Link14R>{rowData.name}</T.Link14R>
      </Link>
    ),
  },
  customDateCol({
    title: "Date opened",
    field: "startDate",
  }),
  customDateCol({
    title: "end date",
    field: "endDate",
  }),
  { title: "region", field: "region" },
  { title: "focus", field: "mainFocus" },
];

const TkProgrammeConflict = ({
  tkConflictedProgrammes,
  loading,
  getEarnGroupByUserIdAction,
  getTkConflictedProgrammesByProgrammeId,
  groupName,
  programmeName,
  assignProgrammeAction,
}) => {
  const { groupUserId: id, approvalRequestId } = useParams();

  const dropdownData = tkConflictedProgrammes?.map((e) => ({
    value: e.id,
    label: e.name,
  }));
  const history = useHistory();

  const [assignedProgramme, setAssignedProgramme] = useState("");
  const [targetProgramme, setTargetProgramme] = useState("");
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [isMergeModalOpen, setIsMergeModalOpen] = useState(false);

  const handleAssignProgramme = () => {
    if (!assignedProgramme) return;
    assignProgrammeAction({
      groupUserId: id,
      approvalRequestId,
      programmeId: assignedProgramme.value,
    });
    setIsApproveModalOpen(true);
  };

  useEffect(() => {
    getEarnGroupByUserIdAction(id);
    getTkConflictedProgrammesByProgrammeId({ id, approvalRequestId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <S.Container>
        <div>
          <S.Head>
            <S.HeadContent>
              <T.H40Caps color="primary" style={{ margin: 0 }}>
                Programme Conflict
              </T.H40Caps>
              <Col w={[4, 6, 10]} mt={70}>
                <T.Body16R>
                  <T.Body16B style={{ display: "inline" }}>
                    {groupName}
                  </T.Body16B>{" "}
                  is already assigned to another programme ({programmeName}). To
                  complete approving the group, you can either choose to assign
                  the group to a specific programme or you may wish to merge the
                  two programmes if you believe they are the same programme.
                </T.Body16R>
              </Col>
            </S.HeadContent>
            <S.ImageWrapper>
              <Image image="painting" alt="painting" height={210} width={220} />
            </S.ImageWrapper>
          </S.Head>
          <Col w={[4, 12, 12]} mt={40} style={{ height: "300px" }}>
            <Table
              hideSearch
              noPagination
              tabs
              color="blue"
              tabbedData={[
                {
                  tabText: "key info",
                  data: tkConflictedProgrammes,
                  columns: programmesColumns,
                },
                {
                  tabText: "other",
                  data: tkConflictedProgrammes,
                  columns: programmesColumnsOther,
                },
              ]}
              flexDir="column"
            />
          </Col>

          <Col w={[4, 6, 6]} mt={2}>
            <T.H24Caps color="midnight">Assign PROGRAMME </T.H24Caps>
          </Col>
          <Col w={[4, 6, 3]} mt={32}>
            <Dropdown
              label="Programme"
              placeholder="Select..."
              options={dropdownData}
              setValue={({ selected }) => {
                setAssignedProgramme(selected);
                setTargetProgramme("");
              }}
              value={assignedProgramme}
            />
          </Col>
          <Col w={[4, 6, 3]} mt={32}>
            <Button
              primary
              onClick={handleAssignProgramme}
              disabled={!assignedProgramme}
            >
              Submit
            </Button>
          </Col>
        </div>
      </S.Container>

      <Modal
        modalOpen={isApproveModalOpen}
        handleCloseModal={() => setIsApproveModalOpen(false)}
        confirmFun={() => {
          history.push(TEMPO.EARN_GROUP_SINGLE.replace(":id", id));
        }}
        buttonText="View group"
        withCancel
        width={690}
        cancelButtonText="View requests"
        onCancel={() => {
          history.push(TEMPO.TK_GROUP_REQUESTS);
        }}
      >
        <T.Body20B color="blue">Approved!</T.Body20B>
        {targetProgramme ? (
          <T.Body16R mt="15">
            These programmes have been merged and
            <T.Body16B style={{ display: "inline" }}>
              {" "}
              {groupName}
            </T.Body16B>{" "}
            has been successfully set up and connected with Team Kinetic.
          </T.Body16R>
        ) : (
          <T.Body16R mt="15">
            <T.Body16B style={{ display: "inline" }}>{groupName}</T.Body16B> has
            been successfully set up and connected with Team Kinetic.
          </T.Body16R>
        )}
      </Modal>

      <WarningMessage
        open={isMergeModalOpen}
        setOpen={setIsMergeModalOpen}
        handleClick={() => setIsApproveModalOpen(true)}
        title="Are you sure you want to merge these programmes?"
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  tkConflictedProgrammes: state.tkProgrammeConflict.data,
  loading: state.tkProgrammeConflict.loading,
  error: state.tkProgrammeConflict.error,
  groupName: state.staffSingleEarnGroup.name,
  programmeName: state.staffSingleEarnGroup.programme.name,
  programmeId: state.staffSingleEarnGroup.programme.id,
});
const mapActionsToProps = {
  getEarnGroupByUserIdAction: getEarnGroupByUserId,
  getTkConflictedProgrammesByProgrammeId:
    actions.getTkConflictedProgrammesByProgrammeId,
  assignProgrammeAction: actions.assignProgramme,
};

export default connect(mapStateToProps, mapActionsToProps)(TkProgrammeConflict);
