import styled from "@material-ui/core/styles/styled";

const Button = styled("button")(({ theme, loading, disabled, bgColor, m }) => {
  const cursor = loading || disabled ? "auto" : "pointer";
  const background = theme.colors[bgColor] || theme.colors.blue;
  return {
    position: "relative",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    borderRadius: "5px",
    outline: "none",
    cursor,
    border: "none",
    width: "100%",
    minHeight: 110,
    justifyContent: "space-around",
    background,
    transition: "0.2s",
    margin: m || "0",
    "& p": {
      maxWidth: "60%",
      textAlign: "left",
    },
    "&:hover": {
      opacity: 0.8,
    },
  };
});

export default Button;
