import { activities } from "../../../../api-calls";

import {
  GET_ACTIVE_APPROVAL_REQUESTS_LOADING,
  GET_ACTIVE_APPROVAL_REQUESTS_SUCCESS,
  GET_ACTIVE_APPROVAL_REQUESTS_FAIL,
  GET_PREV_APPROVAL_REQUESTS_LOADING,
  GET_PREV_APPROVAL_REQUESTS_SUCCESS,
  GET_PREV_APPROVAL_REQUESTS_FAIL,
} from "../../../../redux/types";

export const getActiveSpendActivitiesApprovalRequests =
  () => async (dispatch) => {
    dispatch({ type: GET_ACTIVE_APPROVAL_REQUESTS_LOADING, payload: true });
    try {
      const { data, error } = await activities.getActivitiesApprovalRequests({
        type: "active",
      });

      if (data) {
        dispatch({ type: GET_ACTIVE_APPROVAL_REQUESTS_SUCCESS, payload: data });
      } else {
        dispatch({
          type: GET_ACTIVE_APPROVAL_REQUESTS_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_ACTIVE_APPROVAL_REQUESTS_FAIL,
        payload: error,
      });
    } finally {
      dispatch({ type: GET_ACTIVE_APPROVAL_REQUESTS_LOADING, payload: false });
    }
  };

export const getPrevSpendActivitiesApprovalRequests =
  () => async (dispatch) => {
    dispatch({ type: GET_PREV_APPROVAL_REQUESTS_LOADING, payload: true });
    try {
      const { data, error } = await activities.getActivitiesApprovalRequests({
        type: "previous",
      });

      if (data) {
        dispatch({ type: GET_PREV_APPROVAL_REQUESTS_SUCCESS, payload: data });
      } else {
        dispatch({
          type: GET_PREV_APPROVAL_REQUESTS_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_PREV_APPROVAL_REQUESTS_FAIL,
        payload: error,
      });
    } finally {
      dispatch({ type: GET_PREV_APPROVAL_REQUESTS_LOADING, payload: false });
    }
  };
