import {
  GET_STAFF_SINGLE_PROGRAMME_LOADING,
  GET_STAFF_SINGLE_PROGRAMME_SUCCESS,
  GET_STAFF_SINGLE_PROGRAMME_FAIL,
  GET_STAFF_PROGRAMME_EARN_GROUPS_LOADING,
  GET_STAFF_PROGRAMME_EARN_GROUPS_SUCCESS,
  GET_STAFF_PROGRAMME_EARN_GROUPS_FAIL,
  UPDATE_PROGRAMME_EARN_GROUP_STATUS_SUCCESS,
  UPDATE_PROGRAMME_EARN_GROUP_STATUS_LOADING,
} from "../../../../redux/types";

const initState = {
  id: "",
  issued: "",
  earned: "",
  gifted: "",
  programme: {
    name: "",
    closeDate: "",
    startDate: "",
    endDate: "",
    keyFocuses: [],
  },
  earnGroups: "",
  membersReached: "",
  spendVenues: "",
  transactions: "",
  balance: 0,
  spentWithEarnGroupsPercentage: "0%",
  spentWithSpendVenuesPercentage: "0%",
  programmeEarnGroups: [],
  managers: [],
  spent: "",
  earnGroupsLoading: false,
  loading: false,
};

const staffSingleProgrammeReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_STAFF_SINGLE_PROGRAMME_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_STAFF_SINGLE_PROGRAMME_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case GET_STAFF_SINGLE_PROGRAMME_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case GET_STAFF_PROGRAMME_EARN_GROUPS_LOADING:
      return {
        ...state,
        earnGroupsLoading: true,
      };
    case GET_STAFF_PROGRAMME_EARN_GROUPS_SUCCESS:
      return {
        ...state,
        programmeEarnGroups: payload,
        earnGroupsLoading: false,
      };
    case GET_STAFF_PROGRAMME_EARN_GROUPS_FAIL:
      return {
        ...state,
        earnGroupsLoading: false,
        error: payload,
      };

    case UPDATE_PROGRAMME_EARN_GROUP_STATUS_LOADING:
      return {
        ...state,
        earnGroupsLoading: true,
      };

    case UPDATE_PROGRAMME_EARN_GROUP_STATUS_SUCCESS:
      return {
        ...state,
        earnGroupsLoading: false,
        programmeEarnGroups: state.programmeEarnGroups.map((eg) => {
          if (payload.earnGroupId === eg.id) {
            // eslint-disable-next-line no-param-reassign
            eg.programme.status = payload.status;
          }
          return eg;
        }),
      };

    default:
      return state;
  }
};

export default staffSingleProgrammeReducer;
