import React, { useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { useParams } from "react-router";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import GoBack from "../../../components/GoBack";
import { Col, Row } from "../../../components/Grid";
import * as T from "../../../components/Typograpy";
import Image from "../../../components/Image";
import Divider from "../../../components/Divider";
import * as S from "./style";
import theme from "../../../utils/theme";

import t from "../../../utils/helpers/translator";
import calculatePriceDetails from "../../../utils/helpers/calculate-price-details";

import { getOrderInvoice } from "./actions";

const InvoiceOrder = ({
  language,
  invoiceNumber,
  getOrderInvoiceAction,
  earnGroupName,
  billingAddressLine1,
  billingAddressLine2,
  billingCity,
  billingPostcode,
  createdAt,
  vat,
  credits,
  price,
  status,
}) => {
  const { totalPrice, subTotalPrice, totalVatCost } = calculatePriceDetails({
    credits,
    vat,
    price,
  });
  const { id } = useParams();
  const isTablet = useMediaQuery(theme.gridBreakpoints.tablet);

  useEffect(() => {
    getOrderInvoiceAction({ orderId: id });
  }, [getOrderInvoiceAction, id]);

  return (
    <S.PageWrapper>
      <GoBack showText />
      <Row jc="space-between" jcM="flex-start">
        <Col w={[4, 12, 6]}>
          {isTablet && (
            <S.ImageWrapper>
              <Image image="logoLarge" />
            </S.ImageWrapper>
          )}
          <T.H40Caps color="midnight" mt="37" mb={20}>
            {status === "PAID"
              ? t("vatInvoice", language)
              : t("proformaInvoice", language)}
          </T.H40Caps>
          <T.Body14R color="gray3" mt="37" mb={20}>
            {t("toSaveThisInvoiceClickPrint", language)}
          </T.Body14R>
        </Col>
        {!isTablet && (
          <Col
            w={[4, 4, 4]}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <S.ImageWrapper>
              <Image image="logoLarge" />
            </S.ImageWrapper>
          </Col>
        )}
      </Row>
      <Row mt={8} style={{ flexDirection: isTablet ? "column" : "row" }}>
        {/* To section */}
        <Col
          w={[4, 12, 6]}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <S.Flex>
            <S.subTitleWrapper m="0" mr="20" mt="5">
              <T.H20 color="midnight" style={{ textTransform: "capitalize" }}>
                {t("to", language)}
              </T.H20>
            </S.subTitleWrapper>

            <S.DetailsWrapper isTablet={isTablet} m="0">
              {earnGroupName && (
                <T.Body20 color="gray3">
                  {earnGroupName.trim() + (billingAddressLine1 ? "," : "")}
                </T.Body20>
              )}
              {billingAddressLine1 && (
                <T.Body20 color="gray3">
                  {billingAddressLine1.trim() +
                    (billingAddressLine2 ? "," : "")}
                </T.Body20>
              )}
              {billingAddressLine2 && (
                <T.Body20 color="gray3">
                  {billingAddressLine2.trim() + (billingCity ? "," : "")}
                </T.Body20>
              )}
              {billingCity && (
                <T.Body20 color="gray3">
                  {billingCity.trim()}
                  {billingPostcode && ","}
                </T.Body20>
              )}
              {billingPostcode && (
                <T.Body20 color="gray3">{billingPostcode}</T.Body20>
              )}
            </S.DetailsWrapper>
          </S.Flex>
        </Col>
        {/* From Section */}
        <Col w={[4, 12, 6]}>
          <S.Flex>
            <S.subTitleWrapper m="0" mr="22" mt={isTablet ? 55 : 5}>
              <T.H20 color="midnight" style={{ textTransform: "capitalize" }}>
                {t("from", language)}
              </T.H20>
            </S.subTitleWrapper>
            <S.DetailsWrapper m="0" mt={isTablet && 55}>
              <T.Body20 color="gray3">Tempo Time Credits,</T.Body20>
              <T.Body20 color="gray3">The Maltings,</T.Body20>
              <T.Body20 color="gray3">East Tyndall Street,</T.Body20>
              <T.Body20 color="gray3">Cardiff,</T.Body20>
              <T.Body20 color="gray3">CF24 5EA</T.Body20>
            </S.DetailsWrapper>
          </S.Flex>
        </Col>
      </Row>
      <Row mt={7}>
        <Col w={[4, 6, 4]}>
          {/* Invoice Number */}
          <S.Flex>
            <S.subTitleWrapper m="0" mr="20">
              <T.H20 color="midnight" m="0">
                {t("invoiceNumber", language)}
              </T.H20>
            </S.subTitleWrapper>
            <S.DetailsWrapper m="0" ml="18">
              <T.Body20 color="gray3">{invoiceNumber}</T.Body20>
            </S.DetailsWrapper>
          </S.Flex>

          {/* Invoice Date */}
          <S.Flex style={{ marginTop: 33, alignItems: "center" }}>
            <S.subTitleWrapper m="0" mr="20">
              <T.H20 color="midnight" m="0">
                {t("invoiceDate", language)}
              </T.H20>
            </S.subTitleWrapper>
            <S.DetailsWrapper ml="18">
              <T.Body20 color="gray3">
                {moment(createdAt).format("DD/MM/YYYY")}
              </T.Body20>
            </S.DetailsWrapper>
          </S.Flex>
        </Col>
      </Row>
      <Row mt={isTablet ? 6 : 10}>
        {/* Description */}
        <Col w={[4, 12, 4]} style={{ marginBottom: isTablet && 55 }}>
          <T.H20 color="midnight" m="0" mb={10}>
            {t("description", language)}
          </T.H20>
          <T.Body20 color="gray3">
            {t("tempoTimeCreditsPurchase", language)}
          </T.Body20>
        </Col>
        <Col w={[4, 12, 6]}>
          {/* Quantity &&  Amount GBP */}
          <S.Flex>
            <S.FiledWrapper m="0">
              <T.H20 color="midnight">{t("quantity", language)}</T.H20>
            </S.FiledWrapper>
            <S.FiledWrapper m="0">
              <T.H20 color="midnight">
                {t("amount", language)} {t("GBP", language)}
              </T.H20>
            </S.FiledWrapper>
          </S.Flex>

          <S.Flex>
            <S.FiledWrapper mt="10">
              <T.Body20 color="gray3">{credits}</T.Body20>
            </S.FiledWrapper>
            <S.FiledWrapper mt="10">
              <T.H20 color="black">{subTotalPrice.toFixed(2)}</T.H20>
            </S.FiledWrapper>
          </S.Flex>

          {/* Subtotal */}
          <S.Flex>
            <S.FiledWrapper mt="50">
              <T.H20 color="midnight">{t("subtotal", language)}</T.H20>
            </S.FiledWrapper>
            <S.FiledWrapper mt="50">
              <T.Body20 color="gray3">{subTotalPrice.toFixed(2)}</T.Body20>
            </S.FiledWrapper>
          </S.Flex>

          {/* Total VAT 20% */}
          <S.Flex>
            <S.FiledWrapper mt="21">
              <T.H20 color="midnight">{t("totalVAT20", language)}</T.H20>
            </S.FiledWrapper>
            <S.FiledWrapper mt="21">
              <T.Body20 color="gray3">{totalVatCost.toFixed(2)}</T.Body20>
            </S.FiledWrapper>
          </S.Flex>

          {/* Amount */}
          <S.Flex>
            <S.FiledWrapper mt="40">
              <T.H30 color="midnight">{t("amount", language)}</T.H30>
            </S.FiledWrapper>
            <S.FiledWrapper mt="40">
              <T.H30 color="gray2">
                <span style={{ fontWeight: "normal" }}>
                  {t("GBP", language)}
                </span>{" "}
                {totalPrice.toFixed(2)}
              </T.H30>
            </S.FiledWrapper>
          </S.Flex>
        </Col>
      </Row>
      <Divider h="1px" bgColor="gray5" m="55px 0 40px 0" />
      <T.Body14R mb="10">
        © Tempo Time Credits Limited. Tempo is a Limited Company Registered in
        England and Wales:
        <span style={{ display: "block" }}>
          Tempo Time Credits Limited No. 06593956
        </span>
      </T.Body14R>
      <T.Body14R mb="10">VAT number: GB 129 287 783</T.Body14R>
      <T.Body14R mb="10">Registered Charity No. 1135143</T.Body14R>
      <T.Body14R mb="10">
        Registered office address: The Maltings, East Tyndall Street, Cardiff,
        CF24 5EA
      </T.Body14R>
    </S.PageWrapper>
  );
};

const mapStateToProps = ({ auth, orderInvoice: { data = {} } = {} }) => ({
  language: auth.language,
  ...data,
});

const mapActionToProps = {
  getOrderInvoiceAction: getOrderInvoice,
};

export default connect(mapStateToProps, mapActionToProps)(InvoiceOrder);
