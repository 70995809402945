import React from "react";
import { connect } from "react-redux";

import useStyle from "./style";
import Image from "../../Image";
import ZendeskButton from "../../ZendeskButton";
import Toggle from "../../Toggle";

const SplitScreen = ({ children, hideLangToggle, ...props }) => {
  const { logo, side, color, layoutColor } = props;
  const classes = useStyle({ ...props, color: layoutColor || color });

  const showZendesk = typeof window?.zE === "function";

  return (
    <main className={classes.Wrapper}>
      {!hideLangToggle && <Toggle />}
      <div className={classes.NonColouredDiv} position={side}>
        {logo && (
          <Image
            image="logoLarge"
            alt="logo"
            className={classes.LogoHeader}
            width={110}
            customStyle={{ position: "absolute" }}
          />
        )}
        <section className={classes.ContentWrapper}>{children}</section>
      </div>
      <div className={classes.ColouredDiv} />
      {showZendesk && <ZendeskButton />}
    </main>
  );
};

const mapStateToProps = ({ ui }) => ({ color: ui.color });

export default connect(mapStateToProps)(SplitScreen);
