import React from "react";
import * as T from "../../Typograpy";

const DoubleLine = ({ line1, line2, status }) => (
  <span>
    <T.Body14S style={{ textTransform: "capitalize" }}>
      {status === "deleted" ? "Deleted Volunteer" : line1}
    </T.Body14S>
    {status !== "deleted" && (
      <T.Body14R color="gray3" style={{ textTransform: "capitalize" }}>
        {line2}
      </T.Body14R>
    )}
  </span>
);

export default DoubleLine;
