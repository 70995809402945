import styled from "@material-ui/core/styles/styled";

export const Head = styled("header")(({ theme }) => ({
  marginBottom: theme.spacing(5),
}));

export const BtnContainer = styled("div")({
  alignSelf: "flex-end",
  transform: "translateY(195px)",
  zIndex: 3,

  display: "flex",
});

export const TableContainer = styled("div")({
  marginTop: 30,
  display: "flex",
  flexDirection: "column",
  position: "relative",
});

export const CapitalizedSpan = styled("span")({
  textTransform: "capitalize",
});

export const CsvExport = styled("a")(({ theme }) => ({
  textDecoration: "none",
  marginBottom: theme.spacing(2),
  display: "block",
}));

export const ModalContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
});
export const FilterContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
});
