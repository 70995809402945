import styled from "@material-ui/core/styles/styled";

export const PopContainer = styled("div")(({ position }) => {
  const right = (position && position.right) || -30;
  const top = (position && position.top) || "50%";
  const left = (position && position.left) || "auto";
  const bottom = (position && position.bottom) || "auto";
  return {
    position: "absolute",
    right,
    top,
    left,
    bottom,
    transform: "translateY(-50%)",
    zIndex: 999,
  };
});

export const PopBtn = styled("button")(({ m }) => ({
  background: "none",
  boxShadow: "none",
  border: "none",
  outline: "none",
  margin: m || "0",
}));
