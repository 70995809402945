import React from "react";

const CreditCircleGradient = ({ width, height, color, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M44 22C44 26.3513 42.7097 30.6049 40.2921 34.2228C37.8746 37.8408 34.4385 40.6606 30.4184 42.3256C26.3983 43.9907 21.9746 44.4262 17.707 43.5771C13.4393 42.728 9.51931 40.6324 6.44267 37.5553C3.36602 34.4783 1.27095 30.558 0.422391 26.2903C-0.426166 22.0225 0.009908 17.5989 1.67547 13.579C3.34103 9.55909 6.16126 6.12335 9.77951 3.7063C13.3978 1.28925 17.6515 -0.000555685 22.0028 1.79594e-07C27.8353 0.00669598 33.4269 2.32679 37.5508 6.45121C41.6747 10.5756 43.994 16.1675 44 22ZM38.3061 32.3986L35.8666 30.9933L36.7294 29.501L39.1689 30.9062C40.4731 28.4119 41.2119 25.6609 41.3329 22.8487H38.5056V21.14H41.3301C41.2092 18.327 40.4704 15.575 39.1661 13.0797L36.7266 14.4849L35.8638 12.9954L38.3033 11.5902C36.7847 9.22024 34.7713 7.20689 32.4014 5.6883L30.9962 8.12774L29.5123 7.27338L30.9175 4.83112C28.4226 3.52818 25.6719 2.78942 22.86 2.66709V5.47752H21.1428V2.66709C18.33 2.78938 15.5784 3.52813 13.0825 4.83112L14.4877 7.27338L12.9982 8.13337L11.593 5.69392C9.22384 7.21348 7.21068 9.22664 5.69112 11.5958L8.13056 13.001L7.27058 14.4905L4.82832 13.0853C3.52533 15.5812 2.78658 18.3328 2.66429 21.1456H5.47471V22.8656H2.66429C2.78959 25.6751 3.53024 28.4228 4.83394 30.9147L7.2762 29.5094L8.13619 31.0018L5.69674 32.407C7.21533 34.7769 9.22868 36.7903 11.5986 38.3089L13.0038 35.8694L14.4934 36.7322L13.0882 39.1745C15.5842 40.4766 18.3358 41.2144 21.1484 41.3357V38.5028H22.8684V41.3273C25.6806 41.2063 28.4316 40.4675 30.9259 39.1632L29.5207 36.7238L31.013 35.861L32.4182 38.3004C34.7831 36.7837 36.7916 34.7732 38.3061 32.407V32.3986Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="-8.19564e-08"
        y1="22"
        x2="44"
        y2="22"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0057A3" />
        <stop offset="1" stopColor="#00B2D5" />
      </linearGradient>
    </defs>
  </svg>
);
export default CreditCircleGradient;
