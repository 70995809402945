import React from "react";
import { Fade, Popper as MatPopper } from "@material-ui/core";
import Icon from "../Icon";
import * as S from "./style";
import * as T from "../Typograpy";

const Popper = ({
  open,
  placement,
  anchorEl,
  type,
  icon,
  title,
  content,
  iconColor,
  password,
  popperProps = {},
  children,
}) => (
  <MatPopper
    open={open}
    placement={placement || popperProps.placement || "top"}
    anchorEl={anchorEl}
    transition
    style={{ zIndex: 99999999 }}
  >
    {({ TransitionProps }) => (
      <Fade {...TransitionProps} timeout={250} style={{ background: "white" }}>
        {type === "small" ? (
          <S.Wrapper
            password={password}
            type={type}
            style={{
              position: popperProps.position || "static",
              top: popperProps.top,
              left: popperProps.left,
              right: popperProps.right,
              width: popperProps.width,
              margin: popperProps.margin,
            }}
          >
            {icon && (
              <Icon
                icon={icon}
                color={iconColor || "cyan"}
                height="34px"
                width={popperProps.iconWidth || "85px"}
                margin="0 5px 0 0"
                style={{
                  alignSelf: popperProps.alignIcon,
                }}
              />
            )}

            <>{children}</>
          </S.Wrapper>
        ) : (
          <S.Wrapper type={type}>
            <S.Header>
              {icon && (
                <Icon
                  icon={icon}
                  color={iconColor || "cyan"}
                  height="34px"
                  width="34px"
                  margin="0 5px 0 0"
                />
              )}
              <T.H20 color="gray3">{title}</T.H20>
            </S.Header>
            <T.Body16R
              color="gray3"
              style={{ alignSelf: "center", height: " min-content" }}
            >
              {content}
            </T.Body16R>
          </S.Wrapper>
        )}
      </Fade>
    )}
  </MatPopper>
);
export default Popper;
