import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import * as S from "./style";
import * as T from "../../../components/Typograpy";
import CreditCard from "../../../components/Cards/CreditCard";
import Button from "../../../components/Button";

import RequestReceived from "./RequestReceived";
import * as actions from "./actions";

import t from "../../../utils/helpers/translator";

import PaidRequest from "./PaidRequest";

const RequestCredits = ({
  postCreditsRequest,
  loading,
  error,
  language,
  programmeId,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();

  const [credits, setCredits] = useState(0);

  const notSelected = (cardValue) => credits > 0 && credits !== cardValue;

  const handleSubmit = () => {
    postCreditsRequest(credits, history);
  };

  const hasFollowProgramme = Boolean(programmeId);

  if (!hasFollowProgramme) {
    return <PaidRequest isMobile={isMobile} />;
  }
  return (
    <S.Wrapper>
      {isMobile ? (
        <>
          <T.H30Caps color="midnight" style={{ margin: "0" }}>
            {t("getMoreTempoTC", language).toUpperCase()}
          </T.H30Caps>
          <T.Body18B mt={20} mb={10} color="midnight">
            {t("howManyTCs", language)}?
          </T.Body18B>
        </>
      ) : (
        <>
          <T.H40Caps color="midnight" style={{ margin: "0" }}>
            {t("getMoreTempoTC", language).toUpperCase()}
          </T.H40Caps>
          <T.Body20B mt={40} mb={10} color="midnight">
            {t("howManyTCs", language)}?
          </T.Body20B>
        </>
      )}
      {isMobile ? (
        <T.Body16R>{t("selectPackage", language)}</T.Body16R>
      ) : (
        <T.Body18R>{t("selectPackage", language)}</T.Body18R>
      )}
      <S.CardsWrapper>
        <S.StyledButton
          onClick={() => setCredits(50)}
          notSelected={notSelected(50)}
        >
          <CreditCard
            type="digital"
            credits="50"
            width={isMobile ? "300px" : "340px"}
            hideID
            tcText={t("timeCreditsNumInFront", language)}
          />
        </S.StyledButton>
        <S.StyledButton
          onClick={() => setCredits(100)}
          notSelected={notSelected(100)}
        >
          <CreditCard
            type="digital"
            credits="100"
            width={isMobile ? "300px" : "340px"}
            hideID
            tcText={t("timeCreditsNumInFront", language)}
          />
        </S.StyledButton>
        <S.StyledButton
          onClick={() => setCredits(150)}
          notSelected={notSelected(150)}
        >
          <CreditCard
            type="digital"
            credits="150"
            width={isMobile ? "300px" : "340px"}
            hideID
            tcText={t("timeCreditsNumInFront", language)}
          />
        </S.StyledButton>
        <S.StyledButton
          onClick={() => setCredits(250)}
          notSelected={notSelected(250)}
          tcText={t("timeCreditsNumInFront", language)}
        >
          <CreditCard
            type="digital"
            credits="250"
            width={isMobile ? "300px" : "340px"}
            hideID
            tcText={t("timeCreditsNumInFront", language)}
          />
        </S.StyledButton>
      </S.CardsWrapper>
      {t(error.message, language)}
      <S.ButtonWrapper>
        {credits === 0 || loading ? (
          <Button disabled bgColor="gray5" textColor="black">
            {t("getMoreTempoTC", language)}
          </Button>
        ) : (
          <Button
            primary
            bgColor="blue"
            onClick={handleSubmit}
            loading={loading}
          >
            {t("getMoreTempoTC", language)}
          </Button>
        )}
      </S.ButtonWrapper>
      {isMobile ? (
        <T.Body16B color="primary" mt={30} ml={5}>
          {t("discussThisFurther", language)}
        </T.Body16B>
      ) : (
        <T.Body18S mt={60} ml={5}>
          {t("discussThisFurther", language)}
        </T.Body18S>
      )}
    </S.Wrapper>
  );
};

export { RequestReceived };

const mapStateToProps = (state) => ({
  loading: state.requestTimeCredits.loading,
  error: state.requestTimeCredits.error,
  language: state.auth.language,
  programmeId: state.auth.programmeId,
});

const mapActionToProps = {
  postCreditsRequest: actions.postCreditsRequest,
};

export default connect(mapStateToProps, mapActionToProps)(RequestCredits);
