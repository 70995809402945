import {
  GET_SPEND_ACTIVITY_ADMIN_LOADING_START,
  GET_SPEND_ACTIVITY_ADMIN_LOADING_END,
  GET_SPEND_ACTIVITY_ADMIN_SUCCESS,
  GET_SPEND_ACTIVITY_ADMIN_FAIL,
  UPDATE_SPEND_ACTIVITY_ADMIN_LOADING_START,
  UPDATE_SPEND_ACTIVITY_ADMIN_LOADING_END,
  UPDATE_SPEND_ACTIVITY_ADMIN_SUCCESS,
  UPDATE_SPEND_ACTIVITY_ADMIN_FAIL,
  RESET_SPEND_ACTIVITY_ADMIN,
  DELETE_SPEND_ACTIVITY_LOADING,
  DELETE_SPEND_ACTIVITY_SUCCESS,
  DELETE_SPEND_ACTIVITY_FAIL,
} from "../../redux/types";

import { activities } from "../../api-calls";
import R from "../../constants/roles";

import { TEMPO, SPEND_VENUE, EARN_GROUP } from "../../constants/navRoutes";

export const getActivityById =
  (id, { isPublic }) =>
  async (dispatch) => {
    dispatch({ type: GET_SPEND_ACTIVITY_ADMIN_LOADING_START });
    try {
      const { data, error } = await activities.getActivityById({
        id,
        type: "spend",
        isPublic,
        options: { redirectToRelatedPage: true },
      });
      if (data) {
        dispatch({
          type: GET_SPEND_ACTIVITY_ADMIN_SUCCESS,
          payload: data,
        });
      } else if (error) {
        dispatch({
          type: GET_SPEND_ACTIVITY_ADMIN_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_SPEND_ACTIVITY_ADMIN_FAIL,
        payload: error,
      });
    } finally {
      dispatch({ type: GET_SPEND_ACTIVITY_ADMIN_LOADING_END });
    }
  };

export const updateActivity = (id, updateData) => async (dispatch) => {
  dispatch({ type: UPDATE_SPEND_ACTIVITY_ADMIN_LOADING_START });
  try {
    const { data, error } = await activities.updateActivityById({
      id,
      type: "spend",
      updateData,
    });
    if (error) {
      dispatch({
        type: UPDATE_SPEND_ACTIVITY_ADMIN_FAIL,
        payload: error,
      });
    } else {
      dispatch({
        type: UPDATE_SPEND_ACTIVITY_ADMIN_SUCCESS,
        payload: data,
      });
    }
    return { data, error };
  } catch (error) {
    dispatch({
      type: UPDATE_SPEND_ACTIVITY_ADMIN_FAIL,
      payload: error,
    });
  } finally {
    dispatch({ type: UPDATE_SPEND_ACTIVITY_ADMIN_LOADING_END });
  }
};

export const createActivity =
  (activityData, history, role) => async (dispatch) => {
    dispatch({ type: UPDATE_SPEND_ACTIVITY_ADMIN_LOADING_START });
    try {
      const { data, error } = await activities.createActivity({
        type: "spend",
        activityData,
      });
      if (error) {
        dispatch({
          type: UPDATE_SPEND_ACTIVITY_ADMIN_FAIL,
          payload: error,
        });
      } else {
        dispatch({
          type: UPDATE_SPEND_ACTIVITY_ADMIN_SUCCESS,
          payload: data.createdActivity,
        });

        if (role === R.EARN_GROUP || role === R.EARN_GROUP_ADMIN) {
          history.push({
            pathname: EARN_GROUP.EDIT_OFFERS.replace(
              ":id",
              data.createdActivity.id,
            ),
          });
        } else if (role === R.SPEND_PARTNER || role === R.SPEND_VENUE) {
          history.push({
            pathname: SPEND_VENUE.EDIT_OFFERS.replace(
              ":id",
              data.createdActivity.id,
            ),
          });
        } else {
          history.push({
            pathname: TEMPO.EDIT_SPEND_ACTIVITY.replace(
              ":id",
              data.createdActivity.id,
            ),
          });
        }
      }
      return { error, data };
    } catch (error) {
      dispatch({
        type: UPDATE_SPEND_ACTIVITY_ADMIN_FAIL,
        payload: error,
      });
    } finally {
      dispatch({ type: UPDATE_SPEND_ACTIVITY_ADMIN_LOADING_END });
    }
  };

export const deleteSpendActivity = (id) => async (dispatch) => {
  dispatch({ type: DELETE_SPEND_ACTIVITY_LOADING, payload: true });
  try {
    const { data, error } = await activities.deleteActivity({
      id,
    });
    dispatch({ type: DELETE_SPEND_ACTIVITY_LOADING, payload: false });

    if (error) {
      dispatch({
        type: DELETE_SPEND_ACTIVITY_FAIL,
        payload: error,
      });
      return false;
    }

    dispatch({
      type: DELETE_SPEND_ACTIVITY_SUCCESS,
      payload: data,
    });
    return true;
  } catch (error) {
    dispatch({
      type: DELETE_SPEND_ACTIVITY_FAIL,
      payload: error,
    });
    return false;
  }
};

export const resetSpendActivityForm = () => ({
  type: RESET_SPEND_ACTIVITY_ADMIN,
});
