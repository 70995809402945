import React from "react";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { logout } from "../../../../../../redux/actions/auth";

import * as S from "../style";
import { Row, Col } from "../../../../../../components/Grid";
import { Regular } from "../../../../../../components/Button";
import * as T from "../../../../../../components/Typograpy";

import { GENERAL } from "../../../../../../constants/navRoutes";

import translate from "../../../../../../utils/helpers/translator";

const Content = ({ language, loggedIn, logoutAction, isTablet }) => {
  const history = useHistory();
  return (
    <>
      <Row jcM="center">
        <Col w={[4, 10, 10]}>
          {isTablet ? (
            <T.H30Caps
              color="midnight"
              mb="30"
              mt={10}
              style={{ textTransform: "uppercase" }}
            >
              {translate("createMemberAccount", language)}
            </T.H30Caps>
          ) : (
            <T.H40Caps color="midnight" mb="30">
              {translate("createMemberAccount", language)}
            </T.H40Caps>
          )}
        </Col>
      </Row>
      <Row mb={6}>
        <Col w={[4, 8, 8]}>
          <T.Body16R color="gray3">
            {translate("mustHaveAccount", language)}
          </T.Body16R>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 8, 6]}>
          <Regular
            primary
            bgColor="blue"
            onClick={() => history.push(GENERAL.SIGN_UP)}
          >
            {translate("createAccount", language)}
          </Regular>
          <S.LogInWrapper>
            <T.Body14R color="gray3" m="0 5px 0 0">
              {translate("alreadyHaveAccount", language)}?{" "}
              {loggedIn ? (
                <S.StyledLink to="#" onClick={() => logoutAction({ history })}>
                  {translate("logIn", language)}
                </S.StyledLink>
              ) : (
                <S.StyledLink to={GENERAL.LOG_IN}>
                  {translate("logIn", language)}
                </S.StyledLink>
              )}
            </T.Body14R>
          </S.LogInWrapper>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: state.auth.isLoggedIn,
  language: state.auth.decideLanguage(state.auth),
});

const mapActionToProps = {
  logoutAction: logout,
};

export default connect(mapStateToProps, mapActionToProps)(Content);
