import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../../../utils/theme";

import { GENERAL } from "../../../constants/navRoutes";

import * as S from "./style";
import * as T from "../../../components/Typograpy";
import Button from "../../../components/Button";
import * as actions from "../../../redux/actions/updateEarnGroupProfile";
import { getLocation } from "../../../redux/actions/location";
import {
  focusDropDownEnglish,
  focusDropDownWelsh,
  focusValuesMapWelsh,
} from "../../../constants/dropdDownData";

import validate, {
  validateWelsh,
} from "../../../validation/schemas/recruitVolunteers";

import trimArrayOfStrings from "../../../utils/helpers/trimArrayOfStrings";

import { InputField, Dropdown, Checkbox } from "../../../components/Inputs";
import { Row, Col } from "../../../components/Grid";
import Image from "../../../components/Image";
import LocationWrapper from "../../../components/LocationFields/LocationsWrapper";
import ActivitiesSection from "../../../components/ActivitiesSection";
import SpecificActivities from "../../../components/SpecificActivities";
import UploadImage from "../../../components/UploadImage";
import Notification from "../../../components/Notification";
import Info from "../../../components/Message/Info";
import t from "../../../utils/helpers/translator";

import {
  formatAddresses,
  formatActivitiesRoles,
  setAddressesWelsh,
  setActivitiesRolesWelsh,
} from "../../../utils/helpers/manageWelshEarnGroupForms";

import tidyPostcode from "../../../utils/helpers/tidyPostcode";
import stripLeadingTab from "../../../utils/helpers/stripLeadingTab";
import cleanWhitespace from "../../../utils/helpers/cleanWhitespace";
import formatUrl from "../../../utils/helpers/urlFormatter";
import GoBack from "../../../components/GoBack";

const getCleanData = ({ ...data }) => ({
  contactEmail: data.contactEmail ? data.contactEmail.replace(/ /g, "") : "",
  contactNumber: stripLeadingTab(data.contactNumber?.replace(/ /g, "")) || null,
  description: data.description ? data.description.trim() : "",
  focusFields: data.focusFields,
  getInTouchDescription: data.getInTouchDescription
    ? data.getInTouchDescription.trim()
    : "",
  isOnline: data.isOnline,
  isPrivateAddress: data.isPrivateAddress,
  isPublic: data.isPublic,
  name: data.name ? data.name.trim() : "",
  socialLinks: data.socialLinks,
  websiteUrl: data.websiteUrl ? formatUrl(data.websiteUrl.trim()) : "",
  publicActivities: data.publicActivities,
  keywords: data.keywords,
  // welsh fields
  nameWelsh: data.nameWelsh,
  getInTouchDescriptionWelsh: data.getInTouchDescriptionWelsh,
  descriptionWelsh: data.descriptionWelsh,
  addressesWelsh: data.addressesWelsh,
  addresses: data.addresses,
  activitiesRoles: data.activitiesRoles,
  activitiesRolesWelsh: data.activitiesRolesWelsh,
  keywordsWelsh: data.keywordsWelsh,
});

const RecruitVolunteers = ({
  lang,
  data,
  getEarnGroupProfile,
  getProfileSuccess,
  updateEarnGroupProfile,
  error,
  updateProfileLoading,
  updateProfileSuccess,
  resetUpdateEarnGroupProfile,
  getLocationAction,
}) => {
  const [state, setState] = useState(getCleanData({ ...data }));
  const [addresses, setAddresses] = useState([]);
  const [activitiesRoles, setActivitiesRoles] = useState([]);
  const [welshVersion, setWelshVersion] = useState(false);
  const [errors, setErrors] = useState(getCleanData({}));
  const { id } = useParams();
  const [logoImageInfo, setLogoImageInfo] = useState({
    id: null,
    name: "",
    key: "",
    bucketRegion: "",
    bucket: "",
    new: false,
    uploadedToS3: false,
  });
  const [coverImageInfo, setCoverImageInfo] = useState({
    id: null,
    name: "",
    key: "",
    bucketRegion: "",
    bucket: "",
    new: false,
    uploadedToS3: false,
  });
  const [keywords, setKeywords] = useState([]);
  const [keywordsWelsh, setKeywordsWelsh] = useState([]);

  const [startUpload, setStartUpload] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [newPostcodes, setNewPostcodes] = useState(false);
  const [imagesReady, setImagesReady] = useState(false);
  const [geocodesReady, setGeocodesReady] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);

  const handleWalshCheckbox = () => setWelshVersion((old) => !old);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (name, value) => {
    setState((_state) => ({
      ..._state,
      [name]:
        name === "email" || name === "contactEmail"
          ? value.toLowerCase().replace(/ /g, "").trim()
          : value,
    }));
  };

  useEffect(() => {
    if (state.isOnline) {
      setAddresses([
        {
          id: 0,
        },
      ]);
    }
  }, [state.isOnline]);

  const onFocusFieldsChange = ({ selected }) => {
    const value = selected ? selected.map((e) => e.value) : [];
    if (value.length <= 3) {
      handleChange("focusFields", value);
    }
  };

  const handleKeywords = (e, _lang) => {
    if (e.target.value.trim() === "" || e.target.value.trim() === ",") {
      if (_lang === "english") {
        return setKeywords([]);
      }

      return setKeywordsWelsh([]);
    }

    const keywordsArr = e.target.value.split(",");

    if (_lang === "english") {
      setKeywords(keywordsArr);
    } else {
      return setKeywordsWelsh(keywordsArr);
    }
  };

  const submit = () => {
    const { nameWelsh, getInTouchDescriptionWelsh, descriptionWelsh } = state;

    let formSubmissionFields = {
      ...state,
      name: cleanWhitespace(state.name),
      addresses,
      activitiesRoles,
      coverImage: coverImageInfo,
      logoImage: logoImageInfo,
      keywords: trimArrayOfStrings(keywords),
      contactNumber: state.contactNumber?.replace(/ /g, ""),
    };

    const formSubmissionFieldsWelsh = {
      nameWelsh: cleanWhitespace(nameWelsh),
      getInTouchDescriptionWelsh,
      descriptionWelsh,
      addresses: setAddressesWelsh(addresses),
      activitiesRoles: setActivitiesRolesWelsh(activitiesRoles),
      keywordsWelsh: trimArrayOfStrings(keywordsWelsh),
    };

    if (welshVersion) {
      formSubmissionFields = {
        ...formSubmissionFields,
        welshFields: { ...formSubmissionFieldsWelsh },
      };
    }

    updateEarnGroupProfile(id, {
      ...formSubmissionFields,
    });
  };

  const fetchGeocodes = async (addressArr) => {
    const postcodeArr = addressArr.map((address) => address.postcode);

    try {
      setFetchingData(true);
      const { result } = await getLocationAction({
        query: postcodeArr,
        queryType: "bulkPostcodes",
      });
      const updatedAddresses = addressArr.map((address) => {
        const foundPostcodeInfo = result.find(
          (el) => el.query === address.postcode,
        );
        if (!foundPostcodeInfo || !foundPostcodeInfo.result) {
          throw new Error(t("postcodeNotRecognised", lang));
        }
        const { latitude, longitude } = foundPostcodeInfo.result;
        return { ...address, lat: latitude, long: longitude };
      });
      setAddresses(updatedAddresses);
      setGeocodesReady(true);
    } catch (err) {
      setFetchingData(false);
      setErrors({
        postcode: `Error getting the latitude and longitude for this postcode`,
        anyErrors: `${t("longitudeLatitudeError", lang)}: ${
          t(err.inner, lang) || t(err, lang)
        }"`,
      });
      return null;
    }
  };

  const handleClick = async () => {
    const tidyAddresses = state.isOnline
      ? [{ id: 0 }]
      : addresses.map((address) => ({
          ...address,
          city: address.city && address.city.trim(),
          postcode: tidyPostcode(address.postcode),
        }));
    // client side validation
    try {
      if (welshVersion) {
        validateWelsh({
          ...getCleanData(state),
          coverImageKey: coverImageInfo.key,
          logoImageKey: logoImageInfo.key,
          addresses: tidyAddresses,
          activitiesRoles: activitiesRoles.map((e) => ({
            ...e,
            name: e.name && e.name.trim(),
            details: e.details && e.details.trim(),
            nameWelsh: e.nameWelsh && e.nameWelsh.trim(),
            detailsWelsh: e.detailsWelsh && e.detailsWelsh.trim(),
          })),
          keywords,
          keywordsWelsh,
        });
      } else {
        validate({
          ...getCleanData(state),
          coverImageKey: coverImageInfo.key,
          logoImageKey: logoImageInfo.key,
          addresses: tidyAddresses,
          activitiesRoles: activitiesRoles.map((e) => ({
            ...e,
            name: e.name && e.name.trim(),
            details: e.details && e.details.trim(),
          })),
          keywords,
        });
      }

      setErrors({ ...getCleanData({}), coverImageKey: "", logoImageKey: "" });
      setAddresses(tidyAddresses);

      if (!state.isOnline && newPostcodes) {
        await fetchGeocodes(tidyAddresses);
      } else {
        setGeocodesReady(true);
      }

      if (logoImageInfo.new || coverImageInfo.new) {
        setStartUpload(true);
      } else {
        setImagesReady(true);
      }
    } catch (err) {
      if (err.name === "ValidationError") {
        err.inner.anyErrors = t("inputFieldMissing", lang);
        setErrors(err.inner);
      }
    }
  };

  useEffect(() => {
    getEarnGroupProfile(id);
    return resetUpdateEarnGroupProfile;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    // SET STATE
    let filteredData;
    if (getProfileSuccess) {
      setLogoImageInfo({ ...data.logo, new: false });
      setCoverImageInfo({ ...data.cover, new: false });
      setNewPostcodes(false);

      const { welshFields = {} } = data;

      if (welshFields) {
        const formattedWelshFields = {
          nameWelsh: welshFields.name,
          descriptionWelsh: welshFields.description,
          addressesWelsh: welshFields.addresses,
          getInTouchDescriptionWelsh: welshFields.getInTouchDescription,
          activitiesRolesWelsh: welshFields.activitiesRoles,
          keywordsWelsh: welshFields.keywords,
        };

        filteredData = { ...data, ...formattedWelshFields };
        setWelshVersion(!!filteredData.nameWelsh || lang === "welsh");
      } else {
        filteredData = data;
      }

      setState(getCleanData({ ...filteredData }));

      setAddresses(
        formatAddresses(
          filteredData.addresses,
          filteredData.addressesWelsh || [],
        ),
      );
      setActivitiesRoles(
        formatActivitiesRoles(
          filteredData.activitiesRoles,
          filteredData.activitiesRolesWelsh || [],
        ),
      );

      setKeywords(data.keywords);
      setKeywordsWelsh(welshFields.keywords);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProfileSuccess]);
  useEffect(() => {
    if (coverImageInfo.uploadedToS3 || logoImageInfo.uploadedToS3) {
      if (coverImageInfo.new) {
        if (!coverImageInfo.uploadedToS3) {
          return;
        }
      }
      if (logoImageInfo.new) {
        if (!logoImageInfo.uploadedToS3) {
          return;
        }
      }
      setImagesReady(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverImageInfo.uploadedToS3, logoImageInfo.uploadedToS3]);

  useEffect(() => {
    if (updateProfileSuccess) {
      setIsNotificationOpen(true);
      setLogoImageInfo((_state) => ({
        ..._state,
        new: false,
        uploadedToS3: false,
      }));
      setCoverImageInfo((_state) => ({
        ..._state,
        new: false,
        uploadedToS3: false,
      }));
      setNewPostcodes(false);
      setStartUpload(false);
      setImagesReady(false);
      setGeocodesReady(false);
      getEarnGroupProfile(id);
      return resetUpdateEarnGroupProfile;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProfileSuccess]);

  useEffect(() => {
    if (geocodesReady && imagesReady) {
      setFetchingData(false);
      submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geocodesReady, imagesReady]);

  return (
    <S.Wrapper>
      <Notification
        open={isNotificationOpen}
        setOpen={setIsNotificationOpen}
        message={t("changesSaved", lang)}
        duration={1500}
      />
      <Row mb="2">
        <Col w={[4, 12, 12]}>
          <GoBack showText />
        </Col>
      </Row>
      <Row mb>
        <Col w={[4, 7.5, 7.5]}>
          {isMobile ? (
            <T.H30Caps color="primary">
              {t("recruitVolunteersPageHeader", lang)}
            </T.H30Caps>
          ) : (
            <T.H40Caps color="primary">
              {t("recruitVolunteersPageHeader", lang)}
            </T.H40Caps>
          )}
        </Col>
        <Col w={[0, 4, 4]}>
          <S.ImageContainer>
            <Image image="gardeningLarge" alt="gardening" />
          </S.ImageContainer>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 8, 8]}>
          <T.Body16R color="gray3" mt="20" mb="20">
            {t("provideDetailsAboutOrganisation", lang)}
          </T.Body16R>
        </Col>
      </Row>
      <Row>
        <Col
          w={[4, 10, 4]}
          style={{
            marginBottom: isMobile ? 0 : 30,
          }}
        >
          <S.WelshCheckWrapper style={{ marginBottom: welshVersion ? 10 : 20 }}>
            <Checkbox
              value={welshVersion}
              setValue={handleWalshCheckbox}
              label={t("addWelshVersion", lang)}
              name="welshVersion"
              size={isMobile ? "normal" : "medium"}
            />
          </S.WelshCheckWrapper>
        </Col>

        <Col w={[4, 4, 4]}>
          <S.InfoWrapper
            style={{ marginBottom: welshVersion && isMobile ? 0 : 40 }}
          >
            <Info
              header={
                lang === "english"
                  ? t("needHelpWithTranslation", lang)
                  : t("createEnglishVersionOfProfile", lang)
              }
              size="small"
              color="midnight"
              iconSize="34px"
            />
          </S.InfoWrapper>
        </Col>
      </Row>
      <Row mb={5}>
        <Col
          w={[4, 6, 4]}
          style={{ marginTop: isMobile && welshVersion && 40 }}
        >
          <InputField
            placeholder={`${t("typeYourOrg", lang)}...`}
            label={`${t("orgName", lang)}${
              lang === "welsh" ? ` (${t("english", lang)})` : ""
            }`}
            name="name"
            value={state.name}
            setValue={(e) => handleChange(e.target.name, e.target.value)}
            error={errors.name}
          />
        </Col>
        {welshVersion && (
          <Col w={[4, 6, 4]} style={{ marginTop: isMobile && "30px" }}>
            <InputField
              placeholder={`${t("typeYourOrg", lang)}...`}
              label={`${t("orgName", lang)}${
                lang === "english" ? ` (${t("welsh", lang)})` : ""
              }`}
              labelColor="blue"
              name="nameWelsh"
              value={state.nameWelsh}
              setValue={(e) => handleChange(e.target.name, e.target.value)}
              error={errors.nameWelsh}
            />
          </Col>
        )}
      </Row>
      <Row mb={8}>
        <Col w={[4, 6, 4]}>
          <S.FocusContainer>
            <Dropdown
              name="focusFields"
              options={
                lang === "english" ? focusDropDownEnglish : focusDropDownWelsh
              }
              value={
                state.focusFields &&
                state.focusFields.map((e) => ({
                  value: e,
                  label: lang === "english" ? e : focusValuesMapWelsh[e],
                }))
              }
              setValue={onFocusFieldsChange}
              label={t("focuses", lang)}
              placeholder={`${t("selectUpToThree", lang)}...`}
              error={errors.focusFields}
              isMulti
              allowSelectAll={false}
              fixedHeight={false}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              max={3}
              helper={t("upToThree", lang)}
              style={{ maxWidth: "100%" }}
            />
          </S.FocusContainer>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 6]}>
          <T.H20 color="primary" mb="20">
            {t("whereCanPeopleEarn", lang)}?
          </T.H20>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 6]}>
          <T.Body16R color="gray" mb="20">
            {t("addressDescription", lang)}?
          </T.Body16R>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 12]}>
          <Checkbox
            checkbox
            error={errors.isOnline}
            value={state.isOnline}
            setValue2={(checked) => handleChange("isOnline", checked)}
            label={t("weDontHaveAddress", lang)}
          />
        </Col>
      </Row>
      {!state.isOnline && (
        <Row mt="3">
          <Col w={[4, 12, 12]}>
            <Checkbox
              checkbox
              error={errors.isPrivateAddress}
              value={state.isPrivateAddress}
              setValue2={(checked) => handleChange("isPrivateAddress", checked)}
              label={t("hideFullAddress", lang)}
            />
          </Col>
        </Row>
      )}
      {/* here should be a map for number of locations  */}
      {!state.isOnline && addresses && addresses.length > 0 && (
        <LocationWrapper
          addresses={addresses}
          setAddresses={setAddresses}
          setNewPostcodes={setNewPostcodes}
          isOptional={["addressLine2", "addressLine2Welsh"]}
          welshVersion={welshVersion}
          errors={errors}
          setErrors={setErrors}
        />
      )}
      {/* Tell potential volunteers about your organisation */}
      <Row mb={5} mt={5}>
        <Col w={[4, 6, 6]}>
          <InputField
            name="description"
            type="textarea"
            label={`${t("tellPotentialVolunteersAboutOrg", lang)}${
              lang === "welsh" ? ` (${t("english", lang)})` : ""
            }`}
            placeholder={`${t("typeHere", lang)}...`}
            limit={300}
            showLimit
            value={state.description}
            setValue={(e) => handleChange(e.target.name, e.target.value)}
            error={errors.description}
            lang={lang}
          />
        </Col>
        {welshVersion && (
          <Col w={[4, 6, 6]} style={{ marginTop: isMobile && "30px" }}>
            <InputField
              name="descriptionWelsh"
              type="textarea"
              label={`${t("tellPotentialVolunteersAboutOrg", lang)}${
                lang === "english" ? ` (${t("welsh", lang)})` : ""
              }`}
              placeholder={`${t("typeHere", lang)}...`}
              labelColor="blue"
              limit={300}
              showLimit
              value={state.descriptionWelsh}
              setValue={(e) => handleChange(e.target.name, e.target.value)}
              error={errors.descriptionWelsh}
              lang={lang}
            />
          </Col>
        )}
      </Row>
      <Row mb={8}>
        <Col w={[4, 6, 6]}>
          <InputField
            placeholder={`${t("earnKeywordsExamples", lang)}...`}
            label={`${t("earnKeywordsLabel", lang)}${
              lang === "welsh" ? ` (${t("english", lang)})` : ""
            }`}
            name="keywords"
            isOptional
            lang={lang}
            type="textarea"
            value={keywords && keywords.toString()}
            setValue={(e) => handleKeywords(e, "english")}
            error={errors.keywords}
            justify="center"
          />
        </Col>

        {welshVersion && (
          <Col w={[4, 6, 6]}>
            <InputField
              placeholder={`${t("earnKeywordsExamples", lang)}...`}
              label={`${t("earnKeywordsLabel", lang)}${
                lang === "english" ? ` (${t("welsh", lang)})` : ""
              }`}
              name="keywordsWelsh"
              isOptional
              lang={lang}
              type="textarea"
              value={keywordsWelsh && keywordsWelsh.toString()}
              setValue={(e) => handleKeywords(e, "welsh")}
              error={errors.keywordsWelsh}
              justify="center"
              labelColor="blue"
            />
          </Col>
        )}
      </Row>
      <Row mb={5}>
        <Col w={[4, 6, 6]}>
          <T.H20 color="midnight">
            {t("howShouldPeopleGetInTouch", lang)}?
          </T.H20>
        </Col>
      </Row>
      <Row>
        {/* contact Number */}
        <Col w={[4, 6, 4]}>
          <S.FiledWrapper>
            <InputField
              placeholder={t(`${t("typePhoneNumber", lang)}...`)}
              label={t("contactNumber", lang)}
              name="contactNumber"
              value={state.contactNumber}
              setValue={(e) => handleChange(e.target.name, e.target.value)}
              isOptional
              lang={lang}
              error={errors.contactNumber}
              type="tel"
            />
          </S.FiledWrapper>
        </Col>
        {/* contactEmail */}
        <Col w={[4, 6, 4]}>
          <S.FiledWrapper>
            <InputField
              placeholder={t(`${t("typeEmailHere", lang)}...`)}
              label={t("contactEmail", lang)}
              name="contactEmail"
              value={state.contactEmail}
              setValue={(e) => handleChange(e.target.name, e.target.value)}
              error={errors.contactEmail}
            />
          </S.FiledWrapper>
        </Col>
        <Col w={[4, 6, 6]}>
          <InputField
            name="getInTouchDescription"
            type="textarea"
            label={`${t("anythingElse", lang)}${
              lang === "welsh" ? ` (${t("english", lang)})` : ""
            }?`}
            placeholder={`${t("typeHere", lang)}...`}
            limit={300}
            showLimit
            value={state.getInTouchDescription}
            setValue={(e) =>
              handleChange("getInTouchDescription", e.target.value)
            }
            lang={lang}
            error={errors.getInTouchDescription}
          />
        </Col>
        {welshVersion && (
          <Col w={[4, 6, 6]} style={{ marginTop: isMobile && "30px" }}>
            <InputField
              name="getInTouchDescriptionWelsh"
              type="textarea"
              label={`${t("anythingElse", lang)}${
                lang === "english" ? ` (${t("welsh", lang)})` : ""
              }?`}
              placeholder={`${t("typeHere", lang)}...`}
              labelColor="blue"
              limit={300}
              showLimit
              value={state.getInTouchDescriptionWelsh}
              setValue={(e) =>
                handleChange("getInTouchDescriptionWelsh", e.target.value)
              }
              lang={lang}
              error={errors.getInTouchDescriptionWelsh}
            />
          </Col>
        )}
      </Row>
      <Row>
        {/* websiteUrl */}
        <Col w={[4, 6, 4]}>
          <S.FiledWrapper>
            <InputField
              placeholder={`${t("typeUrlHere", lang)}...`}
              label={t("websiteSignUpLink", lang)}
              name="websiteUrl"
              value={state.websiteUrl}
              setValue={(e) => handleChange(e.target.name, e.target.value)}
              isOptional
              lang={lang}
              error={errors.websiteUrl}
            />
          </S.FiledWrapper>
        </Col>

        <Col w={[4, 6, 4]}>
          <S.FiledWrapper>
            <InputField
              placeholder={`${t("typeFacebookHere", lang)}...`}
              label={t("facebookLink", lang)}
              name="socialLinks"
              value={state.socialLinks && state.socialLinks.facebook}
              setValue={(e) =>
                handleChange(e.target.name, {
                  twitter: state.socialLinks && state.socialLinks.twitter,
                  facebook: e.target.value,
                })
              }
              isOptional
              lang={lang}
              error={errors.socialLinks && errors.socialLinks.facebook}
            />
          </S.FiledWrapper>
        </Col>
        <Col w={[4, 6, 4]}>
          <S.FiledWrapper>
            <InputField
              placeholder={`${t("typeTwitterHere", lang)}...`}
              label={t("twitterLink", lang)}
              name="socialLinks"
              value={state.socialLinks && state.socialLinks.twitter}
              setValue={(e) =>
                handleChange(e.target.name, {
                  facebook: state.socialLinks && state.socialLinks.facebook,
                  twitter: e.target.value,
                })
              }
              lang={lang}
              isOptional
              error={errors.socialLinks && errors.socialLinks.twitter}
            />
          </S.FiledWrapper>
        </Col>
      </Row>
      <Row mb={7} mt={5}>
        <Col w={[4, 6, 4]}>
          <UploadImage
            placeholder={t("dragLogoHere", lang)}
            setImageInfo={setLogoImageInfo}
            imageInfo={logoImageInfo}
            startUpload={startUpload}
            imageType="logo"
            maxSize={2e6}
            maxSizeText="2MB"
            secondaryText={t("fileMaxSize", lang, [2])}
            error={errors.logoImageKey}
          />
        </Col>
        <Col w={[4, 6, 4]}>
          <UploadImage
            placeholder={t("dragImageHere", lang)}
            setImageInfo={setCoverImageInfo}
            imageInfo={coverImageInfo}
            startUpload={startUpload}
            imageType="logo"
            maxSize={2e6}
            maxSizeText="2MB"
            secondaryText={`${t("minDimensions", lang, ["820 x 280"])}, ${t(
              "fileMaxSize",
              lang,
              [2],
            )}`}
            error={errors.coverImageKey}
          />
        </Col>
      </Row>
      <ActivitiesSection
        publicActivities={state.publicActivities}
        setPublicActivities={(value) => handleChange("publicActivities", value)}
        error={errors.publicActivities}
        lang={lang}
      />{" "}
      <Row mt={8}>
        <Col w={[4, 6, 12]}>
          <T.H20 style={{ display: "inline" }} color="primary">
            {t("addSpecificActivities", lang)}
          </T.H20>
          <T.Body14S style={{ display: "inline" }} ml="10" color="gray3">
            ({t("optional", lang)})
          </T.Body14S>
          <T.Body16R color="gray3" mt={10}>
            {t("egBreakfastClub", lang)}
          </T.Body16R>
        </Col>
      </Row>
      <SpecificActivities
        activitiesRoles={activitiesRoles}
        setActivitiesRoles={setActivitiesRoles}
        errors={errors}
        welshVersion={welshVersion}
        isMobile={isMobile}
      />
      <S.SubmitWrapper>
        <Row>
          <Col w={[4, 6, 12]}>
            <Checkbox
              checkbox
              name="isPublic"
              value={state.isPublic}
              setValue2={(value) => handleChange("isPublic", value)}
              label={t("tickToMakePublic", lang)}
              size={isMobile ? "normal" : "medium"}
            />
          </Col>
        </Row>
        <Row mt={6}>
          <Col w={[4, 6, 4]}>
            {error && error.message && (
              <S.Error>{t(error.message, lang)}</S.Error>
            )}
            {errors.anyErrors && <S.Error>{errors.anyErrors}</S.Error>}
            <Button
              primary
              bgColor="blue"
              onClick={handleClick}
              loading={updateProfileLoading || fetchingData}
            >
              {t("saveChanges", lang)}
            </Button>
          </Col>
        </Row>
        {data && data.isPublic && (
          <Row mt={1}>
            <Col w={[4, 6, 4]}>
              <Button
                outline
                onClick={() =>
                  window.open(GENERAL.EARN_GROUP.replace(":id", id), "_blank")
                }
              >
                {t("viewProfile", lang)}
              </Button>
            </Col>
          </Row>
        )}
      </S.SubmitWrapper>
    </S.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  data: state.updateEarnGroupProfile.data,
  getProfileSuccess: state.updateEarnGroupProfile.getProfileSuccess,
  updateProfileLoading: state.updateEarnGroupProfile.updateProfileLoading,
  updateProfileSuccess: state.updateEarnGroupProfile.updateProfileSuccess,
  error: state.updateEarnGroupProfile.updateProfileError,
  lang: state.auth.decideLanguage(state.auth),
});

const mapActionsToProps = {
  getEarnGroupProfile: actions.getEarnGroupProfile,
  updateEarnGroupProfile: actions.updateEarnGroupProfile,
  resetUpdateEarnGroupProfile: actions.resetUpdateEarnGroupProfile,
  getLocationAction: getLocation,
};

export default connect(mapStateToProps, mapActionsToProps)(RecruitVolunteers);
