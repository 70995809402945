import {
  CHECK_ACTIVATE_ACCOUNT_TOKEN_LOADING,
  CHECK_ACTIVATE_ACCOUNT_TOKEN_SUCCESS,
  CHECK_ACTIVATE_ACCOUNT_TOKEN_FAIL,
  GET_ACTIVATE_ACCOUNT_TOKEN_LOADING,
  GET_ACTIVATE_ACCOUNT_TOKEN_SUCCESS,
  GET_ACTIVATE_ACCOUNT_TOKEN_FAIL,
  RESEND_ACTIVATE_ACCOUNT_TOKEN_LOADING,
  RESEND_ACTIVATE_ACCOUNT_TOKEN_SUCCESS,
  RESEND_ACTIVATE_ACCOUNT_TOKEN_FAIL,
} from "../../../redux/types";

const initState = {
  loading: true,
  resendLoading: false,
  error: {},
  data: {},
  token: {},
};

const activateAccountReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CHECK_ACTIVATE_ACCOUNT_TOKEN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CHECK_ACTIVATE_ACCOUNT_TOKEN_SUCCESS:
      return {
        ...state,
        users: payload,
        loading: false,
      };
    case CHECK_ACTIVATE_ACCOUNT_TOKEN_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case GET_ACTIVATE_ACCOUNT_TOKEN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ACTIVATE_ACCOUNT_TOKEN_SUCCESS:
      return {
        ...state,
        token: payload,
        loading: false,
      };
    case GET_ACTIVATE_ACCOUNT_TOKEN_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case RESEND_ACTIVATE_ACCOUNT_TOKEN_LOADING:
      return {
        ...state,
        resendLoading: true,
      };
    case RESEND_ACTIVATE_ACCOUNT_TOKEN_SUCCESS:
      return {
        ...state,
        token: payload,
        resendLoading: false,
      };
    case RESEND_ACTIVATE_ACCOUNT_TOKEN_FAIL:
      return {
        ...state,
        error: payload,
        resendLoading: false,
      };

    default:
      return state;
  }
};

export default activateAccountReducer;
