import React from "react";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { logout } from "../../../../../redux/actions/auth";

import { Row, Col } from "../../../../../components/Grid";
import { Regular } from "../../../../../components/Button";
import * as T from "../../../../../components/Typograpy";
import * as S from "./style";

import { GENERAL } from "../../../../../constants/navRoutes";

import translate from "../../../../../utils/helpers/translator";

const CreateAccount = ({ lang, logoutAction, loggedIn }) => {
  const history = useHistory();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div style={{ marginBottom: isMobile ? "60px" : "120px" }}>
      <Row>
        <Col w={[4, 8, 8]}>
          <T.Body16B color="pink">
            {translate("mustHaveAccount", lang)}
          </T.Body16B>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 6, 6]}>
          <Regular
            primary
            bgColor="blue"
            mt={30}
            mb={10}
            onClick={() => history.push(GENERAL.SIGN_UP)}
          >
            {translate("createAccount", lang)}
          </Regular>
          <S.LogInWrapper>
            <T.Body14R
              color="gray3"
              m="0 5px 0 0"
              // style={{ textAlign: "center" }}
            >
              {translate("alreadyHaveAccount", lang)}?{" "}
              {loggedIn ? (
                <S.StyledLink to="#" onClick={() => logoutAction({ history })}>
                  {translate("logIn", lang)}
                </S.StyledLink>
              ) : (
                <S.StyledLink to={GENERAL.LOG_IN}>
                  {translate("logIn", lang)}
                </S.StyledLink>
              )}
            </T.Body14R>
          </S.LogInWrapper>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: state.auth.isLoggedIn,
});

const mapActionToProps = {
  logoutAction: logout,
};

export default connect(mapStateToProps, mapActionToProps)(CreateAccount);
