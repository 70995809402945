import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";

import * as S from "./style";
import * as T from "../../../../components/Typograpy";
import Button from "../../../../components/Button";
import { Dropdown, InputField, Checkbox } from "../../../../components/Inputs";
import GoBack from "../../../../components/GoBack";
import { Row, Col } from "../../../../components/Grid";
import * as actions from "./actions";
import { roles } from "../../../../constants";
import t from "../../../../utils/helpers/translator";

const useQuery = () => new URLSearchParams(useLocation().search);

const IssueCredits = ({
  getCreditsRequestById,
  action,
  issueTimeCredits,
  issueTCLoading,
  issueError,
  // selectedRequest,
  toRole,
  getUserInfo,
  //
  requestId,
  creditsRequested,
  recipientName,
  recipientID,
  programmeName,
  programmeId,
  programmes,
  role,
  programmeManagerProgrammeId,
  resetState,
  isTkRequest,
}) => {
  const [exchange, setExchange] = useState(false);
  const [credits, setCredits] = useState(0);
  const [message, setMessage] = useState("");
  const [issueFrom, setIssueFrom] = useState({
    label: "No programme",
    value: null,
  });
  const { requestId: requestIdParams, userId } = useParams();
  const history = useHistory();
  const query = useQuery();

  const showProgramme = query.get("showProgramme");

  useEffect(() => {
    if (requestIdParams && !requestId) {
      getCreditsRequestById({ id: requestIdParams });
    } else if (userId) {
      getUserInfo({ id: userId, role: toRole });
    }

    return resetState;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (programmeId) {
      setIssueFrom({ label: programmeName, value: programmeId });
    }
    if (action === "approve") {
      return setCredits(creditsRequested || 0);
    }
  }, [action, creditsRequested, programmeName, programmeId]);

  const handleAmount = (e) => {
    setCredits(e.target.value.replace(/^0+/, ""));
  };

  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const handleProgramme = (e) => {
    setIssueFrom(e.selected);
  };

  const readyToSend = () => {
    if (action === "reject") {
      if (message) return true;
      return false;
    }
    if (credits > 0) return true;
    return false;
  };

  const onSubmit = () => {
    let status;

    switch (action) {
      case "approve":
        status = "approved";
        break;
      case "reject":
        status = "rejected";
        break;
      case "partially approve":
        status = "partiallyApproved";
        break;
      case "issue":
        status = "issue";
        break;
      default:
        break;
    }

    issueTimeCredits({
      id: requestIdParams || requestId,
      status,
      tc: credits || 0,
      toUserId: userId || recipientID, // TODO handle the issue case
      message,
      isWithoutRequest: !!requestId && !userId,
      name: recipientName,
      history,
      programmeId:
        (role === roles.PROGRAMME_MANAGER && programmeManagerProgrammeId) ||
        issueFrom.value,
      role,
      exchange,
      toRole,
    });
  };

  let programmeOptions = [
    { label: "No programme", value: null },
    { label: programmeName, value: programmeId },
  ];

  if (toRole === "member") {
    programmeOptions = [
      { label: "No programme", value: null },
      ...programmes.map((p) => ({ label: p.name, value: p.id })),
    ];
  }

  return (
    <S.Wrapper>
      <S.Questions reject={action === "reject"}>
        <Row mb={5}>
          <Col w={[4, 12, 12]}>
            <GoBack showText />
          </Col>
        </Row>
        <Row mb={5}>
          <Col w={[4, 12, 12]}>
            {action !== "reject" && (
              <InputField
                type="number"
                label="Enter amount of Time Credits"
                placeholder="Enter number..."
                value={!!credits && credits}
                setValue={handleAmount}
                min={1}
                inputProps={{
                  min: 1,
                }}
              />
            )}
          </Col>
        </Row>
        {showProgramme === "true" && (
          <Row mb={5}>
            <Col w={[4, 12, 12]}>
              {action !== "reject" && (
                <Dropdown
                  label="Select Programme"
                  options={programmeOptions}
                  value={issueFrom}
                  isOptional
                  placeholder="Select your programme..."
                  setValue={handleProgramme}
                  disabled={(!programmeName || !programmeId) && !programmes[0]}
                />
              )}
            </Col>
          </Row>
        )}
        <Row mb={action === "issue" ? 4 : 8}>
          <Col w={[4, 12, 12]}>
            <InputField
              type="textarea"
              label="Send them a message"
              placeholder="Type your message here..."
              value={message}
              setValue={handleMessage}
              isOptional={action !== "reject"}
            />
          </Col>
        </Row>
        {action === "issue" && (
          <Row mb={5}>
            <Col w={[4, 12, 12]}>
              <Checkbox
                checkboxSize="large"
                value={exchange}
                setValue={(e) => setExchange(e.target.checked)}
                name="agreedOnTerms"
                label={
                  <T.Body16B color="midnight">
                    This is a paper Time Credit exchange
                  </T.Body16B>
                }
              />
            </Col>
          </Row>
        )}

        <Row mb={5}>
          <Col w={[4, 12, 12]}>
            {issueError && issueError.message && (
              <S.Error>{t(issueError.message, "english")}</S.Error>
            )}
            {!readyToSend() || issueTCLoading ? (
              <Button disabled>Confirm</Button>
            ) : (
              <Button primary onClick={onSubmit} loading={issueTCLoading}>
                Confirm
              </Button>
            )}
          </Col>
        </Row>
      </S.Questions>
      {action === "reject" ? (
        <S.SideContent>
          <T.H24 color="white">
            You are about to reject a request for Time Credits by{" "}
            {recipientName}
          </T.H24>
          {isTkRequest && (
            <T.Body12R color="white">Team Kinetic Request</T.Body12R>
          )}
        </S.SideContent>
      ) : (
        <S.SideContent>
          <T.H24 color="white" mb={20}>
            You are about to issue
          </T.H24>
          <S.CreditWrapper>
            <T.H60 color="white">{credits || "TBC"}</T.H60>
            <T.H40Caps color="white" mb={5} ml={10}>
              TIME CREDITS
            </T.H40Caps>
          </S.CreditWrapper>
          <T.H24 color="white">to {recipientName}</T.H24>
          {isTkRequest && (
            <T.Body12R color="white">Team Kinetic Request</T.Body12R>
          )}
        </S.SideContent>
      )}
    </S.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  role: state.auth.role,
  programmeManagerProgrammeId: state.auth.programmeId,
  requestId: state.staffCreditRequests.requestId,
  creditsRequested: state.staffCreditRequests.creditsRequested,
  recipientName: state.staffCreditRequests.recipientName,
  recipientID: state.staffCreditRequests.recipientID,
  issueTCLoading: state.staffCreditRequests.issueTcLoading,
  selectedRequest: state.staffCreditRequests.selectedRequest,
  programmeName: state.staffCreditRequests.programmeName,
  programmeId: state.staffCreditRequests.programmeId,
  programmes: state.staffCreditRequests.programmes,
  issueError: state.staffCreditRequests.issueError,
  isTkRequest: state.staffCreditRequests.isTkRequest,
});

const mapActionToProps = {
  issueTimeCredits: actions.issueTimeCredits,
  getCreditsRequestById: actions.getCreditsRequestById,
  getUserInfo: actions.getUserInfo,
  resetState: actions.resetState,
};

export default connect(mapStateToProps, mapActionToProps)(IssueCredits);
