import React from "react";

const ArrowLink = ({ width, height, color, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    {...props}
  >
    <circle
      cx="12"
      cy="12"
      r="12"
      transform="rotate(90 12 12)"
      fill={color || "currentColor"}
    />
    <path
      d="M12.358 7.705L16.653 12l-4.295 4.295M16.058 12h-8.71"
      stroke="#fff"
      strokeWidth="1.313"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ArrowLink;
