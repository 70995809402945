import { styled } from "@material-ui/core/styles";

export const Wrapper = styled("div")(({ w, m }) => ({
  display: "flex",
  height: "100%",
  width: w || "100%",
  alignItems: "center",
  position: "relative",
  margin: m || "0",
}));

export const Line = styled("span")(({ theme }) => ({
  width: "5px",
  position: "absolute",
  height: "100%",
  background: theme.gradients.pink,
  borderRadius: theme.borders.radiusSmall,
  minHeight: "48px",
  marginRight: theme.spacing(3),
  flex: "0 1 auto",
}));

export const Details = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.mobileXL]: {
    display: "flex",
    flexDirection: "row",
  },
}));

export const Time = styled("div")(({ theme }) => ({
  alignSelf: "flex-start",
  paddingTop: "2px",
  marginRight: theme.spacing(3),
  display: "flex",
  flexDirection: "row",

  [theme.breakpoints.mobileXL]: {
    flexDirection: "column",
  },
}));

export const Body = styled("div")(() => ({
  alignSelf: "flex-start",
}));
