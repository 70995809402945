import { styled } from "@material-ui/core/styles";

export const Wrapper = styled("section")(() => ({
  position: "relative",
  flex: "1",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export const Progress = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  alignItems: "center",
  // position: "absolute",
  bottom: "0",
  left: "0",

  [theme.breakpoints.mobileL]: {
    flexDirection: "column",
    justifyContent: "flex-end",
    paddingBottom: theme.spacing(5),
    maxWidth: "350px",
    margin: "0 auto",

    "& button": {
      marginTop: theme.spacing(3),
    },
  },
}));

export const Body = styled("div")(({ theme }) => ({
  flex: "1",
  display: "flex",
  flexDirection: "column",
  height: "50%",

  [theme.breakpoints.mobileL]: {
    alignItems: "center",
    textAlign: "center",
    paddingTop: theme.spacing(1),
    maxWidth: "350px",
    margin: "0 auto",

    "& p": {
      marginTop: theme.spacing(3),
    },
  },

  [theme.breakpoints.tablet]: {
    alignItems: "center",
    textAlign: "center",
    paddingTop: theme.spacing(1),
    maxWidth: "350px",

    "& p": {
      marginTop: theme.spacing(3),
    },
  },
}));
