import React from "react";

import { TEMPO } from "../../../../constants/navRoutes";

import * as S from "./style";
import * as T from "../../../../components/Typograpy";

import GoBack from "../../../../components/GoBack";
import ProgrammeSection from "../../../../components/Cards/ProgrammeSection";
import { Row, Col } from "../../../../components/Grid";
import TCBalance from "../../../../components/TCBalance";
import Button from "../../../../components/Button";

const TopSection = ({
  id,
  balance,
  name,
  earnGroups,
  members,
  spendVenues,
  transactions,
  exportCSV,
  justCreated,
  history,
  loading,
}) => (
  <>
    <S.Head>
      <Row>
        <Col w={[4, 8, 8]}>
          <GoBack showText p="0 0 20px 0" />
          <T.H40Caps color="midnight" mb="20">
            {name}
          </T.H40Caps>
        </Col>
        <Col
          w={[4, 4, 4]}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
        >
          <TCBalance color="cyan" credits={balance} loading={loading} />
        </Col>
      </Row>
    </S.Head>
    {justCreated && (
      <>
        <Row>
          <Col w={[4, 8, 8]}>
            <T.H24 color="pink" mb="10">
              Successfully created!
            </T.H24>
            <T.Body16B5 color="midnight" mb="20">
              Now you need to add some Earn Groups to your programme
            </T.Body16B5>
          </Col>
        </Row>
        <Row mb={8}>
          <Col w={[4, 6, 3]}>
            <Button
              primary
              m="0"
              mr="0"
              size="l"
              onClick={() =>
                history.push(TEMPO.PROGRAMME_INVITE_GROUPS.replace(":id", id))
              }
            >
              Add Earn Group
            </Button>
          </Col>
        </Row>
      </>
    )}

    <Row mb={3}>
      <Col w={[4, 6, 3]}>
        <ProgrammeSection
          sectionLink={() => TEMPO.PROGRAMME_EARN_GROUPS.replace(":id", id)}
          stat={earnGroups}
          loading={loading}
          section="earn groups"
          type="earn-groups"
          sectionPromo="view earn groups"
        />
      </Col>
      <Col w={[4, 6, 3]}>
        <ProgrammeSection
          download
          downloadFunction={exportCSV}
          stat={members}
          loading={loading}
          section="volunteers Reached"
          type="members"
          sectionPromo="export volunteers"
          m="0 0 20px 0"
        />
      </Col>
      <Col w={[4, 6, 3]}>
        <ProgrammeSection
          download
          downloadFunction={exportCSV}
          stat={spendVenues}
          loading={loading}
          section="Venues"
          type="spend-venues"
          sectionPromo="Export Venues"
          m="0 0 20px 0"
        />
      </Col>
      <Col w={[4, 6, 3]}>
        <ProgrammeSection
          download
          downloadFunction={exportCSV}
          stat={transactions}
          loading={loading}
          section="transactions"
          type="transactions"
          sectionPromo="Export History"
          m="0 0 20px 0"
        />
      </Col>
    </Row>
  </>
);

export default TopSection;
