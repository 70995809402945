import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import * as S from "../style";

import { Body16R } from "../../../../../../components/Typograpy";
import { LANGUAGE_ERROR } from "../../../../../../validation/err-msgs";
import t from "../../../../../../utils/helpers/translator";

import Content from "./Content";

const BookingCodesSuccess = ({
  isTablet,
  bookingCodes,
  useCodesLink,
  codesExtraDetails,
  getUserCurrentTcAction,
  welshVersion,
  codesExtraDetailsWelsh,
  language,
}) => {
  const [filteredCodes, setFilteredCodes] = useState([]);
  const [pageState, setPageState] = useState(null);

  const codes = bookingCodes && bookingCodes.map(({ code }) => code);

  useEffect(() => {
    // determine whether we are showing just one code or all of them
    window.scrollTo(0, 0);
    if (codes.length === 1) {
      setFilteredCodes(codes);
    } else setFilteredCodes(codes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // update user account balance
  useEffect(() => {
    getUserCurrentTcAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (welshVersion && language === "welsh") {
      setPageState({
        languageError: null,
        codeDetailes: codesExtraDetailsWelsh,
      });
    } else if (!welshVersion && language === "welsh") {
      setPageState({
        codeDetailes: codesExtraDetails,
        languageError: t(LANGUAGE_ERROR, language),
      });
    } else {
      setPageState({ languageError: null, codeDetailes: codesExtraDetails });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.event({
        category: "Activity",
        action: "Member spent TCs on booking code",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <S.OptionWrapper>
      {pageState && pageState.languageError && (
        <Body16R color="pink">{pageState.languageError}</Body16R>
      )}
      <Content
        isMobile={isMobile}
        isTablet={isTablet}
        filteredCodes={filteredCodes}
        codesExtraDetails={pageState && pageState.codeDetailes}
        useCodesLink={useCodesLink}
        lang={welshVersion ? language : "english"}
      />
    </S.OptionWrapper>
  );
};

export default BookingCodesSuccess;
