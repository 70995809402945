import React from "react";

const ChangeArrows = ({ width, height, color, ...props }) => (
  <svg
    width={width || "24"}
    height={height || "18"}
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.7375 7.75L0.75 12.75L5.7375 17.75V14H14.5V11.5H5.7375V7.75ZM23.25 5.25L18.2625 0.25V4H9.5V6.5H18.2625V10.25L23.25 5.25Z"
      fill={color}
    />
  </svg>
);
export default ChangeArrows;
