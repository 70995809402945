import React from "react";

const DownloadOutline = ({ width, height, color, background, ...props }) => (
  <svg
    width={width}
    height={height}
    {...props}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 11H23.5C24.163 11 24.7989 11.2634 25.2678 11.7322C25.7366 12.2011 26 12.837 26 13.5V26.5C26 27.163 25.7366 27.7989 25.2678 28.2678C24.7989 28.7366 24.163 29 23.5 29H8.5C7.83696 29 7.20107 28.7366 6.73223 28.2678C6.26339 27.7989 6 27.163 6 26.5V13.5C6 12.837 6.26339 12.2011 6.73223 11.7322C7.20107 11.2634 7.83696 11 8.5 11H11"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 17L16 22L21 17"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 3V21"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DownloadOutline;
