import React, { useState, useEffect, useMemo } from "react";

import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import PopPassword from "../../PopPassword";

import * as T from "../../Typograpy";
import t from "../../../utils/helpers/translator";

import Icon from "../../Icon";
import useStyles from "../style";

const decideIcon = (
  type,
  handleClickShowPassword,
  handleMouseDownPassword,
  clearFunc,
  value,
  disabled,
) => {
  if (type === "password") {
    return (
      <InputAdornment position="center" style={{ width: "40px" }}>
        <IconButton
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          aria-label="toggle password visibility"
        >
          <Icon width="20px" icon="eye" />
        </IconButton>
      </InputAdornment>
    );
  }

  if (typeof clearFunc === "function" && value && !disabled) {
    return (
      <InputAdornment position="center" style={{ width: "40px" }}>
        <IconButton onClick={clearFunc} aria-label="toggle password visibility">
          <Icon width="28px" icon="close" color="pink" />
        </IconButton>
      </InputAdornment>
    );
  }
  return null;
};

const InputField = (props) => {
  const {
    // input type: number, text, textarea, email, tel, or password
    type = "text",
    // placeholder text
    placeholder,
    // label above box
    label,
    // error for validation render
    error,
    // normal, large
    size,
    // get functions to set value and value itself from parent component
    // TODO replace setValue with setValue 2
    setValue,
    setValue2,
    value,
    justify = "center",
    autoFocus,
    isOptional = false,
    helper = "",
    limit,
    showLimit,
    showPasswordPop,
    lang = "english",
    clearFunc,
  } = props;
  let _helper = helper;
  if (limit && !helper) {
    _helper = t("maxCharacters", lang, [300]);
  }

  const onChange = (e) => {
    if (setValue2 instanceof Function) setValue2(e.target.value);
    if (setValue instanceof Function) setValue(e);
  };
  //  password show / hide UI
  const [showPassword, setShowPassword] = useState(false);

  // set error for length validation
  const [textTooLong, setTextTooLong] = useState(false);

  useEffect(() => {
    if (limit) {
      if (!textTooLong && value && value.length > limit) {
        setTextTooLong(true);
      } else if (textTooLong && value && value.length <= limit) {
        setTextTooLong(false);
      }
    }
  }, [limit, value, textTooLong]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const classes = useStyles({ ...props, size, error, textTooLong });

  // renders typography for labels above box
  const renderLabel = {
    normal: (
      <div style={{ position: "relative", display: "flex" }}>
        <T.Body16B
          className={classes.textInputLabel}
          style={{
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {label}
          {!!_helper && (
            <T.Body14R color="gray3" style={{ display: "block" }}>
              {" "}
              ({_helper})
            </T.Body14R>
          )}
        </T.Body16B>
        {showPasswordPop && (
          <PopPassword>
            <div>
              <T.Body12B color="midnight">
                {t("passwordContain", lang)}
              </T.Body12B>
              {[
                `- ${t("min8characters", lang)}`,
                `- ${t("capitalLetter", lang)}`,
                `- ${t("lowercaseLetter", lang)}`,
                `- ${t("oneNumber", lang)}`,
              ].map((elm) => (
                <T.Body12R key={elm} color="gray3">
                  {elm}
                </T.Body12R>
              ))}
            </div>
          </PopPassword>
        )}
      </div>
    ),
    large: (
      <T.Body20B
        className={classes.textInputLabel}
        style={{
          fontSize: "1.25rem",
        }}
      >
        {label}
      </T.Body20B>
    ),
  };

  // selects input type
  const inputTypes = {
    text: "text",
    password: showPassword === true ? "text" : "password",
    email: "email",
    number: "number",
    textarea: "textarea",
    tel: "tel",
  };

  const textfieldTypes = [
    "text",
    "textarea",
    "email",
    "password",
    "number",
    "tel",
  ];

  const inputProps = useMemo(
    () => ({
      classes: {
        root: classes.input,
        input: classes.inputPlaceholder,
        autoFocus: !!autoFocus,
      },
      // deals with password visibility
      endAdornment: decideIcon(
        type,
        handleClickShowPassword,
        handleMouseDownPassword,
        clearFunc,
        value,
        props.disabled,
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [autoFocus, type, value],
  );

  const renderTextField = (
    <TextField
      {...props}
      error={!!error || textTooLong}
      type={inputTypes[type]}
      value={value}
      onChange={onChange}
      variant="outlined"
      multiline={type === "textarea"}
      rows={type === "textarea" && 4}
      placeholder={placeholder}
      label=""
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={inputProps}
      onWheel={type === "number" ? (event) => event.target.blur() : undefined}
    />
  );

  return (
    <Grid
      container
      direction="column"
      justify={justify}
      alignItems="flex-start"
      className={classes.root}
    >
      {label && (
        <label
          htmlFor="TextInput"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {renderLabel[size || "normal"]}
          {isOptional && (
            <T.Body14R
              color="gray3"
              style={{
                margin: "0 10px 5px 0",
                alignSelf: "flex-end",
              }}
            >
              ({t("optional", lang)})
            </T.Body14R>
          )}
        </label>
      )}

      {textfieldTypes.includes(type) && renderTextField}
      {showLimit && (
        <T.Body14B className={classes.maxLength}>
          {value ? value.length : 0} {t("charactersUsed", lang)}
        </T.Body14B>
      )}
      {error && (
        <T.Body14B className={classes.textFieldError}>{error}</T.Body14B>
      )}
    </Grid>
  );
};

// export default React.memo(InputField, (pProps, nProps) => {
//   const allPropsKeys = [
//     "type",
//     "placeholder",
//     "label",
//     "error",
//     "size",
//     "value",
//     "justify",
//     "autoFocus",
//     "isOptional",
//     "helper",
//     "limit",
//     "showLimit",
//     "showPasswordPop",
//     "lang",
//     "labelColor",
//     "mt",
//     "mb",
//     "ml",
//     "mr",
//     "textTooLong",
//     "disabled",
//   ];
//   return compareProps(pProps, nProps, allPropsKeys);
// });

export default InputField;
