import { keys as keysEnglish } from "./authoritiesEnglish";

const authoritiesWelsh = {
  ABERDEEN_CITY: "Aberdeen City",
  ABERDEENSHIRE: "Aberdeenshire",
  ADUR: "Adur",
  ALLERDALE: "Allerdale",
  AMBER_VALLEY: "Amber Valley",
  ANGUS: "Angus",
  ANTRIM_AND_NEWTOWNABBEY: "Antrim a Newtonabbey",
  ARDS_AND_NORTH_DOWN: "Ards a North Down",
  ARGYLL_AND_BUTE: "Argylle a Bute",
  ARMAGH_CITY_BANBRIDGE_AND_CRAIGAVON: "Dinas Armagh, Banbridge a Craigavon",
  ARUN: "Arun",
  ASHFIELD: "Ashfield",
  ASHFORD: "Ashford",
  AYLESBURY_VALE: "Aylesbury Vale",
  BABERGH: "Babergh",
  BARKING_AND_DAGENHAM: "Barking a Dagenham",
  BARNET: "Barnet",
  BARNSLEY: "Barnsley",
  BARROW_IN_FURNESS: "Barrow-in-Furness",
  BASILDON: "Basildon",
  BASINGSTOKE_AND_DEANE: "Basingstoke a Deane",
  BASSETLAW: "Bassetlaw",
  BATH_AND_NORTH_EAST_SOMERSET: "Caerfaddon a Gogledd Ddwyrain Gwlad yr Hâf",
  BEDFORD: "Bedford",
  BELFAST: "Belfast",
  BEXLEY: "Bexley",
  BIRMINGHAM: "Birmingham",
  BLABY: "Blaby",
  BLACKBURN_WITH_DARWEN: "Blackburn gyda Darwen",
  BLACKPOOL: "Blackpool",
  BLAENAU_GWENT: "Blaenau Gwent",
  BOLSOVER: "Bolsover",
  BOLTON: "Bolton",
  BOSTON: "Boston",
  BOURNEMOUTH_CHRISTCHURCH_AND_POOLE: "Bournemouth, Christchurch a Poole",
  BRACKNELL_FOREST: "Coedwig Bracknell",
  BRADFORD: "Bradford",
  BRAINTREE: "Braintree",
  BRECKLAND: "Breckland",
  BRENT: "Brent",
  BRENTWOOD: "Brentwood",
  BRIDGEND: "Pen-y-bont ar Ogwr",
  BRIGHTON_AND_HOVE: "Brighton a Hove",
  BRISTOL_CITY_OF: "Briste, Dinas",
  BROADLAND: "Broadland",
  BROMLEY: "Bromley",
  BROMSGROVE: "Bromsgrove",
  BROXBOURNE: "Broxbourne",
  BROXTOWE: "Broxtowe",
  BURNLEY: "Burnley",
  BURY: "Bury",
  CAERPHILLY: "Caerffili",
  CALDERDALE: "Calderdale",
  CAMBRIDGE: "Caergrawnt",
  CAMDEN: "Camden",
  CANNOCK_CHASE: "Cannock Chase",
  CANTERBURY: "Caergaint",
  CARDIFF: "Caerdydd",
  CARLISLE: "Carlisle",
  CARMARTHENSHIRE: "Caerfyrddin",
  CASTLE_POINT: "Castle Point",
  CAUSEWAY_COAST_AND_GLENS: "Causeway Coast a'r Glens",
  CENTRAL_BEDFORDSHIRE: "Swydd Bedford Canolig",
  CEREDIGION: "Ceredigion",
  CHARNWOOD: "Charnwood",
  CHELMSFORD: "Chelmsford",
  CHELTENHAM: "Cheltenham",
  CHERWELL: "Cherwell",
  CHESHIRE_EAST: "Dwyrain Swydd Gaer",
  CHESHIRE_WEST_AND_CHESTER: "Gorllewin Swydd Gaer a Chaer",
  CHESTERFIELD: "Chesterfield",
  CHICHESTER: "Chichester",
  CHILTERN: "Chiltern",
  CHORLEY: "Chorley",
  CITY_OF_EDINBURGH: "Dinas Caeredin",
  CITY_OF_LONDON: "Dinas Llundain",
  CLACKMANNANSHIRE: "Clackmannanshire",
  COLCHESTER: "Colchester",
  CONWY: "Conwy",
  COPELAND: "Copeland",
  CORBY: "Corby",
  CORNWALL: "Cernyw",
  COTSWOLD: "Cotswold",
  COUNTY_DURHAM: "County Durham",
  COVENTRY: "Coventry",
  CRAVEN: "Craven",
  CRAWLEY: "Crawley",
  CROYDON: "Croydon",
  DACORUM: "Dacorum",
  DARLINGTON: "Darlington",
  DARTFORD: "Dartford",
  DAVENTRY: "Daventry",
  DENBIGHSHIRE: "Sir Dinbych",
  DERBY: "Derby",
  DERBYSHIRE_DALES: "Dolau Swydd Derby",
  DERRY_CITY_AND_STRABANE: "Dinas Doire a Strabane",
  DONCASTER: "Doncaster",
  DORSET: "Dorset",
  DOVER: "Dover",
  DUDLEY: "Dudley",
  DUMFRIES_AND_GALLOWAY: "Dumfries a Galloway",
  DUNDEE_CITY: "Dinas Dundee",
  EALING: "Ealing",
  EAST_AYRSHIRE: "Dwyrain Ayrshire",
  EAST_CAMBRIDGESHIRE: "Dwyrain Swydd Caergrawnt",
  EAST_DEVON: "Dwyrain Dyfnaint",
  EAST_DUNBARTONSHIRE: "Dwyrain Swydd Dunbarton",
  EAST_HAMPSHIRE: "Dwyrain Hampshire",
  EAST_HERTFORDSHIRE: "Dwyrain Hertfordshire",
  EAST_LINDSEY: "Dwyrain Lindsey",
  EAST_LOTHIAN: "Dwyrain Lothian",
  EAST_NORTHAMPTONSHIRE: "Dwyrain Northamtonshire",
  EAST_RENFREWSHIRE: "Dwyrain Renfrewshire",
  EAST_RIDING_OF_YORKSHIRE: "Dwyrain Swydd Efrog",
  EAST_STAFFORDSHIRE: "Dwyrain Staffordshire",
  EAST_SUFFOLK: "Dwyrain Suffolk",
  EASTBOURNE: "Eastbourne",
  EASTLEIGH: "Eastleigh",
  EDEN: "Eden",
  ELMBRIDGE: "Elmbridge",
  ENFIELD: "Enfield",
  EPPING_FOREST: "Coedwig Epping",
  EPSOM_AND_EWELL: "Epsom ac Ewell",
  EREWASH: "Erewash",
  EXETER: "Caerwysg",
  FALKIRK: "Falkirk",
  FAREHAM: "Fareham",
  FENLAND: "Fenland",
  FERMANAGH_AND_OMAGH: "Fermanagh ac Omagh",
  FIFE: "Fife",
  FLINTSHIRE: "Sir y Fflint",
  FOLKESTONE_AND_HYTHE: "Folkestone a Hythe",
  FOREST_OF_DEAN: "Coedwig Dean",
  FYLDE: "Fylde",
  GATESHEAD: "Gateshead",
  GEDLING: "Gedling",
  GLASGOW_CITY: "Dinas Glasgow",
  GLOUCESTER: "Caerloyw",
  GOSPORT: "Gosport",
  GRAVESHAM: "Gravesham",
  GREAT_YARMOUTH: "Great Yarmouth",
  GREENWICH: "Greenwich",
  GUILDFORD: "Guildford",
  GWYNEDD: "Gwynedd",
  HACKNEY: "Hackney",
  HALTON: "Halton",
  HAMBLETON: "Hambleton",
  HAMMERSMITH_AND_FULHAM: "Hammersmith a Fulham",
  HARBOROUGH: "Harborough",
  HARINGEY: "Haringey",
  HARLOW: "Harlow",
  HARROGATE: "Harrogate",
  HARROW: "Harrow",
  HART: "Hart",
  HARTLEPOOL: "Hartlepool",
  HASTINGS: "Hastings",
  HAVANT: "Havant",
  HAVERING: "Havering",
  HEREFORDSHIRE_COUNTY_OF: "Henffordd, Swydd",
  HERTSMERE: "Hertsmere",
  HIGH_PEAK: "High Peak",
  HIGHLAND: "Highland",
  HILLINGDON: "Hillingdon",
  HINCKLEY_AND_BOSWORTH: "Hinckly a Bosworth",
  HORSHAM: "Horsham",
  HOUNSLOW: "Hounslow",
  HUNTINGDONSHIRE: "Huntingdonshire",
  HYNDBURN: "Hyndburn",
  INVERCLYDE: "Inverclyde",
  IPSWICH: "Ipswich",
  ISLE_OF_ANGLESEY: "Ynys Môn",
  ISLE_OF_WIGHT: "Ynys Wyth",
  ISLES_OF_SCILLY: "Ynysoedd Scilly",
  ISLINGTON: "Islington",
  KENSINGTON_AND_CHELSEA: "Kensington a Chelsea",
  KETTERING: "Kettering",
  KINGS_LYNN_AND_WEST_NORFOLK: "King's Lynn a Norfolk",
  KINGSTON_UPON_HULL_CITY_OF: "Kingston upon Hull, Dinas",
  KINGSTON_UPON_THAMES: "Kingston upon Thames",
  KIRKLEES: "Kirklees",
  KNOWSLEY: "Knowsley",
  LAMBETH: "Lambeth",
  LANCASTER: "Lancaster",
  LEEDS: "Leeds",
  LEICESTER: "Leicester",
  LEWES: "Lewes",
  LEWISHAM: "Lewisham",
  LICHFIELD: "Lichfield",
  LINCOLN: "Lincoln",
  LISBURN_AND_CASTLEREAGH: "Lisburn a Castlereagh",
  LIVERPOOL: "Lerpwl",
  LUTON: "Luton",
  MAIDSTONE: "Maidstone",
  MALDON: "Maldon",
  MALVERN_HILLS: "Bryniau Malvern",
  MANCHESTER: "Manceinion",
  MANSFIELD: "Mansfield",
  MEDWAY: "Medway",
  MELTON: "Melton",
  MENDIP: "Mendip",
  MERTHYR_TYDFIL: "Merthyr Tudful",
  MERTON: "Merton",
  MID_AND_EAST_ANTRIM: "Dwyrain a Chanol Antrim",
  MID_DEVON: "Canolbarth Dyfnaint",
  MID_SUFFOLK: "Canolbarth Suffolk",
  MID_SUSSEX: "Canolbarth Sussex",
  MID_ULSTER: "Canolbarth Ulster",
  MIDDLESBROUGH: "Middlesbrough",
  MIDLOTHIAN: "Midlothian",
  MILTON_KEYNES: "Milton Keynes",
  MOLE_VALLEY: "Cwm Twrch",
  MONMOUTHSHIRE: "Sir Fynwy",
  MORAY: "Moray",
  NA_H_EILEANAN_SIAR: "Na h-Eileanan Siar",
  NEATH_PORT_TALBOT: "Castell-nedd Port Talbot",
  NEW_FOREST: "Y Goedwig Newydd",
  NEWARK_AND_SHERWOOD: "Newark a Sherwood",
  NEWCASTLE_UPON_TYNE: "Newcastle upon Tyne",
  NEWCASTLE_UNDER_LYME: "Newcastle-under-Lyme",
  NEWHAM: "Newham",
  NEWPORT: "Casnewydd",
  NEWRY_MOURNE_AND_DOWN: "Newry, Mourne a Down",
  NORTH_AYRSHIRE: "Gogledd Ayrshire",
  NORTH_DEVON: "Gogledd Dyfnaint",
  NORTH_EAST_DERBYSHIRE: "Gogledd Ddwyrain Derbyshire",
  NORTH_EAST_LINCOLNSHIRE: "Gogledd Ddwyrain Lincolnshire",
  NORTH_HERTFORDSHIRE: "Gogledd Hertfordshire",
  NORTH_KESTEVEN: "Gogledd Kesteven",
  NORTH_LANARKSHIRE: "Gogledd Lanarkshire",
  NORTH_LINCOLNSHIRE: "Gogledd Lincolnshire",
  NORTH_NORFOLK: "Gogledd Norfolk",
  NORTH_SOMERSET: "Gogledd Gwlad yr Hâf",
  NORTH_TYNESIDE: "Gogledd Tyneside",
  NORTH_WARWICKSHIRE: "Gogledd Swydd Warwickshire",
  NORTH_WEST_LEICESTERSHIRE: "Gogledd Orllewin Leicestershire",
  NORTHAMPTON: "Northampton",
  NORTHUMBERLAND: "Northumberland",
  NORWICH: "Norwich",
  NOTTINGHAM: "Nottingham",
  NUNEATON_AND_BEDWORTH: "Nuneaton a Bedworth",
  OADBY_AND_WIGSTON: "Oadby a Wigston",
  OLDHAM: "Oldham",
  ORKNEY_ISLANDS: "Ynysoedd Orkney",
  OXFORD: "Rhydychen",
  PEMBROKESHIRE: "Sir Benfro",
  PENDLE: "Pendle",
  PERTH_AND_KINROSS: "Perth a Kinross",
  PETERBOROUGH: "Peterborough",
  PLYMOUTH: "Plymouth",
  PORTSMOUTH: "Portsmouth",
  POWYS: "Powys",
  PRESTON: "Preston",
  READING: "Reading",
  REDBRIDGE: "Redbridge",
  REDCAR_AND_CLEVELAND: "Redcar a Cleveland",
  REDDITCH: "Redditch",
  REIGATE_AND_BANSTEAD: "Reigate a Banstead",
  RENFREWSHIRE: "Renfrewshire",
  RHONDDA_CYNON_TAF: "Rhondda Cynon Taf",
  RIBBLE_VALLEY: "Ribble Valley",
  RICHMOND_UPON_THAMES: "Richmond upon Thames",
  RICHMONDSHIRE: "Richmondshire",
  ROCHDALE: "Rochdale",
  ROCHFORD: "Rochford",
  ROSSENDALE: "Rossendale",
  ROTHER: "Rother",
  ROTHERHAM: "Rotherham",
  RUGBY: "Rugby",
  RUNNYMEDE: "Runnymede",
  RUSHCLIFFE: "Rushcliffe",
  RUSHMOOR: "Rushmoor",
  RUTLAND: "Rutland",
  RYEDALE: "Ryedale",
  SALFORD: "Salford",
  SANDWELL: "Sandwell",
  SCARBOROUGH: "Scarborough",
  SCOTTISH_BORDERS: "Ffiniau'r Alban",
  SEDGEMOOR: "Sedgemoor",
  SEFTON: "Sefton",
  SELBY: "Selby",
  SEVENOAKS: "Sevenoaks",
  SHEFFIELD: "Sheffield",
  SHETLAND_ISLANDS: "Ynysoedd y Shetland",
  SHROPSHIRE: "Swydd Amwythig",
  SLOUGH: "Slough",
  SOLIHULL: "Solihull",
  SOMERSET_WEST_AND_TAUNTON: "Gorllewin Gwlad yr Hâf a Taunton",
  SOUTH_AYRSHIRE: "De Ayrshire",
  SOUTH_BUCKS: "De Bucks",
  SOUTH_CAMBRIDGESHIRE: "De Swydd Caergrawnt",
  SOUTH_DERBYSHIRE: "De Derbyshire",
  SOUTH_GLOUCESTERSHIRE: "De Swydd Gaerloyw",
  SOUTH_HAMS: "De Hams",
  SOUTH_HOLLAND: "De Holland",
  SOUTH_KESTEVEN: "De Kesteven",
  SOUTH_LAKELAND: "De Lakeland",
  SOUTH_LANARKSHIRE: "De Swydd Lanark",
  SOUTH_NORFOLK: "De Norfolk",
  SOUTH_NORTHAMPTONSHIRE: "De Swydd Northampton",
  SOUTH_OXFORDSHIRE: "De Swydd Rhydychen",
  SOUTH_RIBBLE: "De Ribble",
  SOUTH_SOMERSET: "De Gwlad yr Hâf",
  SOUTH_STAFFORDSHIRE: "De Staffordshire",
  SOUTH_TYNESIDE: "De Tyneside",
  SOUTHAMPTON: "Southampton",
  SOUTHEND_ON_SEA: "Southend-on-Sea",
  SOUTHWARK: "Southwark",
  SPELTHORNE: "Spelthorne",
  ST_ALBANS: "St Albans",
  ST_HELENS: "St. Helens",
  STAFFORD: "Stafford",
  STAFFORDSHIRE_MOORLANDS: "Rhosydd Swydd Stafford",
  STEVENAGE: "Stevenage",
  STIRLING: "Stirling",
  STOCKPORT: "Stockport",
  STOCKTON_ON_TEES: "Stockton-on-Tees",
  STOKE_ON_TRENT: "Stoke-on-Trent",
  STRATFORD_ON_AVON: "Stratford-on-Avon",
  STROUD: "Stroud",
  SUNDERLAND: "Sunderland",
  SURREY_HEATH: "Rhostir Surrey",
  SUTTON: "Sutton",
  SWALE: "Swale",
  SWANSEA: "Abertawe",
  SWINDON: "Swindon",
  TAMESIDE: "Tameside",
  TAMWORTH: "Tamworth",
  TANDRIDGE: "Tandridge",
  TEIGNBRIDGE: "Teignbridge",
  TELFORD_AND_WREKIN: "Telford a Wrekin",
  TENDRING: "Tendring",
  TEST_VALLEY: "Test Valley",
  TEWKESBURY: "Tewkesbury",
  THANET: "Thanet",
  THREE_RIVERS: "Three Rivers",
  THURROCK: "Thurrock",
  TONBRIDGE_AND_MALLING: "Tonbridge a Malling",
  TORBAY: "Torbay",
  TORFAEN: "Torfaen",
  TORRIDGE: "Torridge",
  TOWER_HAMLETS: "Tower Hamlets",
  TRAFFORD: "Trafford",
  TUNBRIDGE_WELLS: "Tunbridge Wells",
  UTTLESFORD: "Uttlesford",
  VALE_OF_GLAMORGAN: "Cwm Tâf Morgannwg",
  VALE_OF_WHITE_HORSE: "Vale of White Horse",
  WAKEFIELD: "Wakefield",
  WALSALL: "Walsall",
  WALTHAM_FOREST: "Coedwig Waltham",
  WANDSWORTH: "Wandsworth",
  WARRINGTON: "Warrington",
  WARWICK: "Warwick",
  WATFORD: "Watford",
  WAVERLEY: "Waverley",
  WEALDEN: "Wealden",
  WELLINGBOROUGH: "Wellingborough",
  WELWYN_HATFIELD: "Welwyn Hatfield",
  WEST_BERKSHIRE: "Gorllewin Berkshire",
  WEST_DEVON: "Gorllewin Dyfnaint",
  WEST_DUNBARTONSHIRE: "Gorllewin Swydd Dunbarton",
  WEST_LANCASHIRE: "Gorllewin Swydd Gaerhirfryn",
  WEST_LINDSEY: "Gorllewin Lindsey",
  WEST_LOTHIAN: "Gorllewin Lothian",
  WEST_OXFORDSHIRE: "Gorllewin Swydd Rhydychen",
  WEST_SUFFOLK: "Gorllewin Suffolk",
  WESTMINSTER: "San Steffan",
  WIGAN: "Wigan",
  WILTSHIRE: "Wiltshire",
  WINCHESTER: "Winchester",
  WINDSOR_AND_MAIDENHEAD: "Windsor a Maidenhead",
  WIRRAL: "Wirral",
  WOKING: "Woking",
  WOKINGHAM: "Wokingham",
  WOLVERHAMPTON: "Wolverhampton",
  WORCESTER: "Caerwrangon",
  WORTHING: "Worthing",
  WREXHAM: "Wrecsam",
  WYCHAVON: "Wychavon",
  WYCOMBE: "Wycombe",
  WYRE: "Wyre",
  WYRE_FOREST: "Coedwig Wyre",
  YORK: "Efrog",
  // /_UPDATES_0212/202
  BUCKINGHAMSHIRE: "Swydd Buckingham",
  CAMBRIDGESHIRE: "Swydd Caergrawnt",
  CUMBRIA: "Cumbria",
  DERBYSHIRE: "Swydd Derby",
  DEVON: "Dyfnaint",
  EAST_SUSSEX: "Dwyrain Sussex",
  ESSEX: "Essex",
  GLOUCESTERSHIRE: "Swydd Caerloyw",
  HAMPSHIRE: "Hampshire",
  HERTFORDSHIRE: "Swydd Hertford",
  KENT: "Caint",
  LANCASHIRE: "Swydd Gaerhirfryn",
  LEICESTERSHIRE: "Leicestershire",
  LINCOLNSHIRE: "Lincolnshire",
  NORFOLK: "Norfolk",
  NORTH_YORKSHIRE: "Gogledd Swydd Efrog",
  NORTHAMPTONSHIRE: "Swydd Northampton",
  NOTTINGHAMSHIRE: "Swydd Nottingham",
  OXFORDSHIRE: "Swydd Rhydychen",
  SOMERSET: "Gwlad yr Hâf",
  STAFFORDSHIRE: "Swydd Stafford",
  SUFFOLK: "Suffolk",
  SURREY: "Surrey",
  WARWICKSHIRE: "Swydd Warwick",
  WEST_SUSSEX: "Gorllwein Sussex",
  WORCESTERSHIRE: "Swydd Caerwrangon",
};

const authoritesWelshDropDown = Object.entries(authoritiesWelsh)
  .map(([key, value]) => ({
    label: value,
    value: keysEnglish[key],
  }))
  .sort((a, b) => {
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  });

const authoritiesValuesMapWelsh = {};
Object.keys(keysEnglish).forEach((e) => {
  authoritiesValuesMapWelsh[keysEnglish[e]] = authoritiesWelsh[e];
});

export { authoritiesWelsh, authoritiesValuesMapWelsh };
export default authoritesWelshDropDown;
