const linkFormatter = (url, platform) => {
  let _url = url;
  if (_url[_url.length - 1] === "/") {
    _url = _url.slice(0, _url.length - 1);
  }
  if (platform === "facebook") {
    const urlArr = _url.split("/");
    return urlArr[urlArr.length - 1] || url;
  }
  if (platform === "twitter") {
    const urlArr = _url.split("/");
    return `@${urlArr[urlArr.length - 1]}` || url;
  }
  if (platform === "googleMaps") {
    return `http://maps.google.co.uk/maps?q=${_url}` || url;
  }
  if (platform === "instagram") {
    const urlArr = _url.split("/");
    return urlArr[urlArr.length - 1] || url;
  }

  if (platform === "youtube") {
    const urlArr = _url.split("channel/");
    return urlArr[1] || url;
  }

  // standard urls
  const urlArr = _url.split("//");
  return urlArr[urlArr.length - 1] || url;
};

export default linkFormatter;
