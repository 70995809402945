import { validate as _validate, createSchema, fields } from "..";

const { password } = fields;

const changePassword = createSchema({
  newPassword: password,
});

const validate = (data) => {
  return _validate(changePassword, data);
};

export default validate;
