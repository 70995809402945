import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import * as T from "../../Typograpy";

import useStyle from "./style";

function FormControlLabelPlacement({ options, label, setValue, value, name }) {
  const classes = useStyle();

  const onChange = (e) => {
    if (setValue instanceof Function) setValue(e);
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        <T.H16 color="primary" style={{ paddingLeft: "5px" }}>
          {label}
        </T.H16>
      </FormLabel>
      <RadioGroup
        row
        aria-label={name || "position"}
        name={name || "position"}
        value={value}
        style={{ paddingLeft: "10px" }}
        onClick={onChange}
      >
        {options.map((e) => (
          <FormControlLabel
            value={typeof e === "string" ? e : e.value}
            key={typeof e === "string" ? e : e.label}
            control={
              <Radio
                className={classes.root}
                disableRipple
                color="primary"
                checkedIcon={
                  <span className={`${classes.checkedIcon} ${classes.icon}`} />
                }
                icon={<span className={classes.icon} />}
              />
            }
            label={
              <T.H16 color={e === value ? "midnight" : "gray3"}>
                {typeof e === "string" ? e : e.label}
              </T.H16>
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default FormControlLabelPlacement;
