import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";

import { TagButton } from "./style";
import * as T from "../Typograpy";
import t from "../../utils/helpers/translator";

const decideColor = (theme, name) => {
  const { colors } = theme;
  if (["spend", "rejected", "returning", "given out"].includes(name)) {
    return colors.pink;
  }
  if (["gift", "partially approved"].includes(name)) {
    return colors.purple;
  }
  if (["issue", "issued"].includes(name)) {
    return colors.gray2;
  }
  if (name === "pending") {
    return colors.cyan;
  }
  return colors.blue;
};

const ClickableTag = ({
  tagName,
  size,
  colors = {},
  justify,
  selected,
  handleClick,
  m,
  language,
}) => {
  const {
    selectedText: selectedTextColor = "white",
    selectedBackground: selectedBackgroundColor = "blue",
    selectedBorder: selectedBorderColor = "blue",
    text: textColor = "blue",
    background: backgroundColor = "white",
    border: borderColor = "blue",
  } = colors;
  const theme = useTheme();

  return (
    <TagButton
      size={size}
      justify={justify}
      type="button"
      color={
        selected
          ? selectedBackgroundColor
          : backgroundColor || decideColor(theme, tagName)
      }
      border={
        selected
          ? selectedBorderColor
          : borderColor || decideColor(theme, tagName)
      }
      selected={selected}
      onClick={() => handleClick(tagName)}
      m={m}
    >
      <T.Body14S
        color={selected ? selectedTextColor : textColor}
        style={{
          padding: "0 5px",
          textTransform: "capitalize",
        }}
      >
        {t(tagName, language)}
      </T.Body14S>
    </TagButton>
  );
};

const mapStateToProps = (state) => ({
  language: state.auth.language,
});

export default connect(mapStateToProps, {})(ClickableTag);
