import React from "react";
import * as S from "./style";
import * as T from "../Typograpy";
import Image from "../Image";

const GeneralList = ({ title, subtitle, graphic, customTitleElem, m }) => (
  <S.GeneralWrapper m={m}>
    <Image
      image={graphic || "graphic1"}
      alt="graphic"
      width="80px"
      height="80px"
    />
    <S.Details>
      {customTitleElem || (
        <T.Body18S color="midnight" mt="10px" style={{ marginTop: "15px" }}>
          {title}
        </T.Body18S>
      )}
      <T.Body14R color="gray3">{subtitle}</T.Body14R>
    </S.Details>
  </S.GeneralWrapper>
);

export default GeneralList;
