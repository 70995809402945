import {
  GET_LOCATION_LOADING_START,
  GET_LOCATION_SUCCESS,
  GET_LOCATION_FAIL,
  GET_LOCATION_LOADING_END,
} from "../types";

const initState = {
  getLocationLoading: false,
  error: null,
  result: null,
};

const locationReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_LOCATION_LOADING_START:
      return { ...state, getLocationLoading: true };
    case GET_LOCATION_SUCCESS:
      return { ...state, result: payload };
    case GET_LOCATION_FAIL:
      return { ...state, error: payload };
    case GET_LOCATION_LOADING_END:
      return { ...state, getLocationLoading: false };
    default:
      return state;
  }
};

export default locationReducer;
