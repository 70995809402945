import React from "react";
import { Switch } from "react-router-dom";

// CONSTANTS
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { EARN_GROUP } from "../../constants/navRoutes";
import { roles } from "../../constants";

// EARN GROUP COMPONENTS
import GiveCredits from "./GiveCredits";
import Invite from "./Invite";
import EarnGroupMembers from "./Members";
import EarnGroupTransactions from "./Transactions";
import PaidTransactions from "./PaidTransactions";
import RequestCredits, { RequestReceived } from "./RequestTimeCredits";
import RecruitVolunteers from "./RecruitVolunteers";
import Offers from "./Offers";
import OrderConfirmation from "./OrderConfirmation";
import PaymentSuccess from "./OrderConfirmation/PaymentSuccess";

// MULTI-USE COMPONENTS
import Dashboard from "../Dashboard";
import SignUpEarnGroup from "../Signup/SignUpEarnGroup";
import SpendActivityForm from "../SpendActivityForm";
// OTHER ROUTERS
import ErrorRouter from "../ErrorRoutes";

// COMMON
import Route from "../../components/Route";
import theme from "../../utils/theme";

const EarnGroupRouter = () => {
  const showProfileFooter = useMediaQuery(theme.breakpoints.down(1200));
  return (
    <Switch>
      <Route
        exact
        path={EARN_GROUP.SIGN_UP}
        layout="onboarding"
        Component={SignUpEarnGroup}
        withToken
      />

      <Route
        exact
        path={EARN_GROUP.ORDER_CONFIRMATION}
        Component={OrderConfirmation}
        navbar
        allowedRoles={[roles.EARN_GROUP, roles.EARN_GROUP_ADMIN]}
      />
      <Route
        exact
        isPrivate
        path={EARN_GROUP.DASHBOARD}
        Component={Dashboard}
        navbar
        header
        fullWidth
        allowedRoles={[roles.EARN_GROUP, roles.EARN_GROUP_ADMIN]}
        footer={!showProfileFooter}
      />

      <Route
        exact
        isPrivate
        path={EARN_GROUP.MEMBERS_SINGLE}
        Component={EarnGroupMembers}
        type="single"
        navbar
        header
        fullWidth
        allowedRoles={[roles.EARN_GROUP, roles.EARN_GROUP_ADMIN]}
        footer={!showProfileFooter}
      />

      <Route
        exact
        isPrivate
        path={EARN_GROUP.MEMBERS}
        Component={EarnGroupMembers}
        type="all"
        navbar
        header
        fullWidth
        allowedRoles={[roles.EARN_GROUP, roles.EARN_GROUP_ADMIN]}
        footer={!showProfileFooter}
      />

      <Route
        exact
        isPrivate
        path={EARN_GROUP.HISTORY}
        Component={EarnGroupTransactions}
        type="all"
        navbar
        header
        fullWidth
        allowedRoles={[roles.EARN_GROUP, roles.EARN_GROUP_ADMIN]}
        footer={!showProfileFooter}
      />

      <Route
        exact
        isPrivate
        path={EARN_GROUP.HISTORY_SINGLE}
        Component={EarnGroupTransactions}
        type="single"
        navbar
        header
        fullWidth
        allowedRoles={[roles.EARN_GROUP, roles.EARN_GROUP_ADMIN]}
        footer={!showProfileFooter}
      />

      <Route
        exact
        isPrivate
        path={EARN_GROUP.GIVE}
        Component={GiveCredits}
        header
        navbar
        allowedRoles={[roles.EARN_GROUP, roles.EARN_GROUP_ADMIN]}
        footer={!showProfileFooter}
      />

      <Route
        exact
        isPrivate
        path={EARN_GROUP.GIVE_SUCCESS}
        Component={GiveCredits}
        header
        navbar
        success
        layout="splitScreen"
        side="right"
        allowedRoles={[roles.EARN_GROUP, roles.EARN_GROUP_ADMIN]}
        footer={!showProfileFooter}
      />

      <Route
        exact
        isPrivate
        path={EARN_GROUP.INVITE}
        Component={Invite}
        header
        navbar
        allowedRoles={[roles.EARN_GROUP, roles.EARN_GROUP_ADMIN]}
        footer={!showProfileFooter}
      />

      <Route
        exact
        isPrivate
        path={EARN_GROUP.INVITE_NONDIGITAL}
        Component={Invite}
        header
        navbar
        inviteType="nonDigital"
        allowedRoles={[roles.EARN_GROUP, roles.EARN_GROUP_ADMIN]}
        footer={!showProfileFooter}
      />

      <Route
        exact
        isPrivate
        path={EARN_GROUP.INVITE_NONDIGITAL_SUCCESS}
        Component={Invite}
        header
        navbar
        inviteType="success"
        layout="splitScreen"
        side="right"
        allowedRoles={[roles.EARN_GROUP, roles.EARN_GROUP_ADMIN]}
        footer={!showProfileFooter}
      />

      <Route
        exact
        isPrivate
        path={EARN_GROUP.REQUEST}
        Component={RequestCredits}
        header
        navbar
        allowedRoles={[roles.EARN_GROUP, roles.EARN_GROUP_ADMIN]}
        footer={!showProfileFooter}
      />

      <Route
        exact
        isPrivate
        path={EARN_GROUP.REQUEST_SUCCESS}
        Component={RequestReceived}
        header
        layout="splitScreen"
        side="right"
        allowedRoles={[roles.EARN_GROUP, roles.EARN_GROUP_ADMIN]}
      />

      <Route
        exact
        isPrivate
        path={EARN_GROUP.ORDER_PAYMENT_SUCCESS}
        Component={PaymentSuccess}
        header
        layout="splitScreen"
        side="right"
        allowedRoles={[roles.EARN_GROUP, roles.EARN_GROUP_ADMIN]}
      />

      <Route
        exact
        path={EARN_GROUP.PROFILE}
        Component={RecruitVolunteers}
        navbar
        fullWidth
        activityType="earn"
        isPrivate
        allowedRoles={[roles.EARN_GROUP, roles.EARN_GROUP_ADMIN]}
        footer={!showProfileFooter}
      />

      <Route
        exact
        isPrivate
        navbar
        path={EARN_GROUP.OFFERS}
        Component={Offers}
        allowedRoles={[
          roles.EARN_GROUP,
          roles.SPEND_VENUE,
          roles.SPEND_PARTNER,
        ]}
        footer={!showProfileFooter}
      />

      <Route
        exact
        isPrivate
        path={EARN_GROUP.HISTORY_SINGLE_PAID}
        Component={PaidTransactions}
        type="single"
        navbar
        header
        fullWidth
        allowedRoles={[roles.EARN_GROUP, roles.EARN_GROUP_ADMIN]}
        footer={!showProfileFooter}
      />

      <Route
        exact
        path={EARN_GROUP.ADD_OFFERS}
        navbar
        header
        isPrivate
        Component={SpendActivityForm}
        allowedRoles={[roles.EARN_GROUP, roles.EARN_GROUP_ADMIN]}
      />

      <Route
        exact
        path={EARN_GROUP.EDIT_OFFERS}
        navbar
        header
        isPrivate
        Component={SpendActivityForm}
        edit
        allowedRoles={[roles.EARN_GROUP, roles.EARN_GROUP_ADMIN]}
      />

      {/* ERROR ROUTES */}
      <ErrorRouter />
    </Switch>
  );
};

export default EarnGroupRouter;
