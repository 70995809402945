import React from "react";
import { useHistory } from "react-router-dom";

import * as S from "../../../style";
import * as T from "../../../../../../components/Typograpy";

import { Row, Col } from "../../../../../../components/Grid";
import Image from "../../../../../../components/Image";
import Icon from "../../../../../../components/Icon";

import { Regular } from "../../../../../../components/Button";
import translate from "../../../../../../utils/helpers/translator";

const Content = ({
  lang,
  contactEmail,
  contactNumber,
  getInTouchDescription,
  websiteUrl,
}) => {
  const history = useHistory();
  return (
    <>
      <Row jc="center" mt={3}>
        <Col w={[4, 12, 12]}>
          <S.SuccessImage>
            <Image image="success" />
          </S.SuccessImage>
          <S.SuccessHeader p="0">
            <T.H40Caps color="midnight" mb={30}>
              {translate("getInTouch", lang)}
            </T.H40Caps>
          </S.SuccessHeader>
          {getInTouchDescription && (
            <T.Body16R style={{ marginBottom: "20px", textAlign: "center" }}>
              {getInTouchDescription}
            </T.Body16R>
          )}
          {contactNumber && (
            <S.Link href={`tel:${contactNumber}`}>
              <S.GetInTouchIconWrapper>
                <Icon
                  icon="phone"
                  color="cyan"
                  width="20px"
                  height="20px"
                  margin="0 15px 0 0"
                />
              </S.GetInTouchIconWrapper>
              <T.Body18S color="midnight" m="0">
                {contactNumber || "N/A"}
              </T.Body18S>
            </S.Link>
          )}
          {contactEmail && (
            <S.Link href={`mailto:${contactEmail}`}>
              <S.GetInTouchIconWrapper>
                <Icon
                  icon="email"
                  color="cyan"
                  width="20px"
                  height="20px"
                  margin="0 15px 0 0"
                />
              </S.GetInTouchIconWrapper>
              <T.Body18S color="midnight" m="0" style={{ textAlign: "center" }}>
                {contactEmail || "N/A"}
              </T.Body18S>
            </S.Link>
          )}
          {websiteUrl && (
            <S.Link
              href={
                websiteUrl.startsWith("http")
                  ? websiteUrl
                  : `http://${websiteUrl}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <S.GetInTouchIconWrapper>
                <Icon
                  icon="computer"
                  color="cyan"
                  width="20px"
                  height="20px"
                  margin="0 15px 0 0"
                />
              </S.GetInTouchIconWrapper>
              <T.Body18S color="midnight" m="0" style={{ textAlign: "center" }}>
                {websiteUrl || "N/A"}
              </T.Body18S>
            </S.Link>
          )}
          <Regular
            primary
            bgColor="blue"
            onClick={() => history.go(-2)}
            mb={10}
            mt={40}
            disabled={history.length < 4}
          >
            {translate("goBack", lang)}
          </Regular>
        </Col>
      </Row>
    </>
  );
};

export default Content;
