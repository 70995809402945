import React from "react";

const SomethingElse = ({ width, height, color, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    {...props}
  >
    <g clipPath="url(#clip0)" fill={color || "currentColor"}>
      <path d="M23.89 18.375a2.14 2.14 0 00-2.72-1.335l-2.17.675V17.5a2 2 0 00-2-2h-5.35a5.915 5.915 0 00-3.3-1H5V14a.5.5 0 00-.5-.5h-4a.5.5 0 00-.5.5v8a.5.5 0 00.5.5h4A.5.5 0 005 22v-.14l.145.085a14.95 14.95 0 007.43 2 15.07 15.07 0 005.635-1.1l4.5-1.825a2.14 2.14 0 001.18-2.645zM4 21.5H1v-7h3v7zm18.335-1.42l-4.5 1.82a13.89 13.89 0 01-12.19-.825L5 20.71V15.5h3.35c.977 0 1.932.29 2.745.83a1 1 0 00.555.17H17a1 1 0 011 1 1 1 0 01-.235.64.346.346 0 00-.055.065 1.001 1.001 0 01-.71.295h-5.5a.5.5 0 000 1H17a2 2 0 001.375-.55L21.5 18a1.13 1.13 0 01.85 2.09l-.015-.01z" />
      <path d="M2.78 19.585l-.09-.045a.32.32 0 00-.09-.04.499.499 0 00-.455.135A.525.525 0 002 20a.5.5 0 10.855-.355l-.075-.06zM12.41 8.355c.363.371.574.866.59 1.385v1.76a1 1 0 00.5.86v.14a1.5 1.5 0 103 0v-.14a1 1 0 00.5-.86V9.83a2 2 0 01.5-1.405 3.469 3.469 0 00.935-3A3.5 3.5 0 0011.5 6c0 .87.324 1.71.91 2.355zM16 11.5h-2v-1h2v1zM15 13a.5.5 0 01-.5-.5h1a.5.5 0 01-.5.5zm-1.66-8.87a2.5 2.5 0 011.965-.63 2.5 2.5 0 012.165 2.075 2.5 2.5 0 01-.67 2.135A2.945 2.945 0 0016 9.5h-2a3.145 3.145 0 00-.84-1.82A2.5 2.5 0 0112.5 6a2.5 2.5 0 01.84-1.87zM15 1.5a.5.5 0 00.5-.5V.5a.5.5 0 00-1 0V1a.5.5 0 00.5.5zM11.11 2.82a.498.498 0 00.71 0 .501.501 0 000-.71l-.355-.355a.5.5 0 10-.705.71l.35.355zM9.5 6.5h.5a.5.5 0 000-1h-.5a.5.5 0 100 1zM11.11 10.39a.501.501 0 00.355-.15l.355-.35a.502.502 0 00-.71-.71l-.35.355a.5.5 0 00.35.855zM18.535 2.965a.5.5 0 00.355-.145l.35-.355a.5.5 0 10-.705-.71l-.355.355a.501.501 0 00.355.855zM20 6.5h.5a.5.5 0 000-1H20a.5.5 0 000 1zM18.89 9.18a.502.502 0 10-.71.71l.355.35a.501.501 0 00.355.15.5.5 0 00.35-.855l-.35-.355z" />
      <path d="M14.625 6.335l-.275.365a.5.5 0 10.8.6l.5-.665a.5.5 0 000-.6l-.275-.37.275-.365a.5.5 0 00-.8-.6l-.5.665a.5.5 0 000 .6l.275.37z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SomethingElse;
