import React from "react";

const NotComplete = ({ width, height, color, ...props }) => (
  <svg
    width={width || "32"}
    height={width || "32"}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.5 16C0.5 7.43959 7.43959 0.5 16 0.5C24.5604 0.5 31.5 7.43959 31.5 16C31.5 24.5604 24.5604 31.5 16 31.5C7.43959 31.5 0.5 24.5604 0.5 16Z"
      stroke={color || "#FF4D4F"}
    />
    <path
      d="M16.9247 15.9992L21.6122 10.4117C21.6908 10.3189 21.6247 10.1778 21.5033 10.1778H20.0783C19.9944 10.1778 19.914 10.2153 19.8587 10.2796L15.9926 14.8885L12.1265 10.2796C12.0729 10.2153 11.9926 10.1778 11.9069 10.1778H10.4819C10.3604 10.1778 10.2944 10.3189 10.3729 10.4117L15.0604 15.9992L10.3729 21.5867C10.3553 21.6074 10.344 21.6327 10.3404 21.6596C10.3368 21.6866 10.3409 21.714 10.3524 21.7386C10.3639 21.7632 10.3822 21.7841 10.4051 21.7986C10.4281 21.8131 10.4547 21.8208 10.4819 21.8206H11.9069C11.9908 21.8206 12.0712 21.7831 12.1265 21.7189L15.9926 17.1099L19.8587 21.7189C19.9122 21.7831 19.9926 21.8206 20.0783 21.8206H21.5033C21.6247 21.8206 21.6908 21.6796 21.6122 21.5867L16.9247 15.9992Z"
      fill={color || "#FF4D4F"}
    />
  </svg>
);
export default NotComplete;
