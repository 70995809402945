import {
  // Spend activities
  GET_SPEND_ACTIVITIES_LOADING_START,
  GET_SPEND_ACTIVITIES_LOADING_END,
  GET_SPEND_ACTIVITIES_SUCCESS,
  GET_SPEND_ACTIVITIES_FAIL,
  GET_ACTIVITIES_CITES_COUNTIES_LOADING_START,
  GET_ACTIVITIES_CITES_COUNTIES_SUCCESS,
  GET_ACTIVITIES_CITES_COUNTIES_FAIL,
  GET_ACTIVITIES_CITES_COUNTIES_LOADING_END,
} from "../types";

import { activities } from "../../api-calls";

// gets spend activities for members (pages: dashboard and spend)
export const getSpendActivities =
  ({
    isPublic = false,
    keyword,
    limit,
    language,
    availableOffersOnly,
    selectedActivity,
    tab,
    location,
    postcode,
    distance,
    county,
    includeNationalOffers,
    sortBy,
  }) =>
  async (dispatch) => {
    dispatch({ type: GET_SPEND_ACTIVITIES_LOADING_START });

    try {
      const { data, error } = await activities.getActivities({
        activityType: "spend",
        keyword,
        isPublic,
        limit,
        options: {},
        language,
        availableOffersOnly,
        selectedActivity,
        tab,
        location,
        postcode,
        distance,
        county,
        includeNationalOffers,
        sortBy,
      });
      if (data) {
        dispatch({
          type: GET_SPEND_ACTIVITIES_SUCCESS,
          payload: {
            spendActivities: data.activities,
            lastFeaturedActivities: data.lastFeaturedActivities,
            citiesList: data.citiesList,
            location,
            county,
          },
        });
        return data.activities;
      }
      if (error) {
        dispatch({
          type: GET_SPEND_ACTIVITIES_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_SPEND_ACTIVITIES_FAIL,
        payload: error,
      });
    } finally {
      dispatch({ type: GET_SPEND_ACTIVITIES_LOADING_END });
    }
  };

export const getSpendActivitiesCitiesAndCounties = () => async (dispatch) => {
  dispatch({ type: GET_ACTIVITIES_CITES_COUNTIES_LOADING_START });
  try {
    const { data, error } = await activities.getActivitiesCitesAndCounties({});
    if (data) {
      dispatch({
        type: GET_ACTIVITIES_CITES_COUNTIES_SUCCESS,
        payload: data,
      });
      return data;
    }
    if (error) {
      dispatch({
        type: GET_ACTIVITIES_CITES_COUNTIES_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ACTIVITIES_CITES_COUNTIES_FAIL,
      payload: error,
    });
  } finally {
    dispatch({ type: GET_ACTIVITIES_CITES_COUNTIES_LOADING_END });
  }
};
