/* eslint-disable import/prefer-default-export */
import {
  GET_USER_ACCOUNT_LOADING,
  GET_USER_ACCOUNT_SUCCESS,
  GET_USER_ACCOUNT_FAIL,
  UPDATE_USER_ACCOUNT_LOADING,
  UPDATE_USER_ACCOUNT_SUCCESS,
  UPDATE_USER_ACCOUNT_FAIL,
} from "../types";

import { users } from "../../api-calls";

export const getUserAccount = (id, role) => async (dispatch) => {
  dispatch({ type: GET_USER_ACCOUNT_LOADING });
  try {
    const { data, error } = await users.getUserAccount({ id, role });
    if (data) {
      dispatch({ type: GET_USER_ACCOUNT_SUCCESS, payload: data });
    } else {
      dispatch({ type: GET_USER_ACCOUNT_FAIL, payload: error });
    }
  } catch (error) {
    dispatch({ type: GET_USER_ACCOUNT_FAIL, payload: error });
  }
};

export const updateUserAccount =
  (accountDetails, id, role) => async (dispatch) => {
    dispatch({ type: UPDATE_USER_ACCOUNT_LOADING });
    try {
      const { data, error } = await users.updateUserAccount({
        accountDetails,
        id,
        role,
      });

      if (!error) {
        dispatch({ type: UPDATE_USER_ACCOUNT_SUCCESS, payload: data });
        return { data };
      }
      dispatch({ type: UPDATE_USER_ACCOUNT_FAIL, payload: error });
      throw error;
    } catch (error) {
      dispatch({ type: UPDATE_USER_ACCOUNT_FAIL, payload: error });
      throw error;
    }
  };
