import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import { connect } from "react-redux";

import * as S from "./style";
import * as T from "../../../../components/Typograpy";
import { TEMPO } from "../../../../constants/navRoutes";
import Image from "../../../../components/Image";
import InviteLink from "../../../../components/Links/InviteLink";
import Button from "../../../../components/Button";
import TCBalance from "../../../../components/TCBalance";
import { Row, Col } from "../../../../components/Grid";
import GoBack from "../../../../components/GoBack";
import * as actions from "./actions";
import { tempoRoles } from "../../../../constants/roles";

const ProgrammeInviteEarnGroup = ({
  inviteToken,
  name,
  getProgrammeById,
  balance,
  role,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();
  const { id: programmeId } = useParams();

  useEffect(() => {
    if (programmeId) {
      getProgrammeById(programmeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programmeId]);

  return (
    <>
      <Row mb={6}>
        <Col w={[4, 8, 8]}>
          <GoBack showText p="0 0 20px 0" />
          <T.H40Caps color="midnight" mb="20">
            {name}
          </T.H40Caps>
          <T.H30Caps color="pink" mb="20">
            add earn group
          </T.H30Caps>
        </Col>
        <Col
          w={[4, 4, 4]}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
        >
          <TCBalance color="cyan" credits={balance} />
        </Col>
      </Row>
      <S.Wrapper>
        <S.ContentWrapper>
          <S.ImageWrapper>
            <Image image="group" alt="group" />
          </S.ImageWrapper>
          <S.Body>
            <T.H20 color="midnight" mb={20}>
              This is your unique invite link
            </T.H20>
            <InviteLink
              background="pinkLight"
              color="pink"
              url={`${window.location.origin}/earn-groups/sign-up/?programme=${inviteToken}`}
              style={{ marginBottom: "30px" }}
            />
            <T.Body16R color="gray3" style={{ padding: "20px 0" }}>
              Send your{" "}
              <span style={{ fontWeight: "bold", color: "#201751" }}>
                unique invite link
              </span>{" "}
              to the Earn Groups you want to add to this programme.
            </T.Body16R>
            <T.Body16R color="gray3" style={{ marginBottom: "60px" }}>
              The Earn Group will need to sign up or log in using this link to
              be added to the Programme.
            </T.Body16R>
            {Object.values(tempoRoles).includes(role) && (
              <>
                <S.SubHeader>Want to add an existing Earn Group?</S.SubHeader>
                <T.Body16R color="gray3" style={{ marginBottom: "40px" }}>
                  You can add or edit the Programme an Earn Group is part of by
                  visiting their individual Earn Group page.
                </T.Body16R>
                <Row>
                  <Col w={[4, 6, 4]}>
                    <Button
                      primary
                      size={isMobile ? "l" : "s"}
                      style={{ margin: "0 0 0 -10px" }}
                      onClick={() => history.push(TEMPO.EARN_GROUPS)}
                    >
                      View Earn Groups
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </S.Body>
        </S.ContentWrapper>
      </S.Wrapper>
    </>
  );
};

const mapStateToProps = (state) => ({
  name: state.programmeInviteEarnGroup.name,
  inviteToken: state.programmeInviteEarnGroup.inviteToken,
  balance: state.programmeInviteEarnGroup.balance,
  role: state.auth.role,
});
const mapActionsToProps = {
  getProgrammeById: actions.getProgrammeById,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(ProgrammeInviteEarnGroup);
