import {
  GET_ORDER_INVOICE_LOADING,
  GET_ORDER_INVOICE_SUCCESS,
  GET_ORDER_INVOICE_FAIL,
} from "../../../redux/types";

const initialState = {
  getOrderInvoiceLoading: false,
  data: {},
  error: null,
};

const orderInvoiceReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ORDER_INVOICE_LOADING:
      return {
        ...state,
        getOrderInvoiceLoading: true,
        data: [],
      };
    case GET_ORDER_INVOICE_SUCCESS:
      return {
        ...state,
        getOrderInvoiceLoading: false,
        data: payload,
      };
    case GET_ORDER_INVOICE_FAIL:
      return {
        ...state,
        getOrderInvoiceLoading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default orderInvoiceReducer;
