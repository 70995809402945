import { fields, createSchema, validate as _validate } from "..";

const {
  membershipId,
  id,
  adultsParticipants,
  budget,
  customActivityName,
  customPersonTcPrice,
} = fields;

const basicObjSchema = {
  membershipId,
  adultsParticipants,
  price: budget,
};

// this is the schema when user choose activity from the list
const acceptTCSchema = createSchema({ ...basicObjSchema, activityId: id });

// this is for custom activity
const acceptTCCustomActivitySchema = createSchema({
  ...basicObjSchema,
  customActivityName,
  customPersonTcPrice,
});

const validate = (data) => _validate(acceptTCSchema, data);
export const validateCustomActivity = (data) =>
  _validate(acceptTCCustomActivitySchema, data);

export default validate;
