import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { GENERAL } from "../../constants/navRoutes";
import cleanWhitespace from "../../utils/helpers/cleanWhitespace";

import {
  signup as signupAction,
  getEarnGroupInfo as getEarnGroupInfoAction,
} from "../../redux/actions/auth";

import { H60Caps, Body18B, Body16R } from "../../components/Typograpy";
import InputField from "../../components/Inputs/InputField";
import { Regular } from "../../components/Button";
import { ReCaptcha } from "../../components/TextSections";

import Checkbox from "../../components/Inputs/Checkbox";
import WelcomeSection from "../Login/WelcomeSection";

import validate from "../../validation/schemas/signup";
import { roles, externalLinks } from "../../constants";
import t from "../../utils/helpers/translator";

import {
  GridItem,
  InputContainer,
  ButtonContainer,
  CheckboxContainer,
  MessageContainer,
  FormContainer,
  ExistsError,
  SignupForm,
  GridTitle,
  ExtLink,
  GridItemCaptcha,
} from "./style";

import { GridContent, TextLinks, Error, StyledLink } from "./customSignupStyle";

const { EARN_GROUP_ADMIN } = roles;

const useQuery = () => new URLSearchParams(useLocation().search);

const initErrors = {
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  agreedOnTerms: "",
};

const Signup = ({
  getEarnGroupInfo,
  earnGroupInfo,
  handleSignup,
  signupLoading,
  signupError,
  language,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [agreedOnTerms, setAgreedOnTerms] = useState(false);
  const [submitAttemp, setSubmitAttemp] = useState(false);
  const history = useHistory();
  const query = useQuery();
  const inviteToken = query.get("administrator");

  const searchParams = new URLSearchParams();
  if (inviteToken) searchParams.append("administrator", inviteToken);

  const [errors, setErrors] = useState(initErrors);

  const partialValidate = (name, value) => {
    try {
      if (submitAttemp) {
        validate(
          {
            [name]: value,
          },
          true,
        );
      }
    } catch (err) {
      if (err.name === "ValidationError") {
        setErrors((_errors) => ({ ..._errors, [name]: err.inner[name] }));
      }
    }
  };

  const onChange = (e) => {
    partialValidate(
      e.target.name,
      e.target.name === "agreedOnTerms" ? e.target.checked : e.target.value,
    );
    switch (e.target.name) {
      case "email":
        setEmail(e.target.value);
        break;
      case "password":
        setPassword(e.target.value);
        break;
      case "firstName":
        setFirstName(e.target.value);
        break;
      case "lastName":
        setLastName(e.target.value);
        break;
      case "agreedOnTerms":
        setAgreedOnTerms(e.target.checked);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (inviteToken) {
      getEarnGroupInfo({
        inviteToken,
        invitedUserRole: EARN_GROUP_ADMIN,
        language,
        history,
      });
    }
    setErrors(initErrors);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      setSubmitAttemp(true);
      const cleanEmail = email.toLowerCase().trim();

      validate({
        role: EARN_GROUP_ADMIN,
        email: cleanEmail,
        password,
        earnGroupUserId: earnGroupInfo.id,
        firstName,
        lastName,
        agreedOnTerms,
        token: inviteToken,
      });
      handleSignup(
        {
          role: EARN_GROUP_ADMIN,
          email: cleanEmail,
          password,
          earnGroupUserId: earnGroupInfo.id,
          firstName: cleanWhitespace(firstName),
          lastName: cleanWhitespace(lastName),
          earnGroupAdministratorInviteToken: inviteToken,
          language,
        },
        history,
      );
    } catch (err) {
      if (err.name === "ValidationError") {
        setErrors(err.inner);
      }
    }
  };

  const renderMainParagraph = () => {
    if (inviteToken) {
      const wantsYouToBecomeAdministrator = t(
        "wantsYouToBecomeAdministrator",
        language,
        [
          language === "welsh" && earnGroupInfo?.profile?.nameWelsh
            ? earnGroupInfo?.profile?.nameWelsh
            : earnGroupInfo?.profile?.name,
        ],
      ).split(" | ");

      return (
        <MessageContainer>
          <Body16R>
            {wantsYouToBecomeAdministrator[0]}{" "}
            <Body18B
              color="gray2"
              style={{
                textTransform: "capitalize",
                display: "inline",
              }}
            >
              {wantsYouToBecomeAdministrator[1]}
            </Body18B>{" "}
            {wantsYouToBecomeAdministrator[2]}.
          </Body16R>
        </MessageContainer>
      );
    }

    return (
      <MessageContainer>
        <Body16R color="gray3">
          <Body18B
            color="gray2"
            style={{
              textTransform: "capitalize",
              display: "inline",
            }}
          >
            {earnGroupInfo.profile.name}
          </Body18B>{" "}
          {t("onlySignUpIfInvitedEGAdmin", language)}
        </Body16R>
      </MessageContainer>
    );
  };

  const termsOfUseText = t("agreeToTerms", language).split(" | ");
  const alreadyHaveAccountLogIn = t("alreadyHaveAccountLogIn", language).split(
    " | ",
  );
  return (
    <Grid container md={12} xl={12} xs={12}>
      <FormContainer item container>
        <GridContent container>
          <GridTitle>
            <H60Caps color="primary" mb="5">
              {t("signUp", language)}
            </H60Caps>
            <TextLinks stopMargin>
              {alreadyHaveAccountLogIn[0]}{" "}
              <StyledLink
                to={{
                  pathname: GENERAL.LOG_IN,
                  search: searchParams.toString(),
                }}
              >
                {alreadyHaveAccountLogIn[1]}
              </StyledLink>
            </TextLinks>
          </GridTitle>
          {renderMainParagraph()}
          <SignupForm>
            <GridItem>
              <InputContainer>
                <InputField
                  placeholder={`${t("typeFirstNameHere", language)}...`}
                  label={t("firstName", language)}
                  name="firstName"
                  value={firstName}
                  setValue={onChange}
                  error={errors.firstName}
                  justify="center"
                />
              </InputContainer>
            </GridItem>
            <GridItem>
              <InputContainer>
                <InputField
                  placeholder={`${t("typeLastNameHere", language)}...`}
                  label={t("lastName", language)}
                  name="lastName"
                  value={lastName}
                  setValue={onChange}
                  error={errors.lastName}
                />
              </InputContainer>
            </GridItem>
            <GridItem>
              <InputContainer>
                <InputField
                  placeholder={`${t("typeEmail", language)}...`}
                  label={t("email", language)}
                  name="email"
                  value={email}
                  setValue={onChange}
                  error={errors.email}
                />
              </InputContainer>
              {signupError && signupError.httpStatusCode === 409 ? (
                <ExistsError>{t(signupError.message, language)}</ExistsError>
              ) : null}
            </GridItem>
            <GridItem>
              <InputContainer>
                <InputField
                  placeholder={`${t("createPassword", language)}...`}
                  label={t("password", language)}
                  type="password"
                  name="password"
                  value={password}
                  setValue={onChange}
                  error={errors.password}
                  showPasswordPop
                  lang={language}
                />
              </InputContainer>
            </GridItem>

            <GridItem>
              <CheckboxContainer style={{ margin: "10px 0 20px 0" }}>
                <Checkbox
                  checkboxSize="small"
                  value={agreedOnTerms}
                  setValue={onChange}
                  bottom
                  mt="20"
                  name="agreedOnTerms"
                  label={
                    <>
                      {termsOfUseText[0]}{" "}
                      <ExtLink
                        href={externalLinks.EARN_TERMS}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {termsOfUseText[1]}
                      </ExtLink>
                      {termsOfUseText[2]}{" "}
                      <ExtLink
                        href={externalLinks.MEMBER_PRIVACY}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {termsOfUseText[3]}
                      </ExtLink>
                      .
                    </>
                  }
                  error={errors.agreedOnTerms}
                />
              </CheckboxContainer>
            </GridItem>
            <GridItem>
              {signupError && !signupError.httpStatusCode === 409 ? (
                <Error>{t(signupError.message, language)}</Error>
              ) : null}
            </GridItem>
            <ButtonContainer>
              <Regular
                bgColor="blue"
                primary
                handleClick={handleSubmit}
                style={{ margin: 0 }}
                loading={signupLoading}
                type="submit"
              >
                {t("signUp", language)}
              </Regular>
            </ButtonContainer>
            <GridItemCaptcha>
              <ReCaptcha />
            </GridItemCaptcha>
          </SignupForm>
        </GridContent>
      </FormContainer>
      <Grid item container justify="center" md={4}>
        <WelcomeSection fullWidth />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  earnGroupInfo: state.auth.earnGroupInfo,
  signupLoading: state.auth.signupLoading,
  signupError: state.auth.signupError,
  language: state.auth.language,
});

const mapActionToProps = {
  getEarnGroupInfo: getEarnGroupInfoAction,
  handleSignup: signupAction,
};

export default connect(mapStateToProps, mapActionToProps)(Signup);
