import React from "react";
import { useHistory } from "react-router-dom";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { GENERAL } from "../../../../../../constants/navRoutes";

import * as T from "../../../../../../components/Typograpy";
import { Row, Col } from "../../../../../../components/Grid";
import Image from "../../../../../../components/Image";
import { TextSection } from "../../../../../../components/TextSections";
import { Regular } from "../../../../../../components/Button";
import { SuccessPageWrapper, CodesWrapper, CodesContainer } from "../style";
import Copy from "../../../../../../components/Copy";

import translate from "../../../../../../utils/helpers/translator";

const Content = ({ lang, filteredCodes, codesExtraDetails, useCodesLink }) => {
  const history = useHistory();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <SuccessPageWrapper>
      <Row jc="center" jcT="center">
        <Col
          w={[4, 8, 8]}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {isMobile ? (
            <div style={{ width: "200px", marginTop: "30px" }}>
              <Image image="successLarge" alt="success" />
            </div>
          ) : (
            <Image image="successLarge" alt="success" />
          )}
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 12]}>
          {isMobile ? (
            <T.H30Caps color="midnight" mb="20" style={{ textAlign: "center" }}>
              {translate("yourAccessCodes", lang)}
            </T.H30Caps>
          ) : (
            <T.H40Caps
              color="midnight"
              style={{
                width: "490px",
                margin: "0 auto",
                textAlign: "center",
                marginBottom: 10,
              }}
            >
              {translate("yourAccessCodes", lang)}
            </T.H40Caps>
          )}
        </Col>
      </Row>
      <Row jc="center" jcT="center" mt={isMobile ? "0px" : 3}>
        <Col w={[4, 6, 6]} style={{ textAlign: "center" }}>
          {filteredCodes.length === 1 ? (
            <T.Body16R
              color="gray3"
              mb="30"
              style={{ maxWidth: "480px", margin: "0 auto", marginBottom: 30 }}
            >
              {translate("singleAccessCode", lang)}.
            </T.Body16R>
          ) : (
            <T.Body16R color="gray3" mb="30">
              {translate("youveGotCodesPartOne", lang, [filteredCodes.length])},
              <T.Body16B style={{ display: "inline" }}>
                {" "}
                one per person.{" "}
              </T.Body16B>
              {translate("youveGotCodesPartTwo", lang)}
            </T.Body16R>
          )}
        </Col>
      </Row>

      <Row jc="center" jcT="center">
        <CodesWrapper w={[4, 4, 4]}>
          <CodesContainer>
            <T.Body16B
              color="pink"
              style={{
                overflowWrap: "break-word",
                margin: "0",
                maxWidth: 170,
              }}
            >
              {filteredCodes.join(", ")}
            </T.Body16B>
            <Copy showText strToCopy={filteredCodes.join(", ")} />
          </CodesContainer>
        </CodesWrapper>
      </Row>
      <Row jc="center" jcT="center" mt={isMobile ? 3 : 7}>
        <Col w={[4, 6, 6]} style={{ textAlign: "center" }}>
          <TextSection
            size="md"
            title={`${translate("redeemCodes", lang)}?`}
            text={codesExtraDetails}
            w="100%"
            m="0 0 10px 0"
          />
        </Col>
      </Row>
      {useCodesLink && (
        <Row jc="center" jcT="center" mt={isMobile ? "0px" : 1}>
          <Col w={[4, 6, 6]} style={{ textAlign: "center" }}>
            <a
              href={
                useCodesLink.startsWith("http")
                  ? useCodesLink
                  : `http://${useCodesLink}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <T.LinkB16 color="pink">
                {translate("clickToBookOnPartnerWebsite", lang)}
              </T.LinkB16>
            </a>
          </Col>
        </Row>
      )}
      <Row jc="center" jcT="center" mt={3}>
        <Col
          w={[4, 4, 4]}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Regular
            primary
            bgColor="blue"
            onClick={() => history.push(GENERAL.SPEND)}
            mt="50"
          >
            {translate("goBack", lang)}
          </Regular>
        </Col>
      </Row>
    </SuccessPageWrapper>
  );
};

export default Content;
