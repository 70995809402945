import styled from "@material-ui/core/styles/styled";

export const Wrapper = styled("div")(({ theme }) => ({
  width: "100%",

  [theme.breakpoints.tablet]: {
    marginTop: "-30px",
  },
}));

export const Header = styled("header")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  paddingTop: theme.spacing(5),
  marginBottom: theme.spacing(6),
}));

export const Section = styled("section")(({ theme, color }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  minHeight: "160px",
  minWidth: "700px",
  width: "calc(100% + 30px)",
  overflow: "hidden",
  backgroundColor: theme.colors[color] || color,
  zIndex: "-1",
  border: "1px solid #F9ECF2",
  marginBottom: "2rem",
  position: "absolute",
  left: "0",
  paddingLeft: theme.spacing(1),

  [theme.breakpoints.tablet]: {
    left: "320px",
    width: "calc(100vw - 320px)",
    paddingLeft: theme.spacing(9),
    paddingRight: theme.spacing(9),
  },
}));

export const OptionsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  paddingTop: "200px",
  marginBottom: theme.spacing(10),
}));

export const Card = styled("div")(({ theme, request }) => ({
  boxShadow: theme.customShadows.dp3,
  border: request
    ? `2px solid ${theme.colors.cyan}`
    : `1px solid ${theme.colors.cyanMed}`,
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  maxWidth: "450px",
  display: request ? "flex" : "block",
  backgroundColor: theme.colors.white,
  borderRadius: "5px",
}));

export const Credits = styled("span")(({ theme }) => ({
  color: theme.colors.cyan,
}));

export const Body = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  width: "80%",
}));

export const CardContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginTop: 42,
});

export const StyledCard = styled("div")(({ theme, height, width }) => ({
  background: theme.colors.white,
  borderRadius: "4px",
  boxShadow: theme.customShadows.dp3,
  width: width || "340px",
  height: height ? `${height}` : `calc(${width}/1.7)`,
  position: "relative",
  padding: "26px 30px",
}));

export const CardItem = styled("div")(() => ({
  display: "flex",
  margin: "21px 0",
  border: "1px red solid",
  "& h5": {
    // width: 70,
    margin: "0 37px 0 0 !important",
  },
  "& h6": {
    margin: "3px 0 !important",
  },
  "& a": {
    textDecoration: "none",
  },
}));

export const Name = styled("span")(() => ({
  textTransform: "capitalize",
}));

export const ProgrammeName = styled("div")(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  paddingTop: 10,
}));

export const Error = styled("p")(({ theme }) => ({
  width: "100%",
  color: theme.colors.pink,
  fontSize: "0.875rem",
  textAlign: "center",
  marginBottom: theme.spacing(1),
}));
