import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import * as S from "./style";
import Image from "../../../../components/Image";
import * as T from "../../../../components/Typograpy";
import Stats from "../../../../components/Stats";
import Table from "../../../../components/FilterTable";
import DownloadFile from "../../../../components/DownloadFile";

import { TEMPO } from "../../../../constants/navRoutes";
import { tempoRoles } from "../../../../constants/roles";

import * as actions from "./actions";

import Button from "../../../../components/Button";
import PopQuestion from "../../../../components/PopQuestion";
import { customDateCol } from "../../../../components/Table/config/reusableColumns";
import FilterByDate from "../../../../components/FilterByDate";
import Modal from "../../../../components/Modal";

const programmesColumns = [
  {
    title: "programme",
    render: (rowData) => <S.CapitalizedSpan>{rowData.name}</S.CapitalizedSpan>,
    field: "name",
  },
  {
    title: (
      <div style={{ position: "relative", color: "midnight" }}>
        earn groups
        <PopQuestion>
          <T.Body16R color="gray3">
            the number of Earn Groups currently part of the Programme
          </T.Body16R>
        </PopQuestion>
      </div>
    ),
    field: "earnGroups",
    type: "numeric",
  },
  {
    title: (
      <div style={{ position: "relative", width: 70, color: "midnight" }}>
        volunteers reached
        <PopQuestion>
          <T.Body16R color="gray3">
            the number of volunteers who have been issued, gifted or earned Time
            Credits in the programme
          </T.Body16R>
        </PopQuestion>
      </div>
    ),
    field: "members",
    type: "numeric",
  },
  {
    title: (
      <div style={{ position: "relative" }}>
        %Used
        <PopQuestion>
          <T.Body16R color="gray3">
            the % of Time Credits in a programme that have been earned or issued
            directly to volunteers that have been used (with Earn Groups or
            Venues)
          </T.Body16R>
        </PopQuestion>
      </div>
    ),
    field: "spent",
    customSort: (a, b) =>
      Number(a.spent?.replace("%", "")) - Number(b.spent?.replace("%", "")),
  },
  {
    title: (
      <div style={{ position: "relative" }}>
        gifted
        <PopQuestion>
          <T.Body16R color="gray3">
            the number of Time Credits that have been gifted
          </T.Body16R>
        </PopQuestion>
      </div>
    ),
    field: "gifts",
    type: "numeric",
  },
  { title: "status", field: "status" },
];

const programmesColumnsOther = [
  {
    title: "programme",
    render: (rowData) => <S.CapitalizedSpan>{rowData.name}</S.CapitalizedSpan>,
  },
  customDateCol({
    title: "Date opened",
    field: "startDate",
  }),
  customDateCol({
    title: "end date",
    field: "endDate",
  }),
  { title: "region", field: "region" },
  { title: "focus", field: "mainFocus" },
];

const Programmes = ({
  programmes,
  loading,
  // error,
  getProgrammes,
  activeProgrammes,
  role,
  getFilteredProgrammes,
}) => {
  const history = useHistory();
  const [dates, setDates] = useState({ startDate: null, endDate: null });
  const startDateValue = dates?.startDate?.valueOf();
  const endDateValue = dates?.endDate?.valueOf();
  const [filterDates, setFilterDates] = useState({
    startDate: null,
    endDate: null,
  });
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    getProgrammes({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!isOpen) {
      getFilteredProgrammes({
        startDate: dates.startDate,
        endDate: dates.endDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateValue, endDateValue]);
  const clickRow = (event, rowData) =>
    history.push(TEMPO.PROGRAMME_SINGLE.replace(":id", rowData.id));
  return (
    <>
      <S.Container>
        <div>
          <S.Head>
            <S.HeadContent>
              <T.H40Caps color="primary" style={{ margin: 0 }}>
                programmes
              </T.H40Caps>
              <S.StatsContent>
                <S.SubStatsContent>
                  <Stats
                    size="s"
                    statNum={activeProgrammes}
                    statNumColor="pink"
                    statText="active programmes"
                    isLoading={loading}
                    bottomMessage={false}
                    rightMessage={false}
                  />
                </S.SubStatsContent>
              </S.StatsContent>
            </S.HeadContent>
            <S.ImageWrapper>
              <Image image="gallery" alt="gallery" height={210} width={220} />
            </S.ImageWrapper>
          </S.Head>
          <S.TableContainer>
            <S.BtnContainer>
              {[
                tempoRoles.TEMPO_STAFF_ADMIN,
                tempoRoles.TEMPO_STAFF_SUPER,
              ].includes(role) && (
                <Button
                  primary
                  w="auto"
                  m="0 10px 10px 0"
                  onClick={() => history.push(TEMPO.NEW_PROGRAMME)}
                >
                  Create a programme
                </Button>
              )}
              <S.CSVExport>
                <Button
                  outline
                  w="auto"
                  m="0 10px 10px 0"
                  onClick={() => setIsOpen(true)}
                >
                  Export Data
                </Button>
              </S.CSVExport>
            </S.BtnContainer>
            <S.FilterContainer>
              <T.Body16B color="midnight" m="8">
                Filter by created date
              </T.Body16B>
              <FilterByDate dates={dates} setDates={setDates} />
              <T.Body16B color="midnight" m="8">
                Search
              </T.Body16B>
            </S.FilterContainer>
            {/* TODO fix loading state - for now it doesn't render data */}
            {loading && (
              <Table
                isLoading
                tabs
                color="blue"
                tabbedData={[
                  {
                    tabText: "key info",
                    data: programmes,
                    columns: programmesColumns,
                  },
                  {
                    tabText: "other",
                    data: programmes,
                    columns: programmesColumnsOther,
                  },
                ]}
                onRowClick={clickRow}
                flexDir="column"
              />
            )}
            {!loading && (
              <Table
                tabs
                color="blue"
                tabbedData={[
                  {
                    tabText: "key info",
                    data: programmes,
                    columns: programmesColumns,
                  },
                  {
                    tabText: "other",
                    data: programmes,
                    columns: programmesColumnsOther,
                  },
                ]}
                onRowClick={clickRow}
                flexDir="column"
              />
            )}
          </S.TableContainer>
        </div>
      </S.Container>
      <Modal
        modalOpen={isOpen}
        handleCloseModal={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
        custom
        width="690px"
      >
        <S.ModalContent>
          <T.H20 color="blue" mb={20}>
            Export data{" "}
          </T.H20>

          <T.Body16B color="midnight" mb="8">
            Filter by creating date
          </T.Body16B>
          <FilterByDate dates={filterDates} setDates={setFilterDates} />
          <DownloadFile
            url={`/csv/programmes?startDate=${filterDates?.startDate?.format(
              "YYYY-MM-DD",
            )}&endDate=${filterDates?.endDate?.format("YYYY-MM-DD")}`}
          />
        </S.ModalContent>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  programmes: state.staffProgrammes.data,
  activeProgrammes: state.staffProgrammes.activeProgrammes,
  loading: state.staffProgrammes.loading,
  error: state.staffProgrammes.loading,
});

const mapActionsToProps = {
  getProgrammes: actions.getProgrammes,
  getFilteredProgrammes: actions.getFilteredProgrammes,
};

export default connect(mapStateToProps, mapActionsToProps)(Programmes);
