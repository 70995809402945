import React from "react";

const BackArrow = ({ width, height, color, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    width={width}
    height={height}
    {...props}
  >
    <circle
      cx="11"
      cy="11"
      r="11"
      transform="rotate(-90 11 11)"
      fill={color || "currentColor"}
    />
    <path
      d="M10.672 14.938L6.734 11l3.938-3.938M7.281 11h7.985"
      stroke="#fff"
      strokeWidth="1.313"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default BackArrow;
