import React, { useCallback } from "react";
import { connect } from "react-redux";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import BasicInformation from "./BasicInformation";
import { Row, Col } from "../../components/Grid";
import Button from "../../components/Button";
import { InputField, Checkbox } from "../../components/Inputs";
import cleanWhitespace from "../../utils/helpers/cleanWhitespace";
import theme from "../../utils/theme";
import t from "../../utils/helpers/translator";
import * as S from "./style";
import { roles } from "../../constants";

const Step1 = ({
  data = {},
  welshVersion,
  startUpload,
  setImageInfo,
  imageInfo,
  setState,
  errors,
  handleNext,
  loading,
  edit,
  language,
  userRole,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleIsLive = useCallback(
    ({ target: { checked } }) =>
      setState((_) => ({
        ..._,
        isLive: checked,
      })),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const handleFeaturedAt = useCallback(
    ({ target: { checked } }) =>
      setState((_) => ({
        ..._,
        featuredAt: checked ? new Date() : 0,
      })),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const isTempoStaff = [
    roles.TEMPO_STAFF_SUPER,
    roles.TEMPO_STAFF_ADMIN,
    roles.TEMPO_STAFF_USER,
  ].includes(userRole);

  const handleCounter = useCallback(({ counter }) => {
    // handle typing

    if (typeof counter !== "string" && counter < 1) return null;

    return setState((_) => ({
      ..._,
      personTcPrice: data.costVaries ? undefined : counter,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDropdown = useCallback(({ selected }, type) => {
    if (type === "locationType") {
      return setState((_) => ({ ..._, locationType: selected.value }));
    }
    const value = selected ? selected.map((e) => e.value) : [];
    if (value.length <= 3) {
      return setState((_) => ({ ..._, activityTypes: value }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCostVaries = useCallback(({ target: { checked } }) => {
    setState((_) => ({ ..._, costVaries: checked }));
    if (checked) {
      return setState((_) => ({ ..._, onlineCodesToBook: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleText = ({ target: { name, value } }) => {
    switch (name) {
      case "name":
        return setState((_) => ({ ..._, name: value }));

      case "subTitle":
        return setState((_) => ({ ..._, subTitle: value }));

      case "isOnline":
        return setState((_) => ({ ..._, isOnline: value === "true" }));

      case "description":
        return setState((_) => ({ ..._, description: value }));

      case "externalLink":
        return setState((_) => ({ ..._, externalLink: value }));

      // Welsh
      case "nameWelsh":
        return setState((_) => ({
          ..._,
          nameWelsh: cleanWhitespace(value),
        }));

      case "subTitleWelsh":
        return setState((_) => ({
          ..._,
          subTitleWelsh: value,
        }));

      case "descriptionWelsh":
        return setState((_) => ({
          ..._,
          descriptionWelsh: value,
        }));

      case "externalLinkWelsh":
        return setState((_) => ({
          ..._,
          externalLinkWelsh: value,
        }));

      default:
        break;
    }
  };

  const handleKeywords = useCallback(({ target: { value } }, lang) => {
    if (value.trim() === "" || value.trim() === ",") {
      if (lang === "english") {
        return setState((_) => ({ ..._, keywords: [] }));
      }
      return setState((_) => ({ ..._, keywordsWelsh: [] }));
    }

    const keywordsArr = value.split(",");

    if (lang === "english") {
      return setState((_) => ({ ..._, keywords: keywordsArr }));
    }
    return setState((_) => ({ ..._, keywordsWelsh: keywordsArr }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BasicInformation
        name={data.name}
        subTitle={data.subTitle}
        activityTypes={data.activityTypes}
        isOnline={data.isOnline}
        description={data.description}
        personTcPrice={data.personTcPrice || 0}
        costVaries={data.costVaries}
        handleDropdown={handleDropdown}
        handleText={handleText}
        handleCounter={handleCounter}
        handleCostVaries={handleCostVaries}
        errors={errors}
        startUpload={startUpload}
        setImageInfo={setImageInfo}
        imageInfo={imageInfo}
        // Welsh
        welshVersion={welshVersion}
        nameWelsh={data.nameWelsh}
        subTitleWelsh={data.subTitleWelsh}
        descriptionWelsh={data.descriptionWelsh}
        externalLink={data.externalLink}
        externalLinkWelsh={data.externalLinkWelsh}
        language={language}
        locationType={data.locationType}
      />
      <Row mb={8}>
        <Col w={welshVersion ? [4, 6, 6] : [4, 12, 6]}>
          <InputField
            placeholder={`${t("keywordsPlaceholder", language)}...`}
            label={`${t("keywordsLabel", language)}${
              language === "welsh" ? ` (${t("english", language)})` : ""
            }`}
            name="keywords"
            isOptional
            type="textarea"
            value={data.keywords && data.keywords.toString()}
            setValue={(e) => handleKeywords(e, "english")}
            error={errors.keywords}
            justify="center"
            lang={language}
          />
        </Col>
        {welshVersion && (
          <Col w={[4, 6, 6]}>
            <InputField
              placeholder={`${t("keywordsPlaceholder", language)}...`}
              label={`${t("keywordsLabel", language)}${
                language === "english" ? ` (${t("welsh", language)})` : ""
              }`}
              name="keywordsWelsh"
              isOptional
              type="textarea"
              value={data.keywordsWelsh && data.keywordsWelsh.toString()}
              setValue={(e) => handleKeywords(e, "welsh")}
              error={errors.keywordsWelsh}
              justify="center"
              labelColor="blue"
              lang={language}
            />
          </Col>
        )}
      </Row>
      {edit && !data.hideIsLiveCheckbox && (
        <Row mb={5}>
          <Col w={[4, 8, 8]}>
            <Checkbox
              value={data.isLive}
              setValue={handleIsLive}
              mt="10"
              label={t("makeActivityLive", language)}
              name="isLive"
              size={isMobile ? "normal" : "medium"}
              error={errors.isLive}
            />
          </Col>
        </Row>
      )}
      {edit && !data.hideIsLiveCheckbox && isTempoStaff && (
        <Row mb={5}>
          <Col w={[4, 8, 8]}>
            <Checkbox
              value={data.featuredAt}
              setValue={handleFeaturedAt}
              mt="10"
              label={t("makeActivityFeatured", language)}
              name="featuredAt"
              size={isMobile ? "normal" : "medium"}
              error={errors.featuredAt}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col w={[4, 6, 4]}>
          <Button
            primary
            size="l"
            handleClick={() => handleNext(0)}
            loading={loading || (imageInfo.new && !imageInfo.uploadedToS3)}
          >
            {edit ? t("saveChanges", language) : t("nextStep", language)}
          </Button>
        </Col>
        {Object.keys(errors).filter((key) => errors[key]).length > 0 && (
          <Col w={[4, 12, 12]} style={{ marginTop: 20 }}>
            <S.Error>{t("activityFormStepError", language)}</S.Error>
          </Col>
        )}
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  language: state.auth.language,
});
export default connect(mapStateToProps, null)(Step1);
