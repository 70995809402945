import React, { useState } from "react";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";
import Slide from "@material-ui/core/Slide/";
import { GENERAL } from "../../constants/navRoutes";
import { logout as logoutAction } from "../../redux/actions/auth";

import { Body12R, H14Caps, Body16B, Body16M } from "../Typograpy";
import NavLink from "./NavLink";
import Icon from "../Icon";
import Image from "../Image";
import { roles } from "../../constants";

import translate from "../../utils/helpers/translator";
import SpendPartnerNav from "./SpendPartnerNav";

import {
  memberLinks,
  earnGroupLinks,
  staffLinks,
  spendVenueLinks,
  programmeManagerLinks,
  earnGroupAdminLinks,
} from "./navlinksContent";
import {
  Aside,
  List,
  Balance,
  TopNav,
  MenuBtn,
  MobileMenu,
  CloseBtn,
  MenuContent,
  BalanceContainer,
  LogOutButton,
} from "./styled";

const BalanceWrapper = ({ role, TCBalance, lang }) => (
  <Balance>
    <Body12R color="white" mb="5">
      {role === roles.MEMBER
        ? translate("myBalance", lang)
        : translate("balance", lang)}
    </Body12R>
    <H14Caps color="white" className="balance" style={{ lineHeight: "14px" }}>
      {TCBalance || "0"} {translate("timeCreditsNumInFront", lang)}
    </H14Caps>
  </Balance>
);

const Navbar = ({
  userId,
  role,
  currentTc: TCBalance,
  handleLogout,
  isLoggedIn,
  isVerified,
  supervisorId,
  lang,
  tempLogin,
}) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  let links = [];
  switch (role) {
    case roles.MEMBER:
      links = memberLinks;
      break;
    case roles.EARN_GROUP:
      links = earnGroupLinks(isVerified, userId);
      break;
    case roles.EARN_GROUP_ADMIN:
      links = earnGroupAdminLinks(isVerified, supervisorId);
      break;
    case roles.SPEND_VENUE:
      links = spendVenueLinks(isVerified);
      break;
    case roles.PROGRAMME_MANAGER:
      links = programmeManagerLinks();
      break;
    case roles.TEMPO_STAFF_USER:
    case roles.TEMPO_STAFF_ADMIN:
    case roles.TEMPO_STAFF_SUPER:
      links = staffLinks(role);
      break;
    default:
      links = memberLinks;
      break;
  }

  const logOut = () => {
    handleLogout({ history });
    setOpen(false);
  };

  return (
    <>
      <Aside>
        <Image
          image="logoMed"
          alt="tempo logo"
          customStyle={{ padding: "60px 0 0 50px" }}
        />
        {role === roles.SPEND_PARTNER ? (
          <SpendPartnerNav logOut={logOut} />
        ) : (
          <>
            {role === roles.MEMBER && (
              <BalanceWrapper role={role} TCBalance={TCBalance} lang={lang} />
            )}
            {isLoggedIn ? (
              <List>
                {links.map((link) => (
                  <li key={link.to}>
                    <NavLink to={link.to}>
                      {translate(link.title, lang)}
                    </NavLink>
                  </li>
                ))}

                <LogOutButton onClick={logOut} type="button">
                  <Body16M color="white" m={0}>
                    {translate("logOut", lang)}
                  </Body16M>
                </LogOutButton>
              </List>
            ) : (
              <List>
                <li key={GENERAL.LOG_IN}>
                  <NavLink to={GENERAL.LOG_IN}>
                    {translate("logIn", lang)}
                  </NavLink>
                </li>
                <li key={GENERAL.SIGN_UP}>
                  <NavLink to={GENERAL.SIGN_UP}>
                    {translate("signUp", lang)}
                  </NavLink>
                </li>
              </List>
            )}
          </>
        )}
      </Aside>
      <TopNav>
        <div>
          {((role === roles.EARN_GROUP && !tempLogin) ||
            role === roles.MEMBER) && (
            <BalanceContainer>
              <BalanceWrapper role={role} TCBalance={TCBalance} lang={lang} />
            </BalanceContainer>
          )}
        </div>
        <MenuBtn onClick={() => setOpen(true)}>
          <Body16B color="white">{translate("menu", lang)}</Body16B>
          <Icon
            icon="menu"
            width="40px"
            height="40px"
            color="white"
            margin="0 0 0 5px"
          />
        </MenuBtn>
      </TopNav>
      <Slide direction="left" in={open} mountOnEnter unmountOnExit>
        <MobileMenu>
          <CloseBtn onClick={() => setOpen(false)}>
            <Icon icon="close" width="40px" height="40px" color="white" />
          </CloseBtn>
          <MenuContent>
            {role === roles.SPEND_PARTNER ? (
              <SpendPartnerNav mobile onClick={setOpen} logOut={logOut} />
            ) : (
              <List>
                {isLoggedIn ? (
                  <>
                    {links.map((link) => (
                      <li key={link.to}>
                        <NavLink
                          to={link.to}
                          mobile
                          onClick={setOpen}
                          lang={lang}
                        >
                          {translate(link.title, lang)}
                        </NavLink>
                      </li>
                    ))}
                    <LogOutButton onClick={logOut} type="button">
                      <Body16M color="white" m={0}>
                        {translate("logOut", lang)}
                      </Body16M>
                    </LogOutButton>
                  </>
                ) : (
                  <List>
                    <li key={GENERAL.LOG_IN}>
                      <NavLink to={GENERAL.LOG_IN}>
                        {translate("logIn", lang)}
                      </NavLink>
                    </li>
                    <li key={GENERAL.SIGN_UP}>
                      <NavLink to={GENERAL.SIGN_UP}>
                        {translate("signUp", lang)}
                      </NavLink>
                    </li>
                  </List>
                )}
              </List>
            )}
          </MenuContent>
        </MobileMenu>
      </Slide>
    </>
  );
};

const mapActionToProps = {
  handleLogout: logoutAction,
};

const mapStateToProps = (state) => ({
  role: state.auth.role,
  userId: state.auth.id,
  supervisorId: state.auth.supervisorId,
  isVerified: state.auth.isVerified,
  isLoggedIn: state.auth.isLoggedIn,
  currentTc: state.user.tc.tcBalance,
  lang: state.auth.language,
  tempLogin: state.auth.tempLogin,
});

export default connect(mapStateToProps, mapActionToProps)(Navbar);
