import React from "react";
import * as S from "./style";
import * as T from "../../../components/Typograpy";
import CreditCard from "../../../components/Cards/CreditCard";

import t from "../../../utils/helpers/translator";
import PopQuestion from "../../../components/PopQuestion";
import ChangeAccount from "../../../components/ChangeAccount";
import { navRoutes } from "../../../constants";

const Header = (props) => {
  const {
    isMobile,
    userInfo,
    currentTc,
    getUserCurrentTcLoading,
    lang,
    multipleUsers,
  } = props;

  const userTc = currentTc && currentTc;
  const { name, membershipId } = userInfo;

  const renderMemberCard = (width) => (
    <CreditCard
      width={width}
      credits={userTc || "0"}
      memberID={membershipId}
      type="digital"
      loading={getUserCurrentTcLoading}
    />
  );

  const renderDesktop = () => (
    <S.HeaderWrapper>
      <S.MemberDetails>
        <T.H40 style={{ marginLeft: "0" }} color="midnight">
          {t("welcomeBack", lang)}, <br /> {name}!
        </T.H40>
        {multipleUsers && <ChangeAccount to={navRoutes.GENERAL.SELECT_USER} />}
        <S.MembershipIDContainer multipleUsers={multipleUsers}>
          <T.H30 color="blue" ml="0">
            {membershipId}
          </T.H30>
          <div
            style={{
              position: "relative",
              width: "fit-content",
            }}
          >
            <T.Body18I caps style={{ marginLeft: "0" }}>
              {t("membershipID", lang)}
            </T.Body18I>
            <PopQuestion
              iconPosition={{ top: "auto", right: "-32px", bottom: "-20px" }}
              popperProps={{
                width: "300px",
                placement: "right",
                alignIcon: "flex-start",
                iconWidth: "170px",
                margin: "0 0 0 14px",
              }}
            >
              <T.Body12B color="midnight">
                {t("membershipIdExplainer", lang)}
              </T.Body12B>
            </PopQuestion>
          </div>
        </S.MembershipIDContainer>
      </S.MemberDetails>
      <S.MemberCardContainer>{renderMemberCard("300px")}</S.MemberCardContainer>
    </S.HeaderWrapper>
  );

  const renderMobile = () => (
    <S.HeaderWrapperMobile>
      <S.MembershipIDContainer>
        <T.H30 color="blue">{membershipId}</T.H30>
        <div
          style={{
            position: "relative",
            width: "fit-content",
          }}
        >
          <T.Body18I caps>{t("membershipID", lang)}</T.Body18I>
          <PopQuestion
            iconPosition={{ top: "auto", right: "-32px", bottom: "-20px" }}
            popperProps={{
              width: "300px",
              placement: "bottom",
              alignIcon: "flex-start",
              iconWidth: "170px",
              margin: "0 10px 10px 0",
              position: "absolute",
              right: "-18px",
              top: "10px",
            }}
          >
            <T.Body12B color="midnight">
              {t("membershipIdExplainer", lang)}
            </T.Body12B>
          </PopQuestion>
        </div>
      </S.MembershipIDContainer>
      <S.MemberDetails>
        <T.H24
          style={{ marginLeft: "0", marginBottom: "20px" }}
          color="midnight"
        >
          {t("welcomeBack", lang)}, {name}!
          {multipleUsers && (
            <ChangeAccount to={navRoutes.GENERAL.SELECT_USER} />
          )}
        </T.H24>

        {renderMemberCard("300px")}
      </S.MemberDetails>
    </S.HeaderWrapperMobile>
  );

  return isMobile ? renderMobile() : renderDesktop();
};

export default Header;
