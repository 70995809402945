import { styled } from "@material-ui/core/styles";

export const Wrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
}));

// START HEADER
export const HeaderWrapper = styled("header")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
}));

export const HeaderWrapperMobile = styled("header")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  width: "100%",
}));

export const MemberDetails = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  paddingTop: theme.spacing(1),
  width: "100%",
  color: theme.colors.midnight,

  [theme.breakpoints.tablet]: {
    paddingTop: "0",
  },
}));

export const MembershipIDContainer = styled("div")(
  ({ theme, multipleUsers }) => ({
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-end",
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(3),

    [theme.breakpoints.mobileXL]: {
      display: "flex",
      flexDirection: "column",
      alignSelf: "flex-start",
      paddingTop: multipleUsers ? "1rem" : "2rem",
    },
  }),
);

export const MemberCardContainer = styled("div")(() => ({}));

// END HEADER

export const Section = styled("section")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  width: "100%",
  paddingTop: theme.spacing(8),
  // desktop view
  [theme.breakpoints.tablet]: {
    alignItems: "flex-start",
  },
}));

export const TransactionSection = styled(Section)({
  "& div": {
    width: "100%",
  },
});

export const CardsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  overflowX: "auto",
  width: "calc(100% + 18px)",
  left: "-10px",
  paddingLeft: "10px",
  paddingRight: "10px",
  position: "relative",
  color: theme.colors.midnight,

  [theme.breakpoints.mobileM]: {
    width: "calc(100% + 58px)",
    left: "-30px",
    paddingLeft: "30px",
  },

  [theme.breakpoints.mobileXL]: {
    width: "calc(100% + 58px + 5%)",
    left: "-60px",
    paddingLeft: "60px",
  },

  [theme.breakpoints.tablet]: {
    marginLeft: "0",
  },
}));

export const Card = styled("div")(({ disabled }) => ({
  margin: "5px",
  opacity: disabled ? "0.3" : "1",
}));

export const FeatureWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  boxShadow: theme.customShadows.card,
  borderRadius: "4px",
  position: "absolute",
  background: "white",
  top: theme.spacing(3),
  left: theme.spacing(3),
}));
