import React from "react";

import { connect } from "react-redux";
import { logout } from "../../../../../redux/actions/auth";

import * as S from "./style";
import * as T from "../../../../../components/Typograpy";
import { Row, Col } from "../../../../../components/Grid";
import SpendButton from "../../../../../components/SpendButton";
import Image from "../../../../../components/Image";

import translate from "../../../../../utils/helpers/translator";

const PickCards = ({
  authorized,
  isTablet,
  lang,
  spendActivity,
  setSpendOption,
}) => {
  const {
    turnUpAtVenue,
    callToBook,
    onlineCodesToBook,
    noCodesAvailable,
    isLive,
  } = spendActivity;

  const renderCards = () => {
    if (
      turnUpAtVenue ||
      callToBook ||
      (onlineCodesToBook && noCodesAvailable > 0)
    ) {
      return (
        <>
          <Row style={{ flex: "1" }}>
            {turnUpAtVenue && (
              <Col w={[4, 6, 4]}>
                <SpendButton
                  size="l"
                  icon="arrowWhite"
                  bgColor="blue"
                  m="0 0 20px 0"
                  onClick={() => setSpendOption("turnUp")}
                >
                  {translate("turnUp", lang)}
                  <br /> ({translate("noNeedToBook", lang)})
                </SpendButton>
              </Col>
            )}
            {callToBook && (
              <Col w={[4, 6, 4]}>
                <SpendButton
                  size="l"
                  icon="arrowWhite"
                  bgColor="pink"
                  m="0 0 20px 0"
                  onClick={() => setSpendOption("callEmail")}
                >
                  {translate("callEmail", lang)}
                </SpendButton>
              </Col>
            )}
          </Row>
          {onlineCodesToBook && noCodesAvailable > 0 && (
            <Row>
              <Col w={[4, 6, 4]}>
                <SpendButton
                  size="l"
                  icon="arrowWhite"
                  bgColor="midnight"
                  onClick={() =>
                    setSpendOption(authorized ? "bookOnline" : "notAuthorized")
                  }
                >
                  {translate("getOnlineBooking", lang)}
                </SpendButton>
              </Col>
            </Row>
          )}
        </>
      );
    }
    return (
      <Row mb={8} jcT="center">
        <Col w={[4, 8, 8]}>
          <T.Body16R color="gray3">
            {translate("noSpendOptions", lang)}.
          </T.Body16R>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <S.Header>
        <Row mb={6}>
          <Col w={[4, 12, 8]}>
            {isTablet ? (
              <T.H30Caps color="midnight" m="0">
                {translate("pickOneOptionShort", lang)}
              </T.H30Caps>
            ) : (
              <T.H40Caps color="midnight" m="0">
                {translate("pickOneOptionShort", lang)}
              </T.H40Caps>
            )}
          </Col>
          <Col w={[0, 0, 4]} style={{ position: "absolute", right: -150 }}>
            <S.SideImageWrapper>
              <Image image="gallery" alt="group" height={248} />
            </S.SideImageWrapper>
          </Col>
        </Row>
      </S.Header>

      {isLive ? (
        renderCards()
      ) : (
        <Row>
          <Col w={[4, 10, 8]}>
            <T.Body16R color="gray3">
              {translate("activityNotLive", lang)}.
            </T.Body16R>
          </Col>
        </Row>
      )}
    </>
  );
};

const mapActionToProps = {
  logoutAction: logout,
};

export default connect(null, mapActionToProps)(PickCards);
