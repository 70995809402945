import React, { useEffect, useReducer, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import * as S from "./style";
import Image from "../../../../components/Image";
import * as T from "../../../../components/Typograpy";
import InputField from "../../../../components/Inputs/InputField";
import Dropdown from "../../../../components/Inputs/Dropdown";
import SingleDatePicker from "../../../../components/Inputs/SingleDatePicker";
import { Col, Row } from "../../../../components/Grid";
import Button from "../../../../components/Button";
import Notification from "../../../../components/Notification";
import GoBack from "../../../../components/GoBack";
import RemoveRejoin from "../../../../components/RemoveRejoin";

import validate from "../../../../validation/schemas/create-programme";

import { programmes } from "../../../../api-calls";
import { navRoutes } from "../../../../constants";
import {
  focusDropDownEnglish,
  regionsDropDownEnglish,
} from "../../../../constants/dropdDownData";
import cleanWhitespace from "../../../../utils/helpers/cleanWhitespace";
import ExtProgManagerSection from "./ExtProgManagerSection";

const initialState = {
  name: "",
  funderName: "",
  startDate: null,
  endDate: null,
  budget: undefined,
  mainFocus: "",
  selectedMainFocus: null,
  otherKeyFocuses: [],
  selectedOtherKeyFocuses: null,
  regionCountry: "",
  region: "",
  selectedRegion: null,
  managers: [""],
  errors: {},
};

const reducer = (state, action) => {
  const { name, value, type, selected, errors } = action;
  switch (type) {
    case "change":
      return { ...state, [name]: value };
    case "dropDownChange":
      return {
        ...state,
        [name]: value,
        [`selected${name[0].toUpperCase()}${name.slice(1)}`]: selected,
      };
    case "regionChange":
      return {
        ...state,
        region: value,
        regionCountry: selected.country,
        selectedRegion: selected,
      };
    case "setInitial":
      return {
        ...state,
        ...action.data,
        errors: {},
      };
    case "error":
      return {
        ...state,
        errors,
      };
    default:
      throw new Error("invalid action type");
  }
};

const CreateProgramme = ({ edit }) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [submit, setSubmit] = useState(false);
  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const [prog, setProg] = useState({});

  const {
    name,
    funderName,
    startDate,
    budget,
    mainFocus,
    otherKeyFocuses,
    region,
    managers,
  } = state;
  useEffect(() => {
    if (submit) {
      try {
        validate(state);
        dispatch({ type: "error", errors: {} });
      } catch (error) {
        dispatch({ type: "error", errors: error.inner });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    name,
    funderName,
    startDate,
    budget,
    mainFocus,
    otherKeyFocuses,
    region,
    managers,
  ]);

  const { id: programmeId } = useParams();

  useEffect(() => {
    async function getProgramme() {
      const { data: programme } = await programmes.getProgramme({
        programmeId,
        type: "profile",
        options: { showModal: true },
      });
      setProg({ ...programme });
      const editInitialState = {
        ...programme,
      };
      editInitialState.selectedMainFocus = {
        label: programme.mainFocus,
        value: programme.mainFocus,
      };
      editInitialState.selectedRegion = {
        country: programme.regionCountry,
        label: programme.region,
        value: programme.region,
      };
      editInitialState.selectedOtherKeyFocuses = programme.otherKeyFocuses.map(
        (e) => ({ label: e, value: e }),
      );
      dispatch({ type: "setInitial", data: editInitialState });
    }
    if (programmeId) {
      getProgramme();
    }
  }, [programmeId]);

  const onChange = (e) => {
    const { name: _name, value } = e.target;

    dispatch({ type: "change", value, name: _name });
  };

  const onChangeBudget = (e) => {
    const { value } = e.target;

    dispatch({
      type: "change",
      value: Number(value.replace(/,/g, "")),
      name: "budget",
    });
  };

  const onStartDateChange = (date) => {
    dispatch({ type: "change", value: date, name: "startDate" });
  };

  const onEndDateChange = (date) => {
    dispatch({ type: "change", value: date, name: "endDate" });
  };

  const onRegionChange = ({ selected }) => {
    dispatch({
      type: "regionChange",
      value: selected && selected.value,
      selected,
      name: "region",
    });
  };

  const onMainFocusChange = ({ selected }) => {
    dispatch({
      type: "dropDownChange",
      value: selected && selected.value,
      selected,
      name: "mainFocus",
    });
  };
  const onOtherFocusesChange = ({ selected }) => {
    const value = selected ? selected.map((e) => e.value) : [];
    if (value.length <= 2) {
      dispatch({
        type: "dropDownChange",
        value: selected && selected.map((e) => e.value),
        selected,
        name: "otherKeyFocuses",
      });
    }
  };

  useEffect(() => {
    const managersArr =
      prog.managers && prog.managers.length ? prog.managers : [""];
    dispatch({ type: "change", value: managersArr, name: "managers" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addEmailField = () => {
    const newFields = [...managers, ""];
    dispatch({ type: "change", value: newFields, name: "managers" });
  };

  const removeEmailField = (e, index) => {
    e.preventDefault();
    if (managers.length <= 1) {
      return dispatch({ type: "change", value: [""], name: "managers" });
    }
    const newManagerArr = managers.filter((manager, i) => index !== i);
    dispatch({ type: "change", value: newManagerArr, name: "managers" });
  };

  const onEmailChange = (e, index) => {
    const { value } = e.target;
    const updatedManagers = [...managers];
    updatedManagers[index] = value;
    dispatch({ type: "change", value: updatedManagers, name: "managers" });
  };

  const tidyEmails = () => managers.filter((manager) => manager !== "");

  const parseErrors = (errors) =>
    Object.entries(errors).reduce((acc, curr) => {
      const [_key, _value] = curr;
      const key = _key.split("[")[0];
      acc[key] = _value;
      return acc;
    }, {});

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!submit) {
      setSubmit(true);
    }
    setLoading(true);
    try {
      const cleanManagers = tidyEmails();
      validate(state);
      const { data, error } = await programmes.createProgramme({
        programmeData: {
          name: cleanWhitespace(name),
          funderName: cleanWhitespace(funderName),
          startDate,
          endDate: state.endDate,
          budget,
          mainFocus,
          otherKeyFocuses,
          regionCountry: state.regionCountry,
          region,
          managers: cleanManagers,
        },
      });
      setLoading(false);

      if (error) {
        setServerError(error);
      } else {
        history.push(navRoutes.TEMPO.PROGRAMME_SINGLE.replace(":id", data.id), {
          justCreated: true,
        });
      }
    } catch (error) {
      setLoading(false);
      if (error.inner) {
        dispatch({ type: "error", errors: parseErrors(error.inner) });
      }
    }
  };

  const onSave = async (e) => {
    e.preventDefault();
    if (!submit) {
      setSubmit(true);
    }
    setLoading(true);
    try {
      validate(state);

      const result = await programmes.editProgramme({
        programmeData: {
          name,
          funderName,
          startDate,
          endDate: state.endDate,
          budget,
          mainFocus,
          otherKeyFocuses,
          regionCountry: state.regionCountry,
          region,
        },
        programmeId,
        options: { showModal: true },
      });
      setLoading(false);

      if (result.error) {
        setServerError(result.error);
      } else {
        setSaved(true);
      }
    } catch (error) {
      setLoading(false);
      if (error.inner) {
        dispatch({ type: "error", errors: parseErrors(error.inner) });
      }
    }
  };

  const validationError = !!Object.keys(state.errors).length;

  return (
    <S.Container>
      <Notification
        open={saved}
        setOpen={setSaved}
        message="changes saved"
        duration={3000}
      />
      <Row mb={6}>
        <Col w={[4, 12, 12]}>
          <GoBack showText mb={2} />
        </Col>
      </Row>

      <Row jc="space-between">
        <T.H40Caps ml="10" mb="60" color="midnight">
          {!programmeId ? "Add Programme" : "Edit Programme"}
        </T.H40Caps>
        <S.ImageWrapper>
          <Image image="gallery" alt="gallery" height={210} width={220} />
        </S.ImageWrapper>
      </Row>
      <S.Form>
        <Row mb={5}>
          <Col w={[4, 6, 4]}>
            <InputField
              placeholder="Type here..."
              label="Programme name"
              name="name"
              value={name}
              setValue={onChange}
              error={state.errors.name}
              autoComplete="new-password"
            />
          </Col>
          <Col w={[4, 6, 4]}>
            <InputField
              placeholder="Type here..."
              label="Funder name"
              name="funderName"
              value={funderName}
              setValue={onChange}
              isOptional
              error={state.errors.funder}
              autoComplete="new-password"
            />
          </Col>
        </Row>

        <Row mb={5}>
          <Col w={[4, 6, 4]}>
            <SingleDatePicker
              label="Start date"
              value={state.startDate}
              setValue={onStartDateChange}
              disableFuture={false}
              error={state.errors.startDate}
            />
          </Col>
          <Col w={[4, 6, 4]}>
            <SingleDatePicker
              label="End date"
              value={state.endDate}
              setValue={onEndDateChange}
              disableFuture={false}
              error={state.errors.endDate}
              isOptional
            />
          </Col>
        </Row>
        <Row mb={5} ai="flex-end">
          <Col w={[4, 6, 4]}>
            <InputField
              placeholder="Type here..."
              label="Programme Budget"
              helper="Time Credits"
              name="budget"
              value={budget && Number(budget).toLocaleString("en")}
              setValue={onChangeBudget}
              error={state.errors.budget}
              autoComplete="new-password"
            />
          </Col>
          <Col w={[4, 6, 4]}>
            <Dropdown
              name="mainFocus"
              options={focusDropDownEnglish}
              value={state.selectedMainFocus}
              setValue={onMainFocusChange}
              label="Main Focus"
              placeholder="Select a focus"
              error={state.errors.mainFocus}
              isOptional
            />
          </Col>
        </Row>
        <Row mb={5}>
          <Col w={[4, 6, 4]}>
            <Dropdown
              name="otherFocuses"
              options={focusDropDownEnglish}
              value={state.selectedOtherKeyFocuses}
              setValue={onOtherFocusesChange}
              label="Other Key Focuses"
              helper="select up to two more"
              placeholder="Select your focus(es)"
              isMulti
              allowSelectAll={false}
              fixedHeight={false}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              max={2}
              error={state.errors.otherKeyFocuses}
            />
          </Col>
          <Col w={[4, 6, 4]} style={{ paddingTop: "25px" }}>
            <Dropdown
              name="region"
              options={regionsDropDownEnglish}
              value={state.selectedRegion}
              setValue={onRegionChange}
              label="Region"
              placeholder="Select a region"
              error={state.errors.region}
            />
          </Col>
        </Row>
        {!edit && (
          <div>
            <Row mt={8} mb={5}>
              <Col w={[4, 8, 8]}>
                <T.H16 color="midnight" mb={10}>
                  External Programme Managers
                </T.H16>
                <T.Body14R color="gray3">
                  You are able to add up to 3 external programme managers
                  (people outside of Tempo who will be managing the programme).
                  Once you click to create the programme they will be emailed a
                  link to sign up and gain access.
                </T.Body14R>
              </Col>
            </Row>
            {managers.map((manager, i) => (
              <Row mb={2}>
                <Col w={[3, 8, 4]}>
                  <InputField
                    name="managers"
                    value={manager}
                    placeholder="Type email here..."
                    setValue={(e) => onEmailChange(e, i)}
                    autoComplete="new-password"
                    error={
                      serverError?.data?.managers &&
                      serverError?.data?.email === manager
                    }
                  />
                </Col>
                {(managers.length > 1 || manager) && (
                  <Col w={[1, 2, 2]}>
                    <RemoveRejoin
                      handleClick={(e) => removeEmailField(e, i)}
                      type="Remove"
                    />
                  </Col>
                )}
              </Row>
            ))}
            {managers.length < 3 && (
              <Row mb={6}>
                <Col w={[4, 8, 4]}>
                  <S.AddButton type="button" onClick={addEmailField}>
                    <T.LinkS14 color="blue">+ Add another</T.LinkS14>
                  </S.AddButton>
                </Col>
              </Row>
            )}
          </div>
        )}
        <Row mt={5} mb={9}>
          <Col w={[4, 6, 4]}>
            <Button
              primary
              size="l"
              handleClick={edit ? onSave : onSubmit}
              loading={loading}
              type="submit"
            >
              {edit ? "Save changes" : "Create programme"}
            </Button>
            {validationError && (
              <T.Body14R color="pink" mt={10}>
                At least one of the input fields has not been filled in or
                details entered incorrectly. Please check the form above for
                more details.
              </T.Body14R>
            )}
            {serverError?.message && (
              <T.Body14R color="pink" mt={10}>
                {serverError.message}
              </T.Body14R>
            )}
          </Col>
        </Row>
      </S.Form>
      {edit && <ExtProgManagerSection />}
    </S.Container>
  );
};

export default CreateProgramme;
