import React from "react";

const RunningActivity = ({ width, height, color, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-1 1 24 26"
    width={width}
    height={height}
    {...props}
  >
    <path
      d="M12 5.5a2 2 0 002-2V3a2 2 0 10-4 0v.5a2 2 0 002 2zM11 3a1 1 0 012 0v.5a1 1 0 01-2 0V3zM13.83 6.3a10.97 10.97 0 00-3.66 0A2 2 0 008.5 8.27v2.23a.5.5 0 00.5.5h6a.5.5 0 00.5-.5V8.27a2 2 0 00-1.67-1.97zm.67 3.7h-5V8.27a1 1 0 01.835-1 10.06 10.06 0 013.33 0 1 1 0 01.835 1V10zM20.5 15.5a2 2 0 002-2V13a2 2 0 00-4 0v.5a2 2 0 002 2zm-1-2.5a1 1 0 012 0v.5a1 1 0 01-2 0V13zM22.33 16.3a10.968 10.968 0 00-3.66 0A2 2 0 0017 18.27v2.23a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-2.23a2 2 0 00-1.67-1.97zM23 20h-5v-1.73a1 1 0 01.835-1 10.06 10.06 0 013.33 0 1 1 0 01.835 1V20zM3.5 15.5a2 2 0 002-2V13a2 2 0 00-4 0v.5a2 2 0 002 2zm-1-2.5a1 1 0 112 0v.5a1 1 0 11-2 0V13zM5.33 16.3a10.968 10.968 0 00-3.66 0A2 2 0 000 18.27v2.23a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-2.23a2 2 0 00-1.67-1.97zM6 20H1v-1.73a1 1 0 01.835-1 10.06 10.06 0 013.33 0 1 1 0 01.835 1V20zM14 16.065l-1.5-.855V13.5a.5.5 0 00-1 0v1.71l-1.5.855a.502.502 0 00.5.87l1.5-.86 1.5.86a.502.502 0 10.5-.87z"
      fill={color || "currentColor"}
    />
  </svg>
);
export default RunningActivity;
