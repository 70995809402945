import t from "../../../../../utils/helpers/translator";

export const concatArrayToString = (arr, lang) => {
  if (arr.length < 1) return "";
  return arr.reduce((accumulator, currentValue, index, array) => {
    if (index === 0) {
      return accumulator;
    }
    if (!currentValue) {
      return accumulator;
    }
    if (index === array.length - 1) {
      return `${accumulator} ${t("and", lang)} ${currentValue}`;
    }
    return `${accumulator}, ${currentValue}`;
  }, arr[0]);
};

export const getRestrictionText = (obj, lang) => {
  const arrOfKeys = Object.keys(obj).filter((key) => !!obj[key]);

  if (arrOfKeys.length > 0) {
    const firstPart = t("restrictionExists", lang);
    const contentArray = arrOfKeys
      .filter((key) => key !== "capacity")
      .map((key) => t(key, lang));
    const secondPart = concatArrayToString(contentArray, lang);
    return `${firstPart} ${secondPart}`;
  }
};

export const hasRestrictions = (obj) =>
  Object.values(obj).filter((e) => !!e).length > 0;

export const restrictionReached = (obj) =>
  Object.keys(obj).some((key) => obj[key]);

export const findFirstRestrictionReached = (obj) =>
  Object.keys(obj).find((i) => obj[i]?.isReached);
