import React from "react";

import * as S from "./style";
import * as T from "../../../components/Typograpy";
import { roles, navRoutes } from "../../../constants";

import GoBack from "../../../components/GoBack";
import TimeCredit from "../../../components/TimeCredit";

import OpportunityLink from "../../../components/Links/OpportunityLink";
import calendarFormatter from "../../../utils/helpers/calendarFormatter";
import t from "../../../utils/helpers/translator";

const IndividualTransactionMobile = ({
  transaction,
  decideActivityTitle,
  venue,
  lang,
}) => {
  const { createdAt, tc: credits, type, activity = {} } = transaction;

  const {
    title,
    welshTitle,
    startDate: activityStartDate,
    endDate: activityEndDate,
    name: activityName,
  } = activity;

  const getAddresses = () => {
    const _addressLine1 =
      lang === "welsh" && venue.addressLine1Welsh
        ? venue.addressLine1Welsh
        : venue.addressLine1;
    const _addressLine2 =
      lang === "welsh" && venue.addressLine2Welsh
        ? venue.addressLine2Welsh
        : venue.addressLine2;
    return (
      (_addressLine1 || _addressLine2) &&
      `${_addressLine1 || ""} ${_addressLine2 || ""}`
    );
  };
  return (
    <S.IndividualWrapper>
      <GoBack showText />
      <S.IndividualHeader>
        <T.H20 color="midnight" mb={5}>
          {lang === "welsh"
            ? welshTitle || decideActivityTitle(type, activityName)
            : title || decideActivityTitle(type, activityName)}
        </T.H20>
        <T.H14CapsS color="gray3">
          {calendarFormatter(createdAt, lang)}
        </T.H14CapsS>
      </S.IndividualHeader>
      <S.Details>
        <TimeCredit
          credits={credits}
          gradient="blue"
          color="blue"
          description
        />
      </S.Details>
      <S.Details>
        {venue.role === roles.SPEND_VENUE ? (
          <OpportunityLink
            path={navRoutes.GENERAL.SPEND_VENUE.replace(":id", venue.userId)}
            address={getAddresses()}
            venue={
              lang === "welsh" && venue.involvedWelsh
                ? venue.involvedWelsh
                : venue.involved
            }
          />
        ) : (
          <T.Body16R>
            {lang === "welsh" && venue.involvedWelsh
              ? venue.involvedWelsh
              : venue.involved}
          </T.Body16R>
        )}
      </S.Details>
      {activityName && (
        <S.OptionalDetail>
          <T.H20 color="midnight" mb={10}>
            {t("activityName", lang)}
          </T.H20>
          <T.Body16R>{decideActivityTitle(type, activityName)}</T.Body16R>
        </S.OptionalDetail>
      )}
      {(activityStartDate || activityEndDate) && (
        <S.OptionalDetail>
          <T.H20 color="midnight" mb={10}>
            {t("activityDate", lang)}
          </T.H20>
          {activityStartDate && (
            <T.Body16R>{calendarFormatter(activityStartDate, lang)}</T.Body16R>
          )}
          {activityEndDate && (
            <T.Body16R>
              {t("to", lang)} {calendarFormatter(activityEndDate, lang)}
            </T.Body16R>
          )}
        </S.OptionalDetail>
      )}
    </S.IndividualWrapper>
  );
};

export default IndividualTransactionMobile;
