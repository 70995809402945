import React, { useEffect } from "react";
import ReactGA from "react-ga4";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import * as S from "./style";
import * as T from "../../../components/Typograpy";

import { MEMBER } from "../../../constants/navRoutes";
import { roles } from "../../../constants/index";

import Image from "../../../components/Image";
import InviteLink from "../../../components/Links/InviteLink";
import Button from "../../../components/Button";
import translate from "../../../utils/helpers/translator";

const GiftSuccess = ({ uniqueLink, recipient, lang }) => {
  const history = useHistory();

  const handleClick = () => history.push(MEMBER.DASHBOARD);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.event({
        category: "Member",
        action: "Gifted TCs",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Wrapper success>
      <S.SuccessImage>
        <Image image="success" alt="success" width="200px" height="100%" />
      </S.SuccessImage>
      <T.H40Caps color="midnight" mb={20} style={{ textAlign: "center" }}>
        {translate("giftSent", lang)}!
      </T.H40Caps>
      {recipient.role !== roles.MEMBER ? (
        <T.Body16R color="gray3">
          {translate("congratulationsGiftReceived", lang, [recipient.name])}!
        </T.Body16R>
      ) : (
        <>
          <T.Body16B color="midnight" mb={20} style={{ textAlign: "center" }}>
            {translate("congratulationsEmailSent", lang)}.{" "}
          </T.Body16B>
          <T.Body16B color="midnight" mb={5} style={{ textAlign: "center" }}>
            {translate("didntReceiveEmail", lang)}?
          </T.Body16B>
          <T.Body16R color="gray3" mb={20} style={{ textAlign: "center" }}>
            {translate("copyLinkExplanation", lang)}
          </T.Body16R>
          <InviteLink color="pink" background="pinkLight" url={uniqueLink} />
        </>
      )}

      <Button primary onClick={handleClick} mt={40} size="l">
        {translate("goToHomePage", lang)}
      </Button>
    </S.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  uniqueLink: `${window.location.origin}/sign-up/?gift=${state.giftTimeCredits.createdGiftToken}`,
  recipient: state.giftTimeCredits.recipient,
  lang: state.auth.language,
});

export default connect(mapStateToProps)(GiftSuccess);
