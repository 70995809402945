import React from "react";

import Table from "../../../../components/Table";
import RemoveRejoin from "../../../../components/RemoveRejoin";
import { Dropdown } from "../../../../components/Inputs";
import { roles } from "../../../../constants/index";

import * as T from "../../../../components/Typograpy";

const AccessTable = ({
  tempoUsers,
  staffRoles,
  requestUpdate,
  isLoading,
  userId,
}) => {
  const createLabel = (role) => {
    switch (role) {
      case roles.TEMPO_STAFF_ADMIN:
        return "Admin";
      case roles.TEMPO_STAFF_SUPER:
        return "Super Admin";
      default:
        return "User";
    }
  };

  const cleanData = (userData) => {
    const updatedData = userData.map((user) => {
      const updatedUser = {
        ...user,
        dropdownDetails: { value: user.role, label: createLabel(user.role) },
      };
      return updatedUser;
    });
    return updatedData;
  };

  const columns = [
    { title: "Email", field: "email" },
    {
      title: "Role",
      field: "role",
      disableClick: true,
      render: (rowData) => (
        <Dropdown
          placeholder="Select..."
          options={staffRoles}
          customSelectFunc={(e) => {
            requestUpdate(rowData, "updateRole", e);
          }}
          size="small"
          value={rowData.dropdownDetails}
          disabled={
            rowData.id === userId || rowData.role === roles.TEMPO_STAFF_SUPER
          }
        />
      ),
    },
    {
      title: "",
      field: "removeRejoin",
      disableClick: true,
      render: (rowData) =>
        rowData.id !== userId && rowData.role !== roles.TEMPO_STAFF_SUPER ? (
          <RemoveRejoin
            type="Remove"
            handleClick={() => requestUpdate(rowData, "remove")}
            showText
          />
        ) : (
          <>
            {rowData.id === userId && (
              <T.Body14I color="gray3">(you)</T.Body14I>
            )}
            {rowData.role === roles.TEMPO_STAFF_SUPER && (
              <T.Body14I color="gray3">(super account)</T.Body14I>
            )}
          </>
        ),
    },
  ];

  return (
    <Table
      data={cleanData(tempoUsers)}
      columns={columns}
      color="blue"
      isLoading={isLoading}
    />
  );
};

export default AccessTable;
