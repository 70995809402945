import {
  GET_SPEND_VENUE_OFFERS_LOADING,
  GET_SPEND_VENUE_OFFERS_SUCCESS,
  GET_SPEND_VENUE_OFFERS_FAIL,
} from "../../../redux/types";

const initState = {
  loading: false,
  activities: [],
  error: null,
};

const spendVenueOffersReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SPEND_VENUE_OFFERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_SPEND_VENUE_OFFERS_SUCCESS:
      return {
        activities: payload,
        loading: false,
      };
    case GET_SPEND_VENUE_OFFERS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default spendVenueOffersReducer;
