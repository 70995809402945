import { styled } from "@material-ui/core/styles";

const Wrapper = styled("button")(() => ({
  cursor: "pointer",
  border: "none",
  background: "none",
  display: "flex",
  justifyContent: "flexStart",
  alignItems: "center",
  outline: "none",
}));

export default Wrapper;
