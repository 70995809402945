import React from "react";

import DigitalInvite from "./DigitalInvite";
import NonDigitalInvite from "./NonDigitalInvite";
import Success from "./Success";

const Invite = ({ inviteType, ...props }) => {
  switch (inviteType) {
    case "nonDigital":
      return <NonDigitalInvite />;
    case "success":
      return <Success />;
    default:
      return <DigitalInvite {...props} />;
  }
};

export default Invite;
