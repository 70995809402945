import Grid from "@material-ui/core/Grid";
import styled from "@material-ui/styles/styled";
import Typograpy from "@material-ui/core/Typography";
import { H60Caps } from "../../components/Typograpy";

export const LargeImage = styled(Grid)(({ theme }) => ({
  width: "100%",
  "& img": {
    padding: 0,
  },
  display: "none",
  [theme.breakpoints.tablet]: {
    display: "block",
  },
}));
export const SmallImage = styled(Grid)(({ theme }) => ({
  width: "100%",
  "& img": {
    padding: 0,
  },
  display: "block",
  [theme.breakpoints.tablet]: {
    display: "none",
  },
}));

export const GridContent = styled(Grid)(({ theme }) => ({
  width: "100%",
  paddingLeft: 0,
  margin: "0 auto",
  maxWidth: "400px",
  [theme.breakpoints.tablet]: {
    width: "100%",
    textAlign: "start",
    paddingLeft: 50,
  },
}));

export const GridWlecome = styled(Grid)(({ theme }) => ({
  display: "none",
  textAlign: "center",
  transform: "translateY(-30px)",
  [theme.breakpoints.tablet]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export const TextLinks = styled(Typograpy)(({ theme }) => ({
  color: theme.colors.gray3,
  ...theme.typography.placeholder,
  textAlign: "center",
}));

export const GridItem = styled(Grid)(({ theme }) => ({
  width: "100%",
  margin: "30px 0",
  order: 2,
  display: "flex",

  [theme.breakpoints.tablet]: {
    justifyContent: "flex-start",
  },
}));

export const ButtonErrorContainer = styled(Grid)(() => ({
  width: "100%",
  margin: "40px 0 30px",
  order: 2,
}));

export const Error = styled(Typograpy)(({ theme }) => ({
  width: "100%",
  color: theme.colors.pink,
  fontSize: "0.875rem",
  textAlign: "center",
  marginBottom: theme.spacing(1),
}));

export const GridItemLinks = styled(Grid)(({ theme, divider }) => ({
  width: "100%",
  margin: "0 auto",
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  borderTop: divider && `1px solid ${theme.colors.gray6}`,
  textAlign: "center",
  order: 3,
  [theme.breakpoints.tablet]: {
    order: 3,
  },
}));

export const SpendGridItem = styled(Grid)(() => ({
  width: "100%",
  margin: "0 auto",
  order: 2,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

export const ButtonContainer = styled(GridItem)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.tablet]: {
    justifyContent: "flex-start",
  },
}));

export const HeaderWrapper = styled("div")(() => ({
  width: "100%",
}));

export const MobilePromoLink = styled("span")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",

  [theme.breakpoints.tablet]: {
    display: "none",
  },
}));

export const LoginForm = styled("form")(() => ({
  width: "100%",
  order: 2,
}));

export const StyledTitle = styled(H60Caps)(({ theme }) => ({
  fontSize: "40px",
  textTransform: "uppercase",

  [theme.breakpoints.mobileM]: {
    fontSize: "50px",
  },
  [theme.breakpoints.mobileL]: {
    fontSize: "60px",
  },
}));
