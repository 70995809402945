import {
  GET_EARN_GROUPS_SUCCESS,
  GET_EARN_GROUPS_FAIL,
  GET_EARN_GROUPS_LOADING,
} from "../types";

const initState = {
  getEarnGroupsLoading: false,
  data: [],
  citiesList: [],
  countiesList: [],
  error: {},
};

const earnReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_EARN_GROUPS_LOADING:
      return {
        ...state,
        getEarnGroupsLoading: true,
      };

    case GET_EARN_GROUPS_SUCCESS:
      return {
        ...state,
        data: payload.earnGroups,
        citiesList: payload?.citiesList
          ? [payload?.location, ...payload.citiesList]
          : [],
        countiesList: payload.countiesList
          ? [payload?.county, ...payload.countiesList]
          : [],
        getEarnGroupsLoading: false,
      };
    case GET_EARN_GROUPS_FAIL:
      return {
        ...state,
        error: payload,
        getEarnGroupsLoading: false,
      };

    default:
      return state;
  }
};

export default earnReducer;
