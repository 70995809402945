import {
  GET_INSIGHTS_SUCCESS,
  GET_INSIGHTS_LOADING,
  GET_INSIGHTS_FAIL,
} from "../../../redux/types";

const initState = {
  insights: {
    balance: "",
    givenOut: "",
    givenByMembers: "",
    signedUp: "",
    spentByMembers: "",
    loading: false,
    error: null,
  },
};

const earnGroupDashboardReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    // INSIGHTS
    case GET_INSIGHTS_LOADING:
      return {
        ...state,
        insights: {
          ...state.insights,
          loading: true,
        },
      };
    case GET_INSIGHTS_SUCCESS:
      return {
        ...state,
        insights: {
          ...payload,
          loading: false,
        },
      };
    case GET_INSIGHTS_FAIL:
      return {
        ...state,
        insights: {
          ...state.insights,
          loading: false,
          error: payload,
        },
      };

    default:
      return state;
  }
};

export default earnGroupDashboardReducer;
