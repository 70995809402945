import React from "react";

const UpArrow = ({ width, height, color, background, ...props }) => (
  <svg
    width={width}
    height={height}
    {...props}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="11" cy="11" r="11" fill={background || "currentColor"} />
    <path
      d="M7.0625 10.6719L11 6.73438L14.9375 10.6719"
      stroke={color || "currentColor"}
      strokeWidth="1.3125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 7.28125V15.2656"
      stroke={color || "currentColor"}
      strokeWidth="1.3125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default UpArrow;
