import { styled } from "@material-ui/core/styles";
import * as T from "../../../components/Typograpy";

const decideColWidth = (col, isMobile) => {
  if (col === "1") {
    return isMobile ? "25%" : "40%";
  }
  if (col === "2") {
    return isMobile ? "70%" : "50%";
  }
  if (col === "3") {
    return isMobile ? "5%" : "10%";
  }
  return "auto";
};

export const Header = styled("header")(() => ({
  display: "flex",
  justifyContent: "space-between",
  paddingLeft: "10px",
}));

export const Heading = styled(T.H30Caps)(({ theme }) => ({
  textTransform: "uppercase",
  color: theme.colors.midnight,
  marginBottom: theme.spacing(5),
  fontSize: "2",
  marginRight: theme.spacing(2),

  [theme.breakpoints.tablet]: {
    fontSize: "40px",
    lineHeight: "40px",
  },
}));

export const Question = styled(T.H18)(({ theme }) => ({
  color: theme.colors.midnight,
  fontSize: "1.125rem",
  fontWeight: "bold",
  margin: 0,
  marginBottom: theme.spacing(1),
}));

export const ActivityWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  width: "100%",
  [theme.breakpoints.tablet]: {
    width: "102%",
  },
}));

export const Optional = styled(T.Body14R)(({ theme }) => ({
  color: theme.colors.gray3,
  display: "in-line",
  fontSize: "0.875rem",
}));

export const QuestionWrapper = styled("div")(({ theme }) => ({
  [theme.breakpoints.tablet]: {
    display: "flex",
    alignItems: "center",
  },
  paddingTop: theme.spacing(2),
  marginBottom: theme.spacing(6),
}));

export const MemberTable = styled("table")(() => ({
  width: "100%",
}));

export const StyledTH = styled("th")(({ theme, p, col }) => ({
  paddingRight: p || theme.spacing(2),

  textAlign: "left",

  width: decideColWidth(col, true),

  [theme.breakpoints.mobileXL]: {
    width: decideColWidth(col, false),
  },
}));

export const StyledTD = styled("td")(({ theme, w, p }) => ({
  width: w || "auto",
  padding: "0",
  wordWrap: "break-word !important",
  overflowWrap: "break-word",
  "-ms-word-break": "break-all",
  wordBreak: "break-word",

  [theme.breakpoints.mobileM]: {
    paddingLeft: theme.spacing(0),
  },

  [theme.breakpoints.mobileL]: {
    paddingRight: p || theme.spacing(2),
    paddingLeft: theme.spacing(0),
  },
}));

export const CreditsTotal = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(6),
  display: "flex",
  alignItems: "center",
  color: theme.colors.midnight,
}));

export const SideWrapper = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(5),
  paddingLeft: "10px",
  [theme.breakpoints.laptopL]: {
    position: "absolute",
    right: "120px",
    top: "300px",
  },
}));

export const TableHeading = styled(T.Body16B)(({ theme }) => ({
  color: theme.colors.midnight,
  fontWeight: "bold",

  [theme.breakpoints.tablet]: {
    fontSize: "1.125rem",
  },
}));

export const Name = styled(T.Body14R)(({ theme }) => ({
  fontSize: "0.875rem",

  [theme.breakpoints.tablet]: {
    fontSize: "1rem",
  },
}));

export const ApplyAll = styled(T.Body14R)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "bold",
  color: theme.colors.pink,

  [theme.breakpoints.tablet]: {
    fontSize: "1.125rem",
  },
}));

export const SuccessWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "0 auto",
  maxWidth: "420px",
  width: "100%",

  [theme.breakpoints.mobileS]: {
    paddingTop: theme.spacing(0),
  },

  [theme.breakpoints.tablet]: {
    paddingTop: "0",
  },

  [theme.breakpoints.laptop]: {
    paddingTop: theme.spacing(4),
  },
}));

export const SuccessHeader = styled("header")(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  textAlign: "center",
}));

export const Emphasis = styled("span")(() => ({
  fontWeight: "bold",
}));

export const ButtonWrapper = styled("div")(() => ({
  display: "flex",
  flexWrap: "wrap",
  flex: "1",
  width: "100%",
  justifyContent: "space-between",
}));

export const SmallSpan = styled("span")(() => ({
  fontSize: "0.875rem",
  fontWeight: 500,
}));

export const HeadWrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
}));

export const Container = styled("div")(() => ({
  minHeight: 600,
}));

export const LinkBtn = styled("button")(({ theme }) => ({
  display: "inline-block",
  outline: "none",
  border: "none",
  background: "none",
  cursor: "pointer",
  fontSize: "inherit",
  color: theme.colors.blue,
  fontWeight: 700,
  textDecoration: "underline",
  padding: 0,
  margin: 0,
}));
