import { styled } from "@material-ui/core/styles";

export const TransactionsWrapper = styled("section")(() => ({
  display: "flex",
  position: "relative",
  flex: "1",
  flexDirection: "column",
}));

export const Header = styled("header")(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

export const Content = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",

  [theme.breakpoints.mobileS]: {
    paddingTop: theme.spacing(5),
  },

  [theme.breakpoints.laptop]: {
    paddingTop: theme.spacing(6),
  },
}));

export const Details = styled("div")(({ theme }) => ({
  width: "100%",
  marginBottom: theme.spacing(6),

  [theme.breakpoints.mobileXL]: {
    width: "50%",
  },
}));
