const isSoldOut = (
  {
    capacity,
    perMonth,
    perVolunteerPerMonth,
    perWeek,
    perVolunteerPerWeek,
    perVolunteer,
    perTransaction,
  },
  adultsParticipants,
  childrenParticipants,
) => {
  if (
    capacity?.capacity &&
    (capacity.totalUses || 0) + adultsParticipants + childrenParticipants >
      capacity.capacity
  ) {
    return "capacityReached";
  }
  if (
    perMonth?.capacity &&
    (perMonth.totalUses || 0) + adultsParticipants + childrenParticipants >
      perMonth.capacity
  ) {
    return "perMonthReached";
  }
  if (
    perVolunteerPerMonth?.capacity &&
    (perVolunteerPerMonth.totalUses || 0) +
      adultsParticipants +
      childrenParticipants >
      perVolunteerPerMonth.capacity
  ) {
    return "perVolunteerPerMonthReached";
  }

  if (
    perWeek?.capacity &&
    (perWeek.totalUses || 0) + adultsParticipants + childrenParticipants >
      perWeek.capacity
  ) {
    return "perWeekReached";
  }

  if (
    perVolunteerPerWeek?.capacity &&
    (perVolunteerPerWeek.totalUses || 0) +
      adultsParticipants +
      childrenParticipants >
      perVolunteerPerWeek.capacity
  ) {
    return "perVolunteerPerWeekReached";
  }

  if (
    perVolunteer?.capacity &&
    (perVolunteer.totalUses || 0) + adultsParticipants + childrenParticipants >
      perVolunteer.capacity
  ) {
    return "perVolunteerReached";
  }
  if (
    perTransaction?.capacity &&
    adultsParticipants + childrenParticipants > perTransaction.capacity
  ) {
    return "perTransactionReached";
  }
  return false;
};

export default isSoldOut;
