import React from "react";
import { Switch } from "react-router-dom";

// CONSTANTS
import { SPEND_PARTNER } from "../../constants/navRoutes";
import { roles } from "../../constants";

import SelectVenue from "./SelectVenue";
import SpendVenues from "./SpendVenues";

// OTHER ROUTERS
import ErrorRouter from "../ErrorRoutes";

// COMMON
import Route from "../../components/Route";

const SpendPartner = () => (
  <Switch>
    <Route
      exact
      path={SPEND_PARTNER.SELECT_VENUE}
      Component={SelectVenue}
      isPrivate
      layout="splitScreen"
      side="left"
      logo
      color="purple"
      allowedRoles={[roles.SPEND_PARTNER]}
    />

    <Route
      exact
      path={SPEND_PARTNER.SPEND_VENUES}
      navbar
      header
      isPrivate
      Component={SpendVenues}
      allowedRoles={[roles.SPEND_PARTNER]}
      layout="general"
      fullWidth
    />

    {/* ERROR ROUTES */}
    <ErrorRouter />
  </Switch>
);

export default SpendPartner;
