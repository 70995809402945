import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";

import * as S from "./style";
import * as T from "../../../components/Typograpy";
import { navRoutes } from "../../../constants";

import Dropdown from "../../../components/Inputs/Dropdown";
import Button from "../../../components/Button";

import t from "../../../utils/helpers/translator";
import * as actions from "../actions";

const SelectVenue = ({
  getSpendVenues,
  spendVenues,
  name,
  lang,
  updateSelectedSpendVenue,
  updateLoading,
  isMobile,
}) => {
  const [selectedVenue, setSelectedVenue] = useState({});
  const [error, setError] = useState("");
  const history = useHistory();

  useEffect(() => {
    getSpendVenues({ basic: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    try {
      await updateSelectedSpendVenue(selectedVenue.value, history);
      history.push(navRoutes.SPEND_PARTNER.VENUES);
    } catch (_error) {
      setError(_error.message);
    }
  };

  const onSelect = ({ selected }) => {
    setSelectedVenue(selected);
  };

  useEffect(() => {
    if (selectedVenue.value) {
      setSelectedVenue({
        ...selectedVenue,
        label:
          lang === "welsh" && selectedVenue.welshName
            ? selectedVenue.welshName
            : selectedVenue.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, selectedVenue.value]);

  return (
    <>
      <S.Wrapper>
        <S.Body>
          <T.H40Caps color="midnight" align="left" mb="40">
            {t("chooseYourVenue", lang)}
          </T.H40Caps>
          <T.H22Caps color="pink" mb="30">
            {name}
          </T.H22Caps>
          <Dropdown
            name="venue"
            options={spendVenues.map(({ name: _name, welshName, id }) => ({
              label: lang === "welsh" && welshName ? welshName : _name,
              value: id,
              name: _name,
              welshName,
            }))}
            value={selectedVenue && selectedVenue.value ? selectedVenue : null}
            setValue={onSelect}
            label={t("venue", lang)}
            placeholder={`${t("typeVenue", lang)}...`}
          />

          <Button
            primary
            bgColor="blue"
            customWidth={!isMobile ? "300px" : undefined}
            onClick={handleSubmit}
            w={!isMobile ? "80%" : undefined}
            m="40px 0 0"
            loading={updateLoading}
            disabled={!selectedVenue || !selectedVenue.value}
          >
            {t("confirm", lang)}
          </Button>
          {error && <T.Body14B color="pink">{t(error, lang)}</T.Body14B>}
        </S.Body>
      </S.Wrapper>
    </>
  );
};

const mapStateToProps = (state) => ({
  lang: state.auth.language,
  spendVenues: state.spendPartner.spendVenues,
  name: state.auth.spendPartner.name,
  isMobile: state.ui.isMobile,
  updateLoading: state.auth.updateSelectedVenueLoading,
});

const mapActionToProps = {
  getSpendVenues: actions.getSpendVenues,
  updateSelectedSpendVenue: actions.updateSelectedSpendVenue,
};
export default connect(mapStateToProps, mapActionToProps)(SelectVenue);
