import React from "react";
import MuiModal from "@material-ui/core/Modal";
import Content from "./Content";

const Modal = ({
  modalOpen,
  handleCloseModal,
  children,
  confirmFun = () => {},
  onCancel = () => {},
  buttonText,
  buttonStyle,
  withCancel,
  width,
  warning,
  position,
  loading,
  error,
  custom,
  keepOpenOnConfirm,
  cancelButtonText,
}) => (
  <MuiModal
    open={modalOpen}
    onClose={handleCloseModal}
    aria-labelledby={warning ? "Warning message" : "Success Message"}
    aria-describedby={warning ? "Warning message" : "Success Message"}
    BackdropProps={{ style: { background: "rgba(255,255,255,0.9)" } }}
  >
    <Content
      warning={warning}
      width={width}
      position={position}
      withCancel={withCancel}
      confirmFun={confirmFun}
      handleCloseModal={handleCloseModal}
      buttonStyle={buttonStyle}
      loading={loading}
      error={error}
      buttonText={buttonText}
      onCancel={onCancel}
      custom={custom}
      keepOpenOnConfirm={keepOpenOnConfirm}
      cancelButtonText={cancelButtonText}
    >
      {children}
    </Content>
  </MuiModal>
);

export default Modal;
