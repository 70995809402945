import { styled } from "@material-ui/core/styles";

export const Wrapper = styled("span")(({ margin }) => ({
  display: "flex",
  margin: margin || 0,
}));

export const Circle = styled("span")(
  ({ theme, current, color, index, clickable }) => ({
    width: theme.spacing(1),
    height: theme.spacing(1),
    border: `1px solid ${theme.colors[color] || theme.colors.gray1}`,
    borderRadius: "50%",
    background:
      current === index && (theme.colors[color] || theme.colors.gray1),
    marginRight: "3px",
    cursor: clickable && "pointer",
  }),
);
