import styled from "@material-ui/core/styles/styled";

export const TimepickerContainer = styled("div")(({ theme, error }) => ({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  width: "100%",
  border: error ? theme.borders.textInputError : theme.borders.textInput,
  borderRadius: 10,
  padding: "0 10px",
  height: "50px",
  boxSizing: "border-box",
  background: theme.colors.white,
}));

export const TimePicker = styled("div")(() => ({
  width: "40%",
}));
