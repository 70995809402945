import {
  GET_STAFF_SINGLE_MEMBER_LOADING,
  GET_STAFF_SINGLE_MEMBER_SUCCESS,
  GET_STAFF_SINGLE_MEMBER_FAIL,
  GET_STAFF_SINGLE_MEMBER_TRANSACTIONS_LOADING,
  GET_STAFF_SINGLE_MEMBER_TRANSACTIONS_SUCCESS,
  GET_STAFF_SINGLE_MEMBER_TRANSACTIONS_FAIL,
  DELETE_STAFF_SINGLE_MEMBER_SUCCESS,
  DELETE_STAFF_SINGLE_MEMBER_FAIL,
} from "../../../../redux/types";

const initState = {
  id: "",
  memberName: "",
  email: "",
  phone: "",
  groups: "",
  membershipId: "",
  balanceTc: 0,
  earnedTc: 0,
  giftedPercentage: 0,
  spentPercentage: 0,
  transactionsData: { data: [] },
  loading: false,
  error: null,
};

const staffSingleSpendVenueReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_STAFF_SINGLE_MEMBER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_STAFF_SINGLE_MEMBER_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case GET_STAFF_SINGLE_MEMBER_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case GET_STAFF_SINGLE_MEMBER_TRANSACTIONS_LOADING:
      return {
        ...state,
        transactionsData: {
          ...state.transactionsData,
          loading: true,
        },
      };
    case GET_STAFF_SINGLE_MEMBER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactionsData: {
          loading: false,
          data: payload,
        },
      };
    case GET_STAFF_SINGLE_MEMBER_TRANSACTIONS_FAIL:
      return {
        ...state,
        transactionsData: {
          ...state.transactionsData,
          loading: false,
          error: payload,
        },
      };

    case DELETE_STAFF_SINGLE_MEMBER_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case DELETE_STAFF_SINGLE_MEMBER_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default staffSingleSpendVenueReducer;
