import { earnGroups, tkGroups } from "../../../../api-calls";
import {
  GET_EARN_GROUP_BY_GROUP_ID_LOADING,
  GET_EARN_GROUP_BY_GROUP_ID_SUCCESS,
  GET_EARN_GROUP_BY_GROUP_ID_FAIL,
  GET_TK_GROUP_CONFLICT_DATA_LOADING_START,
  GET_TK_GROUP_CONFLICT_DATA_LOADING_END,
  GET_TK_GROUP_CONFLICT_DATA_SUCCESS,
  GET_TK_GROUP_CONFLICT_DATA_FAIL,
  TK_MERGE_GROUPS_START,
  TK_MERGE_GROUPS_SUCCESS,
  TK_MERGE_GROUPS_FAIL,
  TK_APPROVE_AS_NEW_GROUP_START,
  TK_APPROVE_AS_NEW_GROUP_SUCCESS,
  TK_APPROVE_AS_NEW_GROUP_FAIL,
} from "../../../../redux/types";

// earnGroups.getEarnGroupAccountHolderLogs;
export const getEarnGroupByGroupId =
  ({ id, isPublic }) =>
  async (dispatch) => {
    dispatch({ type: GET_EARN_GROUP_BY_GROUP_ID_LOADING });

    try {
      const { data, error } = await earnGroups.getEarnGroupByGroupId({
        id,
        isPublic,
      });
      if (data) {
        dispatch({
          type: GET_EARN_GROUP_BY_GROUP_ID_SUCCESS,
          payload: data,
        });
      } else if (error) {
        dispatch({
          type: GET_EARN_GROUP_BY_GROUP_ID_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_EARN_GROUP_BY_GROUP_ID_FAIL,
        payload: error,
      });
    }
  };

export const getTkGroupConflictDataByUserId = (id) => async (dispatch) => {
  dispatch({ type: GET_TK_GROUP_CONFLICT_DATA_LOADING_START });

  try {
    const { data, error } = await tkGroups.getTkGroupConflictDataByUserId({
      id,
    });
    if (data) {
      dispatch({
        type: GET_TK_GROUP_CONFLICT_DATA_SUCCESS,
        payload: data,
      });
    } else if (error) {
      dispatch({
        type: GET_TK_GROUP_CONFLICT_DATA_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_TK_GROUP_CONFLICT_DATA_FAIL,
      payload: error,
    });
  } finally {
    dispatch({ type: GET_TK_GROUP_CONFLICT_DATA_LOADING_END });
  }
};

export const mergeGroups =
  ({ id, userId }) =>
  async (dispatch) => {
    dispatch({ type: TK_MERGE_GROUPS_START });
    try {
      const status = "approved";
      const { data, error } = await tkGroups.approveRejectGroup({
        id,
        userId,
        status,
      });

      if (error) {
        dispatch({
          type: TK_MERGE_GROUPS_FAIL,
          payload: error,
        });
      } else {
        dispatch({
          type: TK_MERGE_GROUPS_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      dispatch({
        type: TK_MERGE_GROUPS_FAIL,
        payload: error,
      });
    }
  };

export const approveAsNewGroup =
  ({ status, id, name, email }) =>
  async (dispatch) => {
    dispatch({ type: TK_APPROVE_AS_NEW_GROUP_START });
    try {
      const { data, error } = await tkGroups.approveRejectGroup({
        id,
        name,
        email,
        status,
      });
      if (data) {
        dispatch({ type: TK_APPROVE_AS_NEW_GROUP_SUCCESS, payload: data });
      } else {
        dispatch({
          type: TK_APPROVE_AS_NEW_GROUP_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: TK_APPROVE_AS_NEW_GROUP_FAIL,
        payload: error,
      });
    }
  };
