import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../../../../utils/theme";

import * as S from "./style";
import * as T from "../../../../components/Typograpy";
import { Regular } from "../../../../components/Button";
import * as actions from "../../../../redux/actions/updateEarnGroupProfile";
import { getLocation } from "../../../../redux/actions/location";

import { focusDropDownEnglish } from "../../../../constants/dropdDownData";
import validate, {
  validateWelsh,
} from "../../../../validation/schemas/recruitVolunteers";

import trimArrayOfStrings from "../../../../utils/helpers/trimArrayOfStrings";

import { InputField, Dropdown, Checkbox } from "../../../../components/Inputs";
import { Row, Col } from "../../../../components/Grid";
import LocationWrapper from "../../../../components/LocationFields/LocationsWrapper";
import ActivitiesSection from "../../../../components/ActivitiesSection";
import SpecificActivities from "../../../../components/SpecificActivities";
import GoBack from "../../../../components/GoBack";

import UploadImage from "../../../../components/UploadImage";
import Notification from "../../../../components/Notification";

import {
  formatAddresses,
  formatActivitiesRoles,
  setAddressesWelsh,
  setActivitiesRolesWelsh,
} from "../../../../utils/helpers/manageWelshEarnGroupForms";

import tidyPostcode from "../../../../utils/helpers/tidyPostcode";
import stripLeadingTab from "../../../../utils/helpers/stripLeadingTab";
import cleanWhitespace from "../../../../utils/helpers/cleanWhitespace";
import formatUrl from "../../../../utils/helpers/urlFormatter";
import { getSpendActivitiesCitiesAndCounties } from "../../../../redux/actions/activities";

const getCleanData = ({ ...data } = {}) => ({
  firstName: data.firstName,
  lastName: data.lastName,
  email: data.email ? data.email.replace(/ /g, "") : "",
  phoneNumber: stripLeadingTab(data.phoneNumber?.replace(/ /g, "")),
  contactEmail: data.contactEmail ? data.contactEmail?.replace(/ /g, "") : "",
  contactNumber: stripLeadingTab(data.contactNumber?.replace(/ /g, "")),
  description: data.description ? data.description.trim() : "",
  focusFields: data.focusFields,
  getInTouchDescription: data.getInTouchDescription
    ? data.getInTouchDescription.trim()
    : "",
  isOnline: data.isOnline,
  isPrivateAddress: data.isPrivateAddress,
  isPublic: data.isPublic,
  name: data.name ? data.name.trim() : "",
  socialLinks: data.socialLinks || {},
  websiteUrl: data.websiteUrl ? formatUrl(data.websiteUrl.trim()) : "",
  publicActivities: data.publicActivities,
  keywords: data.keywords,
  // welsh fields
  nameWelsh: data.nameWelsh,
  getInTouchDescriptionWelsh: data.getInTouchDescriptionWelsh,
  descriptionWelsh: data.descriptionWelsh,
  addressesWelsh: data.addressesWelsh,
  addresses: data.addresses,
  activitiesRoles: data.activitiesRoles,
  activitiesRolesWelsh: data.activitiesRolesWelsh,
  keywordsWelsh: data.keywordsWelsh,
});

const EditEarnGroupProfile = ({
  data,
  getEarnGroupProfile,
  getProfileSuccess,
  updateEarnGroupProfile,
  error,
  updateProfileLoading,
  updateProfileSuccess,
  resetUpdateEarnGroupProfile,
  getLocationAction,
  getSpendActivitiesCitiesAndCountiesAction,
}) => {
  const { id } = useParams();
  const [state, setState] = useState(getCleanData({ ...data }));
  const [addresses, setAddresses] = useState([]);
  const [activitiesRoles, setActivitiesRoles] = useState([]);
  const [errors, setErrors] = useState(getCleanData({}));
  const [logoImageInfo, setLogoImageInfo] = useState({
    id: null,
    name: "",
    key: "",
    bucketRegion: "",
    bucket: "",
    new: false,
    uploadedToS3: false,
  });
  const [coverImageInfo, setCoverImageInfo] = useState({
    id: null,
    name: "",
    key: "",
    bucketRegion: "",
    bucket: "",
    new: false,
    uploadedToS3: false,
  });
  const [startUpload, setStartUpload] = useState(false);
  const [welshVersion, setWelshVersion] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [newPostcodes, setNewPostcodes] = useState(false);
  const [imagesReady, setImagesReady] = useState(false);
  const [geocodesReady, setGeocodesReady] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [keywordsWelsh, setKeywordsWelsh] = useState([]);

  const handleWalshCheckbox = () => setWelshVersion((old) => !old);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (name, value) => {
    setState((_state) => ({
      ..._state,
      [name]:
        name === "email" || name === "contactEmail"
          ? value.toLowerCase().replace(/ /g, "").trim()
          : value,
    }));
  };

  useEffect(() => {
    if (state.isOnline) {
      setAddresses([
        {
          id: 0,
        },
      ]);
    }
  }, [state.isOnline]);

  const onFocusFieldsChange = ({ selected }) => {
    const value = selected ? selected.map((e) => e.value) : [];
    if (value.length <= 3) {
      handleChange("focusFields", value);
    }
  };

  const handleKeywords = (e, lang) => {
    if (e.target.value.trim() === "" || e.target.value.trim() === ",") {
      if (lang === "english") {
        return setKeywords([]);
      }

      return setKeywordsWelsh([]);
    }

    const keywordsArr = e.target.value.split(",");

    if (lang === "english") {
      setKeywords(keywordsArr);
    } else {
      return setKeywordsWelsh(keywordsArr);
    }
  };

  const submit = () => {
    const {
      contactEmail,
      contactNumber,
      description,
      firstName,
      focusFields,
      getInTouchDescription,
      isOnline,
      isPublic,
      isPrivateAddress,
      lastName,
      name,
      phoneNumber,
      publicActivities,
      socialLinks,
      websiteUrl,
      // welsh fields
      nameWelsh,
      getInTouchDescriptionWelsh,
      descriptionWelsh,
    } = state;

    let formSubmissionFields = {
      contactEmail,
      contactNumber: contactNumber?.replace(/ /g, ""),
      description,
      email: data.email === state.email ? undefined : state.email,
      focusFields,
      getInTouchDescription,
      isOnline,
      isPrivateAddress,
      isPublic,
      firstName: cleanWhitespace(firstName),
      lastName: cleanWhitespace(lastName),
      name: cleanWhitespace(name),
      phoneNumber: phoneNumber?.replace(/ /g, ""),
      publicActivities,
      socialLinks,
      websiteUrl: formatUrl(websiteUrl),
      addresses,
      activitiesRoles,
      keywords: trimArrayOfStrings(keywords),
      coverImage: coverImageInfo,
      logoImage: logoImageInfo,
    };

    const formSubmissionFieldsWelsh = {
      nameWelsh: cleanWhitespace(nameWelsh),
      getInTouchDescriptionWelsh,
      descriptionWelsh,
      addressesWelsh: setAddressesWelsh(addresses),
      activitiesRolesWelsh: setActivitiesRolesWelsh(activitiesRoles),
      keywordsWelsh: trimArrayOfStrings(keywordsWelsh),
    };

    if (welshVersion) {
      formSubmissionFields = {
        ...formSubmissionFields,
        welshFields: { ...formSubmissionFieldsWelsh },
      };
    }

    updateEarnGroupProfile(id, {
      ...formSubmissionFields,
    });
  };

  const fetchGeocodes = async (addressArr) => {
    let postcodeArr = addressArr.map((address) => address.postcode);
    postcodeArr = postcodeArr.filter((postcode) => !!postcode);

    if (postcodeArr.length < 1) {
      setGeocodesReady(true);
      return null;
    }

    try {
      setFetchingData(true);
      const { result } = await getLocationAction({
        query: postcodeArr,
        queryType: "bulkPostcodes",
      });
      const updatedAddresses = addressArr.map((address) => {
        const foundPostcodeInfo = result.find(
          (el) => el.query === address.postcode,
        );
        if (!foundPostcodeInfo || !foundPostcodeInfo.result) {
          throw new Error("One of the postcodes entered isn't recognised");
        }
        const { latitude, longitude } = foundPostcodeInfo.result;
        return { ...address, lat: latitude, long: longitude };
      });
      setAddresses(updatedAddresses);
      setGeocodesReady(true);
    } catch (err) {
      setFetchingData(false);
      setErrors({
        postcode: `Error getting the latitude and longitude for this postcode`,
        anyErrors: `Error getting the latitude and longitude for submitted postcode: "${
          err.inner || err
        }"`,
      });
      return null;
    }
  };

  const handleClick = async () => {
    try {
      const tidyAddresses = state.isOnline
        ? [{ id: 0 }]
        : addresses.map((address) => ({
            ...address,
            city: address.city && address.city.trim(),
            county: address.county && address.county.trim(),
            postcode: tidyPostcode(address.postcode),
          }));

      if (welshVersion) {
        validateWelsh({
          admin: true,
          ...getCleanData({ ...state }),
          coverImageKey: coverImageInfo.key,
          logoImageKey: logoImageInfo.key,
          addresses: tidyAddresses,
          activitiesRoles: activitiesRoles.map((e) => ({
            ...e,
            name: e.name && e.name.trim(),
            details: e.details && e.details.trim(),
            nameWelsh: e.nameWelsh && e.nameWelsh.trim(),
            detailsWelsh: e.detailsWelsh && e.detailsWelsh.trim(),
          })),
          keywords,
          keywordsWelsh,
        });
        // client side validation
      } else {
        validate({
          admin: true,
          ...getCleanData({ ...state }),
          coverImageKey: coverImageInfo.key,
          logoImageKey: logoImageInfo.key,
          addresses: tidyAddresses,
          activitiesRoles: activitiesRoles.map((e) => ({
            ...e,
            name: e.name && e.name.trim(),
            details: e.details && e.details.trim(),
          })),
          keywords,
        });
      }

      setErrors({ ...getCleanData({}), coverImageKey: "", logoImageKey: "" });
      setAddresses(tidyAddresses);

      if (!state.isOnline && newPostcodes) {
        await fetchGeocodes(tidyAddresses);
      } else {
        setGeocodesReady(true);
      }

      if (logoImageInfo.new || coverImageInfo.new) {
        setStartUpload(true);
      } else {
        setImagesReady(true);
      }
    } catch (err) {
      if (err.name === "ValidationError") {
        err.inner.anyErrors =
          "At least one of the input fields has not been filled in or details entered incorrectly. Please check the form above for more details.";
        setErrors(err.inner);
      }
    }
  };
  useEffect(() => {
    // SET STATE
    let filteredData;
    if (getProfileSuccess) {
      setLogoImageInfo({ ...data.logo, new: false });
      setCoverImageInfo({ ...data.cover, new: false });
      setNewPostcodes(false);
    }

    const { welshFields = {} } = data;

    if (welshFields) {
      const formattedWelshFields = {
        nameWelsh: welshFields.name,
        descriptionWelsh: welshFields.description,
        addressesWelsh: welshFields.addresses,
        getInTouchDescriptionWelsh: welshFields.getInTouchDescription,
        activitiesRolesWelsh: welshFields.activitiesRoles,
        keywordsWelsh: welshFields.keywords,
      };

      filteredData = { ...data, ...formattedWelshFields };
      setWelshVersion(!!filteredData.nameWelsh);
    } else {
      filteredData = data;
    }

    setState(getCleanData({ ...filteredData }));
    setAddresses(
      formatAddresses(
        filteredData.addresses && filteredData.addresses,
        filteredData.addressesWelsh || [],
      ),
    );
    setActivitiesRoles(
      formatActivitiesRoles(
        filteredData.activitiesRoles,
        filteredData.activitiesRolesWelsh || [],
      ),
    );

    setKeywords(data.keywords);
    setKeywordsWelsh(welshFields.keywords);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProfileSuccess]);

  useEffect(() => {
    if (coverImageInfo.uploadedToS3 || logoImageInfo.uploadedToS3) {
      if (coverImageInfo.new) {
        if (!coverImageInfo.uploadedToS3) {
          return;
        }
      }
      if (logoImageInfo.new) {
        if (!logoImageInfo.uploadedToS3) {
          return;
        }
      }
      setImagesReady(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverImageInfo.uploadedToS3, logoImageInfo.uploadedToS3]);

  useEffect(() => {
    if (updateProfileSuccess) {
      setIsNotificationOpen(true);
      setLogoImageInfo((_state) => ({
        ..._state,
        new: false,
        deleted: false,
        uploadedToS3: false,
      }));
      setCoverImageInfo((_state) => ({
        ..._state,
        new: false,
        deleted: false,
        uploadedToS3: false,
      }));
      setStartUpload(false);
      setNewPostcodes(false);
      setImagesReady(false);
      setGeocodesReady(false);
    }
  }, [updateProfileSuccess]);

  useEffect(() => {
    getEarnGroupProfile(id);
    return resetUpdateEarnGroupProfile;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (geocodesReady && imagesReady) {
      setFetchingData(false);
      submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geocodesReady, imagesReady]);
  useEffect(() => {
    getSpendActivitiesCitiesAndCountiesAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Wrapper>
      <Notification
        open={isNotificationOpen}
        setOpen={setIsNotificationOpen}
        message="PROFILE SAVED"
        duration={1500}
      />
      <Row jc="space-between" mb={1}>
        <Col w={[4, 6, 7]}>
          <GoBack showText />
          <T.H40Caps color="midnight" mt="30">
            {state.name}
          </T.H40Caps>
          <T.H30Caps color="pink" mt="20">
            edit earn listing
          </T.H30Caps>
        </Col>
        <Col w={[4, 12, 12]} style={{ marginTop: 20 }}>
          <Checkbox
            value={welshVersion}
            setValue={handleWalshCheckbox}
            label="Add a version in Welsh language"
            name="welshVersion"
            size={isMobile ? "normal" : "medium"}
          />
        </Col>
      </Row>
      <Row mb={5}>
        <Col w={[4, 8, 8]}>
          <T.Body16R color="gray3" mt="15">
            All the information you provide will be used to create this
            group&apos;s online earn listing.
          </T.Body16R>
        </Col>
      </Row>
      <Row mb={4} ai="flex-end">
        <Col w={[4, 6, 4]}>
          <InputField
            placeholder="type the group name here..."
            label="Group Name"
            name="name"
            value={state.name}
            setValue={(e) => handleChange(e.target.name, e.target.value)}
            error={errors.name}
          />
        </Col>
        {welshVersion && (
          <Col w={[4, 6, 4]}>
            <InputField
              placeholder="type the group name here..."
              label="Group Name (Welsh)"
              labelColor="blue"
              name="nameWelsh"
              value={state.nameWelsh}
              setValue={(e) => handleChange(e.target.name, e.target.value)}
              error={errors.nameWelsh}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col w={[4, 6, 4]}>
          <S.FocusContainer>
            <Dropdown
              name="focusFields"
              options={focusDropDownEnglish}
              value={
                state.focusFields &&
                state.focusFields.map((e) => ({ value: e, label: e }))
              }
              setValue={onFocusFieldsChange}
              label="Focuses"
              placeholder="Select up to three..."
              isMulti
              allowSelectAll={false}
              fixedHeight={false}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              max={3}
              helper="up to three"
              style={{ maxWidth: "100%" }}
              error={errors.focusFields}
            />
          </S.FocusContainer>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 6, 12]}>
          <Checkbox
            checkbox
            error={errors.isOnline}
            value={state.isOnline}
            setValue2={(checked) => handleChange("isOnline", checked)}
            label="This group only offers online activities"
          />
        </Col>
      </Row>
      {!state.isOnline && (
        <Row>
          <Col w={[4, 6, 12]}>
            <Checkbox
              checkbox
              error={errors.isPrivateAddress}
              value={state.isPrivateAddress}
              setValue2={(checked) => handleChange("isPrivateAddress", checked)}
              label="Hide full address (only show town/city)"
            />
          </Col>
        </Row>
      )}
      {!state.isOnline && addresses && addresses.length > 0 && (
        <LocationWrapper
          addresses={addresses}
          welshVersion={welshVersion}
          setAddresses={setAddresses}
          setNewPostcodes={setNewPostcodes}
          isOptional={["addressLine2", "addressLine2Welsh"]}
          setErrors={setErrors}
          errors={errors}
        />
      )}
      {/* Tell potential volunteers about your organisation */}
      <Row mb={3} mt={3}>
        <Col w={[4, 6, 6]}>
          <InputField
            name="description"
            type="textarea"
            label="About the Group"
            placeholder="Type here..."
            limit={300}
            showLimit
            value={state.description}
            setValue={(e) => handleChange(e.target.name, e.target.value)}
            error={errors.description}
          />
        </Col>
        {welshVersion && (
          <Col w={[4, 6, 6]}>
            <InputField
              name="descriptionWelsh"
              type="textarea"
              label="About the Group (Welsh)"
              labelColor="blue"
              placeholder="Type here..."
              limit={300}
              showLimit
              value={state.descriptionWelsh}
              setValue={(e) => handleChange(e.target.name, e.target.value)}
              error={errors.descriptionWelsh}
            />
          </Col>
        )}
      </Row>
      <Row mb={8}>
        <Col w={[4, 6, 6]}>
          <InputField
            placeholder="Example: food, food waste, cooking, cook, chef, dinner..."
            label="Please enter any keywords to help with searching, separated by a comma"
            name="keywords"
            isOptional
            type="textarea"
            value={keywords && keywords.toString()}
            setValue={(e) => handleKeywords(e, "english")}
            error={errors.keywords}
            justify="center"
          />
        </Col>

        {welshVersion && (
          <Col w={[4, 6, 6]}>
            <InputField
              placeholder="Example: food, food waste, cooking, cook, chef, dinner..."
              label="Please enter any keywords for this activity, each separated by a comma (Welsh)"
              name="keywordsWelsh"
              isOptional
              type="textarea"
              value={keywordsWelsh && keywordsWelsh.toString()}
              setValue={(e) => handleKeywords(e, "welsh")}
              error={errors.keywordsWelsh}
              justify="center"
              labelColor="blue"
            />
          </Col>
        )}
      </Row>
      <Row mb="3">
        <Col w={[4, 6, 6]}>
          <T.H20 color="midnight">
            How should people get in touch about earning Time Credits?
          </T.H20>
        </Col>
      </Row>
      <Row mb="5">
        {/* contact Number */}
        <Col w={[4, 6, 4]}>
          <S.FiledWrapper>
            <InputField
              placeholder="Type phone number here...."
              label="Contact Number"
              name="contactNumber"
              value={state.contactNumber}
              setValue={(e) => handleChange(e.target.name, e.target.value)}
              isOptional
              error={errors.contactNumber}
            />
          </S.FiledWrapper>
        </Col>
        {/* contactEmail */}
        <Col w={[4, 6, 4]}>
          <S.FiledWrapper>
            <InputField
              placeholder="Type email here here...."
              label="Contact Email"
              name="contactEmail"
              value={state.contactEmail}
              setValue={(e) => handleChange(e.target.name, e.target.value)}
              error={errors.contactEmail}
              isOptional
            />
          </S.FiledWrapper>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 6, 6]}>
          <InputField
            name="getInTouchDescription"
            type="textarea"
            label="Anything else they should know?"
            placeholder="Example: Give us a call Mondays-Thursdays between 9am-5pm, one of our friendly staff or volunteers will be able to help you. "
            limit={300}
            showLimit
            value={state.getInTouchDescription}
            setValue={(e) =>
              handleChange("getInTouchDescription", e.target.value)
            }
            error={errors.getInTouchDescription}
          />
        </Col>
        {welshVersion && (
          <Col w={[4, 6, 6]}>
            <InputField
              name="getInTouchDescriptionWelsh"
              type="textarea"
              label="Anything else they should know? (Welsh)"
              labelColor="blue"
              placeholder="Example: Give us a call Mondays-Thursdays between 9am-5pm, one of our friendly staff or volunteers will be able to help you. "
              limit={300}
              showLimit
              value={state.getInTouchDescriptionWelsh}
              setValue={(e) =>
                handleChange("getInTouchDescriptionWelsh", e.target.value)
              }
              error={errors.getInTouchDescriptionWelsh}
            />
          </Col>
        )}
      </Row>
      <Row>
        {/* websiteUrl */}
        <Col w={[4, 6, 4]}>
          <S.FiledWrapper>
            <InputField
              placeholder="Type url here...."
              label="Website / Sign up Link"
              name="websiteUrl"
              value={state.websiteUrl}
              setValue={(e) => handleChange(e.target.name, e.target.value)}
              isOptional
              error={errors.websiteUrl}
            />
          </S.FiledWrapper>
        </Col>

        <Col w={[4, 6, 4]}>
          <S.FiledWrapper>
            <InputField
              placeholder="Type Facebook url here...."
              label="Facebook link"
              name="socialLinks"
              value={state.socialLinks && state.socialLinks.facebook}
              setValue={(e) =>
                handleChange(e.target.name, {
                  twitter: state.socialLinks && state.socialLinks.twitter,
                  facebook: e.target.value,
                })
              }
              isOptional
              error={errors.socialLinks && errors.socialLinks.facebook}
            />
          </S.FiledWrapper>
        </Col>
        <Col w={[4, 6, 4]}>
          <S.FiledWrapper>
            <InputField
              placeholder="Type Twitter url here...."
              label="Twitter link"
              name="socialLinks"
              value={state.socialLinks && state.socialLinks.twitter}
              setValue={(e) =>
                handleChange(e.target.name, {
                  facebook: state.socialLinks && state.socialLinks.facebook,
                  twitter: e.target.value,
                })
              }
              isOptional
              error={errors.socialLinks && errors.socialLinks.twitter}
            />
          </S.FiledWrapper>
        </Col>
      </Row>
      <Row mb={8} mt={8}>
        <Col w={[4, 6, 4]}>
          <UploadImage
            placeholder="Drag logo here or click"
            setImageInfo={setLogoImageInfo}
            imageInfo={logoImageInfo}
            startUpload={startUpload}
            imageType="logo"
            maxSize={2e6}
            maxSizeText="2MB"
            secondaryText="file size max 2mb"
            error={errors.logoImageKey}
          />
        </Col>
        <Col w={[4, 6, 4]}>
          <UploadImage
            placeholder="Drag image here or click"
            setImageInfo={setCoverImageInfo}
            imageInfo={coverImageInfo}
            startUpload={startUpload}
            imageType="logo"
            maxSize={2e6}
            maxSizeText="2MB"
            secondaryText="min dimensions 820 x 280, file size max 2mb"
            error={errors.coverImageKey}
          />
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 12]}>
          <T.H20 color="midnight" mb={1}>
            Select the types of activities volunteers can do
          </T.H20>
          <T.Body16R color="gray3" style={{ marginBottom: "20px" }}>
            (Select as many as you need)
          </T.Body16R>
        </Col>
      </Row>
      <ActivitiesSection
        publicActivities={state.publicActivities}
        setPublicActivities={(value) => handleChange("publicActivities", value)}
        error={errors.publicActivities}
      />{" "}
      <Row mt={8}>
        <Col w={[4, 6, 12]}>
          <T.H20 style={{ display: "inline" }} color="primary">
            Add specific activities that you give Time Credits out for
          </T.H20>
          <T.Body14S style={{ display: "inline" }} ml="10" color="gray3">
            (optional)
          </T.Body14S>
          <T.Body16R color="gray3" my="10">
            e.g Breakfast Club or Shifts on Reception. Add as many as you would
            like.
          </T.Body16R>
        </Col>
      </Row>
      <SpecificActivities
        activitiesRoles={activitiesRoles}
        setActivitiesRoles={setActivitiesRoles}
        errors={errors}
        welshVersion={welshVersion}
      />
      <S.SubmitWrapper>
        <Row>
          <Col w={[4, 6, 12]}>
            <Checkbox
              checkbox
              name="isPublic"
              value={state.isPublic}
              setValue2={(value) => handleChange("isPublic", value)}
              label="Make Earn Group Profile Public"
              size={isMobile ? "normal" : "medium"}
            />
          </Col>
        </Row>
        <Row mt={4}>
          <Col w={[4, 6, 4]}>
            {error && error.message && <S.Error>{error.message}</S.Error>}
            {errors.anyErrors && <S.Error>{errors.anyErrors}</S.Error>}
            <Regular
              primary
              bgColor="blue"
              onClick={handleClick}
              loading={updateProfileLoading || fetchingData}
            >
              Save Changes
            </Regular>
          </Col>
        </Row>
      </S.SubmitWrapper>{" "}
    </S.Wrapper>
  );
};
const mapStateToProps = (state) => ({
  data: state.updateEarnGroupProfile.data,
  getProfileSuccess: state.updateEarnGroupProfile.getProfileSuccess,
  updateProfileLoading: state.updateEarnGroupProfile.updateProfileLoading,
  updateProfileSuccess: state.updateEarnGroupProfile.updateProfileSuccess,
  error: state.updateEarnGroupProfile.updateProfileError,
  citesAndCounties: state.activities.citesAndCounties,
});

const mapActionsToProps = {
  getEarnGroupProfile: actions.getEarnGroupProfile,
  updateEarnGroupProfile: actions.updateEarnGroupProfile,
  resetUpdateEarnGroupProfile: actions.resetUpdateEarnGroupProfile,
  getLocationAction: getLocation,
  getSpendActivitiesCitiesAndCountiesAction:
    getSpendActivitiesCitiesAndCounties,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(EditEarnGroupProfile);
