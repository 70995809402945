import { fields, createSchema, validate as _validate } from "..";

const { email } = fields;

const administrators = createSchema({
  email,
});

const validate = (data) => _validate(administrators, data);

export default validate;
