import makeStyles from "@material-ui/core/styles/makeStyles";
import styled from "@material-ui/core/styles/styled";
import loadingStyle from "./loadingStyle";

export const RegularButton = styled("button")(
  ({
    theme,
    loading,
    bgColor,
    bgGrad,
    disabled,
    customWidth,
    textColor,
    outline,
    customHeight,
    p,
    ...props
  }) => {
    const cursor = loading ? "auto" : (disabled && "not-allowed") || "pointer";
    const margin = theme.helpers.setMargin(props);
    const border = outline
      ? `3px solid ${theme.colors[textColor] || theme.colors.gray4}`
      : "none";

    return {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "5px",
      outline: "none",
      cursor,
      margin,
      padding: p || "10px 15px",
      height: customHeight || "50px",
      background: bgGrad
        ? theme.gradients[bgGrad]
        : theme.colors[bgColor] || "none",
      border,
      width: customWidth || "100%",
      transition: "0.2s",
      "&:hover": {
        boxShadow: theme.customShadows.dp3,
        opacity: "0.8",
      },
    };
  },
);

const useStyles = makeStyles((theme) => ({
  StyledButton: {
    position: "relative",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    borderRadius: "5px",
    outline: "none",
    cursor: ({ loading, disabled }) =>
      loading || disabled ? "auto" : "pointer",
    textAlign: "center",
    // textTransform: "capitalize",
    minHeight: ({ size, customHeight }) =>
      size === "xs" ? "1.875rem" : "50px" || customHeight,
    fontSize: ({ size }) => (size === "xs" ? 14 : 16),
    fontFamily: "Poppins",
    padding: ({ size }) => (size === "xs" ? "0rem 0.3rem" : "10px 15px"),
    margin: (props) => theme.helpers.setMargin(props),
    border: ({ size, textColor, outline, disabled }) => {
      switch (true) {
        case outline && size === "xs" && disabled:
          return `2px solid ${theme.colors.gray4}`;
        case outline && size === "xs" && !!textColor:
          return `2px solid ${theme.colors[textColor]}`;
        case outline && size === "xs":
          return `2px solid ${theme.colors.blue}`;
        case outline && size !== "xs" && disabled:
          return `3px solid ${theme.colors.gray5}`;
        case outline && size !== "xs" && !!textColor:
          return `3px solid ${theme.colors[textColor]}`;
        case outline && size !== "xs":
          return `3px solid ${theme.colors.blue}`;
        default:
          return "none";
      }
    },
    justifyContent: "center",
    width: ({ w }) => w || "100%",
    color: ({ bg, primary, secondary, textColor, outline, disabled }) => {
      switch (true) {
        case disabled && outline:
          return theme.colors.gray3;
        case disabled:
          return theme.colors.gray2;
        case !!textColor:
          return theme.colors[textColor] || theme.colors.gray1;
        case (primary || secondary || bg) && bg === "gray":
          return theme.colors.gray1;
        case primary || secondary || bg:
          return theme.colors.white;
        case outline:
          return theme.colors.blue;
        default:
          return theme.colors.white;
      }
    },
    backgroundColor: ({ bg, primary, outline, secondary, disabled }) => {
      switch (true) {
        case disabled && outline:
          return "transparent";
        case disabled:
          return theme.colors.gray5;
        case primary && !!bg:
          return theme.colors[bg];
        case primary:
          return theme.colors.blue;
        case secondary && !!bg:
          return theme.colors[bg];
        case secondary:
          return theme.colors.pink;
        case outline && !!bg:
          return theme.colors[bg];
        case outline:
          return "transparent";
        default:
          return theme.colors.blue;
      }
    },
    "&:hover": {
      boxShadow: ({ primary, secondary }) => {
        switch (true) {
          case primary:
            return theme.customShadows.dp3;
          case secondary:
            return theme.customShadows.dp3;
          default:
            return null;
        }
      },
      opacity: ({ disabled, loading }) => {
        switch (true) {
          case disabled || loading:
            return null;
          default:
            return "0.8";
        }
      },
      // "& div": {
      //   backgroundColor: ({ primary, secondary, loading }) => {
      //     switch (true) {
      //       case loading:
      //         return null;
      //       case primary || secondary:
      //         return theme.colors.white;
      //       default:
      //         return theme.colors.pink;
      //     }
      //   },
      // },
      // "& div:after": {
      //   backgroundColor: ({ primary, secondary, loading }) => {
      //     switch (true) {
      //       case loading:
      //         return null;
      //       case primary:
      //         return theme.colors.pink;
      //       case secondary:
      //         return theme.colors.cyan;
      //       default:
      //         return theme.colors.white;
      //     }
      //   },
      // },
    },
    "&:active": {
      boxShadow: "none",
      opacity: "0.9",
    },
  },
  ...loadingStyle(theme),
}));

export default useStyles;
