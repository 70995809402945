// expects a number and will turn it intro a string separated by commas
const numberWithCommas = (num) => {
  let number = num;
  if (typeof num === "number") {
    if (num === Math.floor(num)) {
      number = num.toString();
    } else {
      const limit =
        num.toString().split(".")[1].length > 3
          ? 3
          : num.toString().split(".")[1].length;

      number = parseFloat(num).toFixed(limit);
    }
  }
  if (num === null) {
    number = "0";
  }
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default numberWithCommas;
