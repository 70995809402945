import { keys } from "./ethnicsEnglish";

const keysWelsh = {
  WHITE: "Gwyn",
  ENGLISH_WELSH_SCOTTISH_NORTHERN_IRISH_BRITISH:
    "Sais/Saesnes / Cymro/Cymraes / Albanwr/Albanes / Gwyddel/Gwyddeles o Ogledd Iwerddon / Prydeiniwr/Prydeinwraig",
  IRISH: "Gwyddel/Gwyddeles",
  GYPSY_OR_IRISH_TRAVELLER: "Sipsiwn neu Deithiwr Gwyddelig",
  ANY_OTHER_WHITE_BACKGROUND: "Unrhyw gefndir Gwyn arall",
  MIXED_MULTIPLE_ETHNIC_GROUPS: "Cymysg / Grwpiau Aml-ethnig",
  WHITE_AND_BLACK_CARIBBEAN: "Gwyn a Du Caribïaidd",
  WHITE_AND_BLACK_AFRICAN: "Gwyn a Du Affricanaidd",
  WHITE_AND_ASIAN: "Gwyn ac Asiaidd",
  ANY_OTHER_MIXED_MULTIPLE_ETHNIC_BACKGROUND:
    "Unrhyw gefndir Cymysg / Aml-ethnig arall",
  ASIAN_ASIAN_BRITISH: "Asiaidd / Asiaidd Prydeinig",
  INDIAN: "Indiaidd",
  PAKISTANI: "Pacistanaidd",
  BANGLADESHI: "Bangladeshaidd",
  CHINESE: "Tsieineaidd",
  ANY_OTHER_ASIAN_BACKGROUND: "Unrhyw gefndir Asiaidd arall",
  BLACK_AFRICAN_CARIBBEAN_BLACK_BRITISH:
    "Du / Affricanaidd / Caribïaidd / Du Prydeinig",
  AFRICAN: "Affricanaidd",
  CARIBBEAN: "Caribïaidd",
  ANY_OTHER_BLACK_AFRICAN_CARIBBEAN_BACKGROUND:
    "Unrhyw gefndir Du / Affricanaidd / Caribïaidd arall",
  OTHER_ETHNIC_GROUP: "Grŵp ethnig arall",
  ARAB: "Arabaidd",
  ANY_OTHER_ETHNIC_GROUP: "Unrhyw grŵp ethnig arall",
  PREFER_NOT_TO_SAY: "Gwell gennyf beidio ag ateb",
};

const ethnicList = [
  {
    label: keysWelsh.WHITE,
    options: [
      {
        label: keysWelsh.ENGLISH_WELSH_SCOTTISH_NORTHERN_IRISH_BRITISH,
        value: keys.ENGLISH_WELSH_SCOTTISH_NORTHERN_IRISH_BRITISH,
      },
      {
        label: keysWelsh.IRISH,
        value: keys.IRISH,
      },
      {
        label: keysWelsh.GYPSY_OR_IRISH_TRAVELLER,
        value: keys.GYPSY_OR_IRISH_TRAVELLER,
      },
      {
        label: keysWelsh.ANY_OTHER_WHITE_BACKGROUND,
        value: keys.ANY_OTHER_WHITE_BACKGROUND,
      },
    ],
  },
  {
    label: keysWelsh.MIXED_MULTIPLE_ETHNIC_GROUPS,
    options: [
      {
        label: keysWelsh.WHITE_AND_BLACK_CARIBBEAN,
        value: keys.WHITE_AND_BLACK_CARIBBEAN,
      },
      {
        label: keysWelsh.WHITE_AND_BLACK_AFRICAN,
        value: keys.WHITE_AND_BLACK_AFRICAN,
      },
      {
        label: keysWelsh.WHITE_AND_ASIAN,
        value: keys.WHITE_AND_ASIAN,
      },
      {
        label: keysWelsh.ANY_OTHER_MIXED_MULTIPLE_ETHNIC_BACKGROUND,
        value: keys.ANY_OTHER_MIXED_MULTIPLE_ETHNIC_BACKGROUND,
      },
    ],
  },
  {
    label: keysWelsh.ASIAN_ASIAN_BRITISH,
    options: [
      {
        label: keysWelsh.INDIAN,
        value: keys.INDIAN,
      },
      {
        label: keysWelsh.PAKISTANI,
        value: keys.PAKISTANI,
      },
      {
        label: keysWelsh.BANGLADESHI,
        value: keys.BANGLADESHI,
      },
      {
        label: keysWelsh.CHINESE,
        value: keys.CHINESE,
      },
      {
        label: keysWelsh.ANY_OTHER_ASIAN_BACKGROUND,
        value: keys.ANY_OTHER_ASIAN_BACKGROUND,
      },
    ],
  },
  {
    label: keysWelsh.BLACK_AFRICAN_CARIBBEAN_BLACK_BRITISH,
    options: [
      {
        label: keysWelsh.AFRICAN,
        value: keys.AFRICAN,
      },
      {
        label: keysWelsh.CARIBBEAN,
        value: keys.CARIBBEAN,
      },
      {
        label: keysWelsh.ANY_OTHER_BLACK_AFRICAN_CARIBBEAN_BACKGROUND,
        value: keys.ANY_OTHER_BLACK_AFRICAN_CARIBBEAN_BACKGROUND,
      },
    ],
  },
  {
    label: keysWelsh.OTHER_ETHNIC_GROUP,
    options: [
      {
        label: keysWelsh.ARAB,
        value: keys.ARAB,
      },
      {
        label: keysWelsh.OTHER_ETHNIC_GROUP,
        value: keys.OTHER_ETHNIC_GROUP,
      },
      {
        label: keysWelsh.PREFER_NOT_TO_SAY,
        value: keys.PREFER_NOT_TO_SAY,
      },
    ],
  },
];

const ethnicListValuesMapWelsh = {};
Object.keys(keys).forEach((e) => {
  ethnicListValuesMapWelsh[keys[e]] = keysWelsh[e];
});

export { ethnicListValuesMapWelsh };
export default ethnicList;
