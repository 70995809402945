import React from "react";
import { styled, useTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const Wrapper = styled("div")(({ center, m }) => ({
  width: "100%",
  height: "100%",
  display: center && "flex",
  justifyContent: center && "center",
  alignItems: center && "center",
  position: center && "absolute",
  left: 0,
  margin: m || "0",
}));

const FullPageWrapper = styled("div")(({ theme, navbar }) => ({
  position: "absolute",
  zIndex: "999",
  height: "100vh",
  width: "100vw",
  background: theme.colors.white,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  left: "0",
  top: "0",

  [theme.breakpoints.tablet]: {
    left: navbar ? "320px" : "0",
    width: navbar ? "calc(100vw - 320px)" : "100vw",
  },
}));

const Loading = ({ center, fullPage, navbar, color, m, size }) => {
  const theme = useTheme();
  if (fullPage) {
    return (
      <FullPageWrapper navbar={navbar}>
        <CircularProgress
          style={{
            color: theme.colors[color] || theme.colors.blue,
            margin: "0 auto",
          }}
          size={size || 40}
          disableShrink
        />
      </FullPageWrapper>
    );
  }
  return (
    <Wrapper center={center} m={m} size={size}>
      <CircularProgress
        style={{
          color: theme.colors[color] || theme.colors.blue,
          margin: "0 auto",
        }}
        size={size}
        disableShrink
      />
    </Wrapper>
  );
};

export default Loading;
