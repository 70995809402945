import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { connect } from "react-redux";

import * as S from "./style";
import * as T from "../../../components/Typograpy";
import { MEMBER } from "../../../constants/navRoutes";

import Image from "../../../components/Image";
import Button from "../../../components/Button";

import * as actions from "./actions";
import translate from "../../../utils/helpers/translator";

const GiftReceived = ({ gift, getGift, lang }) => {
  const history = useHistory();
  const { id } = useParams();
  const onConfirm = () => history.push(MEMBER.DASHBOARD);

  useEffect(() => {
    getGift(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <S.Wrapper>
        <Image
          image="success"
          alt="Success"
          height="100%"
          customStyle={{ maxHeight: "280px", margin: "0 auto 10px auto" }}
        />
        <S.Body>
          <T.H40Caps color="midnight" mb={20}>
            {translate("receivedGift", lang)}
          </T.H40Caps>
          <T.Body16R mb={80}>
            {translate("receivedTCsFromFriend", lang, [gift.tc])}{" "}
            <S.Emphasis>{`${gift.fromUser.firstName} ${gift.fromUser.lastName}`}</S.Emphasis>
            !
          </T.Body16R>
        </S.Body>
        <Button
          size="l"
          primary
          style={{ margin: "0" }}
          handleClick={onConfirm}
        >
          {translate("goToHomePage", lang)}
        </Button>
      </S.Wrapper>
    </>
  );
};

const mapStateToProps = (state) => ({
  getGiftLoading: state.giftTimeCredits.getGiftLoading,
  getGiftError: state.giftTimeCredits.getGiftError,
  getGiftLoaded: state.giftTimeCredits.getGiftLoaded,
  gift: state.giftTimeCredits.gift,
  lang: state.auth.language,
});

const mapActionToProps = {
  getGift: actions.getGift,
};

export default connect(mapStateToProps, mapActionToProps)(GiftReceived);
