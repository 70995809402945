import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { MTableToolbar } from "@material-table/core";
import * as S from "./style";
import * as T from "../Typograpy";
import Icon from "../Icon";
import ViewMoreLink from "../Links/ViewMoreLink";

import Pagination from "../Table/components/CustomPagination";
import { TabsLarge } from "../Tabs";

import t from "../../utils/helpers/translator";
import {
  searchFieldStyle,
  cellStyle,
  rowStyle,
  style,
  headerStyle,
} from "./style";

const decideTableHeight = (dataLength, pageSize) =>
  dataLength < pageSize ? dataLength : pageSize;

const FilterTable = ({
  color,
  columns,
  data,
  onRowClick,
  hideSearch,
  preview,
  previewPath,
  memberMobile,
  limit = 10,
  tabbedData,
  searchPlaceholder,
  language,
  isLoading,
  noPagination,
}) => {
  const [dataToShow, setDataToShow] = useState(null);
  const [pageSize, setPageSize] = useState(limit);

  const selectTab = (tabText) => {
    if (["key info", "pending"].includes(tabText)) {
      setDataToShow(tabbedData[0]);
    } else {
      setDataToShow(tabbedData[1]);
    }
  };

  useEffect(() => {
    if (tabbedData) {
      setDataToShow(tabbedData[0]);
    } else {
      setDataToShow({ columns, data });
    }
  }, [tabbedData, columns, data]);

  const calculatePageSize = () => {
    setPageSize(
      preview
        ? decideTableHeight(dataToShow?.data?.length, 5)
        : decideTableHeight(dataToShow?.data?.length, limit),
    );
  };

  return (
    <S.TableContainer noSearchInputWithTabs={hideSearch && tabbedData}>
      {tabbedData && (
        <S.TabContainer noSearchInputWithTabs={hideSearch && tabbedData}>
          <TabsLarge
            tabOneText={tabbedData[0].tabText || "key info"}
            tabTwoText={tabbedData[1].tabText || "other"}
            handleClick={selectTab}
            selected={dataToShow?.tabText}
            width="300px"
          />
        </S.TabContainer>
      )}
      <S.StyledTable
        columns={dataToShow?.columns}
        data={dataToShow?.data}
        onRowClick={onRowClick}
        rowsPerPageOptions={[]}
        onChangeRowsPerPage={calculatePageSize}
        localization={{
          toolbar: {
            searchPlaceholder: searchPlaceholder || t("search", language),
          },
          body: {
            emptyDataSourceMessage: (
              <T.H16
                color="gray3"
                style={{
                  padding: "20px",
                  textAlign: "center",
                }}
              >
                {t("noRecordsToDisplay", language)}
              </T.H16>
            ),
          },
        }}
        isLoading={isLoading}
        options={{
          pageSize,
          showTitle: false,
          toolbar: !hideSearch,
          searchFieldAlignment: "left",
          sorting: true,
          grouping: false,
          defaultExpanded: true,
          searchFieldStyle,
          rowStyle: rowStyle(color, memberMobile),
          cellStyle: cellStyle(color),
          headerStyle: headerStyle(color, memberMobile),
        }}
        style={style}
        icons={{
          SortArrow: () => null,
          DetailPanel: (props) => (
            <div>
              <Icon icon="arrowUp" {...props} width="15px" height="15px" />
            </div>
          ),
        }}
        components={{
          Toolbar: (props) => (
            <S.ToolbarContent tabbedData={tabbedData}>
              <MTableToolbar {...props} />
            </S.ToolbarContent>
          ),

          Pagination: (props) => {
            const { page, count, onPageChange } = props;
            if (preview)
              return (
                <ViewMoreLink
                  path={previewPath}
                  showIcon
                  showText
                  padding="40px 0 0 5px"
                  tableMobile={memberMobile}
                />
              );
            return (
              !noPagination && (
                <Pagination
                  page={page}
                  count={count}
                  onChangePage={onPageChange}
                  color={color}
                  limit={limit}
                  language={language}
                />
              )
            );
          },
        }}
      />
    </S.TableContainer>
  );
};

const mapStateToProps = (state) => ({
  language: state.auth.language,
});

export default connect(mapStateToProps)(FilterTable);
