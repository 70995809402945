import React from "react";
import { Redirect } from "react-router-dom";

import { GENERAL } from "../../../constants/navRoutes";
import { roles } from "../../../constants";

import MemberAccount from "./MemberAccount";
import EarnGroupAccount from "./EarnGroupAccount";
import SpendVenueAccount from "./SpendVenueAccount";
import ProgrammeManagerAccount from "./ProgrammeManagerAccount";
import SpendPartnerAccount from "./SpendPartnerAccount";
import validate from "../../../validation/schemas/myAccount";

const Content = ({
  role,
  onChange,
  handleClick,
  errors,
  showNotification,
  userAccount,
  getUserAccountAction,
  btnOn,
  onSelectChange,
  ...props
}) => {
  switch (role) {
    case roles.MEMBER:
      return (
        <MemberAccount
          role={role}
          validate={validate}
          onChange={onChange}
          handleClick={handleClick}
          errors={errors}
          showNotification={showNotification}
          userAccount={userAccount}
          getUserAccountAction={getUserAccountAction}
          isUpdating={userAccount.updateAccountLoading}
          btnOn={btnOn}
          {...props}
        />
      );

    case roles.EARN_GROUP:
    case roles.EARN_GROUP_ADMIN:
      return (
        <EarnGroupAccount
          role={role}
          validate={validate}
          onChange={onChange}
          handleClick={handleClick}
          errors={errors}
          showNotification={showNotification}
          onSelectChange={onSelectChange}
          userAccount={userAccount}
          getUserAccountAction={getUserAccountAction}
          isUpdating={userAccount.updateAccountLoading}
          btnOn={btnOn}
          mainError={userAccount.error}
          loading={userAccount.updateAccountLoading}
          {...props}
        />
      );

    case roles.SPEND_VENUE:
      return (
        <SpendVenueAccount
          role={role}
          validate={validate}
          onChange={onChange}
          handleClick={handleClick}
          errors={errors}
          showNotification={showNotification}
          userAccount={userAccount}
          getUserAccountAction={getUserAccountAction}
          isUpdating={userAccount.updateAccountLoading}
          btnOn={btnOn}
          mainError={userAccount.error}
          {...props}
        />
      );
    case roles.SPEND_PARTNER:
      return (
        <SpendPartnerAccount
          validate={validate}
          onChange={onChange}
          handleClick={handleClick}
          errors={errors}
          showNotification={showNotification}
          userAccount={userAccount}
          getUserAccountAction={getUserAccountAction}
          isUpdating={userAccount.updateAccountLoading}
          btnOn={btnOn}
          mainError={userAccount.error}
          {...props}
        />
      );
    case roles.PROGRAMME_MANAGER:
      return (
        <ProgrammeManagerAccount
          validate={validate}
          onChange={onChange}
          handleClick={handleClick}
          errors={errors}
          showNotification={showNotification}
          userAccount={userAccount}
          getUserAccountAction={getUserAccountAction}
          isUpdating={userAccount.updateAccountLoading}
          btnOn={btnOn}
          mainError={userAccount.error}
          {...props}
        />
      );

    default:
      return <Redirect to={GENERAL.UNAUTHORIZED} {...props} />;
  }
};

export default Content;
