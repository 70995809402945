import React from "react";
import * as S from "./style";
import Copy from "../Copy";

const InviteLink = ({ color, url, background, style }) => (
  <S.InviteLinkWrapper background={background} style={style}>
    <S.InviteLink href={url} target="_blank" color={color}>
      {url}
    </S.InviteLink>
    <Copy showText strToCopy={url} />
  </S.InviteLinkWrapper>
);
export default InviteLink;
