import { paidPackages } from "../../../../api-calls";

import {
  GET_ORDERS_SUCCESS,
  GET_ORDERS_LOADING,
  GET_ORDERS_FAIL,
  CONFIRM_ORDER_CANCELED_LOADING,
  CONFIRM_ORDER_CANCELED_SUCCESS,
  CONFIRM_ORDER_CANCELED_FAIL,
} from "../../../../redux/types";

export const getOrders =
  ({ startDate, endDate } = {}) =>
  async (dispatch) => {
    dispatch({ type: GET_ORDERS_LOADING });
    try {
      const { data, error } = await paidPackages.getOrders({
        startDate,
        endDate,
      });
      if (data) {
        dispatch({ type: GET_ORDERS_SUCCESS, payload: data });
      } else {
        dispatch({
          type: GET_ORDERS_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_ORDERS_FAIL,
        payload: error,
      });
    }
  };

export const confirmCancelOrder =
  ({ orderId, cancellationReason }) =>
  async (dispatch) => {
    dispatch({ type: CONFIRM_ORDER_CANCELED_LOADING });
    try {
      const { data, error } = await paidPackages.cancelOrder({
        orderId,
        cancellationReason,
      });
      if (!error) {
        dispatch({ type: CONFIRM_ORDER_CANCELED_SUCCESS, payload: data });
        dispatch(getOrders());
      } else {
        dispatch({
          type: CONFIRM_ORDER_CANCELED_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: CONFIRM_ORDER_CANCELED_FAIL,
        payload: error,
      });
    }
  };
