import {
  GET_MY_GROUPS_LOADING,
  GET_MY_GROUPS_SUCCESS,
  GET_MY_GROUPS_FAIL,
} from "../../../redux/types";

const initState = {
  data: [],
  loading: false,
  error: null,
};

const getMyGroupsReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_MY_GROUPS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_MY_GROUPS_SUCCESS:
      return {
        ...state,
        data: payload,
        loading: false,
      };
    case GET_MY_GROUPS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default getMyGroupsReducer;
