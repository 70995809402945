import { fields, createSchema, validate as _validate } from "..";

const { email } = fields;

const extProgManagers = createSchema({
  email,
});

const validate = (data) => _validate(extProgManagers, data);

export default validate;
