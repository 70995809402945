import React from "react";

import * as S from "./style";
import * as T from "../../Typograpy";
import TimeCredit from "../../TimeCredit";
import t from "../../../utils/helpers/translator";

const SpendActivity = ({
  activity,
  cost,
  costVaries,
  m,
  handleClick,
  selected,
  id,
  customWidth,
  disabled,
  language,
  showComingSoon,
  setOpenModal,
  hasRestrictionReached,
}) => {
  const onClick = () => {
    if (hasRestrictionReached) {
      const secondClick = !!selected;
      if (secondClick) {
        return setOpenModal(true);
      }
    } else if (selected) return handleClick(null);

    return handleClick(id);
  };

  return (
    <S.SpendWrapper
      onClick={onClick}
      selected={selected}
      m={m}
      id={id}
      customWidth={customWidth}
      disabled={disabled}
    >
      <S.RestrictionLayer restriction={showComingSoon}>
        <S.RestrictionTag>
          <T.Body14B color="white">
            {t("sold Out", language).toUpperCase()}
            {/* UPDATE THIS */}
          </T.Body14B>
        </S.RestrictionTag>
      </S.RestrictionLayer>
      <S.Header selected={selected}>
        <T.H20 color="midnight" style={{ textTransform: "capitalize" }}>
          {activity}
        </T.H20>
      </S.Header>
      <S.Bottom selected={selected} customWidth={customWidth}>
        <TimeCredit
          credits={cost}
          costVaries={costVaries}
          description
          subDescription="per person"
          color="white"
          size="small"
        />
      </S.Bottom>
    </S.SpendWrapper>
  );
};

export default SpendActivity;
