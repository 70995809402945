import React from "react";
import { connect } from "react-redux";
import * as T from "../Typograpy";
import Loading from "../Loading";
import t from "../../utils/helpers/translator";

const TCBalance = ({ color, credits, loading, language, ...props }) => (
  <div {...props}>
    <T.Body18B color={color || "midnight"} style={{ lineHeight: "20px" }}>
      {t("balance", language)}:
    </T.Body18B>
    {loading ? <Loading /> : <T.H60Caps color="midnight">{credits}</T.H60Caps>}
    <T.H22Caps color={color || "midnight"} style={{ lineHeight: "25px" }}>
      {t("timeCreditsNumInFront", language)}
    </T.H22Caps>
  </div>
);

const mapStateToProps = (state) => ({
  language: state.auth.language,
});

export default connect(mapStateToProps)(TCBalance);
