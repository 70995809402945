import { styled } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

export const Wrapper = styled("section")(() => ({}));

export const FocusContainer = styled("div")(({ theme }) => ({
  marginBottom: 10,
  "& > div > div > div": {
    maxWidth: "100%",
  },
  [theme.breakpoints.mobile]: {
    marginBottom: 20,
  },
  [theme.breakpoints.tablet]: {
    marginBottom: 60,
  },
}));

export const FiledWrapper = styled("div")(() => ({
  marginTop: 30,
}));

export const SubmitWrapper = styled("div")(() => ({
  marginTop: 80,
  marginBottom: 60,
}));

export const ImageContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  right: 0,
  top: 0,
  display: "none",
  [theme.breakpoints.tablet]: {
    display: "block",
  },
}));

export const InputWrapper = styled("div")(() => ({
  marginTop: 30,
}));

export const Error = styled(Typography)(({ theme }) => ({
  width: "100%",
  color: theme.colors.pink,
  fontSize: "0.875rem",
  textAlign: "center",
  marginBottom: theme.spacing(1),
}));
