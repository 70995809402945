import { styled } from "@material-ui/core/styles";

export const Wrapper = styled("section")(({ success }) => ({
  maxWidth: success ? "400px" : "350px",
  margin: "0 auto",
  marginTop: "10px",
}));

export const Header = styled("header")(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(6),
  alignItems: "flex-end",
}));

export const Body = styled("div")(() => {});

export const ButtonWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  height: "140px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  maxWidth: "320px",
  margin: "0 auto",
  marginBottom: theme.spacing(7),
}));

export const ImageWrapper = styled("span")(({ right }) => ({
  position: "absolute",
  right: right && "0",
}));

export const SuccessImage = styled("span")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  marginBottom: theme.spacing(2),
}));

export const StyledButton = styled("button")(({ theme }) => ({
  background: theme.colors.white,
  outline: "none",
  border: "none",
  display: "flex",
  alignItems: "center",
  fontSize: "0.75rem",
  fontWeight: "bold",
  color: theme.colors.gray3,
  paddingTop: theme.spacing(0),
  cursor: "pointer",
}));

export const Emphasis = styled("span")(() => ({
  fontWeight: "bold",
}));
