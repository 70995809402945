import React, { useEffect } from "react";
import { connect } from "react-redux";

import {
  getUserTransactions,
  getUserCurrentTc,
  cancelGift,
} from "../../../redux/actions/userActions";

import * as S from "./style";
// components
import * as T from "../../../components/Typograpy";
import Image from "../../../components/Image";
import Transactions from "./AllTransactionsLogic";

import translate from "../../../utils/helpers/translator";

const MemberTransactionsHistory = (props) => {
  const {
    getUserCurrentTcAction,
    currentTc,
    getUserTransactionsAction,
    isMobile,
    lang,
  } = props;

  useEffect(() => {
    getUserCurrentTcAction();
    getUserTransactionsAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userTc = currentTc && currentTc;

  const renderHeadline = {
    mobile: () => (
      <S.HeadlineContainer>
        <T.H30Caps color="midnight" mt={20}>
          {translate("myTCHistory", lang)}
        </T.H30Caps>
        <T.Body16B color="blue" mb={0} style={{ marginTop: 20 }}>
          {translate("myTCBalance", lang)}: {` ${userTc}` || "0"}
        </T.Body16B>
      </S.HeadlineContainer>
    ),
    desktop: () => (
      <S.HeadlineContainer>
        <T.H40 style={{ marginLeft: "0" }} color="midnight">
          {translate("myTCHistory", lang)}
        </T.H40>
        <T.H24 color="blue" style={{ marginLeft: "0", marginTop: 30 }}>
          {translate("myTCBalance", lang)}: {` ${userTc}` || "0"}
        </T.H24>
      </S.HeadlineContainer>
    ),
  };

  return (
    <S.Wrapper>
      {isMobile ? (
        <S.HeaderWrapperMobile>
          <Image image="cinema" alt="Cinema" height="200px" />
          {renderHeadline.mobile()}
        </S.HeaderWrapperMobile>
      ) : (
        <S.HeaderWrapper style={{ alignItems: "flex-start" }}>
          {renderHeadline.desktop()}
          <Image image="cinemaLarge" alt="Cinema" height="180px" />
        </S.HeaderWrapper>
      )}
      {/* TRANSACTION HISTORY */}
      <S.Section>
        <Transactions {...props} />
      </S.Section>
    </S.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  userInfo: {
    name: state.auth.firstName,
    id: state.auth.id,
    membershipId: state.auth.profile.membershipId,
  },
  currentTc: state.user.tc.tcBalance,
  transactions: state.user.transactions,
  lang: state.auth.language,
});

const mapActionToProps = {
  getUserTransactionsAction: getUserTransactions,
  getUserCurrentTcAction: getUserCurrentTc,
  cancelGift,
};

export default connect(
  mapStateToProps,
  mapActionToProps,
)(MemberTransactionsHistory);
