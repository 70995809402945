import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getSpendActivities } from "../../../redux/actions/activities";
import { getEarnGroups } from "../../../redux/actions/earnGroups";
import {
  getUserTransactions,
  getUserCurrentTc,
  cancelGift,
} from "../../../redux/actions/userActions";

import * as S from "./style";
// components
import { Col } from "../../../components/Grid";
import * as T from "../../../components/Typograpy";
import Gift from "../../../components/Cards/Gift";
import EarnSpendOpportunity from "../../../components/Cards/EarnSpendOpportunity";
import ViewMoreLink from "../../../components/Links/ViewMoreLink";
import Loading from "../../../components/Loading";
import Transactions from "../../MemberRoutes/Transactions/AllTransactionsLogic";
import translate from "../../../utils/helpers/translator";

import Header from "./Header";

// routes
import { GENERAL } from "../../../constants/navRoutes";

const RenderSectionHeadline = ({ isMobile, title, withTC, lang, currentTc }) =>
  isMobile ? (
    <>
      <T.H22
        style={{ paddingBottom: "1rem", marginLeft: "0" }}
        color="midnight"
      >
        {translate(title, lang)}
      </T.H22>
      {withTC && (
        <T.Body16B
          color="blue"
          style={{
            alignSelf: "start",
            margin: "0",
            textTransform: "capitalize",
          }}
        >
          {translate("myTCBalance", lang)}: {` ${currentTc}` || "0"}
        </T.Body16B>
      )}
    </>
  ) : (
    <>
      <T.H30
        style={{ paddingBottom: "1.875rem", marginLeft: "0" }}
        color="midnight"
      >
        {translate(title, lang)}
      </T.H30>
      {withTC && (
        <T.H24
          color="blue"
          style={{
            margin: "-5px 0 40px 0",
          }}
        >
          {translate("myTCBalance", lang)}: {` ${currentTc}` || "0"}
        </T.H24>
      )}
    </>
  );

const RenderCardsHorizontalScroll = ({
  cards,
  type,
  isLoading = false,
  lang,
}) => {
  if (isLoading) {
    return <Loading />;
  }
  if (!cards || cards.length === 0) {
    return <div>{translate("noActivitiesListed", lang)}</div>;
  }
  if (cards.length > 0 && type === "spend") {
    return cards.map((activity) => (
      <Col w={[4, 6, 4]} style={{ marginLeft: "-8px" }}>
        <EarnSpendOpportunity
          path={GENERAL.SPEND_ACTIVITY.replace(":id", activity.id)}
          imgUrl={activity.imageURL}
          title={activity.name}
          subtitle={activity.subTitle}
          credits={activity.cost}
          venue={activity.spendVenue.name}
          cities={activity.city}
          isOnline={activity.isOnline}
          costVaries={activity.costVaries}
          soldOut={
            activity.soldOut ||
            (!activity.turnUpAtVenue &&
              !activity.callToBook &&
              !Number(activity.numberOfAvailableCodes) > 0)
          }
          type="spend"
        />
      </Col>
    ));
  }

  if (cards.length > 0 && type === "earn") {
    return cards.map((group) => (
      <Col w={[4, 6, 4]} style={{ marginLeft: "-8px" }}>
        <EarnSpendOpportunity
          path={GENERAL.EARN_GROUP.replace(":id", group.id)}
          imgUrl={group.imageURL}
          title={group.name}
          type="earn"
          cities={group.city}
          isOnline={group.isOnline}
        />
      </Col>
    ));
  }
};

const MemberDashboard = (props) => {
  const {
    isMobile,
    getSpendActivitiesAction,
    getUserCurrentTcAction,
    getUserTransactionsAction,
    spendActivities = [],
    getEarnGroupsAction,
    earnGroups = [],
    earnGroupsLoading,
    spendActivitiesLoading,
    currentTc,
    lang,
  } = props;

  useEffect(() => {
    getSpendActivitiesAction({ isPublic: false, limit: 5, lang });
    getEarnGroupsAction({ isPublic: false, lang, limit: 5 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    getUserCurrentTcAction();
    getUserTransactionsAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sectionHeadlines = {
    gift: "giftTC",
    earn: "earnTC",
    spend: "spendTC",
    transactions: "myTCHistory",
  };

  return (
    <S.Wrapper>
      <Header {...props} />
      {/* EARN */}
      <S.Section>
        <RenderSectionHeadline
          isMobile={isMobile}
          title={sectionHeadlines.earn}
          lang={lang}
          currentTc={currentTc}
        />
        <S.CardsWrapper>
          <RenderCardsHorizontalScroll
            cards={earnGroups}
            type="earn"
            isLoading={earnGroupsLoading}
          />
        </S.CardsWrapper>
        <ViewMoreLink
          path={GENERAL.EARN}
          showIcon
          showText
          padding="10px 0 0 15px"
        />
      </S.Section>
      {/* SPEND */}
      <S.Section>
        <RenderSectionHeadline
          title={sectionHeadlines.spend}
          isMobile={isMobile}
          lang={lang}
        />
        <S.CardsWrapper>
          <RenderCardsHorizontalScroll
            cards={spendActivities}
            type="spend"
            isLoading={spendActivitiesLoading}
          />
        </S.CardsWrapper>
        <ViewMoreLink
          path={GENERAL.SPEND}
          showIcon
          showText
          padding="10px 0 0 15px"
        />
      </S.Section>

      {/* GIFT TC */}
      <S.Section>
        <RenderSectionHeadline
          title={sectionHeadlines.gift}
          isMobile={isMobile}
          lang={lang}
        />
        <Gift w="300px" />
      </S.Section>

      {/* TRANSACTION HISTORY */}
      <S.TransactionSection>
        <RenderSectionHeadline
          isMobile={isMobile}
          title={sectionHeadlines.transactions}
          withTC
          lang={lang}
          currentTc={currentTc}
        />

        <Transactions cancelGift={cancelGift} preview {...props} />
      </S.TransactionSection>
    </S.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  userInfo: {
    name: state.auth.firstName,
    id: state.auth.id,
    membershipId: state.auth.profile.membershipId,
  },
  earnGroups: state.earnGroups.data,
  earnGroupsLoading: state.earnGroups.getEarnGroupsLoading,
  spendActivities: state.activities.spendActivities,
  spendActivitiesLoading: state.activities.getSpendActivitiesLoading,
  currentTc: state.user.tc.tcBalance,
  transactions: {
    data: state.user?.transactions?.data?.slice(0, 5),
    loading: state.user?.transactions?.loading,
  },
  lang: state.auth.language,
  multipleUsers: state.auth.multipleUsers,
});

const mapActionToProps = {
  getSpendActivitiesAction: getSpendActivities,
  getEarnGroupsAction: getEarnGroups,
  getUserCurrentTcAction: getUserCurrentTc,
  getUserTransactionsAction: getUserTransactions,
  cancelGift,
};

export default connect(mapStateToProps, mapActionToProps)(MemberDashboard);
