import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import * as LogRocket from "../utils/helpers/LogRocket";

import reducers from "./reducers";

const initialState = {
  isMounted: false,
};

const middleware = [thunk];

const store = createStore(
  reducers,
  initialState,
  composeWithDevTools(
    applyMiddleware(...middleware, LogRocket.reduxLogRocket()),
  ),
);

export default store;
