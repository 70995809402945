import React from "react";
import * as S from "./style";
import * as T from "../../../components/Typograpy";

import Info from "../../../components/Message/Info";
import { Dropdown, Counter } from "../../../components/Inputs";
import RemoveRejoin from "../../../components/RemoveRejoin";
import Button from "../../../components/Button";
import TCBalance from "../../../components/TCBalance";
import Icon from "../../../components/Icon";
import { Row, Col } from "../../../components/Grid";
import t from "../../../utils/helpers/translator";
import alphabetise from "../../../utils/helpers/alphabetise";

const Step2 = ({
  members,
  selectedMembers,
  handleMembers,
  handleCredits,
  totalCredits,
  balance,
  removeMember,
  ableToSubmit,
  isTablet,
  giveCreditsToAll,
  applyAllCredits,
  handleGiveOut,
  loadingIds,
  successIds,
  failedIds,
  bulkLoading,
  language,
  usingPrevious,
}) => {
  const enoughCredits = totalCredits <= balance;

  const renderIcons = (index, credits, memberId) => {
    // TODO show loading spinner/done/error icons
    if (successIds.includes(memberId)) {
      return (
        <Icon
          icon="tickRound"
          width="20px"
          height="20px"
          margin="0 10px 0 0"
          color="white"
        />
      );
    }
    if (loadingIds.includes(memberId)) {
      return <S.SmallSpan>. . .</S.SmallSpan>;
    }
    if (failedIds.includes(memberId)) {
      return <S.SmallSpan>{t("error", language)}</S.SmallSpan>;
    }

    return (
      <RemoveRejoin
        type="Remove"
        handleClick={() => removeMember(index, credits)}
      />
    );
  };
  return (
    <S.Container>
      <S.Header>
        <S.Heading>{t("giveOutTempoTC", language)}</S.Heading>
        {!isTablet && <TCBalance color="pink" credits={balance} />}
      </S.Header>
      <S.SideWrapper>
        {usingPrevious !== null ? (
          <Info
            header={`${t("rememberToCheck", language)}.`}
            body={`${t("rememberToCheckDescription", language)}.`}
          />
        ) : (
          <Info
            header={`${t("recogniseHours", language)}.`}
            body={`${t("oneTimeCreditCanBeGiven", language)}.`}
          />
        )}
      </S.SideWrapper>
      <Row>
        <Col w={[4, 6, 6]}>
          <Dropdown
            name="members"
            options={alphabetise(members)}
            value={selectedMembers}
            setValue={handleMembers}
            mt="5"
            mb="4"
            placeholder={t("selectMembers", language)}
            label={t("whoGiveTCTo", language)}
            isMulti
            allowSelectAll
            closeMenuOnSelect={false}
            blurInputOnSelect={false}
          />
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 12]}>
          <S.Question
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              lineHeight: "25px",
            }}
          >
            {t("howManyTCsGiveOut", language)}?
          </S.Question>
          <S.MemberTable>
            <tbody>
              <tr>
                <S.StyledTH col="1">
                  <S.TableHeading color="midnight">
                    {t("members", language)}
                  </S.TableHeading>
                </S.StyledTH>
                <S.StyledTH col="2">
                  <S.TableHeading color="midnight">
                    {t("timeCredits", language)}
                  </S.TableHeading>
                </S.StyledTH>
                <S.StyledTH col="3"> </S.StyledTH>
              </tr>
              {selectedMembers.length > 0 ? (
                <>
                  {selectedMembers.map((member, index) => (
                    <tr key={member.id}>
                      <S.StyledTD>
                        <S.Name color="midnight">{member.label}</S.Name>
                      </S.StyledTD>
                      <S.StyledTD>
                        <Counter
                          value={member.credits}
                          setValue={handleCredits}
                          m="auto 0"
                          passProps={{ memberID: member.id }}
                          justify="start"
                          size="small"
                        />
                      </S.StyledTD>
                      <S.StyledTD>
                        {renderIcons(index, member.credits, member.id)}
                      </S.StyledTD>
                    </tr>
                  ))}
                  <tr>
                    <S.StyledTD>
                      <S.ApplyAll>{t("applyAll", language)}</S.ApplyAll>
                    </S.StyledTD>
                    <S.StyledTD>
                      <Counter
                        value={applyAllCredits}
                        setValue={giveCreditsToAll}
                        m="auto 0"
                        justify="start"
                        size="small"
                      />
                    </S.StyledTD>
                  </tr>
                </>
              ) : (
                <tr>
                  <S.StyledTD colspan="3">
                    <T.Body16I color="gray3">
                      {t("noMembers", language)}
                    </T.Body16I>
                  </S.StyledTD>
                </tr>
              )}
            </tbody>
          </S.MemberTable>
          <S.CreditsTotal>
            <T.Body18B
              color={enoughCredits ? "midnight" : "pink"}
              style={{ margin: "0 5px" }}
            >
              {t("totalTC", language)}:
            </T.Body18B>
            <T.H24
              color={enoughCredits ? "midnight" : "pink"}
              style={{ margin: "0 10px" }}
            >
              {totalCredits}
            </T.H24>
          </S.CreditsTotal>
          <T.Body16R color="gray3" style={{ margin: "0 5px" }}>
            {t("remainingBalance", language)}: {balance - totalCredits}
          </T.Body16R>
          {!enoughCredits && (
            <T.Body14R color="pink" style={{ margin: "10px 5px" }}>
              {t("sorryNotEnoughInYourAccount", language)}
            </T.Body14R>
          )}
          <Row>
            <Col w={[4, 6, 6]}>
              <Button
                primary
                size="large"
                disabled={!ableToSubmit || failedIds.length > 0 || bulkLoading}
                mt={30}
                onClick={handleGiveOut}
                loading={bulkLoading}
              >
                {t("sayThankYou", language)}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </S.Container>
  );
};

export default Step2;
