import { programmes } from "../../../../../api-calls";

import {
  GET_PROGRAMME_MANAGERS_LOADING_START,
  GET_PROGRAMME_MANAGERS_LOADING_END,
  GET_PROGRAMME_MANAGERS_SUCCESS,
  GET_PROGRAMME_MANAGERS_FAIL,
  ADD_PROGRAMME_MANAGER_LOADING_START,
  ADD_PROGRAMME_MANAGER_LOADING_END,
  ADD_PROGRAMME_MANAGER_SUCCESS,
  ADD_PROGRAMME_MANAGER_FAIL,
  DELETE_PROGRAMME_MANAGER_LOADING_START,
  DELETE_PROGRAMME_MANAGER_LOADING_END,
  DELETE_PROGRAMME_MANAGER_SUCCESS,
  DELETE_PROGRAMME_MANAGER_FAIL,
} from "../../../../../redux/types";

export const getProgrammeManagers = (programmeId) => async (dispatch) => {
  dispatch({ type: GET_PROGRAMME_MANAGERS_LOADING_START });
  try {
    const { data, error } = await programmes.getProgrammeManagers({
      programmeId,
    });
    if (data) {
      dispatch({ type: GET_PROGRAMME_MANAGERS_SUCCESS, payload: data });
    } else {
      dispatch({
        type: GET_PROGRAMME_MANAGERS_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_PROGRAMME_MANAGERS_FAIL,
      payload: error,
    });
  } finally {
    dispatch({ type: GET_PROGRAMME_MANAGERS_LOADING_END });
  }
};

export const addNewManager =
  ({ email, programmeId }) =>
  async (dispatch) => {
    dispatch({ type: ADD_PROGRAMME_MANAGER_LOADING_START });
    try {
      const { error, data } = await programmes.addProgrammeManager({
        email,
        programmeId,
      });
      if (!error) {
        dispatch({
          type: ADD_PROGRAMME_MANAGER_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: ADD_PROGRAMME_MANAGER_FAIL,
          payload: error,
        });
        throw error;
      }
    } catch (error) {
      dispatch({
        type: ADD_PROGRAMME_MANAGER_FAIL,
        payload: error,
      });
      throw error;
    } finally {
      dispatch({ type: ADD_PROGRAMME_MANAGER_LOADING_END });
    }
  };

export const deleteProgrammeManagers =
  ({ programmeId, managerId }) =>
  async (dispatch) => {
    dispatch({ type: DELETE_PROGRAMME_MANAGER_LOADING_START });
    try {
      const { error } = await programmes.deleteProgrammeManagers({
        programmeId,
        managerId,
      });
      if (!error) {
        dispatch({
          type: DELETE_PROGRAMME_MANAGER_SUCCESS,
          payload: managerId,
        });
      } else {
        dispatch({
          type: DELETE_PROGRAMME_MANAGER_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: DELETE_PROGRAMME_MANAGER_FAIL,
        payload: error,
      });
    } finally {
      dispatch({ type: DELETE_PROGRAMME_MANAGER_LOADING_END });
    }
  };
