import { styled } from "@material-ui/core/styles";

export const Wrapper = styled("form")(({ theme }) => ({
  [theme.breakpoints.tablet]: {
    marginTop: "-85px",
  },
}));

export const Options = styled("div")(() => ({
  display: "flex",
  flexWrap: "wrap",
}));

export const ButtonsWrapper = styled("div")(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
}));

export const PageButton = styled("button")(({ prev }) => ({
  background: "none",
  border: "none",
  cursor: "pointer",
  textAlign: "right",
  margin: prev ? "0 auto 0 0" : "0 0 0 auto",
}));

export const SortByWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));
