import {
  GET_PENDING_REQUESTS_LOADING_START,
  GET_PENDING_REQUESTS_LOADING_END,
  GET_PENDING_REQUESTS_SUCCESS,
  GET_PENDING_REQUESTS_FAIL,
  GET_PREV_REQUESTS_LOADING_START,
  GET_PREV_REQUESTS_LOADING_END,
  GET_PREV_REQUESTS_SUCCESS,
  GET_PREV_REQUESTS_FAIL,
  SELECT_CREDITS_REQUEST,
  GET_SINGLE_REQUEST_SUCCESS,
  GET_SINGLE_REQUEST_FAIL,
  ISSUE_TC_LOADING_START,
  ISSUE_TC_LOADING_END,
  ISSUE_TC_SUCCESS,
  ISSUE_TC_FAIL,
  GET_MEMBER_INFO_TO_ISSUEE_TC_LOADING_START,
  GET_MEMBER_INFO_TO_ISSUEE_TC_LOADING_END,
  GET_MEMBER_INFO_TO_ISSUEE_TC_SUCCESS,
  GET_MEMBER_INFO_TO_ISSUEE_TC_FAIL,
  GET_EARN_GROUP_INFO_TO_ISSUEE_TC_LOADING_START,
  GET_EARN_GROUP_INFO_TO_ISSUEE_TC_LOADING_END,
  GET_EARN_GROUP_INFO_TO_ISSUEE_TC_SUCCESS,
  GET_EARN_GROUP_INFO_TO_ISSUEE_TC_FAIL,
  RESET_ISSUE_TC_STATE,
} from "../../../../redux/types";

import { getUserCurrentTc } from "../../../../redux/actions/userActions";

import {
  creditsRequests,
  programmes,
  transactions,
  members,
  earnGroups,
} from "../../../../api-calls";
import { PROGRAMME_MANAGER, TEMPO } from "../../../../constants/navRoutes";

import { roles } from "../../../../constants";

export const getPendingCreditsRequests =
  ({ programmeId }) =>
  async (dispatch) => {
    dispatch({ type: GET_PENDING_REQUESTS_LOADING_START });
    let data;
    let error;
    if (programmeId) {
      ({ data, error } = await programmes.getProgrammeCreditRequests({
        programmeId,
        status: "pending",
        options: { showModal: true },
      }));
    } else {
      ({ data, error } = await creditsRequests.getCreditsRequests({
        status: "pending",
      }));
    }

    dispatch({ type: GET_PENDING_REQUESTS_LOADING_END });

    if (!error) {
      dispatch({
        type: GET_PENDING_REQUESTS_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: GET_PENDING_REQUESTS_FAIL,
        payload: error,
      });
    }
  };

export const getPreviousCreditsRequests =
  ({ programmeId }) =>
  async (dispatch) => {
    dispatch({ type: GET_PREV_REQUESTS_LOADING_START });
    let data;
    let error;
    if (programmeId) {
      ({ data, error } = await programmes.getProgrammeCreditRequests({
        programmeId,
        status: "previous",
        options: { showModal: true },
      }));
    } else {
      ({ data, error } = await creditsRequests.getCreditsRequests({
        status: "previous",
      }));
    }

    dispatch({ type: GET_PREV_REQUESTS_LOADING_END });

    if (!error) {
      dispatch({
        type: GET_PREV_REQUESTS_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: GET_PREV_REQUESTS_FAIL,
        payload: error,
      });
    }
  };

export const selectCreditsRequest = (selectedRequest) => ({
  type: SELECT_CREDITS_REQUEST,
  payload: selectedRequest,
});

export const getCreditsRequestById =
  ({ id }) =>
  async (dispatch) => {
    const { data, error } = await creditsRequests.getCreditsRequestById({
      id,
    });

    if (!error) {
      dispatch({
        type: GET_SINGLE_REQUEST_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: GET_SINGLE_REQUEST_FAIL,
        payload: error,
      });
    }
  };

export const issueTimeCredits =
  ({
    id,
    status,
    tc,
    toUserId,
    message,
    isWithoutRequest,
    name,
    history,
    programmeId,
    role,
    exchange,
    toRole,
  }) =>
  async (dispatch) => {
    let data;
    let error;
    dispatch({ type: ISSUE_TC_LOADING_START });
    if (isWithoutRequest) {
      const res = await creditsRequests.updateCreditsRequest({
        id,
        status,
        tc,
        responseMessage: message,
        programmeId,
      });

      data = res.data;
      error = res.error;
    } else {
      const res = await transactions.createTransaction({
        tc,
        toUserId,
        programmeId,
        message,
        type: exchange ? "exchange" : "free",
        exchange,
        toRole,
      });

      data = res.data;
      error = res.error;
    }

    dispatch({ type: ISSUE_TC_LOADING_END });
    if (!error) {
      dispatch({
        type: ISSUE_TC_SUCCESS,
        payload: data,
      });
      if (isWithoutRequest) {
        history.push({
          pathname:
            role === roles.PROGRAMME_MANAGER
              ? PROGRAMME_MANAGER.REQUEST_SUCCESS
              : TEMPO.REQUEST_SUCCESS,
          state: { name, tc, creditResponse: status },
        });
      }

      if (!isWithoutRequest) {
        history.push({
          pathname:
            role === roles.PROGRAMME_MANAGER
              ? PROGRAMME_MANAGER.ISSUE_SUCCESS
              : TEMPO.ISSUE_SUCCESS,
          state: { name, tc, creditResponse: "issue" },
        });
      }

      if (role === roles.PROGRAMME_MANAGER) {
        dispatch(getUserCurrentTc());
      }
    } else {
      dispatch({
        type: ISSUE_TC_FAIL,
        payload: error,
      });
    }
  };

export const getUserInfo =
  ({ id, role }) =>
  async (dispatch) => {
    if (role === "member") {
      dispatch({ type: GET_MEMBER_INFO_TO_ISSUEE_TC_LOADING_START });
      const { data, error } = await members.getMemberById({
        id,
        basic: true,
      });

      const { data: programmesData, error: pErr } =
        await programmes.getProgrammes({ type: "basic" });

      dispatch({ type: GET_MEMBER_INFO_TO_ISSUEE_TC_LOADING_END });

      if (!error && !pErr) {
        dispatch({
          type: GET_MEMBER_INFO_TO_ISSUEE_TC_SUCCESS,
          payload: { ...data, programmes: programmesData },
        });
      } else {
        dispatch({
          type: GET_MEMBER_INFO_TO_ISSUEE_TC_FAIL,
          payload: error || pErr,
        });
      }
    } else if (role === "earnGroup") {
      dispatch({ type: GET_EARN_GROUP_INFO_TO_ISSUEE_TC_LOADING_START });
      const { data, error } = await earnGroups.getEarnGroupByUserId({
        id,
        basic: true,
      });

      dispatch({ type: GET_EARN_GROUP_INFO_TO_ISSUEE_TC_LOADING_END });

      if (!error) {
        dispatch({
          type: GET_EARN_GROUP_INFO_TO_ISSUEE_TC_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: GET_EARN_GROUP_INFO_TO_ISSUEE_TC_FAIL,
          payload: error,
        });
      }
    }
  };

export const resetState = () => ({ type: RESET_ISSUE_TC_STATE });
