import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import styled from "@material-ui/styles/styled";
import Typograpy from "@material-ui/core/Typography";
import * as T from "../../components/Typograpy";
import { Row } from "../../components/Grid";

export const StyledRow = styled(Row)(({ theme }) => ({
  marginTop: theme.spacing(4),

  [theme.breakpoints.mobileM]: {
    marginTop: theme.spacing(6),
  },
  [theme.breakpoints.mobileL]: {
    marginTop: theme.spacing(8),
  },
}));

export const LargeImage = styled(Grid)(({ theme }) => ({
  width: "100%",
  "& img": {
    padding: 0,
  },
  display: "none",
  [theme.breakpoints.tablet]: {
    display: "block",
  },
}));
export const SmallImage = styled(Grid)(({ theme }) => ({
  width: "100%",
  "& img": {
    padding: 0,
  },
  display: "block",
  [theme.breakpoints.tablet]: {
    display: "none",
  },
}));

export const StyledTitle = styled(T.H60Caps)(({ theme }) => ({
  fontSize: "40px",
  textTransform: "uppercase",

  [theme.breakpoints.mobileM]: {
    fontSize: "50px",
  },
  [theme.breakpoints.mobileL]: {
    fontSize: "60px",
  },
}));

export const GridContent = styled(Grid)(({ theme }) => ({
  width: "100%",
  textAlign: "center",
  maxWidth: "400px",
  margin: "0 auto",
  [theme.breakpoints.tablet]: {
    width: "100%",
    textAlign: "start",
    paddingLeft: 50,
  },
}));

export const TextLinks = styled(Typograpy)(({ theme }) => ({
  color: theme.colors.gray3,
  ...theme.typography.placeholder,
  fontSize: "16px",
  textAlign: "left",
  display: "inline",
  [theme.breakpoints.tablet]: {
    textAlign: "center",
  },
}));

export const GridItem = styled(Grid)(({ theme }) => ({
  width: "100%",
  margin: "0 auto",
  marginBottom: 20,
  order: 2,
  [theme.breakpoints.mobileL]: {
    width: "80%",
  },
  [theme.breakpoints.tablet]: {
    width: "45%",
    marginLeft: 0,
  },
}));

export const GridTitle = styled(GridItem)(({ theme }) => ({
  order: 1,
  width: "100%",
  marginBottom: 0,
  marginTop: 20,
  textAlign: "center",

  "& h1": {
    fontSize: "50px",
  },

  [theme.breakpoints.mobileL]: {
    "& h1": {
      fontSize: "60px",
    },
    marginTop: 40,
  },

  [theme.breakpoints.tablet]: {
    margin: "40px auto",
    textAlign: "left",
  },
}));

export const ButtonContainer = styled(GridItem)(({ theme, marginTop }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  margin: 0,
  marginBottom: 20,
  marginTop: marginTop || 0,

  [theme.breakpoints.mobileL]: {
    margin: "0 auto",
    width: "80%",
    marginTop: marginTop || 0,
  },
  [theme.breakpoints.tablet]: {
    margin: 0,
    justifyContent: "flex-start",
    width: "45%",
    marginTop: marginTop || 0,
  },
}));

export const GridItemLinks = styled(Grid)(({ theme, divider }) => ({
  width: "100%",
  margin: "20px auto",
  order: 1,
  display: "flex",
  justifyContent: "center",

  [theme.breakpoints.tablet]: {
    order: 3,
    justifyContent: "flex-start",
    borderTop: divider && `1px solid ${theme.colors.gray6}`,
    // marginLeft: 20,
  },
}));

export const InputContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.tablet]: {
    padding: 0,
    justifyContent: "flex-start",
  },
}));

export const MessageContainer = styled(Grid)(({ theme, ta }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(5),
  width: "100%",
  order: 2,
  margin: "0 auto",
  textAlign: ta || "left",
  maxWidth: "600px",
  [theme.breakpoints.tablet]: {
    margin: "0",
    marginBottom: "10px",
    maxWidth: "100%",
    textAlign: "left",
  },
}));

export const CheckboxContainer = styled(InputContainer)(({ theme }) => ({
  width: "auto",
  display: "flex",
  justifyContent: "center",
  textAlign: "left",
  padding: "0px",
  paddingTop: 0,
  margin: 0,
  "& div": {
    margin: 0,
    padding: 0,
  },
  [theme.breakpoints.tablet]: {
    justifyContent: "flex-start",
  },
}));

export const Error = styled(Typograpy)(({ theme }) => ({
  ...theme.typography.placeholder,
  color: theme.colors.pink,
}));

export const ExistsError = styled(Typograpy)(() => ({
  display: "flex",
  color: "#D91473",
  fontSize: 14,
  marginLeft: 10,
  paddingTop: 10,
}));

export const FormContainer = styled("div")(({ theme }) => ({
  marginLeft: 0,

  [theme.breakpoints.tablet]: {
    margin: "auto",
    width: "60%",
  },

  width: "100%",
}));

export const SignupForm = styled("form")(() => ({
  width: "100%",
  order: 2,
  display: "flex",
  flexWrap: "wrap",
}));

export const GridItemFullWidth = styled(GridItem)(({ theme }) => ({
  width: "100%",

  "& > div": {
    margin: "0 auto",
    width: "100%",
    [theme.breakpoints.mobileL]: {
      width: "80%",
    },
    [theme.breakpoints.tablet]: {
      width: "45%",
      margin: 0,
    },
  },
}));

export const ExtLink = styled("a")(({ theme }) => ({
  color: theme.colors.gray3,
  fontWeight: "bold",
}));

export const GridItemCaptcha = styled(GridItem)(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(3),

  "& > p": {
    margin: "0 auto",
    width: "100%",
    [theme.breakpoints.mobileL]: {
      width: "80%",
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.tablet]: {
      width: "45%",
      margin: 0,
      marginTop: theme.spacing(3),
    },
  },
}));

export const DecideCard = styled("div")(({ theme, color }) => ({
  width: "100%",
  padding: theme.spacing(5),
  backgroundColor: theme.colors[color],
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  justifyContent: "space-between",
  textDecoration: "none",
  transition: "transform 0.2s",
  "&:hover": {
    transform: "scale(1.01)",
  },
}));
export const StyledLink = styled(Link)(() => ({
  textDecoration: "none",
}));

export const DecideCardText = styled("div")(() => ({
  width: "85%",
  textAlign: "left",
}));
export const DecideCardArrow = styled("div")(() => ({
  width: "10%",
}));
