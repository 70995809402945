import React from "react";
import { Row, Col } from "../Grid";
import { AutoComplete, InputField } from "../Inputs";
import RemoveRejoin from "../RemoveRejoin";
import * as S from "./style";
import t from "../../utils/helpers/translator";

const LocationFields = ({
  index,
  addresses = [],
  setAddresses,
  isOptional,
  errors = {},
  welshVersion,
  setNewPostcodes,
  lang,
  citesAndCounties,
}) => {
  const changeBigState = (name, value) => {
    const previousArray = [...addresses];
    const fieldName = name;

    setNewPostcodes(true);

    if (previousArray.length - 1 >= index) {
      previousArray[index][fieldName] = value;
      setAddresses(previousArray);
      return previousArray;
    }
    setAddresses([...previousArray, { id: index, fieldName: value }]);
  };

  const onChange = async ({ target: { name, value } }) => {
    await changeBigState(name, value);
  };
  return (
    <>
      <Row ai="flex-end">
        <Col w={[4, 4, 4]}>
          <S.Wrapper>
            <InputField
              placeholder={`${t("typeAddress", lang)}...`}
              label={`${t("addressLine1", lang)}${
                lang === "welsh" ? ` (${t("english", lang)})` : ""
              }`}
              name="addressLine1"
              value={addresses[index] && addresses[index].addressLine1}
              setValue={onChange}
              isOptional={
                !!isOptional && isOptional.some((e) => e === "addressLine1")
              }
              lang={lang}
              error={errors.addressLine1}
            />
          </S.Wrapper>
        </Col>
        <Col w={[4, 4, 4]}>
          <S.Wrapper>
            <InputField
              placeholder={`${t("typeAddress", lang)}...`}
              label={`${t("addressLine2", lang)}${
                lang === "welsh" ? ` (${t("english", lang)})` : ""
              }`}
              name="addressLine2"
              value={addresses[index] && addresses[index].addressLine2}
              setValue={onChange}
              isOptional={
                !!isOptional && isOptional.some((e) => e === "addressLine2")
              }
              lang={lang}
              error={errors.addressLine2}
            />
          </S.Wrapper>
        </Col>
      </Row>
      <Row ai="flex-end">
        <Col w={[4, 4, 4]}>
          <S.Wrapper>
            <AutoComplete
              placeholder={`${t("typeTownCity", lang)}...`}
              label={`${t("townCity", lang)}${
                lang === "welsh" ? ` (${t("english", lang)})` : ""
              }`}
              name="city"
              limit={30}
              helper={t("maxCharacters", lang, [30])}
              value={addresses[index] && addresses[index].city}
              isOptional={!!isOptional && isOptional.some((e) => e === "city")}
              lang={lang}
              error={errors.city}
              fullWidth
              blurOnSelect
              options={(citesAndCounties?.EnglishCitiesList?.cities || [])
                .sort()
                .map((text) => ({ name: text, value: text }))}
              setValue={async ({ value }) =>
                changeBigState("city", value?.value || value)
              }
              freeSolo
            />
          </S.Wrapper>
        </Col>
        <Col w={[4, 4, 4]}>
          <S.Wrapper>
            <AutoComplete
              placeholder={`${t("typeCounty", lang)}...`}
              label={`${t("county", lang)}${
                lang === "welsh" ? ` (${t("english", lang)})` : ""
              }`}
              name="county"
              limit={30}
              helper={t("maxCharacters", lang, [30])}
              value={addresses[index] && addresses[index].county}
              isOptional
              lang={lang}
              error={errors.county}
              fullWidth
              blurOnSelect
              options={(citesAndCounties?.EnglishCitiesList?.counties || [])
                .sort()
                .map((text) => ({ name: text, value: text }))}
              setValue={async ({ value }) =>
                changeBigState("county", value?.value || value)
              }
              freeSolo
            />
          </S.Wrapper>
        </Col>
        <Col w={[4, 4, 4]}>
          <S.Wrapper>
            {/* TODO: ASK JOE */}

            <InputField
              placeholder={`${t("typePostcode", lang)}...`}
              label={t("postcode", lang)}
              name="postcode"
              value={addresses[index] && addresses[index].postcode}
              setValue={onChange}
              isOptional={
                !!isOptional && isOptional.some((e) => e === "postcode")
              }
              lang={lang}
              error={errors.postcode}
            />
          </S.Wrapper>
        </Col>
      </Row>
      {welshVersion && (
        <>
          {" "}
          <Row ai="flex-end">
            <Col w={[4, 4, 4]}>
              <S.Wrapper>
                <InputField
                  placeholder={`${t("typeAddress", lang)}...`}
                  label={`${t("addressLine1", lang)}${
                    lang === "english" ? ` (${t("welsh", lang)})` : ""
                  }`}
                  labelColor="blue"
                  name="addressLine1Welsh"
                  value={addresses[index] && addresses[index].addressLine1Welsh}
                  setValue={onChange}
                  isOptional={
                    !!isOptional &&
                    isOptional.some((e) => e === "addressLine1Welsh")
                  }
                  lang={lang}
                  error={errors.addressLine1Welsh}
                />
              </S.Wrapper>
            </Col>
            <Col w={[4, 4, 4]}>
              <S.Wrapper>
                <InputField
                  placeholder={`${t("typeAddress", lang)}...`}
                  label={`${t("addressLine2", lang)}${
                    lang === "english" ? ` (${t("welsh", lang)})` : ""
                  }`}
                  labelColor="blue"
                  name="addressLine2Welsh"
                  value={addresses[index] && addresses[index].addressLine2Welsh}
                  setValue={onChange}
                  isOptional={
                    !!isOptional &&
                    isOptional.some((e) => e === "addressLine2Welsh")
                  }
                  lang={lang}
                  error={errors.addressLine2Welsh}
                />
              </S.Wrapper>
            </Col>
          </Row>
          <Row ai="flex-end">
            <Col w={[4, 4, 4]}>
              <S.Wrapper>
                <AutoComplete
                  placeholder={`${t("typeTownCity", lang)}...`}
                  label={`${t("townCity", lang)}${
                    lang === "english" ? ` (${t("welsh", lang)})` : ""
                  }`}
                  labelColor="blue"
                  name="cityWelsh"
                  limit={30}
                  helper={t("maxCharacters", lang, [30])}
                  value={addresses[index] && addresses[index].cityWelsh}
                  isOptional={
                    !!isOptional && isOptional.some((e) => e === "cityWelsh")
                  }
                  lang={lang}
                  error={errors.cityWelsh}
                  fullWidth
                  blurOnSelect
                  options={(citesAndCounties?.WelshCitiesList?.cities || [])
                    .sort()
                    .map((text) => ({ name: text, value: text }))}
                  setValue={async ({ value }) =>
                    changeBigState("cityWelsh", value?.value || value)
                  }
                  freeSolo
                />
              </S.Wrapper>
            </Col>
            <Col w={[4, 4, 4]}>
              <S.Wrapper>
                <AutoComplete
                  placeholder={`${t("typeCounty", lang)}...`}
                  label={`${t("county", lang)}${
                    lang === "english" ? ` (${t("welsh", lang)})` : ""
                  }`}
                  labelColor="blue"
                  name="countyWelsh"
                  limit={30}
                  helper={t("maxCharacters", lang, [30])}
                  value={addresses[index] && addresses[index].countyWelsh}
                  isOptional
                  lang={lang}
                  error={errors.countyWelsh}
                  fullWidth
                  blurOnSelect
                  options={(citesAndCounties?.WelshCitiesList?.counties || [])
                    .sort()
                    .map((text) => ({ name: text, value: text }))}
                  setValue={async ({ value }) =>
                    changeBigState("countyWelsh", value?.value || value)
                  }
                  freeSolo
                />
              </S.Wrapper>
            </Col>
          </Row>
        </>
      )}
      {index > 0 && (
        <Row ai="flex-end">
          <Col w={[4, 6, 6]}>
            <RemoveRejoin
              handleClick={() =>
                setAddresses(
                  addresses
                    .filter((e) => e.id !== index)
                    .map((elm, i) => ({ ...elm, id: i })),
                )
              }
              showText
              type="Remove"
              language={lang}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default LocationFields;
