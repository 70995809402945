import React from "react";
import Button from "@material-ui/core/Button";
import { Slide, Snackbar } from "@material-ui/core";
import useStyles from "./style";
import * as S from "./style";
import * as T from "../Typograpy";

import Icon from "../Icon";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function Notification({
  message,
  duration = 100000,
  open = false,
  setOpen,
}) {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <S.Container>
      <Snackbar
        open={open}
        autoHideDuration={duration}
        TransitionComponent={Transition}
        onClose={handleClose}
        message={
          <div style={{ display: "flex" }}>
            <Button color="inherit" size="small" className={classes.button}>
              <Icon icon="tickRound" width="24px" height="24px" color="white" />
            </Button>
            <T.H20Caps color="white">{message}</T.H20Caps>
          </div>
        }
        classes={{ anchorOriginBottomCenter: classes.notification }}
        style={{ bottom: 0 }}
      />
    </S.Container>
  );
}
