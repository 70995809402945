import React from "react";

import { Row, Col } from "../../../components/Grid";

import { InputField } from "../../../components/Inputs";

const Links = ({ handleInput, setFormData, formData, errors }) => {
  const { websiteUrl, socialLinks = {} } = formData;
  const { twitter, instagram, facebook, youtube } = socialLinks || {};
  return (
    <>
      <Row mb={5}>
        <Col w={[4, 6, 4]}>
          <InputField
            placeholder="Type website url..."
            label="Website link"
            name="websiteUrl"
            value={websiteUrl}
            setValue={handleInput}
            error={errors.websiteUrl}
            justify="center"
          />
        </Col>
      </Row>
      <Row mb={5}>
        <Col w={[4, 6, 4]}>
          <InputField
            placeholder="Type Twitter url..."
            label="Twitter link"
            name="twitter"
            setValue2={(value) =>
              setFormData((state) => ({
                ...state,
                socialLinks: { ...state.socialLinks, twitter: value },
              }))
            }
            value={twitter}
            error={errors.twitter}
            justify="center"
            isOptional
          />
        </Col>
        <Col w={[4, 6, 4]}>
          <InputField
            placeholder="Type Instagram url..."
            label="Instagram link"
            name="instagram"
            setValue2={(value) =>
              setFormData((state) => ({
                ...state,
                socialLinks: { ...state.socialLinks, instagram: value },
              }))
            }
            value={instagram}
            error={errors.instagram}
            justify="center"
            isOptional
          />
        </Col>
      </Row>
      <Row mb={8}>
        <Col w={[4, 6, 4]}>
          <InputField
            placeholder="Type Facebook url..."
            label="Facebook link"
            name="facebook"
            setValue2={(value) =>
              setFormData((state) => ({
                ...state,
                socialLinks: { ...state.socialLinks, facebook: value },
              }))
            }
            value={facebook}
            error={errors.facebook}
            justify="center"
            isOptional
          />
        </Col>
        <Col w={[4, 6, 4]}>
          <InputField
            placeholder="Type YouTube url..."
            label="YouTube link"
            name="youtube"
            value={youtube}
            setValue2={(value) =>
              setFormData((state) => ({
                ...state,
                socialLinks: { ...state.socialLinks, youtube: value },
              }))
            }
            error={errors.youtube}
            justify="center"
            isOptional
          />
        </Col>
      </Row>
    </>
  );
};

export default Links;
