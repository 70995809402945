import { styled } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import headerImg from "../../../../../assets/graphics/headerGraphic.svg";
import { Col } from "../../../../../components/Grid";

export const Wrapper = styled("section")(({ theme }) => ({
  marginBottom: "40px",

  [theme.breakpoints.laptop]: {
    marginTop: "-30px",
  },
}));

export const ImageWrapper = styled("div")(({ theme }) => ({
  background: `url("${headerImg}") no-repeat top`,
  width: "100%",
  height: "250px",
  backgroundSize: "cover",
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),

  [theme.breakpoints.laptop]: {
    height: "350px",
    marginBottom: theme.spacing(6),
  },
}));

export const Header = styled("header")(() => ({}));

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.colors.blue,
  fontWeight: "bold",
  width: "auto",
  display: "in-line",
}));

export const LogInWrapper = styled("span")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  paddingTop: theme.spacing(3),
  textAlign: "center",

  [theme.breakpoints.mobileL]: {
    textAlign: "left",
  },

  [theme.breakpoints.tablet]: {
    justifyContent: "flex-start",
    textAlign: "left",
  },
}));

export const OptionWrapper = styled("section")(() => ({}));

export const CountersWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  marginBottom: theme.spacing(6),

  [theme.breakpoints.tablet]: {
    flexWrap: "nowrap",
  },
}));

export const SuccessWrapper = styled("section")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  marginBottom: theme.spacing(8),

  [theme.breakpoints.mobileL]: {
    textAlign: "left",

    "& button": {
      marginLeft: "-10px",
    },
  },
}));

export const ListWarapper = styled("div")(() => ({
  "& > div:last-child": {
    paddingBottom: 0,
  },
}));

export const SideImageWrapper = styled("div")({
  minWidth: 220,
  height: 210,
});

export const SuccessPageWrapper = styled("div")(({ theme }) => ({
  marginTop: 0,
  [theme.breakpoints.tablet]: {
    marginTop: -20,
  },
}));

export const CodesWrapper = styled(Col)(({ theme }) => ({
  background: theme.colors.pinkLight,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
export const CodesContainer = styled("div")(() => ({
  width: 300,
  minHeight: 90,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));
