import React from "react";

const Calendar = ({ width, height, color, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 21"
    width={width}
    height={height}
    {...props}
  >
    <path
      d="M16.25 3.614H3.75a1.884 1.884 0 00-1.875 1.892V16.86c0 1.045.84 1.892 1.875 1.892h12.5a1.884 1.884 0 001.875-1.892V5.506c0-1.045-.84-1.892-1.875-1.892z"
      stroke={color || "currentColor"}
      strokeWidth="1.14"
      strokeLinejoin="round"
    />
    <path
      d="M15.54 3.614H4.46c-1.425 0-2.585 1.189-2.585 2.65V8.66H2.5c0-.63.625-1.261 1.25-1.261h12.5c.625 0 1.25.63 1.25 1.261h.625V6.263c0-1.46-1.16-2.649-2.585-2.649zM11.563 10.552a.942.942 0 00.937-.946.942.942 0 00-.938-.946.942.942 0 00-.937.946c0 .523.42.946.938.946zM14.688 10.552a.942.942 0 00.937-.946.942.942 0 00-.938-.946.942.942 0 00-.937.946c0 .523.42.946.938.946zM11.563 13.706a.942.942 0 00.937-.946.942.942 0 00-.938-.946.942.942 0 00-.937.946c0 .522.42.946.938.946zM14.688 13.706a.942.942 0 00.937-.946.942.942 0 00-.938-.946.942.942 0 00-.937.946c0 .522.42.946.938.946zM5.313 13.706a.942.942 0 00.937-.946.942.942 0 00-.938-.946.942.942 0 00-.937.946c0 .522.42.946.938.946zM8.438 13.706a.942.942 0 00.937-.946.942.942 0 00-.938-.946.942.942 0 00-.937.946c0 .522.42.946.938.946zM5.313 16.86a.942.942 0 00.937-.947.942.942 0 00-.938-.946.942.942 0 00-.937.946c0 .523.42.946.938.946zM8.438 16.86a.942.942 0 00.937-.947.942.942 0 00-.938-.946.942.942 0 00-.937.946c0 .523.42.946.938.946zM11.563 16.86a.942.942 0 00.937-.947.942.942 0 00-.938-.946.942.942 0 00-.937.946c0 .523.42.946.938.946z"
      fill={color || "currentColor"}
    />
    <path
      d="M5 2.353v1.261M15 2.353v1.261"
      stroke={color || "currentColor"}
      strokeWidth="1.14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Calendar;
