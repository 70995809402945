import React from "react";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import SpendVenue from "./SpendVenue";
import EarnGroup from "./EarnGroup";
import GetInTouch from "./EarnGroup/GetInTouch";

const Profile = ({ activityType }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  switch (activityType) {
    case "earn":
      return <EarnGroup isTablet={isTablet} isMobile={isMobile} />;
    case "earnGetInTouch":
      return <GetInTouch isTablet={isTablet} isMobile={isMobile} />;
    default:
      return <SpendVenue isTablet={isTablet} isMobile={isMobile} />;
  }
};
export default Profile;
