import styled from "@material-ui/core/styles/styled";

export const Container = styled("div")(() => ({
  width: "100%",
}));

export const Head = styled("header")({
  display: "flex",
  justifyContent: "space-between",
});

export const TableContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  [theme.breakpoints.mobileXL]: {
    transform: "translateY(-130px)",
  },

  [theme.breakpoints.laptopL]: {
    transform: "translateY(0px)",
  },
}));

export const BtnContainer = styled("div")(({ theme }) => ({
  alignSelf: "flex-end",
  zIndex: 3,
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  width: "60%",
  transform: "translateY(200px)",
  [theme.breakpoints.mobileXL]: {
    flexDirection: "column",
  },

  [theme.breakpoints.laptopL]: {
    flexDirection: "row",
  },
}));

export const ImageWrapper = styled("div")({
  minWidth: 250,
  height: 210,
  transform: "translate(30px, -30px)",
});

export const CapitalizedSpan = styled("span")({
  textTransform: "capitalize",
});

export const CSVExport = styled("a")({
  textDecoration: "none",
});

export const ModalContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
});

export const HeadContent = styled("div")({
  display: "flex",
  flexDirection: "column",
});
export const StatsContent = styled("div")({
  display: "flex",
});
export const SubStatsContent = styled("div")({
  marginTop: 50,
  marginRight: 15,
});
export const FilterContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
});
