import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";

import * as S from "./style";
import * as T from "../../../../components/Typograpy";
import GoBack from "../../../../components/GoBack";

import Button from "../../../../components/Button";
import Image from "../../../../components/Image";

import { TEMPO, GENERAL } from "../../../../constants/navRoutes";
import { getSpendPartnerById as getSpendPartnerByIdAction } from "./action";
import { getSpendVenues as getSpendVenuesAction } from "../SpendVenues/actions";
import Loading from "../../../../components/Loading";
import Table from "../../../../components/Table";
import { Row, Col } from "../../../../components/Grid";
// import RemoveLink from "../../../../components/RemoveRejoin";

const SingleSpendPartner = ({
  spendPartner,
  loading,
  spendVenuesData,
  getPartnerById,
  getSpendVenues,
}) => {
  const { name, email, phoneNumber, firstName, lastName } = spendPartner;
  const history = useHistory();
  const { id: spendPartnerUserId } = useParams();

  useEffect(() => {
    if (spendPartnerUserId && !!Number(spendPartnerUserId)) {
      getSpendVenues({ partnerId: spendPartnerUserId });
      getPartnerById(spendPartnerUserId);
    } else {
      history.push(GENERAL.NOT_FOUND);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spendPartnerUserId]);

  const venueColumns = [
    { title: "SPEND venue", field: "name" },
    {
      title: "EMAIL",
      render: ({ email: vEmail }) => vEmail || "N/A",
    },
    {
      title: "profile status",
      render: ({ isPublic }) => (isPublic ? "live" : "draft"),
    },
  ];

  const clickRow = (event, rowData) => {
    history.push(TEMPO.SPEND_VENUES_SINGLE.replace(":id", rowData.id));
  };

  const clickCreateVenue = () =>
    history.push(
      TEMPO.CREATE_VENUE.replace(":spendPartnerId", spendPartnerUserId),
    );

  // const handleDelete = () => {
  //   // eslint-disable-next-line no-console
  //   console.log("delete account clicked !!");
  // };

  if (loading) return <Loading fullPage navbar />;
  return (
    <>
      <S.Head>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <S.Back>
            <GoBack showText />
          </S.Back>
          <T.H40Caps color="primary" style={{ margin: 0 }}>
            {name}
          </T.H40Caps>
        </div>
        <S.ImageWrapper>
          <Image image="gallery" alt="gallery" height={210} width={220} />
        </S.ImageWrapper>
      </S.Head>
      <S.CardContainer>
        <S.StyledCard width={450}>
          <T.H20 color="midnight" style={{ marginBottom: 30 }}>
            Partner Account Details
          </T.H20>

          <Row inner mt="3" mb="3">
            <Col w={[2, 3, 5]}>
              <T.H18 color="gray2">Name</T.H18>
            </Col>{" "}
            <Col w={[2, 8, 7]}>
              <T.H16 color="gray2">
                {firstName} {lastName}
              </T.H16>
            </Col>
          </Row>
          <Row inner mt="3" mb="3">
            <Col w={[2, 3, 5]}>
              <T.H18 color="gray2">Email</T.H18>
            </Col>{" "}
            <Col w={[2, 8, 7]}>
              <a href={`mailto:${email}`} alt="email">
                <T.H16
                  color="cyan"
                  style={{
                    textDecoration: "underline",
                    wordWrap: "break-word !important",
                    overflowWrap: "break-word",
                    "-ms-word-break": "break-all",
                    wordBreak: "break-word",
                  }}
                >
                  {email || "N/A"}
                </T.H16>
              </a>
            </Col>
          </Row>
          <Row inner mt="3" mb="3">
            <Col w={[2, 3, 5]}>
              <T.H18 color="gray2">Phone</T.H18>
            </Col>{" "}
            <Col w={[2, 8, 7]}>
              <T.H16 color="gray2">{phoneNumber || "N/A"}</T.H16>
            </Col>
          </Row>
          <Row inner mt="5" mb="3">
            <Col w={[3, 7, 7]}>
              <Button
                outline
                onClick={() => {
                  history.push(
                    TEMPO.EDIT_SPEND_PARTNER_ACCOUNT.replace(
                      ":id",
                      spendPartnerUserId,
                    ),
                  );
                }}
              >
                Edit account
              </Button>
            </Col>
          </Row>
        </S.StyledCard>
      </S.CardContainer>
      {/* future feature */}
      {/* <Row mt="3">
        <S.RemoveWrapper w={[4, 6, 12]}>
          <RemoveLink
            type="Remove"
            text="Delete partner"
            showText
            handleClick={handleDelete}
          />
        </S.RemoveWrapper>
      </Row> */}
      <Row mt="6">
        <Col w={[4, 6, 6]}>
          <T.H24Caps color="primary" style={{ marginBottom: 10 }}>
            venues
          </T.H24Caps>
          <T.Body16R color="gray3" style={{ marginLeft: 0, marginBottom: 30 }}>
            Please note, the venue profile must be made live for any activities
            to be made visible on the website.
          </T.Body16R>
        </Col>
      </Row>
      <Row style={{ alignItems: "center" }}>
        <Col w={[3, 3, 3]}>
          <S.Venues>
            <S.Venue>
              <S.NumberOfVenues color="cyan">
                {(spendVenuesData.data &&
                  spendVenuesData.data.filter(({ isPublic }) => isPublic)
                    .length) ||
                  0}
              </S.NumberOfVenues>
              <S.VenueType>live venues</S.VenueType>
            </S.Venue>

            <S.Venue>
              <S.NumberOfVenues color="blue">
                {(spendVenuesData.data &&
                  spendVenuesData.data.filter(({ isPublic }) => !isPublic)
                    .length) ||
                  0}
              </S.NumberOfVenues>
              <S.VenueType>draft venues</S.VenueType>
            </S.Venue>
          </S.Venues>
        </Col>
        <Col w={[3, 3, 3]}>
          <Button
            style={{ marginLeft: 40, height: 50 }}
            onClick={clickCreateVenue}
          >
            Add Venue
          </Button>
        </Col>
      </Row>

      <Table
        color="blue"
        data={spendVenuesData.data}
        isLoading={spendVenuesData.loading}
        columns={venueColumns}
        onRowClick={clickRow}
        hideSearch
      />
    </>
  );
};
const mapStateToProps = (state) => ({
  spendPartner: state.staffSingleSpendPartner.spendPartner,
  loading: state.staffSingleSpendPartner.loading,
  spendVenuesData: state.staffSpendVenues,
});

const mapActionsToProps = {
  getPartnerById: getSpendPartnerByIdAction,
  getSpendVenues: getSpendVenuesAction,
};

export default connect(mapStateToProps, mapActionsToProps)(SingleSpendPartner);
