import {
  GET_PROGRAMME_INVITE_DETAILS_LOADING,
  GET_PROGRAMME_INVITE_DETAILS_SUCCESS,
  GET_PROGRAMME_INVITE_DETAILS_FAIL,
} from "../../../../redux/types";

const initState = {
  id: "",
  balance: 0,
  inviteToken: "",
};

const programmeInviteEarnGroup = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PROGRAMME_INVITE_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_PROGRAMME_INVITE_DETAILS_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case GET_PROGRAMME_INVITE_DETAILS_FAIL:
      return {
        ...state,

        ...payload,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default programmeInviteEarnGroup;
