import { styled } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

export const Wrapper = styled("section")(() => ({}));

export const FocusContainer = styled("div")(() => ({
  "& > div > div > div": {
    maxWidth: "100%",
  },
}));

export const FiledWrapper = styled("div")(() => ({
  marginBottom: 30,
}));

export const SubmitWrapper = styled("div")(() => ({
  marginTop: 40,
}));

export const ImageContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  right: 0,
  top: 0,
  display: "none",
  [theme.breakpoints.tablet]: {
    display: "block",
  },
}));

export const Error = styled(Typography)(({ theme }) => ({
  width: "100%",
  color: theme.colors.pink,
  fontSize: "0.875rem",
  textAlign: "center",
  marginBottom: theme.spacing(1),
}));

export const InfoWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  boxShadow: theme.customShadows.card,
  "& p": {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
  },
  // [theme.breakpoints.tablet]: {
  //   width: "300px",
  // },
}));

export const WelshCheckWrapper = styled("div")(() => ({
  width: "inherit",
  color: "green",
  "& div": {
    maxWidth: "inherit",
    color: "red",
  },
  "& div span": {
    paddingLeft: 0,
  },
  "& div span svg": {
    width: 20,
  },
  "& div label": {
    flex: 1,
  },
}));

export const Email = styled("a")(({ theme }) => ({
  textDecoration: "none",
  color: theme.colors.darkPrimary,
}));
