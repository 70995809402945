import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import * as S from "./style";
import * as T from "../../../components/Typograpy";
import EarnActivity from "../../../components/Cards/Activity/Earn";
import { InputField, Datepicker } from "../../../components/Inputs";
import { earnActivityCategories } from "../../../constants";
import Button from "../../../components/Button";
import TCBalance from "../../../components/TCBalance";
import t from "../../../utils/helpers/translator";

import { Row, Col } from "../../../components/Grid";
import Icon from "../../../components/Icon";

import { getRecentEarnGroupTransactions } from "./actions";

import { earnTypesMapWelsh } from "../../../constants/data/earnActivityTypes";

const Step1 = ({
  selectedActivity,
  selectActivity,
  handleDates,
  dates,
  changePage,
  setCatgory,
  setName,
  balance,
  activityName,
  activityCategoryOther,
  activityCategory,
  showSomethingElse,
  language,
  fetchRecentTransactions,
  recentTransactions,
  transactionsLoading,
  presetMembers,
  usingPrevious,
}) => {
  const [showTransactions, setShowTransactions] = useState(false);
  const [showLimit, setShowLimit] = useState(3);
  const bodyText = t("repeatTransactionsHelper", language).split(" | ");

  const handleRecentTransaction = (transaction, index) => {
    const existingCat = earnActivityCategories.find(
      (cat) => cat.categoryName === transaction.activityCategoryName,
    );

    if (existingCat) {
      selectActivity(existingCat.categoryName, existingCat.category);
    } else {
      setCatgory(
        { target: { value: transaction.activityCategoryName } },
        "recentTransactionOther",
      );
    }

    setName({ target: { value: transaction.activityName || "" } });
    presetMembers(transaction.members, index);
  };

  useEffect(() => {
    if (showTransactions) {
      fetchRecentTransactions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTransactions]);

  return (
    <>
      <Row inner jc="space-between">
        <Col w={[4, 12, 8]}>
          <S.Heading>{t("giveOutTempoTC", language)}</S.Heading>
        </Col>
        <Col w={[0, 0, 4]}>
          <TCBalance color="pink" credits={balance} />
        </Col>
      </Row>
      <Row inner mb="50px" mt="20px">
        <Col w={[4, 8, 8]}>
          <T.H20 color="midnight" mb="10">
            {t("repeatTransactions", language)}
          </T.H20>
          {!showTransactions && (
            <T.Body16R color="gray3">
              {bodyText[0]}{" "}
              <S.LinkBtn onClick={() => setShowTransactions(true)}>
                {bodyText[1]}
              </S.LinkBtn>{" "}
              {bodyText[2]}
            </T.Body16R>
          )}
          {showTransactions && (
            <>
              {recentTransactions?.length ? (
                recentTransactions.slice(0, showLimit).map((tran, index) => (
                  <Row mb="20px" mt="30px">
                    <Col w={[1, 4, 4]}>
                      <T.Body14R>
                        {tran.day}/{tran.month}/{tran.year}
                      </T.Body14R>
                    </Col>
                    <Col w={[2, 6, 6]}>
                      <T.Body14R>{tran.activityName || "N/A"}</T.Body14R>
                      <T.Body14B color="blue">
                        {language === "welsh"
                          ? earnTypesMapWelsh[tran.activityCategoryName] ||
                            tran.activityCategoryName
                          : tran.activityCategoryName}
                      </T.Body14B>
                    </Col>

                    <Col w={[1, 2, 2]}>
                      {usingPrevious !== index ? (
                        <Button
                          size="xs"
                          onClick={() => handleRecentTransaction(tran, index)}
                          w={language === "welsh" ? "100px" : "70px"}
                        >
                          {t("use", language)}
                        </Button>
                      ) : (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Icon
                            icon="tickRound"
                            color="white"
                            width="25px"
                            height="25px"
                            margin="0 auto"
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                ))
              ) : (
                <T.Body16R color="gray3">
                  {transactionsLoading ? (
                    <>{t("loading", language)}...</>
                  ) : (
                    "No previous transactions found"
                  )}
                </T.Body16R>
              )}
              {recentTransactions?.length > showLimit && (
                <Row>
                  <Col w={[4, 8, 8]}>
                    <S.LinkBtn onClick={() => setShowLimit(showLimit + 3)}>
                      <T.Body14S color="midnight">
                        {t("viewMore", language)}
                      </T.Body14S>
                    </S.LinkBtn>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Col>
      </Row>
      <S.Question>{t("chooseCategory", language)}</S.Question>
      <S.ActivityWrapper>
        {earnActivityCategories.map((option, index) => (
          <>
            <EarnActivity
              category={option.category}
              categoryName={
                language === "welsh"
                  ? option.categoryNameWelsh
                  : option.categoryName
              }
              subtitle={
                language === "welsh" ? option.subtitleWelsh : option.subtitle
              }
              index={index}
              m="0 10px 10px 0"
              selected={selectedActivity(option.category)}
              handleClick={() => {
                selectActivity(option.categoryName, option.category);
              }}
            />
          </>
        ))}
      </S.ActivityWrapper>
      <EarnActivity
        category="somethingElse"
        categoryName={t("somethingElse", language)}
        subtitle={t("onlyUse", language)}
        index="9"
        m="0 0 10px 0"
        selected={showSomethingElse}
        handleClick={() => {
          setCatgory(null, "somethingElse");
        }}
      />
      {showSomethingElse && (
        <>
          <S.Question style={{ marginTop: "20px" }}>
            {t("typeOfActivity", language)}
          </S.Question>
          <InputField
            type="text"
            name="other"
            placeholder={`${t("typeHere", language)}...`}
            value={activityCategoryOther}
            setValue={setCatgory}
            size="normal"
          />
        </>
      )}
      <S.Question mb={0} style={{ marginTop: "40px", lineHeight: "25px" }}>
        {t("addActivityName", language)}?{" "}
      </S.Question>
      <S.Optional>({t("optional", language)})</S.Optional>
      <S.QuestionWrapper>
        <InputField
          type="text"
          name="categoryName"
          placeholder={`${t("egCoffee", language)}?`}
          value={activityName}
          setValue={setName}
          mr={1}
          mb={1}
          size="normal"
        />
        <Datepicker value={dates} setValue={handleDates} size="normal" mb={1} />
      </S.QuestionWrapper>
      <div style={{ maxWidth: 432 }}>
        {!activityCategory && !activityCategoryOther ? (
          <Button disabled>{t("nowChoose", language)}</Button>
        ) : (
          <Button
            primary
            onClick={() => {
              window.scrollTo(0, 0);
              changePage();
            }}
          >
            {t("nowChoose", language)}
          </Button>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  recentTransactions: state.giveCredits.recentTransactions,
  transactionsLoading: state.giveCredits.transactionsLoading,
});

const mapActionToProps = {
  fetchRecentTransactions: getRecentEarnGroupTransactions,
};

export default connect(mapStateToProps, mapActionToProps)(Step1);
