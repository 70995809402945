/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import Checkbox from "@material-ui/core/Checkbox";
import { useTheme } from "@material-ui/core/styles";
import Icon from "../../Icon";

const SelectComponent = (props) => {
  const {
    error,
    size,
    fixedHeight = true,
    closeMenuOnSelect = true,
    blurInputOnSelect = true,
    maxWidth,
    height,
    isClearable = false,
    disabled,
    // enable keyboard in mobile
    isSearchable = true,
    createLabel,
  } = props;
  const theme = useTheme();

  //  custom styling
  const selectStylesCustom = {
    container: (styles) => ({
      ...styles,
      maxWidth: maxWidth || "100%",
      fontFamily: "Poppins",
      fontWeight: "normal",
      fontSize: "0.875rem",
      width: size === "small" ? "200px" : "100%",
      minHeight: "48px",
    }),
    control: (styles, { isFocused }) => ({
      ...styles,
      border: error ? theme.borders.textInputError : theme.borders.textInput,
      boxShadow: isFocused ? 0 : 0,
      "&:hover": {
        border: isFocused && theme.borders.textInput,
      },
      borderRadius: "10px",
      boxSizing: "border-box",
      height,
    }),
    option: (styles, { isDisabled }) => ({
      ...styles,
      fontFamily: "Poppins",
      fontWeight: "normal",
      fontSize: "0.875rem",
      cursor: isDisabled ? "not-allowed" : "pointer",
      color: theme.colors.gray2,
      backgroundColor: theme.colors.white,

      "&:hover": {
        color: theme.colors.midnight,
      },
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: theme.colors.gray1,
      transition: "all .2s ease",
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
      cursor: "pointer",
      padding: "12px",
    }),

    multiValueLabel: (styles) => ({
      ...styles,
      backgroundColor: theme.colors.white,
      cursor: "pointer",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      backgroundColor: theme.colors.white,
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: "0 15px",
      fontFamily: "Poppins",
      fontSize: "14px",
      maxWidth: "calc(100% - 22px)",
      minHeight: "50px",
      maxHeight: fixedHeight ? "70px" : "auto",
      overflowY: fixedHeight ? "auto" : "none",
    }),
    clearIndicator: (styles) => ({
      ...styles,
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: "none",
    }),
    placeholder: (styles) => ({
      ...styles,
      ...theme.typography.placeholder,
      margin: "0",
    }),
  };

  // custom option with checkboxes
  const Option = (selectProps) =>
    selectProps.isMulti ? (
      <div>
        <components.Option {...selectProps}>
          <Checkbox
            checked={selectProps.isSelected}
            onChange={() => null}
            disabled={
              props.max &&
              !selectProps.isSelected &&
              selectProps.getValue().length >= props.max
            }
            icon={<Icon icon="checkboxUnchecked" width="20px" />}
            checkedIcon={<Icon icon="checkboxChecked" width="20px" />}
          />
          <label
            style={{
              cursor:
                props.max &&
                !selectProps.isSelected &&
                selectProps.getValue().length >= props.max
                  ? "not-allowed"
                  : "pointer",
            }}
          >
            {selectProps.label}
          </label>
        </components.Option>
      </div>
    ) : (
      <div>
        <components.Option {...selectProps}>
          <label style={{ cursor: "pointer" }}>{selectProps.label}</label>
        </components.Option>
      </div>
    );

  if (props.allowSelectAll) {
    return (
      <Select
        {...props}
        menuPortalTarget={document.body}
        options={[props.allOption, ...props.options]}
        styles={selectStylesCustom}
        components={{ Option }}
        isSearchable={isSearchable}
        isClearable={isClearable}
        onChange={(selected) => {
          if (
            selected !== null &&
            selected.length > 0 &&
            selected[selected.length - 1].value === props.allOption.value
          ) {
            if (props.value && props.value.length === props.options.length) {
              return props.onChange([]);
            }

            return props.onChange(props.options);
          }
          return props.onChange(selected);
        }}
        isDisabled={disabled}
      />
    );
  }

  if (props.canCreate) {
    return (
      <CreatableSelect
        {...props}
        menuPortalTarget={document.body}
        closeMenuOnSelect={closeMenuOnSelect}
        blurInputOnSelect={blurInputOnSelect}
        components={{ Option }}
        options={[...props.options]}
        styles={selectStylesCustom}
        isSearchable={isSearchable}
        isClearable={isClearable}
        menuPlacement="auto"
        menuPosition="absolute"
        isMulti={props.isMulti}
        onChange={(selected) => props.onChange(selected)}
        isDisabled={disabled}
        formatCreateLabel={(value) => `${createLabel || "Create"} " ${value}"`}
        // onInputChange={}
      />
    );
  }
  return (
    <Select
      {...props}
      menuPortalTarget={document.body}
      closeMenuOnSelect={closeMenuOnSelect}
      blurInputOnSelect={blurInputOnSelect}
      components={{ Option }}
      options={[...props.options]}
      styles={selectStylesCustom}
      isSearchable={isSearchable}
      isClearable={isClearable}
      menuPlacement="auto"
      menuPosition="absolute"
      isMulti={props.isMulti}
      onChange={(selected) => props.onChange(selected)}
      isDisabled={disabled}
    />
  );
};

SelectComponent.propTypes = {
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

// adds select all option to props
SelectComponent.defaultProps = {
  allOption: {
    label: "Select all",
    value: "*",
  },
};

export default SelectComponent;
