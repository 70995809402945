import React from "react";
import { connect } from "react-redux";

import * as T from "../Typograpy";
import * as S from "./style";

import translate from "../../utils/helpers/translator";

const ReCaptcha = ({ lang }) => {
  const text = translate("recaptcha", lang).split(" | ");

  return (
    <T.Body12R color="gray3" mb={30}>
      {text[0]}{" "}
      <S.Link
        color="blue"
        href="https://policies.google.com/privacy"
        target="_blank"
        rel="noopener"
      >
        {text[1]}
      </S.Link>{" "}
      {text[2]}{" "}
      <S.Link
        color="blue"
        href="https://policies.google.com/terms"
        target="_blank"
        rel="noopener"
      >
        {text[3]}
      </S.Link>{" "}
      {text[4]}.
    </T.Body12R>
  );
};

const mapStateToProps = (state) => ({
  lang: state.auth.language,
});

export default connect(mapStateToProps)(ReCaptcha);
