import Icon from "../../../../components/Icon";
import * as T from "../../../../components/Typograpy";
import * as S from "./style";
import { useHistory } from "react-router-dom";
import { TEMPO } from "../../../../constants/navRoutes";

const EditAccountButton = ({ id }) => {
  const history = useHistory();

  return (
    <S.EditAccountButtonWrapper
      onClick={() => history.push(TEMPO.EDIT_MEMBER_ACCOUNT.replace(":id", id))}
    >
      <Icon
        icon="edit"
        width="20"
        height="20"
        margin="0 0 0 12px"
        color="cyan"
      />
      <T.Body14S color="gray3" ml="5">
        Edit Account
      </T.Body14S>
    </S.EditAccountButtonWrapper>
  );
};

export default EditAccountButton;
