import React from "react";

import * as S from "./style";
import * as T from "../Typograpy";
import translate from "../../utils/helpers/translator";

const TimeCreditCost = ({ cost, m, lang }) => (
  <S.Wrapper m={m}>
    <T.H22 color="midnight" m="0">
      {translate("thisWillCost", lang)}
    </T.H22>
    <S.Number>
      <T.H60 color="midnight" m="0" style={{ lineHeight: "60px" }}>
        {cost}
      </T.H60>
    </S.Number>
    <T.H20Caps
      color="pink"
      m="0"
      style={{ fontWeight: "900", lineHeight: "20px" }}
    >
      {translate("timeCreditsNumInFront", lang)}
    </T.H20Caps>
  </S.Wrapper>
);

export default TimeCreditCost;
