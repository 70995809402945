import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { getEarnGroupByGroupId } from "../actions";

import { GENERAL, TEMPO, EARN_GROUP } from "../../../../../constants/navRoutes";
import userRoles, { tempoRoles } from "../../../../../constants/roles";

import * as S from "../../style";

import { Row, Col } from "../../../../../components/Grid";
import { Regular } from "../../../../../components/Button";
import GoBack from "../../../../../components/GoBack";
import { Body16R } from "../../../../../components/Typograpy";
import Loading from "../../../../../components/Loading";

import { LANGUAGE_ERROR } from "../../../../../validation/err-msgs";

import Content from "./Content";

import t from "../../../../../utils/helpers/translator";

const EarnGroupProfile = ({
  getEarnGroupByGroupIdAction,
  earnGroupProfile,
  earnGroupProfileLoading,
  isTablet,
  userRole,
  earnGroupId,
  supervisorId,
  language,
}) => {
  const [profileData, setProfileData] = useState({});
  const history = useHistory();
  const { id } = useParams();
  useEffect(() => {
    getEarnGroupByGroupIdAction({ id, isPublic: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    cover,
    getInTouchDescription,
    welshFields: {
      addresses: addressesWelsh,
      activitiesRoles: activitiesRolesWelsh,
      description: descriptionWelsh,
      getInTouchDescription: getInTouchDescriptionWelsh,
      name: nameWelsh,
    } = {},
  } = earnGroupProfile;

  const earnGroupProfileWelsh = {
    ...earnGroupProfile,
    name: nameWelsh,
    description: descriptionWelsh,
    getInTouchDescription: getInTouchDescriptionWelsh,
    addresses: addressesWelsh,
    roles: activitiesRolesWelsh,
  };

  const welshVersionExists = earnGroupProfile && earnGroupProfile.welshFields;
  const flowLanguage =
    welshVersionExists && language === "welsh" ? "welsh" : "english";

  useEffect(() => {
    if (welshVersionExists && language === "welsh") {
      setProfileData({ ...earnGroupProfileWelsh, languageError: null });
    } else if (!welshVersionExists && language === "welsh") {
      setProfileData({
        ...earnGroupProfile,
        languageError: t(LANGUAGE_ERROR, language),
      });
    } else {
      setProfileData({ ...earnGroupProfile, languageError: null });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, earnGroupProfile.id]);

  const allowedRoles = Object.values(tempoRoles);

  if (earnGroupProfileLoading) return <Loading />;

  return (
    <S.Wrapper>
      {isTablet && (
        <Row>
          <Col w={[2, 6, 8]}>
            <GoBack
              showText
              onClick={
                history.length === 1 ? () => history.push(GENERAL.EARN) : null
              }
              lang={flowLanguage}
            />
          </Col>
        </Row>
      )}

      <S.ImageWrapper img={cover && cover.imgURL} />
      {!isTablet && (
        <Row>
          <Col w={[2, 9, 8]}>
            <GoBack
              showText
              p="0 0 50px 0px"
              onClick={
                history.length === 1 ? () => history.push(GENERAL.EARN) : null
              }
              lang={flowLanguage}
            />
          </Col>
        </Row>
      )}
      {allowedRoles.includes(userRole) && (
        <S.EditButtonWrapper>
          <Regular
            bgColor="pink"
            customWidth="200px"
            onClick={() => {
              history.push(TEMPO.EDIT_EARN_GROUP_PROFILE.replace(":id", id));
            }}
          >
            Edit Profile
          </Regular>
        </S.EditButtonWrapper>
      )}

      {((userRoles.EARN_GROUP === userRole &&
        Number(earnGroupId) === Number(id)) ||
        (userRoles.EARN_GROUP_ADMIN === userRole &&
          Number(supervisorId) === Number(id))) && (
        <S.EditButtonWrapper>
          <Regular
            bgColor="pink"
            customWidth="200px"
            onClick={() => {
              history.push(EARN_GROUP.PROFILE.replace(":id", id));
            }}
          >
            Edit Profile
          </Regular>
        </S.EditButtonWrapper>
      )}
      {profileData && profileData.languageError && (
        <Body16R color="pink" style={{ marginBottom: 40, paddingLeft: 10 }}>
          {t(profileData.languageError, language)}
        </Body16R>
      )}
      <Content
        isTablet={isTablet}
        earnGroupProfile={profileData && profileData}
        id={id}
        lang={flowLanguage}
        getInTouchDescription={getInTouchDescription}
        welshVersion={welshVersionExists && earnGroupProfile.welshFields}
      />
    </S.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  earnGroupProfile: state.venueGroupProfile.earnGroupProfileData,
  earnGroupProfileLoading: state.venueGroupProfile.getEarnGroupLoading,
  userRole: state.auth.role,
  earnGroupId: state.auth.id,
  supervisorId: state.auth.supervisorId,
  language: state.auth.decideLanguage(state.auth),
});

const mapActionToProps = {
  getEarnGroupByGroupIdAction: getEarnGroupByGroupId,
};

export default connect(mapStateToProps, mapActionToProps)(EarnGroupProfile);
