import React from "react";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";

import Icon from "../../Icon";
import * as T from "../../Typograpy";
import useStyles from "../style";

const CheckboxComponent = ({
  classes: _classes = {},
  color = "gray2",
  ...props
}) => {
  const {
    label,
    value = false,
    // TODO replace setValue with setValue 2
    setValue2,
    setValue,
    error,
    size = "normal",
    checkboxSize,
    name,
    helper,
    disabled,
  } = props;

  const onChange = (e) => {
    if (setValue2 instanceof Function) setValue2(e.target.checked);
    if (setValue instanceof Function) setValue(e);
  };

  const classes = useStyles(props);
  const renderLabel = {
    small: (
      <T.Body12R color={color} style={{ width: "100%" }}>
        {label}
      </T.Body12R>
    ),
    lightNormal: (
      <T.Body14R color={color} style={{ width: "100%" }}>
        {label}
      </T.Body14R>
    ),
    normal: (
      <T.Body16B5 color={color} style={{ width: "100%" }}>
        {label}
      </T.Body16B5>
    ),
    medium: (
      <T.Body16B color={color} style={{ width: "100%" }}>
        {label}
      </T.Body16B>
    ),
    large: (
      <T.Body16B color={color} style={{ width: "100%" }}>
        {label}
      </T.Body16B>
    ),
  };

  const iconSizes = {
    small: "20px",
    lightNormal: "22px",
    normal: "20px",
    medium: "25px",
    large: "20px",
  };

  return (
    <Grid
      container
      direction="row"
      className={`${_classes.root} ${classes.root}`}
      style={{
        margin: "0",
        maxWidth: "100% !important",
      }}
    >
      <Checkbox
        {...props}
        checked={value}
        size={size}
        name={name || "checkbox"}
        onChange={onChange}
        id={label}
        className={`${_classes.checkbox} ${classes.checkbox}`}
        icon={
          <Icon
            icon="checkboxUnchecked"
            color={error && "pink"}
            width={iconSizes[checkboxSize || size]}
          />
        }
        checkedIcon={<Icon icon="checkboxChecked" width="15px" />}
        disabled={disabled}
      />
      <label
        htmlFor={label}
        className={`${classes.checkboxLabelDiv}`}
        disabled={disabled}
      >
        {renderLabel[checkboxSize || size]}
        {helper && (
          <T.Body14I color="gray3" style={{ margin: 0 }}>
            ({helper})
          </T.Body14I>
        )}
      </label>

      {error && (
        <T.Body14R className={classes.textFieldError}>{error}</T.Body14R>
      )}
    </Grid>
  );
};

export default CheckboxComponent;
