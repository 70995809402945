import { styled } from "@material-ui/core/styles";
import MuiModal from "@material-ui/core/Modal";
import shadows from "../../utils/theme/shadows";

export const Modal = styled(MuiModal)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",

  [theme.breakpoints.mobileL]: {
    alignItems: "center",
  },

  [theme.breakpoints.tablet]: {
    paddingLeft: "350px",
  },
}));

export const Container = styled("div")(({ theme }) => ({
  padding: 40,
  // display: "flex",
  maxWidth: 540,
  boxShadow: shadows.card,
  backgroundColor: theme.colors.pinkLight,
  outline: "none",
}));

export const Title = styled("div")(() => ({
  display: "flex",
  marginBottom: 10,
}));

export const ButtonWrapper = styled("div")({
  minWidth: "fit-content",
});
