import React from "react";

import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import Icon from "../../components/Icon";

import t from "../../utils/helpers/translator";
import { Row, Col } from "../../components/Grid";

import * as T from "../../components/Typograpy";
import { GENERAL } from "../../constants/navRoutes";
import { externalLinks } from "../../constants";

import WelcomeSection from "../Login/WelcomeSection";

import {
  StyledTitle,
  StyledRow,
  DecideCard,
  DecideCardText,
  DecideCardArrow,
  ExtLink,
  StyledLink,
} from "./style";

const useQuery = () => new URLSearchParams(useLocation().search);

const SignUpFunnel = ({ language }) => {
  const query = useQuery();
  const programmeToken = query.get("programme");
  const inviteToken = query.get("invite");
  const giftToken = query.get("gift");

  const searchParams = new URLSearchParams();
  if (programmeToken) searchParams.append("programme", programmeToken);
  if (inviteToken) searchParams.append("invite", inviteToken);
  if (giftToken) searchParams.append("gift", giftToken);

  return (
    <>
      <StyledRow jc="space-between" mt="8">
        <Col w={[4, 12, 7]}>
          <Row inner>
            <Col w={[4, 12, 12]} mb="6" mbM="5">
              <StyledTitle color="primary">{t("signUp", language)}</StyledTitle>
            </Col>
            <Col w={[4, 12, 12]} mb="4" mbM="3">
              <T.Body16R color="gray3">
                {t("whoWouldYouLikeToSignUpAs", language)}
              </T.Body16R>
            </Col>
          </Row>
          <Row inner>
            <Col w={[4, 10, 8]} mb="1">
              <StyledLink
                to={{
                  pathname: GENERAL.SIGN_UP,
                  search: searchParams.toString(),
                }}
              >
                <DecideCard color="blue">
                  <DecideCardText>
                    <T.Body16R color="white">
                      <span style={{ fontWeight: "700" }}>
                        {t("volunteer", language)}:
                      </span>{" "}
                      {t("youWouldLikeToOrAlreadyVolunteer", language)}
                    </T.Body16R>
                  </DecideCardText>
                  <DecideCardArrow>
                    <Icon
                      icon="arrowRight"
                      width="24"
                      height="24"
                      color="white"
                    />
                  </DecideCardArrow>
                </DecideCard>
              </StyledLink>
            </Col>
            <Col w={[4, 10, 8]}>
              <ExtLink
                href={externalLinks.WEBSITE}
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <DecideCard as="div" color="pink">
                  <DecideCardText>
                    <T.Body16R color="white">
                      <span style={{ fontWeight: "700" }}>
                        {t("organisation", language)}:
                      </span>{" "}
                      {t("youWouldYouWouldLikeToSupport", language)}.
                    </T.Body16R>
                  </DecideCardText>
                  <DecideCardArrow>
                    <Icon
                      icon="arrowRight"
                      width="24"
                      height="24"
                      color="white"
                    />
                  </DecideCardArrow>
                </DecideCard>
              </ExtLink>
            </Col>
          </Row>
        </Col>
        <Col w={[0, 0, 4]}>
          <WelcomeSection fullWidth />
        </Col>{" "}
      </StyledRow>
    </>
  );
};

const mapStateToProps = (state) => ({
  language: state.auth.language,
});

const mapActionToProps = {};

export default connect(mapStateToProps, mapActionToProps)(SignUpFunnel);
