import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import Table from "../../../components/Table";
import {
  nameJoinDateCol,
  joinDateCol,
} from "../../../components/Table/config/reusableColumns";
import RemoveRejoin from "../../../components/RemoveRejoin";
import Loading from "../../../components/Loading";
import * as T from "../../../components/Typograpy";
import Icon from "../../../components/Icon";
import * as S from "./style";
import { EARN_GROUP } from "../../../constants/navRoutes";
import CSVExport from "../../../components/CSVExport";
import t from "../../../utils/helpers/translator";
import RemoveMemberModal from "./RemoveMemberModal";
import IconButton from "@material-ui/core/IconButton";

import {
  getEarnGroupsMembers,
  updateGroupMemberStatus,
  deleteGroupMember,
} from "./actions";

const EarnGroupMembers = (props) => {
  const history = useHistory();

  const {
    // redux props
    members,
    profile,
    isLoading,
    userId,
    getEarnGroupsMembersAction,
    updateGroupMemberStatusAction,
    deleteGroupMemberAction,
    updateMemberLoading,
    // ui props
    isMobile,
    headline,
    preview,
    hideSearch,
    hideCSV,
    language,
  } = props;

  const { name: groupName } = profile;

  const CSVEarnGroupMembersHeaders =
    language !== "english"
      ? [
          {
            label: t("translateExport", language),
            key: "",
          },
          { label: "First Name", key: "firstName" },
          { label: "Last Name", key: "lastName" },
          { label: "Join Date", key: "date" },
          { label: "Time Credits earned", key: "creditsEarned" },
          {
            label: "% Time Credit earned that have been used",
            key: "creditsSpent",
          },
          { label: "Member status", key: "status" },
        ]
      : [
          { label: "First Name", key: "firstName" },
          { label: "Last Name", key: "lastName" },
          { label: "Join Date", key: "date" },
          { label: "Time Credits earned", key: "creditsEarned" },
          {
            label: "% Time Credit earned that have been used",
            key: "creditsSpent",
          },
          { label: "Member status", key: "status" },
        ];

  useEffect(() => {
    getEarnGroupsMembersAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const [rowToDelete, setRowToDelete] = useState(null);

  const clickRow = (event, rowData) =>
    history.push(EARN_GROUP.MEMBERS_SINGLE.replace(":id", rowData.memberId));

  const handleRemoveRejoin = (data) => {
    const { id: memberId, status: oldStatus, groupId } = data;
    const status = oldStatus === "activated" ? "diactivated" : "activated";
    updateGroupMemberStatusAction({ memberId, groupId, status });
  };

  const DeleteMember = ({ showText, rowData }) => (
    <IconButton
      onClick={() => setRowToDelete(rowData)}
      disabled={rowData.loading || updateMemberLoading}
      aria-label="permanently remove member"
    >
      {!rowData.loading || updateMemberLoading ? (
        <>
          <Icon
            icon={"trash"}
            color={"pink"}
            width="22px"
            height="22px"
            margin="0 5px 0 0"
          />
          {showText && (
            <T.Body14S color="gray3">{t("remove", language)}</T.Body14S>
          )}
        </>
      ) : (
        <Loading />
      )}
    </IconButton>
  );

  const memberColumnsMobile = [
    nameJoinDateCol(language),
    {
      title: t("tcEarned", language),
      field: "creditsEarned",
      type: "numeric",
    },
    {
      title: t("tcSpent", language),
      field: "creditsSpent",
      customSort: (a, b) =>
        Number(a.creditsSpent?.replace("%", "")) -
        Number(b.creditsSpent?.replace("%", "")),
    },
    {
      title: "",
      field: "removeRejoin",
      disableClick: true,
      render: (rowData) => (
        <S.MemberActions>
          <RemoveRejoin
            type={rowData.status === "activated" ? "Remove" : "Re-join"}
            loading={rowData.loading || updateMemberLoading}
            handleClick={() => handleRemoveRejoin(rowData)}
            handleDelete={() => setRowToDelete(rowData)}
            removeText={"deactivate"}
          />
          <DeleteMember showText={false} rowData={rowData} />
        </S.MemberActions>
      ),
    },
  ];

  const memberColumnsDesktop = [
    { title: t("name", language), field: "member" },
    joinDateCol(language),
    {
      title: t("tcEarned", language),
      field: "creditsEarned",
      type: "numeric",
    },
    {
      title: t("tcSpent", language),
      field: "creditsSpent",
      customSort: (a, b) =>
        Number(a.creditsSpent?.replace("%", "")) -
        Number(b.creditsSpent?.replace("%", "")),
    },
    {
      title: "",
      field: "removeRejoin",
      disableClick: true,
      render: (rowData) => (
        <S.MemberActions>
          <RemoveRejoin
            type={rowData.status === "activated" ? "Remove" : "Re-join"}
            loading={rowData.loading}
            handleClick={() => handleRemoveRejoin(rowData)}
            handleDelete={() => setRowToDelete(rowData)}
            showText
            language={language}
            removeText={"deactivate"}
          />
          <DeleteMember showText={true} rowData={rowData} />
        </S.MemberActions>
      ),
    },
  ];
  const csvMembers = () => {
    const cleanMembers = members.map((member) => {
      if (member.status === "diactivated") {
        return { ...member, status: "deactivated" };
      }
      return member;
    });
    return cleanMembers;
  };

  if (isLoading)
    return (
      <S.MemberSectionWrapper>
        {headline && (
          <>
            {isMobile ? (
              <T.H24Caps color="midnight">{headline}</T.H24Caps>
            ) : (
              <T.H40Caps color="midnight" style={{ marginBottom: "40px" }}>
                {headline}
              </T.H40Caps>
            )}
          </>
        )}
        <Table
          isLoading
          data={members}
          columns={memberColumnsMobile}
          hideSearch
        />
      </S.MemberSectionWrapper>
    );
  if (isMobile) {
    return (
      <S.MemberSectionWrapper>
        {headline && <T.H24Caps color="midnight">{headline}</T.H24Caps>}
        <Table
          color="blue"
          data={members}
          isLoading={isLoading}
          columns={memberColumnsMobile}
          hideSearch={hideSearch}
          preview={preview}
          previewPath={EARN_GROUP.MEMBERS}
          onRowClick={clickRow}
        />
      </S.MemberSectionWrapper>
    );
  }

  return (
    <S.MemberSectionWrapper style={{ marginBottom: "80px" }}>
      {headline && (
        <T.H40Caps color="midnight" style={{ marginBottom: "40px" }}>
          {headline}
        </T.H40Caps>
      )}
      <S.TableContainer>
        {!hideCSV && (
          <S.BtnContainer>
            <CSVExport
              data={csvMembers()}
              headers={CSVEarnGroupMembersHeaders}
              filename="earn-group-all-members"
            />
          </S.BtnContainer>
        )}
        <Table
          color="blue"
          isLoading={isLoading}
          data={members}
          columns={memberColumnsDesktop}
          hideSearch={hideSearch}
          preview={preview}
          previewPath={EARN_GROUP.MEMBERS}
          onRowClick={clickRow}
        />
      </S.TableContainer>
      <RemoveMemberModal
        modalOpen={!!rowToDelete}
        confirmFun={() =>
          deleteGroupMemberAction({
            memberId: rowToDelete.id,
            groupId: rowToDelete.groupId,
          })
        }
        handleCloseModal={() => setRowToDelete(null)}
        memberName={rowToDelete?.member}
        earnGroupName={groupName}
        language={language}
      />
    </S.MemberSectionWrapper>
  );
};

const mapStateToProps = (state) => ({
  profile: state.auth.profile,
  members: state.earnGroupMembers.allMembers,
  isLoading: state.earnGroupMembers.loading,
  updateMemberLoading: state.earnGroupMembers.updateLoading,
  userId: state.auth.id,
  language: state.auth.language,
});

const mapActionToProps = {
  getEarnGroupsMembersAction: getEarnGroupsMembers,
  updateGroupMemberStatusAction: updateGroupMemberStatus,
  deleteGroupMemberAction: deleteGroupMember,
};

export default connect(mapStateToProps, mapActionToProps)(EarnGroupMembers);
