import React, { useEffect } from "react";
import { connect } from "react-redux";

import * as S from "./style";
import * as T from "../Typograpy";

import Icon from "../Icon";

import t from "../../utils/helpers/translator";

const ZendeskButton = ({ lang }) => {
  const handleTranslate = () => {
    const iFrame = document.getElementById("webWidget");
    const iFrameDoc = iFrame?.contentWindow?.document;
    if (!iFrame || !iFrameDoc) return;
    const labels = iFrameDoc.getElementsByTagName("label");
    const btns = iFrameDoc.getElementsByTagName("button");
    const allContent = [...labels, ...btns];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < allContent.length; i++) {
      switch (allContent[i].textContent) {
        case "Your name (optional)":
        case "Eich enw (opsiynol)":
          allContent[i].textContent = t("yourNameOptional", lang);
          break;
        case "Email address":
        case "Cyfeiriad ebost":
          allContent[i].textContent = t("zenEmailAddress", lang);
          break;
        case "How can we help you?":
        case "Sut allwn ni eich helpu chi?":
          allContent[i].textContent = t("howCanWeHelpYou", lang);
          break;
        case "Attachments":
        case "Atodiadau":
          allContent[i].textContent = t("attachments", lang);
          break;
        case "Add up to 5 files":
        case "Ychwanegwch hyd at 5 o ffeiliau":
          allContent[i].textContent = t("addUpTo5Files", lang);
          break;
        case "Send":
        case "Anfon":
          allContent[i].textContent = t("send", lang);
          break;
        default:
          break;
      }
    }
  };

  if (typeof window?.zE === "function") {
    window.zE("webWidget:on", "userEvent", ({ action } = {}) => {
      if (action === "Contact Form Shown") {
        handleTranslate();
      }
    });
  }

  useEffect(() => {
    if (lang === "welsh") {
      window.zE("webWidget", "setLocale", "cy");
    } else {
      window.zE("webWidget", "setLocale", "en");
    }
    const display = window.zE("webWidget:get", "display");

    if (display && display !== "hidden") {
      handleTranslate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <S.FeedbackLink
      lang={lang}
      onClick={() => {
        if (typeof window.zE.activate === "function") {
          window.zE.activate({ hideOnClose: true });
        }
      }}
    >
      <Icon icon="questionMark" color="white" width="20px" margin="0 5px 0 0" />
      <T.Body14S color="white" style={{ marginRight: "5px" }}>
        {t("help", lang)}
      </T.Body14S>
    </S.FeedbackLink>
  );
};

const mapStateToProps = (state) => ({
  lang: state.auth.language,
});

export default connect(mapStateToProps)(ZendeskButton);
