import React, { useEffect } from "react";
// only Router accepts the history prop (BrowserRouter does not)
import { Router, Switch, Route as ReactRoute } from "react-router-dom";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CssBaseline from "@material-ui/core/CssBaseline";
import { connect } from "react-redux";
import ScrollToTop from "./utils/helpers/ScrollToTop";
import theme from "./utils/theme";
import history from "./history";
import GoogleTranslateButton from "./components/GoogleTranslateButton";
import SessionManagement from "./components/SessionManagement";

// ACTIONS
import { getLoggedInUserInfo as getLoggedInUserInfoAction } from "./redux/actions/auth";
import { getUserCurrentTc, setMobile } from "./redux/actions/userActions";

// CONSTANTS
import { SECTIONS } from "./constants/navRoutes";

// COMPONENTS
import CookieBanner from "./components/CookieBanner";

// THIRD PARTY
import hotJarConfig from "./hotJarConfig";

// ROUTES
import StaffRouter from "./pages/StaffRoutes";
import MemberRouter from "./pages/MemberRoutes";
import EarnGroupRouter from "./pages/EarnGroupRoutes";
import SpendVenueRouter from "./pages/SpendVenueRoutes";
import ErrorRouter from "./pages/ErrorRoutes";
import ProgrammeManagerRouter from "./pages/ProgrammeManagerRoutes";
import EarnGroupAdminRouter from "./pages/EarnGroupAdminRoutes";
import GeneralRouter from "./pages/GeneralRoutes";
import SpendPartner from "./pages/SpendPartner";

const App = ({
  getLoggedInUserInfo,
  getUserCurrentTcAction,
  setMobileAction,
  isLoggedIn,
}) => {
  useEffect(() => {
    getLoggedInUserInfo(history);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (isLoggedIn) {
      getUserCurrentTcAction();
    }
    setMobileAction(isMobile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, isMobile]);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      hotJarConfig(
        window,
        document,
        "https://static.hotjar.com/c/hotjar-",
        ".js?sv=",
      );
    }
  }, []);

  // if domain is www.timecredits.com then redirect to https://tempotimecredits.org/activities
  if (window.location.hostname === "www.timecredits.com") {
    window.location.replace("https://tempotimecredits.org/activities");
  }

  return (
    <Router history={history}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <ScrollToTop />
        <SessionManagement />
        <GoogleTranslateButton />
        <Switch>
          {/* STAFF ROUTES */}
          <ReactRoute path={SECTIONS.TEMPO}>
            <StaffRouter />
          </ReactRoute>

          {/* MEMBER ROUTES */}
          <ReactRoute path={SECTIONS.MEMBERS}>
            <MemberRouter />
          </ReactRoute>

          {/* EARN GROUP ROUTES */}
          <ReactRoute path={SECTIONS.EARN_GROUPS}>
            <EarnGroupRouter />
          </ReactRoute>

          {/* SPEND VENUE ROUTES */}
          <ReactRoute path={SECTIONS.SPEND_VENUES}>
            <SpendVenueRouter />
          </ReactRoute>

          {/* PROGRAMME MANAGER ROUTES */}
          <ReactRoute path={SECTIONS.PROGRAMME_MANAGERS} hideLangToggle>
            <ProgrammeManagerRouter hideLangToggle />
          </ReactRoute>

          {/* EARN GROUP ADMIN ROUTES */}
          <ReactRoute path={SECTIONS.EARN_GROUP_ADMINS}>
            <EarnGroupAdminRouter />
          </ReactRoute>

          {/* SPEND PARTNER ROUTES */}
          <ReactRoute path={SECTIONS.SPEND_PARTNER}>
            <SpendPartner />
          </ReactRoute>

          {/* GENERAL ROUTES */}
          <GeneralRouter />

          {/* ERROR ROUTES */}
          <ErrorRouter />
        </Switch>
        {process.env.NODE_ENV === "production" && <CookieBanner />}
      </MuiThemeProvider>
    </Router>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
});

const mapActionToProps = {
  getLoggedInUserInfo: getLoggedInUserInfoAction,
  getUserCurrentTcAction: getUserCurrentTc,
  setMobileAction: setMobile,
};

export default connect(mapStateToProps, mapActionToProps)(App);
