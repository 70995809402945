import {
  CREATE_SPEND_PARTNER_LOADING_START,
  CREATE_SPEND_PARTNER_LOADING_END,
  CREATE_SPEND_PARTNER_SUCCESS,
  CREATE_SPEND_PARTNER_FAIL,
} from "../../../redux/types";

import { spendPartners } from "../../../api-calls";

export const createSpendPartner =
  ({
    name,
    firstName,
    lastName,
    phoneNumber,
    email,
    password,
    spendVenueIdToReassign,
  }) =>
  async (dispatch) => {
    dispatch({ type: CREATE_SPEND_PARTNER_LOADING_START });
    try {
      const { data, error } = await spendPartners.createSpendPartner({
        form: {
          name,
          firstName,
          lastName,
          phoneNumber,
          email,
          password,
          spendVenueIdToReassign,
        },
      });
      if (data) {
        dispatch({
          type: CREATE_SPEND_PARTNER_SUCCESS,
          payload: data,
        });
        return { data };
      }
      if (error) {
        dispatch({
          type: CREATE_SPEND_PARTNER_FAIL,
          payload: error,
        });
        return { error };
      }
    } catch (error) {
      dispatch({
        type: CREATE_SPEND_PARTNER_FAIL,
        payload: error,
      });
      return { error };
    } finally {
      dispatch({ type: CREATE_SPEND_PARTNER_LOADING_END });
    }
  };
