const initAddress = [
  {
    id: 0,
    addressLine1: "",
    addressLine2: "",
    city: "",
    county: "",
    postcode: "",
    addressLine1Welsh: "",
    addressLine2Welsh: "",
    cityWelsh: "",
    countyWelsh: "",
  },
];

const fieldExists = (fieldName) => fieldName && fieldName.length > 0;

export const formatAddresses = (_addresses, _addressesWelsh) => {
  if (fieldExists(_addresses) && !fieldExists(_addressesWelsh)) {
    return _addresses.map((elm, i) => ({ ...elm, id: i }));
  }

  if (fieldExists(_addresses) && fieldExists(_addressesWelsh)) {
    const jointAddresses = _addresses.map((el1, i) => {
      const jointObj = {
        id: i,
        addressLine1: el1.addressLine1,
        addressLine2: el1.addressLine2,
        city: el1.city,
        county: el1.county,
        postcode: el1.postcode,
        addressLine1Welsh:
          _addressesWelsh[i] && _addressesWelsh[i].addressLine1,
        addressLine2Welsh:
          _addressesWelsh[i] && _addressesWelsh[i].addressLine2,
        cityWelsh: _addressesWelsh[i] && _addressesWelsh[i].city,
        countyWelsh: _addressesWelsh[i] && _addressesWelsh[i].county,
      };

      return jointObj;
    });
    return jointAddresses;
  }

  return initAddress;
};

export const formatActivitiesRoles = (_roles, _rolesWelsh) => {
  if (fieldExists(_roles) && !fieldExists(_rolesWelsh)) {
    return _roles.map((elm, i) => ({ ...elm, id: i }));
  }

  if (fieldExists(_roles) && fieldExists(_rolesWelsh)) {
    const jointRoles = _roles.map((el1, idx) => {
      const jointObj = {
        id: idx,
        name: el1.name,
        details: el1.details,
        nameWelsh: _rolesWelsh[idx].name,
        detailsWelsh: _rolesWelsh[idx].details,
      };
      return jointObj;
    });

    return jointRoles;
  }
  return [];
};

export const setAddressesWelsh = (addresses) =>
  addresses &&
  addresses.length &&
  addresses.map((el) => {
    const addressObj = {
      addressLine1: el.addressLine1Welsh,
      addressLine2: el.addressLine2Welsh,
      city: el.cityWelsh,
      county: el.countyWelsh,
      lat: el.lat,
      long: el.long,
      postcode: el.postcode,
    };

    return addressObj;
  });

export const setActivitiesRolesWelsh = (activitiesRoles) =>
  activitiesRoles.map((el) => {
    const activitiesRolesObj = {
      name: el.nameWelsh,
      details: el.detailsWelsh,
    };

    return activitiesRolesObj;
  });
