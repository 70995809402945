import {
  GET_TK_GROUP_CONFLICT_DATA_FAIL,
  GET_TK_GROUP_CONFLICT_DATA_LOADING_END,
  GET_TK_GROUP_CONFLICT_DATA_LOADING_START,
  GET_TK_GROUP_CONFLICT_DATA_SUCCESS,
  TK_MERGE_GROUPS_START,
  TK_MERGE_GROUPS_SUCCESS,
  TK_MERGE_GROUPS_FAIL,
  TK_APPROVE_AS_NEW_GROUP_START,
  TK_APPROVE_AS_NEW_GROUP_SUCCESS,
  TK_APPROVE_AS_NEW_GROUP_FAIL,
} from "../../../../redux/types";

const initState = {
  data: [],
  loading: false,
  error: null,
};

const tkGroupConflictData = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TK_GROUP_CONFLICT_DATA_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case GET_TK_GROUP_CONFLICT_DATA_LOADING_END:
      return {
        ...state,
        loading: false,
      };
    case GET_TK_GROUP_CONFLICT_DATA_SUCCESS:
      return {
        ...state,
        data: payload,
        loading: false,
        error: {},
      };

    case GET_TK_GROUP_CONFLICT_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    // Merge groups
    case TK_MERGE_GROUPS_START:
      return {
        ...state,
        mergeGroupLoading: true,
      };
    case TK_MERGE_GROUPS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          name: payload.updatedGroupProfile.name || state.data.name,
        },
        mergeGroupLoading: false,
      };
    case TK_MERGE_GROUPS_FAIL:
      return {
        ...state,
        mergeGroupLoading: false,
        mergeGroupError: payload,
      };

    // approve as new group
    case TK_APPROVE_AS_NEW_GROUP_START:
      return {
        ...state,
        mergeGroupLoading: true,
      };
    case TK_APPROVE_AS_NEW_GROUP_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          name: payload.updatedGroupProfile.name,
          newGroupUserId: payload.updatedGroupProfile.userId,
        },
        mergeGroupLoading: false,
      };
    case TK_APPROVE_AS_NEW_GROUP_FAIL:
      return {
        ...state,
        mergeGroupLoading: false,
        mergeGroupError: payload,
      };

    default:
      return state;
  }
};

export default tkGroupConflictData;
