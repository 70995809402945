import React from "react";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Loading from "../../../components/Loading";

import * as S from "./style";
// components
import BigLink from "../../../components/Links/BigLink";
import * as T from "../../../components/Typograpy";
import MembersSection from "./AllMembersLogic";
import { EARN_GROUP } from "../../../constants/navRoutes";

import t from "../../../utils/helpers/translator";

const EarnGroupAllMembers = ({ isLoading, members, language }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const signedUpATM = members.filter((m) => m.status === "activated").length;

  const renderLinkSection = () => (
    <S.LinkWrapper>
      <BigLink
        color="pink"
        text={t("giveOutTC", language)}
        path={EARN_GROUP.GIVE}
      />
      <BigLink
        color="blue"
        text={t("inviteMembers", language)}
        path={EARN_GROUP.INVITE}
      />
    </S.LinkWrapper>
  );

  return (
    <>
      {isMobile ? (
        <S.MemberWrapper>
          <S.Header>
            <T.H30Caps
              color="midnight"
              style={{ marginBottom: "1rem", marginLeft: "0" }}
            >
              {t("members", language)}
            </T.H30Caps>
            {!isLoading ? (
              <T.Body14R style={{ marginLeft: "0", marginBottom: "20px" }}>
                <strong>{(members && members.length) || "0"}</strong>{" "}
                {t("signedUpAtMoment", language)}
              </T.Body14R>
            ) : (
              <Loading />
            )}
          </S.Header>
          <MembersSection hideSearch isMobile={isMobile} />
          {renderLinkSection()}
        </S.MemberWrapper>
      ) : (
        <S.MemberWrapper>
          <S.Header>
            <T.H40Caps
              color="midnight"
              style={{ marginBottom: "1rem", marginLeft: "0" }}
            >
              {t("members", language)}
            </T.H40Caps>
            {!isLoading ? (
              <T.Body16R style={{ marginLeft: "0" }}>
                <strong>{signedUpATM}</strong> {t("signedUpAtMoment", language)}{" "}
              </T.Body16R>
            ) : (
              <Loading />
            )}
          </S.Header>
          <MembersSection isMobile={isMobile} />
          {renderLinkSection()}
        </S.MemberWrapper>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  members: state.earnGroupMembers.allMembers,
  isLoading: state.earnGroupMembers.loading,
  userId: state.auth.id,
  language: state.auth.language,
});

const mapActionToProps = {};

export default connect(mapStateToProps, mapActionToProps)(EarnGroupAllMembers);
