import axios from "axios";
import handleError from "../handle-errors";

const TICKETS_BASE = `${process.env.REACT_APP_ZENDESK_BASE_URL}requests.json`;

const createTicket = async ({ query, options = {} }) => {
  const { orgName } = query;
  const subject = "New Recognition Listing Requires Approval";
  const body = `${orgName} has just submitted a new recognition listing which requires Tempo approval. 
                    Please 1.) Check the listing is correct 2.) Check the associated Contract Manager/ Business Development Associate is happy for it to be approved 
                    3.)Approve the listing. 4.) Inform the partner. 5.) Close this ticket.
                `;

  try {
    const data = await axios.post(
      TICKETS_BASE,
      {
        request: { subject, comment: { body }, requester: { name: orgName } },
      },
      {
        headers: {
          Authorization: `Basic ${process.env.REACT_APP_ZENDESK_EMAIL}/token:${process.env.REACT_APP_ZENDESK_TOKEN}`,
        },
      },
    );
    return data;
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export { createTicket };
