import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import Table from "../../../components/Table";
import {
  dateCol,
  creditsCol,
  typeCol,
  activityDateCol,
} from "../../../components/Table/config/reusableColumns";
import ExportWithDatesRange from "../../../components/ExportWithDatesRange";

import * as T from "../../../components/Typograpy";
import * as S from "./style";
import { EARN_GROUP } from "../../../constants/navRoutes";
import { roles } from "../../../constants";
import { earnTypesMapWelsh } from "../../../constants/data/earnActivityTypes";

import { getUserTransactions } from "../../../redux/actions/userActions";

import t from "../../../utils/helpers/translator";

const TransactionSection = ({
  getUserTransactionsAction,
  transactions,
  isMobile,
  credits,
  headline,
  preview,
  hideSearch,
  userID,
  hideCSV,
  role,
  supervisorId,
  language,
}) => {
  const history = useHistory();
  useEffect(() => {
    getUserTransactionsAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickRow = (event, rowData) => {
    if (rowData.packageOrderId) {
      return history.push(
        EARN_GROUP.HISTORY_SINGLE_PAID.replace(":id", rowData.packageOrderId),
      );
    }
    return history.push(
      EARN_GROUP.HISTORY_SINGLE.replace(":id", rowData.transactionID),
    );
  };

  const transactionColumnsMobile = [
    activityDateCol(t("activityDate", language), language),
    creditsCol(t("numberOfTC", language)),
  ];

  const transactionColumnsDesktop = [
    dateCol(t("date", language)),
    { title: t("activity", language), field: "activity" },
    creditsCol(t("numberOfTC", language)),
    typeCol(t("type", language), language),
  ];

  const translateTitle = ({ name, welshName }) => {
    if (language === "welsh") {
      if (welshName) return welshName;
      const res = earnTypesMapWelsh[name];
      return res || name;
    }
    return name;
  };

  const transactionTableData = transactions.data.map((transaction) => {
    let activity = transaction.activity.name
      ? translateTitle(transaction.activity)
      : "--";
    if (transaction.type === "gift") {
      activity = t("gifted", language);
    } else if (["free", "exchange"].includes(transaction.type)) {
      activity = t("TCdelivery", language);
    } else if (["bought"].includes(transaction.type)) {
      activity = t("timeCreditsPurchase", language);
    }

    let activityType = transaction.type;

    if (
      transaction.from &&
      (transaction.from.userId === userID ||
        (role === roles.EARN_GROUP_ADMIN &&
          supervisorId === transaction.from.userId))
    ) {
      activityType = "givenOut";
    } else if (
      ["cancelled", "paymentDue", "bought"].includes(transaction.type)
    ) {
      activityType = transaction.type;
    } else {
      activityType = "received";
    }

    return {
      packageOrderId: transaction.packageOrderId,
      date: transaction.createdAt,
      activity,
      credits: transaction.tc,
      type: activityType,
      transactionID: transaction.id,
      status: transaction.status,
    };
  });

  const { loading } = transactions;

  if (loading)
    return (
      <S.TransactionSectionWrapper>
        {headline && (
          <>
            {isMobile ? (
              <>
                <T.H24Caps color="midnight">
                  {t("tempoTCHistory", language)}
                </T.H24Caps>
                {credits && (
                  <T.H18 color="blue" style={{ marginBottom: "5px" }}>
                    {t("tcBalance", language)}: {credits}
                  </T.H18>
                )}
              </>
            ) : (
              <>
                <T.H40Caps color="midnight" style={{ marginBottom: "40px" }}>
                  {t("tempoTCHistory", language)}
                </T.H40Caps>
                {credits && (
                  <T.H18 color="blue" style={{ marginBottom: "5px" }}>
                    {t("tcBalance", language)}: {credits}
                  </T.H18>
                )}
              </>
            )}
          </>
        )}
        <Table
          isLoading
          data={transactionTableData}
          columns={transactionColumnsMobile}
          hideSearch
          preview
        />
      </S.TransactionSectionWrapper>
    );

  if (isMobile) {
    return (
      <S.TransactionSectionWrapper>
        {headline && (
          <>
            <T.H24Caps
              color="midnight"
              style={{ marginBottom: "5px", lineHeight: "24px" }}
            >
              {t("tempoTCHistory", language)}
            </T.H24Caps>

            {credits && (
              <T.Body16B color="blue" style={{ marginBottom: "20px" }}>
                {t("tcBalance", language)}: {credits}
              </T.Body16B>
            )}
          </>
        )}

        <Table
          color="pink"
          data={transactionTableData}
          columns={transactionColumnsMobile}
          hideSearch={hideSearch}
          preview={preview}
          previewPath={EARN_GROUP.HISTORY}
          onRowClick={clickRow}
        />
      </S.TransactionSectionWrapper>
    );
  }

  return (
    <S.TransactionSectionWrapper>
      {headline && (
        <>
          <T.H40Caps color="midnight" style={{ marginBottom: "20px" }}>
            {t("tempoTCHistory", language)}
          </T.H40Caps>

          <T.H24 color="blue" style={{ marginBottom: "40px" }}>
            {t("tcBalance", language)}: {credits || 0}
          </T.H24>
        </>
      )}
      <S.TableContainer>
        {!hideCSV && (
          <S.BtnContainer>
            <ExportWithDatesRange exportEndpoint="my/transactions" />
          </S.BtnContainer>
        )}

        <Table
          color="pink"
          data={transactionTableData}
          columns={transactionColumnsDesktop}
          hideSearch={hideSearch}
          preview={preview}
          previewPath={EARN_GROUP.HISTORY}
          onRowClick={clickRow}
        />
      </S.TableContainer>
    </S.TransactionSectionWrapper>
  );
};

const mapStateToProps = (state) => ({
  transactions: state.user.transactions,
  userID: state.auth.id,
  role: state.auth.role,
  supervisorId: state.auth.supervisorId,
  language: state.auth.decideLanguage(state.auth),
});

const mapActionToProps = {
  getUserTransactionsAction: getUserTransactions,
};

export default connect(mapStateToProps, mapActionToProps)(TransactionSection);
