import { fields, createSchema, validate as _validate } from "..";

const { membershipId, adultsParticipants, id } = fields;

const schema = createSchema({
  membershipId,
  adultsParticipants,
  activityId: id,
});

const validate = (data) => _validate(schema, data);

export default validate;
