import React, { useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import Table from "../../../../components/Table";

import * as T from "../../../../components/Typograpy";
import { Row, Col } from "../../../../components/Grid";
import { Regular } from "../../../../components/Button";
import { TEMPO, GENERAL } from "../../../../constants/navRoutes";
import * as actions from "../SingleSpendVenue/action";

import {
  checkedUnchecked,
  buttonAction,
} from "../../../../components/Table/config/reusableColumns";

const SpendActivity = ({
  liveActivity,
  draftActivity,
  expiredActivity,
  activities,
  getSpendVenueActivities,
  loading,
}) => {
  const history = useHistory();
  const { id } = useParams();

  const clickCreateActivity = () =>
    history.push(TEMPO.CREATE_SPEND_ACTIVITY.replace(":spendVenueId", id));

  const activityColumns = [
    { title: "Activity Name", field: "name" },
    {
      title: "End Date",
      render: ({ endDate }) =>
        endDate ? moment(endDate).format("DD/MM/YYY") : "N/A",
    },
    {
      title: "Status",
      render: ({ isLive, isExpired }) => {
        if (isExpired) return "expired";
        return isLive ? "live" : "draft";
      },
    },
    checkedUnchecked({ title: "WELSH VERSION", field: "hasWelsh" }),
    buttonAction({
      text: "Edit",
      onClick: ({ id: _id }) =>
        history.push(TEMPO.EDIT_SPEND_ACTIVITY.replace(":id", _id)),
    }),
    buttonAction({
      text: "Duplicate",
      onClick: ({ id }) =>
        history.push(TEMPO.DUPLICATE_SPEND_ACTIVITY.replace(":id", id)),
    }),
  ];

  useEffect(() => {
    if (id) {
      if (Number(id)) {
        getSpendVenueActivities(id);
      } else {
        history.push("/404");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const clickRow = (event, rowData) => {
    const win = window.open(
      GENERAL.SPEND_ACTIVITY.replace(":id", rowData.id),
      "_blank",
    );
    win.focus();
  };

  return (
    <>
      <T.H24 mb={20} color="midnight">
        Offers/Activities
      </T.H24>
      <Row>
        <Col w={[4, 7, 7]} style={{ padding: 0 }}>
          <T.Body14R color="gray2" mb={40}>
            Please note, the earn group profile must be made public for any
            activities to be made visible on the website.
          </T.Body14R>
        </Col>
      </Row>
      <Row mb={8}>
        <Col w={[3, 3, 3]}>
          <Row inner>
            <T.H30 color="cyan" mr={15}>
              {liveActivity}
            </T.H30>
            <T.H20 m="0" mt="5" color="gray3">
              {liveActivity !== 1 ? "live activities" : "live activity"}
            </T.H20>
          </Row>
          <Row inner>
            <T.H30 color="blue" mr={15}>
              {draftActivity}
            </T.H30>
            <T.H20 m="0" mt="5" color="gray3">
              {draftActivity !== 1 ? "draft activities" : "draft activity"}
            </T.H20>
          </Row>
          <Row inner>
            <T.H30 color="pink" mr={15}>
              {expiredActivity}
            </T.H30>
            <T.H20 m="0" mt="5" color="gray3">
              {expiredActivity !== 1
                ? "expired activities"
                : "expired activity"}
            </T.H20>
          </Row>
        </Col>
        <Col w={[1, 1, 1]} />
        <Col w={[3, 3, 3]}>
          <Regular primary bgColor="blue" onClick={clickCreateActivity}>
            Add Activity
          </Regular>
        </Col>
      </Row>

      <Table
        color="blue"
        data={activities}
        columns={activityColumns}
        hideSearch
        onRowClick={clickRow}
        isLoading={loading}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  activities: state.staffSingleSpendVenue.activities || [],
  loading: state.staffSingleSpendVenue.activitiesLoading,
  draftActivity: state.staffSingleEarnGroup.activitiesStats.draft,
  liveActivity: state.staffSingleEarnGroup.activitiesStats.live,
  expiredActivity: state.staffSingleEarnGroup.activitiesStats.expired,
});

const mapActionsToProps = {
  getSpendVenueActivities: actions.getSpendVenueActivities,
};

export default connect(mapStateToProps, mapActionsToProps)(SpendActivity);
