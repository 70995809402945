import { transactions } from "../../../../api-calls";

import {
  GET_STAFF_TRANSACTIONS_LOADING,
  GET_STAFF_TRANSACTIONS_SUCCESS,
  GET_STAFF_TRANSACTIONS_FAIL,
} from "../../../../redux/types";

export const getTransactions =
  ({ limit, offset, startDate, endDate, query }) =>
  async (dispatch) => {
    dispatch({ type: GET_STAFF_TRANSACTIONS_LOADING });
    try {
      const { data, error } = await transactions.getUserTransactions({
        limit,
        offset,
        startDate,
        endDate,
        query,
      });
      if (data) {
        dispatch({ type: GET_STAFF_TRANSACTIONS_SUCCESS, payload: data });
      } else {
        dispatch({
          type: GET_STAFF_TRANSACTIONS_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_STAFF_TRANSACTIONS_FAIL,
        payload: error,
      });
    }
  };
