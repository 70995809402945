import { tkGroups } from "../../../../api-calls";

import {
  GET_TK_GROUP_REQUEST_LOADING_START,
  GET_TK_GROUP_REQUEST_LOADING_END,
  GET_TK_GROUP_REQUEST_SUCCESS,
  GET_TK_GROUP_REQUEST_FAIL,
  TK_APPROVE_REJECT_GROUP_START,
  TK_APPROVE_REJECT_GROUP_END,
  TK_APPROVE_REJECT_GROUP_SUCCESS,
  TK_APPROVE_REJECT_GROUP_FAIL,
} from "../../../../redux/types";

export const getTkGroupRequests =
  ({ limit, offset }) =>
  async (dispatch) => {
    dispatch({ type: GET_TK_GROUP_REQUEST_LOADING_START });
    try {
      const { data, error } = await tkGroups.getTkGroupsRequests({
        limit,
        offset,
      });
      if (data) {
        dispatch({ type: GET_TK_GROUP_REQUEST_SUCCESS, payload: data });
      } else {
        dispatch({
          type: GET_TK_GROUP_REQUEST_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_TK_GROUP_REQUEST_FAIL,
        payload: error,
      });
    } finally {
      dispatch({ type: GET_TK_GROUP_REQUEST_LOADING_END });
    }
  };

export const approveRejectGroup =
  ({ userId, status, id, name, email }) =>
  async (dispatch) => {
    dispatch({ type: TK_APPROVE_REJECT_GROUP_START });
    try {
      const { data, error } = await tkGroups.approveRejectGroup({
        id,
        userId,
        status,
        name,
        email,
      });
      if (data) {
        dispatch({ type: TK_APPROVE_REJECT_GROUP_SUCCESS, payload: data });
      } else {
        dispatch({
          type: TK_APPROVE_REJECT_GROUP_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: TK_APPROVE_REJECT_GROUP_FAIL,
        payload: error,
      });
    } finally {
      dispatch({ type: TK_APPROVE_REJECT_GROUP_END });
    }
  };
