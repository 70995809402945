import React, { useState } from "react";

import moment from "moment";
import * as S from "./style";
import Datepicker from "../Inputs/Datepicker";
import Icon from "../Icon";

const FilterByDate = ({ dates, setDates }) => {
  const [_dates, _setDates] = useState(dates);

  const handleDates = (dateObj) => {
    _setDates(dateObj.dates);

    if (!dateObj.dates.startDate && !dateObj.dates.endDate) {
      setDates({ startDate: null, endDate: null });
    }
  };

  const onClose = (values) => {
    const startDate =
      values.startDate && moment.utc(values.startDate).startOf("d");
    const endDate = values.endDate && moment.utc(values.endDate).endOf("d");
    setDates({ startDate, endDate });
  };

  const datePickerProps = {};

  if (!_dates.startDate && !_dates.endDate) {
    datePickerProps.customInputIcon = (
      <Icon icon="calendar" color="midnight" width="24px" height="24px" />
    );
  }

  return (
    <S.Wrapper>
      <Datepicker
        size="normal"
        mb={1}
        value={_dates}
        setValue={handleDates}
        // inputIconPosition="after"
        {...datePickerProps}
        style={{ zIndex: 1000 }}
        onClose={onClose}
        showClearDates
        disableFuture
      />
    </S.Wrapper>
  );
};
export default FilterByDate;
