import React, { useEffect } from "react";
import moment from "moment";

import { connect } from "react-redux";

import * as actions from "../../StaffRoutes/Staff/SingleProgramme/actions";

import * as S from "./style";
// components
import * as T from "../../../components/Typograpy";
import { Col, Row } from "../../../components/Grid";
import Loading from "../../../components/Loading";
import Stats from "../../../components/Stats";
import TCBalance from "../../../components/TCBalance";
import Button from "../../../components/Button";

const ProjectManagerDashboard = ({
  issued,
  earned,
  gifted,
  programme,
  earnGroups,
  totalEarnGroups,
  membersReached,
  activeMembers,
  spendVenues,
  spent,
  balance,
  loading,
  programmeId,
  managers,
  getProgrammeById,
  firstName,
}) => {
  useEffect(() => {
    if (programmeId) {
      getProgrammeById(programmeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programmeId]);

  const bottomMessages = [
    "The number of volunteers who have ever earned or been gifted or issued Time Credits in this Programme",
    "The number of volunteers currently signed up to earn Time Credits with Earn Groups that are in this Programme",
    "The number of Earn Groups that have ever been part of this programme",
    "The number of Earn Groups currently part of this Programme",
    "The number of Venues to have accepted Time Credits that were earned as part of this Programme",
  ];

  const bottomStats = [
    {
      statText: "VOLUNTEERS REACHED",
      statNum: membersReached,
    },
    {
      statText: "ACTIVE VOLUNTEERS",
      statNum: activeMembers,
    },
    {
      statText: "TOTAL EARN GROUPS",
      statNum: totalEarnGroups,
    },
    {
      statText: "CURRENT  EARN GROUPS",
      statNum: earnGroups,
    },
    {
      statText: "VENUES USED",
      statNum: spendVenues,
    },
  ];

  const topStats = [
    { statText: "Time credits issued", statNum: issued },
    { statText: "time credits earned", statNum: earned },
    { statText: "time credits used", statNum: spent },
    { statText: "time credits gifted", statNum: gifted },
  ];

  const bottomStatsWithMsg = bottomStats.map((stat, i) => ({
    ...stat,
    bottomMessage: bottomMessages[i],
  }));

  const details = {
    startDate: programme.startDate,
    closeDate: programme.closeDate,
    endDate: programme.endDate,
    focuses: programme.keyFocuses,
    name: programme.name,
  };

  return (
    <>
      <S.Wrapper>
        <S.HeaderWrapper>
          <T.H40 style={{ margin: "0" }} color="midnight">
            Welcome back, {firstName}!
          </T.H40>
          <TCBalance credits={balance} color="cyan" />
        </S.HeaderWrapper>
        <T.H24Caps
          style={{ marginLeft: "0", marginBottom: "20px" }}
          color="midnight"
        >
          {details.name}
        </T.H24Caps>
        <S.Section color="pinkLight">
          {loading || !topStats ? (
            <Loading center />
          ) : (
            <S.Stats>
              {topStats.map((elm) => (
                <Stats
                  size="s"
                  statNum={elm.statNum || "0"}
                  statNumColor="pink"
                  statText={elm.statText || "N/A"}
                  statTextColor="midnight"
                />
              ))}
            </S.Stats>
          )}
        </S.Section>
        <S.Section color="cyanLight">
          {loading || !topStats ? (
            <Loading center />
          ) : (
            <S.Stats>
              {bottomStatsWithMsg.map((elm) => (
                <Stats
                  key={elm.statText}
                  size="s"
                  statNum={elm.statNum || "0"}
                  statNumColor="darkPrimary"
                  statText={elm.statText || "N/A"}
                  statTextColor="blue"
                  bottomMessage={elm.bottomMessage}
                />
              ))}
            </S.Stats>
          )}
        </S.Section>
      </S.Wrapper>
      <Row mb={8} mt={1}>
        <Col w={[4, 4, 4]}>
          <S.CsvExport
            href={`/api/csv/programmes/${programmeId}?type=transactions`}
          >
            <Button>Download Time Credits History</Button>
          </S.CsvExport>
        </Col>
      </Row>

      {/* should remove the margin top when activate the button  */}
      <Row mt={4} mb={5}>
        <Col w={[4, 12, 9]}>
          <S.CardSection>
            <T.H24 color="midnight" ml="10" mb="30">
              Programme Details
            </T.H24>
            {loading ? (
              <Loading />
            ) : (
              <>
                <Row mb={3}>
                  <Col w={[2, 4, 4]}>
                    <T.Body18S color="gray2">Start Date</T.Body18S>
                  </Col>
                  <Col w={[2, 8, 8]}>
                    <T.Body16R color="gray2">
                      {details.startDate
                        ? moment(details.startDate).format("DD/MM/YYYY")
                        : "N/A"}
                    </T.Body16R>
                  </Col>
                </Row>
                <Row mb={3}>
                  <Col w={[2, 4, 4]}>
                    <T.Body18S color="gray2">End Date</T.Body18S>
                  </Col>
                  <Col w={[2, 8, 8]}>
                    <T.Body16R color="gray2">
                      {details.endDate
                        ? moment(details.endDate).format("DD/MM/YYYY")
                        : "N/A"}
                    </T.Body16R>
                  </Col>
                </Row>
                <Row>
                  <Col w={[2, 4, 4]}>
                    <T.Body18S color="gray2">Programme Managers</T.Body18S>
                  </Col>
                  <Col w={[2, 8, 8]}>
                    {managers && managers.length ? (
                      managers.map((manager) => (
                        <T.Body16R color="gray2">{manager}</T.Body16R>
                      ))
                    ) : (
                      <T.Body16R color="gray2">N/A</T.Body16R>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </S.CardSection>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  programmeId: state.auth.programmeId,
  firstName: state.auth.firstName,
  ...state.staffSingleProgramme,
});

const mapActionsToProps = {
  getProgrammeById: actions.getProgrammeById,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(ProjectManagerDashboard);
