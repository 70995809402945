import styled from "@material-ui/core/styles/styled";

export const Wrapper = styled("div")(({ theme }) => ({
  width: "100%",

  [theme.breakpoints.tablet]: {
    marginTop: "-30px",
  },
}));

export const ImageWrapper = styled("div")({
  minWidth: 250,
  height: 210,
  transform: "translate(30px, -30px)",
});

export const Head = styled("header")({
  display: "flex",
  justifyContent: "space-between",
});

export const HeadContent = styled("div")({
  display: "flex",
  flexDirection: "column",
});

export const StyledCard = styled("div")(({ theme, height, width }) => ({
  background: theme.colors.white,
  borderRadius: "4px",
  boxShadow: theme.customShadows.dp3,
  width: width || "340px",
  height: height ? `${height}` : `calc(${width}/1.7)`,
  position: "relative",
  padding: "26px 30px",
}));
