import { styled } from "@material-ui/core/styles";

export const Wrapper = styled("div")(({ m, theme }) => ({
  width: "100%",
  margin: m || "0",
  textAlign: "center",

  [theme.breakpoints.mobileL]: {
    textAlign: "left",
  },
}));

export const Number = styled("span")(({ theme }) => ({
  textAlign: "center",

  [theme.breakpoints.mobileL]: {
    textAlign: "left",
  },
}));
