import {
  CREATE_SPEND_PARTNER_LOADING_START,
  CREATE_SPEND_PARTNER_LOADING_END,
  CREATE_SPEND_PARTNER_SUCCESS,
  CREATE_SPEND_PARTNER_FAIL,
} from "../../../redux/types";

const initialState = {
  updatedData: {
    spendPartner: {},
    updatedSpendVenue: {},
  },
  createSpendPartnersLoading: false,
  createSpendPartnersError: {},
};

const spendPartnerForm = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SPEND_PARTNER_LOADING_START:
      return { ...state, createSpendPartnersLoading: true };
    case CREATE_SPEND_PARTNER_LOADING_END:
      return { ...state, createSpendPartnersLoading: false };
    case CREATE_SPEND_PARTNER_SUCCESS:
      return {
        ...state,
        updatedData: action.payload,
      };
    case CREATE_SPEND_PARTNER_FAIL:
      return { ...state, createSpendPartnersError: action.payload };

    default:
      return state;
  }
};

export default spendPartnerForm;
