// import styled from "styled-components";
import { Link } from "react-router-dom";
import { styled } from "@material-ui/core/styles";
// import theme from "../../../utils/theme";

export const Wrapper = styled(Link)(({ theme, w, m }) => ({
  color: theme.colors.white,
  textDecoration: "none",
  width: w || "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  background: "white",
  cursor: "pointer",
  boxShadow: theme.customShadows.dp3,
  minHeight: "175px",
  margin: m || "0",
  position: "relative",

  "&:hover": {
    boxShadow: theme.customShadows.cardHover,
  },

  "&:active": {
    boxShadow: theme.customShadows.card,
  },
}));

export const Top = styled("span")(({ theme }) => ({
  padding: theme.spacing(1),
  paddingTop: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

export const Bottom = styled("div")(({ theme }) => ({
  background: theme.gradients.blue,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(2),
  minWidth: "100%",
  width: "100%",
  position: "absolute",
  bottom: "0",
  left: "0",
}));

export const ButtonWrapper = styled("a")(({ theme, w, m, disabled }) => ({
  color: theme.colors.white,
  textDecoration: "none",
  width: w || "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  background: "white",
  border: "none",
  cursor: disabled ? "progress" : "pointer",
  padding: "0",
  textAlign: "left",
  boxShadow: theme.customShadows.dp3,
  minHeight: "175px",
  outline: "none",
  margin: m || "0",
  position: "relative",
  opacity: disabled ? 0.6 : 1,

  "&:hover": {
    boxShadow: theme.customShadows.cardHover,
  },

  "&:active": {
    boxShadow: theme.customShadows.card,
  },
}));

export const Downloading = styled("div")(({ theme }) => ({
  fontSize: "0.875rem",
  color: theme.colors.main,
  fontWeight: 500,
  marginTop: "5px",
  display: "block",
  marginLeft: "4px",
}));

export const Error = styled("span")(({ theme }) => ({
  fontWeight: 600,
  color: theme.colors.pink,
  fontSize: "0.875rem",
  marginLeft: "4px",
  marginTop: "-15px",
  display: "block",
}));
