import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { connect } from "react-redux";
import Content from "./Content";

import * as S from "../../../style";

const GetInTouch = ({ language }) => {
  const location = useLocation();
  const [pageState, setPageState] = useState(null);
  const {
    state: {
      contactEmail = "",
      contactNumber = "",
      getInTouchDescription = "",
      websiteUrl = "",
      welshVersion = null,
    } = {},
  } = location;

  const decideLanguage = () =>
    language === "welsh" && !welshVersion ? "english" : language;

  useEffect(() => {
    if (decideLanguage() === "welsh") {
      setPageState({
        getInTouchDescription: welshVersion.getInTouchDescription,
      });
    } else {
      setPageState({
        getInTouchDescription,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, welshVersion]);

  return (
    <S.GetInTouchSection>
      <Content
        contactEmail={contactEmail}
        contactNumber={contactNumber}
        getInTouchDescription={pageState && pageState.getInTouchDescription}
        websiteUrl={websiteUrl}
        lang={decideLanguage()}
      />
    </S.GetInTouchSection>
  );
};

const mapStateToProps = (state) => ({
  language: state.auth.language,
});

export default connect(mapStateToProps)(GetInTouch);
