import Transactions from "./Transactions";
import SingleTransaction from "./SingleTransaction";
import Members from "./Members";
import SingleMember from "./SingleMember";
import EarnGroups from "./EarnGroups";
import IndividiualEarnGroup from "./SingleEarnGroup";
import AccessPermission from "./AccessPermission";
import SpendVenues from "./SpendVenues";
import SpendVenuesWithLiveOffers from "./SpendVenuesWithLiveOffers";
import SingleSpendVenue from "./SingleSpendVenue";
import Offers from "./Offers";
import CreateProgramme from "./CreateProgramme";
import Programmes from "./Programmes";
import ProgrammeEarnGroups from "./programmeEarnGroups";
import SingleProgramme from "./SingleProgramme";
import ProgrammeInviteEarnGroup from "./ProgrammeInviteEarnGroup";
import EditEarnGroupAdmin from "./EditEarnGroupAdmin";
import EditSpendVenueAccount from "./EditSpendVenueAccount";
import AddMembers from "./AddMembers";
import CreditRequests from "./CreditRequests";
import EditEarnGroupProfile from "./EditEarnGroupProfile";
import SingleSpendPartner from "./SingleSpendPartner";
import RecognitionListingRequests from "./RecognitionListingRequests";
import ManagePackages from "./ManagePackages";
import Orders, { MarkOrderAsPaid, CancelOrder } from "./Orders";
import TkGroupRequests from "./TkGroupRequests";
import TkProgrammeConflict from "./TkProgrammeConflict";
import TkSingleGroupRequest from "./TkSingleGroupRequest";
import EditMemberAccount from "./EditMemberAccount";
import DuplicateSpendActivity from "./DuplicateSpendActivity";

export default {
  Transactions,
  SingleTransaction,
  Members,
  SingleMember,
  EarnGroups,
  IndividiualEarnGroup,
  AccessPermission,
  SpendVenues,
  SpendVenuesWithLiveOffers,
  SingleSpendVenue,
  Offers,
  CreateProgramme,
  Programmes,
  ProgrammeEarnGroups,
  SingleProgramme,
  ProgrammeInviteEarnGroup,
  EditEarnGroupAdmin,
  EditSpendVenueAccount,
  AddMembers,
  CreditRequests,
  EditEarnGroupProfile,
  SingleSpendPartner,
  RecognitionListingRequests,
  ManagePackages,
  Orders,
  MarkOrderAsPaid,
  CancelOrder,
  TkGroupRequests,
  TkProgrammeConflict,
  TkSingleGroupRequest,
  EditMemberAccount,
  DuplicateSpendActivity,
};
