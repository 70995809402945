import axios from "axios";
import handleError from "./handle-errors";
import * as LogRocket from "../utils/helpers/LogRocket";

const USERS_BASE = "/users";

const getUserById = async ({ id, options }) => {
  try {
    const { data } = await axios.get(`${USERS_BASE}/${id}/5545`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getLoggedInUserInfo = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${USERS_BASE}/info`);
    // add user info to log rocket session
    LogRocket.identify(data);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getUserAccount = async ({ id, role, options } = {}) => {
  try {
    let data;
    if (id) {
      const res = await axios.get(`${USERS_BASE}/${id}/account`, {
        params: { role },
      });
      data = res.data;
    } else {
      const res = await axios.get(`${USERS_BASE}/account`);
      data = res.data;
    }
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updateUserAccount = async ({
  accountDetails,
  id,
  role,
  options,
} = {}) => {
  try {
    let data;
    if (id) {
      const res = await axios.patch(
        `${USERS_BASE}/${id}/account`,
        {
          ...accountDetails,
        },
        { params: { role } },
      );
      data = res.data;
    } else {
      const res = await axios.patch(`${USERS_BASE}/account`, {
        ...accountDetails,
      });
      data = res.data;
    }

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updateUserLanguage = async ({ language, options } = {}) => {
  try {
    const { data } = await axios.patch(`${USERS_BASE}/language`, {
      language,
    });

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export {
  getUserById,
  getLoggedInUserInfo,
  getUserAccount,
  updateUserAccount,
  updateUserLanguage,
};
