import { spendPartners, spendVenues } from "../../../api-calls";
import { navRoutes, roles } from "../../../constants";

import {
  UPDATE_ACCEPT_TERMS_LOADING,
  UPDATE_ACCEPT_TERMS_SUCCESS,
  UPDATE_ACCEPT_TERMS_FAIL,
} from "../../../redux/types";

export const updateSpendPartnerTerms =
  ({ history, selectedSpendVenue, role }) =>
  async (dispatch) => {
    dispatch({ type: UPDATE_ACCEPT_TERMS_LOADING });
    try {
      let res;
      if (role === roles.SPEND_PARTNER) {
        res = await spendPartners.updateSpendPartnerTerms();
      } else {
        res = await spendVenues.updateSpendVenueTerms();
      }
      const { error } = res;
      if (!error) {
        dispatch({
          type: UPDATE_ACCEPT_TERMS_SUCCESS,
        });
        if (role === roles.SPEND_VENUE) {
          return history.push(navRoutes.SPEND_VENUE.ACCEPT_TC);
        }
        if (selectedSpendVenue && selectedSpendVenue.id) {
          return history.push(navRoutes.SPEND_VENUE.ACCEPT_TC);
        }
        return history.push(navRoutes.SPEND_PARTNER.SELECT_VENUE);
      }
      dispatch({
        type: UPDATE_ACCEPT_TERMS_FAIL,
        payload: error,
      });
      throw error;
    } catch (error) {
      dispatch({
        type: UPDATE_ACCEPT_TERMS_FAIL,
        payload: error,
      });
      throw error;
    }
  };
