import React from "react";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";

import * as S from "./style";
import * as T from "../../../components/Typograpy";
import { MEMBER } from "../../../constants/navRoutes";

import Image from "../../../components/Image";
import Button from "../../../components/Button";

import t from "../../../utils/helpers/translator";

const InviteByGroupSuccess = ({ earnGroupInfo, lang }) => {
  const history = useHistory();

  const onConfirm = () => history.push(MEMBER.DASHBOARD);

  return (
    <>
      <S.Wrapper>
        <Image
          image="success"
          alt="Success"
          height="100%"
          customStyle={{ maxHeight: "280px", margin: "0 auto 10px auto" }}
        />
        <S.Body>
          <T.H40Caps color="midnight" mb={10}>
            {t("goodNews", lang)}
          </T.H40Caps>
          <T.Body16R>
            {t("youCanEarnTCWith", lang)}{" "}
            <span style={{ fontWeight: "bold" }}>
              {earnGroupInfo.profile.name}
            </span>
            .
          </T.Body16R>
        </S.Body>
        <S.Progress>
          <Button
            size="l"
            primary
            style={{ margin: "0" }}
            handleClick={onConfirm}
          >
            {t("goToHomePage", lang)}
          </Button>
        </S.Progress>
      </S.Wrapper>
    </>
  );
};

const mapStateToProps = (state) => ({
  earnGroupInfo: state.auth.earnGroupInfo,
  role: state.auth.role,
  lang: state.auth.language,
});

export default connect(mapStateToProps)(InviteByGroupSuccess);
