import styled from "@material-ui/core/styles/styled";

export const Container = styled("div")(() => ({
  width: "100%",
}));

export const Head = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
});

export const Back = styled("div")({
  display: "flex",
  marginTop: -30,
  paddingBottom: 30,
});

export const Section = styled("section")(({ theme, color }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  minHeight: "160px",
  minWidth: "700px",
  width: "100vw",
  backgroundColor: theme.colors[color] || color,
  zIndex: "-1",
  border: `1px solid ${theme.colors.pinkMed}`,
  marginBottom: "2rem",
  position: "relative",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
  left: "-40px",

  [theme.breakpoints.mobileXL]: {
    paddingLeft: theme.spacing(9),
    paddingRight: theme.spacing(9),
  },

  [theme.breakpoints.tablet]: {
    width: "calc(100vw - 300px)",
    left: "-100px",
  },
}));

export const StatsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginTop: 60,
  "& h4": {
    margin: 0,
    marginBottom: 30,
  },
});

export const IdContainer = styled("div")({
  display: "flex",
  marginTop: 15,
  flexDirection: "column",
  "& h3": { margin: 0, marginBottom: 10 },
  "& h6": { margin: 0 },
});

export const CardContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  marginTop: 42,
});

export const StyledCard = styled("div")(({ theme, height, width }) => ({
  background: theme.colors.white,
  borderRadius: "4px",
  boxShadow: theme.customShadows.dp3,
  width: width || "340px",
  height: height ? `${height}` : `calc(${width}/1.7)`,
  position: "relative",
  padding: "26px 30px",
  marginRight: "20px",
}));

export const CardItem = styled("div")(() => ({
  display: "flex",
  margin: "21px 0",
  "& h5": {
    width: 70,
    margin: "0 37px 0 0 !important",
  },
  "& h6": {
    margin: "3px 0 !important",
  },
  "& a": {
    textDecoration: "none",
  },
}));

export const TableContainer = styled("div")({
  marginTop: 50,
});

export const Name = styled("span")(() => ({
  textTransform: "capitalize",
}));

export const EditAccountButtonWrapper = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  cursor: "pointer",
});
