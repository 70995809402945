import {
  MEMBER,
  SPEND_VENUE,
  EARN_GROUP,
  TEMPO,
  GENERAL,
  PROGRAMME_MANAGER,
} from "../../constants/navRoutes";

export const linksText = {
  member: [
    { DASHBOARD: "home" },
    { EARN: "Earn Time Credits" },
    { SPEND: "Use Time Credits" },
    { GIFT: "Gift Time Credits" },
    { HISTORY: "My Time Credits history" },
    { HOW_IT_WORKS: "How Time Credits work" },
    { SETTINGS: "Account settings" },
  ],
  earnGroup: [
    { DASHBOARD: "dashboard" },
    { GIVE: "giveOutTC" },
    { ACCEPT_TC: "acceptTC", tempLogin: true },
    { MEMBERS: "members" },
    { ACTIVITIES: "earnSpendActivities" }, // check this
    { HISTORY: "tcHistoryShort" },
    { REQUEST: "getMoreTC" },
    { PROFILE: "recruitVolunteers" },
    { SETTINGS: "accountSettings" },
  ],
  earnGroupAdmin: [
    { DASHBOARD: "dashboard" },
    { GIVE: "Give out Time Credits" },
    { ACCEPT_TC: "Accept Time Credits", tempLogin: true },
    { MEMBERS: "members" },
    { ACTIVITIES: "Search Time Credit opportunities" },
    { HISTORY: "Time Credits history" },
    { REQUEST: "Get more Time Credits" },
    { PROFILE: "Recruit volunteers" },
    { SETTINGS: "Account settings" },
  ],
  spendVenue: [
    { ACCEPT_TC: "acceptTC", tempLogin: true },
    { INSIGHTS: "insights" },
    { OFFERS: "offers" },
    { SETTINGS: "accountSettings" },
  ],
  staff: [
    { DASHBOARD: "Dashboard" },
    { RECOGNITION_LISTING_REQUESTS: "Recognition listing requests" },
    { REQUESTS: "Time Credits requests" },
    { ORDERS: "Time Credits orders" },
    { MEMBERS: "Volunteers" },
    { EARN_GROUPS: "Earn groups" },
    { TK_GROUP_REQUESTS: "TK Group Approvals" },
    { SPEND_VENUES: "Venues" },
    { TRANSACTIONS: "Transactions" },
    { PROGRAMMES: "Programmes" },
    { ACCESS_PERMISSION: "Admin" },
  ],
  programmeManager: [
    { DASHBOARD: "Programme overview" },
    { REQUESTS: "Time Credits requests" },
    { EARN_GROUPS: "Manage Earn Groups" },
    { ACTIVITIES: "Search Time Credit opportunities" },
    { SETTINGS: "Account settings" },
  ],
};

export const linksTest = [
  { title: "test page", to: "/test" },
  { title: "test page2", to: "/test2" },
  { title: "landing page", to: "/" },
  { title: "storybook", to: "/storybook" },
];

export const memberLinks = [
  { title: "home", to: MEMBER.DASHBOARD },
  { title: "earnTC", to: GENERAL.EARN.replace(":search?", "") },
  { title: "spendTC", to: GENERAL.SPEND.replace(":search?", "") },
  { title: "giftTC", to: MEMBER.GIFT },
  { title: "myTCHistory", to: MEMBER.HISTORY },
  { title: "myGroups", to: MEMBER.MY_GROUPS },
  { title: "howTCsWork", to: GENERAL.WELCOME },
  { title: "accountSettings", to: MEMBER.SETTINGS },
];

export const earnGroupLinks = (isVerified, userId) =>
  linksText.earnGroup
    .map((link) => {
      const key = Object.keys(link)[0];
      const { tempLogin } = link;
      if (isVerified || tempLogin) {
        const to = EARN_GROUP[key].replace(":id", userId);
        return { title: link[key], to };
      }
      return undefined;
    })
    .filter((e) => !!e);

export const earnGroupAdminLinks = (isVerified, supervisorId) =>
  linksText.earnGroup
    .map((link) => {
      const key = Object.keys(link)[0];
      const { tempLogin } = link;
      if (isVerified || tempLogin) {
        const to = EARN_GROUP[key].replace(":id", supervisorId);
        return { title: link[key], to };
      }
      return undefined;
    })
    .filter((e) => !!e);

export const spendVenueLinks = (isVerified) =>
  linksText.spendVenue
    .map((link) => {
      const key = Object.keys(link)[0];
      const { tempLogin } = link;
      if (isVerified || tempLogin) {
        return { title: link[key], to: SPEND_VENUE[key] };
      }
      return undefined;
    })
    .filter((e) => !!e);

export const staffLinks = (role) =>
  linksText.staff
    .map((link) => {
      const key = Object.keys(link)[0];
      if (
        role === "tempoStaffUser" &&
        (key === "ACCESS_PERMISSION" || key === "ACCESS_PERMISSION")
      ) {
        return null;
      }
      if (role === "tempoStaffUser" && key === "ORDERS") {
        return null;
      }
      return { title: link[key], to: TEMPO[key] };
    })
    .filter((i) => !!i);

export const programmeManagerLinks = () =>
  linksText.programmeManager.map((link) => {
    const key = Object.keys(link)[0];
    return { title: link[key], to: PROGRAMME_MANAGER[key] };
  });
