const keys = {
  FEMALE: "Female",
  MALE: "Male",
  NON_BINARY: "Non-Binary",
  TRANS: "Trans*",
  PREFER_NOT_TO_SAY: "Prefer not to say",
};

const keysWelsh = {
  FEMALE: "Benywaidd",
  MALE: "Gwrywaidd",
  NON_BINARY: "Anneuaidd",
  TRANS: "Trawsryweddol*",
  PREFER_NOT_TO_SAY: "Gwell gennyf beidio ag ateb",
};

const genderValuesMapWelsh = {};
Object.keys(keys).forEach((e) => {
  genderValuesMapWelsh[keys[e]] = keysWelsh[e];
});

const genderDropDownEnglish = Object.values(keys).map((e) => ({
  label: e,
  value: e,
}));

const genderDropDownWelsh = Object.entries(keysWelsh).map(([key, value]) => ({
  label: value,
  value: keys[key],
}));

export {
  keys,
  keysWelsh,
  genderDropDownEnglish,
  genderDropDownWelsh,
  genderValuesMapWelsh,
};
