import {
  GET_ACTIVE_APPROVAL_REQUESTS_LOADING,
  GET_ACTIVE_APPROVAL_REQUESTS_SUCCESS,
  GET_ACTIVE_APPROVAL_REQUESTS_FAIL,
  GET_PREV_APPROVAL_REQUESTS_LOADING,
  GET_PREV_APPROVAL_REQUESTS_SUCCESS,
  GET_PREV_APPROVAL_REQUESTS_FAIL,
} from "../../../../redux/types";

const initState = {
  activeRequests: [],
  activeLoading: true,
  activeError: {},
  prevRequests: [],
  prevLoading: true,
  prevError: {},
};

const RecognitionListingRequestsReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ACTIVE_APPROVAL_REQUESTS_LOADING:
      return {
        ...state,
        activeLoading: payload,
      };

    case GET_ACTIVE_APPROVAL_REQUESTS_SUCCESS:
      return {
        ...state,
        activeRequests: payload,
        activeLoading: false,
        activeError: {},
      };
    case GET_ACTIVE_APPROVAL_REQUESTS_FAIL:
      return {
        ...state,
        activeRequests: [],
        activeError: payload,
      };
    case GET_PREV_APPROVAL_REQUESTS_LOADING:
      return {
        ...state,
        prevLoading: payload,
      };

    case GET_PREV_APPROVAL_REQUESTS_SUCCESS:
      return {
        ...state,
        prevRequests: payload,
        prevLoading: false,
        prevError: {},
      };
    case GET_PREV_APPROVAL_REQUESTS_FAIL:
      return {
        ...state,
        prevRequests: [],
        prevError: payload,
      };

    default:
      return state;
  }
};

export default RecognitionListingRequestsReducer;
