import React from "react";
import { connect } from "react-redux";
import { Switch } from "react-router-dom";

// CONSTANTS
import { MEMBER } from "../../constants/navRoutes";
import { roles } from "../../constants";

// MEMBER COMPONENTS
import Transactions from "./Transactions";
import InviteByGroupSuccess from "./InviteByGroupSuccess";
import GiftCredits, { GiftSuccess, GiftReceived } from "./GiftTimeCredits";
import MyGroups from "./MyGroups";

// MULTI-USE COMPONENTS
import Dashboard from "../Dashboard";

// OTHER ROUTERS
import ErrorRouter from "../ErrorRoutes";

// COMMON
import Route from "../../components/Route";

const MemberRouter = ({ isMobile }) => (
  <Switch>
    <Route
      isPrivate
      exact
      path={MEMBER.DASHBOARD}
      Component={Dashboard}
      isMobile={isMobile}
      layout="general"
      navbar
      footer
      header
      allowedRoles={[roles.MEMBER]}
    />
    <Route
      isPrivate
      exact
      path={MEMBER.HISTORY}
      Component={Transactions}
      isMobile={isMobile}
      type="all"
      sidebar
      footer
      header
      navbar
      allowedRoles={[roles.MEMBER]}
    />

    <Route
      isPrivate
      exact
      path={MEMBER.HISTORY_SINGLE}
      Component={Transactions}
      isMobile={isMobile}
      type="single"
      sidebar
      footer
      header
      navbar
      allowedRoles={[roles.MEMBER]}
    />

    {/* MEMBER ROUTES */}

    <Route
      exact
      isPrivate
      path={MEMBER.INVITED_BY_GROUP_SUCCESS}
      Component={InviteByGroupSuccess}
      layout="splitScreen"
      side="left"
      color="blue"
      allowedRoles={[roles.MEMBER]}
    />

    <Route
      exact
      isPrivate
      path={MEMBER.GIFT}
      Component={GiftCredits}
      navbar
      sideBar
      allowedRoles={[roles.MEMBER]}
    />

    <Route
      exact
      isPrivate
      path={MEMBER.GIFT_SUCCESS}
      Component={GiftSuccess}
      navbar
      sideBar
      allowedRoles={[roles.MEMBER]}
    />

    <Route
      exact
      path={MEMBER.RECEIVED_GIFT_SUCCESS}
      Component={GiftReceived}
      navbar
      success
      layout="splitScreen"
      side="right"
      allowedRoles={[roles.MEMBER]}
      isPrivate
    />

    <Route
      exact
      path={MEMBER.MY_GROUPS}
      Component={MyGroups}
      navbar
      allowedRoles={[roles.MEMBER]}
      isPrivate
    />

    {/* ERROR ROUTES */}
    <ErrorRouter />
  </Switch>
);

const mapStateToProps = (state) => ({
  isMobile: state.ui.isMobile,
});

export default connect(mapStateToProps)(MemberRouter);
