import {
  DUPLICATE_SPEND_ACTIVITY_LOADING,
  DUPLICATE_SPEND_ACTIVITY_SUCCESS,
  DUPLICATE_SPEND_ACTIVITY_FAIL,
  RESET_DUPLICATE_SPEND_ACTIVITY_STATE,
} from "../../../../redux/types";

const initState = {
  loading: false,
  error: null,
  success: null,
  createdSpendActivity: null,
};

const duplicateSpendActivity = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case DUPLICATE_SPEND_ACTIVITY_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
        createdSpendActivity: null,
      };
    case DUPLICATE_SPEND_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
        createdSpendActivity: payload,
      };
    case DUPLICATE_SPEND_ACTIVITY_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        success: null,
        createdSpendActivity: null,
      };
    case RESET_DUPLICATE_SPEND_ACTIVITY_STATE:
      return { ...initState };
    default:
      return state;
  }
};

export default duplicateSpendActivity;
