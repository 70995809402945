import React, { useCallback } from "react";
import { connect } from "react-redux";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../../utils/theme";

import { Row, Col } from "../../components/Grid";
import { Checkbox } from "../../components/Inputs";
import Button from "../../components/Button";

import * as S from "./style";
import t from "../../utils/helpers/translator";

import HowToSpend from "./HowToSpend";
import { roles } from "../../constants";

const Step4 = ({
  welshVersion,
  edit,
  data = {},
  setState,
  handleNext,
  loading,
  startUpload,
  errors,
  language,
  userRole,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleCheckbox = useCallback(({ target: { name, checked } }) => {
    switch (name) {
      case "turnUpAtVenue":
        return setState((_) => ({
          ..._,
          turnUpAtVenue: checked,
        }));

      case "callToBook":
        return setState((_) => ({
          ..._,
          callToBook: checked,
        }));

      case "isLive":
        return setState((_) => ({
          ..._,
          isLive: checked,
        }));

      case "featuredAt":
        return setState((_) => ({
          ..._,
          featuredAt: checked ? new Date() : 0,
        }));

      case "onlineCodesToBook":
        return setState((_) => ({
          ..._,
          onlineCodesToBook: checked,
        }));

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isTempoStaff = [
    roles.TEMPO_STAFF_SUPER,
    roles.TEMPO_STAFF_ADMIN,
    roles.TEMPO_STAFF_USER,
  ].includes(userRole);

  const handleText = useCallback(({ target: { name, value } }) => {
    switch (name) {
      case "callExtraDetails":
        return setState((_) => ({
          ..._,
          callExtraDetails: value,
        }));
      case "useCodesLink":
        return setState((_) => ({
          ..._,
          useCodesLink: value,
        }));
      case "useCodesDetails":
        return setState((_) => ({
          ..._,
          useCodesDetails: value,
        }));

      case "callExtraDetailsWelsh":
        return setState((_) => ({
          ..._,
          callExtraDetailsWelsh: value,
        }));
      case "useCodesDetailsWelsh":
        return setState((_) => ({
          ..._,
          useCodesDetailsWelsh: value,
        }));

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCodes = useCallback(({ target: { value } }) => {
    if (value.trim() === "" || value.trim() === ",") {
      return setState((_) => ({
        ..._,
        bookingCodes: [],
      }));
    }

    const codeArr = value.split(",");

    return setState((_) => ({
      ..._,
      bookingCodes: codeArr,
      isBookingCodesUpdated: true,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HowToSpend
        turnUpAtVenue={data.turnUpAtVenue}
        callToBook={data.callToBook}
        callExtraDetails={data.callExtraDetails}
        onlineCodesToBook={data.onlineCodesToBook}
        bookingCodes={data.bookingCodes}
        useCodesLink={data.useCodesLink}
        useCodesDetails={data.useCodesDetails}
        handleText={handleText}
        handleCheckbox={handleCheckbox}
        handleCodes={handleCodes}
        costVaries={data.costVaries}
        isMobile={isMobile}
        errors={errors}
        edit={edit}
        bookingCodesUsed={data.bookingCodesUsed}
        language={language}
        // Welsh
        welshVersion={welshVersion}
        callExtraDetailsWelsh={data.callExtraDetailsWelsh}
        useCodesDetailsWelsh={data.useCodesDetailsWelsh}
      />
      {!data.hideIsLiveCheckbox && (
        <Row mb={5}>
          <Col w={[4, 8, 8]}>
            <Checkbox
              value={data.isLive}
              setValue={handleCheckbox}
              mt="10"
              label={t("makeActivityLive", language)}
              name="isLive"
              size={isMobile ? "normal" : "medium"}
              error={errors.isLive}
            />
          </Col>
        </Row>
      )}
      {!data.hideIsLiveCheckbox && isTempoStaff && (
        <Row mb={5}>
          <Col w={[4, 8, 8]}>
            <Checkbox
              value={data.featuredAt}
              setValue={handleCheckbox}
              mt="10"
              label={t("makeActivityFeatured", language)}
              name="featuredAt"
              size={isMobile ? "normal" : "medium"}
              error={errors.featuredAt}
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col w={[4, 6, 4]}>
          <Button
            primary
            size="l"
            handleClick={() => handleNext(3)}
            loading={loading || startUpload}
          >
            {edit ? t("saveChanges", language) : t("createActivity", language)}
          </Button>
        </Col>
        {Object.keys(errors).filter((key) => errors[key]).length > 0 && (
          <Col w={[4, 12, 12]} style={{ marginTop: 20 }}>
            <S.Error>{t("activityFormStepError", language)}</S.Error>
          </Col>
        )}
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  language: state.auth.language,
});
export default connect(mapStateToProps, null)(Step4);
