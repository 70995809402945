import axios from "axios";
import handleError from "./handle-errors";

const GIFTS_BASE = "/gifts";

const getGifts = async ({ category = "given", options } = {}) => {
  try {
    const { data } = await axios.get(GIFTS_BASE, {
      params: {
        category,
      },
    });

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const createGift = async ({ email, tc, type, toUserId, options } = {}) => {
  try {
    const { data } = await axios.post(GIFTS_BASE, {
      email,
      tc,
      type,
      toUserId,
    });

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const cancelGiftById = async ({ id, options } = {}) => {
  try {
    const { data } = await axios.patch(`${GIFTS_BASE}/${id}`, {
      actionType: "cancel",
    });

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getGiftById = async ({ id, options } = {}) => {
  try {
    const { data } = await axios.get(`${GIFTS_BASE}/${id}`);

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export { getGifts, createGift, cancelGiftById, getGiftById };
