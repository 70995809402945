import { styled } from "@material-ui/core/styles";

export const DeleteWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  paddingBottom: theme.spacing(1),

  [theme.breakpoints.tablet]: {
    left: "10px",
  },
}));

export const AccountWrapper = styled("section")(({ theme }) => ({
  position: "relative",
  paddingBottom: theme.spacing(5),
  [theme.breakpoints.tablet]: {
    paddingBottom: theme.spacing(6),
  },
}));

export const Error = styled("p")(({ theme }) => ({
  ...theme.typography.placeholder,
  color: theme.colors.pink,
}));

export const PopperContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  height: "100%",
  width: "100%",
  padding: "0 30px",
});
