export const formateDropDownList = (users = []) => {
  const tempLabels = [];
  const showedRoles = {
    earnGroupAdmin: "admin",
  };
  const formattedUsers = users.map((user) => {
    const { userId, role, firstName, lastName, earnGroupName } = user;
    let label = `${firstName} ${lastName}`;
    if (role === "earnGroup" || role === "earnGroupAdmin") {
      label = earnGroupName;
    }

    label = `${label}${showedRoles[role] ? ` (${showedRoles[role]})` : ""}`;
    tempLabels.push(label);

    return { userId, role, label, value: label };
  });
  return formattedUsers;
};
