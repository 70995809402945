import { fields, createSchema, validate as _validate } from "..";

const {
  role,
  agreedOnTerms,
  organisationName,
  organisationType,
  focusFields,
  regionCountry,
  region,
  localAuthority,
} = fields;

const earnGroup = createSchema({
  role,
  name: organisationName,
  organisationType,
  focusFields,
  regionCountry,
  region,
  localAuthority,
  agreedOnTerms,
});

const all = createSchema({
  role,
  agreedOnTerms,
  name: organisationName,
  organisationType,
  focusFields,
  regionCountry,
  region,
  localAuthority,
});

const validate = (data, isPartial) => {
  if (isPartial) {
    return _validate(all, data);
  }

  return _validate(earnGroup, data);
};

export default validate;
