import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";

import { connect } from "react-redux";

import * as S from "./style";
import Image from "../../../../components/Image";
import * as T from "../../../../components/Typograpy";
import Button, { Regular } from "../../../../components/Button";
import { Col, Row } from "../../../../components/Grid";
import { Dropdown } from "../../../../components/Inputs";
import Table from "../../../../components/FilterTable";
import PopQuestion from "../../../../components/PopQuestion";

import { getInsights } from "../insights.action";
import Loading from "../../../../components/Loading";
import Notification from "../../../../components/Notification";
import { GENERAL, TEMPO } from "../../../../constants/navRoutes";
import { tempoRoles } from "../../../../constants/roles";
import numberWithCommas from "../../../../utils/helpers/numberWithCommas";

import * as actions from "./actions";
import calculatePriceDetails from "../../../../utils/helpers/calculate-price-details";
import FilterByDate from "../../../../components/FilterByDate";
import Modal from "../../../../components/Modal";

import { customDateCol } from "../../../../components/Table/config/reusableColumns";

import MarkOrderAsPaid from "./MarkOrderAsPaid";
import CancelOrder from "./CancelOrder";

const RenderSelect = ({ id }) => {
  const history = useHistory();
  const onSelectAction = (selected) => {
    if (selected.value === "paid") {
      return history.push(`${TEMPO.MARK_ORDER_AS_PAID.replace(":id", id)}`);
    }

    if (selected.value === "cancel") {
      return history.push(`${TEMPO.CANCEL_ORDER.replace(":id", id)}`);
    }
  };

  return (
    <Dropdown
      placeholder="Select..."
      options={[
        { value: "paid", label: "Mark as paid" },
        { value: "cancel", label: "Cancel" },
      ]}
      customSelectFunc={(e) => onSelectAction(e)}
      size="small"
    />
  );
};

const tempoMembersKeyInfo = {
  columns: [
    customDateCol({ title: "date", field: "createdAt" }),
    {
      title: "invoice number",
      field: "invoiceNumber",
      disableClick: true,
      render: (rowData) => (
        <Link to={GENERAL.ORDER_INVOICE.replace(":id", rowData?.id)}>
          <T.LinkB14 color="blue">
            {rowData?.invoiceNumber?.toUpperCase()}
          </T.LinkB14>
        </Link>
      ),
    },
    {
      title: (
        <div style={{ position: "relative" }}>
          name
          <PopQuestion>
            <T.Body16R color="gray3">
              This is the name of the group who made the order request
            </T.Body16R>
          </PopQuestion>
        </div>
      ),
      field: "earnGroup.name",
      disableClick: true,
      render: (rowData) => (
        <Link
          to={TEMPO.EARN_GROUP_SINGLE.replace(":id", rowData?.earnGroup?.id)}
        >
          <T.LinkB14 color="blue">
            {rowData?.earnGroup?.name?.toUpperCase()}
          </T.LinkB14>
        </Link>
      ),
    },
    {
      title: "Time Credits",
      field: "credits",
      type: "numeric",
      render: (rowData) => (
        <T.Body14R>{numberWithCommas(rowData.credits)}</T.Body14R>
      ),
    },
    {
      title: "price",
      disableClick: true,
      render: (rowData) => {
        const { subTotalPriceFormatted, totalVatCostFormatted } =
          calculatePriceDetails({
            vat: rowData.vat,
            credits: rowData.credits,
            price: rowData.price,
          });

        return (
          <>
            <T.Body14B color="black">{subTotalPriceFormatted}</T.Body14B>
            <T.Body14R color="black">+ {totalVatCostFormatted} VAT</T.Body14R>
          </>
        );
      },
    },
    {
      title: "Action",
      field: "action",
      disableClick: true,
      render: (rowData) => <RenderSelect id={rowData.id} />,
    },
  ],
};

const historyOrders = {
  columns: [
    customDateCol({ title: "request date", field: "createdAt" }),
    customDateCol({ title: "PAYMENT DATE", field: "paymentDate" }),
    {
      title: "invoice number",
      field: "invoiceNumber",
      render: (rowData) => (
        <Link to={GENERAL.ORDER_INVOICE.replace(":id", rowData?.id)}>
          <T.LinkB14 color="blue">
            {rowData?.invoiceNumber?.toUpperCase()}
          </T.LinkB14>
        </Link>
      ),
    },
    {
      title: (
        <div style={{ position: "relative" }}>
          name
          <PopQuestion>
            <T.Body16R color="gray3">
              This is the name of the group who made the order request
            </T.Body16R>
          </PopQuestion>
        </div>
      ),
      field: "earnGroup.name",
      disableClick: true,
      render: (rowData) => (
        <Link
          to={TEMPO.EARN_GROUP_SINGLE.replace(":id", rowData?.earnGroup?.id)}
        >
          <T.LinkB14 color="blue">
            {rowData?.earnGroup?.name.toUpperCase()}
          </T.LinkB14>
        </Link>
      ),
    },
    {
      title: "Time Credits",
      field: "credits",
      type: "numeric",
      render: (rowData) => (
        <T.Body14R>{numberWithCommas(rowData.credits)}</T.Body14R>
      ),
    },
    {
      title: "price",
      disableClick: true,
      render: (rowData) => {
        const { subTotalPriceFormatted, totalVatCostFormatted } =
          calculatePriceDetails({
            vat: rowData.vat,
            credits: rowData.credits,
            price: rowData.price,
          });

        return (
          <>
            <T.Body14B color="black">{subTotalPriceFormatted}</T.Body14B>
            <T.Body14R color="black">+ {totalVatCostFormatted} VAT</T.Body14R>
          </>
        );
      },
    },
    {
      title: "payment method",
      customFilterAndSearch: (term, rowData) => {
        let text = "";
        if (rowData.status === "CANCELLED") {
          text += "Cancelled";
        }
        if (!rowData.cardPaymentId) {
          text += "BACS";
        }

        if (rowData.cardPaymentId) {
          text += "CARD";
          text += `ID: ${rowData.braintreeTransactionId}`;
        }
        text = text.toLowerCase();
        return text.includes(term.toLowerCase());
      },
      render: (rowData) => {
        if (rowData.status === "CANCELLED") return "Cancelled";
        if (!rowData.cardPaymentId) return "BACS";
        if (rowData.cardPaymentId) {
          return (
            <>
              <T.Body14B color="black">CARD</T.Body14B>
              <T.Body14R color="black">
                ID: {rowData.braintreeTransactionId}
              </T.Body14R>
            </>
          );
        }
      },
    },
  ],
};

const Orders = ({
  pageError,
  getOrders,
  loading,
  updatedSuccessfully,
  pendingOrders,
  processedOrders,
  role,
  getInsightsAction,
}) => {
  const [showNotification, setShowNotification] = useState(false);

  const [dates, setDates] = useState({ startDate: null, endDate: null });
  const startDateValue = dates?.startDate?.valueOf();
  const endDateValue = dates?.endDate?.valueOf();
  const [filterDates, setFilterDates] = useState({
    startDate: null,
    endDate: null,
  });
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  useEffect(() => {
    getInsightsAction({ category: "orders" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (updatedSuccessfully) {
      setShowNotification(true);
    }
  }, [updatedSuccessfully]);

  useEffect(() => {
    if (!isOpenFilter) {
      getOrders({
        startDate: dates.startDate,
        endDate: dates.endDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateValue, endDateValue]);

  if (loading) {
    return <Loading size={28} color="cyan" center fullPage />;
  }

  return (
    <>
      <Row jc="space-between">
        <Col w={[4, 8, 8]}>
          <T.H40Caps color="primary" style={{ margin: 0 }}>
            Time credits orders
          </T.H40Caps>
        </Col>
        <Col w={[4, 4, 4]}>
          <S.ImageWrapper>
            <Image image="gallery" alt="gallery" height={210} width={220} />
          </S.ImageWrapper>
        </Col>
      </Row>

      <S.TableContainer>
        <S.BtnContainer>
          {[
            tempoRoles.TEMPO_STAFF_ADMIN,
            tempoRoles.TEMPO_STAFF_SUPER,
          ].includes(role) && (
            <Regular
              primary
              bgColor="blue"
              customWidth="185px"
              to={TEMPO.MANAGE_PACKAGES}
              loading={loading}
              w="50%"
              mr="10"
              type="submit"
              disabled={loading}
            >
              Manage Pricing
            </Regular>
          )}
          <S.CsvExport>
            <Button outline onClick={() => setIsOpenFilter(true)}>
              Export Data
            </Button>
          </S.CsvExport>
        </S.BtnContainer>
        <S.FilterContainer>
          <T.Body16B color="midnight" m="8">
            Filter by order date
          </T.Body16B>
          <FilterByDate dates={dates} setDates={setDates} />
          <T.Body16B color="midnight" m="8">
            Search
          </T.Body16B>
        </S.FilterContainer>
        {pageError?.message && (
          <Col w={[4, 12, 12]}>
            <S.Error> {pageError?.message}</S.Error>
          </Col>
        )}

        {/* pendingOrders processedOrders */}
        <Table
          isLoading={loading}
          color="blue"
          tabbedData={[
            {
              tabText: "pending",
              data: pendingOrders,
              columns: tempoMembersKeyInfo.columns,
            },
            {
              tabText: "history",
              data: processedOrders,
              columns: historyOrders.columns,
            },
          ]}
        />
      </S.TableContainer>

      <Notification
        open={showNotification}
        setOpen={setShowNotification}
        message="ORDER UPDATED"
        duration={1500}
      />

      <Modal
        modalOpen={isOpenFilter}
        handleCloseModal={() => setIsOpenFilter(false)}
        onCancel={() => setIsOpenFilter(false)}
        custom
        width="690px"
      >
        <S.ModalContent>
          <T.H20 color="blue" mb={20}>
            Export data{" "}
          </T.H20>

          <T.Body16B color="midnight" m="8">
            Filter by date
          </T.Body16B>
          <FilterByDate dates={filterDates} setDates={setFilterDates} />
          <S.CsvExport
            href={`/api/csv/orders?startDate=${
              filterDates?.startDate?.format("YYYY-MM-DD") || null
            }&endDate=${filterDates?.endDate?.format("YYYY-MM-DD") || null}`}
          >
            <Button style={{ width: "200px", marginTop: "30px" }}>
              Export
            </Button>
          </S.CsvExport>
        </S.ModalContent>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ orders, auth }) => ({
  pendingOrders: orders.pendingOrders || [],
  processedOrders: orders.processedOrders || [],
  pageError: orders.error,
  loading: orders.loading,
  updatedSuccessfully: orders.updatedSuccessfully,
  role: auth.role,
});
const mapActionsToProps = {
  getInsightsAction: getInsights,
  getOrders: actions.getOrders,
};

export { MarkOrderAsPaid, CancelOrder };
export default connect(mapStateToProps, mapActionsToProps)(Orders);
