import * as T from "../../../components/Typograpy";
import {
  regionsDropDownEnglish,
  regionsDropDownWelsh,
  regionsValuesMapWelsh,
  organisationTypesListEnglishDropDown,
  organisationTypesListWelshDropDown,
  organisationTypesValuesMapWelsh,
  authoritiesEnglishDropDown,
  authoritiesWelshDropDown,
  authoritiesValuesMapWelsh,
  focusDropDownEnglish,
  focusDropDownWelsh,
  focusValuesMapWelsh,
} from "../../../constants/dropdDownData";

export const device = (isMobile) => (isMobile ? "mobile" : "desktop");

export const headlineComponents = {
  desktop: T.H40Caps,
  mobile: T.H30Caps,
};

export const subHeadlineComponents = {
  desktop: T.H22,
  mobile: T.H18,
};

export const labelComponents = {
  desktop: T.H16,
  mobile: T.H16,
};

export const fields = {
  firstName: {
    label: "First name",
    type: "text",
    placeholder: "Type first name here...",
    name: "firstName",
  },
  lastName: {
    label: "Last name",
    type: "text",
    placeholder: "Type last name here...",
    name: "lastName",
  },
  email: {
    label: "Email address",
    type: "email",
    placeholder: "Type email here...",
    name: "email",
  },
  password: {
    label: "Password",
    type: "password",
    placeholder: "Type your password here...",
    name: "password",
  },
  orgName: {
    label: "Name of your organisation",
    type: "text",
    placeholder: "Type here...",
    name: "organisationName",
  },
  region: {
    label: "Region",
    type: "dropdown",
    placeholder: "Region",
    name: "region",
    options: regionsDropDownEnglish,
    optionsWelsh: regionsDropDownWelsh,
    welshMap: regionsValuesMapWelsh,
  },

  orgType: {
    label: "Type of organisation",
    type: "dropdown",
    placeholder: "Type of organisation",
    name: "organisationType",
    options: organisationTypesListEnglishDropDown,
    optionsWelsh: organisationTypesListWelshDropDown,
    welshMap: organisationTypesValuesMapWelsh,
  },
  localAuthority: {
    label: "Local authority",
    type: "dropdown",
    placeholder: "Local authority",
    name: "localAuthority",
    options: authoritiesEnglishDropDown,
    optionsWelsh: authoritiesWelshDropDown,
    welshMap: authoritiesValuesMapWelsh,
  },
  focus: {
    label: "What do you focus on",
    type: "select",
    placeholder: "Select as many as you like...",
    name: "focusFields",
    options: focusDropDownEnglish,
    optionsWelsh: focusDropDownWelsh,
    welshMap: focusValuesMapWelsh,
  },
  phone: {
    label: "Phone number",
    type: "tel",
    placeholder: "Type phone number here...",
    name: "phoneNumber",
  },
  phoneNumber: {
    label: "Phone number",
    type: "tel",
    placeholder: "Type phone number here...",
    name: "phoneNumber",
  },
};

export const initStates = {
  spendPartner: {
    email: "",
    password: "",
  },
  member: {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  },
  earnGroup: {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    organisationName: "",
    organisationType: "",
    region: "",
    regionCountry: "",
    localAuthority: "",
    focusFields: [],
    phoneNumber: "",
    showConfirmPasswordModal: false,
  },
};
