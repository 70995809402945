import { spendVenues } from "../../../api-calls";

import {
  GET_SPEND_VENUE_OFFERS_LOADING,
  GET_SPEND_VENUE_OFFERS_SUCCESS,
  GET_SPEND_VENUE_OFFERS_FAIL,
} from "../../../redux/types";

export const getSpendVenueOffers = (spendVenueId) => async (dispatch) => {
  dispatch({ type: GET_SPEND_VENUE_OFFERS_LOADING });
  try {
    const { data, error } = await spendVenues.getActivitiesBySpendVenueId({
      spendVenueId,
    });
    if (data) {
      dispatch({
        type: GET_SPEND_VENUE_OFFERS_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: GET_SPEND_VENUE_OFFERS_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_SPEND_VENUE_OFFERS_FAIL,
      payload: error,
    });
  }
};
