import React from "react";

import * as T from "../Typograpy";
import * as S from "./style";

import translate from "../../utils/helpers/translator";

const AboutVenueOrg = ({ title, text, m, accessibility, lang }) => (
  <S.AboutWrapper m={m}>
    {title && (
      <T.H18 color="pink" m="0 0 15px 0">
        {title}
      </T.H18>
    )}
    <T.Body16R color="gray3">{text}</T.Body16R>
    {accessibility && (
      <a
        href={
          accessibility.startsWith("http")
            ? accessibility
            : `http://${accessibility}`
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        <T.LinkB16 color="blue" style={{ margin: "20px 0" }}>
          {translate("viewAccessibility", lang)}
        </T.LinkB16>
      </a>
    )}
  </S.AboutWrapper>
);

export default AboutVenueOrg;
