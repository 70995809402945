import React from "react";

const Computer = ({ width, height, color, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 21"
    width={width}
    height={height}
    {...props}
  >
    <path
      d="M.633 14.438c0 .521.2 1.022.557 1.391.356.37.838.577 1.342.577h5.297L7.67 17.72H5.697a.622.622 0 00-.447.192.669.669 0 00-.186.464c0 .174.067.341.186.464.118.123.28.192.447.192h8.863c.168 0 .329-.069.447-.192a.669.669 0 00.186-.464.669.669 0 00-.186-.464.622.622 0 00-.447-.192h-1.974l-.158-1.313h5.297c.503 0 .986-.208 1.342-.577.356-.37.556-.87.557-1.392V12.47H.633v1.969zm9.495-.657c.126 0 .248.039.352.11a.652.652 0 01.233.295.678.678 0 01-.137.716.611.611 0 01-.69.142.639.639 0 01-.284-.242.673.673 0 01.079-.829.622.622 0 01.447-.192zM19.624 3.938c0-.522-.2-1.023-.557-1.392a1.868 1.868 0 00-1.342-.577H2.532c-.504 0-.986.208-1.342.577-.356.37-.557.87-.557 1.392v7.874h18.991V3.939z"
      fill={color || "currentColor"}
    />
  </svg>
);
export default Computer;
