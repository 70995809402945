import React from "react";

const FundraisingPromotion = ({ width, height, color, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    {...props}
  >
    <path
      d="M11.625 6.75v-.375a.922.922 0 00.75-.923.96.96 0 00-.75-.952h-.443a.189.189 0 01-.04-.375H12a.375.375 0 000-.75h-.375V3a.375.375 0 10-.75 0v.42a.941.941 0 00.307 1.83c.278 0 .462-.037.462.188a.176.176 0 01-.154.187h-.99a.375.375 0 000 .75h.375v.375a.375.375 0 10.75 0zM5.456 18.821c-.127-.086-.139-.071-.69-.071-.199 0-.199-.375 0-.375h.859a.375.375 0 100-.75H5.25v-.375a.375.375 0 00-.75 0v.42a.941.941 0 00.266 1.83h.349c.199 0 .202.375 0 .375h-.99a.375.375 0 100 .75H4.5V21a.375.375 0 00.75 0v-.375a.945.945 0 00.206-1.804z"
      fill={color || "currentColor"}
    />
    <path
      d="M22.875 15v-.75a1.125 1.125 0 00-1.125-1.125H21a.375.375 0 000 .75h.75a.375.375 0 01.375.375V15a.375.375 0 01-.375.375H16.5a.375.375 0 01-.375-.375v-.75a.375.375 0 01.375-.375h1.125a.375.375 0 100-.75H16.5a1.125 1.125 0 00-1.125 1.125c0 .787-.023.87.068 1.125h-3.818v-1.286a2.625 2.625 0 003.206-.825c1.425-1.947 1.433-1.875 1.399-2.093-.067-.435-1.211-.862-2.1-.724-1.283.195-1.721 1.043-2.505 2.112v-3.96a3.75 3.75 0 10-.75 0v3.63l-.544-.75c-1.267-1.73-3.72-.98-3.817-.308-.034.21-.019.161 1.399 2.093a2.624 2.624 0 002.962.93v1.18c-4.5 0-4.387-.067-4.777.2a3.75 3.75 0 00-4.737 2.298c-1.241 3.402 2.764 6.503 5.764 4.253a1.125 1.125 0 001.058.75h12.442a1.125 1.125 0 001.125-1.125V21c0-.409-.161-.375 0-.375a1.125 1.125 0 001.125-1.125v-.75a1.125 1.125 0 00-1.192-1.125c.097-.278.067-.428.067-1.125 0-.409-.161-.375 0-.375A1.125 1.125 0 0022.875 15zM8.516 12.817l-1.087-1.48a1.876 1.876 0 012.299.595l1.087 1.5a1.875 1.875 0 01-2.299-.615zm4.5-.885a1.875 1.875 0 012.299-.596l-1.088 1.481a1.875 1.875 0 01-2.298.597l1.087-1.482zm-4.458-5.77a3.116 3.116 0 01-.263-1.677 3 3 0 11.263 1.676zm-6.375 14.25a3.116 3.116 0 01-.263-1.662 3 3 0 11.263 1.676v-.015zm5.647 1.44l-.082-.305a3.75 3.75 0 00-.623-5.422c9.686 0 9-.056 9.169.146.168.203.18-.09-1.395 5.58a.375.375 0 01-.375.274H8.149a.375.375 0 01-.319-.274zM21 21v.75a.375.375 0 01-.375.375c-5.599 0-5.018.03-4.991-.071l.397-1.43h4.594A.375.375 0 0121 21zm1.125-2.25v.75a.375.375 0 01-.375.375h-5.509l.416-1.5h5.093a.375.375 0 01.375.375zM21 16.5v.75a.375.375 0 01-.375.375h-3.75c.206-.75.375-1.058.199-1.5h3.551A.375.375 0 0121 16.5z"
      fill={color || "currentColor"}
    />
    <path
      d="M19.125 13.875h.375a.375.375 0 000-.75h-.375a.375.375 0 000 .75z"
      fill={color || "currentColor"}
    />
  </svg>
);
export default FundraisingPromotion;
