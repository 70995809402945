import React from "react";

const Eye = ({ width, height, color, ...props }) => (
  <svg
    width={width}
    height={height}
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9836 5.25C8.33018 5.25 4.58253 7.36453 1.63221 11.5936C1.548 11.7156 1.50185 11.8599 1.4996 12.0082C1.49735 12.1564 1.53909 12.302 1.61956 12.4266C3.88643 15.975 7.58393 18.75 11.9836 18.75C16.3355 18.75 20.1089 15.9666 22.38 12.4102C22.4586 12.2881 22.5004 12.1459 22.5004 12.0007C22.5004 11.8555 22.4586 11.7134 22.38 11.5912C20.1038 8.07562 16.3027 5.25 11.9836 5.25Z"
      stroke={color || "currentColor"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z"
      stroke={color || "currentColor"}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);
export default Eye;
