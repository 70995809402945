import { fields, createSchema, validate as _validate } from "..";

import { roles } from "../../constants";

const { MEMBER, EARN_GROUP, EARN_GROUP_ADMIN, PROGRAMME_MANAGER } = roles;
const {
  role,
  email,
  password,
  firstName,
  lastName,
  birthDate,
  phoneNumber,
  id,
  agreedOnTerms,
  confirmPermission,
  organisationName,
  organisationType,
  focusFields,
  regionCountry,
  region,
  localAuthority,
  token,
  postcode,
  agreedAge,
} = fields;

// member without invitation/gift
const member = createSchema({
  role,
  email,
  password,
  firstName,
  lastName,
  agreedOnTerms,
  agreedAge,
  postcode,
});

const invitedMemberToEarnGroup = createSchema({
  role,
  email,
  password,
  earnGroupUserId: id,
  firstName,
  lastName,
  agreedOnTerms,
  agreedAge,
  postcode,
});

const giftedMember = createSchema({
  role,
  email,
  password,
  firstName,
  lastName,
  agreedOnTerms,
  giftToken: token,
  agreedAge,
});

const offlineMember = createSchema({
  role,
  firstName,
  lastName,
  birthDate,
  phoneNumber,
  agreedOnTerms,
  confirmPermission,
  postcode,
});

const earnGroup = createSchema({
  role,
  email,
  password,
  firstName,
  lastName,
  name: organisationName,
  organisationType,
  focusFields,
  regionCountry,
  region,
  localAuthority,
  phoneNumber,
  agreedOnTerms,
});

const earnGroupAdmin = createSchema({
  role,
  email,
  password,
  earnGroupUserId: id,
  firstName,
  lastName,
  agreedOnTerms,
  token,
});

const staff = createSchema({
  email,
  password,
  firstName,
  lastName,
  token,
});

const extProgManager = createSchema({
  email,
  password,
  firstName,
  lastName,
  token,
});

const all = createSchema({
  role,
  email,
  password,
  earnGroupUserId: id,
  firstName,
  lastName,
  agreedOnTerms,
  birthDate,
  phoneNumber,
  name: organisationName,
  organisationType,
  focusFields,
  regionCountry,
  region,
  localAuthority,
  contactNumber: phoneNumber,
  postcode,
});

const validate = (data, isPartial) => {
  if (isPartial) {
    return _validate(all, data);
  }
  if (data.role === MEMBER && data.isOffline)
    return _validate(offlineMember, data);

  if (data.role === MEMBER && !data.isOffline && data.giftToken) {
    return _validate(giftedMember, data);
  }

  if (data.role === MEMBER && !data.isOffline && data.earnGroupUserId) {
    return _validate(invitedMemberToEarnGroup, data);
  }

  if (data.role === MEMBER) {
    return _validate(member, data);
  }

  if (data.role === EARN_GROUP) return _validate(earnGroup, data);
  if (data.role === EARN_GROUP_ADMIN) return _validate(earnGroupAdmin, data);
  if (data.role === "staff") return _validate(staff, data);
  if (data.role === PROGRAMME_MANAGER) return _validate(extProgManager, data);
};

export default validate;
