import { styled } from "@material-ui/core/styles";

export const Aside = styled("aside")(({ theme }) => ({
  backgroundColor: theme.colors.midnight,
  width: 320,
  height: "100vh",
  overflow: "auto",
  position: "absolute",
  zIndex: 999,
  top: 0,
  display: "none",

  "&::-webkit-scrollbar": {
    display: "none",
  },

  "-ms-overflow-style": "none",
  "scrollbar-width": "none",

  [theme.breakpoints.tablet]: {
    display: "block",
  },

  "@media (min-aspect-ratio: 10/5) and (max-height: 700px)": {
    "& *": {
      zoom: "97%",
    },
  },
}));

export const List = styled("ul")(({ theme, lang }) => ({
  padding: 0,
  paddingTop: 0,
  margin: 0,
  listStyle: "none",
  "& li": {
    margin: lang === "english" ? "20px 0" : "15px 0",
  },
  paddingBottom: 50,
  [theme.breakpoints.tablet]: {
    paddingTop: 20,
  },
}));

export const SiblingList = styled(List)(() => ({
  paddingBottom: 30,
  paddingTop: 0,
}));

export const Balance = styled("div")(({ theme }) => ({
  padding: 40,
  paddingBottom: 0,
  color: "white",

  "& p": {
    lineHeight: "15px",
  },

  [theme.breakpoints.tablet]: {
    "& p": {
      fontSize: "1rem",
      lineHeight: "20px",
    },

    "& .balance": {
      fontSize: "1.125rem",
      color: "white",
      fontWeight: "900",
      textTransform: "uppercase",
    },
  },
}));

// Top nav

export const TopNav = styled("div")(({ theme }) => ({
  width: "100vw",
  height: 60,
  backgroundColor: theme.colors.midnight,
  display: "flex",
  alignItems: "center",
  padding: 15,
  justifyContent: "space-between",
  zIndex: "999",

  [theme.breakpoints.tablet]: {
    display: "none",
  },
  [theme.breakpoints.mobileXL]: {
    padding: "20px",
  },
}));

export const MenuBtn = styled("button")(() => ({
  background: "none",
  border: "none",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  outline: "none",
  padding: "0",
}));

export const CloseBtn = styled("button")(() => ({
  background: "none",
  border: "none",
  outline: "none",
  position: "absolute",
  cursor: "pointer",
  top: 15,
  right: 15,
}));

export const MobileMenu = styled("nav")(({ theme }) => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: theme.colors.midnight,
  display: "flex",
  zIndex: "9999",
  height: "100vh",
}));

export const MenuContent = styled("div")(({ theme }) => ({
  marginTop: "50px",
  overflowY: "auto",
  [theme.breakpoints.mobileXL]: {
    marginTop: "80px",
  },
}));

export const BalanceContainer = styled("div")(({ theme }) => ({
  "& div": {
    padding: 0,
  },
  paddingLeft: 15,
  borderLeft: `4px ${theme.colors.pink} solid`,
}));

export const LogOutButton = styled("button")(({ theme }) => ({
  background: "none",
  outline: "none",
  border: "none",
  cursor: "pointer",
  color: theme.colors.white,
  padding: "0 40px",
}));

export const Divider = styled("hr")(({ theme }) => ({
  color: theme.colors.cyan,
  backgroundColor: theme.colors.cyan,
  margin: "0 40px",
  width: 125,
  border: "1px solid",
}));
