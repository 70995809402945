import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";

import * as S from "./style";
import Image from "../../../../components/Image";
import * as T from "../../../../components/Typograpy";
import Table from "../../../../components/FilterTable";
import Dropdown from "../../../../components/Inputs/Dropdown";
import WarningMessage from "../../../../components/WarningMessage";

import { TEMPO } from "../../../../constants/navRoutes";

import * as actions from "./actions";
import Modal from "../../../../components/Modal";

const TkGroupRequests = ({
  tkGroupRequests,
  loading,
  getTkGroupRequests,
  approveRejectGroup,
  approveRejectLoading,
  newGroupUserId,
  approveRejectError,
  error,
}) => {
  const history = useHistory();
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [
    isRejectionConfirmationModalOpen,
    setIsRejectionConfirmationModalOpen,
  ] = useState(false);

  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [groupToUpdate, setGroupToUpdate] = useState({});
  const [isNewGroup, setIsNewGroup] = useState(false);

  const requestUpdate = (rowData, extraDetails) => {
    setGroupToUpdate(rowData);
    if (extraDetails.value === "approve") {
      const status = "approved";
      const userId = rowData?.earnGroup?.userId;
      setIsNewGroup(!userId);
      approveRejectGroup({
        id: rowData?.id,
        userId,
        name: rowData?.name,
        email: rowData?.email,
        status,
      });
      setIsApproveModalOpen(true);
    } else if (extraDetails.value === "reject") {
      setIsRejectModalOpen(true);
    }
  };

  const tkRequests = [
    {
      title: "EARN GROUP",
      disableClick: true,
      render: (rowData) =>
        rowData?.earnGroup?.userId ? (
          <Link
            to={TEMPO.EARN_GROUP_SINGLE.replace(
              ":id",
              rowData?.earnGroup?.userId,
            )}
          >
            <T.LinkB14 color="blue">
              {rowData?.earnGroup?.name?.toUpperCase()}
            </T.LinkB14>
          </Link>
        ) : (
          <T.LinkB14>{rowData?.name?.toUpperCase()}</T.LinkB14>
        ),
    },
    {
      title: "Programme",
      field: "programmeName",
      disableClick: true,
      render: (rowData) =>
        rowData.programmeId ? (
          <Link to={TEMPO.PROGRAMME_SINGLE.replace(":id", rowData.programmeId)}>
            <T.LinkB14 color="blue">{rowData.programmeName}</T.LinkB14>
          </Link>
        ) : (
          <T.Body14R>N/A</T.Body14R>
        ),
    },
    { title: "TK ID", field: "tkId", disableClick: true },
    {
      title: "Conflicts",
      field: "hasConflict",
      render: (rowData) =>
        rowData.hasConflict ? (
          <Link to={TEMPO.TK_SINGLE_GROUP_REQUESTS.replace(":id", rowData?.id)}>
            <T.LinkB14 color="pink">Yes!View details </T.LinkB14>
          </Link>
        ) : (
          <T.Body14R>None</T.Body14R>
        ),
    },
    { title: "DATE", field: "date", disableClick: true },
    {
      title: "Action",
      field: "action",
      disableClick: true,
      render: (rowData) => (
        <Dropdown
          placeholder="Select..."
          options={[
            { value: "approve", label: "Approve" },
            { value: "reject", label: "Reject" },
          ]}
          customSelectFunc={(e) => requestUpdate(rowData, e)}
          size="small"
          disabled={rowData.hasConflict}
        />
      ),
    },
  ];

  useEffect(() => {
    getTkGroupRequests({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approveRejectLoading]);

  return (
    <>
      <S.Container>
        <div>
          <S.Head>
            <S.HeadContent>
              <T.H40Caps color="primary" style={{ margin: 0 }}>
                Team Kinetic Group Requests
              </T.H40Caps>
            </S.HeadContent>
            <S.ImageWrapper>
              <Image image="painting" alt="painting" height={210} width={220} />
            </S.ImageWrapper>
          </S.Head>
          <S.TableContainer>
            <Table
              color="blue"
              data={tkGroupRequests}
              columns={tkRequests}
              isLoading={loading}
              limit={10}
              hideSearch
              flexDir="column"
            />
          </S.TableContainer>
        </div>
      </S.Container>

      <WarningMessage
        open={isRejectModalOpen}
        setOpen={setIsRejectModalOpen}
        handleClick={() => {
          setIsRejectionConfirmationModalOpen(true);
          const status = "rejected";
          approveRejectGroup({
            id: groupToUpdate?.id,
            userId: groupToUpdate?.earnGroup?.userId,
            status,
          });
        }}
        title="Are you sure you want to reject this request?"
      />

      <WarningMessage
        open={isRejectionConfirmationModalOpen && !approveRejectError}
        setOpen={setIsRejectionConfirmationModalOpen}
        // handleClick={() => setIsRejectionConfirmationModalOpen(true)}
        title="Group rejected"
        text="Please update Team Kinetic so they can inform the group"
        // nameOrEmail={userToUpdate.email}
        withoutBack
        buttonText="Done"
      />

      <Modal
        modalOpen={isApproveModalOpen && !approveRejectError}
        handleCloseModal={() => setIsApproveModalOpen(false)}
        confirmFun={() => {
          history.push(
            TEMPO.EARN_GROUP_SINGLE.replace(
              ":id",
              isNewGroup ? newGroupUserId : groupToUpdate?.earnGroup?.userId,
            ),
          );
        }}
        buttonText="View group"
        withCancel
        width={690}
        cancelButtonText="View requests"
        onCancel={() => setIsApproveModalOpen(false)}
      >
        <T.Body20B color="blue">Approved!</T.Body20B>
        <T.Body16R mt="15">
          <b>{groupToUpdate.name}</b> has been successfully set up and connected
          with Team Kinetic.
        </T.Body16R>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  tkGroupRequests: state.tkGroupRequests.data,
  loading: state.tkGroupRequests.loading,
  error: state.tkGroupRequests.error,
  approveRejectLoading: state.tkGroupRequests.approveRejectLoading,
  approveRejectError: state.tkGroupRequests.approveRejectError,
  newGroupUserId:
    state.tkGroupRequests.approveRejectData?.updatedGroupProfile?.userId,
});
const mapActionsToProps = {
  getTkGroupRequests: actions.getTkGroupRequests,
  approveRejectGroup: actions.approveRejectGroup,
};

export default connect(mapStateToProps, mapActionsToProps)(TkGroupRequests);
