import axios from "axios";
import handleError from "./handle-errors";

const ACTIVITIES_BASE = "/activities";

const getActivityById = async ({ id, type, options, isPublic = false }) => {
  try {
    const { data } = await axios.get(`${ACTIVITIES_BASE}/${id}`, {
      params: { type, isPublic },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getActivities = async ({
  activityType,
  isPublic = false,
  keyword,
  limit,
  options = {},
  language,
  availableOffersOnly,
  selectedActivity,
  tab,
  location,
  postcode,
  distance,
  includeNationalOffers,
  membershipId,
  query,
  county,
  sortBy,
} = {}) => {
  try {
    const { data } = await axios.get(`${ACTIVITIES_BASE}`, {
      params: {
        type: activityType,
        limit,
        isPublic,
        keyword,
        language,
        availableOffersOnly,
        selectedActivity,
        tab,
        location,
        postcode,
        distance,
        includeNationalOffers,
        membershipId,
        query,
        county,
        sortBy,
      },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const addParticipantToActivity = async ({
  membershipId,
  costVaryPersonTcPrice,
  childrenParticipants,
  adultsParticipants,
  activityId,
  customActivityName,
  customPersonTcPrice,
  isCreatedOnFly,
  confirmedByName,
  isConfirmedOnVolunteerRestriction,
  options,
} = {}) => {
  try {
    const { data } = await axios.post(
      `${ACTIVITIES_BASE}/${activityId}/participants`,
      {
        membershipId,
        costVaryPersonTcPrice,
        childrenParticipants,
        adultsParticipants,
        customActivityName,
        customPersonTcPrice,
        isCreatedOnFly,
        confirmedByName,
        isConfirmedOnVolunteerRestriction,
      },
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updateActivityById = async ({ id, type, updateData, options }) => {
  try {
    const { data } = await axios.patch(`${ACTIVITIES_BASE}/${id}`, updateData, {
      params: { type },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const createActivity = async ({ type, activityData, options }) => {
  try {
    const { data } = await axios.post(ACTIVITIES_BASE, activityData, {
      params: { type },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const purchaseBookingCodes = async ({
  activityId,

  purchaseDetails,
  options,
}) => {
  try {
    const { data } = await axios.post(
      `${ACTIVITIES_BASE}/${activityId}/booking-codes`,
      purchaseDetails,
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getActivityCodesUsedByUser = async ({ activityId, options }) => {
  try {
    const { data } = await axios.get(
      `${ACTIVITIES_BASE}/${activityId}/booking-codes`,
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const deleteActivity = async ({ id, options }) => {
  try {
    const { data } = await axios.delete(`${ACTIVITIES_BASE}/${id}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const approvalRequests = "approval-requests";

const getActivitiesApprovalRequests = async ({ type, options }) => {
  try {
    const { data } = await axios.get(`${ACTIVITIES_BASE}/${approvalRequests}`, {
      params: { type },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updateApprovalRequest = async ({ spendActivityId, status, options }) => {
  try {
    const { data } = await axios.patch(
      `${ACTIVITIES_BASE}/${spendActivityId}/${approvalRequests}`,
      { status },
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getActivitiesCitesAndCounties = async ({ options = {} }) => {
  try {
    const { data } = await axios.get(`${ACTIVITIES_BASE}/locations`, {
      params: { isPublic: true },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const duplicateSpendActivity = async ({
  spendActivityId,
  spendVenueIdUserId,
  copyDates,
  copyBookingCodes,
  copyUsedBookingCodes,
}) => {
  try {
    const { data } = await axios.post(
      `${ACTIVITIES_BASE}/${spendActivityId}/duplicate`,
      {
        spendVenueIdUserId,
        copyDates,
        copyBookingCodes,
        copyUsedBookingCodes,
      },
    );
    return { data };
  } catch (error) {
    const err = handleError(error);
    return { error: err };
  }
};

export {
  getActivityById,
  getActivities,
  addParticipantToActivity,
  updateActivityById,
  createActivity,
  purchaseBookingCodes,
  getActivityCodesUsedByUser,
  deleteActivity,
  getActivitiesApprovalRequests,
  updateApprovalRequest,
  getActivitiesCitesAndCounties,
  duplicateSpendActivity,
};
