import React from "react";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import Button from "../Button";

import t from "../../utils/helpers/translator";

const CSVExport = ({ data, filename, headers, text, m, lang }) => (
  <div style={{ margin: m || "0" }}>
    <CSVLink
      data={data}
      filename={`${filename}.csv`}
      target="_blank"
      style={{ textDecoration: "none" }}
      headers={headers}
    >
      <Button outline mr="0" mb={10}>
        {text || t("exportData", lang)}
      </Button>
    </CSVLink>
  </div>
);

const mapStateToProps = (state) => ({
  lang: state.auth.language,
});

export default connect(mapStateToProps)(CSVExport);
