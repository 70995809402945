import React from "react";

import Modal from "../../../../components/Modal";
import * as T from "../../../../components/Typograpy";

const customModal = ({ modalOpen, confirmFun, groupName, onCancel }) => (
  <Modal
    modalOpen={modalOpen}
    handleCloseModal={() => {}}
    onCancel={onCancel}
    confirmFun={confirmFun}
    buttonText="View group"
    cancelButtonText="View requests"
    withCancel
    width="650px"
  >
    <T.H30 color="blue" m="0" mb="25">
      Approved!
    </T.H30>
    <T.Body16R>
      <T.Body16B style={{ display: "inline" }}>{`${groupName} `}</T.Body16B>
      has been successfully set up and connected with Team Kinetic
    </T.Body16R>
  </Modal>
);

export default customModal;
