export default ({ m, mt, mb, ml, mr, mx, my }) => {
  switch (true) {
    case !!mx && !!mb && !!mt:
      return `${mt}px ${mx}px ${mb}px ${mx}px`;
    case !!my && !!mr && !!ml:
      return `${my}px ${mr}px ${ml}px ${ml}px`;
    case !!mx && !!mt:
      return `${mt}px ${mx}px 0px ${mx}px`;
    case !!mx && !!mb:
      return `0px ${mx}px ${mb}px ${mx}px`;
    case !!my && !!ml:
      return `${my}px 0px ${ml}px ${mx}px`;
    case !!my && !!mr:
      return `${my}px ${mr}px  0px ${mx}px`;
    case !!mx && !!my:
      return `${my}px ${mx}px ${my}px ${mx}px`;
    case !!my:
      return `${my}px 0px ${my}px 0px`;
    case !!mx:
      return `0px ${mx}px 0px ${mx}px`;
    case !!mt && !!mb && !!mr:
      return `${mt}px ${mr}px ${mb}px 0px`;
    case !!mt && !!mb && !!ml:
      return `${mt}px 0px ${mb}px ${ml}px`;
    case mt && ml && !!mr:
      return `${mt}px ${mr}px 0px ${ml}px`;
    case !!mb && !!ml && !!mr:
      return `0px ${mr}px ${mb}px ${ml}px`;
    case !!mt && !!mb:
      return `${mt}px 0px ${mb}px 0px`;
    case !!mt && !!mr:
      return `${mt}px ${mr}px 0px 0px`;
    case !!mt && !!ml:
      return `${mt}px 0px 0px ${ml}px`;
    case !!mb && !!mr:
      return `0px ${mr}px ${mb}px 0px`;
    case !!mb && !!ml:
      return `0px 0px ${mb}px ${ml}px`;
    case !!mr && !!ml:
      return `0px ${mr}px 0px ${ml}px`;
    case !!mt:
      return `${mt}px 0px 0px 0px`;
    case !!mb:
      return `0px 0px ${mb}px 0px`;
    case !!mr:
      return `0px ${mr}px 0px 0px`;
    case !!ml:
      return `0px 0px 0px ${ml}px`;
    case !!m && !!mt && !!ml && !!mr:
      return `${mt}px ${mr}px ${m}px ${ml}px`;
    case !!m && !!mt && !!ml && !!mb:
      return `${mt}px ${m}px ${mb}px ${ml}px`;
    case !!m && !!mt && !!mb && !!mr:
      return `${mt}px ${mr}px ${mb}px ${m}px`;
    case !!m && !!mb && !!ml && !!mr:
      return `${m}px ${mr}px ${mb}px ${ml}px`;
    case !!m && !!mt && !!mr:
      return `${mt}px ${mr}px ${m}px ${m}px`;
    case !!m && !!mt && !!mb:
      return `${mt}px ${m}px ${mb}px ${m}px`;
    case !!m && !!mr && !!mb:
      return `${m}px ${mr}px ${mb}px ${m}px`;
    case !!m && !!mt && !!ml:
      return `${mt}px ${m}px ${m}px ${ml}px`;
    case !!m && !!mr && !!ml:
      return `${m}px ${mr}px ${m}px ${ml}px`;
    case !!m && !!mb && ml:
      return `${m}px ${m}px ${mb}px ${ml}px`;
    case !!m && !!mr:
      return `${m}px ${mr}px ${m}px ${m}px`;
    case !!m && !!mt:
      return `${mt}px ${m}px ${m}px ${m}px`;
    case !!m && !!ml:
      return `${m}px ${m}px ${m}px ${ml}px`;
    case !!m && !!mb:
      return `${m}px ${m}px ${mb}px ${m}px`;
    case !!m:
      return `${m}px`;
    default:
      return "0px auto";
  }
};
