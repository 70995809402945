import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import * as T from "../../../components/Typograpy";
import Stat from "../../../components/Stats";
import Table from "../../../components/Table";
import CSVExport from "../../../components/CSVExport";
import { Body16R } from "../../../components/Typograpy";
import {
  dateCol,
  newReturningCol,
} from "../../../components/Table/config/reusableColumns";
import { truncatePostcode } from "../../../utils/helpers/truncatePostcode";
import translate from "../../../utils/helpers/translator";
import * as actions from "./actions";
import {
  Title,
  PageTitle,
  Container,
  TableContainer,
  Message,
  TitleMobile,
} from "./style";

import GoBack from "../../../components/GoBack";
import { Row, Col } from "../../../components/Grid";
import { roles } from "../../../constants";

const CSVSpendVenueTransactionsHeaders = (lang) =>
  lang !== "english"
    ? [
        {
          label: translate("translateExport", lang),
          key: "",
        },
        { label: "Date", key: "date" },
        { label: "Time", key: "time" },
        { label: "Activity", key: "activity" },
        { label: "Postcode", key: "postcode" },
        { label: "# Time Credits", key: "credits" },
        { label: "Adults (over 16)", key: "adults" },
        { label: "Children (under 16)", key: "children" },
        { label: "New / Returning Visitor", key: "visitorType" },
      ]
    : [
        { label: "Date", key: "date" },
        { label: "Time", key: "time" },
        { label: "Activity", key: "activity" },
        { label: "Postcode", key: "postcode" },
        { label: "# Time Credits", key: "credits" },
        { label: "Adults (over 16)", key: "adults" },
        { label: "Children (under 16)", key: "children" },
        { label: "New / Returning Visitor", key: "visitorType" },
      ];

const InsightPage = ({
  getInsights,
  insights,
  getTransactions,
  transactions,
  returningMembersIds,
  transactionsLoaded,
  transactionsLoading,
  venueName,
  getSpendVenueBasicById,
  spendVenueDetails,
  lang,
  welshName,
}) => {
  const visitors = transactions.map((t) => t.from.userId);

  const { spendVenueId } = useParams();

  const findLastDuplicateIndex = (arr) => {
    const existingItems = {};
    arr.forEach((value, index) => {
      existingItems[value] = index;
    });
    return existingItems;
  };

  const statsData = [
    {
      number: insights.totalTcAccepted,
      text: translate("tcAccepted", lang),
      subText: translate("sinceYouJoinedTempo", lang),
      id: "tcAccepted",
    },
    {
      number: insights.allMembers,
      text: translate("uniqueTempoMemberVisits", lang),
      subText: translate("sinceYouJoinedTempo", lang),
      id: "uniqueTempoMemberVisits",
    },
    {
      number: insights.totalTcAcceptedThisYear,
      text: translate("tcAccepted", lang),
      subText: translate("thisYear", lang),
      id: "tcAccepted",
    },
    {
      number: insights.thisYearMembers,
      text: translate("uniqueTempoMemberVisits", lang),
      subText: translate("thisYear", lang),
      id: "uniqueTempoMemberVisits",
    },
  ];

  const spendVenueTransactions = (isExport) => ({
    columns: [
      dateCol(translate("date", lang)),
      { title: translate("activity", lang), field: "activity" },
      {
        title: translate("postcode", lang),
        field: "postcode",
        cellStyle: {
          textTransform: "capitalize",
          fontWeight: "normal",
          fontSize: "0.875rem",
        },
      },
      {
        title: translate("numberOfTC", lang),
        field: "credits",
        type: "numeric",
      },
      {
        title: `${translate("adults", lang)} (${translate("over16", lang)})`,
        field: "adults",
        type: "numeric",
      },
      {
        title: `${translate("children", lang)} (${translate("under16", lang)})`,
        field: "children",
        type: "numeric",
      },
      newReturningCol(lang),
    ],

    data: transactions.map((t, i) => ({
      date: t.date,
      time: t.time,
      credits: t.tc,
      adults: t.adultsParticipants,
      children: t.childrenParticipants,
      postcode: truncatePostcode(t.from.postcode) || "N/A",
      activity:
        lang === "welsh"
          ? t.activity.welshName || t.activity.name
          : t.activity.name,
      visitorType:
        returningMembersIds[t.from.userId] > 1 &&
        !Object.values(findLastDuplicateIndex(visitors)).includes(i)
          ? translate("returning", isExport ? "english" : lang)
          : translate("new", isExport ? "english" : lang),
    })),
  });

  const decideName = () => {
    if (!spendVenueId) {
      return (lang === "welsh" && welshName) || venueName || "N/A";
    }

    return (
      (lang === "welsh" && spendVenueDetails.welshName) ||
      spendVenueDetails.name ||
      "N/A"
    );
  };

  useEffect(() => {
    getInsights(spendVenueId);
    getTransactions(spendVenueId);
    if (spendVenueId) {
      getSpendVenueBasicById(spendVenueId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {spendVenueId && <GoBack showText p="0 0 27px 0" />}
      <Title>
        <T.H20Caps color="pink" style={{ margin: "0" }}>
          {decideName()}
        </T.H20Caps>
        <T.H40Caps color="midnight" style={{ margin: "0" }}>
          {translate("insights", lang)}
        </T.H40Caps>
      </Title>
      <TitleMobile>
        <T.H30Caps color="primary">{translate("insights", lang)}</T.H30Caps>
      </TitleMobile>
      <Row mt="5" jc="center">
        {statsData.map((e) => (
          <Col
            w={[4, 6, 3]}
            key={e.id}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <Stat
              size="l"
              statNum={e.number}
              statNumColor="blue"
              statText={e.text}
              statTextColor="midnight"
              subText={e.subText}
              subTextColor="gray2"
              isLoading={!transactionsLoaded}
            />
          </Col>
        ))}
      </Row>
      <PageTitle>
        <T.H30Caps style={{ margin: 0 }} color="midnight">
          {translate("tempoTCTransactions", lang)}
        </T.H30Caps>
        <CSVExport
          data={spendVenueTransactions(true).data}
          headers={CSVSpendVenueTransactionsHeaders(lang)}
          filename="venue-transactions-insights"
        />
      </PageTitle>
      <TableContainer>
        <Table
          color="blue"
          hideSearch
          data={spendVenueTransactions().data}
          columns={spendVenueTransactions().columns}
          isLoading={transactionsLoading}
        />
      </TableContainer>
      <Message>
        <Body16R color="gray3">{translate("logInOnComputer", lang)} </Body16R>
      </Message>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  insights: state.spendVenueInsights.insights,
  transactions: state.spendVenueInsights.transactions,
  returningMembersIds: state.spendVenueInsights.returningMembersIds,
  transactionsLoaded: state.spendVenueInsights.transactionsLoaded,
  transactionsLoading: state.spendVenueInsights.transactionsLoading,
  spendVenueDetails: state.spendVenueInsights.spendVenueDetails,

  venueName:
    state.auth.role === roles.SPEND_VENUE
      ? state.auth.profile.name
      : state.auth.selectedSpendVenue.profile.name,
  welshName:
    state.auth.role === roles.SPEND_VENUE
      ? state.auth.profile.welshName
      : state.auth.selectedSpendVenue.profile.welshName,

  lang: state.auth.language,
});

const mapActionToProps = {
  getInsights: actions.getInsights,
  getTransactions: actions.getTransactions,
  getSpendVenueBasicById: actions.getSpendVenueBasicById,
};

export default connect(mapStateToProps, mapActionToProps)(InsightPage);
