import {
  GET_TK_CONFLICTED_PROGRAMME_LOADING_START,
  GET_TK_CONFLICTED_PROGRAMME_LOADING_END,
  GET_TK_CONFLICTED_PROGRAMME_SUCCESS,
  GET_CONFLICTED_PROGRAMME_FAIL,
  TK_ASSIGN_PROGRAMME_START,
  TK_ASSIGN_PROGRAMME_SUCCESS,
  TK_ASSIGN_PROGRAMME_FAIL,
} from "../../../../redux/types";

const initState = {
  data: [],
  loading: false,
  error: null,
};

const tkProgrammeConflict = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TK_CONFLICTED_PROGRAMME_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case GET_TK_CONFLICTED_PROGRAMME_LOADING_END:
      return {
        ...state,
        loading: false,
      };
    case GET_TK_CONFLICTED_PROGRAMME_SUCCESS:
      return {
        ...state,
        data: payload,
        loading: false,
        error: {},
      };

    case GET_CONFLICTED_PROGRAMME_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    // Assign programme
    case TK_ASSIGN_PROGRAMME_START:
      return {
        ...state,
        assignProgrammeLoading: true,
      };
    case TK_ASSIGN_PROGRAMME_SUCCESS:
      return {
        ...state,
        assignProgrammeLoading: false,
      };
    case TK_ASSIGN_PROGRAMME_FAIL:
      return {
        ...state,
        assignProgrammeLoading: false,
        assignProgrammeError: payload,
      };

    default:
      return state;
  }
};

export default tkProgrammeConflict;
