import intersection from "lodash.intersection";

const arrayOfObjectsFilter = (array = [], value = "", filterKey = "") => {
  if (array.length > 0) {
    return array.filter((group) => {
      if (!group) return false;
      if (Array.isArray(value)) {
        if (value.includes("All") || value.length === 0) {
          return true;
        }
        const groupActivitiesMatchWithFilterValues = intersection(
          group[filterKey],
          value,
        );
        if (groupActivitiesMatchWithFilterValues.length === 0) return false;
        return true;
      }
      return (
        (group[filterKey] && group[filterKey].includes(value)) ||
        value === "" ||
        value === "All"
      );
    });
  }
  return [];
};

export default arrayOfObjectsFilter;
