import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import { getSingleTransaction } from "../../../redux/actions/singleTransaction";

import * as S from "./style";
import * as T from "../../../components/Typograpy";
import { roles, navRoutes } from "../../../constants";
import { earnTypesMapWelsh } from "../../../constants/data/earnActivityTypes";

import GoBack from "../../../components/GoBack";
import TimeCredit from "../../../components/TimeCredit";
import { Tag } from "../../../components/Tags";
import Loading from "../../../components/Loading";
import t from "../../../utils/helpers/translator";

import kformatter from "../../../utils/helpers/kformatter";
import dateFormatter from "../../../utils/helpers/dateFormatter";
import calendarFormatter from "../../../utils/helpers/calendarFormatter";

const EarnGroupIndividualTransaction = (props) => {
  const { id: transactionId } = useParams();
  const {
    userId,
    getSingleTransactionAction,
    transaction,
    isLoading,
    role,
    supervisorId,
    language,
  } = props;

  useEffect(() => {
    getSingleTransactionAction(transactionId, { redirectToRelatedPage: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId]);

  const {
    createdAt,
    to = {},
    from = {},
    tc: credits,
    type,
    activity = {},
  } = transaction;

  const {
    title,
    startDate: activityStartDate,
    endDate: activityEndDate,
    name: activityName,
    welshName,
  } = activity;

  const decideVenue = (id, fromDetails, toDetails) => {
    if (fromDetails.userId === id) {
      return toDetails;
    }
    return fromDetails;
  };

  const decideActivityTitle = (transactionType) => {
    if (!transactionType) return "N/A";
    if (["free", "exchange"].includes(transactionType)) {
      return t("TCdelivery", language);
    }
    if (transactionType === "spend") {
      return t("use", language);
    }
    return t(transactionType, language);
  };

  const translateTitle = (_title) => {
    if (language === "welsh") {
      const res = earnTypesMapWelsh[_title];
      return res || _title;
    }
    return _title;
  };

  const translateName = () => {
    if (language === "welsh") {
      return welshName || activityName;
    }
    return activityName;
  };

  const venue = decideVenue(userId, from, to);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  if (isLoading) return <Loading fullPage navbar />;

  return (
    <S.TransactionsWrapper>
      <GoBack showText />
      <S.Header>
        <T.H18Caps color="pink" style={{ margin: "30px 0 5px 0" }}>
          {t("activityType", language)}:
        </T.H18Caps>
        {isMobile ? (
          <T.H30Caps color="midnight" style={{ margin: "0" }}>
            {title ? translateTitle(title) : decideActivityTitle(type)}
          </T.H30Caps>
        ) : (
          <T.H40Caps color="midnight" style={{ margin: "0" }}>
            {title ? translateTitle(title) : decideActivityTitle(type)}
          </T.H40Caps>
        )}
      </S.Header>
      <S.Content>
        <S.Details>
          <T.H20 color="midnight" mb={10}>
            {t("transactionDate", language)}:
          </T.H20>
          <T.Body16R>
            {language !== "english"
              ? dateFormatter(createdAt)
              : calendarFormatter(createdAt, language)}
          </T.Body16R>
        </S.Details>
        <S.Details>
          <T.H20 color="midnight" mb={10}>
            {activityStartDate && activityEndDate
              ? `${t("activityDates", language)}:`
              : `${t("activityDateSingle", language)}:`}
          </T.H20>
          {!activityStartDate && !activityEndDate ? (
            <T.Body16R>N/A</T.Body16R>
          ) : (
            <>
              {activityStartDate && (
                <T.Body16R>{dateFormatter(activityStartDate)}</T.Body16R>
              )}
              {activityEndDate && (
                <T.Body16R>{`${t("to", language)} ${dateFormatter(
                  activityEndDate,
                )}`}</T.Body16R>
              )}
            </>
          )}
        </S.Details>

        {type === "free" || type === "bought" ? (
          <S.Details>
            <T.H20 color="midnight" mb={10}>
              {t("organisation", language)}:
            </T.H20>

            <T.Body16R>{t("tempo", language)}</T.Body16R>
          </S.Details>
        ) : (
          <>
            {type !== "spend" && (
              <S.Details>
                <T.H20 color="midnight" mb={10}>
                  {t("name", language)}:
                </T.H20>
                {venue.role === roles.MEMBER ? (
                  <S.StyledLink
                    to={navRoutes.EARN_GROUP.MEMBERS_SINGLE.replace(
                      ":id",
                      venue.memberId,
                    )}
                  >
                    <T.LinkB16 color="blue">{venue.involved}</T.LinkB16>
                  </S.StyledLink>
                ) : (
                  <>
                    <T.Body16R>{venue.involved}</T.Body16R>
                  </>
                )}
              </S.Details>
            )}
          </>
        )}

        <S.Details>
          <T.H20 color="midnight" mb={10}>
            {t("activityName", language)}:
          </T.H20>
          <T.Body16R>{activityName ? translateName() : "N/A"}</T.Body16R>
        </S.Details>
        <S.Details>
          <T.H20 color="midnight" mb={10}>
            {t("TCNumber", language)}:
          </T.H20>
          <TimeCredit
            credits={kformatter(credits)}
            gradient="blue"
            color="blue"
            description
          />
        </S.Details>
        <S.Details>
          <T.H20 color="midnight" mb={10}>
            {t("type", language)}:
          </T.H20>

          <Tag
            tagName={
              from.userId === userId ||
              (role === roles.EARN_GROUP_ADMIN && supervisorId === from.userId)
                ? "givenOut"
                : "received"
            }
            justify="flexStart"
            m="0"
            lang={language}
          />
        </S.Details>
      </S.Content>
    </S.TransactionsWrapper>
  );
};

const mapStateToProps = (state) => ({
  userId: state.auth.id,
  supervisorId: state.auth.supervisorId,
  role: state.auth.role,
  transaction: state.singleTransaction.data,
  isLoading: state.singleTransaction.loading,
  language: state.auth.language,
});

const mapActionToProps = {
  getSingleTransactionAction: getSingleTransaction,
};

export default connect(
  mapStateToProps,
  mapActionToProps,
)(EarnGroupIndividualTransaction);
