import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useHistory, Link } from "react-router-dom";
import { Row, Col } from "../../../../components/Grid";
import { InputField, Dropdown } from "../../../../components/Inputs";
import Button from "../../../../components/Button";
import GoBack from "../../../../components/GoBack";
import { roles } from "../../../../constants";
import * as T from "../../../../components/Typograpy";
import Modal from "../../../../components/Modal";
import stripLeadingTab from "../../../../utils/helpers/stripLeadingTab";
import t from "../../../../utils/helpers/translator";

import {
  getSpendPartners as getSpendPartnersAction,
  createSpendVenueCredentials as createSpendVenueCredentialsAction,
  updateSpendVenuePartner as updateSpendVenuePartnerAction,
} from "./actions";

import validate from "../../../../validation/schemas/spendVenueAccount";

import * as actions from "../../../../redux/actions/userAccount";
import cleanWhitespace from "../../../../utils/helpers/cleanWhitespace";
import { TEMPO } from "../../../../constants/navRoutes";

const EditSpendVenueAccount = ({
  isMobile,
  getUserAccount,
  updateUserAccount,
  userAccount,
  updateAccountLoading,
  spendPartners,
  getSpendPartners,
  createSpendVenueCredentials,
  updateSpendVenuePartner,
  updateSVPartnerLoading,
  createSVCredLoading,
}) => {
  const [state, setState] = useState({
    venueName: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    password: "",
    haLogin: null,
  });
  const [reassignModalOpen, setReassignModalOpen] = useState(false);
  const [updateSuccessModalOpen, setUpdateSuccessModalOpen] = useState(false);
  const [btnOn, setBtnOn] = useState(false);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const { id } = useParams();
  // get user account details
  useEffect(() => {
    getUserAccount(id, roles.SPEND_VENUE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (Number(userAccount.id) === Number(id)) {
      setState({
        venueName: userAccount.venueName,
        email: userAccount.email,
        firstName: userAccount.firstName,
        hasLogin: userAccount.hasLogin,
        id: userAccount.id,
        lastName: userAccount.lastName,
        partner: userAccount.partner,
        phoneNumber: stripLeadingTab(
          userAccount.phoneNumber?.replace(/ /g, ""),
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccount.id]);

  useEffect(() => {
    getSpendPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    setBtnOn(true);

    const { name, value } = e.target;
    const cleanEmail =
      name === "email" && value.toLowerCase().replace(/ /g, "").trim();

    switch (name) {
      case "email":
        setState((_state) => ({ ..._state, email: cleanEmail }));
        break;
      case "phoneNumber":
        setState((_state) => ({ ..._state, [name]: value.trim() }));
        break;
      default:
        setState((_state) => ({ ..._state, [name]: value }));
        break;
    }
  };

  useEffect(() => {
    if (!state.hasLogin) {
      const formHasValues =
        state.email ||
        state.firstName ||
        state.lastName ||
        state.phoneNumber ||
        state.password;

      setIsCreatingAccount(formHasValues);
      if (!formHasValues) {
        setErrors({});
      }
    }
  }, [
    state.email,
    state.firstName,
    state.lastName,
    state.phoneNumber,
    state.password,
    state.hasLogin,
  ]);

  const handleDropdown = (selected) => {
    setBtnOn(true);

    // handle delete
    if (!selected) {
      setState((_state) => ({ ..._state, partner: "" }));
    } else if (selected.value === "new") {
      const searchParams = new URLSearchParams();
      searchParams.append("venueId", id);

      history.push({
        pathname: TEMPO.CREATE_SPEND_PARTNER,
        search: searchParams.toString(),
      });
    } else {
      setState((_state) => ({
        ..._state,
        partner: {
          name: selected.label,
          id: selected.value,
          isNew: selected.__isNew__,
        },
      }));
    }
  };

  const validateForm = () => {
    try {
      validate({
        ...state,
        phoneNumber: stripLeadingTab(state.phoneNumber?.replace(/ /g, "")),
        isCreatingAccount,
        hasLogin: state.hasLogin,
        spendPartnerId: state?.partner?.id,
      });
      setErrors({});
      return true;
    } catch (err) {
      if (err.name === "ValidationError") {
        setErrors({ ...err.inner, anyError: true });
      }
      return false;
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const _phoneNumber = stripLeadingTab(state.phoneNumber?.replace(/ /g, ""));

    try {
      const valid = validateForm();
      if (!valid) return;
      if (state.hasLogin) {
        await updateUserAccount(
          {
            firstName: cleanWhitespace(state.firstName),
            lastName: cleanWhitespace(state.lastName),
            email: cleanWhitespace(state.email),
            phoneNumber: _phoneNumber,
            spendPartnerId: state.partner.id,
            role: roles.SPEND_VENUE,
          },
          id,
          roles.SPEND_VENUE,
        );
      } else if (isCreatingAccount) {
        await createSpendVenueCredentials({
          id,
          firstName: cleanWhitespace(state.firstName),
          lastName: cleanWhitespace(state.lastName),
          email: cleanWhitespace(state.email),
          phoneNumber: _phoneNumber,
          password: state.password,
          spendPartnerId: state.partner.id,
        });
        setIsCreatingAccount(false);
      } else {
        await updateSpendVenuePartner({
          spendVenueUserId: id,
          spendPartnerId: state.partner.id,
        });
      }

      if (userAccount?.partner?.id !== state.partner?.id) {
        setReassignModalOpen(true);
      } else {
        setUpdateSuccessModalOpen(true);
      }

      await getUserAccount(id, roles.SPEND_VENUE);
      setState((_state) => ({
        ..._state,
        hasLogin: _state.hasLogin || isCreatingAccount,
      }));
      setBtnOn(false);
      setErrors({});
    } catch (err) {
      if (err.name === "ValidationError") {
        setErrors(err.inner);
      } else {
        setErrors({ serverError: err.message });
      }
      setBtnOn(false);
    }
  };

  return (
    <>
      {/* Account details */}
      <Row jc="space-between" mb={5}>
        <Col w={[4, 6, 7]}>
          <GoBack showText />
          <T.H40Caps color="midnight" mt="30">
            EDIT ACCOUNT
          </T.H40Caps>
          <T.H30Caps color="midnight" mt="62">
            Venue Account Details
          </T.H30Caps>
        </Col>
      </Row>

      <Row mb={9}>
        <Col w={[4, 6, 4]}>
          <Dropdown
            placeholder="Select or add..."
            label="Recognition Partner"
            name="spendPartner"
            helper="ensure consistency with CRM"
            options={[
              ...spendPartners.map((partner) => ({
                label: partner.name,
                value: partner.id,
              })),
              { label: "Add new", value: "new" },
            ]}
            value={
              state.partner && [
                {
                  value: state.partner.id,
                  label: state.partner.name,
                },
              ]
            }
            setValue={({ selected }) => handleDropdown(selected)}
            error={errors.spendPartnerId}
            blurInputOnSelect={false}
            justify="center"
            isClearable
          />
        </Col>
      </Row>

      <Row>
        {!state.hasLogin ? (
          <>
            <Col w={[4, 8, 8]}>
              <T.Body14R color="gray3">
                This Venue uses the Recognition Partner’s{" "}
                <Link
                  to={TEMPO.EDIT_SPEND_PARTNER_ACCOUNT.replace(
                    ":id",
                    userAccount?.partner?.userId,
                  )}
                >
                  <T.Link14R color="blue" style={{ display: "inline" }}>
                    account details
                  </T.Link14R>
                </Link>{" "}
                to manage and accept Time Credits. If you would like to give
                them a unique account log in, please enter the details below.
              </T.Body14R>
            </Col>
            <Col w={[4, 8, 8]}>
              <T.Body14R color="gray3" mt="25">
                For the password you create, please share with the Venue and ask
                them to change it as soon as they can.
              </T.Body14R>
            </Col>
          </>
        ) : (
          <Col w={[4, 10, 8]}>
            <T.Body14R color="gray3" mb="20">
              Please note if you change the email address this will change the
              login details, so please notify the contact if you do this.
            </T.Body14R>
          </Col>
        )}
      </Row>

      {/* Fields */}

      <Row>
        <Col w={[4, 6, 4]}>
          <InputField
            mt={2}
            label="First name"
            type="text"
            placeholder="Type first name here..."
            name="firstName"
            setValue={onChange}
            value={state.firstName}
            error={errors.firstName}
          />
        </Col>
        <Col w={[4, 6, 4]}>
          <InputField
            mt={2}
            label="Last name"
            type="text"
            placeholder="Type last name here..."
            name="lastName"
            setValue={onChange}
            value={state.lastName}
            error={errors.lastName}
          />
        </Col>
      </Row>
      <Row>
        <Col w={[4, 6, 4]}>
          <InputField
            mt={2}
            label="Email address"
            placeholder="Type email here..."
            name="email"
            setValue={onChange}
            value={state.email}
            error={errors.email}
          />
        </Col>
        <Col w={[4, 6, 4]}>
          <InputField
            mt={2}
            label="Contact Number"
            type="tel"
            placeholder="Type phone number here..."
            name="phoneNumber"
            setValue={onChange}
            value={state.phoneNumber}
            error={errors.phoneNumber}
          />
        </Col>
      </Row>
      {!state.hasLogin && (
        <Row>
          <Col w={[4, 6, 4]}>
            <InputField
              mt={2}
              type="password"
              placeholder="Type password here..."
              label="Password"
              name="password"
              setValue={onChange}
              value={state.password}
              error={errors.password}
              showPasswordPop
            />
          </Col>
        </Row>
      )}
      <Row
        style={{
          marginTop: !isMobile ? "40px" : "2rem",
        }}
      >
        {errors.anyError && (
          <T.Body16R color="pink">
            {t("inputFieldMissing", "english")}
          </T.Body16R>
        )}
        <Col w={[4, 6, 4]}>
          <Button
            primary
            size="l"
            type="submit"
            handleClick={onSubmit}
            loading={
              updateAccountLoading ||
              updateSVPartnerLoading ||
              createSVCredLoading
            }
            disabled={!btnOn}
          >
            Update Venue
          </Button>
        </Col>
      </Row>
      {errors.serverError && (
        <Row mt={2}>
          <Col w={[4, 8, 8]}>
            <T.Body16R color="pink">
              {t(errors.serverError, "english")}
            </T.Body16R>
          </Col>
        </Row>
      )}

      <Modal
        modalOpen={reassignModalOpen}
        handleCloseModal={() => setReassignModalOpen(false)}
        confirmFun={() => {}}
        buttonText="Continue"
        width={690}
      >
        <T.Body20B color="blue">Venue Reassigned!</T.Body20B>
        <T.Body16B color="gray3" mt="15">
          {state.hasLogin ? (
            <>
              You have just reassigned {state.venueName} to{" "}
              {state.partner?.name}.
            </>
          ) : (
            <>
              {" "}
              You have just reassigned {state.venueName} to{" "}
              {state.partner?.name}. They will need to get updated login details
              from {state.partner?.name}.
            </>
          )}
        </T.Body16B>
      </Modal>
      <Modal
        modalOpen={updateSuccessModalOpen}
        handleCloseModal={() => setUpdateSuccessModalOpen(false)}
        confirmFun={() => {}}
        buttonText="Continue"
        width={690}
      >
        <T.Body20B color="blue">
          {isCreatingAccount ? "Account Updated!" : "Venue Updated!"}
        </T.Body20B>
        <T.Body16B color="gray3" mt="15">
          {isCreatingAccount ? (
            <>
              You have now created an account for {state.venueName}. Please
              share the log in details with the venue.
            </>
          ) : (
            <>
              {" "}
              You&apos;ve changed the account details for this Venue. If you
              have changed their email address, please inform all appropriate
              contacts.
            </>
          )}
        </T.Body16B>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  role: state.auth.role,
  userAccount: state.userAccount.data,
  getAccountLoading: state.userAccount.getAccountLoading,
  updateAccountLoading: state.userAccount.updateAccountLoading,
  spendPartners: state.staffEditSVAccount.spendPartners,
  getSpendPartnersLoading: state.staffEditSVAccount.getSpendPartnersLoading,
  updateSVPartnerLoading: state.staffEditSVAccount.updateSVPartnerLoading,
  createSVCredLoading: state.staffEditSVAccount.createSVCredLoading,
});

const mapActionToProps = {
  getUserAccount: actions.getUserAccount,
  updateUserAccount: actions.updateUserAccount,
  getSpendPartners: getSpendPartnersAction,
  createSpendVenueCredentials: createSpendVenueCredentialsAction,
  updateSpendVenuePartner: updateSpendVenuePartnerAction,
};

export default connect(
  mapStateToProps,
  mapActionToProps,
)(EditSpendVenueAccount);
