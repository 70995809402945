const keys = {
  IMPROVING_SERVICES: "Improving Services",
  OFFICE_ADMIN_SUPPORT: "Office & Admin Support",
  EVENTS_OPERATIONS: "Events & Operations",
  PRACTICAL_HELP: "Practical Help",
  PROFESSIONAL_SKILLS: "Professional Skills",
  PROMOTION_FUNDRAISING: "Promotion & Fundraising",
  RUNNING_AN_ACTIVITY_OR_GROUP: "Running an activity or group",
  SUPPORTING_OTHERS: "Supporting Others",
  TEACHING_TRAINING: "Teaching & Training",
};

const keysWelsh = {
  IMPROVING_SERVICES: "Gwella Gwasanaethau",
  OFFICE_ADMIN_SUPPORT: "Cefnogaeth Gweinyddol",
  EVENTS_OPERATIONS: "Digwyddiadau a Gweithrediadau",
  PRACTICAL_HELP: "Cefnogaeth Ymarferol",
  PROFESSIONAL_SKILLS: "Sgiliau Proffesiynol",
  PROMOTION_FUNDRAISING: "Hyrwyddo a Chodi Arian",
  RUNNING_AN_ACTIVITY_OR_GROUP: "Cynnal Gweithgaredd neu Grŵp",
  SUPPORTING_OTHERS: "Cefnogi Eraill",
  TEACHING_TRAINING: "Dysgu a Hyfforddi",
};

const helperTextKeys = {
  EG_WORKING_ON_RECEPTION_OR_COMPLETING_PAPERWORK:
    "e.g. working on reception or completing paperwork",
  EG_SITTING_ON_A_BOARD_OR_A_USER_FORUM:
    "e.g. sitting on a board or a user forum",
  EG_MANAGING_SOCIAL_MEDIA_OR_FUNDRAISING_ACTIVITIES:
    "e.g. managing social media or fundraising activities",
  EG_HELPING_RUN_AN_EVENT_CAFE_FOODBANK_OR_MUSEUM:
    "e.g. helping run an event, cafe, foodbank or museum",
  EG_GARDENING_DRIVING_OR_LITTER_PICKING:
    "e.g. gardening, driving or litter picking",
  EG_ACCOUNTING_GOVERNANCE_OR_REPORT_WRITING:
    "e.g. accounting, governance or report writing",
  EG_MANAGING_VOLUNTEERS_OR_ORGANISING_TRIPS:
    "e.g. managing volunteers or organising trips",
  EG_BEFRIENDING_OR_PROVIDING_ADVICE: "e.g. befriending or providing advice",
  EG_SCHOOL_READING_MENTORING_OR_UPSKILLING_OTHERS:
    "e.g. school reading, mentoring or upskilling others",
};

const helperTextKeysWelsh = {
  EG_WORKING_ON_RECEPTION_OR_COMPLETING_PAPERWORK:
    "e.e. gweithio ar y dderbynfa neu chwblhau gwaith papur",
  EG_SITTING_ON_A_BOARD_OR_A_USER_FORUM:
    "e.e. eistedd ar fwrdd neu fforwm defnyddiwr",
  EG_MANAGING_SOCIAL_MEDIA_OR_FUNDRAISING_ACTIVITIES:
    "e.e. rheoli cyfryngau cymdeithasol neu gweithgareddau codi arian",
  EG_HELPING_RUN_AN_EVENT_CAFE_FOODBANK_OR_MUSEUM:
    "e.e. helpu cynnal digwyddiad, caffi, banc bwyd neu amgueddfa",
  EG_GARDENING_DRIVING_OR_LITTER_PICKING:
    "e.e. garddio, gyrru neu codi ysbwriel",
  EG_ACCOUNTING_GOVERNANCE_OR_REPORT_WRITING:
    "e.e. cyfrifeg, llywodraethu neu ysgrifennu adroddiadau",
  EG_MANAGING_VOLUNTEERS_OR_ORGANISING_TRIPS:
    "e.e. rheoli gwirfoddolwyr neu trefnu teithiau",
  EG_BEFRIENDING_OR_PROVIDING_ADVICE: "e.e. cyfeillio neu darparu cyngor",
  EG_SCHOOL_READING_MENTORING_OR_UPSKILLING_OTHERS:
    "e.e. gwaith darllen ysgol, mentora neu uwchsgilio eraill",
};

const earnActivityTypesDropDown = {
  adminOfficeSupport: {
    value: "adminOfficeSupport",
    englishLabel: keys.OFFICE_ADMIN_SUPPORT,
    welshLabel: keysWelsh.OFFICE_ADMIN_SUPPORT,
  },
  developingServices: {
    value: "developingServices",
    englishLabel: keys.IMPROVING_SERVICES,
    welshLabel: keysWelsh.IMPROVING_SERVICES,
  },

  fundraisingPromotion: {
    value: "fundraisingPromotion",
    englishLabel: keys.PROMOTION_FUNDRAISING,
    welshLabel: keysWelsh.PROMOTION_FUNDRAISING,
  },
  operations: {
    value: "operations",
    englishLabel: keys.EVENTS_OPERATIONS,
    welshLabel: keysWelsh.EVENTS_OPERATIONS,
  },
  practicalHelp: {
    value: "practicalHelp",
    englishLabel: keys.PRACTICAL_HELP,
    welshLabel: keysWelsh.PRACTICAL_HELP,
  },
  professionalSkills: {
    value: "professionalSkills",
    englishLabel: keys.PROFESSIONAL_SKILLS,
    welshLabel: keysWelsh.PROFESSIONAL_SKILLS,
  },
  runningActivity: {
    value: "runningActivity",
    englishLabel: keys.RUNNING_AN_ACTIVITY_OR_GROUP,
    welshLabel: keysWelsh.RUNNING_AN_ACTIVITY_OR_GROUP,
  },
  supportingOthers: {
    value: "supportingOthers",
    englishLabel: keys.SUPPORTING_OTHERS,
    welshLabel: keysWelsh.SUPPORTING_OTHERS,
  },
  teachingTraining: {
    value: "teachingTraining",
    englishLabel: keys.TEACHING_TRAINING,
    welshLabel: keysWelsh.TEACHING_TRAINING,
  },
};

const earnActivityTypesDropDownEnglish = Object.entries(
  earnActivityTypesDropDown,
).map(([key, { englishLabel }]) => ({
  label: englishLabel,
  value: key,
}));

const earnActivityTypesDropDownWelsh = Object.entries(
  earnActivityTypesDropDown,
).map(([key, { welshLabel }]) => ({
  label: welshLabel,
  value: key,
}));

const earnTypesMapWelsh = {};
Object.keys(keys).forEach((e) => {
  earnTypesMapWelsh[keys[e]] = keysWelsh[e];
});

const earnTypesHelperMapWelsh = {};
Object.keys(helperTextKeys).forEach((e) => {
  earnTypesHelperMapWelsh[helperTextKeys[e]] = helperTextKeysWelsh[e];
});

export {
  keys,
  keysWelsh,
  earnActivityTypesDropDownEnglish,
  earnActivityTypesDropDownWelsh,
  helperTextKeys,
  helperTextKeysWelsh,
  earnActivityTypesDropDown,
  earnTypesMapWelsh,
  earnTypesHelperMapWelsh,
};
