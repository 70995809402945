import {
  GET_STAFF_SINGLE_EARNGROUP_LOADING,
  GET_STAFF_SINGLE_EARNGROUP_SUCCESS,
  GET_STAFF_SINGLE_EARNGROUP_FAIL,
  GET_STAFF_SINGLE_EARNGROUP_MEMBERS_LOADING,
  GET_STAFF_SINGLE_EARNGROUP_MEMBERS_SUCCESS,
  GET_STAFF_SINGLE_EARNGROUP_MEMBERS_FAIL,
  GET_STAFF_SINGLE_EARNGROUP_TRANSACTIONS_LOADING,
  GET_STAFF_SINGLE_EARNGROUP_TRANSACTIONS_SUCCESS,
  GET_STAFF_SINGLE_EARNGROUP_TRANSACTIONS_FAIL,
  UPDATE_PROGRAMME_EARN_GROUP_STATUS_SUCCESS,
  UPDATE_PROGRAMME_EARN_GROUP_STATUS_LOADING,
  UPDATE_PROGRAMME_EARN_GROUP_STATUS_FAIL,
  RESET_STAFF_SINGLE_EARNGROUP,
  DELETE_EARNGROUP_LOADING_START,
  DELETE_EARNGROUP_LOADING_END,
  DELETE_EARNGROUP_SUCCESS,
  DELETE_EARNGROUP_FAIL,
} from "../../../../redux/types";

const initState = {
  accepted: null,
  activeMembers: null,
  activitiesStats: { live: null, draft: null },
  balance: null,
  email: "",
  firstName: "",
  givenOut: "",
  id: null,
  isPublic: null,
  issued: "",
  lastName: "",
  name: "",
  phoneNumber: "",
  programme: { id: null, name: "" },
  requests: { id: null, requestedCredits: null },
  spentPercentage: "",
  administratorsCount: null,

  members: [],
  transactions: [],
  transactionsLoading: false,
  membersLoading: false,
  detailsLoading: false,
  error: null,

  programmesLoading: true,
  programmes: [],

  updateEarnGroupLoading: false,
  updateEarnGroupSuccess: false,
  updateEarnGroupError: {},

  deleteEarnGroupLoading: false,
  deleteEarnGroupError: null,
};

const staffSingleEarnGroupReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    // EARNGROUP DETAILS
    case GET_STAFF_SINGLE_EARNGROUP_LOADING:
      return {
        ...state,
        requests: {},
        detailsLoading: true,
      };
    case GET_STAFF_SINGLE_EARNGROUP_SUCCESS:
      return {
        ...state,
        ...payload,

        detailsLoading: false,
      };
    case GET_STAFF_SINGLE_EARNGROUP_FAIL:
      return {
        ...state,
        detailsLoading: false,
        error: payload,
      };

    // MEMBERS
    case GET_STAFF_SINGLE_EARNGROUP_MEMBERS_LOADING:
      return {
        ...state,
        membersLoading: true,
      };
    case GET_STAFF_SINGLE_EARNGROUP_MEMBERS_SUCCESS:
      return {
        ...state,
        members: payload,
        membersLoading: false,
      };
    case GET_STAFF_SINGLE_EARNGROUP_MEMBERS_FAIL:
      return {
        ...state,
        membersLoading: false,
        error: payload,
      };

    // TRANSACTIONS
    case GET_STAFF_SINGLE_EARNGROUP_TRANSACTIONS_LOADING:
      return {
        ...state,
        transactionsLoading: true,
      };
    case GET_STAFF_SINGLE_EARNGROUP_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: payload,
        transactionsLoading: false,
      };
    case GET_STAFF_SINGLE_EARNGROUP_TRANSACTIONS_FAIL:
      return {
        ...state,
        transactionsLoading: false,
        error: payload,
      };

    // ADD/REMOVE earn group to programme
    case UPDATE_PROGRAMME_EARN_GROUP_STATUS_LOADING:
      return {
        ...state,
        updateEarnGroupLoading: true,
        updateEarnGroupSuccess: false,
        updateEarnGroupError: {},
      };
    case UPDATE_PROGRAMME_EARN_GROUP_STATUS_SUCCESS:
      return {
        ...state,
        updateEarnGroupLoading: false,
        updateEarnGroupSuccess: true,
      };
    case UPDATE_PROGRAMME_EARN_GROUP_STATUS_FAIL:
      return {
        ...state,
        updateEarnGroupLoading: false,
        updateEarnGroupSuccess: false,
        updateEarnGroupError: payload,
      };
    case RESET_STAFF_SINGLE_EARNGROUP:
      return {
        ...state,
        ...initState,
      };
    // delete EG
    case DELETE_EARNGROUP_LOADING_START:
      return {
        ...state,
        deleteEarnGroupLoading: true,
      };
    case DELETE_EARNGROUP_LOADING_END:
      return {
        ...state,
        deleteEarnGroupLoading: false,
      };
    case DELETE_EARNGROUP_SUCCESS:
      return {
        ...state,
        deleteEarnGroupLoading: false,
        deleteEarnGroupError: null,
      };
    case DELETE_EARNGROUP_FAIL:
      return {
        ...state,
        deleteEarnGroupLoading: false,
        deleteEarnGroupError: payload,
      };

    default:
      return state;
  }
};

export default staffSingleEarnGroupReducer;
