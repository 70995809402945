import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import * as S from "./style";
import Image from "../../../../components/Image";
import * as T from "../../../../components/Typograpy";
import GoBack from "../../../../components/GoBack";
import { Tag } from "../../../../components/Tags";
import TimeCredit from "../../../../components/TimeCredit";

import { TEMPO, GENERAL } from "../../../../constants/navRoutes";
import roles from "../../../../constants/roles";
import { getSingleTransaction } from "../../../../redux/actions/singleTransaction";
import kFormatter from "../../../../utils/helpers/kformatter";
import dateFormatter from "../../../../utils/helpers/dateFormatter";
import t from "../../../../utils/helpers/translator";
import { decideTypeTempo } from "../../../../utils/helpers/decideTransactionType";

import Loading from "../../../../components/Loading";
import { getEarnGroupByUserId } from "../SingleEarnGroup/actions";

const Transactions = ({
  transactionDetailes,
  getSingleTransactionAction,
  getEarnGroupByUserIdAction,
}) => {
  const { loading, data } = transactionDetailes;

  const { senderUserId, isTkTransaction } = data || {};
  const { id } = useParams();
  useEffect(() => {
    getSingleTransactionAction(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!loading && senderUserId) {
      getEarnGroupByUserIdAction(senderUserId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [senderUserId]);

  const { data: transactionData } = transactionDetailes;

  const { earnActivityStartDate, earnActivityEndDate } = transactionData;
  const userType = (type) => {
    switch (type) {
      case "member":
        return "volunteer";
      case "spendVenue":
        return "venue";
      case "earnGroup":
        return "earn group";
      case "tempoStaffUser":
      case "tempoStaffAdmin":
      case "tempoStaffSuper":
        return "Admin";
      default:
        break;
    }
  };

  const isAdmin = (type) => {
    switch (type) {
      case "tempoStaffUser":
      case "tempoStaffAdmin":
      case "tempoStaffSuper":
        return true;
      default:
        return false;
    }
  };

  const Goto = (type, userId) => {
    if (type === roles.MEMBER)
      return TEMPO.MEMBERS_SINGLE.replace(":id", userId);
    if (type === roles.EARN_GROUP)
      return TEMPO.EARN_GROUP_SINGLE.replace(":id", userId);
    if (type === roles.SPEND_VENUE)
      return TEMPO.SPEND_VENUES_SINGLE.replace(":id", userId);
    return null;
  };

  if (transactionDetailes.loading) return <Loading fullPage navbar />;
  return (
    <S.Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <S.Back>
          <GoBack showText />
        </S.Back>
        <T.H40Caps color="primary" style={{ margin: 0 }}>
          Transaction Details
        </T.H40Caps>
        <S.TagContainer>
          <Tag
            tagName={t(decideTypeTempo(transactionData.type), "english")}
            size="l"
          />
        </S.TagContainer>
        <S.CardContainer>
          <S.Card mb="0">
            <T.H20 color="midnight">
              {isAdmin(transactionData.senderType) ? "By" : "From"}
            </T.H20>
            <T.Body20 color="gray2">{transactionData.senderSubTitle}</T.Body20>
            <T.H18Caps color="gray3" mb={5}>
              {userType(transactionData.senderType)}
            </T.H18Caps>
            {!isAdmin(transactionData.senderType) &&
            transactionData.senderType &&
            transactionData.senderUserId ? (
              <Link
                to={Goto(
                  transactionData.senderType,
                  transactionData.senderUserId,
                )}
              >
                <T.LinkS14 color="blue" style={{ cursor: "pointer" }}>
                  View details
                </T.LinkS14>
              </Link>
            ) : null}
          </S.Card>
          <S.Card mb="0">
            <T.H20 color="midnight">To</T.H20>
            <T.Body20 color="gray2">
              {transactionData.receiverSubTitle}
            </T.Body20>
            <T.H18Caps color="gray3" mb={5}>
              {userType(transactionData.receiverType)}
            </T.H18Caps>
            {transactionData.receiverType && transactionData.receiverUserId ? (
              <Link
                to={Goto(
                  transactionData.receiverType,
                  transactionData.receiverUserId,
                )}
              >
                <T.LinkS14 color="blue" style={{ cursor: "pointer" }}>
                  View details
                </T.LinkS14>
              </Link>
            ) : null}
          </S.Card>
        </S.CardContainer>
        <S.CardContainer>
          <S.Card mb="0">
            <T.H20 color="midnight" mb={5}>
              Transaction Date
            </T.H20>
            <T.Body20 color="gray2">
              {dateFormatter(transactionData.date)}
            </T.Body20>
          </S.Card>
          {transactionData.type === "bought" && transactionData.invoiceId && (
            <S.Card mb="0">
              <Link
                to={GENERAL.ORDER_INVOICE.replace(
                  ":id",
                  transactionData.invoiceId,
                )}
              >
                <T.LinkB20 color="midnight">
                  {transactionData.invoiceNumber}
                </T.LinkB20>
              </Link>
            </S.Card>
          )}
        </S.CardContainer>
        <S.CardContainer>
          <S.Card>
            <>
              <TimeCredit
                credits={kFormatter(transactionData.credits)}
                description
                label
                color="blue"
                gradient
              />
            </>
          </S.Card>
          {transactionData.confirmedByName && (
            <S.Card>
              <T.H20 color="midnight" mb={5}>
                Special Authorisation
              </T.H20>
              <T.Body20 color="gray2">
                {transactionData.confirmedByName}
              </T.Body20>
            </S.Card>
          )}
          {transactionData.braintreeTransactionId && (
            <S.Card>
              <T.H20 color="midnight">Transaction ID</T.H20>
              <T.Body20 color="gray2">
                {transactionData.braintreeTransactionId}
              </T.Body20>
            </S.Card>
          )}
        </S.CardContainer>
        <T.Body16I color="gray2" style={{ margin: "unset" }}>
          {isTkTransaction ? "Team Kinetic transaction" : null}
        </T.Body16I>
        {transactionData.type === "earn" && (
          <>
            <S.Div>
              <T.H20 color="midnight">Activity</T.H20>
              <T.Body20 color="gray2">{transactionData.activityTypes}</T.Body20>
              <T.Body14S color="gray3">
                {transactionData.activityDetailes}
              </T.Body14S>
            </S.Div>
            <S.CardContainer>
              <S.Card>
                <T.H20 color="midnight">Activity Name</T.H20>
                <T.Body20 color="gray2">
                  {transactionData.activityName || "N/A"}
                </T.Body20>
              </S.Card>
              <S.Card>
                <T.H20 color="midnight">
                  {earnActivityStartDate && earnActivityEndDate
                    ? "Activity Dates:"
                    : "Activity Date"}
                </T.H20>
                {!earnActivityStartDate && !earnActivityEndDate ? (
                  <T.Body16R>N/A</T.Body16R>
                ) : (
                  <>
                    {earnActivityStartDate && (
                      <T.Body16R>
                        {dateFormatter(earnActivityStartDate)}
                      </T.Body16R>
                    )}
                    {earnActivityEndDate && (
                      <T.Body16R>{`to  ${dateFormatter(
                        earnActivityEndDate,
                      )}`}</T.Body16R>
                    )}
                  </>
                )}
              </S.Card>
            </S.CardContainer>
          </>
        )}
      </div>
      <S.ImageWrapper>
        <Image image="cinema" alt="cinema" height={210} width={220} />
      </S.ImageWrapper>
    </S.Container>
  );
};

const mapStateToProps = (state) => ({
  transactionDetailes: state.singleTransaction,
});

const mapActionsToProps = {
  getSingleTransactionAction: getSingleTransaction,
  getEarnGroupByUserIdAction: getEarnGroupByUserId,
};

export default connect(mapStateToProps, mapActionsToProps)(Transactions);
