import {
  GET_TK_GROUP_REQUEST_LOADING_START,
  GET_TK_GROUP_REQUEST_LOADING_END,
  GET_TK_GROUP_REQUEST_SUCCESS,
  GET_TK_GROUP_REQUEST_FAIL,
  TK_APPROVE_REJECT_GROUP_START,
  TK_APPROVE_REJECT_GROUP_END,
  TK_APPROVE_REJECT_GROUP_SUCCESS,
  TK_APPROVE_REJECT_GROUP_FAIL,
} from "../../../../redux/types";

const initState = {
  data: [],
  loading: false,
  error: null,
};

const tkGroupRequests = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TK_GROUP_REQUEST_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case GET_TK_GROUP_REQUEST_LOADING_END:
      return {
        ...state,
        loading: false,
      };
    case GET_TK_GROUP_REQUEST_SUCCESS:
      return {
        ...state,
        data: payload,
        loading: false,
        error: null,
      };

    case GET_TK_GROUP_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    // approve /reject group

    case TK_APPROVE_REJECT_GROUP_START:
      return {
        ...state,
        approveRejectLoading: true,
      };

    case TK_APPROVE_REJECT_GROUP_END:
      return {
        ...state,
        approveRejectLoading: false,
      };

    case TK_APPROVE_REJECT_GROUP_SUCCESS:
      return {
        ...state,
        approveRejectData: payload,
        approveRejectLoading: false,
        approveRejectError: null,
      };
    case TK_APPROVE_REJECT_GROUP_FAIL:
      return {
        ...state,
        approveRejectLoading: false,
        approveRejectError: payload,
      };
    default:
      return state;
  }
};

export default tkGroupRequests;
