import React from "react";

import Typography from "@material-ui/core/Typography";
import useStyle from "./style";

export const H60Caps = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);

  return (
    <Typography
      variant="h1"
      className={`${_classes} ${classes.H60Caps}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const H50Caps = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);

  return (
    <Typography className={`${_classes} ${classes.H50Caps}`} {...props}>
      {children}
    </Typography>
  );
};

export const H40Caps = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="h2"
      className={`${_classes} ${classes.H40Caps}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const H30Caps = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="h3"
      className={`${_classes} ${classes.H30Caps}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const H30BoldCaps = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="h3"
      className={`${_classes} ${classes.H30BoldCaps}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const H30Bold = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="h3"
      className={`${_classes} ${classes.H30Bold}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const H24Caps = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="h4"
      className={`${_classes} ${classes.H24Caps}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const H22Caps = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="h5"
      className={`${_classes} ${classes.H22Caps}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const H20Caps = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="h5"
      className={`${_classes} ${classes.H20Caps}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const H18Caps = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="h5"
      className={`${_classes} ${classes.H18Caps}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const H16Caps = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="h6"
      className={`${_classes} ${classes.H16Caps}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const H16CapsLH = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="h6"
      className={`${_classes} ${classes.H16CapsLH}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const H14Caps = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="h6"
      className={`${_classes} ${classes.H14Caps}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const H14CapsLH = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="h6"
      className={`${_classes} ${classes.H14CapsLH}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const H14CapsS = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="h6"
      className={`${_classes} ${classes.H14CapsS}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const H14CapsI = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="h6"
      className={`${_classes} ${classes.H14CapsI}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const H60 = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="h1"
      className={`${_classes} ${classes.H60}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const H50 = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="h1"
      className={`${_classes} ${classes.H50}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const H40 = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="h2"
      className={`${_classes} ${classes.H40}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const H30 = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);

  return (
    <Typography
      variant="h3"
      className={`${_classes} ${classes.H30}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const H24 = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);

  return (
    <Typography
      variant="h4"
      className={`${_classes} ${classes.H24}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const H22 = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);

  return (
    <Typography
      variant="h5"
      className={`${_classes} ${classes.H22}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const H20 = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="h5"
      className={`${_classes} ${classes.H20}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const H18 = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="h5"
      className={`${_classes} ${classes.H18}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const H16 = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="h6"
      className={`${_classes} ${classes.H16}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const Body20B = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="body2"
      className={`${_classes} ${classes.Body20B}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const Body20 = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="body1"
      className={`${_classes} ${classes.Body20}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const Body18B = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="body1"
      className={`${_classes} ${classes.Body18B}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const Body18S = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="body1"
      className={`${_classes} ${classes.Body18S}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const Body18R = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="body1"
      className={`${_classes} ${classes.Body18R}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const Body16B = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="body1"
      className={`${_classes} ${classes.Body16B}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const Body16B5 = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="body1"
      className={`${_classes} ${classes.Body16B5}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const Body16M = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="body1"
      className={`${_classes} ${classes.Body16M}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const Body16R = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="body1"
      className={`${_classes} ${classes.Body16R}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const Body16I = (props) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography variant="body1" className={classes.Body16I} {...props}>
      {children}
    </Typography>
  );
};

export const Body14B = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="body1"
      className={`${_classes} ${classes.Body14B}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const Body14S = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="body1"
      className={`${_classes} ${classes.Body14S}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const Body14R = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="body1"
      className={`${_classes} ${classes.Body14R}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const Body14I = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="body1"
      className={`${_classes} ${classes.Body14I}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const Body18I = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="body1"
      className={`${_classes} ${classes.Body18I}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const Body12B = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="body1"
      className={`${_classes} ${classes.Body12B}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const Body12R = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="body1"
      className={`${_classes} ${classes.Body12R}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const LinkB20 = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="body1"
      className={`${_classes} ${classes.LinkB20}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const LinkB16 = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="body1"
      className={`${_classes} ${classes.LinkB16}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const LinkB14 = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="body1"
      className={`${_classes} ${classes.LinkB14}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const LinkS14 = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="body1"
      className={`${_classes} ${classes.LinkS14}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const Link14R = ({ classes: _classes, ...props }) => {
  const { children } = props;
  const classes = useStyle(props);
  return (
    <Typography
      variant="body1"
      className={`${_classes} ${classes.Link14R}`}
      {...props}
    >
      {children}
    </Typography>
  );
};
