import React from "react";

const Phone = ({ width, height, color, ...props }) => (
  <svg
    width={width}
    height={height}
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3282 22.5001C17.4132 22.5001 16.1278 22.1691 14.2032 21.0938C11.8627 19.7813 10.0524 18.5696 7.72457 16.2479C5.48019 14.0049 4.388 12.5527 2.85941 9.77115C1.13254 6.63052 1.42691 4.98427 1.75597 4.28068C2.14785 3.43974 2.72629 2.93677 3.47394 2.43755C3.89861 2.15932 4.34801 1.92081 4.81644 1.72505C4.86332 1.7049 4.90691 1.68568 4.94582 1.66833C5.17785 1.5638 5.52941 1.40583 5.97472 1.57458C6.27191 1.68615 6.53722 1.91443 6.95254 2.32458C7.80425 3.16458 8.96816 5.03537 9.39754 5.95412C9.68582 6.57333 9.8766 6.98208 9.87707 7.44052C9.87707 7.97724 9.60707 8.39115 9.27941 8.83787C9.218 8.92177 9.15707 9.00193 9.098 9.07974C8.74129 9.54849 8.663 9.68396 8.71457 9.92583C8.8191 10.4119 9.59863 11.859 10.8797 13.1372C12.1608 14.4155 13.5661 15.1458 14.0541 15.2499C14.3063 15.3038 14.4446 15.2222 14.9283 14.8529C14.9977 14.7999 15.0689 14.7451 15.1435 14.6902C15.6432 14.3185 16.0378 14.0555 16.5619 14.0555H16.5647C17.0208 14.0555 17.4113 14.2533 18.0582 14.5796C18.9019 15.0052 20.8289 16.1541 21.6741 17.0068C22.0852 17.4211 22.3144 17.6855 22.4264 17.9822C22.5952 18.429 22.4363 18.7791 22.3327 19.0135C22.3153 19.0524 22.2961 19.0951 22.276 19.1424C22.0787 19.61 21.8387 20.0585 21.5593 20.4821C21.061 21.2274 20.5561 21.8044 19.7133 22.1968C19.2805 22.4015 18.8069 22.5052 18.3282 22.5001Z"
      fill={color || "currentColor"}
    />
  </svg>
);
export default Phone;
