import axios from "axios";
import handleError from "./handle-errors";

const INSIGHTS_BASE = "/insights";

const getTempoInsights = async ({ options, params } = {}) => {
  try {
    const { data } = await axios.get(`${INSIGHTS_BASE}`, {
      params,
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getInsights = async ({
  options,
  spendVenueId,
  category,
  period,
} = {}) => {
  try {
    const { data } = await axios.get(`${INSIGHTS_BASE}`, {
      params: { spendVenueId, category, period },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getTopStats = async ({ options, category }) => {
  try {
    const { data } = await axios.get(INSIGHTS_BASE, { params: { category } });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getUserCurrentTc = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${INSIGHTS_BASE}/current-tc`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export { getInsights, getTempoInsights, getTopStats, getUserCurrentTc };
