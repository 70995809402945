import {
  GET_SPEND_PARTNERS_BASIC_ADMIN_LOADING_START,
  GET_SPEND_PARTNERS_BASIC_ADMIN_LOADING_END,
  GET_SPEND_PARTNERS_BASIC_ADMIN_SUCCESS,
  GET_SPEND_PARTNERS_BASIC_ADMIN_FAIL,
  CREATE_SPEND_VENUE_CRED_LOADING_START,
  CREATE_SPEND_VENUE_CRED_LOADING_END,
  CREATE_SPEND_VENUE_CRED_SUCCESS,
  CREATE_SPEND_VENUE_CRED_FAIL,
  UPDATE_SV_PARTNER_LOADING,
  UPDATE_SV_PARTNER_SUCCESS,
  UPDATE_SV_PARTNER_FAIL,
} from "../../../../redux/types";

const initialState = {
  spendPartners: [{}],
  getSpendPartnersLoading: false,
  getSpendPartnersError: {},
  updateSVPartnerLoading: false,
  updateSVPartnerError: {},
  createSVCredLoading: false,
  createSVCredError: {},
};

const staffEditSVAccount = (state = initialState, action) => {
  switch (action.type) {
    case GET_SPEND_PARTNERS_BASIC_ADMIN_LOADING_START:
      return { ...state, getSpendPartnersLoading: true };
    case GET_SPEND_PARTNERS_BASIC_ADMIN_LOADING_END:
      return { ...state, getSpendPartnersLoading: false };
    case GET_SPEND_PARTNERS_BASIC_ADMIN_SUCCESS:
      return {
        ...state,
        spendPartners: action.payload,
      };
    case GET_SPEND_PARTNERS_BASIC_ADMIN_FAIL:
      return { ...state, getSpendPartnersError: action.payload };

    case UPDATE_SV_PARTNER_LOADING:
      return { ...state, updateSVPartnerLoading: true };
    case UPDATE_SV_PARTNER_SUCCESS:
      return {
        ...state,
        updateSVPartnerLoading: false,
      };
    case UPDATE_SV_PARTNER_FAIL:
      return {
        ...state,
        updateSVPartnerError: action.payload,
        updateSVPartnerLoading: false,
      };

    case CREATE_SPEND_VENUE_CRED_LOADING_START:
      return { ...state, createSVCredLoading: true };
    case CREATE_SPEND_VENUE_CRED_LOADING_END:
      return { ...state, createSVCredLoading: false };
    case CREATE_SPEND_VENUE_CRED_SUCCESS:
      return {
        ...state,
        createSVCredError: {},
      };
    case CREATE_SPEND_VENUE_CRED_FAIL:
      return {
        ...state,
        createSVCredError: action.payload,
      };

    default:
      return state;
  }
};

export default staffEditSVAccount;
