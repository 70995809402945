import {
  GET_ORDERS_SUCCESS,
  GET_ORDERS_LOADING,
  GET_ORDERS_FAIL,
  CONFIRM_ORDER_CANCELED_LOADING,
  CONFIRM_ORDER_CANCELED_SUCCESS,
  CONFIRM_ORDER_CANCELED_FAIL,
} from "../../../../redux/types";

const initState = {
  pendingOrders: [],
  processedOrders: [],
  loading: false,
  error: null,
  markAsPaidError: null,
  markAsPaidLoading: false,
  cancelLoading: false,
  cancelSuccessfully: false,
  cancelError: null,
};

const orderReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ORDERS_LOADING:
      return {
        ...initState,
        loading: true,
      };
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        pendingOrders: payload?.pendingOrders,
        processedOrders: payload?.processedOrders,
        loading: false,
      };
    case GET_ORDERS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case CONFIRM_ORDER_CANCELED_LOADING:
      return {
        ...state,
        cancelLoading: true,
        cancelSuccessfully: false,
      };
    case CONFIRM_ORDER_CANCELED_SUCCESS:
      return {
        ...state,
        cancelLoading: false,
        cancelSuccessfully: true,
      };
    case CONFIRM_ORDER_CANCELED_FAIL:
      return {
        ...state,
        cancelLoading: false,
        cancelSuccessfully: false,
        cancelError: payload,
      };

    default:
      return state;
  }
};

export default orderReducer;
