import React from "react";

import { connect } from "react-redux";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import * as S from "../style";
import * as T from "../../../../components/Typograpy";
import { GENERAL } from "../../../../constants/navRoutes";

import { Tag } from "../../../../components/Tags";
import TimeCredit from "../../../../components/TimeCredit";
import OpportunityLink from "../../../../components/Links/OpportunityLink";
import GoBack from "../../../../components/GoBack";

import dummyEarnActivityPic from "../../../../assets/dummyAssets/dummyEarnActivityPic.png";

const EarnActivity = ({
  role,
  addressLine1,
  postcode,
  typology,
  price,
  link,
  requiredSkills,
  acquiredSkills,
  venueName,
  venueID,
  availability,
  contactNumber,
  contactEmail,
}) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <S.Wrapper>
      <GoBack
        showText
        p="0 0 0 30px"
        textColor={isTablet ? "gray1" : "white"}
      />
      <S.Header>
        <S.ImageWrapper img={dummyEarnActivityPic} />
        {isTablet ? (
          <T.H30Caps color="midnight" ml={30}>
            {role}
          </T.H30Caps>
        ) : (
          <T.H40Caps color="midnight" ml={30}>
            {role}
          </T.H40Caps>
        )}
        <Tag tagName={typology} m="10px 0 0 30px" justify="flex-start" />
      </S.Header>
      <S.TopDetails type="earn">
        <TimeCredit
          credits={price}
          description
          gradient
          color="blue"
          m="0 100px 30px 0"
        />
        <OpportunityLink
          path={GENERAL.SPEND_VENUE.replace(":id", venueID)}
          venue={venueName}
          address={`${addressLine1}, ${postcode}`}
        />
      </S.TopDetails>
      <T.H22Caps color="midnight" ml={isTablet ? 30 : 60}>
        Details
      </T.H22Caps>
      <S.SectionWrapper type="earn">
        {availability && (
          <S.Section type="earn">
            <T.H18 color="gray3" mb={5}>
              When we need you
            </T.H18>
            {availability.map((detail) => (
              <T.Body16R>{detail}</T.Body16R>
            ))}
          </S.Section>
        )}
        <S.Section type="earn">
          <T.H18 color="gray3" mb={5}>
            Skills we need
          </T.H18>
          <T.Body16R>
            {requiredSkills || "No required skills provided"}
          </T.Body16R>
        </S.Section>
        <S.Section type="earn">
          <T.H18 color="gray3" mb={5}>
            Skills you can learn
          </T.H18>
          <T.Body16R>{acquiredSkills || "No skills provided"}</T.Body16R>
        </S.Section>
        <S.Section type="earn">
          <T.H18 color="gray3" mb={5}>
            How to get in touch
          </T.H18>
          {contactEmail && (
            <T.Body16R>
              You can email us on{" "}
              <S.StyledLink
                to={`mailto:${contactEmail}`}
                href={`mailto:${contactEmail}`}
                target="_blank"
              >
                {contactEmail}
              </S.StyledLink>
            </T.Body16R>
          )}
          {contactNumber && (
            <T.Body16R>You can call us on {contactNumber}</T.Body16R>
          )}
          {link && (
            <S.StyledLink to={link} href={link} target="_blank">
              <T.LinkB16 color="blue">Visit our website</T.LinkB16>
            </S.StyledLink>
          )}
        </S.Section>
      </S.SectionWrapper>
    </S.Wrapper>
  );
};

// NEED TO UPDATE TO GET FROM REDUX STATE ONCE BACKEND IS DONE
const mapStateToProps = () => ({
  role: "Chef",
  addressLine1: "89-91 Turnpike Lane",
  addressLine2: null,
  postcode: "N8 9BT",
  city: "London",
  typology: "Professional Skills",
  price: 3,
  availability: ["Wednesdays 11am to 3pm"],
  requiredSkills: "Catering Experience, Enthusiasm, Reliability",
  acquiredSkills: "Food Hygience Level 2 and Catering",
  venueName: "Shine Enterprise Cafe",
  venueID: 2,
  contactNumber: "07944664187",
  contactEmail: "hello@shine.org.uk",
  memberID: "234234",
  link: null,
});

export default connect(mapStateToProps)(EarnActivity);

// export default SpendActivity;
