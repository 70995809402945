import {
  GET_SINGLE_TRANSACTION_SUCCESS,
  GET_SINGLE_TRANSACTION_LOADING,
  GET_SINGLE_TRANSACTION_FAIL,
} from "../types";

const initState = {
  loading: false,
  error: null,
  data: {
    type: "",
    activityDate: "",
    activityDetails: "",
    activityName: "",
    activityType: "",
    credits: "",
    date: "",
    receiverSubTitle: "",
    receiverType: "",
    receiverUserId: "",
    senderSubTitle: "",
    senderType: "",
    senderUserId: "",
  },
};

const singleTransactionReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    // MEMBERS
    case GET_SINGLE_TRANSACTION_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_SINGLE_TRANSACTION_SUCCESS:
      return {
        ...state,
        data: payload,
        loading: false,
      };
    case GET_SINGLE_TRANSACTION_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default singleTransactionReducer;
