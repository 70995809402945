import {
  GET_PAID_PACKAGES_LOADING,
  GET_PAID_PACKAGES_SUCCESS,
  GET_PAID_PACKAGES_FAIL,
  GET_ACTIVE_TC_PRICE_LOADING,
  GET_ACTIVE_TC_PRICE_SUCCESS,
  GET_ACTIVE_TC_PRICE_FAIL,
  UPDATE_PACKAGES_LOADING,
  UPDATE_PACKAGES_FAIL,
  UPDATE_PACKAGES_SUCCESS,
  CONFIRM_ORDER_INFO_LOADING,
  CONFIRM_ORDER_INFO_SUCCESS,
  CONFIRM_ORDER_INFO_FAIL,
  GET_ORDER_INFO_LOADING,
  GET_ORDER_INFO_SUCCESS,
  GET_ORDER_INFO_FAIL,
} from "../types";
import { paidPackages } from "../../api-calls";
import { EARN_GROUP } from "../../constants/navRoutes";

export const getPaidPackages = () => async (dispatch) => {
  dispatch({ type: GET_PAID_PACKAGES_LOADING, payload: true });
  const { data, error } = await paidPackages.getPaidPackages();
  dispatch({ type: GET_PAID_PACKAGES_LOADING, payload: false });

  if (!error) {
    dispatch({
      type: GET_PAID_PACKAGES_SUCCESS,
      payload: data,
    });
  } else {
    dispatch({
      type: GET_PAID_PACKAGES_FAIL,
      payload: error,
    });
  }
};

export const updatePackages = (body) => async (dispatch) => {
  dispatch({ type: UPDATE_PACKAGES_LOADING });
  try {
    const { error, data } = await paidPackages.updatePackages(body);
    if (error) {
      dispatch({
        type: UPDATE_PACKAGES_FAIL,
        payload: error,
      });
    } else {
      dispatch(getPaidPackages());
      dispatch({
        type: UPDATE_PACKAGES_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_PACKAGES_FAIL,
      payload: error,
    });
  }
};

export const getTcPrice = () => async (dispatch) => {
  dispatch({ type: GET_ACTIVE_TC_PRICE_LOADING, payload: true });
  const { data, error } = await paidPackages.getActivePrice();

  dispatch({ type: GET_ACTIVE_TC_PRICE_LOADING, payload: false });

  if (!error) {
    dispatch({
      type: GET_ACTIVE_TC_PRICE_SUCCESS,
      payload: data,
    });
  } else {
    dispatch({
      type: GET_ACTIVE_TC_PRICE_FAIL,
      payload: error,
    });
  }
};

export const confirmOrder =
  ({ id, body, history }) =>
  async (dispatch) => {
    dispatch({ type: CONFIRM_ORDER_INFO_LOADING, payload: true });
    const { data, error } = await paidPackages.confirmOrder({
      id,
      body,
    });

    dispatch({ type: CONFIRM_ORDER_INFO_LOADING, payload: false });

    if (!error) {
      dispatch({
        type: CONFIRM_ORDER_INFO_SUCCESS,
        payload: data,
      });
      if (history) {
        history.push(EARN_GROUP.ORDER_PAYMENT_SUCCESS);
      }
    } else {
      dispatch({
        type: CONFIRM_ORDER_INFO_FAIL,
        payload: error,
      });
    }
  };

export const getOrderDetails = (id) => async (dispatch) => {
  dispatch({ type: GET_ORDER_INFO_LOADING, payload: true });

  const { data, error } = await paidPackages.getOrderById({
    id,
  });

  dispatch({ type: GET_ORDER_INFO_LOADING, payload: false });

  if (!error) {
    dispatch({
      type: GET_ORDER_INFO_SUCCESS,
      payload: data,
    });
  } else {
    dispatch({
      type: GET_ORDER_INFO_FAIL,
      payload: error,
    });
  }
};
