import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as T from "../../../components/Typograpy";
import * as S from "./style";
import { Row, Col } from "../../../components/Grid";
import Table from "../../../components/Table";
import Icon from "../../../components/Icon";
import { Regular } from "../../../components/Button";

import t from "../../../utils/helpers/translator";
import dateFormatter from "../../../utils/helpers/dateFormatter";

import { getSpendVenueOffers } from "./action";

import { GENERAL, SPEND_VENUE } from "../../../constants/navRoutes";
import { roles } from "../../../constants";

import {
  customDateCol,
  twoButtonAction,
} from "../../../components/Table/config/reusableColumns";

const Offers = ({
  language,
  venueName,
  welshName,
  userID,
  getSpendVenueOffersAction,
  activities,
  loading,
  isMobile,
}) => {
  const [liveStats, setLiveStats] = useState({ live: 0, draft: 0 });
  const history = useHistory();

  const decideName = () =>
    (language === "welsh" && welshName) || venueName || "N/A";

  const decideActivityName = (name, nameWelsh) =>
    (language === "welsh" && nameWelsh) || name || "N/A";

  const clickRow = (event, rowData) => {
    history.push(GENERAL.SPEND_ACTIVITY.replace(":id", rowData.id));
  };

  const handleEditButton = (event, rowData) =>
    history.push(SPEND_VENUE.EDIT_OFFERS.replace(":id", rowData.id));

  const viewVenue = () =>
    history.push(GENERAL.SPEND_VENUE.replace(":id", userID));

  useEffect(() => {
    if (userID) {
      if (Number(userID)) {
        getSpendVenueOffersAction(userID);
      } else {
        history.push("/404");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID]);

  useEffect(() => {
    if (activities) {
      const live = activities.filter((activity) => activity?.isLive).length;
      const draft = activities.length - live;
      setLiveStats({ live, draft });
    }
  }, [activities]);

  const activityColumns = [
    {
      title: t("activityName", language),
      render: ({ name, nameWelsh }) => decideActivityName(name, nameWelsh),
    },
    customDateCol({
      title: t("endDate", language),
      field: "endDate",
    }),
    {
      title: t("status", language),
      render: ({ isLive }) =>
        isLive ? t("live", language) : t("draft", language),
    },
    twoButtonAction({
      firstButtonProp: {
        text: t("edit", language),
        onClick: (rowData) => handleEditButton(null, rowData),
        w: "auto",
        h: "auto",
        p: "5px 10px",
        color: "pink",
      },
      SecondButtonProp: {
        text: t("view", language),
        onClick: (rowData) => clickRow(null, rowData),
        w: "auto",
        h: "auto",
        p: "5px 10px",
        color: "blue",
      },
    }),
  ];

  const mobileCols = [
    {
      title: "Activity Name",
      field: language === "english" ? "name" : "nameWelsh",
    },
    {
      title: `${t("endDate")} / ${t("status")}`,
      render: ({ endDate, isLive }) => (
        <S.MobileCol>
          <span>
            {dateFormatter(endDate)}{" "}
            {isLive ? t("live", language) : t("draft", language)}
          </span>
          <Icon
            icon="arrowRight"
            width="20"
            height="20"
            color="blue"
            style={{ margin: "0 0 0 auto" }}
          />
        </S.MobileCol>
      ),
    },
  ];

  const clickAddOffer = () =>
    history.push(SPEND_VENUE.ADD_OFFERS.replace(":spendVenueId", userID));

  return (
    <>
      <S.Title>
        <S.TitleContent>
          <T.H20Caps color="pink" style={{ margin: "0" }}>
            {decideName()}
          </T.H20Caps>
          <T.H40Caps color="midnight" style={{ margin: "0" }}>
            {t("offers", language)}
          </T.H40Caps>
        </S.TitleContent>
        <Col w={[0, 4, 4]}>
          <Regular primary bgColor="blue" onClick={() => viewVenue()}>
            {t("viewVenuePage", language)}
          </Regular>{" "}
          <Regular primary bgColor="blue" onClick={clickAddOffer} mt={20}>
            {t("addOffer", language)}
          </Regular>
        </Col>
      </S.Title>
      <S.TitleMobile>
        <T.H18Caps color="pink" style={{ margin: "0" }}>
          {decideName()}
        </T.H18Caps>
        <T.H30Caps m="0" color="primary">
          {t("offers", language)}
        </T.H30Caps>
      </S.TitleMobile>
      <Row mt="6" mb="4">
        <Col w={[4, 6, 6]}>
          <S.Activities>
            <S.Activity>
              <S.NumberOfActivities color="#00A9CE">
                {liveStats.live || 0}
              </S.NumberOfActivities>
              <S.ActivityType>{t("liveActivities", language)}</S.ActivityType>
            </S.Activity>

            <S.Activity>
              <S.NumberOfActivities color="#0057A3">
                {liveStats.draft || 0}
              </S.NumberOfActivities>
              <S.ActivityType>{t("draftActivities", language)}</S.ActivityType>
            </S.Activity>
          </S.Activities>
        </Col>
      </Row>
      {isMobile ? (
        <Table
          color="blue"
          data={activities}
          isLoading={loading}
          columns={mobileCols}
          onRowClick={clickRow}
          hideSearch
        />
      ) : (
        <Table
          color="blue"
          data={activities}
          isLoading={loading}
          columns={activityColumns}
          onRowClick={clickRow}
          hideSearch
        />
      )}
      <S.MobileButton>
        <Col w={[4, 8, 4]}>
          <Regular primary bgColor="blue" onClick={() => viewVenue()}>
            {t("viewVenuePage", language)}
          </Regular>
        </Col>
      </S.MobileButton>
    </>
  );
};

const mapStateToProps = (state) => ({
  venueName:
    state.auth.role === roles.SPEND_VENUE
      ? state.auth.profile.name
      : state.auth.selectedSpendVenue.profile.name,
  welshName:
    state.auth.role === roles.SPEND_VENUE
      ? state.auth.profile.welshName
      : state.auth.selectedSpendVenue.profile.welshName,
  userID:
    state.auth.role === roles.SPEND_VENUE
      ? state.auth.id
      : state.auth.selectedSpendVenue.id,
  language: state.auth.language,
  activities: state.spendVenueOffers.activities,
  loading: state.spendVenueOffers.loading,
  isMobile: state.ui.isMobile,
});

const mapActionsToProps = {
  getSpendVenueOffersAction: getSpendVenueOffers,
};

export default connect(mapStateToProps, mapActionsToProps)(Offers);
