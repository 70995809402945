import {
  GET_EARN_GROUP_PROFILE_LOADING_START,
  GET_EARN_GROUP_PROFILE_LOADING_END,
  GET_EARN_GROUP_PROFILE_SUCCESS,
  GET_EARN_GROUP_PROFILE_FAIL,
  UPDATE_EARN_GROUP_PROFILE_LOADING_START,
  UPDATE_EARN_GROUP_PROFILE_LOADING_END,
  UPDATE_EARN_GROUP_PROFILE_SUCCESS,
  UPDATE_EARN_GROUP_PROFILE_FAIL,
  RESET_EARN_GROUP_PROFILE,
} from "../types";

const initialState = {
  getProfileLoading: false,
  data: {},
  getProfileError: {},
  getProfileSuccess: false,
  // update
  updateProfileLoading: false,
  updatedSuccessfully: {},
  updateProfileError: {},
  updateProfileSuccess: false,
};

const recruitVolunteersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_EARN_GROUP_PROFILE_LOADING_START:
      return {
        ...state,
        getProfileLoading: true,
        getProfileError: {},
      };
    case GET_EARN_GROUP_PROFILE_LOADING_END:
      return {
        ...state,
        getProfileLoading: false,
      };
    case GET_EARN_GROUP_PROFILE_SUCCESS:
      return {
        ...state,
        getProfileLoading: false,
        data: payload,
        getProfileSuccess: true,
      };
    case GET_EARN_GROUP_PROFILE_FAIL:
      return {
        ...state,
        getProfileLoading: false,
        getProfileError: payload,
        getProfileSuccess: false,
      };

    case UPDATE_EARN_GROUP_PROFILE_LOADING_START:
      return {
        ...state,
        updateProfileLoading: true,
        updateProfileSuccess: false,
        updateProfileError: {},
      };
    case UPDATE_EARN_GROUP_PROFILE_LOADING_END:
      return {
        ...state,
        updateProfileLoading: false,
      };
    case UPDATE_EARN_GROUP_PROFILE_SUCCESS:
      return {
        ...state,
        updateProfileLoading: false,
        updateProfileSuccess: true,
      };
    case UPDATE_EARN_GROUP_PROFILE_FAIL:
      return {
        ...state,
        updateProfileLoading: false,
        updateProfileError: payload,
        updateProfileSuccess: false,
      };

    case RESET_EARN_GROUP_PROFILE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default recruitVolunteersReducer;
