import React from "react";

import { Tag } from "../../Tags";
import Credits from "../components/Credits";
import DoubleLine from "../components/DoubleLine";
import LastActive from "../components/LastActive";
import ActivityDate from "../components/ActivityDate";
import GroupHeader from "../components/GroupHeader";
import MemberMobileActivity from "../components/MemberMobileActivity";
import * as T from "../../Typograpy";
import Icon from "../../Icon";
import { Regular } from "../../Button";

import dateFormatter from "../../../utils/helpers/dateFormatter";
import convertDateIntoMs from "../../../utils/helpers/convertDateIntoMs";
import t from "../../../utils/helpers/translator";
import { decideTypeTempo } from "../../../utils/helpers/decideTransactionType";

// GENERAL
export const dateCol = (title) => ({
  title: title || "Date",
  field: "date",
  customSort: (a, b) => convertDateIntoMs(a.date) - convertDateIntoMs(b.date),
  render: (rowData) => <span>{dateFormatter(rowData.date)}</span>,
});

export const customDateCol = (
  { title = "Date", field = "date" },
  customDateField,
) => ({
  title,
  field,
  customSort: (a, b) =>
    convertDateIntoMs(a[field]) - convertDateIntoMs(b[field]),
  render: (rowData) => (
    <span>
      {dateFormatter(
        customDateField ? rowData[customDateField] : rowData[field],
      )}
    </span>
  ),
});

export const typeCol = (title, language) => ({
  title: title || "Type",
  field: "type",
  render: (rowData) => {
    const { type } = rowData;

    return (
      <Tag
        tagName={decideTypeTempo(type)}
        size="m"
        customStyle={{
          textTransform: "uppercase",
        }}
        lang={language}
      />
    );
  },
});

export const creditsColWithTag = (title) => ({
  title: title || "# Time Credits",
  field: "credits",
  type: "numeric",
  render: (rowData) => (
    <Credits
      withTag
      withSymbol
      credits={rowData.credits}
      type={rowData.type}
      fromUser={rowData.fromUser}
      status={rowData.status}
    />
  ),
});

export const creditsCol = (title) => ({
  title: title || "# Time Credits",
  field: "credits",
  type: "numeric",
  render: (rowData) => (
    <Credits
      credits={rowData.credits}
      type={rowData.type}
      fromUser={rowData.fromUser}
    />
  ),
});

// TEMPO COLUMNS
// REMOVE OR CHANGE ROLE INPUTS NOT INCLUDED AS THEY REQUIRE FUNCTIONS
const tempoRoles = {
  tempoStaffUser: "Tempo Staff",
  tempoStaffAdmin: "Tempo Staff",
  tempoStaffSuper: "Tempo Staff",
};

export const fromCol = {
  title: "From",
  field: "fromSubTitle",
  render: (rowData) => {
    let { fromTitle } = rowData;
    if (fromTitle === "member") {
      fromTitle = "Volunteer";
    }
    if (tempoRoles[fromTitle]) {
      fromTitle = tempoRoles[fromTitle];
    } else {
      fromTitle = fromTitle.replace(/([a-z])([A-Z])/g, "$1 $2");
    }
    return <DoubleLine line1={fromTitle} line2={rowData.fromSubTitle} />;
  },
};

export const toCol = {
  title: "To",
  field: "toSubTitle",
  render: (rowData) => {
    let { toTitle } = rowData;
    if (toTitle === "member") {
      toTitle = "Volunteer";
    }
    if (tempoRoles[toTitle]) {
      toTitle = tempoRoles[toTitle];
    } else {
      toTitle = toTitle.replace(/([a-z])([A-Z])/g, "$1 $2");
    }
    return <DoubleLine line1={toTitle} line2={rowData.toSubTitle} />;
  },
};

export const memberCol = {
  title: "Volunteer",
  field: "member",
  customFilterAndSearch: (searchValue, { member, id }) =>
    member.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
    id.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()),
  render: (rowData) => (
    <DoubleLine
      line1={rowData.member}
      line2={rowData.id}
      status={rowData.status}
    />
  ),
};

export const lastActiveCol = {
  title: "Last Active",
  field: "lastActive",
  customSort: (a, b) =>
    convertDateIntoMs(a.lastActive) - convertDateIntoMs(b.lastActive),
  render: (rowData) =>
    rowData.lastActive ? (
      <LastActive
        date={dateFormatter(rowData.lastActive)}
        type={rowData.typeOfLastActivity}
      />
    ) : (
      "N/A"
    ),
};

const responses = {
  approved: "APPROVED",
  rejected: "REJECTED",
  partiallyApproved: "PARTIALLY APPROVED",
};
export const creditResponseCol = {
  title: "Action",
  field: "creditResponse",
  render: (rowData) => (
    <Tag
      tagName={responses[rowData.creditResponse]}
      size="m"
      customStyle={{
        textTransform: "uppercase",
      }}
    />
  ),
};

// SPEND VENUE

export const newReturningCol = (lang) => ({
  title: t("newReturningVisitor", lang),
  field: "visitorType",
  render: (rowData) => (
    <Tag
      tagName={rowData.visitorType}
      size="m"
      customStyle={{
        textTransform: "uppercase",
      }}
    />
  ),
});

// EARN GROUP

export const activityDateCol = (title, language) => ({
  title: title || "Activity / Date",
  field: "activity",
  customSort: (a, b) => convertDateIntoMs(a.date) - convertDateIntoMs(b.date),
  render: (rowData) => (
    <ActivityDate
      type={rowData.type}
      activity={rowData.activity}
      date={dateFormatter(rowData.date)}
      language={language}
    />
  ),
});

export const joinDateCol = (language) => ({
  title: language ? t("joined", language) : "Joined",
  field: "date",
  customSort: (a, b) => convertDateIntoMs(a.date) - convertDateIntoMs(b.date),
  render: (rowData) => <span>{dateFormatter(rowData.date)}</span>,
});

export const nameJoinDateCol = (language) => ({
  title:
    language === "english" ? "Member / Join Date" : t("nameJoined", language),
  field: "member",
  customSort: (a, b) => a.name - b.name,
  render: (rowData) => (
    <DoubleLine line1={rowData.member} line2={dateFormatter(rowData.date)} />
  ),
});

// MEMBER

export const mobileDateCol = {
  title: "",
  field: "date",
  render: (groupedData) => <GroupHeader header={groupedData} />,
  // defaultGroupOrder: 0,
  // defaultGroupSort: "desc",
  cellStyle: {
    display: "flex",
    alignItems: "flex-start",
    borderBottom: "none",
    borderTop: "none",
    padding: "0",
    width: "0",
    position: "absolute",
    "& b": {
      display: "none",
    },
  },
};

export const mobileMemberActivityCol = {
  title: "",
  field: "activity",
  customSort: (a, b) => a.activity - b.activity,
  render: (rowData) => (
    <MemberMobileActivity
      activity={rowData.activity}
      venue={rowData.venue}
      type={rowData.type}
      fromUser={rowData.fromUser}
      date={rowData.date}
      pending={rowData.status === "pending"}
    />
  ),
  cellStyle: {
    width: "85%",
    padding: "0",
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
  },
};

export const mobileMemberCreditsCol = {
  title: "",
  field: "credits",
  customSort: (a, b) => a.credits - b.credits,
  render: (rowData) => (
    <Credits
      withSymbol
      credits={rowData.credits}
      type={rowData.type}
      fromUser={rowData.fromUser}
      justify="flex-end"
      pr="0"
    />
  ),
  cellStyle: {
    padding: "0",
    width: "10%",
    display: "flex",
    alignItems: "flex-end",
    margin: "0 0 0 auto",
    textAlign: "right",
  },
};

export const emailCol = {
  title: "Email",
  field: "email",
  render: ({ email }) => (
    <a href={`mailto:${email}`}>
      <T.LinkS14 color="blue">{email}</T.LinkS14>
    </a>
  ),
};

export const checkedUnchecked = ({ title, field }) => ({
  title,
  field,
  render: (data) => (
    <Icon
      icon={data[field] ? "tickRound" : "no"}
      width="20px"
      height="20px"
      margin="0 10px 0 0"
      color={data[field] ? "white" : "pink"}
    />
  ),
});

export const buttonAction = ({
  title,
  field,
  text,
  color = "pink",
  onClick,
  w,
  h,
  p,
}) => ({
  title,
  field,
  disableClick: true,
  render: (rowData) => (
    <Regular
      customWidth={w}
      customHeight={h}
      p={p}
      primary
      bgColor={color}
      onClick={() => onClick(rowData)}
    >
      {text}
    </Regular>
  ),
});

export const twoButtonAction = ({
  title,
  field,
  firstButtonProp,
  SecondButtonProp,
}) => ({
  title,
  field,
  disableClick: true,
  render: (rowData) => (
    <div style={{ display: "flex" }}>
      <Regular
        customWidth={firstButtonProp.w}
        customHeight={firstButtonProp.h}
        p={firstButtonProp.p}
        bgColor={firstButtonProp.color}
        onClick={() => firstButtonProp.onClick(rowData)}
        mr="20"
      >
        {firstButtonProp.text}
      </Regular>
      <Regular
        customWidth={SecondButtonProp.w}
        customHeight={SecondButtonProp.h}
        p={SecondButtonProp.p}
        primary={SecondButtonProp.primary}
        bgColor={SecondButtonProp.color}
        onClick={() => SecondButtonProp.onClick(rowData)}
      >
        {SecondButtonProp.text}
      </Regular>
    </div>
  ),
});
