import { fields, createSchema, validate as _validate } from "..";

const { customTCRequested } = fields;

const requestData = {
  customCredits: customTCRequested,
};

const schema = createSchema(requestData);

const validate = (data) => _validate(schema, data);

export default validate;
