import { programmes } from "../../../../api-calls";

import {
  GET_STAFF_SINGLE_PROGRAMME_LOADING,
  GET_STAFF_SINGLE_PROGRAMME_SUCCESS,
  GET_STAFF_SINGLE_PROGRAMME_FAIL,
  GET_STAFF_PROGRAMME_EARN_GROUPS_LOADING,
  GET_STAFF_PROGRAMME_EARN_GROUPS_SUCCESS,
  GET_STAFF_PROGRAMME_EARN_GROUPS_FAIL,
} from "../../../../redux/types";

export const getProgrammeById = (programmeId) => async (dispatch) => {
  dispatch({ type: GET_STAFF_SINGLE_PROGRAMME_LOADING });
  try {
    const { data, error } = await programmes.getProgramme({
      programmeId,
      type: "stats",
      options: { showModal: true },
    });
    if (data) {
      dispatch({ type: GET_STAFF_SINGLE_PROGRAMME_SUCCESS, payload: data });
    } else {
      dispatch({
        type: GET_STAFF_SINGLE_PROGRAMME_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_STAFF_SINGLE_PROGRAMME_FAIL,
      payload: error,
    });
  }
};

export const getProgrammeEarnGroup =
  ({ programmeId, startDate, endDate }) =>
  async (dispatch) => {
    dispatch({ type: GET_STAFF_PROGRAMME_EARN_GROUPS_LOADING });
    try {
      const { data, error } = await programmes.getProgrammeEarnGroup({
        programmeId,
        options: { showModal: true },
        startDate,
        endDate,
      });
      if (data) {
        dispatch({
          type: GET_STAFF_PROGRAMME_EARN_GROUPS_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: GET_STAFF_PROGRAMME_EARN_GROUPS_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_STAFF_PROGRAMME_EARN_GROUPS_FAIL,
        payload: error,
      });
    }
  };
