import translations from "../../constants/translations";

const translate = (string, lang, customData, customDivider) => {
  // IF NO LANG PROVIDED DEFAULT TO ENGLISH
  if (!lang) return string;
  if (typeof string !== "string") {
    // console.warn("Value to be translated not a string");
    return string;
  }

  const translatedWords = translations[lang][string];

  if (!translatedWords && lang === "english") {
    // console.warn("No translation exists");
    return string;
  }

  if (!translatedWords) {
    const defaultEng = translations["english"][string];
    if (!defaultEng) {
      // console.warn("No translation exists");
      return string;
    }
    // console.warn("Cannont find a translation for the selected language");
    return translatedWords;
  }

  let finalTranslation = translatedWords;

  if (customData) {
    customData.forEach((item, i) => {
      const re = new RegExp(`{CUSTOM${i + 1}}`, "gi");
      finalTranslation = customDivider
        ? finalTranslation.replace(re, `|${item}`)
        : finalTranslation.replace(re, item);
    });
  }

  return finalTranslation;
};

export default translate;
