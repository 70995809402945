import React, { useCallback } from "react";
import { connect } from "react-redux";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import moment from "moment";
import { Row, Col } from "../../components/Grid";
import Button from "../../components/Button";
import Warning from "../../components/Message/Warning";

import * as T from "../../components/Typograpy";
import * as S from "./style";
import theme from "../../utils/theme";
import t from "../../utils/helpers/translator";

import {
  InputField,
  Checkbox,
  SingleDatePicker,
} from "../../components/Inputs";
import DateSection from "./DateSection";
import { repeatDropDownEnglish } from "../../constants/dropdDownData";
import { roles } from "../../constants";

const Step2 = ({
  data,
  welshVersion,
  setState,
  errors,
  handleNext,
  loading,
  edit,
  setErrors,
  language,
  userRole,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleCheckbox = useCallback(({ target: { checked } }) => {
    if (checked) {
      setState((_) => ({
        ..._,
        dateTimes: [
          {
            dates: {},
            times: {},
            repeats: "none",
          },
        ],
        latestUseDate: null,
      }));
      setErrors((_) => {
        const datesErrors = {};
        const findDatesKeys = Object.keys(_).filter((key) =>
          key.startsWith("dateTimes"),
        );
        // eslint-disable-next-line no-unused-expressions
        findDatesKeys.length > 0 &&
          findDatesKeys.forEach((key) => {
            datesErrors[key] = false;
          });
        return { ..._, ...datesErrors };
      });
    }
    return setState((_) => ({
      ..._,
      isAvailableAnyTime: checked,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIsLive = useCallback(
    ({ target: { checked } }) =>
      setState((_) => ({
        ..._,
        isLive: checked,
      })),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const handleFeaturedAt = useCallback(
    ({ target: { checked } }) =>
      setState((_) => ({
        ..._,
        featuredAt: checked ? new Date() : 0,
      })),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const isTempoStaff = [
    roles.TEMPO_STAFF_SUPER,
    roles.TEMPO_STAFF_ADMIN,
    roles.TEMPO_STAFF_USER,
  ].includes(userRole);

  const handleDateTime = useCallback(
    (index, updatedValue, type) => {
      setState((_) => {
        const updatedDateTimes = _.dateTimes.map((group, i) => {
          if (i === index) {
            let newGroup = { ...group };
            if (type === "dates") {
              newGroup = { ...group, dates: updatedValue.dates };
            }
            if (type === "times") {
              const { value } = updatedValue;
              const cleanValue =
                updatedValue.name === "endTime" && value === "00:00"
                  ? "23:59"
                  : value;
              newGroup = {
                ...group,
                times: {
                  ...group.times,
                  [updatedValue.name]: cleanValue,
                },
              };
            }
            if (type === "repeat") {
              newGroup = { ...group, repeats: updatedValue.selected.value };
            }
            return newGroup;
          }
          return group;
        });
        const sortedDates = updatedDateTimes
          .filter((e) => e.repeats === "none")
          .sort((a, b) => a?.dates?.endDate?.diff(b?.dates?.endDate));

        const latestUseDate =
          sortedDates[sortedDates.length - 1]?.dates?.endDate;

        return { ..._, dateTimes: updatedDateTimes, latestUseDate };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data.dateTimes],
  );

  const handleRemoveDate = useCallback(
    (e, index) => {
      e.preventDefault();

      setState((_) => {
        const updatedDateTimes = _.dateTimes.filter(
          (group) => group !== data.dateTimes[index],
        );

        const sortedDates = _.dateTimes.sort((a, b) =>
          a?.dates?.endDate?.diff(b?.dates?.endDate),
        );
        const latestUseDate =
          sortedDates[sortedDates.length - 1]?.dates?.endDate;

        return {
          ..._,
          dateTimes: updatedDateTimes,
          latestUseDate,
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data.dateTimes],
  );

  const handleAddNewDate = useCallback(
    (e) => {
      e.preventDefault();
      setState((_) => ({
        ..._,
        dateTimes: _.dateTimes
          ? _.dateTimes.concat({
              dates: {},
              times: {},
              repeats: "none",
            })
          : [
              {
                dates: {},
                times: {},
                repeats: "none",
              },
            ],
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data.dateTimes],
  );

  const handleEndDate = useCallback((newDate) => {
    setState((_) => ({
      ..._,
      endDate: newDate,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleText = useCallback(({ target: { name, value } }) => {
    switch (name) {
      case "restrictions":
        return setState((_) => ({ ..._, restrictions: value }));

      // Welsh
      case "restrictionsWelsh":
        return setState((_) => ({ ..._, restrictionsWelsh: value }));

      case "capacity":
        return setState((_) => ({ ..._, capacity: value }));

      case "capacityMonth":
        return setState((_) => ({ ..._, capacityMonth: value }));

      case "capacityVolunteerMonth":
        return setState((_) => ({ ..._, capacityVolunteerMonth: value }));

      case "capacityWeek":
        return setState((_) => ({ ..._, capacityWeek: value }));

      case "capacityVolunteerWeek":
        return setState((_) => ({ ..._, capacityVolunteerWeek: value }));

      case "capacityVolunteer":
        return setState((_) => ({ ..._, capacityVolunteer: value }));

      case "capacitySlot":
        return setState((_) => ({ ..._, capacitySlot: value }));

      case "capacityPeoplePerTransaction":
        return setState((_) => ({ ..._, capacityPeoplePerTransaction: value }));

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const takeDownDate =
    data.endDate && data.latestUseDate
      ? moment.max(
          moment(data.endDate, "YYYY-MM-DD"),
          moment(data.latestUseDate),
        )
      : (data.endDate && moment(data.endDate, "YYYY-MM-DD")) ||
        (data.latestUseDate && moment(data.latestUseDate));

  return (
    <>
      <Row mb={5}>
        <Col w={welshVersion ? [4, 6, 6] : [4, 12, 6]}>
          <T.H24 color="midnight" m="0 0 15px 0">
            {t("whenUseTC", language)}
          </T.H24>
          <T.Body16R color="gray3" m="0 0 10px 0">
            {t("provideActivityDates", language)}
          </T.Body16R>
        </Col>
      </Row>
      <Row mb={5}>
        <Col w={[4, 8, 8]}>
          <Checkbox
            value={data.isAvailableAnyTime}
            setValue={handleCheckbox}
            mt="10"
            label={t("useTcAnyTime", language)}
            name="isAvailableAnyTime"
            size={isMobile ? "normal" : "medium"}
          />
        </Col>
      </Row>
      {!data.isAvailableAnyTime &&
        data.dateTimes &&
        data.dateTimes.map((group, index) => (
          <DateSection
            index={index}
            handleDateTime={handleDateTime}
            dates={group.dates}
            times={group.times}
            repeats={repeatDropDownEnglish.find(
              (e) => e.value === group.repeats,
            )}
            repeatOptions={repeatDropDownEnglish}
            disabled={data.isAvailableAnyTime}
            removeDate={handleRemoveDate}
            datesLength={data.dateTimes.length}
            errors={errors[`dateTimes[${index}]`]}
            language={language}
            disablePast
          />
        ))}
      {!data.isAvailableAnyTime && (
        <Row mb={6} mt={5}>
          <Col w={[4, 6, 6]}>
            <S.AddDate onClick={handleAddNewDate}>
              <T.LinkB14 color="blue">
                + {t("addAnotherDate", language)}
              </T.LinkB14>
            </S.AddDate>
          </Col>
        </Row>
      )}
      <Row mb={5}>
        <Col w={[4, 12, 6]}>
          <SingleDatePicker
            name="endDate"
            label={t("whenActivityEnd", language)}
            placeholder="DD/MM/YYYY"
            value={data.endDate}
            setValue={handleEndDate}
            singleDatePicker
            mb={4}
            error={errors.endDate}
            subLabel={`(${t("whenActivityEndSubtitle", language)})`}
            disableFuture={false}
          />
        </Col>
      </Row>
      {takeDownDate && (
        <Row mb={4}>
          <Col w={[4, 8, 6]}>
            <Warning
              header={`This offer will be automatically taken down from the public view after ${takeDownDate?.format(
                "DD/MM/YYYY",
              )}`}
              warningType="dateRestriction"
            />
          </Col>
        </Row>
      )}
      <Row mb={8}>
        <Col w={[4, 12, 6]}>
          <InputField
            placeholder={`${t("typeHere", language)}...`}
            label={`${t("restrictionsOnDates", language)}${
              language === "welsh" ? ` (${t("english", language)})` : ""
            }`}
            isOptional
            name="restrictions"
            type="textarea"
            value={data.restrictions}
            setValue={handleText}
            error={errors.restrictions}
            justify="center"
            helper={t("restrictionsOnDatesPlaceholderHelper", language)}
            showLimit
            limit={500}
            lang={language}
          />
        </Col>
        {welshVersion && (
          <Col w={[4, 12, 6]}>
            <InputField
              placeholder={`${t("typeHere", language)}...`}
              label={`${t("restrictionsOnDates", language)}${
                language === "english" ? ` (${t("welsh", language)})` : ""
              }`}
              labelColor="blue"
              isOptional
              name="restrictionsWelsh"
              type="textarea"
              value={data.restrictionsWelsh}
              setValue={handleText}
              error={errors.restrictionsWelsh}
              justify="center"
              helper={t("restrictionsOnDatesPlaceholderHelper", language)}
              showLimit
              limit={500}
              lang={language}
            />
          </Col>
        )}
      </Row>
      <Row mb={5}>
        <Col w={[4, 12, 5]} mb={4}>
          <InputField
            placeholder={t("typeNumber", language)}
            label={t("totalCapacityLabel", language)}
            name="capacity"
            value={data.capacity}
            setValue={handleText}
            error={errors.capacity}
            justify="center"
            type="number"
            isOptional
            lang={language}
          />
        </Col>
        <Col w={[4, 12, 5]} mb={4}>
          <InputField
            placeholder={t("typeNumber", language)}
            label={t("totalCapacityPerVolunteerLabel", language)}
            name="capacityVolunteer"
            value={data.capacityVolunteer}
            setValue={handleText}
            error={errors.capacityVolunteer}
            justify="center"
            type="number"
            isOptional
            lang={language}
          />
        </Col>
        <Col w={[4, 12, 5]} mb={4}>
          <InputField
            placeholder={t("typeNumber", language)}
            label={t("totalCapacityPerWeekLabel", language)}
            name="capacityWeek"
            value={data.capacityWeek}
            setValue={handleText}
            error={errors.capacityWeek}
            justify="center"
            type="number"
            isOptional
            lang={language}
          />
        </Col>
        <Col w={[4, 12, 5]} mb={4}>
          <InputField
            placeholder={t("typeNumber", language)}
            label={t("totalCapacityPerVolunteerPerWeekLabel", language)}
            name="capacityVolunteerWeek"
            value={data.capacityVolunteerWeek}
            setValue={handleText}
            error={errors.capacityVolunteerWeek}
            justify="center"
            type="number"
            isOptional
            lang={language}
          />
        </Col>
        <Col w={[4, 12, 5]} mb={4}>
          <InputField
            placeholder={t("typeNumber", language)}
            label={t("totalCapacityPerMonthLabel", language)}
            name="capacityMonth"
            value={data.capacityMonth}
            setValue={handleText}
            error={errors.capacityMonth}
            justify="center"
            type="number"
            isOptional
            lang={language}
          />
        </Col>
        <Col w={[4, 12, 5]} mb={4}>
          <InputField
            placeholder={t("typeNumber", language)}
            label={t("totalCapacityPerVolunteerPerMonthLabel", language)}
            name="capacityVolunteerMonth"
            value={data.capacityVolunteerMonth}
            setValue={handleText}
            error={errors.capacityVolunteerMonth}
            justify="center"
            type="number"
            isOptional
            lang={language}
          />
        </Col>
        {/* <Col w={[4, 12, 4]} mb={4}>
          <InputField
            placeholder={t("typeNumber", language)}
            label={t("totalCapacityPerSlotLabel", language)}
            name="capacitySlot"
            value={data.capacitySlot}
            setValue={handleText}
            error={errors.capacitySlot}
            justify="center"
            type="number"
            isOptional
            lang={language}
          />
        </Col> */}
        <Col w={[4, 12, 5]} mb={4}>
          <InputField
            placeholder={t("typeNumber", language)}
            label={t("totalCapacityPerTransactionLabel", language)}
            name="capacityPeoplePerTransaction"
            value={data.capacityPeoplePerTransaction}
            setValue={handleText}
            error={errors.capacityPeoplePerTransaction}
            justify="center"
            type="number"
            isOptional
            lang={language}
          />
        </Col>
      </Row>

      {edit && !data.hideIsLiveCheckbox && (
        <Row mb={5}>
          <Col w={[4, 8, 8]}>
            <Checkbox
              value={data.isLive}
              setValue={handleIsLive}
              mt="10"
              label={t("makeActivityLive", language)}
              name="isLive"
              size={isMobile ? "normal" : "medium"}
              error={errors.isLive}
            />
          </Col>
        </Row>
      )}
      {edit && !data.hideIsLiveCheckbox && isTempoStaff && (
        <Row mb={5}>
          <Col w={[4, 8, 8]}>
            <Checkbox
              value={data.featuredAt}
              setValue={handleFeaturedAt}
              mt="10"
              label={t("makeActivityFeatured", language)}
              name="featuredAt"
              size={isMobile ? "normal" : "medium"}
              error={errors.featuredAt}
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col w={[4, 6, 4]}>
          <Button
            primary
            size="l"
            handleClick={() => handleNext(1)}
            loading={loading}
          >
            {edit ? t("saveChanges", language) : t("nextStep", language)}
          </Button>
        </Col>
        {Object.keys(errors).filter((key) => errors[key]).length > 0 && (
          <Col w={[4, 12, 12]} style={{ marginTop: 20 }}>
            <S.Error>{t("activityFormStepError", language)}</S.Error>
          </Col>
        )}
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  language: state.auth.language,
});
export default connect(mapStateToProps, null)(Step2);
