import React from "react";

import { Row, Col } from "../../../components/Grid";

import { InputField } from "../../../components/Inputs";
import UploadImage from "../../../components/UploadImage";
import stripLeadingTab from "../../../utils/helpers/stripLeadingTab";

const VenueDetailsAndImages = ({
  handleInput,
  logoImageInfo,
  setLogoImageInfo,
  coverImageInfo,
  setCoverImageInfo,
  startUpload,
  formData,
  errors,
  welshVersion,
}) => {
  const {
    contactNumber = stripLeadingTab(formData.contactNumber),
    contactEmail,
    description,
    descriptionWelsh,
    accessibilityWebsiteUrl,
  } = formData;

  return (
    <>
      <Row mb={6}>
        <Col w={[4, 6, 4]}>
          <InputField
            placeholder="Type their public phone number..."
            label="Contact number"
            name="contactNumber"
            value={contactNumber}
            setValue={handleInput}
            error={errors.contactNumber}
            justify="center"
            isOptional
          />
        </Col>
        <Col w={[4, 6, 4]}>
          <InputField
            placeholder="Type their public email address..."
            label="Contact email"
            name="contactEmail"
            value={contactEmail}
            setValue={handleInput}
            error={errors.contactEmail}
            justify="center"
            isOptional
          />
        </Col>
      </Row>
      <Row mb={5}>
        <Col w={[4, 6, 6]}>
          <InputField
            placeholder="Type here..."
            label="About the Venue"
            name="description"
            value={description}
            setValue={handleInput}
            error={errors.description}
            justify="center"
            type="textarea"
            limit={300}
            showLimit
          />
        </Col>
        {welshVersion && (
          <Col w={[4, 6, 6]}>
            <InputField
              placeholder="Type here..."
              label="About the Venue (Welsh)"
              name="descriptionWelsh"
              value={descriptionWelsh}
              setValue={handleInput}
              error={errors.descriptionWelsh}
              justify="center"
              type="textarea"
              limit={300}
              showLimit
              labelColor="blue"
            />
          </Col>
        )}
      </Row>
      <Row mb={6}>
        <Col w={[4, 6, 4]}>
          <InputField
            placeholder="Type full url..."
            label="Link to their Accessibility information"
            name="accessibilityWebsiteUrl"
            value={accessibilityWebsiteUrl}
            setValue={handleInput}
            error={errors.accessibilityWebsiteUrl}
            justify="center"
            isOptional
          />
        </Col>
      </Row>
      <Row mb={8}>
        <Col w={[4, 6, 4]}>
          <UploadImage
            placeholder="Drag logo here or click"
            setImageInfo={setLogoImageInfo}
            startUpload={startUpload}
            imageInfo={logoImageInfo}
            imageType="logo"
            maxSize={2e6}
            maxSizeText="2MB"
            secondaryText="file size max 2mb"
            error={errors.logoImageKey}
          />
        </Col>
        <Col w={[4, 6, 4]}>
          <UploadImage
            placeholder="Drag image here or click"
            setImageInfo={setCoverImageInfo}
            startUpload={startUpload}
            imageInfo={coverImageInfo}
            imageType="cover"
            maxSize={2e6}
            maxSizeText="2MB"
            secondaryText="min dimensions 820 x 280, file size max 2mb"
            error={errors.coverImageKey}
          />
        </Col>
      </Row>
    </>
  );
};

export default VenueDetailsAndImages;
