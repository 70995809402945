/* eslint-disable import/prefer-default-export */
import {
  CHANGE_COLOR,
  GET_USER_TC_BALANCE_LOADING,
  GET_USER_TC_BALANCE_SUCCESS,
  GET_USER_TC_BALANCE_FAIL,
  GET_USER_TRANSACTIONS_LOADING,
  GET_USER_TRANSACTIONS_SUCCESS,
  GET_USER_TRANSACTIONS_FAIL,
  CANCEL_GIFT_LOADING_START,
  CANCEL_GIFT_SUCCESS,
  CANCEL_GIFT_FAIL,
  IS_MOBILE,
} from "../types";

import { gifts, insights, transactions } from "../../api-calls";

export const toggleUIcolor = (color) => (dispatch) => {
  dispatch({ type: CHANGE_COLOR, payload: color });
};

export const setMobile = (isMobile) => (dispatch) => {
  dispatch({ type: IS_MOBILE, payload: isMobile });
};

export const getUserCurrentTc = () => async (dispatch) => {
  dispatch({ type: GET_USER_TC_BALANCE_LOADING });
  try {
    const { data, error } = await insights.getUserCurrentTc();
    if (data) {
      dispatch({ type: GET_USER_TC_BALANCE_SUCCESS, payload: { ...data } });
    } else {
      dispatch({
        type: GET_USER_TC_BALANCE_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_USER_TC_BALANCE_FAIL,
      payload: error,
    });
  }
};

export const getUserTransactions = (limit, offset) => async (dispatch) => {
  dispatch({ type: GET_USER_TRANSACTIONS_LOADING });
  try {
    const { data, error } = await transactions.getUserTransactions({
      limit,
      offset,
    });

    if (data) {
      dispatch({ type: GET_USER_TRANSACTIONS_SUCCESS, payload: data });
    } else {
      dispatch({
        type: GET_USER_TRANSACTIONS_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_USER_TRANSACTIONS_FAIL,
      payload: error,
    });
  }
};

const cancelGiftLogicFunctions = {
  showLoadingGiftCancel(id, getState) {
    const newTData = getState().user.transactions.data.map((t) => {
      if (t.type === "pending" && t.giftId === +id) {
        return { ...t, cancelGiftLoading: true };
      }
      return t;
    });
    return {
      type: CANCEL_GIFT_LOADING_START,
      payload: newTData,
    };
  },

  filterAfterDeletingGift(id, getState) {
    const userTransaction = getState().user.transactions.data;
    const userTransactionObject = {
      deletedGiftTC: 0,
      newTDataAfterDeletingGift: [],
    };

    userTransaction.forEach((t) => {
      if (t.giftId !== +id) {
        userTransactionObject.newTDataAfterDeletingGift.push(t);
      } else {
        userTransactionObject.deletedGiftTC = t.tc;
      }
    });

    const TC = getState().user.tc;
    // add back the deleted gift balance to user credits
    const tcBalance =
      TC.tcBalance + Number(userTransactionObject.deletedGiftTC);

    return {
      type: CANCEL_GIFT_SUCCESS,
      payload: {
        tcBalance,
        data: userTransactionObject.newTDataAfterDeletingGift,
      },
    };
  },

  addCancelingGiftError(id, getState, error) {
    const cancelGiftErrorState = getState().user.transactions.data.map((t) => {
      if (t.type === "pending" && t.giftId === +id) {
        return { ...t, cancelGiftLoading: false, cancelGiftError: error };
      }
      return t;
    });

    return {
      type: CANCEL_GIFT_FAIL,
      payload: cancelGiftErrorState,
    };
  },
};

export const cancelGift = (id) => async (dispatch, getState) => {
  dispatch(cancelGiftLogicFunctions.showLoadingGiftCancel(id, getState));

  const { error } = await gifts.cancelGiftById({ id });

  if (!error) {
    dispatch(cancelGiftLogicFunctions.filterAfterDeletingGift(id, getState));
  } else {
    dispatch(
      cancelGiftLogicFunctions.addCancelingGiftError(id, getState, error),
    );
  }
};
