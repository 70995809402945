import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import * as S from "./style";
import * as T from "../../../components/Typograpy";

import Icon from "../../../components/Icon";
import Image from "../../../components/Image";
import Button from "../../../components/Button";
import { Row, Col } from "../../../components/Grid";

import Step2 from "./Step2";
import GiftSuccess from "./GiftSuccess";
import GiftHistory from "./GiftHistory";
import GiftTable from "./GiftTable";
import * as actions from "./actions";
import * as userActions from "../../../redux/actions/userActions";
import GiftReceived from "./GiftReceived";

import { MEMBER } from "../../../constants/navRoutes";

import { email as eamilSchema } from "../../../validation/fields";

import translate from "../../../utils/helpers/translator";

const GiftCredits = ({
  balance,
  gifts,
  getGifts,
  getGiftsLoading,
  getGiftsLoaded,
  createGift,
  getEarnGroups,
  earnGroups,
  getUserCurrentTc,
  createGiftError,
  createGiftLoading,
  cancelGift,
  lang,
}) => {
  // const [stage, setStage] = useState(1);
  const [stage, setStage] = useState(2);
  // const [giftOption, setGiftOption] = useState("earnGroup");
  // eslint-disable-next-line no-unused-vars
  const [giftOption, setGiftOption] = useState("friends");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [credits, setCredits] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [attemptToSubmit, setAttemptToSubmit] = useState(false);
  const history = useHistory();

  // const chooseOption = (option) => {
  //   setGiftOption(option);
  //   setStage(stage + 1);
  // };

  const clearFieldsWithCancelBtn = () => {
    setEmail("");
    setCredits(0);
  };

  const handleEmail = (e) => {
    if (attemptToSubmit && giftOption === "friends") {
      const isValidEmail = eamilSchema.isValidSync(e.target.value);
      if (isValidEmail) {
        setEmailError("");
      }
    }

    setEmail(e.target.value);
  };

  const handleGroup = (selected) => {
    setSelectedGroup([selected.selected]);
  };

  const handleCredits = (newCredits) => setCredits(newCredits.counter);

  const readyToSend = () => {
    if (giftOption === "friends") {
      return credits > 0 && email && credits <= balance;
    }
    if (giftOption === "earnGroup") {
      return credits > 0 && selectedGroup.length && credits <= balance;
    }
    return false;
  };

  const handleCancel = (id) => cancelGift(id);

  useEffect(() => {
    getGifts();
    getEarnGroups();
    getUserCurrentTc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    const isValidEmail = eamilSchema.isValidSync(email);
    setAttemptToSubmit(true);

    if (giftOption === "earnGroup" || isValidEmail) {
      setEmailError("");
      return createGift({
        email,
        tc: credits,
        type: giftOption,
        toUserId: selectedGroup[0] && selectedGroup[0].id,
        earnGroupName: selectedGroup[0] && selectedGroup[0].profile.name,
        history,
      });
    }

    setEmailError(translate("invalidEmail", lang));
  };
  return (
    <S.Wrapper>
      <Row>
        <Col w={[4, 12, 12]}>
          <S.Header>
            <Icon
              icon="gift"
              color="pink"
              width="70px"
              height="70px"
              margin="0 20px 0 0"
            />
            <T.H30Caps color="midnight" style={{ width: "80%" }}>
              {translate("giftTC", lang)}
            </T.H30Caps>
          </S.Header>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 12]}>
          <T.Body16R color="gray2" mb={40}>
            {translate("youCanGiftExplanationLong", lang)}
          </T.Body16R>
        </Col>
      </Row>
      {stage === 1 ? (
        <>
          <S.ButtonWrapper>
            <S.ImageWrapper>
              <Image image="giftShapes1" alt="shapes" />
            </S.ImageWrapper>
            <Button
              primary
              style={{ margin: "0 0 30px 60px", width: "250px" }}
              // onClick={() => chooseOption("friends")}
            >
              Treat Friends & Family
            </Button>
          </S.ButtonWrapper>
          <S.ButtonWrapper>
            <S.ImageWrapper right>
              <Image image="giftShapes2" alt="shapes" />
            </S.ImageWrapper>
            <Button
              primary
              style={{ margin: "0 0 30px 0", width: "250px" }}
              // onClick={() => chooseOption("earnGroup")}
            >
              Give Back to My Group
            </Button>
          </S.ButtonWrapper>
          <T.H18Caps color="blue" mb={20}>
            PREVIOUS GIFTS
          </T.H18Caps>
          {getGiftsLoaded && (
            <GiftTable
              gifts={gifts}
              handleCancel={handleCancel}
              preview
              previewPath={MEMBER.GIFT_HISTORY}
              isLoading={getGiftsLoading}
            />
          )}
        </>
      ) : (
        <Step2
          giftOption={giftOption}
          handleEmail={handleEmail}
          handleGroup={handleGroup}
          memberGroups={earnGroups}
          selectedGroup={selectedGroup}
          credits={credits}
          handleCredits={handleCredits}
          balance={balance}
          readyToSend={readyToSend}
          onSubmit={onSubmit}
          createGiftError={createGiftError}
          createGiftLoading={createGiftLoading}
          setStage={setStage}
          emailError={emailError}
          clearFields={clearFieldsWithCancelBtn}
          email={email}
          lang={lang}
        />
      )}
    </S.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  balance: state.user.tc.tcBalance,
  gifts: state.giftTimeCredits.gifts,
  getGiftsLoading: state.giftTimeCredits.getGiftsLoading,
  getGiftsLoaded: state.giftTimeCredits.getGiftsLoaded,
  earnGroups: state.giftTimeCredits.earnGroups,
  createGiftError: state.giftTimeCredits.createGiftError,
  createGiftLoading: state.giftTimeCredits.createGiftLoading,
  lang: state.auth.language,
});
const mapActionToProps = {
  getGifts: actions.getGifts,
  createGift: actions.createGift,
  getEarnGroups: actions.getEarnGroups,
  getUserCurrentTc: userActions.getUserCurrentTc,
  cancelGift: actions.cancelGift,
};

export { GiftSuccess, GiftHistory, GiftReceived };
export default connect(mapStateToProps, mapActionToProps)(GiftCredits);
