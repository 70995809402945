import styled from "@material-ui/core/styles/styled";

export const Container = styled("div")(() => ({
  width: "100%",
}));

export const Head = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

export const TableContainer = styled("div")({
  marginTop: 30,
  display: "flex",
  flexDirection: "column",
});

export const BtnContainer = styled("div")({
  alignSelf: "flex-end",
  transform: "translateY(195px)",
  zIndex: 13,
});

export const ImageWrapper = styled("div")({
  minWidth: 220,
  height: 210,
  transform: "translate(-10px, -30px)",
});

export const ModalContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
});

export const FilterContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
});

export const SearchWrapper = styled("div")({
  justifyItems: "center",
  marginBottom: "10px",
});
