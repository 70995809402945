import { styled } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import MaterialTable, { MTablePagination } from "material-table";
import theme from "../../utils/theme";

export const StyledTable = styled(MaterialTable)(() => ({}));
export const TableContainer = styled("div")(() => ({
  position: "relative",
}));
export const LoadingStyledTable = styled(MaterialTable)(() => ({}));

export const StyledPagination = styled(MTablePagination)(() => ({
  height: "100%",

  "& span": {
    fontSize: "14px",
  },
}));

export const StyledPageBtn = styled("button")(
  ({ selected, color, disabled }) => ({
    outline: "none",
    border: `1px solid ${selected ? theme.colors[color] : theme.colors.gray3}`,
    borderRadius: "4px",
    width: "32px",
    height: "32px",
    background: selected && theme.colors[color],
    color: selected ? theme.colors.white : theme.colors.gray2,
    margin: "20px 3px",
    cursor: "pointer",
    opacity: disabled && "0.3",
  }),
);

export const PaginationWrapper = styled("td")(() => ({
  padding: theme.spacing(1),
  paddingBottom: 0,
  display: "flex",
  alignItems: "center",
}));

export const StyledCredit = styled("span")(({ justify, pr, pending }) => ({
  display: "flex",
  justifyContent: justify || "flex-start",
  paddingRight: pr || 40,
  paddingLeft: pending && "5px",

  [theme.breakpoints.mobileL]: {
    justifyContent: "flex-start",
    paddingRight: 0,
  },
}));

export const StyledLink = styled(Link)(() => ({
  textDecoration: "none",
}));

export const DateTypeWrapper = styled("span")(() => ({
  display: "flex",
}));

export const MemberActivityWrapper = styled("span")(({ date }) => ({
  display: "flex",
  alignItems: "center",
  paddingTop: date ? "55px" : "10px",
  height: "100%",
}));

export const Overlay = styled("div")(() => ({
  background: theme.colors.white,
  opacity: "0.4",
}));

export const TabContainer = styled("div")(() => ({
  position: "absolute",
  top: "90px",
  zIndex: 100,
}));

export const ToolbarContent = styled("div")(({ tabbedData }) => ({
  marginBottom: tabbedData ? "60px" : "20px",
}));

const decideColor = (color) => {
  switch (color) {
    case "pink":
      return {
        background: "pinkLight",
        headerBorder: "pink",
        rowBorder: "pinkMed",
      };
    default:
      return {
        background: "cyanLight",
        headerBorder: "cyan",
        rowBorder: "cyanMed",
      };
  }
};
export const rowStyle = (color, memberMobile) => ({
  borderBottomColor: theme.colors[decideColor(color).rowBorder],
  border: memberMobile && "none",
  display: memberMobile && "flex",
});

export const cellStyle = (color) => ({
  borderBottomColor: theme.colors[decideColor(color).rowBorder],
  fontSize: "0.875rem",
  color: theme.colors.gray1,
  fontWeight: "400",
  textAlign: "left",
});

export const searchFieldStyle = {
  fontSize: "14px",
  color: theme.colors.gray3,
  border: `1px solid ${theme.colors.gray5}`,
  borderRadius: "10px",
  height: "50px",
  minWidth: "240px",
  maxWidth: "300px",
  background: theme.colors.white,
  paddingLeft: "10px",
  marginLeft: "-20px",
  // marginBottom: tabbedData ? "90px" : "20px",
  marginBottom: "20px",
  "&::placeholder": {
    background: "red",
  },
};

export const headerStyle = (color, memberMobile) => ({
  backgroundColor: theme.colors[decideColor(color).background],
  color: theme.colors.midnight,
  fontWeight: "bold",
  textTransform: "uppercase",
  fontSize: "0.875rem",
  borderBottom: `1px solid ${theme.colors[decideColor(color).headerBorder]}`,
  display: memberMobile && "none",
  textAlign: "left",
});

export const style = {
  boxShadow: "none",
  background: "none",
  width: "100%",
};
