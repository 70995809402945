import {
  earnGroups,
  members,
  transactions,
  programmes,
} from "../../../../api-calls";

import {
  GET_STAFF_SINGLE_EARNGROUP_LOADING,
  GET_STAFF_SINGLE_EARNGROUP_SUCCESS,
  GET_STAFF_SINGLE_EARNGROUP_FAIL,
  GET_EARN_GROUPS_MEMBERS_SUCCESS,
  GET_EARN_GROUPS_MEMBERS_LOADING,
  GET_EARN_GROUPS_MEMBERS_FAIL,
  GET_STAFF_SINGLE_EARNGROUP_TRANSACTIONS_LOADING,
  GET_STAFF_SINGLE_EARNGROUP_TRANSACTIONS_SUCCESS,
  GET_STAFF_SINGLE_EARNGROUP_TRANSACTIONS_FAIL,
  UPDATE_PROGRAMME_EARN_GROUP_STATUS_SUCCESS,
  UPDATE_PROGRAMME_EARN_GROUP_STATUS_LOADING,
  UPDATE_PROGRAMME_EARN_GROUP_STATUS_FAIL,
  RESET_STAFF_SINGLE_EARNGROUP,
  DELETE_EARNGROUP_LOADING_START,
  DELETE_EARNGROUP_LOADING_END,
  DELETE_EARNGROUP_SUCCESS,
  DELETE_EARNGROUP_FAIL,
} from "../../../../redux/types";

export const getEarnGroupByUserId = (id) => async (dispatch) => {
  dispatch({ type: GET_STAFF_SINGLE_EARNGROUP_LOADING });
  try {
    const { data, error } = await earnGroups.getEarnGroupByUserId({
      id,
    });
    if (data) {
      dispatch({ type: GET_STAFF_SINGLE_EARNGROUP_SUCCESS, payload: data });
    } else {
      dispatch({
        type: GET_STAFF_SINGLE_EARNGROUP_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_STAFF_SINGLE_EARNGROUP_FAIL,
      payload: error,
    });
  }
};

export const getMembers = (id, limit, offset) => async (dispatch) => {
  dispatch({ type: GET_EARN_GROUPS_MEMBERS_LOADING });
  try {
    const { data, error } = await members.getMembers({
      limit,
      offset,
      id,
      category: "earngroups",
    });
    if (data) {
      dispatch({
        type: GET_EARN_GROUPS_MEMBERS_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: GET_EARN_GROUPS_MEMBERS_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_EARN_GROUPS_MEMBERS_FAIL,
      payload: error,
    });
  }
};

export const getTransactions = (id, limit, offset) => async (dispatch) => {
  dispatch({ type: GET_STAFF_SINGLE_EARNGROUP_TRANSACTIONS_LOADING });
  try {
    const { data, error } = await transactions.getUserTransactions({
      limit,
      offset,
      earnGroupId: id,
      category: "earngroups",
    });
    if (data) {
      dispatch({
        type: GET_STAFF_SINGLE_EARNGROUP_TRANSACTIONS_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: GET_STAFF_SINGLE_EARNGROUP_TRANSACTIONS_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_STAFF_SINGLE_EARNGROUP_TRANSACTIONS_FAIL,
      payload: error,
    });
  }
};

export const updateProgrammeEarnGroup =
  ({ earnGroupId, programmeId, status }) =>
  async (dispatch) => {
    dispatch({ type: UPDATE_PROGRAMME_EARN_GROUP_STATUS_LOADING });
    try {
      const { error } = await programmes.updateProgrammeEarnGroup({
        earnGroupId,
        programmeId,
        status,
      });

      if (error) {
        dispatch({
          type: UPDATE_PROGRAMME_EARN_GROUP_STATUS_FAIL,
          payload: error,
        });
      } else {
        dispatch({
          type: UPDATE_PROGRAMME_EARN_GROUP_STATUS_SUCCESS,
          payload: { earnGroupId, status },
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_PROGRAMME_EARN_GROUP_STATUS_FAIL,
        payload: error,
      });
    }
  };

export const resetState = () => ({
  type: RESET_STAFF_SINGLE_EARNGROUP,
});

export const deleteEarnGroup =
  ({ id }) =>
  async (dispatch) => {
    dispatch({ type: DELETE_EARNGROUP_LOADING_START });

    try {
      const { error } = await earnGroups.deleteEarnGroup({ id });

      if (!error) {
        dispatch({ type: DELETE_EARNGROUP_SUCCESS, payload: id });
      } else {
        dispatch({ type: DELETE_EARNGROUP_FAIL, payload: error });
      }
    } catch (error) {
      dispatch({ type: DELETE_EARNGROUP_FAIL, payload: error });
    } finally {
      dispatch({ type: DELETE_EARNGROUP_LOADING_END });
    }
  };
