import React from "react";

const Menu = ({ width, height, color, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 33 32"
    width={width}
    height={height}
    {...props}
  >
    <path
      d="M5.156 9.98h22.688M5.156 15.968h22.688M5.156 21.956h22.688"
      stroke={color || "currentColor"}
      strokeWidth="2.063"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
export default Menu;
