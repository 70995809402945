import React, { useEffect, useState } from "react";
import { Row, Col } from "../../../components/Grid";
import Icon from "../../../components/Icon";
import * as T from "../../../components/Typograpy";
import * as S from "./style";
import t from "../../../utils/helpers/translator";
import { auth } from "../../../api-calls";

const CardFields = ({ lang, braintreeRef, setError }) => {
  const [tokenObject, setTokenObject] = useState({});

  useEffect(() => {
    const getToken = async () => {
      const { data, error } = await auth.getBrainTreeClientToken();
      if (!error) {
        setTokenObject(data);
      } else {
        setError(error.message);
      }
    };

    getToken();
  }, []);

  useEffect(() => {
    const initiateBrainTree = async () => {
      if (
        !window?.braintree?.client.create ||
        !tokenObject ||
        typeof tokenObject !== "string"
      ) {
        return;
      }

      try {
        const clientInstance = await window.braintree.client.create({
          // Insert your tokenization key here
          authorization: tokenObject,
        });
        const dataCollectorInstance =
          await window.braintree.dataCollector.create({
            client: clientInstance,
          });

        const { deviceData } = dataCollectorInstance;

        const threeDSecureInstance = await window.braintree.threeDSecure.create(
          {
            version: 2, // Will use 3DS2 whenever possible
            client: clientInstance,
          },
        );

        threeDSecureInstance.on("lookup-complete", (data, next) => {
          // eslint-disable-next-line no-param-reassign
          braintreeRef.current.lookupNonce = data.paymentMethod.nonce;
          // call next when ready to proceed with the challenge
          next();
        });

        // eslint-disable-next-line no-param-reassign
        braintreeRef.current.deviceData = deviceData;

        const threeDSecure = threeDSecureInstance;

        // eslint-disable-next-line no-param-reassign
        braintreeRef.current.threeDSecure = threeDSecure;

        // Create a hostedFields component to initialize the form

        const instance = await window.braintree.hostedFields.create({
          client: clientInstance,
          // Customize the Hosted Fields.
          // More information can be found at:
          // https://developers.braintreepayments.com/guides/hosted-fields/styling/javascript/v3
          styles: {
            input: {
              "font-size": "14px",
            },
            "input.invalid": {
              color: "red",
            },
            "input.valid": {
              color: "green",
            },
          },
          // Configure which fields in your card form will be generated by Hosted Fields instead
          fields: {
            number: {
              container: "#card-number",
              placeholder: "4111 1111 1111 1111",
            },
            cvv: {
              container: "#cvv",
              placeholder: "123",
            },
            expirationDate: {
              container: "#expiration-date",
              placeholder: "10/2022",
            },
          },
        });

        // eslint-disable-next-line no-param-reassign
        braintreeRef.current.instance = instance;
      } catch (error) {
        setError(error.message);
      }
    };

    if (tokenObject && window.braintree.client) {
      initiateBrainTree();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenObject, window.braintree.client]);

  if (!tokenObject) {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <>
      <form action="/checkout" id="hosted-fields-form" method="post">
        <Row mt={6}>
          <Col w={[4, 12, 12]}>
            <T.Body20B color="midnight">{t("payByCard", lang)}</T.Body20B>
          </Col>
        </Row>
        <Row>
          <Col w={[4, 4, 4]} mt={3}>
            <label htmlFor="card-number">
              <T.Body16B color="midnight" ml={15}>
                {t("cardNumber", lang)}
              </T.Body16B>
            </label>
            <S.CUstomInput id="card-number">
              <Icon
                icon="paymentCard"
                color="midnight"
                width="40"
                height="20"
              />
            </S.CUstomInput>
          </Col>
          <Col w={[4, 4, 4]} mt={3}>
            <label htmlFor="cvv">
              <T.Body16B color="midnight" ml={15}>
                {t("CVV", lang)}
              </T.Body16B>
            </label>
            <S.CUstomInput id="cvv" />
          </Col>
          <Col w={[4, 4, 4]} mt={3}>
            <label htmlFor="expiration-date">
              <T.Body16B color="midnight" ml={15}>
                {t("expiryDate", lang)}
              </T.Body16B>
            </label>
            <S.CUstomInput id="expiration-date" />
          </Col>
          <div id="checkout-message" />
        </Row>
      </form>
    </>
  );
};

export default CardFields;
