import React from "react";

import Icon from "../Icon";
import * as T from "../Typograpy";
import * as S from "./style";

import translate from "../../utils/helpers/translator";
import linkFormatter from "../../utils/helpers/linkFormatter";

const VenueDetails = ({
  addresses,
  phoneNum,
  email,
  facebook,
  twitter,
  url,
  logo,
  isOnline,
  instagram,
  youtube,
  type,
  lang,
  isPrivateAddress,
}) => (
  <S.Wrapper>
    <T.H18
      m="0 0 20px 0"
      color="midnight"
      style={{ textTransform: "capitalize" }}
    >
      {translate("details", lang)}
    </T.H18>
    {addresses && addresses.length > 0 ? (
      addresses.map((address) => (
        <S.ListItem>
          <Icon
            icon="location"
            color="cyan"
            width="25px"
            height="25px"
            margin="0 15px 0 0"
          />
          {isPrivateAddress ? (
            <S.Link
              href={linkFormatter(`${address.city}`, "googleMaps")}
              target="_blank"
              rel="noopener noreferrer"
            >
              <S.Address>
                <T.Body16R color="gray3" m="0">
                  {address.city}
                </T.Body16R>
              </S.Address>
            </S.Link>
          ) : (
            <S.Link
              href={linkFormatter(
                `${address.addressLine1}, ${address.city}, ${address.postcode}`,
                "googleMaps",
              )}
              target="_blank"
              rel="noopener noreferrer"
            >
              <S.Address>
                <T.Body16R color="gray3" m="0">
                  {address.addressLine1},
                </T.Body16R>
                <T.Body16R color="gray3" m="0">
                  {address.addressLine2 &&
                    address.addressLine2.length > 0 &&
                    `${address.addressLine2}, `}
                  {address.city},
                </T.Body16R>
                <T.Body16R color="gray3" m="0">
                  {address.postcode}
                </T.Body16R>
              </S.Address>
            </S.Link>
          )}
        </S.ListItem>
      ))
    ) : (
      <S.ListItem>
        <Icon
          icon="location"
          color="cyan"
          width="25px"
          height="25px"
          margin="0 15px 0 0"
        />
        <S.Address>
          <T.Body16R color="gray3" m="0">
            {isOnline ? translate("online", lang) : "-"}
          </T.Body16R>
        </S.Address>
      </S.ListItem>
    )}
    {phoneNum && (
      <S.ListItem>
        <Icon
          icon="phone"
          color="cyan"
          width="25px"
          height="25px"
          margin="0 15px 0 0"
        />
        <S.Link href={`tel:${phoneNum}`}>
          <T.Body16R color="gray3">{phoneNum}</T.Body16R>
        </S.Link>
      </S.ListItem>
    )}
    {email && (
      <S.ListItem>
        <Icon
          icon="email"
          color="cyan"
          width="25px"
          height="25px"
          margin="0 15px 0 0"
        />
        <S.Link href={`mailto:${email}`}>
          <T.Body16R color="gray3">{email}</T.Body16R>
        </S.Link>
      </S.ListItem>
    )}
    {facebook && (
      <S.ListItem>
        <Icon
          icon="facebook"
          color="cyan"
          width="25px"
          height="25px"
          margin="0 15px 0 0"
        />
        <S.Link
          href={facebook.startsWith("http") ? facebook : `http://${facebook}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <T.Body16R color="gray3">
            {linkFormatter(facebook, "facebook")}
          </T.Body16R>
        </S.Link>
      </S.ListItem>
    )}

    {twitter && (
      <S.ListItem>
        <Icon
          icon="twitter"
          color="cyan"
          width="25px"
          height="25px"
          margin="0 15px 0 0"
        />
        <S.Link
          href={twitter.startsWith("http") ? twitter : `http://${twitter}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <T.Body16R color="gray3">
            {linkFormatter(twitter, "twitter")}
          </T.Body16R>
        </S.Link>
      </S.ListItem>
    )}

    {type === "spendVenue" && instagram && (
      <S.ListItem>
        <Icon
          icon="instagram"
          color="cyan"
          width="25px"
          height="25px"
          margin="0 15px 0 0"
        />
        <S.Link
          href={
            instagram.startsWith("http") ? instagram : `http://${instagram}`
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <T.Body16R color="gray3">
            {linkFormatter(instagram, "instagram")}
          </T.Body16R>
        </S.Link>
      </S.ListItem>
    )}

    {type === "spendVenue" && youtube && (
      <S.ListItem>
        <Icon
          icon="youtube"
          color="cyan"
          width="25px"
          height="25px"
          margin="0 15px 0 0"
        />
        <S.Link
          href={youtube.startsWith("http") ? youtube : `http://${youtube}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <T.Body16R color="gray3">
            {linkFormatter(youtube, "youtube")}
          </T.Body16R>
        </S.Link>
      </S.ListItem>
    )}

    {url && (
      <S.ListItem>
        <Icon
          icon="computer"
          color="cyan"
          width="25px"
          height="25px"
          margin="0 15px 0 0"
        />
        <S.Link
          href={url.startsWith("http") ? url : `http://${url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <T.Body16R color="gray3">{linkFormatter(url)}</T.Body16R>
        </S.Link>
      </S.ListItem>
    )}

    {logo && <S.LogoWrapper logo={logo} />}
  </S.Wrapper>
);

export default VenueDetails;
