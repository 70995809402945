import React, { useCallback } from "react";
import { connect } from "react-redux";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import WhatToKnow from "./WhatToKnow";
import { Row, Col } from "../../components/Grid";
import Button from "../../components/Button";
import { Checkbox } from "../../components/Inputs";
import * as S from "./style";
import theme from "../../utils/theme";
import t from "../../utils/helpers/translator";
import { roles } from "../../constants";

const Step3 = ({
  welshVersion,
  data = {},
  setState,
  errors,
  handleNext,
  loading,
  edit,
  language,
  userRole,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleIsLive = useCallback(
    ({ target: { checked } }) =>
      setState((_) => ({
        ..._,
        isLive: checked,
      })),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const handleFeaturedAt = useCallback(
    ({ target: { checked } }) =>
      setState((_) => ({
        ..._,
        featuredAt: checked ? new Date() : 0,
      })),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const isTempoStaff = [
    roles.TEMPO_STAFF_SUPER,
    roles.TEMPO_STAFF_ADMIN,
    roles.TEMPO_STAFF_USER,
  ].includes(userRole);

  const handleText = useCallback(({ target: { value, name } }) => {
    let _value = value;
    if (value === "true") _value = true;
    if (value === "false") _value = false;
    switch (name) {
      case "isBookingRequiredForGroups":
        return setState((_) => ({
          ..._,
          isBookingRequiredForGroups:
            _.isBookingRequiredForGroups === _value ? null : _value,
        }));

      case "maxGroupSize":
        return setState((_) => ({
          ..._,
          maxGroupSize:
            Number(value) <= 0 && value !== ""
              ? Math.max(Math.abs(value), 1)
              : value,
        }));

      case "furtherInformation":
        return setState((_) => ({
          ..._,
          furtherInformation: value,
        }));

      // Welsh
      case "furtherInformationWelsh":
        return setState((_) => ({
          ..._,
          furtherInformationWelsh: value,
        }));

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <WhatToKnow
        isBookingRequiredForGroups={data.isBookingRequiredForGroups}
        maxGroupSize={data.maxGroupSize}
        furtherInformation={data.furtherInformation}
        handleText={handleText}
        errors={errors}
        // Welsh
        welshVersion={welshVersion}
        furtherInformationWelsh={data.furtherInformationWelsh}
        language={language}
      />

      {edit && !data.hideIsLiveCheckbox && (
        <Row mb={5}>
          <Col w={[4, 8, 8]}>
            <Checkbox
              value={data.isLive}
              setValue={handleIsLive}
              mt="10"
              label={t("makeActivityLive", language)}
              name="isLive"
              size={isMobile ? "normal" : "medium"}
              error={errors.isLive}
            />
          </Col>
        </Row>
      )}
      {edit && !data.hideIsLiveCheckbox && isTempoStaff && (
        <Row mb={5}>
          <Col w={[4, 8, 8]}>
            <Checkbox
              value={data.featuredAt}
              setValue={handleFeaturedAt}
              mt="10"
              label={t("makeActivityFeatured", language)}
              name="featuredAt"
              size={isMobile ? "normal" : "medium"}
              error={errors.featuredAt}
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col w={[4, 6, 4]}>
          <Button
            primary
            size="l"
            handleClick={() => handleNext(2)}
            loading={loading}
          >
            {edit ? t("saveChanges", language) : t("nextStep", language)}
          </Button>
        </Col>
        {Object.keys(errors).filter((key) => errors[key]).length > 0 && (
          <Col w={[4, 12, 12]} style={{ marginTop: 20 }}>
            <S.Error>{t("activityFormStepError", language)}</S.Error>
          </Col>
        )}
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  language: state.auth.language,
});
export default connect(mapStateToProps, null)(Step3);
