import React from "react";
import { connect } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import Icon from "../Icon";
import * as S from "./style";
import * as T from "../Typograpy";
import Button from "../Button";

import { Row, Col } from "../Grid";

import t from "../../utils/helpers/translator";

const WarningMessage = ({
  open,
  setOpen,
  handleClick,
  title,
  titleColor,
  nameOrEmail,
  text,
  children,
  lang,
  withoutBack,
  buttonText,
  handleModalClose,
  disabled,
  loading,
  cancelText,
}) => {
  const handleClose = () => {
    if (typeof handleModalClose === "function") {
      handleModalClose();
    }
    setOpen(false);
  };

  const onClick = async () => {
    if (typeof handleClick === "function") {
      await handleClick();
    }
    setOpen(false);
  };

  return (
    <S.Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: { backgroundColor: "rgba(255,255,255,0.9)" },
      }}
    >
      <S.Container>
        <Row mb={1}>
          <Col w={[3, 8, 8]}>
            <S.Title>
              <T.Body20B color={titleColor || "midnight"} m="0">
                {title}
              </T.Body20B>
            </S.Title>
          </Col>
          <Col
            w={[1, 4, 4]}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Icon
              icon="alertTriangle"
              width="66px"
              height="66px"
              color="pink"
            />
          </Col>
        </Row>
        <Row mb={5}>
          <Col w={[4, 12, 12]}>
            {children || (
              <>
                {nameOrEmail && (
                  <T.Body14B color="midnight" style={{ display: "inline" }}>
                    {nameOrEmail}{" "}
                  </T.Body14B>
                )}
                <T.Body14R style={{ display: "inline" }} color="gray3">
                  {text}
                </T.Body14R>
              </>
            )}
          </Col>
        </Row>
        <Row style={{ paddingLeft: 10, paddingRight: 10 }}>
          {!withoutBack && (
            <S.ButtonWrapper style={{ marginRight: 18 }}>
              <Button outline onClick={() => handleClose()} mb={10}>
                {cancelText || t("goBack", lang)}
              </Button>
            </S.ButtonWrapper>
          )}
          <S.ButtonWrapper>
            <Button
              onClick={onClick}
              mb={10}
              disabled={disabled}
              loading={loading}
            >
              {buttonText || t("confirm", lang)}
            </Button>
          </S.ButtonWrapper>
        </Row>
      </S.Container>
    </S.Modal>
  );
};

const mapStateToProps = (state) => ({
  lang: state.auth.decideLanguage(state.auth),
});

export default connect(mapStateToProps)(WarningMessage);
