import styled from "@material-ui/core/styles/styled";

export const Container = styled("div")({
  width: "100%",
});

export const Head = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

export const TableContainer = styled("div")({
  marginTop: 30,
  display: "flex",
  flexDirection: "column",
});

export const BtnContainer = styled("div")({
  alignSelf: "flex-end",
  transform: "translateY(60px)",
  zIndex: 3,
  display: "flex",
});

export const ImageWrapper = styled("div")({
  minWidth: 250,
  height: 210,
  transform: "translate(30px, -30px)",
});

export const CapitalizedSpan = styled("span")({
  textTransform: "capitalize",
});

export const CsvExport = styled("a")(({ theme }) => ({
  textDecoration: "none",
  marginBottom: theme.spacing(2),
  display: "block",
}));
export const SearchWrapper = styled("div")({
  justifyItems: "center",
  width: "365px",
  marginTop: "-2px",
  marginBottom: "10px",
});
