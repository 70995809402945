import { styled } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

export const TransactionsWrapper = styled("section")(() => ({
  display: "flex",
  position: "relative",
  flex: "1",
  flexDirection: "column",
}));

export const Header = styled("header")(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

export const StyledLink = styled(Link)(() => ({
  textDecoration: "underline",
}));

export const Content = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",

  [theme.breakpoints.mobileS]: {
    paddingTop: theme.spacing(5),
  },

  [theme.breakpoints.laptop]: {
    paddingTop: theme.spacing(6),
  },
}));

export const Details = styled("div")(({ theme }) => ({
  width: "100%",
  marginBottom: theme.spacing(6),

  [theme.breakpoints.mobileXL]: {
    width: "50%",
  },
}));

export const TransactionSectionWrapper = styled("section")(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),

  [theme.breakpoints.mobileXL]: {
    paddingLeft: "0",
    paddingRight: "0",
  },
}));

export const TableContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
});

export const BtnContainer = styled("div")({
  alignSelf: "flex-end",
  transform: "translateY(60px)",
  zIndex: 3,
});
