import { useEffect, useRef } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { logout, refreshSession } from "../../redux/actions/auth";
import { useHistory } from "react-router-dom";

const HOW_OFTEN_TO_REFRESH_SESSION = 20000;

const SessionManagement = (props) => {
  const {
    lastActive,
    expires,
    isLoggedIn,
    logoutAction,
    refreshSessionAction,
  } = props;

  const history = useHistory();

  const logoutRef = useRef(null);
  const isLoggedInRef = useRef(null);
  const lastActiveRef = useRef(null);

  isLoggedInRef.current = isLoggedIn;
  lastActiveRef.current = lastActive;

  useEffect(() => {
    if (!expires) {
      return;
    }

    if (logoutRef.current) {
      clearTimeout(logoutRef.current);
    }

    logoutRef.current = setTimeout(() => {
      if (isLoggedInRef.current) {
        logoutAction({ history });
      }
    }, millisecondsUntil(expires));
  }, [expires, history, logoutAction]);

  useEffect(() => {
    const refresher = setInterval(async () => {
      if (isLoggedInRef.current && usedTheAppWithinTimePeriod(lastActiveRef)) {
        const { error } = await refreshSessionAction();

        if (error) {
          await logoutAction({ history });
        }
      }
      return () => clearInterval(refresher);
    }, HOW_OFTEN_TO_REFRESH_SESSION);
  }, [history, logoutAction, refreshSessionAction]);

  return null;
};

function usedTheAppWithinTimePeriod(lastActiveRef) {
  return (
    lastActiveRef.current &&
    moment().diff(moment(lastActiveRef.current), "ms") <
      HOW_OFTEN_TO_REFRESH_SESSION
  );
}

function millisecondsUntil(expires) {
  return moment.unix(expires).diff(moment());
}

const mapStateToProps = (state) => ({
  expires: state.auth?.expires,
  isLoggedIn: state.auth?.isLoggedIn,
  lastActive: state.auth?.lastActive,
});

const mapActionToProps = {
  logoutAction: logout,
  refreshSessionAction: refreshSession,
};

export default connect(mapStateToProps, mapActionToProps)(SessionManagement);
