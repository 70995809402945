import {
  GET_SELECT_USER_LOADING,
  GET_SELECT_USER_SUCCESS,
  GET_SELECT_USER_FAIL,
  SELECT_ACTIVE_USER_LOADING,
  LOGIN_SUCESSS,
  SELECT_ACTIVE_USER_FAIL,
} from "../../../redux/types";

import { auth } from "../../../api-calls";
import { redirectAfterLogin } from "../../../redux/utils";

export const getSelectUser = () => async (dispatch, getState) => {
  dispatch({ type: GET_SELECT_USER_LOADING });
  try {
    const {
      auth: { language },
    } = getState();
    const { data, error } = await auth.getSelectUsers({ language });

    if (data) {
      dispatch({
        type: GET_SELECT_USER_SUCCESS,
        payload: data,
      });
    } else if (error) {
      dispatch({
        type: GET_SELECT_USER_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_SELECT_USER_FAIL,
      payload: error,
    });
  }
};

export const selectActiveUser =
  ({ activeUserId, history }) =>
  async (dispatch) => {
    dispatch({ type: SELECT_ACTIVE_USER_LOADING });
    try {
      const { data, error } = await auth.selectActiveUser({ activeUserId });

      if (data) {
        dispatch({
          type: LOGIN_SUCESSS,
          payload: data,
        });

        redirectAfterLogin({ data, history });
      } else if (error) {
        dispatch({
          type: SELECT_ACTIVE_USER_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: SELECT_ACTIVE_USER_FAIL,
        payload: error,
      });
    }
  };
