import React from "react";

import useStyle from "./style";
import NavBar from "../../Navbar";
import Image from "../../Image";
import Toggle from "../../Toggle";

import ZendeskButton from "../../ZendeskButton";
import Footer from "../../Footer";

const General = ({ children, ...props }) => {
  const classes = useStyle(props);
  const { sideBar, footer, navbar, logo, profile, hideLangToggle } = props;
  const showZendesk = typeof window?.zE === "function";

  const FooterCase = () => {
    switch (true) {
      case !!footer && !!navbar:
        return <Footer navbar profile={profile} />;
      case !!footer:
        return <Footer profile={profile} />;
      default:
        return null;
    }
  };

  return (
    <main className={classes.Wrapper}>
      {!hideLangToggle && <Toggle />}
      {navbar && (
        <nav className={classes.NavBar}>
          <NavBar />
        </nav>
      )}
      {logo && (
        <Image image="logoMed" alt="logo" className={classes.LogoHeader} />
      )}
      {sideBar && <div className={classes.SideBar} />}
      <div className={classes.ContentWrapper}>{children}</div>
      <FooterCase />
      {showZendesk && <ZendeskButton />}
    </main>
  );
};
export default General;
