import React, { useState, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import * as S from "./style";
import * as T from "../../../../components/Typograpy";
import Image from "../../../../components/Image";
import Icon from "../../../../components/Icon";
import Modal from "../../../../components/Modal";
import { InputField } from "../../../../components/Inputs";
import { Regular } from "../../../../components/Button";
import { Col, Row } from "../../../../components/Grid";

import * as actions from "./actions";
import t from "../../../../utils/helpers/translator";

const CancelOrder = ({
  confirmCancelOrder,

  cancelLoading,
  cancelSuccessfully,
  cancelError,
}) => {
  const [cancellationReason, setCancelMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const history = useHistory();
  const { id } = useParams();
  const handleClick = () => {
    confirmCancelOrder({
      orderId: id,
      cancellationReason,
    });
  };

  useEffect(() => {
    if (cancelSuccessfully) {
      setIsModalOpen(true);
    }
  }, [cancelSuccessfully]);

  return (
    <>
      <Row>
        <Col w={[4, 12, 12]}>
          <T.H40Caps color="midnight">MARK AS CANCELLED</T.H40Caps>
        </Col>
      </Row>
      <Row mt={6} mb={7}>
        <Col w={[4, 8, 8]}>
          <S.Container>
            <Row mb={1}>
              <Col w={[3, 8, 8]}>
                <S.Title>
                  <T.Body20B color="midnight" m="0">
                    Are you sure you want to cancel this order?{" "}
                  </T.Body20B>
                </S.Title>
              </Col>
              <Col
                w={[1, 4, 4]}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Icon
                  icon="alertTriangle"
                  width="66px"
                  height="66px"
                  color="pink"
                />
              </Col>
            </Row>
            <Row mb={2}>
              <Col w={[4, 10, 10]}>
                <T.Body14R style={{ display: "inline" }} color="gray3">
                  This will cancel this order to purchase Time Credits and
                  cannot be undone.
                </T.Body14R>
              </Col>
            </Row>
          </S.Container>
        </Col>
        <Col
          w={[4, 4, 4]}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Image image="office" alt="office" />
        </Col>
      </Row>

      <Row>
        <Col w={[4, 4, 4]}>
          <T.Body16R color="gray3" mb="22">
            Send a message to the recipient to let them know why their payment
            has been cancelled (optional)
          </T.Body16R>
          <InputField
            type="textarea"
            size="normal"
            placeholder="Type your message here..."
            setValue={({ target: { value } }) => {
              setCancelMessage(value);
            }}
            value={cancellationReason}
          />
        </Col>
      </Row>

      <Row mt={9}>
        <Col w={[4, 12, 12]}>
          {cancelError && (
            <T.Body16R color="error">
              {" "}
              {t(cancelError.message, "english")}
            </T.Body16R>
          )}
        </Col>
        <Col w={[4, 4, 4]}>
          <Regular
            outline
            textColor="blue"
            handleClick={history.goBack}
            type="submit"
          >
            I want to go back
          </Regular>
        </Col>
        <Col w={[4, 4, 4]}>
          <Regular
            primary
            bgColor="blue"
            handleClick={handleClick}
            loading={cancelLoading}
            type="submit"
          >
            Confirm
          </Regular>
        </Col>
      </Row>

      <Modal
        modalOpen={isModalOpen}
        handleCloseModal={() => setIsModalOpen(false)}
        confirmFun={() => {
          history.goBack();
        }}
        onCancel={() => setIsModalOpen(false)}
      >
        <T.H24 color="blue" mb={20}>
          Success!
        </T.H24>
        <T.Body14R>You’ve successfully cancelled this order.</T.Body14R>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ orders }) => ({
  cancelLoading: orders.cancelLoading,
  cancelSuccessfully: orders.cancelSuccessfully,
  cancelError: orders.cancelError,
});

const mapActionToProps = {
  confirmCancelOrder: actions.confirmCancelOrder,
};

export default connect(mapStateToProps, mapActionToProps)(CancelOrder);
