import React from "react";

const Operations = ({ width, height, color, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    {...props}
  >
    <g clipPath="url(#clip0)" fill={color || "currentColor"}>
      <path d="M20.5 14.435V2.5A1.5 1.5 0 0019 1a1.5 1.5 0 00-1.45 1.14L10.405 5H4.5a4.505 4.505 0 00-.425 9L5 19.58a.5.5 0 00.5.42h3a.5.5 0 00.5-.58L8.09 14h2.315L13 15.03a5 5 0 107.5-.595zM19 2a.5.5 0 01.355.155.465.465 0 01.145.345v11.175a5.124 5.124 0 00-1-.445V2.5A.5.5 0 0119 2zm-9 11H5V6h5v7zM1 9.5a3.5 3.5 0 013-3.465v6.93A3.5 3.5 0 011 9.5zM7.91 19h-2l-.82-5h2l.82 5zM11 5.84l6.5-2.6v9.785c-.165 0-.33-.025-.5-.025a4.96 4.96 0 00-3.29 1.245L11 13.16V5.84zM17 22a4 4 0 110-8 4 4 0 010 8z" />
      <path d="M18.8 15.6a.5.5 0 00-.7.1l-3 4a.5.5 0 10.8.6l3-4a.5.5 0 00-.1-.7zM15.5 17a1 1 0 100-2 1 1 0 000 2zM18.5 21a1 1 0 100-2 1 1 0 000 2zM23.5 9H22a.5.5 0 000 1h1.5a.5.5 0 000-1zM22 6a.5.5 0 00.355-.145l1.5-1.5a.502.502 0 00-.71-.71l-1.5 1.5A.5.5 0 0022 6zM22.355 13.145a.502.502 0 10-.71.71l1.5 1.5a.496.496 0 00.547.11.496.496 0 00.272-.273.5.5 0 00-.11-.547l-1.5-1.5z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default Operations;
