import React from "react";
import { useHistory } from "react-router-dom";
import useStyle, { RegularButton } from "./style";
import * as T from "../Typograpy";
import Loading from "./Loading";

const Button = ({ to, classes: _classes, ...props }) => {
  const { loading, handleClick = () => {}, disabled, children } = props;
  const classes = useStyle(props);
  const history = useHistory();
  const onClick = (e) => {
    if (to) history.push(to);
    if (handleClick instanceof Function) handleClick(e);
  };

  return (
    <button
      onClick={onClick}
      className={`${_classes} ${classes.StyledButton}`}
      type="button"
      disabled={disabled || loading}
      {...props}
    >
      {children}
      {loading && (
        <span style={{ marginLeft: "10px" }}>
          <Loading {...props} style={{ marginLeft: "10px" }} />
        </span>
      )}
    </button>
  );
};

export const Regular = ({
  to,
  classes: _classes,
  textColor,
  light,
  icon,
  ...props
}) => {
  const { loading, handleClick = () => {}, disabled, children } = props;
  const history = useHistory();
  const onClick = (e) => {
    if (to) history.push(to);
    if (handleClick instanceof Function) handleClick(e);
  };

  const Content =
    icon ||
    (light ? (
      <T.Body16M m="0" color={textColor || "white"}>
        {children}
      </T.Body16M>
    ) : (
      <T.Body16B m="0" color={textColor || "white"}>
        {children}
      </T.Body16B>
    ));

  return (
    <RegularButton
      onClick={onClick}
      type="button"
      disabled={disabled || loading}
      textColor={textColor}
      {...props}
    >
      {Content}
      {loading && (
        <span style={{ marginLeft: "10px" }}>
          <Loading {...props} style={{ marginLeft: "10px" }} />
        </span>
      )}
    </RegularButton>
  );
};

export default Button;
