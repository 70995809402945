import {
  GET_SPEND_VENUES_FAIL,
  GET_SPEND_VENUES_SUCCESS,
  GET_SPEND_VENUES_LOADING_START,
  GET_SPEND_VENUES_LOADING_END,
  TEMP_LOGIN_LOADING_START,
  TEMP_LOGIN_LOADING_END,
} from "../../../redux/types";

const initState = {
  getLoading: false,
  postLoading: false,
  error: null,
  spendVenues: [],
};

const spendVenuesPublicReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SPEND_VENUES_LOADING_START:
      return {
        ...state,
        getLoading: true,
      };
    case GET_SPEND_VENUES_LOADING_END:
      return {
        ...state,
        getLoading: false,
      };

    case TEMP_LOGIN_LOADING_START:
      return {
        ...state,
        postLoading: true,
      };
    case TEMP_LOGIN_LOADING_END:
      return {
        ...state,
        postLoading: false,
      };

    case GET_SPEND_VENUES_SUCCESS:
      return {
        ...state,
        spendVenues: payload,
      };
    case GET_SPEND_VENUES_FAIL:
      return {
        error: payload,
      };

    default:
      return state;
  }
};

export default spendVenuesPublicReducer;
