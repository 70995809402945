import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import posthog from "posthog-js";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getUserCurrentTc } from "../../../../../redux/actions/userActions";
import {
  getSpendActivityById,
  purchaseBookingCodes,
  resetState,
} from "../actions";

import { logout } from "../../../../../redux/actions/auth";

import userRoles from "../../../../../constants/roles";
import { LANGUAGE_ERROR } from "../../../../../validation/err-msgs";
import t from "../../../../../utils/helpers/translator";

import * as S from "./style";

import GoBack from "../../../../../components/GoBack";
import Loading from "../../../../../components/Loading";
import { Row, Col } from "../../../../../components/Grid";
import { Body16R } from "../../../../../components/Typograpy";

// PAGES
import TurnUp from "./TurnUp";
import CallEmail from "./CallEmail";
import BookingCodes from "./BookingCodes";
import BookingCodesSuccess from "./BookingCodesSuccess";
import NotAuthorized from "./NotAuthorized";

// SECTIONS
import PickCards from "./PickCards";

const SpendOptions = ({
  loggedIn,
  role,
  isTablet,
  getSpendActivityByIdAction,
  spendActivityLoading,
  spendActivity,
  purchaseBookingCodesAction,
  purchaseBookingCodesLoading,
  purchasedBookingCodes,
  getUserCurrentTcAction,
  resetStateAction,
  language,
}) => {
  const [spendOption, setSpendOption] = useState("home");
  const [pageState, setPageState] = useState({
    authorized: false,
    languageError: null,
  });
  const [decidingPage, setDecidingPage] = useState(false);

  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    setDecidingPage(true);
    getSpendActivityByIdAction(id, "spend", { isPublic: true });

    return resetStateAction;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.event({
        category: "Activity",
        action: "Member clicks to Spend TC",
      });
      posthog.capture("Member clicks to Spend TC");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    callExtraDetails,
    spendVenue,
    personTcPrice: cost,
    useCodesLink,
    useCodesDetails,
    bookingCodes = [],
    noCodesAvailable,
    welshFields = {},
    turnUpAtVenue,
    callToBook,
    onlineCodesToBook,
    isLive,
    reachedRestrictions,
  } = spendActivity;

  const {
    callExtraDetails: callExtraDetailsWelsh = "",
    useCodesDetails: useCodesDetailsWelsh = "",
  } = welshFields;

  const welshVersion =
    (welshFields && welshFields.callExtraDetails) ||
    (welshFields && welshFields.useCodesDetails);

  const welshVersionExists = welshFields && welshFields.name;
  const flowLanguage =
    welshVersionExists && language === "welsh" ? "welsh" : "english";
  useEffect(() => {
    if (loggedIn && role === userRoles.MEMBER) {
      setPageState((oldState) => ({ ...oldState, authorized: true }));
    }
  }, [loggedIn, role]);

  useEffect(() => {
    if (welshVersion && language === "welsh") {
      setPageState((oldState) => ({ ...oldState, languageError: null }));
    } else if (!welshVersion && language === "welsh") {
      setPageState((oldState) => ({
        ...oldState,
        languageError: t(LANGUAGE_ERROR, language),
      }));
    } else {
      setPageState((oldState) => ({ ...oldState, languageError: null }));
    }
  }, [language, welshVersion]);

  const setPage = (goBack) => {
    const options = [
      { text: "turnUp", value: turnUpAtVenue },
      { text: "callEmail", value: callToBook },
      {
        text: "bookOnline",
        value: onlineCodesToBook && noCodesAvailable > 0,
      },
    ].filter((e) => e.value && e);
    if (options.length === 1 && goBack) {
      return history.goBack();
    }
    if (options.length === 1 && isLive) {
      if (options[0].text === "bookOnline") {
        setSpendOption(pageState.authorized ? "bookOnline" : "notAuthorized");
      } else {
        setSpendOption(options[0].text);
      }
    } else {
      setSpendOption("home");
    }
  };

  useEffect(() => {
    if (spendActivity) {
      setPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spendActivity]);

  useEffect(() => {
    setDecidingPage(false);
  }, [spendOption]);

  if (spendActivityLoading || decidingPage) return <Loading fullPage navbar />;

  switch (spendOption) {
    case "turnUp":
      return (
        <TurnUp
          back={() => setPage(true)}
          isTablet={isTablet}
          welshVersion={welshVersion}
          authorized={pageState.authorized}
          language={flowLanguage}
        />
      );
    case "callEmail":
      return (
        <CallEmail
          back={() => setPage(true)}
          spendVenue={spendVenue}
          bookExtraDetails={callExtraDetails}
          bookExtraDetailsWelsh={callExtraDetailsWelsh}
          isTablet={isTablet}
          welshVersion={welshVersion}
          authorized={pageState.authorized}
          language={flowLanguage}
        />
      );
    case "bookOnline":
      return (
        <BookingCodes
          back={() => setPage(true)}
          cost={cost}
          activityId={id}
          codesExtraDetails={useCodesDetails}
          bookingCodes={bookingCodes}
          noCodesAvailable={noCodesAvailable}
          isTablet={isTablet}
          purchaseBookingCodesAction={purchaseBookingCodesAction}
          purchaseBookingCodesLoading={purchaseBookingCodesLoading}
          setSpendOption={setSpendOption}
          welshVersion={welshVersion}
          codesExtraDetailsWelsh={useCodesDetailsWelsh}
          authorized={pageState.authorized}
          language={flowLanguage}
          reachedRestrictions={reachedRestrictions}
        />
      );
    case "bookingSuccess":
      return (
        <BookingCodesSuccess
          codesExtraDetails={useCodesDetails}
          bookingCodes={purchasedBookingCodes}
          useCodesLink={useCodesLink}
          getUserCurrentTcAction={getUserCurrentTcAction}
          welshVersion={welshVersion}
          welshDetails={spendActivity?.welshFields}
          codesExtraDetailsWelsh={useCodesDetailsWelsh}
          language={flowLanguage}
        />
      );
    case "notAuthorized":
      return (
        <NotAuthorized
          welshVersion={welshVersion}
          isTablet={isTablet}
          language={flowLanguage}
        />
      );
    default:
      return (
        <S.Wrapper>
          <Row mb={6} style={{ marginBottom: isTablet && "20px" }}>
            <Col w={[2, 6, 8]}>
              <GoBack showText lang={welshVersion && "welsh"} />
            </Col>
          </Row>
          {pageState.languageError && (
            <Body16R color="pink" style={{ marginBottom: 40 }}>
              {pageState.languageError}
            </Body16R>
          )}
          <PickCards
            isTablet={isTablet}
            authorized={pageState.authorized}
            lang={flowLanguage}
            spendActivity={spendActivity}
            setSpendOption={setSpendOption}
            loggedIn={loggedIn}
          />
        </S.Wrapper>
      );
  }
};

const mapStateToProps = (state) => ({
  loggedIn: state.auth.isLoggedIn,
  role: state.auth.role,
  spendActivity: state.activityProfile.spendActivityData,
  spendActivityLoading: state.activityProfile.getSpendActivityLoading,
  purchasedBookingCodes: state.activityProfile.purchasedBookingCodesData,
  purchaseBookingCodesLoading:
    state.activityProfile.purchaseBookingCodesLoading,
  language: state.auth.decideLanguage(state.auth),
});

const mapActionToProps = {
  getSpendActivityByIdAction: getSpendActivityById,
  purchaseBookingCodesAction: purchaseBookingCodes,
  getUserCurrentTcAction: getUserCurrentTc,
  resetStateAction: resetState,
  logoutAction: logout,
};

export { BookingCodesSuccess };
export default connect(mapStateToProps, mapActionToProps)(SpendOptions);
