import React from "react";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../../../utils/theme";

import Loading from "../../Loading";
import translate from "../../../utils/helpers/translator";
import * as T from "../../Typograpy";
import * as S from "./style";

const CreditCard = ({
  type,
  width,
  credits,
  memberID,
  hideID,
  loading,
  lang,
  price,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <S.StyledCard width={width} type={type}>
      {loading ? (
        <Loading center color="white" />
      ) : (
        <>
          {type === "digital" ? (
            <S.CreditWrapper width={width}>
              <S.Number width={width}>
                {!credits || credits === "N/A" ? "0" : credits}
              </S.Number>
              <S.SubTitle width={width}>
                {translate("timeCreditsNumInFront", lang)}
              </S.SubTitle>
            </S.CreditWrapper>
          ) : (
            <S.CreditWrapper width={width}>
              <S.MemberDetailsTitle width={width}>
                {translate("membershipID", lang)}
              </S.MemberDetailsTitle>
              <S.MemberDetailsNumber width={width}>
                {memberID || "N/A"}
              </S.MemberDetailsNumber>
            </S.CreditWrapper>
          )}
          {!hideID && (
            <S.ID width={width} type={type}>
              <S.Title>ID</S.Title> {memberID || "N/A"}
            </S.ID>
          )}
          {price && (
            <S.Price width={width} type={type} isMobile={isMobile}>
              <S.PriceTitle isMobile={isMobile}>{price}</S.PriceTitle>
              <T.Body14R color="white">incl VAT</T.Body14R>
            </S.Price>
          )}
        </>
      )}
    </S.StyledCard>
  );
};

const mapStateToProps = (state) => ({
  lang: state.auth.language,
});

export default connect(mapStateToProps)(CreditCard);
