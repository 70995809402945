import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { connect } from "react-redux";
import { toggleUIcolor } from "../../../../redux/actions/userActions";
import * as S from "./style";
import * as T from "../../../../components/Typograpy";
import { PROGRAMME_MANAGER, TEMPO } from "../../../../constants/navRoutes";
import { roles } from "../../../../constants";

import Button from "../../../../components/Button";
import Image from "../../../../components/Image";

const IssueCreditsSuccess = ({ changeLayoutColor, role }) => {
  const history = useHistory();
  const location = useLocation();
  const { state: { tc, name, creditResponse } = {} } = location;
  useEffect(() => {
    if (creditResponse === "rejected") {
      changeLayoutColor("pink");
    }
  }, [creditResponse, changeLayoutColor]);

  return (
    <S.Wrapper success>
      <Image image="officeLarge" alt="office" />
      <T.H40Caps color="midnight" mb={30} mt={10}>
        {creditResponse === "rejected" ? "REQUEST COMPLETE" : "SUCCESS!"}
      </T.H40Caps>
      {creditResponse === "rejected" ? (
        <T.Body16R mb={60}>
          The request by <S.Emphasis>{name}</S.Emphasis> has been rejected.
        </T.Body16R>
      ) : (
        <T.Body16R mb={60}>
          You have successfully issued <S.Emphasis>{tc}</S.Emphasis> Time
          Credits to <S.Emphasis>{name}</S.Emphasis>.
        </T.Body16R>
      )}

      {role === roles.PROGRAMME_MANAGER ? (
        <Button
          primary
          onClick={() => history.push(PROGRAMME_MANAGER.DASHBOARD)}
          mb={10}
          size="l"
        >
          Return to Programme
        </Button>
      ) : (
        <>
          <Button
            primary
            onClick={() => history.push(TEMPO.DASHBOARD)}
            mb={10}
            size="l"
          >
            View Dashboard
          </Button>
          <Button
            primary
            onClick={() => history.push(TEMPO.REQUESTS)}
            mb={10}
            size="l"
          >
            View Requests
          </Button>
        </>
      )}
    </S.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  role: state.auth.role,
  programmeId: state.auth.programmeId,
});

const mapActionToProps = {
  changeLayoutColor: toggleUIcolor,
};

export default connect(mapStateToProps, mapActionToProps)(IssueCreditsSuccess);
