import React from "react";
import { connect } from "react-redux";

import LocationFields from ".";
import * as S from "./style";
import * as T from "../Typograpy";
import { Row, Col } from "../Grid";
import t from "../../utils/helpers/translator";
import { validateAddress } from "../../validation/schemas/recruitVolunteers";

const LocationsWrapper = ({
  setAddresses,
  setNewPostcodes,
  welshVersion,
  addresses = [],
  isOptional,
  errors = {},
  setErrors,
  lang,
  citesAndCounties,
}) => {
  const id = addresses.length;
  const handleClick = () => {
    try {
      // validate previous addresses before adding new one
      validateAddress(addresses);

      setAddresses([
        ...addresses,
        {
          id,
          addressLine1: "",
          addressLine2: "",
          city: "",
          county: "",
          postcode: "",
          addressLine1Welsh: "",
          addressLine2Welsh: "",
          cityWelsh: "",
          countyWelsh: "",
        },
      ]);
      setErrors((_err) => ({
        ..._err,
        addAnotherLocation: "",
      }));
    } catch (err) {
      if (err.name === "ValidationError") {
        setErrors((_err) => ({
          ..._err,
          addAnotherLocation:
            "You must fill previous addresses before adding new one",
        }));
      }
    }
  };
  return (
    <>
      {addresses.map((e, i) => (
        <LocationFields
          key={e.id}
          isOptional={isOptional}
          index={e.id}
          addresses={addresses}
          citesAndCounties={citesAndCounties}
          setAddresses={setAddresses}
          setNewPostcodes={setNewPostcodes}
          errors={errors[`addresses[${i}]`]}
          welshVersion={welshVersion}
          lang={lang}
        />
      ))}
      <Row>
        <Col w={[4, 12, 12]}>
          <S.AddLocation onClick={handleClick}>
            <T.LinkB14 color="blue" mt={30} ml={15} mb={30}>
              + {t("addAnotherLocation", lang)}
            </T.LinkB14>
          </S.AddLocation>
          {errors.addAnotherLocation && (
            <S.Error>{errors.addAnotherLocation}</S.Error>
          )}
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  lang: state.auth.language,
  citesAndCounties: state.activities.citesAndCounties,
});

export default connect(mapStateToProps, null)(LocationsWrapper);
