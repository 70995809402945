import React from "react";
import { useLocation, withRouter, useHistory } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import Wrapper from "./style";
import * as T from "../Typograpy";
import Icon from "../Icon";
import t from "../../utils/helpers/translator";

const GoBack = ({ onClick, showText, iconColor, p, language }) => {
  const location = useLocation();
  const isAdminPage = location?.pathname.includes("/admin/");
  const history = useHistory();
  const back =
    onClick && onClick instanceof Function ? onClick : history.goBack;
  return (
    <Wrapper onClick={back} p={p}>
      <Icon
        icon="backArrow"
        color={iconColor || "cyan"}
        width="22px"
        margin="0 10px 0 0"
      />
      {showText && (
        <T.H14Caps
          style={{ margin: "0", textTranform: "uppercase" }}
          color="midnight"
        >
          {t("back", isAdminPage ? "english" : language)}
        </T.H14Caps>
      )}
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  language: state.auth.decideLanguage(state.auth),
});

export default compose(withRouter, connect(mapStateToProps))(GoBack);
