import React, { useState, useEffect } from "react";
import { useParams, Link, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../../../utils/theme";

import { Row, Col } from "../../../components/Grid";
import GoBack from "../../../components/GoBack";
import Image from "../../../components/Image";
import { InputField, Checkbox } from "../../../components/Inputs";
import { Regular } from "../../../components/Button";
import Notification from "../../../components/Notification";

import * as T from "../../../components/Typograpy";
import * as S from "./style";
import * as actions from "./actions";
import { getLocation } from "../../../redux/actions/location";
import {
  validate,
  validateWelsh,
} from "../../../validation/schemas/createSpendVenue";
import VenueAddress from "./VenueAddress";
import VenueDetailsAndImages from "./VenueDetailsAndImages";
import Links from "./Links";
import { TEMPO, GENERAL } from "../../../constants/navRoutes";

import tidyPostcode from "../../../utils/helpers/tidyPostcode";
import stripLeadingTab from "../../../utils/helpers/stripLeadingTab";
import cleanWhitespace from "../../../utils/helpers/cleanWhitespace";
import CreateSpendVenueSelectPartner from "./CreateSpendVenueSelectPartner";
import CreateSpendVenueSuccess from "./Success";
import t from "../../../utils/helpers/translator";
import formatLink from "../../../utils/helpers/urlFormatter";
import { getSpendActivitiesCitiesAndCounties } from "../../../redux/actions/activities";

const useQuery = () => new URLSearchParams(useLocation().search);

const initFormState = {
  name: "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  password: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  county: "",
  region: "",
  regionCountry: "",
  postcode: "",
  long: "",
  lat: "",
  isOnline: false,
  contactNumber: "",
  contactEmail: "",
  description: "",
  accessibilityWebsiteUrl: "",
  logo: "",
  headerImg: "",
  websiteUrl: "",
  twitter: "",
  instagram: "",
  facebook: "",
  youtube: "",
  isPublic: false,
  // welsh
  nameWelsh: "",
  addressLine1Welsh: "",
  addressLine2Welsh: "",
  cityWelsh: "",
  descriptionWelsh: "",
};

const initErrors = {
  name: "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  password: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  county: "",
  region: "",
  postcode: "",
  contactNumber: "",
  contactEmail: "",
  description: "",
  accessibilityWebsiteUrl: "",
  logo: "",
  headerImg: "",
  websiteUrl: "",
  twitter: "",
  instagram: "",
  facebook: "",
  youtube: "",
  anyErrors: "",
  // welsh
  nameWelsh: "",
  addressLine1Welsh: "",
  addressLine2Welsh: "",
  cityWelsh: "",
  countyWelsh: "",
  descriptionWelsh: "",
};

const SpendVenueForm = ({
  edit,
  data,
  updatedSuccessfully,
  getSpendVenue,
  loaded,
  updateSpendVenue,
  createSpendVenue,
  error,
  loading,
  createdSpendVenue = {},
  resetSpendVenueForm,
  getLocationAction,
  citesAndCounties,
  getSpendActivitiesCitiesAndCountiesAction,
}) => {
  const history = useHistory();

  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState(initErrors);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [welshVersion, setWelshVersion] = useState(false);
  const [logoImageInfo, setLogoImageInfo] = useState({
    id: null,
    name: "",
    key: "",
    bucketRegion: "",
    bucket: "",
    new: false,
    uploadedToS3: false,
  });
  const [coverImageInfo, setCoverImageInfo] = useState({
    id: null,
    name: "",
    key: "",
    bucketRegion: "",
    bucket: "",
    new: false,
    uploadedToS3: false,
  });

  const [isNewPostcode, setIsNewPostcode] = useState(true);
  const [startUpload, setStartUpload] = useState(false);
  const [imagesReady, setImagesReady] = useState(false);
  const [geocodesReady, setGeocodesReady] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { spendVenueId, spendPartnerId } = useParams();
  const { name, nameWelsh, isPublic } = formData;

  const query = useQuery();
  const isNewSpendPartner = query.get("new");

  useEffect(() => {
    if (updatedSuccessfully) {
      setIsNotificationOpen(true);
      setLogoImageInfo((_state) => ({
        ..._state,
        new: false,
        deleted: false,
        uploadedToS3: false,
      }));
      setCoverImageInfo((_state) => ({
        ..._state,
        new: false,
        deleted: false,
        uploadedToS3: false,
      }));
      setStartUpload(false);
      setImagesReady(false);
      setGeocodesReady(false);
    }
  }, [updatedSuccessfully]);

  useEffect(() => {
    if (updatedSuccessfully && !isNotificationOpen && createdSpendVenue.id) {
      if (!edit) {
        if (isNewSpendPartner && isNewSpendPartner === "true") {
          return history.push(
            TEMPO.CREATE_SPEND_VENUE_SUCCESS.replace(
              ":spendPartnerId",
              spendPartnerId,
            ).replace(":venueId", createdSpendVenue.id),
          );
        }
        history.push(
          TEMPO.EDIT_SPEND_VENUE.replace(":spendVenueId", createdSpendVenue.id),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedSuccessfully, isNotificationOpen, createdSpendVenue.id]);

  useEffect(() => {
    getSpendActivitiesCitiesAndCountiesAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInput = (input) => {
    const { name: _name, value } = input.target;
    setFormData((oldState) => ({
      ...oldState,
      [_name]:
        _name === "email" || _name === "contactEmail"
          ? value.toLowerCase().replace(/ /g, "").trim()
          : value,
    }));
    if (_name === "postcode") setIsNewPostcode(true);
  };
  const handleValueChange = (_name, value) => {
    setFormData((oldState) => ({
      ...oldState,
      [_name]: value,
    }));
  };

  const handleWalshCheckbox = () => setWelshVersion((old) => !old);

  const handleCheckbox = (input) => {
    const { name: _name, checked } = input.target;
    if (_name === "isOnline") {
      const {
        addressLine1,
        addressLine2,
        city,
        county,
        region,
        regionCountry,
        postcode,
      } = initFormState;
      return setFormData((_formData) => ({
        ..._formData,
        addressLine1,
        addressLine2,
        city,
        county,
        region,
        regionCountry,
        postcode,
        [_name]: checked,
      }));
    }
    setFormData((_formData) => ({ ..._formData, [_name]: checked }));
  };

  const handleRegion = (selected) => {
    const { value, country } = selected;
    setFormData({ ...formData, region: value, regionCountry: country });
  };

  const submit = () => {
    const cleanPostcode = tidyPostcode(formData.postcode);
    const cleanCity = formData.city?.trim();
    const cleanPhoneNumber = formData.phoneNumber?.replace(/ /g, "");
    const cleanContactNumber = formData.contactNumber?.replace(/ /g, "");

    const {
      firstName,
      lastName,
      email,
      addressLine1,
      addressLine2,
      postcode,
      long,
      lat,
      city,
      county,
      region,
      regionCountry,
      isOnline,
      contactEmail,
      description,
      websiteUrl,
      socialLinks,
      accessibilityWebsiteUrl,
      logoImage,
      coverImage,
      descriptionWelsh,
      addressLine1Welsh,
      addressLine2Welsh,
      countyWelsh,
      cityWelsh,
    } = formData;

    let formSubmissionFields = {
      firstName: cleanWhitespace(firstName),
      lastName: cleanWhitespace(lastName),
      email,
      phoneNumber: cleanPhoneNumber,
      name: cleanWhitespace(name),
      addressLine1,
      addressLine2,
      postcode,
      long,
      lat,
      city,
      county,
      region,
      regionCountry,
      isOnline,
      contactNumber: cleanContactNumber,
      contactEmail,
      description,
      websiteUrl: websiteUrl && formatLink(websiteUrl.trim()),
      socialLinks,
      accessibilityWebsiteUrl:
        accessibilityWebsiteUrl && formatLink(accessibilityWebsiteUrl.trim()),
      isPublic,
      logoImage,
      coverImage,
    };

    const formSubmissionFieldsWelsh = {
      nameWelsh,
      descriptionWelsh,
      addressLine1Welsh,
      addressLine2Welsh,
      cityWelsh,
      countyWelsh,
    };

    if (welshVersion) {
      formSubmissionFields = {
        ...formSubmissionFields,
        welshFields: { ...formSubmissionFieldsWelsh },
      };
    }

    if (edit) {
      updateSpendVenue({
        spendVenueId,
        updateData: {
          ...formSubmissionFields,
          postcode: cleanPostcode,
          city: cleanCity,
          email,
          coverImage: coverImageInfo,
          logoImage: logoImageInfo,
          phoneNumber: cleanPhoneNumber,
          contactNumber: cleanContactNumber,
        },
      });
    } else {
      createSpendVenue({
        createData: {
          ...formData,
          websiteUrl: websiteUrl && formatLink(websiteUrl.trim()),
          accessibilityWebsiteUrl:
            accessibilityWebsiteUrl &&
            formatLink(accessibilityWebsiteUrl.trim()),
          postcode: cleanPostcode,
          city: cleanCity,
          email,
          coverImage: coverImageInfo,
          logoImage: logoImageInfo,
          phoneNumber: cleanPhoneNumber,
          contactNumber: cleanContactNumber,
          spendPartnerId,
        },
      });
    }
  };

  const fetchGeocodes = async () => {
    try {
      setFetchingData(true);
      const { result } = await getLocationAction({
        query: formData.postcode,
        queryType: "postcode",
      });
      if (!result) {
        throw new Error("Postcode entered isn't recognised");
      } else {
        setFormData({
          ...formData,
          long: result[0].longitude,
          lat: result[0].latitude,
        });
        setGeocodesReady(true);
      }
    } catch (err) {
      setFetchingData(false);
      setErrors({
        postcode: `Error getting the latitude and longitude for this postcode`,
        anyErrors: `Error getting the latitude and longitude for submitted postcode: "${
          err.inner || err
        }"`,
      });
      return null;
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();

    // get extra fields to validate from form data
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      description,
      isOnline,
      addressLine1,
      addressLine2,
      city,
      county,
      region,
      postcode,
      websiteUrl,
      password,
      cityWelsh,
      addressLine1Welsh,
      addressLine2Welsh,
      countyWelsh,
      descriptionWelsh,
      contactEmail,
      contactNumber,
    } = formData;

    const cleanPostcode = tidyPostcode(postcode);
    const cleanPhoneNumber = phoneNumber?.replace(/ /g, "");
    const cleanContactNumber = contactNumber?.replace(/ /g, "");

    const validationBasicFields = {
      name,
      firstName,
      lastName,
      email,
      phoneNumber: cleanPhoneNumber,
      description,
      isOnline: !!isOnline,
      addressLine1,
      addressLine2,
      city,
      county,
      region,
      postcode: cleanPostcode,
      websiteUrl: websiteUrl && formatLink(websiteUrl.trim()),
      password: edit ? "dummyPassword1ToPassValidation!" : password,
      coverImageKey: coverImageInfo.key,
      logoImageKey: logoImageInfo.key,
      contactEmail,
      contactNumber: cleanContactNumber,
    };

    try {
      if (!welshVersion) {
        validate({
          ...validationBasicFields,
          isPublic,
        });
      } else {
        validateWelsh({
          ...validationBasicFields,
          isPublic,
          nameWelsh,
          cityWelsh,
          addressLine1Welsh,
          addressLine2Welsh,
          countyWelsh,
          descriptionWelsh,
        });
      }

      setErrors(initErrors);

      if (isNewPostcode && !isOnline && formData.postcode) {
        await fetchGeocodes();
      } else {
        setGeocodesReady(true);
      }
      if (
        (logoImageInfo.new && !logoImageInfo.uploadedToS3) ||
        (coverImageInfo.new && !coverImageInfo.uploadedToS3)
      ) {
        setStartUpload(true);
      } else {
        setImagesReady(true);
      }
    } catch (err) {
      if (err.name === "ValidationError") {
        err.inner.anyErrors =
          "At least one of the input fields has not been filled in or details entered incorrectly. Please check the form above for more details.";
        setErrors(err.inner);
      }
    }
  };

  useEffect(() => {
    if (edit) {
      getSpendVenue({ id: spendVenueId, profile: true });
    }

    return resetSpendVenueForm;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  useEffect(() => {
    let filteredData;

    if (loaded && edit) {
      // include welsh fields if present
      const { welshFields = {} } = data;
      if (welshFields) {
        const formattedWelshFields = {
          nameWelsh: welshFields.name,
          descriptionWelsh: welshFields.description,
          addressLine1Welsh: welshFields.addressLine1,
          addressLine2Welsh: welshFields.addressLine2,
          cityWelsh: welshFields.city,
          countyWelsh: welshFields.county,
        };
        filteredData = {
          ...data,
          ...formattedWelshFields,
          phoneNumber: stripLeadingTab(data.phoneNumber),
          contactNumber: stripLeadingTab(data.contactNumber),
        };

        setWelshVersion(!!filteredData.nameWelsh);
      } else {
        filteredData = {
          ...data,
          phoneNumber: stripLeadingTab(data.phoneNumber),
          contactNumber: stripLeadingTab(data.contactNumber),
        };
      }
      setFormData(filteredData);

      setLogoImageInfo({ ...data.logo, new: false });
      setCoverImageInfo({ ...data.cover, new: false });
      setIsNewPostcode(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  useEffect(() => {
    if (coverImageInfo.uploadedToS3 || logoImageInfo.uploadedToS3) {
      if (coverImageInfo.new) {
        if (!coverImageInfo.uploadedToS3) {
          return;
        }
      }
      if (logoImageInfo.new) {
        if (!logoImageInfo.uploadedToS3) {
          return;
        }
      }
      setImagesReady(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverImageInfo.uploadedToS3, logoImageInfo.uploadedToS3]);

  useEffect(() => {
    if (geocodesReady && imagesReady) {
      setFetchingData(false);
      submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geocodesReady, imagesReady]);

  return (
    <S.Wrapper>
      <Notification
        open={isNotificationOpen}
        setOpen={setIsNotificationOpen}
        message="PROFILE SAVED"
        duration={1500}
      />
      <Row>
        <Col w={[4, 12, 12]}>
          <GoBack showText />
        </Col>
        <S.ImageWrapper>
          <Image image="galleryLarge" alt="gallery" height={210} width={220} />
        </S.ImageWrapper>
      </Row>
      <S.Header>
        <Row>
          <Col w={[4, 12, 12]}>
            <T.H40Caps color="midnight" m="0">
              {edit ? "Edit" : "Add"} Venue {edit && "Listing"}
            </T.H40Caps>
            {edit && (
              <Link
                to={GENERAL.SPEND_VENUE.replace(":id", spendVenueId)}
                target="_blank"
              >
                <T.LinkB16 color="pink" m="20px 0 0 0">
                  View Venue
                </T.LinkB16>
              </Link>
            )}
          </Col>
          {edit && (
            <Col w={[4, 12, 12]} style={{ marginTop: 30 }}>
              <Checkbox
                value={welshVersion}
                setValue={handleWalshCheckbox}
                label="Add a version in Welsh language"
                name="welshVersion"
                size={isMobile ? "normal" : "medium"}
              />
            </Col>
          )}
        </Row>
      </S.Header>
      <S.Form>
        <Row mb={5}>
          <Col w={[4, 6, 4]}>
            <InputField
              value={name}
              setValue={handleInput}
              label="Venue name"
              name="name"
              helper="ensure consistency with CRM"
              placeholder="Type venue name here..."
              error={errors.name}
            />
          </Col>
          {welshVersion && (
            <Col w={[4, 6, 4]}>
              <InputField
                value={nameWelsh}
                setValue={handleInput}
                label="Venue name (Welsh)"
                name="nameWelsh"
                helper="ensure consistency with CRM"
                placeholder="Type venue name here..."
                error={errors.nameWelsh}
                labelColor="blue"
              />
            </Col>
          )}
        </Row>

        <Row mt={5}>
          <Col w={[4, 8, 8]}>
            <T.H24 color="midnight" m="0 0 15px 0">
              {edit ? "Edit" : "Create"} Venue Listing
            </T.H24>
            <T.Body16R color="gray3" m="0 0 40px 0">
              Provide the details that will appear on their Venue Listing.
            </T.Body16R>
          </Col>
        </Row>
        <VenueAddress
          formData={formData}
          errors={errors}
          handleInput={handleInput}
          handleRegion={handleRegion}
          handleCheckbox={handleCheckbox}
          handleValueChange={handleValueChange}
          citesAndCounties={citesAndCounties}
          isMobile={isMobile}
          welshVersion={welshVersion}
        />
        <VenueDetailsAndImages
          formData={formData}
          errors={errors}
          handleInput={handleInput}
          logoImageInfo={logoImageInfo}
          setLogoImageInfo={setLogoImageInfo}
          coverImageInfo={coverImageInfo}
          setCoverImageInfo={setCoverImageInfo}
          startUpload={startUpload}
          welshVersion={welshVersion}
        />
        <Links
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          handleInput={handleInput}
        />
        <Row mb={6}>
          <Col w={[4, 12, 12]}>
            <Checkbox
              value={isPublic}
              setValue={handleCheckbox}
              mt="10"
              label="Make Venue Listing public"
              name="isPublic"
              size={isMobile ? "normal" : "medium"}
              helper="venue must be public for activities to appear on the website"
            />
          </Col>
        </Row>
        <Row>
          <Col w={[4, 6, 4]}>
            {errors.anyErrors && (
              <S.Error>{t(errors.anyErrors, "english")}</S.Error>
            )}
            {error.message && <S.Error>{t(error.message, "english")}</S.Error>}
            <Regular
              primary
              bgColor="blue"
              onClick={handleClick}
              loading={loading || fetchingData || startUpload}
            >
              {edit ? "Save Changes" : "Create Venue"}
            </Regular>
          </Col>
        </Row>
      </S.Form>
    </S.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  data: state.spendVenueForm.spendVenue,
  updatedSuccessfully: state.spendVenueForm.updatedSuccessfully,
  loaded: state.spendVenueForm.loaded,
  error: state.spendVenueForm.updateSpendVenueError,
  loading: state.spendVenueForm.updateSpendVenueLoading,
  createdSpendVenue: state.spendVenueForm.createdSpendVenue,
  citesAndCounties: state.activities.citesAndCounties,
});

const mapActionToProps = {
  getSpendVenue: actions.getSpendVenue,
  updateSpendVenue: actions.updateSpendVenue,
  createSpendVenue: actions.createSpendVenue,
  resetSpendVenueForm: actions.resetSpendVenueForm,
  getLocationAction: getLocation,
  getSpendActivitiesCitiesAndCountiesAction:
    getSpendActivitiesCitiesAndCounties,
};

export { CreateSpendVenueSelectPartner, CreateSpendVenueSuccess };
export default connect(mapStateToProps, mapActionToProps)(SpendVenueForm);
