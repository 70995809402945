import { insights } from "../../../api-calls";

import {
  GET_STAFF_INSIGHTS_LOADING,
  GET_STAFF_INSIGHTS_SUCCESS,
  GET_STAFF_INSIGHTS_FAIL,
} from "../../../redux/types";

export const getInsights = (params) => async (dispatch) => {
  dispatch({ type: GET_STAFF_INSIGHTS_LOADING });
  try {
    const { data, error } = await insights.getTempoInsights({
      params,
    });
    if (data) {
      dispatch({ type: GET_STAFF_INSIGHTS_SUCCESS, payload: data });
    } else {
      dispatch({
        type: GET_STAFF_INSIGHTS_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_STAFF_INSIGHTS_FAIL,
      payload: error,
    });
  }
};
