import React from "react";

import * as S from "./style";
import * as T from "../../Typograpy";
import TimeCredit from "../../TimeCredit";
import Icon from "../../Icon";

import translate from "../../../utils/helpers/translator";

const SpendVenueList = ({
  activity,
  subtitle,
  cost,
  m,
  handleClick,
  selected,
  id,
  customWidth,
  online,
  lang,
  costVaries,
}) => {
  const onClick = () => handleClick(id);

  return (
    <S.SpendVenueListWrapper
      onClick={onClick}
      selected={selected}
      m={m}
      id={id}
      customWidth={customWidth}
    >
      <S.Details selected={selected}>
        <T.H18
          color="midnight"
          style={{
            textTransform: "capitalise",
            margin: "0",
            textAlign: "left",
          }}
        >
          {activity}{" "}
          {online && (
            <span style={{ fontWeight: "400" }}>
              ({translate("online", lang)})
            </span>
          )}
        </T.H18>
        {subtitle && (
          <T.H18
            color="midnight"
            style={{
              textTransform: "capitalise",
              margin: "0",
              textAlign: "left",
            }}
          >
            - {subtitle}
          </T.H18>
        )}
      </S.Details>
      <S.Price selected={selected} customWidth={customWidth}>
        <TimeCredit
          credits={cost}
          description
          color="white"
          costVaries={costVaries}
        />
        <Icon icon="arrowRight" color="white" width="25px" height="25px" />
      </S.Price>
    </S.SpendVenueListWrapper>
  );
};

export default SpendVenueList;
