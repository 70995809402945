import { styled } from "@material-ui/core/styles";

const Wrapper = styled("div")(({ theme }) => ({
  cursor: "pointer",
  border: "none",
  background: "none",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flexStart",
  alignItems: "center",
  outline: "none",

  [theme.breakpoints.tablet]: {
    flexDirection: "row",
  },
}));

export default Wrapper;
