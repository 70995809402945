import styled from "@material-ui/core/styles/styled";
import Typograpy from "@material-ui/core/Typography";

export const Container = styled("div")({
  width: "100%",
});

export const Head = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

export const TableContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
});

export const BtnContainer = styled("div")({
  alignSelf: "flex-end",
  transform: "translateY(61px)",
  zIndex: 3,
  display: "flex",
  flexWrap: "wrap",
});

export const ImageWrapper = styled("div")({
  minWidth: 220,
  height: 210,
  transform: "translate(-10px, -30px)",
});

export const MembersContainer = styled("div")({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  marginBottom: 20,
  "& div": {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 10,
  },
  "& div div button": {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

export const Error = styled(Typograpy)(({ theme }) => ({
  width: "100%",
  color: theme.colors.pink,
  fontSize: "0.875rem",
  textAlign: "left",
  marginBottom: theme.spacing(1),
}));

export const SearchWrapper = styled("div")({
  justifyItems: "center",
  width: "365px",
  marginTop: "-2px",
  marginBottom: "10px",
});
