import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import GoBack from "../../../../components/GoBack";
import Image from "../../../../components/Image";
import Button from "../../../../components/Button";
import { InputField, Dropdown } from "../../../../components/Inputs";

import * as S from "./style";
import * as T from "../../../../components/Typograpy";
import { roles } from "../../../../constants/index";
import { email as emailSchema } from "../../../../validation/fields";

import AccessTable from "./AccessTable";
import AccessModal from "./AccessModal";

import * as actions from "./actions";

const staffRoles = [
  { label: "User", value: roles.TEMPO_STAFF_USER },
  { label: "Admin", value: roles.TEMPO_STAFF_ADMIN },
];

const AccessPermission = ({
  tempoUsers,
  getAccessPermissions,
  getLoading,
  addNewUser,
  addLoading,
  deactivateUser,
  updateUserRole,
  userId,
}) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [role, setRole] = useState("");
  const [modalOpen, toggleModal] = useState(true);
  const [changeType, setChangeType] = useState("request");
  const [userToUpdate, setUserToUpdate] = useState({});
  const [errorMsg, setErrorMsg] = useState("");

  const handleEmail = (e) => {
    setEmail(e.target.value?.toLowerCase().trim());
    setEmailError("");
  };

  const handleRole = ({ selected }) => {
    setRole(selected);
  };

  const handleCloseModal = () => toggleModal(false);

  const readyToSubmit = email && role;

  const onAddSuccess = () => {
    setChangeType("createSuccess");
    toggleModal(true);
    setEmail("");
  };

  const onUpdateSuccess = () => {
    toggleModal(true);
    setChangeType("updateSuccess");
  };

  const onError = (error) => {
    toggleModal(true);
    setChangeType("error");
    setErrorMsg(error);
  };

  const createUser = () => {
    const isValidEmail = emailSchema.isValidSync(email);
    setEmailError(isValidEmail ? "" : "Invalid email");
    if (isValidEmail) {
      setUserToUpdate({ email, role });

      addNewUser({ email, role: role.value, onAddSuccess, onError });
    }
  };

  const updateUser = () => {
    if (changeType === "remove") {
      deactivateUser({ id: userToUpdate.id, onUpdateSuccess, onError });
    } else if (changeType === "updateRole") {
      updateUserRole({ id: userToUpdate.id, role, onUpdateSuccess, onError });
    }
  };

  const requestUpdate = (user, requestType, extraDetails) => {
    setUserToUpdate(user);
    setChangeType(requestType);

    if (requestType === "updateRole") {
      setRole(extraDetails.value);
    }
    toggleModal(true);
  };

  useEffect(() => {
    getAccessPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Wrapper>
      <GoBack showText />
      <S.Header>
        <T.H40Caps color="midnight" mb={20}>
          ACCESS PERMISSION
        </T.H40Caps>
        <S.ImageWrapper>
          <Image image="office" />
        </S.ImageWrapper>
        <T.Body18R color="gray3">
          Here you can control which staff members can sign up and have access
          to this platform.
        </T.Body18R>
      </S.Header>
      <S.ActionWrapper>
        <T.H24Caps color="blue" mb={30}>
          ADD NEW TEMPO STAFF USER
        </T.H24Caps>
        <S.Actions>
          <InputField
            type="email"
            label="Tempo Email Address"
            placeholder="Type email here..."
            value={email}
            setValue={handleEmail}
            size="normal"
            mr={3}
            error={emailError}
          />
          <Dropdown
            name="roles"
            options={staffRoles}
            value={role}
            placeholder="Select role..."
            label="Role"
            setValue={handleRole}
          />
          <S.AddButtonWrapper>
            <Button
              primary
              style={{ margin: "0" }}
              disabled={!readyToSubmit}
              onClick={() => createUser("created")}
              loading={addLoading}
            >
              Add user
            </Button>
          </S.AddButtonWrapper>
        </S.Actions>
      </S.ActionWrapper>
      <AccessTable
        tempoUsers={tempoUsers}
        staffRoles={staffRoles}
        requestUpdate={requestUpdate}
        isLoading={getLoading}
        userId={userId}
      />
      <AccessModal
        changeType={changeType}
        modalOpen={modalOpen}
        handleCloseModal={handleCloseModal}
        userToUpdate={userToUpdate}
        updateUser={updateUser}
        errorMsg={errorMsg}
      />
    </S.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  tempoUsers: state.accessPermissions.users,
  getLoading: state.accessPermissions.getLoading,
  addLoading: state.accessPermissions.addLoading,
  userId: state.auth.id,
});

const mapActionToProps = {
  getAccessPermissions: actions.getAccessPermissions,
  addNewUser: actions.addNewUser,
  deactivateUser: actions.deactivateUser,
  updateUserRole: actions.updateUser,
};

export default connect(mapStateToProps, mapActionToProps)(AccessPermission);
