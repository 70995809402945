import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Row, Col } from "../../../components/Grid";
import { InputField } from "../../../components/Inputs";
import Button from "../../../components/Button";
import GoBack from "../../../components/GoBack";
import { navRoutes, roles } from "../../../constants";
import * as T from "../../../components/Typograpy";
import stripLeadingTab from "../../../utils/helpers/stripLeadingTab";
import Modal from "../../../components/Modal";
import Image from "../../../components/Image";

import validate from "../../../validation/schemas/spendPartnerForm";

import * as accountActions from "../../../redux/actions/userAccount";
import * as actions from "./actions";
import * as S from "./style";
import cleanWhitespace from "../../../utils/helpers/cleanWhitespace";
import t from "../../../utils/helpers/translator";

const useQuery = () => new URLSearchParams(useLocation().search);

const SpendPartnerForm = ({
  isMobile,
  getUserAccount,
  updateUserAccount,
  userAccount,
  createLoading,
  createSpendPartner,
  createError,
  updatedData,
  edit,
}) => {
  const [state, setState] = useState({
    name: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    password: "",
  });
  const [btnOn, setBtnOn] = useState(false);
  const [errors, setErrors] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  const query = useQuery();

  const { id } = useParams();
  const spendVenueIdToReassign = query.get("venueId");

  // get user account details
  useEffect(() => {
    if (edit) {
      getUserAccount(id, roles.SPEND_PARTNER);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (userAccount.id && edit) {
      setState({
        ...userAccount,
        phoneNumber: stripLeadingTab(
          userAccount.phoneNumber?.replace(/ /g, ""),
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccount.id]);

  const redirectToCreateVenue = () => {
    if (spendVenueIdToReassign) {
      return history.push(
        navRoutes.TEMPO.SPEND_PARTNER.replace(
          ":id",
          updatedData.spendPartner.userId,
        ),
      );
    }

    history.push({
      pathname: navRoutes.TEMPO.CREATE_SPEND_VENUE.replace(
        ":spendPartnerId",
        updatedData.spendPartner.userId,
      ),
      search: `new=${!edit}`,
    });
  };

  const onChange = (e) => {
    setBtnOn(true);
    const { name, value } = e.target;
    const cleanEmail =
      name === "email" && value.toLowerCase().replace(/ /g, "").trim();

    switch (name) {
      case "email":
        setState((_state) => ({ ..._state, email: cleanEmail }));
        break;
      case "phoneNumber":
        setState((_state) => ({ ..._state, [name]: value.trim() }));
        break;
      default:
        setState((_state) => ({ ..._state, [name]: value }));
        break;
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const _phoneNumber = stripLeadingTab(state.phoneNumber?.replace(/ /g, ""));
    try {
      validate({
        ...state,
        phoneNumber: _phoneNumber,
        isNew: !edit,
      });
      setErrors({});
      let res;
      if (edit) {
        res = await updateUserAccount(
          {
            name: cleanWhitespace(state.name),
            email: cleanWhitespace(state.email),
            firstName: cleanWhitespace(state.firstName),
            lastName: cleanWhitespace(state.lastName),
            phoneNumber: _phoneNumber,
          },
          id,
          roles.SPEND_PARTNER,
        );
      } else {
        res = await createSpendPartner({
          name: cleanWhitespace(state.name),
          email: cleanWhitespace(state.email),
          firstName: cleanWhitespace(state.firstName),
          lastName: cleanWhitespace(state.lastName),
          phoneNumber: _phoneNumber,
          password: state.password,
          spendVenueIdToReassign,
        });
      }
      if (res.data) {
        setErrors({});
        setModalOpen(true);
      }

      setBtnOn(false);
    } catch (err) {
      if (err.name === "ValidationError") {
        setErrors(err.inner);
      } else {
        setErrors({ serverError: err.message });
      }
      setBtnOn(false);
    }
  };

  return (
    <>
      <Row>
        <Col w={[4, 4, 4]}>
          <GoBack showText />
        </Col>
      </Row>
      <Row jc="space-between">
        <Col w={[4, 6, 6]}>
          <T.H40Caps color="midnight" mt="30">
            {edit ? "EDIT ACCOUNT" : "ADD RECOGNITION PARTNER"}
          </T.H40Caps>
        </Col>
        <Col w={[4, 6, 4]}>
          <S.ImageWrapper>
            <Image
              image="galleryLarge"
              alt="gallery"
              height={210}
              width={220}
            />
          </S.ImageWrapper>
        </Col>
      </Row>

      {edit && (
        <Row mt="-50px">
          <Col w={[4, 12, 12]}>
            <T.H24 color="midnight">Partner Account Details</T.H24>
          </Col>
          <Col w={[4, 10, 8]}>
            <T.Body14R color="gray3" mt="20" mb="20">
              Please note if you change the email address this will change the
              login details, so please notify the contact if you do this.
            </T.Body14R>
          </Col>
        </Row>
      )}

      <Row mb={edit ? 4 : undefined} mt={!edit ? "-50px" : "0"}>
        <Col w={[4, 6, 4]}>
          <InputField
            placeholder="Type partner name here...."
            label="Partner Name"
            type="text"
            name="name"
            setValue={onChange}
            value={state.name}
            error={errors.name}
            autoComplete="new-password"
          />
        </Col>
      </Row>

      {!edit && (
        <Row>
          <Col w={[4, 12, 12]}>
            <T.H24 color="midnight" mt="60">
              Account Settings
            </T.H24>
          </Col>
          <Col w={[4, 10, 8]}>
            <T.Body16R color="gray3" mt="20" mb="20">
              Enter the account details so the recognition partner can set up
              their account. These will be the details the partner will use to
              log-in and manage their venue(s). Please make a note of the
              password you create and ask them to change it as soon as they can.
            </T.Body16R>
          </Col>
        </Row>
      )}

      {/* Fields */}

      <Row>
        <Col w={[4, 6, 4]}>
          <InputField
            mt={2}
            label="First name"
            type="text"
            placeholder="Type first name here..."
            name="firstName"
            setValue={onChange}
            value={state.firstName}
            error={errors.firstName}
          />
        </Col>
        <Col w={[4, 6, 4]}>
          <InputField
            mt={2}
            label="Last name"
            type="text"
            placeholder="Type last name here..."
            name="lastName"
            setValue={onChange}
            value={state.lastName}
            error={errors.lastName}
          />
        </Col>
      </Row>
      <Row>
        <Col w={[4, 6, 4]}>
          <InputField
            mt={2}
            label="Email"
            placeholder="Type email here..."
            name="email"
            setValue={onChange}
            value={state.email}
            error={
              errors.email ||
              (createError &&
                createError.httpStatusCode === 409 &&
                t(createError.message, "english"))
            }
          />
        </Col>
        <Col w={[4, 6, 4]}>
          <InputField
            mt={2}
            label="Contact Number"
            type="tel"
            placeholder="Type contact number here..."
            name="phoneNumber"
            setValue={onChange}
            value={state.phoneNumber}
            error={errors.phoneNumber}
          />
        </Col>
      </Row>

      {!edit && (
        <Row>
          <Col w={[4, 6, 4]}>
            <InputField
              mt={2}
              type="password"
              placeholder="Type password here..."
              label="Password"
              name="password"
              setValue={onChange}
              value={state.password}
              error={errors.password}
              showPasswordPop
            />
          </Col>
        </Row>
      )}
      <Row
        style={{
          marginTop: !isMobile ? "40px" : "2rem",
        }}
      >
        <Col w={[4, 6, 4]}>
          {createError && createError.httpStatusCode !== 409 && (
            <T.Body12B color="pink">
              {t(createError.message, "english")}
            </T.Body12B>
          )}
          <Button
            primary
            size="l"
            type="submit"
            handleClick={onSubmit}
            loading={createLoading}
            disabled={!btnOn}
          >
            {edit ? "Update Partner" : "Continue"}
          </Button>
        </Col>
      </Row>
      {errors.serverError && (
        <Row mt={2}>
          <Col w={[4, 8, 8]}>
            <T.Body16R color="pink">
              {t(errors.serverError, "english")}
            </T.Body16R>
          </Col>
        </Row>
      )}

      <Modal
        modalOpen={modalOpen}
        handleCloseModal={() => setModalOpen(false)}
        confirmFun={() => {
          if (!edit) redirectToCreateVenue();
        }}
        buttonText="Continue"
        width={690}
      >
        <T.Body20B color="blue">
          {edit
            ? "Recognition Partner Updated!"
            : "Recognition Partner Created!"}
        </T.Body20B>
        <T.Body16B color="gray3" mt="15">
          {edit ? (
            <>
              You&apos;ve changed the account details for this Recognition
              Partner. If you have changed their email address, please inform
              all appropriate contacts.
            </>
          ) : (
            <>
              You have now set up {state.name} as a recognition partner and can
              add venues to it.{" "}
              {spendVenueIdToReassign && (
                <>
                  You have also reassigned {updatedData.updatedSpendVenue.name}{" "}
                  to {state.name}.
                </>
              )}
            </>
          )}
        </T.Body16B>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  createError: state.spendPartnerForm.createSpendPartnersError,
  createLoading: state.spendPartnerForm.createSpendPartnersLoading,
  updatedData: state.spendPartnerForm.updatedData,
  userAccount: state.userAccount.data,
});

const mapActionToProps = {
  createSpendPartner: actions.createSpendPartner,
  getUserAccount: accountActions.getUserAccount,
  updateUserAccount: accountActions.updateUserAccount,
};

export default connect(mapStateToProps, mapActionToProps)(SpendPartnerForm);
