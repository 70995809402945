const formatLink = (link) => {
  let _link = link;
  const hasProtocol = link.includes("http://") || link.includes("https://");
  if (!hasProtocol) {
    _link = `https://${link}`;
  }

  return _link;
};

export default formatLink;
