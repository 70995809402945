/* eslint-disable import/prefer-default-export */
import {
  GET_USER_ACCOUNT_LOADING,
  GET_USER_ACCOUNT_SUCCESS,
  GET_USER_ACCOUNT_FAIL,
  UPDATE_USER_ACCOUNT_LOADING,
  UPDATE_USER_ACCOUNT_SUCCESS,
  UPDATE_USER_ACCOUNT_FAIL,
} from "../types";

const initialState = {
  getAccountLoading: false,
  updateAccountLoading: false,
  data: [],
  error: null,
};

const myAccountReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_USER_ACCOUNT_LOADING:
      return {
        ...state,
        getAccountLoading: true,
        data: [],
      };
    case GET_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        getAccountLoading: false,
        data: payload,
      };
    case GET_USER_ACCOUNT_FAIL:
      return {
        ...state,
        getAccountLoading: false,
        error: payload,
      };

    case UPDATE_USER_ACCOUNT_LOADING:
      return {
        ...state,
        updateAccountLoading: true,
        error: null,
      };
    case UPDATE_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        updateAccountLoading: false,
      };
    case UPDATE_USER_ACCOUNT_FAIL:
      return {
        ...state,
        updateAccountLoading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default myAccountReducer;
