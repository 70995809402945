import React from "react";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import SpendActivity from "./SpendActivity";
import EarnActivity from "./EarnActivity";
import SpendOptions, { BookingCodesSuccess } from "./SpendOptions";

const Activity = ({ activityType, location, lang }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const language = location?.state?.lang || lang;

  switch (activityType) {
    case "earn":
      return (
        <EarnActivity isTablet={isTablet} isMobile={isMobile} lang={language} />
      );
    case "spendOptions":
      return (
        <SpendOptions isTablet={isTablet} isMobile={isMobile} lang={language} />
      );
    case "bookingSuccess":
      return (
        <BookingCodesSuccess
          isTablet={isTablet}
          isMobile={isMobile}
          lang={language}
        />
      );
    case "spend":
    default:
      return (
        <SpendActivity
          isTablet={isTablet}
          isMobile={isMobile}
          lang={language}
        />
      );
  }
};
export default Activity;
