import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";

import * as S from "./style";

import { Row } from "../../../../components/Grid";

// SECTIONS
import TopSection from "./TopSection";
import DetailsAndStatsSection from "./DetailsAndStatsSection";
import CreditRequests from "../CreditRequests";

import { getProgrammeById } from "./actions";

const SingleProgramme = ({
  issued,
  earned,
  gifted,
  programme,
  earnGroups,
  membersReached,
  spendVenues,
  transactions,
  balance,
  spentWithEarnGroupsPercentage,
  spentWithSpendVenuesPercentage,
  loading,
  getProgrammeByIdAction,
  role,
  managers,
}) => {
  const stats = {
    issued,
    earned,
    spendPercVenues: spentWithSpendVenuesPercentage,
    spendPercEarnGroups: spentWithEarnGroupsPercentage,
    gifted,
  };

  const details = {
    startDate: programme.startDate,
    closeDate: programme.closeDate,
    endDate: programme.endDate,
    focuses: programme.keyFocuses,
    tkId: programme.tkId,
  };

  const history = useHistory();
  const { id: programmeId } = useParams();
  const { state } = useLocation();

  useEffect(() => {
    if (programmeId) {
      getProgrammeByIdAction(programmeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programmeId]);

  return (
    <S.Container>
      <TopSection
        balance={balance}
        name={programme.name}
        earnGroups={earnGroups}
        members={membersReached}
        spendVenues={spendVenues}
        transactions={transactions}
        justCreated={state && state.justCreated}
        history={history}
        id={programmeId}
        loading={loading}
      />
      <DetailsAndStatsSection
        id={programmeId}
        details={details}
        stats={stats}
        history={history}
        loading={loading}
        role={role}
        managers={managers}
      />
      <Row style={{ paddingLeft: "10px" }}>
        <CreditRequests programme programmeId={programmeId} />
      </Row>
    </S.Container>
  );
};

const mapStateToProps = (state) => ({
  ...state.staffSingleProgramme,
});

const mapActionsToProps = {
  getProgrammeByIdAction: getProgrammeById,
};

export default connect(mapStateToProps, mapActionsToProps)(SingleProgramme);
