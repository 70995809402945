import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => {
  const stylesObj = {
    root: {
      maxWidth: ({ size }) => {
        switch (size) {
          case "small":
            return "290px";
          case "normal":
            return "300px";
          case "large":
          case "largeOptional":
            return "500px";
          case "xLarge":
            return "650px";
          default:
            return "100%";
        }
      },
      marginTop: ({ mt }) => (mt ? theme.spacing(mt) : 0),
      marginBottom: ({ mb }) => (mb ? theme.spacing(mb) : 0),
      marginLeft: ({ ml }) => (ml ? theme.spacing(ml) : 0),
      marginRight: ({ mr }) => (mr ? theme.spacing(mr) : 0),
      position: "relative",
      minHeight: "48px",
      "& input::-ms-reveal": {
        display: "none",
      },
      "& input::-ms-clear": {
        display: "none",
      },
    },
    textInputLabel: {
      color: ({ labelColor }) =>
        labelColor ? theme.colors[labelColor] : theme.colors.midnight,
      paddingBottom: theme.spacing(0),
      marginLeft: theme.spacing(2),
      fontSize: "16px",
      fontWeight: "bold",
      labelColor: theme,
    },
    textInputSubLabel: {
      color: theme.colors.gray3,
      marginLeft: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      fontSize: "14px",
      fontWeight: "normal",
    },
    input: {
      width: ({ size }) => {
        switch (size) {
          case "xLarge":
            return "360px";
          case "normal":
            return "300px";
          case "small":
            return "100px";
          default:
            return "100%";
        }
      },

      borderRadius: "10px",
      border: ({ error, textTooLong }) =>
        error || textTooLong
          ? theme.borders.textInputError
          : theme.borders.textInput,

      fontSize: "0.875rem",
      background: theme.colors.white,
    },
    inputPlaceholder: {
      "&::placeholder": {
        textOverflow: "ellipsis !important",
        color: theme.colors.gray3,
        ...theme.typography.placeholder,
      },
    },
    textFieldError: {
      paddingTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      color: theme.colors.pink,
      fontSize: "14px",
    },
    counter: {
      display: "flex",
    },
    counterHeaderText: {
      textAlign: ({ textLeft }) => (textLeft ? "left" : "center"),
      paddingBottom: ({ size }) => (size === "small" ? "0" : theme.spacing(1)),
      width: "100%",
      marginLeft: "15px !important",
    },
    counterInput: {
      border: ({ error }) =>
        error ? theme.borders.textInputError : theme.borders.textInput,
      borderRadius: "10px",
      height: ({ size }) => (size === "small" ? "40px" : "60px"),
      width: "70%",
      boxSizing: "border-box",
      textAlign: "center",
      color: theme.colors.blue,
      fontFamily: "Poppins",
      marginLeft: ({ size }) => size === "small" && "5px",
      marginRight: ({ size }) => size === "small" && "5px",
      display: "flex",
      alignItems: "center",
      background: "white",
      fontSize: ({ size }) => (size === "small" ? "1rem" : "22px"),
      fontWeight: "900",
      minHeight: "50px",
      padding: "0",

      "&::placeholder": {
        color: theme.colors.gray3,
      },

      "&:focus": {
        "&::-webkit-input-placeholder, &::-moz-placeholder, &::-moz-placeholder, &:-ms-input-placeholder, &::placeholder":
          {
            color: "transparent",
          },

        "&::placeholder": {
          color: "transparent",
        },
      },

      // hide the counter arrows
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "-moz-appearance": "textfield",
    },
    counterWrapper: {
      display: "flex",
      justifyContent: ({ justify }) => justify || "center",
      position: "relative",
      opacity: ({ disabled }) => (disabled ? "0.4" : "1"),

      "& button": {
        backgroundColor: "none !important",
        "&:hover": {
          background: "none !important",
          backgroundColor: "none !important",
          transition: "none",
          color: "none",
        },
        "&:active": {
          background: `none !important`,
          backgroundColor: "none !important",
          transition: "none",
          color: "none",
        },
      },
    },
    checkbox: {
      height: "25px",
      width: "35px",
      opacity: ({ disabled }) => (disabled ? "0.4" : "1"),
    },
    checkboxLabelDiv: {
      marginLeft: "-3px",
      paddingTop: ({ checkboxSize }) => checkboxSize === "small" && "2px",
      width: "80%",
      wordWrap: "break-word",
      overflowWrap: "break-word",
      "-ms-word-break": "break-all",
      wordBreak: "break-word",
      display: "flex",
      flexDirection: "column",
      cursor: ({ disabled }) => (disabled ? "not-allowed" : "pointer"),
      maxWidth: ({ checkboxSize }) => checkboxSize === "small" && "350px",
      opacity: ({ disabled }) => (disabled ? "0.4" : "1"),
    },

    dropdownField: {
      // minWidth: "280px",
      width: "100%",
    },
    dropdownCaption: {
      paddingTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
      fontSize: "14px",
    },
    calendarWrapper: {
      fontFamily: "Poppins",
      zIndex: ({ noZIndex }) => (noZIndex ? "inherit" : "998"), // keep under 999 (z-index of header) so that it doesn't overflow the header, use noZIndex prop to override when there is no header in the page
      ".CalendarDay__hovered_span": {
        backgroundColor: "red",
      },
      height: "50px",
      background: "white",
      fontSize: "0.875rem",
      border: ({ error }) =>
        error ? theme.borders.textInputError : theme.borders.textInput,
      borderRadius: "10px",
      boxSizing: "border-box",
      width: ({ size }) => {
        switch (size) {
          case "xLarge":
            return "360px";
          case "normal":
            return "300px";
          case "small":
            return "100px";
          default:
            return "100%";
        }
      },
    },
    singleDateWrapper: {
      border: ({ error }) =>
        error ? theme.borders.textInputError : theme.borders.textInput,
      borderRadius: "10px",
      boxSizing: "border-box",
      width: ({ size }) => {
        switch (size) {
          case "xLarge":
            return "360px";
          default:
            return "300px";
        }
      },

      "& input": {
        width: "100%",
      },
    },
    autoComplete: {
      border: ({ error }) =>
        error ? theme.borders.textInputError : theme.borders.textInput,
      borderRadius: "10px",
      boxSizing: "border-box",
      height: "50px",
      background: "white",
    },

    autoCompleteInput: {
      "&::placeholder": {
        ...theme.typography.placeholder,
      },
      height: "10px",
    },
    modal: {
      "& .MuiTypography-caption": {
        fontSize: ({ language }) => (language === "welsh" ? "14px" : "16px"),
      },
    },

    datePicker: {
      border: ({ error }) =>
        error ? theme.borders.textInputError : theme.borders.textInput,
      borderRadius: "10px",
      boxSizing: "border-box",
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      display: "flex",
      justifyContent: "center",
      background: "white",
      width: "100%",
      fontSize: "14px",
      "& input": {
        fontSize: "1rem",
      },
      "& input::-webkit-clear-button": { display: "none" },
      "&::placeholder": {
        textOverflow: "ellipsis !important",
        color: theme.colors.gray3,
        ...theme.typography.placeholder,
      },
    },

    datePickerWrapper: {
      position: "relative",
      width: "100%",
      marginBottom: ({ mb }) => (mb ? theme.spacing(mb) : 0),

      "& input": {
        paddingLeft: "10px",
      },
    },

    datePickerIconWrapper: {
      position: "absolute",
      outline: "none",
      border: "none",
      right: 20,
      bottom: "0",
      background: "none",
      cursor: "pointer",
      height: 50,
    },
    maxLength: {
      alignSelf: "flex-end",
      fontSize: "0.875rem",
      margin: theme.spacing(1),
      color: ({ textTooLong }) =>
        textTooLong ? theme.colors.pink : theme.colors.gray3,
    },
    costVaries: {
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: theme.spacing(3),
    },
  };

  stylesObj.searchByActivityAutoComplete = {
    ...stylesObj.autoComplete,
    ...{
      height: "100%",
      width: "100%",
      "&.MuiInputBase-root": {
        flexWrap: "wrap-reverse",
      },
    },
  };

  stylesObj.searchByActivityAutoCompleteInput = {
    ...stylesObj.autoCompleteInput,
    ...{
      "&.MuiInputBase-input": {
        "&.MuiAutocomplete-input": {
          width: "100%",
        },
      },
    },
  };
  return stylesObj;
});

export default useStyles;
