import {
  SIGN_UP_OFFLINE_LOADING_START,
  SIGN_UP_OFFLINE_LOADING_END,
  SIGN_UP_OFFLINE_SUCCESS,
  SIGN_UP_OFFLINE_FAIL,
  SIGN_UP_OFFLINE_CLOSE_MODAL,
} from "../../../redux/types";
import { auth } from "../../../api-calls";
import { EARN_GROUP } from "../../../constants/navRoutes";

export const signupOffline = (formData, history) => async (dispatch) => {
  dispatch({
    type: SIGN_UP_OFFLINE_LOADING_START,
  });

  const { data, error } = await auth.signup({ formData });

  dispatch({
    type: SIGN_UP_OFFLINE_LOADING_END,
  });

  if (data) {
    dispatch({
      type: SIGN_UP_OFFLINE_SUCCESS,
      payload: data,
    });
    history.push(EARN_GROUP.INVITE_NONDIGITAL_SUCCESS);
  } else if (error) {
    dispatch({
      type: SIGN_UP_OFFLINE_FAIL,
      payload: error,
    });
  }
};

export const cancelErrorModal = () => ({ type: SIGN_UP_OFFLINE_CLOSE_MODAL });
