import React from "react";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";

import * as S from "./style";
import * as T from "../../../components/Typograpy";
import { EARN_GROUP } from "../../../constants/navRoutes";
import Image from "../../../components/Image";
import InviteLink from "../../../components/Links/InviteLink";
import { Regular } from "../../../components/Button";

import t from "../../../utils/helpers/translator";

const DigitalInvite = ({ inviteToken, language }) => {
  const history = useHistory();

  return (
    <S.Wrapper>
      <S.Header>{t("inviteMembers", language)}</S.Header>
      <S.ContentWrapper>
        <S.ImageWrapperDigital>
          <Image image="group" alt="group" />
        </S.ImageWrapperDigital>
        <S.BodyDigital>
          <S.SubHeader>{t("sendYourUnique", language)}</S.SubHeader>
          <InviteLink
            background="pinkLight"
            color="pink"
            url={`${window.location.origin}/sign-up/?invite=${inviteToken}`}
          />
          <T.Body16R style={{ padding: "20px 0" }}>
            {t("sendUniqueLinkExplanation", language)}.
          </T.Body16R>
          <T.Body16R style={{ marginBottom: "40px" }}>
            {t("membersNeedEmailAddressExplanation", language)}.
          </T.Body16R>
          <S.SubHeader>{t("whatIfNoEmail", language)}?</S.SubHeader>
          <S.ButtonWrapper>
            <Regular
              bgColor="blue"
              primary
              style={{ margin: "0" }}
              onClick={() => history.push(EARN_GROUP.INVITE_NONDIGITAL)}
            >
              {t("clickToFindOut", language)}
            </Regular>
          </S.ButtonWrapper>
        </S.BodyDigital>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  inviteToken: state.auth.profile.inviteToken,
  language: state.auth.language,
});

export default connect(mapStateToProps)(DigitalInvite);
