import { styled } from "@material-ui/core/styles";

export const Wrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
}));

export const HeaderWrapper = styled("header")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  marginBottom: "80px",
}));

export const Section = styled("section")(({ theme, color }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "160px",
  backgroundColor: theme.colors[color] || color,
  border: "1px solid #F9ECF2",
  marginBottom: "2rem",
  marginLeft: "0",

  [theme.breakpoints.mobileXL]: {
    width: "100vw",
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },

  [theme.breakpoints.tablet]: {
    width: "calc(100vw - 320px)",
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },

  [theme.breakpoints.laptop]: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    maxWidth: "calc(1800px - 560px)",
  },
}));

export const Stats = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  marginLeft: 0,
  width: "100%",
}));

export const CardSection = styled("div")(({ theme }) => ({
  background: theme.colors.white,
  boxShadow: theme.customShadows.dp3,
  paddingTop: theme.spacing(5),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  minHeight: 280,
  borderRadius: "1px",
}));

export const CsvExport = styled("a")({
  textDecoration: "none",
});
