import React from "react";
import { Switch } from "react-router-dom";

// CONSTANTS
import { PROGRAMME_MANAGER } from "../../constants/navRoutes";
import { roles } from "../../constants";

// PROGRAMME MANAGER COMPONENTS
import EarnGroups from "./EarnGroups";
import TimeCreditRequest from "./TimeCreditRequest";

// MULTI-USE COMPONENTS
import Dashboard from "../Dashboard";
import SignUpExtProgManager from "../Signup/SignUpExtProgManager";

// OTHER ROUTERS / COMPONENTS
import ErrorRouter from "../ErrorRoutes";
import {
  IssueCredits,
  IssueCreditsSuccess,
} from "../StaffRoutes/Staff/CreditRequests";
import Staff from "../StaffRoutes/Staff";

// COMMON
import Route from "../../components/Route";

const ProgrammeManagerRouter = () => (
  <Switch>
    <Route
      exact
      path={PROGRAMME_MANAGER.SIGN_UP}
      layout="onboarding"
      Component={SignUpExtProgManager}
      hideLangToggle
    />
    <Route
      exact
      isPrivate
      path={PROGRAMME_MANAGER.DASHBOARD}
      Component={Dashboard}
      layout="general"
      navbar
      footer
      header
      allowedRoles={[roles.PROGRAMME_MANAGER]}
      hideLangToggle
    />

    <Route
      exact
      path={PROGRAMME_MANAGER.EARN_GROUPS}
      navbar
      header
      isPrivate
      Component={EarnGroups}
      allowedRoles={[roles.PROGRAMME_MANAGER]}
      hideLangToggle
    />

    <Route
      exact
      path={PROGRAMME_MANAGER.ISSUE_CREDITS_EARN_GROUP}
      Component={IssueCredits}
      action="issue"
      toRole={roles.EARN_GROUP}
      navbar
      header
      layout="splitScreen"
      side="right"
      isPrivate
      allowedRoles={[roles.PROGRAMME_MANAGER]}
      hideLangToggle
    />
    <Route
      exact
      path={PROGRAMME_MANAGER.PROGRAMME_INVITE_GROUPS}
      navbar
      header
      isPrivate
      Component={Staff.ProgrammeInviteEarnGroup}
      allowedRoles={[roles.PROGRAMME_MANAGER]}
      hideLangToggle
    />

    <Route
      exact
      path={PROGRAMME_MANAGER.REQUESTS}
      Component={TimeCreditRequest}
      navbar
      isPrivate
      layout="general"
      header
      allowedRoles={[roles.PROGRAMME_MANAGER]}
      hideLangToggle
    />
    <Route
      exact
      path={PROGRAMME_MANAGER.REQUEST_REJECT}
      Component={IssueCredits}
      action="reject"
      navbar
      header
      layout="splitScreen"
      side="right"
      isPrivate
      allowedRoles={[roles.PROGRAMME_MANAGER]}
      hideLangToggle
    />
    <Route
      exact
      path={PROGRAMME_MANAGER.REQUEST_APPROVE}
      Component={IssueCredits}
      action="approve"
      navbar
      header
      layout="splitScreen"
      side="right"
      isPrivate
      allowedRoles={[roles.PROGRAMME_MANAGER]}
      hideLangToggle
    />
    <Route
      exact
      path={PROGRAMME_MANAGER.REQUEST_PARTIAL}
      Component={IssueCredits}
      action="partially approve"
      navbar
      header
      layout="splitScreen"
      side="right"
      isPrivate
      allowedRoles={[roles.PROGRAMME_MANAGER]}
      hideLangToggle
    />
    <Route
      exact
      path={PROGRAMME_MANAGER.REQUEST_SUCCESS}
      Component={IssueCreditsSuccess}
      navbar
      header
      layout="splitScreen"
      side="right"
      isPrivate
      allowedRoles={[roles.PROGRAMME_MANAGER]}
      hideLangToggle
    />

    <Route
      exact
      path={PROGRAMME_MANAGER.ISSUE_SUCCESS}
      Component={IssueCreditsSuccess}
      navbar
      header
      layout="splitScreen"
      side="right"
      isPrivate
      allowedRoles={[roles.PROGRAMME_MANAGER]}
      hideLangToggle
    />

    {/* ERROR ROUTES */}
    <ErrorRouter />
  </Switch>
);

export default ProgrammeManagerRouter;
