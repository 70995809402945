import React from "react";
import * as S from "./style";
import * as T from "../Typograpy";

const BigLink = ({ color, text, path, lang }) => (
  <S.BigLinkWrapper to={path} color={color}>
    <T.H40
      color="white"
      style={{
        textDecoration: "none",
        lineHeight: "50px",
        fontSize: lang !== "english" && "34px",
      }}
    >
      {text}
    </T.H40>
  </S.BigLinkWrapper>
);
export default BigLink;
