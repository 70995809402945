import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { redirectAfterLogin } from "../../../redux/utils";

import * as S from "./style";
import * as actions from "./actions";
import * as T from "../../../components/Typograpy";

import Image from "../../../components/Image";

import translate from "../../../utils/helpers/translator";
import ResendLink from "./ResendLink";

const ActivateAccountEmailSent = ({
  lang,
  userData,
  emailVerified,
  getActivateAccountToken,
  userId,
  error = {},
}) => {
  const history = useHistory();

  if (emailVerified) {
    redirectAfterLogin({ data: userData, history });
  }

  useEffect(() => {
    getActivateAccountToken({ userId, history });
  }, [getActivateAccountToken, userId, history]);

  return (
    <S.Wrapper>
      <Image
        image="welcomeMember1"
        alt="Tempo activity"
        width="200px"
        height="auto"
        customStyle={{ maxHeight: "280px", margin: "0 auto" }}
      />
      <S.Body>
        <T.H40Caps
          color="midnight"
          style={{
            marginBottom: "21px",
          }}
        >
          {translate("nearlyThere", lang)}!
        </T.H40Caps>
        <T.Body16R style={{ margin: "0" }} color="gray3">
          {translate("toActivateYourAccountPleaseGoToYourInbox", lang)}
        </T.Body16R>
        {error?.message && (
          <T.Body16R color="pink">{translate(error.message, lang)}</T.Body16R>
        )}

        <ResendLink />
      </S.Body>
    </S.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  userId: state.auth.id,
  lang: state.auth.language,
  userData: state.auth,
  emailVerified: state.auth.emailVerified,
  token: state.activateAccount.token,
  error: state.activateAccount.error,
  loading: state.activateAccount.resendLoading,
});

export default connect(mapStateToProps, {
  getActivateAccountToken: actions.getActivateAccountToken,
  resendActivateAccountToken: actions.resendActivateAccountToken,
})(ActivateAccountEmailSent);
