export const decideType = (type) => {
  switch (type) {
    case "free":
    case "exchange":
    case "bought":
      return "Issued";
    case "pending":
    case "gift":
      return "Gifted";
    case "spend":
      return "Used";
    case "earn":
      return "Earned";
    case " paymentDue": // cyan
      return " Payment Due";
    case " cancelled": // purple
      return " Cancelled";
    case " purchased": // midnight
      return " Purchased";
    default:
      return type;
  }
};

export const decideTypeTempo = (type) => {
  switch (type) {
    case "bought":
      return "PURCHASED";
    case "free":
    case "issue":
      return "Issued";
    case "exchange":
      return "Exchanged";
    case "pending":
    case "gift":
      return "Gifted";
    case "spend":
      return "Used";
    case "earn":
      return "Earned";
    default:
      return type;
  }
};
