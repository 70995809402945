const keys = {
  ADDICTION: "Addiction",
  ANIMALS: "Animals",
  ARTS_CULTURE_SCIENCE: "Arts, Culture & Science",
  ARMED_FORCES_EMERGENCY_SERVICES: "Armed Forces & Emergency Services",
  COMMUNITY_DEVELOPMENT: "Community Development",
  DISABILITY: "Disability",
  ENVIRONMENT_CONSERVATION_HERITAGE: "Environment, Conservation & Heritage",
  FAMILIES_CHILDREN: "Families & Children",
  FOOD_FOOD_WASTE: "Food & Food Waste",
  HEALTH_SOCIAL_CARE: "Health & Social Care",
  HOUSING_HOMELESSNESS: "Housing & Homelessness",
  OLDER_PEOPLE: "Older People",
  POVERTY_RELIEF: "Poverty Relief",
  RIGHTS_RELIGION_EQUALITY: "Rights, Religion & Equality",
  SCHOOLS_EDUCATION: "Schools & Education",
  SKILLS_EMPLOYABILITY: "Skills & Employability",
  SPORTS_PHYSICAL_ACTIVITY: "Sports & Physical Activity",
  YOUNG_PEOPLE: "Young People",
};

const keysWelsh = {
  ADDICTION: "Dibyniaeth",
  ANIMALS: "Anifeiliaid",
  ARTS_CULTURE_SCIENCE: "Lluoedd Arfog a Gwasanaethau Brys",
  ARMED_FORCES_EMERGENCY_SERVICES: "Celfyddydau, diwylliant a gwyddoniaeth",
  COMMUNITY_DEVELOPMENT: "Datblygiad Cymunedol",
  DISABILITY: "Anabledd",
  ENVIRONMENT_CONSERVATION_HERITAGE: "Cadwraeth, Amgylchedd a Threftadaeth",
  FAMILIES_CHILDREN: "Teuleuoedd a Phlant",
  FOOD_FOOD_WASTE: "Bwyd a Gwastraff Bwyd",
  HEALTH_SOCIAL_CARE: "Iechyd a Gofal Cymdeithasol",
  HOUSING_HOMELESSNESS: "Tai a Digartrefedd",
  OLDER_PEOPLE: "Pobl Hŷn",
  POVERTY_RELIEF: "Lliniaru Tlodi",
  RIGHTS_RELIGION_EQUALITY: "Hawliau, Crefydd a Chydraddoldeb",
  SCHOOLS_EDUCATION: "Ysgolion ac Addysg",
  SKILLS_EMPLOYABILITY: "Sgiliau a Chyflogadwyedd",
  SPORTS_PHYSICAL_ACTIVITY: "Chwaraeon a Gweithgaredd Corfforol",
  YOUNG_PEOPLE: "Pobl Ifanc",
};

const focusValuesMapWelsh = {};
Object.keys(keys).forEach((e) => {
  focusValuesMapWelsh[keys[e]] = keysWelsh[e];
});

const focusDropDownEnglish = Object.values(keys).map((e) => ({
  label: e,
  value: e,
}));

const focusDropDownWelsh = Object.entries(keysWelsh).map(([key, value]) => ({
  label: value,
  value: keys[key],
}));

export {
  keys,
  keysWelsh,
  focusDropDownEnglish,
  focusDropDownWelsh,
  focusValuesMapWelsh,
};
