import { spendVenues, spendPartners } from "../../api-calls";
import { navRoutes } from "../../constants";

import {
  GET_PARTNER_SPEND_VENUES_LOADING,
  GET_PARTNER_SPEND_VENUES_SUCCESS,
  GET_PARTNER_SPEND_VENUES_FAIL,
  UPDATE_SELECTED_VENUE_LOADING,
  UPDATE_SELECTED_VENUE_SUCCESS,
  UPDATE_SELECTED_VENUE_FAIL,
} from "../../redux/types";

export const getSpendVenues =
  ({ basic, details }) =>
  async (dispatch) => {
    dispatch({ type: GET_PARTNER_SPEND_VENUES_LOADING });
    try {
      const { data, error } = await spendVenues.getSpendVenues({
        basic,
        details,
      });
      if (data) {
        dispatch({ type: GET_PARTNER_SPEND_VENUES_SUCCESS, payload: data });
      } else {
        dispatch({
          type: GET_PARTNER_SPEND_VENUES_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_PARTNER_SPEND_VENUES_FAIL,
        payload: error,
      });
    }
  };

export const updateSelectedSpendVenue =
  (spendVenueUserId, history) => async (dispatch) => {
    dispatch({ type: UPDATE_SELECTED_VENUE_LOADING });
    try {
      const { data, error } = await spendPartners.updateSelectedSpendVenue({
        spendVenueUserId,
      });
      if (data) {
        dispatch({ type: UPDATE_SELECTED_VENUE_SUCCESS, payload: data });
        history.push(navRoutes.SPEND_VENUE.ACCEPT_TC);
      } else {
        dispatch({
          type: UPDATE_SELECTED_VENUE_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_SELECTED_VENUE_FAIL,
        payload: error,
      });
    }
  };
