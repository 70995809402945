import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import * as S from "./style";
import * as T from "../../../components/Typograpy";
import CreditCard from "../../../components/Cards/CreditCard";
import { Regular } from "../../../components/Button";
import { InputField } from "../../../components/Inputs";
import { Row, Col } from "../../../components/Grid";

import * as actions from "./actions";
import * as paidPackagesActions from "../../../redux/actions/paidPackages";

import validate from "../../../validation/schemas/paid-request";
import calculatePriceDetails from "../../../utils/helpers/calculate-price-details";

import t from "../../../utils/helpers/translator";

const PaidRequest = ({
  language,
  isMobile,
  error,
  loading,
  createPaidPackageOrder,
  getPaidPackages,
  activePrice,
  packages,
  getTcPrice,
}) => {
  const [selectedPackage, setSelectedPackage] = useState({});
  const [customCredits, setCustomCredits] = useState(null);
  const [customCreditsError, setCustomCreditsError] = useState("");
  const notSelected = (packageId) =>
    (selectedPackage.id && selectedPackage.id !== packageId) || customCredits;

  const history = useHistory();

  const handleSubmit = () => {
    createPaidPackageOrder({
      customCredits,
      selectedPackageId: selectedPackage.id,
      history,
    });
  };
  useEffect(() => {
    try {
      if (customCredits) {
        validate({
          customCredits,
          minimumTc: activePrice.minimumTc,
          maximumTc: activePrice.maximumTc,
        });
      }
      setCustomCreditsError("");
    } catch (err) {
      if (err.name === "ValidationError") {
        setCustomCreditsError(err.inner.customCredits);
      }
    }
  }, [customCredits, activePrice.minimumTc, activePrice.maximumTc]);

  const _setSelectedPackage = (value) => {
    setCustomCredits(null);
    setSelectedPackage(value);
  };

  useEffect(() => {
    getPaidPackages();
    getTcPrice();
  }, [getPaidPackages, getTcPrice]);

  const getTotalPrice = (tcCredits) => {
    const { vat, price } = activePrice;

    const { totalPriceFormatted2 } = calculatePriceDetails({
      vat,
      price,
      credits: tcCredits,
    });

    return totalPriceFormatted2;
  };

  return (
    <S.Wrapper>
      {isMobile ? (
        <>
          <T.H30Caps color="midnight" style={{ margin: "0" }}>
            {t("buyTempoTc", language).toUpperCase()}
          </T.H30Caps>
          <T.Body18B mt={20} mb={10} color="midnight">
            {t("howManyTCs", language)}?
          </T.Body18B>
        </>
      ) : (
        <>
          <T.H40Caps color="midnight" style={{ margin: "0" }}>
            {t("buyTempoTc", language).toUpperCase()}
          </T.H40Caps>
          <T.Body20B mt={40} mb={10} color="midnight">
            {t("howManyTCs", language)}?
          </T.Body20B>
        </>
      )}
      {isMobile ? (
        <T.Body16R>{t("selectPackage", language)}</T.Body16R>
      ) : (
        <T.Body18R>{t("selectPackage", language)}</T.Body18R>
      )}
      <S.CardsWrapper>
        {packages.map((_package) => (
          <S.StyledButton
            key={_package.id}
            onClick={() => _setSelectedPackage(_package)}
            notSelected={notSelected(_package.id)}
          >
            <CreditCard
              type="digital"
              credits={_package.credits}
              width={isMobile ? "300px" : "340px"}
              hideID
              tcText={t("timeCreditsNumInFront", language)}
              price={getTotalPrice(_package.credits)}
            />
          </S.StyledButton>
        ))}
      </S.CardsWrapper>
      <Row>
        <Col w={[4, 6, 4]}>
          {isMobile ? (
            <T.Body18B mt={20} mb={10} color="midnight">
              {t("wantDifferentAmount", language)}?
            </T.Body18B>
          ) : (
            <T.Body20B mt={40} mb={10} color="midnight">
              {t("wantDifferentAmount", language)}?
            </T.Body20B>
          )}
          <div style={{ maxWidth: 340 }}>
            <InputField
              type="number"
              placeholder={t("pricePlaceholder", language, [
                activePrice.minimumTc,
              ])}
              setValue={({ target: { value } }) => {
                setSelectedPackage({});
                setCustomCredits(value);
              }}
              value={customCredits || ""}
              error={customCreditsError}
              size="large"
              inputProps={{
                min: 1,
              }}
              onBlur={(e) => {
                if (!e.target.value) return;
                setCustomCredits((tc) => Number(tc).toFixed(0));
              }}
            />
          </div>
        </Col>
        {Boolean(customCredits) && !customCreditsError && (
          <Col w={[4, 6, 4]} ml="50">
            <T.H22 color="primary" mt={40}>
              Total amount
            </T.H22>
            <T.H60 color="primary" mt={10}>
              {getTotalPrice(customCredits)}
            </T.H60>
            <T.Body20B color="primary" mt={10}>
              incl.{activePrice.vat}% VAT
            </T.Body20B>
          </Col>
        )}
      </Row>
      {t(error.message, language)}
      <Row>
        <Col w={[4, 6, 4]}>
          <div style={{ maxWidth: 340 }}>
            <Regular
              primary
              bgColor="blue"
              disabled={
                (!selectedPackage?.id && !customCredits) ||
                loading ||
                !!customCreditsError
              }
              onClick={handleSubmit}
              loading={loading}
              p="30px 5px"
              mt={39}
              mb={3}
            >
              {t("generateInvoice", language)}
            </Regular>
          </div>
        </Col>
      </Row>
      {isMobile ? (
        <T.Body16B color="primary" mt={30} ml={5}>
          {t("discussThisFurther", language)}
        </T.Body16B>
      ) : (
        <T.Body20B color="primary" mt={60} ml={5}>
          {t("discussThisFurther", language)}
        </T.Body20B>
      )}
    </S.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  loading: state.requestTimeCredits.loading,
  error: state.requestTimeCredits.error,
  language: state.auth.language,
  userId: state.auth.id,
  activePrice: state.paidPackages.activePrice,
  packages: state.paidPackages.packages,
});

const mapActionToProps = {
  createPaidPackageOrder: actions.createPaidPackageOrder,
  getPaidPackages: paidPackagesActions.getPaidPackages,
  getTcPrice: paidPackagesActions.getTcPrice,
};

export default connect(mapStateToProps, mapActionToProps)(PaidRequest);
