import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";

import * as S from "./style";
import * as T from "../../../../components/Typograpy";

import Button from "../../../../components/Button";
import Image from "../../../../components/Image";
import { Row, Col } from "../../../../components/Grid";
import WarningMessage from "../../../../components/WarningMessage";

const AccessModal = ({
  changeType,
  modalOpen,
  handleCloseModal,
  updateUser,
  userToUpdate,
  errorMsg,
}) => {
  if (changeType === "createSuccess" || changeType === "updateSuccess")
    return (
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="Success Message"
        aria-describedby="Request completed"
        BackdropComponent={Backdrop}
        BackdropProps={{
          style: { backgroundColor: "rgba(255,255,255,0.9)" },
        }}
      >
        <S.Container>
          <Row style={{ padding: 0 }}>
            <div style={{ padding: "0 30px 0 0", width: "380px" }}>
              <Row style={{ padding: 0 }}>
                <Col w={[4, 12, 12]} style={{ padding: 0 }}>
                  <T.H20 color="midnight" mb={20}>
                    User successfully{" "}
                    {changeType === "createSuccess" ? "created" : "updated"}
                  </T.H20>
                  {changeType === "createSuccess" ? (
                    <T.Body16R color="gray3">
                      <S.Emphasis>{userToUpdate.email}</S.Emphasis> has received
                      an email with a sign up link to set their password. Please
                      ask them to check their inbox.
                    </T.Body16R>
                  ) : (
                    <T.Body14R color="gray3">
                      The access rights for{" "}
                      <S.Emphasis>{userToUpdate.email}</S.Emphasis> have been
                      updated. Please let them know.
                    </T.Body14R>
                  )}
                </Col>
              </Row>
              <Row style={{ padding: 0 }}>
                <Col w={[4, 8, 8]} style={{ padding: 0 }}>
                  <Button
                    primary
                    onClick={handleCloseModal}
                    style={{ margin: "30px 0 0 0" }}
                  >
                    Done
                  </Button>
                </Col>
              </Row>
            </div>
            <div style={{ padding: 0 }}>
              <Image
                image="success"
                alt="success"
                width="200px"
                height="200px"
              />
            </div>
          </Row>
        </S.Container>
      </Modal>
    );

  if (changeType === "remove")
    return (
      <WarningMessage
        open={modalOpen}
        setOpen={handleCloseModal}
        handleClick={updateUser}
        title="Are you sure you want to remove this staff member?"
        text="will be deleted as staff member."
        nameOrEmail={userToUpdate.email}
      />
    );

  if (changeType === "updateRole")
    return (
      <WarningMessage
        open={modalOpen}
        setOpen={handleCloseModal}
        handleClick={updateUser}
        title="Are you sure you want to change their role?"
        text="This will change their access levels and affect what they can and cannot do on the platform."
      />
    );

  if (changeType === "error") {
    return (
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="Error Message"
        aria-describedby="Request failed"
        BackdropComponent={Backdrop}
        BackdropProps={{
          style: { backgroundColor: "rgba(255,255,255,0.9)" },
        }}
      >
        <S.Container>
          <S.Body>
            <Row>
              <Col w={[4, 12, 12]}>
                <T.H24 color="midnight" mb={20}>
                  Error
                </T.H24>
              </Col>
            </Row>
            <Row>
              <Col w={[4, 12, 12]}>
                <T.Body14R color="gray3">{errorMsg}.</T.Body14R>
              </Col>
            </Row>
            <Row>
              <Col w={[4, 6, 6]}>
                <Button
                  primary
                  onClick={handleCloseModal}
                  style={{ margin: "20px 0 0 0" }}
                >
                  Done
                </Button>
              </Col>
            </Row>
          </S.Body>
          {/* <Image image="success" alt="success" width="200px" height="205px" /> */}
        </S.Container>
      </Modal>
    );
  }

  return null;
};

export default AccessModal;
