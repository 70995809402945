import styled from "@material-ui/styles/styled";
import { Link } from "react-router-dom";
import * as T from "../../../components/Typograpy";

export const ExtLink = styled("a")(({ theme: _theme }) => ({
  color: _theme.colors.blue,
  fontWeight: "bold",
}));
export const Wrapper = styled("div")(() => ({
  maxWidth: "650px",
}));

export const CUstomInput = styled("div")(() => ({
  height: "50px",
  border: "1px solid rgba(224, 224, 224, 0.9)",
  padding: 10,
  borderRadius: 10,
  background: "white",
  position: "relative",
  "& > svg": {
    position: "absolute",
    top: "14px",
    right: 0,
  },
}));

export const DownloadWrapper = styled(Link)(({ theme }) => ({
  marginTop: "auto",
  marginBottom: theme.spacing(1),
  "& > p > svg": {
    transform: "translate(10px, 5px)",
  },
}));

export const Error = styled(T.Body16R)(({ theme }) => ({
  width: "100%",
  color: theme.colors.pink,
  fontSize: "0.875rem",
  textAlign: "left",
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(0),
}));

// 88888888888888888888888888

export const SuccessWrapper = styled("section")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: theme.spacing(3),
  maxWidth: "420px",
  margin: "0 auto",
  paddingBottom: theme.spacing(7),

  [theme.breakpoints.tablet]: {
    paddingTop: 0,
  },
}));

export const H40Caps = styled(T.H40Caps)(({ theme }) => ({
  color: theme.colors.midnight,
}));

export const StyledHeader = styled("header")(() => ({
  width: "100%",
}));

export const Body18R = styled(T.Body16R)(({ theme }) => ({
  color: theme.colors.gray2,
  marginTop: "2rem",
  fontSize: "16px",
  display: "inline-block",
}));
