import React from "react";
import { useTheme } from "@material-ui/core/styles";
import * as S from "./style";
import * as T from "../Typograpy";
import Icon from "../Icon";

const OpportunityLink = ({ path, venue, address }) => {
  const theme = useTheme();
  return (
    <S.OpportunityLinkWrapper>
      <Icon
        icon="at"
        width="30px"
        height="30px"
        color={theme.colors.pink}
        margin="0 10px 0 0"
      />
      <S.Details>
        <T.H16 color={theme.colors.gray2} className="venue">
          {venue}
        </T.H16>
        <T.Body12R color={theme.colors.gray3}>{address}</T.Body12R>
        <S.FindOutMore to={path} type="text">
          <T.LinkS14 color={theme.colors.blue}>Find out more</T.LinkS14>
        </S.FindOutMore>
      </S.Details>
      <S.FindOutMore to={path} type="icon">
        <Icon
          icon="arrowLink"
          width="24px"
          height="24px"
          color={theme.colors.blue}
          margin="0 0 0 10px"
        />
      </S.FindOutMore>
    </S.OpportunityLinkWrapper>
  );
};
export default OpportunityLink;
