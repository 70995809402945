import React from "react";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";
import Table from "../../../../components/Table";
import { joinDateCol } from "../../../../components/Table/config/reusableColumns";

import RemoveRejoin from "../../../../components/RemoveRejoin";
import PopQuestion from "../../../../components/PopQuestion";
import Button from "../../../../components/Button";
import { Row, Col } from "../../../../components/Grid";

import * as T from "../../../../components/Typograpy";
import { TEMPO } from "../../../../constants/navRoutes";

import { updateGroupMemberStatus } from "../../../EarnGroupRoutes/Members/actions";

const Members = ({
  updateGroupMemberStatusAction,
  members,
  loadingMemberId,
  earnGroupId,
  earnGroupName,
  inviteToken,
  loading,
}) => {
  const history = useHistory();

  const handleRemoveRejoin = (data) => {
    const { id: memberId, status: oldStatus, groupId } = data;
    const status = oldStatus === "activated" ? "diactivated" : "activated";
    updateGroupMemberStatusAction({ memberId, groupId, status });
  };

  const memberColumns = [
    { title: "Name", field: "member" },
    joinDateCol(),
    { title: "Time Credits Earned", field: "creditsEarned" },
    {
      title: (
        <div style={{ position: "relative" }}>
          % Time Credits Used
          <PopQuestion>
            <T.Body16R color="gray3">
              the % of Time Credits that this volunteer has earned with this
              Earn Group that they have used
            </T.Body16R>
          </PopQuestion>
        </div>
      ),
      field: "creditsSpent",
      render: (rowData) => `${rowData.creditsSpent}`,
    },
    {
      title: "",
      field: "removeRejoin",
      disableClick: true,
      render: (rowData) => (
        <RemoveRejoin
          type={rowData.status === "activated" ? "Remove" : "Re-join"}
          loading={rowData.id === loadingMemberId}
          handleClick={() => handleRemoveRejoin(rowData)}
          showText
        />
      ),
    },
  ];

  const clickRow = (event, rowData) =>
    history.push(TEMPO.MEMBERS_SINGLE.replace(":id", rowData.id));

  return (
    <>
      <Row jc="space-between" mb={5}>
        <Col w={[4, 3, 3]}>
          <T.H30 mb={20} color="midnight">
            VOLUNTEERS
          </T.H30>
        </Col>
        <Col w={[4, 3, 3]}>
          <Button
            primary
            onClick={() =>
              history.push({
                pathname: TEMPO.ADD_MEMBERS,
                state: {
                  earnGroupUserId: earnGroupId,
                  earnGroupName,
                  inviteToken,
                },
              })
            }
          >
            Add Volunteers
          </Button>
        </Col>
      </Row>

      <Table
        color="blue"
        data={members}
        isLoading={loading}
        columns={memberColumns}
        hideSearch
        onRowClick={clickRow}
        limit={5}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  members: state.earnGroupMembers.allMembers,
  loadingMemberId: state.earnGroupMembers.loadingMemberId,
});

const mapActionsToProps = {
  updateGroupMemberStatusAction: updateGroupMemberStatus,
};

export default connect(mapStateToProps, mapActionsToProps)(Members);
