import React from "react";
import * as S from "../style";
import * as T from "../../Typograpy";

import t from "../../../utils/helpers/translator";

const Pagination = ({ page, count, onChangePage, color, limit, language }) => {
  const actualPage = page + 1;
  const totalPages = Math.ceil(count / limit);
  const records = () => {
    if (actualPage < totalPages) {
      return `${page * limit + 1}-${page * limit + limit} ${t(
        "of",
        language,
      )} ${count} ${t("records", language)}`;
    }
    return `${page * limit + 1}-${count} ${t("of", language)} ${count} ${t(
      "records",
      language,
    )}`;
  };

  if (count > 0)
    return (
      <>
        <S.PaginationWrapper>
          {/* previous buttons */}

          <S.StyledPageBtn
            type="button"
            disabled={actualPage <= 1}
            onClick={(event) => onChangePage(event, 0)}
          >
            {`<<`}
          </S.StyledPageBtn>
          <S.StyledPageBtn
            type="button"
            disabled={actualPage <= 1}
            onClick={(event) => actualPage > 1 && onChangePage(event, page - 1)}
          >{`<`}</S.StyledPageBtn>

          <S.StyledPageBtn selected color={color}>
            {actualPage}
          </S.StyledPageBtn>

          {actualPage < totalPages && (
            <S.StyledPageBtn
              type="button"
              onClick={(event) => onChangePage(event, page + 1)}
            >
              {actualPage + 1}
            </S.StyledPageBtn>
          )}

          {actualPage + 1 < totalPages && (
            <S.StyledPageBtn
              type="button"
              onClick={(event) => onChangePage(event, page + 2)}
            >
              {actualPage + 2}
            </S.StyledPageBtn>
          )}

          {/* next buttons */}

          <S.StyledPageBtn
            type="button"
            onClick={(event) => onChangePage(event, page + 1)}
            disabled={actualPage === totalPages}
          >
            {`>`}
          </S.StyledPageBtn>
          <S.StyledPageBtn
            type="button"
            onClick={(event) => onChangePage(event, totalPages)}
            disabled={actualPage === totalPages}
          >
            {`>>`}
          </S.StyledPageBtn>
        </S.PaginationWrapper>
        <T.H14CapsS style={{ marginLeft: "20px" }}>{records()}</T.H14CapsS>
      </>
    );
  return <></>;
};

export default Pagination;
