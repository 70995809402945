import {
  GET_SPEND_VENUE_INSIGHTS_LOADING_START,
  GET_SPEND_VENUE_INSIGHTS_LOADING_END,
  GET_SPEND_VENUE_INSIGHTS_SUCCESS,
  GET_SPEND_VENUE_INSIGHTS_FAIL,
  GET_SPEND_VENUE_TRANSACTIONS_LOADING_START,
  GET_SPEND_VENUE_TRANSACTIONS_LOADING_END,
  GET_SPEND_VENUE_TRANSACTIONS_SUCCESS,
  GET_SPEND_VENUE_TRANSACTIONS_FAIL,
  GET_SPEND_VENUE_BY_ID_BASIC_LOADING_START,
  GET_SPEND_VENUE_BY_ID_BASIC_LOADING_END,
  GET_SPEND_VENUE_BY_ID_BASIC_SUCCESS,
  GET_SPEND_VENUE_BY_ID_BASIC_FAIL,
} from "../../../redux/types";

import { insights, transactions, spendVenues } from "../../../api-calls";

export const getInsights = (spendVenueId) => async (dispatch) => {
  dispatch({ type: GET_SPEND_VENUE_INSIGHTS_LOADING_START });
  try {
    const { data, error } = await insights.getInsights({
      spendVenueId,
      category: "singleSpendVenue",
    });
    if (data) {
      dispatch({
        type: GET_SPEND_VENUE_INSIGHTS_SUCCESS,
        payload: data,
      });
    } else if (error) {
      dispatch({
        type: GET_SPEND_VENUE_INSIGHTS_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_SPEND_VENUE_INSIGHTS_FAIL,
      payload: error,
    });
  }
  dispatch({ type: GET_SPEND_VENUE_INSIGHTS_LOADING_END });
};

export const getTransactions = (spendVenueId) => async (dispatch) => {
  dispatch({ type: GET_SPEND_VENUE_TRANSACTIONS_LOADING_START });
  const { data, error } = await transactions.getUserTransactions({
    spendVenueId,
    category: "singleSpendVenue",
  });
  if (data) {
    dispatch({
      type: GET_SPEND_VENUE_TRANSACTIONS_SUCCESS,
      payload: data,
    });
  } else if (error) {
    dispatch({
      type: GET_SPEND_VENUE_TRANSACTIONS_FAIL,
      payload: error,
    });
  }

  dispatch({ type: GET_SPEND_VENUE_TRANSACTIONS_LOADING_END });
};

export const getSpendVenueBasicById = (spendVenueId) => async (dispatch) => {
  dispatch({ type: GET_SPEND_VENUE_BY_ID_BASIC_LOADING_START });
  const { data, error } = await spendVenues.getSpendVenueById({
    spendVenueId,
    isBasic: true,
  });

  if (data) {
    dispatch({
      type: GET_SPEND_VENUE_BY_ID_BASIC_SUCCESS,
      payload: data,
    });
  } else if (error) {
    dispatch({
      type: GET_SPEND_VENUE_BY_ID_BASIC_FAIL,
      payload: error,
    });
  }

  dispatch({ type: GET_SPEND_VENUE_BY_ID_BASIC_LOADING_END });
};
