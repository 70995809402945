import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyle = makeStyles((theme) => ({
  Wrapper: {
    paddingBottom: ({ footer }) => (footer ? "270px" : theme.spacing(11)),
    minHeight: "100vh",
    position: "relative",
    [theme.breakpoints.tablet]: {
      padding: "60px 8% 230px 8%",
      paddingBottom: "400px",
    },
  },

  LogoHeader: {
    paddingTop: ({ hideLangToggle }) => (hideLangToggle ? "40px" : "75px"),
    paddingLeft: "20px",
    maxWidth: "400px",
    margin: "0 auto",

    [theme.breakpoints.mobileM]: {
      paddingLeft: "30px",
    },

    [theme.breakpoints.tablet]: {
      paddingTop: "30px",
    },

    [theme.breakpoints.laptop]: {
      paddingLeft: "40px",
    },
  },

  ContentWrapper: {
    padding: "0px 10px",

    [theme.breakpoints.mobileM]: {
      padding: "0px 20px",
    },

    [theme.breakpoints.mobileL]: {
      padding: "0 30px",
    },
  },

  //   these styles can be moved to the relevant component once we build the footer
  Footer: {
    width: "100%",
    position: "fixed",
    bottom: "0",
    left: 0,
    height: "255px",
    backgroundColor: "lightGray",
    [theme.breakpoints.tablet]: {
      height: "180px",
    },
  },
}));

export default useStyle;
