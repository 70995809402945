import { makeStyles, styled } from "@material-ui/core/styles";

export const Container = styled("div")(({ theme }) => ({
  width: "100%",
  "& > * + *": {
    marginTop: theme.spacing(2),
  },
  "& div:first-child div:first-child": {
    display: "flex",
  },
}));

const useStyles = makeStyles((theme) => ({
  notification: {
    width: "100%",
    "& div:last-child": {
      paddingLeft: 0,
    },
    "& > div": {
      flexWrap: "nowrap",
      backgroundColor: theme.colors.midnight,
      width: "100%",
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "flex-start",
      paddingRight: 20,
      minHeight: 80,
      borderRadius: 0,
      boxShadow: "none",
    },
    [theme.breakpoints.tablet]: {
      "& > div": {
        paddingRight: 80,
        display: "flex",
        flexDirection: "row-reverse",
      },
    },
  },
  button: {
    padding: 0,
    height: "24px",
    width: "24px",
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "10px",
    marginTop: "4px",
  },
}));

export default useStyles;
