export default (theme) => ({
  "@keyframes rotate": {
    from: { transform: "rotate(0deg)" },
    to: { transform: "rotate(360deg) " },
  },
  spinner: {
    animation: "$rotate 1s linear infinite",
    marginRight: 10,
    background: ({ bg, primary, secondary, textColor, outline }) => {
      switch (true) {
        case textColor:
          return theme.colors[textColor] || theme.colors.white;
        case (primary || secondary || bg) && bg === "gray":
          return theme.colors.black;
        case primary || secondary || bg:
          return theme.colors.white;
        case outline:
          return theme.colors.blue;
        default:
          return theme.colors.white;
      }
    },
    borderRadius: "50%",
    height: "20px",
    width: "20px",
    position: "relative",
    "&:before": {
      content: "''",
      position: "absolute",
      borderRadius: "50%",
      background:
        "linear-gradient(0deg,   hsla(0, 0%, 100%, 1  ) 50%, hsla(0, 0%, 100%, 0.9) 100%)   0%   0%,linear-gradient(90deg,  hsla(0, 0%, 100%, 0.9)  0%, hsla(0, 0%, 100%, 0.6) 100%) 100%   0%,linear-gradient(180deg, hsla(0, 0%, 100%, 0.6)  0%, hsla(0, 0%, 100%, 0.3) 100%) 100% 100%,linear-gradient(360deg, hsla(0, 0%, 100%, 0.3)  0%, hsla(0, 0%, 100%, 0  ) 100%)   0% 100%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "50% 50%",
      top: "-1px",
      bottom: "-1px",
      left: "-1px",
      right: "-1px",
    },
    "&:after": {
      content: "''",
      position: "absolute",
      backgroundColor: ({ bg, primary, outline, secondary }) => {
        switch (true) {
          case primary && bg:
            return theme.colors[bg];
          case primary:
            return theme.colors.blue;
          case secondary && bg:
            return theme.colors[bg];
          case secondary:
            return theme.colors.pink;
          case outline && bg:
            return theme.colors[bg];
          case outline:
            return theme.colors.white;
          default:
            return theme.colors.blue;
        }
      },
      borderRadius: "50%",
      top: "3%",
      bottom: "3%",
      left: "3%",
      right: "3%",
    },
  },
});
