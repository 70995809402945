import React from "react";
import { useTheme } from "@material-ui/core/styles";

import { Wrapper } from "./style";
import * as T from "../Typograpy";

import t from "../../utils/helpers/translator";

const decideColor = (theme, name, status) => {
  const tidyText = (str) => str && str.toLowerCase();
  const { colors } = theme;

  if (
    [
      "spend",
      "used",
      "rejected",
      "returning",
      "given out",
      "defnyddio",
      "dychwelyd",
      "givenout",
      "rhoddwyd",
    ].includes(tidyText(name) || tidyText(status))
  ) {
    return colors.pink;
  }
  if (
    ["gift", "gifted", "partially approved", "rhoddi", "cancelled"].includes(
      tidyText(name),
    ) &&
    tidyText(status) !== "pending"
  ) {
    return colors.purple;
  }
  if (
    ["issue", "issued", "exchange", "exchanged"].includes(
      tidyText(name) || tidyText(status),
    )
  ) {
    return colors.gray2;
  }
  if (["pending", "paymentdue"].includes(tidyText(name) || tidyText(status))) {
    return colors.cyan;
  }

  if (
    ["purchased", "welsh - purchased"].includes(
      tidyText(name) || tidyText(status),
    )
  ) {
    return colors.midnight;
  }
  return colors.blue;
};

const decideSize = (size) => {
  switch (size) {
    case "s":
      return "4rem";
    case "m":
      return "5.625rem";
    case "l":
      return "10rem";
    default:
      return "auto";
  }
};

const Tag = ({
  tagName,
  size,
  customColor,
  customStyle,
  lineHeight,
  m,
  justify,
  large,
  status,
  lang,
}) => {
  const theme = useTheme();

  return (
    <Wrapper
      size={size}
      styling={customStyle}
      width={decideSize(size)}
      justify={justify}
    >
      {large ? (
        <T.Body14B
          color="white"
          style={{
            background: customColor || decideColor(theme, tagName, status),
            padding: "5px 25px",
            width: decideSize(size),
            textAlign: "center",
            lineHeight,
            margin: m || "0 auto",
            textTransform: "uppercase",
          }}
        >
          {lang ? t(tagName, lang) : tagName}
        </T.Body14B>
      ) : (
        <T.Body12B
          color="white"
          style={{
            background: customColor || decideColor(theme, tagName, status),
            padding: "0px 7px",
            width: decideSize(size),
            textAlign: "center",
            lineHeight,
            margin: m || "0 auto",
            textTransform: "uppercase",
          }}
        >
          {lang ? t(tagName, lang) : tagName}
        </T.Body12B>
      )}
    </Wrapper>
  );
};

export default Tag;
