import React from "react";
import Stepper from "@material-ui/core/Stepper";
import StepButton from "@material-ui/core/StepButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import * as T from "../Typograpy";

import * as S from "./style";

import Icon from "../Icon";

export default function HorizontalNonLinearAlternativeLabelStepper({
  componentArr,
  steps,
  stepProps,
  activeStep,
  stepsStatuses,
  handleClickOnStep,
  handleNext,
  loading,
  edit,
}) {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const CurrentComponent = componentArr[activeStep];
  return (
    <S.Wrapper edit={edit}>
      <Stepper
        nonLinear
        alternativeLabel={isTablet}
        connector={
          <Icon
            icon="chevronRight"
            width="32px"
            color="blue"
            custom={{ margin: isTablet ? "0 18px" : "0 24px" }}
          />
        }
        activeStep={activeStep}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 60,
        }}
      >
        {steps.map((label, index) => {
          const buttonProps = {};
          return (
            <S.Step key={label} active isActive={activeStep === index}>
              {stepsStatuses[index].completed || stepsStatuses[index].error ? (
                <StepButton
                  onClick={() => handleClickOnStep(index, activeStep)}
                  completed
                  active
                  {...buttonProps}
                  icon={
                    stepsStatuses[index].error ? (
                      <Icon icon="notComplete" width="24px" color="pink" />
                    ) : (
                      <Icon icon="completed" />
                    )
                  }
                >
                  <T.Body14R
                    color={stepsStatuses[index].error ? "pink" : "gray3"}
                    style={{ textAlign: "left" }}
                  >
                    {label}
                  </T.Body14R>
                </StepButton>
              ) : (
                <S.StepButton
                  onClick={() => handleClickOnStep(index, activeStep)}
                  completed={false}
                  {...buttonProps}
                >
                  <T.Body14R color="gray3" style={{ textAlign: "left" }}>
                    {label}
                  </T.Body14R>
                </S.StepButton>
              )}
            </S.Step>
          );
        })}
      </Stepper>

      <CurrentComponent
        activeStep={activeStep}
        handleNext={handleNext}
        loading={loading}
        {...stepProps}
      />
    </S.Wrapper>
  );
}
