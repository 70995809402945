import { styled } from "@material-ui/core/styles";

export const Wrapper = styled("section")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  maxWidth: "500px",
  width: "100%",
  margin: "0 auto",
  paddingBottom: "100px",
  [theme.breakpoints.mobileL]: {
    maxWidth: "315px",
  },
}));

export const Body = styled("form")(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  height: "50%",
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: 0,
}));

export const ImageWrapper = styled("div")(() => ({
  maxWidth: "350px",
}));

export const ButtonWrapper = styled("div")(() => ({
  width: "100%",
  marginTop: "30px",
}));
