import styled from "@material-ui/core/styles/styled";

export const Container = styled("div")(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
}));

export const Back = styled("div")(() => ({
  display: "flex",
  paddingBottom: 30,
}));

export const TagContainer = styled("div")({
  display: "flex",
  marginTop: 15,
  justifyContent: "flex-start",
  "& div": { margin: 0, justifyContent: "flex-start" },
  "& p": {
    margin: "0 !important",
    width: "90px !important",
    justifyContent: "flex-start",
  },
});

export const CardContainer = styled("div")({
  display: "flex",
  marginTop: 50,
});
export const Card = styled("div")(({ mb = 0 }) => ({
  width: 300,
  display: "flex",
  flexDirection: "column",
  marginRight: 10,
  marginBottom: mb || 50,
  "& h5": {
    margin: "0 0 5px 0",
  },
  "& p": {
    margin: "0 0 20px 0 !important",
  },
}));

export const Div = styled("div")({
  marginTop: 50,
});

export const ImageWrapper = styled("div")({
  minWidth: 220,
  height: 210,
});
