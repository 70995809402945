import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";

import validate from "../../../../validation/schemas/addAsNewGroup";
import { getEarnGroupByUserId, resetState } from "../SingleEarnGroup/actions";

import * as S from "./style";
import { TEMPO } from "../../../../constants/navRoutes";

import {
  getTkGroupConflictDataByUserId,
  mergeGroups,
  approveAsNewGroup,
} from "./actions";

import * as T from "../../../../components/Typograpy";
import { InputField } from "../../../../components/Inputs";

import Button from "../../../../components/Button";
import ApprovalModal from "./ApprovalModal";
import { Row, Col } from "../../../../components/Grid";
import WarningMessage from "../../../../components/WarningMessage";

import Table from "../../../../components/Table";
import Image from "../../../../components/Image";

const TkSingleGroupRequest = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const {
    firstName,
    lastName,
    name,
    email,
    phoneNumber,
    loading,
    tkId,
    programmeName,
    programmeId,
    getEarnGroupByUserIdAction,
    resetStateAction,
    getTkGroupConflictDataByUserIdAction,
    conflictGroupsData,
    mergeGroupLoading,
    mergeGroupsAction,
    mergeGroupError,
    approveAsNewGroupAction,
    newGroupUserId,
  } = props;
  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false);
  const [isAddAsNewGroupModalOpen, setIsAddAsNewGroupModalOpen] =
    useState(false);
  const [isMergeModalOpen, setIsMergeModalOpen] = useState(false);
  const [newGroupEmail, setNewGroupEmail] = useState("");
  const [organisationName, setOrganisationName] = useState("");
  const [submitAttempt, setSubmitAttempt] = useState(false);
  const [rowDataToBeMerged, setRowDataToBeMerged] = useState("");
  const [errors, setErrors] = useState({
    newGroupEmail: "",
    organisationName: "",
  });
  const partialValidate = (targetName, value) => {
    try {
      if (submitAttempt) {
        validate(
          {
            [targetName]: value,
          },
          true,
        );
      }
    } catch (err) {
      if (err.name === "ValidationError") {
        setErrors((_errors) => ({
          ..._errors,
          [targetName]: err.inner[targetName],
        }));
      }
    }
  };

  const onChange = (e) => {
    partialValidate(e.target.name, e.target.value);
    switch (e.target.name) {
      case "newGroupEmail":
        setNewGroupEmail(e.target.value);
        break;
      case "organisationName":
        setOrganisationName(e.target.value);
        break;
      default:
        break;
    }
  };
  const requestMerge = (rowData) => {
    const { userId } = rowData;
    if (programmeId !== rowData.programmeId) {
      history.push(
        TEMPO.TK_PROGRAMME_CONFLICT.replace(":groupUserId", userId).replace(
          ":approvalRequestId",
          id,
        ),
      );
    } else {
      mergeGroupsAction({ id, userId });
      setIsApprovalModalOpen(true);
    }
  };
  const conflictGroupsTableColumns = [
    {
      title: "EARN GROUP",
      field: "earnGroup",
      disableClick: true,
      render: (rowData) => (
        <Link to={TEMPO.EARN_GROUP_SINGLE.replace(":id", rowData?.userId)}>
          <T.LinkB14 color="blue">{rowData?.name?.toUpperCase()}</T.LinkB14>
        </Link>
      ),
    },
    {
      title: "PROGRAMME",
      field: "programmeName",
      disableClick: true,
      render: (rowData) =>
        rowData.programmeId ? (
          <Link to={TEMPO.PROGRAMME_SINGLE.replace(":id", rowData.programmeId)}>
            <T.LinkB14 color="blue">{rowData.programmeName}</T.LinkB14>
          </Link>
        ) : (
          <T.Body14R>N/A</T.Body14R>
        ),
    },
    { title: "LEAD EMAIL", field: "email", disableClick: true },
    {
      title: "REASON FOR CONFLICT",
      field: "conflictReason",
      disableClick: true,
      render: (rowData) =>
        `${rowData.name.toUpperCase() !== name.toUpperCase() ? "Name" : ""}${
          rowData.programmeId !== programmeId ? " Programme" : ""
        }${rowData.email.toUpperCase() !== email.toUpperCase() ? " Email" : ""}`
          ?.trim()
          ?.split(" ")
          ?.join(" & "),
    },
    {
      title: "",
      field: "merge",
      disableClick: true,
      render: (rowData) => (
        <Col w={[6, 6, 6]}>
          {rowData.tkId ? (
            "Already Connected"
          ) : (
            <Button
              primary
              onClick={() => {
                setRowDataToBeMerged(rowData);
                setIsMergeModalOpen(true);

                // requestMerge(rowData);
              }}
              loading={mergeGroupLoading}
            >
              Merge
            </Button>
          )}
        </Col>
      ),
    },
  ];
  useEffect(() => {
    getEarnGroupByUserIdAction(id);
    getTkGroupConflictDataByUserIdAction(id);
    return resetStateAction;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Wrapper>
      <WarningMessage
        open={isAddAsNewGroupModalOpen}
        setOpen={setIsAddAsNewGroupModalOpen}
        handleClick={() => {
          setIsApprovalModalOpen(true);
          approveAsNewGroupAction({
            email: newGroupEmail,
            name: organisationName,
            id,
            status: "approved",
          });
        }}
        title="Are you sure you want to save this as a new group?"
      />
      <WarningMessage
        open={isMergeModalOpen}
        setOpen={setIsMergeModalOpen}
        handleClick={() => requestMerge(rowDataToBeMerged)}
        title="Are you sure you want to merge these two groups?"
      />
      {/* Remove Modal: should pass confirm function */}
      <ApprovalModal
        modalOpen={isApprovalModalOpen}
        confirmFun={() => {
          history.push(
            TEMPO.EARN_GROUP_SINGLE.replace(
              ":id",
              newGroupUserId || rowDataToBeMerged?.userId,
            ),
          );
        }}
        onCancel={() => {
          history.push(TEMPO.TK_GROUP_REQUESTS);
        }}
        handleCloseModal={() => ({})}
        groupName={name || "group"}
      />

      <S.Head>
        <S.HeadContent>
          <T.H40Caps color="primary" style={{ margin: 0 }}>
            {name || "N/A"}
          </T.H40Caps>
        </S.HeadContent>
        <S.ImageWrapper>
          <Image image="painting" alt="painting" height={210} width={220} />
        </S.ImageWrapper>
      </S.Head>

      {/* new stats section */}
      <Row mt={-40}>
        <Col w={[4, 7, 7]}>
          <S.StyledCard width="100%">
            <Row mb={4}>
              <Col w={[4, 12, 12]}>
                <T.H24 color="midnight">Account Details</T.H24>
              </Col>
            </Row>
            <Row mb={3}>
              <Col w={[2, 4, 6]}>
                <T.H18 color="gray2">Name</T.H18>
              </Col>
              <Col w={[2, 8, 6]}>
                <T.Body16R style={{ textTransform: "capitalize" }}>
                  {firstName || lastName ? `${firstName} ${lastName}` : "N/A"}
                </T.Body16R>
              </Col>
            </Row>
            <Row mb={3}>
              <Col w={[2, 4, 6]}>
                <T.H18 color="gray2">Email</T.H18>
              </Col>
              <Col w={[2, 8, 6]}>
                <a
                  href={`mailto:${email}`}
                  rel="noopener noreferrer"
                  target="_blank"
                  alt="email"
                  style={{ textDecoration: "none" }}
                >
                  <T.Body16R color="blue">{email || "N/A"}</T.Body16R>
                </a>
              </Col>
            </Row>
            <Row mb={3}>
              <Col w={[2, 4, 6]}>
                <T.H18 color="gray2">Phone</T.H18>
              </Col>
              <Col w={[2, 8, 4]}>
                <T.Body16R>{phoneNumber || "N/A"}</T.Body16R>
              </Col>
            </Row>
            <Row mb={3}>
              <Col w={[2, 4, 6]}>
                <T.H18 color="gray2" style={{ overflowWrap: "anywhere" }}>
                  TK ID
                </T.H18>
              </Col>
              <Col w={[2, 4, 4]}>
                <T.Body16R>{tkId || "N/A"}</T.Body16R>
              </Col>
            </Row>
            <Row>
              <Col w={[2, 4, 6]}>
                <T.H18 color="gray2" style={{ overflowWrap: "anywhere" }}>
                  Programme
                </T.H18>
              </Col>
              <Col w={[2, 4, 4]}>
                {programmeId ? (
                  <Link to={TEMPO.PROGRAMME_SINGLE.replace(":id", programmeId)}>
                    <T.LinkB14 color="blue">{programmeName || "N/A"}</T.LinkB14>
                  </Link>
                ) : (
                  <T.Body16R>{programmeName || "N/A"}</T.Body16R>
                )}
              </Col>
            </Row>
          </S.StyledCard>
        </Col>
      </Row>
      <Row mt={9}>
        <Col w={[8, 8, 8]}>
          <T.Body16R>
            Below are the potential duplicate groups we have detected based on
            name or email address.
          </T.Body16R>
          <br />
          <T.Body16R>
            Either merge this group with one of the groups listed or Approve as
            New Group (please remember that if you approve as a new group you
            will need to update the name and/or email to it is no longer
            conflicting).
          </T.Body16R>
        </Col>
      </Row>
      <Row mt={7} />
      {!loading ? (
        <Table
          color="blue"
          data={conflictGroupsData?.conflicts}
          columns={conflictGroupsTableColumns}
          onRowClick={() => {}}
          isLoading={conflictGroupsData?.loading}
          hideSearch
          noPagination
        />
      ) : null}
      <Row mt={10} />

      <T.H24Caps color="midnight" mb={20}>
        APPROVE AS A NEW GROUP
      </T.H24Caps>
      <Row mb={5} mt={6}>
        <Col w={[4, 6, 4]} mb={4}>
          <InputField
            name="organisationName"
            placeholder={name}
            label="Earn Group Name"
            value={organisationName}
            setValue={onChange}
            error={errors.organisationName}
          />
        </Col>
        <Col w={[4, 6, 4]} mb={4}>
          <InputField
            name="newGroupEmail"
            placeholder={email}
            label="Lead Email"
            value={newGroupEmail}
            setValue={onChange}
            error={errors.newGroupEmail}
          />
        </Col>
      </Row>
      <Col w={[4, 6, 4]}>
        <Button
          primary
          onClick={() => {
            try {
              setSubmitAttempt(true);
              validate({ newGroupEmail, organisationName });
              setIsAddAsNewGroupModalOpen(true);
            } catch (err) {
              if (err.name === "ValidationError") {
                setErrors((_errors) => ({
                  ..._errors,
                  ...err.inner,
                }));
              }
            }
          }}
          disabled={!newGroupEmail || !organisationName}
        >
          Add new group
        </Button>
      </Col>
    </S.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  firstName: state.tkGroupConflictData.data.firstName,
  lastName: state.tkGroupConflictData.data.lastName,
  name: state.tkGroupConflictData.data.name,
  email: state.tkGroupConflictData.data.email,
  phoneNumber: state.tkGroupConflictData.data.phoneNumber,
  tkId: state.tkGroupConflictData.data.tkId,
  loading: state.tkGroupConflictData.loading,
  programme: state.tkGroupConflictData.data.programmeName,
  programmeName: state.tkGroupConflictData.data.programmeName,
  programmeId: state.tkGroupConflictData.data.programmeId,
  staffSingleEarnGroup: state.tkGroupConflictData.data,
  conflictGroupsData: state.tkGroupConflictData.data,
  mergeGroupLoading: state.tkGroupConflictData.mergeGroupLoading,
  mergeGroupError: state.tkGroupConflictData.mergeGroupError,
  newGroupUserId: state.tkGroupConflictData.data.newGroupUserId,
});

const mapActionsToProps = {
  getEarnGroupByUserIdAction: getEarnGroupByUserId,
  resetStateAction: resetState,
  getTkGroupConflictDataByUserIdAction: getTkGroupConflictDataByUserId,
  mergeGroupsAction: mergeGroups,
  approveAsNewGroupAction: approveAsNewGroup,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(TkSingleGroupRequest);
