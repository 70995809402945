import React, { useEffect } from "react";
import ReactGA from "react-ga4";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import * as S from "../style";

import GoBack from "../../../../../../components/GoBack";
import { Row, Col } from "../../../../../../components/Grid";

import Content from "./Content";

const TurnUp = ({ memberID, back, isTablet, authorized, language }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.event({
        category: "Activity",
        action: "Member clicks to view Turn Up details",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.OptionWrapper>
      <Row mb={6} style={{ marginBottom: isMobile ? "30px" : "40px" }}>
        <Col w={[2, 6, 8]}>
          <GoBack showText onClick={back} lang={language} />
        </Col>
      </Row>

      <Content
        lang={language}
        isTablet={isTablet}
        memberID={memberID}
        authorized={authorized}
      />
    </S.OptionWrapper>
  );
};

export default TurnUp;
