import { Link } from "react-router-dom";
import { styled } from "@material-ui/core/styles";

export const Wrapper = styled(Link)(({ theme, customWidth, m, p }) => ({
  color: theme.colors.white,
  textDecoration: "none",
  width: customWidth || "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  cursor: "pointer",
  borderRadius: "8px",
  minWidth: "240px",
  position: "relative",
  marginBottom: "5px",
  margin: m || "0",
  padding: p || "0",
  maxWidth: "390px",
}));

export const RestrictionLayer = styled("div")(({ soldOut }) => {
  const display = soldOut ? "block" : "none";
  return {
    display,
    width: "100%",
    height: "100%",
    background: "rgba(255,255,255,0.5)",
    position: "absolute",
    zIndex: 1,
  };
});

export const RestrictionTag = styled("div")(({ theme }) => ({
  position: "absolute",
  background: theme.colors.pink,
  right: 0,
  top: 24,
  maxWidth: 113,
  padding: "8px 10px",
  borderTopLeftRadius: 5,
  borderBottomLeftRadius: 5,
}));

export const Header = styled("span")(({ theme, imgUrl }) => ({
  background: `no-repeat url("${imgUrl}") white center`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  height: "220px",
  maxWidth: "390px",
  position: "relative",
  borderRadius: "8px",

  "&:hover": {
    boxShadow: theme.customShadows.card,
  },

  "&:active": {
    boxShadow: theme.customShadows.cardClick,
  },
}));

export const Body = styled("span")(({ theme, extraRightPadding }) => ({
  position: "relative",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(extraRightPadding ? 7 : 2),
  minHeight: "70px",
}));

export const TimeCredits = styled("span")(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
}));

export const Featured = styled("div")(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  left: theme.spacing(1),
  background: `#0057A3`,
  width: theme.spacing(10),
  height: theme.spacing(5),
  borderRadius: theme.spacing(10),
  fontSize: "14px",
  padding: `0 10px`,
  paddingTop: "4px",
  color: "#FFF",
  zIndex: 1,
}));
