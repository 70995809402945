import { styled } from "@material-ui/core/styles";

export const Wrapper = styled("div")(({ theme, width, margin, height }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width,
  padding: `0 ${theme.spacing(0)}`,
  margin,
  height,

  "& *": {
    textAlign: "center",
  },
}));

export const NumberWrapper = styled("div")(() => ({
  display: "flex",
  position: "relative",
  justifyContent: "center",
}));

export const RightPopWrapper = styled("div")(() => ({
  position: "absolute",
  right: -40,
}));
