import {
  GET_PROGRAMMES_LOADING_START,
  GET_PROGRAMMES_LOADING_END,
  GET_PROGRAMMES_SUCCESS,
  GET_PROGRAMMES_FAIL,
  GET_FILTERED_PROGRAMMES_SUCCESS,
} from "../../../../redux/types";

const initState = {
  data: [],
  activeProgrammes: 0,
  loading: false,
  error: null,
};

const staffProgrammes = (state = initState, action) => {
  const { type, payload } = action;
  let activeProgrammes;

  switch (type) {
    case GET_PROGRAMMES_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case GET_PROGRAMMES_LOADING_END:
      return {
        ...state,
        loading: false,
      };
    case GET_PROGRAMMES_SUCCESS:
      activeProgrammes = payload.filter((e) => e.status === "active").length;

      return {
        ...state,
        data: payload,
        activeProgrammes,
        loading: false,
        error: {},
      };
    case GET_FILTERED_PROGRAMMES_SUCCESS:
      return {
        ...state,
        data: payload,

        loading: false,
        error: {},
      };
    case GET_PROGRAMMES_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default staffProgrammes;
