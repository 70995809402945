import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { GENERAL } from "../../../constants/navRoutes";
import t from "../../../utils/helpers/translator";

import {
  H60Caps,
  H40Caps,
  LinkB14,
  Body16R,
} from "../../../components/Typograpy";
import { Regular } from "../../../components/Button";
import WelcomeSection from "../../Login/WelcomeSection";
import Dropdown from "../../../components/Inputs/Dropdown";
import SingleDatePicker from "../../../components/Inputs/SingleDatePicker";
import * as actions from "../../../redux/actions/auth";
import validate from "../../../validation/schemas/continueSignup";
import {
  genderDropDownEnglish,
  genderDropDownWelsh,
  genderValuesMapWelsh,
  ethnicsDropDownEnglish,
  ethnicsDropDownWelsh,
  ethnicListValuesMapWelsh,
  mentalHealthDropDownEnglish,
  mentalHealthDropDownWelsh,
  mentalHealthValuesMapWelsh,
  volunteeredListEnglish,
  volunteeredListWelsh,
  volunteeredValuesMapWelsh,
} from "../../../constants/dropdDownData";

import { SubmitBox, Title, Error } from "./style";

import { Row, Col } from "../../../components/Grid";

const ContinueSignUp = ({
  error,
  updateMemberProfile,
  updateMemberProfileLoading,
  isLoggedIn,
  language,
}) => {
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [submitAttempt, setSubmitAttempt] = useState(false);
  const history = useHistory();

  const partialValidate = (name, value) => {
    try {
      if (submitAttempt) {
        validate(
          {
            [name]: value,
          },
          true,
        );
      }
    } catch (err) {
      if (err.name === "ValidationError") {
        setErrors((_errors) => ({ ..._errors, [name]: err.inner[name] }));
      }
    }
  };

  const onChange = async (name, value) => {
    try {
      partialValidate(name, value);
      setData((currentData) => ({ ...currentData, [name]: value }));
    } catch (err) {
      if (err.name === "ValidationError") {
        setErrors(err.inner);
      }
    }
  };

  const handleSubmit = async () => {
    try {
      setSubmitAttempt(true);

      await validate(data);
      updateMemberProfile(data, history);
    } catch (err) {
      if (err.name === "ValidationError") {
        setErrors(err.inner);
      }
    }
  };

  const onMentalHealthConditionChange = ({ selected }) => {
    const value =
      selected && selected.length ? selected.map((e) => e.value) : [];

    if (value && value.includes("No")) {
      onChange("mentalHealthCondition", ["No"]);
    } else {
      onChange("mentalHealthCondition", value);
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const informationUseExplanation = t(
    "informationUseExplanation",
    language,
  ).split(" | ");

  useEffect(() => {
    setErrors({});
  }, [language]);

  return (
    <div style={{ position: "relative", marginBottom: "80px" }}>
      <div>
        <Row>
          <Col w={[4, 8, 8]}>
            {isMobile ? (
              <Title>
                <H40Caps color="primary" style={{ lineHeight: "40px" }}>
                  {t("aboutYou", language)}
                </H40Caps>
              </Title>
            ) : (
              <Title>
                <H60Caps color="primary" style={{ lineHeight: "60px" }}>
                  {t("aboutYou", language)}
                </H60Caps>
              </Title>
            )}
          </Col>
        </Row>
        <Row mb={3}>
          <Col w={[4, 7, 7]}>
            <Body16R color="gray3">{t("atTempoExplanation", language)}</Body16R>
          </Col>
        </Row>
        <Row mb={3}>
          <Col w={[4, 7, 7]}>
            <Body16R color="gray3">
              {informationUseExplanation[0]}
              <span style={{ fontWeight: "bold", color: "#201751" }}>
                {" "}
                {informationUseExplanation[1]}
              </span>
            </Body16R>
          </Col>
        </Row>
        <Row mb={isMobile ? 6 : 8}>
          <Col w={[4, 7, 7]}>
            <Body16R color="gray3">{t("appreciateYourHelp", language)}</Body16R>
          </Col>
        </Row>
        <Row mb={5}>
          <Col w={[4, 4, 4]} style={{ marginBottom: isMobile && "30px" }}>
            <Dropdown
              name="gender"
              options={
                language === "english"
                  ? genderDropDownEnglish
                  : genderDropDownWelsh
              }
              value={
                data.gender && {
                  label:
                    language === "english"
                      ? data.gender
                      : genderValuesMapWelsh[data.gender],
                  value: data.gender,
                }
              }
              setValue={({ selected }) => onChange("gender", selected.value)}
              label={t("whatGender", language)}
              placeholder={t("select", language)}
              error={errors.gender}
              isSearchable={false}
            />
          </Col>
          <Col w={[4, 4, 4]}>
            <Dropdown
              name="ethnic"
              options={
                language === "english"
                  ? ethnicsDropDownEnglish
                  : ethnicsDropDownWelsh
              }
              value={
                data.ethnic && {
                  label:
                    language === "english"
                      ? data.ethnic
                      : ethnicListValuesMapWelsh[data.ethnic],
                  value: data.ethnic,
                }
              }
              setValue={({ selected }) => onChange("ethnic", selected.value)}
              label={t("WhatIsYourEthnic", language)}
              placeholder={t("select", language)}
              error={errors.ethnic}
              isSearchable={false}
            />
          </Col>
        </Row>
        <Row mb={5}>
          <Col w={[4, 4, 4]} style={{ marginBottom: isMobile && "30px" }}>
            <Dropdown
              name="mentalHealthCondition"
              options={
                language === "english"
                  ? mentalHealthDropDownEnglish
                  : mentalHealthDropDownWelsh
              }
              value={
                data.mentalHealthCondition && data.mentalHealthCondition.length
                  ? data.mentalHealthCondition.map((e) => ({
                      label:
                        language === "english"
                          ? e
                          : mentalHealthValuesMapWelsh[e],
                      value: e,
                    }))
                  : []
              }
              setValue={onMentalHealthConditionChange}
              label={t("doYouHavePhysicalConditions", language)}
              placeholder={t("select", language)}
              helper={t("tickAsManyAsApply", language)}
              error={errors.mentalHealthCondition}
              fixedHeight={false}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              isMulti
              max={
                data.mentalHealthCondition &&
                data.mentalHealthCondition.includes("No")
                  ? 1
                  : undefined
              }
              isSearchable={false}
            />
          </Col>
          <Col w={[4, 4, 4]}>
            <SingleDatePicker
              name="birthDate"
              label={t("dateOfBirth", language)}
              placeholder={t("dateOfBirth", language)}
              value={data.birthDate}
              setValue={(datePick) => onChange("birthDate", datePick)}
              singleDatePicker
              error={errors.birthDate}
              disableFuture
            />
          </Col>
        </Row>
        <Row mb={6}>
          <Col w={[4, 4, 4]}>
            <Dropdown
              name="volunteeredBefore"
              options={
                language === "english"
                  ? volunteeredListEnglish
                  : volunteeredListWelsh
              }
              value={
                data.volunteeredBefore && {
                  label:
                    language === "english"
                      ? data.volunteeredBefore
                      : volunteeredValuesMapWelsh[data.volunteeredBefore],
                  value: data.volunteeredBefore,
                }
              }
              setValue={({ selected }) =>
                onChange("volunteeredBefore", selected.value)
              }
              label={`${t("haveYouVolunteered", language)}?`}
              placeholder={t("select", language)}
              error={errors.volunteeredBefore}
              isSearchable={false}
            />
          </Col>
        </Row>
        <Row mb={5}>
          <Col w={[4, 4, 4]}>
            <SubmitBox>
              {error && error.message && !isLoggedIn && (
                <Error style={{ marginBottom: "30px" }}>
                  {t(error.message, language)}
                </Error>
              )}
              <Regular
                bgColor="blue"
                handleClick={handleSubmit}
                ml="0"
                loading={updateMemberProfileLoading}
              >
                {t("continue", language)}
              </Regular>
            </SubmitBox>
          </Col>
        </Row>
        <Row>
          <Col w={[4, 4, 4]}>
            <LinkB14
              color="gray2"
              style={{ display: "inline", margin: "auto", cursor: "pointer" }}
            >
              <Link to={GENERAL.WELCOME} style={{ color: "inherit" }}>
                {t("skipStep", language)}
              </Link>
            </LinkB14>
          </Col>
        </Row>
      </div>
      <div
        style={{ width: "300px", position: "absolute", top: "0", right: "0" }}
      >
        <WelcomeSection fullWidth />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  updateMemberProfileSuccess: state.auth.updateMemberProfileSuccess,
  updateMemberProfileLoading: state.auth.updateMemberProfileLoading,
  error: state.auth.error,
  isLoggedIn: state.auth.isLoggedIn,
  language: state.auth.language,
});

const mapActionToProps = {
  updateMemberProfile: actions.updateMemberProfile,
};

export default connect(mapStateToProps, mapActionToProps)(ContinueSignUp);
