import axios from "axios";
import handleError from "./handle-errors";

const PAID_PACKAGES_BASE = "/paid-packages";

const getPaidPackages = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(PAID_PACKAGES_BASE);

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getActivePrice = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${PAID_PACKAGES_BASE}/prices/active`);

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updatePackages = async (body = {}, { options } = {}) => {
  try {
    const { data } = await axios.patch(`${PAID_PACKAGES_BASE}`, body);

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const createPaidPackageOrder = async ({
  customCredits,
  selectedPackageId,
  options,
}) => {
  try {
    const { data } = await axios.post(`${PAID_PACKAGES_BASE}/orders`, {
      customCredits,
      selectedPackageId,
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getOrderById = async ({ id, options }) => {
  try {
    const { data } = await axios.get(`${PAID_PACKAGES_BASE}/orders/${id}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const confirmOrder = async ({ id, body, options }) => {
  try {
    const { data } = await axios.patch(
      `${PAID_PACKAGES_BASE}/orders/${id}/confirm`,
      body,
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const cancelOrder = async ({ orderId, cancellationReason, options }) => {
  try {
    const { data } = await axios.patch(
      `${PAID_PACKAGES_BASE}/orders/${orderId}/cancel`,
      { cancellationReason },
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getOrders = async ({ startDate, endDate, options } = {}) => {
  try {
    const { data } = await axios.get(`${PAID_PACKAGES_BASE}/orders`, {
      params: { startDate, endDate },
    });

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const testInvoice = {
  to: {
    name: "Shine Enterprise Ltd",
    address1: "Shine Enterprise, 9 Test Street",
    address2: "Imaginary Road",
    city: "London",
    postcode: "E1 1TT",
  },
  from: {
    name: "Tempo Time Credits Ltd",
    address1: "Tempo, Unit 2, 58 – 62 Cowbridge Court",
    address2: "Cowbridge Road West Cardiff ",
    city: "Wales, United Kingdom",
    postcode: "CF5 5BS",
  },
  invoiceNumber: "INV-0012312",
  invoiceDate: "14/11/2020",
  description: "Tempo Time Credits Purchase",
  quantity: "100",
  amountGBP: "20.00",
  subtotal: "20.00",
  totalVat: "4.00",
  totalAmount: "24.00",
};

const getOrderInvoice = async ({ options, orderId } = {}) => {
  try {
    const { data } = await axios.get(
      `${PAID_PACKAGES_BASE}/orders/${orderId}/invoice`,
    );

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export {
  getPaidPackages,
  updatePackages,
  getActivePrice,
  createPaidPackageOrder,
  getOrderById,
  confirmOrder,
  getOrders,
  cancelOrder,
  getOrderInvoice,
};
