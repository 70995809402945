import React from "react";

const File = ({ width, height, color, background, ...props }) => (
  <svg
    width={width}
    height={height}
    {...props}
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.0779 12.4331L30.0701 12.4019L25.6873 1.26514C25.492 0.63623 24.9099 0.202637 24.2498 0.202637H6.48414C5.82008 0.202637 5.23023 0.644043 5.04273 1.28076L0.945076 12.3042L0.933358 12.3315L0.925545 12.3628C0.874764 12.5542 0.859139 12.7495 0.886483 12.9409C0.882576 13.0034 0.87867 13.0659 0.87867 13.1284V27.4214C0.879703 28.051 1.13026 28.6544 1.57543 29.0996C2.02061 29.5448 2.6241 29.7953 3.25367 29.7964H27.7537C29.0623 29.7964 30.1287 28.73 30.1326 27.4214V13.1284C30.1326 13.0776 30.1326 13.0269 30.1287 12.9839C30.1443 12.7925 30.1287 12.6089 30.0779 12.4331ZM18.5232 10.7534L18.5115 11.3667C18.4802 13.1206 17.2693 14.3003 15.4998 14.3003C14.6365 14.3003 13.8943 14.0229 13.3591 13.4956C12.824 12.9683 12.531 12.2339 12.5154 11.3667L12.5037 10.7534H4.46461L7.57008 3.20264H23.1638L26.3552 10.7534H18.5232ZM3.87476 13.7534H10.0193C10.9685 15.9839 12.988 17.3003 15.5037 17.3003C16.8201 17.3003 18.0427 16.9331 19.031 16.2378C19.8982 15.6284 20.574 14.7769 21.0115 13.7534H27.1248V26.7964H3.87476V13.7534Z"
      fill={color || "#C4C4C4"}
    />
  </svg>
);
export default File;
