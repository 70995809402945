import React from "react";
import { Switch } from "react-router-dom";

// CONSTANTS
import { EARN_GROUP_ADMIN } from "../../constants/navRoutes";
import { roles } from "../../constants";

// MULTI-USE COMPONENTS
import SignUpEarnGroupAdmin from "../Signup/SignUpEarnGroupAdmin";
import Login from "../Login";

// OTHER ROUTERS
import ErrorRouter from "../ErrorRoutes";

// COMMON
import Route from "../../components/Route";

const EarnGroupAdminRouter = () => (
  <Switch>
    <Route
      exact
      path={EARN_GROUP_ADMIN.SIGN_UP}
      layout="onboarding"
      Component={SignUpEarnGroupAdmin}
      userType={roles.EARN_GROUP_ADMIN}
    />

    <Route
      exact
      path={EARN_GROUP_ADMIN.LOGIN_ADD}
      layout="onboarding"
      footer
      Component={Login}
      userType={roles.EARN_GROUP_ADMIN}
    />

    {/* ERROR ROUTES */}
    <ErrorRouter />
  </Switch>
);

export default EarnGroupAdminRouter;
