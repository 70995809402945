import { tkGroups } from "../../../../api-calls";

import {
  GET_TK_CONFLICTED_PROGRAMME_LOADING_START,
  GET_TK_CONFLICTED_PROGRAMME_LOADING_END,
  GET_TK_CONFLICTED_PROGRAMME_SUCCESS,
  GET_CONFLICTED_PROGRAMME_FAIL,
  TK_ASSIGN_PROGRAMME_START,
  TK_ASSIGN_PROGRAMME_END,
  TK_ASSIGN_PROGRAMME_SUCCESS,
  TK_ASSIGN_PROGRAMME_FAIL,
} from "../../../../redux/types";

export const getTkConflictedProgrammesByProgrammeId =
  ({ id: userId, approvalRequestId }) =>
  async (dispatch) => {
    dispatch({ type: GET_TK_CONFLICTED_PROGRAMME_LOADING_START });
    try {
      const { data, error } =
        await tkGroups.getTkConflictedProgrammesByProgrammeId({
          userId,
          approvalRequestId,
        });
      if (data) {
        dispatch({ type: GET_TK_CONFLICTED_PROGRAMME_SUCCESS, payload: data });
      } else {
        dispatch({
          type: GET_CONFLICTED_PROGRAMME_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_CONFLICTED_PROGRAMME_FAIL,
        payload: error,
      });
    } finally {
      dispatch({ type: GET_TK_CONFLICTED_PROGRAMME_LOADING_END });
    }
  };

export const assignProgramme =
  ({ groupUserId, approvalRequestId, programmeId }) =>
  async (dispatch) => {
    dispatch({ type: TK_ASSIGN_PROGRAMME_START });
    try {
      const { data, error } = await tkGroups.assignProgramme({
        groupUserId,
        approvalRequestId,
        programmeId,
      });

      if (error) {
        dispatch({
          type: TK_ASSIGN_PROGRAMME_FAIL,
          payload: error,
        });
      } else {
        dispatch({
          type: TK_ASSIGN_PROGRAMME_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      dispatch({
        type: TK_ASSIGN_PROGRAMME_FAIL,
        payload: error,
      });
    } finally {
      dispatch({ type: TK_ASSIGN_PROGRAMME_END });
    }
  };
