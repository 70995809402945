import React from "react";
import * as S from "./style";
import * as T from "../Typograpy";
import Icon from "../Icon";

const Info = ({ header, size, body, color, iconSize, fullWidth }) => (
  <S.Wrapper size={size} fullWidth={fullWidth}>
    <Icon
      icon="info"
      color="cyan"
      width={iconSize || "60px"}
      height={iconSize || "60px"}
      margin="0 10px 0 0"
    />
    <S.Body>
      {size === "large" ? (
        <T.H18
          color={color || "midnight"}
          style={{ marginBottom: body ? "10px" : "0" }}
        >
          {header}
        </T.H18>
      ) : (
        <T.Body16B
          color={color || "blue"}
          style={{ marginBottom: body ? "10px" : "0" }}
        >
          {header}
        </T.Body16B>
      )}
      {body && <T.Body14R>{body}</T.Body14R>}
    </S.Body>
  </S.Wrapper>
);

export default Info;
