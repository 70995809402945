import { styled } from "@material-ui/core/styles";
import * as T from "../../../components/Typograpy";

export const Wrapper = styled("section")(() => ({
  position: "relative",
  flex: "1",
  display: "flex",
  flexDirection: "column",
}));

export const StyledEarnGroup = styled(T.H18Caps)(({ theme }) => ({
  color: theme.colors.pink,
  textTransform: "uppercase",
  marginBottom: theme.spacing(0),
  fontSize: "18px",
  lineHeight: "20px",

  [theme.breakpoints.tablet]: {
    fontSize: "22px",
    lineHeight: "25px",
  },
}));

export const StyledHeading = styled(T.H24)(({ theme }) => ({
  color: theme.colors.midnight,
  fontSize: "1.5rem",
  fontWeight: "900",
  lineHeight: "2.1875rem",

  [theme.breakpoints.tablet]: {
    fontSize: "40px",
    lineHeight: "40px",
  },
}));

export const Header = styled("header")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  paddingRight: "10%",
  paddingLeft: "10%",

  [theme.breakpoints.mobileL]: {
    padding: "0",
  },
}));

export const StatsSection = styled("section")(({ theme }) => ({
  paddingTop: theme.spacing(6),

  [theme.breakpoints.mobileS]: {
    padding: "0",
  },
}));

export const Tabs = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-evenly",
  minHeight: 35,

  [theme.breakpoints.mobileXL]: {
    justifyContent: "flex-start",
  },
}));

export const Tab = styled("button")(({ theme, active }) => ({
  outline: "none",
  border: "none",
  background: "none",
  cursor: "pointer",
  textDecoration: "none",
  color: theme.colors.midnight,
  padding: 0,
  margin: "0 5px",
  maxWidth: "33%",

  boxSizing: "border-box",
  paddingBottom: !active && "5px",

  borderBottom: active ? `3px ${theme.colors.pink} solid` : "none",

  [theme.breakpoints.mobileM]: {
    margin: "0 15px",
  },

  [theme.breakpoints.mobileXL]: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(3),
  },
}));

export const StatsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: theme.spacing(5),

  [theme.breakpoints.mobileL]: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
  },
}));

export const LinkWrapper = styled("section")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  marginTop: 0,
  marginBottom: theme.spacing(8),
  maxWidth: "1000px",

  [theme.breakpoints.mobileS]: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },

  [theme.breakpoints.mobileL]: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),

    "& a": {
      marginBottom: theme.spacing(1),
    },
  },

  [theme.breakpoints.mobileXL]: {
    "& a:nth-child(odd)": {
      marginRight: theme.spacing(1),
    },
  },
}));

export const MemberWrapper = styled("section")(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(9),

  [theme.breakpoints.mobileXL]: {
    paddingLeft: "0",
    paddingRight: "0",
  },
}));
