import {
  REQUEST_TC_LOADING_START,
  REQUEST_TC_LOADING_END,
  REQUEST_TC_FAIL,
  CREATE_PAID_ORDER_LOADING_START,
  CREATE_PAID_ORDER_LOADING_END,
  CREATE_PAID_ORDER_FAIL,
} from "../../../redux/types";

const initState = {
  loading: false,
  error: {},
};

const requestCreditsReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_TC_LOADING_START:
    case CREATE_PAID_ORDER_LOADING_START:
      return {
        ...state,
        loading: true,
        error: {},
      };
    case REQUEST_TC_LOADING_END:
    case CREATE_PAID_ORDER_LOADING_END:
      return {
        ...state,
        loading: false,
      };

    case REQUEST_TC_FAIL:
    case CREATE_PAID_ORDER_FAIL:
      return {
        ...state,
        error: payload,
      };

    default:
      return state;
  }
};

export default requestCreditsReducer;
