import { styled } from "@material-ui/core/styles";

const getRight = (lang, isOpen) => {
  if (isOpen) return -3;
  if (lang === "english") return -209;
  return -213;
};

export const FeedbackLink = styled("button")(
  ({ theme, lang, isOpen, disabled }) => ({
    position: "fixed",
    bottom: lang === "english" ? "200px" : "215px",
    marginBottom: lang === "english" ? "10px" : "0px",
    right: getRight(lang, isOpen),
    paddingInline: 0,
    background: theme.colors.blue,
    paddingLeft: "50px",
    transition: "all ease 0.1s",
    border: "none",
    borderTopLeftRadius: theme.borders.radius,
    borderBottomLeftRadius: theme.borders.radius,
    cursor: disabled ? "not-allowed" : "pointer",
    opacity: disabled ? 0.5 : 1,
    "&:hover": {
      right:
        lang === "english"
          ? getRight(lang, isOpen) + 3
          : getRight(lang, isOpen),
    },
    zIndex: 1,
  }),
);
export const FeedbackContent = styled("div")(() => ({
  width: 250,
  minHeight: 150,
  position: "relative",
  display: "grid",
  placeItems: "center",
}));
export const FeedbackTitle = styled("div")(() => ({
  color: "white",
  display: "flex",
  position: "absolute",
  transform: "rotate(270deg) translate(-50%, 0px)",
  transformOrigin: "top left",
  top: "50%",
  left: "10px",
}));

export const GoogleTranslateElementParent = styled("div")(() => ({
  marginTop: "20px",
  marginLeft: "50px",
}));
