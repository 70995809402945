import React from "react";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CookieConsent from "react-cookie-consent";
import theme from "../../utils/theme";

import cookieStyles from "./style";

import translate from "../../utils/helpers/translator";

import { externalLinks } from "../../constants";

const CookieBanner = ({ lang }) => {
  const isTablet = useMediaQuery("(max-width: 1200px)");
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const styles = cookieStyles({ isTablet, isMobile });
  return (
    <CookieConsent
      overlay={isTablet}
      overlayStyle={styles.overlayStyle}
      contentStyle={{
        flex: isTablet && 0,
        marginBottom: 5,
        marginTop: isMobile ? 5 : 15,
      }}
      location="bottom"
      buttonText={translate("iUnderstand", lang)}
      cookieName="Tempo Cookie"
      style={isTablet ? styles.tabletStyle : styles.general}
      buttonStyle={styles.button}
    >
      {translate("cookieExplanation", lang)}.
      <a
        style={styles.link}
        href={externalLinks.COOKIE_POLICY}
        target="_blank"
        rel="noopener noreferrer"
      >
        {translate("findOutMoreCookies", lang)}
      </a>
    </CookieConsent>
  );
};

const mapStateToProps = (state) => ({
  lang: state.auth.language,
});

export default connect(mapStateToProps)(CookieBanner);
