import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import makeAnimated from "react-select/animated";

import SelectComponent from "./SelectComponent";

import * as T from "../../Typograpy";
import useStyles from "../style";
// import compareProps from "../../../utils/helpers/compareProps";

const animatedComponents = makeAnimated();

const DropdownComponent = (props) => {
  const {
    label,
    caption,
    placeholder,
    options,
    value = null,
    setValue,
    error,
    // decides if multiple option (true) or single option (false)
    isMulti,
    // decides to show "select all" option or not
    allowSelectAll,
    size = "normal",
    customSelectFunc,
    closeMenuOnSelect,
    blurInputOnSelect,
    isClearable,
    canCreate,
    helper = "",
    disabled,
  } = props;
  const classes = useStyles(props);

  // renders typography for labels above box
  const renderLabel = {
    normal: (
      <>
        <T.Body18B className={classes.textInputLabel}>{label}</T.Body18B>
        {!!helper && (
          <T.Body14R
            color="gray3"
            style={{
              display: "block",
              paddingLeft: 15,
              marginTop: "-5px",
              marginBottom: "10px",
            }}
            m="0 0 0 5px"
          >
            {" "}
            ({helper})
          </T.Body14R>
        )}
      </>
    ),
    large: (
      <>
        <T.Body20B className={classes.textInputLabel}>{label}</T.Body20B>
        {!!helper && (
          <T.Body14R
            color="gray3"
            style={{
              display: "block",
              paddingLeft: 15,
              marginTop: "-5px",
              marginBottom: "10px",
            }}
          >
            {" "}
            ({helper})
          </T.Body14R>
        )}
      </>
    ),
    largeOptional: (
      <T.Body20B className={classes.textInputLabel}>
        {label}
        {/* optional brackets render */}
        <T.Body14R
          style={{
            marginBottom: "1rem",
            marginLeft: "0.3rem",
          }}
          variant="brackets"
        >
          (optional)
        </T.Body14R>
      </T.Body20B>
    ),
  };

  const handleChange = (selected) => {
    setValue({
      selected,
    });
  };

  // disable the keyboard in mobile view

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="flex-start"
      className={classes.root}
    >
      {label && renderLabel[size]}

      <Box className={classes.dropdownField}>
        <SelectComponent
          {...props}
          isMulti={isMulti}
          ref={(select) => {
            if (select) select.setState({ isOpen: true });
          }}
          options={options}
          hideSelectedOptions={false}
          components={animatedComponents}
          onChange={(e) =>
            customSelectFunc ? customSelectFunc(e) : handleChange(e)
          }
          allowSelectAll={allowSelectAll}
          value={value}
          placeholder={placeholder}
          size={size}
          closeMenuOnSelect={closeMenuOnSelect}
          blurInputOnSelect={blurInputOnSelect}
          isClearable={isClearable}
          canCreate={canCreate}
          disabled={disabled}
        />
      </Box>
      {caption && (
        <T.Body14R className={classes.dropdownCaption}>{caption}</T.Body14R>
      )}
      {error && (
        <T.Body14R className={classes.textFieldError}>{error}</T.Body14R>
      )}
    </Grid>
  );
};

// export default React.memo(DropdownComponent, (pProps, nProps) => {
//   const allPropsKeys = [
//     "name",
//     "label",
//     "caption",
//     "placeholder",
//     "options",
//     "value",
//     "error",
//     "isMulti",
//     "allowSelectAll",
//     "size",
//     "isClearable",
//     "canCreate",
//     "helper",
//     "disabled",
//     "labelColor",
//     "mt",
//     "mb",
//     "ml",
//     "mr",
//   ];

//   return compareProps(pProps, nProps, allPropsKeys);
// });

export default DropdownComponent;
