import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { toggleUIcolor } from "../../../redux/actions/userActions";

import * as S from "./style";
import * as T from "../../../components/Typograpy";
import { MEMBER, EARN_GROUP } from "../../../constants/navRoutes";

import Image from "../../../components/Image";
import Button from "../../../components/Button";
import ProgressBar from "../../../components/ProgressBar";

import { earnGroupContent, memberContent } from "./content";
import { roles } from "../../../constants";

import translate from "../../../utils/helpers/translator";

const Welcome = ({
  changeLayoutColor,
  earnGroupInfo,
  role,
  acceptedGiftId,
  lang,
}) => {
  const [position, setPosition] = useState(0);
  const [contentArr, setContentArr] = useState(memberContent);

  const history = useHistory();

  const decideUrl = () => {
    switch (role) {
      case roles.EARN_GROUP:
      case roles.EARN_GROUP_ADMIN:
        return EARN_GROUP.DASHBOARD;
      default:
        return MEMBER.DASHBOARD;
    }
  };

  const nextPage = () => {
    if (position + 1 < contentArr.length) {
      changeLayoutColor(contentArr[position + 1].color);
      return setPosition(position + 1);
    }
    changeLayoutColor("blue");
    if (earnGroupInfo.profile.name && role === MEMBER) {
      return history.push(MEMBER.INVITED_BY_GROUP_SUCCESS);
    }
    if (acceptedGiftId && role === MEMBER) {
      return history.push(
        MEMBER.RECEIVED_GIFT_SUCCESS.replace(":id", acceptedGiftId),
      );
    }

    return history.push(decideUrl());
  };

  const clickProgressCircle = (event, index) => {
    changeLayoutColor(contentArr[index].color);
    setPosition(index);
  };

  useEffect(() => {
    switch (role) {
      case roles.EARN_GROUP:
      case roles.EARN_GROUP_ADMIN:
        setContentArr(earnGroupContent);
        break;
      default:
        setContentArr(memberContent);
    }
  }, [role]);

  return (
    <>
      <S.Wrapper>
        <Image
          image={contentArr[position].img}
          alt="Tempo activity"
          width="200px"
          height="auto"
          customStyle={{ maxHeight: "280px", margin: "0 auto" }}
        />
        <S.Body>
          <T.H40Caps
            color="midnight"
            style={{
              fontSize: contentArr[position].size === "small" && "30px",
              lineHeight: contentArr[position].size === "small" && "30px",
              marginBottom: "10px",
            }}
          >
            {translate(contentArr[position].title, lang)}
          </T.H40Caps>
          <T.Body16R style={{ margin: "0" }}>
            {translate(contentArr[position].content, lang)}.
          </T.Body16R>
        </S.Body>
        <S.Progress>
          <ProgressBar
            number={contentArr.length}
            current={position}
            handleClick={clickProgressCircle}
            margin="20px 0"
            color="midnight"
          />
          <Button
            primary
            bgColor="blue"
            customWidth="140px"
            handleClick={nextPage}
            w="50%"
            m="0"
          >
            {translate("continue", lang)}
          </Button>
        </S.Progress>
      </S.Wrapper>
    </>
  );
};

const mapStateToProps = (state) => ({
  earnGroupInfo: state.auth.earnGroupInfo,
  role: state.auth.role,
  acceptedGiftId: state.auth.acceptedGiftId,
  lang: state.auth.language,
});

const mapActionToProps = {
  changeLayoutColor: toggleUIcolor,
};

export default connect(mapStateToProps, mapActionToProps)(Welcome);
