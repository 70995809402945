import { styled } from "@material-ui/core/styles";

export const Wrapper = styled("section")(() => ({
  position: "relative",
  flex: "1",
  display: "flex",
  flexDirection: "column",
}));

export const Body = styled("div")(({ theme }) => ({
  margin: "0 auto",
  marginTop: theme.spacing(9),
  maxWidth: "450px",
}));
