import { keys as keysEnglish } from "./regionsEnglish";

const keys = {
  UK_WIDE: "Ledled y du",
  ENGLAND: "Lloegr",
  ENGLAND_WIDE: "Ledled lloegr",
  EAST_OF_ENGLAND: "Dwyrain Lloegr",
  EAST_MIDLANDS: "Dwyrain Canolbarth Lloegr",
  LONDON: "Llundain",
  NORTH_EAST: "Gogledd-ddwyrain Lloegr",
  NORTH_WEST: "Gogledd-orllewin Lloegr",
  SOUTH_EAST: "De-ddwyrain Lloegr",
  SOUTH_WEST: "De-orllewin Lloegr",
  WEST_MIDLANDS: "Gorllewin Canolbarth Lloegr",
  YORKSHIRE_AND_THE_HUMBER: "Swydd Efrog a Humber",
  WALES: "Cymru",
  WALES_WIDE: "Ledled cymru",
  MID_WALES: "Canolbarth Cymru",
  NORTH_WALES: "Gogledd Cymru",
  SOUTH_EAST_WALES: "De-ddwyrain Cymru",
  SOUTH_WEST_WALES: "De-orllewin Cymru",
  SCOTLAND: "Yr Alban",
  SCOTLAND_WIDE: "Ledled yr alban",
  ABERDEEN_GRAMPIAN: "Aberdeen a Grampian",
  ANGUS_DUNDEE: "Angus a Dundee",
  ARGYLL: "Argyll",
  DUMFRIES_GALLOWAY: "Dumfries a Galloway",
  EDINBURGH_THE_LOTHIANS: "Caeredin a'r Lothians",
  FIFE: "Fife",
  GLASGOW_CLYDE_VALLEY: "Glasgow a Dyffryn Clyde",
  THE_SCOTTISH_HIGHLANDS: "Ucheldiroedd yr Alban",
  ORKNEY: "Orkney",
  THE_OUTER_INNER_HEBRIDES: "Yr Hebrides Allanol a Mewnol",
  PERTHSHIRE: "Swydd Perth",
  THE_SHETLAND_ISLANDS: "Ynysoedd Shetland",
  STIRLING_THE_TROSSACHS: "Stirling a'r Trossachs",
  NORTHERN_IRELAND: "Gogledd Iwerddon",
  NORTHERN_IRELAND_WIDE: "Ledled Gogledd Iwerddon",
  ANTRIM: "Antrim",
  ARMAGH: "Armagh",
  DOWN: "Down",
  FERMANAGH: "Fermanagh",
  LONDONDERRY: "Londonderry",
  TYRONE: "Tyrone",
};

const regionsDropDownWelsh = [
  {
    country: keys.UK_WIDE,
    label: keys.UK_WIDE,
    value: keysEnglish.UK_WIDE,
    countryValue: keysEnglish.UK_WIDE,
  },
  {
    label: keys.ENGLAND,
    options: [
      {
        country: keys.ENGLAND,
        countryValue: keysEnglish.ENGLAND,
        label: keys.ENGLAND_WIDE,
        value: keysEnglish.ENGLAND_WIDE,
      },
      {
        country: keys.ENGLAND,
        countryValue: keysEnglish.ENGLAND,
        label: keys.EAST_OF_ENGLAND,
        value: keysEnglish.EAST_OF_ENGLAND,
      },
      {
        country: keys.ENGLAND,
        countryValue: keysEnglish.ENGLAND,
        label: keys.EAST_MIDLANDS,
        value: keysEnglish.EAST_MIDLANDS,
      },
      { country: keys.ENGLAND, label: keys.LONDON, value: keysEnglish.LONDON },
      {
        country: keys.ENGLAND,
        countryValue: keysEnglish.ENGLAND,
        label: keys.NORTH_EAST,
        value: keysEnglish.NORTH_EAST,
      },
      {
        country: keys.ENGLAND,
        countryValue: keysEnglish.ENGLAND,
        label: keys.NORTH_WEST,
        value: keysEnglish.NORTH_WEST,
      },
      {
        country: keys.ENGLAND,
        countryValue: keysEnglish.ENGLAND,
        label: keys.SOUTH_EAST,
        value: keysEnglish.SOUTH_EAST,
      },
      {
        country: keys.ENGLAND,
        countryValue: keysEnglish.ENGLAND,
        label: keys.SOUTH_WEST,
        value: keysEnglish.SOUTH_WEST,
      },
      {
        country: keys.ENGLAND,
        countryValue: keysEnglish.ENGLAND,
        label: keys.WEST_MIDLANDS,
        value: keysEnglish.WEST_MIDLANDS,
      },
      {
        country: keys.ENGLAND,
        countryValue: keysEnglish.ENGLAND,
        label: keys.YORKSHIRE_AND_THE_HUMBER,
        value: keysEnglish.YORKSHIRE_AND_THE_HUMBER,
      },
    ].sort((a, b) => {
      if (a.label === keys.ENGLAND_WIDE || b.label === keys.ENGLAND_WIDE)
        return 1;
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    }),
  },

  {
    label: keys.WALES,
    options: [
      {
        country: keys.WALES,
        countryValue: keysEnglish.WALES,
        label: keys.WALES_WIDE,
        value: keysEnglish.WALES_WIDE,
      },
      {
        country: keys.WALES,
        countryValue: keysEnglish.WALES,
        label: keys.MID_WALES,
        value: keysEnglish.MID_WALES,
      },
      {
        country: keys.WALES,
        countryValue: keysEnglish.WALES,
        label: keys.NORTH_WALES,
        value: keysEnglish.NORTH_WALES,
      },
      {
        country: keys.WALES,
        countryValue: keysEnglish.WALES,
        label: keys.SOUTH_EAST_WALES,
        value: keysEnglish.SOUTH_EAST_WALES,
      },
      {
        country: keys.WALES,
        countryValue: keysEnglish.WALES,
        label: keys.SOUTH_WEST_WALES,
        value: keysEnglish.SOUTH_WEST_WALES,
      },
    ].sort((a, b) => {
      if (a.label === keys.WALES_WIDE || b.label === keys.WALES_WIDE) return 1;
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    }),
  },
  {
    label: keys.SCOTLAND,
    options: [
      {
        country: keys.SCOTLAND,
        countryValue: keysEnglish.SCOTLAND,
        value: keysEnglish.SCOTLAND_WIDE,
        label: keys.SCOTLAND_WIDE,
      },
      {
        country: keys.SCOTLAND,
        countryValue: keysEnglish.SCOTLAND,
        value: keysEnglish.ABERDEEN_GRAMPIAN,
        label: keys.ABERDEEN_GRAMPIAN,
      },
      {
        country: keys.SCOTLAND,
        countryValue: keysEnglish.SCOTLAND,
        value: keysEnglish.ANGUS_DUNDEE,
        label: keys.ANGUS_DUNDEE,
      },
      { country: keys.SCOTLAND, value: keysEnglish.ARGYLL, label: keys.ARGYLL },
      {
        countryValue: keysEnglish.SCOTLAND,
        value: keysEnglish.ARGYLL,
        label: keys.ARGYLL,
      },
      {
        country: keys.SCOTLAND,
        countryValue: keysEnglish.SCOTLAND,
        value: keysEnglish.DUMFRIES_GALLOWAY,
        label: keys.DUMFRIES_GALLOWAY,
      },
      {
        country: keys.SCOTLAND,
        countryValue: keysEnglish.SCOTLAND,
        value: keysEnglish.EDINBURGH_THE_LOTHIANS,
        label: keys.EDINBURGH_THE_LOTHIANS,
      },
      { country: keys.SCOTLAND, value: keysEnglish.FIFE, label: keys.FIFE },
      {
        countryValue: keysEnglish.SCOTLAND,
        value: keysEnglish.FIFE,
        label: keys.FIFE,
      },
      {
        country: keys.SCOTLAND,
        countryValue: keysEnglish.SCOTLAND,
        value: keysEnglish.GLASGOW_CLYDE_VALLEY,
        label: keys.GLASGOW_CLYDE_VALLEY,
      },
      {
        country: keys.SCOTLAND,
        countryValue: keysEnglish.SCOTLAND,
        value: keysEnglish.THE_SCOTTISH_HIGHLANDS,
        label: keys.THE_SCOTTISH_HIGHLANDS,
      },
      { country: keys.SCOTLAND, value: keysEnglish.ORKNEY, label: keys.ORKNEY },
      {
        countryValue: keysEnglish.SCOTLAND,
        value: keysEnglish.ORKNEY,
        label: keys.ORKNEY,
      },
      {
        country: keys.SCOTLAND,
        countryValue: keysEnglish.SCOTLAND,
        value: keysEnglish.THE_OUTER_INNER_HEBRIDES,
        label: keys.THE_OUTER_INNER_HEBRIDES,
      },
      {
        country: keys.SCOTLAND,
        countryValue: keysEnglish.SCOTLAND,
        value: keysEnglish.PERTHSHIRE,
        label: keys.PERTHSHIRE,
      },
      {
        country: keys.SCOTLAND,
        countryValue: keysEnglish.SCOTLAND,
        value: keysEnglish.THE_SHETLAND_ISLANDS,
        label: keys.THE_SHETLAND_ISLANDS,
      },
      {
        country: keys.SCOTLAND,
        countryValue: keysEnglish.SCOTLAND,
        value: keysEnglish.STIRLING_THE_TROSSACHS,
        label: keys.STIRLING_THE_TROSSACHS,
      },
    ].sort((a, b) => {
      if (a.label === keys.SCOTLAND_WIDE || b.label === keys.SCOTLAND_WIDE)
        return 1;
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    }),
  },
  {
    label: keys.NORTHERN_IRELAND,
    options: [
      {
        country: keys.NORTHERN_IRELAND,
        countryValue: keysEnglish.NORTHERN_IRELAND,
        label: keys.NORTHERN_IRELAND_WIDE,
        value: keysEnglish.NORTHERN_IRELAND_WIDE,
      },
      {
        country: keys.NORTHERN_IRELAND,
        countryValue: keysEnglish.NORTHERN_IRELAND,
        label: keys.ANTRIM,
        value: keysEnglish.ANTRIM,
      },
      {
        country: keys.NORTHERN_IRELAND,
        countryValue: keysEnglish.NORTHERN_IRELAND,
        label: keys.ARMAGH,
        value: keysEnglish.ARMAGH,
      },
      {
        country: keys.NORTHERN_IRELAND,
        countryValue: keysEnglish.NORTHERN_IRELAND,
        label: keys.DOWN,
        value: keysEnglish.DOWN,
      },
      {
        country: keys.NORTHERN_IRELAND,
        countryValue: keysEnglish.NORTHERN_IRELAND,
        label: keys.FERMANAGH,
        value: keysEnglish.FERMANAGH,
      },
      {
        country: keys.NORTHERN_IRELAND,
        countryValue: keysEnglish.NORTHERN_IRELAND,
        label: keys.LONDONDERRY,
        value: keysEnglish.LONDONDERRY,
      },
      {
        country: keys.NORTHERN_IRELAND,
        countryValue: keysEnglish.NORTHERN_IRELAND,
        label: keys.TYRONE,
        value: keysEnglish.TYRONE,
      },
    ].sort((a, b) => {
      if (
        a.label === keys.NORTHERN_IRELAND_WIDE ||
        b.label === keys.NORTHERN_IRELAND_WIDE
      )
        return 1;
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    }),
  },
];

const regionsValuesMapWelsh = {};
Object.keys(keysEnglish).forEach((e) => {
  regionsValuesMapWelsh[keysEnglish[e]] = keys[e];
});

export { keys, regionsValuesMapWelsh };
export default regionsDropDownWelsh;
