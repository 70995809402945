import React from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { GridWlecome } from "./style";
import { H24Caps, H16, Body16R } from "../../components/Typograpy";
import Image from "../../components/Image";
import translate from "../../utils/helpers/translator";

const WelcomeSection = ({ fullWidth, lang }) => {
  const width = fullWidth ? "100%" : "70%";

  const translateSection = (string) => {
    const text = translate(string, lang).split(" | ");

    return (
      <Body16R
        style={{ textAlign: "center", marginBottom: "20px" }}
        color="gray3"
      >
        <H16 color="midnight" style={{ display: "inline", marginRight: 5 }}>
          {text[0]}
        </H16>
        {text[1]}
      </Body16R>
    );
  };

  return (
    <GridWlecome>
      <Grid container style={{ width }}>
        <Grid
          container
          item
          justify="center"
          alignItems="flex-start"
          style={{ width: "260px", margin: "0 auto", marginBottom: "30px" }}
        >
          <Image image="welcomeMember1" alt="tempo logo" />
        </Grid>
        <Grid>
          <Grid>
            <H24Caps
              color="primary"
              style={{
                textAlign: "center",
                lineHeight: "24px",
                marginBottom: "20px",
              }}
            >
              {translate("welcomeToTC", lang)}!
            </H24Caps>
          </Grid>
          <Grid>{translateSection("earnTempoTCs")}</Grid>
          <Grid>{translateSection("spendTempoTCs")}</Grid>
        </Grid>
      </Grid>
    </GridWlecome>
  );
};

const mapStateToProps = (state) => ({
  lang: state.auth.language,
});

export default connect(mapStateToProps, null)(WelcomeSection);
