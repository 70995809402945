import { fields, createSchema, validate as _validate } from "..";

const {
  optionalPhoneNumber,
  textRequired,
  requiredText,
  optionalText,
  focusFields,
  focusFieldsOptional,
  booleanValue,
  url,
  socialLinks,
  arrRequired,
  addresses,
  addressesOptional,
  activitiesRoles,
  addressesWelsh,
  addressesWelshOptional,
  activitiesRolesWelsh,
  arrOptional,
  optionalEmail,
} = fields;

const basic = {
  contactEmail: optionalEmail,
  contactNumber: optionalPhoneNumber,
  description: requiredText(300),
  getInTouchDescription: requiredText(300),
  focusFields,
  isOnline: booleanValue,
  isPublic: booleanValue,
  isPrivateAddress: booleanValue,
  name: textRequired,
  socialLinks,
  websiteUrl: url,
  publicActivities: arrRequired,
  coverImageKey: textRequired,
  logoImageKey: textRequired,
  addresses,
  activitiesRoles,
  keywords: arrOptional,
};

const basicDraft = {
  name: textRequired,
  focusFields: focusFieldsOptional,
  addresses: addressesOptional,
  description: optionalText(300),
  keywords: arrOptional,
  contactNumber: optionalPhoneNumber,
  contactEmail: optionalEmail,
  getInTouchDescription: optionalText(300),
  websiteUrl: url,
  socialLinks,
  logoImageKey: optionalText(),
  coverImageKey: optionalText(),
  publicActivities: arrOptional,

  isOnline: booleanValue,
  isPublic: booleanValue,
  isPrivateAddress: booleanValue,
  activitiesRoles,
};

const basicWelsh = {
  ...basic,
  nameWelsh: textRequired,
  descriptionWelsh: requiredText(300),
  getInTouchDescriptionWelsh: requiredText(300),
  addresses: addressesWelsh,
  activitiesRoles: activitiesRolesWelsh,
  keywords: arrOptional,
};

const basicWelshDraft = {
  ...basicDraft,
  nameWelsh: textRequired,
  descriptionWelsh: optionalText(300),
  getInTouchDescriptionWelsh: optionalText(300),
  addresses: addressesWelshOptional,
  activitiesRoles: activitiesRolesWelsh,
  keywords: arrOptional,
};

const recruitVolunteers = createSchema(basic);

const recruitVolunteersAdmin = createSchema(basic);
const recruitVolunteersAdminDraft = createSchema(basicDraft);

const recruitVolunteersWelsh = createSchema({ ...basic, ...basicWelsh });
const recruitVolunteersWelshDraft = createSchema({ ...basicWelshDraft });

const validate = (data) => {
  if (data.admin)
    return _validate(
      data.isPublic ? recruitVolunteersAdmin : recruitVolunteersAdminDraft,
      data,
    );
  return _validate(recruitVolunteers, data);
};

export const validateWelsh = (data) =>
  _validate(
    data.isPublic ? recruitVolunteersWelsh : recruitVolunteersWelshDraft,
    data,
  );
export const validateAddress = (_addresses) =>
  _validate(createSchema({ addresses }), {
    isOnline: false,
    addresses: _addresses,
  });

export default validate;
