import React from "react";

const CheckboxChecked = ({ width, height, color, ...props }) => (
  <svg
    width={width}
    height={height}
    {...props}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.75 0.25H3.25C2.45462 0.250869 1.69206 0.567218 1.12964 1.12964C0.567218 1.69206 0.250869 2.45462 0.25 3.25V16.75C0.250869 17.5454 0.567218 18.3079 1.12964 18.8704C1.69206 19.4328 2.45462 19.7491 3.25 19.75H16.75C17.5454 19.7491 18.3079 19.4328 18.8704 18.8704C19.4328 18.3079 19.7491 17.5454 19.75 16.75V3.25C19.7491 2.45462 19.4328 1.69206 18.8704 1.12964C18.3079 0.567218 17.5454 0.250869 16.75 0.25ZM15.0742 6.73234L8.77422 14.2323C8.70511 14.3147 8.61912 14.3812 8.52207 14.4273C8.42502 14.4735 8.31918 14.4983 8.21172 14.5H8.19906C8.09394 14.5 7.99 14.4778 7.89398 14.435C7.79797 14.3922 7.71202 14.3297 7.64172 14.2516L4.94172 11.2516C4.87315 11.1788 4.81981 11.0931 4.78483 10.9995C4.74986 10.9059 4.73395 10.8062 4.73805 10.7063C4.74215 10.6064 4.76617 10.5084 4.8087 10.4179C4.85124 10.3275 4.91142 10.2464 4.98572 10.1796C5.06002 10.1127 5.14694 10.0614 5.24136 10.0286C5.33579 9.99578 5.43581 9.98219 5.53556 9.98861C5.63531 9.99502 5.73277 10.0213 5.82222 10.0659C5.91166 10.1106 5.99128 10.1726 6.05641 10.2484L8.17938 12.6072L13.9258 5.76766C14.0547 5.61863 14.237 5.52631 14.4335 5.51066C14.6299 5.49501 14.8246 5.55728 14.9754 5.68402C15.1263 5.81075 15.2212 5.99176 15.2397 6.18793C15.2582 6.3841 15.1988 6.57966 15.0742 6.73234Z"
      fill="#201751"
    />
  </svg>
);
export default CheckboxChecked;
