import { fields, createSchema, validate as _validate } from "..";

const {
  textRequired,
  optionalText,
  requiredText,
  addressFieldRequired,
  cityFieldRequired,
  addressFieldOptional,
  cityFieldOptional,
  booleanValue,
  urlRequired,
  url,
  optionalEmail,
  optionalPhoneNumber,
  postcodeRequired,
  postcodeOptional,
  county,
} = fields;

const spendVenueStandardFields = {
  name: textRequired,
  contactNumber: optionalPhoneNumber,
  description: requiredText(300),
  isOnline: booleanValue,
  addressLine1: addressFieldRequired,
  addressLine2: addressFieldOptional,
  city: cityFieldRequired,
  county,
  region: addressFieldRequired,
  postcode: postcodeRequired,
  websiteUrl: urlRequired,
  coverImageKey: textRequired,
  logoImageKey: textRequired,
  contactEmail: optionalEmail,
};

const spendVenueStandardFieldsDraft = {
  name: textRequired,
  addressLine1: addressFieldOptional,
  addressLine2: addressFieldOptional,
  city: cityFieldOptional,
  county,
  region: addressFieldOptional,
  postcode: postcodeOptional,
  contactNumber: optionalPhoneNumber,
  contactEmail: optionalEmail,
  description: optionalText(300),
  logoImageKey: optionalText(),
  coverImageKey: optionalText(),
  websiteUrl: url,
  isOnline: booleanValue,
};

const spendVenueWelshFields = {
  nameWelsh: textRequired,
  addressLine1Welsh: addressFieldRequired,
  addressLine2Welsh: addressFieldOptional,
  cityWelsh: cityFieldRequired,
  countyWelsh: county,
  descriptionWelsh: textRequired,
};

const spendVenueWelshFieldsDraft = {
  nameWelsh: textRequired,
  addressLine1Welsh: addressFieldOptional,
  addressLine2Welsh: addressFieldOptional,
  cityWelsh: cityFieldOptional,
  countyWelsh: county,
  descriptionWelsh: optionalText(),
};

const spendVenue = createSchema({
  ...spendVenueStandardFields,
});
const spendVenueDraft = createSchema({
  ...spendVenueStandardFieldsDraft,
});

const spendVenueWelsh = createSchema({
  ...spendVenueStandardFields,
  ...spendVenueWelshFields,
});

const spendVenueWelshDraft = createSchema({
  ...spendVenueStandardFieldsDraft,
  ...spendVenueWelshFieldsDraft,
});

const validate = (data) =>
  _validate(data.isPublic ? spendVenue : spendVenueDraft, data);
const validateWelsh = (data) =>
  _validate(data.isPublic ? spendVenueWelsh : spendVenueWelshDraft, data);

export { validate, validateWelsh };
