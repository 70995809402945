import {
  SIGN_UP_OFFLINE_LOADING_START,
  SIGN_UP_OFFLINE_LOADING_END,
  SIGN_UP_OFFLINE_SUCCESS,
  SIGN_UP_OFFLINE_FAIL,
  SIGN_UP_OFFLINE_CLOSE_MODAL,
} from "../../../redux/types";

const initialState = {
  loading: false,
  openErrorModal: false,
  membershipId: "",
  error: {},
};

const inviteReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_UP_OFFLINE_LOADING_START:
      return { loading: true };

    case SIGN_UP_OFFLINE_LOADING_END:
      return { loading: false };

    case SIGN_UP_OFFLINE_SUCCESS:
      return {
        ...state,
        membershipId: action.payload.profile.membershipId,
      };

    case SIGN_UP_OFFLINE_FAIL:
      return { error: action.payload, openErrorModal: true };

    case SIGN_UP_OFFLINE_CLOSE_MODAL:
      return { error: {}, openErrorModal: false };

    default:
      return state;
  }
};

export default inviteReducer;
