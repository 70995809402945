const keys = {
  NATIONAL: "national",
  REGIONAL: "regional",
  LOCAL: "local",
};

const keysWelsh = {
  NATIONAL: "w - national",
  REGIONAL: "w- regional",
  LOCAL: "w- local",
};

const locationTypesDropdown = Object.entries(keys).map(([key, value]) => ({
  label: value,
  value: keys[key],
}));

const locationTypesDropdownWelsh = Object.entries(keysWelsh).map(
  ([key, value]) => ({
    label: value,
    value: keys[key],
  }),
);

const locationTypesValuesMapWelsh = {};
Object.keys(keys).forEach((e) => {
  locationTypesValuesMapWelsh[keys[e]] = keysWelsh[e];
});

// TODO: handle for dropdown
export {
  keys,
  keysWelsh,
  locationTypesDropdown,
  locationTypesDropdownWelsh,
  locationTypesValuesMapWelsh,
};
