import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Row, Col } from "../../../../components/Grid";
import { InputField } from "../../../../components/Inputs";
import Button from "../../../../components/Button";
import Notification from "../../../../components/Notification";
import GoBack from "../../../../components/GoBack";
import { roles } from "../../../../constants";
import * as T from "../../../../components/Typograpy";
import stripLeadingTab from "../../../../utils/helpers/stripLeadingTab";
import Modal from "../../../../components/Modal";

import AdministratorSection from "../../../../components/AdministratorSection";

import validate from "../../../../validation/schemas/earnGroupAdmin";

import { fields, getInitState } from "./data";
import * as actions from "../../../../redux/actions/userAccount";
import cleanWhitespace from "../../../../utils/helpers/cleanWhitespace";
import t from "../../../../utils/helpers/translator";

const { firstName, lastName, email, phoneNumber } = fields;

const EarnGroupAccount = ({
  isMobile,
  getUserAccount,
  updateUserAccount,
  userAccount,
  updateAccountLoading,
  userAccountError,
}) => {
  const [state, setState] = useState(getInitState(userAccount));
  const [btnOn, setBtnOn] = useState(false);
  const [errors, setErrors] = useState({});
  const [showMsg, setShowMsg] = useState(false);
  const [showConfirmPasswordModal, setShowConfirmPasswordModal] = useState();

  const { id } = useParams();

  // get user account details
  useEffect(() => {
    getUserAccount(id, roles.EARN_GROUP);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (userAccount) {
      setState(
        getInitState({
          ...userAccount,
          phoneNumber: stripLeadingTab(
            userAccount.phoneNumber?.replace(/ /g, ""),
          ),
        }),
      );
    }
  }, [userAccount]);

  const onChange = (e, _setState, _state) => {
    setBtnOn(true);
    const { name, value } = e.target;
    const cleanEmail =
      name === "email" && value.toLowerCase().replace(/ /g, "").trim();

    switch (name) {
      case "email":
        _setState({ ..._state, email: cleanEmail });
        break;
      case "phoneNumber":
        _setState({ ..._state, [name]: value.trim() });
        break;
      default:
        _setState({ ..._state, [name]: value });
        break;
    }
  };

  const onSubmit = async (e, confirmed) => {
    e.preventDefault();
    try {
      validate({
        ...state,
        phoneNumber: stripLeadingTab(state.phoneNumber?.replace(/ /g, "")),
        role: roles.EARN_GROUP,
        isAdmin: true,
      });
      await updateUserAccount(
        {
          ...state,
          firstName: cleanWhitespace(state.firstName),
          lastName: cleanWhitespace(state.lastName),
          organisationName: undefined,
          staffSwitchAccountConfirmation: confirmed,
        },
        id,
        roles.EARN_GROUP,
      );

      if (showConfirmPasswordModal) {
        setShowConfirmPasswordModal(false);
        getUserAccount(id, roles.EARN_GROUP);
      }

      setShowMsg(true);
      setBtnOn(false);
      setErrors({});
    } catch (err) {
      if (err.name === "ValidationError") {
        setErrors(err.inner);
      } else {
        setErrors({ serverError: err.message });
      }
      setBtnOn(false);
    }
  };

  const showNotification = (message) => (
    <Notification
      open={showMsg}
      setOpen={setShowMsg}
      message={message}
      duration={2500}
    />
  );

  useEffect(() => {
    if (userAccountError?.message === "multipleAccountConfirmationRequired") {
      setShowConfirmPasswordModal(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccountError?.message]);

  return (
    <>
      {/* Account details */}
      <Row jc="space-between" mb={8}>
        <Col w={[4, 6, 7]}>
          <GoBack showText />
          <T.H40Caps color="midnight" mt="30">
            {state.organisationName}
          </T.H40Caps>
          <T.H30Caps color="pink" mt="20">
            account settings
          </T.H30Caps>
        </Col>
      </Row>

      <Row mb={3}>
        <Col w={[4, 8, 8]}>
          <T.H24 color="midnight">Main Account Holder Details</T.H24>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 8, 8]}>
          <T.Body16R color="gray3">
            This is for internal use. Please note that changing the email
            address wil change the email address they need to log in with, so
            make sure to notify the Earn Group.
          </T.Body16R>
        </Col>
      </Row>

      {/* Fields */}

      <Row>
        <Col w={[4, 4, 4]}>
          <InputField
            mt={!isMobile ? 6 : 4}
            label={firstName.label}
            type={firstName.type}
            placeholder={firstName.placeholder}
            name={firstName.name}
            setValue={(e) => onChange(e, setState, state)}
            value={state.firstName || ""}
            error={errors.firstName}
          />
        </Col>
        <Col w={[4, 4, 4]}>
          <InputField
            mt={!isMobile ? 6 : 4}
            label={lastName.label}
            type={lastName.type}
            placeholder={lastName.placeholder}
            name={lastName.name}
            setValue={(e) => onChange(e, setState, state)}
            value={state.lastName || ""}
            error={errors.lastName}
          />
        </Col>
      </Row>
      <Row>
        <Col w={[4, 4, 4]}>
          <InputField
            mt={6}
            label={email.label}
            placeholder={email.placeholder}
            name={email.name}
            setValue={(e) => onChange(e, setState, state)}
            value={state.email || ""}
            error={errors.email}
          />
        </Col>
        <Col w={[4, 4, 4]}>
          <InputField
            mt={!isMobile ? 6 : 4}
            label={phoneNumber.label}
            type={phoneNumber.type}
            placeholder={phoneNumber.placeholder}
            name={phoneNumber.name}
            setValue={(e) => onChange(e, setState, state)}
            value={state.phoneNumber || ""}
            error={errors[phoneNumber.name]}
          />
        </Col>
      </Row>
      <Row
        style={{
          marginTop: !isMobile ? "40px" : "2rem",
        }}
      >
        <Col w={[4, 4, 4]}>
          <Button
            primary
            size="l"
            type="submit"
            handleClick={onSubmit}
            loading={updateAccountLoading}
            disabled={!btnOn}
          >
            Save changes
          </Button>
          {showNotification("Changes saved")}
        </Col>
      </Row>
      {errors.serverError && (
        <Row mt={2}>
          <Col w={[4, 8, 8]}>
            <T.Body16R color="pink">
              {t(errors.serverError, "english")}
            </T.Body16R>
          </Col>
        </Row>
      )}
      <AdministratorSection earnGroupUserId={id} />
      <Modal
        modalOpen={showConfirmPasswordModal}
        handleCloseModal={() => setShowConfirmPasswordModal(false)}
        confirmFun={(e) => onSubmit(e, true)}
        buttonText="Confirm"
        withCancel
        width="640px"
        keepOpenOnConfirm
        loading={userAccount.updateAccountLoading}
      >
        <T.H30 color="blue" m="0" mb="25">
          {t("areYouSure", "english")}
        </T.H30>

        <T.Body16B color="midnight">
          {t("thisEmailIsLinked", "english", [userAccount?.error?.data?.role])}
        </T.Body16B>
        <T.Body16R mt="20">
          Click confirm if you want to switch this account to{" "}
          <T.Body16B>{state.email}</T.Body16B>
        </T.Body16R>
        <T.Body16B mt="20">
          Note:
          <T.Body16R>
            This current account holder&rsquo;s name will be updated to the new
            account {`(${state.email})`}
          </T.Body16R>
        </T.Body16B>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  role: state.auth.role,
  userAccount: state.userAccount.data,
  getAccountLoading: state.userAccount.getAccountLoading,
  updateAccountLoading: state.userAccount.updateAccountLoading,
  userAccountError: state.userAccount.error,
});

const mapActionToProps = {
  getUserAccount: actions.getUserAccount,
  updateUserAccount: actions.updateUserAccount,
};

export default connect(mapStateToProps, mapActionToProps)(EarnGroupAccount);
