import React from "react";

const PracticalHelp = ({ width, height, color, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    {...props}
  >
    <path
      d="M21.942 17.333a1.778 1.778 0 00-2.246-1.118 5.89 5.89 0 00-5.175-3.757v-1.87c4.11.656 6.878-2.466 6.035-6.448a.418.418 0 00-.321-.325c-2.33-.489-4.629.217-5.823 2.237-.571-3.047-3.339-4.554-6.44-3.902a.417.417 0 00-.32.32c-.836 3.978 1.919 7.096 6.034 6.453v3.552a6.01 6.01 0 00-3.84 1.703c-.567-.08-.576-.08-3.672-.08v-.417a.417.417 0 00-.418-.417H2.417A.417.417 0 002 13.68v6.678a.417.417 0 00.417.417h3.34a.417.417 0 00.417-.417c0-.146-.038-.138.12-.046 5.91 3.385 10.585.88 14.671-.789a1.786 1.786 0 00.977-2.19zm-2.15-12.75c.534 3.2-1.586 5.542-4.77 5.234l2.892-2.893a.418.418 0 00-.593-.588l-2.8 2.788c-.163-3.072 2.166-5.042 5.271-4.541zM8.42 2.913c3.18-.534 5.547 1.586 5.23 4.783l-2.763-2.78a.418.418 0 00-.593.59l2.65 2.65c-3.059.183-5.046-2.138-4.524-5.243zM18.9 16.457l-1.04.325a1.67 1.67 0 00-1.669-1.849c-5.885 0-3.756.159-5.396-.488 2.533-2.058 6.715-1.356 8.105 2.012zM5.34 19.942H2.835v-5.843h2.504v5.843zm15.305-1.186c-5.927 2.396-8.844 3.732-14.47.526v-4.349c2.75 0 3.777-.18 5.087.693.293.196.113.142 4.93.142a.835.835 0 01.638 1.373c-.46.418-.109.297-5.23.297a.417.417 0 100 .834c4.704 0 5.092.155 5.74-.459 2.92-.897 2.733-.834 2.92-.834a.943.943 0 01.385 1.777z"
      fill={color || "currentColor"}
    />
  </svg>
);
export default PracticalHelp;
