import colors from "./colors";

export default {
  typography: {
    fontFamily: "Poppins",
    h1: {
      fontSize: "3.75rem", // 60px
      fontWeight: 900,
      lineHeight: "3.75rem", // 60px
    },
    h2: {
      fontSize: "2.5rem", // 40px
      fontWeight: 900,
      lineHeight: "2.5rem", // 40px
    },
    h3: {
      fontSize: "1.875rem", // 30px
      fontWeight: 900,
      lineHeight: "1.875rem", // 30px
    },
    h4: {
      fontSize: "1.5", // 24px
      fontWeight: 900,
      lineHeight: "2.1875", // 35px
    },
    h5: {
      fontSize: "1.375rem", // 22px
      fontWeight: 900,
      lineHeight: "2.1875", // 35px
    },
    h6: {
      fontSize: "1rem", // 16px
      fontWeight: "bold",
      lineHeight: "1.5625", // 25px
    },
    subtitle1: {
      fontSize: "1.67rem", // not using
      fontWeight: 900,
    },
    subtitle2: {
      fontSize: "1.5rem", // not using
      fontWeight: 900,
    },
    body1: {
      fontSize: "1rem", // 16px
      fontWeight: "normal",
      lineHeight: "1.5625", // 25px
    },
    body2: {
      fontSize: "1.25",
      fontWeight: 900,
    },
    caption: {
      fontSize: "1.67rem", // not using
      fontWeight: "normal",
    },
    brackets: {
      fontSize: "0.875rem", // 14px
      lineHeight: "1.25", // 20px
      fontWeight: "normal",
    },
    placeholder: {
      fontSize: "0.875rem",
      fontWeight: "400",
      color: colors.gray3,
      display: "flex",
      alignItems: "center",
      opacity: "1",
    },
  },
};
