import { fields, createSchema, validate as _validate } from "..";

import { roles } from "../../constants";

const { MEMBER, EARN_GROUP, SPEND_VENUE, PROGRAMME_MANAGER, SPEND_PARTNER } =
  roles;

const {
  email,
  firstName,
  lastName,
  optionalPhoneNumber,
  organisationName,
  organisationType,
  focusFields,
  region,
  localAuthority,
  confirmationPassword,
} = fields;

const spendVenueAccount = createSchema({
  email,
});

const memberAccount = createSchema({
  firstName,
  lastName,
  email,
});

const programmeManagerAccount = createSchema({
  firstName,
  lastName,
  email,
});

const earnGroupAccount = createSchema({
  firstName,
  lastName,
  email,
  organisationName,
  organisationType,
  region,
  focusFields,
  localAuthority,
  phoneNumber: optionalPhoneNumber,
  password: confirmationPassword,
});

const spendPartner = createSchema({
  firstName,
  lastName,
  email,
});

const validate = (data) => {
  switch (data.role) {
    case SPEND_VENUE:
      return _validate(spendVenueAccount, data);
    case MEMBER:
      return _validate(memberAccount, data);
    case PROGRAMME_MANAGER:
      return _validate(programmeManagerAccount, data);
    case EARN_GROUP:
      return _validate(earnGroupAccount, data);
    case SPEND_PARTNER:
      return _validate(spendPartner, data);
    default:
      return null;
  }
};

export default validate;
