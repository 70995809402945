import React from "react";

import { InputField } from "../Inputs";
import Button from "../Button";

import * as S from "./style";

const Search = ({ handleSearch, search, setSearch, loading }) => (
  <S.SearchWrapper>
    <InputField setValue2={setSearch} value={search} placeholder="Search..." />
    <Button
      type="submit"
      onClick={handleSearch}
      style={{ width: "200px", marginLeft: "10px" }}
      loading={loading}
    >
      Search
    </Button>
  </S.SearchWrapper>
);
export default Search;
