import {
  GET_EARN_GROUPS_ADMINS_LOADING_START,
  GET_EARN_GROUPS_ADMINS_LOADING_END,
  GET_EARN_GROUPS_ADMINS_SUCCESS,
  GET_EARN_GROUPS_ADMINS_FAIL,
  ADD_EARN_GROUP_ADMINISTRATOR_LOADING_START,
  ADD_EARN_GROUP_ADMINISTRATOR_LOADING_END,
  ADD_EARN_GROUP_ADMINISTRATOR_SUCCESS,
  ADD_EARN_GROUP_ADMINISTRATOR_FAIL,
  DELETE_ADMINISTRATOR_USER_LOADING_START,
  DELETE_ADMINISTRATOR_USER_LOADING_END,
  DELETE_ADMINISTRATOR_USER_SUCCESS,
  DELETE_ADMINISTRATOR_USER_FAIL,
  CHANGE_MAIN_ACCOUNT_LOADING,
  CHANGE_MAIN_ACCOUNT_SUCCESS,
  CHANGE_MAIN_ACCOUNT_FAIL,
  EARN_GROUP_ACCOUNT_HOLDER_LOGS_LOADING,
  EARN_GROUP_ACCOUNT_HOLDER_LOGS_SUCCESS,
  EARN_GROUP_ACCOUNT_HOLDER_LOGS_FAIL,
} from "../../redux/types";

const initState = {
  admins: [],
  getLoading: true,
  getError: null,
  addLoading: false,
  addError: null,
  deactivateLoading: false,
  deactivateError: null,
};

const accessPermissions = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_EARN_GROUPS_ADMINS_LOADING_START:
      return {
        ...state,
        getLoading: true,
        getError: null,
      };
    case GET_EARN_GROUPS_ADMINS_LOADING_END:
      return {
        ...state,
        getLoading: false,
      };
    case GET_EARN_GROUPS_ADMINS_SUCCESS:
      return {
        ...state,
        admins: payload,
        getLoading: false,
      };
    case GET_EARN_GROUPS_ADMINS_FAIL:
      return {
        ...state,
        getLoading: false,
        getError: payload,
      };

    case ADD_EARN_GROUP_ADMINISTRATOR_LOADING_START:
      return {
        ...state,
        addLoading: true,
        addError: null,
      };
    case ADD_EARN_GROUP_ADMINISTRATOR_LOADING_END:
      return {
        ...state,
        addLoading: false,
      };
    case ADD_EARN_GROUP_ADMINISTRATOR_SUCCESS:
      return {
        ...state,
        admins:
          payload && payload.id ? [...state.admins, payload] : state.admins,
        addLoading: false,
      };
    case ADD_EARN_GROUP_ADMINISTRATOR_FAIL:
      return {
        ...state,
        addLoading: false,
        addError: payload,
      };

    case DELETE_ADMINISTRATOR_USER_LOADING_START:
      return {
        ...state,
        deactivateLoading: true,
        deactivateError: null,
        getLoading: true,
      };
    case DELETE_ADMINISTRATOR_USER_LOADING_END:
      return {
        ...state,
        getLoading: false,
        deactivateLoading: false,
      };
    case DELETE_ADMINISTRATOR_USER_SUCCESS:
      return {
        ...state,
        admins: state.admins.filter((i) => i.id !== payload),
        getLoading: false,
        deactivateLoading: false,
      };
    case DELETE_ADMINISTRATOR_USER_FAIL:
      return {
        ...state,
        deactivateLoading: false,
        getLoading: false,
        deactivateError: payload,
      };

    case CHANGE_MAIN_ACCOUNT_LOADING:
      return {
        ...state,
        getLoading: true,
      };
    case CHANGE_MAIN_ACCOUNT_SUCCESS:
      return {
        ...state,
        getLoading: false,
        changeAccountSuccess: true,
      };
    case CHANGE_MAIN_ACCOUNT_FAIL:
      return {
        ...state,
        getLoading: false,
        promoteError: payload,
      };
    default:
      return state;
  }
};

const egLogsInitState = {
  data: [],
  loading: false,
  error: null,
};

export const earnGroupAccountHolderLogs = (state = egLogsInitState, action) => {
  const { type, payload } = action;

  switch (type) {
    case EARN_GROUP_ACCOUNT_HOLDER_LOGS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EARN_GROUP_ACCOUNT_HOLDER_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload,
      };
    case EARN_GROUP_ACCOUNT_HOLDER_LOGS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default accessPermissions;
