import React, { useEffect, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";

import * as S from "./style";
import Image from "../../../../components/Image";
import * as T from "../../../../components/Typograpy";
import Stats from "../../../../components/Stats";
import Table from "../../../../components/FilterTable";

import { TEMPO, GENERAL } from "../../../../constants/navRoutes";
import Search from "../../../../components/Search";

import { getInsights } from "../insights.action";
import { getOffers } from "./actions";

const tempoOffers = {
  columns: [
    {
      title: "name",
      field: "activityName",
      disableClick: true,
      render: (rowData) => (
        <Link to={GENERAL.SPEND_ACTIVITY.replace(":id", rowData?.activityId)}>
          <T.LinkB14 color="blue">{rowData?.activityName}</T.LinkB14>
        </Link>
      ),
    },
    {
      title: "venue / earn group",
      field: "spendVenueName",
      disableClick: true,
      render: (rowData) => (
        <Link
          to={
            rowData.isEarnGroup
              ? TEMPO.EARN_GROUP_SINGLE.replace(":id", rowData?.spendVenueId)
              : TEMPO.SPEND_VENUES_SINGLE.replace(":id", rowData?.spendVenueId)
          }
        >
          <T.LinkB14 color="blue">{rowData?.spendVenueName}</T.LinkB14>
        </Link>
      ),
    },
    {
      title: "status",
      field: "status",
      render: (rowData) => (
        <S.CapitalizedSpan>{rowData?.status}</S.CapitalizedSpan>
      ),
    },
    {
      title: "availability",
      field: "availability",
      render: (rowData) => (
        <S.CapitalizedSpan>{rowData?.availability}</S.CapitalizedSpan>
      ),
    },
  ],
};

const Offers = ({
  staffOffersData,
  liveOffers,
  soldOutOffers,
  draftOffers,
  getInsightsAction,
  getOffersAction,
  insightsLoading,
}) => {
  const history = useHistory();
  useEffect(() => {
    getInsightsAction({ category: "offers" });
    getOffersAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const search = useRef(""); // useRef to prevent page re-render on every change

  const clickRow = (event, rowData) =>
    history.push(GENERAL.SPEND_ACTIVITY.replace(":id", rowData?.activityId));
  const statsNum = [liveOffers, draftOffers, soldOutOffers];
  const statsText = ["Live offers", "Draft offers", "Sold out offers"];

  const handleSearch = (e) => {
    e.preventDefault();

    getOffersAction({ query: search.current });
  };

  const setSearch = (value) => {
    search.current = value;
  };

  return (
    <S.Container>
      <div>
        <S.Head>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <T.H40Caps color="primary" style={{ margin: 0 }}>
              offers
            </T.H40Caps>
            <div style={{ display: "flex" }}>
              {statsText.map((e, i) => (
                <div key={e} style={{ marginTop: 50, marginRight: 15 }}>
                  <Stats
                    size="s"
                    statNum={statsNum[i]}
                    statNumColor="pink"
                    statText={e}
                    isLoading={insightsLoading}
                  />
                </div>
              ))}
            </div>
          </div>
          <S.ImageWrapper>
            <Image image="gallery" alt="gallery" height={210} width={220} />
          </S.ImageWrapper>
        </S.Head>
        <S.TableContainer>
          <S.SearchWrapper>
            <Search
              handleSearch={handleSearch}
              // search={search}
              setSearch={setSearch}
              loading={staffOffersData.loading}
            />
          </S.SearchWrapper>

          {!staffOffersData.loading && (
            <Table
              color="blue"
              data={staffOffersData?.data || []}
              columns={tempoOffers.columns}
              onRowClick={clickRow}
              hideSearch
            />
          )}
        </S.TableContainer>
      </div>
    </S.Container>
  );
};

const mapStateToProps = (state) => ({
  ...state.staffInsights,
  staffOffersData: state.staffOffers,
  insightsLoading: state.staffInsights.loading,
});

const mapActionsToProps = {
  getInsightsAction: getInsights,
  getOffersAction: getOffers,
};

export default connect(mapStateToProps, mapActionsToProps)(Offers);
