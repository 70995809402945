import {
  MOBILE_S_WIDTH,
  MOBILE_M_WIDTH,
  MOBILE_L_WIDTH,
  MOBILE_XL_WIDTH,
  TABLET_WIDTH,
  LAPTOP_WIDTH,
  LAPTOP_L_WIDTH,
  DESKTOP_WIDTH,
} from "../../constants/screenWidths";

export const screenWidths = {
  mobileS: `${MOBILE_S_WIDTH}px`,
  mobileM: `${MOBILE_M_WIDTH}px`,
  mobileL: `${MOBILE_L_WIDTH}px`,
  mobileXL: `${MOBILE_XL_WIDTH}px`,
  tablet: `${TABLET_WIDTH}px`,
  laptop: `${LAPTOP_WIDTH}px`,
  laptopL: `${LAPTOP_L_WIDTH}px`,
  desktop: `${DESKTOP_WIDTH}px`,
};

export const breakpoints = {
  mobileS: `@media (min-width: ${screenWidths.mobileS})`,
  mobileM: `@media (min-width: ${screenWidths.mobileM})`,
  mobileL: `@media (min-width: ${screenWidths.mobileL})`,
  mobileXL: `@media (min-width: ${screenWidths.mobileXL})`,
  tablet: `@media (min-width: ${screenWidths.tablet})`,
  laptop: `@media (min-width: ${screenWidths.laptop})`,
  laptopL: `@media (min-width: ${screenWidths.laptopL})`,
  desktop: `@media (min-width: ${screenWidths.desktop})`,
  desktopL: `@media (min-width: ${screenWidths.desktop})`,
};
