import React from "react";
import Proptypes from "prop-types";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { connect } from "react-redux";
import { toggleUIcolor } from "../../../redux/actions/userActions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: 250,
    color: theme.colors.purple,
  },
  title: {
    margin: 50,
  },
}));

const Landing = (props) => {
  const classes = useStyles();
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid container item justify="center" className={classes.title}>
        <Typography variant="h4">Hi.. welcome to Tempo website</Typography>
      </Grid>

      <Grid
        container
        item
        md={12}
        justify="center"
        alignContent="center"
        direction="column"
      >
        <Typography variant="h3" color="secondary">
          change the header color
        </Typography>
        <Button
          variant="contained"
          style={{ position: "static" }}
          onClick={() => {
            props.changeHeaderColor("#00A9CE");
          }}
        >
          Cyan
        </Button>
        <Button
          variant="contained"
          style={{ position: "static" }}
          onClick={() => {
            props.changeHeaderColor("#0057A3");
          }}
        >
          blue
        </Button>
        <Button
          variant="contained"
          style={{ position: "static" }}
          onClick={() => {
            props.changeHeaderColor("#FFC709");
          }}
        >
          yellow
        </Button>
        <Button
          variant="contained"
          style={{ position: "static" }}
          onClick={() => {
            props.changeHeaderColor("#D91473");
          }}
        >
          Pink
        </Button>
      </Grid>
    </Grid>
  );
};

const mapActionToProps = {
  changeHeaderColor: toggleUIcolor,
};

Landing.propTypes = {
  changeHeaderColor: Proptypes.func.isRequired,
};

export default connect(null, mapActionToProps)(Landing);
