import { styled } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

export const Wrapper = styled("section")(({ theme }) => ({
  [theme.breakpoints.tablet]: {
    paddingLeft: "275px",
    paddingRight: theme.spacing(6),
  },
}));

export const Header = styled("header")(({ theme }) => ({
  maxWidth: "550px",
  paddingTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
}));

export const ImageWrapper = styled("span")(({ theme }) => ({
  display: "none",

  [theme.breakpoints.tablet]: {
    display: "block",
    position: "absolute",
    right: theme.spacing(4),
    top: theme.spacing(9),
    zIndex: -1,
  },
}));

export const ActionWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  boxShadow: theme.customShadows.card,
  padding: theme.spacing(5),
  marginBottom: theme.spacing(8),
}));

export const Actions = styled("div")(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(5),
  flexWrap: "flex",
}));

export const PopperContainer = styled("div")(() => ({
  overflow: "visible",
  zIndex: 900,
}));

export const StyledModal = styled(Modal)(() => ({}));

export const Body = styled("div")(() => ({
  width: "80%",
}));

export const Container = styled("div")(({ theme, width, position }) => ({
  boxShadow: theme.customShadows.card,
  background: theme.colors.white,
  width: width || 600,
  position: "absolute",
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(7),
  margin: "0 auto",
  top: (position && position.top) || "25%",
  left: (position && position.left) || "35%",
  outline: "none",
}));

export const Emphasis = styled("span")(() => ({
  fontWeight: "bold",
}));

export const ButtonWrapper = styled("span")(() => ({
  display: "flex",
}));

export const WarningWrapper = styled("div")(() => ({
  width: "100%",
}));

export const WarningContainer = styled("div")(({ theme }) => ({
  boxShadow: theme.customShadows.card,
  background: theme.colors.white,
  minWidth: 400,
  position: "absolute",
  margin: "0 auto",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
}));
