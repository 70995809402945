import axios from "axios";
import handleError from "./handle-errors";

const SPEND_VENUES_BASE = "/spend-venues";

const getSpendVenues = async ({
  partnerId,
  isPublic,
  basic,
  details,
  limit,
  offset = 0,
  liveOffersOnly,
  options,
  startDate,
  endDate,
  query,
}) => {
  try {
    const { data } = await axios.get(SPEND_VENUES_BASE, {
      params: {
        limit,
        offset,
        liveOffersOnly,
        isPublic,
        partnerId,
        basic,
        details,
        startDate,
        endDate,
        query,
      },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getSpendVenueById = async ({
  options,
  spendVenueId,
  isPublic,
  isBasic,
  profile,
}) => {
  try {
    const { data } = await axios.get(`${SPEND_VENUES_BASE}/${spendVenueId}`, {
      params: { profile, isPublic, isBasic },
    });

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getActivitiesBySpendVenueId = async ({
  options,
  spendVenueId,
  isPublic,
}) => {
  try {
    const { data } = await axios.get(
      `${SPEND_VENUES_BASE}/${spendVenueId}/activities`,
      {
        params: { isPublic },
      },
    );

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updateSpendVenue = async ({ id, updateData, options }) => {
  try {
    const { data } = await axios.patch(
      `${SPEND_VENUES_BASE}/${id}`,
      updateData,
    );

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const createSpendVenue = async ({ createData, options }) => {
  try {
    const { data } = await axios.post(SPEND_VENUES_BASE, createData);

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const createSpendVenueCredentials = async ({ id, form, options }) => {
  try {
    const { data } = await axios.post(
      `${SPEND_VENUES_BASE}/${id}/signup`,
      form,
    );

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updateSpendVenueTerms = async ({ options } = {}) => {
  try {
    const { data } = await axios.patch(`${SPEND_VENUES_BASE}/terms`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};
const updateSpendVenuePartner = async ({
  spendVenueUserId,
  spendPartnerId,
  options,
} = {}) => {
  try {
    const { data } = await axios.patch(
      `${SPEND_VENUES_BASE}/${spendVenueUserId}/partner`,
      {
        spendPartnerId,
      },
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export {
  getSpendVenues,
  getSpendVenueById,
  getActivitiesBySpendVenueId,
  updateSpendVenue,
  createSpendVenue,
  createSpendVenueCredentials,
  updateSpendVenueTerms,
  updateSpendVenuePartner,
};
