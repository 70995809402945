import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Success from "./Success";

import * as actions from "./actions";
import { getUserCurrentTc } from "../../../redux/actions/userActions";

const GiveCredits = ({
  members,
  success,
  balance,
  getMembersBasicInfo,
  giveOutTC,
  loadingIds,
  successIds,
  failedIds,
  getTC,
  bulkLoading,
  reset,
  language,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const [page, setPage] = useState(1);
  const [dates, setDates] = useState({ startDate: null, endDate: null });
  const [selectedMembers, selectMembers] = useState([]);
  const [totalCredits, setTotalCredits] = useState(0);
  const [ableToSubmit, setAbleToSubmit] = useState(false);
  const [applyAllCredits, setApplyAllCredits] = useState(1);
  const [activityCategory, setActivityCategory] = useState("");
  const [activityCategoryOther, setActivityCategoryOther] = useState("");
  const [activityCategoryName, setActivityCategoryName] = useState("");
  const [activityName, setActivityName] = useState("");
  const [showSomethingElse, toggleShowSomethingElse] = useState(false);
  const [usingPrevious, setUsingPrevious] = useState(null);

  const checkSubmit = (newCount, newMembers) => {
    if (newCount <= balance && newMembers.length > 0 && newCount > 0) {
      return setAbleToSubmit(true);
    }
    return setAbleToSubmit(false);
  };

  const selectedActivity = (category) => category === activityCategory;

  const selectActivity = (name, category) => {
    setActivityCategory(category);
    setActivityCategoryName(name);
    setActivityCategoryOther("");
    toggleShowSomethingElse(false);
  };

  const setCatgory = (e, type) => {
    if (type === "somethingElse") {
      toggleShowSomethingElse(!showSomethingElse);
      setActivityCategory("");
      setActivityCategoryName("");
      setActivityCategoryOther("");
    } else if (type === "recentTransactionOther") {
      toggleShowSomethingElse(true);
      setActivityCategory("");
      setActivityCategoryName(e.target.value);
      setActivityCategoryOther(e.target.value);
    } else {
      setActivityCategory("");
      setActivityCategoryName(e.target.value);
      setActivityCategoryOther(e.target.value);
    }
  };
  const setName = (e) => {
    setActivityName(e.target.value);
  };

  const handleDates = (dateObj) => setDates(dateObj.dates);

  const changePage = () => setPage(page + 1);

  const presetMembers = (prevMembers, index) => {
    let count = 0;

    const updatedMembers = members
      .filter((member) =>
        prevMembers.find((prev) => prev.memberId === member.id),
      )
      .map((member) => {
        const prevMember = prevMembers.find(
          (prev) => prev.memberId === member.id,
        );
        count += prevMember.tc;
        return { ...member, credits: prevMember.tc };
      });

    setTotalCredits(count);
    selectMembers(updatedMembers);
    checkSubmit(count, updatedMembers);
    setUsingPrevious(index);
  };

  const handleMembers = (selection) => {
    let count = 0;
    let selectionWithCredits = [];
    const { selected } = selection;
    if (selected && selected.length > 0) {
      selectionWithCredits = selected.map((member) => {
        const { credits } = member;
        if (!credits) {
          const newMember = { credits: 1, ...member };
          count += +newMember.credits;
          return newMember;
        }
        count += +member.credits;
        return member;
      });
    }

    setTotalCredits(count);
    selectMembers(selectionWithCredits);
    checkSubmit(count, selectionWithCredits);
  };

  const handleCredits = (credits) => {
    if (credits.counter > 0) {
      let count = 0;

      selectMembers((_selectedMembers) => {
        const updatedMembers = _selectedMembers.map((member) => {
          if (member.id === credits.memberID) {
            const updatedMember = {
              ...member,
              credits: credits.counter,
            };
            count += +updatedMember.credits;
            return updatedMember;
          }
          count += +member.credits;
          return member;
        });
        checkSubmit(count, updatedMembers);

        return updatedMembers;
      });
      setTotalCredits(count);
    }
  };

  const giveCreditsToAll = (credits) => {
    if (credits.counter > 0) {
      let count = 0;

      selectMembers((_selectedMembers) => {
        const updatedMembers = _selectedMembers.map((member) => {
          const updatedMember = { ...member, credits: credits.counter };
          count += +updatedMember.credits;
          return updatedMember;
        });

        setTotalCredits(count);
        setApplyAllCredits(credits.counter);
        checkSubmit(count, updatedMembers);

        return updatedMembers;
      });
    }
  };

  const removeMember = (memberIndex, memberCredits) => {
    selectMembers((_selectedMembers) => {
      const updatedMembers = _selectedMembers.filter(
        (member, index) => index !== memberIndex,
      );

      setTotalCredits((_totalCredits) => {
        const newTotal = _totalCredits - memberCredits;
        checkSubmit(newTotal, updatedMembers);

        return newTotal;
      });

      return updatedMembers;
    });
  };

  useEffect(() => {
    getMembersBasicInfo();
    getTC();
    if (process.env.NODE_ENV === "production") {
      ReactGA.event({
        category: "Earn Group",
        action: "Starting giving out TCs",
      });
    }

    return () => reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGiveOut = () => {
    giveOutTC({
      membersData: selectedMembers,
      activityCategory: activityCategory || activityCategoryOther,
      activityCategoryName,
      activityName,
      startDate: dates.startDate,
      endDate: dates.endDate,
      history,
    });
  };

  if (success) return <Success isTablet={isTablet} balance={balance} />;

  if (page === 2)
    return (
      <Step2
        members={members}
        selectedMembers={selectedMembers}
        handleMembers={handleMembers}
        handleCredits={handleCredits}
        giveCreditsToAll={giveCreditsToAll}
        totalCredits={totalCredits}
        balance={balance}
        removeMember={removeMember}
        ableToSubmit={ableToSubmit}
        isTablet={isTablet}
        applyAllCredits={applyAllCredits}
        handleGiveOut={handleGiveOut}
        loadingIds={loadingIds}
        successIds={successIds}
        failedIds={failedIds}
        bulkLoading={bulkLoading}
        language={language}
        usingPrevious={usingPrevious}
      />
    );

  return (
    <Step1
      selectedActivity={selectedActivity}
      selectActivity={selectActivity}
      setCatgory={setCatgory}
      setName={setName}
      activityCategory={activityCategory}
      activityCategoryName={activityCategoryName}
      activityName={activityName}
      activityCategoryOther={activityCategoryOther}
      handleDates={handleDates}
      dates={dates}
      changePage={changePage}
      isTablet={isTablet}
      balance={balance}
      toggleShowSomethingElse={toggleShowSomethingElse}
      showSomethingElse={showSomethingElse}
      language={language}
      presetMembers={presetMembers}
      usingPrevious={usingPrevious}
    />
  );
};

const mapStateToProps = (state) => ({
  members: state.giveCredits.members,
  balance: state.user.tc.tcBalance,
  loadingIds: state.giveCredits.loadingIds,
  successIds: state.giveCredits.successIds,
  failedIds: state.giveCredits.failedIds,
  bulkLoading: state.giveCredits.bulkLoading,
  language: state.auth.language,
});

const mapActionToProps = {
  getMembersBasicInfo: actions.getMembersBasicInfo,
  giveOutTC: actions.giveOutTC,
  reset: actions.reset,
  getTC: getUserCurrentTc,
};

export default connect(mapStateToProps, mapActionToProps)(GiveCredits);
