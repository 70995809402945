import React from "react";

import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import Grid from "@material-ui/core/Grid";

import * as T from "../../Typograpy";

import useStyles from "../style";

import t from "../../../utils/helpers/translator";

const filter = createFilterOptions();

const AutoComplete = (props) => {
  const {
    // placeholder text
    placeholder,
    label,
    // error for validation render
    error,
    // material ui id if needed
    id,
    fullWidth,
    // get functions to set value and value itself from parent component
    setValue,
    options,
    helper,
    isOptional = false,
    lang,
    freeSolo,
    size,
    value: _value,
  } = props;
  const _helper = helper;
  const classes = useStyles(props);
  // renders typography for labels above box
  const renderLabel = {
    normal: (
      <div style={{ position: "relative", display: "flex" }}>
        <T.Body16B
          className={classes.textInputLabel}
          style={{
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {label}
          {!!_helper && (
            <T.Body14R color="gray3" style={{ display: "block" }}>
              {" "}
              ({_helper})
            </T.Body14R>
          )}
        </T.Body16B>
      </div>
    ),
    large: (
      <T.Body20B
        className={classes.textInputLabel}
        style={{
          fontSize: "1.25rem",
        }}
      >
        {label}
      </T.Body20B>
    ),
  };

  const handleChange = (event, value) => {
    setValue({
      value,
    });
  };

  const renderInput = (params) => (
    <TextField {...params} placeholder={placeholder} variant="outlined" />
  );

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="flex-start"
      className={classes.root}
    >
      {label && (
        <label
          htmlFor="TextInput"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {renderLabel[size || "normal"]}
          {isOptional && (
            <T.Body14R
              color="gray3"
              style={{
                margin: "0 10px 5px 0",
                alignSelf: "flex-end",
              }}
            >
              ({t("optional", lang)})
            </T.Body14R>
          )}
        </label>
      )}

      <Autocomplete
        {...props}
        id={id}
        fullWidth={fullWidth}
        placeholder={placeholder}
        options={options}
        error={!!error}
        label=""
        value={_value}
        classes={
          props.searchByActivity
            ? {
                inputRoot: classes.searchByActivityAutoComplete,
                input: classes.searchByActivityAutoCompleteInput,
              }
            : {
                inputRoot: classes.autoComplete,
                input: classes.autoCompleteInput,
              }
        }
        onChange={handleChange}
        filterOptions={(_options = [], params) => {
          const filtered = filter(_options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = _options.some(
            (option) => inputValue === option.name,
          );
          if (freeSolo && inputValue !== "" && !isExisting) {
            filtered.push({
              value: inputValue,
              name: `Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        renderInput={renderInput}
      />
      {error && (
        <T.Body14R className={classes.textFieldError}>{error}</T.Body14R>
      )}
    </Grid>
  );
};

export default AutoComplete;
