import * as T from "../../../../../components/Typograpy";

export const device = (isMobile) => (isMobile ? "mobile" : "desktop");

export const headlineComponents = {
  desktop: T.H40Caps,
  mobile: T.H30Caps,
};

export const subHeadlineComponents = {
  desktop: T.H24Caps,
  mobile: T.H22Caps,
};
