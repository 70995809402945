import React, { useEffect } from "react";
import { connect } from "react-redux";

import * as S from "./style";
import * as T from "../Typograpy";

import Icon from "../Icon";

import t from "../../utils/helpers/translator";

const TranslationDiv = ({ setIsOpen }) => (
  <S.GoogleTranslateElementParent id="google_translate_element_parent">
    <div
      id="translationDetector"
      style={{
        display: "none",
      }}
    >
      English
    </div>
    <T.LinkB16
      onClick={(e) => {
        setIsOpen(false);
        e.stopPropagation();
      }}
      mt="10"
      color="white"
      style={{
        cursor: "pointer",
      }}
    >
      Close
    </T.LinkB16>
  </S.GoogleTranslateElementParent>
);

const GoogleTranslateButton = ({ lang }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  useEffect(() => {
    const googleTranslateElement = document.getElementById(
      "google_translate_element",
    );

    const googleTranslateElementParent = document.getElementById(
      "google_translate_element_parent",
    );

    if (googleTranslateElement && googleTranslateElementParent) {
      googleTranslateElementParent.prepend(googleTranslateElement);
    }

    const translationDetector = document.getElementById("translationDetector");
    const listener = translationDetector.addEventListener(
      "DOMSubtreeModified",
      () => {
        if (translationDetector.innerHTML !== "English") {
          setIsOpen(false);
        }
      },
      false,
    );

    return () => {
      translationDetector.removeEventListener("DOMSubtreeModified", listener);
    };
  }, []);

  useEffect(() => {
    if (lang === "welsh") {
      setIsOpen(false);
    }
  }, [lang]);

  const onClickTranslate = () => {
    setIsOpen(true);
  };

  return (
    <S.FeedbackLink
      lang={lang}
      onClick={onClickTranslate}
      isOpen={isOpen}
      disabled={lang !== "english"}
      title={
        lang === "english" ? "Translate" : "Switch to English to translate"
      }
    >
      <S.FeedbackContent>
        <S.FeedbackTitle>
          <Icon
            icon="translation"
            color="white"
            width="20px"
            margin="0 5px 0 0"
          />
          <T.Body14S color="white" style={{ marginRight: "5px" }}>
            {t("translate", lang)}
          </T.Body14S>
        </S.FeedbackTitle>
        <TranslationDiv setIsOpen={setIsOpen} />
      </S.FeedbackContent>
    </S.FeedbackLink>
  );
};

const mapStateToProps = (state) => ({
  lang: state.auth.language,
});

export default connect(mapStateToProps)(GoogleTranslateButton);
