export const tempoRoles = {
  TEMPO_STAFF_USER: "tempoStaffUser",
  TEMPO_STAFF_ADMIN: "tempoStaffAdmin",
  TEMPO_STAFF_SUPER: "tempoStaffSuper",
};

const userRoles = {
  MEMBER: "member",
  EARN_GROUP: "earnGroup",
  SPEND_VENUE: "spendVenue",
  EARN_GROUP_ADMIN: "earnGroupAdmin",
  PROGRAMME_MANAGER: "programmeManager",
  SPEND_PARTNER: "spendPartner",
  ...tempoRoles,
};

export default userRoles;
