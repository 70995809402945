import React from "react";

import PopQuestion from "../../../../components/PopQuestion";
import Button from "../../../../components/Button";
import * as T from "../../../../components/Typograpy";
import RemoveRejoin from "../../../../components/RemoveRejoin";

import { customDateCol } from "../../../../components/Table/config/reusableColumns";

export const programmeEarnGroupsColumns = (issueCredits) => [
  {
    title: "name",
    field: "name",
  },
  {
    title: "members",
    field: "members.activeMembers",
    type: "numeric",
  },
  {
    title: "balance",
    field: "balance",
    type: "numeric",
  },
  {
    title: "earned",
    field: "tcGivenOut",
    type: "numeric",
  },
  {
    title: "accepted",
    field: "tcAccepted",
    type: "numeric",
  },

  {
    title: (
      <div style={{ position: "relative" }}>
        %Used
        <PopQuestion>
          <T.Body16R color="gray3">
            the % of Time Credits that an Earn Group has given out that have
            been used (with Earn Groups or Venues)
          </T.Body16R>
        </PopQuestion>
      </div>
    ),
    field: "spentPercentage",
    customSort: (a, b) =>
      Number(a.spentPercentage?.replace("%", "")) -
      Number(b.spentPercentage?.replace("%", "")),
  },
  {
    title: "action",
    field: "action",
    disableClick: true,
    render: (rowData) => (
      <div style={{ width: "150px" }}>
        {rowData.programme && rowData.programme.status === "activated" ? (
          <Button
            secondary
            size="xs"
            mr="10"
            style={{ height: "30px", padding: "0 10px" }}
            onClick={() => issueCredits(rowData)}
          >
            Issue Credits
          </Button>
        ) : (
          <T.Body16M>Not active</T.Body16M>
        )}
      </div>
    ),
  },
];

export const programmeEarnGroupsColumnsOther = (handleRemoveEarnGroup) => {
  const columns = [
    {
      title: "joined",
      field: "programme.joiningDate",
      sorting: false,
    },
    customDateCol({
      title: "last active",
      field: "lastActive",
    }),
    { title: "Lead contact", field: "leadContact" },
    {
      title: "email",
      field: "leadContactEmail",
      disableClick: true,
      render: (data) => (
        <a href={`mailto: ${data.leadContactEmail}`}>
          <T.LinkS14 color="blue">{data.leadContactEmail}</T.LinkS14>
        </a>
      ),
    },
    { title: "phone", field: "leadContactPhone" },
  ];
  if (handleRemoveEarnGroup) {
    columns.push({
      title: "",
      field: "removeRejoin",
      disableClick: true,
      render: (rowData) => (
        <RemoveRejoin
          type="Remove"
          loading={rowData.loading}
          handleClick={() => handleRemoveEarnGroup(rowData)}
          showText
        />
      ),
    });
  }
  return columns;
};
