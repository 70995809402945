import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as S from "./style";
import * as T from "../../../../components/Typograpy";
import GoBack from "../../../../components/GoBack";

import Button from "../../../../components/Button";
import Stats from "../../../../components/Stats";
import Table from "../../../../components/Table";
import { TEMPO } from "../../../../constants/navRoutes";
import Modal from "../../../../components/Modal";
import DeleteMember from "../../../../components/RemoveRejoin";
import EditAccountButton from "./EditAccountButton";

import {
  dateCol,
  creditsColWithTag,
} from "../../../../components/Table/config/reusableColumns";

import { decideTypeTempo } from "../../../../utils/helpers/decideTransactionType";

import {
  getMemberById,
  getMemberTransactions,
  deleteMemberById,
} from "./actions";
import Loading from "../../../../components/Loading";

const Transactions = ({
  memberData,
  transactionsData,
  getMemberByIdAction,
  getMemberTransactionsAction,
  deleteMemberByIdAction,
}) => {
  const {
    memberName,
    email,
    phone,
    groups,
    membershipId,
    balanceTc,
    earnedTc,
    giftedPercentage,
    spentPercentage,
    status,
    tkId,
  } = memberData;
  const history = useHistory();
  const { id } = useParams();
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    getMemberByIdAction(id);
    getMemberTransactionsAction(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onIssue = () =>
    history.push(
      `${TEMPO.ISSUE_CREDITS_MEMBER.replace(":userId", id)}?showProgramme=true`,
    );

  const clickRow = (event, rowData) =>
    history.push(TEMPO.TRANSACTIONS_SINGLE.replace(":id", rowData.id));

  const memberTransactions = {
    columns: [
      dateCol(),
      { title: "Activity", field: "activity" },
      { title: "Organisation / Person", field: "with" },
      creditsColWithTag(),
    ],
  };

  const createMemberTransactionRows = (transactionsArray) => {
    const createTableRows = transactionsArray.map((t) => ({
      date: t.date,
      activity: t.activity,
      with: t.orgNameOrPerson || "Tempo",
      credits: t.credits,
      type: decideTypeTempo(t.type),
      fromUser: t.from.toString() === id,
      id: t.id,
    }));

    return createTableRows;
  };

  const onDeleteMemberById = () => {
    setModalOpen(true);
  };

  const statsNums = [balanceTc, earnedTc, spentPercentage, giftedPercentage];
  const statsText = [
    "TIME CREDITS",
    "TIME CREDITS EARNED",
    "% USED",
    "% GIFTED",
  ];

  const defaultText = {
    memberName: "DELETED VOLUNTEER",
    unKnown: "N/A",
  };

  if (modalOpen) {
    return (
      <Modal
        modalOpen
        buttonText="Confirm"
        handleCloseModal={() => setModalOpen(false)}
        confirmFun={() => {
          deleteMemberByIdAction(id);
          getMemberByIdAction(id);
        }}
      >
        <T.H30Bold color="blue" style={{ lineHeight: "60px " }}>
          Are you sure?
        </T.H30Bold>
        <T.Body16R>
          {`${memberName}'s personal details will be deleted.
            You will still be able to see their Tempo ID
            and transaction history.`}
        </T.Body16R>
      </Modal>
    );
  }

  return (
    <S.Container>
      {!memberData.loading ? (
        <>
          <S.Head>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <S.Back>
                <GoBack showText />
              </S.Back>
              <T.H40Caps
                color="primary"
                style={{ margin: 0, marginBottom: "10px" }}
              >
                {(status !== "deleted" && memberName) || defaultText.memberName}
              </T.H40Caps>
              <S.IdContainer>
                <T.H30 color="blue">{membershipId}</T.H30>
                <T.H14CapsI color="gray2">Tempo ID</T.H14CapsI>
              </S.IdContainer>
            </div>
            <div>
              <T.Body18B color="cyan">Balance:</T.Body18B>
              <T.H60Caps color="primary">{balanceTc || "0"}</T.H60Caps>
              <T.H22Caps color="cyan">TIME CREDITS</T.H22Caps>
            </div>
          </S.Head>
          <S.CardContainer>
            <S.StyledCard width={475}>
              <T.H20 color="midnight">Volunteer Details</T.H20>
              <S.CardItem>
                <T.H18 color="gray2">Name</T.H18>
                <T.H16 style={{ textTransform: "capitalize" }}>
                  {(status !== "deleted" && memberName) || defaultText.unKnown}
                </T.H16>
              </S.CardItem>
              <S.CardItem>
                <T.H18 color="gray2">Email</T.H18>
                {status !== "deleted" && email ? (
                  <a
                    href={`mailto:${email}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    alt="email"
                  >
                    <T.H16 color="cyan" style={{ textDecoration: "underline" }}>
                      {email || defaultText.unKnown}
                    </T.H16>
                  </a>
                ) : (
                  <T.H16>N/A</T.H16>
                )}
              </S.CardItem>
              <S.CardItem>
                <T.H18 color="gray2">Phone</T.H18>
                <T.H16>
                  {(status !== "deleted" && phone) || defaultText.unKnown}
                </T.H16>
              </S.CardItem>
              <S.CardItem>
                <T.H18 color="gray2">Groups</T.H18>
                <T.H16>
                  {(status !== "deleted" && groups) || defaultText.unKnown}
                </T.H16>
              </S.CardItem>
              <S.CardItem>
                <T.H18 color="gray2">Team Kinetic ID</T.H18>
                <T.H16>
                  {(status !== "deleted" && tkId) || defaultText.unKnown}
                </T.H16>
              </S.CardItem>
            </S.StyledCard>
            <S.StyledCard width={400} height="fit-content">
              <T.H16 color="midnight">
                Want to give <S.Name>{memberName}</S.Name> Time Credits?
              </T.H16>
              <Button
                ml="0"
                mt="10"
                primary
                disabled={status === "deleted"}
                onClick={onIssue}
              >
                Issue Time Credits
              </Button>
            </S.StyledCard>
          </S.CardContainer>
          <br />
          {status !== "deleted" && <EditAccountButton id={id} />}
          {memberName && status !== "deleted" && (
            <DeleteMember
              handleClick={onDeleteMemberById}
              showText
              text="Delete Volunteer"
              type="Remove"
            />
          )}
        </>
      ) : (
        <Loading />
      )}

      <S.StatsContainer>
        <T.H24Caps color="primary">TEMPO ACTIVITY </T.H24Caps>
        <S.Section color="cyanLight">
          {statsText.map((e, i) => (
            <Stats
              size="s"
              statNum={statsNums[i]}
              statNumColor="cyan"
              statText={e}
              statTextColor="blue"
            />
          ))}
        </S.Section>
      </S.StatsContainer>
      <S.TableContainer style={{ marginTop: "10px" }}>
        {!transactionsData.loading ? (
          <Table
            color="pink"
            hideSearch
            data={createMemberTransactionRows(transactionsData.data)}
            columns={memberTransactions.columns}
            onRowClick={clickRow}
            limit={5}
          />
        ) : (
          <Loading />
        )}
      </S.TableContainer>
    </S.Container>
  );
};
const mapStateToProps = (state) => ({
  memberData: state.staffSingleMember,
  transactionsData: state.staffSingleMember.transactionsData,
});

const mapActionToProps = {
  getMemberByIdAction: getMemberById,
  deleteMemberByIdAction: deleteMemberById,
  getMemberTransactionsAction: getMemberTransactions,
};

export default connect(mapStateToProps, mapActionToProps)(Transactions);
