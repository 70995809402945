import styled from "@material-ui/core/styles/styled";

export const ImageWrapper = styled("div")({
  minWidth: 250,
  height: 210,
  transform: "translate(30px, -30px)",
  display: "flex",
  justifyContent: "flex-end",
});

export const TotalVatContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "17px",
});

export const TotalAmountContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

export const Error = styled("p")(({ theme }) => ({
  width: "100%",
  color: theme.colors.pink,
  fontSize: "0.875rem",
  textAlign: "center",
  marginBottom: theme.spacing(1),
}));

export const CsvExport = styled("a")({
  textDecoration: "none",
  display: "flex",
  marginTop: 32,
  maxWidth: 220,
});
