import React from "react";
import * as S from "./style";
import * as T from "../Typograpy";
import Image from "../Image";

const Info = ({ header, body, customGraphic }) => (
  <S.GraphicWrapper>
    <Image
      image={customGraphic || "graphic4"}
      width="80px"
      height="80px"
      margin="0 20px 0 0"
    />
    <S.GraphicBody>
      <T.Body18S color="blue" style={{ marginBottom: body ? "10px" : "0" }}>
        {header}
      </T.Body18S>
      {body && <T.Body14R>{body}</T.Body14R>}
    </S.GraphicBody>
  </S.GraphicWrapper>
);

export default Info;
