import styled from "@material-ui/core/styles/styled";

export const Container = styled("div")(() => ({
  width: "100%",
}));

export const Head = styled("div")({
  position: "relative",
  display: "flex",
  justifyContent: "space-between",
});

export const StatsWrapper = styled("div")({
  marginTop: 50,
  marginRight: 7,
  "& div": {
    width: 155,
  },
});
export const ImageWrapper = styled("div")({
  minWidth: 220,
  height: 210,
  transform: "translate(-10px, -30px)",
});

export const TableContainer = styled("div")({
  marginTop: 30,
  display: "flex",
  flexDirection: "column",
  position: "relative",
});

export const BtnContainer = styled("div")({
  alignSelf: "flex-end",
  transform: "translateY(195px)",
  zIndex: 2,
});

export const ModalContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
});

export const HeadContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "80%",
  zIndex: 999,
});
export const FilterContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
});

export const SearchWrapper = styled("div")({
  justifyItems: "center",
  width: "365px",
  marginTop: "-2px",
  marginBottom: "10px",
});
