import React from "react";
import * as S from "./style";
import Image from "../../../components/Image";

const Wrapper = ({ children }) => (
  <>
    <S.Wrapper>
      <S.ImageWrapper>
        <Image image="officeLarge" />
      </S.ImageWrapper>
      {children}
    </S.Wrapper>
  </>
);

export default Wrapper;
