import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import Notification from "../../../components/Notification";
import Loading from "../../../components/Loading";
import * as T from "../../../components/Typograpy";
import Modal from "../../../components/Modal";
import { InputField } from "../../../components/Inputs";

import validate from "../../../validation/schemas/myAccount";

import t from "../../../utils/helpers/translator";

import {
  getUserAccount,
  updateUserAccount,
} from "./../../../redux/actions/userAccount";

import Content from "./Content";

const MyAccount = (props) => {
  const {
    role,
    getUserAccountAction,
    userAccount,
    updateUserAccountAction,
    isMobile,
    language,
  } = props;

  const [showMsg, setShowMsg] = useState(false);
  const [btnOn, setBtnOn] = useState(false);
  const [errors, setErrors] = useState({});
  const [showConfirmPasswordModal, setShowConfirmPasswordModal] = useState();
  const [password, setPassword] = useState("");
  const currentState = useRef();

  // handles on change email / number / tezt / password input events
  const onChange = (e, _setState, _state) => {
    setBtnOn(true);
    const { name, value } = e.target;
    const cleanEmail = name === "email" && value.toLowerCase().trim();

    setErrors((_errors) => ({ ..._errors, [name]: "" }));

    switch (name) {
      case "email":
        _setState({ ..._state, email: cleanEmail });
        break;
      default:
        _setState({ ..._state, [name]: value });
        break;
    }
  };
  // handles drop down selection with edge cases
  const onSelectChange = ({ value, name }, _setState, _state) => {
    setBtnOn(true);
    setErrors((e) => ({ ...e, [name]: "" }));
    switch (name) {
      case "region":
        _setState({
          ..._state,
          region: value.value,
          regionCountry: value.countryValue || value.country,
        });
        break;
      case "focusFields":
        if (!value || !value.length) {
          _setState({
            ..._state,
            [name]: [],
          });
        }
        if (value && value.length > 0 && value.length <= 3) {
          _setState({
            ..._state,
            [name]: value.map((e) => e.value),
          });
          break;
        } else if (value && value.length > 0 && value.length > 3) {
          setErrors((_errors) => ({
            ..._errors,
            [name]: t("pickUp3Options", language),
          }));
        }
        break;
      default:
        _setState({ ..._state, [name]: value });
        break;
    }
  };

  const handleClick = async ({ e, state }) => {
    e.preventDefault();
    currentState.current = state;
    try {
      validate({
        ...state,
        role,
        backendError: userAccount?.error?.message,
        password,
      });
      setErrors({});
      await updateUserAccountAction({ ...state, password });

      if (showConfirmPasswordModal) {
        setShowConfirmPasswordModal(false);
        getUserAccountAction();
      }

      if (!userAccount.updateAccountLoading && !userAccount.error) {
        setShowMsg(true);
      }
      setBtnOn(false);

      return true;
    } catch (err) {
      if (err?.name === "ValidationError") {
        setErrors(err.inner);
      }
      setBtnOn(false);
    }
  };

  const showNotification = (message) => (
    <Notification
      open={showMsg}
      setOpen={setShowMsg}
      message={message}
      duration={2500}
    />
  );

  // get user account details
  useEffect(() => {
    getUserAccountAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userAccount?.error?.message === "multipleAccountConfirmationRequired") {
      setShowConfirmPasswordModal(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccount?.error?.message]);

  const confirmFun = async (e) => {
    handleClick({ e, state: currentState.current });
  };

  if (userAccount.getAccountLoading) {
    return <Loading />;
  }

  return (
    <>
      <Content
        role={role}
        validate={validate}
        onChange={onChange}
        handleClick={handleClick}
        errors={errors}
        showNotification={showNotification}
        userAccount={userAccount}
        getUserAccountAction={getUserAccountAction}
        isUpdating={userAccount.updateAccountLoading}
        btnOn={btnOn}
        onSelectChange={onSelectChange}
        {...props}
      />
      <Modal
        modalOpen={showConfirmPasswordModal}
        handleCloseModal={() => setShowConfirmPasswordModal(false)}
        confirmFun={confirmFun}
        buttonText="Confirm"
        withCancel
        width="640px"
        keepOpenOnConfirm
        loading={userAccount.updateAccountLoading}
      >
        <T.H30 color="blue" m="0" mb="25">
          {t("areYouSure", language)}
        </T.H30>

        <T.Body16B color="midnight">
          {t("thisEmailIsLinked", language, [userAccount?.error?.data?.role])}
        </T.Body16B>

        <T.Body16R mt="20">
          {t("enterPasswordToSwitch", language, [
            userAccount?.error?.data?.email,
          ])}
        </T.Body16R>
        <T.Body16B mt="20">
          {t("note", language)}:{" "}
          <T.Body16R>
            {t("yourNameWillBeUpdated", language, [
              userAccount?.error?.data?.email,
            ])}
          </T.Body16R>
        </T.Body16B>
        <InputField
          mt={!isMobile ? 6 : 4}
          label={t("password", language)}
          type="password"
          placeholder={`${t("typePassword", language)}...`}
          name={password.name}
          setValue={(e) => setPassword(e.target.value)}
          value={password || ""}
          error={
            errors.password ||
            (userAccount?.error?.data?.field === "password" &&
              t(userAccount?.error.message, language))
          }
        />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  role: state.auth.role,
  userAccount: state.userAccount,
  language: state.auth.language,
});

const mapActionToProps = {
  getUserAccountAction: getUserAccount,
  updateUserAccountAction: updateUserAccount,
};

export default connect(mapStateToProps, mapActionToProps)(MyAccount);
