import { styled } from "@material-ui/core/styles";

export const ImageWrapper = styled("span")({
  marginTop: 37,
  display: "block",
});

export const Flex = styled("div")({
  display: "flex",
});

export const DetailsWrapper = styled("div")(({ theme, ...props }) => ({
  maxWidth: 296,
  margin: theme.helpers.setMargin(props),
  [theme.gridBreakpoints.tablet]: {
    minWidth: "75%",
    maxWidth: "auto",
  },
}));

export const subTitleWrapper = styled("div")(
  ({ theme, isTablet, ...props }) => ({
    maxWidth: 160,
    margin: theme.helpers.setMargin(props),
    [theme.gridBreakpoints.tablet]: {
      minWidth: 60,
    },
  }),
);

export const FiledWrapper = styled("div")(({ theme, ...props }) => ({
  width: 240,
  margin: theme.helpers.setMargin(props),
  [theme.gridBreakpoints.tablet]: {
    width: "50%",
  },
}));

export const PageWrapper = styled("div")({
  maxWidth: 1040,
  width: "100%",
});
