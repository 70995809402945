import {
  GET_GIFTS_SUCCESS,
  GET_GIFTS_FAIL,
  GET_GIFTS_LOADING_START,
  GET_GIFTS_LOADING_END,
  CREATE_GIFT_SUCCESS,
  CREATE_GIFT_FAIL,
  CREATE_GIFT_LOADING_START,
  CREATE_GIFT_LOADING_END,
  GET_EARN_GROUPS_SUCCESS,
  GET_EARN_GROUPS_FAIL,
  GET_EARN_GROUPS_LOADING_START,
  GET_EARN_GROUPS_LOADING_END,
  SET_GIFT_RECIPIENT,
  CANCEL_GIFT_SUCCESS,
  CANCEL_GIFT_FAIL,
  CANCEL_GIFT_LOADING_START,
  CANCEL_GIFT_LOADING_END,
  GET_GIFT_SUCCESS,
  GET_GIFT_FAIL,
  GET_GIFT_LOADING_START,
  GET_GIFT_LOADING_END,
  RESET_GIFT_ERRORS,
} from "../../../redux/types";

const initState = {
  getGiftsLoading: false,
  gifts: [],
  getGiftsError: {},
  getGiftsLoaded: false,

  createGiftLoading: false,
  createGiftError: {},
  createGiftLoaded: false,
  createdGiftToken: "",

  getEarnGroupsLoading: false,
  getEarnGroupsLoaded: false,
  earnGroups: [],
  getEarnGroupsError: {},

  cancelGiftLoading: false,
  cancelGiftError: {},

  recipient: { name: "", role: "" },

  getGiftLoading: false,
  getGiftError: {},
  getGiftLoaded: false,
  gift: {
    tc: null,
    fromUser: {},
    toUser: {},
  },
};

const giftsReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_GIFTS_LOADING_START:
      return {
        ...state,
        getGiftsLoading: true,
      };
    case GET_GIFTS_LOADING_END:
      return {
        ...state,
        getGiftsLoading: false,
      };

    case GET_GIFTS_SUCCESS:
      return {
        ...state,
        gifts: payload,
        getGiftsLoaded: true,
      };
    case GET_GIFTS_FAIL:
      return {
        ...state,
        getGiftsError: payload,
      };

    case CREATE_GIFT_LOADING_START:
      return {
        ...state,
        createGiftLoading: true,
        createGiftLoaded: false,
      };
    case CREATE_GIFT_LOADING_END:
      return {
        ...state,
        createGiftLoading: false,
      };

    case CREATE_GIFT_SUCCESS:
      return {
        ...state,
        createdGiftToken: payload.token,
        createGiftLoaded: true,
      };
    case CREATE_GIFT_FAIL:
      return {
        ...state,
        createGiftError: payload,
      };

    case GET_EARN_GROUPS_LOADING_START:
      return {
        ...state,
        getEarnGroupsLoading: true,
      };
    case GET_EARN_GROUPS_LOADING_END:
      return {
        ...state,
        getEarnGroupsLoading: false,
      };

    case GET_EARN_GROUPS_SUCCESS:
      return {
        ...state,
        earnGroups: payload,
        getEarnGroupsLoaded: true,
      };
    case GET_EARN_GROUPS_FAIL:
      return {
        ...state,
        getEarnGroupsError: payload,
      };

    case SET_GIFT_RECIPIENT:
      return {
        ...state,
        recipient: payload,
      };

    case CANCEL_GIFT_LOADING_START:
      return {
        ...state,
        gifts: state.gifts.map((_gift) => {
          const gift = { ..._gift };
          if (gift.id === Number(payload.id)) {
            gift.loading = true;
          }
          return gift;
        }),

        cancelGiftLoading: true,
      };

    case CANCEL_GIFT_LOADING_END:
      return {
        ...state,
        cancelGiftLoading: false,
      };

    case CANCEL_GIFT_SUCCESS:
      return {
        ...state,
        gifts: state.gifts.map((_gift) => {
          const gift = { ..._gift };
          if (gift.id === Number(payload.id)) {
            gift.status = "cancelled";
            gift.loading = false;
          }
          return gift;
        }),
        cancelGiftLoaded: true,
      };

    case CANCEL_GIFT_FAIL:
      return {
        ...state,
        gifts: state.gifts.map((_gift) => {
          const gift = { ..._gift };
          if (gift.id === Number(payload.id)) {
            gift.loading = false;
            gift.error = payload;
          }
          return gift;
        }),

        cancelGiftError: payload,
      };

    case GET_GIFT_LOADING_START:
      return {
        ...state,
        getGiftLoading: true,
      };
    case GET_GIFT_LOADING_END:
      return {
        ...state,
        getGiftLoading: false,
      };

    case GET_GIFT_SUCCESS:
      return {
        ...state,
        gift: payload,
        getGiftLoaded: true,
      };
    case GET_GIFT_FAIL:
      return {
        ...state,
        getGiftError: payload,
      };

    case RESET_GIFT_ERRORS:
      return {
        ...state,
        getGiftsError: {},
        createGiftError: {},
        getEarnGroupsError: {},
        getGiftError: {},
      };

    default:
      return state;
  }
};

export default giftsReducer;
