import React from "react";
import { connect } from "react-redux";
import { Switch } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import theme from "../../utils/theme";

// CONSTANTS
import { GENERAL } from "../../constants/navRoutes";
import { roles } from "../../constants";

// GENERAL COMPONENTS
import MyAccount from "./MyAccount";
import Spend from "./Spend";
import Earn from "./Earn";
import ForgetPassword, { SetPassword } from "./ForgetPassword";
import SelectVenue from "./SelectVenue";
import Landing from "./Landing";
import Welcome from "./Welcome";
import ActivityProfile from "./Profile/Activity";
import VenueProfile from "./Profile/VenueOrGroup";
import EarnSpendActivities from "./EarnSpendActivities";
import SelectUser from "./SelectUser";
import Terms from "./Terms";
import ActivateAccountCheckToken, { EmailSent } from "./ActivateAccount";

// MULTI-USE COMPONENTS
import Login from "../Login";
import AddGroupLogin from "../AddGroupLogin";
import AddGroupSignup from "../AddGroupSignup";
import Signup from "../Signup";
import ContinueSignUp from "../Signup/ContinueSignup";
import Funnel from "../Signup/Funnel";

// OTHER ROUTERS
import ErrorRouter from "../ErrorRoutes";
import InvoiceOrder from "./InvoiceOrder";

// COMMON
import Route from "../../components/Route";

const GeneralRouter = ({ isMobile, isMounted }) => {
  const showProfileFooter = useMediaQuery(theme.breakpoints.down(1200));
  return (
    <Switch>
      <Route
        exact
        path={GENERAL.ACTIVATE_ACCOUNT_EMAIL_SENT}
        isPrivate
        layout="splitScreen"
        side="left"
        logo
        allowedRoles={[
          roles.MEMBER,
          roles.EARN_GROUP,
          roles.SPEND_VENUE,
          roles.EARN_GROUP_ADMIN,
          roles.PROGRAMME_MANAGER,
          roles.SPEND_PARTNER,
        ]}
        Component={EmailSent}
      />
      <Route
        exact
        path={GENERAL.ACTIVATE_ACCOUNT_CHECK_TOKEN}
        isPrivate
        layout="splitScreen"
        side="left"
        logo
        allowedRoles={[
          roles.MEMBER,
          roles.EARN_GROUP,
          roles.SPEND_VENUE,
          roles.EARN_GROUP_ADMIN,
          roles.PROGRAMME_MANAGER,
          roles.SPEND_PARTNER,
        ]}
        Component={ActivateAccountCheckToken}
      />
      <Route
        exact
        path={GENERAL.LOG_IN}
        layout="onboarding"
        footer
        Component={Login}
      />
      <Route
        exact
        path={GENERAL.ADD_GROUP_LOGIN}
        layout="onboarding"
        footer
        Component={AddGroupLogin}
      />
      <Route
        exact
        path={GENERAL.ADD_GROUP_SIGNUP}
        layout="onboarding"
        footer
        Component={AddGroupSignup}
      />
      <Route
        exact
        path={GENERAL.SELECT_USER}
        Component={SelectUser}
        layout="splitScreen"
        side="left"
        color="pink"
        logo
      />
      <Route
        exact
        path={GENERAL.SIGN_UP_FUNNEL}
        layout="onboarding"
        Component={Funnel}
        footer
      />
      <Route
        exact
        path={GENERAL.SIGN_UP}
        layout="onboarding"
        Component={Signup}
      />
      <Route
        exact
        path={GENERAL.CONTINUE_SIGN_UP}
        layout="onboarding"
        isPrivate
        Component={ContinueSignUp}
        allowedRoles={[roles.EARN_GROUP, roles.MEMBER]}
      />
      <Route
        isPrivate
        exact
        path={GENERAL.SETTINGS}
        Component={MyAccount}
        layout="general"
        navbar
        header
        isMobile={isMobile}
        fullWidth
        allowedRoles={[
          roles.MEMBER,
          roles.SPEND_VENUE,
          roles.EARN_GROUP,
          roles.EARN_GROUP_ADMIN,
          roles.PROGRAMME_MANAGER,
          roles.SPEND_PARTNER,
        ]}
        footer={!showProfileFooter}
      />
      <Route
        exact
        path={GENERAL.WELCOME}
        Component={Welcome}
        isPrivate
        layout="splitScreen"
        side="left"
        logo
        allowedRoles={[roles.EARN_GROUP, roles.MEMBER, roles.EARN_GROUP_ADMIN]}
      />

      {/* FORGOT PASSWORD */}
      <Route
        exact
        path={GENERAL.FORGET_PASSWORD}
        Component={ForgetPassword}
        layout={isMobile ? "general" : "splitScreen"}
        side="right"
      />

      <Route
        exact
        path={GENERAL.SET_PASSWORD}
        Component={SetPassword}
        layout={isMobile ? "general" : "splitScreen"}
        side="right"
      />

      <Route
        exact
        path={GENERAL.SELECT_VENUE}
        Component={SelectVenue}
        layout={isMobile ? "general" : "splitScreen"}
        side="left"
        footer
        logo
      />

      {/* LISTINGS */}
      <Route
        exact
        path={GENERAL.SPEND_ACTIVITY}
        Component={ActivityProfile}
        navbar
        fullWidth
        header
        footer={!showProfileFooter}
        profile
        activityType="spend"
      />

      <Route
        exact
        path={GENERAL.SPEND_OPTIONS}
        Component={ActivityProfile}
        navbar
        fullWidth
        header
        footer
        activityType="spendOptions"
      />

      <Route
        exact
        path={GENERAL.BOOK_ONLINE_SUCCESS}
        Component={ActivityProfile}
        navbars
        activityType="bookingSuccess"
        layout="splitScreen"
        side="right"
        isPrivate
        allowedRoles={roles.MEMBER}
      />

      <Route
        exact
        path={GENERAL.ORDER_INVOICE}
        Component={InvoiceOrder}
        layout="center"
        isPrivate
        allowedRoles={[
          roles.EARN_GROUP,
          roles.EARN_GROUP_ADMIN,
          roles.TEMPO_STAFF_USER,
          roles.TEMPO_STAFF_SUPER,
          roles.TEMPO_STAFF_ADMIN,
        ]}
      />

      <Route
        exact
        path={GENERAL.EARN_GROUP}
        Component={VenueProfile}
        navbar
        fullWidth
        header
        footer={!showProfileFooter}
        profile
        activityType="earn"
      />

      <Route
        exact
        path={GENERAL.EARN_GROUP_GET_IN_TOUCH}
        Component={VenueProfile}
        layout="splitScreen"
        side="right"
        activityType="earnGetInTouch"
        allowedRoles={Object.values(roles)}
      />

      <Route
        exact
        path={GENERAL.SPEND_VENUE}
        Component={VenueProfile}
        navbar
        fullWidth
        header
        footer
        activityType="spend"
      />

      <Route path={GENERAL.SPEND} Component={Spend} navbar fullWidth header />

      <Route path={GENERAL.EARN} Component={Earn} navbar fullWidth header />

      <Route
        exact
        path={GENERAL.ACTIVITIES}
        Component={EarnSpendActivities}
        navbar
        fullWidth
        header
        footer={!showProfileFooter}
      />

      <Route
        exact
        path={GENERAL.ACCEPT_TERMS}
        Component={Terms}
        isPrivate
        layout="splitScreen"
        side="left"
        logo
        color="purple"
        allowedRoles={[roles.SPEND_PARTNER, roles.SPEND_VENUE]}
      />

      {/* LANDING - TO BE UPDATED AT SOME POINT - CURRENTLY WE REDIRECT TO LOG IN */}
      <Route
        exact
        path={GENERAL.HOME_URL}
        Component={Landing}
        layout="general"
        navbar
        isMounted={isMounted}
      />

      {/* ERROR ROUTES */}
      <ErrorRouter />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state.ui.isMobile,
  isMounted: state.auth.isMounted,
});

export default connect(mapStateToProps)(GeneralRouter);
