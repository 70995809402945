import React from "react";
import { connect } from "react-redux";
import * as T from "../../Typograpy";
import Icon from "../../Icon";
import { MemberActivityWrapper } from "../style";
import colors from "../../../utils/theme/colors";

import t from "../../../utils/helpers/translator";

const decideColor = (type) => {
  if (["spend", "rejected", "returning", "given out"].includes(type)) {
    return "pink";
  }
  if (["gift", "partially approved"].includes(type)) {
    return "purple";
  }
  if (["issue", "issued"].includes(type)) {
    return "gray2";
  }
  if (type === "pending") {
    return "cyan";
  }
  return "blue";
};

// at the moment expects fromUser to be a boolean to confirm it is from the member (not to)
const MemberMobileActivity = ({
  activity,
  venue,
  type,
  fromUser,
  date,
  pending,
  lang,
}) => (
  <MemberActivityWrapper date={date}>
    <span
      style={{
        width: "22px",
        height: "22px",
        marginRight: "15px",
        paddingBottom: pending && "45px",
      }}
    >
      <Icon
        icon={fromUser ? "downArrow" : "upArrow"}
        width="22px"
        height="22px"
        color="white"
        background={decideColor(type)}
      />
    </span>
    <span
      style={{
        paddingBottom: pending && "25px",
      }}
    >
      <T.Body16R style={{ wordWrap: "break-word" }}>
        {activity}{" "}
        {pending && (
          <i style={{ color: colors.gray3 }}>({t("pending", lang)})</i>
        )}
      </T.Body16R>
      <T.Body12R color="gray2">{venue}</T.Body12R>
    </span>
  </MemberActivityWrapper>
);

const mapStateToProps = (state) => ({
  lang: state.auth.decideLanguage(state.auth),
});

export default connect(mapStateToProps)(MemberMobileActivity);
