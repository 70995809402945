const keys = {
  UK_WIDE: "UK-wide",
  ENGLAND: "England",
  ENGLAND_WIDE: "England-wide",
  EAST_OF_ENGLAND: "East of England",
  EAST_MIDLANDS: "East Midlands",
  LONDON: "London",
  NORTH_EAST: "North East",
  NORTH_WEST: "North West",
  SOUTH_EAST: "South East",
  SOUTH_WEST: "South West",
  WEST_MIDLANDS: "West Midlands",
  YORKSHIRE_AND_THE_HUMBER: "Yorkshire and the Humber",
  WALES: "Wales",
  WALES_WIDE: "Wales-wide",
  MID_WALES: "Mid Wales",
  NORTH_WALES: "North Wales",
  SOUTH_EAST_WALES: "South East Wales",
  SOUTH_WEST_WALES: "South West Wales",
  SCOTLAND: "Scotland",
  SCOTLAND_WIDE: "Scotland-wide",
  ABERDEEN_GRAMPIAN: "Aberdeen & Grampian",
  ANGUS_DUNDEE: "Angus & Dundee",
  ARGYLL: "Argyll",
  DUMFRIES_GALLOWAY: "Dumfries & Galloway",
  EDINBURGH_THE_LOTHIANS: "Edinburgh & the Lothians",
  FIFE: "Fife",
  GLASGOW_CLYDE_VALLEY: "Glasgow & Clyde Valley",
  THE_SCOTTISH_HIGHLANDS: "The Scottish Highlands",
  ORKNEY: "Orkney",
  THE_OUTER_INNER_HEBRIDES: "The Outer & Inner Hebrides",
  PERTHSHIRE: "Perthshire",
  THE_SHETLAND_ISLANDS: "The Shetland Islands",
  STIRLING_THE_TROSSACHS: "Stirling & The Trossachs",
  NORTHERN_IRELAND: "Northern Ireland",
  NORTHERN_IRELAND_WIDE: "Northern Ireland-wide",
  ANTRIM: "Antrim",
  ARMAGH: "Armagh",
  DOWN: "Down",
  FERMANAGH: "Fermanagh",
  LONDONDERRY: "Londonderry",
  TYRONE: "Tyrone",
};

const regionsDropDownEnglish = [
  {
    country: keys.UK_WIDE,
    label: keys.UK_WIDE,
    value: keys.UK_WIDE,
  },
  {
    label: keys.ENGLAND,
    options: [
      {
        country: keys.ENGLAND,
        label: keys.ENGLAND_WIDE,
        value: keys.ENGLAND_WIDE,
      },
      {
        country: keys.ENGLAND,
        label: keys.EAST_OF_ENGLAND,
        value: keys.EAST_OF_ENGLAND,
      },
      {
        country: keys.ENGLAND,
        label: keys.EAST_MIDLANDS,
        value: keys.EAST_MIDLANDS,
      },
      { country: keys.ENGLAND, label: keys.LONDON, value: keys.LONDON },
      { country: keys.ENGLAND, label: keys.NORTH_EAST, value: keys.NORTH_EAST },
      { country: keys.ENGLAND, label: keys.NORTH_WEST, value: keys.NORTH_WEST },
      { country: keys.ENGLAND, label: keys.SOUTH_EAST, value: keys.SOUTH_EAST },
      { country: keys.ENGLAND, label: keys.SOUTH_WEST, value: keys.SOUTH_WEST },
      {
        country: keys.ENGLAND,
        label: keys.WEST_MIDLANDS,
        value: keys.WEST_MIDLANDS,
      },
      {
        country: keys.ENGLAND,
        label: keys.YORKSHIRE_AND_THE_HUMBER,
        value: keys.YORKSHIRE_AND_THE_HUMBER,
      },
    ],
  },

  {
    label: keys.WALES,
    options: [
      {
        country: keys.WALES,
        label: keys.WALES_WIDE,
        value: keys.WALES_WIDE,
      },
      { country: keys.WALES, label: keys.MID_WALES, value: keys.MID_WALES },
      { country: keys.WALES, label: keys.NORTH_WALES, value: keys.NORTH_WALES },
      {
        country: keys.WALES,
        label: keys.SOUTH_EAST_WALES,
        value: keys.SOUTH_EAST_WALES,
      },
      {
        country: keys.WALES,
        label: keys.SOUTH_WEST_WALES,
        value: keys.SOUTH_WEST_WALES,
      },
    ],
  },
  {
    label: keys.SCOTLAND,
    options: [
      {
        country: keys.SCOTLAND,
        value: keys.SCOTLAND_WIDE,
        label: keys.SCOTLAND_WIDE,
      },
      {
        country: keys.SCOTLAND,
        value: keys.ABERDEEN_GRAMPIAN,
        label: keys.ABERDEEN_GRAMPIAN,
      },
      {
        country: keys.SCOTLAND,
        value: keys.ANGUS_DUNDEE,
        label: keys.ANGUS_DUNDEE,
      },
      { country: keys.SCOTLAND, value: keys.ARGYLL, label: keys.ARGYLL },
      {
        country: keys.SCOTLAND,
        value: keys.DUMFRIES_GALLOWAY,
        label: keys.DUMFRIES_GALLOWAY,
      },
      {
        country: keys.SCOTLAND,
        value: keys.EDINBURGH_THE_LOTHIANS,
        label: keys.EDINBURGH_THE_LOTHIANS,
      },
      { country: keys.SCOTLAND, value: keys.FIFE, label: keys.FIFE },
      {
        country: keys.SCOTLAND,
        value: keys.GLASGOW_CLYDE_VALLEY,
        label: keys.GLASGOW_CLYDE_VALLEY,
      },
      {
        country: keys.SCOTLAND,
        value: keys.THE_SCOTTISH_HIGHLANDS,
        label: keys.THE_SCOTTISH_HIGHLANDS,
      },
      { country: keys.SCOTLAND, value: keys.ORKNEY, label: keys.ORKNEY },
      {
        country: keys.SCOTLAND,
        value: keys.THE_OUTER_INNER_HEBRIDES,
        label: keys.THE_OUTER_INNER_HEBRIDES,
      },
      {
        country: keys.SCOTLAND,
        value: keys.PERTHSHIRE,
        label: keys.PERTHSHIRE,
      },
      {
        country: keys.SCOTLAND,
        value: keys.THE_SHETLAND_ISLANDS,
        label: keys.THE_SHETLAND_ISLANDS,
      },
      {
        country: keys.SCOTLAND,
        value: keys.STIRLING_THE_TROSSACHS,
        label: keys.STIRLING_THE_TROSSACHS,
      },
    ],
  },
  {
    label: keys.NORTHERN_IRELAND,
    options: [
      {
        country: keys.NORTHERN_IRELAND,
        label: keys.NORTHERN_IRELAND_WID,
        value: keys.NORTHERN_IRELAND_WID,
      },
      {
        country: keys.NORTHERN_IRELAND,
        label: keys.ANTRIM,
        value: keys.ANTRIM,
      },
      {
        country: keys.NORTHERN_IRELAND,
        label: keys.ARMAGH,
        value: keys.ARMAGH,
      },
      { country: keys.NORTHERN_IRELAND, label: keys.DOWN, value: keys.DOWN },
      {
        country: keys.NORTHERN_IRELAND,
        label: keys.FERMANAGH,
        value: keys.FERMANAGH,
      },
      {
        country: keys.NORTHERN_IRELAND,
        label: keys.LONDONDERRY,
        value: keys.LONDONDERRY,
      },
      {
        country: keys.NORTHERN_IRELAND,
        label: keys.TYRONE,
        value: keys.TYRONE,
      },
    ],
  },
];

export { keys };
export default regionsDropDownEnglish;
