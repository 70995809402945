import makeStyles from "@material-ui/core/styles/makeStyles";

const decideHeight = (hideLangToggle) => {
  if (hideLangToggle) return "80px";
  return "125px";
};

const useStyle = makeStyles((theme) => ({
  Wrapper: {
    minHeight: "100vh",
    position: "relative",
    background: "none",
    paddingTop: ({ hideLangToggle }) => decideHeight(hideLangToggle),
    paddingBottom: theme.spacing(11),

    [theme.breakpoints.tablet]: {
      background: "none",
      maxWidth: "2000px",
      paddingLeft: "8%",
      paddingRight: "8%",
    },
  },

  ContentWrapper: {
    display: "flex",
    justifyContent: "center",
    paddingLeft: "5%",
    paddingRight: "5%",
    width: "100%",
  },
}));

export default useStyle;
