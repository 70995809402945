import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "./actions";
import * as S from "./style";
import * as T from "../../../components/Typograpy";
import { InputField } from "../../../components/Inputs";
import { Regular } from "../../../components/Button";
import { email as emailSchema } from "../../../validation/fields";
import { GENERAL } from "../../../constants/navRoutes";
import SetPassword from "./SetPassword";
import Wrapper from "./Wrapper";
import t from "../../../utils/helpers/translator";

const ForgetPassword = (props) => {
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState("");

  const history = useHistory();
  const {
    resetPasswordLink,
    sendLinkSuccess,
    sendLinkLoading,
    sendLinkError,
    resetState,
    language,
  } = props;

  const handleEmail = (_email) => {
    setEmailError("");

    setEmail(_email);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const cleanEmail = email?.toLowerCase().trim();
    const isValidEmail = emailSchema.isValidSync(cleanEmail);
    setEmailError(isValidEmail ? "" : t("invalidEmail", language));
    if (isValidEmail) {
      resetPasswordLink(cleanEmail);
    }
  };

  useEffect(() => {
    resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [sendLinkSuccess]);

  return (
    <Wrapper>
      <T.H40Caps color="midnight" mt="20">
        {t("resetPassword", language)}
      </T.H40Caps>
      {sendLinkSuccess ? (
        <>
          <T.Body16R color="gray2" mt={25}>
            {t("passwordResetLinkSent", language)}
          </T.Body16R>
          <Regular
            primary
            mt={78}
            bgColor="blue"
            onClick={() => history.push(GENERAL.LOG_IN)}
          >
            {t("returnToLogIn", language)}
          </Regular>
        </>
      ) : (
        <>
          <T.Body16R color="gray2" mt={20}>
            {t("enterEmailExplanation", language)}.
          </T.Body16R>
          <S.Body onSubmit={onSubmit}>
            <InputField
              disabled={sendLinkLoading}
              setValue2={handleEmail}
              fullWidth
              type="email"
              placeholder={`${t("typeEmailHere", language)}...`}
              label={t("email", language)}
              mt="5"
              error={emailError || t(sendLinkError.message, language)}
            />
            <S.ButtonWrapper>
              {!email || !!emailError ? (
                <Regular bgColor="gray5" textColor="gray1" disabled>
                  {t("sendLink", language)}
                </Regular>
              ) : (
                <Regular
                  bgColor="blue"
                  textColor="white"
                  primary
                  loading={sendLinkLoading}
                  type="submit"
                >
                  {t("sendLink", language)}
                </Regular>
              )}
            </S.ButtonWrapper>
          </S.Body>
        </>
      )}
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  sendLinkSuccess: state.forgetPassword.sendLinkSuccess,
  sendLinkLoading: state.forgetPassword.sendLinkLoading,
  sendLinkError: state.forgetPassword.sendLinkError,
  language: state.auth.language,
});

const mapActionToProps = {
  resetPasswordLink: actions.resetPasswordLink,
  resetState: actions.resetState,
};

export { SetPassword };
export default connect(mapStateToProps, mapActionToProps)(ForgetPassword);
