import React from "react";

const LinkedIn = ({ width, height, color, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 19"
    width={width}
    height={height}
    {...props}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M17.852 1.174H4.132c-.75 0-1.405.54-1.405 1.28v13.752c0 .745.655 1.409 1.404 1.409H17.85c.753 0 1.318-.669 1.318-1.41V2.456c.005-.742-.565-1.281-1.315-1.281zM7.822 14.878H5.469V7.555h2.355v7.323zM6.728 6.442H6.71c-.754 0-1.242-.562-1.242-1.264 0-.715.5-1.263 1.272-1.263.77 0 1.242.544 1.259 1.263 0 .702-.489 1.264-1.272 1.264zm9.704 8.436h-2.356v-4.004c0-.96-.342-1.615-1.194-1.615-.651 0-1.037.44-1.208.87-.064.154-.082.364-.082.578v4.171H9.236V7.555h2.355v1.019c.343-.488.879-1.19 2.125-1.19 1.546 0 2.715 1.019 2.715 3.216v4.278z"
        fill={color || "currentColor"}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill={color || "currentColor"} d="M.553 0h18.79v18.79H.553z" />
      </clipPath>
    </defs>
  </svg>
);
export default LinkedIn;
