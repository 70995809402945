import axios from "axios";
import handleError from "./handle-errors";

const EARN_GROUPS_BASE = "/earn-groups";

const getEarnGroups = async ({
  inviteToken,
  invitedUserRole,
  options,
  keyword,
  limit,
  offset,
  isPublic,
  language,
  startDate,
  endDate,
  query,
  skipLimit,
  sortBy,
} = {}) => {
  try {
    const { data } = await axios.get(EARN_GROUPS_BASE, {
      params: {
        inviteToken,
        invitedUserRole,
        limit,
        offset,
        isPublic,
        keyword,
        language,
        startDate,
        endDate,
        query,
        skipLimit,
        sortBy,
      },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getEarnGroupByUserId = async ({ id, basic, profile, options }) => {
  try {
    const { data } = await axios.get(`${EARN_GROUPS_BASE}/${id}`, {
      params: { basic, profile },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updateEarnGroupProfile = async ({ id, updateDate, options }) => {
  try {
    const { data } = await axios.patch(`${EARN_GROUPS_BASE}/${id}`, updateDate);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getEarnGroupByGroupId = async ({ id, isPublic = false, options }) => {
  try {
    const { data } = await axios.get(`${EARN_GROUPS_BASE}/${id}`, {
      params: { isPublic },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getEarnGroupAdmins = async ({ id, options }) => {
  try {
    const { data } = await axios.get(
      `${EARN_GROUPS_BASE}/${id}/administrators`,
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const addEarnGroupAdmins = async ({ id, email, options }) => {
  try {
    const { data } = await axios.post(
      `${EARN_GROUPS_BASE}/${id}/administrators`,
      { email },
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const addMembersToEarnGroup = async ({
  earnGroupUserId,
  membersToAdd,
  options,
} = {}) => {
  try {
    const { data } = await axios.post(
      `${EARN_GROUPS_BASE}/${earnGroupUserId}/add-members`,
      {
        membersToAdd,
      },
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const deleteAdministrator = async ({
  earnGroupUserId,
  administratorId,
  options,
}) => {
  try {
    const { data } = await axios.delete(
      `${EARN_GROUPS_BASE}/${earnGroupUserId}/administrators/${administratorId}`,
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const deleteEarnGroup = async ({ id, options = {} } = {}) => {
  try {
    const { data } = await axios.delete(`${EARN_GROUPS_BASE}/${id}`);

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const promoteEGAdmin = async ({
  earnGroupUserId,
  administratorId,
  options = {},
} = {}) => {
  try {
    const { data } = await axios.patch(
      `${EARN_GROUPS_BASE}/${earnGroupUserId}/promote-eg-admin`,
      {
        administratorId,
      },
    );

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getEarnGroupAccountHolderLogs = async ({
  earnGroupUserId,
  options = {},
}) => {
  try {
    const { data } = await axios.get(
      `${EARN_GROUPS_BASE}/${earnGroupUserId}/account-holder-logs`,
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getEarnGroupsLocations = async ({ language, options = {} }) => {
  try {
    const { data } = await axios.get(`${EARN_GROUPS_BASE}/locations`, {
      params: { language, isPublic: true },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export {
  getEarnGroups,
  getEarnGroupByUserId,
  getEarnGroupByGroupId,
  updateEarnGroupProfile,
  getEarnGroupAdmins,
  addEarnGroupAdmins,
  deleteAdministrator,
  addMembersToEarnGroup,
  deleteEarnGroup,
  promoteEGAdmin,
  getEarnGroupAccountHolderLogs,
  getEarnGroupsLocations,
};
