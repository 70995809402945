import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import stripLeadingTab from "../../../utils/helpers/stripLeadingTab";

import * as actions from "./actions";
import * as S from "./style";
import {
  InputField,
  SingleDatePicker,
  Checkbox,
} from "../../../components/Inputs";
import { Regular } from "../../../components/Button";
import Warning from "../../../components/Message/Warning";
import Image from "../../../components/Image";
import { roles, navRoutes, externalLinks } from "../../../constants";
import validate from "../../../validation/schemas/signup";
import { Row, Col } from "../../../components/Grid";
import Modal from "../../../components/Modal";

import tidyPostcode from "../../../utils/helpers/tidyPostcode";
import t from "../../../utils/helpers/translator";

const { MEMBER } = roles;

const NonDigitalInvite = ({
  signupOffline,
  cancelErrorModal,
  loading,
  openErrorModal,
  error,
  language,
}) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const matches = useMediaQuery("(min-width:1040px)");
  const history = useHistory();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthDate, setBirthDate] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [agreedOnTerms, setAgreedOnTerms] = useState(false);
  const [confirmPermission, setConfirmPermission] = useState(false);
  const [warning, setWarning] = useState(false);
  const [submitAttemp, setSubmitAttemp] = useState(false);
  const [postcode, setPostcode] = useState("");
  const [loaded, setLoaded] = useState(false);

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    birthDate: "",
    agreedOnTerms: "",
    confirmPermission: "",
  });

  const partialValidate = (name, value) => {
    try {
      if (submitAttemp) {
        if (name === "phoneNumber") {
          validate(
            {
              [name]: stripLeadingTab(value),
            },
            true,
          );
        } else {
          validate(
            {
              [name]: value,
            },
            true,
          );
        }
      }
    } catch (err) {
      if (err.name === "ValidationError") {
        setErrors((_errors) => ({ ..._errors, [name]: err.inner[name] }));
      }
    }
  };

  const onChange = (e) => {
    partialValidate(
      e.target.name,
      e.target.name === "agreedOnTerms" || e.target.name === "confirmPermission"
        ? e.target.checked
        : e.target.value,
    );

    switch (e.target.name) {
      case "firstName":
        return setFirstName(e.target.value);
      case "lastName":
        return setLastName(e.target.value);
      case "phoneNumber":
        return setPhoneNumber(e.target.value);
      case "agreedOnTerms":
        return setAgreedOnTerms(e.target.checked);
      case "confirmPermission":
        return setConfirmPermission(e.target.checked);
      case "postcode":
        setPostcode(e.target.value);
        break;
      default:
        break;
    }
  };

  const datePick = (newBirth) => {
    setBirthDate(newBirth);
  };

  const handleSubmit = () => {
    try {
      setSubmitAttemp(true);

      validate({
        firstName,
        lastName,
        phoneNumber: stripLeadingTab(phoneNumber),
        birthDate,
        role: MEMBER,
        isOffline: true,
        agreedOnTerms,
        confirmPermission,
        postcode: tidyPostcode(postcode),
      });

      signupOffline(
        {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          phoneNumber: stripLeadingTab(phoneNumber),
          birthDate,
          role: MEMBER,
          isOffline: true,
          postcode: tidyPostcode(postcode),
          confirmPermission,
        },
        history,
      );
    } catch (err) {
      if (err.name === "ValidationError") {
        setErrors(err.inner);
      }
      setWarning(false);
    }
  };

  const toggleWarning = () => {
    if (isTablet) {
      setWarning((state) => !state);
    } else {
      handleSubmit();
    }
  };

  useEffect(() => {
    setLoaded(true);
  }, []);

  const termsOfUseText = t("agreeToTerms", language).split(" | ");

  return (
    <S.Wrapper>
      <Row>
        <Col w={[4, 8, 8]}>
          <S.Header>{t("inviteMemberNoEmail", language)}</S.Header>
        </Col>
      </Row>
      {!isTablet && loaded && (
        <Row>
          <S.WarningWrapper>
            <Col w={[4, 8, 8]}>
              <Warning
                warningType="email"
                header={`${t("areYouSureEmail", language)}?`}
                body={t("ifTheyDoHaveEmail", language)}
              />
            </Col>
            <Col w={[0, 4, 4]}>
              <S.ImageWrapper>
                <Image image="group" alt="group" />
              </S.ImageWrapper>
            </Col>
          </S.WarningWrapper>
        </Row>
      )}
      <S.ContentWrapper>
        <S.NonDigitalForm>
          <Row>
            <Col w={[4, 8, 8]}>
              <S.BodyText>
                {t("membershipCardExplanation", language)}:
              </S.BodyText>
            </Col>
          </Row>
          <S.NonDigitalWrapper>
            <Row mb={2}>
              <Col w={[4, 6, 4]}>
                <InputField
                  type="text"
                  name="firstName"
                  label={t("firstName", language)}
                  placeholder={`${t("typeFirstNameHere", language)}...`}
                  value={firstName}
                  setValue={onChange}
                  mb={4}
                  error={errors.firstName}
                />
              </Col>
              <Col w={[4, 6, 4]}>
                <InputField
                  type="text"
                  name="lastName"
                  label={t("lastName", language)}
                  placeholder={`${t("typeLastNameHere", language)}...`}
                  value={lastName}
                  setValue={onChange}
                  mb={4}
                  mr={!isMobile ? 2 : 0}
                  error={errors.lastName}
                />
              </Col>

              <Col w={[4, 6, 4]}>
                <SingleDatePicker
                  name="birth"
                  label={t("dateOfBirth", language)}
                  placeholder={t("dateOfBirth", language)}
                  value={birthDate}
                  setValue={datePick}
                  singleDatePicker
                  error={errors.birthDate}
                  disableFuture
                  mb={4}
                />
              </Col>

              <Col w={[4, 6, 4]}>
                <InputField
                  name="postcode"
                  label={t("postcode", language)}
                  placeholder={`${t("typePostcode", language)}...`}
                  value={postcode}
                  setValue={onChange}
                  error={errors.postcode}
                  mb={4}
                />
              </Col>

              <Col w={[4, 6, 4]}>
                <S.PhoneWrapper matches={matches}>
                  <InputField
                    type="tel"
                    name="phoneNumber"
                    label={t("phoneNumber", language)}
                    placeholder={`${t("typePhoneNumber", language)}...`}
                    value={phoneNumber}
                    setValue={onChange}
                    error={errors.phoneNumber}
                    mb={4}
                  />
                </S.PhoneWrapper>
              </Col>
            </Row>
            <Row>
              <Col w={[4, 6, 4]}>
                <Checkbox
                  value={agreedOnTerms}
                  checkboxSize="small"
                  name="agreedOnTerms"
                  setValue={onChange}
                  label={
                    <>
                      {termsOfUseText[0]}{" "}
                      <S.ExtLink
                        href={externalLinks.MEMBER_TERMS}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {termsOfUseText[1]}
                      </S.ExtLink>
                      . {termsOfUseText[2]}{" "}
                      <S.ExtLink
                        href={externalLinks.MEMBER_PRIVACY}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {termsOfUseText[3]}
                      </S.ExtLink>
                      .
                    </>
                  }
                  error={errors.agreedOnTerms}
                />
              </Col>
              <Col w={[4, 6, 4]}>
                <Checkbox
                  value={confirmPermission}
                  checkboxSize="small"
                  name="confirmPermission"
                  setValue={onChange}
                  label={t("confirmPermission", language)}
                  error={errors.confirmPermission}
                />
              </Col>
            </Row>
          </S.NonDigitalWrapper>
          <Row>
            <Col w={[4, 6, 4]}>
              <Regular
                outline
                primary
                textColor="blue"
                m="0 0 30px 0"
                onClick={() => history.push(navRoutes.EARN_GROUP.INVITE)}
              >
                {t("wantToGoBack", language)}
              </Regular>
            </Col>
            <Col w={[4, 6, 4]}>
              <Regular
                primary
                loading={loading}
                bgColor="blue"
                m="0"
                onClick={toggleWarning}
              >
                {t("registerMember", language)}
              </Regular>
            </Col>
          </Row>
          {warning && !openErrorModal && isTablet && (
            <Warning
              warningType="email"
              header={`${t("areYouSureEmail", language)}?`}
              body={t("ifTheyDoHaveEmail", language)}
              backFunc={toggleWarning}
              onConfirm={handleSubmit}
              confirmLoading={loading}
              modal
            />
          )}
        </S.NonDigitalForm>
        <Modal
          modalOpen={openErrorModal}
          handleCloseModal={cancelErrorModal}
          warning
        >
          <Warning
            warningType="modal"
            header={t("warning", language)}
            body={error && t(error.message, language)}
          />
        </Modal>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  loading: state.invite.loading,
  openErrorModal: state.invite.openErrorModal,
  error: state.invite.error,
  language: state.auth.language,
});

const mapActionToProps = {
  signupOffline: actions.signupOffline,
  cancelErrorModal: actions.cancelErrorModal,
};

export default connect(
  mapStateToProps,
  mapActionToProps,
)(withRouter(NonDigitalInvite));
