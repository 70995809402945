import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import * as S from "./style";
import * as T from "../../../components/Typograpy";
import { EARN_GROUP } from "../../../constants/navRoutes";
import CreditCard from "../../../components/Cards/CreditCard";
import Button from "../../../components/Button";

import t from "../../../utils/helpers/translator";

const Success = ({ membershipId, language }) => {
  const history = useHistory();

  return (
    <S.SuccessWrapper>
      <CreditCard memberID={membershipId} />
      <S.MemberHeader>
        <T.H40Caps color="midnight">{t("membershipID", language)}:</T.H40Caps>
        <T.H40 color="blue">{membershipId}</T.H40>
      </S.MemberHeader>
      <T.Body16R style={{ marginBottom: "20px" }}>
        {t("nowUseMembershipID", language)}.
      </T.Body16R>
      <T.Body16R style={{ marginBottom: "20px" }}>
        {t("whenArriveAtSpendVenue", language)}.
      </T.Body16R>
      <T.Body16B5 style={{ marginBottom: "40px" }}>
        {t("pleaseWriteMembershipID", language)}
      </T.Body16B5>
      <Button
        primary
        size="l"
        style={{ margin: "0" }}
        onClick={() => history.push(EARN_GROUP.MEMBERS)}
      >
        {t("understandAndGiveMembershipID", language)}
      </Button>
    </S.SuccessWrapper>
  );
};

const mapStateToProps = (state) => ({
  membershipId: state.invite.membershipId,
  language: state.auth.language,
});

export default connect(mapStateToProps)(Success);
