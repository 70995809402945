const keys = {
  I_HAVE_NEVER_VOLUNTEERED_BEFORE: "I have never volunteered before",
  I_HAVE_OCCASIONALLY_VOLUNTEERED_BEFORE:
    "I have occasionally volunteered before",
  I_HAVE_VOLUNTEERED_IN_THE_PAST_BUT_NOT_RECENTLY:
    "I have volunteered in the past but not recently",
  I_ALREADY_VOLUNTEER_REGULARLY: "I already volunteer regularly",
};

const keysWelsh = {
  I_HAVE_NEVER_VOLUNTEERED_BEFORE: "Nid wyf erioed wedi gwirfoddoli o'r blaen",
  I_HAVE_OCCASIONALLY_VOLUNTEERED_BEFORE:
    "Rwyf wedi gwirfoddoli o'r blaen yn achlysurol",
  I_HAVE_VOLUNTEERED_IN_THE_PAST_BUT_NOT_RECENTLY:
    "Rwyf wedi gwirfoddoli yn y gorffennol ond nid yn ddiweddar",
  I_ALREADY_VOLUNTEER_REGULARLY: "Rwyf eisoes yn gwirfoddoli'n rheolaidd",
};

const volunteeredListEnglish = [
  {
    label: keys.I_HAVE_NEVER_VOLUNTEERED_BEFORE,
    value: keys.I_HAVE_NEVER_VOLUNTEERED_BEFORE,
  },
  {
    label: keys.I_HAVE_OCCASIONALLY_VOLUNTEERED_BEFORE,
    value: keys.I_HAVE_OCCASIONALLY_VOLUNTEERED_BEFORE,
  },
  {
    label: keys.I_HAVE_VOLUNTEERED_IN_THE_PAST_BUT_NOT_RECENTLY,
    value: keys.I_HAVE_VOLUNTEERED_IN_THE_PAST_BUT_NOT_RECENTLY,
  },
  {
    label: keys.I_ALREADY_VOLUNTEER_REGULARLY,
    value: keys.I_ALREADY_VOLUNTEER_REGULARLY,
  },
];

const volunteeredListWelsh = [
  {
    label: keysWelsh.I_HAVE_NEVER_VOLUNTEERED_BEFORE,
    value: keys.I_HAVE_NEVER_VOLUNTEERED_BEFORE,
  },
  {
    label: keysWelsh.I_HAVE_OCCASIONALLY_VOLUNTEERED_BEFORE,
    value: keys.I_HAVE_OCCASIONALLY_VOLUNTEERED_BEFORE,
  },
  {
    label: keysWelsh.I_HAVE_VOLUNTEERED_IN_THE_PAST_BUT_NOT_RECENTLY,
    value: keys.I_HAVE_VOLUNTEERED_IN_THE_PAST_BUT_NOT_RECENTLY,
  },
  {
    label: keysWelsh.I_ALREADY_VOLUNTEER_REGULARLY,
    value: keys.I_ALREADY_VOLUNTEER_REGULARLY,
  },
];

const volunteeredValuesMapWelsh = {};
Object.keys(keys).forEach((e) => {
  volunteeredValuesMapWelsh[keys[e]] = keysWelsh[e];
});

export {
  keys,
  keysWelsh,
  volunteeredListEnglish,
  volunteeredListWelsh,
  volunteeredValuesMapWelsh,
};
