import { fields, createSchema, validate as _validate } from "..";

const { addMembersToEarnGroup } = fields;

const schema = createSchema({
  addMembersToEarnGroup,
});

const validate = (data) => _validate(schema, { addMembersToEarnGroup: data });

export default validate;
