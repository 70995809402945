import styled from "@material-ui/core/styles/styled";

export const Title = styled("div")(({ theme }) => ({
  margin: "0 auto",
  display: "none",
  [theme.breakpoints.tablet]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flexStart",
    padding: `0 ${theme.spacing(0)}`,
    margin: 0,
    paddingTop: 0,
  },
}));

export const TitleMobile = styled("div")(({ theme }) => ({
  width: 200,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: `0 ${theme.spacing(0)}`,
  "& *": {
    textAlign: "center",
  },
  paddingTop: 20,
  margin: "0 auto",
  [theme.breakpoints.tablet]: {
    display: "none",
  },
}));

export const PageTitle = styled("div")(({ theme }) => ({
  width: "100%",
  display: "none",
  marginTop: theme.spacing(9),
  marginBottom: "10px",
  "& button": {
    maxHeight: "48",
    width: "190px",
    marginLeft: 10,
  },
  [theme.breakpoints.tablet]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
}));

export const Container = styled("div")(() => ({}));

export const TableContainer = styled("div")(({ theme }) => ({
  display: "none",
  [theme.breakpoints.tablet]: {
    display: "block",
  },
}));

export const Message = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  marginTop: "50px",
  [theme.breakpoints.tablet]: {
    display: "none",
  },
}));
