import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import { getSingleTransaction } from "../../../redux/actions/singleTransaction";

import * as S from "./style";
import * as T from "../../../components/Typograpy";
import { roles, navRoutes } from "../../../constants";
import { earnTypesMapWelsh } from "../../../constants/data/earnActivityTypes";

import GoBack from "../../../components/GoBack";
import TimeCredit from "../../../components/TimeCredit";
import Image from "../../../components/Image";

import IndividualTransactionMobile from "./IndividualTransactionMobile";
import Loading from "../../../components/Loading";
import t from "../../../utils/helpers/translator";
import calendarFormatter from "../../../utils/helpers/calendarFormatter";
import dateFormatter from "../../../utils/helpers/dateFormatter";

const MemberSingleTransaction = (props) => {
  const { id: transactionId } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { userId, getSingleTransactionAction, transaction, isLoading, lang } =
    props;

  useEffect(() => {
    getSingleTransactionAction(transactionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId]);

  const {
    createdAt,
    to = {},
    from = {},
    tc: credits,
    type,
    activity = {},
  } = transaction;

  const {
    title,
    startDate: activityStartDate,
    endDate: activityEndDate,
    name: activityName,
    welshName,
    welshTitle,
  } = activity;

  const translateTitle = () => {
    if (lang === "welsh") {
      const res = earnTypesMapWelsh[title];
      return res || welshTitle || title;
    }
    return title || "N/A";
  };

  const decideActivityTitle = (transactionType, name) => {
    if (["free", "bought"].includes(transactionType)) {
      return t("tcDelivery", lang);
    }
    if (type === "gift") {
      return t("gift", lang);
    }
    if (type === "spend") {
      if (lang === "welsh") {
        return welshName || name;
      }
      return name;
    }
    if (type === "earn") {
      return translateTitle();
    }
    return "N/A";
  };

  const decideVenue = (id, fromDetails, toDetails) => {
    if (fromDetails.userId === id) {
      return toDetails;
    }
    return fromDetails;
  };

  const translateOrgName = (org) => {
    if (lang === "welsh") {
      return org.involvedWelsh || org.involved;
    }
    return org.involved;
  };

  const venue = decideVenue(userId, from, to);

  const decideAddress = () => {
    const {
      isOnline,
      addressLine1,
      addressLine2,
      addressLine1Welsh,
      addressLine2Welsh,
      earnGroupAddresses,
      earnGroupWelshAddresses,
      earnGroupIsOnline,
    } = venue;
    if (isOnline || earnGroupIsOnline) return t("online", lang);
    if (earnGroupAddresses) {
      if (earnGroupAddresses.length > 1) return t("multpleLocations", lang);

      const line1 =
        lang === "english"
          ? earnGroupAddresses[0].addressLine1
          : earnGroupWelshAddresses[0]?.addressLine1 ||
            earnGroupAddresses[0].addressLine1;

      const line2 =
        lang === "english"
          ? earnGroupAddresses[0].addressLine2
          : earnGroupWelshAddresses[0]?.addressLine2 ||
            earnGroupAddresses[0].addressLine2;

      return `${line1 && line1} ${line2 && line2}`;
    }

    const _addressLine1 =
      lang === "welsh" && addressLine1Welsh ? addressLine1Welsh : addressLine1;
    const _addressLine2 =
      lang === "welsh" && addressLine2Welsh ? addressLine2Welsh : addressLine2;

    const address =
      (_addressLine1 || _addressLine2) &&
      `${_addressLine1 || ""} ${_addressLine2 || ""}`;

    return address;
  };

  if (isLoading) return <Loading />;

  if (isMobile)
    return (
      <IndividualTransactionMobile
        transaction={transaction}
        decideActivityTitle={decideActivityTitle}
        venue={venue}
        lang={lang}
      />
    );

  return (
    <S.IndividualWrapper>
      <GoBack showText />
      <S.IndividualHeader>
        <T.H40Caps color="midnight" style={{ margin: "0" }}>
          {t("transactionDetails", lang)}
        </T.H40Caps>
        <S.ImageWrapper>
          <Image image="galleryLarge" alt="gallery" width="100%" />
        </S.ImageWrapper>
      </S.IndividualHeader>
      <S.Details>
        <T.H20 color="midnight" mb={10}>
          {t("activity", lang)}
        </T.H20>
        <T.Body16R>{decideActivityTitle(type, activityName)}</T.Body16R>
      </S.Details>
      <S.Details>
        <T.H20 color="midnight" mb={10}>
          {type === "gift" ? t("person", lang) : t("organisation", lang)}
        </T.H20>
        {venue.role === roles.SPEND_VENUE || venue.role === roles.EARN_GROUP ? (
          <S.StyledLink
            to={
              type === "earn"
                ? navRoutes.GENERAL.EARN_GROUP.replace(":id", venue.userId)
                : navRoutes.GENERAL.SPEND_VENUE.replace(":id", venue.userId)
            }
          >
            <T.LinkB16 color="blue">{translateOrgName(venue)}</T.LinkB16>

            {/* {(_addressLine1 || _addressLine2) && ( */}
            <T.Body12R color="gray3">{decideAddress()}</T.Body12R>
            {/* )} */}
          </S.StyledLink>
        ) : (
          <T.Body16R>
            {venue.involved ? translateOrgName(venue) : "Tempo"}
          </T.Body16R>
        )}
      </S.Details>
      <S.Details>
        <T.H20 color="midnight" mb={10}>
          {t("transactionDate", lang)}
        </T.H20>
        <T.Body16R>
          {lang !== "english"
            ? dateFormatter(createdAt)
            : calendarFormatter(createdAt, lang)}
        </T.Body16R>
      </S.Details>
      <S.Details>
        <T.H20 color="midnight" mb={10}>
          {t("TCNumber", lang)}
        </T.H20>
        <TimeCredit
          credits={credits}
          gradient="blue"
          color="blue"
          description
        />
      </S.Details>
      {type !== "spend" && (
        <S.OptionalDetailsWrapper>
          {activityName && (
            <S.OptionalDetail>
              <T.H20 color="midnight" mb={10}>
                {t("details", lang)}
              </T.H20>
              <T.Body16R>{activityName}</T.Body16R>
            </S.OptionalDetail>
          )}
          {(activityStartDate || activityEndDate) && (
            <S.OptionalDetail>
              <T.H20 color="midnight" mb={10}>
                {t("dateOfActivity", lang)}
              </T.H20>
              {activityStartDate && (
                <T.Body16R>
                  {lang !== "english"
                    ? dateFormatter(activityStartDate)
                    : calendarFormatter(activityStartDate, lang)}
                </T.Body16R>
              )}
              {activityEndDate && (
                <T.Body16R>
                  {t("to", lang)}{" "}
                  {lang !== "english"
                    ? dateFormatter(activityEndDate)
                    : calendarFormatter(activityEndDate, lang)}
                </T.Body16R>
              )}
            </S.OptionalDetail>
          )}
        </S.OptionalDetailsWrapper>
      )}
    </S.IndividualWrapper>
  );
};

const mapStateToProps = (state) => ({
  userId: state.auth.id,
  transaction: state.singleTransaction.data,
  isLoading: state.singleTransaction.loading,
  lang: state.auth.language,
});

const mapActionToProps = {
  getSingleTransactionAction: getSingleTransaction,
};

export default connect(
  mapStateToProps,
  mapActionToProps,
)(MemberSingleTransaction);
