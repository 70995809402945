import React from "react";
import { Redirect } from "react-router-dom";

import AllTransactions from "./AllTransactions";
import IndividualTransaction from "./IndividualTransaction";

const EarnGroupMembers = (props) => {
  const { type } = props;
  switch (type) {
    case "all":
      return <AllTransactions {...props} />;
    case "single":
      return <IndividualTransaction {...props} />;

    default:
      return <Redirect to="#" />;
  }
};

export default EarnGroupMembers;
