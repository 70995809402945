export const userTags = {
  members: "Members",
  spendvenues: "Spend venues",
  earngroups: "Earn groups",
  programmes: "Programmes",
};

export const tcTags = {
  earn: "time credits earned",
  spend: "time credits used",
  gift: "time credits gifted",
  tcTotalIssued: "Time credits issued",
};
