import React from "react";

const Info = ({ width, height, color, ...props }) => (
  <svg
    width={width}
    height={height}
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.625 3C6.86203 3 3 6.86203 3 11.625C3 16.388 6.86203 20.25 11.625 20.25C16.388 20.25 20.25 16.388 20.25 11.625C20.25 6.86203 16.388 3 11.625 3Z"
      stroke={color || "currentColor"}
      strokeWidth="1.62188"
      strokeMiterlimit="10"
    />
    <path
      d="M10.3123 10.3125H11.8123V15.75"
      stroke={color || "currentColor"}
      strokeWidth="1.62188"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.74976 15.9375H13.8748"
      stroke={color || "currentColor"}
      strokeWidth="1.62188"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M11.6252 6.09375C11.3842 6.09375 11.1486 6.16523 10.9481 6.29915C10.7477 6.43306 10.5915 6.62341 10.4993 6.8461C10.407 7.0688 10.3829 7.31385 10.4299 7.55027C10.4769 7.78668 10.593 8.00384 10.7635 8.17429C10.9339 8.34473 11.1511 8.46081 11.3875 8.50783C11.6239 8.55486 11.8689 8.53072 12.0916 8.43848C12.3143 8.34623 12.5047 8.19002 12.6386 7.9896C12.7725 7.78918 12.844 7.55355 12.844 7.3125C12.844 6.98927 12.7156 6.67927 12.487 6.45071C12.2585 6.22215 11.9485 6.09375 11.6252 6.09375Z"
      fill={color || "currentColor"}
    />
  </svg>
);
export default Info;
