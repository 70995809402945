import React, { useState, useEffect } from "react";

import { Row, Col } from "../../../components/Grid";
import { InputField } from "../../../components/Inputs";
import Button from "../../../components/Button";
import * as T from "../../../components/Typograpy";
import Icon from "../../../components/Icon";
import PasswordChange from "./PasswordChange";
import * as S from "./style";

import {
  fields,
  device,
  headlineComponents,
  subHeadlineComponents,
  initStates,
} from "./data";

const { firstName, lastName, email } = fields;

const ProgrammeManagerAccount = ({
  isMobile,
  onChange,
  handleClick,
  errors,
  showNotification,
  userAccount,
  isUpdating,
  btnOn,
  mainError,
}) => {
  const Headline = headlineComponents[device(isMobile)];
  const SubHeadline = subHeadlineComponents[device(isMobile)];

  const [state, setState] = useState({
    ...initStates.member,
  });

  // set state with user data
  useEffect(() => {
    if (userAccount.data) {
      setState(userAccount.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <S.AccountWrapper>
      <Row>
        <Col w={[4, 12, 12]}>
          <Headline color="midnight">My account</Headline>
          <SubHeadline
            color="midnight"
            style={{
              marginTop: isMobile ? "2.5rem" : "3.5rem",
              fontWeight: "900",
            }}
          >
            Account Details
          </SubHeadline>
        </Col>
      </Row>

      {/* Fields */}
      <Row style={{ alignItems: "stretch" }}>
        <Col w={[4, 4, 4]}>
          <InputField
            mt={!isMobile ? 6 : 4}
            label={firstName.label}
            type={firstName.type}
            placeholder={firstName.placeholder}
            name={firstName.name}
            setValue={(e) => onChange(e, setState, state)}
            value={state.firstName || ""}
            error={errors.firstName}
          />
          <InputField
            mt={!isMobile ? 6 : 4}
            label={lastName.label}
            type={lastName.type}
            placeholder={lastName.placeholder}
            name={lastName.name}
            setValue={(e) => onChange(e, setState, state)}
            value={state.lastName || ""}
            error={errors.lastName}
          />
        </Col>
        <Col w={[4, 4, 4]}>
          <InputField
            mt={!isMobile ? 6 : 4}
            label={email.label}
            type={email.type}
            placeholder={email.placeholder}
            name={email.name}
            setValue={(e) => onChange(e, setState, state)}
            value={state.email || ""}
            error={
              errors.email ||
              (mainError?.data?.field === "email" && mainError.message)
            }
          />
        </Col>
        <Col w={[4, 4, 4]} style={{ height: "inherit" }}>
          <S.PopperContainer>
            <div
              style={{
                width: 60,
                height: 60,
                marginRight: 16,
              }}
            >
              <Icon icon="info" color="cyan" width="60px" height="60px" />
            </div>
            <T.Body16B color="blue" style={{ paddingRight: 16 }}>
              Want to add another Programme Manager? Please contact Tempo
            </T.Body16B>
          </S.PopperContainer>
        </Col>
      </Row>

      <Row>
        <Col
          style={{
            marginTop: !isMobile ? "60px" : "40px",
          }}
          w={[4, 4, 4]}
        >
          <Button
            primary
            size="l"
            type="submit"
            handleClick={(e) => handleClick({ e, state })}
            loading={isUpdating}
            disabled={!btnOn}
          >
            Save changes
          </Button>
          {showNotification("Changes saved")}
        </Col>
      </Row>
      <PasswordChange />
    </S.AccountWrapper>
  );
};

export default ProgrammeManagerAccount;
