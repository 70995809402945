import { createTheme } from "@material-ui/core/styles";
import {
  MOBILE_S_WIDTH,
  MOBILE_M_WIDTH,
  MOBILE_L_WIDTH,
  MOBILE_XL_WIDTH,
  TABLET_WIDTH,
} from "../../constants/screenWidths";
import vars from "./vars";
import colors from "./colors";
import typography from "./typography";
import spacing from "./spacing";
import borders from "./borders";
import shadows from "./shadows";
import { screenWidths, breakpoints } from "./breakpoints";
import { gridBreakpoints, gridScreenWidths } from "./grid-breakpoints";

import gradients from "./gradients";
import setMargin from "./setMargin";

import "../fonts/fonts.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#201751", // Midnight
      light: "#00A9CE", // Cyan
      dark: "#0057A3", // blue
    },
    secondary: {
      main: "#D91473", // Pink
      dark: "#5E2E86", // Purple
      light: "#FFC709", // yellow
    },
  },
  borders: { ...borders },
  colors: {
    ...colors,
  },
  spacing: [...spacing],

  customShadows: {
    ...shadows,
  },
  ...typography,
  screenWidths: { ...screenWidths },
  breakpoints: {
    values: {
      gridTab: 1200,
      xs: MOBILE_S_WIDTH,
      sm: MOBILE_M_WIDTH,
      md: MOBILE_L_WIDTH,
      lg: MOBILE_XL_WIDTH,
      xl: TABLET_WIDTH,
    },
    ...breakpoints,
  },
  gridScreenWidths,
  gridBreakpoints,
  gradients: { ...gradients },
  helpers: {
    setMargin,
  },
  vars,
  overrides: {
    MuiCssBaseline: {
      "@global": {
        // Remove recaptcha badge
        ".grecaptcha-badge": {
          visibility: "hidden",
        },
      },
    },
    MuiInput: {
      underline: {
        borderBottom: "none",
        "&:before": {
          borderBottom: "none",
        },
        "&:after": {
          borderBottom: "none",
        },
        "&:hover:not($disabled):before": {
          borderBottom: "#585858",
        },
      },
      formControl: {
        "label + &": {
          marginTop: "0",
          width: "100%",
        },
      },
    },
    MuiInputBase: {
      input: {
        margin: "0",
        height: "50px",
        padding: "0",
      },
      inputMultiline: {
        margin: "-10px 0",
        height: "80px !important",
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: "translate(0, 1.5px) scale(0.9)",
      },
    },
    MuiInputAdornment: {
      positionStart: {
        display: "none",
      },
      positionEnd: {
        display: "none",
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "none",
        "& b": {
          display: "none",
        },
      },
    },
    MuiTableRow: {
      root: { overflow: "visible" },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        border: "none",
      },
      input: {
        height: "50px",
        padding: "0 15px",
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        fontSize: "16px",
        fontWeight: "500",
      },
      transitionContainer: {
        overflow: "visible",
        height: "30px",
      },
    },
    MuiIconButton: {
      root: {
        color: "none",
        "&:hover": { backgroundColor: "none ", opacity: "0.95" },
        "&:active": { backgroundColor: "none ", opacity: "0.9" },
      },
    },
    MuiTouchRipple: {
      root: { display: "none" },
    },
  },
});

export default theme;
