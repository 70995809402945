import { earnGroups } from "../../api-calls";

import {
  GET_EARN_GROUPS_ADMINS_LOADING_START,
  GET_EARN_GROUPS_ADMINS_LOADING_END,
  GET_EARN_GROUPS_ADMINS_SUCCESS,
  GET_EARN_GROUPS_ADMINS_FAIL,
  ADD_EARN_GROUP_ADMINISTRATOR_LOADING_START,
  ADD_EARN_GROUP_ADMINISTRATOR_LOADING_END,
  ADD_EARN_GROUP_ADMINISTRATOR_SUCCESS,
  ADD_EARN_GROUP_ADMINISTRATOR_FAIL,
  DELETE_ADMINISTRATOR_USER_LOADING_START,
  DELETE_ADMINISTRATOR_USER_LOADING_END,
  DELETE_ADMINISTRATOR_USER_SUCCESS,
  DELETE_ADMINISTRATOR_USER_FAIL,
  CHANGE_MAIN_ACCOUNT_LOADING,
  CHANGE_MAIN_ACCOUNT_SUCCESS,
  CHANGE_MAIN_ACCOUNT_FAIL,
  EARN_GROUP_ACCOUNT_HOLDER_LOGS_LOADING,
  EARN_GROUP_ACCOUNT_HOLDER_LOGS_SUCCESS,
  EARN_GROUP_ACCOUNT_HOLDER_LOGS_FAIL,
} from "../../redux/types";

export const getEarnGroupAdmins = (id) => async (dispatch) => {
  dispatch({ type: GET_EARN_GROUPS_ADMINS_LOADING_START });
  try {
    const { data, error } = await earnGroups.getEarnGroupAdmins({ id });
    if (data) {
      dispatch({ type: GET_EARN_GROUPS_ADMINS_SUCCESS, payload: data });
    } else {
      dispatch({
        type: GET_EARN_GROUPS_ADMINS_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_EARN_GROUPS_ADMINS_FAIL,
      payload: error,
    });
  } finally {
    dispatch({ type: GET_EARN_GROUPS_ADMINS_LOADING_END });
  }
};

export const addNewAdmin =
  ({ email, id }) =>
  async (dispatch) => {
    dispatch({ type: ADD_EARN_GROUP_ADMINISTRATOR_LOADING_START });
    try {
      const { error, data } = await earnGroups.addEarnGroupAdmins({
        email,
        id,
      });
      if (!error) {
        dispatch({
          type: ADD_EARN_GROUP_ADMINISTRATOR_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: ADD_EARN_GROUP_ADMINISTRATOR_FAIL,
          payload: error,
        });
        throw error;
      }
    } catch (error) {
      dispatch({
        type: ADD_EARN_GROUP_ADMINISTRATOR_FAIL,
        payload: error,
      });
      throw error;
    } finally {
      dispatch({ type: ADD_EARN_GROUP_ADMINISTRATOR_LOADING_END });
    }
  };

export const deleteAdministrator =
  ({ earnGroupUserId, administratorId }) =>
  async (dispatch) => {
    dispatch({ type: DELETE_ADMINISTRATOR_USER_LOADING_START });
    try {
      const { error } = await earnGroups.deleteAdministrator({
        earnGroupUserId,
        administratorId,
      });
      if (!error) {
        dispatch({
          type: DELETE_ADMINISTRATOR_USER_SUCCESS,
          payload: administratorId,
        });
      } else {
        dispatch({
          type: DELETE_ADMINISTRATOR_USER_FAIL,
          payload: error,
        });
        throw error;
      }
    } catch (error) {
      dispatch({
        type: DELETE_ADMINISTRATOR_USER_FAIL,
        payload: error,
      });
      throw error;
    } finally {
      dispatch({ type: DELETE_ADMINISTRATOR_USER_LOADING_END });
    }
  };

export const promoteEGAdmin =
  ({ earnGroupUserId, administratorId }) =>
  async (dispatch) => {
    dispatch({ type: CHANGE_MAIN_ACCOUNT_LOADING });
    try {
      const { error, data } = await earnGroups.promoteEGAdmin({
        earnGroupUserId,
        administratorId,
      });

      if (!error) {
        dispatch({
          type: CHANGE_MAIN_ACCOUNT_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: CHANGE_MAIN_ACCOUNT_FAIL,
          payload: error,
        });
        throw error;
      }
    } catch (error) {
      dispatch({
        type: CHANGE_MAIN_ACCOUNT_FAIL,
        payload: error,
      });
      throw error;
    }
  };

export const getEarnGroupAccountHolderLogs =
  ({ earnGroupUserId }) =>
  async (dispatch) => {
    dispatch({ type: EARN_GROUP_ACCOUNT_HOLDER_LOGS_LOADING });
    try {
      const { data, error } = await earnGroups.getEarnGroupAccountHolderLogs({
        earnGroupUserId,
      });
      if (data) {
        dispatch({
          type: EARN_GROUP_ACCOUNT_HOLDER_LOGS_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: EARN_GROUP_ACCOUNT_HOLDER_LOGS_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: EARN_GROUP_ACCOUNT_HOLDER_LOGS_FAIL,
        payload: error,
      });
    }
  };
