import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import roles from "../../../constants/roles";

import { Row, Col } from "../../../components/Grid";
import { InputField, Dropdown } from "../../../components/Inputs";
import Button from "../../../components/Button";

import AdministratorSection from "../../../components/AdministratorSection";
import stripLeadingTab from "../../../utils/helpers/stripLeadingTab";
import PasswordChange from "./PasswordChange";

import t from "../../../utils/helpers/translator";

import * as S from "./style";

import {
  fields,
  device,
  headlineComponents,
  subHeadlineComponents,
  initStates,
} from "./data";

const {
  firstName,
  lastName,
  email,
  orgName,
  region,
  orgType,
  localAuthority,
  focus,
  phoneNumber,
} = fields;

const EarnGroupAccount = ({
  isMobile,
  onChange,
  onSelectChange,
  handleClick,
  errors,
  showNotification,
  userAccount,
  isUpdating,
  btnOn,
  role,
  mainError,
  language,
}) => {
  const Headline = headlineComponents[device(isMobile)];
  const SubHeadline = subHeadlineComponents[device(isMobile)];

  const [state, setState] = useState({
    ...initStates.earnGroup,
  });

  // set state with user data
  useEffect(() => {
    if (userAccount.data) {
      setState({
        ...userAccount.data,
        phoneNumber: stripLeadingTab(userAccount.data.phoneNumber),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedRegion =
    (state.region && {
      label:
        language === "english" ? state.region : region.welshMap[state.region],
      value: state.region,
    }) ||
    undefined;

  return role === roles.EARN_GROUP_ADMIN ? (
    <>
      {/* Account details */}
      <Row>
        <Col w={[4, 12, 12]}>
          <Headline color="midnight">{t("myAccount", language)}</Headline>
          <SubHeadline
            color="midnight"
            style={{
              marginTop: isMobile ? "2.5rem" : "3.5rem",
              fontWeight: "900",
            }}
          >
            {t("accountDetails", language)}
          </SubHeadline>
        </Col>
      </Row>

      {/* Fields */}
      <Row>
        <Col w={[4, 6, 4]}>
          <InputField
            mt={!isMobile ? 6 : 4}
            label={t("firstName", language)}
            type={firstName.type}
            placeholder={`${t("typeFirstNameHere", language)}...`}
            name={firstName.name}
            setValue={(e) => onChange(e, setState, state)}
            value={state.firstName || ""}
            error={errors.firstName}
          />
        </Col>
        <Col w={[4, 6, 4]}>
          <InputField
            mt={!isMobile ? 6 : 4}
            label={t("lastName", language)}
            type={lastName.type}
            placeholder={`${t("typeLastNameHere", language)}...`}
            name={lastName.name}
            setValue={(e) => onChange(e, setState, state)}
            value={state.lastName || ""}
            error={errors.lastName}
          />
        </Col>
      </Row>
      <Row>
        <Col w={[4, 6, 4]}>
          <InputField
            mt={6}
            label={t("emailAddress", language)}
            type={email.type}
            placeholder={`${t("typeEmailHere", language)}...`}
            name={email.name}
            setValue={(e) => onChange(e, setState, state)}
            value={state.email || ""}
            error={
              errors.email ||
              (mainError?.data?.field === "email" &&
                t(mainError.message, language))
            }
          />
        </Col>
      </Row>

      <Row
        style={{
          marginTop: !isMobile ? "60px" : "2rem",
        }}
      >
        <Col w={[4, 6, 4]}>
          <Button
            primary
            size="l"
            type="submit"
            handleClick={(e) => handleClick({ e, state })}
            loading={isUpdating}
            disabled={!btnOn}
          >
            {t("saveChanges", language)}
          </Button>
          {showNotification(t("profileSaved", language))}
        </Col>
      </Row>
      <PasswordChange />
    </>
  ) : (
    <>
      {/* Account details */}
      <Row>
        <Col w={[4, 12, 12]}>
          <Headline color="midnight">{t("myAccount", language)}</Headline>
          <SubHeadline
            color="midnight"
            style={{
              marginTop: isMobile ? "2.5rem" : "3.5rem",
              fontWeight: "900",
            }}
          >
            {t("accountDetails", language)}
          </SubHeadline>
        </Col>
      </Row>

      {/* Fields */}
      <Row>
        <Col w={[4, 6, 6]}>
          <InputField
            mt={6}
            label={t("emailAddress", language)}
            type={email.type}
            placeholder={t("emailAddress", language)}
            name={email.name}
            setValue={(e) => onChange(e, setState, state)}
            value={state.email || ""}
            error={
              errors.email ||
              (mainError?.data?.field === "email" &&
                t(mainError.message, language))
            }
          />
        </Col>
      </Row>
      <Row>
        <Col w={[4, 6, 6]}>
          <InputField
            mt={!isMobile ? 6 : 4}
            label={t("firstName", language)}
            type={firstName.type}
            placeholder={`${t("typeFirstNameHere", language)}...`}
            name={firstName.name}
            setValue={(e) => onChange(e, setState, state)}
            value={state.firstName || ""}
            error={errors.firstName}
          />
        </Col>
        <Col w={[4, 6, 6]}>
          <InputField
            mt={!isMobile ? 6 : 4}
            label={t("lastName", language)}
            type={lastName.type}
            placeholder={`${t("typeLastNameHere", language)}...`}
            name={lastName.name}
            setValue={(e) => onChange(e, setState, state)}
            value={state.lastName || ""}
            error={errors.lastName}
          />
        </Col>
      </Row>

      {/* Organisation details */}
      <Row>
        <Col w={[4, 12, 12]}>
          <SubHeadline
            color="midnight"
            style={{
              marginTop: isMobile ? "2.5rem" : "3.5rem",
              fontWeight: "900",
            }}
          >
            {t("orgDetails", language)}
          </SubHeadline>
        </Col>
      </Row>

      {/* Fields */}
      <Row>
        <Col w={[4, 6, 6]}>
          <InputField
            mt={!isMobile ? 6 : 4}
            label={t("nameOfOrg", language)}
            type={orgName.type}
            placeholder={`${t("typeHere", language)}...`}
            name={orgName.name}
            setValue={(e) => onChange(e, setState, state)}
            value={state.organisationName || ""}
            error={errors[orgName.name]}
          />
        </Col>
        <Col w={[4, 6, 6]}>
          <Dropdown
            mt={!isMobile ? 6 : 4}
            label={t("region", language)}
            name={region.name}
            placeholder={t("region", language)}
            options={
              language === "welsh" ? region.optionsWelsh : region.options
            }
            maxWidth="100%"
            height="57px"
            setValue={({ selected }) =>
              onSelectChange(
                { value: selected, name: region.name },
                setState,
                state,
              )
            }
            value={selectedRegion}
            error={errors[region.name]}
          />
        </Col>
      </Row>
      <Row>
        <Col w={[4, 6, 6]}>
          <Dropdown
            mt={!isMobile ? 6 : 4}
            label={t("typeOfOrg", language)}
            name={orgType.name}
            placeholder={t("typeOfOrg", language)}
            options={
              language === "welsh" ? orgType.optionsWelsh : orgType.options
            }
            maxWidth="100%"
            height="57px"
            setValue={({ selected }) =>
              onSelectChange(
                {
                  value: selected.value,
                  name: orgType.name,
                },
                setState,
                state,
              )
            }
            value={
              (state.organisationType && {
                value: state.organisationType,
                label:
                  language === "english"
                    ? state.organisationType
                    : orgType.welshMap[state.organisationType],
              }) ||
              ""
            }
            error={errors[orgType.name]}
          />
        </Col>
        <Col w={[4, 6, 6]}>
          <Dropdown
            mt={!isMobile ? 6 : 4}
            label={t("localAuthority", language)}
            name={localAuthority.name}
            placeholder={t("localAuthority", language)}
            options={
              language === "welsh"
                ? localAuthority.optionsWelsh
                : localAuthority.options
            }
            maxWidth="100%"
            height="57px"
            setValue={({ selected }) =>
              onSelectChange(
                { value: selected.value, name: localAuthority.name },
                setState,
                state,
              )
            }
            value={
              (state.localAuthority && {
                value: state.localAuthority,
                label:
                  language === "english"
                    ? state.localAuthority
                    : localAuthority.welshMap[state.localAuthority],
              }) ||
              ""
            }
            error={errors[localAuthority.name]}
          />
        </Col>
      </Row>
      <Row>
        <Col w={[4, 6, 6]}>
          <Dropdown
            mt={!isMobile ? 6 : 4}
            label={t("whatFocus", language)}
            name={focus.name}
            placeholder={`${t("selectAsManyLike", language)}...`}
            options={language === "welsh" ? focus.optionsWelsh : focus.options}
            maxWidth="100%"
            height="auto"
            isMulti
            setValue={({ selected }) =>
              onSelectChange(
                { value: selected, name: focus.name },
                setState,
                state,
              )
            }
            value={
              state.focusFields &&
              state.focusFields.map((e) => ({
                value: e,
                label: language === "english" ? e : focus.welshMap[e],
              }))
            }
            error={errors[focus.name]}
          />
        </Col>

        <Col w={[4, 6, 6]}>
          <InputField
            mt={!isMobile ? 6 : 4}
            label={t("phoneNumber", language)}
            type={phoneNumber.type}
            placeholder={`${t("typePhoneNumber", language)}...`}
            name={phoneNumber.name}
            setValue={(e) => onChange(e, setState, state)}
            value={state.phoneNumber || ""}
            error={errors[phoneNumber.name]}
          />
        </Col>
      </Row>

      <Row
        style={{
          marginTop: !isMobile ? "60px" : "80px",
        }}
      >
        <Col w={[4, 12, 12]}>
          {mainError && mainError.message && (
            <S.Error>{t(mainError.message, language)}</S.Error>
          )}
        </Col>
        <Col w={[4, 6, 6]}>
          <Button
            primary
            size="l"
            type="submit"
            handleClick={(e) => handleClick({ e, state })}
            loading={isUpdating}
            disabled={!btnOn}
          >
            {t("saveChanges", language)}
          </Button>
          {showNotification(t("profileSaved", language))}
        </Col>
      </Row>
      <PasswordChange />
      <AdministratorSection />
    </>
  );
};

const mapStateToProps = (state) => ({ language: state.auth.language });

export default connect(mapStateToProps)(EarnGroupAccount);
