import React from "react";
import { connect } from "react-redux";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import Icon from "../Icon";
import * as T from "../Typograpy";

import translate from "../../utils/helpers/translator";

const ShareToSocialMedia = ({ language }) => {
  const currentUrl = window.location.href;
  return (
    <div>
      <T.H22 color="midnight" mb="20" mt="20">
        {translate("shareActivity", language)}
      </T.H22>
      <FacebookShareButton
        url={currentUrl}
        style={{
          outline: "none",
        }}
      >
        <Icon
          icon="facebook"
          color="blue"
          width="34.48px"
          height="34.48px"
          margin="0 15px 0 0"
        />
      </FacebookShareButton>
      <TwitterShareButton
        url={currentUrl}
        style={{
          outline: "none",
        }}
      >
        <Icon
          icon="twitter"
          color="blue"
          width="31px"
          height="31px"
          margin="0 15px 0 0"
        />
      </TwitterShareButton>

      <LinkedinShareButton
        url={currentUrl}
        style={{
          outline: "none",
        }}
      >
        <Icon
          icon="linkedin"
          color="blue"
          width="34.48px"
          height="34.48px"
          margin="0 15px 0 0"
        />
      </LinkedinShareButton>
    </div>
  );
};

const mapStateToProps = (state) => ({
  language: state.auth.decideLanguage(state.auth),
});

export default connect(mapStateToProps, {})(ShareToSocialMedia);
