export const DEFAULT_REQUIRED = "fieldRequired";
export const SHORT_PASSWORD = "passwordMustContain";
export const INVALID_EMAIL = "invalidEmail";
export const INVALID_PHONE = "invalidPhone";
export const INVALID_ROLE = "invalidRole";
export const INVALID_URL = "invalidUrl";
export const NO_PARTICIPANTS = "selectParticipantsNumber";
export const SHOULD_AGREE_ON_TERMS = "mustAgreeTC";
export const SHOULD_CONFIRM_PERMISSION = "mustConfirmPermission";
export const TOO_SHORT_MIN_1 = "tooShort1";
export const TOO_SHORT_MIN_5 = "tooShort5";
export const TOO_LONG_MAX_7 = "tooLong7";
export const TOO_LONG_MAX_50 = "tooLong50";
export const TOO_LONG_MAX_100 = "tooLong100";
export const TOO_LONG_MAX_500 = "tooLong500";
export const INVALID_POSTCODE = "invalidPostcode";
export const MEMBERSHIP_LENGTH = "membershipIDShouldBe";
export const MORE_THAN_ZERO = "shouldBeMoreThanZero";
export const ONE_OF_TYPES = "shouldBeValidType";
export const AGREED_AGE = "pleaseIndicateOver16";
export const END_DATE_FUTURE = "spendActivityCannotBeMadeLive";
export const MULTI_EMAILS = "oneAboveInvalidEmail";
export const ARRAY_REQUIRED = "selectOneItem";
export const LANGUAGE_ERROR = "noVersionInLanguage";
export const SHOULD_BE_GREATER_THAN_0_OR_EMPTY = "shouldBeGreaterThan0OrEmpty";
export const MAX_SHOULD_BE_GRATER_THAN_MIN =
  "the maximum number should be greater than the minimum number";
export const SHOULD_BE_GRATER_THAN_MIN =
  "the amount should be greater than the minimum number";
export const SHOULD_BE_LESS_THAN_MAX =
  "the amount should be less than the maximum number";
export const SHOULD_BE_GREATER_THAN_MIN_VALUE = "shouldBeGreaterThanMinValue";
