import {
  DUPLICATE_SPEND_ACTIVITY_LOADING,
  DUPLICATE_SPEND_ACTIVITY_SUCCESS,
  DUPLICATE_SPEND_ACTIVITY_FAIL,
  RESET_DUPLICATE_SPEND_ACTIVITY_STATE,
} from "../../../../redux/types";

import { activities } from "../../../../api-calls";

export const duplicateSpendActivity =
  ({
    spendActivityId,
    spendVenueIdUserId,
    copyDates,
    copyBookingCodes,
    copyUsedBookingCodes,
  }) =>
  async (dispatch) => {
    dispatch({ type: DUPLICATE_SPEND_ACTIVITY_LOADING });

    const { data, error } = await activities.duplicateSpendActivity({
      spendActivityId,
      spendVenueIdUserId,
      copyDates,
      copyBookingCodes,
      copyUsedBookingCodes,
    });

    if (!error) {
      dispatch({
        type: DUPLICATE_SPEND_ACTIVITY_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: DUPLICATE_SPEND_ACTIVITY_FAIL,
        payload: error,
      });
    }
  };

export const resetState = () => ({
  type: RESET_DUPLICATE_SPEND_ACTIVITY_STATE,
});
