import {
  GET_SPEND_ACTIVITY_ADMIN_LOADING_START,
  GET_SPEND_ACTIVITY_ADMIN_LOADING_END,
  GET_SPEND_ACTIVITY_ADMIN_SUCCESS,
  GET_SPEND_ACTIVITY_ADMIN_FAIL,
  UPDATE_SPEND_ACTIVITY_ADMIN_LOADING_START,
  UPDATE_SPEND_ACTIVITY_ADMIN_LOADING_END,
  UPDATE_SPEND_ACTIVITY_ADMIN_SUCCESS,
  UPDATE_SPEND_ACTIVITY_ADMIN_FAIL,
  RESET_SPEND_ACTIVITY_ADMIN,
  DELETE_SPEND_ACTIVITY_LOADING,
  DELETE_SPEND_ACTIVITY_SUCCESS,
  DELETE_SPEND_ACTIVITY_FAIL,
} from "../../redux/types";

const initialState = {
  spendActivity: {},
  getSpendActivityLoading: false,
  getSpendActivityError: {},
  loaded: false,
  updateSpendActivityLoading: false,
  updateSpendActivityError: {},
  updatedSuccessfully: false,
  welshRequiredFieldsPresent: null,
  welshProfilePresents: null,
  role: null,
  deleteActivityLoading: false,
  deleteActivitySuccess: false,
  deleteActivityError: {},
  deleteActivitySuccessData: {}, // contains spend venue/earn group id
};

const spendActivity = (state = initialState, action) => {
  switch (action.type) {
    case GET_SPEND_ACTIVITY_ADMIN_LOADING_START:
      return { ...state, getSpendActivityLoading: true };

    case GET_SPEND_ACTIVITY_ADMIN_LOADING_END:
      return { ...state, getSpendActivityLoading: false };

    case GET_SPEND_ACTIVITY_ADMIN_SUCCESS:
      return {
        ...state,
        spendActivity: action.payload,
        loaded: true,
      };

    case GET_SPEND_ACTIVITY_ADMIN_FAIL:
      return { ...state, getSpendActivityError: action.payload };

    case UPDATE_SPEND_ACTIVITY_ADMIN_LOADING_START:
      return {
        ...state,
        updateSpendActivityLoading: true,
        updatedSuccessfully: false,
        updateSpendActivityError: {},
        welshRequiredFieldsPresent: null,
        welshProfilePresents: null,
        role: null,
      };

    case UPDATE_SPEND_ACTIVITY_ADMIN_LOADING_END:
      return { ...state, updateSpendActivityLoading: false };

    case UPDATE_SPEND_ACTIVITY_ADMIN_SUCCESS:
      return {
        ...state,
        updatedSuccessfully: true,
        welshRequiredFieldsPresent: action.payload?.welshRequiredFieldsPresent,
        welshProfilePresents: action.payload?.welshProfilePresents,
        role: action.payload?.role,
      };

    case UPDATE_SPEND_ACTIVITY_ADMIN_FAIL:
      return {
        ...state,
        updateSpendActivityError: action.payload,
        updatedSuccessfully: false,
      };
    case DELETE_SPEND_ACTIVITY_LOADING:
      return {
        ...state,
        deleteActivityLoading: action.payload,
      };
    case DELETE_SPEND_ACTIVITY_SUCCESS:
      return {
        ...state,
        deleteActivityLoading: false,
        deleteActivitySuccess: true,
        deleteActivitySuccessData: action.payload,
        deleteActivityError: {},
      };
    case DELETE_SPEND_ACTIVITY_FAIL:
      return {
        ...state,
        deleteActivityLoading: false,
        deleteActivitySuccess: false,
        deleteActivityError: action.payload,
      };

    case RESET_SPEND_ACTIVITY_ADMIN:
      return { ...state, ...initialState };

    default:
      return state;
  }
};

export default spendActivity;
