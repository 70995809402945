import React from "react";

const SupportingOthers = ({ width, height, color, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-1 2 25 24"
    width={width}
    height={height}
    {...props}
  >
    <path
      d="M23.49 15.408a1.717 1.717 0 00-1.242-2.938c-1.82 0-1.552 1.394-1.552-.414a.414.414 0 00-.414-.414h-7.037V9.986a.414.414 0 00-.413-.414H3.725a.414.414 0 00-.414.414v2.897A2.07 2.07 0 000 14.54v3.312a2.07 2.07 0 003.311 1.655v1.242a1.656 1.656 0 001.656 1.656h6.623a1.656 1.656 0 001.655-1.656c0-1.209-.206-.199 1.4.509a.414.414 0 00.256.733h3.312a.414.414 0 00.256-.733 3.725 3.725 0 002.17-2.786 1.14 1.14 0 00.827.365c1.13 0 1.482-1.524.708-1.524a.414.414 0 00-.414.414.29.29 0 01-.294.282c-.22 0-.414-.236-.215-.414l2.24-2.186zm-11.072 2.79c-3.146-1.477-3.026-4.487-1.242-4.487.571 0 .828.356 1.242.787v3.7zm.828-3.278c.413-.414.55-.658 1.105-.658 1.37 0 1.527 2.504-1.097 3.791-.008-.203-.008.17-.008-3.133zM2.07 19.092a1.242 1.242 0 01-1.242-1.241v-3.312a1.245 1.245 0 012.483-.165v3.642a1.242 1.242 0 01-1.241 1.076zm9.52 2.484H4.967a.828.828 0 01-.828-.828V10.4h8.279v2.939a1.954 1.954 0 00-1.325-.456c-2.715 0-2.988 4.496 1.325 6.21v1.655a.828.828 0 01-.828.828zm8.278-3.726a2.898 2.898 0 01-2.897 2.898h-.828a2.897 2.897 0 01-2.703-1.867c3.61-1.627 3.311-5.447.828-5.447-.37.003-.728.122-1.027.34V12.47h6.627v5.38zm.828-.873V14.73c0-1.655 2.484-1.916 2.484-.542 0 .489-.096.46-2.484 2.79zM6.747 6.898a.414.414 0 00.29.708c.579 0 1.2-1.453.29-2.364a.757.757 0 010-1.067.414.414 0 00-.58-.588 1.585 1.585 0 000 2.243.757.757 0 010 1.068zM9.23 6.898a.414.414 0 00.29.708c.58 0 1.2-1.453.29-2.364a.758.758 0 010-1.067.414.414 0 00-.58-.588 1.585 1.585 0 000 2.243.757.757 0 010 1.068zM15.026 9.382a.414.414 0 00.29.707c.579 0 1.2-1.452.29-2.363a.758.758 0 010-1.068.414.414 0 00-.585-.588 1.584 1.584 0 000 2.244.757.757 0 01.005 1.068zM17.509 9.382a.414.414 0 00.29.707c.58 0 1.2-1.452.29-2.363a.758.758 0 010-1.068.414.414 0 00-.584-.588 1.584 1.584 0 000 2.244.757.757 0 01.004 1.068z"
      fill={color || "currentColor"}
    />
  </svg>
);
export default SupportingOthers;
