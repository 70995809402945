import React from "react";

import * as S from "./style";
import * as T from "../Typograpy";

import translate from "../../utils/helpers/translator";

const TimeListing = ({ startTime, endTime, title, subtitle, w, m, lang }) => {
  const formattedTime = (time) => {
    const timeArr = time.split(":");
    return `${timeArr[0]}:${timeArr[1]}`;
  };
  return (
    <S.Wrapper w={w} m={m}>
      <S.Line />
      <S.Details>
        <S.Time>
          <T.H20 m="0 5px 0 0">
            {startTime ? formattedTime(startTime) : translate("anytime", lang)}
          </T.H20>
          {endTime && <T.Body14S>- {formattedTime(endTime)}</T.Body14S>}
        </S.Time>
        <S.Body>
          <T.Body16R>{title}</T.Body16R>
          <T.Body12R color="gray3">{subtitle}</T.Body12R>
        </S.Body>
      </S.Details>
    </S.Wrapper>
  );
};

export default TimeListing;
