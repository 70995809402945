import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { GENERAL } from "../../constants/navRoutes";
import userRoles from "../../constants/roles";
import cleanWhitespace from "../../utils/helpers/cleanWhitespace";

import { signup as signupAction } from "../../redux/actions/auth";

import { H60Caps, Body16R } from "../../components/Typograpy";
import InputField from "../../components/Inputs/InputField";
import { Regular } from "../../components/Button";
import { ReCaptcha } from "../../components/TextSections";

import validate from "../../validation/schemas/signup";

import {
  GridItem,
  InputContainer,
  ButtonContainer,
  MessageContainer,
  FormContainer,
  ExistsError,
  SignupForm,
  GridItemCaptcha,
} from "./style";

import {
  GridContent,
  TextLinks,
  Error,
  Title,
  StyledLink,
} from "./customSignupStyle";
import Image from "../../components/Image";

const useQuery = () => new URLSearchParams(useLocation().search);

const SignUpExtProgManager = ({
  handleSignup,
  signupLoading,
  signupError,
  lang,
  isMobile,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [submitAttempt, setSubmitAttempt] = useState(false);
  const history = useHistory();
  const query = useQuery();
  const inviteToken = query.get("programmeManager");

  const searchParams = new URLSearchParams();
  if (inviteToken) searchParams.append("programmeManager", inviteToken);

  const [errors, setErrors] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  });

  const partialValidate = (name, value) => {
    try {
      if (submitAttempt) {
        validate(
          {
            [name]: value,
          },
          true,
        );

        setErrors((_errors) => ({ ..._errors, [name]: "" }));
      }
    } catch (err) {
      if (err.name === "ValidationError") {
        setErrors((_errors) => ({ ..._errors, [name]: err.inner[name] }));
      }
    }
  };

  const onChange = (e) => {
    partialValidate(e.target.name, e.target.value);
    switch (e.target.name) {
      case "email":
        setEmail(e.target.value);
        break;
      case "password":
        setPassword(e.target.value);
        break;
      case "firstName":
        setFirstName(e.target.value);
        break;
      case "lastName":
        setLastName(e.target.value);
        break;

      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      setSubmitAttempt(true);
      const cleanEmail = email.toLowerCase().trim();

      validate({
        email: cleanEmail,
        password,
        firstName,
        lastName,
        token: inviteToken,
        role: userRoles.PROGRAMME_MANAGER,
      });
      setErrors({});

      handleSignup(
        {
          email: cleanEmail,
          password,
          firstName: cleanWhitespace(firstName),
          lastName: cleanWhitespace(lastName),
          inviteToken,
          role: userRoles.PROGRAMME_MANAGER,
        },
        history,
      );
    } catch (err) {
      if (err.name === "ValidationError") {
        setErrors(err.inner);
      }
    }
  };

  const renderMainParagraph = () => {
    if (!inviteToken) {
      return (
        <MessageContainer ta="center">
          <Body16R color="gray3" mb="3" ml="1">
            Sorry, you can only sign up if you are invited by Tempo staff.
          </Body16R>
        </MessageContainer>
      );
    }
  };

  return (
    <Grid container md={12} xl={12} xs={12}>
      <FormContainer item container>
        <GridContent container>
          <Title>
            <H60Caps color="primary">Sign up</H60Caps>
          </Title>

          <TextLinks>
            Already have a Tempo account?
            <StyledLink
              to={{
                pathname: GENERAL.LOG_IN,
                search: searchParams.toString(),
              }}
            >
              Log in
            </StyledLink>
          </TextLinks>
          {renderMainParagraph()}
          <SignupForm>
            <GridItem>
              <InputContainer>
                <InputField
                  placeholder="Type your first name here..."
                  label="First Name"
                  name="firstName"
                  value={firstName}
                  setValue={onChange}
                  error={errors.firstName}
                  justify="center"
                />
              </InputContainer>
            </GridItem>
            <GridItem>
              <InputContainer>
                <InputField
                  placeholder="Type your last name here..."
                  label="Last Name"
                  name="lastName"
                  value={lastName}
                  setValue={onChange}
                  error={errors.lastName}
                />
              </InputContainer>
            </GridItem>
            <GridItem>
              <InputContainer>
                <InputField
                  placeholder="Type your email here..."
                  label="Email"
                  name="email"
                  value={email}
                  setValue={onChange}
                  error={errors.email}
                />
              </InputContainer>
              {signupError && signupError.httpStatusCode === 409 ? (
                <ExistsError>{signupError.message}</ExistsError>
              ) : null}
            </GridItem>
            <GridItem>
              <InputContainer>
                <InputField
                  type="password"
                  placeholder="Create a password here..."
                  label="Password"
                  name="password"
                  value={password}
                  setValue={onChange}
                  error={errors.password}
                  showPasswordPop
                  lang={lang}
                />
              </InputContainer>
            </GridItem>

            {signupError && !signupError.httpStatusCode === 409 ? (
              <GridItem>
                <Error>{signupError.message}</Error>
              </GridItem>
            ) : null}

            <ButtonContainer marginTop={20}>
              <Regular
                bgColor="blue"
                primary
                handleClick={handleSubmit}
                style={{ margin: 0 }}
                loading={signupLoading}
                type="submit"
                disabled={!inviteToken}
              >
                Sign up
              </Regular>
            </ButtonContainer>
            <GridItemCaptcha>
              <ReCaptcha />
            </GridItemCaptcha>
          </SignupForm>
        </GridContent>
      </FormContainer>
      <Grid
        item
        container
        justify="center"
        md={4}
        style={{ display: isMobile && "none" }}
      >
        <Grid
          container
          item
          justify="center"
          alignItems="center"
          style={{ display: isMobile && "none" }}
        >
          <Image image="welcomeMember1" alt="tempo logo" />
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  signupLoading: state.auth.signupLoading,
  signupError: state.auth.signupError,
  lang: state.auth.language,
  isMobile: state.ui.isMobile,
});

const mapActionToProps = {
  handleSignup: signupAction,
};

export default connect(mapStateToProps, mapActionToProps)(SignUpExtProgManager);
