export const truncatePostcode = (postcode) => {
  if (postcode) {
    const trimPostcode = postcode.replace(/ /g, "");
    switch (trimPostcode.length) {
      case 5:
        return trimPostcode.substr(0, 2);
      case 6:
        return trimPostcode.substr(0, 3);
      case 7:
        return trimPostcode.substr(0, 4);
      default:
        return trimPostcode.substr(0, 3);
    }
  }
};
