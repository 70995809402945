import React from "react";
import { connect } from "react-redux";
import { Route as RouterRoute, Redirect, useLocation } from "react-router-dom";
import Loading from "../Loading";
import Layout from "../Layout";
import {
  GENERAL,
  MEMBER,
  EARN_GROUP,
  SPEND_VENUE,
  TEMPO,
  PROGRAMME_MANAGER,
  SPEND_PARTNER,
} from "../../constants/navRoutes";
import { roles } from "../../constants";

import authorization from "../../utils/helpers/authorization";

const useQuery = () => new URLSearchParams(useLocation().search);

const Route = (props) => {
  const {
    isMounted,
    isPrivate,
    layout,
    path,
    isLoggedIn,
    role,
    Component,
    exact,
    allowedRoles,
    isVerified,
    allowTemporaryLogin = false,
  } = props;

  const query = useQuery();
  const inviteToken = query.get("invite");
  const giftToken = query.get("gift");
  const administratorToken = query.get("administrator");
  const location = useLocation();
  const activateAccountToken =
    query.get("activateAccountToken") ||
    (path === GENERAL.ACTIVATE_ACCOUNT_CHECK_TOKEN &&
      location?.pathname?.split("/")?.slice(-1)?.[0]);

  const searchParams = new URLSearchParams();
  if (activateAccountToken)
    searchParams.append("activateAccountToken", activateAccountToken);

  // ADDING IN A REDIRECT TO LOG IN WHILE WE DON'T HAVE A LOG IN PAGE
  if (path === GENERAL.HOME_URL) {
    return (
      <Redirect
        to={{ pathname: GENERAL.LOG_IN, search: searchParams.toString() }}
      />
    );
  }

  if (!isMounted) return <Loading center />;

  if (isPrivate) {
    const authorized = authorization(role, allowedRoles);

    if (isLoggedIn) {
      if (role === roles.EARN_GROUP || role === roles.SPEND_VENUE) {
        if (!isVerified && !allowTemporaryLogin) {
          return <Redirect to={SPEND_VENUE.ACCEPT_TC} {...props} />;
        }
      }

      return (
        <Layout layout={layout} {...props}>
          <RouterRoute
            path={path}
            props
            render={(LinkProps) =>
              authorized ? (
                <Component role={role} {...LinkProps} {...props} />
              ) : (
                <Redirect to={GENERAL.UNAUTHORIZED} {...LinkProps} {...props} />
              )
            }
            exact={exact}
          />
        </Layout>
      );
    }

    return (
      <Redirect
        to={{ pathname: GENERAL.LOG_IN, search: searchParams.toString() }}
      />
    );
  }

  if (
    isLoggedIn &&
    path === GENERAL.LOG_IN &&
    !inviteToken &&
    !giftToken &&
    !administratorToken
  ) {
    let newPath = "";
    switch (role) {
      case roles.MEMBER:
        newPath = MEMBER.DASHBOARD;
        break;
      case roles.EARN_GROUP:
      case roles.EARN_GROUP_ADMIN:
        newPath = EARN_GROUP.DASHBOARD;
        break;
      case roles.SPEND_VENUE:
        newPath = SPEND_VENUE.ACCEPT_TC;
        break;

      case roles.PROGRAMME_MANAGER:
        newPath = PROGRAMME_MANAGER.DASHBOARD;
        break;

      case roles.SPEND_PARTNER:
        newPath = SPEND_PARTNER.SPEND_VENUES;
        break;

      default:
        newPath = TEMPO.DASHBOARD;
        break;
    }

    if (role === roles.EARN_GROUP || role === roles.SPEND_VENUE) {
      if (!isVerified && !allowTemporaryLogin) {
        newPath = SPEND_VENUE.ACCEPT_TC;
      }
    }

    return (
      <Redirect to={{ pathname: newPath, search: searchParams.toString() }} />
    );
  }

  return (
    <Layout layout={layout} {...props}>
      <RouterRoute
        path={path}
        props
        render={(LinkProps) => <Component {...LinkProps} {...props} />}
        exact={exact}
      />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  role: state.auth.role,
  isLoggedIn: state.auth.isLoggedIn,
  isMounted: state.auth.isMounted,
  isVerified: state.auth.isVerified,
});

export default connect(mapStateToProps)(Route);
