import Welsh from "../../constants/translations/welsh";
import t from "./translator";

const {
  todayAt,
  January,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
  tomorrowAt,
  yesterdayAt,
  at,
  lastTo,
} = Welsh;
export default (language) => ({
  months: [
    January,
    February,
    March,
    April,
    May,
    June,
    July,
    August,
    September,
    October,
    November,
    December,
  ],

  monthsShort: [
    January,
    February,
    March,
    April,
    May,
    June,
    July,
    August,
    September,
    October,
    November,
    December,
  ],
  monthsParseExact: true,
  weekdays: [
    t("Monday", language),
    t("Tuesday", language),
    t("Wednesday", language),
    t("Thursday", language),
    t("Friday", language),
    t("Saturday", language),
    t("Sunday", language),
  ],
  weekdaysShort: [
    t("Sun", language),
    t("Mon", language),
    t("Tue", language),
    t("Wed", language),
    t("Thu", language),
    t("Fri", language),
    t("Sat", language),
  ],
  weekdaysMin: [
    t("Monday", language),
    t("Tuesday", language),
    t("Wednesday", language),
    t("Thursday", language),
    t("Friday", language),
    t("Saturday", language),
    t("Sunday", language),
  ],
  weekdaysParseExact: true,
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD/MM/YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY HH:mm",
    LLLL: "dddd D MMMM YYYY HH:mm",
  },
  calendar: {
    sameDay: `[${todayAt}] LT`,
    nextDay: `[${tomorrowAt}] LT`,
    nextWeek: `dddd [${at}] LT`,
    lastDay: `[${yesterdayAt}] LT`,
    lastWeek: `dddd [${lastTo}] LT`,
    sameElse: "L",
  },
  // relativeTime: {
  //   future: "dans %s",
  //   past: "il y a %s",
  //   s: "quelques secondes",
  //   m: "une minute",
  //   mm: "%d minutes",
  //   h: "une heure",
  //   hh: "%d heures",
  //   d: "un jour",
  //   dd: "%d jours",
  //   M: "un mois",
  //   MM: "%d mois",
  //   y: "un an",
  //   yy: "%d ans",
  // },
  // dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
  // ordinal(number) {
  //   return number + (number === 1 ? "er" : "e");
  // },
  // meridiemParse: /PD|MD/,
  // isPM(input) {
  //   return input.charAt(0) === "M";
  // },
  // // In case the meridiem units are not separated around 12, then implement
  // // this function (look at locale/id.js for an example).
  // // meridiemHour : function (hour, meridiem) {
  // //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
  // // },
  // meridiem(hours, minutes, isLower) {
  //   return hours < 12 ? "PD" : "MD";
  // },
  // week: {
  //   dow: 1, // Monday is the first day of the week.
  //   doy: 4, // Used to determine first week of the year.
  // },
});
