import {
  GET_PAID_PACKAGES_LOADING,
  GET_PAID_PACKAGES_SUCCESS,
  GET_PAID_PACKAGES_FAIL,
  GET_ACTIVE_TC_PRICE_LOADING,
  GET_ACTIVE_TC_PRICE_SUCCESS,
  GET_ACTIVE_TC_PRICE_FAIL,
  UPDATE_PACKAGES_LOADING,
  UPDATE_PACKAGES_FAIL,
  UPDATE_PACKAGES_SUCCESS,
  CONFIRM_ORDER_INFO_LOADING,
  CONFIRM_ORDER_INFO_SUCCESS,
  CONFIRM_ORDER_INFO_FAIL,
  GET_ORDER_INFO_LOADING,
  GET_ORDER_INFO_SUCCESS,
  GET_ORDER_INFO_FAIL,
} from "../types";

const initState = {
  getPackagesLoading: false,
  packages: [],
  getPackagesError: {},
  getPackagesLoaded: false,
  activePrice: {
    id: null,
    price: null,
    minimumTc: null,
    maximumTc: null,
    vat: null,
  },
  getPriceError: {},
  getPriceLoading: {},
  getPriceLoaded: false,
  //
  updatedSuccessfully: false,
  updateError: {},
  updateLoading: false,
  //
  confirmLoading: false,
  confirmError: {},
  confirmedSuccessfully: false,
  //
  getOrderLoading: false,
  getOrderError: {},
  order: {},
};

const paidPackagesReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PAID_PACKAGES_LOADING:
      return {
        ...state,
        getPackagesLoading: payload,
        confirmError: {},
      };
    case GET_PAID_PACKAGES_SUCCESS:
      return {
        ...state,
        packages: payload,
        getPackagesLoaded: true,
        getPackagesError: {},
      };

    case GET_PAID_PACKAGES_FAIL:
      return {
        ...state,
        packages: [],
        getPackagesError: payload,
      };

    case GET_ACTIVE_TC_PRICE_LOADING:
      return {
        ...state,
        getPriceLoading: payload,
      };

    case GET_ACTIVE_TC_PRICE_SUCCESS:
      return {
        ...state,
        activePrice: payload,
        getPriceError: {},
        getPriceLoaded: true,
      };
    case GET_ACTIVE_TC_PRICE_FAIL:
      return {
        ...state,
        activePrice: {},
        getPriceError: payload,
      };

    case UPDATE_PACKAGES_LOADING:
      return {
        ...state,
        updatePackagesLoading: true,
        error: null,
        updatedSuccessfully: false,
      };

    case UPDATE_PACKAGES_SUCCESS:
      return {
        ...state,
        updatePackagesLoading: false,
        updateError: null,
        updatedSuccessfully: true,
      };

    case UPDATE_PACKAGES_FAIL:
      return {
        ...state,
        updatePackagesLoading: false,
        updateError: payload,
      };

    case CONFIRM_ORDER_INFO_LOADING:
      return {
        ...state,
        confirmLoading: payload,
        confirmError: {},
        confirmedSuccessfully: false,
      };

    case CONFIRM_ORDER_INFO_SUCCESS:
      return {
        ...state,
        confirmedSuccessfully: true,
        confirmError: {},
      };

    case CONFIRM_ORDER_INFO_FAIL:
      return {
        ...state,
        confirmError: payload,
      };

    case GET_ORDER_INFO_LOADING:
      return {
        ...state,
        getOrderLoading: payload,
        getOrderError: {},
        confirmError: {},
      };

    case GET_ORDER_INFO_SUCCESS:
      return {
        ...state,
        order: payload,
        confirmError: {},
      };

    case GET_ORDER_INFO_FAIL:
      return {
        ...state,
        getOrderError: payload,
      };

    default:
      return state;
  }
};

export default paidPackagesReducer;
