import React from "react";
import { styled } from "@material-ui/core/styles";
import { earnActivityCategories } from "../../constants";
import EarnActivity from "../Cards/Activity/Earn";

export const CardContainer = styled("div")(() => ({
  maxWidth: 350,
  marginTop: 10,
  marginRight: 10,
  "& button": {
    height: "100%",
  },
}));

export const Error = styled("p")(({ theme }) => ({
  ...theme.typography.placeholder,
  color: theme.colors.pink,
}));

const ActivitiesSection = ({
  setPublicActivities,
  publicActivities = [],
  error,
  lang = "english",
}) => {
  let _publicActivities = publicActivities;
  if (!publicActivities) {
    _publicActivities = [];
  }
  const cb = (category) => {
    const check = _publicActivities.find((element) => element === category);
    if (check) {
      return _publicActivities.filter((item) => item !== category);
    }
    return [..._publicActivities, category];
  };

  const selectActivity = (category) => {
    setPublicActivities(cb(category));
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginLeft: "10px",
        }}
      >
        {earnActivityCategories.map((option, index) => (
          <CardContainer>
            <EarnActivity
              category={option.category}
              categoryName={
                lang === "english"
                  ? option.categoryName
                  : option.categoryNameWelsh
              }
              subtitle={
                lang === "english" ? option.subtitle : option.subtitleWelsh
              }
              index={index}
              selected={_publicActivities.some((e) => e === option.category)}
              handleClick={() => {
                selectActivity(option.category);
              }}
            />
          </CardContainer>
        ))}
      </div>
      {error && <Error>{error}</Error>}
    </>
  );
};

export default ActivitiesSection;
