import colors from "../../utils/theme/colors";

const cookieStyles = ({ isTablet, isMobile }) => ({
  general: {
    background: `${colors.midnight}`,
    color: `${colors.white}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxHeight: 155,
    zIndex: 9999,
    paddingRight: "1rem",
    textAlign: "left",
    fontWeight: "600",
    fontStyle: "normal",
    fontSize: "1rem",
  },
  tabletStyle: {
    background: `${colors.midnight}`,
    color: `${colors.white}`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 15,
    paddingLeft: 10,
    paddingBottom: isMobile ? 5 : 15,
    zIndex: 999,
    paddingRight: "1rem",
    fontWeight: "600",
    fontStyle: "normal",
    fontSize: "1rem",
    minHeight: isMobile ? "auto" : "180px",
    maxWidth: isTablet ? 640 : 540,
    position: "static",
    transform: isMobile ? "translate(0, 0)" : "translate(0, -40vh)",
    borderRadius: 10,
    bottom: 0,
  },
  button: {
    background: `${colors.white}`,
    color: `${colors.midnight}`,
    fontSize: "16px",
    fontWeight: "bold",
    borderRadius: 4,
    border: `1px ${colors.white}`,
    marginTop: "15px",
    marginBottom: 15,
    fontFamily: "Poppins",
    width: 200,
    height: 50,
  },
  link: {
    cursor: "pointer",
    color: `${colors.white}`,
    marginLeft: 5,
  },
  overlayStyle: {
    background: "rgba(255,255,255,0.7)",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    flexDirection: "column-reverse",
    alignItems: "center",
    padding: "20px 16px",
    zIndex: 9999,
  },
});
export default cookieStyles;
