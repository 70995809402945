/* eslint-disable func-names */
import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";

import validate from "../../validation/schemas/login";

import * as R from "../../constants/navRoutes";
import userRoles from "../../constants/roles";

// ACTIONS
import {
  login as loginAction,
  getEarnGroupInfo as getEarnGroupInfoAction,
} from "../../redux/actions/auth";

import {
  LinkB14,
  Body18B,
  Body16R,
  Body16B,
  Body14R,
} from "../../components/Typograpy";
import InputField from "../../components/Inputs/InputField";
import Button, { Regular } from "../../components/Button";
import { ReCaptcha } from "../../components/TextSections";
import WelcomeSection from "./WelcomeSection";
import secondsToHms from "../../utils/helpers/secondsToHms";
import translate from "../../utils/helpers/translator";

import {
  GridContent,
  TextLinks,
  GridItem,
  Error,
  SpendGridItem,
  HeaderWrapper,
  GridItemLinks,
  MobilePromoLink,
  ButtonErrorContainer,
  LoginForm,
  StyledTitle,
} from "./style";

const useQuery = () => new URLSearchParams(useLocation().search);

const Login = ({
  spendVenue,
  staff,
  handleLogin,
  loginLoading,
  loginError,
  earnGroupInfo,
  getEarnGroupInfo,
  lang,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const history = useHistory();
  const query = useQuery();
  // TODO: remove this once google indexing issue is fixed
  const inviteToken =
    query.get("invite") === "kk5c0rpm" ? undefined : query.get("invite");
  const giftToken = query.get("gift");
  const programmeToken = query.get("programme");
  const administratorToken = query.get("administrator");
  const programmeManagerToken = query.get("programmeManager");
  const activateAccountToken = query.get("activateAccountToken");
  const volunteerTkId = query.get("guid");
  const tkRedirectUrl = query.get("redirect_uri");

  const searchParams = new URLSearchParams();
  if (inviteToken) searchParams.append("invite", inviteToken);
  if (giftToken) searchParams.append("gift", giftToken);
  if (programmeToken) searchParams.append("programme", programmeToken);
  if (administratorToken) {
    searchParams.append("administrator", administratorToken);
  }
  if (programmeManagerToken) {
    searchParams.append("programmeManager", programmeManagerToken);
  }
  if (volunteerTkId) searchParams.append("guid", volunteerTkId);
  if (tkRedirectUrl) searchParams.append("redirect_uri", tkRedirectUrl);

  useLayoutEffect(() => {
    const script = document.createElement("script");

    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    const body = document.querySelector("body");
    body.appendChild(script);

    return () => {
      body.removeChild(script);
    };
  }, []);

  const onChange = (e) => {
    switch (e.target.name) {
      case "email":
        setEmail(e.target.value);
        break;
      case "password":
        setPassword(e.target.value);
        break;
      default:
        break;
    }
  };
  const handleClick = (e) => {
    e.preventDefault();
    const cleanEmail = email.toLowerCase().trim();
    try {
      validate({ email: cleanEmail, password }, null, { lang });
      const data = {
        email: cleanEmail,
        password,
        earnGroupUserId: (inviteToken && earnGroupInfo.id) || undefined,
        giftToken,
        history,
        programmeToken,
        administratorToken,
        programmeManagerToken,
        activateAccountToken,
        addToTk: {
          isTkVolunteer: !!volunteerTkId,
          volunteerTkId,
          tkRedirectUrl,
          inviteToken,
        },
      };

      if (process.env.NODE_ENV === "production") {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
              action: "login",
            })
            .then((reToken) => {
              handleLogin({ ...data, reToken });
            });
        });
      } else {
        handleLogin(data);
      }
    } catch (err) {
      if (err.name === "ValidationError") {
        setEmailErr(err.inner.email);
        setPasswordErr(err.inner.password);
      }
    }
  };

  useEffect(() => {
    if (inviteToken) {
      getEarnGroupInfo({
        inviteToken,
        invitedUserRole: userRoles.MEMBER,
        language: lang,
        history,
      });
    } else if (administratorToken) {
      getEarnGroupInfo({
        inviteToken: administratorToken,
        invitedUserRole: userRoles.EARN_GROUP_ADMIN,
        language: lang,
        history,
      });
    }

    setEmailErr("");
    setPasswordErr("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  let error;
  if (loginError && loginError.message) {
    const { message, httpStatusCode, data } = loginError;
    error = translate(message, lang);
    if (httpStatusCode === 429) {
      const tryAfter = secondsToHms(data.tryAfter);
      error = `${translate("tooManyRequestsAfter", lang)} ${tryAfter}`;
    }
  }

  const decideSignupLink = () => {
    let link = R.GENERAL.SIGN_UP_FUNNEL;
    if (administratorToken) {
      link = R.EARN_GROUP_ADMIN.SIGN_UP;
    } else if (programmeManagerToken) {
      link = R.PROGRAMME_MANAGER.SIGN_UP;
    } else if (volunteerTkId) {
      link = R.GENERAL.SIGN_UP;
    }
    return link;
  };

  const translateAcceptTimeCredits = () => {
    const text = translate("clickToAcceptTimeCredits", lang).split(", ");
    return lang === "english" ? (
      <TextLinks>
        {text[0]}
        <Link
          to={{
            pathname: R.SPEND_VENUE.SPEND_LOG_IN,
            search: searchParams.toString(),
          }}
        >
          <LinkB14 color="blue" style={{ display: "inline", marginLeft: 5 }}>
            {text[1]}
          </LinkB14>
        </Link>
      </TextLinks>
    ) : (
      <TextLinks>
        <Link
          to={{
            pathname: R.SPEND_VENUE.SPEND_LOG_IN,
            search: searchParams.toString(),
          }}
        >
          <LinkB14 color="blue" style={{ display: "inline", marginRight: 5 }}>
            {text[0]}
          </LinkB14>
        </Link>
        {text[1]}
      </TextLinks>
    );
  };

  // if (!gotLang) return "loading";

  return (
    <Grid container md={12} xl={12} xs={12} style={{ flexGrow: 1 }}>
      <Grid
        item
        container
        direction="column"
        justify="center"
        alignItems="center"
        xl={6}
        style={{ display: "flex", flexGrow: 1 }}
      >
        <GridContent container>
          <GridItem style={{ order: 1 }}>
            <HeaderWrapper>
              <StyledTitle
                color="primary"
                style={{
                  marginBottom: "10px",
                  textAlign: "left",
                  width: "100%",
                }}
              >
                {translate("logIn", lang)}
              </StyledTitle>
              {inviteToken && (
                <MobilePromoLink>
                  <Body14R style={{ margin: "0" }} color="gray3">
                    {translate("dontHaveTempoAccount", lang)}
                  </Body14R>
                  <Link to={`${R.GENERAL.SIGN_UP}/?invite=${inviteToken}`}>
                    <LinkB14
                      color="blue"
                      style={{
                        margin: "0 0 0 5px",
                      }}
                    >
                      {translate("signUp", lang)}
                    </LinkB14>
                  </Link>
                </MobilePromoLink>
              )}
            </HeaderWrapper>
          </GridItem>
          {(inviteToken || administratorToken) && (
            <Grid item style={{ order: 2 }}>
              <Body16R>
                {translate("logInSo", lang)}{" "}
                <Body18B color="gray2" style={{ display: "inline" }}>
                  {lang === "welsh" && earnGroupInfo?.profile?.nameWelsh
                    ? earnGroupInfo?.profile?.nameWelsh
                    : earnGroupInfo?.profile?.name}
                </Body18B>{" "}
                {administratorToken
                  ? translate("canAddAsAdmin", lang)
                  : translate("canAddYouToGroup", lang)}
              </Body16R>
            </Grid>
          )}
          <LoginForm>
            <GridItem>
              <InputField
                placeholder={`${translate("typeEmail", lang)}...`}
                label={translate("email", lang)}
                name="email"
                value={email}
                setValue={onChange}
                error={emailErr}
                autoFocus
              />
            </GridItem>
            <GridItem style={{ marginBottom: "0" }}>
              <InputField
                type="password"
                placeholder={`${translate("typePassword", lang)}...`}
                label={translate("password", lang)}
                name="password"
                value={password}
                setValue={onChange}
                error={passwordErr}
              />
            </GridItem>
            <Link to={`${R.GENERAL.FORGET_PASSWORD}`}>
              <LinkB14 color="gray3" ml={15} mt={10}>
                {translate("forgotPassword", lang)}?
              </LinkB14>
            </Link>
            <ButtonErrorContainer>
              {error && <Error>{error}</Error>}
              <Regular
                primary
                bgColor="blue"
                size="l"
                handleClick={handleClick}
                style={{ margin: 0 }}
                loading={loginLoading}
                type="submit"
              >
                {translate("logIn", lang)}
              </Regular>
            </ButtonErrorContainer>
            <ReCaptcha />
          </LoginForm>

          <GridItemLinks spendVenue={spendVenue}>
            <TextLinks>
              {translate("dontHaveTempoAccount", lang)}?
              <Link
                to={{
                  pathname: decideSignupLink(),

                  search: searchParams.toString(),
                }}
              >
                <LinkB14
                  color="blue"
                  style={{ margin: "0 0 0 5px", display: "inline" }}
                >
                  {translate("signUp", lang)}
                </LinkB14>
              </Link>
            </TextLinks>
          </GridItemLinks>

          {!inviteToken &&
            !spendVenue &&
            !staff &&
            !administratorToken &&
            !programmeManagerToken && (
              <GridItemLinks spendVenue={spendVenue} divider>
                {translateAcceptTimeCredits()}
              </GridItemLinks>
            )}
          {spendVenue && (
            <SpendGridItem
              style={{ width: "100%", textAlign: "center", margin: "30px 0" }}
            >
              <Body16B style={{ marginBottom: "50px" }}>
                {translate("or", lang)}
              </Body16B>
              <Button size="n" outline to={R.GENERAL.SELECT_VENUE}>
                {translate("acceptTCWithoutLogIn", lang)}
              </Button>
            </SpendGridItem>
          )}
        </GridContent>
      </Grid>
      <Grid item container justify="center" md={6}>
        <WelcomeSection />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  earnGroupInfo: state.auth.earnGroupInfo,
  loginLoading: state.auth.loginLoading,
  loginError: state.auth.loginError,
  lang: state.auth.language,
});

const mapActionToProps = {
  handleLogin: loginAction,
  getEarnGroupInfo: getEarnGroupInfoAction,
};

export default connect(mapStateToProps, mapActionToProps)(Login);
