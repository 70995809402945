import {
  GET_SPEND_VENUE_INSIGHTS_LOADING_START,
  GET_SPEND_VENUE_INSIGHTS_LOADING_END,
  GET_SPEND_VENUE_INSIGHTS_SUCCESS,
  GET_SPEND_VENUE_INSIGHTS_FAIL,
  GET_SPEND_VENUE_TRANSACTIONS_LOADING_START,
  GET_SPEND_VENUE_TRANSACTIONS_LOADING_END,
  GET_SPEND_VENUE_TRANSACTIONS_SUCCESS,
  GET_SPEND_VENUE_TRANSACTIONS_FAIL,
  GET_SPEND_VENUE_BY_ID_BASIC_LOADING_START,
  GET_SPEND_VENUE_BY_ID_BASIC_LOADING_END,
  GET_SPEND_VENUE_BY_ID_BASIC_SUCCESS,
  GET_SPEND_VENUE_BY_ID_BASIC_FAIL,
} from "../../../redux/types";

const initialState = {
  insights: {
    allMembers: 0,
    thisYearMembers: 0,
    totalTcAccepted: 0,
    totalTcAcceptedThisYear: 0,
  },
  getInsightsLoading: false,
  insightsError: {},
  transactions: [],
  transactionsLoading: false,
  transactionsError: {},
  transactionsLoaded: false,
  spendVenueLoading: false,
  spendVenueDetails: {},
  spendVenueError: {},
};

const returningMembersIds = (transactions) => {
  const ids = {};
  transactions.forEach((t) => {
    ids[t.from.userId] = ids[t.from.userId] || 0;
    ids[t.from.userId] += 1;
  });
  return ids;
};

const spendVenueReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SPEND_VENUE_INSIGHTS_LOADING_START:
      return { ...state, getInsightsLoading: true };

    case GET_SPEND_VENUE_INSIGHTS_LOADING_END:
      return { ...state, getInsightsLoading: false };

    case GET_SPEND_VENUE_INSIGHTS_SUCCESS:
      return {
        ...state,
        insights: {
          allMembers: action.payload[0].allMembers,
          thisYearMembers: action.payload[0].thisYearMembers,
          totalTcAccepted: action.payload[1].totalTcAccepted,
          totalTcAcceptedThisYear: action.payload[1].totalTcAcceptedThisYear,
        },
      };

    case GET_SPEND_VENUE_INSIGHTS_FAIL:
      return { ...state, insightsError: action.payload };

    case GET_SPEND_VENUE_TRANSACTIONS_LOADING_START:
      return { ...state, transactionsLoading: true };

    case GET_SPEND_VENUE_TRANSACTIONS_LOADING_END:
      return { ...state, transactionsLoading: false };

    case GET_SPEND_VENUE_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: action.payload,
        returningMembersIds: returningMembersIds(action.payload),
        transactionsLoaded: true,
      };

    case GET_SPEND_VENUE_TRANSACTIONS_FAIL:
      return { ...state, transactionsError: action.payload };

    case GET_SPEND_VENUE_BY_ID_BASIC_LOADING_START:
      return { ...state, spendVenueLoading: true };

    case GET_SPEND_VENUE_BY_ID_BASIC_LOADING_END:
      return { ...state, spendVenueLoading: false };

    case GET_SPEND_VENUE_BY_ID_BASIC_SUCCESS:
      return {
        ...state,
        spendVenueDetails: action.payload,
      };

    case GET_SPEND_VENUE_BY_ID_BASIC_FAIL:
      return { ...state, spendVenueError: action.payload };

    default:
      return state;
  }
};

export default spendVenueReducer;
