import React, { useState, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import * as S from "./style";
import * as T from "../../../../components/Typograpy";
import Image from "../../../../components/Image";
import Icon from "../../../../components/Icon";
import Modal from "../../../../components/Modal";
import { SingleDatePicker } from "../../../../components/Inputs";
import { Regular } from "../../../../components/Button";
import { Col, Row } from "../../../../components/Grid";

import { confirmOrder } from "../../../../redux/actions/paidPackages";
import t from "../../../../utils/helpers/translator";

const MarkOrderAsPaid = ({
  confirmOrderAction,
  confirmLoading,
  confirmError,
  confirmedSuccessfully,
}) => {
  const [paymentDate, setPaymentDate] = useState(moment());
  const [isModalOpen, setIsModalOpen] = useState(false);

  const history = useHistory();
  const { id } = useParams();
  const handleClick = () => {
    const _paymentDate = paymentDate || moment();
    confirmOrderAction({
      id,
      body: { paymentDate: _paymentDate, type: "BACS" },
    });
  };

  useEffect(() => {
    if (confirmedSuccessfully) {
      setIsModalOpen(true);
    }
  }, [confirmedSuccessfully]);

  return (
    <>
      <Row>
        <Col w={[4, 12, 12]}>
          <T.H40Caps color="midnight">MARK AS PAID</T.H40Caps>
        </Col>
      </Row>
      <Row mt={6} mb={6}>
        <Col w={[4, 8, 8]}>
          <S.Container>
            <Row mb={1}>
              <Col w={[3, 8, 8]}>
                <S.Title>
                  <T.Body20B color="midnight" m="0">
                    Are you sure you want to mark this order as paid?
                  </T.Body20B>
                </S.Title>
              </Col>
              <Col
                w={[1, 4, 4]}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Icon
                  icon="alertTriangle"
                  width="66px"
                  height="66px"
                  color="pink"
                />
              </Col>
            </Row>
            <Row mb={2}>
              <Col w={[4, 10, 10]}>
                <T.Body14R style={{ display: "inline" }} color="gray3">
                  This will issue the Time Credits to the recipient and cannot
                  be undone. Please make sure money has been received into the
                  Tempo bank account.
                </T.Body14R>
              </Col>
            </Row>
          </S.Container>
        </Col>
        <Col
          w={[4, 4, 4]}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Image image="office" alt="office" />
        </Col>
      </Row>

      <Row>
        <Col w={[4, 4, 4]}>
          <T.Body16R color="gray3" mb="22">
            Below you can select a payment date:
          </T.Body16R>
          <SingleDatePicker
            label="Date"
            value={paymentDate ? moment(paymentDate) : undefined}
            setValue={setPaymentDate}
            singleDatePicker
          />
        </Col>
      </Row>

      <Row mt={9}>
        <Col w={[4, 12, 12]}>
          {confirmError && (
            <T.Body16R color="error">
              {" "}
              {t(confirmError.message, "english")}
            </T.Body16R>
          )}
        </Col>
        <Col w={[4, 4, 4]}>
          <Regular
            outline
            textColor="blue"
            handleClick={history.goBack}
            type="submit"
          >
            I want to go back
          </Regular>
        </Col>
        <Col w={[4, 4, 4]}>
          <Regular
            primary
            bgColor="blue"
            handleClick={handleClick}
            loading={confirmLoading}
            type="submit"
          >
            Confirm
          </Regular>
        </Col>
      </Row>

      <Modal
        modalOpen={isModalOpen}
        handleCloseModal={() => setIsModalOpen(false)}
        confirmFun={() => {
          history.goBack();
        }}
        onCancel={() => setIsModalOpen(false)}
      >
        <T.H24 color="blue" mb={20}>
          Success!
        </T.H24>
        <T.Body14R>You’ve successfully marked as paid.</T.Body14R>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ paidPackages }) => ({
  confirmLoading: paidPackages.confirmLoading,
  confirmError: paidPackages.confirmError,
  confirmedSuccessfully: paidPackages.confirmedSuccessfully,
});

const mapActionToProps = {
  confirmOrderAction: confirmOrder,
};

export default connect(mapStateToProps, mapActionToProps)(MarkOrderAsPaid);
