import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import Image from "../../../components/Image";
import * as S from "./style";
import Button from "../../../components/Button";
import { TEMPO } from "../../../constants/navRoutes";
import * as T from "../../../components/Typograpy";

import * as actions from "./actions";

const CreateSpendVenueSuccess = ({
  spendPartner,
  spendVenue,
  getSpendPartnerById,
  getSpendVenue,
}) => {
  const { spendPartnerId: spendPartnerUserId, venueId } = useParams();

  useEffect(() => {
    getSpendPartnerById({ id: spendPartnerUserId });
    getSpendVenue({ id: venueId, isBasic: true });
  }, [getSpendPartnerById, getSpendVenue, spendPartnerUserId, venueId]);

  return (
    <S.SuccessWrapper>
      <Image
        image="successLarge"
        alt="success"
        customStyle={{ maxWidth: "100%" }}
      />
      <S.StyledHeader>
        <T.H40Caps align="center" color="midnight">
          NICE ONE!
        </T.H40Caps>
      </S.StyledHeader>
      <T.Body16R mt="20" color="gray3" style={{ width: "120%" }} align="center">
        You have just created {spendVenue?.name}. {spendPartner?.name} can now
        manage this venue via their account. What do you want to do next?
      </T.Body16R>

      <Button
        primary
        size="l"
        mt="20"
        to={TEMPO.CREATE_SPEND_VENUE.replace(
          ":spendPartnerId",
          spendPartnerUserId,
        )}
      >
        Add another venue to this partner
      </Button>
      <Button
        secondary
        size="l"
        mt="10"
        to={TEMPO.CREATE_SPEND_ACTIVITY.replace(":spendVenueId", venueId)}
      >
        Add activities to this venue
      </Button>
      <Button size="l" mt="10" to={TEMPO.DASHBOARD} outline>
        Go back to dashboard
      </Button>
    </S.SuccessWrapper>
  );
};

const mapStateToProps = (state) => ({
  spendPartner: state.spendVenueForm.spendPartner,
  spendVenue: state.spendVenueForm.spendVenue,
});

const mapActionToProps = {
  getSpendPartnerById: actions.getSpendPartnerById,
  getSpendVenue: actions.getSpendVenue,
};

export default connect(
  mapStateToProps,
  mapActionToProps,
)(CreateSpendVenueSuccess);
