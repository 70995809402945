import styled from "@material-ui/core/styles/styled";
import shadows from "../../../../utils/theme/shadows";

export const ImageWrapper = styled("div")({
  minWidth: 250,
  height: 210,
  transform: "translate(30px, -30px)",
  display: "flex",
  justifyContent: "flex-end",
});

export const TotalVatContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "17px",
});

export const TotalAmountContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

export const Error = styled("p")(({ theme }) => ({
  width: "100%",
  color: theme.colors.pink,
  fontSize: "0.875rem",
  textAlign: "center",
  marginBottom: theme.spacing(1),
}));

export const TableContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  position: "relative",
});

export const BtnContainer = styled("div")(({ theme }) => ({
  alignSelf: "flex-end",
  transform: "translateY(208px)",
  display: "flex",
  zIndex: 2,
  marginTop: -theme.spacing(8),
}));

export const CsvExport = styled("a")(({ theme }) => ({
  textDecoration: "none",
  marginBottom: theme.spacing(2),
  display: "block",
}));

// confirm
export const Container = styled("div")(({ theme }) => ({
  padding: 40,
  maxWidth: 540,
  boxShadow: shadows.card,
  backgroundColor: theme.colors.pinkLight,
  outline: "none",
}));

export const Title = styled("div")(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(1),
}));

export const ModalContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
});
export const HeadContent = styled("div")({
  display: "flex",
  flexDirection: "column",
});
export const FilterContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
});
