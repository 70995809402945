import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getSpendActivities } from "../../../redux/actions/activities";
import { getEarnGroups } from "../../../redux/actions/earnGroups";

import * as S from "./style";
// components
import { Row, Col } from "../../../components/Grid";
import * as T from "../../../components/Typograpy";
import EarnSpendOpportunity from "../../../components/Cards/EarnSpendOpportunity";
import ViewMoreLink from "../../../components/Links/ViewMoreLink";
import Loading from "../../../components/Loading";

import t from "../../../utils/helpers/translator";

// routes
import { GENERAL } from "../../../constants/navRoutes";

const MemberDashboard = (props) => {
  const {
    isMobile,
    getSpendActivitiesAction,
    spendActivities = [],
    lastFeaturedActivities = [],
    getEarnGroupsAction,
    earnGroups = [],
    earnGroupsLoading,
    spendActivitiesLoading,
    language,
  } = props;

  const codesFilter = (activities) =>
    activities.filter(
      (activity) =>
        activity.turnUpAtVenue ||
        activity.callToBook ||
        Number(activity.numberOfAvailableCodes) > 0,
    );

  useEffect(() => {
    getSpendActivitiesAction({
      isPublic: true,
      limit: 40,
      language,
      availableOffersOnly: true,
    });
    getEarnGroupsAction({ isPublic: true, limit: 40, language });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const renderCardsHorizontalScroll = (cards, type, isLoading = false) => {
    if (isLoading) {
      return <Loading />;
    }
    if (!cards || cards.length === 0) {
      return <div>{t("noActivitiesFound", language)}</div>;
    }
    if (cards.length > 0 && type === "spend") {
      return cards.slice(0, 8).map((activity) => (
        <Col w={[4, 6, 4]} style={{ marginLeft: "-8px" }}>
          <EarnSpendOpportunity
            path={GENERAL.SPEND_ACTIVITY.replace(":id", activity.id)}
            imgUrl={activity.imageURL}
            title={
              language === "welsh" && activity.nameWelsh
                ? activity.nameWelsh
                : activity.name
            }
            subtitle={
              language === "welsh" && activity.subTitleWelsh
                ? activity.subTitleWelsh
                : activity.subTitle
            }
            credits={activity.cost}
            venue={activity.spendVenue && activity.spendVenue.name}
            cities={
              language === "welsh" && activity.cityWelsh
                ? activity.cityWelsh
                : activity.city
            }
            isOnline={activity.isOnline}
            costVaries={activity.costVaries}
            type="spend"
            featuredAt={activity.featuredAt}
          />
        </Col>
      ));
    }

    if (cards.length > 0 && type === "earn") {
      return cards.slice(0, 8).map((group) => (
        <Col w={[4, 6, 4]} style={{ marginLeft: "-8px" }}>
          <EarnSpendOpportunity
            path={GENERAL.EARN_GROUP.replace(":id", group.id)}
            imgUrl={group.imageURL}
            title={group.name}
            cities={group.city}
            isOnline={group.isOnline}
            type="earn"
          />
        </Col>
      ));
    }
  };

  return (
    <S.Wrapper>
      <S.Header>
        <Row>
          <Col w={[4, 8, 8]}>
            {isMobile ? (
              <T.H30Caps color="midnight">
                {t("mostPopular", language)}
              </T.H30Caps>
            ) : (
              <T.H40Caps color="midnight">
                {t("mostPopular", language)}
              </T.H40Caps>
            )}
          </Col>
        </Row>
      </S.Header>
      {/* EARN */}
      <S.Section>
        {isMobile ? (
          <T.H22 color="midnight" m="0">
            {t("earnTempoTC", language)}
          </T.H22>
        ) : (
          <T.H30 color="midnight" m="0">
            {t("earnTempoTC", language)}
          </T.H30>
        )}
        <S.CardsWrapper disabled>
          {renderCardsHorizontalScroll(earnGroups, "earn", earnGroupsLoading)}
        </S.CardsWrapper>
        <ViewMoreLink
          path={GENERAL.EARN}
          showIcon
          showText
          text={t("viewMore", language)}
          padding="20px 0 0 15px"
        />
      </S.Section>
      {/* SPEND */}
      <S.Section>
        {isMobile ? (
          <T.H22 color="midnight" m="0">
            {t("spendTC", language)}
          </T.H22>
        ) : (
          <T.H30 color="midnight" m="0">
            {t("spendTC", language)}
          </T.H30>
        )}
        <S.CardsWrapper>
          {renderCardsHorizontalScroll(
            codesFilter([
              ...lastFeaturedActivities,
              ...spendActivities.filter(({ featuredAt }) => !featuredAt),
            ]),
            "spend",
            spendActivitiesLoading,
          )}
        </S.CardsWrapper>
        <ViewMoreLink
          path={GENERAL.SPEND}
          showIcon
          showText
          text={t("viewMore", language)}
          padding="20px 0 0 15px"
        />
      </S.Section>
    </S.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  earnGroups: state.earnGroups.data,
  earnGroupsLoading: state.earnGroups.getEarnGroupsLoading,
  spendActivities: state.activities.spendActivities,
  lastFeaturedActivities: state.activities.lastFeaturedActivities,
  spendActivitiesLoading: state.activities.getSpendActivitiesLoading,
  language: state.auth.decideLanguage(state.auth),
});

const mapActionToProps = {
  getSpendActivitiesAction: getSpendActivities,
  getEarnGroupsAction: getEarnGroups,
};

export default connect(mapStateToProps, mapActionToProps)(MemberDashboard);
