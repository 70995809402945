import React from "react";

const AlertTriangle = ({ width, height, color, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66 66"
    width={width}
    height={height}
    {...props}
  >
    <g clipPath="url(#clip0)" fill={color || "currentColor"}>
      <path d="M65.002 53.782L37.864 7.07c-1.248-2.105-3.042-3.353-4.913-3.353-1.95 0-3.744 1.248-4.913 3.353L1.055 53.782c-1.247 2.106-1.403 4.29-.39 5.927.936 1.638 2.886 2.574 5.381 2.574h53.965c2.418 0 4.367-.936 5.381-2.574.936-1.637.78-3.82-.39-5.927zm-2.573 4.29c-.39.623-1.248.935-2.496.935H5.968c-1.247 0-2.183-.39-2.495-.935-.39-.624-.234-1.56.39-2.652L30.845 8.708c.624-1.092 1.404-1.716 2.106-1.716s1.481.624 2.105 1.716L62.04 55.42c.624 1.092.78 2.028.39 2.652z" />
      <path d="M32.95 19.08c-.935 0-1.637.702-1.637 1.638v21.68c0 .935.702 1.637 1.638 1.637.936 0 1.637-.702 1.637-1.638v-21.68c0-.935-.701-1.637-1.637-1.637zM32.95 53.47a2.885 2.885 0 100-5.77 2.885 2.885 0 000 5.77z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0h66v66H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default AlertTriangle;
