import { fields, createSchema, validate as _validate } from "..";

const { email, firstName, lastName, phoneNumber } = fields;

const earnGroupAccountTempo = createSchema({
  firstName,
  lastName,
  email,
  phoneNumber,
});

const validate = (data) => _validate(earnGroupAccountTempo, data);

export default validate;
