import React, { useEffect, useState } from "react";
import { styled } from "@material-ui/core/styles";
import { NavLink as ReactLink } from "react-router-dom";
import { Body16B, Body16M } from "../Typograpy";
import { spend, earn, spendActivity, TEMPO } from "../../constants/navRoutes";

const Link = styled(ReactLink)(({ theme, active, lang }) => {
  const paddingLeft = active ? 50 : 40;
  const borderLeft = active ? `5px solid ${theme.colors.cyan}` : "none";
  return {
    textDecoration: "none",
    borderLeft,
    padding: lang === "english" ? "0 30px" : "0 20px",
    paddingLeft,
    display: "flex",

    "& p": {
      margin: "0",
    },
  };
});

const NavLink = ({ children, to, onClick, mobile, lang }) => {
  const [active, setActive] = useState(false);
  useEffect(() => {
    if (to.startsWith(spendActivity)) {
      setActive(true);
    } else if (
      (!to.startsWith(`${spend}-`) && to.startsWith(spend)) ||
      (!to.startsWith(`${earn}-`) && to.startsWith(earn))
    ) {
      setActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isExact = !(
    (to.startsWith(spend) && !to.startsWith(`${spend}-`)) ||
    (to.startsWith(earn) && !to.startsWith(`${earn}-`))
  );

  return (
    <Link
      to={to}
      exact={isExact}
      isActive={(match, pathname) => {
        if (
          pathname.pathname.startsWith(spendActivity) &&
          to.startsWith(spend)
        ) {
          setActive(true);
        } else if (
          pathname.pathname.startsWith(spend) &&
          to.startsWith(spend) &&
          !pathname.pathname.startsWith(`${spend}-`)
        ) {
          setActive(true);
        } else if (
          pathname.pathname === TEMPO.SPEND_VENUES_WITH_LIVE_OFFERS &&
          to.startsWith(TEMPO.SPEND_VENUES)
        ) {
          setActive(true);
        } else if (
          pathname.pathname === TEMPO.OFFERS &&
          to.startsWith(TEMPO.SPEND_VENUES)
        ) {
          setActive(true);
        } else if (!match) {
          setActive(false);
          return false;
        } else if (match.isExact === true) {
          setActive(true);
          return true;
        }
        return false;
      }}
      active={active}
      onClick={() => (mobile ? onClick(false) : null)}
      lang={lang}
    >
      {active ? (
        <Body16B color="white" m={0}>
          {children}
        </Body16B>
      ) : (
        <Body16M color="white" m={0}>
          {children}
        </Body16M>
      )}
    </Link>
  );
};

export default NavLink;
