// actions go here
import {
  // profile data
  GET_SPEND_VENUE_BY_ID_LOADING_START,
  GET_SPEND_VENUE_BY_ID_LOADING_END,
  GET_SPEND_VENUE_BY_ID_SUCCESS,
  GET_SPEND_VENUE_BY_ID_FAIL,
  // activities by spend venue
  GET_ACTIVITIES_BY_SPEND_VENUE_ID_LOADING_START,
  GET_ACTIVITIES_BY_SPEND_VENUE_ID_LOADING_END,
  GET_ACTIVITIES_BY_SPEND_VENUE_ID_SUCCESS,
  GET_ACTIVITIES_BY_SPEND_VENUE_ID_FAIL,
  // earn group profile data
  GET_EARN_GROUP_BY_GROUP_ID_LOADING,
  GET_EARN_GROUP_BY_GROUP_ID_SUCCESS,
  GET_EARN_GROUP_BY_GROUP_ID_FAIL,
} from "../../../../redux/types";

import { spendVenues, earnGroups } from "../../../../api-calls";

export const getSpendVenueProfile =
  ({ spendVenueId, isPublic = false }) =>
  async (dispatch) => {
    dispatch({ type: GET_SPEND_VENUE_BY_ID_LOADING_START });

    try {
      const { data, error } = await spendVenues.getSpendVenueById({
        spendVenueId,
        isPublic,
      });

      if (data) {
        dispatch({
          type: GET_SPEND_VENUE_BY_ID_SUCCESS,
          payload: data,
        });
      } else if (error) {
        dispatch({
          type: GET_SPEND_VENUE_BY_ID_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_SPEND_VENUE_BY_ID_FAIL,
        payload: error,
      });
    } finally {
      dispatch({ type: GET_SPEND_VENUE_BY_ID_LOADING_END });
    }
  };

export const getSpendVenueActivities =
  ({ spendVenueId, isPublic = false }) =>
  async (dispatch) => {
    dispatch({ type: GET_ACTIVITIES_BY_SPEND_VENUE_ID_LOADING_START });

    try {
      const { data, error } = await spendVenues.getActivitiesBySpendVenueId({
        spendVenueId,
        isPublic,
      });

      if (data) {
        dispatch({
          type: GET_ACTIVITIES_BY_SPEND_VENUE_ID_SUCCESS,
          payload: data,
        });
      } else if (error) {
        dispatch({
          type: GET_ACTIVITIES_BY_SPEND_VENUE_ID_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_ACTIVITIES_BY_SPEND_VENUE_ID_FAIL,
        payload: error,
      });
    } finally {
      dispatch({ type: GET_ACTIVITIES_BY_SPEND_VENUE_ID_LOADING_END });
    }
  };

export const getEarnGroupByGroupId =
  ({ id, isPublic }) =>
  async (dispatch) => {
    dispatch({ type: GET_EARN_GROUP_BY_GROUP_ID_LOADING });

    try {
      const { data, error } = await earnGroups.getEarnGroupByGroupId({
        id,
        isPublic,
      });

      if (data) {
        dispatch({
          type: GET_EARN_GROUP_BY_GROUP_ID_SUCCESS,
          payload: data,
        });
      } else if (error) {
        dispatch({
          type: GET_EARN_GROUP_BY_GROUP_ID_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_EARN_GROUP_BY_GROUP_ID_FAIL,
        payload: error,
      });
    }
  };
