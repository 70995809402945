import React from "react";
import { useHistory } from "react-router-dom";

import * as S from "../../style";
import * as T from "../../../../../components/Typograpy";

import {
  VenueDetails,
  TextSection,
  AboutVenueOrg,
} from "../../../../../components/TextSections";

import { Row, Col } from "../../../../../components/Grid";
import SpendVenueList from "../../../../../components/Cards/Activity/SpendVenueList";

import { GENERAL } from "../../../../../constants/navRoutes";

import translate from "../../../../../utils/helpers/translator";

const Content = ({
  lang,
  spendVenueProfile,
  isTablet,
  spendVenueActivities,
}) => {
  const {
    name,
    description,
    isOnline,
    contactEmail,
    contactNumber,
    url: webLink,
    socialLinks = {},
    logo,
    earnGroupAddresses,
    spendVenueAddress,
    accessibilityLink,
  } = spendVenueProfile;

  const history = useHistory();

  const filteredSpendVenueActivities = spendVenueActivities?.filter(
    (activity) => activity.isLive && !activity.hide,
  );
  return (
    <>
      <Row>
        <Col w={[4, 12, 8]}>
          <S.Header>
            {isTablet ? (
              <T.H30Caps color="midnight">{name}</T.H30Caps>
            ) : (
              <T.H40Caps color="midnight">{name}</T.H40Caps>
            )}
          </S.Header>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 8]}>
          <S.LeftWrapper>
            <T.H22 color="midnight" mb="20">
              {translate("activities", lang)}
            </T.H22>
            {!filteredSpendVenueActivities ||
            !filteredSpendVenueActivities.length ? (
              <T.Body16B5 mb="40px">
                {translate("noActivitiesListed", lang)}
              </T.Body16B5>
            ) : (
              <S.ActivitiesWrapper>
                {filteredSpendVenueActivities.map((activity) => (
                  <>
                    <SpendVenueList
                      activity={
                        lang === "welsh"
                          ? activity.nameWelsh || activity.name
                          : activity.name
                      }
                      subtitle={
                        lang === "welsh"
                          ? activity.subTitleWelsh || activity.subTitle
                          : activity.subTitle
                      }
                      cost={activity.price}
                      costVaries={activity.costVaries}
                      m="0 0 20px 0"
                      online={activity.isOnline}
                      handleClick={() =>
                        history.push(
                          GENERAL.SPEND_ACTIVITY.replace(":id", activity.id),
                        )
                      }
                      lang={lang}
                    />
                  </>
                ))}
              </S.ActivitiesWrapper>
            )}
            {!isTablet && (
              <>
                <TextSection
                  size="lg"
                  title={`${translate("about", lang)} ${name}`}
                  text={description}
                  m="30px 0 0 0"
                />
                {accessibilityLink && (
                  <a
                    href={
                      accessibilityLink.startsWith("http")
                        ? accessibilityLink
                        : `http://${accessibilityLink}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <T.LinkB16 color="blue">
                      {translate("viewAccessibility", lang)}
                    </T.LinkB16>
                  </a>
                )}
              </>
            )}
          </S.LeftWrapper>
        </Col>
        <Col w={[4, 12, 4]}>
          <VenueDetails
            addresses={
              spendVenueAddress && spendVenueAddress.addressLine1
                ? [spendVenueAddress]
                : earnGroupAddresses
            }
            phoneNum={contactNumber}
            email={contactEmail}
            facebook={socialLinks && socialLinks.facebook}
            twitter={socialLinks && socialLinks.twitter}
            instagram={socialLinks && socialLinks.instagram}
            youtube={socialLinks && socialLinks.youtube}
            url={webLink}
            logo={logo && logo.imageURL}
            isOnline={isOnline}
            type="spendVenue"
            lang={lang}
          />
          {isTablet && (
            <AboutVenueOrg
              title={`${translate("about", lang)} ${name}`}
              text={description}
              accessibility={accessibilityLink}
              m="40px 0 0 0"
              lang={lang}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default Content;
