import axios from "axios";
import handleError from "./handle-errors";

const PROGRAMMES_BASE = "/programmes";

const getProgrammes = async ({
  limit,
  type,
  options = {},
  startDate,
  endDate,
} = {}) => {
  try {
    const { data } = await axios.get(`${PROGRAMMES_BASE}`, {
      params: { limit, type, startDate, endDate },
    });

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getProgrammeByToken = async ({ programmeToken, options = {} } = {}) => {
  try {
    const { data } = await axios.get(`${PROGRAMMES_BASE}/programme-name`, {
      params: { programmeToken },
    });

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const createProgramme = async ({ programmeData, options = {} } = {}) => {
  try {
    const { data } = await axios.post(`${PROGRAMMES_BASE}`, programmeData);

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const programmeChangeRequest = async ({
  programmeToken,
  activeUserId,
  options = {},
} = {}) => {
  try {
    const { data } = await axios.post(`${PROGRAMMES_BASE}/change-request`, {
      programmeToken,
      activeUserId,
    });

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const editProgramme = async ({
  programmeData,
  programmeId,
  options = {},
} = {}) => {
  try {
    const { data } = await axios.patch(
      `${PROGRAMMES_BASE}/${programmeId}`,
      programmeData,
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getProgramme = async ({ programmeId, type, options = {} } = {}) => {
  try {
    const { data } = await axios.get(
      `${PROGRAMMES_BASE}/${programmeId}?type=${type}`,
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getProgrammeCreditRequests = async ({
  programmeId,
  status,
  options = {},
} = {}) => {
  try {
    const { data } = await axios.get(
      `${PROGRAMMES_BASE}/${programmeId}/credit-requests?status=${status}`,
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getProgrammeEarnGroup = async ({
  programmeId,
  options = {},
  startDate,
  endDate,
} = {}) => {
  try {
    const { data } = await axios.get(
      `${PROGRAMMES_BASE}/${programmeId}/earn-groups`,
      {
        params: { startDate, endDate },
      },
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updateProgrammeEarnGroup = async ({
  earnGroupId,
  programmeId,
  status,
  options = {},
} = {}) => {
  try {
    const { data } = await axios.patch(
      `${PROGRAMMES_BASE}/${programmeId}/earn-groups/${earnGroupId}`,
      { status },
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const addProgrammeManager = async ({
  programmeId,
  email,
  options = {},
} = {}) => {
  try {
    const { data } = await axios.post(
      `${PROGRAMMES_BASE}/${programmeId}/managers`,
      {
        email,
      },
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getProgrammeManagers = async ({ programmeId, options = {} } = {}) => {
  try {
    const { data } = await axios.get(
      `${PROGRAMMES_BASE}/${programmeId}/managers`,
    );

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const deleteProgrammeManagers = async ({
  programmeId,
  managerId,
  options = {},
} = {}) => {
  try {
    const { data } = await axios.delete(
      `${PROGRAMMES_BASE}/${programmeId}/managers/${managerId}`,
    );

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export {
  getProgrammes,
  getProgrammeByToken,
  createProgramme,
  getProgramme,
  editProgramme,
  getProgrammeCreditRequests,
  getProgrammeEarnGroup,
  updateProgrammeEarnGroup,
  addProgrammeManager,
  getProgrammeManagers,
  deleteProgrammeManagers,
  programmeChangeRequest,
};
