import { fields, createSchema, validate as _validate } from "..";

const {
  requiredText,
  startDate,
  budget,
  mainFocus,
  otherKeyFocuses,
  region,
  text,
  emailArr,
} = fields;

const createProgrammeSchema = createSchema({
  name: requiredText(50),
  funder: text(50),
  startDate,
  budget,
  mainFocus,
  otherKeyFocuses,
  region,
  managers: emailArr,
});

const validate = (data) => _validate(createProgrammeSchema, data);

export default validate;
