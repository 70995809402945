export const gridScreenWidths = {
  mobile: `${450}px`,
  tablet: `${1200}px`,
  desktop: `${1440}px`,
};

export const gridBreakpoints = {
  mobile: `@media (max-width: ${gridScreenWidths.mobile})`,
  tablet: `@media (max-width: ${gridScreenWidths.tablet})`,
  desktop: `@media (max-width: ${gridScreenWidths.desktop})`,
};
