import React from "react";

const AdminOfficeSupport = ({ width, height, color, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    {...props}
  >
    <g clipPath="url(#clip0)" fill="#000">
      <path
        d="M20.5 2.5H16V2a.5.5 0 00-.5-.5h-1.565a2 2 0 00-3.87 0H8.5A.5.5 0 008 2v.5H3.5A.5.5 0 003 3v20.5a.5.5 0 00.5.5h17a.5.5 0 00.5-.5V3a.5.5 0 00-.5-.5zM9 2.5h1.5A.5.5 0 0011 2a1 1 0 012 0 .5.5 0 00.5.5H15v1H9v-1zM20 23H4V3.5h4V4a.5.5 0 00.5.5h7A.5.5 0 0016 4v-.5h4V23z"
        fill={color || "currentColor"}
      />
      <path
        d="M9 17.5H6a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5zm-.5 3h-2v-2h2v2zM9 12H6a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3A.5.5 0 009 12zm-.5 3h-2v-2h2v2zM18 9h-6.5a.5.5 0 000 1H18a.5.5 0 000-1zM11.5 7a.5.5 0 000 1H18a.5.5 0 000-1h-6.5zM18 14.5h-6.5a.5.5 0 000 1H18a.5.5 0 100-1zM18 12.5h-6.5a.5.5 0 000 1H18a.5.5 0 000-1zM18 20h-6.5a.5.5 0 000 1H18a.5.5 0 000-1zM18 18h-6.5a.5.5 0 000 1H18a.5.5 0 000-1zM8.645 7.145L7 8.795l-.645-.65a.502.502 0 10-.71.71l1 1a.5.5 0 00.71 0l2-2a.502.502 0 10-.71-.71z"
        fill={color || "currentColor"}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default AdminOfficeSupport;
