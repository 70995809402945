import { transactions } from "../../api-calls";

import {
  GET_SINGLE_TRANSACTION_SUCCESS,
  GET_SINGLE_TRANSACTION_LOADING,
  GET_SINGLE_TRANSACTION_FAIL,
} from "../types";

export const getSingleTransaction =
  (transactionId, type, options) => async (dispatch) => {
    dispatch({ type: GET_SINGLE_TRANSACTION_LOADING });
    try {
      const { data, error } = await transactions.getTransactionById(
        transactionId,
        type,
        options,
      );

      if (data) {
        dispatch({ type: GET_SINGLE_TRANSACTION_SUCCESS, payload: data });
      } else {
        dispatch({
          type: GET_SINGLE_TRANSACTION_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_SINGLE_TRANSACTION_FAIL,
        payload: error,
      });
    }
  };
