import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import * as S from "./style";
import * as T from "../../../components/Typograpy";
import Image from "../../../components/Image";
import Table from "../../../components/Table";
import { Row, Col } from "../../../components/Grid";

import t from "../../../utils/helpers/translator";

import { GENERAL } from "../../../constants/navRoutes";

import { getMyGroups } from "./actions";

const MyGroups = ({
  groups = [],
  lang,
  getMyGroupsActions,
  fetchingGroups,
}) => {
  const history = useHistory();

  useEffect(() => {
    getMyGroupsActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: t("name", lang),
      render: (rowData) => {
        let _name = rowData.name;
        if (lang === "welsh") {
          _name = rowData.nameWelsh || rowData.name;
        }
        return _name;
      },
    },
    {
      render: (rowData) =>
        rowData.public && (
          <T.LinkB14
            color="blue"
            to={GENERAL.EARN_GROUP.replace(":id", rowData.id)}
          >
            {t("viewProfile", lang)}
          </T.LinkB14>
        ),
    },
  ];

  const rowClick = (e, rowData) =>
    rowData.public &&
    history.push(GENERAL.EARN_GROUP.replace(":id", rowData.id));

  return (
    <S.Wrapper>
      <Row>
        <Col w={[4, 12, 8]}>
          <T.H40Caps color="midnight" mb="30" mbM="20">
            {t("myGroups", lang)}
          </T.H40Caps>
          <T.Body16R color="gray3" mb="50">
            {t("groupsIntro", lang)}
          </T.Body16R>
          <Table
            color="pink"
            columns={columns}
            data={groups}
            isLoading={fetchingGroups}
            hideSearch
            onRowClick={rowClick}
          />
        </Col>
        <Col w={[0, 0, 4]} jc="flex-end" display="flex">
          <Image image="galleryLarge" alt="gallery" height="248px" />
        </Col>
      </Row>
    </S.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  lang: state.auth.language,
  groups: state.memberGroups?.data,
  fetchingGroups: state.memberGroups.loading,
});

const mapActionToProps = {
  getMyGroupsActions: getMyGroups,
};

export default connect(mapStateToProps, mapActionToProps)(MyGroups);
