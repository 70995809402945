import styled from "@material-ui/core/styles/styled";
import * as T from "../../../../components/Typograpy";
import { Col } from "../../../../components/Grid";

export const Container = styled("div")(() => ({}));

export const Head = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
});

export const Back = styled("div")({
  display: "flex",
  marginTop: -30,
  paddingBottom: 30,
});

export const CardContainer = styled("div")({
  display: "flex",
  marginTop: -100,
});

export const StyledCard = styled("div")(({ theme, height, width }) => ({
  background: theme.colors.white,
  borderRadius: "4px",
  boxShadow: theme.customShadows.dp3,
  width: width || "340px",
  height: height ? `${height}px` : `calc(${width}/1.7)`,
  position: "relative",
  padding: "26px 30px",
  marginRight: 45,
  "& h6": {
    fontWeight: "normal",
  },
}));

export const Buttons = styled("div")({
  margin: 0,
  marginBottom: "40px",
});

export const BtnContainer = styled("div")(({ height, width }) => ({
  marginTop: 40,
  width: width || "340px",
  height: height ? `${height}px` : `calc(${width}/1.7)`,
  position: "relative",
  display: "flex",
  flexDirection: "column",
}));

export const Figures = styled("div")({
  display: "flex",
});

export const BtnSide = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  maxWidth: 160,
  textAlign: "left",
});

export const ImageWrapper = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  minWidth: 250,
  height: 210,
  transform: "translate(30px, -30px)",
});

export const SpendActivitiesContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(4),
  display: "flex",
}));

export const Activities = styled("div")({
  display: "inline",
});

export const ActivitiesWrapper = styled("div")({
  display: "flex",
});

export const Activity = styled("div")(({ theme }) => ({
  width: 450,
  display: "flex",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

export const NumberOfActivities = styled(T.H30)(({ theme, color }) => ({
  margin: "0",
  lineHeight: "1.375rem",
  textAlign: "left",
  width: "30px",
  color: theme.colors[color] || color,
}));

export const ActivityType = styled(T.H20)(({ theme }) => ({
  color: theme.colors.gray3,
  marginLeft: "5px",
  lineHeight: "1.375rem",
}));

export const StatWrapper = styled("div")(() => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
}));

export const RemoveWrapper = styled(Col)({
  paddingLeft: 0,
  "& button": {
    padding: 0,
  },
});
