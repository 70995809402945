import React from "react";

const ArrowRight = ({ width, height, color, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...props}
    viewBox="0 0 26 25"
  >
    <path
      d="M13.563 5.469l7.288 7.031-7.288 7.031M19.839 12.5H5.06"
      stroke={color || "currentColor"}
      strokeWidth="1.88"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ArrowRight;
