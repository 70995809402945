export const WEBSITE = "https://wearetempo.org/";
export const FACEBOOK = "https://www.facebook.com/timecredits/";
export const TWITTER = "https://twitter.com/tempo_tweets";
export const LINKEDIN = "https://www.linkedin.com/company/tempo-time-credits/";
export const INSTAGRAM = "https://www.instagram.com/tempotimecredits/";

export const GENERAL_TERMS =
  "https://tempo365.sharepoint.com/:b:/g/Resources/Ebo-11p9_aBDnRBiDuWyWGgBP0N27m4lvjHt2x8bsOyE6w?e=0oBVhf";
export const GENERAL_PRIVACY =
  "https://tempo365.sharepoint.com/:b:/g/Resources/EXn62Fwg0RtNs0VewFWNSe8BU0UndaTddNSwWCRD3Il8HA?e=VT5rIq";

export const MEMBER_TERMS =
  "https://tempo365.sharepoint.com/:b:/g/Resources/EXhhhUZ0ZlJEjrrtWQoBwLUBasRMBGsOmtaHoZuO1wWVIA?e=NVsbrV";
export const MEMBER_PRIVACY = GENERAL_PRIVACY;

export const EARN_TERMS =
  "https://tempo365.sharepoint.com/:b:/g/Resources/EY9rg9ysmtxGm3RvvWFiOFYBcW2tIdYlwgbqc1OX0v7KUw?e=lfFR0B";
export const EARN_PRIVACY = GENERAL_PRIVACY;

export const SPEND_PARTNER_TERMS =
  "https://tempo365.sharepoint.com/:b:/g/Resources/EecfIrJrIE5MvWtKLHSUTf0BM6S_jGtsVNXxZWobyIeqgw?e=Fb2kI3";
export const SPEND_PARTNER_PRIVACY = GENERAL_PRIVACY;

export const SPEND_VENUE_TERMS =
  "https://tempo365.sharepoint.com/:b:/g/Resources/EecfIrJrIE5MvWtKLHSUTf0BM6S_jGtsVNXxZWobyIeqgw?e=Fb2kI3";
export const SPEND_VENUE_PRIVACY = GENERAL_PRIVACY;

export const COOKIE_POLICY =
  "https://tempo365.sharepoint.com/:b:/g/Digital/EfeqWxG-lTBFotlqVrjrRgMBb4w_2aEGKs4ZEvuIdCF6VQ?e=631O8w";
