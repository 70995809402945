import React from "react";
import * as T from "../../Typograpy";

const decideColor = (type) => {
  switch (type) {
    case "spend" || "rejected" || "returning":
      return "pink";
    case "given out":
      return "pink";
    case "gift":
      return "purple";
    case "issue" || "issued":
      return "gray2";
    case "pending":
      return "cyan";
    default:
      return "blue";
  }
};

const LastActive = ({ date, type }) => (
  <span>
    <T.Body14R color="gray3" style={{ textTransform: "capitalize" }}>
      {date}
    </T.Body14R>
    <T.Body14B color={decideColor(type)} style={{ textTransform: "uppercase" }}>
      {type}
    </T.Body14B>
  </span>
);

export default LastActive;
