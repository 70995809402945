import React from "react";

const Completed = ({ width, height, color, ...props }) => (
  <svg
    width={width || "24"}
    height={height || "25"}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="0.5" y="1" width="23" height="23" rx="11.5" fill="white" />
    <path
      d="M16.6872 8.72656H15.8681C15.7532 8.72656 15.6442 8.7793 15.5739 8.86953L10.7423 14.9902L8.42548 12.0547C8.39044 12.0102 8.34576 11.9742 8.29482 11.9494C8.24388 11.9247 8.18798 11.9118 8.13134 11.9117H7.3122C7.23369 11.9117 7.19033 12.002 7.23837 12.0629L10.4481 16.1293C10.5981 16.3191 10.8864 16.3191 11.0376 16.1293L16.761 8.87656C16.8091 8.8168 16.7657 8.72656 16.6872 8.72656Z"
      fill={color || "#201751"}
    />
    <rect
      x="0.5"
      y="1"
      width="23"
      height="23"
      rx="11.5"
      stroke={color || "#201751"}
    />
  </svg>
);
export default Completed;
