import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyle = makeStyles((theme) => ({
  H60Caps: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    textTransform: "uppercase",
    margin: (props) => theme.helpers.setMargin(props),
  },
  H50Caps: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    textTransform: "uppercase",
    lineHeight: "3.125rem", // 50px
    fontSize: "3.125rem", // 50px
    fontWeight: "600",
    margin: (props) => theme.helpers.setMargin(props),
  },
  H40Caps: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    textTransform: "uppercase",
    margin: (props) => theme.helpers.setMargin(props),
  },
  H30Caps: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    textTransform: "uppercase",
    margin: (props) => theme.helpers.setMargin(props),
  },
  H30BoldCaps: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    textTransform: "uppercase",
    lineHeight: "1.875rem", // 30px
    fontSize: "1.875rem", // 30px
    fontWeight: "700",
    margin: (props) => theme.helpers.setMargin(props),
  },
  H30Bold: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    lineHeight: "1.875rem", // 30px
    fontSize: "1.875rem", // 30px
    fontWeight: "700",
    margin: (props) => theme.helpers.setMargin(props),
  },
  H24Caps: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    textTransform: "uppercase",
    fontSize: "1.5rem",
    lineHeight: "1.5625", // 25px
    fontWeight: "900",
    margin: (props) => theme.helpers.setMargin(props),
  },
  H22Caps: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    textTransform: "uppercase",
    lineHeight: "1.5625rem", // 25px
    margin: (props) => theme.helpers.setMargin(props),
  },
  H20Caps: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontSize: "1.25rem",
    fontWeight: "700",
    textTransform: "uppercase",
    lineHeight: "1.875rem", // 30px
    margin: (props) => theme.helpers.setMargin(props),
  },
  H18Caps: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontSize: "1.125rem",
    fontWeight: "700",
    textTransform: "uppercase",
    lineHeight: "1.5625rem", // 25px
    margin: (props) => theme.helpers.setMargin(props),
  },
  H16CapsLH: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    lineHeight: "1.25", // 20px
    textTransform: "uppercase",
    fontWeight: "700",
    margin: (props) => theme.helpers.setMargin(props),
  },
  H16Caps: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    lineHeight: "1.5625rem", // 25px
    textTransform: "uppercase",
    fontWeight: "700",
    margin: (props) => theme.helpers.setMargin(props),
  },
  H14Caps: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    textTransform: "uppercase !important",
    lineHeight: "1.25rem", // 20px
    fontSize: ".875rem",
    fontWeight: "700",
    margin: (props) => theme.helpers.setMargin(props),
  },
  H14CapsLH: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    textTransform: "uppercase",
    lineHeight: "0.9375rem", // 15px
    fontSize: ".875rem",
    fontWeight: "700",
    margin: (props) => theme.helpers.setMargin(props),
  },
  H14CapsS: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    textTransform: "uppercase",
    lineHeight: "1.875rem", // 30px
    fontSize: ".875rem",
    fontWeight: "600",
    margin: (props) => theme.helpers.setMargin(props),
  },
  H14CapsI: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    textTransform: "uppercase",
    lineHeight: "0.9375rem", // 15px
    fontSize: ".875rem",
    fontWeight: "normal",
    fontStyle: "italic",
    margin: (props) => theme.helpers.setMargin(props),
  },
  H60: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontSize: "3.75rem",
    fontWeight: "900",
    lineHeight: "3.75rem",
    margin: (props) => theme.helpers.setMargin(props),
  },
  H50: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontSize: "3.125rem",
    fontWeight: "900",
    lineHeight: "3.125rem",
    margin: (props) => theme.helpers.setMargin(props),
  },
  H40: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    margin: (props) => theme.helpers.setMargin(props),
    fontSize: "2.5rem",
    fontWeight: "900",
    lineHeight: "2.5rem",
  },
  H30: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    margin: (props) => theme.helpers.setMargin(props),
    fontSize: "1.875rem",
    fontWeight: "900",
    lineHeight: "1.875rem",
  },
  H24: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    margin: (props) => theme.helpers.setMargin(props),
    fontSize: "1.5rem",
    fontWeight: "900",
    lineHeight: "1.5rem",
  },
  H22: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontSize: "1.375rem",
    fontWeight: "900",
    lineHeight: "1.5625rem", // 25px
    margin: (props) => theme.helpers.setMargin(props),
  },
  H20: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontStyle: "normal",
    fontSize: "1.25rem",
    fontWeight: "bold",
    lineHeight: "1.25rem", // 20px
    margin: (props) => theme.helpers.setMargin(props),
  },
  H18: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontStyle: "normal",
    fontSize: "1.125rem",
    fontWeight: "bold",
    lineHeight: "1.5625rem", // 25px
    margin: (props) => theme.helpers.setMargin(props),
  },
  H16: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: "1.5625rem", // 25px
    margin: (props) => theme.helpers.setMargin(props),
  },
  Body30: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontSize: "1.875rem",
    fontWeight: "normal",
    whiteSpace: "pre-line",
  },
  Body20B: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontSize: "1.25rem",
    fontWeight: "700",
    lineHeight: "1.875rem", // 30px
    margin: (props) => theme.helpers.setMargin(props),
    whiteSpace: "pre-line",
  },
  Body20: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontSize: "1.25rem",
    fontWeight: "normal",
    lineHeight: "1.875rem", // 30px
    margin: (props) => theme.helpers.setMargin(props),
    whiteSpace: "pre-line",
  },
  Body18B: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontWeight: "900",
    fontStyle: "normal",
    fontSize: "1.125rem",
    lineHeight: "1.5625rem", // 25px
    margin: (props) => theme.helpers.setMargin(props),
    whiteSpace: "pre-line",
  },
  Body18S: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontWeight: "600",
    fontStyle: "normal",
    fontSize: "1.125rem",
    lineHeight: "1.5625rem", // 25px
    margin: (props) => theme.helpers.setMargin(props),
    whiteSpace: "pre-line",
  },
  Body18R: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontWeight: "normal",
    fontStyle: "normal",
    fontSize: "1.125rem",
    lineHeight: "1.5625rem", // 25px
    margin: (props) => theme.helpers.setMargin(props),
    whiteSpace: "pre-line",
  },
  Body16B: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: "1rem",
    lineHeight: "1.5625rem", // 25px
    margin: (props) => theme.helpers.setMargin(props),
    whiteSpace: "pre-line",
  },
  Body16I: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontWeight: "normal",
    fontStyle: "italic",
    fontSize: "1rem",
    lineHeight: "1.5625rem", // 25px
    margin: (props) => theme.helpers.setMargin(props),
    whiteSpace: "pre-line",
  },
  Body16B5: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: "1rem",
    lineHeight: "1.5625rem", // 25px
    margin: (props) => theme.helpers.setMargin(props),
    whiteSpace: "pre-line",
  },
  Body16M: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontWeight: "500",
    fontStyle: "normal",
    fontSize: "1rem",
    lineHeight: "1.5625rem", // 25px
    margin: (props) => theme.helpers.setMargin(props),
    whiteSpace: "pre-line",
  },
  Body16R: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontWeight: "normal",
    fontStyle: "normal",
    fontSize: "1rem",
    lineHeight: "1.5625rem", // 25px
    margin: (props) => theme.helpers.setMargin(props),
    wordWrap: "break-word",
    whiteSpace: "pre-line",
  },
  Body14B: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: "0.875rem",
    lineHeight: "1.25rem", // 20px
    margin: (props) => theme.helpers.setMargin(props),
    whiteSpace: "pre-line",
  },
  Body14S: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontWeight: "600",
    fontStyle: "normal",
    fontSize: "0.875rem",
    lineHeight: "1.25rem", // 20px
    margin: (props) => theme.helpers.setMargin(props),
    whiteSpace: "pre-line",
  },
  Body14R: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontWeight: "normal",
    fontStyle: "normal",
    fontSize: "0.875rem",
    lineHeight: "1.25rem", // 20px
    margin: (props) => theme.helpers.setMargin(props),
    whiteSpace: "pre-line",
  },
  Body14I: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontWeight: "normal",
    fontStyle: "italic",
    fontSize: "0.875rem",
    lineHeight: "1.25rem", // 20px
    margin: (props) => theme.helpers.setMargin(props),
    whiteSpace: "pre-line",
  },
  Body18I: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontWeight: "normal",
    fontStyle: "italic",
    fontSize: "1.125rem",
    lineHeight: "1.25rem", // 20px
    margin: (props) => theme.helpers.setMargin(props),
    textTransform: ({ caps }) => caps && "uppercase",
    whiteSpace: "pre-line",
  },
  Body12B: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontWeight: "600",
    fontStyle: "normal",
    fontSize: "0.75rem",
    lineHeight: "1.25rem", // 20px
    textTransform: ({ caps }) => (caps ? "uppercase" : "normal"),
    margin: (props) => theme.helpers.setMargin(props),
    whiteSpace: "pre-line",
  },
  Body12R: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontWeight: "normal",
    fontStyle: "normal",
    fontSize: "0.75rem",
    lineHeight: "1.25rem", // 20px
    margin: (props) => theme.helpers.setMargin(props),
    whiteSpace: "pre-line",
  },
  LinkB20: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontSize: "1.25rem",
    fontWeight: "bold",
    textDecoration: "underline",
    margin: (props) => theme.helpers.setMargin(props),
    wordWrap: "break-word !important",
    overflowWrap: "break-word",
    "-ms-word-break": "break-all",
    wordBreak: "break-word",
  },
  LinkB16: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontSize: "1rem",
    fontWeight: "bold",
    lineHeight: "1.5625rem", // 25px
    textDecoration: "underline",
    margin: (props) => theme.helpers.setMargin(props),
    wordWrap: "break-word !important",
    overflowWrap: "break-word",
    "-ms-word-break": "break-all",
    wordBreak: "break-word",
  },
  LinkB14: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontSize: "0.875rem",
    fontWeight: "bold",
    textDecoration: "underline",
    margin: (props) => theme.helpers.setMargin(props),
    wordWrap: "break-word !important",
    overflowWrap: "break-word",
    "-ms-word-break": "break-all",
    wordBreak: "break-word",
  },
  LinkS14: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontSize: "0.875rem",
    fontWeight: "600",
    textDecoration: "underline",
    margin: (props) => theme.helpers.setMargin(props),
    wordWrap: "break-word !important",
    overflowWrap: "break-word",
    "-ms-word-break": "break-all",
    wordBreak: "break-word",
  },
  Link14R: {
    color: ({ color }) => (color ? theme.colors[color] : theme.colors.gray1),
    fontSize: "0.875rem",
    fontWeight: "normal",
    textDecoration: "underline",
    margin: (props) => theme.helpers.setMargin(props),
    wordWrap: "break-word !important",
    overflowWrap: "break-word",
    "-ms-word-break": "break-all",
    wordBreak: "break-word",
  },
  bold: {
    fontWeight: "bold",
  },
}));

export default useStyle;
