import React, { useEffect } from "react";
import { connect } from "react-redux";

import * as T from "../Typograpy";
import Table from "../Table";
import { Row, Col } from "../Grid";

import dateFormatter from "../../utils/helpers/dateFormatter";
import t from "../../utils/helpers/translator";
import * as actions from "./actions";

const columns = [
  {
    title: "Main account holder",
    render: ({ email }) => email,
    cellStyle: {
      width: "30%",
      fontSize: "0.875rem",
      fontWeight: "normal",
    },
  },
  {
    title: "Date",
    field: "createdAt",
    render: (rowData) => <span>{dateFormatter(rowData.createdAt)}</span>,
    cellStyle: {
      fontSize: "0.875rem",
      fontWeight: "normal",
    },
  },
  {
    title: "Authorized by",
    field: "authorizedBy",
    disableClick: true,
    render: ({ authorizedBy }) => authorizedBy,
    cellStyle: {
      fontSize: "0.875rem",
      fontWeight: "normal",
      padding: 0,
    },
  },
];

const AccountHolderLog = ({
  earnGroupUserId,
  loading,
  error,
  earnGroupAccountHolderLogs,
  getEarnGroupAccountHolderLogs,
  language,
}) => {
  useEffect(() => {
    getEarnGroupAccountHolderLogs({ earnGroupUserId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row mt={9} mb={2}>
        <Col w={[4, 8, 8]}>
          <T.H24 color="midnight">Account Holder Log</T.H24>
        </Col>
      </Row>
      <Table
        isLoading={loading}
        data={earnGroupAccountHolderLogs}
        columns={columns}
        hideSearch
        limit={5}
      />
      {error && (
        <Row mt={2}>
          <Col w={[4, 8, 8]}>
            <T.Body16R color="pink">{t(error.message, language)}</T.Body16R>
          </Col>
        </Row>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.earnGroupAccountHolderLogs.loading,
  error: state.earnGroupAccountHolderLogs.error,
  earnGroupAccountHolderLogs: state.earnGroupAccountHolderLogs.data,
  language: state.auth.language,
});

const mapActionsToProps = {
  getEarnGroupAccountHolderLogs: actions.getEarnGroupAccountHolderLogs,
};

export default connect(mapStateToProps, mapActionsToProps)(AccountHolderLog);
