import styled from "@material-ui/core/styles/styled";

export const Container = styled("div")(() => ({
  width: "100%",
}));

export const Head = styled("div")({
  position: "relative",
  display: "flex",
  justifyContent: "space-between",
});

export const TableContainer = styled("div")({
  marginTop: 30,
  display: "flex",
  flexDirection: "column",
  position: "relative",
});

export const BtnContainer = styled("div")({
  display: "flex",
  alignSelf: "flex-end",
  transform: "translateY(60px)",
  zIndex: 2,
});

export const CsvExport = styled("a")({
  textDecoration: "none",
});
