// import styled from "styled-components";
import { Link } from "react-router-dom";
import { styled } from "@material-ui/core/styles";
// import theme from "../../../utils/theme";

export const Wrapper = styled(Link)(({ theme, p, w }) => ({
  color: theme.colors.white,
  textDecoration: "none",
  width: w || "100%",
  display: "flex",
  flexDirection: "column",
  padding: p || "20px",
  background: theme.gradients.pink,
  cursor: "pointer",
  borderRadius: "4px",
  boxShadow: theme.customShadows.card,
  minHeight: "178px",

  "&:hover": {
    background: theme.colors.pink,
    boxShadow: "none",
  },
}));

export const Header = styled("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(3),
}));
