import React, { useState } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Icon from "../Icon";
import Popper from "../Popper";
import * as S from "./style";

const QuestionPop = ({ message, size }) => {
  const [popperOpen, togglePopper] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickAway = () => {
    togglePopper(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    togglePopper(!popperOpen);
  };

  return (
    <S.PopContainer>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <S.PopBtn type="button" onClick={handleClick}>
            <Icon
              icon="questionMark"
              width={size === "l" ? "24px" : "21px"}
              height={size === "l" ? "24px" : "21px"}
              color="blue"
              style={{ cursor: "pointer" }}
            />
          </S.PopBtn>
          <Popper
            placement="bottom"
            open={popperOpen}
            content={message}
            icon="info"
            anchorEl={anchorEl}
            marginThreshold={30}
          />
        </div>
      </ClickAwayListener>
    </S.PopContainer>
  );
};

export default QuestionPop;
