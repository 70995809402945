import { earnGroups } from "../../../../api-calls";

import {
  ADD_MEMBERS_TO_EARN_GROUP_LOADING,
  ADD_MEMBERS_TO_EARN_GROUP_SUCCESS,
  ADD_MEMBERS_TO_EARN_GROUP_FAIL,
} from "../../../../redux/types";

export const addMembersToEarnGroup =
  ({ earnGroupUserId, membersToAdd }) =>
  async (dispatch) => {
    dispatch({ type: ADD_MEMBERS_TO_EARN_GROUP_LOADING });
    try {
      const { error } = await earnGroups.addMembersToEarnGroup({
        earnGroupUserId,
        membersToAdd,
      });

      if (error) {
        dispatch({
          type: ADD_MEMBERS_TO_EARN_GROUP_FAIL,
          payload: error,
        });
      } else {
        dispatch({
          type: ADD_MEMBERS_TO_EARN_GROUP_SUCCESS,
        });
      }
    } catch (err) {
      dispatch({
        type: ADD_MEMBERS_TO_EARN_GROUP_FAIL,
        payload: err,
      });
    }
  };
