import React from "react";
import * as S from "./style";

import Button from "../Button";
import Image from "../Image";

const Content = ({
  warning,
  children,
  width,
  position,
  withCancel,
  confirmFun,
  handleCloseModal,
  buttonStyle,
  loading,
  error,
  buttonText,
  onCancel,
  custom,
  keepOpenOnConfirm,
  cancelButtonText,
}) => {
  if (custom) {
    return (
      <S.Container width={width} position={position}>
        <S.Body>{children}</S.Body>
        <Image image="success" alt="success" width="180px" height="180px" />
      </S.Container>
    );
  }
  return warning ? (
    <S.WarningContainer>{children}</S.WarningContainer>
  ) : (
    <S.Container width={width} position={position}>
      <S.Body>
        {children}
        {!withCancel ? (
          <Button
            primary
            onClick={() => {
              confirmFun();
              handleCloseModal();
            }}
            style={{ margin: "20px 0 0 0", ...buttonStyle }}
            loading={loading}
            disabled={loading || error}
          >
            {buttonText || "Done"}
          </Button>
        ) : (
          <div
            style={{
              width: "90%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              outline
              onClick={() => {
                onCancel();
                handleCloseModal();
              }}
              style={{ margin: "20px 10px 0 0" }}
            >
              {cancelButtonText || "Cancel"}
            </Button>
            <Button
              primary
              onClick={(e) => {
                confirmFun(e);
                if (!keepOpenOnConfirm) handleCloseModal();
              }}
              style={{ margin: "20px 0 0 0" }}
              loading={loading}
            >
              {buttonText || "Done"}
            </Button>
          </div>
        )}
      </S.Body>
      <Image image="success" alt="success" width="200px" height="205px" />
    </S.Container>
  );
};
export default Content;
