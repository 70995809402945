import { styled } from "@material-ui/core/styles";
import FooterBackgroundLeft from "../../assets/graphics/footerBackgroundLeft.svg";
import FooterBackgroundRight from "../../assets/graphics/footerBackgroundRight.svg";

export const Wrapper = styled("footer")(({ theme, navbar, profile }) => ({
  position: "absolute",
  width: "100%",
  display: "flex",
  bottom: profile ? "132px" : "0",
  left: "0",
  height: "300px",
  paddingLeft: "8%",
  zIndex: "10",

  [theme.breakpoints.mobileL]: {
    background: theme.gradients.blue,
    height: "180px",
  },

  [theme.breakpoints.tablet]: {
    left: navbar && "320px",
    width: navbar && "calc(100% - 320px)",
    paddingLeft: navbar ? theme.spacing(6) : theme.spacing(9),
    bottom: "0",
  },
}));

export const Links = styled("nav")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  position: "absolute",
  bottom: theme.spacing(3),
  paddingLeft: theme.spacing(3),

  [theme.breakpoints.mobileM]: {
    bottom: theme.spacing(5),
  },

  [theme.breakpoints.mobileL]: {
    top: theme.spacing(5),
    justifyContent: "flex-start",
    bottom: "0",
  },
}));

export const StyledLink = styled("a")(({ theme }) => ({
  color: theme.colors.white,
  marginTop: theme.spacing(1),

  "& p": {
    color: theme.colors.white,
  },

  [theme.breakpoints.mobileL]: {
    marginTop: "0",
    marginBottom: theme.spacing(1),
  },
}));

export const SocialLinkWrapper = styled("div")(({ theme, navbar }) => ({
  position: "absolute",
  right: theme.spacing(2),
  display: "flex",
  justifyContent: "flex-end",
  bottom: theme.spacing(3),

  [theme.breakpoints.mobileM]: {
    bottom: theme.spacing(5),
  },

  [theme.breakpoints.mobileL]: {
    right: "8%",
    top: theme.spacing(5),
    justifyContent: "flex-start",
  },

  [theme.breakpoints.tablet]: {
    right: navbar ? theme.spacing(6) : theme.spacing(9),
  },
}));

export const BackgroundLeft = styled("div")(({ theme }) => ({
  position: "absolute",
  left: "0",
  bottom: "0",
  width: "100%",
  zIndex: "-2",
  height: "300px",
  background: `no-repeat url(${FooterBackgroundLeft})`,
  backgroundPosition: `0 100%`,
  backgroundSize: "contain",

  [theme.breakpoints.mobileL]: {
    display: "none",
  },
}));

export const BackgroundRight = styled("div")(({ theme }) => ({
  position: "absolute",
  right: "0",
  bottom: "0",
  width: "100%",
  zIndex: "-1",
  height: "270px",
  background: `no-repeat url(${FooterBackgroundRight})`,
  backgroundPosition: `100% 100%`,
  backgroundSize: "contain",

  [theme.breakpoints.mobileL]: {
    display: "none",
  },
}));

export const SocialLink = styled("a")(({ theme }) => ({
  display: "block",
  marginLeft: theme.spacing(1),
  width: "20px",
  height: "20px",
  color: "white",

  [theme.breakpoints.mobileL]: {
    right: "8%",
    top: theme.spacing(5),
    width: "24px",
    height: "24px",
  },
}));
