import makeStyles from "@material-ui/core/styles/makeStyles";

const decideHeight = (hideLangToggle) => {
  if (hideLangToggle) return "80px";
  return "125px";
};

const useStyle = makeStyles((theme) => ({
  Wrapper: {
    minHeight: "100vh",
    position: "relative",
    background: ({ background }) =>
      background ? theme.gradients[background] : "none",
    paddingTop: ({ page, hideLangToggle }) =>
      page ? 0 : decideHeight(hideLangToggle),
    paddingBottom: ({ footer }) => (footer ? "320px" : theme.spacing(11)),

    [theme.breakpoints.tablet]: {
      background: ({ background }) => (background ? "none" : "none"),
      display: "flex",
      maxWidth: "2000px",
      paddingLeft: ({ sideBar, page }) => {
        if (page) {
          return 50;
        }

        if (sideBar) {
          return "calc(320px + 30%)";
        }

        return "320px";
      },
      paddingBottom: theme.spacing(11),
    },
  },
  LogoHeader: {
    display: "block",
    paddingLeft: "38px",
  },
  ContentWrapper: {
    paddingBottom: theme.spacing(6),
    paddingLeft: ({ fullWidth }) => (fullWidth ? "0" : "10px"),
    paddingRight: ({ fullWidth }) => (fullWidth ? "0" : "10px"),

    [theme.breakpoints.mobileM]: {
      paddingTop: "5px",
      paddingLeft: ({ fullWidth }) => (fullWidth ? "10px" : "30px"),
      paddingRight: ({ fullWidth }) => (fullWidth ? "10px" : "30px"),
    },
    [theme.breakpoints.mobileXL]: {
      paddingLeft: ({ page }) => (page ? 30 : 60),
      paddingRight: ({ page }) => (page ? 0 : "5%"),
    },

    [theme.breakpoints.tablet]: {
      padding: ({ page, header, sideBar }) => {
        if (page) {
          return `0 0 0 30px`;
        }

        if (header) {
          return `${theme.spacing(6)}px 8% 0 8%`;
        }

        if (sideBar) {
          return `${theme.spacing(8)}px 8% 0 8%`;
        }
        return `0 8% 0 8%`;
      },
      [theme.breakpoints.laptop]: {
        paddingTop: 0,
      },
      width: "100%",
    },
  },

  //   these styles can be moved to the relevant component once we build the navbar, sidemenu and footer
  NavBar: {
    width: "100vw",
    position: "fixed",
    left: 0,
    top: ({ hideLangToggle }) => (hideLangToggle ? 0 : "44px"),
    height: theme.spacing(8),
    backgroundColor: "gray",
    zIndex: "9999",
    [theme.breakpoints.tablet]: {
      top: "0 !important",
      width: "320px",
      height: "100vh",
    },
  },

  SideBar: {
    display: "none",
    [theme.breakpoints.tablet]: {
      height: "100vh",
      width: "30%",
      maxWidth: "450px",
      position: "fixed",
      top: 0,
      background: theme.gradients.blue,
      display: "block",
      left: "320px",
    },
  },

  Footer: {
    width: "100%",
    position: "fixed",
    bottom: "0",
    height: "180px",
    backgroundColor: "lightGray",
  },
}));

export default useStyle;
