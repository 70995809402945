import ReactGA from "react-ga4";

import {
  SIGN_UP_POST_LOADING_START,
  SIGN_UP_POST_LOADING_END,
  SIGN_UP_POST_SUCCESS,
  SIGN_UP_POST_FAIL,
} from "../../redux/types";
import { auth } from "../../api-calls";
import { navRoutes } from "../../constants";

export const addGroupSignup = (formData, history) => async (dispatch) => {
  dispatch({
    type: SIGN_UP_POST_LOADING_START,
  });

  const { data, error } = await auth.addGroupSignup({ formData });
  dispatch({
    type: SIGN_UP_POST_LOADING_END,
  });
  if (data) {
    dispatch({
      type: SIGN_UP_POST_SUCCESS,
      payload: data,
    });

    // Analytics track earn groups who completed sign up
    if (process.env.NODE_ENV === "production") {
      ReactGA.event({
        category: "Earn Group",
        action: "Completed sign up",
      });
    }
    history.push(navRoutes.EARN_GROUP.DASHBOARD);
  } else if (error) {
    dispatch({
      type: SIGN_UP_POST_FAIL,
      payload: error,
    });
  }
};
