import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Image from "../../../components/Image";
import * as S from "./style";
import { Regular } from "../../../components/Button";
import * as links from "../../../constants/navRoutes";
import * as actions from "./actions";
import userRoles from "../../../constants/roles";

import t from "../../../utils/helpers/translator";

const AcceptTcSuccess = ({
  membershipId,
  reason,
  role,
  lang,
  resetPostError,
}) => {
  const history = useHistory();
  const { location: state = {} } = history;
  const onClick = async () => {
    await resetPostError();
    return role === userRoles.MEMBER
      ? history.push(links.MEMBER.DASHBOARD)
      : history.push(links.SPEND_VENUE.ACCEPT_TC);
  };
  return (
    <S.SuccessWrapper>
      <Image
        image="unsuccessfulLarge"
        alt="unsuccessful"
        width="250px"
        height="auto"
        customStyle={{
          maxHeight: "280px",
          margin: "0 0 30px 0",
        }}
      />
      <S.StyledHeader>
        <S.H40Caps style={{ textAlign: "left" }}>
          {t("unsuccessful", lang)}
        </S.H40Caps>
      </S.StyledHeader>

      <S.Black>{t("ohNoShort", lang)}.</S.Black>
      <S.GrayBold>
        {t("reason", lang)}:<S.Gray> {t(reason, lang) || "N/A"}</S.Gray>
      </S.GrayBold>

      {role === userRoles.MEMBER ? (
        <Regular bgColor="blue" primary mt={50} onClick={onClick}>
          {t("goBack", lang)}
        </Regular>
      ) : (
        <>
          <S.Black>
            {t("didYouEnterCorrectly", lang)}{" "}
            <S.BlackBold>
              {(state && state.membershipId) || membershipId || "N/A"}
            </S.BlackBold>
          </S.Black>
          <S.Black>
            {t("pleaseAskCustomer", lang)}{" "}
            <S.Underline onClick={onClick}>
              {t("tryingAgain", lang)}
            </S.Underline>
          </S.Black>
          <Regular bgColor="blue" primary mt={40} onClick={onClick}>
            {t("done", lang)}
          </Regular>{" "}
        </>
      )}
    </S.SuccessWrapper>
  );
};

const mapStateToProps = (state) => ({
  membershipId: state.AcceptTC.membershipId,
  role: state.auth.role,
  reason: state.AcceptTC.postError.message,
  lang: state.auth.language,
});

export default connect(mapStateToProps, {
  resetPostError: actions.resetPostError,
})(AcceptTcSuccess);
