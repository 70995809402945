import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import * as T from "../Typograpy";
import t from "../../utils/helpers/translator";

const ChangeAccount = ({ lang, to = "/", mb }) => (
  <Link
    style={{
      textDecoration: "none",
      display: "flex",
      // justifyContent: "center",
      alignItems: "center",
      paddingLeft: "5px",
      marginBottom: mb || 0,
      marginTop: "10px",
    }}
    to={to}
  >
    <Icon icon="changeArrows" color="cyan" width="30" height="30" />
    <T.Body14B color="gray3" style={{ display: "inline" }} ml="12">
      {t("switchAccount", lang).toUpperCase()}
    </T.Body14B>
  </Link>
);

const mapStateToProps = (state) => ({
  lang: state.auth.language,
});

export default connect(mapStateToProps)(ChangeAccount);
