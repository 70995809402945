import React, { useState, useEffect } from "react";
import moment from "moment";

import useStyles from "./style";
import SpendActivity from "../../../components/Cards/Activity/Spend";
import * as T from "../../../components/Typograpy";
import { InputField, Counter } from "../../../components/Inputs";
import Info from "../../../components/Message/Info";
import Warning from "../../../components/Message/Warning";
import Graphic from "../../../components/Message/Graphic";
import Button from "../../../components/Button";
import WarningMessage from "../../../components/WarningMessage";
import * as S from "./style";
import R from "../../../constants/roles";
import { EARN_GROUP } from "../../../constants/navRoutes";

import { getRestrictionText, concatArrayToString } from "./helpers";
import t from "../../../utils/helpers/translator";
import isSoldOut from "../../../utils/helpers/is-sold-out";

const Content = ({
  name,
  nameWelsh,
  membershipId,
  activities,
  selectedActivityId,
  selectedActivity,
  adultsParticipants,
  childrenParticipants,
  setSelectedActivityId,
  price,
  isDisabled,
  onSubmit,
  postLoading,
  errors,
  handleID,
  handleCounter,
  setCustomActivityName,
  setCustomPersonTcPrice,
  setCostVaryPersonTcPrice,
  customActivityName,
  customPersonTcPrice,
  costVaryPersonTcPrice,
  isVerified,
  language,
  role,
  fullName,
  setFullName,
  setError,
  volunteerCapacityError,
}) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const handleFullName = (e) => {
    setFullName(e.target.value);
  };
  const classes = useStyles();

  const adultsParticipantsNumber = Number(adultsParticipants.counter || 0);
  const childrenParticipantsNumber = Number(childrenParticipants.counter || 0);

  const checkAvailableActivities = () => {
    if (activities.length < 1) return false;
    const filteredActivities = activities.filter(
      (activity) => activity.turnUpAtVenue || activity.callToBook,
    );
    if (filteredActivities.length < 1) return false;
    return true;
  };

  useEffect(() => {
    if (volunteerCapacityError) {
      setConfirmModalOpen(true);
    }
  }, [volunteerCapacityError]);

  const handleModalConfirm = () => {
    if (!fullName) {
      return setError((_) => ({ ..._, fullName: "this field is required" }));
    }
    setError((_) => ({ ..._, fullName: "" }));

    onSubmit(!!volunteerCapacityError);
  };

  const { costVaries } = selectedActivity;

  let disableCounter = false;
  if (costVaries) {
    disableCounter = !Number(costVaryPersonTcPrice);
  } else {
    disableCounter =
      !Number(customPersonTcPrice) && !selectedActivity.personTcPrice;
  }

  const findRestrictionReached = (obj) => {
    const bold = [];
    let light = [];

    if (obj.capacity?.isReached) {
      bold.push(t("capacityReached", language));
    }

    if (obj.perMonth?.isReached) {
      bold.push(t("perMonthReached", language));
    } else if (obj.perMonth?.capacity) {
      light.push(t("perMonth", language));
    }
    if (obj.perVolunteerPerMonth?.isReached) {
      bold.push(t("perVolunteerPerMonthReached", language));
    } else if (obj.perVolunteerPerMonth?.capacity) {
      light.push(t("perVolunteerPerMonth", language));
    }
    if (obj.perWeek?.isReached) {
      bold.push(t("perWeekReached", language));
    } else if (obj.perWeek?.capacity) {
      light.push(t("perWeek", language));
    }
    if (obj.perVolunteerPerWeek?.isReached) {
      bold.push(t("perVolunteerPerWeekReached", language));
    } else if (obj.perVolunteerPerWeek?.capacity) {
      light.push(t("perVolunteerPerWeek", language));
    }
    if (obj.perVolunteer?.capacity) {
      light.push(t("perVolunteer", language));
    }
    if (obj.perSlot?.capacity) {
      light.push(t("perSlot", language));
    }
    if (obj.perTransaction?.capacity) {
      light.push(t("perTransaction", language));
    }

    if (light.length) {
      light.unshift(t("restrictionExists", language));
    }

    if (light.length > 0) {
      const contentArray = light.map((key) => t(key, language));
      light = concatArrayToString(contentArray);
    } else {
      light = "";
    }

    return {
      bold,
      light,
    };
  };

  const handleSubmitForm = () => {
    let soldOut;
    if (selectedActivityId) {
      soldOut = isSoldOut(
        selectedActivity.reachedRestrictions,
        adultsParticipantsNumber,
        childrenParticipantsNumber,
      );
    }

    if (soldOut) {
      return setConfirmModalOpen(true);
    }

    onSubmit();
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <header className={classes.header}>
          <div>
            <T.H18Caps color="pink" classes={classes.venueName}>
              {language === "welsh" && nameWelsh ? nameWelsh : name}
            </T.H18Caps>
            <T.H30Caps color="midnight" classes={classes.pageTitle}>
              {t("acceptTempoTC", language)}
            </T.H30Caps>
          </div>

          {[R.EARN_GROUP, R.EARN_GROUP_ADMIN].includes(role) && isVerified && (
            <div>
              <Button outline to={EARN_GROUP.OFFERS}>
                {t("viewManageOffers", language)}
              </Button>
            </div>
          )}
        </header>
        <section className={classes.section}>
          <h4 className={classes.sectionNumber}>1.</h4>
          <div className={`${classes.sectionBody}`}>
            <T.H18 classes={classes.sectionHeading}>
              {t("askCustomerForID", language)}
            </T.H18>
            <div
              className={`${classes.sectionBody} ${classes.flex} ${classes.membershipWrapper}`}
            >
              <S.membershipInputWrapper extraMargin={!membershipId}>
                <InputField
                  placeholder={`${t("typeMembershipID", language)}...`}
                  justify="flex-start"
                  setValue2={handleID}
                  error={errors.membershipId}
                  size="normal"
                />
              </S.membershipInputWrapper>
              {!membershipId && (
                <Info header={t("canBeFound", language)} size="small" />
              )}
            </div>
          </div>
        </section>
        <section className={classes.section}>
          <h4 className={classes.sectionNumber}>2.</h4>
          <div className={classes.sectionBody}>
            <T.H18 classes={classes.sectionHeading}>
              {t("chooseActivity", language)}:
            </T.H18>
            <div className={`${classes.activitiesWrapper} ${classes.flex}`}>
              {(checkAvailableActivities() &&
                activities.map((activity) => (
                  <React.Fragment key={activity.id}>
                    <div className={classes.activity}>
                      <SpendActivity
                        showComingSoon={activity.soldOut}
                        activity={
                          language === "welsh" && activity.nameWelsh
                            ? activity.nameWelsh
                            : activity.name
                        }
                        availability={moment(activity.startDate).format(
                          "DD MMMM YYYY",
                        )}
                        cost={activity.personTcPrice}
                        costVaries={activity.costVaries}
                        id={activity.id}
                        selected={selectedActivityId === activity.id}
                        handleClick={setSelectedActivityId}
                        customWidth="300px"
                        language={language}
                      />
                    </div>

                    {selectedActivityId &&
                      selectedActivityId === activity.id &&
                      (activity.soldOut || selectedActivity.restrictions) && (
                        <Warning
                          header={`${selectedActivity.name} ${t(
                            "hasRestrictions",
                            language,
                          )}`}
                          restrictionsBoldArray={
                            findRestrictionReached(
                              selectedActivity.reachedRestrictions,
                            ).bold
                          }
                          restrictionsArray={[
                            findRestrictionReached(
                              selectedActivity.reachedRestrictions,
                            ).light,
                            selectedActivity.restrictions,
                          ].filter((e) => !!e)}
                          warningType="restriction"
                        />
                      )}
                  </React.Fragment>
                ))) || <Graphic header={t("wantToAddSomething", language)} />}
            </div>
          </div>
        </section>
        {costVaries && (
          <section
            className={classes.sectionBody}
            style={{ marginTop: "30px" }}
          >
            <S.CustomInputsWrapper>
              <InputField
                placeholder={`${t("tcPerPersonHere", language)}...`}
                justify="flex-start"
                type="number"
                setValue2={setCostVaryPersonTcPrice}
                error={errors.costVaryPersonTcPrice}
                value={costVaryPersonTcPrice}
                label={t("tcPerPerson", language)}
                size="normal"
              />
            </S.CustomInputsWrapper>
          </section>
        )}
        {isVerified && (
          <section
            className={`${classes.section} ${
              costVaries ? classes.disabled : ""
            }`}
            style={{ marginTop: "30px" }}
          >
            <div className={classes.sectionBody}>
              <S.SomethingElse>{t("somethingElse", language)}?</S.SomethingElse>
              <div className={`${classes.flex}`}>
                <S.CustomInputsWrapper marginRight>
                  <InputField
                    placeholder={`${t("provideAsMuchDetail", language)}...`}
                    justify="flex-start"
                    setValue2={setCustomActivityName}
                    error={errors.customActivityName}
                    value={customActivityName}
                    label={t("activity", language)}
                    size="normal"
                    disabled={costVaries}
                  />
                </S.CustomInputsWrapper>
                <S.CustomInputsWrapper>
                  <InputField
                    placeholder={`${t("tcPerPersonHere", language)}...`}
                    justify="flex-start"
                    type="number"
                    setValue2={setCustomPersonTcPrice}
                    error={errors.customPersonTcPrice}
                    value={customPersonTcPrice}
                    label={t("tcPerPerson", language)}
                    size="normal"
                    disabled={costVaries}
                  />
                </S.CustomInputsWrapper>
              </div>
            </div>
          </section>
        )}
        <section className={classes.section}>
          <h4 className={classes.sectionNumber}>3.</h4>
          <div className={classes.sectionBody}>
            <T.H18 classes={classes.sectionHeading}>
              {t("pickNumberOfPeople", language)}
            </T.H18>
            <div className={`${classes.counters}`}>
              <Counter
                value={adultsParticipantsNumber}
                setValue={(value) => handleCounter(value, "adults")}
                mt="2"
                label={t("adults", language)}
                subLabel={`(${t("over16", language)})`}
                error={errors.adultsParticipants}
                disabled={disableCounter}
              />
              <Counter
                value={childrenParticipantsNumber}
                setValue={(value) => handleCounter(value, "children")}
                mt="2"
                label={t("children", language)}
                subLabel={`(${t("under16", language)})`}
                error={errors.childrenParticipants}
                disabled={disableCounter}
              />
            </div>
            {price > 0 && (
              <div className={classes.costWrapper}>
                <T.Body20B color="midnight">
                  {t("thisWillCost", language)}
                </T.Body20B>
                <T.H60 color="midnight">{price}</T.H60>
                <T.Body20B color="pink">
                  {t("timeCreditsNumInFront", language)}
                </T.Body20B>
              </div>
            )}
          </div>
        </section>
        <S.ButtonContainer>
          {errors.anyError && (
            <T.Body16R color="pink">
              {t("inputFieldMissing", language)}
            </T.Body16R>
          )}
          {isDisabled || postLoading ? (
            <Button disabled>{t("acceptTempoTC", language)}</Button>
          ) : (
            <Button primary onClick={handleSubmitForm}>
              {t("acceptTempoTC", language)}
            </Button>
          )}
        </S.ButtonContainer>
      </div>
      {selectedActivityId && (
        <WarningMessage
          open={confirmModalOpen}
          setOpen={setConfirmModalOpen}
          handleClick={handleModalConfirm}
          title={t("areYouSure", language)}
          disabled={!fullName}
        >
          <T.Body14R color="gray3" style={{ marginBottom: "20px" }}>
            {t("toProceed", language)}
          </T.Body14R>
          <T.Body14R color="gray3">
            {volunteerCapacityError
              ? t("proceedingTransactionWithError", language)
              : getRestrictionText(
                  activities.find((item) => item.id === selectedActivityId)
                    ?.reachedRestrictions,
                  language,
                  adultsParticipantsNumber + childrenParticipantsNumber,
                )}
          </T.Body14R>
          <InputField
            type="text"
            name="fullName"
            placeholder={`${t("typeFullName", language)}...`}
            value={fullName}
            setValue={handleFullName}
            mt={4}
            style={{ maxWidth: 300 }}
          />
        </WarningMessage>
      )}
    </div>
  );
};
export default Content;
