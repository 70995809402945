import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";

import moment from "moment";

// AirBnB date picker package
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
// currently no custom syling -> overwrite css classes
import { useTheme } from "@material-ui/core/styles";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./react_dates_overrides.css";
import {
  DateRangePicker,
  SingleDatePicker,
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
} from "react-dates";
import { connect } from "react-redux";
import * as T from "../../Typograpy";
import useStyles from "../style";
import t from "../../../utils/helpers/translator";

const falseFunc = () => false;

const DatePicker = (props) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const classes = useStyles(props);
  const {
    value = { startDate: null, endDate: null, singleDate: null },
    setValue,
    label,
    error,
    singleDatePicker,
    justify,
    disablePast,
    disableFuture,
    language,
    customLang,
    onClose,
  } = props;
  let isOutsideRange = falseFunc;
  if (disablePast) {
    isOutsideRange = (day) => !isInclusivelyAfterDay(day, moment());
  } else if (disableFuture) {
    isOutsideRange = (day) => !isInclusivelyBeforeDay(day, moment());
  }

  const [focus, setFocus] = useState(null);

  const { startDate, endDate, singleDate } = value;

  const handleDateChange = ({ startDate: _startDate, endDate: _endDate }) =>
    setValue({ dates: { startDate: _startDate, endDate: _endDate } });

  const handleSingleDateChange = (date) => {
    setValue({ dates: { singleDate: date } });
    setFocus(null);
  };
  return (
    <Grid
      container
      direction="column"
      justify={justify || "center"}
      alignItems={singleDatePicker ? "flex-start" : "center"}
      className={classes.root}
    >
      {label && (
        <T.Body18B
          className={classes.textInputLabel}
          style={{ width: "100%", paddingLeft: "5px" }}
        >
          {label}
        </T.Body18B>
      )}
      <div
        className={
          singleDatePicker ? classes.singleDateWrapper : classes.calendarWrapper
        }
        noZIndex={props.noZIndex}
      >
        {singleDatePicker ? (
          <SingleDatePicker
            {...props}
            startDatePlaceholderText={t("startDate", customLang || language)}
            onDateChange={handleSingleDateChange}
            endDatePlaceholderText={t("endDate", customLang || language)}
            focused={focus}
            onFocusChange={() => setFocus(!focus)}
            id="singleDate"
            date={singleDate}
            size="normal"
            className={classes.textInputLabel}
          />
        ) : (
          <DateRangePicker
            {...props}
            startDatePlaceholderText={t("startDate", customLang || language)}
            startDate={startDate ? moment(startDate) : undefined}
            onDatesChange={handleDateChange}
            endDatePlaceholderText={t("endDate", customLang || language)}
            endDate={endDate ? moment(endDate) : undefined}
            focusedInput={focus}
            onFocusChange={(_focus) => setFocus(_focus)}
            startDateId="startDate"
            endDateId="endDate"
            minimumNights={0}
            displayFormat={() => "DD/MM/YYYY"}
            orientation={isMobile ? "vertical" : "horizontal"}
            withFullScreenPortal={isMobile}
            readOnly
            isOutsideRange={isOutsideRange}
            onClose={onClose}
          />
        )}
      </div>
      {error && (
        <T.Body14R className={classes.textFieldError}>{error}</T.Body14R>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  language: state.auth.decideLanguage(state.auth),
});
export default connect(mapStateToProps, {})(DatePicker);
