export const earnGroupContent = [
  {
    img: "welcomeCustomer1",
    title: "welcomeBig",
    size: "large",
    content: "nowPartOfNetwork",
    btnText: "continue",
    color: "blue",
  },
  {
    img: "welcomeCustomer2",
    title: "giveOutTempoTC",
    size: "small",
    content: "toSayThanks",
    btnText: "continue",
    color: "purple",
  },
  {
    img: "welcomeCustomer3",
    title: "checkTCBalance",
    size: "small",
    content: "knowHowMany",
    btnText: "continue",
    color: "pink",
  },
  {
    img: "welcomeCustomer4",
    title: "invitePeople",
    size: "small",
    content: "toEarnTC",
    btnText: "letsGo",
    color: "yellow",
  },
];

export const memberContent = [
  {
    img: "welcomeMember1",
    title: "welcomeBig",
    size: "large",
    content: "nowAMember",
    btnText: "continue",
    color: "blue",
  },
  {
    img: "welcomeMember2",
    title: "findWaysToVolunteer",
    size: "small",
    content: "searchForWaysExplanation",
    btnText: "continue",
    color: "purple",
  },
  {
    img: "welcomeMember3",
    title: "checkBalance",
    size: "small",
    content: "youCanCheckTCExplanation",
    btnText: "continue",
    color: "pink",
  },
  {
    img: "welcomeMember4",
    title: "enjoyFunActivities",
    size: "small",
    content: "searchForFunActivitiesExplanation",
    btnText: "letsGo",
    color: "purple",
  },
];
