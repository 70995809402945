import {
  REQUEST_TC_LOADING_START,
  REQUEST_TC_LOADING_END,
  REQUEST_TC_SUCCESS,
  REQUEST_TC_FAIL,
  CREATE_PAID_ORDER_LOADING_START,
  CREATE_PAID_ORDER_LOADING_END,
  CREATE_PAID_ORDER_SUCCESS,
  CREATE_PAID_ORDER_FAIL,
} from "../../../redux/types";

import { creditsRequests, paidPackages } from "../../../api-calls";
import { EARN_GROUP } from "../../../constants/navRoutes";

export const postCreditsRequest =
  (requestedCredits, history) => async (dispatch) => {
    dispatch({ type: REQUEST_TC_LOADING_START });
    const { data, error } = await creditsRequests.createCreditsRequests({
      requestedCredits,
    });

    dispatch({ type: REQUEST_TC_LOADING_END });

    if (!error) {
      dispatch({
        type: REQUEST_TC_SUCCESS,
        payload: data,
      });
      history.push(EARN_GROUP.REQUEST_SUCCESS);
    } else {
      dispatch({
        type: REQUEST_TC_FAIL,
        payload: error,
      });
    }
  };

export const createPaidPackageOrder =
  ({ customCredits, selectedPackageId, history }) =>
  async (dispatch) => {
    dispatch({ type: CREATE_PAID_ORDER_LOADING_START });
    const { data, error } = await paidPackages.createPaidPackageOrder({
      customCredits,
      selectedPackageId,
    });
    dispatch({ type: CREATE_PAID_ORDER_LOADING_END });

    if (!error) {
      dispatch({
        type: CREATE_PAID_ORDER_SUCCESS,
        payload: data,
      });
      history.push(EARN_GROUP.ORDER_CONFIRMATION.replace(":id", data.id));
    } else {
      dispatch({
        type: CREATE_PAID_ORDER_FAIL,
        payload: error,
      });
    }
  };
