import styled from "@material-ui/core/styles/styled";

export const Container = styled("div")(() => ({
  width: "100%",
}));

export const Head = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

export const TableContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.mobileXL]: {
    transform: "translateY(-130px)",
  },

  [theme.breakpoints.laptopL]: {
    transform: "translateY(0px)",
  },
}));

export const BtnContainer = styled("div")(({ theme }) => ({
  zIndex: 3,
  display: "flex",
  transform: "translateY(200px)",
  alignSelf: "flex-end",
  [theme.breakpoints.mobileXL]: {
    flexDirection: "column",
  },

  [theme.breakpoints.laptopL]: {
    flexDirection: "row",
  },
}));

export const ImageWrapper = styled("div")({
  minWidth: 220,
  height: 210,
  transform: "translate(-10px, -30px)",
});

export const CSVExport = styled("a")({
  textDecoration: "none",
  display: "block",
  marginLeft: "7px",
});

export const ModalContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
});
export const HeadContent = styled("div")({
  display: "flex",
  flexDirection: "column",
});
export const FilterContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
});

export const SearchWrapper = styled("div")({
  justifyItems: "center",
  width: "365px",
  marginTop: "-2px",
  marginBottom: "10px",
});
