import React, { useEffect } from "react";
import { Redirect, useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import Loading from "../../../components/Loading";
import EmailSent from "./EmailSent";
import * as actions from "./actions";
import t from "../../../utils/helpers/translator";
import * as S from "./style";
import { navRoutes } from "../../../constants";
import ResendLink from "./ResendLink";

const ActivateAccountCheckToken = ({
  checkActivateAccountToken,
  loading,
  error,
  language,
}) => {
  const { token } = useParams();
  const history = useHistory();

  useEffect(() => {
    checkActivateAccountToken(token, history);
  }, [checkActivateAccountToken, history, token]);

  if (loading) {
    return <Loading fullPage />;
  }

  if (error) {
    return (
      <S.ValidatePage>
        {t(error.message, language)}
        <ResendLink />
      </S.ValidatePage>
    );
  }

  return <Redirect to={navRoutes.GENERAL.CONTINUE_SIGN_UP} />;
};

export { EmailSent };
const mapStateToProps = (state) => ({
  data: state.activateAccount.data,
  loading: state.activateAccount.loading,
  error: state.activateAccount.error,
  language: state.auth.language,
  emailVerified: state.auth.emailVerified,
});

const mapActionToProps = {
  checkActivateAccountToken: actions.checkActivateAccountToken,
};
export default connect(
  mapStateToProps,
  mapActionToProps,
)(ActivateAccountCheckToken);
