import { styled } from "@material-ui/core/styles";

export const Wrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
}));

export const HeaderWrapper = styled("header")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
}));

export const Section = styled("section")(({ theme, color }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "160px",
  backgroundColor: theme.colors[color] || color,
  border: "1px solid #F9ECF2",
  marginBottom: "2rem",
  marginLeft: "0",

  [theme.breakpoints.mobileXL]: {
    width: "100vw",
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },

  [theme.breakpoints.tablet]: {
    width: "calc(100vw - 320px)",
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },

  [theme.breakpoints.laptop]: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    maxWidth: "calc(1800px - 560px)",
  },
}));

export const Stats = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  marginLeft: 0,
  width: "100%",
}));

export const FilterContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  alignSelf: "stretch",
  flexWrap: "wrap",
});
export const FilterWrapper = styled("div")(
  ({ theme, marginRight, first, minWidth }) => ({
    marginRight: marginRight && theme.spacing(3),
    marginTop: first ? "-8px" : 0,
    minWidth: minWidth || "250px",
  }),
);
