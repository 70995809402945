import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, Link } from "react-router-dom";

import * as S from "./style";
import * as T from "../../../../components/Typograpy";
import { TEMPO } from "../../../../constants/navRoutes";
import Table from "../../../../components/Table";
import Image from "../../../../components/Image";
import Dropdown from "../../../../components/Inputs/Dropdown";

import * as actions from "./actions";

import {
  dateCol,
  creditResponseCol,
  customDateCol,
} from "../../../../components/Table/config/reusableColumns";

import IssueCredits from "./IssueCredits";
import IssueCreditsSuccess from "./IssueCreditsSuccess";

const CreditRequests = ({
  getPendingCreditsRequests,
  getPreviousCreditsRequests,
  selectCreditsRequest,
  pendingRequests,
  pendingRequestsLoaded,
  prevRequests,
  prevRequestsLoaded,
  programme,
  programmeId,

  // TODO error handling
  // prevError,
  // prevLoading,
}) => {
  const history = useHistory();

  const decideRoute = (selected, requestID) => {
    switch (selected.value) {
      case "approve":
        return history.push(
          `${TEMPO.REQUEST_APPROVE.replace(":requestId", requestID)}${
            programme ? "" : `?showProgramme=${true}`
          }`,
        );
      case "partialApprove":
        return history.push(
          `${TEMPO.REQUEST_PARTIAL.replace(":requestId", requestID)}${
            programme ? "" : `?showProgramme=${true}`
          }`,
        );
      case "reject":
        return history.push(
          `${TEMPO.REQUEST_REJECT.replace(":requestId", requestID)}${
            programme ? "" : `?showProgramme=${true}`
          }`,
        );

      default:
        return null;
    }
  };

  const onSelectAction = (selected, selectedRequest) => {
    selectCreditsRequest({
      ...selectedRequest,
      programmeId: programmeId || selectedRequest.programmeId,
    });
    decideRoute(selected, selectedRequest.id);
  };

  const activeRequestCols = [
    {
      title: "Earn Group",
      field: "earnGroup",
      render: (rowData) => (
        <>
          <T.Body14R>{rowData.earnGroup}</T.Body14R>
          {rowData.isTkRequest ? (
            <T.Body12R color="gray3">Team Kinetic Request</T.Body12R>
          ) : null}
        </>
      ),
    },
    {
      title: "Programme",
      field: "programmeName",
      disableClick: true,
      render: (rowData) =>
        rowData.programmeId ? (
          <Link to={TEMPO.PROGRAMME_SINGLE.replace(":id", rowData.programmeId)}>
            <T.LinkB14 color="blue">{rowData.programmeName}</T.LinkB14>
          </Link>
        ) : (
          <T.Body14R>N/A</T.Body14R>
        ),
    },
    { title: "Balance", field: "earnGroupBalance", type: "numeric" },
    {
      title: "Time Credits Requested",
      field: "creditsRequested",
      type: "numeric",
    },
    dateCol(),
    {
      title: "Action",
      field: "action",
      disableClick: true,
      render: (rowData) => (
        <Dropdown
          placeholder="Select..."
          options={[
            { value: "approve", label: "Approve" },
            { value: "partialApprove", label: "Partially Approve" },
            { value: "reject", label: "Reject" },
          ]}
          customSelectFunc={(e) => onSelectAction(e, rowData)}
          size="small"
        />
      ),
    },
  ];

  const oldRequestCols = [
    {
      title: "Earn Group",
      field: "earnGroup",
      render: (rowData) => (
        <>
          <T.Body14R>{rowData.earnGroup}</T.Body14R>
          {rowData.isTkRequest ? (
            <T.Body12R color="gray3">Team Kinetic Request</T.Body12R>
          ) : null}
        </>
      ),
    },
    {
      title: "Time Credits Requested",
      field: "creditsRequested",
      type: "numeric",
    },
    { title: "Time Credits Issued", field: "creditsIssued", type: "numeric" },
    customDateCol({ title: "Request Date", field: "date" }),
    customDateCol(
      { title: "Issue Date", field: "responseDate" },
      "responseDate",
    ),
    creditResponseCol,
    { title: "By", field: "by" },
  ];

  const clickRow = (event, rowData) => {
    history.push(TEMPO.EARN_GROUP_SINGLE.replace(":id", rowData.earnGroupID));
  };

  const cleanRequests = (requests) => {
    const updatedRequests = requests.map((request) => ({
      ...request,
      earnGroupBalance: request.earnGroupBalance || 0,
    }));
    return updatedRequests;
  };

  useEffect(() => {
    getPendingCreditsRequests({ programmeId });
    getPreviousCreditsRequests({ programmeId });
  }, [getPendingCreditsRequests, getPreviousCreditsRequests, programmeId]);

  return (
    <S.Wrapper>
      {!programme && (
        <S.Header>
          <T.H40Caps color="midnight">TIME CREDIT REQUESTS</T.H40Caps>
          <S.ImageWrapper>
            <Image image="office" alt="office" />
          </S.ImageWrapper>
        </S.Header>
      )}
      <T.H24Caps color="midnight" mb={20}>
        {programme ? "Time Credit Requests" : "ACTIVE REQUESTS"}
      </T.H24Caps>

      <Table
        color="blue"
        limit={5}
        data={cleanRequests(pendingRequests)}
        columns={activeRequestCols}
        onRowClick={clickRow}
        isLoading={!pendingRequestsLoaded}
      />
      <T.H24Caps color="gray3" mb={20} mt={80}>
        PREVIOUS REQUESTS
      </T.H24Caps>
      {/* TODO handle loading */}

      <Table
        color="blue"
        limit={5}
        data={prevRequests}
        columns={oldRequestCols}
        onRowClick={clickRow}
        isLoading={!prevRequestsLoaded}
      />
    </S.Wrapper>
  );
};

export { IssueCredits, IssueCreditsSuccess };

const mapStateToProps = (state) => ({
  pendingError: state.staffCreditRequests.pendingError,
  pendingLoading: state.staffCreditRequests.pendingLoading,
  pendingRequests: state.staffCreditRequests.pendingRequests,
  pendingRequestsLoaded: state.staffCreditRequests.pendingRequestsLoaded,

  prevError: state.staffCreditRequests.prevError,
  prevLoading: state.staffCreditRequests.prevLoading,
  prevRequests: state.staffCreditRequests.prevRequests,
  prevRequestsLoaded: state.staffCreditRequests.prevRequestsLoaded,
});
const mapActionToProps = {
  getPendingCreditsRequests: actions.getPendingCreditsRequests,
  getPreviousCreditsRequests: actions.getPreviousCreditsRequests,
  selectCreditsRequest: actions.selectCreditsRequest,
};

export default connect(mapStateToProps, mapActionToProps)(CreditRequests);
