import React, { useState } from "react";
import Modal from "../Modal";
import * as S from "./style";
import * as T from "../Typograpy";
import Button from "../Button";

import FilterByDate from "../FilterByDate";
import DownloadFile from "../DownloadFile";

const ExportWithDatesRange = ({ exportEndpoint }) => {
  const [filterDates, setFilterDates] = useState({
    startDate: null,
    endDate: null,
  });
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Button outline onClick={() => setIsOpen(true)}>
        Export Data
      </Button>
      <Modal
        modalOpen={isOpen}
        handleCloseModal={() => setIsOpen(false)}
        onCancel={() => {
          setIsOpen(false);
        }}
        custom
        width="690px"
      >
        <S.ModalContent>
          <T.H20 color="blue" mb={20}>
            Export data{" "}
          </T.H20>

          <T.Body16B color="midnight" m="8">
            Filter by date
          </T.Body16B>
          <FilterByDate dates={filterDates} setDates={setFilterDates} />
          <DownloadFile
            url={`/csv/${exportEndpoint}?startDate=${filterDates?.startDate?.format(
              "YYYY-MM-DD",
            )}&endDate=${filterDates?.endDate?.format("YYYY-MM-DD")}`}
          />
        </S.ModalContent>
      </Modal>
    </>
  );
};

export default ExportWithDatesRange;
