/* eslint-disable quotes */
import { styled } from "@material-ui/core/styles";
import MUIStep from "@material-ui/core/Step";
import MUIStepButton from "@material-ui/core/StepButton";

export const StepButton = styled(MUIStepButton)(() => ({
  "& text": {
    fontSize: 14,
  },
}));

export const Step = styled(MUIStep)(({ isActive, theme }) => ({
  display: "flex",
  position: "relative",
  "&::after": {
    position: "absolute",
    bottom: -7,
    content: '" "',
    background: theme.colors.blue,
    width: "100%",
    height: 3,
    transform: isActive ? "scaleX(1)" : "scaleX(0)",
    opacity: isActive ? 1 : 0,
    transition: "0.3s",
  },
}));

export const Wrapper = styled("div")(({ edit }) => ({
  width: "100%",
  marginTop: edit ? "0" : "50px",
  "& > div": {
    background: "transparent",
  },
}));
