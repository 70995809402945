import {
  GET_EARN_GROUP_PROFILE_LOADING_START,
  GET_EARN_GROUP_PROFILE_LOADING_END,
  GET_EARN_GROUP_PROFILE_SUCCESS,
  GET_EARN_GROUP_PROFILE_FAIL,
  UPDATE_EARN_GROUP_PROFILE_LOADING_START,
  UPDATE_EARN_GROUP_PROFILE_LOADING_END,
  UPDATE_EARN_GROUP_PROFILE_SUCCESS,
  UPDATE_EARN_GROUP_PROFILE_FAIL,
  RESET_EARN_GROUP_PROFILE,
} from "../types";

import { earnGroups } from "../../api-calls";

export const getEarnGroupProfile = (id) => async (dispatch) => {
  dispatch({ type: GET_EARN_GROUP_PROFILE_LOADING_START });
  try {
    const { data, error } = await earnGroups.getEarnGroupByUserId({
      id,
      profile: true,
    });

    if (data) {
      dispatch({ type: GET_EARN_GROUP_PROFILE_SUCCESS, payload: data });
    } else {
      dispatch({ type: GET_EARN_GROUP_PROFILE_FAIL, payload: error });
    }
  } catch (error) {
    dispatch({ type: GET_EARN_GROUP_PROFILE_FAIL, payload: error });
  } finally {
    dispatch({ type: GET_EARN_GROUP_PROFILE_LOADING_END });
  }
};

export const updateEarnGroupProfile = (id, updateDate) => async (dispatch) => {
  dispatch({ type: UPDATE_EARN_GROUP_PROFILE_LOADING_START });
  try {
    const { data, error } = await earnGroups.updateEarnGroupProfile({
      id,
      updateDate,
    });

    if (data) {
      dispatch({ type: UPDATE_EARN_GROUP_PROFILE_SUCCESS, payload: data });
    } else {
      dispatch({ type: UPDATE_EARN_GROUP_PROFILE_FAIL, payload: error });
    }
  } catch (error) {
    dispatch({ type: UPDATE_EARN_GROUP_PROFILE_FAIL, payload: error });
  } finally {
    dispatch({ type: UPDATE_EARN_GROUP_PROFILE_LOADING_END });
  }
};

export const resetUpdateEarnGroupProfile = () => ({
  type: RESET_EARN_GROUP_PROFILE,
});
