import {
  GET_SPEND_VENUES_SUCCESS,
  GET_SPEND_VENUES_FAIL,
  GET_SPEND_VENUES_LOADING_START,
  GET_SPEND_VENUES_LOADING_END,
} from "../../../redux/types";

import { spendVenues } from "../../../api-calls";

export const getSpendVenuesPublic = () => async (dispatch) => {
  dispatch({ type: GET_SPEND_VENUES_LOADING_START });
  try {
    const { data, error } = await spendVenues.getSpendVenues({
      isPublic: true,
    });

    if (data) {
      dispatch({
        type: GET_SPEND_VENUES_SUCCESS,
        payload: data,
      });
    } else if (error) {
      dispatch({
        type: GET_SPEND_VENUES_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_SPEND_VENUES_FAIL,
      payload: error,
    });
  }
  dispatch({ type: GET_SPEND_VENUES_LOADING_END });
};
