import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, Link } from "react-router-dom";

import * as S from "./style";
import * as T from "../../../../components/Typograpy";
import { GENERAL } from "../../../../constants/navRoutes";
import Table from "../../../../components/Table";
import Image from "../../../../components/Image";

import * as actions from "./actions";

import {
  dateCol,
  customDateCol,
  buttonAction,
} from "../../../../components/Table/config/reusableColumns";

const CreditRequests = ({
  activeRequests,
  activeError,
  activeLoading,

  prevError,
  prevLoading,
  prevRequests,
  getActiveSpendActivitiesApprovalRequests,
  getPrevSpendActivitiesApprovalRequests,
}) => {
  const history = useHistory();

  const clickRow = (event, rowData) => {
    history.push(
      GENERAL.SPEND_ACTIVITY.replace(":id", rowData.spendActivityId),
    );
  };

  const activeRequestCols = [
    { title: "Activity Name", field: "activity.name" },
    {
      title: "ORGANIZATION",
      field: "organisation",
      disableClick: true,
      render: (rowData) => {
        if (rowData.earnGroupUserId) {
          return (
            <Link
              to={GENERAL.EARN_GROUP.replace(":id", rowData.earnGroupUserId)}
            >
              <T.LinkB14 color="blue">{rowData.spendVenue.name}</T.LinkB14>
            </Link>
          );
        }
        if (rowData.spendVenueUserId) {
          return (
            <Link
              to={GENERAL.SPEND_VENUE.replace(":id", rowData.spendVenueUserId)}
            >
              <T.LinkB14 color="blue">{rowData.spendVenue.name}</T.LinkB14>
            </Link>
          );
        }
        return <T.Body14R>N/A</T.Body14R>;
      },
    },

    dateCol(),

    buttonAction({
      text: "View",
      onClick: (rowData) => clickRow(null, rowData),
      w: "auto",
      h: "auto",
      p: "5px 10px",
    }),
  ];

  const oldRequestCols = [
    { title: "Activity Name", field: "activity.name" },
    {
      title: "ORGANIZATION",
      field: "organisation",
      disableClick: true,
      render: (rowData) => {
        if (rowData.earnGroupUserId) {
          return (
            <Link
              to={GENERAL.EARN_GROUP.replace(":id", rowData.earnGroupUserId)}
            >
              <T.LinkB14 color="blue">{rowData.spendVenue.name}</T.LinkB14>
            </Link>
          );
        }
        if (rowData.spendVenueUserId) {
          return (
            <Link
              to={GENERAL.SPEND_VENUE.replace(":id", rowData.spendVenueUserId)}
            >
              <T.LinkB14 color="blue">{rowData.spendVenue.name}</T.LinkB14>
            </Link>
          );
        }
        return <T.Body14R>N/A</T.Body14R>;
      },
    },
    customDateCol({ title: "Request Date", field: "createdAt" }),
    customDateCol({ title: "Approval Date", field: "respondedAt" }),
    { title: "By", field: "approvedBy" },
  ];

  useEffect(() => {
    getActiveSpendActivitiesApprovalRequests();
    getPrevSpendActivitiesApprovalRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Wrapper>
      <S.Header>
        <T.H40Caps color="midnight">RECOGNITION LISTING REQUESTS</T.H40Caps>
        <S.ImageWrapper>
          <Image image="office" alt="office" />
        </S.ImageWrapper>
      </S.Header>
      <T.H24Caps color="midnight" mb={20}>
        ACTIVE REQUESTS
      </T.H24Caps>
      {activeError?.message && (
        <T.Body16B color="pink">{activeError?.message}</T.Body16B>
      )}
      <Table
        color="blue"
        limit={5}
        data={activeRequests}
        columns={activeRequestCols}
        onRowClick={clickRow}
        isLoading={activeLoading}
      />

      <T.H24Caps color="gray3" mb={20} mt={80}>
        PREVIOUS REQUESTS
      </T.H24Caps>
      {prevError?.message && (
        <T.Body16B color="pink">{prevError?.message}</T.Body16B>
      )}
      <Table
        color="blue"
        limit={5}
        data={prevRequests}
        columns={oldRequestCols}
        onRowClick={clickRow}
        isLoading={prevLoading}
      />
    </S.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  activeRequests: state.RecognitionListingRequests.activeRequests,
  activeError: state.RecognitionListingRequests.activeError,
  activeLoading: state.RecognitionListingRequests.activeLoading,
  activeLoaded: state.RecognitionListingRequests.activeLoaded,
  prevError: state.RecognitionListingRequests.prevError,
  prevLoading: state.RecognitionListingRequests.prevLoading,
  prevRequests: state.RecognitionListingRequests.prevRequests,
});
const mapActionToProps = {
  getActiveSpendActivitiesApprovalRequests:
    actions.getActiveSpendActivitiesApprovalRequests,
  getPrevSpendActivitiesApprovalRequests:
    actions.getPrevSpendActivitiesApprovalRequests,
};

export default connect(mapStateToProps, mapActionToProps)(CreditRequests);
