import React from "react";
import { connect } from "react-redux";
import { styled } from "@material-ui/core/styles";
import * as T from "../Typograpy";

import { Row, Col } from "../Grid";
import { InputField } from "../Inputs";
import RemoveRejoin from "../RemoveRejoin";
import t from "../../utils/helpers/translator";

const FiledWrapper = styled("div")(() => ({
  marginTop: 30,
}));

const AddActivity = styled("button")(() => ({
  outline: "none",
  border: "none",
  background: "none",
  cursor: "pointer",
}));

const ActivityFields = ({
  index,
  element,
  activitiesRoles,
  setActivitiesRoles,
  welshVersion,
  errors = {},
  isMobile,
  lang,
}) => {
  const changeBigState = (name, value) => {
    const previousArray = [...activitiesRoles];
    const fieldName = name;
    if (previousArray.length - 1 >= index) {
      previousArray[index][fieldName] = value;
      setActivitiesRoles(previousArray);
      return previousArray;
    }
    setActivitiesRoles([...previousArray, { id: index, fieldName: value }]);
  };

  const onChange = async ({ target: { name, value } }) => {
    await changeBigState(name, value);
  };

  return (
    <>
      <Row ai="flex-start">
        <Col w={[4, 4, 4]}>
          <FiledWrapper>
            <InputField
              placeholder={`${t("typeActivityName", lang)}...`}
              label={`${t("activityName", lang)}${
                lang === "welsh" ? ` (${t("english", lang)})` : ""
              }`}
              name="name"
              value={element.name}
              setValue={onChange}
              error={errors.name}
            />
          </FiledWrapper>
        </Col>
        <Col w={[4, 4, 4]}>
          <FiledWrapper>
            <InputField
              type="textarea"
              placeholder={`${t("addDatesTimesSkills", lang)}...`}
              label={`${t("details", lang)}${
                lang === "welsh" ? ` (${t("english", lang)})` : ""
              }`}
              name="details"
              value={element.details}
              setValue={onChange}
              isOptional
              lang={lang}
              error={errors.details}
            />
          </FiledWrapper>
        </Col>
        <Col w={[4, 4, 4]}>
          <RemoveRejoin
            style={{ alignSelf: "center" }}
            handleClick={() => {
              setActivitiesRoles(
                activitiesRoles
                  .filter((e) => e.id !== index)
                  .map((elm, i) => ({ ...elm, id: i })),
              );
            }}
            type="Remove"
            showText={isMobile}
          />
        </Col>
      </Row>
      {welshVersion && (
        <Row>
          <Col w={[4, 4, 4]}>
            <FiledWrapper>
              <InputField
                placeholder={`${t("typeActivityName", lang)}...`}
                label={`${t("activityName", lang)}${
                  lang === "english" ? ` (${t("welsh", lang)})` : ""
                }`}
                name="nameWelsh"
                value={element.nameWelsh}
                setValue={onChange}
                error={errors.nameWelsh}
                labelColor="blue"
              />
            </FiledWrapper>
          </Col>
          <Col w={[4, 4, 4]}>
            <FiledWrapper>
              <InputField
                type="textarea"
                placeholder={t("addDatesTimesSkills", lang)}
                label={`${t("details", lang)}${
                  lang === "english" ? ` (${t("welsh", lang)})` : ""
                }`}
                name="detailsWelsh"
                value={element.detailsWelsh}
                setValue={onChange}
                isOptional
                lang={lang}
                error={errors.detailsWelsh}
                labelColor="blue"
              />
            </FiledWrapper>
          </Col>
        </Row>
      )}
    </>
  );
};

const SpecificActivities = ({
  activitiesRoles = [],
  setActivitiesRoles,
  errors = {},
  welshVersion,
  isMobile,
  lang,
}) => {
  const id = activitiesRoles.length;
  const handleClick = () => {
    setActivitiesRoles([
      ...activitiesRoles,
      {
        id,
        name: "",
        details: "",
        nameWelsh: "",
        detailsWelsh: "",
      },
    ]);
  };

  return (
    <>
      {activitiesRoles.map((e, i) => {
        const index = e.id ? e.id : 0;
        return (
          <ActivityFields
            key={index}
            element={e}
            index={index}
            activitiesRoles={activitiesRoles}
            setActivitiesRoles={setActivitiesRoles}
            errors={errors[`activitiesRoles[${i}]`]}
            welshVersion={welshVersion}
            isMobile={isMobile}
            lang={lang}
          />
        );
      })}
      <Row ai="flex-end">
        {id > 0 ? (
          <Col w={[4, 12, 12]}>
            <AddActivity onClick={handleClick}>
              <T.LinkB14 color="blue" mt="30" mb="30">
                + {t("addAnotherActivity", lang)}
              </T.LinkB14>
            </AddActivity>
          </Col>
        ) : (
          <Col w={[4, 12, 12]}>
            <AddActivity onClick={handleClick}>
              <T.LinkB14 color="blue" mt="20">
                + {t("addAnActivity", lang)}
              </T.LinkB14>
            </AddActivity>
          </Col>
        )}
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  lang: state.auth.language,
});

export default connect(mapStateToProps, null)(SpecificActivities);
