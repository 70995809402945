import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col } from "../../../../components/Grid";
import { InputField } from "../../../../components/Inputs";
import Button from "../../../../components/Button";
import * as T from "../../../../components/Typograpy";
import { connect } from "react-redux";
import validate from "../../../../validation/schemas/admin-edit-member-account";
import Notification from "../../../../components/Notification";
import { roles } from "./../../../../constants";
import * as S from "./style";
import t from "../../../../utils/helpers/translator";
import Modal from "../../../../components/Modal";
import {
  getUserAccount,
  updateUserAccount,
} from "./../../../../redux/actions/userAccount";

const AdminEditMemberAccount = ({
  getUserAccount,
  updateUserAccountAction,
  error,
  isUpdating,
  data,
  getAccountLoading,
}) => {
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [errors, setErrors] = useState({});
  const [showMsg, setShowMsg] = useState(false);
  const [showConfirmPasswordModal, setShowConfirmPasswordModal] = useState();

  const { id } = useParams();

  const onChange = (e, _setState, _state) => {
    const { name, value } = e.target;
    const cleanEmail = name === "email" && value.toLowerCase().trim();

    setErrors((_errors) => ({ ..._errors, [name]: "" }));

    switch (name) {
      case "email":
        _setState({ ..._state, email: cleanEmail });
        break;
      default:
        _setState({ ..._state, [name]: value });
        break;
    }
  };

  const handleClick = async (e, confirmed) => {
    e.preventDefault();
    try {
      validate({ ...state, isOffline: data?.isOffline });
      setErrors({});
      await updateUserAccountAction(
        {
          ...state,
          staffSwitchAccountConfirmation: confirmed,
          role: roles.MEMBER,
        },
        id,
        roles.MEMBER,
      );

      if (showConfirmPasswordModal) {
        setShowConfirmPasswordModal(false);
      }
      getUserAccount(id, roles.MEMBER);

      if (!isUpdating && !error) {
        setShowMsg(true);
      }

      return true;
    } catch (err) {
      if (err?.name === "ValidationError") {
        setErrors(err.inner);
      }
    }
  };

  const showNotification = (message) => (
    <Notification
      open={showMsg}
      setOpen={setShowMsg}
      message={message}
      duration={2500}
    />
  );

  useEffect(() => {
    getUserAccount(id, roles.MEMBER);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set state with user data
  useEffect(() => {
    if (data && !getAccountLoading) {
      setState(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccountLoading]);

  useEffect(() => {
    if (error?.message === "multipleAccountConfirmationRequired") {
      setShowConfirmPasswordModal(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error?.message]);

  return (
    <S.AccountWrapper>
      <Row>
        <Col w={[4, 12, 6]}>
          <T.H40 color="midnight">{data?.firstName}'s Account</T.H40>
          {data?.isOffline && (
            <T.Body16R color="midnight" mt="8">
              This volunteer is <strong>non-digital</strong>, and by filling in
              their email address you are creating a digital account for them.
              They will receive an email to inform them and set a password.
            </T.Body16R>
          )}
          <T.H22
            color="midnight"
            style={{
              marginTop: "2.5rem",
              fontWeight: "900",
            }}
          >
            Account Details
          </T.H22>
        </Col>
      </Row>

      {/* Fields */}
      <Row>
        <Col w={[4, 6, 4]}>
          <InputField
            mt={6}
            label={"First Name"}
            type={"text"}
            placeholder={"First Name..."}
            name={"firstName"}
            setValue={(e) => onChange(e, setState, state)}
            value={state.firstName || ""}
            error={errors.firstName}
          />
        </Col>
        <Col w={[4, 6, 4]}>
          <InputField
            mt={6}
            label={"Last Name"}
            type={"text"}
            placeholder={"Last Name..."}
            name={"lastName"}
            setValue={(e) => onChange(e, setState, state)}
            value={state.lastName || ""}
            error={errors.lastName}
          />
        </Col>
      </Row>
      <Row>
        <Col w={[4, 6, 4]}>
          <InputField
            mt={6}
            label={"Email"}
            type={"email"}
            placeholder={`Email...`}
            name={"email"}
            setValue={(e) => onChange(e, setState, state)}
            value={state.email || ""}
            error={
              errors.email ||
              (error?.data?.field === "email" && t(error.message, "english"))
            }
          />
        </Col>
      </Row>
      <Row>
        <Col
          style={{
            marginTop: "60px",
          }}
          w={[4, 6, 4]}
        >
          <Button
            primary
            size="l"
            type="submit"
            handleClick={handleClick}
            loading={isUpdating}
          >
            Save
          </Button>
          {showNotification("Account updated successfully")}
        </Col>
      </Row>
      <Modal
        modalOpen={showConfirmPasswordModal}
        handleCloseModal={() => setShowConfirmPasswordModal(false)}
        confirmFun={(e) => handleClick(e, true)}
        buttonText="Confirm"
        withCancel
        width="640px"
        keepOpenOnConfirm
        loading={getAccountLoading}
      >
        <T.H30 color="blue" m="0" mb="25">
          {t("areYouSure", "english")}
        </T.H30>

        <T.Body16B color="midnight">
          {t("thisEmailIsLinked", "english", [error?.data?.role])}
        </T.Body16B>
        <T.Body16R mt="20">
          Click confirm if you want to switch this account to{" "}
          <T.Body16B>{state.email}</T.Body16B>
        </T.Body16R>
        <T.Body16B mt="20">
          Note:
          <T.Body16R>
            This current account holder&rsquo;s name will be updated to the new
            account {`(${state.email})`}
          </T.Body16R>
        </T.Body16B>
      </Modal>
    </S.AccountWrapper>
  );
};

const mapStateToProps = (state) => ({
  role: state.auth.role,
  error: state.userAccount.error,
  isUpdating: state.userAccount.updateAccountLoading,
  getAccountLoading: state.userAccount.getAccountLoading,
  language: state.auth.language,
  data: state.userAccount.data,
});

const mapActionToProps = {
  getUserAccount: getUserAccount,
  updateUserAccountAction: updateUserAccount,
};

export default connect(
  mapStateToProps,
  mapActionToProps,
)(AdminEditMemberAccount);
