import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/browser";
import "url-search-params-polyfill";

import ReactGA from "react-ga4";

import * as LogRocket from "./utils/helpers/LogRocket";
import App from "./App";

import store from "./redux/store";
import posthog from "posthog-js";

import ErrorBoundary from "./ErrorBoundary";

if (process.env.NODE_ENV === "production") {
  // sentry
  Sentry.init({
    ignoreErrors: ["Non-Error promise rejection captured"],
    dsn: process.env.REACT_APP_SENTRY_DSN,
    beforeSend(event, hint) {
      if (
        hint.originalException === "Timeout" ||
        hint.originalException === null
      )
        return null;
      return event;
    },
  });
  // logrocket
  LogRocket.init();
  // Google Analytics
  ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
  });
  // posthog
  posthog.init(process.env.REACT_APP_POSTHOG_API_KEY, {
    api_host: "https://eu.i.posthog.com",
    person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
  });
}

ReactDOM.render(
  process.env.NODE_ENV === "production" ? (
    <ErrorBoundary>
      <React.StrictMode>
        <Provider store={store}>
          <App />
        </Provider>
      </React.StrictMode>
    </ErrorBoundary>
  ) : (
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  ),
  document.getElementById("root"),
);
