import React, { useEffect } from "react";
import ReactGA from "react-ga4";

import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import * as S from "./style";
import * as T from "../../../components/Typograpy";
import Image from "../../../components/Image";
import Button from "../../../components/Button";

import { EARN_GROUP } from "../../../constants/navRoutes";

import t from "../../../utils/helpers/translator";

const Success = ({ balance, isTablet, language }) => {
  const history = useHistory();
  const translateText = t("youHaveThankedYourMembers", language, [
    balance,
  ]).split(balance);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.event({
        category: "Earn Group",
        action: "Completed giving out TCs",
      });
    }
  }, []);
  return (
    <S.SuccessWrapper>
      <Image image="success" />
      <S.SuccessHeader>
        <T.H40Caps color="midnight" mb="20">
          {t("woohoo", language)}!
        </T.H40Caps>
      </S.SuccessHeader>
      <T.Body16R style={{ marginBottom: "40px", textAlign: "center" }}>
        {[
          translateText[0],
          <S.Emphasis>{balance}</S.Emphasis>,
          translateText[1],
        ]}
      </T.Body16R>
      <S.ButtonWrapper>
        <Button
          primary
          onClick={() => history.push(EARN_GROUP.DASHBOARD)}
          mb={10}
          size="l"
          style={{ maxWidth: isTablet ? "100%" : "49%" }}
        >
          {t("viewDashboard", language)}
        </Button>
        <Button
          primary
          onClick={() => history.push(EARN_GROUP.GIVE)}
          mb={10}
          size="l"
          style={{ maxWidth: isTablet ? "100%" : "49%" }}
        >
          {t("giveOutMore", language)}
        </Button>
      </S.ButtonWrapper>
    </S.SuccessWrapper>
  );
};

const mapStateToProps = (state) => ({
  language: state.auth.language,
});

export default connect(mapStateToProps)(Success);
