const keys = {
  WHITE: "White",
  ENGLISH_WELSH_SCOTTISH_NORTHERN_IRISH_BRITISH:
    "English / Welsh / Scottish / Northern Irish / British",
  IRISH: "Irish",
  GYPSY_OR_IRISH_TRAVELLER: "Gypsy or Irish Traveller",
  ANY_OTHER_WHITE_BACKGROUND: "Any other White background",
  MIXED_MULTIPLE_ETHNIC_GROUPS: "Mixed / Multiple ethnic groups",
  WHITE_AND_BLACK_CARIBBEAN: "White and Black Caribbean",
  WHITE_AND_BLACK_AFRICAN: "White and Black African",
  WHITE_AND_ASIAN: "White and Asian",
  ANY_OTHER_MIXED_MULTIPLE_ETHNIC_BACKGROUND:
    "Any other Mixed / Multiple ethnic background",
  ASIAN_ASIAN_BRITISH: "Asian / Asian British",
  INDIAN: "Indian",
  PAKISTANI: "Pakistani",
  BANGLADESHI: "Bangladeshi",
  CHINESE: "Chinese",
  ANY_OTHER_ASIAN_BACKGROUND: "Any other Asian background",
  BLACK_AFRICAN_CARIBBEAN_BLACK_BRITISH:
    "Black / African / Caribbean / Black British",
  AFRICAN: "African",
  CARIBBEAN: "Caribbean",
  ANY_OTHER_BLACK_AFRICAN_CARIBBEAN_BACKGROUND:
    "Any other Black / African / Caribbean background",
  OTHER_ETHNIC_GROUP: "Other ethnic group",
  ARAB: "Arab",
  ANY_OTHER_ETHNIC_GROUP: "Any other ethnic group",
  PREFER_NOT_TO_SAY: "Prefer not to say",
};

const ethnicList = [
  {
    label: keys.WHITE,
    options: [
      {
        label: keys.ENGLISH_WELSH_SCOTTISH_NORTHERN_IRISH_BRITISH,
        value: keys.ENGLISH_WELSH_SCOTTISH_NORTHERN_IRISH_BRITISH,
      },
      {
        label: keys.IRISH,
        value: keys.IRISH,
      },
      {
        label: keys.GYPSY_OR_IRISH_TRAVELLER,
        value: keys.GYPSY_OR_IRISH_TRAVELLER,
      },
      {
        label: keys.ANY_OTHER_WHITE_BACKGROUND,
        value: keys.ANY_OTHER_WHITE_BACKGROUND,
      },
    ],
  },
  {
    label: keys.MIXED_MULTIPLE_ETHNIC_GROUPS,
    options: [
      {
        label: keys.WHITE_AND_BLACK_CARIBBEAN,
        value: keys.WHITE_AND_BLACK_CARIBBEAN,
      },
      {
        label: keys.WHITE_AND_BLACK_AFRICAN,
        value: keys.WHITE_AND_BLACK_AFRICAN,
      },
      {
        label: keys.WHITE_AND_ASIAN,
        value: keys.WHITE_AND_ASIAN,
      },
      {
        label: keys.ANY_OTHER_MIXED_MULTIPLE_ETHNIC_BACKGROUND,
        value: keys.ANY_OTHER_MIXED_MULTIPLE_ETHNIC_BACKGROUND,
      },
    ],
  },
  {
    label: keys.ASIAN_ASIAN_BRITISH,
    options: [
      {
        label: keys.INDIAN,
        value: keys.INDIAN,
      },
      {
        label: keys.PAKISTANI,
        value: keys.PAKISTANI,
      },
      {
        label: keys.BANGLADESHI,
        value: keys.BANGLADESHI,
      },
      {
        label: keys.CHINESE,
        value: keys.CHINESE,
      },
      {
        label: keys.ANY_OTHER_ASIAN_BACKGROUND,
        value: keys.ANY_OTHER_ASIAN_BACKGROUND,
      },
    ],
  },
  {
    label: keys.BLACK_AFRICAN_CARIBBEAN_BLACK_BRITISH,
    options: [
      {
        label: keys.AFRICAN,
        value: keys.AFRICAN,
      },
      {
        label: keys.CARIBBEAN,
        value: keys.CARIBBEAN,
      },
      {
        label: keys.ANY_OTHER_BLACK_AFRICAN_CARIBBEAN_BACKGROUND,
        value: keys.ANY_OTHER_BLACK_AFRICAN_CARIBBEAN_BACKGROUND,
      },
    ],
  },
  {
    label: keys.OTHER_ETHNIC_GROUP,
    options: [
      {
        label: keys.ARAB,
        value: keys.ARAB,
      },
      {
        label: keys.ANY_OTHER_ETHNIC_GROUP,
        value: keys.ANY_OTHER_ETHNIC_GROUP,
      },
      {
        label: keys.PREFER_NOT_TO_SAY,
        value: keys.PREFER_NOT_TO_SAY,
      },
    ],
  },
];

export { keys };

export default ethnicList;
