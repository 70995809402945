import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactGA from "react-ga4";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import * as S from "../style";
import GoBack from "../../../../../../components/GoBack";
import { Row, Col } from "../../../../../../components/Grid";
import { Body16R } from "../../../../../../components/Typograpy";

import { LANGUAGE_ERROR } from "../../../../../../validation/err-msgs";
import t from "../../../../../../utils/helpers/translator";

import Content from "./Content";

const CallEmail = ({
  memberID,
  back,
  spendVenue,
  bookExtraDetails,
  bookExtraDetailsWelsh,
  isTablet,
  welshVersion,
  language,
}) => {
  const [pageState, setPageState] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (welshVersion && language === "welsh") {
      setPageState({ bookDetails: bookExtraDetailsWelsh, languageError: null });
    } else if (!welshVersion && language === "welsh") {
      setPageState({
        bookDetails: bookExtraDetails,
        languageError: t(LANGUAGE_ERROR, language),
      });
    } else {
      setPageState({ bookDetails: bookExtraDetails, languageError: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.event({
        category: "Activity",
        action: "Member clicks to view Call/Email details",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.OptionWrapper>
      <Row mb={isMobile ? 3 : 6}>
        <Col w={[2, 6, 8]}>
          <GoBack
            showText
            onClick={back}
            lang={welshVersion && language === "welsh" && "welsh"}
          />
        </Col>
      </Row>
      {pageState && pageState.languageError && (
        <Body16R color="pink" style={{ marginBottom: 40 }}>
          {pageState.languageError}
        </Body16R>
      )}
      <Content
        lang={welshVersion ? language : "english"}
        isTablet={isTablet}
        memberID={memberID}
        bookExtraDetails={pageState && pageState.bookDetails}
        spendVenue={spendVenue}
        authorized={!!memberID}
      />
    </S.OptionWrapper>
  );
};

const mapStateToProps = (state) => ({
  memberID: state.auth.profile.membershipId,
});

export default connect(mapStateToProps, null)(CallEmail);
