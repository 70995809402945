import React from "react";

import * as S from "./style";
import * as T from "../../../components/Typograpy";

import Icon from "../../../components/Icon";

import Table from "../../../components/Table";
import TimeCredit from "../../../components/TimeCredit";
import { Tag } from "../../../components/Tags";

import stringCutter from "../../../utils/helpers/stringCutter";
import t from "../../../utils/helpers/translator";

const renderCancelStatus = (rowData) => {
  if (rowData.loading) return "cancelling...";
  if (rowData.error && rowData.error.message) return "error";
  return "CANCEL";
};

const GiftTable = ({
  gifts,
  handleCancel,
  preview,
  previewPath,
  isLoading,
  language,
}) => {
  const cleanData = (giftData) => {
    const giftArr = giftData.map((gift) => {
      const updatedGift = {
        ...gift,
        recipient: stringCutter(gift.recipient, 16),
      };
      return updatedGift;
    });
    return giftArr;
  };

  const columns = [
    {
      title: "",
      field: "credits",
      type: "numeric",
      render: (rowData) => (
        <TimeCredit
          credits={rowData.credits}
          color="blue"
          gradient
          size="small"
        />
      ),
    },
    {
      title: "",
      field: "name",
      render: (rowData) => (
        <T.Body14B color="midnight">{rowData.recipient}</T.Body14B>
      ),
    },
    {
      title: "",
      field: "name",
      render: (rowData) => (
        <>
          <Tag
            tagName={language ? t(rowData.status, language) : rowData.status}
          />
          {rowData.status === "pending" && (
            <S.StyledButton
              type="button"
              id={rowData.id}
              onClick={() => handleCancel(rowData.id)}
            >
              <Icon icon="close" width="20px" height="20px" color="pink" />{" "}
              {renderCancelStatus(rowData)}
            </S.StyledButton>
          )}
        </>
      ),
    },
  ];

  return (
    <Table
      memberMobile
      hideSearch
      data={cleanData(gifts)}
      columns={columns}
      preview={preview}
      previewPath={previewPath}
      isLoading={isLoading}
    />
  );
};

export default GiftTable;
