// Convert Degress to Radians
const deg2Rad = (deg) => (deg * Math.PI) / 180;

const getDistance = (point1, point2) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2Rad(point2.lat - point1.lat);
  const dLon = deg2Rad(point2.long - point1.long);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2Rad(point1.lat)) *
      Math.cos(deg2Rad(point2.lat)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  const miles = d * 0.6214;
  return miles;
};

export default getDistance;
