import {
  GET_SPEND_ACTIVITY_BY_ID_LOADING_START,
  GET_SPEND_ACTIVITY_BY_ID_LOADING_END,
  GET_SPEND_ACTIVITY_BY_ID_SUCCESS,
  GET_SPEND_ACTIVITY_BY_ID_FAIL,
  PURCHASE_BOOKING_CODES_LOADING,
  PURCHASE_BOOKING_CODES_SUCCESS,
  PURCHASE_BOOKING_CODES_FAIL,
  RESET_PURCHASE_BOOKING_CODES,
  GET_USER_BOOKING_CODES_LOADING,
  GET_USER_BOOKING_CODES_SUCCESS,
  GET_USER_BOOKING_CODES_FAIL,
  APPROVE_ACTIVITY_APPROVAL_REQUEST_LOADING,
  APPROVE_ACTIVITY_APPROVAL_REQUEST_SUCCESS,
  APPROVE_ACTIVITY_APPROVAL_REQUEST_FAIL,
} from "../../../../redux/types";

import { activities } from "../../../../api-calls";

export const getSpendActivityById =
  (id, type, { isPublic }) =>
  async (dispatch) => {
    dispatch({ type: GET_SPEND_ACTIVITY_BY_ID_LOADING_START });

    try {
      const { data, error } = await activities.getActivityById({
        id,
        type,
        isPublic,
        options: { redirectToRelatedPage: true },
      });

      if (data) {
        dispatch({
          type: GET_SPEND_ACTIVITY_BY_ID_SUCCESS,
          payload: data,
        });
      } else if (error) {
        dispatch({
          type: GET_SPEND_ACTIVITY_BY_ID_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_SPEND_ACTIVITY_BY_ID_FAIL,
        payload: error,
      });
    } finally {
      dispatch({ type: GET_SPEND_ACTIVITY_BY_ID_LOADING_END });
    }
  };

export const purchaseBookingCodes =
  ({ activityId, purchaseDetails }) =>
  async (dispatch) => {
    dispatch({ type: PURCHASE_BOOKING_CODES_LOADING });

    try {
      const { data, error } = await activities.purchaseBookingCodes({
        activityId,
        purchaseDetails,
      });

      if (data) {
        dispatch({
          type: PURCHASE_BOOKING_CODES_SUCCESS,
          payload: data,
        });
      } else if (error) {
        dispatch({
          type: PURCHASE_BOOKING_CODES_FAIL,
          payload: error,
        });
      }
      return { data, error };
    } catch (error) {
      dispatch({
        type: PURCHASE_BOOKING_CODES_FAIL,
        payload: error,
      });
    }
  };

export const resetState = () => ({ type: RESET_PURCHASE_BOOKING_CODES });

export const getActivityCodesUsedByUser =
  ({ activityId }) =>
  async (dispatch) => {
    dispatch({ type: GET_USER_BOOKING_CODES_LOADING });

    try {
      const { data, error } = await activities.getActivityCodesUsedByUser({
        activityId,
      });

      if (data) {
        dispatch({
          type: GET_USER_BOOKING_CODES_SUCCESS,
          payload: data,
        });
      } else if (error) {
        dispatch({
          type: GET_USER_BOOKING_CODES_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_USER_BOOKING_CODES_FAIL,
        payload: error,
      });
    }
  };

export const approveApprovalRequest = (spendActivityId) => async (dispatch) => {
  dispatch({ type: APPROVE_ACTIVITY_APPROVAL_REQUEST_LOADING, payload: true });

  try {
    const { data, error } = await activities.updateApprovalRequest({
      spendActivityId,
      status: "approve",
    });

    if (error) {
      dispatch({
        type: APPROVE_ACTIVITY_APPROVAL_REQUEST_FAIL,
        payload: error,
      });
    } else {
      dispatch({
        type: APPROVE_ACTIVITY_APPROVAL_REQUEST_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: APPROVE_ACTIVITY_APPROVAL_REQUEST_FAIL,
      payload: error,
    });
  } finally {
    dispatch({
      type: APPROVE_ACTIVITY_APPROVAL_REQUEST_LOADING,
      payload: false,
    });
  }
};
