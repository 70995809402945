import {
  GET_GIFTS_SUCCESS,
  GET_GIFTS_FAIL,
  GET_GIFTS_LOADING_START,
  GET_GIFTS_LOADING_END,
  CREATE_GIFT_SUCCESS,
  CREATE_GIFT_FAIL,
  CREATE_GIFT_LOADING_START,
  CREATE_GIFT_LOADING_END,
  GET_EARN_GROUPS_SUCCESS,
  GET_EARN_GROUPS_FAIL,
  GET_EARN_GROUPS_LOADING_START,
  GET_EARN_GROUPS_LOADING_END,
  SET_GIFT_RECIPIENT,
  CANCEL_GIFT_SUCCESS,
  CANCEL_GIFT_FAIL,
  CANCEL_GIFT_LOADING_START,
  CANCEL_GIFT_LOADING_END,
  GET_GIFT_SUCCESS,
  GET_GIFT_FAIL,
  GET_GIFT_LOADING_START,
  GET_GIFT_LOADING_END,
  RESET_GIFT_ERRORS,
} from "../../../redux/types";

import { gifts, earnGroups } from "../../../api-calls";
import { getUserCurrentTc } from "../../../redux/actions/userActions";

import { MEMBER } from "../../../constants/navRoutes";

export const getGifts = () => async (dispatch) => {
  dispatch({ type: GET_GIFTS_LOADING_START });
  const { data, error } = await gifts.getGifts();

  if (!error) {
    const cleanData = data.map((gift) => {
      let recipient;
      let { status } = gift;

      if (status === "transferred") {
        status = "accepted";
      }

      if (gift.type === "friends" && gift.status === "transferred") {
        recipient = `${gift.toUser.firstName} ${gift.toUser.lastName}`;
      } else if (gift.type === "friends") {
        recipient = gift.toUser.email;
      } else if (gift.type === "earnGroup") {
        recipient = gift.toUser.name;
      }
      return { credits: gift.tc, recipient, status, id: gift.id };
    });

    dispatch({
      type: GET_GIFTS_SUCCESS,
      payload: cleanData,
    });
  } else {
    dispatch({
      type: GET_GIFTS_FAIL,
      payload: error,
    });
  }

  dispatch({ type: GET_GIFTS_LOADING_END });
};

export const createGift =
  ({ email, tc, type, toUserId, earnGroupName, history }) =>
  async (dispatch) => {
    dispatch({ type: CREATE_GIFT_LOADING_START });
    const { data, error } = await gifts.createGift({
      email,
      tc,
      type,
      toUserId,
    });

    if (!error) {
      dispatch({
        type: CREATE_GIFT_SUCCESS,
        payload: data,
      });
      if (type === "earnGroup") {
        dispatch({
          type: SET_GIFT_RECIPIENT,
          payload: { name: earnGroupName, role: "earnGroup" },
        });
      } else {
        dispatch({
          type: SET_GIFT_RECIPIENT,
          payload: { name: email, role: "member" },
        });
      }
      dispatch(getUserCurrentTc());
      history.push(MEMBER.GIFT_SUCCESS);
    } else {
      dispatch({
        type: CREATE_GIFT_FAIL,
        payload: error,
      });
    }

    dispatch({ type: CREATE_GIFT_LOADING_END });
  };

export const getEarnGroups = () => async (dispatch) => {
  dispatch({ type: GET_EARN_GROUPS_LOADING_START });
  const { data, error } = await earnGroups.getEarnGroups();

  if (!error) {
    const cleanedGroups = data.map((group) => {
      const updatedGroup = {
        ...group,
        label: group.profile && group.profile.name,
      };
      return updatedGroup;
    });

    dispatch({
      type: GET_EARN_GROUPS_SUCCESS,
      payload: cleanedGroups,
    });
  } else {
    dispatch({
      type: GET_EARN_GROUPS_FAIL,
      payload: error,
    });
  }

  dispatch({ type: GET_EARN_GROUPS_LOADING_END });
};

export const cancelGift = (id) => async (dispatch) => {
  dispatch({ type: CANCEL_GIFT_LOADING_START, payload: { id } });
  const { error } = await gifts.cancelGiftById({ id });

  if (!error) {
    dispatch({
      type: CANCEL_GIFT_SUCCESS,
      payload: { id },
    });
    dispatch(getUserCurrentTc());
  } else {
    dispatch({
      type: CANCEL_GIFT_FAIL,
      payload: error,
    });
  }

  dispatch({ type: CANCEL_GIFT_LOADING_END, payload: { id } });
};

export const getGift = (id) => async (dispatch) => {
  dispatch({ type: GET_GIFT_LOADING_START });
  const { data, error } = await gifts.getGiftById({ id });

  if (!error) {
    dispatch({
      type: GET_GIFT_SUCCESS,
      payload: data,
    });
  } else {
    dispatch({
      type: GET_GIFT_FAIL,
      payload: error,
    });
  }

  dispatch({ type: GET_GIFT_LOADING_END });
};

export const resetErrors = () => ({ type: RESET_GIFT_ERRORS });
