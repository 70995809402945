import React from "react";
import { useHistory } from "react-router-dom";

import * as S from "../../style";
import * as T from "../../../../../components/Typograpy";

import { Row, Col } from "../../../../../components/Grid";
import { Regular } from "../../../../../components/Button";
import { Tag } from "../../../../../components/Tags";
import { EarnActivityList } from "../../../../../components/List";
import EarnActivity from "../../../../../components/Cards/Activity/Earn";
import {
  AboutVenueOrg,
  VenueDetails,
} from "../../../../../components/TextSections";

import { GENERAL } from "../../../../../constants/navRoutes";
import { earnActivityCategories } from "../../../../../constants";

import translate from "../../../../../utils/helpers/translator";
import { focusValuesMapWelsh } from "../../../../../constants/data/focusList";
import {
  earnTypesMapWelsh,
  earnTypesHelperMapWelsh,
} from "../../../../../constants/data/earnActivityTypes";
import ShareToSocialMedia from "../../../../../components/TextSections/ShareToSocialMedia";

const Content = ({
  earnGroupProfile,
  isTablet,
  id,
  lang,
  welshVersion,
  getInTouchDescription,
}) => {
  const history = useHistory();

  const {
    name,
    description,
    focusFields: causes,
    contactNumber,
    contactEmail,
    websiteUrl,
    publicActivities: activityTypes,
    addresses,
    roles,
    socialLinks = {},
    logo,
    isOnline,
    isPrivateAddress,
  } = earnGroupProfile;

  return (
    <>
      <Row>
        <Col w={[4, 12, 8]}>
          <S.LeftWrapper>
            {isTablet ? (
              <S.Header style={{ marginBottom: "30px" }}>
                <T.H30Caps color="midnight" mb="20">
                  {name}
                </T.H30Caps>
                <S.TagWrapper>
                  {causes &&
                    causes.map((cause) => (
                      <Tag
                        tagName={
                          lang === "english"
                            ? cause
                            : focusValuesMapWelsh[cause]
                        }
                        large
                        m="0 10px 10px 0"
                      />
                    ))}
                </S.TagWrapper>
              </S.Header>
            ) : (
              <S.Header style={{ marginBottom: "30px" }}>
                <T.H40Caps color="midnight" mb="20">
                  {name}
                </T.H40Caps>
                <S.TagWrapper>
                  {causes &&
                    causes.map((cause) => (
                      <Tag
                        tagName={
                          lang === "english"
                            ? cause
                            : focusValuesMapWelsh[cause]
                        }
                        large
                        m="0 10px 10px 0"
                      />
                    ))}
                </S.TagWrapper>
              </S.Header>
            )}
            <AboutVenueOrg text={description} m="0 0 40px 0" lang={lang} />
            {roles && roles.length > 0 && roles[0].name && (
              <>
                <T.H22 color="midnight" mb="20">
                  {translate("currentRoles", lang)}
                </T.H22>
                <T.Body16R color="gray3">
                  {translate("currentlyHiringFor", lang)}
                </T.Body16R>
                {roles.map((role, index) => (
                  <EarnActivityList
                    title={role.name}
                    subtitle={role.details}
                    graphic={index % 2 === 0 ? "graphic1" : "graphic2"}
                  />
                ))}
              </>
            )}
            {activityTypes && (
              <>
                <T.H22 color="midnight" mt={isTablet ? "30" : "40"} mb={15}>
                  {translate("lookingForHelp", lang)}
                </T.H22>
                <Row mb={1}>
                  {activityTypes.map((category, index) => {
                    const categoryDetails = earnActivityCategories.find(
                      (e) => e.category === category,
                    );
                    return (
                      <Col w={[4, 12, 12]} style={{ padding: 0 }}>
                        <EarnActivity
                          category={category}
                          categoryName={
                            lang === "english"
                              ? categoryDetails.categoryName
                              : earnTypesMapWelsh[categoryDetails.categoryName]
                          }
                          subtitle={
                            lang === "english"
                              ? categoryDetails.subtitle
                              : earnTypesHelperMapWelsh[
                                  categoryDetails.subtitle
                                ]
                          }
                          index={index}
                          noBackground
                          noButton
                        />
                      </Col>
                    );
                  })}
                </Row>
              </>
            )}
            <AboutVenueOrg
              m={isTablet ? "15px 0 0 0" : "0 0 30px 0"}
              text={translate("lotsOfWays", lang)}
              lang={lang}
            />
          </S.LeftWrapper>
        </Col>
        <Col w={[4, 12, 4]}>
          <S.ButtonWrapper>
            <Regular
              primary
              bgGrad="pink"
              style={{ maxWidth: "400px", height: "60px" }}
              onClick={() =>
                history.push(
                  GENERAL.EARN_GROUP_GET_IN_TOUCH.replace(":id", id),
                  {
                    contactNumber,
                    contactEmail,
                    websiteUrl,
                    getInTouchDescription,
                    welshVersion,
                  },
                )
              }
            >
              {translate("volunteerHere", lang)}
            </Regular>
          </S.ButtonWrapper>
          <VenueDetails
            isPrivateAddress={isPrivateAddress}
            addresses={addresses}
            phoneNum={contactNumber}
            email={contactEmail}
            twitter={socialLinks && socialLinks.twitter}
            facebook={socialLinks && socialLinks.facebook}
            url={websiteUrl}
            logo={logo && logo.imgURL}
            isOnline={isOnline}
            type="earnGroup"
            lang={lang}
          />
          <ShareToSocialMedia />
        </Col>
      </Row>
    </>
  );
};

export default Content;
