import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import * as S from "./style";
import * as T from "../Typograpy";
import Icon from "../Icon";
import Button from "../Button";
import theme from "../../utils/theme";

const Warning = ({
  header,
  size,
  restrictionsArray,
  restrictionsBoldArray,
  body,
  warningType,
  backFunc,
  modal,
  margin,
  onConfirm,
  confirmLoading,
  p,
  activityRestriction,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <>
      {warningType === "restriction" && (
        <S.RestrictionsWrapper>
          <Icon
            icon="alertTriangle"
            color="pink"
            width="66px"
            height="66px"
            margin="5px 10px 0 0"
          />
          <S.RestrictionsBody>
            <T.H14Caps
              color="midnight"
              style={{ lineHeight: "20px", margin: 0 }}
            >
              {header}
            </T.H14Caps>
            {restrictionsArray && (
              <S.List>
                {restrictionsBoldArray.length > 0 &&
                  restrictionsBoldArray.map((item) => (
                    <S.ListItem>
                      <S.StyledHyphen>-</S.StyledHyphen>
                      {isMobile ? (
                        <T.Body14B
                          style={{ margin: "0 0 0 10px" }}
                          color="gray3"
                        >
                          {item}
                        </T.Body14B>
                      ) : (
                        <T.Body16B
                          style={{ margin: "0 0 0 10px" }}
                          color="gray3"
                        >
                          {item}
                        </T.Body16B>
                      )}
                    </S.ListItem>
                  ))}
                {restrictionsArray.map((item) => (
                  <S.ListItem>
                    <S.StyledHyphen>-</S.StyledHyphen>
                    {isMobile ? (
                      <T.Body14R style={{ margin: "0 0 0 10px" }} color="gray3">
                        {item}
                      </T.Body14R>
                    ) : (
                      <T.Body16R style={{ margin: "0 0 0 10px" }} color="gray3">
                        {item}
                      </T.Body16R>
                    )}
                  </S.ListItem>
                ))}
              </S.List>
            )}
          </S.RestrictionsBody>
        </S.RestrictionsWrapper>
      )}

      {warningType === "activityRestriction" && (
        <S.RestrictionsWrapper p={p}>
          <Icon
            icon="alertTriangle"
            color="pink"
            width="66px"
            height="66px"
            margin="5px 10px 0 0"
          />
          <S.RestrictionsBody>
            <T.H14Caps
              color="midnight"
              style={{
                lineHeight: "20px",
                marginBottom: 10,
                marginLeft: 0,
              }}
            >
              {header}
            </T.H14Caps>
            <>
              {isMobile ? (
                <T.Body14R style={{ margin: "0 0 0 10px" }} color="gray3">
                  {activityRestriction}
                </T.Body14R>
              ) : (
                <T.Body16B style={{ margin: "0 0 0 10px" }} color="gray3">
                  {activityRestriction}
                </T.Body16B>
              )}
            </>
          </S.RestrictionsBody>
        </S.RestrictionsWrapper>
      )}

      {warningType === "dateRestriction" && (
        <S.RestrictionsWrapper size={size} p={p}>
          <Icon
            icon="alertTriangle"
            color="pink"
            width="40px"
            height="40px"
            margin="5px 10px 0 0"
          />
          <S.RestrictionsBody>
            <T.Body16B>{header}</T.Body16B>
          </S.RestrictionsBody>
        </S.RestrictionsWrapper>
      )}

      {warningType === "email" && (
        <S.EmailWrapper modal={modal} margin={margin}>
          <S.EmailHeader>
            <T.H20
              color="midnight"
              style={{ lineHeight: "25px", width: "80%", margin: "0" }}
            >
              {header}
            </T.H20>
            <Icon icon="alertTriangle" color="pink" width="15%" height="15%" />
          </S.EmailHeader>
          {body && (
            <S.EmailBody>
              <T.Body14R style={{ textAlign: "left", margin: "0" }}>
                {body}
              </T.Body14R>
              <S.ButtonWrapper>
                <Button
                  size="l"
                  primary
                  mb={10}
                  onClick={onConfirm}
                  loading={confirmLoading}
                >
                  I&apos;m sure
                </Button>
                <Button size="l" outline onClick={backFunc}>
                  I want to go back
                </Button>
              </S.ButtonWrapper>
            </S.EmailBody>
          )}
        </S.EmailWrapper>
      )}

      {warningType === "modal" && (
        <S.EmailWrapper margin={margin}>
          <S.EmailHeader>
            <T.H20
              color="midnight"
              style={{ lineHeight: "25px", width: "80%", margin: "0" }}
            >
              {header}
            </T.H20>
            <Icon icon="alertTriangle" color="pink" width="15%" height="15%" />
          </S.EmailHeader>
          {body && (
            <S.EmailBody>
              <T.Body14R style={{ textAlign: "left", margin: "0" }}>
                {body}
              </T.Body14R>
            </S.EmailBody>
          )}
        </S.EmailWrapper>
      )}
    </>
  );
};

export default Warning;
