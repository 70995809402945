import { styled } from "@material-ui/core/styles";

export const Wrapper = styled("section")(({ theme, success }) => ({
  width: "100%",
  flex: "1",
  flexDirection: "column",
  maxWidth: success && "400px",
  alignItems: success ? "center" : "flex-start",
  margin: success && "0 auto",
  marginBottom: theme.spacing(9),
}));

export const Questions = styled("section")(({ theme }) => ({
  width: "100%",
  flex: "1",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",

  paddingTop: theme.spacing(5),

  paddingRight: theme.spacing(5),
  paddingLeft: theme.spacing(5),
  maxWidth: "400px",
  margin: "0 auto",
}));

export const Error = styled("p")(({ theme }) => ({
  width: "100%",
  color: theme.colors.pink,
  fontSize: "0.875rem",
  textAlign: "left",
  fontWeight: "normal",
  marginBottom: theme.spacing(2),
}));
