import React from "react";
import { Switch } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// CONSTANTS
import { SPEND_VENUE } from "../../constants/navRoutes";
import { roles } from "../../constants";

// SPEND VENUE COMPONENTS
import AcceptTimeCredits, {
  AcceptTcSuccess,
  AcceptTcFail,
} from "./AcceptTimeCredits";
import SpendVenueInsights from "./SpendVenueInsights";
import Offers from "./Offers";

// MULTI-USE COMPONENTS
import Login from "../Login";

// OTHER ROUTERS
import ErrorRouter from "../ErrorRoutes";
import SpendActivityForm from "../SpendActivityForm";

// COMMON
import Route from "../../components/Route";
import theme from "../../utils/theme";

const SpendVenueRouter = () => {
  const showProfileFooter = useMediaQuery(theme.breakpoints.down(1200));
  return (
    <Switch>
      <Route
        exact
        path={SPEND_VENUE.SPEND_LOG_IN}
        layout="onboarding"
        footer
        Component={Login}
        spendVenue
      />

      <Route
        exact
        path={SPEND_VENUE.SPEND_HOME}
        layout="onboarding"
        footer
        Component={Login}
        spendVenue
      />

      <Route
        exact
        isPrivate
        path={SPEND_VENUE.ACCEPT_TC}
        Component={AcceptTimeCredits}
        navbar
        allowedRoles={[
          roles.EARN_GROUP,
          roles.SPEND_VENUE,
          roles.EARN_GROUP_ADMIN,
          roles.SPEND_PARTNER,
        ]}
        allowTemporaryLogin
        footer={!showProfileFooter}
      />

      <Route
        exact
        isPrivate
        path={SPEND_VENUE.ACCEPT_TC_SUCCESS}
        Component={AcceptTcSuccess}
        layout="splitScreen"
        side="right"
        allowedRoles={[
          roles.EARN_GROUP,
          roles.SPEND_VENUE,
          roles.EARN_GROUP_ADMIN,
          roles.SPEND_PARTNER,
        ]}
        allowTemporaryLogin
      />

      <Route
        exact
        isPrivate
        allowTemporaryLogin
        path={SPEND_VENUE.ACCEPT_TC_FAIL}
        Component={AcceptTcFail}
        layout="splitScreen"
        side="right"
        allowedRoles={[
          roles.EARN_GROUP,
          roles.SPEND_VENUE,
          roles.MEMBER,
          roles.EARN_GROUP_ADMIN,
          roles.SPEND_PARTNER,
        ]}
      />

      <Route
        exact
        isPrivate
        navbar
        path={SPEND_VENUE.OFFERS}
        Component={Offers}
        allowedRoles={[
          roles.EARN_GROUP,
          roles.SPEND_VENUE,
          roles.SPEND_PARTNER,
        ]}
        footer={!showProfileFooter}
      />

      <Route
        exact
        isPrivate
        path={SPEND_VENUE.INSIGHTS}
        Component={SpendVenueInsights}
        header
        navbar
        allowedRoles={[roles.SPEND_VENUE, roles.SPEND_PARTNER]}
        footer={!showProfileFooter}
      />
      <Route
        exact
        path={SPEND_VENUE.ADD_OFFERS}
        navbar
        header
        isPrivate
        Component={SpendActivityForm}
        allowedRoles={[roles.SPEND_PARTNER, roles.SPEND_VENUE]}
      />

      <Route
        exact
        path={SPEND_VENUE.EDIT_OFFERS}
        navbar
        header
        isPrivate
        Component={SpendActivityForm}
        edit
        allowedRoles={[roles.SPEND_PARTNER, roles.SPEND_VENUE]}
      />

      {/* ERROR ROUTES */}
      <ErrorRouter />
    </Switch>
  );
};

export default SpendVenueRouter;
