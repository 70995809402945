import { members } from "../../../api-calls";

import {
  GET_MY_GROUPS_LOADING,
  GET_MY_GROUPS_SUCCESS,
  GET_MY_GROUPS_FAIL,
} from "../../../redux/types";

export const getMyGroups = () => async (dispatch) => {
  dispatch({ type: GET_MY_GROUPS_LOADING });
  try {
    const { data, error } = await members.getMyGroups({});
    if (data) {
      dispatch({ type: GET_MY_GROUPS_SUCCESS, payload: data });
    } else {
      dispatch({
        type: GET_MY_GROUPS_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_MY_GROUPS_FAIL,
      payload: error,
    });
  }
};
