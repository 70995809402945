// SECTION CONSTANTS
const activateAccountEmailSent = "/activate-account-email-sent";
const signUp = "/sign-up";
const continueSignUp = "/continue-sign-up";
const dashboard = "/dashboard";
const login = "/log-in";
const logout = "/log-out";
const forgetPassword = "/forget-password";
const setPassword = "/set-password/:token";
const transactions = "/transactions";
const programmes = "/programmes";
const insights = "/insights";
export const earn = "/earn";
export const spend = "/use";
const gift = "/gift-credits";
const give = "/give-credits";
const invite = "/invite";
const request = "/get-more-credits";
const viewRequests = "/requests";
const issueCredits = "/issue-credits";
const selectVenue = "/select-venue";
const welcome = "/welcome";
const access = "/access";
const accept = "/accept";
const profile = "/profile";
const settings = "/settings";
const activities = "/activities";
const addMembers = "/add-members";
const terms = "/terms";
const selectUser = "/select-user";
const spendVenues = "/venues";
const offers = "/offers";
export const spendActivity = "/spend-activity";

// USER URL CONSTANTS
const member = "/volunteers";
const earnGroup = "/earn-groups";
const spendVenue = "/venue";
const spendPartner = "/recognition-partner";
const tempo = "/admin";
const earnGroupAdmin = "/earn-group/admin";
const programmeManager = "/programme-manager";
const notFound = "/404";
const unauthorized = "/401";
const serverError = "/500";

export const SECTIONS = {
  GENERAL: "/",
  MEMBERS: member,
  EARN_GROUPS: earnGroup,
  SPEND_VENUES: spendVenue,
  TEMPO: tempo,
  EARN_GROUP_ADMINS: earnGroupAdmin,
  PROGRAMME_MANAGERS: programmeManager,
  SPEND_PARTNER: spendPartner,
};

// PUBLIC SECTIONS
// GENERAL
export const GENERAL = {
  HOME_URL: "/",
  ACTIVATE_ACCOUNT_CHECK_TOKEN: "/activate-account/:token",
  ACTIVATE_ACCOUNT_EMAIL_SENT: "/activate-account-email-sent",
  LOG_IN: login,
  SIGN_UP: signUp,
  SIGN_UP_FUNNEL: `${signUp}/select`,
  EARN_GROUP: `${earn}${earnGroup}/:id`,
  EARN_GROUP_GET_IN_TOUCH: `${earn}${earnGroup}/volunteer/:id`,
  SPEND: `${spend}/:search?`,
  SPEND_ACTIVITY: `/activity/:id`,
  SPEND_OPTIONS: `${spend}/:id/use-time-credits`,
  BOOK_ONLINE_SUCCESS: `${spend}/:id/use-time-credits/success`,
  SPEND_VENUE: `${spend}${spendVenue}/:id`,
  LOG_OUT: logout,
  SELECT_VENUE: selectVenue,
  WELCOME: welcome,
  CONTINUE_SIGN_UP: continueSignUp,
  FORGET_PASSWORD: forgetPassword,
  SET_PASSWORD: setPassword,
  NOT_FOUND: notFound,
  UNAUTHORIZED: unauthorized,
  SERVER_ERROR: serverError,
  SETTINGS: settings,
  EARN: `${earn}/:search?`,
  ACTIVITIES: activities,
  SELECT_USER: selectUser,
  ACCEPT_TERMS: `${terms}`,
  ADD_GROUP_LOGIN: `/add-group${login}`,
  // PRIVATE ROUTES
  ADD_GROUP_SIGNUP: `/add-group${signUp}`,
  ORDER_INVOICE: "/orders/:id/invoice",
};
// PRIVATE SECTIONS
// MEMBER
export const MEMBER = {
  DASHBOARD: `${member}${dashboard}`,
  EARN: `${member}${earn}`,
  SPEND: `${member}${spend}`,
  GIFT: `${member}${gift}`,
  GIFT_SUCCESS: `${member}${gift}/success`,
  GIFT_HISTORY: `${member}${gift}/history`,
  HISTORY: `${member}${transactions}`,
  HISTORY_SINGLE: `${member}${transactions}/:id`,
  INVITED_BY_GROUP_SUCCESS: `${member}${invite}/success`,
  SETTINGS: settings,
  LOG_OUT: logout,
  RECEIVED_GIFT_SUCCESS: `${member}${gift}/received/:id`,
  MY_GROUPS: `${member}/my-groups`,
};
// EARN GROUP
export const EARN_GROUP = {
  SIGN_UP: `${earnGroup}${signUp}`,
  DASHBOARD: `${earnGroup}${dashboard}`,
  GIVE: `${earnGroup}${give}`,
  GIVE_SUCCESS: `${earnGroup}${give}/success`,
  ACCEPT_TC: `${spendVenue}`,
  MEMBERS: `${earnGroup}${member}`,
  MEMBERS_SINGLE: `${earnGroup}${member}/:id`,
  INVITE: `${earnGroup}${invite}`,
  INVITE_NONDIGITAL: `${earnGroup}${invite}/no-email`,
  INVITE_NONDIGITAL_SUCCESS: `${earnGroup}${invite}/success`,
  HISTORY: `${earnGroup}${transactions}`,
  HISTORY_SINGLE: `${earnGroup}${transactions}/:id`,
  HISTORY_SINGLE_PAID: `${earnGroup}/orders/:id/transaction`,
  REQUEST: `${earnGroup}${request}`,
  REQUEST_SUCCESS: `${earnGroup}${request}/success`,
  PROFILE: `${earnGroup}/:id${profile}/edit`,
  OFFERS: `${earnGroup}${offers}`,
  ADD_OFFERS: `${earnGroup}/:earnGroupId/add-offer`,
  EDIT_OFFERS: `${earnGroup}/edit-offer/:id`,
  ORDER_CONFIRMATION: `${earnGroup}/order/:id/confirm`,
  ORDER_PAYMENT_SUCCESS: `${earnGroup}/order/success`,
  SETTINGS: settings,
  ACTIVITIES: activities,
  LOG_OUT: logout,
};
// SPEND VENUE
export const SPEND_VENUE = {
  SPEND_HOME: `${accept}`,
  SPEND_LOG_IN: `${spendVenue}${login}`,
  DASHBOARD: `${spendVenue}/home`,
  INSIGHTS: `${spendVenue}${insights}`,
  OFFERS: `${spendVenue}${offers}`,
  ACCEPT_TC: `${spendVenue}`,
  ACCEPT_TC_SUCCESS: `${spendVenue}/success`,
  ACCEPT_TC_FAIL: `${spendVenue}/fail`,
  ADD_OFFERS: `${spendVenue}/:spendVenueId/add-offer`,
  EDIT_OFFERS: `${spendVenue}/edit-offer/:id`,

  SETTINGS: settings,
  LOG_OUT: logout,
};
// TEMPO
export const TEMPO = {
  ADMIN_LOG_IN: `${tempo}${login}`,
  DASHBOARD: `${tempo}${dashboard}`,
  REQUESTS: `${tempo}${viewRequests}`,
  REQUEST_APPROVE: `${tempo}${viewRequests}/:requestId/approve`,
  REQUEST_REJECT: `${tempo}${viewRequests}/:requestId/reject`,
  REQUEST_PARTIAL: `${tempo}${viewRequests}/:requestId/partial`,
  REQUEST_SUCCESS: `${tempo}${viewRequests}/:requestId/success`,
  ISSUE_CREDITS_MEMBER: `${tempo}${issueCredits}${member}/:userId`,
  ISSUE_CREDITS_EARN_GROUP: `${tempo}${issueCredits}${earnGroup}/:userId`,
  ISSUE_SUCCESS: `${tempo}${issueCredits}/success`,
  MEMBERS: `${tempo}${member}`,
  ADD_MEMBERS: `${tempo}${addMembers}`,
  MEMBERS_SINGLE: `${tempo}${member}/:id`,
  EARN_GROUPS: `${tempo}${earnGroup}`,
  EARN_GROUP_SINGLE: `${tempo}${earnGroup}/:id`,
  SPEND_VENUES: `${tempo}${spendVenue}`,
  SPEND_VENUES_WITH_LIVE_OFFERS: `${tempo}${spendVenue}/live`,
  SPEND_VENUES_SINGLE: `${tempo}${spendVenue}/:id/details`,
  OFFERS: `${tempo}${offers}`,
  TRANSACTIONS: `${tempo}${transactions}`,
  PROGRAMMES: `${tempo}${programmes}`,
  PROGRAMME_SINGLE: `${tempo}${programmes}/:id`,
  NEW_PROGRAMME: `${tempo}${programmes}/new`,
  // NEW_PROGRAMME_SUCCESS: `${tempo}${programmes}/:id/created`,
  PROGRAMME_INVITE_GROUPS: `${tempo}${programmes}/:id/invite`,
  TRANSACTIONS_SINGLE: `${tempo}${transactions}/:id`,
  LOG_OUT: logout,
  ACCESS_PERMISSION: `${tempo}${access}`,
  CREATE_SPEND_ACTIVITY: `${tempo}${spendVenue}/:spendVenueId/create-activity`,
  EDIT_SPEND_ACTIVITY: `${tempo}${spend}/:id/edit`,
  EDIT_PROGRAMME: `${tempo}${programmes}/:id/edit`,
  PROGRAMME_EARN_GROUPS: `${tempo}${programmes}/:id${earnGroup}`,
  CREATE_SPEND_VENUE_SELECT_VENUE: `${tempo}${spendVenue}/create/step/1`,
  CREATE_SPEND_VENUE: `${tempo}${spendVenue}/create/step/2/partner/:spendPartnerId`,
  CREATE_SPEND_VENUE_SUCCESS: `${tempo}${spendVenue}/create/step/3/partner/:spendPartnerId/venue/:venueId`,
  CREATE_SPEND_PARTNER: `${tempo}${spendPartner}/create`,
  EDIT_EARN_GROUP_PROFILE: `${tempo}${earnGroup}/:id/edit`,
  EDIT_EARN_GROUP_ADMIN: `${tempo}${earnGroup}/:id/admin/edit`,
  EDIT_SPEND_VENUE_ACCOUNT: `${tempo}${spendVenue}/:id/account/edit`,
  EDIT_SPEND_PARTNER_ACCOUNT: `${tempo}${spendPartner}/:id/account/edit`,
  EDIT_SPEND_VENUE: `${tempo}${spendVenue}/:spendVenueId/edit`,
  EDIT_MEMBER_ACCOUNT: `${tempo}${member}/:id/edit`,
  SPEND_VENUE_INSIGHTS: `${tempo}${spendVenue}/:spendVenueId/insights`,
  SIGN_UP: `${tempo}${signUp}`,
  SPEND_PARTNER: `${tempo}${spendPartner}/:id`,
  CREATE_VENUE: `${tempo}${spendPartner}/:spendPartnerId/create-venue`,
  RECOGNITION_LISTING_REQUESTS: `${tempo}/recognition-listing-requests`,
  ORDERS: `${tempo}/orders`,
  ORDER_CONFIRMATION: `${tempo}/order-confirmation/:id`,
  MANAGE_PACKAGES: `${tempo}/manage-packages`,
  MARK_ORDER_AS_PAID: `${tempo}/orders/:id/mark-as-paid`,
  CANCEL_ORDER: `${tempo}/orders/:id/cancel`,
  TK_GROUP_REQUESTS: `${tempo}/tk-group-requests`,
  TK_PROGRAMME_CONFLICT: `${tempo}/tk-programme-conflict/:groupUserId/:approvalRequestId`,
  TK_SINGLE_GROUP_REQUESTS: `${tempo}/tk-group-requests/:id`,
  DUPLICATE_SPEND_ACTIVITY: `${tempo}${spend}/:id/duplicate`,
};
// EARN GROUP ADMINISTRATORS
export const EARN_GROUP_ADMIN = {
  SIGN_UP: `${earnGroupAdmin}${signUp}`,
  LOGIN_ADD: `${earnGroupAdmin}${login}`,
};

export const SPEND_PARTNER = {
  SELECT_VENUE: `${spendPartner}${selectVenue}`,
  SPEND_VENUES: `${spendPartner}${spendVenues}`,
  SETTINGS: settings,
};

// EXTERNAL PROGRAMME MANAGER
export const PROGRAMME_MANAGER = {
  DASHBOARD: `${programmeManager}${dashboard}`,
  REQUESTS: `${programmeManager}${viewRequests}`,
  EARN_GROUPS: `${programmeManager}${earnGroup}`,
  ACTIVITIES: `${activities}`,
  SETTINGS: `${settings}`,
  REQUEST_APPROVE: `${programmeManager}${viewRequests}/:requestId/approve`,
  REQUEST_REJECT: `${programmeManager}${viewRequests}/:requestId/reject`,
  REQUEST_PARTIAL: `${programmeManager}${viewRequests}/:requestId/partial`,
  REQUEST_SUCCESS: `${programmeManager}${viewRequests}/:requestId/success`,
  ISSUE_SUCCESS: `${programmeManager}${issueCredits}/success`,
  ISSUE_CREDITS_EARN_GROUP: `${programmeManager}${issueCredits}${earnGroup}/:userId`,
  PROGRAMME_INVITE_GROUPS: `${programmeManager}${programmes}/:id/invite`,
  SIGN_UP: `${programmeManager}${signUp}`,
};
