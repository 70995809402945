import React, { useEffect } from "react";
import { connect } from "react-redux";

import * as S from "./style";
import * as T from "../../../components/Typograpy";
import Table from "../../../components/Table";

import { Row, Col } from "../../../components/Grid";

import * as actions from "../actions";
import t from "../../../utils/helpers/translator";
import Info from "../../../components/Message/Info";

const SpendVenues = ({
  spendPartner,
  getSpendVenues,
  spendVenues,
  lang,
  loading,
  isMobile,
}) => {
  useEffect(() => {
    getSpendVenues({ basic: false, details: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnsDesktop = [
    {
      title: t("name", lang),
      field: "name",
      render: (rowData) => (
        <T.Body14R>
          {lang === "welsh" && rowData.welshName
            ? rowData.welshName
            : rowData.name}
        </T.Body14R>
      ),
    },
    {
      title: t("liveActivities", lang),
      field: "liveActivities",
      type: "numeric",
      render: ({ liveActivities }) => liveActivities || 0,
    },
    {
      title: (
        <>
          <T.Body14B color="midnight">{t("tcAccepted", lang)}</T.Body14B>
          <T.Body12R color="midnight" style={{ textTransform: "lowercase" }}>
            {t("allTime", lang)}
          </T.Body12R>
        </>
      ),
      field: "totalTcAccepted",
      type: "numeric",
      render: ({ totalTcAccepted }) => totalTcAccepted || 0,
    },
    {
      title: (
        <>
          <T.Body14B color="midnight">
            {t("uniqueTempoMemberVisits", lang)}
          </T.Body14B>
          <T.Body12R color="midnight" style={{ textTransform: "lowercase" }}>
            {t("allTime", lang)}
          </T.Body12R>
        </>
      ),
      field: "allMembers",
      type: "numeric",
      render: ({ allMembers }) => allMembers || 0,
    },
    {
      title: (
        <>
          <T.Body14B color="midnight">{t("tcAccepted", lang)}</T.Body14B>
          <T.Body12R color="midnight" style={{ textTransform: "lowercase" }}>
            {t("thisYear", lang)}
          </T.Body12R>
        </>
      ),
      field: "totalTcAcceptedThisYear",
      type: "numeric",
      render: ({ totalTcAcceptedThisYear }) => totalTcAcceptedThisYear || 0,
    },
    {
      title: (
        <>
          <T.Body14B color="midnight">
            {t("uniqueTempoMemberVisits", lang)}
          </T.Body14B>
          <T.Body12R color="midnight" style={{ textTransform: "lowercase" }}>
            {t("thisYear", lang)}
          </T.Body12R>
        </>
      ),
      field: "thisYearMembers",
      type: "numeric",
      render: ({ thisYearMembers }) => thisYearMembers || 0,
    },
  ];

  const columnsMobile = [
    {
      title: t("name", lang),
      field: "name",
      render: (rowData) => (
        <T.Body14R>
          {lang === "welsh" && rowData.welshName
            ? rowData.welshName
            : rowData.name}
        </T.Body14R>
      ),
    },
    {
      title: t("liveActivities", lang),
      field: "liveActivities",
      type: "numeric",
      render: ({ liveActivities }) => liveActivities || 0,
    },
    {
      title: t("tcAccepted", lang),
      field: "totalTcAcceptedThisYear",
      type: "numeric",
      render: ({ totalTcAcceptedThisYear }) => totalTcAcceptedThisYear || 0,
    },
  ];

  return (
    <S.Container>
      <Row mb={isMobile ? 7 : 0}>
        <Col w={[4, 12, 12]}>
          <T.H22Caps color="pink">{spendPartner?.name}</T.H22Caps>
          {isMobile ? (
            <T.H30Caps color="midnight" mt="10">
              {t("venues", lang)}
            </T.H30Caps>
          ) : (
            <T.H40Caps color="midnight" mt="10">
              {t("venues", lang)}
            </T.H40Caps>
          )}
        </Col>
      </Row>

      {isMobile && (
        <Info
          header={t("tcAcceptedDuringYear", lang)}
          size="small"
          fullWidth
          color="blue"
        />
      )}
      <div>
        <S.TableContainer>
          <Table
            color="blue"
            data={spendVenues}
            columns={isMobile ? columnsMobile : columnsDesktop}
            hideSearch
            isLoading={loading}
          />
        </S.TableContainer>
      </div>
    </S.Container>
  );
};

const mapStateToProps = (state) => ({
  spendPartner: state.auth.spendPartner,
  spendVenues: state.spendPartner.spendVenues,
  loading: state.spendPartner.getLoading,
  lang: state.auth.language,
  isMobile: state.ui.isMobile,
});

const mapActionsToProps = {
  getSpendVenues: actions.getSpendVenues,
};

export default connect(mapStateToProps, mapActionsToProps)(SpendVenues);
