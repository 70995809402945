import React from "react";

import * as T from "../Typograpy";
import * as S from "./style";

import translate from "../../utils/helpers/translator";

const TextSection = ({
  title,
  text,
  size,
  groupAdvance,
  groupSize,
  m,
  lang,
  customTextElem,
  w,
}) => (
  <S.TextWrapper size={size} m={m}>
    {title && (
      <T.H18 color="midnight" mb="10" lg style={{ width: w }}>
        {title}
      </T.H18>
    )}
    {text && (
      <T.Body16R color="gray3" m="0 0 20px 0" style={{ width: w }}>
        {text}
      </T.Body16R>
    )}
    {customTextElem}
    {/* {groupAdvance === false && (
      <T.Body14S color="midnight" m="0 0 10px 0">
        {translate("groupsDontBookInAdvance", lang)}
      </T.Body14S>
    )} */}
    {groupAdvance === true && (
      <T.Body14S color="midnight" m="0 0 10px 0">
        {translate("groupsBookInAdvance", lang)}
      </T.Body14S>
    )}
    {!!groupSize && (
      <T.Body14S color="midnight" m="0">
        {translate("maxGroupSize", lang)} {groupSize}
      </T.Body14S>
    )}
  </S.TextWrapper>
);

export default TextSection;
