import { styled } from "@material-ui/core/styles";

export const Wrapper = styled("aside")(({ w }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: w || "100%",
}));

export const ListItem = styled("div")(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(4),
  wordWrap: "break-word !important",
  overflowWrap: "break-word",
  "-ms-word-break": "break-all",
  wordBreak: "break-word",
}));

export const LogoWrapper = styled("div")(({ theme, logo }) => ({
  background: `no-repeat url("${logo}")`,
  backgroundPosition: `0 100%`,
  backgroundSize: "contain",
  width: "100%",
  height: "50%",
  minHeight: "140px",
  borderRadius: theme.borders.radius,
  marginTop: theme.spacing(4),
}));

export const Address = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
}));

export const Link = styled("a")(({ theme, color }) => ({
  textDecoration: "none",
  color: color && theme.colors[color],
}));

export const AboutWrapper = styled("aside")(({ w, m }) => ({
  width: w || "100%",
  margin: m || "0",
}));

export const TextWrapper = styled("aside")(({ theme, w, size, m }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: w || "100%",
  margin: m || "0",

  "& h1, h2, h3, h4, h5, h6": {
    fontWeight: size === "lg" && "900",
  },

  [theme.breakpoints.mobileL]: {
    "& h1, h2, h3, h4, h5, h6": {
      fontSize: size === "lg" && "22px",
      lineHeight: size === "lg" && "35px",
      fontWeight: size === "lg" && "900",
      marginBottom: size === "lg" && theme.spacing(0),
    },
  },
}));
