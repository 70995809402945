import React from "react";
import * as T from "../../Typograpy";

const GroupHeader = ({ header }) => (
  <T.Body14S
    color="gray2"
    style={{
      textTransform: "uppercase",
      minWidth: "200px",
      margin: "0",
      position: "absolute",
      left: "0",
      top: "30px",
    }}
  >
    {header.date}
  </T.Body14S>
);

export default GroupHeader;
