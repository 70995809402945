import { styled } from "@material-ui/core/styles";
import * as T from "../../../components/Typograpy";

export const Wrapper = styled("section")(() => ({
  position: "relative",
  flex: "1",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
}));

export const ImageWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: theme.spacing(6),
  minWidth: "200px",

  [theme.breakpoints.tablet]: {
    // width: "245px",
    // height: "227px",
    // marginRight: theme.spacing(3),
  },
}));

export const ImageWrapperDigital = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  marginBottom: theme.spacing(6),
  minWidth: "200px",

  [theme.breakpoints.tablet]: {
    justifyContent: "flex-end",
    width: "245px",
    height: "227px",
    marginRight: theme.spacing(3),
  },
}));

export const Header = styled(T.H24Caps)(({ theme }) => ({
  textTransform: "uppercase",
  color: theme.colors.midnight,
  marginBottom: theme.spacing(5),
  fontSize: "24px",

  [theme.breakpoints.tablet]: {
    fontSize: "40px",
    lineHeight: "40px",
    marginBottom: theme.spacing(7),
  },
}));

export const Body = styled("div")(() => ({
  width: "100%",
  wordWrap: "break-word",
}));

export const BodyDigital = styled("div")(({ theme }) => ({
  width: "100%",
  wordWrap: "break-word",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.tablet]: {
    width: "60%",
    display: "block",
  },
}));

export const SubHeader = styled(T.Body18B)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: "900",
  color: theme.colors.midnight,
  marginBottom: theme.spacing(3),
}));

export const ContentWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  position: "realtive",
  [theme.breakpoints.tablet]: {
    display: "flex",
  },
}));

export const WarningWrapper = styled("aside")(({ theme }) => ({
  width: "100%",
  display: "flex",
  marginBottom: theme.spacing(6),
}));

export const BodyText = styled(T.Body14R)(({ theme }) => ({
  fontSize: "0.875rem",
  [theme.breakpoints.tablet]: {
    fontSize: "18px",
    lineHeight: "25px",
    width: "100%",
  },
}));

export const NonDigitalWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  wordWrap: "break-word",
  paddingTop: theme.spacing(5),
  marginBottom: theme.spacing(6),
}));

export const NonDigitalForm = styled("form")(() => ({
  width: "100%",
  wordWrap: "break-word",
}));

export const SuccessWrapper = styled("section")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "0 auto",
  maxWidth: "420px",
  width: "100%",
  paddingTop: theme.spacing(4),

  [theme.breakpoints.tablet]: {
    paddingTop: "0",
  },
}));

export const MemberHeader = styled("header")(({ theme }) => ({
  paddingTop: theme.spacing(7),
  paddingBottom: theme.spacing(7),
  textAlign: "center",
}));

export const Emphasis = styled("span")(() => ({
  fontWeight: "bold",
}));

export const PhoneWrapper = styled("div")(() => {});

export const ButtonWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.mobileL]: {
    width: "50%",
  },
}));

export const ExtLink = styled("a")(({ theme }) => ({
  color: theme.colors.gray3,
  fontWeight: "bold",
}));
