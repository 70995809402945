import React, { useState } from "react";
import Dropzone from "react-dropzone";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Icon from "../Icon";
import * as T from "../Typograpy";
import { images } from "../../api-calls";
import * as S from "./style";
import theme from "../../utils/theme";
import t from "../../utils/helpers/translator";

const initialImage = {
  url: "",
  name: "",
  key: "",
  bucketRegion: "",
  bucket: "",
};

const UploadImage = ({
  maxSize,
  maxSizeText,
  minHeight,
  minWidth,
  disabled,
  imageType,
  setImageInfo,
  imageInfo: oldImageInfo = {},
  secondaryText,
  error: validationError,
  placeholder,
  mobilePlaceholder,
  lang = "english",
}) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [showOldImage, setShowOldImage] = useState(true);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const resetState = () => {
    setFile(null);
    setError("");

    setImageInfo({ ...initialImage, deleted: true });

    setShowOldImage(false);
  };

  const uploadToS3 = async (data) => {
    // size, path, name, fileType: type, new: true
    const { data: imageInfo, error: _error } = await images.uploadToS3({
      signedURL: data.url,
      file: data.file,
    });
    if (_error) {
      setError(_error.message);
    } else {
      setImageInfo({ ...data, ...imageInfo, uploadedToS3: true });
    }
  };

  const getSignedURL = async ({ fileType, fileName, file: _file }) => {
    const { data, error: _error } = await images.getSignedURL({
      fileType,
      fileName,
      imageType,
    });
    if (_error) {
      setError(_error.message);
    } else {
      setImageInfo({ ...data, new: true });
      uploadToS3({
        ...oldImageInfo,
        ...data,
        deleted: false,
        new: true,
        file: _file,
      });
    }
  };

  const checkImageDimensions = (_file) => {
    if (_file) {
      if (minHeight || minWidth) {
        const image = new Image();

        image.addEventListener("load", () => {
          if (image.width < minWidth || image.height < minHeight) {
            setError(`Image should be larger than ${minHeight} × ${minWidth}`);
            return setFile(null);
          }

          getSignedURL({
            fileType: _file.type,
            fileName: _file.name,
            file: _file,
          });
        });

        image.src = URL.createObjectURL(_file);
      } else {
        getSignedURL({
          fileType: _file.type,
          fileName: _file.name,
          file: _file,
        });
      }
    }
  };

  const onDropRejected = (errors) => {
    if (
      errors &&
      errors[0] &&
      errors[0].errors &&
      errors[0].errors[0] &&
      errors[0].errors[0].code
    ) {
      if (errors[0].errors[0].code === "file-too-large") {
        setError(`File is larger than ${maxSizeText}`);
      } else {
        setError(errors[0].errors[0].message);
      }
    }
  };

  const optimizeFileName = (name) => {
    const [fileName, ext] = name.split(".");
    if (ext && fileName.length > 28) {
      return `${fileName.substring(0, 28)}... .${ext}`;
    }
    return name.length > 50 ? `${name.substring(0, 32)}...` : name;
  };

  const onDrop = ([_file]) => {
    setFile(_file);
    setError("");
    checkImageDimensions(_file);
  };

  const renderFileName =
    file || (showOldImage && oldImageInfo.name) ? (
      <S.FileNameWrapper>
        <div style={{ width: 24, height: 24 }}>
          <Icon icon="tickRound" width="24" height="24" color="white" />
        </div>
        <T.Body14S style={{ lineHeight: "24px" }} color="gray3" ml={15} mr={10}>
          {(file && optimizeFileName(file.path)) ||
            (showOldImage && optimizeFileName(oldImageInfo.name))}
        </T.Body14S>
        <div style={{ width: 24, height: 24, marginTop: "2px" }}>
          <Icon
            icon="close"
            width="24"
            height="24"
            color="pink"
            onClick={resetState}
            style={{ cursor: "pointer" }}
          />
        </div>
      </S.FileNameWrapper>
    ) : null;

  return (
    <Dropzone
      onDrop={onDrop}
      multiple={false}
      accept=".jpeg,.jpg"
      maxSize={maxSize}
      onDropRejected={onDropRejected}
      disabled={disabled}
    >
      {({ getRootProps, getInputProps }) => (
        <section className="container">
          <S.BoxWrapper
            {...getRootProps({ className: "dropzone" })}
            disabled={disabled}
          >
            <input {...getInputProps()} />
            <Icon icon="file" width="30" height="30" />
            <T.Body14S color="midnight" mt={15} style={{ textAlign: "center" }}>
              {isMobile
                ? mobilePlaceholder || t("clickToUpload", lang)
                : placeholder || t("dragImageHereOrClickToUpload", lang)}
            </T.Body14S>
            {secondaryText && (
              <T.Body14R color="gray3" style={{ textAlign: "center" }}>
                ({secondaryText})
              </T.Body14R>
            )}
          </S.BoxWrapper>
          {(error || validationError) && (
            <S.Error>{error || validationError}</S.Error>
          )}
          <aside>{renderFileName}</aside>
        </section>
      )}
    </Dropzone>
  );
};

export default UploadImage;
