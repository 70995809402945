import React from "react";
import { connect } from "react-redux";
import theme from "../../../../../../utils/theme";

import * as S from "../style";
import * as T from "../../../../../../components/Typograpy";
import { Row, Col } from "../../../../../../components/Grid";
import Image from "../../../../../../components/Image";
import { GeneralList } from "../../../../../../components/List";

import translate from "../../../../../../utils/helpers/translator";

import CreateAccount from "../CreateAccount";

const Content = ({ hideImage, lang, isTablet, memberID, isLoggedIn }) => (
  <>
    <S.Header mt={hideImage && 9}>
      {!hideImage && (
        <Row>
          <Col w={[4, 0, 0]} style={{ marginBottom: "40px" }}>
            <Image image="galleryLarge" alt="gallery" />
          </Col>
        </Row>
      )}
      <Row jcM="center">
        {isTablet ? (
          <Col w={[4, 8, 8]} style={{ marginBottom: 20 }}>
            <T.H30Caps
              color="midnight"
              style={{
                textTransform: "uppercase",
                paddingTop: 30,
              }}
            >
              {translate("turnUpWithID", lang)}
            </T.H30Caps>
            <T.H16 color="midnight">({translate("noNeedToBook", lang)})</T.H16>
          </Col>
        ) : (
          <Col w={[4, 8, 8]} style={{ marginBottom: 40 }}>
            <T.H40Caps color="midnight" mb="10">
              {translate("turnUpWithID", lang)}
            </T.H40Caps>
            <T.H16 color="midnight">({translate("noNeedToBook", lang)})</T.H16>
          </Col>
        )}
      </Row>
    </S.Header>
    <Row mb={!hideImage && 0}>
      <Col w={[4, 8, 8]} style={{ marginBottom: isTablet ? "20px" : "80px" }}>
        <S.ListWarapper>
          <GeneralList
            title={translate("arriveAtVenue", lang)}
            subtitle={`(${translate("checkAvailability", lang)})`}
          />
          <GeneralList
            title={translate("tellThemActivity", lang)}
            graphic="graphic2"
          />
          <GeneralList
            graphic="graphic3"
            customTitleElem={
              <T.Body18S color="midnight">
                {translate("giveID", lang)}
                {memberID && ": "}
                {memberID && (
                  <span style={{ color: theme.colors.pink, fontWeight: 900 }}>
                    {memberID}
                  </span>
                )}
              </T.Body18S>
            }
          />
          <GeneralList
            title={`${translate("automaticallyDeducted", lang)}. ${translate(
              "haveFun",
              lang,
            )}!`}
            graphic="graphic4"
          />
        </S.ListWarapper>
      </Col>
      {!hideImage && (
        <Col w={[0, 4, 4]}>
          <Image image="galleryLarge" alt="gallery" />
        </Col>
      )}
    </Row>
    {!isLoggedIn && <CreateAccount lang={lang} />}
  </>
);

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  memberID: state.auth.profile.membershipId,
});

export default connect(mapStateToProps, null)(Content);
