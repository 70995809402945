import {
  GET_SPEND_VENUE_PROFILE_ADMIN_LOADING_START,
  GET_SPEND_VENUE_PROFILE_ADMIN_LOADING_END,
  GET_SPEND_VENUE_PROFILE_ADMIN_SUCCESS,
  GET_SPEND_VENUE_PROFILE_ADMIN_FAIL,
  GET_SPEND_PARTNERS_BASIC_ADMIN_LOADING_START,
  GET_SPEND_PARTNERS_BASIC_ADMIN_LOADING_END,
  GET_SPEND_PARTNERS_BASIC_ADMIN_SUCCESS,
  GET_SPEND_PARTNERS_BASIC_ADMIN_FAIL,
  UPDATE_SPEND_VENUE_PROFILE_ADMIN_LOADING_START,
  UPDATE_SPEND_VENUE_PROFILE_ADMIN_LOADING_END,
  UPDATE_SPEND_VENUE_PROFILE_ADMIN_SUCCESS,
  UPDATE_SPEND_VENUE_PROFILE_ADMIN_FAIL,
  RESET_SPEND_VENUE_PROFILE_ADMIN,
  GET_SPEND_PARTNER_LOADING_START,
  GET_SPEND_PARTNER_LOADING_END,
  GET_SPEND_PARTNER_SUCCESS,
  GET_SPEND_PARTNER_FAIL,
} from "../../../redux/types";

const initialState = {
  spendVenue: {},
  getSpendVenueLoading: false,
  getSpendVenueError: {},

  spendPartners: [],
  getSpendPartnersLoading: false,
  getSpendPartnersError: {},

  loaded: false,
  updateSpendVenueLoading: false,
  updateSpendVenueError: {},
  createdSpendVenue: {},
  updatedSuccessfully: false,

  spendPartner: {},
};

const spendVenue = (state = initialState, action) => {
  switch (action.type) {
    case GET_SPEND_VENUE_PROFILE_ADMIN_LOADING_START:
      return {
        ...state,
        getSpendVenueLoading: true,
        spendVenue: {},
        updatedSuccessfully: false,
      };
    case GET_SPEND_VENUE_PROFILE_ADMIN_LOADING_END:
      return { ...state, getSpendVenueLoading: false };
    case GET_SPEND_VENUE_PROFILE_ADMIN_SUCCESS:
      return {
        ...state,
        spendVenue: action.payload,
        loaded: true,
      };
    case GET_SPEND_VENUE_PROFILE_ADMIN_FAIL:
      return { ...state, updateSpendVenueError: action.payload };

    case GET_SPEND_PARTNERS_BASIC_ADMIN_LOADING_START:
      return { ...state, getSpendPartnersLoading: true };
    case GET_SPEND_PARTNERS_BASIC_ADMIN_LOADING_END:
      return { ...state, getSpendPartnersLoading: false };
    case GET_SPEND_PARTNERS_BASIC_ADMIN_SUCCESS:
      return {
        ...state,
        spendPartners: action.payload,
      };
    case GET_SPEND_PARTNERS_BASIC_ADMIN_FAIL:
      return { ...state, getSpendPartnersError: action.payload };

    case UPDATE_SPEND_VENUE_PROFILE_ADMIN_LOADING_START:
      return {
        ...state,
        updateSpendVenueLoading: true,
        updateSpendVenueError: {},
        createdSpendVenue: {},
        updatedSuccessfully: false,
      };
    case UPDATE_SPEND_VENUE_PROFILE_ADMIN_LOADING_END:
      return { ...state, updateSpendVenueLoading: false };
    case UPDATE_SPEND_VENUE_PROFILE_ADMIN_SUCCESS:
      return {
        ...state,
        createdSpendVenue: action.payload,
        updatedSuccessfully: true,
      };
    case UPDATE_SPEND_VENUE_PROFILE_ADMIN_FAIL:
      return {
        ...state,
        updateSpendVenueError: action.payload,
        updatedSuccessfully: false,
      };

    case GET_SPEND_PARTNER_LOADING_START:
      return {
        ...state,
        spendPartnerLoading: true,
        spendPartner: {},
      };
    case GET_SPEND_PARTNER_LOADING_END:
      return { ...state, spendPartnerLoading: false };
    case GET_SPEND_PARTNER_SUCCESS:
      return {
        ...state,
        spendPartner: action.payload,
      };
    case GET_SPEND_PARTNER_FAIL:
      return { ...state, spendPartnerError: action.payload, spendPartner: {} };

    case RESET_SPEND_VENUE_PROFILE_ADMIN:
      return { ...state, ...initialState };

    default:
      return state;
  }
};

export default spendVenue;
