import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import * as S from "./style";
import * as T from "../../../components/Typograpy";
import { PROGRAMME_MANAGER } from "../../../constants/navRoutes";
import Table from "../../../components/Table";
import Dropdown from "../../../components/Inputs/Dropdown";
import GoBack from "../../../components/GoBack";

import TCBalance from "../../../components/TCBalance";

import * as actions from "../../StaffRoutes/Staff/CreditRequests/actions";

import {
  creditResponseCol,
  customDateCol,
} from "../../../components/Table/config/reusableColumns";

const CreditRequests = ({
  programmeId,
  getPendingCreditsRequests,
  getPreviousCreditsRequests,
  selectCreditsRequest,
  pendingRequests,
  pendingRequestsLoaded,
  prevRequests,
  prevRequestsLoaded,
  balance,
  // TODO error handling
  // prevError,
  // prevLoading,
}) => {
  const history = useHistory();

  const decideRoute = (selected, requestID) => {
    switch (selected.value) {
      case "approve":
        return history.push(
          PROGRAMME_MANAGER.REQUEST_APPROVE.replace(":requestId", requestID),
        );
      case "partialApprove":
        return history.push(
          PROGRAMME_MANAGER.REQUEST_PARTIAL.replace(":requestId", requestID),
        );
      case "reject":
        return history.push(
          PROGRAMME_MANAGER.REQUEST_REJECT.replace(":requestId", requestID),
        );
      default:
        return null;
    }
  };

  const onSelectAction = (selected, selectedRequest) => {
    selectCreditsRequest(selectedRequest);
    decideRoute(selected, selectedRequest.id);
  };

  const activeRequestCols = [
    { title: "Earn Group", field: "earnGroup" },
    { title: "Balance", field: "earnGroupBalance", type: "numeric" },
    {
      title: "Time Credits Requested",
      field: "creditsRequested",
      type: "numeric",
    },
    customDateCol({ title: "Request Date", field: "date" }),
    {
      title: "Action",
      field: "action",
      disableClick: true,
      render: (rowData) => (
        <Dropdown
          placeholder="Select..."
          options={[
            { value: "approve", label: "Approve" },
            { value: "partialApprove", label: "Partially Approve" },
            { value: "reject", label: "Reject" },
          ]}
          customSelectFunc={(e) => onSelectAction(e, rowData)}
          size="small"
        />
      ),
    },
  ];

  const oldRequestCols = [
    { title: "Earn Group", field: "earnGroup" },
    {
      title: "Time Credits Requested",
      field: "creditsRequested",
      type: "numeric",
    },
    { title: "Time Credits Issued", field: "creditsIssued", type: "numeric" },
    customDateCol({ title: "Request Date", field: "date" }),
    customDateCol(
      { title: "Issue Date", field: "responseDate" },
      "responseDate",
    ),
    creditResponseCol,
    { title: "By", field: "by" },
  ];

  const cleanRequests = (requests) => {
    const updatedRequests = requests.map((request) => ({
      ...request,
      earnGroupBalance: request.earnGroupBalance || 0,
    }));
    return updatedRequests;
  };

  useEffect(() => {
    getPendingCreditsRequests(programmeId);
    getPreviousCreditsRequests(programmeId);
  }, [getPendingCreditsRequests, getPreviousCreditsRequests, programmeId]);

  return (
    <S.Wrapper>
      <S.Header>
        <div>
          <GoBack showText p="0 0 40px 0" />
          <T.H40Caps color="midnight" m="0">
            TIME CREDIT REQUESTS
          </T.H40Caps>
        </div>
        <TCBalance credits={balance} color="cyan" />
      </S.Header>
      <T.H24Caps color="midnight" mb={20}>
        Time Credit Requests
      </T.H24Caps>
      <Table
        color="blue"
        limit={5}
        data={cleanRequests(pendingRequests)}
        columns={activeRequestCols}
        isLoading={!pendingRequestsLoaded}
        hideSearch
      />
      <T.H24Caps color="gray3" mb={20} mt={80}>
        PREVIOUS REQUESTS
      </T.H24Caps>
      {/* TODO handle loading */}
      <Table
        color="blue"
        limit={5}
        data={prevRequests}
        columns={oldRequestCols}
        isLoading={!prevRequestsLoaded}
        hideSearch
      />
    </S.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  pendingError: state.staffCreditRequests.pendingError,
  pendingLoading: state.staffCreditRequests.pendingLoading,
  pendingRequests: state.staffCreditRequests.pendingRequests,
  pendingRequestsLoaded: state.staffCreditRequests.pendingRequestsLoaded,

  prevError: state.staffCreditRequests.prevError,
  prevLoading: state.staffCreditRequests.prevLoading,
  prevRequests: state.staffCreditRequests.prevRequests,
  prevRequestsLoaded: state.staffCreditRequests.prevRequestsLoaded,
  programmeId: state.auth.programmeId,
  balance: state.user.tc.tcBalance,
});
const mapActionToProps = {
  getPendingCreditsRequests: actions.getPendingCreditsRequests,
  getPreviousCreditsRequests: actions.getPreviousCreditsRequests,
  selectCreditsRequest: actions.selectCreditsRequest,
};

export default connect(mapStateToProps, mapActionToProps)(CreditRequests);
