import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { getSpendVenueProfile, getSpendVenueActivities } from "../actions";

import * as S from "../../style";
import { GENERAL, TEMPO } from "../../../../../constants/navRoutes";

import GoBack from "../../../../../components/GoBack";
import { Regular } from "../../../../../components/Button";
import { Body16R } from "../../../../../components/Typograpy";
import Loading from "../../../../../components/Loading";
import { Row, Col } from "../../../../../components/Grid";

import userRoles, { tempoRoles } from "../../../../../constants/roles";
import { LANGUAGE_ERROR } from "../../../../../validation/err-msgs";
import t from "../../../../../utils/helpers/translator";

import Content from "./Content";

const SpendVenueProfile = ({
  isTablet,
  getSpendVenueProfileAction,
  getSpendVenueActivitiesAction,
  spendVenueProfileLoading,
  spendVenueProfile,
  spendVenueActivities,
  spendVenueActivitiesLoading,
  userRole,
  language,
}) => {
  const { id: spendVenueId } = useParams();
  const [profileData, setProfileData] = useState(null);

  const history = useHistory();

  const allowedRoles = Object.values(tempoRoles);

  // manage welsh data of spend venue
  const {
    welshFields: {
      addressLine1: addressLine1Welsh,
      addressLine2: addressLine2Welsh,
      city: cityWelsh,
      description: descriptionWelsh,
      name: nameWelsh,
      earnGroupAddresses: earnGroupAddressesWelsh,
    } = {},
    spendVenueAddress = {},
  } = spendVenueProfile;

  const spendVenueProfileWelsh = {
    ...spendVenueProfile,
    name: nameWelsh,
    description: descriptionWelsh,
    spendVenueAddress: {
      addressLine1: addressLine1Welsh,
      addressLine2: addressLine2Welsh,
      city: cityWelsh,
      postcode: spendVenueAddress.postcode,
    },
    earnGroupAddresses: earnGroupAddressesWelsh,
  };

  useEffect(() => {
    if (spendVenueId) {
      if (Number(spendVenueId)) {
        getSpendVenueProfileAction({ spendVenueId, isPublic: true });
        getSpendVenueActivitiesAction({ spendVenueId, isPublic: true });
      } else {
        history.push("/404");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const welshVersionExists = spendVenueProfile && spendVenueProfile.welshFields;
  const flowLanguage =
    welshVersionExists && language === "welsh" ? "welsh" : "english";
  useEffect(() => {
    if (welshVersionExists && language === "welsh") {
      setProfileData({ ...spendVenueProfileWelsh, languageError: null });
    } else if (!welshVersionExists && language === "welsh") {
      setProfileData({
        ...spendVenueProfile,
        languageError: t(LANGUAGE_ERROR, language),
      });
    } else {
      setProfileData({ ...spendVenueProfile, languageError: null });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, spendVenueProfile]);

  const { cover } = spendVenueProfile;

  if (spendVenueProfileLoading || spendVenueActivitiesLoading || !profileData) {
    return <Loading fullPage navbar />;
  }

  return (
    <S.Wrapper lessPadding>
      {isTablet && (
        <Row mb={3}>
          <Col w={[2, 6, 8]}>
            <GoBack
              showText
              onClick={
                history.length === 1 ? () => history.push(GENERAL.SPEND) : null
              }
              lang={flowLanguage}
            />
          </Col>
        </Row>
      )}
      <S.ImageWrapper img={cover && cover.imageURL} />
      {!isTablet && (
        <Row mb={6}>
          <Col w={[2, 9, 8]}>
            <GoBack
              showText
              onClick={
                history.length === 1 ? () => history.push(GENERAL.SPEND) : null
              }
              lang={flowLanguage}
            />
          </Col>
        </Row>
      )}
      {allowedRoles.includes(userRole) && (
        <S.EditButtonWrapper>
          <Regular
            bgColor="pink"
            customWidth="200px"
            onClick={() => {
              if (spendVenueProfile.role === userRoles.EARN_GROUP) {
                history.push(
                  TEMPO.EDIT_EARN_GROUP_PROFILE.replace(":id", spendVenueId),
                );
              } else {
                history.push(
                  TEMPO.EDIT_SPEND_VENUE.replace(":spendVenueId", spendVenueId),
                );
              }
            }}
          >
            Edit Venue
          </Regular>
        </S.EditButtonWrapper>
      )}
      {profileData.languageError && (
        <Body16R color="pink" style={{ marginBottom: 40 }}>
          {profileData.languageError}
        </Body16R>
      )}
      <Content
        lang={flowLanguage}
        spendVenueProfile={profileData}
        spendVenueActivities={spendVenueActivities}
        isTablet={isTablet}
        welshVersion={welshVersionExists && spendVenueProfile.welshFields}
      />
    </S.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  spendVenueProfile: state.venueGroupProfile.spendVenueProfileData,
  spendVenueProfileLoading: state.venueGroupProfile.getSpendVenueLoading,
  spendVenueActivities: state.venueGroupProfile.activitiesData,
  spendVenueActivitiesLoading:
    state.venueGroupProfile.getSpendActivitiesLoading,
  userRole: state.auth.role,
  language: state.auth.decideLanguage(state.auth),
});

const mapActionToProps = {
  getSpendVenueProfileAction: getSpendVenueProfile,
  getSpendVenueActivitiesAction: getSpendVenueActivities,
};

export default connect(mapStateToProps, mapActionToProps)(SpendVenueProfile);
