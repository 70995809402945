import React from "react";

const ProfessionalSkills = ({ width, height, color, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    {...props}
  >
    <path
      d="M22.5 11H22V1.5A1.5 1.5 0 0020.5 0h-19A1.5 1.5 0 000 1.5v13A1.5 1.5 0 001.5 16h5v4H6a.5.5 0 000 1h7v1.5a1.5 1.5 0 001.5 1.5h8a1.5 1.5 0 001.5-1.5v-10a1.5 1.5 0 00-1.5-1.5zM1 1.5a.5.5 0 01.5-.5h19a.5.5 0 01.5.5V11h-6.5a1.5 1.5 0 00-1.41 1H1V1.5zm0 13V13h12v2H1.5a.5.5 0 01-.5-.5zM7.5 20v-4H13v4H7.5zM23 22.5a.5.5 0 01-.5.5h-8a.5.5 0 01-.5-.5v-10a.5.5 0 01.5-.5h8a.5.5 0 01.5.5v10z"
      fill={color || "currentColor"}
    />
    <path
      d="M21.5 13h-6a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5zm-.5 2h-5v-1h5v1zM16.5 17h-1a.5.5 0 000 1h1a.5.5 0 000-1zM19 17h-1a.5.5 0 000 1h1a.5.5 0 000-1zM21.5 17h-1a.5.5 0 000 1h1a.5.5 0 000-1zM16.5 19h-1a.5.5 0 000 1h1a.5.5 0 000-1zM19 19h-1a.5.5 0 000 1h1a.5.5 0 000-1zM16.5 21h-1a.5.5 0 000 1h1a.5.5 0 000-1zM19 21h-1a.5.5 0 000 1h1a.5.5 0 000-1zM21.5 19h-1a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5zM11.5 9v-.09A1.5 1.5 0 0011 6a.5.5 0 11.5-.5.5.5 0 001 0 1.5 1.5 0 00-1-1.41V4a.5.5 0 10-1 0v.09A1.5 1.5 0 0011 7a.5.5 0 11-.5.5.5.5 0 10-1 0 1.5 1.5 0 001 1.41V9a.5.5 0 001 0z"
      fill={color || "currentColor"}
    />
    <path
      d="M15.5 6.5a4.5 4.5 0 10-9 0 4.5 4.5 0 009 0zm-8 0a3.5 3.5 0 117 0 3.5 3.5 0 01-7 0zM11 14.5a.5.5 0 100-1 .5.5 0 000 1z"
      fill={color || "currentColor"}
    />
  </svg>
);
export default ProfessionalSkills;
