import React from "react";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import * as S from "../style";
import * as T from "../../../../../../components/Typograpy";
import { Row, Col } from "../../../../../../components/Grid";
import Image from "../../../../../../components/Image";
import { Counter } from "../../../../../../components/Inputs";
import TimeCreditCost from "../../../../../../components/TimeCreditCost";
import { Regular } from "../../../../../../components/Button";

import translate from "../../../../../../utils/helpers/translator";

const Content = ({
  isTablet,
  errors,
  childrenParticipants,
  handleCounter,
  price,
  onSubmit,
  isDisabled,
  purchaseBookingCodesLoading,
  adultsParticipants,
  lang,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <S.Header>
        <Row>
          <Col w={[4, 0, 0]}>
            <Image image="officeLarge" alt="office" />
          </Col>
        </Row>
        <Row mb={7}>
          <Col w={[4, 8, 8]}>
            {isTablet ? (
              <T.H30Caps color="midnight" mt="20">
                {translate("getAccessCodeOnline", lang)}
              </T.H30Caps>
            ) : (
              <T.H40Caps color="midnight">
                {translate("getAccessCodeOnline", lang)}
              </T.H40Caps>
            )}
          </Col>
        </Row>
      </S.Header>
      <Row>
        <Col w={[4, 8, 8]}>
          <T.H20 color="midnight" mt="20" mb="40">
            {translate("numberOfPeople", lang)}
          </T.H20>
          <S.CountersWrapper>
            <Col w={[4, 6, 6]} style={{ marginBottom: isMobile && "30px" }}>
              <Counter
                label={translate("adults", lang)}
                subLabel={`(${translate("over16", lang)})`}
                m="0"
                counterWidth="100%"
                value={adultsParticipants.counter}
                setValue={(value) => handleCounter(value, "adults")}
                error={errors.adultsParticipants}
              />
            </Col>
            <Col w={[4, 6, 6]}>
              <Counter
                label={translate("children", lang)}
                subLabel={`(${translate("under16", lang)})`}
                m="0"
                counterWidth="100%"
                value={childrenParticipants.counter}
                setValue={(value) => handleCounter(value, "children")}
                error={errors.childrenParticipants}
              />
            </Col>
          </S.CountersWrapper>
          <T.Body16R color="gray3" mb="40">
            {translate("clickGetAccess", lang)}
          </T.Body16R>
          {price > 0 && <TimeCreditCost cost={price} m="40px 0" lang={lang} />}
        </Col>
        <Col w={[0, 4, 4]}>
          <Image image="officeLarge" alt="office" />
        </Col>
      </Row>
      <Row mb={3}>
        <Col w={[4, 4, 4]}>
          {isDisabled || errors.preSubmitError ? (
            <Regular bgColor="gray5" size="l" disabled>
              {translate("getCode", lang)}
            </Regular>
          ) : (
            <Regular
              primary
              bgColor="blue"
              size="l"
              onClick={onSubmit}
              loading={purchaseBookingCodesLoading}
            >
              {translate("getCode", lang)}
            </Regular>
          )}
        </Col>
      </Row>
      {errors.preSubmitError && (
        <Row>
          <Col w={[4, 8, 8]}>
            <T.Body16R color="pink">
              {translate(
                errors.preSubmitError.msg,
                lang,
                errors.preSubmitError.customData,
              )}
            </T.Body16R>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Content;
