import axios from "axios";
import * as activities from "./activities";
import * as auth from "./auth";
import * as creditsRequests from "./credits-requests";
import * as csv from "./csv";
import * as earnGroups from "./earn-groups";
import * as gifts from "./gifts";
import * as groups from "./groups";
import * as insights from "./insights";
import * as members from "./members";
import * as spendVenues from "./spend-venues";
import * as staffPermissions from "./staff-permissions";
import * as transactions from "./transactions";
import * as users from "./users";
import * as images from "./images";
import * as programmes from "./programmes";
import * as spendPartners from "./spend-partners";
import * as paidPackages from "./paid-packages";
import * as tkGroups from "./tk-groups";

axios.defaults.baseURL = "/api";

export {
  activities,
  auth,
  creditsRequests,
  csv,
  earnGroups,
  gifts,
  groups,
  insights,
  members,
  spendVenues,
  staffPermissions,
  transactions,
  users,
  images,
  programmes,
  spendPartners,
  paidPackages,
  tkGroups,
};
