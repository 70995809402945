import {
  RESET_PASSWORD_LINK_SUCCESS,
  RESET_PASSWORD_LINK_FAIL,
  RESET_PASSWORD_LINK_LOADING_START,
  RESET_PASSWORD_LINK_LOADING_END,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_FAIL,
  SET_PASSWORD_LOADING_START,
  SET_PASSWORD_LOADING_END,
  RESET_FORGET_PASSWORD_STATE,
} from "../../../redux/types";

import { auth } from "../../../api-calls";

export const resetPasswordLink = (email) => async (dispatch) => {
  dispatch({ type: RESET_PASSWORD_LINK_LOADING_START });
  try {
    const { data, error } = await auth.sendResetPasswordLink({
      email,
    });
    if (error) {
      dispatch({
        type: RESET_PASSWORD_LINK_FAIL,
        payload: error,
      });
    } else {
      dispatch({
        type: RESET_PASSWORD_LINK_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_LINK_LOADING_END,
      payload: error,
    });
  }
  dispatch({ type: RESET_PASSWORD_LINK_LOADING_END });
};

export const setPassword =
  ({ token, password }) =>
  async (dispatch) => {
    dispatch({ type: SET_PASSWORD_LOADING_START });
    try {
      const { data, error } = await auth.setPassword({
        token,
        password,
      });
      if (error) {
        dispatch({
          type: SET_PASSWORD_FAIL,
          payload: error,
        });
      } else {
        dispatch({
          type: SET_PASSWORD_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      dispatch({
        type: SET_PASSWORD_LOADING_END,
        payload: error,
      });
    } finally {
      dispatch({ type: SET_PASSWORD_LOADING_END });
    }
  };

export const resetState = () => ({
  type: RESET_FORGET_PASSWORD_STATE,
});
