import { styled } from "@material-ui/core/styles";

const decideColor = (index, theme) => {
  const { gradients } = theme;
  switch (0) {
    case index - 1:
      return gradients.cyan;
    case index - 2:
      return gradients.pink;
    case index - 3:
      return gradients.blue;
    case index % 5:
      return gradients.cyan;
    case index % 6:
      return gradients.pink;
    case index % 7:
      return gradients.blue;
    default:
      return gradients.purple;
  }
};

export const ButtonWrapper = styled("button")(
  ({ theme, m, selected, noBackground }) => ({
    color: theme.colors.white,
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: "25px 15px",
    width: "300px",
    margin: m || "0",
    marginRight: "0",
    marginLeft: "0",
    outline: "none",
    background: noBackground ? "none" : "white",
    boxShadow: noBackground ? "none" : theme.customShadows.card,
    // eslint-disable-next-line no-nested-ternary
    border: selected
      ? `3px solid ${theme.colors.purple}`
      : noBackground
      ? "none"
      : `1px solid ${theme.colors.cyanMed}`,

    "&:hover": {
      boxShadow: noBackground
        ? theme.customShadows.card
        : theme.customShadows.cardHover,
    },

    [theme.breakpoints.mobileM]: {
      margin: m || "0",
    },
  }),
);

export const Wrapper = styled("div")(({ theme, m, noBackground }) => ({
  color: theme.colors.white,
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: "15px 0",
  marginRight: "0",
  marginLeft: "0",
  margin: m || "0",
  outline: "none",
  background: noBackground ? "none" : "white",
  boxShadow: noBackground ? "none" : theme.customShadows.card,

  [theme.breakpoints.mobileM]: {
    margin: m || "0",
  },
}));

export const IconWrapper = styled("span")(({ theme, index }) => ({
  width: "50px",
  height: "50px",
  borderRadius: "50%",
  background: index ? decideColor(index, theme) : theme.gradients.purple,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "15px",
}));

export const Body = styled("span")(() => ({
  maxWidth: "230px",
  textAlign: "left",
  wordWrap: "break-word !important",
  width: "80%",
  overflowWrap: "break-word",
  "-ms-word-break": "break-all",
  wordBreak: "break-word",
}));

export const SpendWrapper = styled("button")(({ theme, m, customWidth }) => ({
  cursor: "pointer",
  textAlign: "left",
  padding: "0",
  width: customWidth || "100%",
  margin: m || "0",
  outline: "none",
  border: "none",
  borderRadius: theme.borders.radius,
  boxShadow: theme.customShadows.card,
  minHeight: "150px",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  background: "none",
  transition: "0.5s transform",

  "&:hover": {
    boxShadow: theme.customShadows.cardHover,
  },

  "&:active": {
    boxShadow: theme.customShadows.cardClick,
  },
}));

export const Header = styled("span")(() => ({
  padding: "20px 15px",
  minHeight: "100px",
}));

export const Bottom = styled("span")(({ theme, selected, customWidth }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  background: selected ? theme.gradients.pink : theme.gradients.blue,
  width: customWidth || "100%",
  padding: "15px",
  borderRadius: `0 0 ${theme.borders.radius} ${theme.borders.radius}`,
}));

export const SpendVenueListWrapper = styled("button")(
  ({ theme, customWidth, m }) => ({
    width: customWidth || "100%",
    minHeight: "70px",
    justifyContent: "space-between",
    boxShadow: theme.customShadows.card,
    border: "none",
    borderRadius: theme.borders.radiusSmall,
    outline: "none",
    cursor: "pointer",
    background: theme.gradients.blue,
    padding: "0",
    margin: m || "0",

    "&:hover": {
      boxShadow: theme.customShadows.cardHover,
    },

    "&:active": {
      boxShadow: theme.customShadows.cardClick,
    },

    [theme.breakpoints.mobileXL]: {
      display: "flex",
      flexDirection: "row",
      padding: "3px",
    },
  }),
);

export const Price = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  height: "100%",
  minHeight: "70px",
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  borderRadius: `0 ${theme.borders.radiusSmall} ${theme.borders.radiusSmall} 0`,

  [theme.breakpoints.mobileXL]: {
    flex: "1",
    padding: "0 10px",
    justifyContent: "space-around",
    width: "auto",
  },
}));

export const Details = styled("span")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  height: "100%",
  minHeight: "90px",
  width: "100%",
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(1),
  background: "white",
  border: "none",
  borderRadius: `${theme.borders.radiusSmall} ${theme.borders.radiusSmall} 0 0`,

  [theme.breakpoints.mobileXL]: {
    width: "60%",
    borderRadius: `calc(${theme.borders.radiusSmall} / 2)`,
    minHeight: "70px",
  },
}));

export const RestrictionLayer = styled("div")(({ restriction }) => {
  const display = restriction ? "block" : "none";
  return {
    display,
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
  };
});

export const RestrictionTag = styled("div")(({ theme }) => ({
  position: "absolute",
  background: theme.colors.pink,
  right: 0,
  top: 24,
  maxWidth: 64,
  padding: "4px 12px",

  borderTopLeftRadius: 5,
  borderBottomLeftRadius: 5,
}));
