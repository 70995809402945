import React from "react";

import Modal from "../../../../components/Modal";
import * as T from "../../../../components/Typograpy";

const RemoveModal = ({
  modalOpen,
  confirmFun,
  handleCloseModal,
  name,
  programmeName,
}) => (
  <Modal
    modalOpen={modalOpen}
    handleCloseModal={handleCloseModal}
    confirmFun={confirmFun}
    buttonText="Confirm"
    withCancel
    width="640px"
  >
    <T.H30 color="blue" m="0" mb="25">
      Are you sure?
    </T.H30>
    <T.Body16R>
      <T.Body16B style={{ display: "inline" }}>{name}</T.Body16B>’s earn
      activity will no longer count towards{" "}
      <T.Body16B style={{ display: "inline" }}>{programmeName}</T.Body16B> from
      this date onwards
    </T.Body16R>
  </Modal>
);

export default RemoveModal;
