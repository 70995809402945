import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import * as S from "./style";
import Image from "../../../../components/Image";
import * as T from "../../../../components/Typograpy";
import Stats from "../../../../components/Stats";
import Table from "../../../../components/FilterTable";
import PopQuestion from "../../../../components/PopQuestion";
import Button from "../../../../components/Button";
import Search from "../../../../components/Search";
import DownloadFile from "../../../../components/DownloadFile";

import { TEMPO } from "../../../../constants/navRoutes";
import { tempoRoles } from "../../../../constants/roles";
import FilterByDate from "../../../../components/FilterByDate";
import Modal from "../../../../components/Modal";

import {
  customDateCol,
  memberCol,
  lastActiveCol,
} from "../../../../components/Table/config/reusableColumns";

import { getInsights } from "../insights.action";
import { getMembers } from "./actions";

const tempoMembers = {
  columns: [
    memberCol,
    customDateCol({ title: "Joined" }),
    { title: "Balance", field: "balance", type: "numeric" },
    { title: "Earned", field: "earned", type: "numeric" },
    {
      title: (
        <div style={{ position: "relative" }}>
          %Used
          <PopQuestion>
            <T.Body16R color="gray3">
              the % of Time Credits that a volunteer has been issued, gifted or
              earned that a volunteer has used (with Earn Groups or Venues)
            </T.Body16R>
          </PopQuestion>
        </div>
      ),
      customSort: (a, b) =>
        Number(a.spentPercentage?.replace("%", "")) -
        Number(b.spentPercentage?.replace("%", "")),
      field: "spentPercentage",
    },
    {
      title: (
        <div style={{ position: "relative" }}>
          %Gifted
          <PopQuestion>
            <T.Body16R color="gray3">
              the % of Time Credits that a volunteer has been issued, gifted or
              earned that a volunteer has gifted to another volunteer
            </T.Body16R>
          </PopQuestion>
        </div>
      ),
      customSort: (a, b) =>
        Number(a.giftedPercentage?.replace("%", "")) -
        Number(b.giftedPercentage?.replace("%", "")),
      field: "giftedPercentage",
    },
    lastActiveCol,
    {
      title: (
        <div style={{ position: "relative" }}>
          Programme
          <PopQuestion>
            <T.Body16R color="gray3">
              the programme that a volunteer has earned the most Time Credits
              with
            </T.Body16R>
          </PopQuestion>
        </div>
      ),
      field: "programme",
    },
  ],
};

const StaffMembers = ({
  members,
  totalMembers,
  // totalIncome,
  earnedTc,
  spentTc,
  getInsightsAction,
  getMembersAction,
  insightsLoading,
  role,
}) => {
  const history = useHistory();
  const [dates, setDates] = useState({ startDate: null, endDate: null });
  const startDateValue = dates?.startDate?.valueOf();
  const endDateValue = dates?.endDate?.valueOf();
  const [filterDates, setFilterDates] = useState({
    startDate: null,
    endDate: null,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    getInsightsAction({ category: "members" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!isOpen) {
      getMembersAction({
        query: search,
        startDate: dates.startDate,
        endDate: dates.endDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateValue, endDateValue]);

  const clickRow = (event, rowData) => {
    history.push(TEMPO.MEMBERS_SINGLE.replace(":id", rowData.userId));
  };

  const handleSearch = (e) => {
    e.preventDefault();

    getMembersAction({
      query: search,
      startDate: dates.startDate,
      endDate: dates.endDate,
    });
  };

  const statsNum = [
    totalMembers,
    // totalIncome,
    earnedTc,
    spentTc,
  ];

  const statsBottomMessage = [
    "all volunteers signed up to use Tempo Time Credits",
    false,
    false,
  ];
  const statsText = [
    "total volunteers",
    "Time credits earned",
    "Time credits used",
  ];

  return (
    <>
      <S.Container>
        <div>
          <S.Head>
            <S.HeadContent>
              <T.H40Caps color="primary" style={{ margin: 0 }}>
                Tempo Volunteers
              </T.H40Caps>
              <div style={{ display: "flex" }}>
                {statsText.map((e, i) => (
                  <div key={e} style={{ marginTop: 50, marginRight: 15 }}>
                    <Stats
                      size="s"
                      statNum={statsNum[i]}
                      statNumColor="pink"
                      statText={e}
                      isLoading={insightsLoading}
                      bottomMessage={statsBottomMessage[i]}
                    />
                  </div>
                ))}
              </div>
            </S.HeadContent>
            <S.ImageWrapper>
              <Image image="group" alt="group" height={210} width={220} />
            </S.ImageWrapper>
          </S.Head>
          <S.TableContainer>
            <S.BtnContainer>
              {role !== tempoRoles.TEMPO_STAFF_USER && (
                <>
                  <DownloadFile
                    url="/csv/members?type=demographics"
                    buttonProps={{
                      outline: true,
                      mb: "10",
                      mr: "0",
                      style: { marginLeft: "7px", width: "230px" },
                    }}
                    text="Export Demographics"
                  />
                  <DownloadFile
                    url="/csv/members?type=contacts"
                    buttonProps={{
                      outline: true,
                      mb: "10",
                      mr: "0",
                      style: { marginLeft: "7px", width: "170px" },
                    }}
                    text="Export Contacts"
                  />
                </>
              )}

              <S.CSVExport>
                <Button outline mr="0" mb={10} onClick={() => setIsOpen(true)}>
                  Export Activity
                </Button>
              </S.CSVExport>
            </S.BtnContainer>
            <S.FilterContainer>
              <T.Body16B color="midnight" m="8">
                Filter by join date
              </T.Body16B>
              <FilterByDate dates={dates} setDates={setDates} />
              <T.Body16B color="midnight" m="8">
                Search
              </T.Body16B>
              <S.SearchWrapper>
                <Search
                  handleSearch={handleSearch}
                  search={search}
                  setSearch={setSearch}
                  loading={members.loading}
                />
              </S.SearchWrapper>
            </S.FilterContainer>
            {members.loading && (
              <Table
                color="blue"
                data={members.data}
                columns={tempoMembers.columns}
                onRowClick={clickRow}
                flexDir="column"
                isLoading
                hideSearch
              />
            )}
            {!members.loading && (
              <Table
                color="blue"
                data={members.data}
                columns={tempoMembers.columns}
                onRowClick={clickRow}
                flexDir="column"
                hideSearch
              />
            )}
          </S.TableContainer>
        </div>
      </S.Container>
      <Modal
        modalOpen={isOpen}
        handleCloseModal={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
        custom
        width="690px"
      >
        <S.ModalContent>
          <T.H20 color="blue" mb={20}>
            Export data{" "}
          </T.H20>

          <T.Body16B color="midnight" m="8">
            Filter by joining date
          </T.Body16B>
          <FilterByDate dates={filterDates} setDates={setFilterDates} />
          <DownloadFile
            url={`/csv/members?type=activity&startDate=${filterDates?.startDate?.format(
              "YYYY-MM-DD",
            )}&endDate=${filterDates?.endDate?.format("YYYY-MM-DD")}`}
          />
        </S.ModalContent>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state.staffInsights,
  members: state.staffMembers,
  membersLoading: state.staffMembers.loading,
  insightsLoading: state.staffMembers.loading,
  role: state.auth.role,
});

const mapActionsToProps = {
  getInsightsAction: getInsights,
  getMembersAction: getMembers,
};

export default connect(mapStateToProps, mapActionsToProps)(StaffMembers);
