import { styled } from "@material-ui/core/styles";

export const Wrapper = styled("div")(({ theme, type }) => ({
  padding: theme.spacing(1),
  display: "flex",
  flexDirection: type === "large" && "column",
  justifyContent: type === "large" && "center",
  alignItems: type === "small" && "center",
  boxShadow: theme.customShadows.card,
  borderRadius: "4px",
  maxWidth: 475,
}));

export const Header = styled("header")(({ theme, type }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: type === "small" ? theme.spacing(1) : 0,
}));
