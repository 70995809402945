import React from "react";
import { connect } from "react-redux";
import { Switch } from "react-router-dom";

// CONSTANTS
import { GENERAL, MEMBER } from "../../constants/navRoutes";
import { roles } from "../../constants";

// ERROR COMPONENTS
import ErrorPage from "./ErrorPages";

// COMMON
import Route from "../../components/Route";

// HELPERS
import t from "../../utils/helpers/translator";

const ErrorRouter = ({ language }) => (
  <Switch>
    <Route
      exact
      path={MEMBER.EARN}
      Component={ErrorPage}
      title={t("ifYouBuildIt", language)}
      message={t("buildingThisPart", language)}
      navbar
      comingSoon
      header
      allowedRoles={Object.values(roles)}
      isPrivate
    />
    <Route
      exact
      path={GENERAL.UNAUTHORIZED}
      title={t("shallNotPass", language)}
      message={t("doNotHavePermission", language)}
      Component={ErrorPage}
      navbar
    />

    <Route
      Component={ErrorPage}
      title={t("bitLost", language)}
      message={t("sorryPageDoesntExist", language)}
      navbar
    />
  </Switch>
);

const mapStateToProps = (state) => ({
  language: state.auth.language,
});

export default connect(mapStateToProps)(ErrorRouter);
