import React from "react";

const Youtube = ({ width, height, color, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    {...props}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M23.842 6.975c0-2.11-1.551-3.807-3.468-3.807A170.465 170.465 0 0012.421 3h-.844c-2.7 0-5.353.047-7.95.169-1.913 0-3.464 1.706-3.464 3.815A69.732 69.732 0 000 11.99a72.582 72.582 0 00.16 5.011c0 2.11 1.55 3.82 3.463 3.82 2.728.127 5.527.183 8.372.178 2.85.01 5.64-.05 8.372-.178 1.917 0 3.469-1.71 3.469-3.82.112-1.673.164-3.342.16-5.016.01-1.668-.041-3.339-.154-5.01zm-14.14 9.614V7.378L16.5 11.98l-6.797 4.608z"
        fill={color || "currentColor"}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default Youtube;
