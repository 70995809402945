import React from "react";

import * as T from "../../../components/Typograpy";
import * as S from "./style";
import Image from "../../../components/Image";

const ErrorPage = ({ title, message, comingSoon }) => {
  if (comingSoon) {
    return (
      <S.Wrapper>
        <Image image="officeLarge" alt="working" margin="0 0 10px 0" />
        <T.H40Caps color="midnight" mb={15} style={{ maxWidth: "450px" }}>
          {title}
        </T.H40Caps>
        <T.Body16R color="gray3" style={{ maxWidth: "450px" }}>
          {message}
        </T.Body16R>
      </S.Wrapper>
    );
  }

  return (
    <S.Wrapper>
      <Image image="unsuccessful" alt="unsuccessful" margin="0 0 40px 0" />
      <T.H40Caps color="midnight" mb={40}>
        {title}
      </T.H40Caps>
      <T.Body16R color="gray3">{message}</T.Body16R>
    </S.Wrapper>
  );
};

export default ErrorPage;
