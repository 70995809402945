import {
  GET_MEMBERS_SUCCESS,
  GET_MEMBERS_FAIL,
  GET_MEMBERS_LOADING_START,
  GET_MEMBERS_LOADING_END,
  // GIVE
  GIVE_MEMBER_LOADING_START,
  GIVE_MEMBER_LOADING_END,
  GIVE_MEMBER_LOADING_SUCCESS,
  GIVE_MEMBER_LOADING_FAIL,
  // bulk
  GIVE_BULK_MEMBER_LOADING_START,
  GIVE_BULK_MEMBER_LOADING_END,
  // reset
  RESET_GIVE_TC,
  // get recent transactions
  GET_EARN_GROUP_TRANSACTIONS_LOADING_START,
  GET_EARN_GROUP_TRANSACTIONS_SUCCESS,
  GET_EARN_GROUP_TRANSACTIONS_FAIL,
} from "../../../redux/types";

const initState = {
  getLoading: false,
  error: {},
  members: [],
  loadingIds: [],
  successIds: [],
  failedIds: [],
  bulkLoading: false,
  recentTransactions: [],
  transactionsLoading: [],
};

const giveCredits = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_MEMBERS_LOADING_START:
      return {
        ...state,
        getLoading: true,
      };
    case GET_MEMBERS_LOADING_END:
      return {
        ...state,
        getLoading: false,
      };
    case GIVE_MEMBER_LOADING_START:
      return {
        ...state,
        loadingIds: [...state.loadingIds, payload],
      };
    case GIVE_MEMBER_LOADING_END:
      // eslint-disable-next-line no-case-declarations
      const a = [...state.loadingIds];

      a.splice(state.loadingIds.indexOf(payload), 1);
      return {
        ...state,
        loadingIds: a,
      };
    case GIVE_MEMBER_LOADING_SUCCESS:
      return {
        ...state,
        successIds: [...state.successIds, payload],
      };

    case GIVE_MEMBER_LOADING_FAIL:
      return {
        ...state,
        error: payload.error,
        failedIds: [...state.failedIds, payload.id],
      };

    case GET_MEMBERS_SUCCESS:
      return {
        ...state,
        members: payload,
      };
    case GET_MEMBERS_FAIL:
      return {
        ...state,
        error: payload,
        members: [],
      };

    case GIVE_BULK_MEMBER_LOADING_START:
      return {
        ...state,
        bulkLoading: true,
      };
    case GIVE_BULK_MEMBER_LOADING_END:
      return {
        ...state,
        bulkLoading: false,
      };

    case RESET_GIVE_TC:
      return initState;

    case GET_EARN_GROUP_TRANSACTIONS_LOADING_START:
      return {
        ...state,
        transactionsLoading: true,
      };

    case GET_EARN_GROUP_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        recentTransactions: payload,
        transactionsLoading: false,
      };

    case GET_EARN_GROUP_TRANSACTIONS_FAIL:
      return {
        ...state,
        transactionsLoading: false,
      };

    default:
      return state;
  }
};

export default giveCredits;
