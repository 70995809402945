import React, { useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { GENERAL } from "../../constants/navRoutes";
import TempoDashboard from "./Tempo";
import MemberDashboard from "./Member";
import EarnGroupDashboard from "./EarnGroup";
import ProgrammeManagerDashboard from "./ProgrammeManager";

import { getLoggedInUserInfo } from "../../redux/actions/auth";

import { roles } from "../../constants";
import { tempoRoles } from "../../constants/roles";

const Dashboard = (props) => {
  const { role, getLoggedInUserInfoAction } = props;
  const history = useHistory();
  // call userInfo from App.js to update info (important after updating account settings like name)
  useEffect(() => {
    getLoggedInUserInfoAction(history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  switch (role) {
    case tempoRoles.TEMPO_STAFF_SUPER:
    case tempoRoles.TEMPO_STAFF_ADMIN:
    case tempoRoles.TEMPO_STAFF_USER:
      return <TempoDashboard {...props} />;
    case roles.MEMBER:
      return <MemberDashboard {...props} />;
    case roles.EARN_GROUP:
    case roles.EARN_GROUP_ADMIN:
      return <EarnGroupDashboard {...props} />;
    case roles.PROGRAMME_MANAGER:
      return <ProgrammeManagerDashboard {...props} />;
    default:
      return <Redirect to={GENERAL.UNAUTHORIZED} {...props} />;
  }
};

const mapStateToProps = (state) => ({
  role: state.auth.role,
  isMobile: state.ui.isMobile,
});

const mapActionToProps = {
  getLoggedInUserInfoAction: getLoggedInUserInfo,
};

export default connect(mapStateToProps, mapActionToProps)(Dashboard);
