import axios from "axios";
import handleError from "./handle-errors";

const IMAGE_BASE = "/images";

const getSignedURL = async ({
  fileType,
  fileName,
  imageType,
  options,
} = {}) => {
  try {
    // remove + sign from fileName
    const _fileName = fileName.replace(/\+/g, "_");
    const { data } = await axios.get(`${IMAGE_BASE}/`, {
      params: { fileType, fileName: _fileName, imageType },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

// const deleteImageById = async ({ id, imageType, options } = {}) => {
//   try {
//     const { data } = await axios.delete(`${IMAGE_BASE}/${id}`, {
//       params: { imageType },
//     });
//     return { data };
//   } catch (error) {
//     const err = handleError(error, options);
//     return { error: err };
//   }
// };

const uploadToS3 = async ({ signedURL, file, options } = {}) => {
  try {
    const {
      config: {
        data: { size, path, name, type },
      },
    } = await axios.put(signedURL, file);
    return { data: { size, path, name, fileType: type, new: true } };
  } catch (error) {
    handleError(error, options);
    return {
      error: {
        error:
          (error.response && error.response.message) || "somethingWentWrong",
        message:
          (error.response && error.response.statusText) || "somethingWentWrong",
        httpStatusCode: (error.response && error.response.status) || 409,
      },
    };
  }
};

export {
  getSignedURL,
  uploadToS3,
  //  deleteImageById
};
