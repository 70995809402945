import { styled } from "@material-ui/core/styles";
import * as T from "../../../components/Typograpy";

export const Wrapper = styled("section")(() => ({}));

export const CardsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  paddingTop: theme.spacing(4),

  [theme.breakpoints.XL]: {
    paddingTop: theme.spacing(6),
  },
}));

export const StyledButton = styled("button")(({ theme, notSelected }) => ({
  outline: "none",
  border: "none",
  background: theme.colors.white,
  cursor: "pointer",
  opacity: notSelected ? "0.4" : "1",

  marginBottom: theme.spacing(2),

  [theme.breakpoints.mobileXL]: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },

  [theme.breakpoints.laptop]: {
    marginRight: theme.spacing(4),
  },
}));

export const ButtonWrapper = styled("div")(({ theme }) => ({
  maxWidth: "345px",
  paddingLeft: theme.spacing(0),
  paddingTop: theme.spacing(3),
}));

export const SuccessWrapper = styled("section")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: theme.spacing(3),
  maxWidth: "420px",
  margin: "0 auto",
  paddingBottom: theme.spacing(7),

  [theme.breakpoints.tablet]: {
    paddingTop: 0,
  },
}));

export const H40Caps = styled(T.H40Caps)(({ theme }) => ({
  color: theme.colors.midnight,
}));

export const StyledHeader = styled("header")(() => ({
  width: "100%",
}));

export const Body18R = styled(T.Body16R)(({ theme }) => ({
  color: theme.colors.gray2,
  marginTop: "2rem",
  fontSize: "16px",
  display: "inline-block",
}));

export const Body18S = styled(T.Body18S)(({ theme }) => ({
  color: theme.colors.gray2,
  marginTop: "2rem",
  fontWeight: "600",
  fontSize: "16px",
}));
