import React from "react";
import moment from "moment";

import { Row, Col } from "../../components/Grid";
import t from "../../utils/helpers/translator";

import { Dropdown, Datepicker, Timepicker } from "../../components/Inputs";
import RemoveRejoin from "../../components/RemoveRejoin";

const DateSection = ({
  index,
  handleDateTime,
  dates,
  times,
  repeats = {},
  repeatOptions,
  removeDate,
  datesLength,
  errors = {},
  disablePast,
  language = "english",
}) => {
  const dateChange = (selectedDates) =>
    handleDateTime(index, selectedDates, "dates");

  const timeChange = (e) => handleDateTime(index, e.target, "times");

  const repeatChange = (selected) => handleDateTime(index, selected, "repeat");

  const handleDayOfMonthOption = (startDate) => {
    const dayOfMonthOptions = ["1st", "2nd", "3rd", "4th", "last"];
    const startDayNum = moment(moment(startDate).day());
    const startDay = moment().day(startDayNum).format("dddd");
    const dayNum = Math.ceil(moment(startDate).format("DD") / 7);

    return `${t("repeatsMonthly", language)} ${
      dayOfMonthOptions[dayNum - 1]
    } ${startDay}`;
  };

  return (
    <>
      <Row mb={2}>
        <Col w={[4, 6, 6]} style={{ paddingLeft: 2, paddingRight: 19 }}>
          <Datepicker
            value={dates}
            setValue={dateChange}
            label={t("selectDates", language)}
            justify="flex-start"
            error={errors.dates}
            disablePast={disablePast}
            customLang={language || "english"}
            noZIndex
          />
        </Col>
        <Col w={[4, 6, 6]}>
          <Timepicker
            startTime={times.startTime}
            endTime={times.endTime}
            setValue={timeChange}
            label={t("selectTimes", language)}
            justify="flex-start"
            error={errors.times}
            language={language}
          />
        </Col>
      </Row>
      <Row>
        <Col w={[4, 6, 6]}>
          <Dropdown
            name="activityTypes"
            options={repeatOptions.map((repeat) => {
              let updatedLabel;

              if (repeat.value === "monthly" && dates.startDate) {
                updatedLabel = handleDayOfMonthOption(dates.startDate);
              }
              return {
                label: updatedLabel || repeat.label,
                value: repeat.value,
              };
            })}
            value={
              repeats.value === "monthly" && dates.startDate
                ? {
                    label: handleDayOfMonthOption(dates.startDate),
                    value: repeats.value,
                  }
                : repeats
            }
            setValue={repeatChange}
            fixedHeight={false}
            closeMenuOnSelect
            blurInputOnSelect
            justify="center"
          />
        </Col>
      </Row>
      {index < datesLength - 1 && (
        <Row mb={5}>
          <Col w={[4, 6, 6]}>
            <RemoveRejoin
              handleClick={(e) => removeDate(e, index)}
              showText
              type="Remove"
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default React.memo(DateSection);
