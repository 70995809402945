import React from "react";
import { connect } from "react-redux";
import * as S from "../style";
import * as T from "../../Typograpy";

import { Tag } from "../../Tags";

const decideColor = (type, status) => {
  const tidiedType = type.toLowerCase();
  if (
    [
      "spend",
      "used",
      "rejected",
      "returning",
      "given out",
      "givenout",
      "rhoddwyd",
    ].includes(tidiedType)
  ) {
    return "pink";
  }
  if (
    ["gift", "gifted", "partially approved", "cancelled"].includes(
      tidiedType,
    ) &&
    status !== "pending"
  ) {
    return "purple";
  }
  if (["issue", "issued", "exchange", "exchanged"].includes(tidiedType)) {
    return "gray2";
  }
  if (
    tidiedType === "pending" ||
    status === "pending" ||
    tidiedType === "paymentDue"
  ) {
    return "cyan";
  }
  if (tidiedType === "bought" || status === "bought") {
    return "midnight";
  }
  return "blue";
};

const decideSymbol = (fromUser) => (fromUser ? "-" : "+");

const Credits = ({
  withTag,
  withSymbol,
  credits,
  type,
  justify,
  fromUser,
  pr,
  pending,
  lang,
  status,
}) => (
  <S.StyledCredit justify={justify} pr={pr} pending={pending}>
    {withTag && (
      <Tag
        tagName={type.toLowerCase()}
        status={status}
        size="m"
        m="0"
        justify="flexStart"
        customStyle={{
          textTransform: "uppercase",
          marginLeft: "0",
        }}
        lang={lang}
      />
    )}
    <T.H18
      color={decideColor(type, status)}
      style={{
        margin: withTag ? "0 0 0 10px" : "0",
        width: "100%",
      }}
    >
      {withSymbol && decideSymbol(fromUser)}
      {credits}
    </T.H18>
  </S.StyledCredit>
);

const mapStateToProps = (state) => ({
  lang: state.auth.decideLanguage(state.auth),
});

export default connect(mapStateToProps)(Credits);
