import React, { useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

import * as S from "./style";
import * as T from "../../../../components/Typograpy";
import GoBack from "../../../../components/GoBack";

import Button from "../../../../components/Button";
import Image from "../../../../components/Image";
import Icon from "../../../../components/Icon";

import { GENERAL, TEMPO } from "../../../../constants/navRoutes";
import { getSpendVenueById, getSpendVenueActivities } from "./action";
import Loading from "../../../../components/Loading";
import Table from "../../../../components/Table";
import { Row, Col } from "../../../../components/Grid";
// import RemoveLink from "../../../../components/RemoveRejoin";
import {
  customDateCol,
  checkedUnchecked,
  buttonAction,
} from "../../../../components/Table/config/reusableColumns";

const SingleSpendVenue = ({
  data,
  getSpendVenueByIdAction,
  getSpendVenueActivitiesAction,
}) => {
  const {
    name,
    email,
    phone,
    address,
    region,
    url,
    dateOfLastAccept,
    loading,
    firstName,
    lastName,
    activities = [],
    activitiesLoading,
    isPublic,
    activitiesStats = {},
    hasWelsh,
    spendPartner = {},
  } = data;
  const history = useHistory();
  const { id: spendVenueId } = useParams();

  useEffect(() => {
    if (spendVenueId) {
      if (Number(spendVenueId)) {
        getSpendVenueByIdAction(spendVenueId);
        getSpendVenueActivitiesAction(spendVenueId);
      } else {
        history.push("/404");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spendVenueId]);

  const onClick = (type) => {
    switch (type) {
      case "view":
        history.push(GENERAL.SPEND_VENUE.replace(":id", spendVenueId));
        break;

      case "edit":
        history.push(
          TEMPO.EDIT_SPEND_VENUE.replace(":spendVenueId", spendVenueId),
        );
        break;

      case "insights":
        history.push(
          TEMPO.SPEND_VENUE_INSIGHTS.replace(":spendVenueId", spendVenueId),
        );
        break;

      default:
        break;
    }
  };
  const activityColumns = [
    { title: "Activity Name", field: "name" },
    customDateCol({
      title: "End Date",
      field: "endDate",
    }),
    {
      title: "Status",
      render: ({ isLive, isExpired }) => {
        if (isExpired) return "expired";
        return isLive ? "live" : "draft";
      },
    },

    checkedUnchecked({ title: "WELSH VERSION", field: "hasWelsh" }),
    buttonAction({
      text: "edit",
      onClick: ({ id }) =>
        history.push(TEMPO.EDIT_SPEND_ACTIVITY.replace(":id", id)),
    }),
    buttonAction({
      text: "Duplicate",
      onClick: ({ id }) =>
        history.push(TEMPO.DUPLICATE_SPEND_ACTIVITY.replace(":id", id)),
    }),
  ];

  const clickRow = (event, rowData) => {
    const win = window.open(
      GENERAL.SPEND_ACTIVITY.replace(":id", rowData.id),
      "_blank",
    );
    win.focus();
  };

  const clickCreateActivity = () =>
    history.push(
      TEMPO.CREATE_SPEND_ACTIVITY.replace(":spendVenueId", spendVenueId),
    );

  // const handleDelete = () => {
  //   console.log("delete account clicked !!");
  // };

  if (loading) return <Loading fullPage navbar />;
  return (
    <S.Container>
      <S.Head>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <S.Back>
            <GoBack showText onClick={() => history.push(TEMPO.SPEND_VENUES)} />
          </S.Back>
          <T.H40Caps color="primary" style={{ margin: 0 }}>
            {name}
          </T.H40Caps>
        </div>
        <S.ImageWrapper>
          <Image image="gallery" alt="gallery" height={210} width={220} />
        </S.ImageWrapper>
      </S.Head>
      <S.CardContainer>
        <S.StyledCard width={450}>
          <T.H20 color="midnight" style={{ marginBottom: 30 }}>
            Venue Account Details
          </T.H20>
          <Row inner mt="3" mb="3">
            <Col w={[2, 5, 5]}>
              <T.H18 color="gray2">Recognition Partner</T.H18>
            </Col>{" "}
            <Col w={[2, 7, 7]}>
              <T.H16 color="gray2">
                <Link
                  to={TEMPO.SPEND_PARTNER.replace(":id", spendPartner.userId)}
                >
                  <T.H16 color="cyan" style={{ textDecoration: "underline" }}>
                    {spendPartner.name || "N/A"}
                  </T.H16>
                </Link>
              </T.H16>
            </Col>
          </Row>
          <Row inner mt="3" mb="3">
            <Col w={[2, 5, 5]}>
              <T.H18 color="gray2">Name</T.H18>
            </Col>{" "}
            <Col w={[2, 7, 7]}>
              <T.H16 color="gray2">
                {firstName || lastName ? `${firstName} ${lastName}` : "N/A"}
              </T.H16>
            </Col>
          </Row>
          <Row inner mt="3" mb="3">
            <Col w={[2, 5, 5]}>
              <T.H18 color="gray2">Email</T.H18>
            </Col>{" "}
            <Col w={[2, 7, 7]}>
              {email ? (
                <a href={`mailto:${email}`} alt="email">
                  <T.H16
                    color="cyan"
                    style={{
                      textDecoration: "underline",
                      wordWrap: "break-word !important",
                      overflowWrap: "break-word",
                      "-ms-word-break": "break-all",
                      wordBreak: "break-word",
                    }}
                  >
                    {email}
                  </T.H16>
                </a>
              ) : (
                <T.H16 color="gray2">N/A</T.H16>
              )}
            </Col>
          </Row>
          <Row inner mt="3" mb="3">
            <Col w={[2, 5, 5]}>
              <T.H18 color="gray2">Phone</T.H18>
            </Col>{" "}
            <Col w={[2, 7, 7]}>
              <T.H16 color="gray2">{phone || "N/A"}</T.H16>
            </Col>
          </Row>
          <Row inner mt="3" mb="3">
            <Col w={[2, 5, 5]}>
              <T.H18 color="gray2">Address</T.H18>
            </Col>

            <Col w={[2, 5, 4]}>
              <T.H16 color="gray2">
                {address && address.replace(/ /g, "").trim().length
                  ? address
                  : "N/A"}
              </T.H16>
            </Col>
          </Row>
          <Row inner mt="3" mb="3">
            <Col w={[2, 5, 5]}>
              <T.H18 color="gray2">Region</T.H18>
            </Col>{" "}
            <Col w={[2, 7, 7]}>
              <T.H16 color="gray2">{region || "N/A"}</T.H16>
            </Col>
          </Row>
          <Row inner mt="3" mb="3">
            <Col w={[2, 5, 5]}>
              <T.H18 color="gray2">URL</T.H18>
            </Col>{" "}
            <Col w={[2, 7, 7]}>
              <a href={url} alt="URL">
                <T.H16
                  color="cyan"
                  style={{
                    textDecoration: "underline",
                    wordWrap: "break-word !important",
                    overflowWrap: "break-word",
                    "-ms-word-break": "break-all",
                    wordBreak: "break-word",
                  }}
                >
                  {url}
                </T.H16>
              </a>
            </Col>
          </Row>
          <Row mt="5" mb="5">
            <Col w={[4, 8, 8]}>
              <Button
                outline
                handleClick={() => {
                  history.push(
                    TEMPO.EDIT_SPEND_VENUE_ACCOUNT.replace(":id", spendVenueId),
                  );
                }}
              >
                Edit account
              </Button>
            </Col>
          </Row>
        </S.StyledCard>
        <S.BtnContainer width={400}>
          <S.Buttons>
            <Button
              primary
              onClick={() => onClick("view")}
              style={{ marginBottom: 10 }}
              w="280px"
              m="0"
            >
              View Venue Listing
            </Button>
            <Button
              outline
              onClick={() => onClick("edit")}
              style={{ margin: 0 }}
              w="280px"
              m="0"
            >
              Edit Venue Listing
            </Button>
            <T.Body16B color="gray3" style={{ marginTop: 20 }}>
              Profile status: {isPublic ? "Live" : "Draft"}
            </T.Body16B>

            <T.Body14R color="gray3" style={{ marginTop: 5 }}>
              Welsh version created:{" "}
              {
                <Icon
                  icon={hasWelsh ? "tickRound" : "no"}
                  width="16px"
                  height="16px"
                  margin="0 0 -2px 10px"
                  color={hasWelsh ? "white" : "pink"}
                />
              }
            </T.Body14R>
          </S.Buttons>
          <S.Figures>
            <S.BtnSide>
              <T.H18Caps color="pink">date last accepted</T.H18Caps>
            </S.BtnSide>
            <S.BtnSide>
              <T.H30 color="primary" style={{ marginLeft: 40 }}>
                {dateOfLastAccept
                  ? moment(dateOfLastAccept).format("DD/MM/YYYY")
                  : "N/A"}
              </T.H30>
            </S.BtnSide>
          </S.Figures>
          <Button
            primary
            onClick={() => onClick("insights")}
            style={{ marginBottom: 10 }}
            w="280px"
            m="30px 0 0"
          >
            See Insights
          </Button>
        </S.BtnContainer>
      </S.CardContainer>
      {/* future feature */}
      {/* <Row mt="3">
        <S.RemoveWrapper w={[4, 6, 12]}>
          <RemoveLink
            type="Remove"
            text="Delete Venue"
            showText
            handleClick={handleDelete}
          />
        </S.RemoveWrapper>
      </Row> */}
      <S.SpendActivitiesContainer>
        <div style={{ maxWidth: 600 }}>
          <T.H24Caps color="primary" style={{ marginBottom: 10 }}>
            Offers/Activities
          </T.H24Caps>
          <T.Body16R
            color="gray3"
            style={{ maxWidth: 450, marginLeft: 0, marginBottom: 30 }}
          >
            Please note, the venue profile must be made public for any
            activities to be made visible on the website.
          </T.Body16R>
          <Row>
            <Col w={[4, 6, 6]}>
              <S.Activities>
                <S.Activity>
                  <S.NumberOfActivities color="#00A9CE">
                    {activitiesStats.live || 0}
                  </S.NumberOfActivities>
                  <S.ActivityType>
                    {activitiesStats?.live !== 1
                      ? "live activities"
                      : "live activity"}
                  </S.ActivityType>
                </S.Activity>

                <S.Activity>
                  <S.NumberOfActivities color="#0057A3">
                    {activitiesStats.draft || 0}
                  </S.NumberOfActivities>
                  <S.ActivityType>
                    {activitiesStats?.draft !== 1
                      ? "draft activities"
                      : "draft activity"}
                  </S.ActivityType>
                </S.Activity>
                <S.Activity>
                  <S.NumberOfActivities color="pink">
                    {activitiesStats.expired || 0}
                  </S.NumberOfActivities>
                  <S.ActivityType>
                    {activitiesStats?.expired !== 1
                      ? "expired activities"
                      : "expired activity"}
                  </S.ActivityType>
                </S.Activity>
              </S.Activities>
            </Col>
            <Col w={[4, 6, 6]}>
              <Button
                style={{ marginLeft: 40, height: 50 }}
                onClick={clickCreateActivity}
              >
                Add Activity
              </Button>
            </Col>
          </Row>
        </div>
      </S.SpendActivitiesContainer>
      <Table
        color="blue"
        data={activities}
        isLoading={activitiesLoading}
        columns={activityColumns}
        onRowClick={clickRow}
        hideSearch
      />
    </S.Container>
  );
};
const mapStateToProps = (state) => ({
  data: state.staffSingleSpendVenue,
});

const mapActionsToProps = {
  getSpendVenueByIdAction: getSpendVenueById,
  getSpendVenueActivitiesAction: getSpendVenueActivities,
};

export default connect(mapStateToProps, mapActionsToProps)(SingleSpendVenue);
