import axios from "axios";
import handleError from "./handle-errors";

const SPEND_PARTNERS_BASE = "/spend-partners";

const getSpendPartners = async ({ basic, options } = {}) => {
  try {
    const { data } = await axios.get(SPEND_PARTNERS_BASE, {
      params: { basic },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};
const createSpendPartner = async ({ form, options } = {}) => {
  try {
    const { data } = await axios.post(SPEND_PARTNERS_BASE, form);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getSpendPartnerById = async ({ id, options } = {}) => {
  try {
    const { data } = await axios.get(`${SPEND_PARTNERS_BASE}/${id}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updateSpendPartnerTerms = async ({ options } = {}) => {
  try {
    const { data } = await axios.patch(`${SPEND_PARTNERS_BASE}/terms`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updateSelectedSpendVenue = async ({ spendVenueUserId, options } = {}) => {
  try {
    const { data } = await axios.patch(
      `${SPEND_PARTNERS_BASE}/selected-venue`,
      { spendVenueUserId },
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export {
  getSpendPartners,
  getSpendPartnerById,
  createSpendPartner,
  updateSpendPartnerTerms,
  updateSelectedSpendVenue,
};
