import moment from "moment";

import translate from "./translator";

const calendarFormatter = (date, lang) => {
  if (moment(date).isValid()) {
    const day = moment(date).format("l");
    const month = moment(date).format("MMMM");
    const cleanDay = moment(day, "l").calendar(null, {
      sameDay: `[${translate("today", lang)}] DD MMMM YYYY`,
      nextDay: `[${translate("tomorrow", lang)}] DD MMMM YYYY`,
      nextWeek: "DD MMMM YYYY",
      lastDay: "DD MMMM YYYY",
      sameElse: "DD MMMM YYYY",
    });

    const translatedDate = cleanDay.replace(month, translate(month, lang));

    return translatedDate;
  }
  return date;
};

export default calendarFormatter;
