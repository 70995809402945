export default [
  5, //   0
  10, //  1
  15, //  2
  20, //  3
  25, //  4
  30, //  5
  40, //  6
  50, //  7
  60, //  8
  80, //  9
  110, // 10
  120, // 11
  150, // 12
  180, // 13
  220, // 14
  260, // 15
];
