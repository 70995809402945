import {
  GET_SPEND_PARTNERS_BASIC_ADMIN_LOADING_START,
  GET_SPEND_PARTNERS_BASIC_ADMIN_LOADING_END,
  GET_SPEND_PARTNERS_BASIC_ADMIN_SUCCESS,
  GET_SPEND_PARTNERS_BASIC_ADMIN_FAIL,
  CREATE_SPEND_VENUE_CRED_LOADING_START,
  CREATE_SPEND_VENUE_CRED_LOADING_END,
  CREATE_SPEND_VENUE_CRED_SUCCESS,
  CREATE_SPEND_VENUE_CRED_FAIL,
  UPDATE_SV_PARTNER_LOADING,
  UPDATE_SV_PARTNER_SUCCESS,
  UPDATE_SV_PARTNER_FAIL,
} from "../../../../redux/types";

import { spendPartners, spendVenues } from "../../../../api-calls";

export const getSpendPartners = () => async (dispatch) => {
  dispatch({ type: GET_SPEND_PARTNERS_BASIC_ADMIN_LOADING_START });
  try {
    const { data, error } = await spendPartners.getSpendPartners({
      basic: true,
    });
    if (data) {
      dispatch({
        type: GET_SPEND_PARTNERS_BASIC_ADMIN_SUCCESS,
        payload: data,
      });
    } else if (error) {
      dispatch({
        type: GET_SPEND_PARTNERS_BASIC_ADMIN_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_SPEND_PARTNERS_BASIC_ADMIN_FAIL,
      payload: error,
    });
  } finally {
    dispatch({ type: GET_SPEND_PARTNERS_BASIC_ADMIN_LOADING_END });
  }
};

export const createSpendVenueCredentials =
  ({ id, firstName, lastName, email, phoneNumber, password, spendPartnerId }) =>
  async (dispatch) => {
    dispatch({ type: CREATE_SPEND_VENUE_CRED_LOADING_START });
    try {
      const { data, error } = await spendVenues.createSpendVenueCredentials({
        form: {
          firstName,
          lastName,
          email,
          phoneNumber,
          password,
          spendPartnerId,
        },
        id,
      });
      if (data) {
        dispatch({
          type: CREATE_SPEND_VENUE_CRED_SUCCESS,
          payload: data,
        });
      } else if (error) {
        dispatch({
          type: CREATE_SPEND_VENUE_CRED_FAIL,
          payload: error,
        });
        throw error;
      }
    } catch (error) {
      dispatch({
        type: CREATE_SPEND_VENUE_CRED_FAIL,
        payload: error,
      });
      throw error;
    } finally {
      dispatch({ type: CREATE_SPEND_VENUE_CRED_LOADING_END });
    }
  };

export const updateSpendVenuePartner =
  ({ spendVenueUserId, spendPartnerId }) =>
  async (dispatch) => {
    dispatch({ type: UPDATE_SV_PARTNER_LOADING });
    try {
      const { error } = await spendVenues.updateSpendVenuePartner({
        spendVenueUserId,
        spendPartnerId,
      });
      if (!error) {
        dispatch({
          type: UPDATE_SV_PARTNER_SUCCESS,
        });
      } else {
        dispatch({
          type: UPDATE_SV_PARTNER_FAIL,
          payload: error,
        });
        throw error;
      }
    } catch (error) {
      dispatch({
        type: UPDATE_SV_PARTNER_FAIL,
        payload: error,
      });
      throw error;
    }
  };
