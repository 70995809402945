import {
  LOGIN_SUCESSS,
  LOGIN_FAIL,
  LOGIN_LOADING_START,
  LOGIN_LOADING_END,
} from "../../redux/types";
import { auth } from "../../api-calls";
import { navRoutes } from "../../constants";

export const login =
  ({ email, password, history, reToken }) =>
  async (dispatch) => {
    dispatch({
      type: LOGIN_LOADING_START,
    });
    const { data, error } = await auth.login({
      email,
      password,
      reToken,
    });

    dispatch({
      type: LOGIN_LOADING_END,
    });

    if (error) {
      return dispatch({
        type: LOGIN_FAIL,
        payload: error,
      });
    }

    dispatch({
      type: LOGIN_SUCESSS,
      payload: data,
    });

    return history.push(navRoutes.GENERAL.ADD_GROUP_SIGNUP);
  };
