import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "./actions";
import * as S from "./style";
import * as T from "../../../components/Typograpy";
import { InputField } from "../../../components/Inputs";
import Button, { Regular } from "../../../components/Button";
import { password as passwordSchema } from "../../../validation/fields";
import { GENERAL } from "../../../constants/navRoutes";
import Wrapper from "./Wrapper";
import t from "../../../utils/helpers/translator";

const SetPassword = (props) => {
  const [password, setPassword] = useState(null);
  const [attemptToSubmit, setAttemptToSubmit] = useState(false);
  const [PasswordError, setPasswordError] = useState("");
  const { token } = useParams();

  const history = useHistory();
  const { setPasswordAction, setPasswordSuccess, setPasswordLoading, error } =
    props;

  const handlePassword = (_password) => {
    if (attemptToSubmit) {
      const isValidPassword = passwordSchema.isValidSync(_password);
      setPasswordError(isValidPassword ? "" : t("invalidPassword", props.lang));
    }

    setPassword(_password);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setAttemptToSubmit(true);
    const isValidPassword = passwordSchema.isValidSync(password);
    setPasswordError(isValidPassword ? "" : t("invalidPassword", props.lang));
    if (isValidPassword) {
      setPasswordAction({ password, token });
    }
  };

  return (
    <Wrapper>
      <T.H40Caps color="midnight" mt="20">
        {t("resetPassword", props.lang)}
      </T.H40Caps>
      {!setPasswordSuccess ? (
        <>
          <S.Body onSubmit={onSubmit}>
            <InputField
              disabled={setPasswordLoading}
              setValue2={handlePassword}
              fullWidth
              type="password"
              placeholder={`${t("createPassword", props.lang)}...`}
              label={t("password", props.lang)}
              showPasswordPop
              mt="5"
              lang={props.lang}
              error={PasswordError || t(error.message, props.lang)}
            />
            <Button
              loading={setPasswordLoading}
              disabled={!password || !!PasswordError || setPasswordLoading}
              style={{ marginTop: "71px" }}
              primary
              size="l"
              type="submit"
            >
              {t("resetPassword", props.lang)}
            </Button>
          </S.Body>
        </>
      ) : (
        <>
          <T.Body16R color="gray2" mt={25}>
            {t("greatSetUpPassword", props.lang)}
          </T.Body16R>
          <S.ButtonWrapper>
            <Regular
              primary
              bgColor="blue"
              onClick={() => history.push(GENERAL.LOG_IN)}
            >
              {t("logIn", props.lang)}
            </Regular>
          </S.ButtonWrapper>
        </>
      )}
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  setPasswordSuccess: state.forgetPassword.setPasswordSuccess,
  setPasswordLoading: state.forgetPassword.setPasswordLoading,
  error: state.forgetPassword.setPasswordError,
  lang: state.auth.language,
});

const mapActionToProps = {
  setPasswordAction: actions.setPassword,
};

export default connect(mapStateToProps, mapActionToProps)(SetPassword);
