import React from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Icon from "../../Icon";
import * as T from "../../Typograpy";
import useStyles from "../style";
import Checkbox from "../Checkbox";

import t from "../../../utils/helpers/translator";

const Counter = (props) => {
  const {
    value = 0,
    disabled,
    setValue,
    error,
    label,
    subLabel,
    size = "normal",
    passProps,
    justify,
    // TODO decided standard max value
    max = 100,
    labelSize,
    costVaries,
    handleCostVaries,
    lang = "english",
  } = props;

  const theme = useTheme();
  const classes = useStyles(props);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleInc = () => {
    if (value < max) {
      // this to fix the problem with NAN with the counter input
      const valueChecked =
        typeof value === "string" && value.trim() === "" ? 0 : value;
      setValue({
        counter: parseInt(valueChecked, 10) + 1,
        ...passProps,
      });
    } else {
      setValue({
        counter: max,
        error: `Please select a number equal to or less than ${max}`,
        ...passProps,
      });
    }
  };

  const handleDec = () => {
    setValue({ counter: value - 1, ...passProps });
  };

  const handleChange = (e) => {
    const {
      target: { value: amount },
    } = e;
    const hasDecimals = amount % 1 !== 0;
    if (hasDecimals) {
      return null;
    }

    if (amount < max) {
      setValue({ counter: amount, ...passProps });
    } else {
      setValue({
        counter: max,
        error: `Please select a number equal to or less than ${max}`,
        ...passProps,
      });
    }
  };

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems={justify || "center"}
      className={classes.root}
    >
      {labelSize === "reg" ? (
        <div className={classes.counterHeaderText}>
          <T.Body16B color="midnight">{label}</T.Body16B>
          <T.Body14R>{subLabel}</T.Body14R>
        </div>
      ) : (
        <div className={classes.counterHeaderText}>
          <T.H22Caps color="midnight" style={{ marginBottom: "5px" }}>
            {label}
          </T.H22Caps>
          <T.Body14R>{subLabel}</T.Body14R>
        </div>
      )}
      <Grid
        container
        direction="row"
        justify={justify || "space-evenly"}
        alignItems="center"
        wrap="nowrap"
      >
        <div className={classes.counterWrapper}>
          <IconButton
            {...props}
            disabled={disabled || value < 1}
            onClick={handleDec}
            aria-label="decrement counter"
            style={{ paddingLeft: "5px" }}
          >
            <Icon
              color={value && "blue"}
              width={size === "small" || isMobile ? "26px" : "32px"}
              icon="minusCircle"
            />
          </IconButton>

          <input
            className={classes.counterInput}
            type="number"
            onChange={handleChange}
            placeholder="0"
            value={value > 0 && value}
            disabled={disabled}
          />

          <IconButton
            {...props}
            disabled={disabled}
            onClick={handleInc}
            aria-label="increment counter"
            style={{ paddingRight: "5px" }}
          >
            <Icon
              color={value < max && "midnight"}
              width={size === "small" || isMobile ? "26px" : "32px"}
              icon="plusCircle"
            />
          </IconButton>
        </div>
        {handleCostVaries && (
          <Checkbox
            value={costVaries}
            classes={{ root: classes.costVaries }}
            setValue={handleCostVaries}
            label={t("costsVaries", lang)}
            name="costVaries"
            size="lightNormal"
            style={{ marginLeft: "30px" }}
          />
        )}
      </Grid>
      {error && (
        <T.Body14R className={classes.textFieldError}>{error}</T.Body14R>
      )}
    </Grid>
  );
};

export default Counter;
