import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";

import { getInsights } from "../insights.action";
import { getEarnGroups } from "./actions";

import * as S from "./style";
import Image from "../../../../components/Image";
import * as T from "../../../../components/Typograpy";
import Stats from "../../../../components/Stats";
import Table from "../../../../components/FilterTable";
import PopQuestion from "../../../../components/PopQuestion";
import stripLeadingTab from "../../../../utils/helpers/stripLeadingTab";
import Button from "../../../../components/Button";
import FilterByDate from "../../../../components/FilterByDate";
import Modal from "../../../../components/Modal";
import DownloadFile from "../../../../components/DownloadFile";
import Search from "../../../../components/Search";

import {
  joinDateCol,
  lastActiveCol,
} from "../../../../components/Table/config/reusableColumns";

import { TEMPO } from "../../../../constants/navRoutes";

const tempoMembersKeyInfo = {
  columns: [
    { title: "Earn Group", field: "earnGroupName" },
    { title: "Volunteers", field: "activeMembers", type: "numeric" },
    {
      title: "Balance",
      field: "balance",
      type: "numeric",
    },
    {
      title: "Earned",
      field: "givenOut",
      type: "numeric",
    },
    { title: "Accepted", field: "accepted", type: "numeric" },
    {
      title: (
        <div style={{ position: "relative" }}>
          used %
          <PopQuestion>
            <T.Body16R color="gray3">
              the % of Time Credits that an Earn Group has given out that have
              been used (with Earn Groups or Venues)
            </T.Body16R>
          </PopQuestion>
        </div>
      ),
      field: "spent",
      type: "numeric",
    },
  ],
};

const tempoMembersOtherInfo = {
  columns: [
    { title: "Earn Group", field: "earnGroupName" },
    joinDateCol(),
    lastActiveCol,
    {
      title: "programme",
      field: "programme.name",
      disableClick: true,
      render: ({ programme }) => {
        if (programme && programme.id) {
          return (
            <Link to={TEMPO.PROGRAMME_SINGLE.replace(":id", programme.id)}>
              <T.Link14R color="blue">{programme.name}</T.Link14R>
            </Link>
          );
        }
        return "N/A";
      },
    },
    { title: "Lead Contact", field: "name" },
    { title: "Email", field: "email" },
    {
      title: "Phone",
      field: "phoneNumber",
      render: ({ phoneNumber }) => stripLeadingTab(phoneNumber),
    },
  ],
};

const statsText = [
  "total Earn groups",
  "total Volunteers",
  "time credits issued to Earn groups",
  "time credits EARNED",
];

const statsBottomMessage = [
  "all Earn Groups signed up to use Tempo Time Credits",
  "number of volunteers currently registered to earn Time Credits with the Earn Group",
  false,
  false,
];

const EarnGroups = (props) => {
  const {
    getInsightsAction,
    getEarnGroupsAction,
    topStats = {},
    earnGroups = [],
  } = props;
  const [dates, setDates] = useState({ startDate: null, endDate: null });
  const history = useHistory();
  const startDateValue = dates?.startDate?.valueOf();
  const endDateValue = dates?.endDate?.valueOf();
  const [filterDates, setFilterDates] = useState({
    startDate: null,
    endDate: null,
  });
  const [isOpen, setIsOpen] = useState(false);
  const search = useRef(""); // useRef to prevent page re-render on every change

  useEffect(() => {
    getInsightsAction({ category: "earngroups" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!isOpen) {
      getEarnGroupsAction({
        query: search.current,
        startDate: dates.startDate,
        endDate: dates.endDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateValue, endDateValue]);

  const handleSearch = (e) => {
    e.preventDefault();

    getEarnGroupsAction({
      query: search.current,
      startDate: dates.startDate,
      endDate: dates.endDate,
    });
  };

  const setSearch = (value) => {
    search.current = value;
  };

  const clickRow = (event, rowData) =>
    history.push(TEMPO.EARN_GROUP_SINGLE.replace(":id", rowData.userId));

  const { totalEarnGroups, activeMembers, issuedTc, givenOut } = topStats;

  const statsNum = [totalEarnGroups, activeMembers, issuedTc, givenOut];

  return (
    <>
      <S.Container>
        <div>
          <S.Head>
            <S.HeadContent>
              <T.H40Caps color="primary" style={{ margin: 0 }}>
                Tempo EARN GROUPS
              </T.H40Caps>
              <div style={{ display: "flex" }}>
                {statsText.map((e, i) => (
                  <S.StatsWrapper key={e}>
                    <Stats
                      size="s"
                      statNum={statsNum[i] || "0"}
                      statNumColor="pink"
                      statText={e}
                      style={{ width: 120 }}
                      isLoading={topStats.loading}
                      bottomMessage={statsBottomMessage[i]}
                    />
                  </S.StatsWrapper>
                ))}
              </div>
            </S.HeadContent>
            <S.ImageWrapper>
              <Image image="painting" alt="painting" height={210} width={220} />
            </S.ImageWrapper>
          </S.Head>
          <S.TableContainer>
            <S.BtnContainer>
              <Button outline onClick={() => setIsOpen(true)}>
                Export Data
              </Button>
            </S.BtnContainer>
            <S.FilterContainer>
              <T.Body16B color="midnight" m="8">
                Filter by sign up date
              </T.Body16B>
              <FilterByDate dates={dates} setDates={setDates} />
              <T.Body16B color="midnight" m="8">
                Search
              </T.Body16B>
            </S.FilterContainer>
            <S.SearchWrapper>
              <Search
                handleSearch={handleSearch}
                // search={search}
                setSearch={setSearch}
                loading={earnGroups.loading}
              />
            </S.SearchWrapper>
            {earnGroups.loading && (
              <Table
                color="blue"
                tabbedData={[
                  {
                    tabText: "key info",
                    data: earnGroups.data,
                    columns: tempoMembersKeyInfo.columns,
                  },
                  {
                    tabText: "other",
                    data: earnGroups.data,
                    columns: tempoMembersOtherInfo.columns,
                  },
                ]}
                onRowClick={clickRow}
                isLoading
                hideSearch
              />
            )}
            {!earnGroups.loading && (
              <Table
                color="blue"
                tabbedData={[
                  {
                    tabText: "key info",
                    data: earnGroups.data,
                    columns: tempoMembersKeyInfo.columns,
                  },
                  {
                    tabText: "other",
                    data: earnGroups.data,
                    columns: tempoMembersOtherInfo.columns,
                  },
                ]}
                onRowClick={clickRow}
                hideSearch
              />
            )}
          </S.TableContainer>
        </div>
      </S.Container>
      <Modal
        modalOpen={isOpen}
        handleCloseModal={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
        custom
        width="690px"
      >
        <S.ModalContent>
          <T.H20 color="blue" mb={20}>
            Export data{" "}
          </T.H20>

          <T.Body16B color="midnight" mb="8">
            Filter by joining date
          </T.Body16B>
          <FilterByDate dates={filterDates} setDates={setFilterDates} />
          <DownloadFile
            url={`/csv/earn-groups?startDate=${filterDates?.startDate?.format(
              "YYYY-MM-DD",
            )}&endDate=${filterDates?.endDate?.format("YYYY-MM-DD")}`}
          />
        </S.ModalContent>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  topStats: state.staffInsights,
  earnGroups: state.staffEarnGroups,
});

const mapActionsToProps = {
  getInsightsAction: getInsights,
  getEarnGroupsAction: getEarnGroups,
};

export default connect(mapStateToProps, mapActionsToProps)(EarnGroups);
