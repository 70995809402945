import {
  GET_PROGRAMME_MANAGERS_LOADING_START,
  GET_PROGRAMME_MANAGERS_LOADING_END,
  GET_PROGRAMME_MANAGERS_SUCCESS,
  GET_PROGRAMME_MANAGERS_FAIL,
  ADD_PROGRAMME_MANAGER_LOADING_START,
  ADD_PROGRAMME_MANAGER_LOADING_END,
  ADD_PROGRAMME_MANAGER_SUCCESS,
  ADD_PROGRAMME_MANAGER_FAIL,
  DELETE_PROGRAMME_MANAGER_LOADING_START,
  DELETE_PROGRAMME_MANAGER_LOADING_END,
  DELETE_PROGRAMME_MANAGER_SUCCESS,
  DELETE_PROGRAMME_MANAGER_FAIL,
} from "../../../../../redux/types";

const initState = {
  managers: [],
  getLoading: true,
  getError: null,
  addLoading: false,
  addError: null,
  deactivateLoading: false,
  deactivateError: null,
};

const accessPermissions = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PROGRAMME_MANAGERS_LOADING_START:
      return {
        ...state,
        getLoading: true,
        getError: null,
      };
    case GET_PROGRAMME_MANAGERS_LOADING_END:
      return {
        ...state,
        getLoading: false,
      };
    case GET_PROGRAMME_MANAGERS_SUCCESS:
      return {
        ...state,
        managers: payload,
        getLoading: false,
      };
    case GET_PROGRAMME_MANAGERS_FAIL:
      return {
        ...state,
        getLoading: false,
        getError: payload,
      };

    case ADD_PROGRAMME_MANAGER_LOADING_START:
      return {
        ...state,
        addLoading: true,
        addError: null,
      };
    case ADD_PROGRAMME_MANAGER_LOADING_END:
      return {
        ...state,
        addLoading: false,
      };
    case ADD_PROGRAMME_MANAGER_SUCCESS:
      return {
        ...state,
        managers:
          payload && payload.id ? [...state.managers, payload] : state.managers,
        addLoading: false,
      };
    case ADD_PROGRAMME_MANAGER_FAIL:
      return {
        ...state,
        addLoading: false,
        addError: payload,
      };

    case DELETE_PROGRAMME_MANAGER_LOADING_START:
      return {
        ...state,
        deactivateLoading: true,
        deactivateError: null,
        getLoading: true,
      };
    case DELETE_PROGRAMME_MANAGER_LOADING_END:
      return {
        ...state,
        getLoading: false,
        deactivateLoading: false,
      };
    case DELETE_PROGRAMME_MANAGER_SUCCESS:
      return {
        ...state,
        managers: state.managers.filter((i) => i.id !== payload),
        getLoading: false,
        deactivateLoading: false,
      };
    case DELETE_PROGRAMME_MANAGER_FAIL:
      return {
        ...state,
        deactivateLoading: false,
        getLoading: false,
        deactivateError: payload,
      };

    default:
      return state;
  }
};

export default accessPermissions;
