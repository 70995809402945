import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import GoBack from "../../../../components/GoBack";
import * as T from "../../../../components/Typograpy";
import Table from "../../../../components/FilterTable";
import Button, { Regular } from "../../../../components/Button";
import Stats from "../../../../components/Stats";
import TCBalance from "../../../../components/TCBalance";
import { Row, Col } from "../../../../components/Grid";
import DownloadFile from "../../../../components/DownloadFile";
import {
  programmeEarnGroupsColumns,
  programmeEarnGroupsColumnsOther,
} from "./tableConfig";
import { TEMPO } from "../../../../constants/navRoutes";
import {
  getProgrammeById,
  getProgrammeEarnGroup,
} from "../SingleProgramme/actions";
import FilterByDate from "../../../../components/FilterByDate";
import Modal from "../../../../components/Modal";
import * as S from "./style";

const statsText = [
  "total earn groups",
  "Active earn groups",
  " active volunteers",
  "issued to earn groups",
  "time credits earned",
  "time credits accepted",
];

const ProgrammeEarnGroups = ({
  programme: { name },
  balance = "0",
  earnGroups: activeEarnGroups,
  totalEarnGroups,
  activeMembers,
  issued,
  earned,
  timeCreditsAccepted,
  programmeEarnGroups,
  earnGroupsLoading,
  getProgrammeByIdAction,
  getProgrammeEarnGroupAction,
  issuedToMemberDirectly,
}) => {
  const StatsNumbers = [
    totalEarnGroups,
    activeEarnGroups,
    activeMembers,
    issued - issuedToMemberDirectly,
    earned,
    timeCreditsAccepted,
  ];

  const statsHelperText = [
    "the number of Earn Groups that have been part of this programme",
    "the number of Earn Groups that are currently part of this programme",
    "the number of volunteers who are currently signed up to earn Time Credits with the Earn Groups currently part of this programme",
    null,
    null,
    null,
  ];

  const history = useHistory();
  const { id } = useParams();
  const [dates, setDates] = useState({ startDate: null, endDate: null });
  const startDateValue = dates?.startDate?.valueOf();
  const endDateValue = dates?.endDate?.valueOf();
  const [filterDates, setFilterDates] = useState({
    startDate: null,
    endDate: null,
  });
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    getProgrammeByIdAction(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  useEffect(() => {
    if (!isOpen) {
      getProgrammeEarnGroupAction({
        programmeId: id,
        startDate: dates.startDate,
        endDate: dates.endDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateValue, endDateValue]);
  const clickRow = (e, rowData) => {
    history.push(TEMPO.EARN_GROUP_SINGLE.replace(":id", rowData.id));
  };

  const issueCredits = (rowData) =>
    history.push(TEMPO.ISSUE_CREDITS_EARN_GROUP.replace(":userId", rowData.id));

  // filter out the EG that are deactivated and have no transactions related to the programme
  const filterNonActiveEG = (arr) =>
    arr.filter(
      ({ noProgrammeTransactions, programme }) =>
        programme.status === "activated" ||
        (programme.status === "deactivated" &&
          Number(noProgrammeTransactions > 0)),
    );

  return (
    <>
      <S.Head>
        <Row>
          <Col w={[4, 8, 8]}>
            <GoBack showText p="0 0 20px 0" />
            <T.H40Caps color="midnight" mb="20">
              {name}
            </T.H40Caps>
            <T.H30Caps color="pink" mb="20">
              earn groups
            </T.H30Caps>
          </Col>
          <Col
            w={[4, 4, 4]}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "20px",
            }}
          >
            <TCBalance color="cyan" credits={balance} />
          </Col>
        </Row>
        <Row mt={7}>
          {statsText.map((e, i) => (
            <Col w={[4, 2, 2]}>
              <Stats
                size="s"
                statNum={StatsNumbers[i] || "0"}
                statNumColor="pink"
                statText={e || "N/A"}
                statTextColor="primary"
                style={{ width: "auto" }}
                width="auto"
                bottomMessage={statsHelperText[i]}
              />
            </Col>
          ))}
        </Row>
        <S.TableContainer>
          <S.BtnContainer>
            <Regular
              primary
              customWidth="fit-content"
              style={{ padding: "0 25px" }}
              bgColor="blue"
              mr="10"
              onClick={() =>
                history.push(TEMPO.PROGRAMME_INVITE_GROUPS.replace(":id", id))
              }
            >
              Add Earn Group
            </Regular>
            <S.CsvExport>
              <Regular
                outline
                textColor="blue"
                mr="0"
                light
                onClick={() => setIsOpen(true)}
              >
                Export Data
              </Regular>
            </S.CsvExport>
          </S.BtnContainer>
          <S.FilterContainer>
            <T.Body16B color="midnight" m="8">
              Filter by date
            </T.Body16B>
            <FilterByDate dates={dates} setDates={setDates} />
            <T.Body16B color="midnight" m="8">
              Search
            </T.Body16B>
          </S.FilterContainer>
          <Table
            isLoading={earnGroupsLoading}
            tabs
            color="blue"
            tabbedData={[
              {
                tabText: "key info",
                data: filterNonActiveEG(programmeEarnGroups),
                columns: programmeEarnGroupsColumns(issueCredits),
              },
              {
                tabText: "other",
                data: filterNonActiveEG(programmeEarnGroups),
                columns: programmeEarnGroupsColumnsOther(),
              },
            ]}
            onRowClick={clickRow}
          />
        </S.TableContainer>
      </S.Head>
      <Modal
        modalOpen={isOpen}
        handleCloseModal={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
        custom
        width="690px"
      >
        <S.ModalContent>
          <T.H20 color="blue" mb={20}>
            Export data{" "}
          </T.H20>

          <T.Body16B color="midnight" m="8">
            Filter by joining date
          </T.Body16B>
          <FilterByDate dates={filterDates} setDates={setFilterDates} />
          <DownloadFile
            url={`/csv/programmes/${id}?type=earn-groups&startDate=${filterDates?.startDate?.format(
              "YYYY-MM-DD",
            )}&endDate=${filterDates?.endDate?.format("YYYY-MM-DD")}`}
          />
        </S.ModalContent>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state.staffSingleProgramme,
});

const mapActionsToProps = {
  getProgrammeByIdAction: getProgrammeById,
  getProgrammeEarnGroupAction: getProgrammeEarnGroup,
};

export default connect(mapStateToProps, mapActionsToProps)(ProgrammeEarnGroups);
