import React, { useEffect } from "react";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import * as S from "./style";

import ChangeAccount from "../../../components/ChangeAccount";
import TCBalance from "../../../components/TCBalance";
import MembersSection from "../../EarnGroupRoutes/Members/AllMembersLogic";
import TransactionSection from "../../EarnGroupRoutes/Transactions/AllTransactionsLogic";

import StatSection from "./Stats";
import LinkSection from "./Links";

import { getUserCurrentTc } from "../../../redux/actions/userActions";
import t from "../../../utils/helpers/translator";
import { navRoutes } from "../../../constants";

const EarnGroupDashboard = ({
  user,
  profile,
  credits,
  getUserCurrentTcAction,
  language,
  multipleUsers,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    getUserCurrentTcAction();
  }, [getUserCurrentTcAction]);

  let { name } = profile;
  if (language === "welsh" && profile.welshName) {
    name = profile.welshName;
  }

  return (
    <S.Wrapper>
      <S.Header>
        <span>
          <S.StyledEarnGroup>{name}</S.StyledEarnGroup>
          <S.StyledHeading color="midnight">
            {t("welcomeBack", language)}, {user.firstName}!
          </S.StyledHeading>
          {multipleUsers && (
            <ChangeAccount
              mb={isMobile ? "0px" : "40px"}
              to={navRoutes.GENERAL.SELECT_USER}
            />
          )}
        </span>
        {!isTablet && <TCBalance credits={credits || 0} color="pink" />}
      </S.Header>
      {isMobile ? (
        <>
          <LinkSection isTablet={isTablet} language={language} />
          <StatSection />
        </>
      ) : (
        <>
          <StatSection />
          <LinkSection language={language} />
        </>
      )}
      <MembersSection
        hideSearch
        preview
        headline={t("myMembers", language)}
        isMobile={isMobile}
        hideCSV
      />
      <TransactionSection
        headline={t("myTCHistory", language)}
        preview
        hideSearch
        isMobile={isMobile}
        credits={credits}
        hideCSV
      />
    </S.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  user: { firstName: state.auth.firstName },
  profile: state.auth.profile,
  credits: state.user.tc.tcBalance,
  loadingStats: state.earnGroupDashboard.insights.loading,
  language: state.auth.language,
  multipleUsers: state.auth.multipleUsers,
});

const mapActionToProps = {
  getUserCurrentTcAction: getUserCurrentTc,
};

export default connect(mapStateToProps, mapActionToProps)(EarnGroupDashboard);
