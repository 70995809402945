import moment from "moment";

const getDatesTimesObjectsFromPatterns = (patterns) => {
  if (!patterns || !patterns.length) {
    return [
      {
        dates: {},
        times: {},
        repeats: "none",
      },
    ];
  }

  return patterns.map((pattern) => ({
    dates: {
      startDate: pattern.startDate ? moment(pattern.startDate) : null,
      endDate: pattern.endDate ? moment(pattern.endDate) : null,
    },
    times: {
      startTime: pattern.startTime && pattern.startTime.slice(0, 5),
      endTime: pattern.endTime && pattern.endTime.slice(0, 5),
    },
    repeats: pattern.recurringType,
  }));
};

const sortDatesTimesArray = (array) => {
  if (array.length === 1) return array;

  return array?.sort((a, b) => {
    const startDateComparison =
      a?.dates?.startDate?.toDate() - b?.dates?.startDate?.toDate();

    if (startDateComparison !== 0) {
      // If start dates are different, return the result of the start date comparison
      return startDateComparison;
    }
    // If start dates are equal, compare start times
    return a?.times?.startTime?.localeCompare(b?.times?.startTime);
  });
};

export { getDatesTimesObjectsFromPatterns, sortDatesTimesArray };
