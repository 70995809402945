import { staffPermissions } from "../../../../api-calls";

import {
  GET_STAFF_USERS_LOADING_START,
  GET_STAFF_USERS_LOADING_END,
  GET_STAFF_USERS_SUCCESS,
  GET_STAFF_USERS_FAIL,
  ADD_STAFF_USER_LOADING_START,
  ADD_STAFF_USER_LOADING_END,
  ADD_STAFF_USER_SUCCESS,
  ADD_STAFF_USER_FAIL,
  DEACTIVATE_STAFF_USER_LOADING_START,
  DEACTIVATE_STAFF_USER_LOADING_END,
  DEACTIVATE_STAFF_USER_SUCCESS,
  DEACTIVATE_STAFF_USER_FAIL,
  UPDATE_STAFF_USER_LOADING_START,
  UPDATE_STAFF_USER_LOADING_END,
  UPDATE_STAFF_USER_SUCCESS,
  UPDATE_STAFF_USER_FAIL,
} from "../../../../redux/types";

export const getAccessPermissions = () => async (dispatch) => {
  dispatch({ type: GET_STAFF_USERS_LOADING_START });
  try {
    const { data, error } =
      await staffPermissions.getStaffUsersAndPendingInvitations();
    if (data) {
      const users = [
        // ...data.pendingInvitations.map((e) => ({ ...e, pending: true })),
        ...data.staffUsers,
      ];
      dispatch({ type: GET_STAFF_USERS_SUCCESS, payload: users });
    } else {
      dispatch({
        type: GET_STAFF_USERS_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_STAFF_USERS_FAIL,
      payload: error,
    });
  } finally {
    dispatch({ type: GET_STAFF_USERS_LOADING_END });
  }
};

export const addNewUser =
  ({ email, role, onAddSuccess, onError }) =>
  async (dispatch) => {
    dispatch({ type: ADD_STAFF_USER_LOADING_START });
    try {
      const { error } = await staffPermissions.addStaffUser({
        email,
        role,
      });
      if (!error) {
        dispatch({
          type: ADD_STAFF_USER_SUCCESS,
        });
        onAddSuccess();
      } else {
        dispatch({
          type: ADD_STAFF_USER_FAIL,
          payload: error,
        });
        onError(error.message);
      }
    } catch (error) {
      dispatch({
        type: ADD_STAFF_USER_FAIL,
        payload: error,
      });
    } finally {
      dispatch({ type: ADD_STAFF_USER_LOADING_END });
    }
  };

export const deactivateUser =
  ({ id, onUpdateSuccess, onError }) =>
  async (dispatch) => {
    dispatch({ type: DEACTIVATE_STAFF_USER_LOADING_START });
    try {
      const { error } = await staffPermissions.deactivateStaffUser({
        id,
      });
      if (!error) {
        dispatch({
          type: DEACTIVATE_STAFF_USER_SUCCESS,
          payload: id,
        });
        onUpdateSuccess();
      } else {
        dispatch({
          type: DEACTIVATE_STAFF_USER_FAIL,
          payload: error,
        });
        onError(error.message);
      }
    } catch (error) {
      dispatch({
        type: DEACTIVATE_STAFF_USER_FAIL,
        payload: error,
      });
    } finally {
      dispatch({ type: DEACTIVATE_STAFF_USER_LOADING_END });
    }
  };

export const updateUser =
  ({ id, role, onUpdateSuccess, onError }) =>
  async (dispatch) => {
    dispatch({ type: UPDATE_STAFF_USER_LOADING_START });
    try {
      const { error } = await staffPermissions.updateStaffUser({
        id,
        role,
      });
      if (!error) {
        dispatch({
          type: UPDATE_STAFF_USER_SUCCESS,
          payload: { id, role },
        });
        onUpdateSuccess();
      } else {
        dispatch({
          type: UPDATE_STAFF_USER_FAIL,
          payload: error,
        });
        onError(error.message);
      }
    } catch (error) {
      dispatch({
        type: UPDATE_STAFF_USER_FAIL,
        payload: error,
      });
    } finally {
      dispatch({ type: UPDATE_STAFF_USER_LOADING_END });
    }
  };
