import React from "react";

const TeachingTraining = ({ width, height, color, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    {...props}
  >
    <g clipPath="url(#clip0)" fill={color || "currentColor"}>
      <path d="M22.5 0h-11A1.5 1.5 0 0010 1.5V9H1.5A1.5 1.5 0 000 10.5v10A1.5 1.5 0 001.5 22h1v1.5a.5.5 0 00.5.5c.215 0 .03.08 3.15-2h6.35a1.5 1.5 0 001.5-1.5V13h3.85c3.1 2.07 2.94 2 3.15 2a.5.5 0 00.5-.5V13h1a1.5 1.5 0 001.5-1.5v-10A1.5 1.5 0 0022.5 0zM13 20.5a.5.5 0 01-.5.5H6c-.21 0-.11-.03-2.5 1.565V21.5c0-1-1.885-.17-2.355-.655C.935 20.645 1 21.38 1 10.5a.5.5 0 01.5-.5H10v1.5a1.5 1.5 0 001.5 1.5H13v7.5zm10-9a.5.5 0 01-.5.5H21a.5.5 0 00-.5.5v1.065C18.105 11.97 18.21 12 18 12h-6.5a.5.5 0 01-.5-.5v-10a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v10z" />
      <path d="M7.47 13.325a.5.5 0 00-.94 0l-1.5 4a.502.502 0 10.94.35L6.22 17h1.56l.25.675a.501.501 0 10.94-.35l-1.5-4zM6.595 16L7 14.925 7.405 16h-.81zM19 4h-1.5v-.5a.5.5 0 00-1 0V4H15a.5.5 0 000 1h2.945A3.61 3.61 0 0117 6.73a4.714 4.714 0 01-.42-.5.5.5 0 00-.82.57c.152.218.319.425.5.62a7.083 7.083 0 01-1 .65.5.5 0 00.45.89c.458-.242.89-.53 1.29-.86a7.5 7.5 0 001.275.845.5.5 0 00.45-.89 7.08 7.08 0 01-1-.645A4.5 4.5 0 0018.965 5 .5.5 0 0019 4z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default TeachingTraining;
