import React, { useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";

import * as S from "./style";
import Image from "../../../../components/Image";
import * as T from "../../../../components/Typograpy";
import Stats from "../../../../components/Stats";
import Table from "../../../../components/Table";
import PopQuestion from "../../../../components/PopQuestion";
import Button, { Regular } from "../../../../components/Button";

import { TEMPO } from "../../../../constants/navRoutes";

import { getInsights } from "../insights.action";
import { getSpendVenues } from "../SpendVenues/actions";

import { customDateCol } from "../../../../components/Table/config/reusableColumns";

const tempoSpendVenues = {
  columns: [
    {
      title: "recognition partner",
      field: "partner.name",
      disableClick: true,
      render: (rowData) => (
        <Link to={TEMPO.SPEND_PARTNER.replace(":id", rowData?.partner?.userId)}>
          <T.LinkB14 color="blue">{rowData?.partner?.name}</T.LinkB14>
        </Link>
      ),
    },
    {
      title: "Venue",
      field: "venueName",
      render: (rowData) => (
        <S.CapitalizedSpan>{rowData?.venueName}</S.CapitalizedSpan>
      ),
    },
    { title: "region", field: "region" },
    {
      title: "time credits accepted",
      field: "acceptedTc",
      type: "numeric",
    },
    {
      title: "transactions",
      field: "numberOfTransactions",
      type: "numeric",
    },
    {
      title: (
        <div style={{ position: "relative", width: 70, color: "midnight" }}>
          unique visitors
          <PopQuestion>
            <T.Body16R color="gray3">
              the number of individual Tempo Members who have visited the Venue
            </T.Body16R>
          </PopQuestion>
        </div>
      ),
      field: "uniqueVisitors",
      type: "numeric",
    },
    customDateCol({ title: "date last accepted", field: "dateOfLastAccept" }),
  ],
};

const SpendVenuesWithLiveOffers = ({
  spendVenuesData,
  spendVenues,
  spendPartners,
  spentTcWithVenues,
  getInsightsAction,
  getSpendVenuesAction,
  insightsLoading,
}) => {
  const history = useHistory();
  useEffect(() => {
    getInsightsAction({
      category: "spend-venues",
      liveOffersOnly: true,
    });
    getSpendVenuesAction({ liveOffersOnly: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickRow = (event, rowData) =>
    history.push(TEMPO.SPEND_VENUES_SINGLE.replace(":id", rowData.id));

  const statsNum = [spendVenues, spendPartners, spentTcWithVenues];
  const statsText = [
    "Venues",
    "Recognition partners",
    "time credits used with venues",
  ];
  return (
    <S.Container>
      <div>
        <S.Head>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <T.H40Caps color="primary" style={{ margin: 0 }}>
              venues with live offers
            </T.H40Caps>
            <div style={{ display: "flex" }}>
              {statsText.map((e, i) => (
                <div key={e} style={{ marginTop: 50, marginRight: 15 }}>
                  <Stats
                    size="s"
                    statNum={statsNum[i]}
                    statNumColor="pink"
                    statText={e}
                    isLoading={insightsLoading}
                  />
                </div>
              ))}
            </div>
          </div>
          <S.ImageWrapper>
            <Image image="gallery" alt="gallery" height={210} width={220} />
          </S.ImageWrapper>
        </S.Head>
        <S.TableContainer>
          <S.BtnContainer>
            <Regular
              primary
              bgColor="midnight"
              m="0 10px 10px 0"
              onClick={() => history.push(TEMPO.CREATE_SPEND_PARTNER)}
              customWidth="fit-content"
              style={{ padding: "0 25px" }}
            >
              Add a Partner
            </Regular>
            <Regular
              primary
              bgColor="blue"
              m="0 10px 10px 0"
              onClick={() =>
                history.push(TEMPO.CREATE_SPEND_VENUE_SELECT_VENUE)
              }
              customWidth="fit-content"
              style={{ padding: "0 25px" }}
            >
              Add a Venue
            </Regular>
            <S.CsvExport href="/api/csv/spend-venues?liveOffersOnly=true">
              <Button outline>Export Data</Button>
            </S.CsvExport>
          </S.BtnContainer>
          {spendVenuesData.loading && (
            <Table
              color="blue"
              data={spendVenuesData?.data || []}
              columns={tempoSpendVenues.columns}
              isLoading
            />
          )}
          {!spendVenuesData.loading && (
            <Table
              color="blue"
              data={spendVenuesData?.data || []}
              columns={tempoSpendVenues.columns}
              onRowClick={clickRow}
            />
          )}
        </S.TableContainer>
      </div>
    </S.Container>
  );
};

const mapStateToProps = (state) => ({
  ...state.staffInsights,
  spendVenuesData: state.staffSpendVenues,
  insightsLoading: state.staffInsights.loading,
});

const mapActionsToProps = {
  getInsightsAction: getInsights,
  getSpendVenuesAction: getSpendVenues,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(SpendVenuesWithLiveOffers);
