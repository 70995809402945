import React from "react";

import Modal from "../../../components/Modal";
import * as T from "../../../components/Typograpy";
import t from "../../../utils/helpers/translator";

const RemoveMemberModal = ({
  modalOpen,
  confirmFun,
  handleCloseModal,
  memberName,
  earnGroupName,
  language,
}) => (
  <Modal
    modalOpen={modalOpen}
    handleCloseModal={handleCloseModal}
    confirmFun={confirmFun}
    buttonText={t("removeMemberConfirmationLabel", language)}
    cancelButtonText={t("removeMemberCancelLabel", language)}
    withCancel
    width="640px"
  >
    <T.H30 color="blue" m="0" mb="25">
      {t("areYouSure", language)}
    </T.H30>
    <T.Body16R>
      <p
        dangerouslySetInnerHTML={{
          __html: t("removeMemberConfirmation", language, [
            `<b>${memberName}</b>`,
            `<b>${earnGroupName}</b>`,
          ]),
        }}
      />
      <p>{t("immediateEffect", language)}</p>
      <p>{t("removeMemberInstruction", language)}</p>
    </T.Body16R>
  </Modal>
);

export default RemoveMemberModal;
