import React from "react";

import * as S from "./style";

const ProgressBar = ({ number, current, color, handleClick, margin }) => (
  <S.Wrapper margin={margin}>
    {Array.from({ length: number }, (v, i) => (
      <S.Circle
        index={i}
        current={current}
        color={color}
        onClick={(event) => handleClick(event, i)}
        clickable={handleClick}
      />
    ))}
  </S.Wrapper>
);

export default ProgressBar;
