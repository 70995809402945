import { CHANGE_COLOR, IS_MOBILE } from "../types";

const initialState = {
  color: "blue",
  isMobile: false,
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_COLOR:
      return { ...state, color: action.payload };
    case IS_MOBILE:
      return { ...state, isMobile: action.payload };
    default:
      return state;
  }
};

export default uiReducer;
