import {
  GET_SPEND_ACTIVITIES_TO_ACCEPT_SUCCESS,
  GET_SPEND_ACTIVITIES_TO_ACCEPT_FAIL,
  GET_SPEND_ACTIVITIES_TO_ACCEPT_LOADING_START,
  GET_SPEND_ACTIVITIES_TO_ACCEPT_LOADING_END,
  ACCEPT_TC_LOADING_START,
  ACCEPT_TC_LOADING_END,
  ACCEPT_TC_SUCCESS,
  ACCEPT_TC_FAIL,
  RESET_ACCEPT_TC_POST_ERROR,
} from "../../../redux/types";

const initState = {
  getLoading: false,
  postLoading: false,
  getError: {},
  postError: {},
  activities: [],
  membershipId: null,
};

const spendVenuesPublicReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SPEND_ACTIVITIES_TO_ACCEPT_LOADING_START:
      return {
        ...state,
        getLoading: true,
      };
    case GET_SPEND_ACTIVITIES_TO_ACCEPT_LOADING_END:
      return {
        ...state,
        getLoading: false,
      };

    case ACCEPT_TC_LOADING_START:
      return {
        ...state,
        postLoading: true,
      };
    case ACCEPT_TC_LOADING_END:
      return {
        ...state,
        postLoading: false,
      };

    case GET_SPEND_ACTIVITIES_TO_ACCEPT_SUCCESS:
      return {
        ...state,
        activities: payload,
      };
    case GET_SPEND_ACTIVITIES_TO_ACCEPT_FAIL:
      return {
        ...state,
        getError: payload,
      };

    case ACCEPT_TC_SUCCESS:
      return {
        ...state,
        data: payload,
        postError: {},
      };
    case ACCEPT_TC_FAIL:
      return {
        ...state,
        postError: payload.error,
        membershipId: payload.membershipId,
      };
    case RESET_ACCEPT_TC_POST_ERROR:
      return {
        ...state,
        postError: {},
      };

    default:
      return state;
  }
};

export default spendVenuesPublicReducer;
