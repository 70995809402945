import { styled } from "@material-ui/core/styles";

export const Wrapper = styled("section")(({ theme, success }) => ({
  width: "100%",
  flex: "1",
  flexDirection: "column",
  maxWidth: success && "400px",
  alignItems: success ? "center" : "flex-start",
  margin: success && "0 auto",
  marginBottom: theme.spacing(9),
}));

export const Header = styled("header")(({ theme }) => ({
  marginBottom: theme.spacing(9),
}));

export const ImageWrapper = styled("span")(({ theme }) => ({
  display: "none",

  [theme.breakpoints.tablet]: {
    display: "block",
    position: "absolute",
    right: theme.spacing(5),
    top: theme.spacing(8),
    zIndex: -1,
  },
}));

export const Error = styled("p")(({ theme }) => ({
  width: "100%",
  color: theme.colors.pink,
  fontSize: "0.875rem",
  textAlign: "left",
  fontWeight: "normal",
  marginBottom: theme.spacing(2),
}));
