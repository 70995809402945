import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment-recur";

import * as S from "../../style";
import * as T from "../../../../../components/Typograpy";

import Loading from "../../../../../components/Loading";
import { Row, Col } from "../../../../../components/Grid";
import TimeListing from "../../../../../components/TimeListing";
import Warning from "../../../../../components/Message/Warning";

import calendarFormatter from "../../../../../utils/helpers/calendarFormatter";
import translate from "../../../../../utils/helpers/translator";

const handleRecurring = (
  date,
  startTime,
  endTime,
  type,
  recurLimit,
  rangeStart,
  finalEndDate,
) => {
  let startDateArr = [];

  const updatedEndDate = finalEndDate || moment().add(5, "Y");
  const formattedEndDate = moment(updatedEndDate);

  const inPast = moment(date).diff(moment(), "days") < 0;

  if (type === "monthly") {
    // get which first week of the month it should repeat
    const monthlyStartDates = moment
      .recur({ end: formattedEndDate })
      .every(moment(rangeStart).day())
      .daysOfWeek()
      .every(moment(rangeStart).monthWeekByDay())
      .weeksOfMonthByDay();

    if (inPast) {
      monthlyStartDates.fromDate(moment()).endDate(formattedEndDate);
    } else {
      monthlyStartDates.fromDate(moment(date)).endDate(formattedEndDate);
    }

    const altArr = monthlyStartDates.next(recurLimit);

    const dayNum = moment(date).day();
    const startRangeDayNum = moment(rangeStart).day();
    const dayBefore = dayNum < startRangeDayNum;

    altArr.forEach((dateEl) =>
      startDateArr.push(moment(dateEl).day(dayBefore ? dayNum + 7 : dayNum)),
    );

    if (!inPast) {
      startDateArr.push(moment(date));
    }
  } else if (type === "weekly") {
    const altDays = moment.recur().every(moment(date).day()).daysOfWeek();

    if (inPast) {
      altDays.fromDate(moment()).endDate(formattedEndDate);
    } else {
      altDays.fromDate(moment(date)).endDate(formattedEndDate);
    }

    startDateArr = altDays.next(recurLimit);

    if (!inPast) {
      startDateArr.push(moment(date));
    }
  }

  const dateArr = startDateArr
    .filter(
      (item) =>
        moment(updatedEndDate) >= item ||
        moment(updatedEndDate).format("l") === item.format("l"),
    )
    .map((item) => ({
      date: item,
      startTime,
      endTime,
      recurringType: type,
    }));

  return dateArr;
};

const ActivityDates = ({
  activityName,
  venueName,
  dates,
  firstRestrictionReached,
  restrictionsText,
  finalEndDate,
  isAvailableAnyTime,
  lang,
}) => {
  const [datesToShow, setDatesToShow] = useState([]);
  const [datesLoading, setDatesLoading] = useState(false);
  const [limit, setLimit] = useState(6);

  useEffect(() => {
    if (isAvailableAnyTime) return;

    setDatesLoading(true);
    // LIMIT THE NUMBER OF DATE OBJECTS TO GO THROUGH
    let dateCounter = 0;
    const datesToRender = [];
    for (let i = 0; dates[i] && dateCounter < limit; i += 1) {
      const { startDate, endDate } = dates[i];
      const momentStart = moment(startDate);
      const momentEnd = moment(endDate);

      const startDateInPast = moment().diff(momentStart, "days") > 0;

      const range = moment.duration(momentEnd.diff(momentStart)).asDays() + 1;
      if (!startDateInPast) {
        datesToRender.push({ ...dates[i], range });
        dateCounter += range;
      }
    }

    // TURN DATES INTO ONE ARRAY
    // eslint-disable-next-line no-unused-vars
    let datesLeftToRender = limit;
    const totalDates = datesToRender
      .map((dateObj) => {
        const { startDate, startTime, endDate, endTime, range, recurringType } =
          dateObj;
        const recurring = ["weekly", "monthly"].includes(recurringType);

        if (range > 1) {
          // check if in past and if so modify start date
          const inPast = moment(startDate).diff(moment(), "days") < 0;
          const updatedStartDate = inPast ? moment() : moment(startDate);
          const actualRange =
            moment.duration(moment(endDate).diff(updatedStartDate)).asDays() +
            1;
          const datesArr = [
            {
              date: moment(startDate),
              startTime,
              endTime,
              recurringType,
              rangeStart: startDate,
            },
          ];

          while (datesArr.length < range) {
            const updatedDate = moment(startDate)
              .clone()
              .add(datesArr.length, "days");
            datesArr.push({
              date: updatedDate,
              startTime,
              endTime,
              recurringType,
              rangeStart: startDate,
            });
          }

          if (recurring) {
            const recurringDates = datesArr
              .map((date) =>
                handleRecurring(
                  date.date,
                  date.startTime,
                  date.endTime,
                  date.recurringType,
                  Math.ceil(limit / actualRange),
                  date.rangeStart,
                  finalEndDate,
                ),
              )
              .reduce((acc, val) => acc.concat(val), []);
            return recurringDates;
          }

          const updatedDates = datesArr.filter(
            (date) => moment().diff(date.date, "days") <= 0,
          );
          datesLeftToRender -= updatedDates.length;

          return updatedDates;
        }

        // eslint-disable-next-line no-unused-vars
        datesLeftToRender -= recurring ? 0 : range;
        return recurring
          ? handleRecurring(
              startDate,
              startTime,
              endTime,
              recurringType,
              limit,
              startDate,
              finalEndDate,
            )
          : {
              date: moment(startDate),
              startTime,
              endTime,
              recurringType,
            };
      })
      .reduce((acc, val) => acc.concat(val), []);

    const sortedByDate = totalDates
      .sort(
        (a, b) =>
          moment(a.date).valueOf() +
          moment(a.startTime, "hh:mm:ss").valueOf() -
          moment(b.date).valueOf() +
          moment(b.startTime, "hh:mm:ss").valueOf(),
      )
      .filter((obj, index) => {
        if (index === 0) return true;
        const prev = totalDates[index - 1];
        if (
          obj.startTime === prev.startTime &&
          obj.endTime === prev.endTime &&
          obj.date === prev.date
        )
          return false;
        return true;
      });

    setDatesToShow(sortedByDate);
    setDatesLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit]);

  const handleLoadMore = () => {
    const newLimit = limit + 6;
    setLimit(newLimit);
  };

  const renderDateTitle = (date, index, datesArr) => {
    if (index === 0)
      return (
        <T.H14CapsS m="0 0 10px 0" color="gray2">
          {calendarFormatter(date, lang)}
        </T.H14CapsS>
      );
    if (index > 0 && date !== datesArr[index - 1].date)
      return (
        <T.H14CapsS m="20px 0 10px 0" color="gray2">
          {calendarFormatter(date, lang)}
        </T.H14CapsS>
      );
  };

  if (datesLoading) return <Loading m="60px 0" />;

  return (
    <>
      {isAvailableAnyTime ? (
        <Row mb={6} mt={6}>
          <Col w={[4, 12, 12]} style={{ padding: 0 }}>
            <TimeListing
              title={activityName}
              subtitle={venueName}
              m="0"
              lang={lang}
            />
            {(firstRestrictionReached || restrictionsText) && (
              <Warning
                header={firstRestrictionReached || restrictionsText || ""}
                warningType="dateRestriction"
                p="30px 30px 30px 5px"
              />
            )}
          </Col>
        </Row>
      ) : (
        <Row mb={5}>
          <Col w={[4, 12, 12]} style={{ padding: 0 }}>
            {firstRestrictionReached && (
              <Warning
                header={translate("currentlyUnavailable", lang).toUpperCase()}
                warningType="activityRestriction"
                activityRestriction={firstRestrictionReached}
                p="0 0 30px 0"
              />
            )}
            {datesToShow &&
              datesToShow.slice(0, limit).map((date, index) => (
                <>
                  {renderDateTitle(date.date, index, datesToShow)}
                  <TimeListing
                    startTime={date.startTime}
                    endTime={date.endTime}
                    title={activityName}
                    subtitle={venueName}
                    m="0 0 30px 0"
                  />
                </>
              ))}
            {limit <= datesToShow.length && (
              <S.LoadMoreButton>
                <T.LinkB14 onClick={handleLoadMore} color="blue">
                  {translate("loadMore", lang)}
                </T.LinkB14>
              </S.LoadMoreButton>
            )}
            {restrictionsText && (
              <Warning
                header={restrictionsText}
                warningType="dateRestriction"
                p="30px 30px 30px 5px"
              />
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default ActivityDates;
