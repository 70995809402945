import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import ReactDOM from "react-dom";
import theme from "../../utils/theme";

import Button from "../Button";

const Alert = ({ title, description, onOk, onCancel, okText, cancelText }) => {
  const [open, setOpen] = useState(true);
  const _onOk = () => {
    if (onOk instanceof Function) onOk();
    setOpen(false);
  };

  const _onCancel = () => {
    if (onCancel instanceof Function) onCancel(false);
    setOpen(false);
  };

  return (
    <Router>
      <Dialog
        open={open}
        fullWidth
        onClose={_onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {(cancelText || onCancel) && (
            <Button onClick={_onCancel} primary>
              {cancelText || "cancel"}
            </Button>
          )}

          <Button onClick={_onOk} primary autoFocus>
            {okText || "Agree"}
          </Button>
        </DialogActions>
      </Dialog>
    </Router>
  );
};

Alert.fire = ({ title, description, onOk, onCancel, okText, cancelText }) => {
  ReactDOM.render(
    <MuiThemeProvider theme={theme}>
      <Alert
        title={title}
        description={description}
        onOk={onOk}
        onCancel={onCancel}
        okText={okText}
        cancelText={cancelText}
      />
    </MuiThemeProvider>,
    document.getElementById("dialog-root"),
  );
};

export default Alert;
