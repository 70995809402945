import { styled } from "@material-ui/core/styles";

export const Wrapper = styled("div")(({ width, styling, m, justify }) => ({
  display: "flex",
  justifyContent: justify || "center",
  alignItems: "center",
  width,
  margin: m || "0",
  ...styling,
}));

export const TagButton = styled("button")(
  ({ width, theme, color, border, selected, m }) => ({
    height: "30px",
    border: `2px ${theme.colors[border] || theme.colors.blue} solid`,
    borderRadius: "8px",
    background: selected ? theme.colors[color] || theme.colors.blue : "white",
    width: width || "auto",
    cursor: "pointer",
    outline: "none",
    margin: m || "0",

    [theme.breakpoints.mobileL]: {
      height: "40px",
    },
  }),
);

export default Wrapper;
