import axios from "axios";
import handleError from "./handle-errors";

const MEMBERS_BASE = "/members";

const getMembers = async ({
  basic,
  options,
  limit,
  offset,
  category,
  id,
  startDate,
  endDate,
  query,
} = {}) => {
  try {
    const { data } = await axios.get(MEMBERS_BASE, {
      params: {
        basic,
        limit,
        offset,
        category,
        id,
        startDate,
        endDate,
        query,
      },
    });

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getMemberById = async ({ memberId, basic, id, options } = {}) => {
  try {
    const { data } = await axios.get(`${MEMBERS_BASE}/${memberId || id}`, {
      params: { basic },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getMemberTransactions = async ({ memberId, options } = {}) => {
  try {
    const { data } = await axios.get(
      `${MEMBERS_BASE}/${memberId}/transactions`,
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const deleteMemberById = async ({ memberId, options } = {}) => {
  try {
    const { data } = await axios.delete(`${MEMBERS_BASE}/${memberId}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updateMemberProfile = async ({ formData, options } = {}) => {
  try {
    const { data } = await axios.patch(`${MEMBERS_BASE}/profile`, formData);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getMyGroups = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${MEMBERS_BASE}/my-groups`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export {
  getMembers,
  getMemberById,
  getMemberTransactions,
  deleteMemberById,
  updateMemberProfile,
  getMyGroups,
};
