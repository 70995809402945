import { fields, createSchema, validate as _validate } from "..";

const { email, firstName, lastName, phoneNumber, spendPartnerName, password } =
  fields;

const createForm = createSchema({
  firstName,
  lastName,
  email,
  name: spendPartnerName,
  password,
  phoneNumber,
});
const editForm = createSchema({
  firstName,
  lastName,
  email,
  name: spendPartnerName,
  phoneNumber,
});

const validate = (data) => {
  if (data.isNew) {
    return _validate(createForm, data);
  }
  return _validate(editForm, data);
};

export default validate;
