import React from "react";

const DevelopingServices = ({ width, height, color, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    {...props}
  >
    <path
      d="M2.625 16.125c-.525 0-.488.75 0 .75.525 0 .487-.75 0-.75zM10.387 14.25a.849.849 0 00.863-.863V9.862c0-.9-1.162-1.162-1.575-.487-.6.863-1.388.75-2.55.75-.825 0-1.5.675-1.5 1.5 0 .75.525 1.35 1.237 1.463.713 1.8.675 1.912 1.013 1.912a.37.37 0 00.338-.525l-.525-1.35c2.4 0 1.65 1.125 2.7 1.125zm0-4.5c0-.525 0-.488 0 0zm-2.1 1.125c.825 0 1.575-.412 2.025-1.088.038-.075.188-.075.188.075 0 3.788.037 3.638-.113 3.638-.037 0-.075 0-.112-.037-.412-.675-1.162-1.088-2.025-1.088 0-1.688 0-1.5.037-1.5zm-1.912.75c0-.412.338-.75.75-.75H7.5v1.5h-.375a.752.752 0 01-.75-.75zM17.475 6.938c.375.3.787.487 1.237.562.038 0 .038-.075.038 1.162 0 .488.75.488.75 0 0-1.762-.188-.825.9-1.462 2.363-1.35 1.425-4.95-1.275-4.95-2.475 0-3.563 3.112-1.65 4.688zm1.65-2.063c-.488 0-.488-.75 0-.75s.488.75 0 .75zM20.1 6.45c-.638.375-1.388.375-1.988 0 .263-1.125 1.763-1.088 1.988 0zM19.125 3c1.5 0 2.363 1.65 1.575 2.888-.15-.338-.375-.563-.675-.75.525-.75 0-1.763-.938-1.763-.9 0-1.424 1.013-.937 1.763a1.66 1.66 0 00-.675.75C16.8 4.724 17.587 3 19.125 3z"
      fill={color || "currentColor"}
    />
    <path
      d="M19.126 9.375c-1.313 0-2.4.975-2.588 2.25h-1.537v-6.75a2.609 2.609 0 00-2.625-2.625h-7.5A2.609 2.609 0 002.25 4.875V15c0 .488.75.488.75 0V4.875C3 3.825 3.826 3 4.876 3h7.5c1.05 0 1.875.825 1.875 1.875v6.75h-.75v-4.5c0-.487-.75-.487-.75 0v4.5h-.375c-.488 0-.488.75 0 .75h.375c0 7.35 0 6.825-.075 6.975-.15.225.3.15-7.8.15a.376.376 0 01-.375-.375c0-15-.113-14.475.225-14.588.112-.037 0-.037 1.425-.037.487.938.75.75 3.9.75.412 0 .825-.263 1.012-.6.113-.188-.112-.15 1.35-.15.188 0 .375.15.375.375v.75c0 .487.75.487.75 0v-.713c0-.712-.525-1.162-1.125-1.162-1.912 0-1.65-.15-1.987.563-.075.15-.225.187-.338.187-2.925 0-3 .075-3.15-.188-.412-.675-.15-.562-2.062-.562-.6 0-1.125.45-1.125 1.162 0 15.038-.113 14.438.225 14.888.487.637.525.45 8.4.45.6 0 1.125-.45 1.125-1.163v-6.712h.75v6.75c0 1.05-.825 1.875-1.875 1.875h-7.5A1.857 1.857 0 013 19.125V18c0-.488-.75-.488-.75 0v1.125a2.609 2.609 0 002.625 2.625h7.5A2.609 2.609 0 0015 19.125v-6.75h1.537c.3 2.025 2.7 2.925 4.238 1.688 1.95-1.575.825-4.688-1.65-4.688zm0 2.625c-.488 0-.488-.75 0-.75.487 0 .487.75 0 .75zm.975 1.575c-.638.375-1.388.375-1.988 0 .263-1.125 1.763-1.087 1.988 0zm.6-.563c-.15-.337-.375-.562-.675-.75.525-.75 0-1.762-.938-1.762-.9 0-1.425 1.012-.937 1.762a1.66 1.66 0 00-.675.75c-.75-1.162.037-2.887 1.575-2.887 1.575 0 2.437 1.65 1.65 2.887z"
      fill={color || "currentColor"}
    />
    <path
      d="M21.262 19.5c-.337 0-.337.262-.562.637a1.799 1.799 0 00-.637-.75c.524-.75 0-1.762-.938-1.762-.9 0-1.425 1.012-.938 1.762a1.66 1.66 0 00-.675.75 1.688 1.688 0 01-.224-1.425c.412-1.8 2.962-1.987 3.6-.187.112.262.412.262.337.262.9 0 0-1.95-1.763-2.212v-1.2c0-.488-.75-.488-.75 0v1.162c-2.662.375-3.112 4.163-.487 5.063 1.313.45 2.85-.225 3.337-1.613.113-.262-.075-.487-.3-.487zm-3.112 1.2c.225-1.125 1.725-1.125 1.988 0-.6.375-1.388.375-1.988 0zm.975-2.325c.488 0 .488.75 0 .75s-.488-.75 0-.75z"
      fill={color || "currentColor"}
    />
  </svg>
);
export default DevelopingServices;
